import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  EquipmentTypeDto,
  EquipmentTypeDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  equipmentTypeStore,
  getEquipmentTypesColumnsFx,
  getEquipmentTypesFx,
  deleteEquipmentTypeFx,
  exportEquipmentTypesFx,
  importEquipmentTypesFx,
} from '../equipmentType.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_EQUIPMENTTYPE_LINK_KEY,
  DELETE_EQUIPMENTTYPE_LINK_KEY,
  IMPORT_EQUIPMENTTYPES_LINK_KEY,
  EXPORT_EQUIPMENTTYPES_LINK_KEY,
  GetEquipmentTypeParams,
} from '../equipmentType.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { EquipmentTypeDialog } from './equipmentType.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type EquipmentTypesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (equipmentTypeParams: GetEquipmentTypeParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    equipmentType: EquipmentTypeDto,
    equipmentTypeParams: GetEquipmentTypeParams,
  ) => void;
};

export const EquipmentTypesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: EquipmentTypesListProps) => {
  const [
    equipmentTypes,
    setEquipmentTypes,
  ] = useState<EquipmentTypeDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  const {
    equipmentTypeColumns: columns,
    defaultEquipmentTypeColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(equipmentTypeStore);

  useEffect(() => {
    getEquipmentTypesColumnsFx().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const getEquipmentTypes = useCallback(async () => {
    const equipmentTypesData = await getEquipmentTypesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });

    if (equipmentTypesData) {
      setActions(getActions(equipmentTypesData));
      setEquipmentTypes(equipmentTypesData);
      setGetDataSuccess(true);
    }
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: EquipmentTypeDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_EQUIPMENTTYPES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importEquipmentTypesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getEquipmentTypes();
          },
          result?.links,
        ),
        getAction(
          EXPORT_EQUIPMENTTYPES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportEquipmentTypesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getEquipmentTypes],
  );

  useEffect(() => {
    getEquipmentTypes();
  }, [getEquipmentTypes]);

  const onDeleteEquipmentType = useCallback(
    async (equipmentType: EquipmentTypeDto) => {
      if (
        userHas(DELETE_EQUIPMENTTYPE_LINK_KEY, equipmentTypes?.items[0]?.links)
      ) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Equipment type`,
            message: 'Are you sure you want to delete equipment type?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteEquipmentTypeFx(equipmentType);
            await getEquipmentTypes();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getEquipmentTypes, onDelete, equipmentTypes],
  );

  const onEditEquipmentType = (equipmentType: EquipmentTypeDto) => {
    if (equipmentType)
      goToDetails({ equipmentTypeId: equipmentType.equipmentTypeId });
  };

  const onEquipmentTypeSelect = useCallback(
    async (equipmentType) => {
      const selectedEquipmentType = await showDialog({
        dialog: EquipmentTypeDialog,
        props: {
          equipmentTypeId: equipmentType.equipmentTypeId,
          title: 'Update Equipment type',
        },
      });
      if (selectedEquipmentType) {
        getEquipmentTypes();
      }
    },
    [getEquipmentTypes],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['equipmentTypeId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={equipmentTypes.items}
          onLimitChanged={onLimitChanged}
          total={equipmentTypes.totalCount}
          onDelete={
            userHas(
              DELETE_EQUIPMENTTYPE_LINK_KEY,
              equipmentTypes?.items[0]?.links,
            )
              ? onDeleteEquipmentType
              : null
          }
          onSort={onSort}
          onEdit={onEditEquipmentType}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onEquipmentTypeSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {equipmentTypes?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Equipment type"
            entityLinkKey={CREATE_EQUIPMENTTYPE_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
