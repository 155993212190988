import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { OrderForm } from './order.form';
import {
  AttachmentParentType,
  ChargeDto,
  ChargeStatuses,
  ChargeType,
  CommodityDto,
  CommodityStatuses,
  ContactDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
  DimensionsUnit,
  DivisionDto,
  DocumentTemplateType,
  EntityTypes,
  EquipmentTypeDto,
  OrderDto,
  OrderStatusDto,
  OrderTypes,
  PackageTypeDto,
  PaidAs,
  StatusStage,
  TrackingEventDto,
  VisibleTransactions,
  VolumeUnit,
  WeightUnit,
} from '../../../models/data.models';
import { createOrder, getOrder, updateOrder } from '../orders.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CommodityForm } from '../../commodities/components/commodity.form';
import { organizationsStore } from '../../organization/organization.store';
import { CommoditiesForOrderList } from '../../commodities/components/commodities-forOrder-list.component';
import {
  FormattedProfitValues,
  OrderEditFormDto,
  OrderEntityEditFormDto,
  ProfitValues,
} from '../../../models/custom.models';
import { GetCommodityParams } from '../../commodities/commodities.service';
import { showDialog } from '../../common/dialog.store';
import { CommodityDialog } from '../../commodities/components/commodity.dialog';
import { useLocation } from 'react-router-dom';
import { ChargeForOrderList } from '../../charges/components/charge-forOrder-list.component';
import {
  CREATE_CHARGE_LINK_KEY,
  GetChargeParams,
} from '../../charges/charges.service';
import { ChargeDialog } from '../../charges/components/charge.dialog';
import { ChargeForm } from '../../charges/components/charge.form';
import { ShipperForm } from './form-items/shipper-form';
import { FormContext } from '../../common/components/form/form.component';
import { ConsigneeForm } from './form-items/consignee-form';
import { SendEmailDialog } from '../../sendEmail/components/sendEmail.dialog';
import {
  generateValidationSchemaWithCustomFields,
  getEnumKeyByValue,
  getPaidAs,
  validateNumberInput,
  validatePositiveNumberInput,
} from '../../../utils/helper.utils';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import {
  getDocumentTemplatesFx,
  getRenderedDocumentLink,
} from '../../documentTemplates/documentTemplates.store';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import {
  ChargeDefaultValues,
  CommodityDefaultValues,
  EntityDefaultValues,
  OrderDefaultValues,
  ProfitDefaultValues,
} from '../../common/DefaultValues';
import * as Yup from 'yup';
import { getFormattedPrice } from '../../../utils/formatting.utils';
import { currencyStore } from '../../currencies/currencies.store';
import { getContact, getContacts } from '../../contacts/contacts.store';
import { authStore, userHas } from '../../auth/auth.store';
import { CarrierDialog } from '../../carriers/components/carrier.dialog';
import { DriverDialog } from '../../drivers/components/driver.dialog';
import { FormikProps, FormikValues } from 'formik';
import { Prompt } from '../../common/components/prompt/prompt.component';
import { TrackingEventForOrderList } from '../../trackingEvents/components/trackingEvents-forOrder-list.component';
import { GetTrackingEventParams } from '../../trackingEvents/trackingEvents.service';
import { TrackingEventDialog } from '../../trackingEvents/components/trackingEvent.dialog';
import { getEventDefinitionFx } from '../../eventDefinitions/eventDefinitions.store';
import { BOLManagerDialog } from './document-managers/bol-manager.dialog';
import { BOLType } from './document-managers/bol-manager.component';
import { RateManagerDialog } from './document-managers/rate-manager.dialog';
import { RateType } from './document-managers/rate-manager.component';
import { ConfirmationDialog } from '../../common/components/confirmation-dialog/confirmation.dialog';
import { addMessage, Message } from '../../common/messages.store';
import { SelectContactDialog } from '../../contacts/components/select-contact.dialog';
import { GenerateInvoiceDialog } from '../../invoiceManager/components/generateInvoice.dialog';
import { getUnInvoicedOrders } from '../../invoiceManager/invoiceManager.store';
import { AiFillPlusCircle } from 'react-icons/ai';

export type OrderEditProps = {
  orderId: number | null;
  onOrderCreated?: (order: OrderDto) => void;
  onOrderUpdated?: (order: OrderDto) => void;
  onOrderLoaded?: (order: OrderDto) => void;
  onCancel?: () => void;
  onInvoiceGenerated?: () => void;
};
const getInitialStateOrderPickupEditForm = () => {
  const initialStateOrderPickupEditForm: OrderEntityEditFormDto = {
    created: null,
    createdBy: null,
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderId: 0,
    orderEntityId: 0,
    orderEntitySequence: 0,
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    customValues: { pickDate: null, shippingNotes: '', shipperFilter: '' },
    entityType: EntityTypes.Shipper,
  };
  return initialStateOrderPickupEditForm;
};

const getInitialStateOrderDeliveryEditForm = () => {
  const initialStateOrderDeliveryEditForm: OrderEntityEditFormDto = {
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    created: null,
    createdBy: null,
    entityType: EntityTypes.Consignee,
    customValues: {
      deliveryDate: null,
      deliveryNotes: '',
      consigneeFilter: '',
    },
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderEntityId: 0,
    orderEntitySequence: 0,
    orderId: 0,
  };
  return initialStateOrderDeliveryEditForm;
};
const initialStateCommodity: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  commodityTypeId: null,
  commodityTypeCode: CommodityDefaultValues.description,
  customValues: CommodityDefaultValues.customValues,
  valueByTotal: CommodityDefaultValues.valueByTotal,
};
const initialProfitValues: ProfitValues = {
  expense: ProfitDefaultValues.expense,
  income: ProfitDefaultValues.income,
  profit: ProfitDefaultValues.profit,
};

const getInitialState = () => {
  const initialState: OrderEditFormDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: [],
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntities: [
      getInitialStateOrderPickupEditForm(),
      getInitialStateOrderDeliveryEditForm(),
    ],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    links: [],
  };
  return initialState;
};

let orderSchema = Yup.object().shape({
  billToContactId: Yup.string().required("Can't be blank").nullable(true),
  orderStatusId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

const numberSchema = Yup.string()
  .transform((value) => (value === null ? '' : value))
  .test('numberFormat', 'Incorrect number format', (value) => {
    return (
      (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
        value?.toString(),
      ) &&
        Number(value) < Number.MAX_SAFE_INTEGER &&
        Number(value) > Number.MIN_SAFE_INTEGER) ||
      value === '' ||
      value === undefined
    );
  })
  .test('length', 'Max value is 999999', (value) => {
    return value === undefined || Number(value) <= 999999;
  })
  .nullable(true);

const commoditySchema = Yup.object().shape({
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
});

export const OrderEdit = ({
  orderId = 0,
  onOrderLoaded = () => {},
  onOrderCreated = () => {},
  onOrderUpdated = () => {},
  onCancel = () => {},
  onInvoiceGenerated = () => {},
}: OrderEditProps) => {
  const { user: currentUser } = authStore.getState();
  const { currentOrganization } = organizationsStore.getState();

  const limit = 20;
  const isCreateMode = !orderId || orderId == 0;

  const commodityFormRef = useRef<HTMLFormElement>();
  const orderFormRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const forceUpdate: () => void = useState()[1].bind(null, {});

  const [
    orderContextFormValue,
    setOrderContextFormValue,
  ] = useState<OrderEditFormDto | null>(null);

  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    getInitialState(),
  );

  const [
    applyToContactPaidAs,
    setApplyToContactPaidAs,
  ] = useState<PaidAs | null>(null);
  const [
    applyToCarrierPaidAs,
    setApplyToCarrierPaidAs,
  ] = useState<PaidAs | null>(null);

  const [packageType, setPackageType] = useState<{
    packageTypeId: string;
    name: string;
  }>(null);

  const [
    profitValues,
    setProfitValues,
  ] = useState<FormattedProfitValues | null>(null);

  const [commodities, setCommodities] = useState<CommodityDto[] | null>([]);
  const [fakeCommodityIndex, setFakeCommodityIndex] = useState<number>(null);

  const [charges, setCharges] = useState<ChargeDto[] | null>([]);
  const [trackingEvents, setTrackingEvents] = useState<
    TrackingEventDto[] | null
  >([]);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [carriers, setCarriers] = useState<ContactDto[]>([]);

  const [
    rateDocumentTemplatesCount,
    setRateDocumentTemplatesCount,
  ] = useState<number>(0);

  const [
    bolDocumentTemplatesCount,
    setBolDocumentTemplatesCount,
  ] = useState<number>(0);

  const isOrgAdminOrOperation: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;

  const isOrgAdminOrAccounting: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInAccountingRole;

  const listener = (e, carrierElements, carrierElement) => {
    const index = Array.prototype.indexOf.call(carrierElements, carrierElement);
    const carrierOrDriver = carriers[index];
    onCarrierDriverEdit(carrierOrDriver, index, carrierElement);
  };

  useEffect(() => {
    setTimeout(() => {
      const carrierElements = document.querySelectorAll(
        '.select__multi-value__label',
      );
      carrierElements.forEach((carrierElement: HTMLElement) => {
        carrierElement.onclick = (e) =>
          listener(e, carrierElements, carrierElement);
      });
    }, 300);
  }, [carriers]);

  const contactTypeToDialog = (contactType: string) => {
    switch (contactType) {
      case ContactType.Carrier:
        return CarrierDialog;
      case ContactType.Driver:
        return DriverDialog;
      default:
        return null;
    }
  };

  const onCarrierDriverEdit = async (
    carrierOrDriver: ContactDto,
    index: number,
    carrierElement: HTMLElement,
  ) => {
    if (carrierOrDriver?.contactId) {
      let contactType;
      if (carrierOrDriver.contactType) {
        contactType = carrierOrDriver.contactType;
      } else {
        const contact = await getContact({
          contactId: Number(carrierOrDriver.contactId) || null,
        });
        contactType = contact.contactType;
      }
      const contactDialog = contactTypeToDialog(contactType);
      if (contactDialog) {
        showDialog({
          dialog: contactDialog,
          props: {
            contactId: carrierOrDriver.contactId,
            title: `Update ${contactType}`,
            disableDots: true,
          },
        }).then(
          async () => {
            const editedCarrierDriver = await getContact({
              contactId: Number(carrierOrDriver.contactId) || null,
            });
            setCarriers((oldCarriers) => {
              oldCarriers[index] = editedCarrierDriver;
              return { ...oldCarriers };
            });
            setOrderContextFormValue((oldOrderContextFormValue) => {
              oldOrderContextFormValue.carriers[index] = editedCarrierDriver;
              return { ...oldOrderContextFormValue };
            });
            carrierElement.innerText = `${editedCarrierDriver?.name} — ${editedCarrierDriver?.contactType}`;
          },
          () => {},
        );
      }
    }
  };

  useEffect(() => {
    getDocumentTemplatesFx({
      filter: `documentTemplateType:RateConfirmationDocument AND isInactive: false`,
    }).then((documentTemplates) => {
      setRateDocumentTemplatesCount(documentTemplates.items?.length);
    });
    getDocumentTemplatesFx({
      filter: `documentTemplateType:BillOfLadingDocument AND isInactive: false`,
    }).then((documentTemplates) => {
      setBolDocumentTemplatesCount(documentTemplates.items?.length);
    });
  }, []);

  useEffect(() => {
    initialProfitValues.profit =
      initialProfitValues.income - initialProfitValues.expense;
    const income = getFormattedPrice(
      initialProfitValues.income,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const expense = getFormattedPrice(
      initialProfitValues.expense,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const profit = getFormattedPrice(
      initialProfitValues.profit,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    setProfitValues({ income, expense, profit });
    setOrderContextFormValue((orderContextFormValueDto) => {
      orderContextFormValueDto = getInitialState();
      return { ...orderContextFormValueDto };
    });
  }, []);
  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Order} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        orderSchema = generateValidationSchemaWithCustomFields(
          customFields,
          orderSchema,
        );
        setCustomFields(customFields);
      },
      () => {},
    );
    getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
      (result) => {
        const defaultDispatcher = result.items.find(
          (employee) => employee.userEmployee?.userId == currentUser?.userId,
        );
        setOrderContextFormValue((orderContextFormValueDto) => {
          orderContextFormValueDto.employeeContactName =
            defaultDispatcher?.name;
          orderContextFormValueDto.employeeContactId =
            defaultDispatcher?.contactId;
          setInitialValues({ ...orderContextFormValueDto });
          return { ...orderContextFormValueDto };
        });
      },
      () => {},
    );
    if (isCreateMode) {
      setFakeCommodityIndex(0);
      setIsLoading(false);
    } else if (orderId) {
      getOrder({ orderId }).then(
        (orderDto: OrderDto) => {
          if (orderDto.trackingEvents) {
            orderDto.trackingEvents.forEach((trackingEvent) => {
              if (trackingEvent.eventDefinitionId) {
                getEventDefinitionFx({
                  eventDefinitionId: trackingEvent.eventDefinitionId,
                }).then(
                  (eventDefinition) =>
                    (trackingEvent.eventName = eventDefinition.eventName),
                );
              }
            });
          }

          setOrderContextFormValue((orderContextFormValueDto) => {
            if (!orderContextFormValueDto) {
              orderContextFormValueDto = getInitialState();
            }
            orderContextFormValueDto.orderId = orderDto.orderId;
            orderContextFormValueDto.orderStatus = orderDto.orderStatus;
            orderContextFormValueDto.orderNumber = orderDto.orderNumber;
            orderDto.charges.forEach((chargeDto) => {
              if (chargeDto.chargeType === ChargeType.IncomeCharge) {
                chargeDto.income = chargeDto.amount;
              } else {
                chargeDto.expense = chargeDto.amount;
              }
            });
            orderContextFormValueDto.charges = orderDto.charges;
            orderContextFormValueDto.trackingEvents = orderDto.trackingEvents;
            if (orderDto?.orderEntities && orderDto?.orderEntities.length > 0) {
              orderContextFormValueDto.orderEntities = orderDto?.orderEntities
                ?.map<OrderEntityEditFormDto>((item) => {
                  return {
                    orderId: item.orderId,
                    created: item.created,
                    createdBy: item.createdBy,
                    lastModified: item.lastModified,
                    lastModifiedBy: item.lastModifiedBy,
                    links: item.links,
                    orderEntityId: item.orderEntityId,
                    orderEntitySequence: item.orderEntitySequence,
                    contactAddressId: item.contactAddressId,
                    contactAddressName: item.contactAddressName,
                    contactId: item.contactId,
                    contactName: item.contactName,
                    contactType: item.contactType,
                    filter: item.contactId
                      ? `contactId:${item.contactId}`
                      : ' ',
                    customValues: item.customValues,
                    entityType: item.entityType,
                  };
                })
                .sort((a, b) => a.orderEntityId - b.orderEntityId);
            } else {
              orderContextFormValueDto.orderEntities = [
                getInitialStateOrderPickupEditForm(),
                getInitialStateOrderDeliveryEditForm(),
              ];
            }
            orderContextFormValueDto.billToContactId = orderDto.billToContactId;
            orderContextFormValueDto.billToContactName =
              orderDto.billToContactName;
            orderContextFormValueDto.billToContactType =
              orderDto.billToContactType;
            orderContextFormValueDto.carrierContactId =
              orderDto.carrierContactId;
            (orderContextFormValueDto.orderStatusId = orderDto?.orderStatusId),
              (orderContextFormValueDto.carrierContactName =
                orderDto.carrierContactName);
            orderContextFormValueDto.commodities = orderDto.commodities;
            orderContextFormValueDto.commodities.map((commodity) => {
              commodity.commodityStatus = commodity.commodityStatus;
            });
            orderContextFormValueDto.weighTotal = orderDto.weighTotal;
            orderContextFormValueDto.volumeTotal = orderDto.volumeTotal;
            orderContextFormValueDto.totalPcsCrt = orderDto.totalPcsCrt;
            orderContextFormValueDto.salespersonContactName =
              orderDto.salespersonContactName;
            orderContextFormValueDto.salespersonContactId =
              orderDto.salespersonContactId;
            orderContextFormValueDto.organizationId = orderDto.organizationId;
            orderContextFormValueDto.links = orderDto.links;
            orderContextFormValueDto.lastModifiedBy = orderDto.lastModifiedBy;
            orderContextFormValueDto.lastModified = orderDto.lastModified;
            if (orderDto.employeeContactName && orderDto.employeeContactId) {
              orderContextFormValueDto.employeeContactName =
                orderDto.employeeContactName;
              orderContextFormValueDto.employeeContactId =
                orderDto.employeeContactId;
            }
            orderContextFormValueDto.divisionName = orderDto.divisionName;
            orderContextFormValueDto.divisionId = orderDto.divisionId;
            orderContextFormValueDto.equipmentTypeId = orderDto.equipmentTypeId;
            orderContextFormValueDto.equipmentTypeName =
              orderDto.equipmentTypeName;
            orderContextFormValueDto.createdBy = orderDto.createdBy;
            orderContextFormValueDto.created = orderDto.created;
            orderContextFormValueDto.carriers = orderDto.carriers;
            orderContextFormValueDto.customValues = orderDto.customValues;
            orderContextFormValueDto.trackingNumber = orderDto.trackingNumber;
            setInitialValues({ ...orderContextFormValueDto });
            return { ...orderContextFormValueDto };
          });
          orderDto.billToContactId
            ? getPaidAs(orderDto.billToContactId).then((paidAs) => {
                setApplyToContactPaidAs(paidAs);
              })
            : setApplyToContactPaidAs(ChargeDefaultValues.paidAs);
          orderDto.carriers[0]?.contactId
            ? setApplyToCarrierPaidAs(orderDto.carriers[0].paidAs)
            : setApplyToCarrierPaidAs(ChargeDefaultValues.paidAs);
          setCarriers(orderDto?.carriers);
          onOrderLoaded(orderDto);
          setCommodities(orderDto.commodities);
          setFakeCommodityIndex(
            (orderDto.commodities[orderDto.commodities.length - 1]
              ?.commodityId +
              1) |
              0,
          );
          setCharges(orderDto.charges);
          countProfitValue(orderDto.charges);
          setTrackingEvents(orderDto.trackingEvents);
          setIsLoading(false);
        },
        () => {},
      );
    } else {
      throw new Error('Order keys were not provided');
    }
  }, [orderId]);
  useEffect(() => {
    if (isLoading === false)
      setOrderContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.commodities = commodities;
        return orderEditFormDto;
      });
  }, [commodities]);
  useEffect(() => {
    if (isLoading === false) {
      setOrderContextFormValue((orderDto) => {
        if (!orderDto) {
          orderDto = getInitialState();
        }
        orderDto.charges = charges;
        return { ...orderDto };
      });
      countProfitValue(charges);
    }
  }, [charges]);
  useEffect(() => {
    if (isLoading === false)
      setOrderContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.trackingEvents = trackingEvents;
        return orderEditFormDto;
      });
  }, [trackingEvents]);

  const goToDetailsCommodity = (commodityParams: GetCommodityParams) => {
    const cloneCommodity = Object.assign({}, commodityParams.commodity);
    if (commodityParams.commodity) {
      showDialog({
        dialog: CommodityDialog,
        props: {
          title: 'Update Commodity',
          commodity: commodityParams?.commodity,
          commodityId: commodityParams?.commodity?.commodityId,
          className: 'commodity-modal',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          isEditMode: true,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: CommodityDto[] = commodities.map((obj) => {
              const compareCommodityResult =
                obj.commodityId === commodityParams?.commodity.commodityId;
              if (compareCommodityResult) obj = result;
              return obj;
            });
            setCommodities(
              itemsResult.sort((a, b) => a.commodityId - b.commodityId),
            );
          } else {
            setCommodities((oldCommodities) => {
              const index = oldCommodities.findIndex(
                (commodity) =>
                  commodity.commodityId === cloneCommodity.commodityId,
              );
              oldCommodities[index] = cloneCommodity;
              return oldCommodities;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToDetailsCharge = (chargeParams: GetChargeParams) => {
    const cloneCharge = Object.assign({}, chargeParams.charge);
    if (chargeParams.charge) {
      showDialog({
        dialog: ChargeDialog,
        props: {
          title: 'Update Charge',
          charge: chargeParams?.charge,
          chargeId: chargeParams?.charge?.chargeId,
          chargeType: chargeParams?.charge?.chargeType,
          className: 'charge-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          charges: charges,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: ChargeDto[] = charges.map((obj) => {
              const compareChargeResult =
                obj.chargeId === chargeParams?.charge.chargeId;
              if (compareChargeResult) obj = result;
              return obj;
            });
            setCharges(itemsResult.sort((a, b) => a.chargeId - b.chargeId));
          } else {
            setCharges((oldCharges) => {
              const index = oldCharges.findIndex(
                (charge) => charge.chargeId === cloneCharge.chargeId,
              );
              oldCharges[index] = cloneCharge;
              return oldCharges;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToDetailsTrackingEvent = (
    trackingEventParams: GetTrackingEventParams,
  ) => {
    const cloneEvent = Object.assign({}, trackingEventParams.trackingEvent);
    if (trackingEventParams.trackingEvent) {
      showDialog({
        dialog: TrackingEventDialog,
        props: {
          title: 'Update Event',
          trackingEvent: trackingEventParams?.trackingEvent,
          trackingEventId: trackingEventParams?.trackingEvent?.trackingEventId,
          className: 'trackingEvent-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: TrackingEventDto[] = trackingEvents.map(
              (obj) => {
                const compareTrackingEventResult =
                  obj === trackingEventParams?.trackingEvent;
                if (compareTrackingEventResult) obj = result;
                return obj;
              },
            );
            setTrackingEvents(
              itemsResult.sort((a, b) => a.trackingEventId - b.trackingEventId),
            );
          } else {
            setTrackingEvents((oldTrackingEvents) => {
              const index = oldTrackingEvents.findIndex(
                (trackingEvent) =>
                  trackingEvent.trackingEventId === cloneEvent.trackingEventId,
              );
              oldTrackingEvents[index] = cloneEvent;
              return oldTrackingEvents;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const countProfitValue = (charges: ChargeDto[]) => {
    const { defaultCurrency } = currencyStore?.getState();
    setDefaultCurrency(defaultCurrency);
    setProfitValues((profitValue) => {
      const initialZero = getFormattedPrice(0, defaultCurrency?.decimalPlaces);
      profitValue.income = initialZero;
      profitValue.expense = initialZero;
      profitValue.profit = initialZero;
      charges.forEach((item) => {
        if (item.isDeleted) return;
        let totalPrice = item.price * item.quantity;
        if (item.chargeType === ChargeType.IncomeCharge)
          profitValue.income = (
            Number(profitValue.income) + Number(totalPrice)
          ).toString();
        else
          profitValue.expense = (
            Number(profitValue.expense) + Number(totalPrice)
          ).toString();
      });
      profitValue.profit = getFormattedPrice(
        Number(profitValue.income) - Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.income = getFormattedPrice(
        Number(profitValue.income),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.expense = getFormattedPrice(
        Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      return { ...profitValue };
    });
  };
  const getCommodities = (): CommodityDto[] => {
    return commodities?.sort((a, b) => a.commodityId - b.commodityId);
  };
  const getCharges = (): ChargeDto[] => {
    return charges?.sort((a, b) => a.chargeId - b.chargeId);
  };
  const getTrackingEvents = (): TrackingEventDto[] => {
    let events = trackingEvents;
    events?.forEach((event) => {
      if (typeof event.eventDate === 'object') {
        event.eventDate = event.eventDate.toISOString();
      }
    });
    setTrackingEvents(events);
    return events?.sort(
      (a, b) =>
        new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
    );
  };
  const getTotalPcsCrt = () => {
    let sumPieces: number = 0;
    commodities?.forEach((commodity) => {
      sumPieces += Number(commodity.pieces);
    });
    return sumPieces;
  };
  const getWeightTotal = () => {
    let weightTotal = 0;
    commodities?.forEach((commodity) => {
      weightTotal += commodity.weightTotal;
    });
    return weightTotal;
  };
  const getVolumeTotal = () => {
    let volumeTotal = 0;
    commodities?.forEach((commodity) => {
      volumeTotal += commodity?.volumeTotal;
    });
    return volumeTotal;
  };

  const onChargeCreated = (charge: ChargeDto) => {
    if (!charges) {
      const initialCharge: ChargeDto[] = [];
      setCharges(initialCharge);
    }
    setCharges((chargesDto) => [...chargesDto, charge]);
  };

  const onTrackingEventCreated = (trackingEvent: TrackingEventDto) => {
    if (!trackingEvents) {
      const initialTrackingEvent: TrackingEventDto[] = [];
      setTrackingEvents(initialTrackingEvent);
    }
    setTrackingEvents((trackingEventsDto) => [
      ...trackingEventsDto,
      trackingEvent,
    ]);
  };

  const onShipperDateChange = (
    { setFieldValue },
    date?: any,
    setShipperDateIndex?: number,
  ) => {
    setOrderContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperDateIndex]) {
        orderPickups[
          setShipperDateIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperDateIndex].customValues['pickDate'] = new Date(
        date,
      );
      setFieldValue(
        `orderPickups[${setShipperDateIndex}].pickDate`,
        new Date(date),
      );
      return { ...orderDto };
    });
  };
  const onDeliveryDateChange = (
    { setFieldValue },
    date?: any,
    setDeliveryDateIndex?: number,
  ) => {
    setOrderContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setDeliveryDateIndex]) {
        orderDeliveries[
          setDeliveryDateIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setDeliveryDateIndex].customValues[
        'deliveryDate'
      ] = new Date(date);
      setFieldValue(
        `orderDeliveries[${setDeliveryDateIndex}].deliveryDate`,
        new Date(date),
      );
      return { ...orderDto };
    });
  };
  const onShipperNoteChange = (data?: any, setShipperNoteIndex?: number) => {
    setOrderContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperNoteIndex]) {
        orderPickups[
          setShipperNoteIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperNoteIndex].customValues['shippingNotes'] =
        data.target.value;
      return { ...orderDto };
    });
  };
  const onDeliveryNoteChange = (data?: any, setConsigneeNoteIndex?: number) => {
    setOrderContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setConsigneeNoteIndex]) {
        orderDeliveries[
          setConsigneeNoteIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setConsigneeNoteIndex].customValues['deliveryNotes'] =
        data.target.value;
      return { ...orderDto };
    });
  };
  const setShipperFilter = (data?: any, setShipperFilterIndex?: number) => {
    setOrderContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperFilterIndex]) {
        orderPickups[
          setShipperFilterIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperFilterIndex].filter = data;
      return { ...orderDto };
    });
  };
  const setDeliveryFilter = (data?: any, setDeliveryFilterIndex?: number) => {
    setOrderContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setDeliveryFilterIndex]) {
        orderDeliveries[
          setDeliveryFilterIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setDeliveryFilterIndex].filter = data;
      return { ...orderDto };
    });
  };
  const setShipperContactAddress = (
    contactAddressId: number,
    addressLine: string,
    shipperContactAddressFilterIndex?: number,
  ) => {
    setOrderContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[shipperContactAddressFilterIndex]) {
        orderPickups[
          shipperContactAddressFilterIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[
        shipperContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderPickups[
        shipperContactAddressFilterIndex
      ].contactAddressName = addressLine;
      return { ...orderDto };
    });
  };
  const setDeliveryContactAddress = (
    contactAddressId: number,
    addressLine: string,
    deliveryContactAddressFilterIndex?: number,
  ) => {
    setOrderContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[deliveryContactAddressFilterIndex]) {
        orderDeliveries[
          deliveryContactAddressFilterIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressName = addressLine;
      return { ...orderDto };
    });
  };
  const setShipperContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    shipperContactIndex?: number,
  ) => {
    setOrderContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups[shipperContactIndex]) {
        orderPickups[
          shipperContactIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[shipperContactIndex].contactId = Number(contactId);
      orderPickups[shipperContactIndex].contactName = name;
      orderPickups[shipperContactIndex].contactType = contactType;
      return { ...orderDto };
    });
  };
  const setDeliveryContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    consigneeContactIndex?: number,
  ) => {
    setOrderContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries[consigneeContactIndex]) {
        orderDeliveries[
          consigneeContactIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[consigneeContactIndex].contactId = Number(contactId);
      orderDeliveries[consigneeContactIndex].contactName = name;
      orderDeliveries[consigneeContactIndex].contactType = contactType;
      return { ...orderDto };
    });
  };

  const pickupDeliveryFilter = (data: OrderDto) => {
    let orderPickups = data?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    let orderDeliveries = data?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );

    if (orderPickups.length > 1) {
      orderPickups = orderPickups.filter((item) => {
        return item?.contactId != null && !isNaN(item?.contactId);
      });
    }
    if (orderPickups.length === 0) {
      orderPickups.push({
        ...getInitialStateOrderPickupEditForm(),
      });
    }

    if (orderDeliveries.length > 1) {
      orderDeliveries = orderDeliveries.filter((item) => {
        return item?.contactId != null && !isNaN(item?.contactId);
      });
    }
    if (orderDeliveries.length === 0) {
      orderDeliveries.push({
        ...getInitialStateOrderDeliveryEditForm(),
      });
    }

    data.orderEntities = [...orderPickups, ...orderDeliveries];
  };

  const onOrderStatusChange = (
    newValueOrderStatus: OrderStatusDto,
    context: FormikProps<FormikValues>,
  ) => {
    let newOrderStatusId = newValueOrderStatus?.orderStatusId;
    if (newValueOrderStatus?.requireConfirmation === true) {
      showDialog({
        dialog: Prompt,
        props: {
          title: `Cancel ${orderContextFormValue?.orderNumber} Order`,
          message: `Please type order number (${orderContextFormValue?.orderNumber}) to confirm its cancelling.`,
          className: 'cancel-order-modal',
          orderNumber: orderContextFormValue?.orderNumber,
        },
      }).then((result) => {
        if (!result) {
          newOrderStatusId = orderContextFormValue?.orderStatusId;
        }
        setOrderContextFormValue((orderContextFormValueDto) => {
          if (!orderContextFormValueDto) {
            orderContextFormValueDto = getInitialState();
          }
          orderContextFormValueDto.orderStatusId = newOrderStatusId;
          context.setFieldValue(
            'orderStatusId',
            orderContextFormValueDto?.orderStatusId,
          );
          setInitialValues({ ...orderContextFormValueDto });
          return { ...orderContextFormValueDto };
        });
      });
    } else {
      setOrderContextFormValue((orderContextFormValueDto) => {
        if (!orderContextFormValueDto) {
          orderContextFormValueDto = getInitialState();
        }
        orderContextFormValueDto.orderStatusId =
          newValueOrderStatus?.orderStatusId;
        context.setFieldValue(
          'orderStatusId',
          newValueOrderStatus?.orderStatusId,
        );
        orderContextFormValue.orderStatus = newValueOrderStatus;
        setInitialValues({ ...orderContextFormValueDto });
        return { ...orderContextFormValueDto };
      });
    }
  };

  const onSaveOrder = (data: OrderDto) => {
    data.commodities = commodities;
    data.charges = charges;
    data.trackingEvents = trackingEvents;
    data.commodities = data.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    data.carriers = orderContextFormValue?.carriers;
    data.customValues = orderContextFormValue?.customValues;

    pickupDeliveryFilter(data);

    setIsSending(true);
    return data;
  };

  const onSubmit = (data: OrderDto) => {
    data.commodities = commodities;
    data.charges = charges;
    data.trackingEvents = trackingEvents;
    data.commodities = data.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        warehouseLocationId: item.warehouseLocation?.warehouseLocationId,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    data.carriers = orderContextFormValue?.carriers;
    data.customValues = orderContextFormValue?.customValues;

    pickupDeliveryFilter(data);

    setIsSending(true);
    if (isCreateMode) {
      createOrder(data)
        .then(
          (result) => {
            onOrderCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateOrder(data)
        .then(
          (result) => {
            onOrderUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  const onSubmitCommodityDto = (
    data: CommodityDto,
    { resetForm, setFieldValue },
  ) => {
    const { currentOrganization } = organizationsStore.getState();
    data.commodityId = fakeCommodityIndex;
    data.organizationId = currentOrganization.organizationId;
    data.commodityStatus = null;
    data.dimensionsUnit = DimensionsUnit.In;
    data.weightUnit = WeightUnit.Lb;
    data.volumeUnit = VolumeUnit.Vkg;
    data.weightTotal = data.pieces * data.weight;
    data.volumePiece = data.length * data.height * data.width;
    data.volumeTotal = data.pieces * data.volumePiece;
    setCommodities((commoditiesDto) => [...commoditiesDto, data]);
    setPackageType((packageTypeDto) => {
      packageTypeDto = {
        name: null,
        packageTypeId: null,
      };
      return { ...packageTypeDto };
    });
    resetForm();
    setFieldValue('description', '');
    setFieldValue('pieces', '1');
    setFieldValue('length', '');
    setFieldValue('width', '');
    setFieldValue('height', '');
    setFieldValue('weight', '');
    setFieldValue('packageTypeId', '');
    setFieldValue('packageTypeSelect', '');
    setFieldValue('commodityTypeSelect', '');
    setFakeCommodityIndex(fakeCommodityIndex + 1);
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  const createNewItemOrderPickup = () => {
    setOrderContextFormValue((orderContextFormValueDto) => {
      orderContextFormValueDto?.orderEntities?.push({
        ...getInitialStateOrderPickupEditForm(),
      });
      return { ...orderContextFormValueDto };
    });
  };
  const createNewItemOrderDelivery = () => {
    setOrderContextFormValue((orderContextFormValueDto) => {
      orderContextFormValueDto?.orderEntities?.push({
        ...getInitialStateOrderDeliveryEditForm(),
      });
      return { ...orderContextFormValueDto };
    });
  };
  const deleteItemOrderPickup = (indexItemToDelete?: number, context?: any) => {
    setOrderContextFormValue((orderContextFormValueDto) => {
      const orderPickups = orderContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      const orderDeliveries = orderContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      orderPickups?.splice(indexItemToDelete, 1);
      orderContextFormValueDto.orderEntities = [
        ...orderPickups,
        ...orderDeliveries,
      ];
      context.setFieldValue(
        'orderEntities',
        orderContextFormValueDto.orderEntities,
      );
      return { ...orderContextFormValueDto };
    });
  };

  const deleteItemOrderDelivery = (
    indexItemToDelete?: number,
    context?: any,
  ) => {
    setOrderContextFormValue((orderContextFormValueDto) => {
      const orderPickups = orderContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      const orderDeliveries = orderContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      orderDeliveries?.splice(indexItemToDelete, 1);
      orderContextFormValueDto.orderEntities = [
        ...orderPickups,
        ...orderDeliveries,
      ];
      context.setFieldValue(
        'orderEntities',
        orderContextFormValueDto.orderEntities,
      );
      return { ...orderContextFormValueDto };
    });
  };

  const getItemsOrderPickup = (context?: any) => {
    const orderPickups = orderContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    if (orderPickups == null || orderPickups.length === 0) {
      return (
        <ShipperForm
          shipperFilter={orderPickups[0]?.filter}
          allowDelete={false}
          shipperContact={{
            contactId: orderPickups[0]?.contactId,
            name: orderPickups[0]?.contactName,
            contactType: orderPickups[0]?.contactType,
          }}
          onShipperNoteChange={(note: string) => onShipperNoteChange(note, 0)}
          shipperContactAddress={{
            contactAddressId: orderPickups[0]?.contactAddressId?.toString(),
            addressLine: orderPickups[0]?.contactAddressName,
          }}
          onShipperDateChange={(data) => onShipperDateChange(context, data, 0)}
          index={0}
          setShipperContact={(contactId, name, contactType) =>
            setShipperContact(contactId, name, contactType, 0)
          }
          orderPickupDto={orderPickups[0]}
          setShipperFilter={(data) => setShipperFilter(data, 0)}
          setShipperContactAddress={(contactAddressId, addressLine) =>
            setShipperContactAddress(contactAddressId, addressLine, 0)
          }
        />
      );
    }
    if (orderPickups != null && orderPickups !== undefined) {
      return orderPickups?.map((item, index) => (
        <ShipperForm
          shipperFilter={orderPickups[index]?.filter}
          onShipperNoteChange={(note: string) =>
            onShipperNoteChange(note, index)
          }
          allowDelete={orderPickups?.length > 1}
          shipperContact={{
            contactId: orderPickups[index]?.contactId,
            name: orderPickups[index]?.contactName,
            contactType: orderPickups[index]?.contactType,
          }}
          shipperContactAddress={{
            contactAddressId: orderPickups[index]?.contactAddressId?.toString(),
            addressLine: orderPickups[index]?.contactAddressName,
          }}
          onShipperDeleted={() => deleteItemOrderPickup(index, context)}
          onShipperDateChange={(data) =>
            onShipperDateChange(context, data, index)
          }
          index={index}
          setShipperContact={(contactId, name, contactType) =>
            setShipperContact(contactId, name, contactType, index)
          }
          orderPickupDto={orderPickups[index]}
          setShipperFilter={(data) => setShipperFilter(data, index)}
          setShipperContactAddress={(contactAddressId, addressLine) =>
            setShipperContactAddress(contactAddressId, addressLine, index)
          }
          key={index}
        />
      ));
    } else return '';
  };

  const getItemsOrderDelivery = (context?: any) => {
    const orderDeliveries = orderContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );

    if (orderDeliveries == null || orderDeliveries.length === 0) {
      return (
        <ConsigneeForm
          deliveryFilter={orderDeliveries[0]?.filter}
          allowDelete={false}
          deliveryContact={{
            contactId: orderDeliveries[0]?.contactId,
            name: orderDeliveries[0]?.contactName,
            contactType: orderDeliveries[0]?.contactType,
          }}
          deliveryContactAddress={{
            contactAddressId: orderDeliveries[0]?.contactAddressId?.toString(),
            addressLine: orderDeliveries[0]?.contactAddressName,
          }}
          onDeliveryDateChange={(data) =>
            onDeliveryDateChange(context, data, 0)
          }
          index={0}
          onDeliveryNoteChange={(note) => onDeliveryNoteChange(note, 0)}
          setDeliveryContact={(contactId, name, contactType) =>
            setDeliveryContact(contactId, name, contactType, 0)
          }
          orderDeliveryDto={orderDeliveries[0]}
          setDeliveryFilter={(data) => setDeliveryFilter(data, 0)}
          setDeliveryContactAddress={(contactAddressId, addressLine) =>
            setDeliveryContactAddress(contactAddressId, addressLine, 0)
          }
        />
      );
    }
    if (orderDeliveries != null && orderDeliveries !== undefined) {
      return orderDeliveries?.map((item, index) => (
        <ConsigneeForm
          deliveryFilter={orderDeliveries[index]?.filter}
          allowDelete={orderDeliveries?.length > 1}
          deliveryContact={{
            contactId: orderDeliveries[index]?.contactId,
            name: orderDeliveries[index]?.contactName,
            contactType: orderDeliveries[index]?.contactType,
          }}
          deliveryContactAddress={{
            contactAddressId: orderDeliveries[
              index
            ]?.contactAddressId?.toString(),
            addressLine: orderDeliveries[index]?.contactAddressName,
          }}
          onDeliveryNoteChange={(note) => onDeliveryNoteChange(note, index)}
          onDeliveryDeleted={() => deleteItemOrderDelivery(index, context)}
          onDeliveryDateChange={(data) =>
            onDeliveryDateChange(context, data, index)
          }
          index={index}
          setDeliveryContact={(contactId, name, contactType) =>
            setDeliveryContact(contactId, name, contactType, index)
          }
          orderDeliveryDto={orderDeliveries[index]}
          setDeliveryFilter={(data) => setDeliveryFilter(data, index)}
          setDeliveryContactAddress={(contactAddressId, addressLine) =>
            setDeliveryContactAddress(contactAddressId, addressLine, index)
          }
          key={index}
        />
      ));
    } else return '';
  };

  const isNoRateTemplate = (): boolean => {
    return rateDocumentTemplatesCount === 0;
  };

  const isRateDisabled = (): boolean => {
    const orderPickups = orderContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    const orderDeliveries = orderContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );
    return (
      !orderContextFormValue.carriers ||
      orderContextFormValue.carriers.filter(
        (carrier) => carrier.contactType === ContactType.Carrier,
      ).length === 0 ||
      !orderPickups?.some((orderPickup) => orderPickup.contactId) ||
      !orderDeliveries?.some((orderDelivery) => orderDelivery.contactId)
    );
  };

  const isNoBolTemplate = (): boolean => {
    return bolDocumentTemplatesCount === 0;
  };

  const isBOLDisabled = (): boolean => {
    const orderPickups = orderContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    const orderDeliveries = orderContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );
    return (
      !orderPickups?.some((orderPickup) => orderPickup.contactId) ||
      !orderDeliveries?.some((orderDelivery) => orderDelivery.contactId)
    );
  };

  const getRateDocument = () => {
    const orderData: OrderDto = orderContextFormValue;
    pickupDeliveryFilter(orderData);

    showDialog({
      dialog: RateManagerDialog,
      props: {
        title: 'Rate Confirmation Manager',
        className: 'rate-manager-modal',
        orderDto: orderData,
        setTrackingEvents: setTrackingEvents,
      },
    }).then(
      (rateData: RateType) => {
        if (
          rateData?.shipperId &&
          rateData?.consigneeId &&
          rateData?.carrierId
        ) {
          window.open(
            getRenderedDocumentLink(
              DocumentTemplateType.RateConfirmationDocument,
              {
                orderId: orderData.orderId,
                orderPickupId: rateData.orderPickupId,
                orderDeliveryId: rateData.orderDeliveryId,
                carrierId: rateData.carrierId,
                driverId: rateData.driverId,
              },
            ),
            '_blank',
          );
        }
      },
      () => {},
    );
  };

  const getBOLDocument = () => {
    const orderData: OrderDto = orderContextFormValue;
    pickupDeliveryFilter(orderData);

    showDialog({
      dialog: BOLManagerDialog,
      props: {
        title: 'BOL Manager',
        className: 'bol-manager-modal',
        orderDto: orderData,
        setTrackingEvents: setTrackingEvents,
      },
    }).then(
      (bolData: BOLType) => {
        if (bolData?.shipperId && bolData?.consigneeId) {
          window.open(
            getRenderedDocumentLink(DocumentTemplateType.BillOfLadingDocument, {
              orderId: orderData.orderId,
              orderPickupId: bolData.orderPickupId,
              orderDeliveryId: bolData.orderDeliveryId,
              thirdPartyContactId: bolData.thirdPartyContactId,
              carrierId: bolData.carrierId,
            }),
            '_blank',
          );
        }
      },
      () => {},
    );
  };

  const sendRateEmail = () => {
    const orderData: OrderDto = orderContextFormValue;
    pickupDeliveryFilter(orderData);

    showDialog({
      dialog: RateManagerDialog,
      props: {
        title: 'Rate Confirmation Manager',
        className: 'rate-manager-modal',
        orderDto: orderData,
        setTrackingEvents: setTrackingEvents,
      },
    }).then(
      (rateData: RateType) => {
        if (
          rateData?.shipperId &&
          rateData?.consigneeId &&
          rateData?.carrierId
        ) {
          showDialog({
            dialog: SendEmailDialog,
            props: {
              title: 'Send Rate Confirmation',
              className: 'send-email-modal',
              selectedRecipientId: rateData.carrierId,
              recipientIds: orderData.carriers.map((carrier) => {
                return carrier.contactId;
              }),
              metadata: {
                orderId: orderData.orderId,
                orderPickupId: rateData.orderPickupId,
                orderDeliveryId: rateData.orderDeliveryId,
                carrierId: rateData.carrierId,
                driverId: rateData.driverId,
              },
              emailTemplate: getEnumKeyByValue(
                DocumentTemplateType.RateConfirmationEmail,
                DocumentTemplateType,
              ),
              documentTypes: getEnumKeyByValue(
                [DocumentTemplateType.RateConfirmationDocument],
                DocumentTemplateType,
              ),
              onEmailSent: () => {},
              onEmailTemplateLoaded: () => {},
            },
          }).then(() => {});
        }
      },
      () => {},
    );
  };

  const sendBOLEmail = () => {
    const orderData: OrderDto = orderContextFormValue;
    pickupDeliveryFilter(orderData);

    showDialog({
      dialog: BOLManagerDialog,
      props: {
        title: 'BOL Manager',
        className: 'bol-manager-modal',
        orderDto: orderData,
        setTrackingEvents: setTrackingEvents,
      },
    }).then(
      (bolData: BOLType) => {
        if (bolData?.shipperId && bolData?.consigneeId) {
          showDialog({
            dialog: SendEmailDialog,
            props: {
              title: 'Send Bill of Lading',
              className: 'send-email-modal',
              selectedRecipientId: orderData.billToContactId,
              recipientIds: [orderData.billToContactId],
              metadata: {
                orderId: orderData.orderId,
                customerId: orderData.billToContactId,
                orderPickupId: bolData.orderPickupId,
                orderDeliveryId: bolData.orderDeliveryId,
                thirdPartyContactId: bolData.thirdPartyContactId,
                carrierId: bolData.carrierId,
              },
              emailTemplate: getEnumKeyByValue(
                DocumentTemplateType.BillOfLadingEmail,
                DocumentTemplateType,
              ),
              documentTypes: getEnumKeyByValue(
                [DocumentTemplateType.BillOfLadingDocument],
                DocumentTemplateType,
              ),
              onEmailSent: () => {},
              onEmailTemplateLoaded: () => {},
            },
          }).then(() => {});
        }
      },
      () => {},
    );
  };

  const isRestrictedGetAndSendDocs = (): boolean => {
    return currentUser?.isInOrgUserRole;
  };

  const generateInvoice = () => {
    showDialog({
      dialog: ConfirmationDialog,
      props: {
        className: 'confirmation-dialog-modal',
        title: 'Save changes',
        actionText: 'creating an invoice',
      },
    }).then((confirmResult) => {
      if (confirmResult === true) {
        const filter = null;
        const sort = null;
        const search = '';
        const data = onSaveOrder(orderContextFormValue);
        updateOrder(data).then(() => {
          const message: Message = {
            id: 'order-updated',
            type: 'success',
            autoHide: true,
            message: 'Order successfully updated!',
          };
          addMessage(message);
          setIsSending(false);
          getUnInvoicedOrders({
            offset,
            limit: 10000,
            sort,
            filter,
            search,
          }).then((ordersResult) => {
            const order = ordersResult.items?.find(
              (x) => x.orderId === orderContextFormValue.orderId,
            );

            getContacts({
              filter: order?.customerIds
                .map((x) => `contactId: ${x}`)
                .join(' OR '),
              limit:
                order?.customerIds.length == 0 ? 99 : order?.customerIds.length,
              search: null,
              offset: 0,
              sort: null,
            }).then((contactsResult) => {
              showDialog({
                dialog: SelectContactDialog,
                props: {
                  title: 'Select Customer',
                  className: 'select-contact-modal',
                  contacts: contactsResult.items,
                  multiple: false,
                  header: 'Select Customer',
                  placeholder: 'Select Customer',
                  required: true,
                  onContactsSelected: () => {},
                },
              }).then((selectedContacts) => {
                if (selectedContacts) {
                  showDialog({
                    dialog: GenerateInvoiceDialog,
                    props: {
                      className: 'generate-invoice-modal',
                      title: 'Generate Invoice',
                      orderId: orderContextFormValue.orderId,
                      customerId: selectedContacts[0].contactId,
                      transactionNumber: orderContextFormValue.orderId,
                      onInvoiceGenerated: () => {
                        const message: Message = {
                          id: 'order-invoice-generated',
                          type: 'success',
                          autoHide: true,
                          message: 'Invoice successfully created!',
                        };
                        addMessage(message);
                      },
                    },
                  }).then(() => {
                    getOrder({
                      orderId,
                    }).then((orderDto) => {
                      setCharges(orderDto.charges);
                      setOrderContextFormValue({
                        ...orderContextFormValue,
                        orderStatus: orderDto.orderStatus,
                      });
                      onInvoiceGenerated();
                    });
                  });
                }
              });
            });
          });
        });
      }
    });
  };

  const isGenerateInvoiceEnabled = charges.some(
    (charge) => charge.chargeStatus === ChargeStatuses.Open,
  );

  return (
    <div className={'order-edit-form'}>
      <OrderForm
        id={'OrderForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={orderFormRef}
        validationSchema={orderSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <div className="d-flex justify-content-between">
                  <span>
                    <Tab data-cy={'general'}>Order Information</Tab>
                    <Tab data-cy={'charges'}>Charges</Tab>
                    <Tab data-cy={'events'}>Events</Tab>
                    <Tab data-cy={'additional'}>Additional</Tab>
                    <Tab data-cy={'attachments'} disabled={isCreateMode}>
                      Attachments
                    </Tab>
                  </span>
                  {!isCreateMode && (
                    <ActionBarComponent
                      buttonText={<>&middot;&middot;&middot;</>}
                      style={{
                        minWidth: 'fit-content',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      <button
                        disabled={
                          isRateDisabled() ||
                          isRestrictedGetAndSendDocs() ||
                          isNoRateTemplate()
                        }
                        type={'button'}
                        onClick={getRateDocument}
                        title={
                          isRateDisabled() ||
                          isRestrictedGetAndSendDocs() ||
                          isNoRateTemplate()
                            ? isRestrictedGetAndSendDocs()
                              ? 'Restricted'
                              : isNoRateTemplate()
                              ? 'There is no active rate document template'
                              : 'The order should contain at least one of each Carrier, Shipper and Consignee'
                            : null
                        }
                      >
                        Get Rate Confirmation
                      </button>
                      <button
                        disabled={
                          isRateDisabled() || isRestrictedGetAndSendDocs()
                        }
                        type={'button'}
                        onClick={sendRateEmail}
                        title={
                          isRateDisabled() || isRestrictedGetAndSendDocs()
                            ? isRestrictedGetAndSendDocs()
                              ? 'Restricted'
                              : 'The order should contain at least one of each Carrier, Shipper and Consignee'
                            : null
                        }
                      >
                        Send Rate Confirmation
                      </button>
                      <button
                        disabled={
                          isBOLDisabled() ||
                          isRestrictedGetAndSendDocs() ||
                          isNoBolTemplate()
                        }
                        type={'button'}
                        onClick={getBOLDocument}
                        title={
                          isBOLDisabled() ||
                          isRestrictedGetAndSendDocs() ||
                          isNoBolTemplate()
                            ? isRestrictedGetAndSendDocs()
                              ? 'Restricted'
                              : isNoRateTemplate()
                              ? 'There is no active BOL document template'
                              : 'The order should contain at least one of each Shipper and Consignee'
                            : null
                        }
                      >
                        Get Bill of Lading
                      </button>
                      <button
                        disabled={
                          isBOLDisabled() || isRestrictedGetAndSendDocs()
                        }
                        type={'button'}
                        onClick={sendBOLEmail}
                        title={
                          isBOLDisabled() || isRestrictedGetAndSendDocs()
                            ? isRestrictedGetAndSendDocs()
                              ? 'Restricted'
                              : 'The order should contain at least one of each Shipper and Consignee'
                            : null
                        }
                      >
                        Send Bill of Lading
                      </button>
                      <a
                        type={'button'}
                        href={`${window.location.origin}/org/${currentOrganization?.organizationId}/tracking?orderSelector=${orderContextFormValue?.orderNumber}`}
                        target={'_blank'}
                      >
                        Go To Tracking
                      </a>
                      <button
                        disabled={
                          !isGenerateInvoiceEnabled ||
                          charges.length == 0 ||
                          !isOrgAdminOrAccounting
                        }
                        type={'button'}
                        onClick={generateInvoice}
                        title={
                          isOrgAdminOrAccounting
                            ? ''
                            : 'You have no permissions'
                        }
                      >
                        Generate Invoice
                      </button>
                    </ActionBarComponent>
                  )}
                </div>
              </TabList>
              <div>
                <TabPanel forceRender={true}>
                  <Panel className="m-3">
                    <h2>Order Information</h2>
                    <div className="row">
                      {isCreateMode ? (
                        <div />
                      ) : (
                        <>
                          <div className="col-2">
                            <OrderForm.OrderNumber />
                          </div>
                        </>
                      )}
                      <div className={`${isCreateMode ? 'col-3' : 'col-2'}`}>
                        <OrderForm.BillToContactSelect
                          id={'billToContactId'}
                          header={'Customer'}
                          contactTypes={[
                            ContactType.Customer,
                            ContactType.Vendor,
                            ContactType.ForwardingAgent,
                            ContactType.Contact,
                          ]}
                          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
                          required={true}
                          defaultValue={
                            orderContextFormValue &&
                            orderContextFormValue.billToContactId != null &&
                            orderContextFormValue.billToContactName != null &&
                            orderContextFormValue.billToContactType != null
                              ? {
                                  contactId:
                                    orderContextFormValue.billToContactId,
                                  name: orderContextFormValue.billToContactName,
                                  contactType:
                                    orderContextFormValue.billToContactType,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setOrderContextFormValue(
                              (orderContextFormValueDto) => {
                                if (!orderContextFormValueDto) {
                                  orderContextFormValueDto = getInitialState();
                                }
                                orderContextFormValueDto.billToContactId =
                                  data?.contactId;
                                orderContextFormValueDto.billToContactName =
                                  data?.name;
                                orderContextFormValueDto.billToContactType =
                                  data?.contactType;
                                return orderContextFormValueDto;
                              },
                            );
                            data?.contactId
                              ? setApplyToContactPaidAs(data?.paidAs)
                              : setApplyToContactPaidAs(
                                  ChargeDefaultValues.paidAs,
                                );
                          }}
                          nameId={'billToContactName'}
                          showContactType={true}
                        />
                      </div>
                      <div
                        className={`${
                          isCreateMode ? 'col-3' : 'col-2'
                        } employee-contact-input`}
                      >
                        <OrderForm.EmployeeContactSelect
                          id={'employeeContactId'}
                          header={'Employee'}
                          contactTypes={[ContactType.Employee]}
                          selectedFilter={`contactType: ${ContactType.Employee}`}
                          required={false}
                          defaultValue={
                            orderContextFormValue &&
                            orderContextFormValue.employeeContactId != null &&
                            orderContextFormValue.employeeContactName != null
                              ? {
                                  contactId:
                                    orderContextFormValue.employeeContactId,
                                  name:
                                    orderContextFormValue.employeeContactName,
                                  contactType: ContactType.Employee,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setOrderContextFormValue(
                              (orderContextFormValueDto) => {
                                if (!orderContextFormValueDto) {
                                  orderContextFormValueDto = getInitialState();
                                }
                                orderContextFormValueDto.employeeContactId =
                                  data?.contactId;
                                orderContextFormValueDto.employeeContactName =
                                  data?.name;
                                return orderContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'employeeContactName'}
                        />
                      </div>
                      <div className="col-3 sales-person-contact-input">
                        <OrderForm.SalespersonContactSelect
                          id={'salespersonContactId'}
                          header={'Sales Person'}
                          contactTypes={[ContactType.SalesPerson]}
                          selectedFilter={`contactType: ${ContactType.SalesPerson}`}
                          required={false}
                          defaultValue={
                            orderContextFormValue &&
                            orderContextFormValue.salespersonContactId !=
                              null &&
                            orderContextFormValue.salespersonContactName != null
                              ? {
                                  contactId:
                                    orderContextFormValue.salespersonContactId,
                                  name:
                                    orderContextFormValue.salespersonContactName,
                                  contactType: ContactType.SalesPerson,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setOrderContextFormValue(
                              (orderContextFormValueDto) => {
                                if (!orderContextFormValueDto) {
                                  orderContextFormValueDto = getInitialState();
                                }
                                orderContextFormValueDto.salespersonContactId =
                                  data?.contactId;
                                orderContextFormValueDto.salespersonContactName =
                                  data?.name;
                                return orderContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'salespersonContactName'}
                        />
                      </div>
                      <div className="col-3">
                        {/* order status */}
                        <OrderForm.OrderStatus
                          defaultValue={orderContextFormValue.orderStatus}
                          onChange={(value) =>
                            onOrderStatusChange(value, context)
                          }
                          isClearable={false}
                          selectedFilter={`OrderType:${OrderTypes.Order}`}
                          defaultValueFilter={`OrderType:${OrderTypes.Order}`}
                        />
                      </div>
                      <div className="col-3 carrier-contact-input">
                        <OrderForm.CarrierContactSelect
                          contactTypes={[
                            ContactType.Carrier,
                            ContactType.Driver,
                          ]}
                          selectedFilter={`contactType: ${ContactType.Carrier} OR contactType: ${ContactType.Driver}`}
                          defaultValue={(): any => {
                            return orderContextFormValue
                              ? orderContextFormValue.carriers
                              : null;
                          }}
                          onChange={(contact?: any) => {
                            setOrderContextFormValue(
                              (orderContextFormValueDto) => {
                                if (!orderContextFormValueDto) {
                                  orderContextFormValueDto = getInitialState();
                                }
                                orderContextFormValueDto.carriers = contact;
                                return orderContextFormValueDto;
                              },
                            );
                            setCarriers(contact);
                            contact[0]?.contactId
                              ? setApplyToCarrierPaidAs(contact[0]?.paidAs)
                              : setApplyToCarrierPaidAs(
                                  ChargeDefaultValues.paidAs,
                                );
                          }}
                        />
                      </div>
                      <div className={'col-3 equipmentType-input'}>
                        <OrderForm.EquipmentType
                          id={'equipmentTypeId'}
                          header={'Equipment Type'}
                          selectedFilter={``}
                          required={false}
                          defaultValue={(() => {
                            return orderContextFormValue &&
                              orderContextFormValue.equipmentTypeId != null &&
                              orderContextFormValue.equipmentTypeName != null
                              ? {
                                  equipmentTypeId:
                                    orderContextFormValue.equipmentTypeId,
                                  name: orderContextFormValue.equipmentTypeName,
                                }
                              : '';
                          })()}
                          onChange={(data?: EquipmentTypeDto) => {
                            setOrderContextFormValue(
                              (orderContextFormValueDto) => {
                                if (!orderContextFormValueDto) {
                                  orderContextFormValueDto = getInitialState();
                                }
                                orderContextFormValueDto.equipmentTypeId =
                                  data?.equipmentTypeId;
                                orderContextFormValueDto.equipmentTypeName =
                                  data?.name;
                                return orderContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'equipmentTypeName'}
                        />
                      </div>
                      <div className="col-3">
                        <OrderForm.TrackingNumber name={'trackingNumber'} />
                      </div>
                      <div className="col-3 division-input">
                        <OrderForm.Division
                          id={'divisionId'}
                          header={'Division'}
                          required={true}
                          defaultValue={
                            orderContextFormValue &&
                            orderContextFormValue.divisionId != null &&
                            orderContextFormValue.divisionName != null
                              ? {
                                  divisionId: orderContextFormValue.divisionId,
                                  divisionName:
                                    orderContextFormValue.divisionName,
                                }
                              : ''
                          }
                          onChange={(data?: DivisionDto) => {
                            setOrderContextFormValue(
                              (orderContextFormValueDto) => {
                                if (!orderContextFormValueDto) {
                                  orderContextFormValueDto = getInitialState();
                                }
                                orderContextFormValueDto.divisionId =
                                  data?.divisionId;
                                orderContextFormValueDto.divisionName =
                                  data?.divisionName;
                                return orderContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'divisionName'}
                          defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                          disabled={
                            !(
                              (currentUser?.isInOrgAdminRole ||
                                currentUser?.isInOperationRole) &&
                              VisibleTransactions[
                                currentUser?.visibleTransactions
                              ] === VisibleTransactions.AllTransactions
                            )
                          }
                        />
                      </div>
                    </div>
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Shipper</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderPickup}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-cy="btn-create-new-shipper"
                      />
                    </div>
                    {getItemsOrderPickup(context)}
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Consignee</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderDelivery}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-cy="btn-create-new-consignee"
                      />
                    </div>
                    {getItemsOrderDelivery(context)}
                    <hr className="my-4" />

                    <CommodityForm
                      id={'commodityForm'}
                      initialValues={initialStateCommodity}
                      onSubmit={onSubmitCommodityDto}
                      innerRef={commodityFormRef}
                      validationSchema={commoditySchema}
                      onKeyPress={(event, formikParams) => {
                        if (
                          event.code === 'Enter' &&
                          (currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole)
                        ) {
                          formikParams.submitForm();
                        }
                      }}
                    >
                      <h2>Commodities</h2>
                      <div className="row">
                        <div className={'col-1'}>
                          <CommodityForm.Pieces
                            onKeyDown={validateNumberInput}
                          />
                        </div>
                        <div className="col-2">
                          <CommodityForm.PackageTypeSelect
                            required={false}
                            defaultValue={
                              packageType &&
                              packageType.packageTypeId !== undefined &&
                              packageType.packageTypeId != null &&
                              packageType.name !== undefined &&
                              packageType.name != null
                                ? {
                                    packageTypeId: packageType?.packageTypeId,
                                    name: packageType?.name,
                                  }
                                : ''
                            }
                            onChange={(
                              data?: PackageTypeDto,
                              context?: any,
                            ) => {
                              setPackageType((packageTypeData) => {
                                if (!packageTypeData) {
                                  packageTypeData = {
                                    name: null,
                                    packageTypeId: null,
                                  };
                                }
                                packageTypeData.packageTypeId = data?.packageTypeId?.toString();
                                packageTypeData.name = data?.name;
                                return { ...packageTypeData };
                              });
                              context?.setFieldValue(
                                'packageTypeName',
                                data?.name,
                              );
                            }}
                            nameId={'packageTypeName'}
                          />
                        </div>
                        <div
                          className={isOrgAdminOrOperation ? 'col-4' : 'col-5'}
                        >
                          <CommodityForm.Description />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Length
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Width
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Height
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Weight
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        {(currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole) && (
                          <div className="col-1 pt-4">
                            <Button
                              form={'commodityForm'}
                              name={'create-commodity'}
                              type="button"
                              onClick={(event) => {
                                if (commodityFormRef.current) {
                                  commodityFormRef.current.submitForm();
                                }
                              }}
                              color="secondary"
                              className="btn-block text-break"
                            >
                              +
                            </Button>
                          </div>
                        )}
                      </div>
                      <CommoditiesForOrderList
                        className={'mb-4 commodities-for-order-list'}
                        limit={limit}
                        showPagination={false}
                        onPageChanged={(page) => setOffset(page * limit)}
                        offset={offset}
                        items={getCommodities()}
                        goToDetails={goToDetailsCommodity}
                        changeItems={setCommodities}
                        userCanDelete={
                          currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole
                        }
                        orderType={OrderTypes.Order}
                      />
                      {getCommodities().length === 0 && (
                        <h4 className="text-center m-5 text-muted">
                          No commodities
                        </h4>
                      )}
                      <div className="row">
                        <div className="col-2">
                          <OrderForm.TotalPcsCrt
                            selectedName={getTotalPcsCrt()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.WeightTotal
                            selectedName={getWeightTotal()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.VolumeTotal
                            selectedName={getVolumeTotal()}
                          />
                        </div>
                        <div className="justify-content-end d-flex col-6">
                          {(currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole) && (
                            <div className="col-6 pt-4">
                              <Button
                                type="submit"
                                form={'OrderForm'}
                                color="primary"
                                className="btn-block"
                                disabled={isSending}
                                isSending={isSending}
                              >
                                Save Order
                              </Button>
                            </div>
                          )}
                          <div className="col-6 pt-4">
                            <Button
                              type="button"
                              color="secondary"
                              onClick={onCancel}
                              className="col-12"
                              disabled={isSending}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CommodityForm>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <ChargeForOrderList
                      limit={limit}
                      showPagination={false}
                      showIncomeCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      showExpenseCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onChargeCreated={onChargeCreated}
                      items={getCharges()}
                      goToDetails={goToDetailsCharge}
                      changeItems={setCharges}
                      selectedApplyToContact={{
                        contactId: orderContextFormValue.billToContactId,
                        name: orderContextFormValue.billToContactName,
                        contactType: orderContextFormValue.billToContactType,
                        paidAs: applyToContactPaidAs,
                      }}
                      selectedApplyToCarrier={{
                        contactId: orderContextFormValue.carriers[0]?.contactId,
                        name: orderContextFormValue.carriers[0]?.name,
                        contactType:
                          orderContextFormValue.carriers[0]?.contactType,
                        paidAs: applyToCarrierPaidAs,
                      }}
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                      charges={orderContextFormValue.charges}
                    />
                    <div className="mt-3">
                      <h2>Totals</h2>
                    </div>
                    <ChargeForm initialValues={profitValues} id={'ChargeForm'}>
                      <div className={'row'}>
                        <div className={'col-2'}>
                          <ChargeForm.Income disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Expense disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Profit disabled={true} />
                        </div>
                      </div>
                    </ChargeForm>
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'OrderForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Order
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <TrackingEventForOrderList
                      limit={limit}
                      showPagination={false}
                      showAddButton={isOrgAdminOrOperation ? true : false}
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onTrackingEventCreated={onTrackingEventCreated}
                      items={getTrackingEvents()}
                      goToDetails={goToDetailsTrackingEvent}
                      changeItems={setTrackingEvents}
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                    />

                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'OrderForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Order
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel forceRender={isCreateMode ? false : true}>
                  <OrderForm.CustomValues
                    customFields={customFields}
                    entityType={CustomFieldEntityType.Order}
                    defaultValue={{ ...orderContextFormValue.customValues }}
                    onChange={(result) => {
                      setOrderContextFormValue((orderContextFormValue) => {
                        orderContextFormValue.customValues = result;
                        return { ...orderContextFormValue };
                      });
                    }}
                    saveButtonRenderCondition={
                      currentUser?.isInOrgAdminRole ||
                      currentUser?.isInOperationRole
                    }
                    isSending={isSending}
                    formName={'OrderForm'}
                    entityName={'Order'}
                    onCancel={onCancel}
                    context={context}
                  />
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <AttachmentsFilesList
                      parentId={orderId}
                      parentType={AttachmentParentType.Order}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'OrderForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Order
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              </div>
            </Tabs>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
