import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { OrganizationForm } from './organizations.form';
import { OrganizationDto } from '../../../models/data.models';
import { getOrganization, updateOrganization } from '../organization.store';
import { OrganizationEditFormDto } from '../../../models/custom.models';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { authStore } from '../../auth/auth.store';

export type OrganizationEditProps = {
  organizationId?: number | null;
  onOrganizationCreated?: (organization: OrganizationDto) => void;
  onOrganizationUpdated?: (organization: OrganizationDto) => void;
  onOrganizationLoaded?: (organization: OrganizationDto) => void;
  onCancel?: () => void;
};
const initialState: OrganizationEditFormDto = {
  createdByUserName: '',
  lastModifiedByUserName: '',
  carriers: [],
  totalPcsCrt: 0,
  weighTotal: 0,
  volumeTotal: 0,
  organizationId: null,
  billToContactId: null,
  carrierContactId: null,
  created: new Date(),
  createdBy: '',
  employeeContactId: null,
  lastModified: new Date(),
  lastModifiedBy: '',
  organizationNumber: '',
  salespersonContactId: null,
  commodities: [],
  charges: [],
  divisionId: null,
  organizationStatus: null,
  links: [],
};

const organizationSchema = Yup.object().shape({
  companyName: Yup.string().required("Can't be blank").nullable(true),
});

export const OrganizationEdit = ({
  organizationId = 0,
  onOrganizationLoaded = () => {},
  onOrganizationUpdated = () => {},
}: OrganizationEditProps) => {
  const { user: currentUser } = authStore?.getState();

  const limit = 20;

  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [
    organizationContextFormValue,
    setOrganizationContextFormValue,
  ] = useState<OrganizationEditFormDto | null>(null);

  useEffect(() => {
    setOrganizationContextFormValue((organizationContextFormValueDto) => {
      organizationContextFormValueDto = initialState;
      return { ...organizationContextFormValueDto };
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getOrganization({ organizationId }).then(
      (organizationDto: OrganizationDto) => {
        setOrganizationContextFormValue((organizationContextFormValueDto) => {
          if (!organizationContextFormValueDto) {
            organizationContextFormValueDto = initialState;
          } else {
            organizationContextFormValueDto = organizationDto;
          }
          return organizationContextFormValueDto;
        });
        setIsLoading(false);
      },
      () => {},
    );
  }, [organizationId]);

  const onSubmit = (data: OrganizationDto) => {
    setIsSending(true);
    updateOrganization(data)
      .then(
        (result) => {
          onOrganizationUpdated(result);
        },
        () => {},
      )
      .finally(() => setIsSending(false));
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <Tabs>
      <TabList>
        <Tab name="organization">Organization</Tab>
      </TabList>
      <OrganizationForm
        initialValues={organizationContextFormValue}
        onSubmit={onSubmit}
        id={'organization-edit-form'}
        validationSchema={organizationSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            <h3 className="header-form">Update Organization</h3>
            <div className="row">
              <div className="col-4">
                <div className="mb-5">
                  <OrganizationForm.CompanyName />
                </div>
                <OrganizationForm.AddressLine />
                <OrganizationForm.AddressLine2 />
                <div className="row mb-5">
                  <div className="col-6">
                    <OrganizationForm.City />
                  </div>
                  <div className="col-6">
                    <OrganizationForm.StateCode />
                  </div>
                  <div className="col-6">
                    <OrganizationForm.CountryCode />
                  </div>
                  <div className="col-6">
                    <OrganizationForm.PostalCode />
                  </div>
                </div>

                <div className="mb-5">
                  <OrganizationForm.PhoneNumber />
                  <OrganizationForm.FaxNumber />
                </div>
              </div>
            </div>
            {currentUser?.isInOrgAdminRole && (
              <div className="row">
                <div className="ml-auto col-3">
                  <Button
                    name="update-organization"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    form={'organization-edit-form'}
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Organization
                  </Button>
                </div>
              </div>
            )}
          </Panel>
        </TabPanel>
      </OrganizationForm>
    </Tabs>
  );
};
