import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetOrdersQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import React from 'react';
import {
  WAREHOUSE_PACKAGE_RECEIVE_PATH,
  WAREHOUSE_RECEIPT_CREATE_PATH,
  WAREHOUSE_RECEIPT_EDIT_PATH,
  WAREHOUSE_RECEIPT_LIST_PATH,
} from '../warehouseReceipt.route';
import { WarehouseReceiptsList } from '../components/warehouseReceipts-list.component';
import {
  CREATE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../../orders/orders.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';
import {
  getWarehouseReceiptsDefaultColumns,
  warehouseReceiptsStore,
} from '../warehouseReceipts.store';
import { useStore } from 'effector-react';

export type WarehouseReceiptsListScreenProps = {
  pageTitle: string;
  group: string;
};

export const WarehouseReceiptsListScreen = ({
  pageTitle,
  group,
}: WarehouseReceiptsListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(warehouseReceiptsStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `orderType:${OrderTypes.WarehouseReceipt}`,
    search = '',
    view = '',
  } = useQuery() as GetOrdersQuery;
  const navigate = useNavigate();

  const createPath = generatePath(
    INTERNAL_PATH + WAREHOUSE_RECEIPT_CREATE_PATH,
    {
      organizationId,
    },
  );

  const goToDetails = (warehouseReceiptParams: GetOrderParams) => {
    const warehouseReceiptId = warehouseReceiptParams.orderId;
    const createOrEdit =
      warehouseReceiptId === 0
        ? WAREHOUSE_RECEIPT_CREATE_PATH
        : WAREHOUSE_RECEIPT_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      warehouseReceiptId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getWarehouseReceiptsDefaultColumns();
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}&view=${view}`,
    );
  };

  const addPath = generatePath(INTERNAL_PATH + WAREHOUSE_PACKAGE_RECEIVE_PATH, {
    organizationId,
  });

  return (
    <InternalLayout
      group={group}
      title={'Warehouse Receipts'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
      addPath={addPath}
      addPathLabel="Receive"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <WarehouseReceiptsList
        filter={filter}
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onViewChanged={onViewChanged}
        onLimitChanged={onLimitChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
