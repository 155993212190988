import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { CustomCodeEdit } from './customCode-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CUSTOMCODES_LIST_PATH,
  CUSTOMCODE_CREATE_PATH,
} from '../customCodes.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_CUSTOMCODE_LINK_KEY } from '../customCodes.service';
import { userHas } from '../../auth/auth.store';

export type CustomCodeScreenProps = { pageTitle: string; group: string };

export const CustomCodeScreen = ({
  pageTitle,
  group,
}: CustomCodeScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, code } = useParams();

  const createPath = generatePath(INTERNAL_PATH + CUSTOMCODE_CREATE_PATH, {
    organizationId,
  });

  const onCustomCodeCreated = () => {
    const path = generatePath(INTERNAL_PATH + CUSTOMCODES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'customCode-created',
      type: 'success',
      autoHide: true,
      message: 'Custom code successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCustomCodeUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CUSTOMCODES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'customCode-updated',
      type: 'success',
      autoHide: true,
      message: 'Custom code successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CUSTOMCODES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Customs code'}
      createPath={userHas(CREATE_CUSTOMCODE_LINK_KEY) ? createPath : null}
      createLabel={'Code'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CustomCodeEdit
        code={code === 'create' ? null : code}
        onCustomCodeUpdated={onCustomCodeUpdated}
        onCustomCodeCreated={onCustomCodeCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
