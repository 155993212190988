import React, {
  CSSProperties,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';

import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import lodash from 'lodash';

import { Pagination } from '../pagination/pagination.component';
import {
  getAdditionalColumnsData,
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import {
  OrderForListDto,
  ReactSelectItem,
} from '../../../../models/custom.models';
import {
  ChargeStatuses,
  Column,
  ContactDto,
  CustomFieldType,
  EntityTypes,
  OrderDto,
  OrderStatusDto,
  OrderStatuses,
  OrderTypes,
  StatusStage,
} from '../../../../models/data.models';
import {
  PATCH_ORDER_LINK_KEY,
  PatchOrderParams,
} from '../../../orders/orders.service';
import { patchOrder } from '../../../orders/orders.store';
import { unescapeString } from '../../../../utils/helper.utils';
import { showDialog } from '../../dialog.store';
import { Prompt } from '../prompt/prompt.component';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import { userHas } from '../../../auth/auth.store';
import Dropdown from 'react-bootstrap/Dropdown';
import { getContact } from '../../../contacts/contacts.store';
import { SelectOrderStatusPaginate } from '../../../orderStatuses/components/orderStatus-react-select.component';
import {
  FilteredField,
  FiltersTab,
  GridContextValue,
} from '../filters/filtersTab.component';
import { IAction } from '../actions/actions';

export const getDisabledAndEnabledOrderStatuses = (
  order: OrderForListDto,
  statuses: ReactSelectItem[],
): ReactSelectItem[] => {
  if (
    order?.charges &&
    (order?.charges.some(
      (charge) =>
        charge.chargeStatus === ChargeStatuses.Posted ||
        charge.chargeStatus === ChargeStatuses.Paid,
    ) ||
      order?.orderStatus.statusStage === StatusStage.Completed)
  ) {
    statuses[statuses.length - 1]['isDisabled'] = true;
  }
  return statuses;
};

export type OrderGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  actions?: IAction[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: OrderForListDto[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  onChangeItem?: (items: any[]) => void;
  showAllFilters?: boolean;
  defaultColumns?: Column[];
  defaultSort?: string;
  defaultLimit?: number;
  showToolbar?: boolean;
  entityType?: string;
};

const GridContext = createContext<GridContextValue | null>(null);

export const useGridContext = (): GridContextValue => {
  const contextValue = useContext(GridContext);

  if (!contextValue) {
    throw new Error(
      'useGridContext must be used within a GridContext.Provider',
    );
  }

  return contextValue;
};

export const OrderGrid = ({
  data = [],
  columns = [],
  actions = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onChangeItem = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = false,
  showAllStore = false,
  showAllFilters = true,
  defaultColumns,
  defaultSort,
  defaultLimit,
  showToolbar = true,
  entityType = null,
}: OrderGridProps) => {
  const colByName: { [key: string]: any } = {};

  const [gridColumns, setGridColumns] = useState<Column[]>([...columns]);
  const [filters, setFilters] = useState<FilteredField[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<FilteredField[]>([]);

  const onColumnsChangedHandler = (columnsChanged: Column[]): void => {
    setGridColumns([...columnsChanged]);
  };

  useEffect(() => {
    if (columns.length === 0) return;
    setGridColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    setFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: filters.find((y) => y.name === x.name)?.visible ?? false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
    setDefaultFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
  }, [gridColumns]);

  const [addData, setAddData] = useState({});

  useEffect(() => {
    const filterParts = new URLSearchParams(window.location.search)
      .get('filter')
      ?.split(' AND ');

    const billToContactId = filterParts
      ?.find((x) => x.includes('BillToContactId:'))
      ?.split(':')[1];

    const orderStatus = filterParts
      ?.find((x) => x.includes('orderStatus:'))
      ?.split(':')[1];

    let createdByUserName = filterParts
      ?.find((x) => x.includes('CreatedUser.UserName:'))
      ?.split(':')[1];

    if (createdByUserName) {
      createdByUserName = unescapeString(createdByUserName, false);
    }

    const customFieldsNames = gridColumns
      ?.filter((column) => column.type === 'customField')
      .map((column) => column.name);

    const customFieldsValues = customFieldsNames
      .map((name) => {
        return filterParts?.find((x) => x.includes(name))?.split(':')[1];
      })
      .filter((field) => field !== null && field !== undefined)
      .map((field) => {
        return unescapeString(field, true);
      });

    let customFields = {};
    for (let i = 0; i < customFieldsValues.length; i++) {
      customFields[`__${customFieldsNames[i]}`] = customFieldsValues[i];
    }

    if (billToContactId) {
      getContact({ contactId: Number(billToContactId) }).then((contact) => {
        setAddData({
          ...addData,
          __BillToContactName: contact.name,
          __BillToContactType: contact.contactType,
          __orderStatus: orderStatus ? OrderStatuses[orderStatus] : null,
          __createdByUserName: createdByUserName ?? '',
          ...customFields,
        });
      });
    } else {
      setAddData({
        ...addData,
        __orderStatus: orderStatus ? OrderStatuses[orderStatus] : null,
        __createdByUserName: createdByUserName ?? '',
        ...customFields,
      });
    }
  }, [gridColumns]);

  const updateCols = (colName: string) => {
    setGridColumns((prev) =>
      prev.map((x) => {
        const col = x;
        if (x.name === colName) {
          x.visible = !col.visible;
        }
        return x;
      }),
    );
  };

  const updateSort = (colName: string) => {
    const sortName = gridColumns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    (document.querySelector(
      '.filter-dropdown .dropdown-toggle',
    ) as HTMLSpanElement).click();
    setAddData(getAdditionalColumnsData(filters));
    return onFilter(getSearchQuery(filters, gridColumns));
  };

  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  const getClassGridItem = (row?: any): string => {
    const orderStatus = OrderStatuses[row?.orderStatus];
    let classes = [];
    if (orderStatus === OrderStatuses.OnRoute) {
      classes.push('bg-light-green');
    } else if (orderStatus === OrderStatuses.Open) {
      classes.push('bg-light-red');
    } else if (orderStatus === OrderStatuses.Refused) {
      classes.push('bg-violet');
    } else if (orderStatus === OrderStatuses.Covered) {
      classes.push('bg-dark-blue');
    } else if (orderStatus === OrderStatuses.Dispatched) {
      classes.push('bg-brown');
    } else if (
      orderStatus === OrderStatuses.Loading ||
      orderStatus === OrderStatuses.UnLoading ||
      orderStatus === OrderStatuses.Cancelled
    ) {
      classes.push('bg-gray');
    } else if (orderStatus === OrderStatuses.InYard) {
      classes.push('bg-pink');
    }
    return classes.join(' ');
  };

  const isEqualsColumnType = (columnName: string, type: string): boolean => {
    if (columnName == type) {
      return true;
    }
    return false;
  };

  const patchCarriers = (contact: ContactDto[], row?: any) => {
    const contactParams = contact
      ? contact.map((item) => {
          return { carrierId: item.contactId };
        })
      : [];
    const patchOrderParams: PatchOrderParams = {
      resource: { links: row.links },
      patchOrderCommand: {
        patchItems: [
          {
            path: `/OrderCarriers`,
            op: 'replace',
            value: contactParams,
          },
        ],
      },
    };
    patchOrderCommand(patchOrderParams, row);
  };

  const patchOrderStatus = (
    newValueOrderStatus: OrderStatusDto,
    row?: OrderForListDto,
  ) => {
    let newOrderStatusId = newValueOrderStatus?.orderStatusId;

    if (newValueOrderStatus.statusStage === StatusStage.Completed) {
      showDialog({
        dialog: Prompt,
        props: {
          title: `Cancel ${row?.orderNumber} Order`,
          message: `Please type order number (${row?.orderNumber}) to confirm its cancelling.`,
          className: 'cancel-order-modal',
          orderNumber: row?.orderNumber,
        },
      }).then((result) => {
        if (!result) {
          newOrderStatusId = row?.orderStatusId;
        }

        const patchOrderParams: PatchOrderParams = {
          resource: { links: row.links },
          patchOrderCommand: {
            patchItems: [
              {
                path: `/OrderStatusId`,
                op: 'add',
                value: `${[newOrderStatusId]}`,
              },
            ],
          },
        };
        patchOrderCommand(patchOrderParams, row);
      });
    } else {
      const patchOrderParams: PatchOrderParams = {
        resource: { links: row.links },
        patchOrderCommand: {
          patchItems: [
            {
              path: `/OrderStatusId`,
              op: 'add',
              value: `${[newOrderStatusId]}`,
            },
          ],
        },
      };
      patchOrderCommand(patchOrderParams, row);
    }
  };

  const getTdClass = (itemName?: any) => {
    let resultClasses = ['cursor-pointer'];
    if (itemName === 'firstOrderPickupsShipperAddressName') {
      resultClasses.push('three-dots order-grid-origin');
    } else if (itemName === 'firstOrderDeliveriesConsigneeAddressName') {
      resultClasses.push('three-dots order-grid-destination');
    } else if (itemName === 'orderStatus') {
      resultClasses.push('order-grid-order-status');
    }
    return resultClasses.join(' ');
  };

  const getFilteredRowsFilter = (): Column[] => {
    return gridColumns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  const patchOrderCommand = (patchOrderParams: PatchOrderParams, row?: any) => {
    patchOrder(patchOrderParams)
      .then((item: OrderDto) => {
        const orderPickups = item?.orderEntities?.filter(
          (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
        );
        const orderDeliveries = item?.orderEntities?.filter(
          (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
        );

        const foundOrderIndex = data.findIndex(
          (x) => x.orderId === row.orderId,
        );
        let firstOrderDeliveriesConsigneeAddressName = '';
        if (orderDeliveries[0]?.contactName) {
          firstOrderDeliveriesConsigneeAddressName += `${
            orderDeliveries[0]?.contactName ?? ''
          }`;
        }
        if (orderDeliveries[0]?.contactCityName) {
          if (orderDeliveries[0]?.contactName) {
            firstOrderDeliveriesConsigneeAddressName += ' / ';
          }
          firstOrderDeliveriesConsigneeAddressName += `${
            orderDeliveries[0]?.contactCityName ?? ''
          }`;
        }
        if (orderDeliveries[0]?.contactStateCode) {
          if (orderDeliveries[0]?.contactCityName) {
            firstOrderDeliveriesConsigneeAddressName += ', ';
          }
          firstOrderDeliveriesConsigneeAddressName += `${
            orderDeliveries[0]?.contactStateCode ?? ''
          }`;
        }

        let firstOrderPickupsShipperAddressName = '';
        if (orderPickups[0]?.contactName) {
          firstOrderPickupsShipperAddressName += `${
            orderPickups[0]?.contactName ?? ''
          }`;
        }
        if (orderPickups[0]?.contactCityName) {
          if (orderPickups[0]?.contactName) {
            firstOrderPickupsShipperAddressName += ' / ';
          }
          firstOrderPickupsShipperAddressName += `${
            orderPickups[0]?.contactCityName ?? ''
          }`;
        }
        if (orderPickups[0]?.contactStateCode) {
          if (orderPickups[0]?.contactCityName) {
            firstOrderPickupsShipperAddressName += ', ';
          }
          firstOrderPickupsShipperAddressName += `${
            orderPickups[0]?.contactStateCode ?? ''
          }`;
        }
        const orderDtoForList: OrderForListDto = {
          lastModifiedByUserName: item.lastModifiedByUserName,
          createdByUserName: item.createdByUserName,
          orderEntities: item?.orderEntities,
          orderStatus: item?.orderStatus,
          orderStatusId: item.orderStatusId,
          firstOrderDeliveriesConsigneeAddressName,
          firstOrderPickupsShipperAddressName,
          firstOrderDeliveriesDeliveryDate: orderDeliveries[0]?.customValues[
            'deliveryDate'
          ]
            ? getFormattedDate(
                orderDeliveries[0]?.customValues['deliveryDate'],
                false,
              )
            : null,
          orderId: item?.orderId,
          firstOrderPickupsPickDate: orderPickups[0]?.customValues['pickDate']
            ? getFormattedDate(orderPickups[0]?.customValues['pickDate'], false)
            : null,
          billToContactId: item?.billToContactId,
          billToContactName: item?.billToContactName,
          carrierContactId: item?.carrierContactId,
          carrierContactName: item?.carrierContactName,
          charges: item?.charges,
          commodities: item?.commodities,
          created: item?.created,
          createdBy: item?.createdBy,
          divisionId: item?.divisionId,
          divisionName: item?.divisionName,
          employeeContactId: item?.employeeContactId,
          links: item?.links,
          employeeContactName: item?.employeeContactName,
          orderNumber: item?.orderNumber,
          lastModified: item?.lastModified,
          lastModifiedBy: item?.lastModifiedBy,
          organizationId: item?.organizationId,
          salespersonContactId: item?.salespersonContactId,
          salespersonContactName: item?.salespersonContactName,
          totalPcsCrt: item?.totalPcsCrt,
          volumeTotal: item?.volumeTotal,
          weighTotal: item?.weighTotal,
          carriers: item?.carriers,
        };
        data[foundOrderIndex] = { ...orderDtoForList };
        onChangeItem(data);
      })
      .catch(() => {});
  };

  gridColumns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const onFiltersChangedHandler = (filtersChanged: FilteredField[]): void => {
    if (filtersChanged) setFilters([...filtersChanged]);
  };

  const [gridContext, setGridContext] = useState<GridContextValue>({
    columns: gridColumns,
    filters,
    search,
    defaultColumns,
    defaultFilters,
    defaultSort,
    defaultLimit,
    onSearch,
    onFilter,
    onColumnsChanged: onColumnsChangedHandler,
    onFiltersChanged: onFiltersChangedHandler,
    onViewChanged,
    actions,
    entityType: entityType,
  });

  useEffect(() => {
    setGridContext({
      columns: gridColumns,
      filters,
      search,
      defaultColumns,
      defaultFilters,
      defaultSort,
      defaultLimit,
      onSearch,
      onFilter,
      onColumnsChanged: onColumnsChangedHandler,
      onFiltersChanged: onFiltersChangedHandler,
      onViewChanged,
      actions,
      entityType: entityType,
    });
  }, [gridColumns, filters, search, defaultColumns, defaultFilters]);

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) =>
      gridColumns.some((column) => column.showFilter) && (
        <span
          className="dropdown-toggle pointer"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          <BsFilter />
          &nbsp;
          <small className="align-middle pl-1 text-uppercase">Filters</small>
          &nbsp;
          <small className="align-middle text-primary">
            {getReadableQueryString(filter, getFilteredRowsFilter(), addData)}
          </small>
        </span>
      ),
  );

  const [highlightRows, setHighlightRows] = useState(false);
  function handleToggleChange() {
    setHighlightRows(!highlightRows);
  }

  return (
    <>
      <div className="mx-3">
        {(filters.length > 0 || gridColumns.length > 0) && (
          <GridContext.Provider value={gridContext}>
            <FiltersTab useGridContext={useGridContext} />
          </GridContext.Provider>
        )}
      </div>

      <div className={`grid mx-3 my-4 ${className}`} style={style}>
        {showToolbar && (
          <div className="grid-toolbar d-flex justify-content-between px-3">
            <div className={'d-flex'}>
              {onFilter ? (
                <div className="my-3 px-3 w-100 filter-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu>
                      {showAllFilters === true ||
                      rowIncludeFilterKeys?.length > 0 ? (
                        <div className="px-5" style={{ minHeight: '300px' }}>
                          <div className="filters-form-block">
                            <Filters
                              query={filter}
                              columns={getFilteredRowsFilter()}
                              onFilter={updateFilter}
                              addData={addData}
                            />
                          </div>
                        </div>
                      ) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null}

              <div className="dropdown dropdown-columns my-3 px-3 ml-auto pointer">
                <div
                  className="dropdown-toggle"
                  id="dropdownColumnsButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BsListTask className="" />
                  &nbsp;
                  <small className="align-middle pl-1 text-uppercase">
                    Columns
                  </small>
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownColumnsButton"
                  style={{ width: 'max-content', maxWidth: 'unset' }}
                >
                  {gridColumns
                    .filter((item) => {
                      if (showAllStore) {
                        return true;
                      }
                      return rowKeys?.includes(item.name);
                    })
                    .map((col) => {
                      return (
                        <label key={col.name} className="dropdown-item pointer">
                          <input
                            type="checkbox"
                            onChange={() => updateCols(col.name)}
                            defaultChecked={col.visible}
                          />{' '}
                          {col.title}
                        </label>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className={'d-flex'}>
              {onSearch ? (
                <input
                  type="search"
                  className="form-control my-2"
                  placeholder="Search"
                  onChange={updateSearch}
                  value={decodeURIComponent(search)}
                />
              ) : null}

              <div
                className={`form-group d-none align-items-center mb-0 px-5 mr-3`}
              >
                <div className="custom-control custom-toggle ">
                  <label
                    className={`custom-toggle-label label-first font-size-sm text-nowrap `}
                    htmlFor={'highlight'}
                  >
                    Highlight rows
                  </label>
                  <input
                    id={'highlight'}
                    name={'highlight'}
                    className="custom-toggle-input"
                    type="checkbox"
                    checked={highlightRows}
                    onChange={handleToggleChange}
                  />
                  <span
                    id={'highlight'}
                    className="custom-toggle-checkmark"
                    onClick={handleToggleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {total > 0 || showEmptyTable === true ? (
          <div className="bg-white horizontal-scrollbar">
            <table className="table">
              <thead>
                <tr>
                  {gridColumns
                    .filter((col) => {
                      if (showAllStore && col.visible) {
                        return true;
                      }
                      return col.visible && rowKeys?.includes(col.name);
                    })
                    .map((col) => {
                      return (
                        <th scope="col" key={col.name}>
                          <a
                            className={col.sortName ? 'link' : 'inactive-link'}
                            onClick={() =>
                              col.sortName ? updateSort(col.name) : null
                            }
                          >
                            {col.title}
                            {sort === col.name ||
                            sort === col.sortName ||
                            sort === col.sortName + '~ToInt32' ? (
                              <FaArrowDown />
                            ) : null}
                            {sort === '-' + col.name ||
                            sort === '-' + col.sortName ||
                            sort === '-' + col.sortName + '~ToInt32' ? (
                              <FaArrowUp />
                            ) : null}
                          </a>
                        </th>
                      );
                    })}
                  <th colSpan={2}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => {
                  return (
                    <tr
                      data-cy-tracking-number={row.trackingNumber}
                      className={getClassGridItem(row)}
                      key={rowKeys?.map((x) => row[x]).join('_')}
                    >
                      {Object.values(gridColumns)
                        .filter((item) => {
                          if (showAllStore && item.visible) {
                            return true;
                          }
                          return item.visible && rowKeys?.includes(item.name);
                        })
                        .map((item, index) => {
                          switch (item.name) {
                            case 'orderStatus':
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                >
                                  <SelectOrderStatusPaginate
                                    isDisabled={
                                      !userHas(PATCH_ORDER_LINK_KEY, row?.links)
                                    }
                                    selectedValue={
                                      lodash.get(row, item.name)
                                        ? lodash.get(row, item.name)
                                        : null
                                    }
                                    onChangeStatus={(
                                      newValueOrderStatus: OrderStatusDto,
                                    ) =>
                                      patchOrderStatus(newValueOrderStatus, row)
                                    }
                                    isMulti={false}
                                    useContext={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    defaultValueFilter={`OrderType:${OrderTypes.Order}`}
                                    selectedFilter={`OrderType:${OrderTypes.Order}`}
                                  />
                                </td>
                              );
                            case 'carrierContactName':
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  onClick={(event) => {
                                    if (onSelect) {
                                      onSelect(
                                        row,
                                        rowKeys?.reduce((keyObj, field) => {
                                          return row[field];
                                        }),
                                      );
                                    }
                                  }}
                                  style={{
                                    width: `calc(100% / ${
                                      gridColumns.filter(
                                        (column) => column.visible === true,
                                      ).length
                                    })`,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {row?.carriers.map((carrier) => (
                                    <span style={{ whiteSpace: 'pre' }}>
                                      {carrier.name} - {carrier.contactType}
                                      {'\n'}
                                    </span>
                                  ))}
                                </td>
                              );
                            default:
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  onClick={(event) => {
                                    if (onSelect) {
                                      onSelect(
                                        row,
                                        rowKeys?.reduce((keyObj, field) => {
                                          return row[field];
                                        }),
                                      );
                                    }
                                  }}
                                  style={{
                                    width: `calc(100% / ${
                                      gridColumns.filter(
                                        (column) => column.visible === true,
                                      ).length
                                    })`,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {gridColumns?.find(
                                    (column) =>
                                      column.name === item.name &&
                                      column.type === 'customField' &&
                                      column.customFieldType ===
                                        CustomFieldType.Boolean,
                                  ) ? (
                                    lodash.get(row, item.name) === true ||
                                    lodash.get(row, item.name) === 'true' ? (
                                      <>&#x2713;</>
                                    ) : (
                                      <></>
                                    )
                                  ) : gridColumns?.find(
                                      (column) =>
                                        column.name === item.name &&
                                        column.type === 'customField' &&
                                        column.customFieldType ===
                                          CustomFieldType.Date,
                                    ) ? (
                                    getFormattedDate(
                                      lodash.get(row, item.name),
                                      false,
                                    )
                                  ) : (
                                    <>{lodash.get(row, item.name)}</>
                                  )}
                                </td>
                              );
                          }
                        })}
                      <td style={{ textAlign: 'center' }}>
                        <div className="dropdown">
                          <BsThreeDotsVertical
                            className="dropdown-toggle dropdown-dots-vertical pointer"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          />
                          <div
                            className="dropdown-menu dropdown-menu-grid"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {onDelete && (
                              <span
                                title={
                                  row.orderStatus.statusStage ===
                                    StatusStage.Pending &&
                                  row.charges?.length > 0
                                    ? 'Order contains charges'
                                    : row.orderStatus?.statusStage !==
                                        StatusStage.Pending &&
                                      row.charges?.length === 0
                                    ? 'Order is not in the Pending status'
                                    : row.orderStatus?.statusStage !==
                                        StatusStage.Pending &&
                                      row.charges?.length > 0
                                    ? 'Order contains charges and not in the Pending status'
                                    : null
                                }
                              >
                                <a
                                  role="button"
                                  className="dropdown-item"
                                  onClick={() => {
                                    onDelete(row);
                                  }}
                                  style={
                                    row.orderStatus.statusStage !==
                                      StatusStage.Pending ||
                                    row.charges?.length > 0
                                      ? {
                                          pointerEvents: 'none',
                                          color: 'gray',
                                        }
                                      : null
                                  }
                                >
                                  Delete
                                </a>
                              </span>
                            )}
                            <a
                              role="button"
                              className="dropdown-item"
                              onClick={() => {
                                onEdit(row);
                              }}
                            >
                              Edit
                            </a>
                            {onCopy && (
                              <a
                                role="button"
                                className="dropdown-item"
                                onClick={() => {
                                  onCopy(row);
                                }}
                              >
                                Copy
                              </a>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center mt-4">Nothing Found</p>
        )}
        {showPagination === true ? (
          <div className="d-flex justify-content-center grid-footer">
            <Pagination
              onLimitChanged={onLimitChanged}
              goToPage={onPageChanged}
              offset={offset}
              limit={limit}
              total={total}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
