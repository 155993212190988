import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { WarehouseReceiptEdit } from './warehouseReceipt-edit.component';

export type WarehouseReceiptDialogProperties = {
  orderId?: number | null;
  title: string;
  className?: string;
  onWarehouseReceiptSaved?: (warehouseReceipt: OrderDto) => void;
  onInvoiceGenerated?: () => void;
};

export class WarehouseReceiptDialog extends Dialog<WarehouseReceiptDialogProperties> {
  onInvoiceGenerated?: () => void;

  constructor(props: WarehouseReceiptDialogProperties) {
    super(props);
    this.onInvoiceGenerated = props.onInvoiceGenerated?.bind(this);
    this.onWarehouseReceiptSaved = this.onWarehouseReceiptSaved.bind(this);
  }

  onWarehouseReceiptSaved = (warehouseReceipt: OrderDto) => {
    this.close(warehouseReceipt);
  };

  renderContent(): any {
    const { orderId } = this.props as WarehouseReceiptDialogProperties;
    const onInvoiceGenerated = this.onInvoiceGenerated;
    return (
      <WarehouseReceiptEdit
        orderId={orderId}
        onWarehouseReceiptCreated={this.onWarehouseReceiptSaved}
        onWarehouseReceiptUpdated={this.onWarehouseReceiptSaved}
        onCancel={this.close.bind(this)}
        onInvoiceGenerated={onInvoiceGenerated && this.onInvoiceGenerated}
      />
    );
  }
}
