import { createStore, createEvent, createEffect, Event, Store } from 'effector';
import { LinkDto } from '../../models/data.models';
import { init } from './api.service';

export const addRoute: Event<LinkDto> = createEvent();
export const addRequest: Event<any> = createEvent();
export const removeRequest: Event<string> = createEvent();
export const authError = createEvent();
export const requestError: Event<any> = createEvent();

export const initApi = createEffect(async () => {
  return init();
});

type ApiStoreState = {
  requests: any[];
};

const defaultState: ApiStoreState = {
  requests: [],
};

export const apiStore: Store<ApiStoreState> = createStore(defaultState)
  .on(addRoute, (state, payload: LinkDto) => state)
  .on(addRequest, (state, payload) => {
    return { ...state, requests: [...state.requests, payload] };
  })
  .on(removeRequest, (state, payload) => {
    const index = state.requests.findIndex((req) => req.requestId === payload);
    return { ...state, requests: [...state.requests.splice(index, 1)] };
  })
  .on(initApi.done, (state, payload) => {
    payload.result.links.forEach((link: LinkDto) => {
      addRoute(link);
    });
  });
