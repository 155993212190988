import {
  BusinessRuleDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  BusinessRuleDtoPagedResult,
  CreateBusinessRuleCommandValues,
  UpdateBusinessRuleCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createBusinessRuleRequest,
  getBusinessRulesListRequest,
  getBusinessRuleRequest,
  deleteBusinessRuleRequest,
  updateBusinessRuleRequest,
  importBusinessRulesRequest,
  exportBusinessRulesRequest,
  GetBusinessRuleParams,
} from './businessRules.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type BusinessRulesStoreState = {
  links: LinkDto[];
  businessRuleColumns: EntityFieldDto[];
  defaultBusinessRuleColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getBusinessRulesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'BusinessRule',
  });
});

addEffectStatusHandling(getBusinessRulesColumnsFx);

export const getBusinessRulesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'BusinessRule',
  });
});

addEffectStatusHandling(getBusinessRulesDefaultColumnsFx);

export const updateBusinessRulesColumns = createEvent<EntityFieldDto[]>();

export const getBusinessRulesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getBusinessRulesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getBusinessRulesFx);

export const createBusinessRuleFx = createEffect(
  (businessRuleData: BusinessRuleDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createBusinessRuleCommand: CreateBusinessRuleCommandValues = {
      ...businessRuleData,
    };

    return createBusinessRuleRequest(
      currentOrganization!,
      createBusinessRuleCommand,
    );
  },
);

addEffectStatusHandling(createBusinessRuleFx, {
  completeMessage: 'Business rule was created successfully',
  errorMessage: 'Business rule was not created',
});

export const getBusinessRuleFx = createEffect(
  (businessRuleParams: GetBusinessRuleParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getBusinessRuleRequest(
      currentOrganization as LinkResourceBaseDto,
      businessRuleParams,
    );
  },
);

addEffectStatusHandling(getBusinessRuleFx);

export const updateBusinessRuleFx = createEffect(
  (businessRule: BusinessRuleDto) => {
    const updateBusinessRuleCommand: UpdateBusinessRuleCommandValues = {
      ...businessRule,
    };
    return updateBusinessRuleRequest(businessRule, updateBusinessRuleCommand);
  },
);

addEffectStatusHandling(updateBusinessRuleFx, {
  completeMessage: 'Business rule was updated successfully',
  errorMessage: 'Business rule was not updated',
});

export const deleteBusinessRuleFx = createEffect(
  (businessRule: BusinessRuleDto) => {
    return deleteBusinessRuleRequest(businessRule);
  },
);

addEffectStatusHandling(deleteBusinessRuleFx, {
  completeMessage: 'Business rule was deleted successfully',
  errorMessage: 'Business rule was not deleted',
});

export const exportBusinessRulesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportBusinessRulesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportBusinessRulesFx, {
  completeMessage: 'Business rules were exported successfully',
  errorMessage: 'Business rules were not exported',
});

export const importBusinessRulesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importBusinessRulesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importBusinessRulesFx, {
  completeMessage: 'Business rules were imported successfully',
  errorMessage: 'Business rules were not imported',
  inFlightMessage: 'Importing business rules...',
});

const defaultState: BusinessRulesStoreState = {
  links: [],
  businessRuleColumns: [],
  defaultBusinessRuleColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const businessRuleStore: Store<BusinessRulesStoreState> = createStore(
  defaultState,
)
  .on(getBusinessRulesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      businessRuleColumns: payload.result.items,
      defaultBusinessRuleColumns: payload.result.items,
    };
  })
  .on(getBusinessRulesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultBusinessRuleColumns: payload.result.items,
    };
  })
  .on(updateBusinessRulesColumns, (state, payload) => {
    return { ...state, businessRuleColumns: payload };
  });
