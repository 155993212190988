import {
  CommodityStatusDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  CommodityStatusDtoPagedResult,
  CreateCommodityStatusCommandValues,
  UpdateCommodityStatusCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_COMMODITYSTATUSES_LINK_KEY = 'get-commoditystatuses';
export const CREATE_COMMODITYSTATUS_LINK_KEY = 'create-commoditystatus';
export const GET_COMMODITYSTATUS_LINK_KEY = 'get-commoditystatus';
export const UPDATE_COMMODITYSTATUS_LINK_KEY = 'update-commoditystatus';
export const DELETE_COMMODITYSTATUS_LINK_KEY = 'delete-commoditystatus';
export const IMPORT_COMMODITYSTATUSES_LINK_KEY = 'import-commoditystatuses';
export const EXPORT_COMMODITYSTATUSES_LINK_KEY = 'export-commoditystatuses';
export const COMMODITYSTATUS_ENTITY_NAME = 'Commodity Status';

export interface GetCommodityStatusParams {
  statusId: number;
}

export interface ImportCommodityStatusesParams {
  organizationId: number;
}

export interface ExportCommodityStatusesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getCommodityStatusRequest = async (
  resource: LinkResourceBaseDto | null,
  { statusId }: GetCommodityStatusParams,
): Promise<CommodityStatusDto> => {
  if (resource && statusId) {
    const getCommodityStatusLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITYSTATUS_LINK_KEY,
    );
    if (getCommodityStatusLink) {
      const result = await execLink(getCommodityStatusLink, { statusId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COMMODITYSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCommodityStatusesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CommodityStatusDtoPagedResult> => {
  if (resource) {
    const getCommodityStatusesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITYSTATUSES_LINK_KEY,
    );
    if (getCommodityStatusesListLink) {
      const result = await execLink(getCommodityStatusesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COMMODITYSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCommodityStatusRequest = async (
  resource: LinkResourceBaseDto,
  commodityStatus: CreateCommodityStatusCommandValues,
) => {
  const createCommodityStatusLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_COMMODITYSTATUS_LINK_KEY,
  );
  if (createCommodityStatusLink) {
    const result = await execLink(createCommodityStatusLink, commodityStatus);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_COMMODITYSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateCommodityStatusRequest = async (
  resource: LinkResourceBaseDto,
  commodityStatus: UpdateCommodityStatusCommandValues,
) => {
  const updateCommodityStatusLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_COMMODITYSTATUS_LINK_KEY,
  );
  if (updateCommodityStatusLink) {
    const result = await execLink(updateCommodityStatusLink, commodityStatus);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_COMMODITYSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteCommodityStatusRequest = async (
  commodityStatus: CommodityStatusDto,
) => {
  const deleteCommodityStatusLink = commodityStatus.links?.find(
    (x: LinkDto) => x.rel === DELETE_COMMODITYSTATUS_LINK_KEY,
  );
  if (deleteCommodityStatusLink) {
    const result = await execLink(deleteCommodityStatusLink, commodityStatus);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_COMMODITYSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importCommodityStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportCommodityStatusesParams,
  file: File,
) => {
  const importCommodityStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_COMMODITYSTATUSES_LINK_KEY,
  );
  if (importCommodityStatusesLink) {
    const result = await postFormData(importCommodityStatusesLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_COMMODITYSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportCommodityStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportCommodityStatusesParams,
) => {
  const exportCommodityStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_COMMODITYSTATUSES_LINK_KEY,
  );
  if (exportCommodityStatusesLink) {
    const result = await downloadLinkFile(exportCommodityStatusesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_COMMODITYSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};
