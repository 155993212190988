import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { RatesListScreen } from './screens/rates-list.screen';
import { RateScreen } from './screens/rate.screen';
import { RateType } from '../../models/data.models';

export const CLIENT_RATES_LIST_PATH = '/client-rates';
export const CLIENT_RATE_EDIT_PATH = '/client-rates/:rateId';
export const CLIENT_RATE_CREATE_PATH = '/client-rates/0';

export const ClientRatesRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <RatesListScreen
              pageTitle="Client Rates"
              rateType={RateType.ClientRate}
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <RateScreen
              pageTitle="Add New Rate"
              rateType={RateType.ClientRate}
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':rateId'}
        element={
          <PrivateRoute>
            <RateScreen
              pageTitle="Update Rate"
              rateType={RateType.ClientRate}
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
