import { Routes, Route } from 'react-router-dom';
import React from 'react';
import { PrivateRoute } from '../common/private.route';
import { UsersListScreen } from './screens/users-list.screen';
import { UserScreen } from './screens/user.screen';

export const USERS_LIST_PATH = '/users';
export const USER_CREATE_PATH = '/users/0';
export const USER_EDIT_PATH = '/users/:userId';

export const UsersRoute = () => {
  const group = 'Administration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <UsersListScreen pageTitle="Users" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <UserScreen pageTitle="Add New User" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':userId'}
        element={
          <PrivateRoute>
            <UserScreen pageTitle="Update User" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
