import React, { useMemo, useCallback } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetContactAddressesQuery } from '../../../models/data.models';
import {
  CONTACTS_ADDRESS_CREATE_PATH,
  CONTACTS_ADDRESS_EDIT_PATH,
  CONTACTS_ADDRESS_LIST_PATH,
} from '../contactAddress.route';
import { ContactAddressesList } from '../components/contactAddresses-list.component';
import {
  CREATE_CONTACTADDRESS_LINK_KEY,
  GetContactAddressParams,
} from '../contactAddresses.service';
import { userHas } from '../../auth/auth.store';
import { useStore } from 'effector-react';
import {
  contactAddressStore,
  getContactAddressesDefaultColumnsFx,
} from '../contactAddresses.store';

export type ContactAddressesListScreenProps = {
  pageTitle: string;
  group: string;
};

const generateHistoryPath = (
  organizationId: string,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    viewName?: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_LIST_PATH, {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

export const ContactAddressesListScreen = ({
  pageTitle,
  group,
}: ContactAddressesListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(contactAddressStore);
  const {
    offset = 0,
    limit = defaultLimit,
    filter = '',
    sort = defaultSort,
    search = '',
    view = '',
  } = useQuery();

  const navigate = useNavigate();

  const createPath = useMemo(() => {
    if (userHas(CREATE_CONTACTADDRESS_LINK_KEY)) {
      return generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_CREATE_PATH, {
        organizationId,
      });
    } else {
      return null;
    }
  }, [organizationId]);

  const goToDetails = useCallback(
    (contactAddressParams: GetContactAddressParams) => {
      const path = generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_EDIT_PATH, {
        organizationId,
        ...contactAddressParams,
      });
      navigate(path);
    },
    [organizationId, navigate],
  );

  const goToCreate = useCallback(() => {
    if (createPath) navigate(createPath);
  }, [createPath]);

  const onDelete = useCallback(() => {
    navigate(navigate.length - 1);
  }, [navigate]);

  const onQueryChange = useCallback(
    (
      newParams: Partial<{
        offset: number;
        limit: number;
        sort: string;
        filter: string;
        search: string;
        view: string;
      }>,
    ) => {
      const params = { offset, limit, sort, filter, search, view };
      const mergedParams = { ...params, ...newParams };
      const newPath = generateHistoryPath(organizationId, mergedParams);
      navigate(newPath);
    },
    [offset, limit, sort, filter, search, view, organizationId, navigate],
  );

  const onPageChanged = useCallback(
    (page: number) => onQueryChange({ offset: page * limit }),
    [onQueryChange, limit],
  );

  const onLimitChanged = useCallback(
    (l: number) => onQueryChange({ limit: l }),
    [onQueryChange],
  );

  const onSortChanged = useCallback((s: string) => onQueryChange({ sort: s }), [
    onQueryChange,
  ]);

  const onFilterChanged = useCallback(
    (f: string) => onQueryChange({ filter: f, offset: 0 }),
    [onQueryChange],
  );

  const onSearchChanged = useCallback(
    (s: string) => onQueryChange({ search: s, offset: 0 }),
    [onQueryChange],
  );

  const onViewChanged = useCallback(
    (viewName: string, sortField: string, limitNumber: number) => {
      onQueryChange({ view: viewName, sort: sortField, limit: limitNumber });
      getContactAddressesDefaultColumnsFx();
    },
    [onQueryChange],
  );

  return (
    <InternalLayout group={group} title={pageTitle} createPath={createPath}>
      <ContactAddressesList
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        search={search}
        goToDetails={goToDetails}
        goToCreate={goToCreate}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSortChanged}
        onFilter={onFilterChanged}
        onLimitChanged={onLimitChanged}
        onSearch={onSearchChanged}
        onViewChanged={onViewChanged}
      />
    </InternalLayout>
  );
};
