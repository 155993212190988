import React, { useEffect, useState } from 'react';
import {
  CommodityDto,
  OrderDto,
  PickingActivityStatuses,
} from '../../../models/data.models';
import { CargoMovementForm } from '../components/cargoMovement.form';
import { Button } from '../../common/components/button/button.component';
import { FaBarcode } from 'react-icons/fa';
import { patchOrder } from '../cargoMovements.store';
import { PatchOrderParams } from '../cargoMovements.service';
import { CargoMovementDto } from '../../../models/custom.models';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';

export type ReportPackageProps = {
  order: CargoMovementDto;
  onOrderUpdated: (order: OrderDto) => void;
  onCancel: () => void;
};

export const ReportPackage = ({
  order,
  onOrderUpdated,
  onCancel,
}: ReportPackageProps) => {
  const [enteredNumber, setEnteredNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [foundCommodity, setFoundCommodity] = useState<CommodityDto | null>(
    null,
  );

  //find commodity by tracking number,
  useEffect(() => {
    {
      if (enteredNumber) {
        const chosenCommodity = order?.commodities?.find(
          (x) => x.commodityTrackingNumber === enteredNumber,
        );
        if (chosenCommodity) {
          setFoundCommodity(chosenCommodity);
        } else {
          setFoundCommodity(null);
        }
      } else {
        setFoundCommodity(null);
      }
    }
  }, [enteredNumber]);

  //change chosen commodity location and then change its status
  const onReportDamaged = () => {
    if (foundCommodity) {
      setIsLoading(true);
      const commodityParams = order.orderCommoditiesWithStatus?.map(
        (commodity) => {
          return {
            commodityId: commodity?.commodityId,
            orderId: order?.orderId,
            customValues: commodity?.customValues,
          };
        },
      );

      commodityParams?.forEach((x) => {
        if (x.commodityId === foundCommodity.commodityId) {
          x.customValues['activityStatus'] = PickingActivityStatuses.Damaged;
        }
      });

      const patchOrderParams: PatchOrderParams = {
        resource: { links: order?.links },
        patchOrderCommand: {
          patchItems: [
            {
              path: `/OrderCommodities`,
              op: 'replace',
              value: commodityParams,
            },
          ],
        },
      };

      setIsLoading(true);
      patchOrder(patchOrderParams)
        .then((result: CargoMovementDto) => {
          onOrderUpdated(result);
          addMessage({
            message: `Package status is set to ${PickingActivityStatuses.Damaged}`,
            type: 'success',
            id: v4(),
          });
        })
        .catch((error) => {
          addMessage({
            message: error?.Message || error,
            type: 'danger',
            id: v4(),
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div>
      <div>
        <h3>Report damaged package</h3>
        <div className="row mt-5">
          <div className="col-6">
            <CargoMovementForm>
              <CargoMovementForm.EnteredNumber
                label="Tracking number"
                barcode={true}
                onChange={(value) => {
                  setEnteredNumber(value);
                }}
                defaultValue={enteredNumber}
              />
            </CargoMovementForm>
          </div>
        </div>
        <div className="row mt-5">
          <div className={'col-3'}>
            <Button
              className={'w-100'}
              color={'action-waiting'}
              outline={false}
              onClick={onReportDamaged}
              disabled={!foundCommodity || isLoading}
            >
              Report Damaged
            </Button>
          </div>
          <div className={'col-3'}>
            <Button
              className={'w-100'}
              color={'action-secondary'}
              outline={false}
              onClick={onCancel}
              disabled={false}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
