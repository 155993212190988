import React from 'react';
import { Panel } from '../../common/components/panel/panel.component';
import { ResetPasswordComponent } from '../components/reset-password.component';
import { Helmet } from 'react-helmet';

export type ResetPasswordScreenProps = { pageTitle: string };

export const ResetPasswordScreen = ({
  pageTitle,
}: ResetPasswordScreenProps) => {
  return (
    <div className="py-5">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="d-flex justify-content-center">
        <Panel style={{ maxWidth: '500px', width: '100%' }}>
          <h1 className="text-center login-register-header font-weight-light">
            Reset password
          </h1>
          <ResetPasswordComponent />
        </Panel>
      </div>
    </div>
  );
};
