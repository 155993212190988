import {
  ModeOfTransportationDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateModeOfTransportationCommandValues,
  UpdateModeOfTransportationCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createModeOfTransportationRequest,
  getModeOfTransportationsListRequest,
  getModeOfTransportationRequest,
  deleteModeOfTransportationRequest,
  updateModeOfTransportationRequest,
  importModeOfTransportationsRequest,
  exportModeOfTransportationsRequest,
  GetModeOfTransportationParams,
} from './modeOfTransportations.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type ModeOfTransportationsStoreState = {
  links: LinkDto[];
  modeOfTransportationColumns: EntityFieldDto[];
  defaultModeOfTransportationColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getModeOfTransportationsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ModeOfTransportation',
  });
});

addEffectStatusHandling(getModeOfTransportationsColumnsFx);

export const getModeOfTransportationsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ModeOfTransportation',
  });
});

addEffectStatusHandling(getModeOfTransportationsDefaultColumnsFx);

export const updateModeOfTransportationsColumns = createEvent<
  EntityFieldDto[]
>();

export const getModeOfTransportationsFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getModeOfTransportationsListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getModeOfTransportationsFx);

export const createModeOfTransportationFx = createEffect(
  (modeOfTransportationData: ModeOfTransportationDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createModeOfTransportationCommand: CreateModeOfTransportationCommandValues = {
      transportationMethod: {
        transportationMethod: modeOfTransportationData.transportationMethod,
        transportationMethodDescription:
          modeOfTransportationData.transportationMethodDescription,
      },
      description: modeOfTransportationData.description,
      usCustomsCode: modeOfTransportationData.usCustomsCode,
      customValues: modeOfTransportationData.customValues,
    };

    return createModeOfTransportationRequest(
      currentOrganization!,
      createModeOfTransportationCommand,
    );
  },
);

addEffectStatusHandling(createModeOfTransportationFx, {
  completeMessage: 'Mode of transportation was created successfully',
  errorMessage: 'Mode of transportation was not created',
});

export const getModeOfTransportationFx = createEffect(
  (modeOfTransportationParams: GetModeOfTransportationParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getModeOfTransportationRequest(
      currentOrganization as LinkResourceBaseDto,
      modeOfTransportationParams,
    );
  },
);

addEffectStatusHandling(getModeOfTransportationFx);

export const updateModeOfTransportationFx = createEffect(
  (modeOfTransportation: ModeOfTransportationDto) => {
    const updateModeOfTransportationCommand: UpdateModeOfTransportationCommandValues = {
      transportationMethod: {
        transportationMethod: modeOfTransportation.transportationMethod,
        transportationMethodDescription:
          modeOfTransportation.transportationMethodDescription,
      },
      description: modeOfTransportation.description,
      usCustomsCode: modeOfTransportation.usCustomsCode,
      customValues: modeOfTransportation.customValues,
    };
    return updateModeOfTransportationRequest(
      modeOfTransportation,
      updateModeOfTransportationCommand,
    );
  },
);

addEffectStatusHandling(updateModeOfTransportationFx, {
  completeMessage: 'Mode of transportation was updated successfully',
  errorMessage: 'Mode of transportation was not updated',
});

export const deleteModeOfTransportationFx = createEffect(
  (modeOfTransportation: ModeOfTransportationDto) => {
    return deleteModeOfTransportationRequest(modeOfTransportation);
  },
);

addEffectStatusHandling(deleteModeOfTransportationFx, {
  completeMessage: 'Mode of transportation was deleted successfully',
  errorMessage: 'Mode of transportation was not deleted',
});

export const exportModeOfTransportationsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportModeOfTransportationsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportModeOfTransportationsFx, {
  completeMessage: 'Mode of transportations were exported successfully',
  errorMessage: 'Mode of transportations were not exported',
});

export const importModeOfTransportationsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importModeOfTransportationsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importModeOfTransportationsFx, {
  completeMessage: 'Mode of transportations were imported successfully',
  errorMessage: 'Mode of transportations were not imported',
  inFlightMessage: 'Importing mode of transportations...',
});

const defaultState: ModeOfTransportationsStoreState = {
  links: [],
  modeOfTransportationColumns: [],
  defaultModeOfTransportationColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const modeOfTransportationStore: Store<ModeOfTransportationsStoreState> = createStore(
  defaultState,
)
  .on(getModeOfTransportationsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      modeOfTransportationColumns: payload.result.items,
      defaultModeOfTransportationColumns: payload.result.items,
    };
  })
  .on(getModeOfTransportationsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultModeOfTransportationColumns: payload.result.items,
    };
  })
  .on(updateModeOfTransportationsColumns, (state, payload) => {
    return { ...state, modeOfTransportationColumns: payload };
  });
