import {
  WorkflowDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  WorkflowDtoPagedResult,
  CreateWorkflowCommandValues,
  UpdateWorkflowCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_WORKFLOWS_LINK_KEY = 'get-workflows';
export const CREATE_WORKFLOW_LINK_KEY = 'create-workflow';
export const GET_WORKFLOW_LINK_KEY = 'get-workflow';
export const UPDATE_WORKFLOW_LINK_KEY = 'update-workflow';
export const DELETE_WORKFLOW_LINK_KEY = 'delete-workflow';
export const IMPORT_WORKFLOWS_LINK_KEY = 'import-workflows';
export const EXPORT_WORKFLOWS_LINK_KEY = 'export-workflows';
export const WORKFLOW_ENTITY_NAME = 'Workflow';

export interface GetWorkflowParams {
  workflowId: any;
}

export interface ImportWorkflowsParams {
  organizationId: number;
}

export interface ExportWorkflowsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getWorkflowRequest = async (
  resource: LinkResourceBaseDto | null,
  { workflowId }: GetWorkflowParams,
): Promise<WorkflowDto> => {
  if (resource && workflowId) {
    const getWorkflowLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_WORKFLOW_LINK_KEY,
    );
    if (getWorkflowLink) {
      const result = await execLink(getWorkflowLink, { workflowId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WORKFLOW_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getWorkflowsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<WorkflowDtoPagedResult> => {
  if (resource) {
    const getWorkflowsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_WORKFLOWS_LINK_KEY,
    );
    if (getWorkflowsListLink) {
      const result = await execLink(getWorkflowsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WORKFLOWS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createWorkflowRequest = async (
  resource: LinkResourceBaseDto,
  workflow: CreateWorkflowCommandValues,
) => {
  const createWorkflowLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_WORKFLOW_LINK_KEY,
  );
  console.log(createWorkflowLink);
  if (createWorkflowLink) {
    const result = await execLink(createWorkflowLink, workflow);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_WORKFLOW_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateWorkflowRequest = async (
  resource: LinkResourceBaseDto,
  workflow: UpdateWorkflowCommandValues,
) => {
  const updateWorkflowLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_WORKFLOW_LINK_KEY,
  );
  if (updateWorkflowLink) {
    const result = await execLink(updateWorkflowLink, workflow);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_WORKFLOW_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteWorkflowRequest = async (workflow: WorkflowDto) => {
  const deleteWorkflowLink = workflow.links?.find(
    (x: LinkDto) => x.rel === DELETE_WORKFLOW_LINK_KEY,
  );
  if (deleteWorkflowLink) {
    const result = await execLink(deleteWorkflowLink, workflow);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_WORKFLOW_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importWorkflowsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportWorkflowsParams,
  file: File,
) => {
  const importWorkflowsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_WORKFLOWS_LINK_KEY,
  );
  if (importWorkflowsLink) {
    const result = await postFormData(importWorkflowsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_WORKFLOWS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportWorkflowsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportWorkflowsParams,
) => {
  const exportWorkflowsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_WORKFLOWS_LINK_KEY,
  );
  if (exportWorkflowsLink) {
    const result = await downloadLinkFile(exportWorkflowsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_WORKFLOWS_LINK_KEY} is not allowed or missing link action`,
  );
};
