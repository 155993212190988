import React from 'react';
import Draggable from 'react-draggable';
import { GrClose } from 'react-icons/gr';

export type DialogProps = {
  title: string;
  className?: string;
  bodyClassName?: string;
  message?: string;
  orderNumber?: string;
  topPosition?: string;
  mainModalClassName?: string;
  close?: (obj: any) => void | null;
  dialog?: any;
};
export class Dialog<
  Props extends DialogProps,
  State = {}
> extends React.Component<DialogProps, State> {
  constructor(props: Props) {
    super(props);
  }

  _resolve: (obj: any) => void | null = () => {};

  close(result?) {
    this.props.close(result);
  }

  renderContent(): any {
    return null;
  }

  renderDialog(title: string, className: string, mainModalClassName: string) {
    const modalClassName = 'modal fade show ' + className;
    const handle = ['.modal-header'];
    const cancel = [
      'input',
      'button',
      'label',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'a',
      'svg',
      'p',
      'span',
      'textarea',
      'img',
      '.select__value-container',
      '.icon-edit-entity',
      '.custom-control',
      '.grid',
      '.header-form',
      '.quill',
      '.form-border-text',
      '.text-h3',
      '.prevent-drag',
    ];
    const { bodyClassName, topPosition, dialog } = this.props;
    const dialogClassName = dialog?.props?.className;
    const modalStyle =
      topPosition !== undefined ? { top: `${topPosition}px` } : {};

    return (
      <div
        className={modalClassName}
        style={{ display: 'block' }}
        role="dialog"
      >
        <div className={`modal-dialog ${dialogClassName}`} role="document">
          <Draggable handle={handle.join(', ')} cancel={cancel.join(', ')}>
            <div
              className={`modal-content shadow ${mainModalClassName}`}
              style={modalStyle}
            >
              <div className="modal-header">
                <span className="modal-title">{title}</span>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.close(null)}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <GrClose className="x-square" size={'18px'} />
                </button>
              </div>
              <div
                className={`modal-body${
                  bodyClassName ? ' ' + bodyClassName : ''
                }`}
              >
                {this.renderContent()}
              </div>
            </div>
          </Draggable>
        </div>
      </div>
    );
  }

  render() {
    const { title, className, mainModalClassName } = this.props;
    return this.renderDialog(title, className, mainModalClassName);
  }
}
