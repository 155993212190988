import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { PortDto, PortDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  portStore,
  getPortsColumnsFx,
  getPortsFx,
  deletePortFx,
  exportPortsFx,
  importPortsFx,
} from '../ports.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_PORT_LINK_KEY,
  DELETE_PORT_LINK_KEY,
  IMPORT_PORTS_LINK_KEY,
  EXPORT_PORTS_LINK_KEY,
  GetPortParams,
} from '../ports.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { PortDialog } from './port.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type PortsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  isDropDownList?: boolean;
  goToDetails?: (portParams: GetPortParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (viewName: string) => void;
  onSort?: (field: string) => void;
  onSelect?: (port: PortDto, portParams: GetPortParams) => void;
};

export const PortsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
  isDropDownList = false,
}: PortsListProps) => {
  const [ports, setPorts] = useState<PortDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const { portColumns: columns, defaultPortColumns: defaultColumns } = useStore(
    portStore,
  );

  useEffect(() => {
    getPortsColumnsFx();
  }, []);

  const getPorts = useCallback(async () => {
    const portsData = await getPortsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(portsData));
    setPorts(portsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: PortDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_PORTS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importPortsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getPorts();
          },
          result?.links,
        ),
        getAction(
          EXPORT_PORTS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportPortsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getPorts],
  );

  useEffect(() => {
    getPorts();
  }, [getPorts]);

  const onDeletePort = useCallback(
    async (port: PortDto) => {
      if (userHas(DELETE_PORT_LINK_KEY, ports?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Port`,
            message: 'Are you sure you want to delete port?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deletePortFx(port);
            getPorts();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getPorts, onDelete, ports],
  );

  const onEditPort = (port: PortDto) => {
    if (port)
      goToDetails({ portId: port.portId, countryCode: port.countryCode });
  };

  const onPortSelect = useCallback(
    async (port) => {
      const selectedPort = await showDialog({
        dialog: PortDialog,
        props: {
          portId: port.portId,
          countryCode: port.countryCode,
          title: 'Update Port',
        },
      });
      if (selectedPort) {
        getPorts();
      }
    },
    [getPorts],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          isDropDownList={true}
          rowKeys={['portId', 'countryCode']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={ports.items}
          onLimitChanged={onLimitChanged}
          total={ports.totalCount}
          onDelete={onDeletePort}
          onSort={onSort}
          onEdit={onEditPort}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onPortSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {ports?.totalCount === 0 && !search && (
          <ListElementsNotFound
            entityName="Port"
            entityLinkKey={CREATE_PORT_LINK_KEY}
            onClick={goToCreate}
          />
        )}
      </>
    );
  }
};
