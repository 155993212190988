import { CommodityDto, OrderDto } from '../../../../models/data.models';
import { Dialog } from '../../../common/components/dialog/dialog.component';
import { CargoFragmentSelectCommodities } from './cargoFragmentSelectCommodities.component';

export type CargoFragmentDialogProperties = {
  title: string;
  className?: string;
  saveButtonRenderCondition?: boolean;
  isEditMode?: boolean;
  saveBtnLabel?: string;
  selectedCommodities?: CommodityDto[];
};

export class CargoFragmentDialog extends Dialog<CargoFragmentDialogProperties> {
  constructor(props: CargoFragmentDialogProperties) {
    super(props);
    this.onCommoditiesSelect = this.onCommoditiesSelect.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isEditMode = props.isEditMode;
    this.saveBtnLabel = props.saveBtnLabel ?? 'Select Commodity';
  }

  isEditMode: boolean | undefined = false;
  saveBtnLabel: string;

  onCommoditiesSelect = (commodities: CommodityDto[]) => {
    this.close(commodities);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { selectedCommodities } = this.props as CargoFragmentDialogProperties;
    return (
      <CargoFragmentSelectCommodities
        onCancel={this.onCancel}
        saveBtnLabel={this.saveBtnLabel}
        onCommoditiesSelect={this.onCommoditiesSelect}
        selectedCommodities={selectedCommodities}
      />
    );
  }
}
