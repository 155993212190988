import React, { useEffect, useState } from 'react';
import { DivisionDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { v4 as uuidv4 } from 'uuid';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { showDialog } from '../../common/dialog.store';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { getJobFx, getJobsFx } from '../jobs.store';
import { JobDialog } from './job.dialog';
import {
  CREATE_JOB_LINK_KEY,
  GET_JOB_LINK_KEY,
  JOB_ENTITY_NAME,
} from '../jobs.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export const SelectJobPaginate = ({
  id = 'jobId' + uuidv4(),
  nameId = 'job' + uuidv4(),
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeJob = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Job',
  getOptionValue = (option) => option?.jobId,
  getOptionLabel = (option) => `${option?.jobNumber} - ${option?.description}`,
}): JSX.Element => {
  if (selectedFilter.trim().length > 0) {
    selectedFilter = `${selectedFilter}`;
  } else {
    selectedFilter = ``;
  }

  const formikContext = useFormikContext();
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (selectedValue) {
      getJobFx({ jobId: selectedValue }).then(
        (data) => setValue(data),
        () => {},
      );
    } else {
      setValue(null);
    }
  }, [selectedValue]);

  const [context, setContext] = useState(null);
  useEffect(() => {
    setContext(useContext);
  }, [useContext]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseContactDtoPagedResult: DivisionDtoPagedResult = await getJobsFx(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseContactDtoPagedResult.items,
      hasMore: responseContactDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeJob === 'function') {
      onChangeJob(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const onChangeWithContext = (job, currentContext) => {
    onChange(job, currentContext);
    if (currentContext) {
      if (isMulti === true) {
        if (job?.length > 0) {
          currentContext.setFieldValue(id, job?.length);
          currentContext.setFieldError(id, undefined);
        } else {
          if (required) {
            currentContext.setFieldValue(id, '');
          }
        }
      } else {
        currentContext.setFieldValue(id, getOptionValue(job));
        currentContext.setFieldValue(nameId, getOptionLabel(job));
        currentContext.setFieldError(id, undefined);
        if (required && !job) {
          currentContext.setFieldValue(id, '');
        }
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onJobEditIconClick = () => {
    if (value?.jobId) {
      showDialog({
        dialog: JobDialog,
        props: {
          jobId: value.jobId,
          title: 'Update Job',
        },
      }).then(
        (job) => {
          if (job !== null) {
            getJobFx({
              jobId: value.jobId || null,
            }).then(
              (jobDto) => {
                onChangeWithContext(jobDto, formikContext);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  if (context) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti && value && userHas(GET_JOB_LINK_KEY) ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onJobEditIconClick}
                    data-cy={`job-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={JOB_ENTITY_NAME}
              canCreateNewEntity={userHas(CREATE_JOB_LINK_KEY)}
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: JobDialog,
                  props: {
                    title: showCreateDialogTitle,
                  },
                }).then(
                  (job) => {
                    setClearLoadOptionsCache(!clearLoadOptionsCache);
                    return job;
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.jobId}
              getOptionLabel={(option) =>
                `${option?.jobNumber} - ${option?.description}`
              }
              onChange={(job?: any) => {
                onChangeWithContext(job, context);
              }}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              isDisabled={isDisabled}
              isClearable={isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={isSearchable}
              placeholder={placeholder}
              components={{
                IndicatorSeparator: () => null,
              }}
              classNamePrefix={classNamePrefix}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={JOB_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_JOB_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: JobDialog,
            props: {
              title: showCreateDialogTitle,
            },
          }).then(
            (job) => {
              setClearLoadOptionsCache(!clearLoadOptionsCache);
              return job;
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        onChange={(job?: any) => {
          onChange(job, context);
        }}
        onFocus={clearMessages}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
