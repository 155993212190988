import { Dialog } from '../../common/components/dialog/dialog.component';
import { CommodityStatusEdit } from './commodityStatus-edit.component';
import { CommodityStatusDto } from '../../../models/data.models';

export type CommodityStatusDialogProperties = {
  statusId?: number | null;
  title: string;
};

export class CommodityStatusDialog extends Dialog<CommodityStatusDialogProperties> {
  constructor(props: CommodityStatusDialogProperties) {
    super(props);
    this.onCommodityStatusSaved = this.onCommodityStatusSaved.bind(this);
  }

  onCommodityStatusSaved = (commodityStatus: CommodityStatusDto) => {
    this.close(commodityStatus);
  };

  renderContent(): any {
    const { statusId } = this.props as CommodityStatusDialogProperties;
    return (
      <CommodityStatusEdit
        statusId={statusId}
        onCommodityStatusCreated={this.onCommodityStatusSaved}
        onCommodityStatusUpdated={this.onCommodityStatusSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
