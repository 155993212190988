import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { OrderForm } from '../../orders/components/order.form';
import {
  AttachmentParentType,
  ChargeDto,
  ChargeType,
  CommodityCustomValues,
  CommodityDto,
  CommodityStatuses,
  ContactDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
  DangerousItemsValues,
  DimensionsUnit,
  DivisionDto,
  EntityTypes,
  OrderDto,
  OrderEntityDto,
  OrderStatusDto,
  OrderTypes,
  PackageTypeDto,
  PaidAs,
  TrackingEventDto,
  VisibleTransactions,
  VolumeUnit,
  WeightUnit,
} from '../../../models/data.models';
import { Prompt } from '../../common/components/prompt/prompt.component';
import { createOrder, getOrder, updateOrder } from '../../orders/orders.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CommodityForm } from '../../commodities/components/commodity.form';
import { organizationsStore } from '../../organization/organization.store';
import { CommoditiesForOrderList } from '../../commodities/components/commodities-forOrder-list.component';
import {
  FormattedProfitValues,
  OrderEditFormDto,
  ProfitValues,
} from '../../../models/custom.models';
import { GetCommodityParams } from '../../commodities/commodities.service';
import { showDialog } from '../../common/dialog.store';
import { CommodityDialog } from '../../commodities/components/commodity.dialog';
import { ChargeForOrderList } from '../../charges/components/charge-forOrder-list.component';
import {
  CalculateChargesAmountParams,
  CREATE_CHARGE_LINK_KEY,
  GetChargeParams,
} from '../../charges/charges.service';
import { ChargeDialog } from '../../charges/components/charge.dialog';
import { ChargeForm } from '../../charges/components/charge.form';
import { FormContext } from '../../common/components/form/form.component';
import { SendEmailDialog } from '../../sendEmail/components/sendEmail.dialog';
import { CarrierForm } from './form-items/carrier-from';
import { OrderEntityForm } from './form-items/orderEntity-form';
import {
  generateValidationSchemaWithCustomFields,
  getCustomFieldOptionsValues,
  getEnumKeyByValue,
  getPaidAs,
  validateNumberInput,
  validatePositiveNumberInput,
} from '../../../utils/helper.utils';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import {
  getDocumentTemplatesFx,
  getRenderedDocumentLink,
} from '../../documentTemplates/documentTemplates.store';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import {
  ChargeDefaultValues,
  CommodityDefaultValues,
  OrderDefaultValues,
  ProfitDefaultValues,
} from '../../common/DefaultValues';
import * as Yup from 'yup';
import { getFormattedPrice } from '../../../utils/formatting.utils';
import { currencyStore } from '../../currencies/currencies.store';
import { getContacts } from '../../contacts/contacts.store';
import { authStore, userHas } from '../../auth/auth.store';
import { FormikProps, FormikValues } from 'formik';
import { getEventDefinitionFx } from '../../eventDefinitions/eventDefinitions.store';
import { addMessage, Message } from '../../common/messages.store';
import { TrackingEventForOrderList } from '../../trackingEvents/components/trackingEvents-forOrder-list.component';
import { GetTrackingEventParams } from '../../trackingEvents/trackingEvents.service';
import { TrackingEventDialog } from '../../trackingEvents/components/trackingEvent.dialog';
import { GetAccountingTransactionParams } from '../../accountingTransactions/accountingTransactions.service';
import { InvoiceDialog } from '../../invoice/components/invoice.dialog';
import { CustomFieldsLayout } from '../../common/components/form/customFields-layout.component';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FaPaperclip } from 'react-icons/fa';
import { CargoFragmentDialog } from '../../airShipmentOrders/wizard/common/cargoFragment.dialog';
import { OrderDocumentsFragment } from '../../orderDocuments/components/orderDocumentsFragment';
import { getWorkflowTriggersFx } from '../../workflowTriggers/workflowTriggers.store';
import { useCallback } from 'react';
import { WorkflowTriggerDtoListResult } from '../../../models/data.models';
import { IAction, getAction } from '../../common/components/actions/actions';
import { EXECUTE_WORKFLOWTRIGGER_LINK_KEY } from '../../workflowTriggers/workflowTriggers.service';
import { AiFillCode } from 'react-icons/ai';
import { WorkflowTriggerDialog } from '../../workflowTriggers/components/workflowTrigger.dialog';
import { ActionsDropdown } from '../../common/components/actions/actions.component';

export type ParcelShipmentEditProps = {
  orderId: number | null;
  onParcelShipmentCreated?: (parcelShipment: OrderDto) => void;
  onParcelShipmentUpdated?: (parcelShipment: OrderDto) => void;
  onParcelShipmentLoaded?: (parcelShipment: OrderDto) => void;
  onCancel?: () => void;
  onInvoiceGenerated?: () => void;
};

const getInitialStateOrderEntities = (
  contactType: ContactType,
  entityType: EntityTypes,
) => {
  const initialStateOrderCarrier: OrderEntityDto = {
    orderEntityId: 0,
    contactAddressId: null,
    contactAddressName: null,
    contactId: null,
    contactName: null,
    contactType: contactType,
    created: null,
    createdBy: null,
    date: null,
    notes: '',
    lastModified: null,
    lastModifiedBy: null,
    orderEntitySequence: 0,
    orderId: 0,
    contactCityName: null,
    contactStateCode: null,
    entityType: entityType,
    customValues: {},
    links: [],
  };
  return initialStateOrderCarrier;
};

//initial state
const initialStateCommodity: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  commodityTypeId: null,
  commodityTypeCode: CommodityDefaultValues.description,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  customValues: CommodityDefaultValues.customValues,
  valueByTotal: CommodityDefaultValues.valueByTotal,
};

const initialProfitValues: ProfitValues = {
  expense: ProfitDefaultValues.expense,
  income: ProfitDefaultValues.income,
  profit: ProfitDefaultValues.profit,
};

const EntityPickupsInitial = () =>
  getInitialStateOrderEntities(ContactType.Contact, EntityTypes.Shipper);
const EntityDeliveriesInitial = () =>
  getInitialStateOrderEntities(ContactType.Contact, EntityTypes.Consignee);

const getInitialState = () => {
  const initialState: OrderEditFormDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: null,
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntityCarriers: [
      getInitialStateOrderEntities(ContactType.Carrier, EntityTypes.Carrier),
    ],
    orderEntityDeliveries: [EntityDeliveriesInitial()],
    orderEntityPickups: [EntityPickupsInitial()],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    orderEntities: [],
    links: [],
    problemReason: '',
  };
  return initialState;
};

//validation
let parcelShipmentSchema = Yup.object().shape({
  billToContactId: Yup.string().required("Can't be blank").nullable(true),
  orderStatusId: Yup.number().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

const numberSchema = Yup.string()
  .transform((value) => (value === null ? '' : value))
  .test('numberFormat', 'Incorrect number format', (value) => {
    return (
      (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
        value?.toString(),
      ) &&
        Number(value) < Number.MAX_SAFE_INTEGER &&
        Number(value) > Number.MIN_SAFE_INTEGER) ||
      value === '' ||
      value === undefined
    );
  })
  .test('length', 'Max value is 999999', (value) => {
    return value === undefined || Number(value) <= 999999;
  })
  .nullable(true);

const commoditySchema = Yup.object().shape({
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
});

//component
export const ParcelShipmentEdit = ({
  orderId = 0,
  onParcelShipmentLoaded = () => {},
  onParcelShipmentCreated = () => {},
  onParcelShipmentUpdated = () => {},
  onCancel = () => {},
  onInvoiceGenerated = () => {},
}: ParcelShipmentEditProps) => {
  const { user: currentUser } = authStore.getState();

  const limit = 20;
  const isCreateMode = !orderId || orderId == 0;

  const commodityFormRef = useRef<HTMLFormElement>();
  const orderFormRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const forceUpdate: () => void = useState()[1].bind(null, {});

  const [
    parcelShipmentContextFormValue,
    setParcelShipmentContextFormValue,
  ] = useState<OrderEditFormDto | null>(null);
  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    getInitialState(),
  );

  const [
    applyToContactPaidAs,
    setApplyToContactPaidAs,
  ] = useState<PaidAs | null>(null);

  const [
    applyToCarrierPaidAs,
    setApplyToCarrierPaidAs,
  ] = useState<PaidAs | null>(null);

  const [commodity, setCommodity] = useState<CommodityDto | null>(null);

  const [packageType, setPackageType] = useState<{
    packageTypeId: string;
    name: string;
  }>(null);

  const [
    profitValues,
    setProfitValues,
  ] = useState<FormattedProfitValues | null>(null);

  const [commodityType, setcommodityType] = useState<{
    commodityTypeId: number;
    code: string;
  }>(null);

  const [commodities, setCommodities] = useState<CommodityDto[] | null>([]);

  const [fakeCommodityIndex, setFakeCommodityIndex] = useState<number>(null);

  const [charges, setCharges] = useState<ChargeDto[] | null>([]);

  const [trackingEvents, setTrackingEvents] = useState<
    TrackingEventDto[] | null
  >([]);

  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  const [systemCustomFields, setSystemCustomFields] = useState<
    CustomFieldDto[]
  >([]);

  const [orderEntityCustomFields, setOrderEntityCustomFields] = useState<
    CustomFieldDto[]
  >([]);

  const [orderCustomFields, setOrderCustomFields] = useState<CustomFieldDto[]>(
    [],
  );

  const [carrier, setCarrier] = useState<ContactDto>(null);

  const [
    parcelShipmentDocumentTemplatesCount,
    setParcelShipmentDocumentTemplatesCount,
  ] = useState<number>(0);

  const [
    generalCustomFieldRows,
    setGeneralCustomFieldRows,
  ] = React.useState<number>(0);

  const [actions, setActions] = useState([]);

  const isOrgAdminOrOperation: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;

  const isOrgAdminOrAccounting: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInAccountingRole;

  //fill carriers and vendors
  const parseOrderEntities = (
    parcelShipmentContextFormValueDto: OrderEditFormDto,
  ): void => {
    const carriers: OrderEntityDto[] = [];
    const shippers: OrderEntityDto[] = [];
    const consignees: OrderEntityDto[] = [];
    parcelShipmentContextFormValueDto.orderEntities.forEach(
      (entity: OrderEntityDto) => {
        switch (EntityTypes[entity.entityType].toString()) {
          case EntityTypes.Carrier.toString():
            carriers.push(entity);
            break;
          case EntityTypes.Shipper.toString():
            shippers.push(entity);
            break;
          case EntityTypes.Consignee.toString():
            consignees.push(entity);
            break;
        }
      },
    );
    if (carriers.length > 0)
      parcelShipmentContextFormValueDto.orderEntityCarriers = [...carriers];
    if (shippers.length > 0)
      parcelShipmentContextFormValueDto.orderEntityPickups = [...shippers];
    if (consignees.length > 0)
      parcelShipmentContextFormValueDto.orderEntityDeliveries = [...consignees];
  };

  // document templates
  useEffect(() => {
    getDocumentTemplatesFx({
      filter: `documentTemplateType:QuoteConfirmationDocument AND isInactive: false`,
    }).then((documentTemplates) => {
      setParcelShipmentDocumentTemplatesCount(documentTemplates.items?.length);
    });
  }, []);

  const documentTemplateFilter =
    'documentTemplateType:BillOfLadingDocument OR documentTemplateType:ShippingLabelDocument OR documentTemplateType:CustomsDeclarationDocument';

  // profitValues
  useEffect(() => {
    initialProfitValues.profit =
      initialProfitValues.income - initialProfitValues.expense;
    const income = getFormattedPrice(
      initialProfitValues.income,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const expense = getFormattedPrice(
      initialProfitValues.expense,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const profit = getFormattedPrice(
      initialProfitValues.profit,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    setProfitValues({ income, expense, profit });
    setParcelShipmentContextFormValue((parcelShipmentContextFormValueDto) => {
      parcelShipmentContextFormValueDto = getInitialState();
      return { ...parcelShipmentContextFormValueDto };
    });
  }, []);

  //parcelShipment custom fields and contacts
  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.ParcelShipment} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        parcelShipmentSchema = generateValidationSchemaWithCustomFields(
          customFields,
          parcelShipmentSchema,
        );
        setCustomFields(
          customFields.filter((value) => value.isSystem === false),
        );
        setSystemCustomFields(
          customFields.filter((value) => value.isSystem === true),
        );
      },
      () => {},
    );

    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.PurchaseCarrier}
      OR customFieldEntityType: ${CustomFieldEntityType.Contact}
      OR customFieldEntityType: ${CustomFieldEntityType.Carrier}
      OR customFieldEntityType: ${CustomFieldEntityType.ParcelShipmentCarrier}
      AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        parcelShipmentSchema = generateValidationSchemaWithCustomFields(
          customFields,
          parcelShipmentSchema,
        );
        setOrderEntityCustomFields(customFields);
      },
      () => {},
    );

    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Order} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        parcelShipmentSchema = generateValidationSchemaWithCustomFields(
          customFields,
          parcelShipmentSchema,
        );
        setOrderCustomFields(customFields);
      },
      () => {},
    );

    getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
      (result) => {
        const defaultDispatcher = result.items.find(
          (employee) => employee.userEmployee?.userId == currentUser?.userId,
        );
        setParcelShipmentContextFormValue(
          (parcelShipmentContextFormValueDto) => {
            parcelShipmentContextFormValueDto.employeeContactName =
              defaultDispatcher?.name;
            parcelShipmentContextFormValueDto.employeeContactId =
              defaultDispatcher?.contactId;
            setInitialValues({ ...parcelShipmentContextFormValueDto });
            return { ...parcelShipmentContextFormValueDto };
          },
        );
      },
      () => {},
    );

    if (isCreateMode) {
      setFakeCommodityIndex(0);
      setIsLoading(false);
    } else if (orderId) {
      getOrder({ orderId }).then(
        (orderDto: OrderDto) => {
          if (orderDto.trackingEvents) {
            orderDto.trackingEvents.forEach((trackingEvent) => {
              if (trackingEvent.eventDefinitionId) {
                getEventDefinitionFx({
                  eventDefinitionId: trackingEvent.eventDefinitionId,
                }).then(
                  (eventDefinition) =>
                    (trackingEvent.eventName = eventDefinition.eventName),
                );
              }
            });
          }

          setParcelShipmentContextFormValue(
            (parcelShipmentContextFormValueDto) => {
              if (!parcelShipmentContextFormValueDto) {
                parcelShipmentContextFormValueDto = getInitialState();
              }
              parcelShipmentContextFormValueDto.orderId = orderDto.orderId;
              parcelShipmentContextFormValueDto.orderStatus =
                orderDto.orderStatus;
              parcelShipmentContextFormValueDto.orderStatusId =
                orderDto.orderStatusId;
              parcelShipmentContextFormValueDto.orderNumber =
                orderDto.orderNumber;
              orderDto.charges.forEach((chargeDto) => {
                if (chargeDto.chargeType === ChargeType.IncomeCharge) {
                  chargeDto.income = chargeDto.amount;
                } else {
                  chargeDto.expense = chargeDto.amount;
                }
              });
              parcelShipmentContextFormValueDto.charges = orderDto.charges;
              parcelShipmentContextFormValueDto.trackingEvents =
                orderDto.trackingEvents;

              parcelShipmentContextFormValueDto.billToContactId =
                orderDto.billToContactId;
              parcelShipmentContextFormValueDto.billToContactName =
                orderDto.billToContactName;
              parcelShipmentContextFormValueDto.billToContactType =
                orderDto.billToContactType;
              parcelShipmentContextFormValueDto.carrierContactId =
                orderDto.carriers[0]?.contactId;
              parcelShipmentContextFormValueDto.carrierContactName =
                orderDto.carriers[0]?.name;
              parcelShipmentContextFormValueDto.commodities =
                orderDto.commodities;
              parcelShipmentContextFormValueDto.commodities.map((commodity) => {
                commodity.commodityStatus = commodity.commodityStatus;
              });
              parcelShipmentContextFormValueDto.weighTotal =
                orderDto.weighTotal;
              parcelShipmentContextFormValueDto.volumeTotal =
                orderDto.volumeTotal;
              parcelShipmentContextFormValueDto.totalPcsCrt =
                orderDto.totalPcsCrt;
              parcelShipmentContextFormValueDto.salespersonContactName =
                orderDto.salespersonContactName;
              parcelShipmentContextFormValueDto.salespersonContactId =
                orderDto.salespersonContactId;
              parcelShipmentContextFormValueDto.organizationId =
                orderDto.organizationId;

              parcelShipmentContextFormValueDto.links = orderDto.links;
              parcelShipmentContextFormValueDto.lastModifiedBy =
                orderDto.lastModifiedBy;
              parcelShipmentContextFormValueDto.lastModified =
                orderDto.lastModified;
              if (orderDto.employeeContactName && orderDto.employeeContactId) {
                parcelShipmentContextFormValueDto.employeeContactName =
                  orderDto.employeeContactName;
                parcelShipmentContextFormValueDto.employeeContactId =
                  orderDto.employeeContactId;
              }
              parcelShipmentContextFormValueDto.divisionName =
                orderDto.divisionName;
              parcelShipmentContextFormValueDto.divisionId =
                orderDto.divisionId;
              parcelShipmentContextFormValueDto.equipmentTypeId =
                orderDto.equipmentTypeId;
              parcelShipmentContextFormValueDto.equipmentTypeName =
                orderDto.equipmentTypeName;
              parcelShipmentContextFormValueDto.createdBy = orderDto.createdBy;
              parcelShipmentContextFormValueDto.created = orderDto.created;
              parcelShipmentContextFormValueDto.carriers = orderDto.carriers;
              parcelShipmentContextFormValueDto.customValues =
                orderDto.customValues;
              parcelShipmentContextFormValueDto.trackingNumber =
                orderDto.trackingNumber;

              parcelShipmentContextFormValueDto.modeOfTransportationId =
                orderDto.customValues['modeOfTransportationId'];
              parcelShipmentContextFormValueDto.modeOfTransportationDescription =
                orderDto.customValues['modeOfTransportationDescription'];
              parcelShipmentContextFormValueDto.paymentTermId =
                orderDto.customValues['paymentTermId'];
              parcelShipmentContextFormValueDto.paymentTermDescription =
                orderDto.customValues['paymentTermDescription'];
              parcelShipmentContextFormValueDto.expDate =
                orderDto.customValues['expDate'];
              parcelShipmentContextFormValueDto.problemReason =
                orderDto.customValues['problemReason'];

              parcelShipmentContextFormValueDto.orderEntities =
                orderDto.orderEntities;

              parcelShipmentContextFormValueDto.orderDocuments =
                orderDto?.orderDocuments?.length > 0
                  ? [
                      orderDto.orderDocuments.find(
                        (x) => x?.lastGeneratedFile != null,
                      ),
                    ]
                  : [];

              parseOrderEntities(parcelShipmentContextFormValueDto);

              setInitialValues({ ...parcelShipmentContextFormValueDto });
              return { ...parcelShipmentContextFormValueDto };
            },
          );

          orderDto.billToContactId
            ? getPaidAs(orderDto.billToContactId).then((paidAs) => {
                setApplyToContactPaidAs(paidAs);
              })
            : setApplyToContactPaidAs(ChargeDefaultValues.paidAs);
          orderDto.carriers[0]?.contactId
            ? setApplyToCarrierPaidAs(orderDto.carriers[0].paidAs)
            : setApplyToCarrierPaidAs(ChargeDefaultValues.paidAs);
          setCarrier(orderDto?.carriers as ContactDto);
          onParcelShipmentLoaded(orderDto);
          setCommodities(orderDto.commodities);
          setFakeCommodityIndex(
            (orderDto.commodities[orderDto.commodities.length - 1]
              ?.commodityId +
              1) |
              0,
          );
          setCharges(orderDto.charges);
          countProfitValue(orderDto.charges);
          setTrackingEvents(orderDto.trackingEvents);
          setIsLoading(false);
        },
        () => {},
      );
    } else {
      throw new Error('Order keys were not provided');
    }
  }, [orderId]);

  useEffect(() => {
    if (isLoading === false)
      setParcelShipmentContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.commodities = commodities;
        return orderEditFormDto;
      });
  }, [commodities]);

  useEffect(() => {
    if (isLoading === false) {
      setParcelShipmentContextFormValue((orderDto) => {
        if (!orderDto) {
          orderDto = getInitialState();
        }
        orderDto.charges = charges;
        return { ...orderDto };
      });
      countProfitValue(charges);
    }
  }, [charges]);

  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  useEffect(() => {
    setIsFieldsDisabled(
      !parcelShipmentContextFormValue?.billToContactId || false,
    );
  }, [parcelShipmentContextFormValue?.billToContactId]);

  useEffect(() => {
    if (isLoading === false)
      setParcelShipmentContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.trackingEvents = trackingEvents;
        return orderEditFormDto;
      });
  }, [trackingEvents]);

  useEffect(() => {
    const customFields = [...orderCustomFields, ...systemCustomFields];
    let row = 1,
      pos = 1;
    customFields.forEach((customField) => {
      customField.fieldConfig = {};
      customField.fieldConfig['col'] = 3;
      customField.fieldConfig['row'] = row;
      customField.fieldConfig['pos'] = pos;
      customField.fieldConfig['filter'] = 'general';
      pos = pos + 1;
      if (pos > 4) {
        row = row + 1;
        pos = 1;
      }
    });
    setGeneralCustomFieldRows(row);
  }, [orderCustomFields, systemCustomFields]);

  const getWorkflowActions = useCallback(
    (triggers: WorkflowTriggerDtoListResult = {}) => {
      const workflowActions: IAction[] = [];
      for (const trigger of triggers.items) {
        const actionItem = getAction(
          EXECUTE_WORKFLOWTRIGGER_LINK_KEY,
          trigger.workflowName,
          <AiFillCode />,
          async () => {
            await showDialog({
              dialog: WorkflowTriggerDialog,
              props: {
                workflowId: trigger?.workflowId,
                title: trigger?.workflowName || 'Execute Workflow',
                defaultVariables: {
                  order: parcelShipmentContextFormValue,
                },
              },
            });
          },
          trigger?.links,
        );
        if (actionItem) workflowActions.push(actionItem);
      }
      return workflowActions;
    },
    [parcelShipmentContextFormValue],
  );

  const loadWorkflowTriggers = useCallback(async () => {
    const triggers = await getWorkflowTriggersFx({
      triggerType: 'Manual',
      organizationId: parcelShipmentContextFormValue?.organizationId,
      entityName: 'ParcelShipment',
    });

    setActions(getWorkflowActions(triggers));
  }, [parcelShipmentContextFormValue]);

  useEffect(() => {
    loadWorkflowTriggers();
  }, [parcelShipmentContextFormValue]);

  const goToDetailsCommodity = (commodityParams: GetCommodityParams) => {
    const cloneCommodity = Object.assign({}, commodityParams.commodity);
    if (commodityParams.commodity) {
      showDialog({
        dialog: CommodityDialog,
        props: {
          title: 'Update Commodity',
          commodity: commodityParams?.commodity,
          commodityId: commodityParams?.commodity?.commodityId,
          className: 'commodity-modal',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          isEditMode: true,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: CommodityDto[] = commodities.map((obj) => {
              const compareCommodityResult =
                obj.commodityId === commodityParams?.commodity.commodityId;
              if (compareCommodityResult) obj = result;
              return obj;
            });
            setCommodities(
              itemsResult.sort((a, b) => a.commodityId - b.commodityId),
            );
          } else {
            setCommodities((oldCommodities) => {
              const index = oldCommodities.findIndex(
                (commodity) =>
                  commodity.commodityId === cloneCommodity.commodityId,
              );
              oldCommodities[index] = cloneCommodity;
              return oldCommodities;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToDetailsCommoditySelector = () => {
    showDialog({
      dialog: CargoFragmentDialog,
      props: {
        title: 'Select Commodity',
        className: 'commodity-modal',
        selectedCommodities: commodities,
        setSelectedCommodities: setCommodities,
      },
    }).then(
      (result: CommodityDto[] | null) => {
        setOffset(0);
        if (result !== null) {
          setCommodities(result);
        } else {
          forceUpdate();
        }
      },
      () => {},
    );
  };

  const goToDetailsCharge = (chargeParams: GetChargeParams) => {
    const cloneCharge = Object.assign({}, chargeParams.charge);
    if (chargeParams.charge) {
      showDialog({
        dialog: ChargeDialog,
        props: {
          title: 'Update Charge',
          charge: chargeParams?.charge,
          chargeId: chargeParams?.charge?.chargeId,
          chargeType: chargeParams?.charge?.chargeType,
          className: 'charge-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          charges: charges,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: ChargeDto[] = charges.map((obj) => {
              const compareChargeResult =
                obj.chargeId === chargeParams?.charge.chargeId;
              if (compareChargeResult) obj = result;
              return obj;
            });
            const insuranceQuantity = itemsResult.find(
              (x) => x.description === 'Shipping',
            )?.amount;
            const itemToUpdate = itemsResult.find(
              (x) => x.description === 'Insurance',
            );
            if (itemToUpdate) {
              itemToUpdate.quantity = insuranceQuantity;
            }
            setCharges(itemsResult.sort((a, b) => a.chargeId - b.chargeId));
          } else {
            setCharges((oldCharges) => {
              const index = oldCharges.findIndex(
                (charge) => charge.chargeId === cloneCharge.chargeId,
              );
              oldCharges[index] = cloneCharge;
              return oldCharges;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToInvoiceDetails = (
    invoiceParams: GetAccountingTransactionParams,
  ) => {
    showDialog({
      dialog: InvoiceDialog,
      props: {
        title: 'Update Invoice',
        className: 'accounting-transaction-modal',
        accountingTransactionId: invoiceParams.accountingTransactionId,
      },
    }).then(
      (result) => {},
      () => {},
    );
  };

  const goToDetailsTrackingEvent = (
    trackingEventParams: GetTrackingEventParams,
  ) => {
    const cloneEvent = Object.assign({}, trackingEventParams.trackingEvent);
    if (trackingEventParams.trackingEvent) {
      showDialog({
        dialog: TrackingEventDialog,
        props: {
          title: 'Update Event',
          trackingEvent: trackingEventParams?.trackingEvent,
          trackingEventId: trackingEventParams?.trackingEvent?.trackingEventId,
          className: 'trackingEvent-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: TrackingEventDto[] = trackingEvents.map(
              (obj) => {
                const compareTrackingEventResult =
                  obj === trackingEventParams?.trackingEvent;
                if (compareTrackingEventResult) obj = result;
                return obj;
              },
            );
            setTrackingEvents(
              itemsResult.sort((a, b) => a.trackingEventId - b.trackingEventId),
            );
          } else {
            setTrackingEvents((oldTrackingEvents) => {
              const index = oldTrackingEvents.findIndex(
                (trackingEvent) =>
                  trackingEvent.trackingEventId === cloneEvent.trackingEventId,
              );
              oldTrackingEvents[index] = cloneEvent;
              return oldTrackingEvents;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const countProfitValue = (charges: ChargeDto[]) => {
    const { defaultCurrency } = currencyStore?.getState();
    setDefaultCurrency(defaultCurrency);
    setProfitValues((profitValue) => {
      const initialZero = getFormattedPrice(0, defaultCurrency?.decimalPlaces);
      profitValue.income = initialZero;
      profitValue.expense = initialZero;
      profitValue.profit = initialZero;
      charges.forEach((item) => {
        if (item.isDeleted) return;
        if (item.chargeType === ChargeType.IncomeCharge)
          profitValue.income = (
            Number(profitValue.income) + item.amount
          ).toString();
        else
          profitValue.expense = (
            Number(profitValue.expense) + item.amount
          ).toString();
      });
      profitValue.profit = getFormattedPrice(
        Number(profitValue.income) - Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.income = getFormattedPrice(
        Number(profitValue.income),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.expense = getFormattedPrice(
        Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      return { ...profitValue };
    });
  };

  const getCommodities = (): CommodityDto[] => {
    return commodities?.sort((a, b) => a.commodityId - b.commodityId);
  };

  const getCharges = (): ChargeDto[] => {
    return charges?.sort((a, b) => a.chargeId - b.chargeId);
  };

  const getTrackingEvents = (): TrackingEventDto[] => {
    let events = trackingEvents;
    events?.forEach((event) => {
      if (typeof event.eventDate === 'object') {
        event.eventDate = event.eventDate.toISOString();
      }
    });
    setTrackingEvents(events);
    return events?.sort(
      (a, b) =>
        new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
    );
  };

  const getTotalPcsCrt = () => {
    let sumPieces: number = 0;
    commodities?.forEach((commodity) => {
      sumPieces += Number(commodity.pieces);
    });
    return sumPieces;
  };

  const getWeightTotal = () => {
    let weightTotal = 0;
    commodities?.forEach((commodity) => {
      weightTotal += commodity.weightTotal;
    });
    return weightTotal;
  };

  const getVolumeTotal = () => {
    let volumeTotal = 0;
    commodities?.forEach((commodity) => {
      volumeTotal += commodity?.volumeTotal;
    });
    return volumeTotal;
  };

  const onChargeCreated = (charge: ChargeDto) => {
    if (!charges) {
      const initialCharge: ChargeDto[] = [];
      setCharges(initialCharge);
    }
    setCharges((chargesDto) => [...chargesDto, charge]);
  };

  const onTrackingEventCreated = (trackingEvent: TrackingEventDto) => {
    if (!trackingEvents) {
      const initialTrackingEvent: TrackingEventDto[] = [];
      setTrackingEvents(initialTrackingEvent);
    }
    setTrackingEvents((trackingEventsDto) => [
      ...trackingEventsDto,
      trackingEvent,
    ]);
  };

  //submit form
  const filterOrderEntities = (data: OrderEditFormDto) => {
    if (data.orderEntityCarriers.length > 0) {
      data.orderEntityCarriers = data.orderEntityCarriers.filter((item) => {
        return item?.contactId != null;
      });
    }
    if (data.orderEntityCarriers.length === 0) {
      data.orderEntityCarriers.push({
        ...getInitialStateOrderEntities(
          ContactType.Carrier,
          EntityTypes.Carrier,
        ),
      });
    }

    if (data.orderEntityPickups.length > 0) {
      data.orderEntityPickups = data.orderEntityPickups.filter((item) => {
        return item?.contactId != null;
      });
    }
    if (data.orderEntityPickups.length === 0) {
      data.orderEntityPickups.push({
        ...EntityPickupsInitial(),
      });
    }

    if (data.orderEntityDeliveries.length > 0) {
      data.orderEntityDeliveries = data.orderEntityDeliveries.filter((item) => {
        return item?.contactId != null;
      });
    }
    if (data.orderEntityDeliveries.length === 0) {
      data.orderEntityDeliveries.push({
        ...EntityDeliveriesInitial(),
      });
    }
  };

  const onSubmit = async (data) => {
    data.commodities = commodities;
    data.charges = charges;
    data.trackingEvents = trackingEvents;
    data.commodities = data.commodities.map((item) => {
      const customValues: CommodityCustomValues = {
        ...item?.customValues,
        purposeOfCommodity: item?.customValues?.purposeOfCommodity,
        dangerousItems:
          typeof item?.customValues?.dangerousItems === 'string'
            ? item?.customValues?.dangerousItems
            : (item?.customValues
                ?.dangerousItems as DangerousItemsValues[])?.join(','),
      };
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        warehouseLocationId: item.warehouseLocation?.warehouseLocationId,
        customValues,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    data.customValues = parcelShipmentContextFormValue?.customValues;

    data.customValues = {
      ...data.customValues,
      problemReason: data.problemReason,
    };

    filterOrderEntities(parcelShipmentContextFormValue);

    data.orderEntities = parcelShipmentContextFormValue.orderEntities.filter(
      (entity) => {
        entity.entityType !== EntityTypes.Carrier &&
          entity.entityType !== EntityTypes.Shipper &&
          entity.entityType !== EntityTypes.Consignee;
      },
    );

    data.orderEntities.push(
      ...parcelShipmentContextFormValue?.orderEntityCarriers,
    );
    data.orderEntities.push(
      ...parcelShipmentContextFormValue?.orderEntityDeliveries,
    );
    data.orderEntities.push(
      ...parcelShipmentContextFormValue?.orderEntityPickups,
    );

    data.orderType = OrderTypes.ParcelShipment;

    const params: CalculateChargesAmountParams = {
      commodities: data.commodities,
      charges: data.charges,
    };

    setIsSending(true);
    if (isCreateMode) {
      try {
        //const chargesAmountResponse = await calculateChargesAmount(params);
        //data.charges = chargesAmountResponse;
        const createdOrderResponse = await createOrder(data);
        onParcelShipmentCreated(createdOrderResponse);
      } catch (error) {
        addMessage(error);
      } finally {
        setIsSending(false);
      }
    } else {
      try {
        //const chargesAmountResponse = await calculateChargesAmount(params);
        //data.charges = chargesAmountResponse;
        await updateOrder(data);
        onParcelShipmentUpdated(data);
      } catch (error) {
        addMessage(error);
      } finally {
        setIsSending(false);
      }
    }
  };

  const onSubmitCommodityDto = (
    data: CommodityDto,
    { resetForm, setFieldValue },
  ) => {
    const { currentOrganization } = organizationsStore.getState();
    data.commodityId = fakeCommodityIndex;
    data.organizationId = currentOrganization.organizationId;
    data.commodityStatus = null;
    data.dimensionsUnit = DimensionsUnit.In;
    data.weightUnit = WeightUnit.Lb;
    data.volumeUnit = VolumeUnit.Vkg;
    data.weightTotal = data.pieces * data.weight;
    data.volumePiece = data.length * data.height * data.width;
    data.volumeTotal = data.pieces * data.volumePiece;
    setCommodities((commoditiesDto) => [...commoditiesDto, data]);
    setPackageType((packageTypeDto) => {
      packageTypeDto = {
        name: null,
        packageTypeId: null,
      };
      return { ...packageTypeDto };
    });
    setcommodityType({
      commodityTypeId: 0,
      code: null,
    });
    resetForm();
    setFieldValue('description', '');
    setFieldValue('pieces', '1');
    setFieldValue('length', '');
    setFieldValue('width', '');
    setFieldValue('height', '');
    setFieldValue('weight', '');
    setFieldValue('packageTypeId', '');
    setFieldValue('packageTypeSelect', '');
    setFakeCommodityIndex(fakeCommodityIndex + 1);
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const onSaveParcelShipment = (data) => {
    data.commodities = commodities;
    data.charges = charges;
    data.trackingEvents = trackingEvents;
    data.commodities = data.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    data.customValues = parcelShipmentContextFormValue?.customValues;

    filterOrderEntities(parcelShipmentContextFormValue);

    data.orderEntities = parcelShipmentContextFormValue.orderEntities.filter(
      (entity) => {
        entity.entityType !== EntityTypes.Carrier &&
          entity.entityType !== EntityTypes.Shipper &&
          entity.entityType !== EntityTypes.Consignee;
      },
    );

    data.orderEntities.push(
      ...parcelShipmentContextFormValue?.orderEntityCarriers,
    );
    data.orderEntities.push(
      ...parcelShipmentContextFormValue?.orderEntityDeliveries,
    );
    data.orderEntities.push(
      ...parcelShipmentContextFormValue?.orderEntityPickups,
    );

    data.orderType = OrderTypes.ParcelShipment;

    return data;
  };

  //Carrier form handlers
  const checkInitialState = (
    orderDto: OrderEditFormDto,
    carrierContactIndex?: number,
  ): void => {
    if (!orderDto) {
      orderDto = getInitialState();
    }
    if (!orderDto.orderEntityCarriers[carrierContactIndex]) {
      orderDto.orderEntityCarriers[
        carrierContactIndex
      ] = getInitialStateOrderEntities(
        ContactType.Carrier,
        EntityTypes.Carrier,
      );
    }
  };

  const setCarrierContact = async (
    contactId: number,
    name: string,
    contactType: ContactType,
    carrierContactIndex?: number,
  ) => {
    setParcelShipmentContextFormValue((orderDto) => {
      checkInitialState(orderDto, carrierContactIndex);
      orderDto.orderEntityCarriers[carrierContactIndex].contactId = Number(
        contactId,
      );
      orderDto.orderEntityCarriers[carrierContactIndex].contactName = name;
      orderDto.orderEntityCarriers[
        carrierContactIndex
      ].contactType = contactType;
      return { ...orderDto };
    });
  };

  const getCarrierContact = (index: number): any => {
    return {
      contactId:
        parcelShipmentContextFormValue?.orderEntityCarriers[index]?.contactId,
      name:
        parcelShipmentContextFormValue?.orderEntityCarriers[index]?.contactName,
      contactType:
        parcelShipmentContextFormValue?.orderEntityCarriers[index]?.contactType,
    };
  };

  const createNewItemOrderEntityCarrier = () => {
    setParcelShipmentContextFormValue((parcelShipmentContextFormValueDto) => {
      if (!parcelShipmentContextFormValueDto) {
        parcelShipmentContextFormValueDto = getInitialState();
      }
      if (!parcelShipmentContextFormValueDto.orderEntityCarriers) {
        parcelShipmentContextFormValueDto.orderEntityCarriers = [];
      }
      parcelShipmentContextFormValueDto.orderEntityCarriers.push({
        ...getInitialStateOrderEntities(
          ContactType.Carrier,
          EntityTypes.Carrier,
        ),
      });
      return { ...parcelShipmentContextFormValueDto };
    });
  };

  const deleteItemOrderCarrierItem = (
    indexItemToDelete?: number,
    context?: any,
  ) => {
    setParcelShipmentContextFormValue((parcelShipmentContextFormValueDto) => {
      parcelShipmentContextFormValueDto.orderEntityCarriers?.splice(
        indexItemToDelete,
        1,
      );
      return { ...parcelShipmentContextFormValueDto };
    });
  };

  //carrier form
  const getCarriers = (context: any) => {
    if (
      parcelShipmentContextFormValue?.orderEntityCarriers != null &&
      parcelShipmentContextFormValue?.orderEntityCarriers !== undefined
    ) {
      return parcelShipmentContextFormValue?.orderEntityCarriers?.map(
        (item, index) => (
          <CarrierForm
            index={index}
            allowDelete={
              parcelShipmentContextFormValue?.orderEntityCarriers?.length > 1
            }
            setCarrierFilter={(filter: string): void => {}}
            carrierFilter={''}
            carrierContact={getCarrierContact(index)}
            setCarrierContact={(contactId, name, contactType) => {
              setCarrierContact(contactId, name, contactType, index);
            }}
            onCarrierDeleted={() => deleteItemOrderCarrierItem(index, context)}
            customValues={item.customValues}
            customFields={orderEntityCustomFields}
          />
        ),
      );
    }
  };

  const createNewItemOrderPickup = () => {
    setParcelShipmentContextFormValue((orderContextFormValueDto) => {
      if (!orderContextFormValueDto) {
        orderContextFormValueDto = getInitialState();
      }
      if (!orderContextFormValueDto.orderEntityPickups) {
        orderContextFormValueDto.orderEntityPickups = [];
      }
      orderContextFormValueDto.orderEntityPickups.push({
        ...EntityPickupsInitial(),
      });
      return { ...orderContextFormValueDto };
    });
  };

  const deleteItemOrderPickup = (indexItemToDelete?: number, context?: any) => {
    setParcelShipmentContextFormValue((orderContextFormValueDto) => {
      orderContextFormValueDto.orderEntityPickups?.splice(indexItemToDelete, 1);
      return { ...orderContextFormValueDto };
    });
  };

  const setShipperContactAddress = (
    contactAddressId: number,
    fullAddress: string,
    shipperContactAddressFilterIndex?: number,
  ) => {
    setParcelShipmentContextFormValue((orderDto) => {
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDto.orderEntityPickups) {
        orderDto.orderEntityPickups = [];
      }
      if (!orderDto.orderEntityPickups[shipperContactAddressFilterIndex]) {
        orderDto.orderEntityPickups[
          shipperContactAddressFilterIndex
        ] = EntityPickupsInitial();
      }
      orderDto.orderEntityPickups[
        shipperContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderDto.orderEntityPickups[
        shipperContactAddressFilterIndex
      ].contactAddressName = fullAddress;
      return { ...orderDto };
    });
  };

  const setShipperContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    shipperContactIndex?: number,
  ) => {
    setParcelShipmentContextFormValue((orderDto) => {
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDto.orderEntityPickups[shipperContactIndex]) {
        orderDto.orderEntityPickups[
          shipperContactIndex
        ] = EntityPickupsInitial();
      }
      orderDto.orderEntityPickups[shipperContactIndex].contactId = Number(
        contactId,
      );
      orderDto.orderEntityPickups[shipperContactIndex].contactName = name;
      orderDto.orderEntityPickups[
        shipperContactIndex
      ].contactType = contactType;
      return { ...orderDto };
    });
  };

  const createNewItemOrderDelivery = () => {
    setParcelShipmentContextFormValue((orderContextFormValueDto) => {
      if (!orderContextFormValueDto) {
        orderContextFormValueDto = getInitialState();
      }
      if (!orderContextFormValueDto.orderEntityDeliveries) {
        orderContextFormValueDto.orderEntityDeliveries = [];
      }
      orderContextFormValueDto.orderEntityDeliveries.push({
        ...EntityDeliveriesInitial(),
      });
      return { ...orderContextFormValueDto };
    });
  };

  const setDeliveryContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    consigneeContactIndex?: number,
  ) => {
    setParcelShipmentContextFormValue((orderDto) => {
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDto.orderEntityDeliveries[consigneeContactIndex]) {
        orderDto.orderEntityDeliveries[
          consigneeContactIndex
        ] = EntityDeliveriesInitial();
      }
      orderDto.orderEntityDeliveries[consigneeContactIndex].contactId = Number(
        contactId,
      );
      orderDto.orderEntityDeliveries[consigneeContactIndex].contactName = name;
      orderDto.orderEntityDeliveries[
        consigneeContactIndex
      ].contactType = contactType;
      return { ...orderDto };
    });
  };
  const setDeliveryFilter = (data?: any, setDeliveryFilterIndex?: number) => {
    setParcelShipmentContextFormValue((orderDto) => {
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDto.orderEntityDeliveries) {
        orderDto.orderEntityDeliveries = [];
      }
      if (!orderDto.orderEntityDeliveries[setDeliveryFilterIndex]) {
        orderDto.orderEntityDeliveries[
          setDeliveryFilterIndex
        ] = EntityDeliveriesInitial();
      }
      // orderDto.orderEntityDeliveries[setDeliveryFilterIndex].consigneeFilter = data;
      return { ...orderDto };
    });
  };
  const setDeliveryContactAddress = (
    contactAddressId: number,
    fullAddress: string,
    deliveryContactAddressFilterIndex?: number,
  ) => {
    setParcelShipmentContextFormValue((orderDto) => {
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDto.orderEntityDeliveries) {
        orderDto.orderEntityDeliveries = [];
      }
      if (!orderDto.orderEntityDeliveries[deliveryContactAddressFilterIndex]) {
        orderDto.orderEntityDeliveries[
          deliveryContactAddressFilterIndex
        ] = EntityDeliveriesInitial();
      }
      orderDto.orderEntityDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderDto.orderEntityDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressName = fullAddress;
      return { ...orderDto };
    });
  };
  const deleteItemOrderDelivery = (
    indexItemToDelete?: number,
    context?: any,
  ) => {
    setParcelShipmentContextFormValue((orderContextFormValueDto) => {
      orderContextFormValueDto?.orderEntityDeliveries?.splice(
        indexItemToDelete,
        1,
      );
      return { ...orderContextFormValueDto };
    });
  };

  const getItemsOrderPickup = (context?: any) => {
    if (
      parcelShipmentContextFormValue?.orderEntityPickups != null &&
      parcelShipmentContextFormValue?.orderEntityPickups !== undefined
    ) {
      return parcelShipmentContextFormValue?.orderEntityPickups?.map(
        (item, index) => (
          <OrderEntityForm
            index={index}
            entityName={'orderEntityPickups'}
            header="Shipper"
            allowDelete={
              parcelShipmentContextFormValue?.orderEntityPickups?.length > 1
            }
            entityTypeFilter={CustomFieldEntityType.Contact}
            orderEntityContact={{
              contactId:
                parcelShipmentContextFormValue?.orderEntityPickups[index]
                  ?.contactId,
              name:
                parcelShipmentContextFormValue?.orderEntityPickups[index]
                  ?.contactName,
              contactType:
                parcelShipmentContextFormValue?.orderEntityPickups[index]
                  ?.contactType,
              contactAddressId:
                parcelShipmentContextFormValue?.orderEntityPickups[index]
                  ?.contactAddressId,
              fullAddress:
                parcelShipmentContextFormValue?.orderEntityPickups[index]
                  ?.contactAddressName,
            }}
            onOrderEntityDeleted={() => deleteItemOrderPickup(index, context)}
            setOrderEntityContact={(contactId, name, contactType) =>
              setShipperContact(contactId, name, contactType, index)
            }
            setOrderEntityContactAddress={(contactAddressId, fullAddress) =>
              setShipperContactAddress(contactAddressId, fullAddress, index)
            }
            setOrderEntityFilter={() => {}}
            customValues={item.customValues}
            customFields={orderEntityCustomFields}
          />
        ),
      );
    } else return '';
  };

  const getItemsOrderDelivery = (context?: any) => {
    if (
      parcelShipmentContextFormValue?.orderEntityDeliveries != null &&
      parcelShipmentContextFormValue?.orderEntityDeliveries !== undefined
    ) {
      return parcelShipmentContextFormValue?.orderEntityDeliveries?.map(
        (item, index) => (
          <OrderEntityForm
            index={index}
            entityName={'orderEntityDeliveries'}
            header="Consignee"
            allowDelete={
              parcelShipmentContextFormValue?.orderEntityDeliveries?.length > 1
            }
            entityTypeFilter={CustomFieldEntityType.Contact}
            orderEntityContact={{
              contactId:
                parcelShipmentContextFormValue?.orderEntityDeliveries[index]
                  ?.contactId,
              name:
                parcelShipmentContextFormValue?.orderEntityDeliveries[index]
                  ?.contactName,
              contactType:
                parcelShipmentContextFormValue?.orderEntityDeliveries[index]
                  ?.contactType,
              contactAddressId:
                parcelShipmentContextFormValue?.orderEntityDeliveries[index]
                  ?.contactAddressId,
              fullAddress:
                parcelShipmentContextFormValue?.orderEntityDeliveries[index]
                  ?.contactAddressName,
            }}
            onOrderEntityDeleted={() => deleteItemOrderDelivery(index, context)}
            setOrderEntityContact={(contactId, name, contactType) =>
              setDeliveryContact(contactId, name, contactType, index)
            }
            setOrderEntityContactAddress={(contactAddressId, fullAddress) =>
              setDeliveryContactAddress(contactAddressId, fullAddress, index)
            }
            setOrderEntityFilter={() => {}}
            customValues={item.customValues}
            customFields={orderEntityCustomFields}
          />
        ),
      );
    } else return '';
  };

  //TODO: refactor using workflows if needed

  // const isNoParcelShipmentTemplate = (): boolean => {
  //   return parcelShipmentDocumentTemplatesCount === 0;
  // };

  // const getParcelShipmentDocument = () => {
  //   const parcelShipmentData: OrderDto = parcelShipmentContextFormValue;
  //   parcelShipmentData.orderType = OrderTypes.ParcelShipment;
  //   parcelShipmentData.commodities = parcelShipmentData.commodities.map(
  //     (item) => {
  //       const commodityDto: CommodityDto = {
  //         note: item.note,
  //         quantity: item.quantity,
  //         unit: item.unit,
  //         unitaryValue: item.unitaryValue,
  //         commodityTypeId: item.commodityTypeId,
  //         commodityTypeCode: item.commodityTypeCode,
  //         height: item.height,
  //         width: item.width,
  //         length: item.length,
  //         weight: item.weight,
  //         pieces: item.pieces,
  //         packageTypeName: item.packageTypeName,
  //         commodityId: item.commodityId,
  //         organizationId: item.organizationId,
  //         commodityStatus: getEnumKeyByValue(
  //           item.commodityStatus,
  //           CommodityStatuses,
  //         ),
  //         description: item.description,
  //         dimensionsUnit: item.dimensionsUnit,
  //         links: item.links,
  //         packageTypeId: item.packageTypeId,
  //         volumePiece: item.volumePiece,
  //         volumeTotal: item.volumeTotal,
  //         valueTotal: item.valueTotal,
  //         volumeUnit: item.volumeUnit,
  //         weightByTotal: item.weightByTotal,
  //         weightTotal: item.pieces * item.weight,
  //         weightUnit: item.weightUnit,
  //       };
  //       return commodityDto;
  //     },
  //   );

  //   filterOrderEntities(parcelShipmentContextFormValue);

  //   updateOrder(parcelShipmentData).then(
  //     () => {
  //       window.open(
  //         getRenderedDocumentLink(
  //           DocumentTemplateType.QuoteConfirmationDocument,
  //           {
  //             quoteId: parcelShipmentData.orderId,
  //           },
  //         ),
  //         '_blank',
  //       );
  //     },
  //     () => {},
  //   );
  // };

  // const sendParcelShipmentEmail = () => {
  //   const parcelShipmentData: OrderDto = parcelShipmentContextFormValue;
  //   parcelShipmentData.orderType = OrderTypes.ParcelShipment;
  //   parcelShipmentData.commodities = parcelShipmentData.commodities.map(
  //     (item) => {
  //       const commodityDto: CommodityDto = {
  //         note: item.note,
  //         quantity: item.quantity,
  //         unit: item.unit,
  //         unitaryValue: item.unitaryValue,
  //         height: item.height,
  //         width: item.width,
  //         length: item.length,
  //         weight: item.weight,
  //         pieces: item.pieces,
  //         packageTypeName: item.packageTypeName,
  //         commodityId: item.commodityId,
  //         organizationId: item.organizationId,
  //         commodityStatus: getEnumKeyByValue(
  //           item.commodityStatus,
  //           CommodityStatuses,
  //         ),
  //         description: item.description,
  //         dimensionsUnit: item.dimensionsUnit,
  //         links: item.links,
  //         packageTypeId: item.packageTypeId,
  //         volumePiece: item.volumePiece,
  //         volumeTotal: item.volumeTotal,
  //         valueTotal: item.valueTotal,
  //         volumeUnit: item.volumeUnit,
  //         weightByTotal: item.weightByTotal,
  //         weightTotal: item.pieces * item.weight,
  //         weightUnit: item.weightUnit,
  //         commodityTypeId: item.commodityTypeId,
  //         commodityTypeCode: item.commodityTypeCode,
  //       };
  //       return commodityDto;
  //     },
  //   );

  //   filterOrderEntities(parcelShipmentContextFormValue);

  //   updateOrder(parcelShipmentData).then(
  //     () => {
  //       showDialog({
  //         dialog: SendEmailDialog,
  //         props: {
  //           title: 'Send ParcelShipment Confirmation',
  //           className: 'send-email-modal',
  //           selectedRecipientId: 0,
  //           recipientIds: [],
  //           metadata: {
  //             parcelShipmentId: parcelShipmentData.orderId,
  //           },
  //           emailTemplate: getEnumKeyByValue(
  //             DocumentTemplateType.QuoteConfirmationEmail,
  //             DocumentTemplateType,
  //           ),
  //           documentTypes: getEnumKeyByValue(
  //             [DocumentTemplateType.QuoteConfirmationDocument],
  //             DocumentTemplateType,
  //           ),
  //           onEmailSent: () => {},
  //           onEmailTemplateLoaded: () => {},
  //         },
  //       }).then(() => {});
  //     },
  //     () => {},
  //   );
  // };

  // const isRestrictedGetAndSendDocs = (): boolean => {
  //   return currentUser?.isInOrgUserRole;
  // };

  const onParcelStatusChange = (
    newValueOrderStatus: OrderStatusDto,
    context: FormikProps<FormikValues>,
  ) => {
    let newOrderStatusId = newValueOrderStatus?.orderStatusId;
    if (newValueOrderStatus?.requireConfirmation === true) {
      showDialog({
        dialog: Prompt,
        props: {
          title: `Cancel ${parcelShipmentContextFormValue?.orderNumber} Order`,
          message: `Please type order number (${parcelShipmentContextFormValue?.orderNumber}) to confirm its cancelling.`,
          className: 'cancel-order-modal',
          orderNumber: parcelShipmentContextFormValue?.orderNumber,
        },
      }).then((result) => {
        if (!result) {
          newOrderStatusId = parcelShipmentContextFormValue?.orderStatusId;
        }
        setParcelShipmentContextFormValue(
          (parcelShipmentContextFormValueDto) => {
            if (!parcelShipmentContextFormValueDto) {
              parcelShipmentContextFormValueDto = getInitialState();
            }
            parcelShipmentContextFormValueDto.orderStatusId = newOrderStatusId;
            context.setFieldValue(
              'orderStatusId',
              parcelShipmentContextFormValueDto?.orderStatusId,
            );
            setInitialValues({ ...parcelShipmentContextFormValueDto });
            return { ...parcelShipmentContextFormValueDto };
          },
        );
      });
    } else {
      setParcelShipmentContextFormValue((parcelShipmentContextFormValueDto) => {
        if (!parcelShipmentContextFormValueDto) {
          parcelShipmentContextFormValueDto = getInitialState();
        }
        parcelShipmentContextFormValueDto.orderStatusId =
          newValueOrderStatus?.orderStatusId;
        context.setFieldValue(
          'orderStatusId',
          newValueOrderStatus?.orderStatusId,
        );
        parcelShipmentContextFormValue.orderStatus = newValueOrderStatus;
        setInitialValues({ ...parcelShipmentContextFormValueDto });
        return { ...parcelShipmentContextFormValueDto };
      });
    }
  };

  return (
    <div className={'purchase-edit-form'}>
      <OrderForm
        id={'ParcelShipmentForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={orderFormRef}
        validationSchema={parcelShipmentSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <div className="d-flex justify-content-between">
                  <span>
                    <Tab data-testid={'general'}>
                      Parcel Shipment Information
                    </Tab>
                    <Tab data-testid={'charges'}>Charges</Tab>
                    <Tab data-cy={'events'}>Events</Tab>
                    <Tab data-testid={'additional'}>Additional</Tab>
                    <Tab data-testid={'attachments'} disabled={isCreateMode}>
                      Attachments
                    </Tab>
                    <Tab data-testid={'orderDocuments'}>Order Documents</Tab>
                  </span>
                  {!isCreateMode && (
                    <ActionsDropdown
                      size="sm"
                      actions={actions}
                      className={'mr-2'}
                    />
                  )}
                </div>
              </TabList>
              <div>
                <TabPanel forceRender={true}>
                  <Panel className="m-3">
                    <h2>Parcel Shipment Information</h2>
                    <div className="row">
                      {isCreateMode ? (
                        <div />
                      ) : (
                        <>
                          <div className="col-2">
                            <OrderForm.OrderNumber label="ParcelShipment Number" />
                          </div>
                        </>
                      )}
                      <div className={`col-2`}>
                        <OrderForm.BillToContactSelect
                          id={'billToContactId'}
                          header={'Customer'}
                          placeholder={'Select Customer'}
                          contactTypes={[
                            ContactType.Customer,
                            ContactType.Vendor,
                            ContactType.ForwardingAgent,
                            ContactType.Contact,
                            ContactType.Lead,
                          ]}
                          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact} OR contactType: ${ContactType.Lead}`}
                          required={true}
                          defaultValue={
                            parcelShipmentContextFormValue &&
                            parcelShipmentContextFormValue.billToContactId !=
                              null &&
                            parcelShipmentContextFormValue.billToContactName !=
                              null &&
                            parcelShipmentContextFormValue.billToContactType !=
                              null
                              ? {
                                  contactId:
                                    parcelShipmentContextFormValue.billToContactId,
                                  name:
                                    parcelShipmentContextFormValue.billToContactName,
                                  contactType:
                                    parcelShipmentContextFormValue.billToContactType,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setParcelShipmentContextFormValue(
                              (parcelShipmentContextFormValueDto) => {
                                if (!parcelShipmentContextFormValueDto) {
                                  parcelShipmentContextFormValueDto = getInitialState();
                                }
                                parcelShipmentContextFormValueDto.billToContactId =
                                  data?.contactId;
                                parcelShipmentContextFormValueDto.billToContactName =
                                  data?.name;
                                parcelShipmentContextFormValueDto.billToContactType =
                                  data?.contactType;
                                return parcelShipmentContextFormValueDto;
                              },
                            );
                            data?.contactId
                              ? setApplyToContactPaidAs(data?.paidAs)
                              : setApplyToContactPaidAs(
                                  ChargeDefaultValues.paidAs,
                                );
                          }}
                          nameId={'billToContactName'}
                          showContactType={true}
                        />
                      </div>
                      <div className={`${'col-2'} employee-contact-input`}>
                        <OrderForm.EmployeeContactSelect
                          id={'employeeContactId'}
                          header={'Employee'}
                          contactTypes={[ContactType.Employee]}
                          selectedFilter={`contactType: ${ContactType.Employee}`}
                          required={false}
                          defaultValue={
                            parcelShipmentContextFormValue &&
                            parcelShipmentContextFormValue.employeeContactId !=
                              null &&
                            parcelShipmentContextFormValue.employeeContactName !=
                              null
                              ? {
                                  contactId:
                                    parcelShipmentContextFormValue.employeeContactId,
                                  name:
                                    parcelShipmentContextFormValue.employeeContactName,
                                  contactType: ContactType.Employee,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setParcelShipmentContextFormValue(
                              (parcelShipmentContextFormValueDto) => {
                                if (!parcelShipmentContextFormValueDto) {
                                  parcelShipmentContextFormValueDto = getInitialState();
                                }
                                parcelShipmentContextFormValueDto.employeeContactId =
                                  data?.contactId;
                                parcelShipmentContextFormValueDto.employeeContactName =
                                  data?.name;
                                return parcelShipmentContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'employeeContactName'}
                        />
                      </div>
                      <div className="col-2 sales-person-contact-input">
                        <OrderForm.SalespersonContactSelect
                          id={'salespersonContactId'}
                          header={'Sales Person'}
                          contactTypes={[ContactType.SalesPerson]}
                          selectedFilter={`contactType: ${ContactType.SalesPerson}`}
                          required={false}
                          defaultValue={
                            parcelShipmentContextFormValue &&
                            parcelShipmentContextFormValue.salespersonContactId !=
                              null &&
                            parcelShipmentContextFormValue.salespersonContactName !=
                              null
                              ? {
                                  contactId:
                                    parcelShipmentContextFormValue.salespersonContactId,
                                  name:
                                    parcelShipmentContextFormValue.salespersonContactName,
                                  contactType: ContactType.SalesPerson,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setParcelShipmentContextFormValue(
                              (parcelShipmentContextFormValueDto) => {
                                if (!parcelShipmentContextFormValueDto) {
                                  parcelShipmentContextFormValueDto = getInitialState();
                                }
                                parcelShipmentContextFormValueDto.salespersonContactId =
                                  data?.contactId;
                                parcelShipmentContextFormValueDto.salespersonContactName =
                                  data?.name;
                                return parcelShipmentContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'salespersonContactName'}
                        />
                      </div>
                      <div className="col-2">
                        <OrderForm.Division
                          id={'divisionId'}
                          header={'Division'}
                          required={true}
                          defaultValue={
                            parcelShipmentContextFormValue &&
                            parcelShipmentContextFormValue.divisionId != null &&
                            parcelShipmentContextFormValue.divisionName != null
                              ? {
                                  divisionId:
                                    parcelShipmentContextFormValue.divisionId,
                                  divisionName:
                                    parcelShipmentContextFormValue.divisionName,
                                }
                              : ''
                          }
                          onChange={(data?: DivisionDto) => {
                            setParcelShipmentContextFormValue(
                              (parcelShipmentContextFormValueDto) => {
                                if (!parcelShipmentContextFormValueDto) {
                                  parcelShipmentContextFormValueDto = getInitialState();
                                }
                                parcelShipmentContextFormValueDto.divisionId =
                                  data?.divisionId;
                                parcelShipmentContextFormValueDto.divisionName =
                                  data?.divisionName;
                                return parcelShipmentContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'divisionName'}
                          defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                          disabled={
                            !(
                              (currentUser?.isInOrgAdminRole ||
                                currentUser?.isInOperationRole) &&
                              VisibleTransactions[
                                currentUser?.visibleTransactions
                              ] === VisibleTransactions.AllTransactions
                            )
                          }
                        />
                      </div>
                      <div className="col-2">
                        <OrderForm.OrderStatus
                          defaultValue={
                            parcelShipmentContextFormValue.orderStatus
                          }
                          onChange={(value) =>
                            onParcelStatusChange(value, context)
                          }
                          isClearable={false}
                          header="Parcel Shipment Status"
                          selectedFilter={`OrderType:${OrderTypes.ParcelShipment}`}
                          defaultValueFilter={`OrderType:${OrderTypes.ParcelShipment}`}
                        />
                      </div>
                      {!isCreateMode && (
                        <div className="col-3">
                          <OrderForm.TrackingNumber
                            id={'trackingNumber'}
                            name={'trackingNumber'}
                            defaultValue={
                              parcelShipmentContextFormValue.trackingNumber
                            }
                          />
                        </div>
                      )}
                      <div className="col-3">
                        <OrderForm.ProblemReason
                          defaultValue={
                            parcelShipmentContextFormValue?.problemReason
                              ? {
                                  label:
                                    parcelShipmentContextFormValue?.problemReason,
                                  value:
                                    parcelShipmentContextFormValue?.problemReason,
                                }
                              : null
                          }
                          name="problemReason"
                          options={getCustomFieldOptionsValues(
                            customFields.find(
                              (x) => x.internalName === 'problemReason',
                            )?.fieldConfig['options'] ?? [],
                          )}
                        />
                      </div>
                    </div>

                    <CustomFieldsLayout
                      inputNamePrefix=""
                      rows={Array(generalCustomFieldRows)
                        .fill({})
                        .map((_, i) => {
                          return { rowNumber: i + 1 };
                        })}
                      filter="general"
                      customFields={[
                        ...orderCustomFields,
                        ...systemCustomFields,
                      ]}
                      defaultValue={parcelShipmentContextFormValue.customValues}
                      onChange={(result) => {
                        setParcelShipmentContextFormValue(
                          (orderContextFormValue) => {
                            orderContextFormValue.customValues = result;
                            return { ...orderContextFormValue };
                          },
                        );
                      }}
                    />

                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Carrier</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderEntityCarrier}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-testid="btn-create-new-carrier"
                      />
                    </div>
                    {getCarriers(context)}
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Shipper</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderPickup}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-testid="btn-create-new-carrier"
                      />
                    </div>
                    {getItemsOrderPickup(context)}
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Consignee</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderDelivery}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-testid="btn-create-new-carrier"
                      />
                    </div>
                    {getItemsOrderDelivery(context)}
                    <hr className="my-4" />

                    <CommodityForm
                      id={'commodityForm'}
                      initialValues={commodity || initialStateCommodity}
                      onSubmit={onSubmitCommodityDto}
                      innerRef={commodityFormRef}
                      validationSchema={commoditySchema}
                      onKeyPress={(event, formikParams) => {
                        if (
                          event.code === 'Enter' &&
                          (currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole)
                        ) {
                          formikParams.submitForm();
                        }
                      }}
                    >
                      <h2>Commodities</h2>
                      <div className="row">
                        <div className={'col-1'}>
                          <CommodityForm.Pieces
                            onKeyDown={validateNumberInput}
                          />
                        </div>
                        <div className="col-2">
                          <CommodityForm.PackageTypeSelect
                            required={false}
                            defaultValue={
                              packageType &&
                              packageType.packageTypeId !== undefined &&
                              packageType.packageTypeId != null &&
                              packageType.name !== undefined &&
                              packageType.name != null
                                ? {
                                    packageTypeId: packageType?.packageTypeId,
                                    name: packageType?.name,
                                  }
                                : ''
                            }
                            onChange={(
                              data?: PackageTypeDto,
                              context?: any,
                            ) => {
                              setPackageType((packageTypeData) => {
                                if (!packageTypeData) {
                                  packageTypeData = {
                                    name: null,
                                    packageTypeId: null,
                                  };
                                }
                                packageTypeData.packageTypeId = data?.packageTypeId?.toString();
                                packageTypeData.name = data?.name;
                                return { ...packageTypeData };
                              });
                              context?.setFieldValue(
                                'packageTypeName',
                                data?.name,
                              );
                            }}
                            nameId={'packageTypeName'}
                          />
                        </div>
                        {isOrgAdminOrOperation ? (
                          <div className="col-3">
                            <CommodityForm.Description />
                          </div>
                        ) : (
                          <div className="col-4">
                            <CommodityForm.Description />
                          </div>
                        )}
                        <div className="col-1">
                          <CommodityForm.Length
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Width
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Height
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Weight
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        {(currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole) && (
                          <div className="col-1 pt-4">
                            <Button
                              form={'commodityForm'}
                              name={'create-commodity'}
                              type="button"
                              onClick={(event) => {
                                if (commodityFormRef.current) {
                                  commodityFormRef.current.submitForm();
                                }
                              }}
                              color="secondary"
                              className="btn-block text-break"
                            >
                              +
                            </Button>
                          </div>
                        )}
                        <div className="col-1 pt-4">
                          <Button
                            form={'commodityForm'}
                            name={'create-commodity'}
                            type="button"
                            color="secondary"
                            onClick={goToDetailsCommoditySelector}
                            className="btn-block text-break"
                          >
                            <FaPaperclip className="font-size-sm" />
                          </Button>
                        </div>
                      </div>
                      <CommoditiesForOrderList
                        className={'mb-4 commodities-for-order-list'}
                        limit={limit}
                        showPagination={false}
                        onPageChanged={(page) => setOffset(page * limit)}
                        offset={offset}
                        items={getCommodities()}
                        goToDetails={goToDetailsCommodity}
                        changeItems={setCommodities}
                        userCanDelete={
                          currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole
                        }
                        orderType={OrderTypes.ParcelShipment}
                      />
                      {getCommodities().length === 0 && (
                        <h4 className="text-center m-5 text-muted">
                          No commodities
                        </h4>
                      )}
                      <div className="row">
                        <div className="col-2">
                          <OrderForm.TotalPcsCrt
                            selectedName={getTotalPcsCrt()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.WeightTotal
                            selectedName={getWeightTotal()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.VolumeTotal
                            selectedName={getVolumeTotal()}
                          />
                        </div>
                        <div className="justify-content-end d-flex col-6">
                          {(currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole) && (
                            <div className="col-6 pt-4">
                              <Button
                                type="submit"
                                form={'ParcelShipmentForm'}
                                color="primary"
                                className="btn-block"
                                disabled={isSending}
                                isSending={isSending}
                              >
                                Save Parcel Shipment
                              </Button>
                            </div>
                          )}
                          <div className="col-6 pt-4">
                            <Button
                              type="button"
                              color="secondary"
                              onClick={onCancel}
                              className="col-12"
                              disabled={isSending}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CommodityForm>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <ChargeForOrderList
                      limit={limit}
                      showPagination={false}
                      showIncomeCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      showExpenseCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onChargeCreated={onChargeCreated}
                      items={getCharges()}
                      goToDetails={goToDetailsCharge}
                      goToInvoiceDetails={goToInvoiceDetails}
                      changeItems={setCharges}
                      selectedApplyToContact={{
                        contactId:
                          parcelShipmentContextFormValue.billToContactId,
                        name: parcelShipmentContextFormValue.billToContactName,
                        contactType:
                          parcelShipmentContextFormValue.billToContactType,
                        paidAs: applyToContactPaidAs,
                      }}
                      selectedApplyToCarrier={{
                        contactId:
                          parcelShipmentContextFormValue.carrierContactId,
                        name: parcelShipmentContextFormValue.carrierContactName,
                        contactType: ContactType.Carrier,
                        paidAs: applyToCarrierPaidAs,
                      }}
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                      charges={parcelShipmentContextFormValue.charges}
                    />
                    <div className="mt-3">
                      <h2>Totals</h2>
                    </div>
                    <ChargeForm initialValues={profitValues} id={'ChargeForm'}>
                      <div className={'row'}>
                        <div className={'col-2'}>
                          <ChargeForm.Income disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Expense disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Profit disabled={true} />
                        </div>
                      </div>
                    </ChargeForm>
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'ParcelShipmentForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Parcel Shipment
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <TrackingEventForOrderList
                      limit={limit}
                      showPagination={false}
                      showAddButton={isOrgAdminOrOperation ? true : false}
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onTrackingEventCreated={onTrackingEventCreated}
                      items={getTrackingEvents()}
                      goToDetails={goToDetailsTrackingEvent}
                      changeItems={setTrackingEvents}
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'ParcelShipmentForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Parcel Shipment
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel forceRender={isCreateMode ? false : true}>
                  <OrderForm.CustomValues
                    customFields={customFields}
                    entityType={CustomFieldEntityType.ParcelShipment}
                    defaultValue={{
                      ...parcelShipmentContextFormValue.customValues,
                    }}
                    onChange={(result) => {
                      setParcelShipmentContextFormValue(
                        (parcelShipmentContextFormValue) => {
                          parcelShipmentContextFormValue.customValues = result;
                          return { ...parcelShipmentContextFormValue };
                        },
                      );
                    }}
                    saveButtonRenderCondition={
                      currentUser?.isInOrgAdminRole ||
                      currentUser?.isInOperationRole
                    }
                    isSending={isSending}
                    formName={'ParcelShipmentForm'}
                    entityName={'ParcelShipment'}
                    onCancel={onCancel}
                    context={context}
                  />
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <AttachmentsFilesList
                      parentId={orderId}
                      parentType={AttachmentParentType.Order}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'ParcelShipmentForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Parcel Shipment
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <OrderDocumentsFragment
                      orderContextFormValue={parcelShipmentContextFormValue}
                      onSaveOrder={onSaveParcelShipment}
                      setIsSending={setIsSending}
                      documentTemplatesFilter={documentTemplateFilter}
                      setOrderContextFormValue={() =>
                        setParcelShipmentContextFormValue
                      }
                      orderType={OrderTypes.ParcelShipment}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'ParcelShipmentForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Parcel Shipment
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              </div>
            </Tabs>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
