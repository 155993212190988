import React, { useEffect } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';

type CallbackValues = {
  dependencies?: Array<string>;
  values?: any;
};

const callbackValues = ({ dependencies, values }): CallbackValues => {
  const result = {};
  dependencies.forEach((key: string | number) => {
    result[key] = values?.[key];
  });
  return result;
};

export const ComputedInput = ({ dependencies = [], callback, ...props }) => {
  const { values, touched, setFieldValue } = useFormikContext();
  const [field, meta] = useField(props as string | FieldHookConfig<any>);

  const dependenceValues = dependencies.map(
    (key: string | number) => values?.[key],
  );
  const isDependenciesTouched = dependencies
    .map((key: string | number) => touched?.[key])
    .every((val: any) => !!val);

  useEffect(() => {
    if (callback instanceof Function) {
      const callbackArguments = callbackValues({ dependencies, values });
      const result = callback(callbackArguments);
      setFieldValue(props?.name, result);
    }
  }, [...dependenceValues]);

  return (
    <>
      <div>
        <input {...props} {...field} />
        {props?.appendElement ?? null}
      </div>
      {!!isDependenciesTouched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};
