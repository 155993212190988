import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ComponentProps } from '../layout-interfaces';
import { LayoutComponent } from './layout-component';
import { localized, parseTemplate } from '../component-hooks';
import { Panel } from '../../panel/panel.component';
import { ComponentRender } from '../component-render';
import { useCallback, useEffect, useState } from 'react';

export const TabsComponent = (props: ComponentProps) => {
  const { children, variables } = props ?? { children: [] };
  const { options, toolbar, useNavigationForTabs } = props?.props || {};

  // read tab index from variables or set to 0
  const [tabIndex, setTabIndex] = useState(
    variables ? variables[`${props?.name}Ix`] || 0 : 0,
  );

  const setTab = useCallback(
    (index) => {
      if (!props?.variables?.isDialog && index === tabIndex) return;
      setTabIndex(index);
      if (props?.variables) {
        props?.context?.setParams(
          {
            [`${props?.name}Ix`]: index,
          },
          { replace: !useNavigationForTabs },
        );
      }
    },
    [props?.variables, props?.name, tabIndex],
  );

  // monitor tab index changes and update variables
  useEffect(() => {
    setTab(variables ? variables[`${props?.name}Ix`] || 0 : 0);
  }, [props?.variables, props?.name]);

  return (
    <Tabs {...options} selectedIndex={tabIndex} onSelect={setTab}>
      <TabList>
        {children?.map((child, index) => {
          return (
            <Tab
              {...parseTemplate(child.props?.options, {
                ...variables,
                ...props?.context?.store,
                tabIndex: index,
              })}
              key={child.name}
            >
              {parseTemplate(localized(child.props?.label), {
                ...variables,
                ...props?.context?.store,
              })}
            </Tab>
          );
        })}
        <li className="react-tabs__tab react-tabs__tab--button">
          {toolbar?.map((child, index) => {
            return (
              <ComponentRender
                key={child.name}
                {...child}
                context={props.context}
                variables={props.variables}
              />
            );
          })}
        </li>
      </TabList>
      {children?.map((child, index) => {
        return (
          <TabPanel key={index}>
            <Panel className="m-3">
              <LayoutComponent
                {...child}
                context={props.context}
                variables={props.variables}
              />
            </Panel>
          </TabPanel>
        );
      })}
    </Tabs>
  );
};
