import { Dialog } from '../../common/components/dialog/dialog.component';
import { ClauseEdit } from './clause-edit.component';
import { ClauseDto } from '../../../models/data.models';

export type ClauseDialogProperties = {
  clauseId?: number | null;
  title: string;
};

export class ClauseDialog extends Dialog<ClauseDialogProperties> {
  constructor(props: ClauseDialogProperties) {
    super(props);
    this.onClauseSaved = this.onClauseSaved.bind(this);
  }

  onClauseSaved = (clause: ClauseDto) => {
    this.close(clause);
  };

  renderContent(): any {
    const { clauseId } = this.props as ClauseDialogProperties;
    return (
      <ClauseEdit
        clauseId={clauseId}
        onClauseCreated={this.onClauseSaved}
        onClauseUpdated={this.onClauseSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
