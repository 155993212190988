import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { InvoiceManagerListScreen } from './screens/invoiceManager-list.screen';

export const INVOICE_MANAGER_LIST_PATH = '/invoice-manager';

export const InvoiceManagerRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <InvoiceManagerListScreen
              pageTitle="Invoice Manager"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
