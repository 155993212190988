import React, { CSSProperties, useState } from 'react';
import { Field } from 'formik';

export type ToggleProps = {
  label?: string;
  name: string;
  id?: string;
  labelFirst?: boolean;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  required?: boolean;
  defaultChecked?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (value: boolean) => void;
  index?: number;
};

export const Toggle = ({
  label,
  name,
  id = null,
  labelFirst = false,
  disabled = false,
  defaultChecked = false,
  size = 'md',
  className = '',
  style = {},
  onClick = () => {},
  onChange = () => {},
  required,
  index,
}: ToggleProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  const handleToggleChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
    event?.stopPropagation();
  };

  return (
    <div
      className={`h-100 d-flex align-items-center justify-content-center ${className}`}
      style={style}
    >
      <div className="custom-control custom-toggle" onClick={onClick}>
        {labelFirst && label && (
          <label
            className={`custom-toggle-label label-first font-size-${size}`}
            htmlFor={id ? id : name}
          >
            {label}
          </label>
        )}

        <Field
          type="checkbox"
          disabled={disabled}
          id={id ? id : name}
          name={name}
          required={required}
          checked={isChecked}
        >
          {({ field }: any) => {
            return (
              <React.Fragment>
                <input
                  {...field}
                  required={required}
                  id={id ? id : name}
                  name={name}
                  disabled={disabled}
                  checked={isChecked}
                  className="custom-toggle-input"
                  type="checkbox"
                />
                <span
                  className={`custom-toggle-checkmark custom-toggle-checkmark-${index}`}
                  onClick={(event) => handleToggleChange(event)}
                />
              </React.Fragment>
            );
          }}
        </Field>

        {!labelFirst && label && (
          <label
            className={`custom-toggle-label font-size-${size}`}
            htmlFor={id ? id : name}
          >
            {label}
          </label>
        )}
      </div>
    </div>
  );
};
