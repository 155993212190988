import {
  Column,
  LinkDto,
  ReceiveDriverPayCommandValues,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  getDriverPaysListRequest,
  receiveDriverPayRequest,
} from './driverPayManager.service';

export const getDriverPays = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getDriverPaysListRequest(currentOrganization, params);
});

export const receiveDriverPay = createEffect(
  (driverPay: ReceiveDriverPayCommandValues) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return receiveDriverPayRequest(currentOrganization!, driverPay);
  },
);

type DriverPayManagerStoreState = {
  links: LinkDto[];
  orderDriverPayColumns: Column[];
};

const defaultState: DriverPayManagerStoreState = {
  links: [],
  orderDriverPayColumns: [
    {
      name: 'orderNumber',
      visible: true,
      title: 'Load',
      sortName: 'Order.OrderNumber~ToInt32',
    },
    {
      name: 'customerName',
      visible: true,
      title: 'Customer',
      sortName: 'Order.BillToContact.Name',
    },
    { name: 'origin', visible: true, title: 'Origin' },
    { name: 'shippedDate', visible: true, title: 'Shipped' },
    { name: 'destination', visible: true, title: 'Destination' },
    { name: 'deliveredDate', visible: true, title: 'Delivered' },
    { name: 'orderStatus', visible: true, title: 'Order Status' },
    { name: 'driverFee', visible: true, title: 'Driver Fee' },
    { name: 'balanceOwning', visible: true, title: 'Balance Owning' },
    { name: 'gross', visible: true, title: 'Gross' },
    { name: 'billReceived', visible: true, title: 'Bill Received' },
    { name: 'receivedDate', visible: false, title: 'Received Date' },
    { name: 'aging', visible: false, title: 'Aging' },
  ],
};
export const updateDriverPayColumns = createEvent<Column[]>();

export const driverPayManagerStore: Store<DriverPayManagerStoreState> = createStore(
  defaultState,
)?.on(updateDriverPayColumns, (state, payload) => {
  return { ...state, orderColumns: payload };
});
