import React from 'react';
import { FaCheck, FaHourglassEnd } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { BiMessageRoundedDots } from 'react-icons/bi';
import {
  PickingActivityStatuses,
  PutAwayActivityStatuses,
} from '../../../../models/data.models';

export type TotalStatusesProps = {
  title?: string;
  activityStatuses?: string[];
  statusesType?: any;
  color?: string;
};

type StatusIconMapping = {
  [key in string]: React.ReactNode;
};

const statusIcons: StatusIconMapping = {
  [PutAwayActivityStatuses.InMovement]: (
    <FaHourglassEnd size={14} className={'mr-2 color-text-neutral'} />
  ),
  [PutAwayActivityStatuses.Completed]: (
    <FaCheck size={16} className={'mr-2 color-text-success'} />
  ),
  [PickingActivityStatuses.AwaitingPicking]: (
    <FaHourglassEnd size={14} className={'mr-2 color-text-neutral'} />
  ),
  [PickingActivityStatuses.Damaged]: (
    <IoMdClose size={20} className={'mr-2 color-text-negative'} />
  ),
  [PickingActivityStatuses.NotFound]: (
    <BiMessageRoundedDots size={20} className={'mr-2 color-text-disable'} />
  ),
  [PickingActivityStatuses.Picked]: (
    <FaCheck size={16} className={'mr-2 color-text-success'} />
  ),
};

export const TotalStatuses = ({
  title = 'All packages statuses',
  activityStatuses,
  statusesType,
  color = 'text-primary',
}: TotalStatusesProps) => {
  return (
    <div className={'box px-6 py-4 my-5'}>
      <div className="row rounded align-items-center justify-content-between">
        <div className="col-3">
          <div className={'text-h3 font-weight-bold mb-md-0 mb-3 text-nowrap'}>
            {title}
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between px-5">
          {Object.values(statusesType).map((status) => {
            const iconComponent =
              statusIcons[
                status as
                  | keyof typeof PutAwayActivityStatuses
                  | keyof typeof PickingActivityStatuses
              ];
            return (
              <div
                key={`package-status-${
                  status as
                    | keyof typeof PutAwayActivityStatuses
                    | keyof typeof PickingActivityStatuses
                }`}
                className="d-flex align-items-center pl-sm-4"
              >
                {iconComponent && (
                  <>
                    {iconComponent}
                    &nbsp;
                  </>
                )}
                {status}:&nbsp;
                <div className={`text-h3 font-weight-bold color-${color}`}>
                  {
                    activityStatuses.filter(
                      (activityStatus) => activityStatus === status,
                    ).length
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
