import React, { useEffect, useState } from 'react';
import { WarehouseZoneDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { v4 as uuidv4 } from 'uuid';
import { showDialog } from '../../common/dialog.store';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { WarehouseZoneDialog } from './warehouseZone.dialog';
import {
  CREATE_WAREHOUSEZONE_LINK_KEY,
  GET_WAREHOUSEZONE_LINK_KEY,
  WAREHOUSEZONE_ENTITY_NAME,
} from '../warehouseZones.service';
import {
  getWarehouseZoneFx,
  getWarehouseZonesFx,
} from '../warehouseZones.store';

export const SelectWarehouseZonePaginate = ({
  id = 'warehouseZoneId' + uuidv4(),
  nameId = 'warehouseZone' + uuidv4(),
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  options = [],
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeWarehouseZone = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Warehouse Zone',
}): JSX.Element => {
  const formikContext = useFormikContext();
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const [context, setContext] = useState(null);
  useEffect(() => {
    setContext(useContext);
  }, [useContext]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseWarehouseZoneDtoPagedResult: WarehouseZoneDtoPagedResult = await getWarehouseZonesFx(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseWarehouseZoneDtoPagedResult.items.filter(
        (el) => el.warehouseZoneId !== options[0],
      ),
      hasMore: responseWarehouseZoneDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeWarehouseZone === 'function') {
      onChangeWarehouseZone(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const onChangeWithContext = (warehouseZone, currentContext) => {
    onChange(warehouseZone, currentContext);
    if (currentContext) {
      currentContext.setFieldValue(id, warehouseZone?.warehouseZoneId);
      currentContext.setFieldError(id, undefined);
      setValue(() => {
        return (
          warehouseZone && {
            warehouseZoneId: warehouseZone?.warehouseZoneId,
            name: warehouseZone?.name,
          }
        );
      });
      if (required && !warehouseZone) {
        currentContext.setFieldValue(id, null);
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onWarehouseZoneEditIconClick = () => {
    if (value?.warehouseZoneId) {
      showDialog({
        dialog: WarehouseZoneDialog,
        props: {
          warehouseZoneId: value.warehouseZoneId,
          title: 'Update Warehouse Zone',
        },
      }).then(
        (warehouseZone) => {
          if (warehouseZone !== null) {
            getWarehouseZoneFx({
              warehouseZoneId: Number(value.warehouseZoneId) || null,
            }).then(
              (warehouseZoneDto) => {
                onChangeWithContext(warehouseZoneDto, formikContext);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  if (context) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={WAREHOUSEZONE_ENTITY_NAME}
              canCreateNewEntity={userHas(CREATE_WAREHOUSEZONE_LINK_KEY)}
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: WarehouseZoneDialog,
                  props: {
                    title: showCreateDialogTitle,
                    warehouseZoneId: 0,
                  },
                }).then(
                  (warehouseZone) => {
                    setClearLoadOptionsCache(!clearLoadOptionsCache);
                    return warehouseZone;
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.warehouseZoneId}
              getOptionLabel={(option) => `${option?.name}`}
              onChange={(warehouseZone?: any) => {
                onChangeWithContext(warehouseZone, context);
              }}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              isDisabled={isDisabled}
              isClearable={isClearable}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={isSearchable}
              placeholder={placeholder}
              classNamePrefix={classNamePrefix}
              components={{
                IndicatorSeparator: () => null,
              }}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={WAREHOUSEZONE_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_WAREHOUSEZONE_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: WarehouseZoneDialog,
            props: {
              title: showCreateDialogTitle,
              warehouseZoneId: 0,
            },
          }).then(
            (warehouseZone) => {
              setClearLoadOptionsCache(!clearLoadOptionsCache);
              return warehouseZone;
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.warehouseZoneId}
        getOptionLabel={(option) => option?.name}
        onChange={(warehouseZone?: any) => {
          onChange(warehouseZone, context);
        }}
        onFocus={clearMessages}
        isDisabled={isDisabled}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
