import { Dialog } from '../../common/components/dialog/dialog.component';
import {
  AttachmentDto,
  AttachmentParentType,
} from '../../../models/data.models';
import { UploadAttachments } from './upload-attachments.component';

export type UploadAttachmentsDialogProperties = {
  title: string;
  className?: string;
  parentId: number;
  parentType: AttachmentParentType;
  childIds?: number[];
  prefix?: string;
};

export class UploadAttachmentsDialog extends Dialog<UploadAttachmentsDialogProperties> {
  constructor(props: UploadAttachmentsDialogProperties) {
    super(props);
    this.onAttachmentSaved = this.onAttachmentSaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onAttachmentSaved = (attachments: AttachmentDto[]) => {
    this.close(attachments);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { parentId, parentType, childIds, prefix } = this
      .props as UploadAttachmentsDialogProperties;
    return (
      <UploadAttachments
        onAttachmentsCreated={this.onAttachmentSaved}
        onAttachmentsUpdated={this.onAttachmentSaved}
        onCancel={this.onCancel}
        parentId={parentId}
        parentType={parentType}
        childIds={childIds}
        prefix={prefix}
      />
    );
  }
}
