import React, { useEffect, useState } from 'react';
import { AddTrackingNumberParams } from '../../../orders/orders.service';
import { addMessage, Message } from '../../../common/messages.store';
import { Button } from '../../../common/components/button/button.component';
import { FaBarcode } from 'react-icons/fa';
import { deleteCommodityTrackingNumber } from '../../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import {
  CommodityTrackingNumberDto,
  PackingStatuses,
} from '../../../../models/data.models';
import { Confirm } from '../../../common/components/confirm/confirm.component';
import { showDialog } from '../../../common/dialog.store';
import { useFormikContext } from 'formik';
import { PackingForm } from '../../../parcelShipments/packingWizard/packing.form';
import { setSubmitAction } from '../../../parcelShipments/packingWizard/packingWizard.component';
import { v4 } from 'uuid';
import {
  ACTION_DELETE_TRACKING_NUMBER,
  ACTION_PACKING_SCAN_TRACKING_NUMBER,
} from '../../../constants/api';
import { FiX, FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';
import { triggerWorkflowByConfig } from '../../../workflowTriggers/workflowTriggerHook';

type TrackingNumbersProps = {
  commodityTrackingNumbers?: CommodityTrackingNumberDto[];
  setCommodityTrackingNumbers?: any;
  allowEdit?: boolean | null;
  allowDelete?: boolean | null;
  commodityId?: number | null;
};

export const getInitialState = () => {
  const initialState: AddTrackingNumberParams = {
    trackingNumber: null,
  };

  return initialState;
};

export const scanTrackingNumber = async (data: any, { setFieldValue }) => {
  const scannedInnerPurchase = data?.parcelShipment?.commodities?.find((item) =>
    item.trackingNumbers?.some((x) => x.trackingNumber === data.trackingNumber),
  );
  if (scannedInnerPurchase) {
    const workflowExecutionVariables = {
      commodityId: scannedInnerPurchase?.commodityId,
      packingStatus: PackingStatuses.Scanned,
    };

    try {
      await triggerWorkflowByConfig({
        configName: 'tms.modules.packing.scanCommodity',
        workflowIdConfigKey: 'scanCommodityWorkflowId',
        workflowVariables: workflowExecutionVariables,
      });

      scannedInnerPurchase.customValues = {
        ...scannedInnerPurchase.customValues,
        packingStatus: PackingStatuses.Scanned,
      };
    } catch (error) {
      addMessage({
        message: error?.message || error,
        type: 'danger',
        autoHide: true,
        id: v4(),
      });
    }
  }
  setFieldValue('trackingNumber', '');
};

export const handleDeleteTrackingNumber = async (
  data: any,
  { setFieldValue },
) => {
  const result = await showDialog({
    dialog: Confirm,
    props: {
      title: `Delete ${data?.commodityTrackingNumber.trackingNumber} Tracking Number`,
      message: 'Are you sure you want to delete?',
      className: 'delete-modal',
    },
  });

  if (result) {
    if (data?.commodityTrackingNumbers.length === 1) {
      const message: Message = {
        id: 'last-tracking-number',
        type: 'warning',
        autoHide: true,
        message: 'There must be at least one tracking number!',
      };
      addMessage(message);
    } else {
      if (data?.trackingNumber?.commodityTrackingNumberId > 0) {
        await deleteCommodityTrackingNumber(data?.commodityTrackingNumber);

        const message: Message = {
          id: 'tracking-number-deleted',
          type: 'success',
          autoHide: true,
          message: 'Tracking Number deleted successfully!',
        };

        addMessage(message);
        const filteredTrackingNumbers = data?.trackingNumbers?.filter(
          (value) =>
            value.commodityTrackingNumberId !==
            data?.trackingNumber?.commodityTrackingNumberId,
        );

        setFieldValue('trackingNumbers', filteredTrackingNumbers);
      } else {
        const filteredTrackingNumbers = data?.trackingNumbers?.filter(
          (value) => value.trackingNumber !== data?.trackingNumber,
        );
        setFieldValue('trackingNumbers', filteredTrackingNumbers);

        const message: Message = {
          id: 'tracking-number-deleted',
          type: 'success',
          autoHide: true,
          message: 'Tracking Number deleted successfully!',
        };
        addMessage(message);
      }
    }
  }
};

export const TrackingNumbersFormik = ({
  allowEdit = false,
  allowDelete = true,
}: TrackingNumbersProps) => {
  const [overflowTrackingNumberIdx, setOverflowTrackingNumberIdx] = useState<
    number | null
  >(null);
  const [
    displayAllTrackingNumbers,
    setDisplayAllTrackingNumbers,
  ] = useState<boolean>(false);
  const formikContext = useFormikContext<any>();

  const sortedTrackingNumbers = [...formikContext.values?.trackingNumbers].sort(
    (a, b) => {
      if (a.isPrimary && !b.isPrimary) return -1;
      if (!a.isPrimary && b.isPrimary) return 1;
      return 0;
    },
  );

  useEffect(() => {
    if (sortedTrackingNumbers?.length > 0) {
      const ctr = document.getElementById('tracking-number-container');
      const ert = Array.from(ctr.children).sort((a, b) =>
        a.id.localeCompare(b.id),
      );

      for (let i = 0; i < ert?.length; i++) {
        if (
          ert[i].getBoundingClientRect().right + 100 >
          ctr.getBoundingClientRect().right
        ) {
          setOverflowTrackingNumberIdx(i);
          break;
        }
      }
    }
  }, [sortedTrackingNumbers?.length]);

  const displayedTrackingNumbers = sortedTrackingNumbers.filter(
    (_, idx) =>
      displayAllTrackingNumbers ||
      overflowTrackingNumberIdx === null ||
      idx < overflowTrackingNumberIdx,
  );

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="w-100 d-flex flex-row align-items-center">
        <div className="text-label d-flex flex-row align-items-center">
          <FaBarcode className={'mr-2'} />
          Tracking Numbers:&nbsp;
        </div>
        <div
          id={`tracking-number-container`}
          className={`col-9 white font-weight-bold ${
            !displayAllTrackingNumbers && 'text-nowrap overflow-hidden'
          }`}
        >
          {displayedTrackingNumbers.map(
            (
              commodityTrackingNumber: CommodityTrackingNumberDto,
              idx: number,
            ) => {
              return (
                <div
                  key={commodityTrackingNumber.commodityTrackingNumberId}
                  id={`tracking-number-badge-${idx}`}
                  className="m-2 d-inline-block"
                >
                  <div className={`tracking-number-badge`}>
                    {commodityTrackingNumber.trackingNumber}
                    {allowDelete && (
                      <FiX
                        className={'ml-2 buttonIcon'}
                        color="black"
                        size={'20px'}
                        onClick={() => {
                          if (!formikContext.isSubmitting && allowEdit) {
                            setSubmitAction(
                              formikContext,
                              ACTION_DELETE_TRACKING_NUMBER,
                              { commodityTrackingNumber },
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            },
          )}
          {sortedTrackingNumbers.length > 1 &&
            (displayedTrackingNumbers.length < sortedTrackingNumbers.length ||
              displayAllTrackingNumbers) && (
              <div
                id={`tracking-number-badge-more`}
                className="m-2 d-inline-block"
              >
                <div className={`tracking-number-badge`}>
                  {displayAllTrackingNumbers
                    ? 'hide'
                    : `${
                        sortedTrackingNumbers.length - overflowTrackingNumberIdx
                      } more`}
                  {displayAllTrackingNumbers ? (
                    <FiMoreVertical
                      className={'ml-2 buttonIcon'}
                      color="black"
                      size={'20px'}
                      onClick={() =>
                        setDisplayAllTrackingNumbers((prev) => !prev)
                      }
                    />
                  ) : (
                    <FiMoreHorizontal
                      className={'ml-2 buttonIcon'}
                      color="black"
                      size={'20px'}
                      onClick={() =>
                        setDisplayAllTrackingNumbers((prev) => !prev)
                      }
                    />
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
      {allowEdit && (
        <div className="input-group d-flex flex-row flex-nowrap col-3">
          <PackingForm.TrackingNumber
            className={'h-100 input-tracking-number mb-0'}
            placeholder="Tracking number"
            style={{ width: '100%' }}
          />
          <Button
            disabled={formikContext.isSubmitting}
            color={'secondary'}
            outline
            className={
              'input-group-append btn-add-barcode d-flex justify-content-center align-items-center'
            }
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              width: 110,
            }}
            onClick={() => {
              setSubmitAction(
                formikContext,
                ACTION_PACKING_SCAN_TRACKING_NUMBER,
              );
            }}
          >
            <FaBarcode className={'mr-2'} />
            Scan
          </Button>
        </div>
      )}
    </div>
  );
};
