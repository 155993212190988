import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';
import { SelectWarehouseZonePaginate } from './warehouseZone-react-select.component';

export const WarehouseZoneForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

WarehouseZoneForm.CustomerId = ({
  selectedFilter = ``,
  placeholder = 'Select Customer',
  required = false,
  multiple = false,
  defaultValue,
  sort = 'name',
  id = 'customerId',
  nameId = 'customerIdName',
  header = 'Customer',
  onChange = () => {},
  readonly,
  contactTypes = [],
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      useContext={true}
      nameId={nameId}
      id={id}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      required={required}
      selectedValue={defaultValue}
      isMulti={multiple}
      onChangeContact={onChange}
      showContactType={false}
      selectedFilter={selectedFilter}
      closeMenuOnSelect={true}
      isSearchable={true}
      contactTypes={contactTypes}
      readonly={readonly ? true : false}
    />
  );
};
WarehouseZoneForm.Description = ({
  className,
  style,
  size,
  onChange = () => {},
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
WarehouseZoneForm.DivisionId = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = false,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isClearable={false}
      isDisabled={disabled}
    />
  );
};
WarehouseZoneForm.Name = ({
  id = 'warehouseZoneName',
  className,
  style,
  size,
  onChange = () => {},
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
WarehouseZoneForm.ParentZoneId = ({
  selectedFilter = ``,
  placeholder = 'Select Parent Zone',
  required = false,
  defaultValue,
  sort = '',
  id = 'parentZoneId',
  options = [],
  header = 'Parent Zone',
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <SelectWarehouseZonePaginate
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      options={options}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeWarehouseZone={onChange}
    />
  );
};
