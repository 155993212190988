import { v4 } from 'uuid';
import { addMessage } from '../../messages.store';
import { parseTemplate } from '../../components/layout/component-hooks';
import logger from '../../logger';

export const setLocalStorageAction = async ({
  actionProps,
  data,
  latestStoreValues,
  source,
  onAction,
}) => {
  const { key, value } = actionProps?.setLocalStorage;
  const scopedKey = `${data?.organizationId || 'default'}:${key}`;
  const parsedValue = parseTemplate(value, {
    ...latestStoreValues,
    ...data,
    ...source?.formikContext?.values,
  });
  try {
    localStorage.setItem(scopedKey, parsedValue);
    if (actionProps.onSuccess) {
      await onAction(
        actionProps.onSuccess,
        { ...data, key, value },
        latestStoreValues,
        source,
      );
    }

    return true;
  } catch (e) {
    logger.error('Setting local storage went wrong', e);
    if (actionProps.onError) {
      await onAction(
        actionProps.onError,
        {
          error: e,
          ...data,
        },
        latestStoreValues,
        source,
      );
    } else {
      addMessage({
        message: e?.message || e,
        type: 'danger',
        autoHide: true,
        id: v4(),
      });
    }

    return false; // do not continue actions
  }
};
