import {
  TrackingEventDto,
  LinkDto,
  LinkResourceBaseDto,
  ChargeDtoPagedResult,
  CreateTrackingEventCommandValues,
  UpdateTrackingEventCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_TRACKINGEVENTS_LINK_KEY = 'get-trackingevents';
export const CREATE_TRACKINGEVENT_LINK_KEY = 'create-trackingevent';
export const GET_TRACKINGEVENT_LINK_KEY = 'get-trackingevent';
export const UPDATE_TRACKINGEVENT_LINK_KEY = 'update-trackingevent';
export const DELETE_TRACKINGEVENT_LINK_KEY = 'delete-trackingevent';

export interface GetTrackingEventParams {
  trackingEventId: number;
  trackingEvent?: TrackingEventDto;
}

export const getTrackingEventRequest = async (
  resource: LinkResourceBaseDto | null,
  { trackingEventId }: GetTrackingEventParams,
): Promise<TrackingEventDto> => {
  if (resource && trackingEventId) {
    const getTrackingEventLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_TRACKINGEVENT_LINK_KEY,
    );
    if (getTrackingEventLink) {
      const result = await execLink(getTrackingEventLink, { trackingEventId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_TRACKINGEVENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getTrackingEventsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ChargeDtoPagedResult> => {
  if (resource) {
    const getTrackingEventsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_TRACKINGEVENTS_LINK_KEY,
    );
    if (getTrackingEventsListLink) {
      const result = await execLink(getTrackingEventsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_TRACKINGEVENTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createTrackingEventRequest = async (
  resource: LinkResourceBaseDto,
  trackingEvent: CreateTrackingEventCommandValues,
) => {
  const createTrackingEventLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_TRACKINGEVENT_LINK_KEY,
  );
  if (createTrackingEventLink) {
    const result = await execLink(createTrackingEventLink, trackingEvent);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_TRACKINGEVENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateTrackingEventRequest = async (
  resource: LinkResourceBaseDto,
  trackingEvent: UpdateTrackingEventCommandValues,
) => {
  const updateTrackingEventLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_TRACKINGEVENT_LINK_KEY,
  );
  if (updateTrackingEventLink) {
    const result = await execLink(updateTrackingEventLink, trackingEvent);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_TRACKINGEVENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteTrackingEventRequest = async (
  trackingEvent: TrackingEventDto,
) => {
  const deleteTrackingEventLink = trackingEvent.links?.find(
    (x: LinkDto) => x.rel === DELETE_TRACKINGEVENT_LINK_KEY,
  );
  if (deleteTrackingEventLink) {
    const result = await execLink(deleteTrackingEventLink, trackingEvent);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_TRACKINGEVENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
