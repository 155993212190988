import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import {
  AccountingTransactionType,
  GetContactsQuery,
} from '../../../models/data.models';
import {
  CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  GetAccountingTransactionParams,
} from '../../accountingTransactions/accountingTransactions.service';
import {
  BILLS_CREATE_PATH,
  BILLS_EDIT_PATH,
  BILLS_LIST_PATH,
} from '../bills.route';
import { BillsList } from '../components/bill-list.component';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';
import {
  accountingTransactionStore,
  getAccountingTransactionsDefaultColumnsFx,
} from '../../accountingTransactions/accountingTransactions.store';
import { useStore } from 'effector-react';

export type BillsListScreenProps = { pageTitle: string; group: string };

export const BillsListScreen = ({ pageTitle, group }: BillsListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(accountingTransactionStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `accountingTransactionType: ${AccountingTransactionType.Bill}`,
    search = '',
    view = '',
  } = useQuery() as GetContactsQuery;
  const navigate = useNavigate();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + BILLS_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (params: GetAccountingTransactionParams) => {
    const path = generatePath(INTERNAL_PATH + BILLS_EDIT_PATH, {
      organizationId,
      accountingTransactionId: params.accountingTransactionId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getAccountingTransactionsDefaultColumnsFx();
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };
  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };
  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}&view=${view}`,
    );
  };
  return (
    <InternalLayout
      group={group}
      title={'Bills'}
      createPath={
        userHas(CREATE_ACCOUNTINGTRANSACTION_LINK_KEY) ? createPath() : null
      }
      createLabel={'Bill'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <BillsList
        search={search}
        offset={offset}
        limit={limit}
        filter={filter}
        sort={sort}
        goToDetails={goToDetails}
        onSearch={onSearch}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onViewChanged={onViewChanged}
        onLimitChanged={onLimitChanged}
        onSort={onSort}
        onFilter={onFilter}
      />
    </InternalLayout>
  );
};
