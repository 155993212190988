import {
  OrganizationDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateOrganizationCommandValues,
  UpdateOrganizationCommandValues,
  OrganizationDtoPagedResult,
  PatchCommandBase,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_ORGANIZATIONS_LINK_KEY = 'get-organizations';
export const CREATE_ORGANIZATION_LINK_KEY = 'create-organization';
export const GET_ORGANIZATION_LINK_KEY = 'self';
export const UPDATE_ORGANIZATION_LINK_KEY = 'update-organization';
export const PATCH_ORGANIZATION_LINK_KEY = 'patch-organization';
export const DELETE_ORGANIZATION_LINK_KEY = 'delete-organization';

export interface GetOrganizationParams {
  organizationId: number;
}
export interface PatchOrganizationParams {
  resource: LinkResourceBaseDto;
  patchOrganizationCommand: PatchCommandBase;
}
export const getOrganizationRequest = async (
  resource: LinkResourceBaseDto | null,
  { organizationId }: GetOrganizationParams,
): Promise<OrganizationDto> => {
  if (resource && organizationId) {
    const getOrganizationLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORGANIZATION_LINK_KEY,
    );
    if (getOrganizationLink) {
      const result = await execLink(getOrganizationLink, { organizationId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORGANIZATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrganizationsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrganizationDtoPagedResult> => {
  if (resource) {
    const getOrganizationsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORGANIZATIONS_LINK_KEY,
    );
    if (getOrganizationsListLink) {
      const result = await execLink(getOrganizationsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORGANIZATIONS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createOrganizationRequest = async (
  resource: LinkResourceBaseDto,
  organization: CreateOrganizationCommandValues,
) => {
  const createOrganizationLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORGANIZATION_LINK_KEY,
  );
  if (createOrganizationLink) {
    const result = await execLink(createOrganizationLink, organization);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ORGANIZATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateOrganizationRequest = async (
  resource: LinkResourceBaseDto,
  organization: UpdateOrganizationCommandValues,
) => {
  const updateOrganizationLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORGANIZATION_LINK_KEY,
  );
  if (updateOrganizationLink) {
    const result = await execLink(updateOrganizationLink, organization);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ORGANIZATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const patchOrganizationRequest = async (
  resource: LinkResourceBaseDto,
  organization: PatchCommandBase,
) => {
  const patchOrganizationLink = resource.links?.find((x: LinkDto) => {
    return x.rel === PATCH_ORGANIZATION_LINK_KEY;
  });
  if (patchOrganizationLink) {
    const result = await execLink(
      patchOrganizationLink,
      organization?.patchItems,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${PATCH_ORGANIZATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteOrganizationRequest = async (
  organization: OrganizationDto,
) => {
  const deleteOrganizationLink = organization.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORGANIZATION_LINK_KEY,
  );
  if (deleteOrganizationLink) {
    const result = await execLink(deleteOrganizationLink, organization);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ORGANIZATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
