import React, { useEffect } from 'react';
import { InternalLayout } from '../../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PutAwayActivityFlow } from '../putAwayActivityFlow.component';
import { Helmet } from 'react-helmet';
import { setCurrentPagePath } from '../../../common/nav.store';
import { CARGO_MOVEMENT_LIST_PATH } from '../../cargoMovement.route';
import { INTERNAL_PATH } from '../../../../app.router';

export type OrderScreenProps = { pageTitle: string; group: string };

export const PutAwayActivityScreen = ({
  pageTitle,
  group,
}: OrderScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderId } = useParams();

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  useEffect(() => {
    setCurrentPagePath([
      group,
      'Cargo Movements',
      [orderId, '(PutAway)'].join(' '),
    ]);
  });

  return (
    <InternalLayout group={group} title={'Put Away'} createPath={null}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PutAwayActivityFlow orderId={orderId} onCancel={onCancel} />
    </InternalLayout>
  );
};
