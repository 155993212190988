import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  CargoMovementType,
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  createOrder,
  deleteOrder,
  getOrders,
  getOrdersColumns,
  ordersStore,
} from '../cargoMovements.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_CARGO_MOVEMENT_LINK_KEY,
  DELETE_CARGO_MOVEMENT_LINK_KEY,
  GetOrderParams,
} from '../cargoMovements.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CARGO_MOVEMENT_CREATE_PATH,
  CARGO_MOVEMENT_EDIT_PICKING_PATH,
  CARGO_MOVEMENT_EDIT_PUTAWAY_PATH,
} from '../cargoMovement.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { OrderDialog } from './cargoMovement.dialog';
import { userHas } from '../../auth/auth.store';
import { Grid } from '../../common/components/grid/grid.component';
import { hidenFilter } from '../../../utils/query.utils';

export type OrdersListProps = {
  goToDetails?: (orderParams: GetOrderParams) => void;
  goToPickingActivity?: (orderParams: GetOrderParams) => void;
  goToPutAwayActivity?: (orderParams: GetOrderParams) => void;
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (order: OrderDto, orderId: string) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const CargoMovementsList = ({
  goToDetails = () => {},
  goToPickingActivity = () => {},
  goToPutAwayActivity = () => {},
  filter = null,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch = () => {},
}: OrdersListProps) => {
  const [orders, setOrders] = useState<OrderDtoPagedResult | null>(null);
  const [loading, setLoading] = useState(true);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  const {
    orderColumns: columns,
    defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(ordersStore);

  useEffect(() => {
    getOrdersColumns().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  useEffect(() => {
    getOrdersData();
  }, [offset, limit, sort, filter, search]);

  const getOrdersData = () => {
    try {
      const additionalFilter = hidenFilter(
        `orderType:${OrderTypes.CargoMovement}`,
        filter,
      );
      getOrders({ offset, limit, sort, filter: additionalFilter, search }).then(
        (ordersData) => {
          const orderDtoResult: OrderDtoForListPagedResult = {
            limit: ordersData.limit,
            offset: ordersData.offset,
            links: ordersData.links,
            totalCount: ordersData.totalCount,
            items: ordersData.items.map<OrderForListDto>((item) => {
              const resultOrder: OrderForListDto = item;
              if (item?.customValues) {
                Object.keys(item.customValues).forEach(
                  (customFieldInternalName) => {
                    if (!resultOrder.hasOwnProperty(customFieldInternalName))
                      resultOrder[customFieldInternalName] =
                        item.customValues[customFieldInternalName];
                  },
                );
                item.customValues['packages'] = item.commodities.length;
              }
              return item;
            }),
          };
          setOrders(orderDtoResult);
          setGetDataSuccess(true);
        },
        () => {},
      );
    } catch (error) {
      // Handle error here
    }
  };

  const { currentOrganization } = organizationsStore.getState();

  const onDeleteOrder = async (order: OrderDto) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${order.orderNumber} Cargo Movement Movement`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteOrder(order);
        onDelete();
        getOrdersData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditOrder = (order: OrderDto) => {
    if (order.customValues?.movementType === CargoMovementType.Picking) {
      goToPickingActivity({ orderId: order.orderId });
    } else if (order.customValues?.movementType === CargoMovementType.PutAway) {
      goToPutAwayActivity({ orderId: order.orderId });
    } else {
      goToDetails({ orderId: order.orderId });
    }
  };

  const onCopyOrder = async (order: OrderDto) => {
    const orderCopy = { ...order };
    delete orderCopy.charges;
    await createOrder(orderCopy);
    getOrdersData();
  };

  const navigate = useNavigate();

  const onCreateNewOrder = () => {
    const createPath = generatePath(
      INTERNAL_PATH + CARGO_MOVEMENT_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    navigate(createPath);
  };

  const onOrderSelect = async (order, orderId) => {
    onSelect?.(order, orderId);
    if (order?.orderId) {
      if (order.customValues?.movementType === CargoMovementType.Picking) {
        const path = generatePath(
          INTERNAL_PATH + CARGO_MOVEMENT_EDIT_PICKING_PATH,
          {
            organizationId: currentOrganization.organizationId,
            orderId: order?.orderId,
          },
        );
        navigate(path);
      } else if (
        order.customValues?.movementType === CargoMovementType.PutAway
      ) {
        const path = generatePath(
          INTERNAL_PATH + CARGO_MOVEMENT_EDIT_PUTAWAY_PATH,
          {
            organizationId: currentOrganization.organizationId,
            orderId: order?.orderId,
          },
        );
        navigate(path);
      } else {
        const selectedCargoMovement = await showDialog({
          dialog: OrderDialog,
          props: {
            className: 'order-modal',
            title: 'Update Cargo Movement',
            orderId: order?.orderId,
          },
        });
        if (selectedCargoMovement !== null) {
          getOrdersData();
        }
      }
    }
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={['orderId']}
          data={orders?.items}
          columns={columns}
          summary={orders?.summary}
          offset={offset}
          limit={limit}
          total={orders?.totalCount}
          additionalTotal={orders?.items?.reduce(
            (sum, prev) => sum + prev.commodities?.length,
            0,
          )}
          sort={sort}
          onDelete={
            userHas(DELETE_CARGO_MOVEMENT_LINK_KEY, orders?.items[0]?.links)
              ? onDeleteOrder
              : null
          }
          onSort={(field) => {
            onSort(field);
          }}
          hasProgressBar={true}
          search={search}
          onEdit={onEditOrder}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onLimitChanged={onLimitChanged}
          onSelect={onOrderSelect}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
          onCopy={userHas(CREATE_CARGO_MOVEMENT_LINK_KEY) ? onCopyOrder : null}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          showToolbar={false}
          showGridPanel={true}
          entityType={OrderTypes.CargoMovement}
        />
        {!search && !orders?.items?.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Movements Found</h3>
            {userHas(CREATE_CARGO_MOVEMENT_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewOrder}>
                Create Cargo Movement
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
