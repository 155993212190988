import {
  VesselDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  VesselDtoPagedResult,
  CreateVesselCommandValues,
  UpdateVesselCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_VESSELS_LINK_KEY = 'get-vessels';
export const CREATE_VESSEL_LINK_KEY = 'create-vessel';
export const GET_VESSEL_LINK_KEY = 'get-vessel';
export const UPDATE_VESSEL_LINK_KEY = 'update-vessel';
export const DELETE_VESSEL_LINK_KEY = 'delete-vessel';
export const IMPORT_VESSELS_LINK_KEY = 'import-vessels';
export const EXPORT_VESSELS_LINK_KEY = 'export-vessels';
export const VESSEL_ENTITY_NAME = 'Vessel';

export interface GetVesselParams {
  vesselId: number;
}

export interface ImportVesselsParams {
  organizationId: number;
}

export interface ExportVesselsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getVesselRequest = async (
  resource: LinkResourceBaseDto | null,
  { vesselId }: GetVesselParams,
): Promise<VesselDto> => {
  if (resource && vesselId) {
    const getVesselLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_VESSEL_LINK_KEY,
    );
    if (getVesselLink) {
      const result = await execLink(getVesselLink, { vesselId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_VESSEL_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getVesselsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<VesselDtoPagedResult> => {
  if (resource) {
    const getVesselsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_VESSELS_LINK_KEY,
    );
    if (getVesselsListLink) {
      const result = await execLink(getVesselsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_VESSELS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createVesselRequest = async (
  resource: LinkResourceBaseDto,
  vessel: CreateVesselCommandValues,
) => {
  const createVesselLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_VESSEL_LINK_KEY,
  );
  if (createVesselLink) {
    const result = await execLink(createVesselLink, vessel);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_VESSEL_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateVesselRequest = async (
  resource: LinkResourceBaseDto,
  vessel: UpdateVesselCommandValues,
) => {
  const updateVesselLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_VESSEL_LINK_KEY,
  );
  if (updateVesselLink) {
    const result = await execLink(updateVesselLink, vessel);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_VESSEL_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteVesselRequest = async (vessel: VesselDto) => {
  const deleteVesselLink = vessel.links?.find(
    (x: LinkDto) => x.rel === DELETE_VESSEL_LINK_KEY,
  );
  if (deleteVesselLink) {
    const result = await execLink(deleteVesselLink, vessel);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_VESSEL_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importVesselsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportVesselsParams,
  file: File,
) => {
  const importVesselsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_VESSELS_LINK_KEY,
  );
  if (importVesselsLink) {
    const result = await postFormData(importVesselsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_VESSELS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportVesselsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportVesselsParams,
) => {
  const exportVesselsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_VESSELS_LINK_KEY,
  );
  if (exportVesselsLink) {
    const result = await downloadLinkFile(exportVesselsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_VESSELS_LINK_KEY} is not allowed or missing link action`,
  );
};
