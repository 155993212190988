import React from 'react';
import { Button } from '../../../common/components/button/button.component';
import {
  OrderDto,
  CustomFieldDto,
  ContactDto,
  DivisionDto,
  ContactType,
  VisibleTransactions,
} from '../../../../models/data.models';
import { FormikProps, FormikValues } from 'formik';
import { CustomFieldsLayout } from '../../../common/components/form/customFields-layout.component';
import { OrderForm } from '../../../orders/components/order.form';
import { authStore } from '../../../auth/auth.store';

type GeneralFragmentProps = {
  handleNext: () => void;
  oceanOrderContextFormValue: OrderDto;
  setOceanOrderContextFormValue: (oceanOrder: any) => void;
  context: FormikProps<FormikValues>;
  customFields: CustomFieldDto[];
};

export const GeneralFragment = ({
  oceanOrderContextFormValue,
  setOceanOrderContextFormValue,
  handleNext,
  customFields,
}: GeneralFragmentProps) => {
  const { user: currentUser } = authStore.getState();

  return (
    <div>
      <div className="row mb-3">
        <div className="col-4">
          <h2 className="font-weight-medium">Ocean shipment information</h2>
        </div>
      </div>

      <div className="row">
        <div className={'col-3'}>
          <CustomFieldsLayout
            inputNamePrefix=""
            rows={[{ rowNumber: 1 }]}
            filter={'shipmentName'}
            customFields={customFields}
            defaultValue={{
              ...oceanOrderContextFormValue?.customValues,
            }}
            onChange={(result) => {
              setOceanOrderContextFormValue((oceanOrderContextFormValue) => {
                oceanOrderContextFormValue.customValues = {
                  ...oceanOrderContextFormValue?.customValues,
                  ...result,
                };
                return { ...oceanOrderContextFormValue };
              });
            }}
          />
        </div>

        <div className="col-3 employee-contact-input">
          <OrderForm.EmployeeContactSelect
            id={'employeeContactId'}
            header={'Executed by(Employee)'}
            contactTypes={[ContactType.Employee]}
            selectedFilter={`contactType: ${ContactType.Employee}`}
            required={false}
            defaultValue={
              oceanOrderContextFormValue &&
              oceanOrderContextFormValue.employeeContactId != null &&
              oceanOrderContextFormValue.employeeContactName != null
                ? {
                    contactId: oceanOrderContextFormValue.employeeContactId,
                    name: oceanOrderContextFormValue.employeeContactName,
                    contactType: ContactType.Employee,
                  }
                : ''
            }
            onChange={(data?: ContactDto) => {
              setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
                oceanOrderContextFormValueDto.employeeContactId =
                  data?.contactId;
                oceanOrderContextFormValueDto.employeeContactName = data?.name;
                return oceanOrderContextFormValueDto;
              });
            }}
            nameId={'employeeContactName'}
          />
        </div>

        <div className={'col-3'}>
          <OrderForm.Division
            id={'divisionId'}
            header={'Division'}
            required={true}
            defaultValue={
              oceanOrderContextFormValue &&
              oceanOrderContextFormValue.divisionId != null &&
              oceanOrderContextFormValue.divisionName != null
                ? {
                    divisionId: oceanOrderContextFormValue.divisionId,
                    divisionName: oceanOrderContextFormValue.divisionName,
                  }
                : ''
            }
            onChange={(data?: DivisionDto) => {
              setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
                oceanOrderContextFormValueDto.divisionId = data?.divisionId;
                oceanOrderContextFormValueDto.divisionName = data?.divisionName;
                return oceanOrderContextFormValueDto;
              });
            }}
            nameId={'divisionName'}
            defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
            disabled={
              !(
                (currentUser?.isInOrgAdminRole ||
                  currentUser?.isInOperationRole) &&
                VisibleTransactions[currentUser?.visibleTransactions] ===
                  VisibleTransactions.AllTransactions
              )
            }
          />
        </div>
        <div className="col-3"></div>
      </div>

      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[
          { rowNumber: 1 },
          { rowNumber: 2 },
          { rowNumber: 3 },
          { rowNumber: 4 },
        ]}
        filter={'general'}
        customFields={customFields}
        defaultValue={{
          ...oceanOrderContextFormValue?.customValues,
        }}
        onChange={(result) => {
          setOceanOrderContextFormValue((oceanOrderContextFormValue) => {
            oceanOrderContextFormValue.customValues = {
              ...oceanOrderContextFormValue.customValues,
              ...result,
            };
            return { ...oceanOrderContextFormValue };
          });
        }}
      />

      <div className="row mt-5 d-flex flex-row-reverse">
        <Button className="mr-2" color="primary" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};
