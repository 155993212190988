import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { CreateOrganizationScreen } from './screens/create-organization.screen';

export const ORGANIZATION_LIST_PATH = '/organizations';
export const ORGANIZATION_EDIT_PATH = '/organization';
export const ORGANIZATION_CREATE_PATH = '/organizations/0';
export const OUTER_ORGANIZATION_CREATE_PATH = '/createOrganization';

export const OrganizationRoute = (props: RouteProps) => {
  const group = 'Configuration';
  const pageTitle = 'Create Organization - TrtExpress Express';

  return (
    <Routes>
      <Route
        path="/"
        element={
          <CreateOrganizationScreen pageTitle={pageTitle} group={group} />
        }
      />
    </Routes>
  );
};
