import { FormikContext } from 'formik';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ComponentProps } from '../layout-interfaces';

export const BarCodeScannerComponent = (props: ComponentProps) => {
  const formikContext = useContext(FormikContext);

  const minBarcodeLength = props.props.minBarcodeLength ?? 4;

  const [keyPressed, setKeyPressed] = useState<string>('');
  const accumulatedKeysRef = useRef<string>(''); // Using ref to get the latest state inside the event handler
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Using ref to persist timer across renders

  const processAccumulatedKeys = useCallback(() => {
    if (accumulatedKeysRef.current.length >= minBarcodeLength) {
      setKeyPressed(accumulatedKeysRef.current);
      props.context?.action(
        props.props.onScan,
        {
          ...props.variables,
          result: {
            data: accumulatedKeysRef.current,
            format: 'input',
          },
        },
        {
          actions: props.actions,
          formikContext,
        },
      );
    }
    accumulatedKeysRef.current = '';
  }, [props, formikContext]);

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      const target = event.target as HTMLElement;
      const { tagName, isContentEditable } = target;
      const ignoredElements = [
        'INPUT',
        'TEXTAREA',
        'SELECT',
        'OPTION',
        'BUTTON',
      ];

      if (ignoredElements.includes(tagName) || isContentEditable) {
        accumulatedKeysRef.current = '';
        if (timerRef.current) clearTimeout(timerRef.current);
        return;
      }
      if (
        event.key === 'Enter' &&
        accumulatedKeysRef.current.length >= minBarcodeLength
      ) {
        processAccumulatedKeys();
      } else {
        if (event.key !== 'Enter') accumulatedKeysRef.current += event.key;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(processAccumulatedKeys, 200);
      }
    }

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [processAccumulatedKeys]);

  return <></>;
};
