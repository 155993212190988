import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { AirShipmentOrderEdit } from './airShipmentOrder-edit.component';

export type AirOrderDialogProperties = {
  orderId?: number | null;
  title: string;
  className?: string;
  onAirOrderSaved?: (airOrder: OrderDto) => void;
  onInvoiceGenerated?: () => void;
};

export class AirShipmentOrderDialog extends Dialog<AirOrderDialogProperties> {
  onInvoiceGenerated?: () => void;

  constructor(props: AirOrderDialogProperties) {
    super(props);
    this.onInvoiceGenerated = props.onInvoiceGenerated?.bind(this);
    this.onAirOrderSaved = this.onAirOrderSaved.bind(this);
  }

  onAirOrderSaved = (airOrder: OrderDto) => {
    this.close(airOrder);
  };

  renderContent(): any {
    const { orderId } = this.props as AirOrderDialogProperties;
    const onInvoiceGenerated = this.onInvoiceGenerated;
    return (
      <AirShipmentOrderEdit
        orderId={orderId}
        onAirOrderUpdated={this.onAirOrderSaved}
        onCancel={this.close.bind(this)}
        onInvoiceGenerated={onInvoiceGenerated && this.onInvoiceGenerated}
      />
    );
  }
}
