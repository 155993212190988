import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AppModuleForm } from './appModule.form';
import yaml from 'js-yaml';

import {
  AppModuleDto,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  CustomFieldType,
} from '../../../models/data.models';
import {
  createAppModuleFx,
  deleteAppModuleFx,
  getAppModuleFx,
  updateAppModuleFx,
} from '../appModules.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import {
  DELETE_APPMODULE_LINK_KEY,
  UPDATE_APPMODULE_LINK_KEY,
} from '../appModules.service';
import { userHas } from '../../auth/auth.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { Dropdown } from 'react-bootstrap';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { IAction, getAction } from '../../common/components/actions/actions';
import { AiFillDelete } from 'react-icons/ai';
import { MainLayoutComponent } from '../../common/components/layout/mainlayout-component';
import { ComponentResolver } from '../../common/components/layout/component-resolver';
import { UIContextType } from '../../common/components/layout/layout-interfaces';
import { dialogAction } from '../../common/context/actions/dialogAction';

export type AppModuleEditProps = {
  appModuleId?: string | null | undefined;
  onAppModuleCreated?: (appModule: AppModuleDto) => void;
  onAppModuleUpdated?: (appModule: AppModuleDto) => void;
  onAppModuleLoaded?: (appModule: AppModuleDto) => void;
  onCancel?: () => void;
  onDelete?: (appModule: AppModuleDto) => void;
};

const initialState: AppModuleDto = {
  appManifestId: null,
  appModuleYamlDocument: '',
  name: '',
  links: [],
};

export const AppModuleEdit = ({
  appModuleId,
  onAppModuleLoaded,
  onAppModuleCreated,
  onAppModuleUpdated,
  onCancel,
  onDelete,
}: AppModuleEditProps) => {
  const [isCreateMode, setIsCreateMode] = useState(appModuleId == null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [appModule, setAppModule] = useState<AppModuleDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [actions, setActions] = useState([]);
  const [componentPreview, setComponentPreview] = useState<any>(null);
  const [uiContext, setUIContext] = useState<UIContextType>(null);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState<
    number | null
  >(null);

  const onSelectComponent = useCallback((index: number) => {
    setSelectedComponentIndex(index);
  }, []);

  useEffect(() => {
    setIsCreateMode(appModuleId == null);
  }, [appModuleId]);

  const appModuleSchema = useMemo(() => {
    let schema = Yup.object().shape({
      appModuleYamlDocument: Yup.string().required(
        'App Module Yaml Document is required',
      ),
    });

    if (customFields.length > 0) {
      schema = generateValidationSchemaWithCustomFields(customFields, schema);
    }

    return schema;
  }, [customFields]);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        DELETE_APPMODULE_LINK_KEY,
        'Delete',
        <AiFillDelete />,
        async () => {
          const dialogResult = await showDialog({
            dialog: Confirm,
            props: {
              title: `Delete AppModule`,
              message: `Are you sure you want to delete ${appModule.name}?`,
              className: 'delete-modal',
            },
          });
          if (dialogResult) {
            try {
              await deleteAppModuleFx(appModule);
              if (onCancel) onCancel();
            } catch (error) {
              // Handle error here
            }
          }
        },
        appModule?.links,
      ),
    ];
    return activeActions.filter(Boolean);
  }, [appModule]);

  const fetchCustomFields = useCallback(async () => {
    // const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
    //   filter: `customFieldEntityType: ${CustomFieldEntityType.AppModule} AND isInactive: false`,
    // });
    // setCustomFields(fields.items);
  }, []);

  const fetchAppModuleData = useCallback(async () => {
    const appModuleDto = await getAppModuleFx({ appModuleId });
    setAppModule(appModuleDto);
    onYamlChange(appModuleDto.appModuleYamlDocument);
    if (onAppModuleLoaded) onAppModuleLoaded(appModuleDto);
    setIsLoading(false);
  }, [appModuleId, onAppModuleLoaded]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    if (!isCreateMode) {
      fetchAppModuleData();
    } else {
      setAppModule({ ...initialState });
      setIsLoading(false);
    }
  }, [isCreateMode, fetchAppModuleData]);

  const onSubmit = useCallback(
    async (data: AppModuleDto) => {
      setIsSubmitting(true);
      try {
        const result = isCreateMode
          ? await createAppModuleFx(data)
          : await updateAppModuleFx({
              ...data,
              appModuleDocument: undefined,
            });
        isCreateMode
          ? onAppModuleCreated?.(result)
          : onAppModuleUpdated?.(result);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isCreateMode, onAppModuleCreated, onAppModuleUpdated],
  );

  const onCustomFieldChange = (result) => {
    setAppModule((prevState) => ({
      ...prevState,
      customValues: result,
    }));
  };

  const action = useCallback(async (actionProps, data) => {
    // if array of actions
    if (Array.isArray(actionProps)) {
      for (const action1 of actionProps) {
        const result = await action(action1, data);
        if (result === false) {
          return;
        }
      }
      return;
    }
    if (actionProps?.dialog) {
      const dialogResult = await dialogAction(actionProps, data, {});
      return dialogResult;
    }
    return true;
  }, []);

  const onYamlChange = (value: string) => {
    try {
      const module = yaml.load(value);
      setUIContext({
        variables: {
          organizationId: 1,
          isDevelopment: true,
        },
        resolver: ComponentResolver([module]).resolver,
        action,
        setParams: () => {},
      });

      setComponentPreview(module.components);
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <AppModuleForm
      id={'appModule-form'}
      initialValues={appModule || initialState}
      onSubmit={onSubmit}
      validationSchema={appModuleSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Additional</Tab>
                <li className="react-tabs__tab react-tabs__tab--button">
                  <ActionsDropdown size="sm" actions={actions} />
                </li>
              </TabList>

              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New AppModule</h3>
                  ) : (
                    <h3 className="header-form">{`Update AppModule ${appModule.name}`}</h3>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <AppModuleForm.AppModuleYamlDocument
                        defaultValue={appModule.appModuleYamlDocument}
                        onChange={onYamlChange}
                      />
                    </div>
                    <div className="col-6">
                      {componentPreview?.length > 0 && (
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            {' '}
                            {selectedComponentIndex !== null
                              ? componentPreview[selectedComponentIndex].name
                              : 'Select Component'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {componentPreview.map((component, index) => (
                              <Dropdown.Item
                                key={index}
                                onClick={() => onSelectComponent(index)}
                              >
                                {component.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      {typeof selectedComponentIndex === 'number' &&
                        componentPreview.length > 0 && (
                          <>
                            <MainLayoutComponent
                              key={
                                componentPreview[selectedComponentIndex].name
                              }
                              variables={uiContext.variables}
                              context={uiContext}
                              {...componentPreview[selectedComponentIndex]}
                            />
                            <hr />
                          </>
                        )}
                    </div>
                  </div>
                </Panel>
              </TabPanel>
            </Tabs>
            <div className="row m-3">
              <div className="col-auto text-right">
                {(userHas(UPDATE_APPMODULE_LINK_KEY, appModule?.links) ||
                  isCreateMode) && (
                  <Button
                    name="save-appModule"
                    type="submit"
                    color="primary"
                    form={'appModule-form'}
                    disabled={isSubmitting}
                  >
                    Save AppModule
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="ml-2"
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </AppModuleForm>
  );
};
