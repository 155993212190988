import React, { useEffect, useState } from 'react';
import { CommodityGrid } from '../../../common/components/grid/commodity-grid.component';
import {
  getCommodity,
  updateCommoditiesTableColumns,
} from '../../../commodities/commodities.store';
import { showDialog } from '../../../common/dialog.store';
import { CommodityDialog } from '../../../commodities/components/commodity.dialog';
import { authStore } from '../../../auth/auth.store';
import {
  Column,
  CommodityDto,
  OrderTypes,
  WarehouseZoneDto,
} from '../../../../models/data.models';
import { BsFilter } from 'react-icons/bs';
import { Button } from '../../../common/components/button/button.component';
import { FiSearch } from 'react-icons/fi';
import { getObjectStrings, sortFn } from '../../../../utils/query.utils';
import { BiMessageAltError } from 'react-icons/bi';

export interface ActivityCommoditiesTableProps {
  commodities: CommodityDto[];
  columns: Column[];
  warehouseZones?: WarehouseZoneDto[];
  sortField?: string;
  onReportPackage?: () => void;
  selectNext?: (commodityId: number) => void;
  goToParcelDetails?: (commodity: CommodityDto) => void;
}

export const ActivityCommoditiesTable = ({
  commodities: defaultCommodities,
  columns,
  warehouseZones,
  sortField = '',
  onReportPackage,
  selectNext,
  goToParcelDetails,
}: ActivityCommoditiesTableProps) => {
  const { user: currentUser } = authStore.getState();

  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState('commodityStatus:Pending');
  const [sort, setSort] = useState(sortField);

  const [commodities, setCommodities] = useState(defaultCommodities);

  const [filteredCommodities, setFilteredCommodities] = useState<
    CommodityDto[]
  >(defaultCommodities);
  const [sortedCommodities, setSortedCommodities] = useState<CommodityDto[]>(
    [],
  );

  useEffect(() => {
    setCommodities(defaultCommodities);
    setFilteredCommodities(defaultCommodities);
  }, [defaultCommodities]);

  const onCommoditySelect = async (commodity, commodityId) => {
    if (commodityId) {
      const commodityToUpdate = await getCommodity({
        commodityId: commodity?.commodityId,
      });

      showDialog({
        dialog: CommodityDialog,
        props: {
          commodity: commodityToUpdate,
          commodityId: commodityToUpdate.commodityId,
          title: 'Update Package',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          isEditModeInCommodities: true,
          saveBtnLabel: 'Save Package',
        },
      }).then((commodity) => {
        if (commodity !== null) {
          const i = commodities.findIndex(
            (x) => x?.commodityId == commodity?.commodityId,
          );
          const newCommodities = commodities;
          newCommodities[i] = commodity;
          setCommodities(newCommodities);
          filterCommodities();
          sortCommmodities();
        }
      });
    }
  };

  const onLimitChanged = (limit: number) => {
    setLimit(limit);
    setOffset(0);
  };

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const filterCommodities = () => {
    setOffset(0);
    setFilteredCommodities(
      commodities
        .map((commodity) => {
          const strings = getObjectStrings(commodity);
          const results = strings?.map((v) => {
            return v.toLowerCase().includes(search.toLowerCase());
          });
          if (results?.includes(true)) return commodity;
          else return null;
        })
        .filter((x) => !!x),
    );
  };

  const sortCommmodities = () => {
    const sorted = filteredCommodities?.sort((a, b) => sortFn(a, b, sort));
    const sliced = sorted.slice(offset, offset + limit);
    setSortedCommodities(sliced);
  };

  useEffect(() => {
    filterCommodities();
  }, [search, commodities]);

  useEffect(() => {
    sortCommmodities();
  }, [limit, offset, sort, filteredCommodities, commodities]);

  return (
    <div>
      <div className={'d-flex align-items-center mb-4'}>
        <div className={'w-100 d-flex'}>
          <div className={'d-flex align-items-center col-6'}>
            <h3 className={'m-0'}>Packages</h3>
            <div className={'d-flex align-items-center w-100'}>
              <FiSearch
                className={'position-relative'}
                style={{ left: '30px' }}
              />
              <input
                type="search"
                className={'form-control w-50 search-with-icon w-auto'}
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                placeholder={'Search'}
              />
            </div>
          </div>
          <div className={'col-6 d-flex justify-content-end'}>
            <div className={'dropdown dropdown-columns ml-2'}>
              {columns.some((column) => column.showFilter) && (
                <div
                  className=""
                  key="dropdownFilterButton"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <Button
                    color={'secondary'}
                    outline
                    style={{ fontSize: '14px' }}
                    className={'d-flex align-items-center'}
                  >
                    <BsFilter className={'mr-2'} size={20} />
                    Filters
                  </Button>
                </div>
              )}
              <div
                className="dropdown-menu px-5"
                aria-labelledby="dropdownFilterButton"
                style={{ minWidth: '500px', minHeight: '300px' }}
              >
                <div>
                  {/*<Filters
                    query={filter}
                    columns={columns}
                    onFilter={setFilter}
                  />*/}
                </div>
              </div>
            </div>
            {onReportPackage && (
              <Button
                className={'ml-2'}
                color={'action-waiting'}
                outline={false}
                onClick={onReportPackage}
                disabled={false}
              >
                <BiMessageAltError className="mr-2" />
                Report damaged
              </Button>
            )}
          </div>
        </div>
      </div>
      <CommodityGrid
        showAllStore={true}
        showEmptyTable={true}
        data={sortedCommodities}
        total={filteredCommodities?.length ?? 0}
        columns={columns}
        rowKeys={['commodityId']}
        onColumnsChanged={updateCommoditiesTableColumns}
        showToolbar={false}
        expandable={false}
        onEdit={null}
        onDelete={null}
        defaultExpanded={true}
        onSelect={onCommoditySelect}
        parentOrderType={OrderTypes.Purchase}
        limit={limit}
        onLimitChanged={onLimitChanged}
        offset={offset}
        onPageChanged={onPageChanged}
        sort={sort}
        onSort={setSort}
        filter={filter}
        onFilter={setFilter}
        warehouseZones={warehouseZones}
        selectNext={selectNext}
        goToParcelDetails={goToParcelDetails}
      ></CommodityGrid>
    </div>
  );
};
