import { useStore } from 'effector-react';
import {
  appComponentsStore,
  getAppComponentFx,
} from '../../appModules/appComponents.store';
import { MainLayoutComponent } from '../components/layout/mainlayout-component';
import { useContext, useEffect, useMemo, useState } from 'react';
import UiContext from '../context/uiContext';
import { parseTemplate } from '../components/layout/component-hooks';
import { FormikContext } from 'formik';
import { fromConfig } from '../components/layout/variableHandlers/fromConfig';

export const AppComponent = ({
  component,
  variables,
  name,
  props,
  onComponentChange,
}: {
  component: any;
  variables: any;
  name?: string;
  props?: any;
  onComponentChange?: (component: any) => void;
}) => {
  const { context } = useContext(UiContext);
  const formikContext = useContext(FormikContext);

  const [appComponent, setAppComponent] = useState({} as any);
  const [isLoaded, setIsLoaded] = useState(false);
  const appComponents = useStore(appComponentsStore);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadedOrgConfigs, setIsLoadedOrgConfigs] = useState(false);
  const [componentVariables, setComponentVariables] = useState(variables);

  useEffect(() => {
    if (appComponent?.props?.configs && !isLoadedOrgConfigs) {
      const configsPromises = appComponent?.props?.configs.map((config) => {
        if (config?.name) {
          if (!componentVariables.config?.[config?.name]) {
            return fromConfig(config?.name, config?.path, variables);
          }
        }
      });

      Promise.all(configsPromises)
        .then((configs) => {
          configs.forEach((config) => {
            context?.setStore('configs.' + config?.configName, config);
            setComponentVariables({
              ...componentVariables,
              configs: {
                ...componentVariables.configs,
                [config?.configName]: config,
              },
              ...variables,
            });
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setIsLoadedOrgConfigs(true));
    }
  }, [appComponent, variables]);

  useEffect(() => {
    if (!isLoading) {
      // if component is defined in store
      if (typeof component === 'string') {
        if (!appComponents[component]) {
          setIsLoading(true); // Set loading state to true to prevent duplicate calls

          getAppComponentFx({
            name: parseTemplate(component, formikContext?.values),
          })
            .then((res) => {
              setAppComponent(res);
              setIsLoaded(true);
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setAppComponent(appComponents[component]);
          setIsLoaded(true);
        }
      } else {
        // if component is defined inlined
        setAppComponent(component);
        setIsLoaded(true);
      }
    }
  }, [component, variables]);

  useEffect(() => {
    if (onComponentChange) {
      onComponentChange({
        ...appComponent,
        context,
        variables,
      });
    }
  }, [appComponent, variables]);

  const layout = useMemo(() => {
    const componentProps = {
      ...appComponent?.layout,
      name: name ?? appComponent?.layout?.name,
      props: {
        ...appComponent?.layout?.props,
        ...props,
        label: parseTemplate(
          props?.label ?? appComponent?.props?.label,
          variables,
        ),
        placeholder: props?.placeholder,
        icon: props?.icon ?? appComponent?.props?.icon,
        disabled: parseTemplate(
          props?.disabled ?? appComponent?.props?.disabled,
          {
            ...variables,
            ...(formikContext?.values as object),
            prefix: props?.prefix,
          },
          props?.prefix,
        ),
        filter: props?.filter ?? appComponent?.props?.filter,
        prefix: props?.prefix,
        options: {
          ...appComponent?.layout?.props?.options,
          ...props?.options,
        },
        onSelectValue: props?.onSelectValue,
        parentId: parseTemplate(props?.parentId, variables),
        parentType: parseTemplate(props?.parentType, variables),
      },
    };
    return componentProps;
  }, [appComponent?.layout, name, props, variables]);

  // parse variables from root component props
  useEffect(() => {
    if (props?.variables) {
      setComponentVariables({
        ...variables,
        ...parseTemplate(props?.variables, variables),
      });
    } else {
      setComponentVariables(variables);
    }
  }, [variables, props]);

  if (!isLoaded) {
    return <div>Loading Component...</div>;
  }
  if (!appComponent) {
    return (
      <div className="alert alert-danger">
        {`Component ${component} not found`}
      </div>
    );
  }
  return (
    <MainLayoutComponent
      layout={layout}
      context={context}
      props={layout.props}
      variables={componentVariables}
    />
  );
};
