import {
  ScanningResult,
  onScannerResult,
} from '../../../modules/barcodeScanner/scanner.store';

// https://developer.fedex.com/api/en-us/catalog/open-ship/docs.html
export function extractTrackingCodeFromFedexLabel(
  payload: ScanningResult,
): ScanningResult {
  if (payload.data && payload.data.length === 34) {
    // FDX 1D barcode, 34 digit code fix (Fedex)
    payload.data = payload.data.substring(20, 34).replace(/^0+/, '');
  } else if (payload.data && payload.data.length === 32) {
    // ASTRA Label 32 digit code fix (Fedex)
    payload.data = payload.data.substring(16, 28).replace(/^0+/, '');
  }

  return payload;
}

export const FedexTrackingCodeScannerHook = () => {
  onScannerResult.watch((payload) => {
    // process fedex label
    extractTrackingCodeFromFedexLabel(payload);
  });
};
