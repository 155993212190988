import React, { useEffect, useRef, useState } from 'react';
import {
  ContactDto,
  ContactType,
  EntityTypes,
  OrderEntityDto,
  OrderDto,
  TrackingEventDto,
} from '../../../../models/data.models';
import { Button } from '../../../common/components/button/button.component';
import { BOLManagerForm } from './bol-manager.form';
import * as Yup from 'yup';
import { FormContext } from '../../../common/components/form/form.component';
import { ReactSelectItem } from '../../../../models/custom.models';
import { getOrder, updateOrder } from '../../orders.store';

export type BOLManagerProps = {
  className?: string;
  orderDto?: OrderDto;
  onConfirm?: (bolData: BOLType) => void;
  onCancel?: () => void;
  setTrackingEvents?: (value: React.SetStateAction<TrackingEventDto[]>) => void;
};

export type BOLType = {
  shipperId?: string | number;
  consigneeId?: string | number;
  thirdPartyContactId?: string | number;
  thirdPartyContactName?: string;
  thirdPartyContactType?: string;
  carrierId?: string | number;
  orderPickupId?: number;
  orderDeliveryId?: number;
};

const getInitialState = () => {
  const initialState: BOLType = {
    shipperId: null,
    consigneeId: null,
    thirdPartyContactId: null,
    carrierId: null,
  };
  return initialState;
};

const bolManagerSchema = Yup.object().shape({
  shipperId: Yup.string().required("Can't be blank").nullable(true),
  consigneeId: Yup.string().required("Can't be blank").nullable(true),
});

export const BOLManagerComponent = ({
  orderDto,
  onConfirm,
  onCancel,
  setTrackingEvents,
}: BOLManagerProps) => {
  const bolManagerFormRef = useRef<HTMLFormElement>();
  const [initialValues, setInitialValues] = useState<BOLType>(
    getInitialState(),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shippers, setShippers] = useState<OrderEntityDto[]>(null);
  const [consignees, setConsignees] = useState<OrderEntityDto[]>(null);
  const [carriers, setCarriers] = useState<ContactDto[]>(null);

  useEffect(() => {
    setIsLoading(true);
    updateOrder(orderDto).then(
      () => {
        getOrder({ orderId: orderDto.orderId }).then((order) => {
          const orderPickups = order?.orderEntities?.filter(
            (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
          );
          const orderDeliveries = order?.orderEntities?.filter(
            (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
          );
          setShippers(orderPickups);
          setConsignees(orderDeliveries);
          setCarriers(
            order.carriers.filter(
              (carrier) => carrier.contactType === ContactType.Carrier,
            ),
          );
          setInitialValues((initialValues) => {
            initialValues.shipperId = orderPickups[0].contactId;
            initialValues.consigneeId = orderDeliveries[0].contactId;
            return { ...initialValues };
          });
          setTrackingEvents(order.trackingEvents);
          setIsLoading(false);
        });
      },
      () => {},
    );
  }, []);

  const onSubmit = (data: BOLType) => {
    data.orderPickupId = shippers.find(
      (shipper) => shipper.contactId == data.shipperId,
    ).orderEntityId;
    data.orderDeliveryId = consignees.find(
      (consignee) => consignee.contactId == data.consigneeId,
    ).orderEntityId;
    onConfirm(data);
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <div className={'bol-manager-form'}>
      <BOLManagerForm
        id={'BOLManager'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={bolManagerFormRef}
        validationSchema={bolManagerSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <div>
              <div className="row mb-3">
                <div className="col-6">
                  <BOLManagerForm.ShipperSelect
                    options={shippers
                      ?.map(
                        (shipper): ReactSelectItem => {
                          return {
                            value: shipper.contactId?.toString(),
                            label: shipper.contactName,
                          };
                        },
                      )
                      .filter(
                        (option) =>
                          option.value !== null &&
                          option.value !== undefined &&
                          !isNaN(Number(option.value)),
                      )}
                    defaultValue={
                      shippers
                        ? {
                            value: shippers[0].contactId,
                            label: shippers[0].contactName,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-6">
                  <BOLManagerForm.ConsigneeSelect
                    options={consignees
                      ?.map(
                        (consignee): ReactSelectItem => {
                          return {
                            value: consignee.contactId?.toString(),
                            label: consignee.contactName,
                          };
                        },
                      )
                      .filter(
                        (option) =>
                          option.value !== null &&
                          option.value !== undefined &&
                          !isNaN(Number(option.value)),
                      )}
                    defaultValue={
                      consignees
                        ? {
                            value: consignees[0].contactId,
                            label: consignees[0].contactName,
                          }
                        : null
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <BOLManagerForm.ThirdPartySelect
                    contactTypes={[
                      ContactType.Customer,
                      ContactType.Vendor,
                      ContactType.ForwardingAgent,
                      ContactType.Contact,
                      ContactType.Carrier,
                      ContactType.Driver,
                      ContactType.FactoringCompany,
                      ContactType.SalesPerson,
                    ]}
                    selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact} OR contactType: ${ContactType.Carrier} OR contactType: ${ContactType.Driver} OR contactType: ${ContactType.FactoringCompany} OR contactType: ${ContactType.SalesPerson}`}
                    onChange={(data?: ContactDto) => {
                      setInitialValues((initialValues) => {
                        if (!initialValues) {
                          initialValues = getInitialState();
                        }
                        initialValues.thirdPartyContactId = data?.contactId;
                        initialValues.thirdPartyContactName = data?.name;
                        initialValues.thirdPartyContactType = data?.contactType;
                        return initialValues;
                      });
                    }}
                    defaultValue={
                      initialValues &&
                      initialValues.thirdPartyContactId != null &&
                      initialValues.thirdPartyContactName != null &&
                      initialValues.thirdPartyContactType != null
                        ? {
                            contactId: initialValues.thirdPartyContactId,
                            name: initialValues.thirdPartyContactName,
                            contactType: initialValues.thirdPartyContactType,
                          }
                        : ''
                    }
                  />
                </div>
                <div className="col-6">
                  <BOLManagerForm.CarrierSelect
                    options={carriers
                      ?.map(
                        (carrier): ReactSelectItem => {
                          return {
                            value: carrier.contactId?.toString(),
                            label: carrier.name,
                          };
                        },
                      )
                      .filter(
                        (option) =>
                          option.value !== null &&
                          option.value !== undefined &&
                          !isNaN(Number(option.value)),
                      )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-block ml-auto"
                    name={'select'}
                  >
                    Confirm
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    onClick={onCancel}
                    type="button"
                    color="secondary"
                    className="btn-block ml-auto"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </FormContext.Consumer>
      </BOLManagerForm>
    </div>
  );
};
