import {
  PaymentTermDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  PaymentTermDtoPagedResult,
  CreatePaymentTermCommandValues,
  UpdatePaymentTermCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_PAYMENTTERMS_LINK_KEY = 'get-paymentterms';
export const CREATE_PAYMENTTERM_LINK_KEY = 'create-paymentterm';
export const GET_PAYMENTTERM_LINK_KEY = 'get-paymentterm';
export const UPDATE_PAYMENTTERM_LINK_KEY = 'update-paymentterm';
export const DELETE_PAYMENTTERM_LINK_KEY = 'delete-paymentterm';
export const IMPORT_PAYMENTTERMS_LINK_KEY = 'import-paymentterms';
export const EXPORT_PAYMENTTERMS_LINK_KEY = 'export-paymentterms';
export const PAYMENTTERM_ENTITY_NAME = 'Payment Term';

export interface GetPaymentTermParams {
  paymentTermId: number;
}

export interface ImportPaymentTermsParams {
  organizationId: number;
}

export interface ExportPaymentTermsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getPaymentTermRequest = async (
  resource: LinkResourceBaseDto | null,
  { paymentTermId }: GetPaymentTermParams,
): Promise<PaymentTermDto> => {
  if (resource && paymentTermId) {
    const getPaymentTermLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENTTERM_LINK_KEY,
    );
    if (getPaymentTermLink) {
      const result = await execLink(getPaymentTermLink, { paymentTermId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getPaymentTermsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PaymentTermDtoPagedResult> => {
  if (resource) {
    const getPaymentTermsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENTTERMS_LINK_KEY,
    );
    if (getPaymentTermsListLink) {
      const result = await execLink(getPaymentTermsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PAYMENTTERMS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createPaymentTermRequest = async (
  resource: LinkResourceBaseDto,
  paymentTerm: CreatePaymentTermCommandValues,
) => {
  const createPaymentTermLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PAYMENTTERM_LINK_KEY,
  );
  if (createPaymentTermLink) {
    const result = await execLink(createPaymentTermLink, paymentTerm);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updatePaymentTermRequest = async (
  resource: LinkResourceBaseDto,
  paymentTerm: UpdatePaymentTermCommandValues,
) => {
  const updatePaymentTermLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PAYMENTTERM_LINK_KEY,
  );
  if (updatePaymentTermLink) {
    const result = await execLink(updatePaymentTermLink, paymentTerm);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deletePaymentTermRequest = async (paymentTerm: PaymentTermDto) => {
  const deletePaymentTermLink = paymentTerm.links?.find(
    (x: LinkDto) => x.rel === DELETE_PAYMENTTERM_LINK_KEY,
  );
  if (deletePaymentTermLink) {
    const result = await execLink(deletePaymentTermLink, paymentTerm);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importPaymentTermsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportPaymentTermsParams,
  file: File,
) => {
  const importPaymentTermsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_PAYMENTTERMS_LINK_KEY,
  );
  if (importPaymentTermsLink) {
    const result = await postFormData(importPaymentTermsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_PAYMENTTERMS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportPaymentTermsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportPaymentTermsParams,
) => {
  const exportPaymentTermsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_PAYMENTTERMS_LINK_KEY,
  );
  if (exportPaymentTermsLink) {
    const result = await downloadLinkFile(exportPaymentTermsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_PAYMENTTERMS_LINK_KEY} is not allowed or missing link action`,
  );
};
