import { log } from 'console';
import * as Sentry from '@sentry/browser';

const logger = {
  log: (message, ...optionalParams) => {
    logger.info(message, ...optionalParams);
  },
  info: (message, ...optionalParams) => {
    // tslint:disable-next-line:no-console
    console.info(
      `[INFO] ${new Date().toISOString()}: ${message}`,
      ...optionalParams,
    );
  },
  warn: (message, ...optionalParams) => {
    // tslint:disable-next-line:no-console
    console.warn(
      `[WARN] ${new Date().toISOString()}: ${message}`,
      ...optionalParams,
    );
  },
  error: (message, ...optionalParams) => {
    // report to Sentry Browser
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(new Error(message));
    }

    // tslint:disable-next-line:no-console
    console.error(
      `[ERROR] ${new Date().toISOString()}: ${message}`,
      ...optionalParams,
    );
  },
  debug: (message, ...optionalParams) => {
    if (process.env.NODE_ENV === 'development') {
      // tslint:disable-next-line:no-console
      console.debug(
        `[DEBUG] ${new Date().toISOString()}: ${message}`,
        ...optionalParams,
      );
    }
  },
};

export default logger;
