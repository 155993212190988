import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import {
  ContactType,
  CustomFieldEntityType,
  GetContactsQuery,
} from '../../../models/data.models';
import { ContactsList } from '../components/contacts-list.component';
import {
  CONTACTS_CREATE_PATH,
  CONTACTS_EDIT_PATH,
  CONTACTS_LIST_PATH,
} from '../contact.route';
import { CREATE_CONTACT_LINK_KEY, GetContactParams } from '../contacts.service';
import { userHas } from '../../auth/auth.store';
import { contactStore, getContactsDefaultColumns } from '../contacts.store';
import { useStore } from 'effector-react';
import { ContactDialog } from '../components/contact.dialog';

export type ContactsListScreenProps = {
  pageTitle: string;
  group: string;
};

const generateHistoryPath = (
  organizationId: string,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    view: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + CONTACTS_LIST_PATH, {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

const contactType = ContactType.Contact;
const customFieldEntityType = CustomFieldEntityType.Contact;

export const ContactsListScreen = ({
  pageTitle,
  group,
}: ContactsListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();

  const { defaultSort, defaultLimit } = useStore(contactStore);

  const navigate = useNavigate();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + CONTACTS_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `contactType:${contactType}`,
    search = '',
    view = '',
  } = useQuery() as GetContactsQuery;

  const goToDetails = (contactId: number = 0) => {
    const createOrEdit =
      contactId === 0 ? CONTACTS_CREATE_PATH : CONTACTS_EDIT_PATH;

    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      contactId: contactId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const params = { offset, limit, sort, filter, search, view };
  const onQueryChange = (newParams: Partial<typeof params>) => {
    const mergedParams = { ...params, ...newParams };
    const newPath = generateHistoryPath(organizationId, mergedParams);
    navigate(newPath);
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + CONTACTS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getContactsDefaultColumns(contactType);
  };

  return (
    <InternalLayout
      group={group}
      title={pageTitle}
      createPath={userHas(CREATE_CONTACT_LINK_KEY) ? createPath : null}
      createLabel={'Contact'}
    >
      <ContactsList
        search={search}
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        contactType={contactType}
        customFieldEntityType={customFieldEntityType}
        selectDialogType={ContactDialog}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onViewChanged={onViewChanged}
        onPageChanged={(page) => onQueryChange({ offset: page * limit })}
        onLimitChanged={(limit) => onQueryChange({ limit })}
        onSearch={(search) => onQueryChange({ search, offset: 0 })}
        onSort={(sort) => onQueryChange({ sort })}
        onFilter={(filter) => onQueryChange({ filter, offset: 0 })}
      />
    </InternalLayout>
  );
};
