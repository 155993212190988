import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { OrderForm } from './../../orders/components/order.form';
import {
  AttachmentParentType,
  ChargeDto,
  ChargeType,
  CommodityDto,
  CommodityStatuses,
  ContactDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
  DimensionsUnit,
  DivisionDto,
  DocumentTemplateType,
  EntityTypes,
  ModeOfTransportationDto,
  OrderDto,
  OrderTypes,
  PackageTypeDto,
  PaidAs,
  PaymentTermDto,
  TrackingEventDto,
  VisibleTransactions,
  VolumeUnit,
  WeightUnit,
} from '../../../models/data.models';
import { createOrder, getOrder, updateOrder } from '../../orders/orders.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CommodityForm } from '../../commodities/components/commodity.form';
import { organizationsStore } from '../../organization/organization.store';
import { CommoditiesForOrderList } from '../../commodities/components/commodities-forOrder-list.component';
import {
  FormattedProfitValues,
  OrderEditFormDto,
  OrderEntityEditFormDto,
  ProfitValues,
} from '../../../models/custom.models';
import { GetCommodityParams } from '../../commodities/commodities.service';
import { showDialog } from '../../common/dialog.store';
import { CommodityDialog } from '../../commodities/components/commodity.dialog';
import { useLocation } from 'react-router-dom';
import { ChargeForOrderList } from '../../charges/components/charge-forOrder-list.component';
import {
  CREATE_CHARGE_LINK_KEY,
  GetChargeParams,
} from '../../charges/charges.service';
import { ChargeDialog } from '../../charges/components/charge.dialog';
import { ChargeForm } from '../../charges/components/charge.form';
import { AiFillPlusCircle } from 'react-icons/ai';
import { ShipperForm } from '../../orders//components/form-items/shipper-form';
import { FormContext } from '../../common/components/form/form.component';
import { ConsigneeForm } from '../../orders//components/form-items/consignee-form';
import { SendEmailDialog } from '../../sendEmail/components/sendEmail.dialog';
import {
  generateValidationSchemaWithCustomFields,
  getEnumKeyByValue,
  getPaidAs,
  validateNumberInput,
  validatePositiveNumberInput,
} from '../../../utils/helper.utils';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import {
  getDocumentTemplatesFx,
  getRenderedDocumentLink,
} from '../../documentTemplates/documentTemplates.store';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import {
  ChargeDefaultValues,
  CommodityDefaultValues,
  EntityDefaultValues,
  OrderDefaultValues,
  ProfitDefaultValues,
} from '../../common/DefaultValues';
import * as Yup from 'yup';
import { getFormattedPrice } from '../../../utils/formatting.utils';
import { currencyStore } from '../../currencies/currencies.store';
import { getContacts } from '../../contacts/contacts.store';
import { authStore, userHas } from '../../auth/auth.store';
import { CarrierDialog } from '../../carriers/components/carrier.dialog';
import { DriverDialog } from '../../drivers/components/driver.dialog';
import { FormikProps, FormikValues } from 'formik';
import { getEventDefinitionFx } from '../../eventDefinitions/eventDefinitions.store';

export type QuoteEditProps = {
  orderId: number | null;
  onQuoteCreated?: (quote: OrderDto) => void;
  onQuoteUpdated?: (quote: OrderDto) => void;
  onQuoteLoaded?: (quote: OrderDto) => void;
  onCancel?: () => void;
};
const getInitialStateOrderPickupEditForm = () => {
  const initialStateOrderPickupEditForm: OrderEntityEditFormDto = {
    created: null,
    createdBy: null,
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderId: 0,
    orderEntityId: 0,
    orderEntitySequence: 0,
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    customValues: { pickDate: null, shipperNotes: '', shipperFilter: '' },
    entityType: EntityTypes.Shipper,
  };
  return initialStateOrderPickupEditForm;
};

const getInitialStateOrderDeliveryEditForm = () => {
  const initialStateOrderDeliveryEditForm: OrderEntityEditFormDto = {
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    created: null,
    createdBy: null,
    entityType: EntityTypes.Consignee,
    customValues: {
      deliveryDate: null,
      deliveryNotes: '',
      consigneeFilter: '',
    },
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderEntityId: 0,
    orderEntitySequence: 0,
    orderId: 0,
  };
  return initialStateOrderDeliveryEditForm;
};
const initialStateCommodity: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  commodityTypeId: null,
  commodityTypeCode: CommodityDefaultValues.description,
  customValues: CommodityDefaultValues.customValues,
  valueByTotal: CommodityDefaultValues.valueByTotal,
};
const initialProfitValues: ProfitValues = {
  expense: ProfitDefaultValues.expense,
  income: ProfitDefaultValues.income,
  profit: ProfitDefaultValues.profit,
};

const getInitialState = () => {
  const initialState: OrderEditFormDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: null,
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntities: [
      getInitialStateOrderPickupEditForm(),
      getInitialStateOrderDeliveryEditForm(),
    ],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    orderStatusId: OrderDefaultValues.orderStatusId,
    links: [],
  };
  return initialState;
};

let quoteSchema = Yup.object().shape({
  billToContactId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

const numberSchema = Yup.string()
  .transform((value) => (value === null ? '' : value))
  .test('numberFormat', 'Incorrect number format', (value) => {
    return (
      (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
        value?.toString(),
      ) &&
        Number(value) < Number.MAX_SAFE_INTEGER &&
        Number(value) > Number.MIN_SAFE_INTEGER) ||
      value === '' ||
      value === undefined
    );
  })
  .test('length', 'Max value is 999999', (value) => {
    return value === undefined || Number(value) <= 999999;
  })
  .nullable(true);

const commoditySchema = Yup.object().shape({
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
});

export const QuoteEdit = ({
  orderId = 0,
  onQuoteLoaded = () => {},
  onQuoteCreated = () => {},
  onQuoteUpdated = () => {},
  onCancel = () => {},
}: QuoteEditProps) => {
  const { user: currentUser } = authStore.getState();
  const { currentOrganization } = organizationsStore.getState();

  const limit = 20;
  const isCreateMode = !orderId || orderId == 0;

  const commodityFormRef = useRef<HTMLFormElement>();
  const orderFormRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const forceUpdate: () => void = useState()[1].bind(null, {});

  const [
    quoteContextFormValue,
    setQuoteContextFormValue,
  ] = useState<OrderEditFormDto | null>(null);
  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    getInitialState(),
  );

  const [
    applyToContactPaidAs,
    setApplyToContactPaidAs,
  ] = useState<PaidAs | null>(null);
  const [
    applyToCarrierPaidAs,
    setApplyToCarrierPaidAs,
  ] = useState<PaidAs | null>(null);

  const [packageType, setPackageType] = useState<{
    packageTypeId: string;
    name: string;
  }>(null);

  const [
    profitValues,
    setProfitValues,
  ] = useState<FormattedProfitValues | null>(null);

  const [commodities, setCommodities] = useState<CommodityDto[] | null>([]);
  const [fakeCommodityIndex, setFakeCommodityIndex] = useState<number>(null);

  const [charges, setCharges] = useState<ChargeDto[] | null>([]);
  const [trackingEvents, setTrackingEvents] = useState<
    TrackingEventDto[] | null
  >([]);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [carrier, setCarrier] = useState<ContactDto>(null);

  const [
    quoteDocumentTemplatesCount,
    setQuoteDocumentTemplatesCount,
  ] = useState<number>(0);

  const isOrgAdminOrOperation: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;

  const contactTypeToDialog = (contactType: string) => {
    switch (contactType) {
      case ContactType.Carrier:
        return CarrierDialog;
      case ContactType.Driver:
        return DriverDialog;
      default:
        return null;
    }
  };

  useEffect(() => {
    getDocumentTemplatesFx({
      filter: `documentTemplateType:QuoteConfirmationDocument AND isInactive: false`,
    }).then((documentTemplates) => {
      setQuoteDocumentTemplatesCount(documentTemplates.items?.length);
    });
  }, []);

  useEffect(() => {
    initialProfitValues.profit =
      initialProfitValues.income - initialProfitValues.expense;
    const income = getFormattedPrice(
      initialProfitValues.income,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const expense = getFormattedPrice(
      initialProfitValues.expense,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const profit = getFormattedPrice(
      initialProfitValues.profit,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    setProfitValues({ income, expense, profit });
    setQuoteContextFormValue((quoteContextFormValueDto) => {
      quoteContextFormValueDto = getInitialState();
      return { ...quoteContextFormValueDto };
    });
  }, []);
  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Order} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        quoteSchema = generateValidationSchemaWithCustomFields(
          customFields,
          quoteSchema,
        );
        setCustomFields(customFields);
      },
      () => {},
    );
    getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
      (result) => {
        const defaultDispatcher = result.items.find(
          (employee) => employee.userEmployee?.userId == currentUser?.userId,
        );
        setQuoteContextFormValue((quoteContextFormValueDto) => {
          quoteContextFormValueDto.employeeContactName =
            defaultDispatcher?.name;
          quoteContextFormValueDto.employeeContactId =
            defaultDispatcher?.contactId;
          setInitialValues({ ...quoteContextFormValueDto });
          return { ...quoteContextFormValueDto };
        });
      },
      () => {},
    );
    if (isCreateMode) {
      setFakeCommodityIndex(0);
      setIsLoading(false);
    } else if (orderId) {
      getOrder({ orderId }).then(
        (orderDto: OrderDto) => {
          if (orderDto.trackingEvents) {
            orderDto.trackingEvents.forEach((trackingEvent) => {
              if (trackingEvent.eventDefinitionId) {
                getEventDefinitionFx({
                  eventDefinitionId: trackingEvent.eventDefinitionId,
                }).then(
                  (eventDefinition) =>
                    (trackingEvent.eventName = eventDefinition.eventName),
                );
              }
            });
          }

          setQuoteContextFormValue((quoteContextFormValueDto) => {
            if (!quoteContextFormValueDto) {
              quoteContextFormValueDto = getInitialState();
            }
            quoteContextFormValueDto.orderId = orderDto.orderId;
            quoteContextFormValueDto.orderStatus = orderDto.orderStatus;
            quoteContextFormValueDto.orderNumber = orderDto.orderNumber;
            orderDto.charges.forEach((chargeDto) => {
              if (chargeDto.chargeType === ChargeType.IncomeCharge) {
                chargeDto.income = chargeDto.amount;
              } else {
                chargeDto.expense = chargeDto.amount;
              }
            });
            quoteContextFormValueDto.charges = orderDto.charges;
            quoteContextFormValueDto.trackingEvents = orderDto.trackingEvents;
            if (orderDto?.orderEntities && orderDto?.orderEntities.length > 0) {
              quoteContextFormValueDto.orderEntities = orderDto?.orderEntities?.map<OrderEntityEditFormDto>(
                (item) => {
                  return {
                    orderId: item.orderId,
                    created: item.created,
                    createdBy: item.createdBy,
                    lastModified: item.lastModified,
                    lastModifiedBy: item.lastModifiedBy,
                    links: item.links,
                    orderEntityId: item.orderEntityId,
                    orderEntitySequence: item.orderEntitySequence,
                    contactAddressId: item.contactAddressId,
                    contactAddressName: item.contactAddressName,
                    contactId: item.contactId,
                    contactName: item.contactName,
                    contactType: item.contactType,
                    filter: item.contactId
                      ? `contactId:${item.contactId}`
                      : ' ',
                    customValues: item.customValues,
                    entityType: item.entityType,
                  };
                },
              );
            } else {
              quoteContextFormValueDto.orderEntities = [
                getInitialStateOrderPickupEditForm(),
                getInitialStateOrderDeliveryEditForm(),
              ];
            }
            quoteContextFormValueDto.billToContactId = orderDto.billToContactId;
            quoteContextFormValueDto.billToContactName =
              orderDto.billToContactName;
            quoteContextFormValueDto.billToContactType =
              orderDto.billToContactType;
            quoteContextFormValueDto.carrierContactId =
              orderDto.carriers[0]?.contactId;
            quoteContextFormValueDto.carrierContactName =
              orderDto.carriers[0]?.name;
            quoteContextFormValueDto.commodities = orderDto.commodities;
            quoteContextFormValueDto.commodities.forEach((commodity) => {
              commodity.commodityStatus = commodity.commodityStatus;
            });
            quoteContextFormValueDto.weighTotal = orderDto.weighTotal;
            quoteContextFormValueDto.volumeTotal = orderDto.volumeTotal;
            quoteContextFormValueDto.totalPcsCrt = orderDto.totalPcsCrt;
            quoteContextFormValueDto.salespersonContactName =
              orderDto.salespersonContactName;
            quoteContextFormValueDto.salespersonContactId =
              orderDto.salespersonContactId;
            quoteContextFormValueDto.organizationId = orderDto.organizationId;
            quoteContextFormValueDto.links = orderDto.links;
            quoteContextFormValueDto.lastModifiedBy = orderDto.lastModifiedBy;
            quoteContextFormValueDto.lastModified = orderDto.lastModified;
            if (orderDto.employeeContactName && orderDto.employeeContactId) {
              quoteContextFormValueDto.employeeContactName =
                orderDto.employeeContactName;
              quoteContextFormValueDto.employeeContactId =
                orderDto.employeeContactId;
            }
            quoteContextFormValueDto.divisionName = orderDto.divisionName;
            quoteContextFormValueDto.divisionId = orderDto.divisionId;
            quoteContextFormValueDto.equipmentTypeId = orderDto.equipmentTypeId;
            quoteContextFormValueDto.equipmentTypeName =
              orderDto.equipmentTypeName;
            quoteContextFormValueDto.createdBy = orderDto.createdBy;
            quoteContextFormValueDto.created = orderDto.created;
            quoteContextFormValueDto.carriers = orderDto.carriers;
            quoteContextFormValueDto.customValues = orderDto.customValues;
            quoteContextFormValueDto.trackingNumber = orderDto.trackingNumber;

            quoteContextFormValueDto.modeOfTransportationId =
              orderDto.customValues['modeOfTransportationId'];
            quoteContextFormValueDto.modeOfTransportationDescription =
              orderDto.customValues['modeOfTransportationDescription'];
            quoteContextFormValueDto.paymentTermId =
              orderDto.customValues['paymentTermId'];
            quoteContextFormValueDto.paymentTermDescription =
              orderDto.customValues['paymentTermDescription'];
            quoteContextFormValueDto.expDate = orderDto.customValues['expDate'];

            setInitialValues({ ...quoteContextFormValueDto });
            return { ...quoteContextFormValueDto };
          });
          orderDto.billToContactId
            ? getPaidAs(orderDto.billToContactId).then((paidAs) => {
                setApplyToContactPaidAs(paidAs);
              })
            : setApplyToContactPaidAs(ChargeDefaultValues.paidAs);
          orderDto.carriers[0]?.contactId
            ? setApplyToCarrierPaidAs(orderDto.carriers[0].paidAs)
            : setApplyToCarrierPaidAs(ChargeDefaultValues.paidAs);
          setCarrier(orderDto?.carriers as ContactDto);
          onQuoteLoaded(orderDto);
          setCommodities(orderDto.commodities);
          setFakeCommodityIndex(
            (orderDto.commodities[orderDto.commodities.length - 1]
              ?.commodityId +
              1) |
              0,
          );
          setCharges(orderDto.charges);
          countProfitValue(orderDto.charges);
          setTrackingEvents(orderDto.trackingEvents);
          setIsLoading(false);
        },
        () => {},
      );
    } else {
      throw new Error('Order keys were not provided');
    }
  }, [orderId]);
  useEffect(() => {
    if (isLoading === false)
      setQuoteContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.commodities = commodities;
        return orderEditFormDto;
      });
  }, [commodities]);
  useEffect(() => {
    if (isLoading === false) {
      setQuoteContextFormValue((orderDto) => {
        if (!orderDto) {
          orderDto = getInitialState();
        }
        orderDto.charges = charges;
        return { ...orderDto };
      });
      countProfitValue(charges);
    }
  }, [charges]);
  useEffect(() => {
    if (isLoading === false)
      setQuoteContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.trackingEvents = trackingEvents;
        return orderEditFormDto;
      });
  }, [trackingEvents]);

  const goToDetailsCommodity = (commodityParams: GetCommodityParams) => {
    const cloneCommodity = Object.assign({}, commodityParams.commodity);
    if (commodityParams.commodity) {
      showDialog({
        dialog: CommodityDialog,
        props: {
          title: 'Update Commodity',
          commodity: commodityParams?.commodity,
          commodityId: commodityParams?.commodity?.commodityId,
          className: 'commodity-modal',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          isEditMode: true,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: CommodityDto[] = commodities.map((obj) => {
              const compareCommodityResult =
                obj.commodityId === commodityParams?.commodity.commodityId;
              if (compareCommodityResult) obj = result;
              return obj;
            });
            setCommodities(
              itemsResult.sort((a, b) => a.commodityId - b.commodityId),
            );
          } else {
            setCommodities((oldCommodities) => {
              const index = oldCommodities.findIndex(
                (commodity) =>
                  commodity.commodityId === cloneCommodity.commodityId,
              );
              oldCommodities[index] = cloneCommodity;
              return oldCommodities;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToDetailsCharge = (chargeParams: GetChargeParams) => {
    const cloneCharge = Object.assign({}, chargeParams.charge);
    if (chargeParams.charge) {
      showDialog({
        dialog: ChargeDialog,
        props: {
          title: 'Update Charge',
          charge: chargeParams?.charge,
          chargeId: chargeParams?.charge?.chargeId,
          chargeType: chargeParams?.charge?.chargeType,
          className: 'charge-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          charges: charges,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: ChargeDto[] = charges.map((obj) => {
              const compareChargeResult =
                obj.chargeId === chargeParams?.charge.chargeId;
              if (compareChargeResult) obj = result;
              return obj;
            });
            setCharges(itemsResult.sort((a, b) => a.chargeId - b.chargeId));
          } else {
            setCharges((oldCharges) => {
              const index = oldCharges.findIndex(
                (charge) => charge.chargeId === cloneCharge.chargeId,
              );
              oldCharges[index] = cloneCharge;
              return oldCharges;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const countProfitValue = (charges: ChargeDto[]) => {
    const { defaultCurrency } = currencyStore?.getState();
    setDefaultCurrency(defaultCurrency);
    setProfitValues((profitValue) => {
      const initialZero = getFormattedPrice(0, defaultCurrency?.decimalPlaces);
      profitValue.income = initialZero;
      profitValue.expense = initialZero;
      profitValue.profit = initialZero;
      charges.forEach((item) => {
        if (item.isDeleted) return;
        let totalPrice = item.price * item.quantity;
        if (item.chargeType === ChargeType.IncomeCharge)
          profitValue.income = (
            Number(profitValue.income) + Number(totalPrice)
          ).toString();
        else
          profitValue.expense = (
            Number(profitValue.expense) + Number(totalPrice)
          ).toString();
      });
      profitValue.profit = getFormattedPrice(
        Number(profitValue.income) - Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.income = getFormattedPrice(
        Number(profitValue.income),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.expense = getFormattedPrice(
        Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      return { ...profitValue };
    });
  };
  const getCommodities = (): CommodityDto[] => {
    return commodities?.sort((a, b) => a.commodityId - b.commodityId);
  };
  const getCharges = (): ChargeDto[] => {
    return charges?.sort((a, b) => a.chargeId - b.chargeId);
  };
  const getTrackingEvents = (): TrackingEventDto[] => {
    let events = trackingEvents;
    events?.forEach((event) => {
      if (typeof event.eventDate === 'object') {
        event.eventDate = event.eventDate.toISOString();
      }
    });
    setTrackingEvents(events);
    return events?.sort(
      (a, b) =>
        new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
    );
  };
  const getTotalPcsCrt = () => {
    let sumPieces: number = 0;
    commodities?.forEach((commodity) => {
      sumPieces += Number(commodity.pieces);
    });
    return sumPieces;
  };
  const getWeightTotal = () => {
    let weightTotal = 0;
    commodities?.forEach((commodity) => {
      weightTotal += commodity.weightTotal;
    });
    return weightTotal;
  };
  const getVolumeTotal = () => {
    let volumeTotal = 0;
    commodities?.forEach((commodity) => {
      volumeTotal += commodity?.volumeTotal;
    });
    return volumeTotal;
  };

  const onChargeCreated = (charge: ChargeDto) => {
    if (!charges) {
      const initialCharge: ChargeDto[] = [];
      setCharges(initialCharge);
    }
    setCharges((chargesDto) => [...chargesDto, charge]);
  };

  const onShipperDateChange = (
    { setFieldValue },
    date?: any,
    setShipperDateIndex?: number,
  ) => {
    setQuoteContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperDateIndex]) {
        orderPickups[
          setShipperDateIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperDateIndex].customValues['pickDate'] = new Date(
        date,
      );
      setFieldValue(
        `orderEntities[${setShipperDateIndex}].pickDate`,
        new Date(date),
      );
      return { ...orderDto };
    });
  };
  const onDeliveryDateChange = (
    { setFieldValue },
    date?: any,
    setDeliveryDateIndex?: number,
  ) => {
    setQuoteContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setDeliveryDateIndex]) {
        orderDeliveries[
          setDeliveryDateIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setDeliveryDateIndex].customValues[
        'deliveryDate'
      ] = new Date(date);
      setFieldValue(
        `orderDeliveries[${setDeliveryDateIndex}].deliveryDate`,
        new Date(date),
      );
      return { ...orderDto };
    });
  };
  const onShipperNoteChange = (data?: any, setShipperNoteIndex?: number) => {
    setQuoteContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperNoteIndex]) {
        orderPickups[
          setShipperNoteIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperNoteIndex].customValues['shippingNotes'] =
        data.target.value;
      return { ...orderDto };
    });
  };
  const onDeliveryNoteChange = (data?: any, setConsigneeNoteIndex?: number) => {
    setQuoteContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setConsigneeNoteIndex]) {
        orderDeliveries[
          setConsigneeNoteIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setConsigneeNoteIndex].customValues['deliveryNotes'] =
        data.target.value;
      return { ...orderDto };
    });
  };
  const setShipperFilter = (data?: any, setShipperFilterIndex?: number) => {
    setQuoteContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperFilterIndex]) {
        orderPickups[
          setShipperFilterIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperFilterIndex].customValues['shipperFilter'] = data;
      return { ...orderDto };
    });
  };
  const setDeliveryFilter = (data?: any, setDeliveryFilterIndex?: number) => {
    setQuoteContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setDeliveryFilterIndex]) {
        orderDeliveries[
          setDeliveryFilterIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setDeliveryFilterIndex].customValues[
        'consigneeFilter'
      ] = data;
      return { ...orderDto };
    });
  };
  const setShipperContactAddress = (
    contactAddressId: number,
    addressLine: string,
    shipperContactAddressFilterIndex?: number,
  ) => {
    setQuoteContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[shipperContactAddressFilterIndex]) {
        orderPickups[
          shipperContactAddressFilterIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[
        shipperContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderPickups[
        shipperContactAddressFilterIndex
      ].contactAddressName = addressLine;
      return { ...orderDto };
    });
  };
  const setDeliveryContactAddress = (
    contactAddressId: number,
    addressLine: string,
    deliveryContactAddressFilterIndex?: number,
  ) => {
    setQuoteContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[deliveryContactAddressFilterIndex]) {
        orderDeliveries[
          deliveryContactAddressFilterIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressName = addressLine;
      return { ...orderDto };
    });
  };
  const setShipperContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    shipperContactIndex?: number,
  ) => {
    setQuoteContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups[shipperContactIndex]) {
        orderPickups[
          shipperContactIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[shipperContactIndex].contactId = Number(contactId);
      orderPickups[shipperContactIndex].contactName = name;
      orderPickups[shipperContactIndex].contactType = contactType;
      return { ...orderDto };
    });
  };
  const setDeliveryContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    consigneeContactIndex?: number,
  ) => {
    setQuoteContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries[consigneeContactIndex]) {
        orderDeliveries[
          consigneeContactIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[consigneeContactIndex].contactId = Number(contactId);
      orderDeliveries[consigneeContactIndex].contactName = name;
      orderDeliveries[consigneeContactIndex].contactType = contactType;
      return { ...orderDto };
    });
  };

  const pickupDeliveryFilter = (data: OrderDto) => {
    let orderPickups = data?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    let orderDeliveries = data?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );

    if (orderPickups.length > 1) {
      orderPickups = orderPickups.filter((item) => {
        return item?.contactId != null && !isNaN(item?.contactId);
      });
    }
    if (orderPickups.length === 0) {
      orderPickups.push({
        ...getInitialStateOrderPickupEditForm(),
      });
    }

    if (orderDeliveries.length > 1) {
      orderDeliveries = orderDeliveries.filter((item) => {
        return item?.contactId != null && !isNaN(item?.contactId);
      });
    }
    if (orderDeliveries.length === 0) {
      orderDeliveries.push({
        ...getInitialStateOrderDeliveryEditForm(),
      });
    }

    data.orderEntities = [...orderPickups, ...orderDeliveries];
  };

  const onSubmit = (data: OrderDto) => {
    data.commodities = commodities;
    data.charges = charges;
    data.trackingEvents = trackingEvents;
    data.commodities = data.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    data.customValues = quoteContextFormValue?.customValues;

    pickupDeliveryFilter(data);

    data.orderType = OrderTypes.Quote;

    setIsSending(true);
    if (isCreateMode) {
      createOrder(data)
        .then(
          (result) => {
            onQuoteCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateOrder(data)
        .then(
          (result) => {
            onQuoteUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  const onSubmitCommodityDto = (
    data: CommodityDto,
    { resetForm, setFieldValue },
  ) => {
    const { currentOrganization } = organizationsStore.getState();
    data.commodityId = fakeCommodityIndex;
    data.organizationId = currentOrganization.organizationId;
    data.commodityStatus = null;
    data.dimensionsUnit = DimensionsUnit.In;
    data.weightUnit = WeightUnit.Lb;
    data.volumeUnit = VolumeUnit.Vkg;
    data.weightTotal = data.pieces * data.weight;
    data.volumePiece = data.length * data.height * data.width;
    data.volumeTotal = data.pieces * data.volumePiece;
    setCommodities((commoditiesDto) => [...commoditiesDto, data]);
    setPackageType((packageTypeDto) => {
      packageTypeDto = {
        name: null,
        packageTypeId: null,
      };
      return { ...packageTypeDto };
    });
    resetForm();
    setFieldValue('description', '');
    setFieldValue('pieces', '1');
    setFieldValue('length', '');
    setFieldValue('width', '');
    setFieldValue('height', '');
    setFieldValue('weight', '');
    setFieldValue('packageTypeId', '');
    setFieldValue('packageTypeSelect', '');
    setFieldValue('commodityTypeSelect', '');
    setFakeCommodityIndex(fakeCommodityIndex + 1);
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  const createNewItemOrderPickup = () => {
    setQuoteContextFormValue((quoteContextFormValueDto) => {
      quoteContextFormValueDto?.orderEntities?.push({
        ...getInitialStateOrderPickupEditForm(),
      });
      return { ...quoteContextFormValueDto };
    });
  };
  const createNewItemOrderDelivery = () => {
    setQuoteContextFormValue((quoteContextFormValueDto) => {
      quoteContextFormValueDto?.orderEntities?.push({
        ...getInitialStateOrderDeliveryEditForm(),
      });
      return { ...quoteContextFormValueDto };
    });
  };
  const deleteItemOrderPickup = (indexItemToDelete?: number, context?: any) => {
    setQuoteContextFormValue((quoteContextFormValueDto) => {
      const orderPickups = quoteContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      const orderDeliveries = quoteContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      orderPickups?.splice(indexItemToDelete, 1);
      quoteContextFormValueDto.orderEntities = [
        ...orderPickups,
        ...orderDeliveries,
      ];
      context.setFieldValue(
        'orderEntities',
        quoteContextFormValueDto.orderEntities,
      );
      return { ...quoteContextFormValueDto };
    });
  };

  const deleteItemOrderDelivery = (
    indexItemToDelete?: number,
    context?: any,
  ) => {
    setQuoteContextFormValue((quoteContextFormValueDto) => {
      const orderPickups = quoteContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      const orderDeliveries = quoteContextFormValueDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      orderDeliveries?.splice(indexItemToDelete, 1);
      quoteContextFormValueDto.orderEntities = [
        ...orderPickups,
        ...orderDeliveries,
      ];
      context.setFieldValue(
        'orderEntities',
        quoteContextFormValueDto.orderEntities,
      );
      return { ...quoteContextFormValueDto };
    });
  };

  const getItemsOrderPickup = (context?: any) => {
    const orderPickups = quoteContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    if (orderPickups == null || orderPickups.length === 0) {
      return (
        <ShipperForm
          shipperFilter={orderPickups[0]?.filter}
          allowDelete={false}
          shipperContact={{
            contactId: orderPickups[0]?.contactId,
            name: orderPickups[0]?.contactName,
            contactType: orderPickups[0]?.contactType,
          }}
          onShipperNoteChange={(note: string) => onShipperNoteChange(note, 0)}
          shipperContactAddress={{
            contactAddressId: orderPickups[0]?.contactAddressId?.toString(),
            addressLine: orderPickups[0]?.contactAddressName,
          }}
          onShipperDateChange={(data) => onShipperDateChange(context, data, 0)}
          index={0}
          setShipperContact={(contactId, name, contactType) =>
            setShipperContact(contactId, name, contactType, 0)
          }
          orderPickupDto={orderPickups[0]}
          setShipperFilter={(data) => setShipperFilter(data, 0)}
          setShipperContactAddress={(contactAddressId, addressLine) =>
            setShipperContactAddress(contactAddressId, addressLine, 0)
          }
        />
      );
    }
    if (orderPickups != null && orderPickups !== undefined) {
      return orderPickups?.map((item, index) => (
        <ShipperForm
          shipperFilter={orderPickups[index]?.filter}
          onShipperNoteChange={(note: string) =>
            onShipperNoteChange(note, index)
          }
          allowDelete={orderPickups?.length > 1}
          shipperContact={{
            contactId: orderPickups[index]?.contactId,
            name: orderPickups[index]?.contactName,
            contactType: orderPickups[index]?.contactType,
          }}
          shipperContactAddress={{
            contactAddressId: orderPickups[index]?.contactAddressId?.toString(),
            addressLine: orderPickups[index]?.contactAddressName,
          }}
          onShipperDeleted={() => deleteItemOrderPickup(index, context)}
          onShipperDateChange={(data) =>
            onShipperDateChange(context, data, index)
          }
          index={index}
          setShipperContact={(contactId, name, contactType) =>
            setShipperContact(contactId, name, contactType, index)
          }
          orderPickupDto={orderPickups[index]}
          setShipperFilter={(data) => setShipperFilter(data, index)}
          setShipperContactAddress={(contactAddressId, addressLine) =>
            setShipperContactAddress(contactAddressId, addressLine, index)
          }
          key={index}
        />
      ));
    } else return '';
  };

  const getItemsOrderDelivery = (context?: any) => {
    const orderDeliveries = quoteContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );

    if (orderDeliveries == null || orderDeliveries.length === 0) {
      return (
        <ConsigneeForm
          deliveryFilter={orderDeliveries[0]?.filter}
          allowDelete={false}
          deliveryContact={{
            contactId: orderDeliveries[0]?.contactId,
            name: orderDeliveries[0]?.contactName,
            contactType: orderDeliveries[0]?.contactType,
          }}
          deliveryContactAddress={{
            contactAddressId: orderDeliveries[0]?.contactAddressId?.toString(),
            addressLine: orderDeliveries[0]?.contactAddressName,
          }}
          onDeliveryDateChange={(data) =>
            onDeliveryDateChange(context, data, 0)
          }
          index={0}
          onDeliveryNoteChange={(note) => onDeliveryNoteChange(note, 0)}
          setDeliveryContact={(contactId, name, contactType) =>
            setDeliveryContact(contactId, name, contactType, 0)
          }
          orderDeliveryDto={orderDeliveries[0]}
          setDeliveryFilter={(data) => setDeliveryFilter(data, 0)}
          setDeliveryContactAddress={(contactAddressId, addressLine) =>
            setDeliveryContactAddress(contactAddressId, addressLine, 0)
          }
        />
      );
    }
    if (orderDeliveries != null && orderDeliveries !== undefined) {
      return orderDeliveries?.map((item, index) => (
        <ConsigneeForm
          deliveryFilter={orderDeliveries[index]?.filter}
          allowDelete={orderDeliveries?.length > 1}
          deliveryContact={{
            contactId: orderDeliveries[index]?.contactId,
            name: orderDeliveries[index]?.contactName,
            contactType: orderDeliveries[index]?.contactType,
          }}
          deliveryContactAddress={{
            contactAddressId: orderDeliveries[
              index
            ]?.contactAddressId?.toString(),
            addressLine: orderDeliveries[index]?.contactAddressName,
          }}
          onDeliveryNoteChange={(note) => onDeliveryNoteChange(note, index)}
          onDeliveryDeleted={() => deleteItemOrderDelivery(index, context)}
          onDeliveryDateChange={(data) =>
            onDeliveryDateChange(context, data, index)
          }
          index={index}
          setDeliveryContact={(contactId, name, contactType) =>
            setDeliveryContact(contactId, name, contactType, index)
          }
          orderDeliveryDto={orderDeliveries[index]}
          setDeliveryFilter={(data) => setDeliveryFilter(data, index)}
          setDeliveryContactAddress={(contactAddressId, addressLine) =>
            setDeliveryContactAddress(contactAddressId, addressLine, index)
          }
          key={index}
        />
      ));
    } else return '';
  };

  const onExpDateChange = (date: any, context: FormikProps<FormikValues>) => {
    setQuoteContextFormValue((quoteContextFormValueDto) => {
      if (!quoteContextFormValueDto) {
        quoteContextFormValueDto = getInitialState();
      }
      quoteContextFormValueDto.expDate = new Date(date);
      context.setFieldValue('expDate', quoteContextFormValueDto.expDate);
      return quoteContextFormValueDto;
    });
  };

  const isNoQuoteTemplate = (): boolean => {
    return quoteDocumentTemplatesCount === 0;
  };

  const getQuoteDocument = () => {
    const quoteData: OrderDto = quoteContextFormValue;
    quoteData.orderType = OrderTypes.Quote;
    quoteData.commodities = quoteData.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });
    pickupDeliveryFilter(quoteData);
    updateOrder(quoteData).then(
      () => {
        window.open(
          getRenderedDocumentLink(
            DocumentTemplateType.QuoteConfirmationDocument,
            {
              quoteId: quoteData.orderId,
            },
          ),
          '_blank',
        );
      },
      () => {},
    );
  };

  const sendQuoteEmail = () => {
    const quoteData: OrderDto = quoteContextFormValue;
    quoteData.orderType = OrderTypes.Quote;
    quoteData.commodities = quoteData.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });
    pickupDeliveryFilter(quoteData);
    updateOrder(quoteData).then(
      () => {
        showDialog({
          dialog: SendEmailDialog,
          props: {
            title: 'Send Quote Confirmation',
            className: 'send-email-modal',
            selectedRecipientId: 0,
            recipientIds: [],
            metadata: {
              quoteId: quoteData.orderId,
            },
            emailTemplate: getEnumKeyByValue(
              DocumentTemplateType.QuoteConfirmationEmail,
              DocumentTemplateType,
            ),
            documentTypes: getEnumKeyByValue(
              [DocumentTemplateType.QuoteConfirmationDocument],
              DocumentTemplateType,
            ),
            onEmailSent: () => {},
            onEmailTemplateLoaded: () => {},
          },
        }).then(() => {});
      },
      () => {},
    );
  };

  const isRestrictedGetAndSendDocs = (): boolean => {
    return currentUser?.isInOrgUserRole;
  };

  return (
    <div className={'quote-edit-form'}>
      <OrderForm
        id={'QuoteForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={orderFormRef}
        validationSchema={quoteSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <div className="d-flex justify-content-between">
                  <span>
                    <Tab data-cy={'general'}>Quote Information</Tab>
                    <Tab data-cy={'charges'}>Charges</Tab>
                    <Tab data-cy={'additional'}>Additional</Tab>
                    <Tab data-cy={'attachments'} disabled={isCreateMode}>
                      Attachments
                    </Tab>
                  </span>
                  {!isCreateMode && (
                    <ActionBarComponent
                      buttonText={<>&middot;&middot;&middot;</>}
                      style={{
                        minWidth: 'fit-content',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      <button
                        disabled={
                          isRestrictedGetAndSendDocs() || isNoQuoteTemplate()
                        }
                        type={'button'}
                        onClick={getQuoteDocument}
                        title={
                          isRestrictedGetAndSendDocs() || isNoQuoteTemplate()
                            ? isRestrictedGetAndSendDocs()
                              ? 'Restricted'
                              : 'There is no active quotation document template'
                            : null
                        }
                      >
                        Get Quote Confirmation
                      </button>
                      <button
                        disabled={isRestrictedGetAndSendDocs()}
                        type={'button'}
                        onClick={sendQuoteEmail}
                        title={
                          isRestrictedGetAndSendDocs() ? 'Restricted' : null
                        }
                      >
                        Send Quote Confirmation
                      </button>
                    </ActionBarComponent>
                  )}
                </div>
              </TabList>
              <div>
                <TabPanel forceRender={true}>
                  <Panel className="m-3">
                    <h2>Quote Information</h2>
                    <div className="row">
                      {isCreateMode ? (
                        <div />
                      ) : (
                        <>
                          <div className="col-2">
                            <OrderForm.OrderNumber label="Quote Number" />
                          </div>
                        </>
                      )}
                      <div className={`${isCreateMode ? 'col-3' : 'col-2'}`}>
                        <OrderForm.BillToContactSelect
                          id={'billToContactId'}
                          header={'Customer/Lead'}
                          placeholder={'Select Customer/Lead'}
                          contactTypes={[
                            ContactType.Customer,
                            ContactType.Vendor,
                            ContactType.ForwardingAgent,
                            ContactType.Contact,
                            ContactType.Lead,
                          ]}
                          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact} OR contactType: ${ContactType.Lead}`}
                          required={true}
                          defaultValue={
                            quoteContextFormValue &&
                            quoteContextFormValue.billToContactId != null &&
                            quoteContextFormValue.billToContactName != null &&
                            quoteContextFormValue.billToContactType != null
                              ? {
                                  contactId:
                                    quoteContextFormValue.billToContactId,
                                  name: quoteContextFormValue.billToContactName,
                                  contactType:
                                    quoteContextFormValue.billToContactType,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setQuoteContextFormValue(
                              (quoteContextFormValueDto) => {
                                if (!quoteContextFormValueDto) {
                                  quoteContextFormValueDto = getInitialState();
                                }
                                quoteContextFormValueDto.billToContactId =
                                  data?.contactId;
                                quoteContextFormValueDto.billToContactName =
                                  data?.name;
                                quoteContextFormValueDto.billToContactType =
                                  data?.contactType;
                                return quoteContextFormValueDto;
                              },
                            );
                            data?.contactId
                              ? setApplyToContactPaidAs(data?.paidAs)
                              : setApplyToContactPaidAs(
                                  ChargeDefaultValues.paidAs,
                                );
                          }}
                          nameId={'billToContactName'}
                          showContactType={true}
                        />
                      </div>
                      <div
                        className={`${
                          isCreateMode ? 'col-3' : 'col-2'
                        } employee-contact-input`}
                      >
                        <OrderForm.EmployeeContactSelect
                          id={'employeeContactId'}
                          header={'Employee'}
                          contactTypes={[ContactType.Employee]}
                          selectedFilter={`contactType: ${ContactType.Employee}`}
                          required={false}
                          defaultValue={
                            quoteContextFormValue &&
                            quoteContextFormValue.employeeContactId != null &&
                            quoteContextFormValue.employeeContactName != null
                              ? {
                                  contactId:
                                    quoteContextFormValue.employeeContactId,
                                  name:
                                    quoteContextFormValue.employeeContactName,
                                  contactType: ContactType.Employee,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setQuoteContextFormValue(
                              (quoteContextFormValueDto) => {
                                if (!quoteContextFormValueDto) {
                                  quoteContextFormValueDto = getInitialState();
                                }
                                quoteContextFormValueDto.employeeContactId =
                                  data?.contactId;
                                quoteContextFormValueDto.employeeContactName =
                                  data?.name;
                                return quoteContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'employeeContactName'}
                        />
                      </div>
                      <div className="col-3 sales-person-contact-input">
                        <OrderForm.SalespersonContactSelect
                          id={'salespersonContactId'}
                          header={'Sales Person'}
                          contactTypes={[ContactType.SalesPerson]}
                          selectedFilter={`contactType: ${ContactType.SalesPerson}`}
                          required={false}
                          defaultValue={
                            quoteContextFormValue &&
                            quoteContextFormValue.salespersonContactId !=
                              null &&
                            quoteContextFormValue.salespersonContactName != null
                              ? {
                                  contactId:
                                    quoteContextFormValue.salespersonContactId,
                                  name:
                                    quoteContextFormValue.salespersonContactName,
                                  contactType: ContactType.SalesPerson,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setQuoteContextFormValue(
                              (quoteContextFormValueDto) => {
                                if (!quoteContextFormValueDto) {
                                  quoteContextFormValueDto = getInitialState();
                                }
                                quoteContextFormValueDto.salespersonContactId =
                                  data?.contactId;
                                quoteContextFormValueDto.salespersonContactName =
                                  data?.name;
                                return quoteContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'salespersonContactName'}
                        />
                      </div>
                      <div className="col-3">
                        <OrderForm.ModeOfTransportation
                          header={'Mode of Transportation'}
                          selectedFilter={` `}
                          required={false}
                          defaultValue={
                            quoteContextFormValue &&
                            quoteContextFormValue.modeOfTransportationId !=
                              null &&
                            quoteContextFormValue.modeOfTransportationDescription !=
                              null
                              ? {
                                  modeOfTransportationId:
                                    quoteContextFormValue.modeOfTransportationId,
                                  description:
                                    quoteContextFormValue.modeOfTransportationDescription,
                                }
                              : ''
                          }
                          onChange={(data?: ModeOfTransportationDto) => {
                            setQuoteContextFormValue(
                              (quoteContextFormValueDto) => {
                                if (!quoteContextFormValueDto) {
                                  quoteContextFormValueDto = getInitialState();
                                }
                                quoteContextFormValueDto.modeOfTransportationId =
                                  data?.modeOfTransportationId;
                                quoteContextFormValueDto.modeOfTransportationDescription =
                                  data?.description;
                                return quoteContextFormValueDto;
                              },
                            );
                          }}
                        />
                      </div>
                      <div className="col-3 carrier-contact-input">
                        <OrderForm.CarrierContactSelect
                          header={'Carrier'}
                          multiple={false}
                          contactTypes={[ContactType.Carrier]}
                          selectedFilter={`contactType: ${ContactType.Carrier}`}
                          closeMenuOnSelect={true}
                          defaultValue={(): any => {
                            return quoteContextFormValue
                              ? quoteContextFormValue.carriers
                              : null;
                          }}
                          onChange={(contact?: any) => {
                            setQuoteContextFormValue(
                              (quoteContextFormValueDto) => {
                                if (!quoteContextFormValueDto) {
                                  quoteContextFormValueDto = getInitialState();
                                }
                                quoteContextFormValueDto.carriers = contact;
                                return quoteContextFormValueDto;
                              },
                            );
                            setCarrier(contact);
                            contact?.contactId
                              ? setApplyToCarrierPaidAs(contact?.paidAs)
                              : setApplyToCarrierPaidAs(
                                  ChargeDefaultValues.paidAs,
                                );
                          }}
                        />
                      </div>
                      <div className={'col-3 equipmentType-input'}>
                        <OrderForm.ExpDate
                          defaultValue={quoteContextFormValue?.expDate}
                          onChange={(date) => onExpDateChange(date, context)}
                        />
                      </div>
                      <div className="col-3">
                        <OrderForm.PaymentTermsSelect
                          selectedFilter={` `}
                          header="Payment Term"
                          required={false}
                          defaultValue={
                            quoteContextFormValue &&
                            quoteContextFormValue.paymentTermId != null &&
                            quoteContextFormValue.paymentTermDescription != null
                              ? {
                                  paymentTermId:
                                    quoteContextFormValue.paymentTermId,
                                  description:
                                    quoteContextFormValue.paymentTermDescription,
                                }
                              : ''
                          }
                          onChange={(paymentTermDto?: PaymentTermDto) => {
                            setQuoteContextFormValue(
                              (quoteContextFormValueDto) => {
                                if (!quoteContextFormValueDto) {
                                  quoteContextFormValueDto = getInitialState();
                                }
                                quoteContextFormValueDto.paymentTermId =
                                  paymentTermDto?.paymentTermId;
                                quoteContextFormValueDto.paymentTermDescription =
                                  paymentTermDto?.description;
                                return quoteContextFormValueDto;
                              },
                            );
                          }}
                        />
                      </div>
                      <div className="col-3 division-input">
                        <OrderForm.Division
                          id={'divisionId'}
                          header={'Division'}
                          required={true}
                          defaultValue={
                            quoteContextFormValue &&
                            quoteContextFormValue.divisionId != null &&
                            quoteContextFormValue.divisionName != null
                              ? {
                                  divisionId: quoteContextFormValue.divisionId,
                                  divisionName:
                                    quoteContextFormValue.divisionName,
                                }
                              : ''
                          }
                          onChange={(data?: DivisionDto) => {
                            setQuoteContextFormValue(
                              (quoteContextFormValueDto) => {
                                if (!quoteContextFormValueDto) {
                                  quoteContextFormValueDto = getInitialState();
                                }
                                quoteContextFormValueDto.divisionId =
                                  data?.divisionId;
                                quoteContextFormValueDto.divisionName =
                                  data?.divisionName;
                                return quoteContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'divisionName'}
                          defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                          disabled={
                            !(
                              (currentUser?.isInOrgAdminRole ||
                                currentUser?.isInOperationRole) &&
                              VisibleTransactions[
                                currentUser?.visibleTransactions
                              ] === VisibleTransactions.AllTransactions
                            )
                          }
                        />
                      </div>
                    </div>
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Shipper</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderPickup}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-cy="btn-create-new-shipper"
                      />
                    </div>
                    {getItemsOrderPickup(context)}
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Consignee</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderDelivery}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-cy="btn-create-new-consignee"
                      />
                    </div>
                    {getItemsOrderDelivery(context)}
                    <hr className="my-4" />

                    <CommodityForm
                      id={'commodityForm'}
                      initialValues={initialStateCommodity}
                      onSubmit={onSubmitCommodityDto}
                      innerRef={commodityFormRef}
                      validationSchema={commoditySchema}
                      onKeyPress={(event, formikParams) => {
                        if (
                          event.code === 'Enter' &&
                          (currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole)
                        ) {
                          formikParams.submitForm();
                        }
                      }}
                    >
                      <h2>Commodities</h2>
                      <div className="row">
                        <div className={'col-1'}>
                          <CommodityForm.Pieces
                            onKeyDown={validateNumberInput}
                          />
                        </div>
                        <div className="col-2">
                          <CommodityForm.PackageTypeSelect
                            required={false}
                            defaultValue={
                              packageType &&
                              packageType.packageTypeId !== undefined &&
                              packageType.packageTypeId != null &&
                              packageType.name !== undefined &&
                              packageType.name != null
                                ? {
                                    packageTypeId: packageType?.packageTypeId,
                                    name: packageType?.name,
                                  }
                                : ''
                            }
                            onChange={(
                              data?: PackageTypeDto,
                              context?: any,
                            ) => {
                              setPackageType((packageTypeData) => {
                                if (!packageTypeData) {
                                  packageTypeData = {
                                    name: null,
                                    packageTypeId: null,
                                  };
                                }
                                packageTypeData.packageTypeId = data?.packageTypeId?.toString();
                                packageTypeData.name = data?.name;
                                return { ...packageTypeData };
                              });
                              context?.setFieldValue(
                                'packageTypeName',
                                data?.name,
                              );
                            }}
                            nameId={'packageTypeName'}
                          />
                        </div>
                        <div
                          className={isOrgAdminOrOperation ? 'col-4' : 'col-5'}
                        >
                          <CommodityForm.Description />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Length
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Width
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Height
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Weight
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        {(currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole) && (
                          <div className="col-1 pt-4">
                            <Button
                              form={'commodityForm'}
                              name={'create-commodity'}
                              type="button"
                              onClick={(event) => {
                                if (commodityFormRef.current) {
                                  commodityFormRef.current.submitForm();
                                }
                              }}
                              color="secondary"
                              className="btn-block text-break"
                            >
                              +
                            </Button>
                          </div>
                        )}
                      </div>
                      <CommoditiesForOrderList
                        className={'mb-4 commodities-for-order-list'}
                        limit={limit}
                        showPagination={false}
                        onPageChanged={(page) => setOffset(page * limit)}
                        offset={offset}
                        items={getCommodities()}
                        goToDetails={goToDetailsCommodity}
                        changeItems={setCommodities}
                        userCanDelete={
                          currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole
                        }
                        orderType={OrderTypes.Quote}
                      />
                      {getCommodities().length === 0 && (
                        <h4 className="text-center m-5 text-muted">
                          No commodities
                        </h4>
                      )}
                      <div className="row">
                        <div className="col-2">
                          <OrderForm.TotalPcsCrt
                            selectedName={getTotalPcsCrt()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.WeightTotal
                            selectedName={getWeightTotal()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.VolumeTotal
                            selectedName={getVolumeTotal()}
                          />
                        </div>
                        <div className="justify-content-end d-flex col-6">
                          {(currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole) && (
                            <div className="col-6 pt-4">
                              <Button
                                type="submit"
                                form={'QuoteForm'}
                                color="primary"
                                className="btn-block"
                                disabled={isSending}
                                isSending={isSending}
                              >
                                Save Quote
                              </Button>
                            </div>
                          )}
                          <div className="col-6 pt-4">
                            <Button
                              type="button"
                              color="secondary"
                              onClick={onCancel}
                              className="col-12"
                              disabled={isSending}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CommodityForm>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <ChargeForOrderList
                      limit={limit}
                      showPagination={false}
                      showIncomeCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      showExpenseCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onChargeCreated={onChargeCreated}
                      items={getCharges()}
                      goToDetails={goToDetailsCharge}
                      changeItems={setCharges}
                      selectedApplyToContact={{
                        contactId: quoteContextFormValue.billToContactId,
                        name: quoteContextFormValue.billToContactName,
                        contactType: quoteContextFormValue.billToContactType,
                        paidAs: applyToContactPaidAs,
                      }}
                      selectedApplyToCarrier={{
                        contactId: quoteContextFormValue.carrierContactId,
                        name: quoteContextFormValue.carrierContactName,
                        contactType: ContactType.Carrier,
                        paidAs: applyToCarrierPaidAs,
                      }}
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                      charges={quoteContextFormValue.charges}
                    />
                    <div className="mt-3">
                      <h2>Totals</h2>
                    </div>
                    <ChargeForm initialValues={profitValues} id={'ChargeForm'}>
                      <div className={'row'}>
                        <div className={'col-2'}>
                          <ChargeForm.Income disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Expense disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Profit disabled={true} />
                        </div>
                      </div>
                    </ChargeForm>
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'QuoteForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Quote
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel forceRender={isCreateMode ? false : true}>
                  <OrderForm.CustomValues
                    customFields={customFields}
                    entityType={CustomFieldEntityType.Order}
                    defaultValue={{ ...quoteContextFormValue.customValues }}
                    onChange={(result) => {
                      setQuoteContextFormValue((quoteContextFormValue) => {
                        quoteContextFormValue.customValues = result;
                        return { ...quoteContextFormValue };
                      });
                    }}
                    saveButtonRenderCondition={
                      currentUser?.isInOrgAdminRole ||
                      currentUser?.isInOperationRole
                    }
                    isSending={isSending}
                    formName={'QuoteForm'}
                    entityName={'Quote'}
                    onCancel={onCancel}
                    context={context}
                  />
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <AttachmentsFilesList
                      parentId={orderId}
                      parentType={AttachmentParentType.Order}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'QuoteForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Quote
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              </div>
            </Tabs>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
