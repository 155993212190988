import {
  EquipmentTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateEquipmentTypeCommandValues,
  UpdateEquipmentTypeCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createEquipmentTypeRequest,
  getEquipmentTypesListRequest,
  getEquipmentTypeRequest,
  deleteEquipmentTypeRequest,
  updateEquipmentTypeRequest,
  importEquipmentTypesRequest,
  exportEquipmentTypesRequest,
  GetEquipmentTypeParams,
} from './equipmentType.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type EquipmentTypesStoreState = {
  links: LinkDto[];
  equipmentTypeColumns: EntityFieldDto[];
  defaultEquipmentTypeColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getEquipmentTypesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'EquipmentType',
  });
});

addEffectStatusHandling(getEquipmentTypesColumnsFx);

export const getEquipmentTypesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'EquipmentType',
  });
});

addEffectStatusHandling(getEquipmentTypesDefaultColumnsFx);

export const updateEquipmentTypesColumns = createEvent<EntityFieldDto[]>();

export const getEquipmentTypesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getEquipmentTypesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getEquipmentTypesFx);

export const createEquipmentTypeFx = createEffect(
  (equipmentTypeData: EquipmentTypeDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createEquipmentTypeCommand: CreateEquipmentTypeCommandValues = {
      ...equipmentTypeData,
    };

    return createEquipmentTypeRequest(
      currentOrganization!,
      createEquipmentTypeCommand,
    );
  },
);

addEffectStatusHandling(createEquipmentTypeFx, {
  completeMessage: 'Equipment type was created successfully',
  errorMessage: 'Equipment type was not created',
});

export const getEquipmentTypeFx = createEffect(
  (equipmentTypeParams: GetEquipmentTypeParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getEquipmentTypeRequest(
      currentOrganization as LinkResourceBaseDto,
      equipmentTypeParams,
    );
  },
);

addEffectStatusHandling(getEquipmentTypeFx);

export const updateEquipmentTypeFx = createEffect(
  (equipmentType: EquipmentTypeDto) => {
    const updateEquipmentTypeCommand: UpdateEquipmentTypeCommandValues = {
      ...equipmentType,
    };
    return updateEquipmentTypeRequest(
      equipmentType,
      updateEquipmentTypeCommand,
    );
  },
);

addEffectStatusHandling(updateEquipmentTypeFx, {
  completeMessage: 'Equipment type was updated successfully',
  errorMessage: 'Equipment type was not updated',
});

export const deleteEquipmentTypeFx = createEffect(
  (equipmentType: EquipmentTypeDto) => {
    return deleteEquipmentTypeRequest(equipmentType);
  },
);

addEffectStatusHandling(deleteEquipmentTypeFx, {
  completeMessage: 'Equipment type was deleted successfully',
  errorMessage: 'Equipment type was not deleted',
});

export const exportEquipmentTypesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportEquipmentTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportEquipmentTypesFx, {
  completeMessage: 'Equipment types were exported successfully',
  errorMessage: 'Equipment types were not exported',
});

export const importEquipmentTypesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importEquipmentTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importEquipmentTypesFx, {
  completeMessage: 'Equipment types were imported successfully',
  errorMessage: 'Equipment types were not imported',
  inFlightMessage: 'Importing equipment types...',
});

const defaultState: EquipmentTypesStoreState = {
  links: [],
  equipmentTypeColumns: [],
  defaultEquipmentTypeColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const equipmentTypeStore: Store<EquipmentTypesStoreState> = createStore(
  defaultState,
)
  .on(getEquipmentTypesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      equipmentTypeColumns: payload.result.items,
      defaultEquipmentTypeColumns: payload.result.items,
    };
  })
  .on(getEquipmentTypesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultEquipmentTypeColumns: payload.result.items,
    };
  })
  .on(updateEquipmentTypesColumns, (state, payload) => {
    return { ...state, equipmentTypeColumns: payload };
  });

export const equipmentTypeFilterFields: any = [
  'equipmentTypeId',
  'created',
  'createdBy',
  'lastModified',
  'lastModifiedBy',
  'name',
  'organizationId',
];
