import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  AddressType,
  ContactAddressDto,
  ContactAddressDtoPagedResult,
  ContactType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  contactAddressStore,
  deleteContactAddressFx,
  exportContactAddressesFx,
  getContactAddressesFx,
  getContactAddressesColumnsFx,
  importContactAddressesFx,
} from '../contactAddresses.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  EXPORT_CONTACTADDRESSES_LINK_KEY,
  GetContactAddressParams,
  IMPORT_CONTACTADDRESSES_LINK_KEY,
} from '../contactAddresses.service';
import { ListParams } from '../../common/models/ListParams';
import { ContactAddressesDialog } from './contactAddresses.dialog';
import { FiUpload, FiDownload } from 'react-icons/fi';
import { IAction, getAction } from '../../common/components/actions/actions';
import { getFileFromUser } from '../../common/utilities';

export type ContactAddressesOtherAddressesTabListProps = {
  contactId: number;
  contactType: ContactType;
  goToDetails?: (contactAddressParams: GetContactAddressParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onCreate?: (contactAddress: ContactAddressDto) => void;
  onEdit?: (contactAddress: ContactAddressDto) => void;
  onDelete?: (contactAddress: ContactAddressDto) => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSelect?: (
    contactAddress: ContactAddressDto,
    contactAddressId: string,
  ) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  isDropDownList?: boolean;
  hideColumnsSelect?: boolean;
  addButtonRenderCondition?: boolean;
};

export const ContactAddressesOtherAddressesTabList = ({
  contactId,
  contactType,
  goToDetails = () => {},
  offset = 0,
  limit = 10,
  search = '',
  filter = ' ',
  sort = 'contactAddressId',
  onDelete = () => {},
  onCreate = () => {},
  onEdit = () => {},
  onPageChanged = () => {},
  onFilter = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['contactAddressId'],
  isDropDownList = false,
  hideColumnsSelect = false,
  addButtonRenderCondition = true,
}: ContactAddressesOtherAddressesTabListProps) => {
  const [
    contactAddresses,
    setContactAddresses,
  ] = useState<ContactAddressDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const [currentSearch, setCurrentSearch] = useState<string>(search);
  const [currentFilter, setCurrentFilter] = useState<string>(filter);
  const [currentLimit, setCurrentLimit] = useState<number>(limit);
  const [currentOffset, setCurrentOffset] = useState<number>(offset);
  const [currentSort, setCurrentSort] = useState<string>(sort);

  useEffect(() => {
    if (offset !== null && offset !== undefined) setCurrentOffset(offset);
  }, [offset]);
  const {
    contactAddressColumns: columns,
    defaultContactAddressColumns: defaultColumns,
  } = useStore(contactAddressStore);

  useEffect(() => {
    getContactAddressesColumnsFx();
  }, []);

  const updateContactAddressDialogTitle = 'Update Contact Address';
  const createContactAddressDialogTitle = 'Create Contact Address';
  const deleteContactAddressDialogTitle = 'Delete Contact Address';

  useEffect(() => {
    GetContactAddresses({
      offset: currentOffset,
      limit: currentLimit,
      sort: currentSort,
      filter: currentFilter,
      search: currentSearch,
    });
  }, [
    currentOffset,
    currentLimit,
    currentSort,
    currentFilter,
    currentSearch,
    loading,
  ]);
  function GetContactAddresses(params: ListParams = {}) {
    getContactAddressesFx(params).then(
      (contactAddressesData) => {
        setContactAddresses(contactAddressesData);
        setActions(getActions(contactAddressesData));
        setLoading(false);
      },
      () => {},
    );
  }

  const getActions = (result: ContactAddressDtoPagedResult): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        IMPORT_CONTACTADDRESSES_LINK_KEY,
        'Import',
        <FiUpload />,
        async () => {
          await importContactAddressesFx({
            file: await getFileFromUser(),
            listResource: result,
          });
          setLoading(true);
        },
        result?.links,
      ),
      getAction(
        EXPORT_CONTACTADDRESSES_LINK_KEY,
        'Export',
        <FiDownload />,
        () => exportContactAddressesFx({ listResource: result }),
        result?.links,
      ),
    ];
    return activeActions.filter(Boolean) as IAction[];
  };

  const onCreateNewContactAddress = () => {
    showDialog({
      dialog: ContactAddressesDialog,
      props: {
        title: createContactAddressDialogTitle,
        contactAddressId: 0,
        contactId,
        contactType,
        contactAddressType: AddressType.Other,
      },
    }).then(
      (result) => {
        if (result) {
          onCreate(result);
          GetContactAddresses({ offset, limit, sort, filter });
        }
      },
      () => {},
    );
  };

  const onEditContactAddress = (contactAddress: ContactAddressDto) => {
    if (contactAddress) {
      showDialog({
        dialog: ContactAddressesDialog,
        props: {
          title: updateContactAddressDialogTitle,
          contactAddressId: contactAddress.contactAddressId,
          contactId,
          contactType,
          contactAddressType: AddressType.Other,
        },
      }).then(
        (result) => {
          if (result) {
            onEdit(result);
          }
          GetContactAddresses({ offset, limit, sort, filter });
        },
        () => {},
      );
    }
  };

  const onDeleteContactAddress = (contactAddress: ContactAddressDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: deleteContactAddressDialogTitle,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteContactAddressFx(contactAddress).then(
            () => {
              onDelete(result);
              GetContactAddresses({ offset, limit, sort, filter });
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div>
        <div className="row m-3">
          {addButtonRenderCondition && (
            <div className="offset-10 col-2">
              <Button
                size={'sm'}
                color="secondary"
                className="w-100 h-100"
                name="create-otherContactAddress"
                onClick={onCreateNewContactAddress}
              >
                Add Contact Address
              </Button>
            </div>
          )}
        </div>
        <Grid
          isDropDownList={isDropDownList}
          showAllStore={showAllStore}
          rowKeys={rowKeys}
          data={contactAddresses.items}
          columns={columns}
          offset={currentOffset}
          limit={currentLimit}
          total={contactAddresses.totalCount}
          sort={currentSort}
          search={currentSearch}
          filter={currentFilter}
          onDelete={onDeleteContactAddress}
          onSort={(value) => setCurrentSort(value)}
          onEdit={onEditContactAddress}
          onPageChanged={onPageChanged}
          onSelect={onEditContactAddress}
          onFilter={(value) => setCurrentFilter(value)}
          className={'contactAddresses-list'}
          hideColumnsSelect={hideColumnsSelect}
          actions={actions}
          showToolbar={false}
          showGridPanel={false}
          defaultColumns={defaultColumns}
          showEmptyTable={true}
          onSearch={(value) => setCurrentSearch(value)}
          onLimitChanged={(value) => setCurrentLimit(value)}
          isIntegratedGrid={true}
        />
        {!currentSearch && !contactAddresses?.items?.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">
              You Don't Have Any Other Contact Addresses Yet
            </h3>
          </div>
        )}
      </div>
    );
  }
};
