import { Dialog } from '../../common/components/dialog/dialog.component';
import { CommodityDto } from '../../../models/data.models';
import { CommoditiesSelector } from './commoditiesSelector';

export type CommoditiesSelectorDialogProperties = {
  title: string;
  className?: string;
};

export class CommoditiesSelectorDialog extends Dialog<CommoditiesSelectorDialogProperties> {
  isEditMode: boolean | undefined = false;
  saveBtnLabel: string;

  constructor(props: CommoditiesSelectorDialogProperties) {
    super(props);
    this.onCommoditiesSelect = this.onCommoditiesSelect.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onCommoditiesSelect = (commodities: CommodityDto[]) => {
    this.close(commodities);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    return (
      <CommoditiesSelector
        onCommoditiesSelect={this.onCommoditiesSelect}
        onCancel={this.onCancel}
      />
    );
  }
}
