import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';
import { AttachmentInputProps } from '../../common/components/input/input.component';
import {
  createAttachment,
  getAttachment,
  getAttachmentContentFx,
  deleteAttachment,
} from '../attachments.store';
import {
  AttachmentParentType,
  AttachmentType,
  CreateAttachmentCommandValues,
  OrderDto,
  AccountingTransactionDto,
  EquipmentTypeDto,
  ContactDto,
  S3UriTypes,
  AddressType,
} from '../../../models/data.models';
import { addMessage } from '../../common/messages.store';
import { v4 as uuidv4 } from 'uuid';
import { getOrder, updateOrder } from '../../orders/orders.store';
import {
  getAccountingTransactionFx,
  updateAccountingTransactionFx,
} from '../../accountingTransactions/accountingTransactions.store';
import {
  getContact,
  updateContactAndContactAddresses,
} from '../../contacts/contacts.store';
import {
  getEquipmentTypeFx,
  updateEquipmentTypeFx,
} from '../../equipmentTypes/equipmentType.store';
import lodash from 'lodash';
import { showDialog } from '../../common/dialog.store';
import { AttachmentPreviewDialog } from './previewAttachment.dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { UpdateContactAndContactAddressesCommand } from '../../../models/custom.models';
import { Button } from '../../common/components/button/button.component';

export const AttachmentInput = ({
  readonly,
  required,
  name,
  size,
  className,
  parentId,
  parentType,
  context,
  setParentValues,
}: AttachmentInputProps) => {
  const [attachmentId, setAttachmentId] = useState(null);
  const [isSending, setIsSending] = useState<boolean>(false);

  const buttonRef = useRef<any>(null);

  useEffect(() => {
    if (context) {
      setAttachmentId(lodash.get(context.values, name));
    }
  }, [context]);

  const getParent = async (
    parentId: number,
    parentType: AttachmentParentType,
  ) => {
    switch (parentType) {
      case AttachmentParentType.Order: {
        return await getOrder({ orderId: parentId });
      }
      case AttachmentParentType.AccountingTransaction: {
        return await getAccountingTransactionFx({
          accountingTransactionId: parentId,
        });
      }
      case AttachmentParentType.Contact: {
        return await getContact({ contactId: parentId });
      }
      case AttachmentParentType.EquipmentType: {
        return await getEquipmentTypeFx({ equipmentTypeId: parentId });
      }
    }
  };

  const updateParent = async (parent: any, parentType) => {
    switch (parentType) {
      case AttachmentParentType.Order: {
        return await updateOrder(parent as OrderDto);
      }
      case AttachmentParentType.AccountingTransaction: {
        return await updateAccountingTransactionFx(
          parent as AccountingTransactionDto,
        );
      }
      case AttachmentParentType.Contact: {
        const contact = parent as ContactDto;
        const updateContactAndContactAddressesCommand: UpdateContactAndContactAddressesCommand = {
          contact,
          shippingAddress: contact.contactAddresses?.find(
            (address) => address.addressType === AddressType.Shipping,
          ),
          billingAddress: contact.contactAddresses?.find(
            (address) => address.addressType === AddressType.Billing,
          ),
        };
        return await updateContactAndContactAddresses(
          updateContactAndContactAddressesCommand,
        );
      }
      case AttachmentParentType.EquipmentType: {
        return await updateEquipmentTypeFx(parent as EquipmentTypeDto);
      }
    }
  };

  const onfileUpload = async (event: any) => {
    setIsSending(true);
    if (event?.target?.files?.length > 0) {
      const values: CreateAttachmentCommandValues = {
        attachmentType: AttachmentType.Avatar,
        description: '',
        fileName: event?.target?.files[0].name,
        parentId: parentId,
        parentType: parentType,
      };

      try {
        const attachment = await createAttachment({
          attachmentData: values,
          file: event?.target?.files[0],
        });

        const parent = await getParent(parentId, parentType);
        lodash.set(parent, name, attachment.attachmentId);
        if (setParentValues) {
          setParentValues((values) => {
            lodash.set(values, name, attachment.attachmentId);
            return values;
          });
          context.setFieldValue(name, attachment.attachmentId);
        }
        setAttachmentId(attachment.attachmentId);

        await updateParent(parent, parentType);
      } catch (error) {
        addMessage({
          message: error.message || error,
          type: 'danger',
          id: uuidv4(),
        });
      } finally {
        setIsSending(false);
      }
    }
  };

  const showAttachment = async () => {
    try {
      const attachment = await getAttachment({ attachmentId: attachmentId });
      const url = await getAttachmentContentFx({
        attachment,
        uriType: S3UriTypes.FileUri,
      });
      showDialog({
        dialog: AttachmentPreviewDialog,
        props: {
          url: url,
          title: attachment.fileName,
          mainModalClassName: 'preview-dialog-modal',
        },
      });
    } catch (error) {
      addMessage({
        message: error.message || error,
        type: 'danger',
        id: uuidv4(),
      });
    }
  };

  const onDeleteAttachment = async () => {
    if (attachmentId) {
      try {
        const attachment = await getAttachment({ attachmentId });
        await deleteAttachment(attachment);
        setAttachmentId(null);
        const parent = await getParent(parentId, parentType);
        const parentWithoutAttachmentdId = lodash.omit(parent, name);
        if (setParentValues) {
          setParentValues((values) => {
            lodash.set(values, name, null);
            return values;
          });
          context.setFieldValue(name, null);
        }

        await updateParent(parentWithoutAttachmentdId, parentType);
      } catch (error) {
        addMessage({
          message: error.message || error,
          type: 'danger',
          id: uuidv4(),
        });
      }
    }
  };

  return (
    <>
      <div className="form-group d-flex justify-content-start">
        <Field
          type={'file'}
          placeholder={''}
          name={'attachment'}
          readOnly={readonly}
          disabled={attachmentId || !parentId}
          required={required}
          tabIndex={null}
          id={'attachment'}
          onChange={onfileUpload}
          hidden={true}
          innerRef={buttonRef}
        />
        <Button
          type="button"
          color="primary"
          disabled={attachmentId || !parentId}
          isSending={isSending}
          onClick={() => buttonRef.current.click()}
          className="mr-3"
          style={{ height: '30px', fontSize: '14px', minWidth: '110px' }}
          title={
            !parentId
              ? 'At first you should create this Vendor'
              : 'Upload vendor avatar/logo'
          }
        >
          Choose file
        </Button>
        <span className="d-flex align-items-center">
          {attachmentId ? (
            <div className="d-flex justify-content-between align-items-right">
              <div
                className="menu-icon-wrapper d-flex icon-edit-entity mr-2"
                onClick={showAttachment}
                data-cy={`attachment-edit-icon`}
                title="Open file"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div
                className="menu-icon-wrapper d-flex icon-edit-entity"
                onClick={onDeleteAttachment}
                data-cy={`attachment-edit-icon`}
                title="Delete file"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>
          ) : null}
        </span>
      </div>
    </>
  );
};
