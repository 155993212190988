import React, { useEffect, useState } from 'react';
import {
  WarehouseLocationDto,
  WarehouseZoneDto,
} from '../../../../models/data.models';
import { HiOutlineLocationMarker } from 'react-icons/hi';

export type locationInfoProps = {
  warehouseLocation: WarehouseLocationDto;
  locationZones: WarehouseZoneDto[];
  showIcon?: boolean;
  size?: 'sm' | 'md' | 'lg';
  emphasisedPath?: boolean;
};

export const LocationInfo = ({
  warehouseLocation,
  locationZones,
  showIcon,
  size = 'sm',
  emphasisedPath = true,
}: locationInfoProps) => {
  const [path, setPath] = useState('');

  const findPath = (
    path: string,
    parentZoneId: number,
    warehouseZones: WarehouseZoneDto[],
  ) => {
    let foundPath = path;
    if (!parentZoneId) return foundPath;
    const parentZone = warehouseZones.find(
      (x) => x.warehouseZoneId == parentZoneId,
    );
    path = `${parentZone?.name}/` + foundPath;
    return findPath(path, parentZone?.parentZoneId, warehouseZones);
  };

  const renderText = (text) => {
    if (text) {
      const parts = text.split('/');

      return parts.map((part, index) => (
        <span key={index} className={'text-nowrap'}>
          <span className={emphasisedPath ? 'color-main-accent' : null}>
            {part}
          </span>
          {index < parts.length - 1 && (
            <span className={'color-action-disable'}>&nbsp;/&nbsp;</span>
          )}
        </span>
      ));
    }
  };

  useEffect(() => {
    setPath(
      findPath(
        warehouseLocation?.code,
        warehouseLocation?.parentZoneId,
        locationZones,
      ),
    );
  }, [warehouseLocation, locationZones]);

  return (
    <div
      className={`d-flex align-items-center justify-content-center flex-wrap location-info location-info-${size}`}
    >
      {showIcon && (
        <HiOutlineLocationMarker
          size={22}
          className={'mr-2 color-text-disable'}
        />
      )}{' '}
      {renderText(path)}
    </div>
  );
};
