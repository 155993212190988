import {
  CommodityTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateCommodityTypeCommandValues,
  UpdateCommodityTypeCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCommodityTypeRequest,
  getCommodityTypesListRequest,
  getCommodityTypeRequest,
  deleteCommodityTypeRequest,
  updateCommodityTypeRequest,
  importCommodityTypesRequest,
  exportCommodityTypesRequest,
  GetCommodityTypeParams,
} from './commodityTypes.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type CommodityTypesStoreState = {
  links: LinkDto[];
  commodityTypeColumns: EntityFieldDto[];
  defaultCommodityTypeColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getCommodityTypesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CommodityType',
  });
});

addEffectStatusHandling(getCommodityTypesColumnsFx);

export const getCommodityTypesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CommodityType',
  });
});

addEffectStatusHandling(getCommodityTypesDefaultColumnsFx);

export const updateCommodityTypesColumns = createEvent<EntityFieldDto[]>();

export const getCommodityTypesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCommodityTypesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getCommodityTypesFx);

export const createCommodityTypeFx = createEffect(
  (commodityTypeData: CommodityTypeDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createCommodityTypeCommand: CreateCommodityTypeCommandValues = {
      ...commodityTypeData,
    };

    return createCommodityTypeRequest(
      currentOrganization!,
      createCommodityTypeCommand,
    );
  },
);

addEffectStatusHandling(createCommodityTypeFx, {
  completeMessage: 'Commodity type was created successfully',
  errorMessage: 'Commodity type was not created',
});

export const getCommodityTypeFx = createEffect(
  (commodityTypeParams: GetCommodityTypeParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCommodityTypeRequest(
      currentOrganization as LinkResourceBaseDto,
      commodityTypeParams,
    );
  },
);

addEffectStatusHandling(getCommodityTypeFx);

export const updateCommodityTypeFx = createEffect(
  (commodityType: CommodityTypeDto) => {
    const updateCommodityTypeCommand: UpdateCommodityTypeCommandValues = {
      ...commodityType,
    };
    return updateCommodityTypeRequest(
      commodityType,
      updateCommodityTypeCommand,
    );
  },
);

addEffectStatusHandling(updateCommodityTypeFx, {
  completeMessage: 'Commodity type was updated successfully',
  errorMessage: 'Commodity type was not updated',
});

export const deleteCommodityTypeFx = createEffect(
  (commodityType: CommodityTypeDto) => {
    return deleteCommodityTypeRequest(commodityType);
  },
);

addEffectStatusHandling(deleteCommodityTypeFx, {
  completeMessage: 'Commodity type was deleted successfully',
  errorMessage: 'Commodity type was not deleted',
});

export const exportCommodityTypesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportCommodityTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportCommodityTypesFx, {
  completeMessage: 'Commodity types were exported successfully',
  errorMessage: 'Commodity types were not exported',
});

export const importCommodityTypesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importCommodityTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importCommodityTypesFx, {
  completeMessage: 'Commodity types were imported successfully',
  errorMessage: 'Commodity types were not imported',
  inFlightMessage: 'Importing commodity types...',
});

const defaultState: CommodityTypesStoreState = {
  links: [],
  commodityTypeColumns: [],
  defaultCommodityTypeColumns: [],
  defaultSort: 'commodityTypeId',
  defaultLimit: 20,
};

export const commodityTypeStore: Store<CommodityTypesStoreState> = createStore(
  defaultState,
)
  .on(getCommodityTypesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      commodityTypeColumns: payload.result.items,
      defaultCommodityTypeColumns: payload.result.items,
    };
  })
  .on(getCommodityTypesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultCommodityTypeColumns: payload.result.items,
    };
  })
  .on(updateCommodityTypesColumns, (state, payload) => {
    return { ...state, commodityTypeColumns: payload };
  });
