import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { AirShipmentOrderListScreen } from './screens/airShipmentOrders-list.screen';
import { AirShipmentOrderScreen } from './screens/airShipmentOrder.screen';

export const AIR_ORDER_LIST_PATH = '/airShipmentOrders';
export const AIR_ORDER_EDIT_PATH = '/airShipmentOrders/:orderId';
export const AIR_ORDER_CREATE_PATH = '/airShipmentOrders/0';

export const AirShipmentOrderRoute = () => {
  const group = 'Shipment';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <AirShipmentOrderListScreen
              pageTitle={'Air Shipments'}
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <AirShipmentOrderScreen
              pageTitle={'Add new Air Shipment'}
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':orderId'}
        element={
          <PrivateRoute>
            <AirShipmentOrderScreen
              pageTitle={'Update Air Shipment'}
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
