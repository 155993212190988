import React, { useRef, useState } from 'react';
import { Button } from '../button/button.component';
import { FormContext } from '../form/form.component';
import { parseSearchQuery } from '../../../../utils/query.utils';
import { FiltersForm } from './filters.form';
import {
  Column,
  ContactDto,
  ContactType,
  IDNumberType,
  ModeOfTransportationDto,
  OrderStatuses,
  RateType,
} from '../../../../models/data.models';
import { FormikProps, FormikValues } from 'formik';
import { escapeString, getEnumValues } from '../../../../utils/helper.utils';

export type FiltersProperties = {
  query?: string;
  columns: Column[];
  onFilter?: (filter: any) => void;
  addData?: any;
};

export const Filters = ({
  columns,
  query = '',
  onFilter = () => {},
  addData = {},
}: FiltersProperties) => {
  const queryValues = parseSearchQuery(query);
  for (let key in queryValues) {
    if (key.includes('.')) {
      delete queryValues[key];
    }
  }

  const [initialValues, setInitialValues] = useState({
    ...addData,
    ...queryValues,
  });
  const formikRef = useRef<HTMLFormElement>();

  return (
    <FiltersForm
      initialValues={initialValues}
      className={'d-flex flex-wrap row filters-form w-100'}
      onSubmit={onFilter}
      id={'filters-form'}
      innerRef={formikRef}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            {columns
              .filter((column) => column.showFilter)
              .map((column, index) => {
                return FilterField(column, context, index, addData);
              })}
            <div
              className={
                'd-flex align-items-end justify-content-end form-group w-100 row'
              }
            >
              <div className="col-3 justify-content-end d-flex">
                <Button
                  color="secondary"
                  onClick={() => {
                    setInitialValues({});
                    context.resetForm();
                  }}
                  size="sm"
                  style={{ height: '38px', width: '100%' }}
                  type="button"
                >
                  Reset
                </Button>
              </div>
              <div className="col-3 justify-content-end d-flex">
                <Button
                  color="primary"
                  onClick={() => context.submitForm()}
                  size="sm"
                  style={{ height: '38px', width: '100%' }}
                  type="button"
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </FiltersForm>
  );
};

const reactSelectCustomStyles = {
  menu: (provided, state) => {
    return {
      ...provided,
      height: 150,
    };
  },

  menuList: (provided, state) => {
    return {
      ...provided,
      maxHeight: 150,
    };
  },
};

const FilterField = (
  column: Column,
  context: FormikProps<FormikValues>,
  index?: number,
  addData?: any,
) => {
  switch (column.type) {
    case 'contact':
      switch (column.contactType) {
        case ContactType.Customer:
          return (
            <div className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}>
              <FiltersForm.CustomerSelect
                selectedFilter={column.contactTypes
                  .map((type) => `contactType: ${type}`)
                  .join(' OR ')}
                customStyles={reactSelectCustomStyles}
                id={column.dataField ?? column.name}
                defaultValue={
                  context.values &&
                  context.values.BillToContactId != null &&
                  ((context.values.__BillToContactName != null &&
                    context.values.__BillToContactType != null) ||
                    (addData.__BillToContactName != null &&
                      addData.__BillToContactType != null))
                    ? {
                        contactId: context.values.BillToContactId,
                        name:
                          context.values.__BillToContactName ??
                          addData.__BillToContactName,
                        contactType:
                          context.values.__BillToContactType ??
                          addData.__BillToContactType,
                      }
                    : ''
                }
                onChange={(
                  data?: ContactDto,
                  context?: FormikProps<FormikValues>,
                ) => {
                  context.setFieldValue(
                    '__BillToContactType',
                    data?.contactType,
                  );
                }}
              />
            </div>
          );
        case ContactType.Carrier:
          return (
            <div className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}>
              <FiltersForm.CarrierContactSelect
                selectedFilter={column.contactTypes
                  .map((type) => `contactType: ${type}`)
                  .join(' OR ')}
                customStyles={reactSelectCustomStyles}
              />
            </div>
          );
      }
      break;
    case 'orderStatus':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.OrderStatus
            customStyles={reactSelectCustomStyles}
            options={getEnumValues(OrderStatuses)}
            defaultValue={
              context.values &&
              context.values.__orderStatus != null &&
              context.values.orderStatus != null
                ? {
                    label: context.values.__orderStatus,
                    value: context.values.orderStatus,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('orderStatus', data?.value ?? null);
              context.setFieldValue('__orderStatus', data?.label ?? null);
            }}
          />
        </div>
      );
    case 'commodityStatus':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.CommodityStatus
            customStyles={reactSelectCustomStyles}
            options={getEnumValues(OrderStatuses)}
            defaultValue={
              context.values &&
              context.values.__orderStatus != null &&
              context.values.orderStatus != null
                ? {
                    label: context.values.__orderStatus,
                    value: context.values.orderStatus,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('orderStatus', data?.value ?? null);
              context.setFieldValue('__orderStatus', data?.label ?? null);
            }}
          />
        </div>
      );
    case 'idNumberType':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.IdNumberType
            customStyles={reactSelectCustomStyles}
            options={getEnumValues(IDNumberType)}
            defaultValue={
              context.values &&
              context.values.__idNumberType != null &&
              context.values.idNumberType != null
                ? {
                    label: context.values.__idNumberType,
                    value: context.values.idNumberType,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('idNumberType', data?.value ?? null);
              context.setFieldValue('__idNumberType', data?.label ?? null);
            }}
          />
        </div>
      );
    case 'date':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.Date
            name={column.dataField ?? column.name}
            id={column.name}
            header={column.title}
            placeholder={`Select ${column.title}`}
            defaultValue={context.values[column.name] || null}
          />
        </div>
      );
    case 'customField':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.Textbox
            id={column.name}
            placeholder={column.title}
            header={column.title}
            defaultValue={context.values[`__${column.name}`]}
            onChange={async (data?: any) => {
              let luceneFilter: string = escapeString(
                data.target.value,
                column.type,
              );
              await context.setFieldValue(
                `__${column.name}`,
                data.target.value,
              );
              await context.setFieldValue(column.name, luceneFilter);
            }}
          />
        </div>
      );
    case 'rateType':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.RateType
            customStyles={reactSelectCustomStyles}
            options={getEnumValues(RateType)}
            defaultValue={
              context.values &&
              context.values.__rateType != null &&
              context.values.rateType != null
                ? {
                    label: context.values.__rateType,
                    value: context.values.rateType,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('rateType', data?.value ?? null);
              context.setFieldValue('__rateType', data?.label ?? null);
            }}
          />
        </div>
      );
    case 'modeOfTransportation':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.ModeOfTransportation
            customStyles={reactSelectCustomStyles}
            id={column.dataField ?? column.name}
            defaultValue={
              context.values &&
              context.values.modeOfTransportationId != null &&
              (context.values.__Description != null ||
                addData.__Description != null)
                ? {
                    modeOfTransportationId:
                      context.values.modeOfTransportationId,
                    description:
                      context.values.__Description ?? addData.__Description,
                  }
                : ''
            }
            onChange={(
              data?: ModeOfTransportationDto,
              context?: FormikProps<FormikValues>,
            ) => {
              context.setFieldValue('__Description', data?.description);
            }}
          />
        </div>
      );
    case 'contactStatus':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.ContactStatus
            customStyles={reactSelectCustomStyles}
            defaultValue={
              context.values &&
              context.values.contactStatusId != null &&
              context.values.__statusName != null
                ? {
                    statusId: context?.values?.statusId,
                    statusName: context?.values?.__statusName,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('contactStatusId', data?.statusId ?? null);
              context.setFieldValue('__statusName', data?.statusName ?? null);
            }}
            selectedFilter={`contactType:${ContactType.Customer}`}
            isClearable={true}
          />
        </div>
      );
    default:
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-lg-6 col-xl-4'}
          key={index}
        >
          <FiltersForm.Textbox
            id={column.name}
            placeholder={column.title}
            header={column.title}
            defaultValue={context.values[`__${column.name}`]}
            onChange={async (data?: any) => {
              let luceneFilter: string = escapeString(
                data.target.value,
                column.type,
              );
              await context.setFieldValue(
                `__${column.name}`,
                data.target.value,
              );
              await context.setFieldValue(column.name, luceneFilter);
            }}
          />
        </div>
      );
  }
};
