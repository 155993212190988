import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const AttachmentForm = ({
  children,
  onSubmit,
  initialValues,
}: FormProps) => {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {children}
    </Form>
  );
};

AttachmentForm.AttachmentType = ({
  onChange,
  options,
  name = 'attachmentType',
  id = 'attachmentType',
  placeholder = 'Select Attachment Type',
  required = true,
  multiple = false,
  disabled = false,
  header = 'Attachment Type',
  defaultValue = null,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
AttachmentForm.Description = ({
  className,
  style,
  size,
  disabled,
  name = 'description',
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AttachmentForm.FileName = ({
  className,
  style,
  size,
  disabled,
  name = 'fileName',
  label,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      placeholder={'File Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AttachmentForm.FileUri = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'fileUri'}
      label={'File Uri'}
      placeholder={'File Uri'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AttachmentForm.ParentId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'parentId'}
      label={'Parent Id'}
      placeholder={'Parent Id'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AttachmentForm.ParentType = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'parentType'}
      label={'Parent Type'}
      placeholder={'Parent Type'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AttachmentForm.PreviewUri = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'previewUri'}
      label={'Preview Uri'}
      placeholder={'Preview Uri'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AttachmentForm.ThumbnailUri = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'thumbnailUri'}
      label={'Thumbnail Uri'}
      placeholder={'Thumbnail Uri'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
