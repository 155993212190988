import { Container, Nav, NavDropdown, NavItem, Navbar } from 'react-bootstrap';
import { ComponentProps } from '../layout-interfaces';
import { ComponentRender } from '../component-render';
import { localized, parseTemplate } from '../component-hooks';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const NavbarLinkComponent = (props: ComponentProps) => {
  const { to } = props.props;
  const [href, setHref] = useState(parseTemplate(to, props.variables));

  useEffect(() => {
    setHref(parseTemplate(to, props.variables));
  }, [to, props.variables]);

  const onClick = () => {
    if (props?.props?.onClick && props.context?.action) {
      props.context?.action(props.props.onClick, props.variables, {
        sender: props.name,
        actions: props.actions,
      });
    }
  };

  return (
    <Nav.Link as={Link} to={href} {...props?.props?.options} onClick={onClick}>
      {localized(props?.props?.label)}
    </Nav.Link>
  );
};

export const NavbarItemComponent = (props: ComponentProps) => {
  return (
    <NavItem>
      {props?.children?.map(
        (item, index) =>
          item && (
            <ComponentRender
              key={'navbar-item' + (item?.name ?? index)}
              {...item}
              context={props.context}
              variables={props.variables}
            />
          ),
      )}
    </NavItem>
  );
};

export const NavDropdownComponent = (props: ComponentProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <NavDropdown
      title={localized(parseTemplate(props?.props?.label, props.variables))}
      id={props?.props?.id}
      align={props?.props?.align ?? 'start'}
      show={isDropdownOpen}
      onMouseEnter={() => setIsDropdownOpen(true)}
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      {props?.children?.map(
        (item, index) =>
          item && (
            <ComponentRender
              key={index}
              {...item}
              context={props.context}
              variables={props.variables}
            />
          ),
      )}
    </NavDropdown>
  );
};

export const NavbarComponent = (props: ComponentProps) => {
  return (
    <Navbar
      className="app-navbar"
      data-testid={props?.name ?? 'navbar'}
      {...props?.props?.options}
    >
      <Container>
        <Navbar.Brand>
          {props?.props?.brand && (
            <ComponentRender
              {...props?.props?.brand}
              context={props.context}
              variables={props.variables}
            />
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {props?.props?.items?.map((item, index) => (
              <ComponentRender
                key={'navbar-item-' + index}
                {...item}
                context={props.context}
                variables={props.variables}
              />
            ))}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {props?.props?.contextItems?.map((item, index) => (
              <ComponentRender
                key={'navbar-end-item-' + index}
                {...item}
                context={props.context}
                variables={props.variables}
              />
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
