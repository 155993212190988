import React, { ChangeEvent, useEffect } from 'react';
import {
  Form,
  FormProps,
} from '../../../common/components/form/form.component';
import { InputProps } from '../../../common/components/input/input.component';
import { ReactSelect } from '../../../common/components/input/input.select';

export const RateManagerForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

RateManagerForm.ShipperSelect = ({
  name = 'shipperId',
  id = 'shipperId',
  placeholder = 'Select Shipper',
  required = true,
  header = 'Shipper',
  options,
  onChange = () => {},
  defaultValue,
}: InputProps) => {
  return (
    <ReactSelect
      useContext={true}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={false}
      disabled={false}
      onChange={onChange}
      options={options}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      isSearchable={true}
      isClearable={true}
    />
  );
};

RateManagerForm.ConsigneeSelect = ({
  name = 'consigneeId',
  id = 'consigneeId',
  placeholder = 'Select Consignee',
  required = true,
  header = 'Consignee',
  options,
  onChange = () => {},
  defaultValue,
}: InputProps) => {
  return (
    <ReactSelect
      useContext={true}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={false}
      disabled={false}
      onChange={onChange}
      options={options}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      isSearchable={true}
      isClearable={true}
    />
  );
};

RateManagerForm.CarrierSelect = ({
  name = 'carrierId',
  id = 'carrierId',
  placeholder = 'Select Carrier',
  required = false,
  header = 'Carrier',
  options,
  onChange = () => {},
  defaultValue,
}: InputProps) => {
  return (
    <ReactSelect
      useContext={true}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={false}
      disabled={false}
      onChange={onChange}
      options={options}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      isSearchable={true}
      isClearable={true}
    />
  );
};

RateManagerForm.DriverSelect = ({
  name = 'driverId',
  id = 'driverId',
  placeholder = 'Select Driver',
  required = false,
  header = 'Driver',
  options,
  onChange = () => {},
  defaultValue,
}: InputProps) => {
  return (
    <ReactSelect
      useContext={true}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={false}
      disabled={false}
      onChange={onChange}
      options={options}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      isSearchable={true}
      isClearable={true}
    />
  );
};
