import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputProps,
} from '../../common/components/input/input.component';

export const TrackingForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

TrackingForm.OrderSelector = ({
  className,
  style,
  size,
  disabled = false,
}: InputProps) => {
  return (
    <Input
      name={'orderSelector'}
      label={'Order Number or Tracking Number'}
      placeholder={'Order Selector'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
