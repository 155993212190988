import React, { useEffect, useState } from 'react';
import { RoundCheckbox } from '../../../common/components/checkbox/roundCheckbox';
import { CommodityDto } from '../../../../models/data.models';
import { updateCommodity } from '../../../commodities/commodities.store';

type VerifyContentCheckboxProps = {
  row?: CommodityDto;
  rows?: CommodityDto[];
  rowIndex?: number;
  commodityIndex?: number;
  userId?: string;
  styles?: string;
  setIsVerifiedAll?: any;
  disabled?: boolean;
};

export const VerifyContentCheckbox = ({
  row,
  rows,
  rowIndex,
  commodityIndex,
  userId,
  styles,
  setIsVerifiedAll,
  disabled = false,
}: VerifyContentCheckboxProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(
    row.customValues && row.customValues['content_verified'] === 'true',
  );

  useEffect(() => {
    setIsLoading(false);
    if (rows?.length > 0 && setIsVerifiedAll) {
      setIsVerifiedAll(
        rows.every(
          (row) =>
            row.customValues && row.customValues['content_verified'] === 'true',
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isVerified) {
        if (!row.customValues) row.customValues = {};
        row.customValues['content_verified'] = 'true';
        row.customValues['content_verified_by'] = userId;
        var timezoneOffset = new Date().getTimezoneOffset() * 60000;
        row.customValues['content_verified_on'] = new Date(
          Date.now() - timezoneOffset,
        ).toISOString();
      } else {
        if (!row.customValues) row.customValues = {};
        row.customValues['content_verified'] = 'false';
        row.customValues['content_verified_by'] = null;
        row.customValues['content_verified_on'] = null;
      }
      setIsSending(true);
      updateCommodity(row)
        .then(() => {
          setIsVerifiedAll(
            rows?.every(
              (row) => row.customValues['content_verified'] === 'true',
            ),
          );
        })
        .finally(() => setIsSending(false));
    }
  }, [isVerified]);

  return (
    <>
      <div
        className={`w-auto d-flex justify-content-start align-items-center ${styles}`}
        style={{ minWidth: '20px', minHeight: '20px' }}
      >
        {isVerified ? (
          <RoundCheckbox
            chkBoxClassName={`checkbox-content-verify checkbox-content-verify-checked checkbox-content-verify-${rowIndex}-${commodityIndex}`}
            onClick={() => setIsVerified(false)}
            size={15}
            isLoading={isSending}
            spinnerClassName="mx-0"
            label="Verified"
          />
        ) : (
          <RoundCheckbox
            chkBoxClassName={`checkbox-content-verify checkbox-content-verify-${rowIndex}-${commodityIndex} ${
              disabled ? 'text-muted' : ''
            }`}
            onClick={() => !disabled && setIsVerified(true)}
            size={15}
            isLoading={isSending}
            spinnerClassName="mx-0"
            label="Verified"
            labelClassName={disabled ? 'text-muted' : ''}
          />
        )}
      </div>
    </>
  );
};
