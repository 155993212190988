import React, { useState, useEffect } from 'react';
import { getEnumKeyByValue } from '../../../../utils/helper.utils';
import {
  EntityFieldDto,
  OrderDtoPagedResult,
  SummaryItems,
  VolumeUnit,
  WeightUnit,
} from '../../../../models/data.models';
import { RiFileList2Line, RiScalesFill } from 'react-icons/ri';
import { HiOutlineCube } from 'react-icons/hi';
import { PurchaseGrid } from '../grid/purchase-grid.component';
import { useStore } from 'effector-react';
import { parcelShipmentsStore } from '../../../parcelShipments/parcelShipments.store';

export type TotalItemsProps = {
  title?: string;
  itemName?: string;
  color?: string;
  summary: SummaryItems;
  expandable?: boolean;
  orders?: OrderDtoPagedResult;
  columns?: EntityFieldDto[];
  onOrderSelect?: (order: any) => Promise<void>;
  offset?: number | undefined;
  setOffset?: any;
  limit?: number | undefined;
  setLimit?: any;
  sort?: string;
  setSort?: any;
};

export const TotalItems = ({
  title,
  itemName,
  color = 'main-accent',
  summary,
  expandable = false,
  orders: defaultOrders,
  columns,
  onOrderSelect,
  offset,
  setOffset,
  limit,
  setLimit,
  sort,
  setSort,
}: TotalItemsProps) => {
  const { defaultColumns, defaultLimit } = useStore(parcelShipmentsStore);
  const [orders, setOrders] = useState<OrderDtoPagedResult>(defaultOrders);

  useEffect(() => {
    setOrders(defaultOrders);
  }, [defaultOrders]);

  return (
    <>
      <div
        className="row rounded align-items-center justify-content-between"
        data-toggle={expandable ? 'collapse' : undefined}
        data-target={expandable ? '#collapseExample' : undefined}
        aria-expanded={expandable ? 'false' : undefined}
        aria-controls={expandable ? 'collapseExample' : undefined}
        style={expandable ? { cursor: 'pointer' } : undefined}
      >
        <div className="col-3">
          <div className={'text-h3 font-weight-bold mb-md-0 mb-3 text-nowrap'}>
            {title ?? 'Total'}:
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between px-5">
          <div className="d-flex align-items-center pl-sm-4">
            <RiFileList2Line size={22} className={'mr-2 color-text-disable'} />
            {itemName ?? 'Parcels'}:&nbsp;
            <div className={`text-h3 font-weight-bold color-${color}`}>
              {summary?.totalCount}
            </div>
          </div>
          <div className="d-flex align-items-center pl-sm-4 ">
            <RiScalesFill size={22} className={'mr-2 color-text-disable'} />
            Weight:&nbsp;
            <div className={`text-h3 font-weight-bold color-${color}`}>
              &#126;{summary?.totalWeight.toFixed(1)}
              &nbsp;{getEnumKeyByValue(summary?.totalWeightUnits, WeightUnit)}
            </div>
          </div>
          <div className="d-flex align-items-center pl-sm-4">
            <HiOutlineCube size={22} className={'mr-2 color-text-disable'} />
            Cube:&nbsp;
            <div className={`text-h3 font-weight-bold color-${color}`}>
              &#126;{summary?.totalCube.toFixed(1)}
              &nbsp;{VolumeUnit[summary?.totalCubeUnits]}
            </div>
          </div>
        </div>
      </div>
      {expandable && orders && columns && (
        <div className="collapse" id="collapseExample">
          <PurchaseGrid
            showEmptyTable={true}
            showAllStore={true}
            rowKeys={['orderId']}
            data={orders?.items}
            offset={offset}
            limit={limit}
            total={orders?.totalCount}
            columns={columns}
            summary={null}
            sort={sort || 'orderId'}
            onDelete={null}
            onSort={setSort}
            search={null}
            onEdit={null}
            onPageChanged={(page: number) => {
              setOffset(page * limit);
            }}
            onLimitChanged={(limit: number) => {
              setLimit(limit);
              setOffset(0);
            }}
            onViewChanged={null}
            onSelect={onOrderSelect}
            onChangeItem={null}
            defaultColumns={defaultColumns}
            defaultSort={''}
            defaultLimit={defaultLimit}
            filter={''}
            onFilter={null}
            onSearch={null}
            enableScanner={false}
            showGridPanel={false}
          />
        </div>
      )}
    </>
  );
};
