import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { JobDto, JobListItemDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  jobStore,
  getJobsColumnsFx,
  getJobsFx,
  deleteJobFx,
  exportJobsFx,
  importJobsFx,
} from '../jobs.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_JOB_LINK_KEY,
  DELETE_JOB_LINK_KEY,
  IMPORT_JOBS_LINK_KEY,
  EXPORT_JOBS_LINK_KEY,
  GetJobParams,
} from '../jobs.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { JobDialog } from './job.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type JobsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (jobParams: GetJobParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (viewName: string) => void;
  onSort?: (field: string) => void;
  onSelect?: (job: JobDto, jobParams: GetJobParams) => void;
};

export const JobsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: JobsListProps) => {
  const [jobs, setJobs] = useState<JobListItemDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const { jobColumns: columns, defaultJobColumns: defaultColumns } = useStore(
    jobStore,
  );

  useEffect(() => {
    getJobsColumnsFx();
  }, []);

  const getJobs = useCallback(async () => {
    const jobsData = await getJobsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(jobsData));
    setJobs(jobsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: JobListItemDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_JOBS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importJobsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getJobs();
          },
          result?.links,
        ),
        getAction(
          EXPORT_JOBS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportJobsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getJobs],
  );

  useEffect(() => {
    getJobs();
  }, [getJobs]);

  const onDeleteJob = useCallback(
    async (job: JobDto) => {
      if (userHas(DELETE_JOB_LINK_KEY, jobs?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Job`,
            message: 'Are you sure you want to delete job?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteJobFx(job);
            getJobs();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getJobs, onDelete, jobs],
  );

  const onEditJob = (job: JobDto) => {
    if (job) goToDetails({ jobId: job.jobId });
  };

  const onJobSelect = useCallback(
    async (job) => {
      const selectedJob = await showDialog({
        dialog: JobDialog,
        props: {
          jobId: job.jobId,
          title: 'Update Job',
        },
      });
      if (selectedJob) {
        getJobs();
      }
    },
    [getJobs],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          rowKeys={['jobId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={jobs.items}
          onLimitChanged={onLimitChanged}
          total={jobs.totalCount}
          onDelete={onDeleteJob}
          onSort={onSort}
          onEdit={onEditJob}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onJobSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {jobs?.totalCount === 0 && !search && (
          <ListElementsNotFound
            entityName="Job"
            entityLinkKey={CREATE_JOB_LINK_KEY}
            onClick={goToCreate}
          />
        )}
      </>
    );
  }
};
