import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { EmployeeEdit } from './employee-edit.component';

export type EmployeeDialogProperties = {
  contactId: number;
  title: string;
};

export class EmployeeDialog extends Dialog<EmployeeDialogProperties> {
  constructor(props: EmployeeDialogProperties) {
    super(props);
    this.onEmployeeSaved = this.onEmployeeSaved.bind(this);
  }

  onEmployeeSaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId } = this.props as EmployeeDialogProperties;
    return (
      <EmployeeEdit
        contactId={contactId}
        onEmployeeCreated={this.onEmployeeSaved}
        onEmployeeUpdated={this.onEmployeeSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
