import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CommodityTrackingNumberDto } from '../../../models/data.models';
import {
  createCommodityTrackingNumber,
  getCommodityTrackingNumber,
  updateCommodityTrackingNumber,
} from '../commodityTrackingNumbers.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CommodityTrackingNumberForm } from './commodityTrackingNumbers.form';

export type CommodityTrackingNumberEditProps = {
  commodityTrackingNumberId?: number | null;
  commodityId: number;
  onCommodityTrackingNumberCreated?: (
    commodityTrackingNumber: CommodityTrackingNumberDto,
  ) => void;
  onCommodityTrackingNumberUpdated?: (
    commodityTrackingNumber: CommodityTrackingNumberDto,
  ) => void;
  onCommodityTrackingNumberLoaded?: (
    commodityTrackingNumber: CommodityTrackingNumberDto,
  ) => void;
  isEditMode?: boolean;
  defaultCommodityTrackingNumber?: CommodityTrackingNumberDto;
};

const initialState: CommodityTrackingNumberDto = {
  commodityTrackingNumberId: 0,
  commodityId: 0,
  syncOrderId: null,
  trackingNumber: '',
  isPrimary: false,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  links: [],
};

export const CommodityTrackingNumberEdit = ({
  commodityTrackingNumberId,
  commodityId,
  onCommodityTrackingNumberLoaded = () => {},
  onCommodityTrackingNumberCreated = () => {},
  onCommodityTrackingNumberUpdated = () => {},
  isEditMode = false,
  defaultCommodityTrackingNumber,
}: CommodityTrackingNumberEditProps) => {
  const isCreateMode =
    !commodityTrackingNumberId || commodityTrackingNumberId === 0;
  const [isLoading, setIsLoading] = useState(true);
  const [
    commodityTrackingNumber,
    setCommodityTrackingNumber,
  ] = useState<CommodityTrackingNumberDto | null>(null);
  const [
    initialValues,
    setInitialValues,
  ] = useState<CommodityTrackingNumberDto | null>(
    defaultCommodityTrackingNumber || initialState,
  );

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (commodityTrackingNumberId || isEditMode) {
      if (defaultCommodityTrackingNumber) {
        setCommodityTrackingNumber(defaultCommodityTrackingNumber);
        onCommodityTrackingNumberLoaded(defaultCommodityTrackingNumber);
        setCommodityTrackingNumber(defaultCommodityTrackingNumber);
        setIsLoading(false);
      } else {
        getCommodityTrackingNumber({ commodityTrackingNumberId }).then(
          (commodityTrackingNumberDto: CommodityTrackingNumberDto) => {
            setInitialValues(commodityTrackingNumberDto);
            setCommodityTrackingNumber(commodityTrackingNumberDto);
            onCommodityTrackingNumberLoaded(commodityTrackingNumberDto);
            setIsLoading(false);
          },
        );
      }
    } else {
      throw new Error('CommodityTrackingNumber keys were not provided');
    }
  }, [commodityTrackingNumberId]);

  const onSubmit = (data: CommodityTrackingNumberDto) => {
    data.commodityId = commodityId;
    if (
      defaultCommodityTrackingNumber == null ||
      defaultCommodityTrackingNumber == undefined
    ) {
      if (isCreateMode) {
        createCommodityTrackingNumber(data).then((result) => {
          onCommodityTrackingNumberCreated(result.data);
        });
      } else {
        updateCommodityTrackingNumber(data).then((result) => {
          onCommodityTrackingNumberUpdated(result.data);
        });
      }
    } else {
      if (isCreateMode) {
        onCommodityTrackingNumberCreated(data);
      } else {
        onCommodityTrackingNumberUpdated(data);
      }
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-8">
      <div className="row">
        <div className="col-8">
          <CommodityTrackingNumberForm
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <CommodityTrackingNumberForm.TrackingNumber
              disabled={initialState.syncOrderId > 0}
            />
            <CommodityTrackingNumberForm.TrackingNumberType
              disabled={initialState.syncOrderId > 0}
            />
            <CommodityTrackingNumberForm.IsPrimary
              name="isPrimary"
              defaultValue={initialValues.isPrimary ?? false}
            />
            <Button
              name="save-commodityTrackingNumber"
              type="submit"
              color="primary"
              className="btn-block"
            >
              Save Commodity Tracking Number
            </Button>
          </CommodityTrackingNumberForm>
        </div>
      </div>
    </Panel>
  );
};
