import { Button } from 'react-bootstrap';
import { ComponentProps } from '../layout-interfaces';
import { localized, parseTemplate } from '../component-hooks';
import { Icon } from './icon-component';
import React, { useContext, useEffect, useState } from 'react';
import { FormikContext } from 'formik';
import { Oval } from 'react-loader-spinner';

export const loader = (
  <Oval
    strokeWidthSecondary={3}
    strokeWidth={3}
    secondaryColor="#eff1f7"
    color="#e6e6e6"
    height={25}
    width={25}
  />
);

export const ButtonComponent = (props: ComponentProps) => {
  const formikContext = useContext(FormikContext);
  const { options, icon, prefix } = props?.props || {};

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async (e) => {
    if (props?.props?.stopPropagation) e.stopPropagation();
    if (props?.props?.onClick && props.context?.action) {
      setIsLoading(true);
      try {
        await props.context?.action(props.props.onClick, props.variables, {
          sender: props.name,
          actions: props.actions,
          formikContext,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [parsedOptions, setParsedOptions] = useState(null);

  useEffect(() => {
    setParsedOptions(
      parseTemplate(options, {
        ...props.variables,
        ...props.context.store,
      }),
    );
  }, [props]);

  return (
    <Button
      onClick={onClick}
      data-testid={props.name}
      variant={options?.variant ?? 'secondary'}
      disabled={isLoading || parsedOptions?.disabled}
      {...parsedOptions}
    >
      {isLoading && <div className="loader-wrapper">{loader}</div>}
      {!isLoading && (
        <>
          {' '}
          {icon && (
            <>
              <Icon icon={icon} />
            </>
          )}
          {props?.props?.label
            ? (icon ? ' ' : '') +
              localized(
                parseTemplate(props?.props?.label, {
                  ...props.variables,
                  ...props.context.store,
                }),
              )
            : ''}
        </>
      )}
    </Button>
  );
};
