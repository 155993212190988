import {
  LinkDto,
  LinkResourceBaseDto,
  UserRoleDtoPagedResult,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_USERROLES_LINK_KEY = 'get-user-roles';

export const getUserRolesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<UserRoleDtoPagedResult> => {
  if (resource) {
    const getUserRolesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_USERROLES_LINK_KEY,
    );
    if (getUserRolesListLink) {
      const result = await execLink(getUserRolesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_USERROLES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
