import { Dialog } from '../../../common/components/dialog/dialog.component';
import { OceanConsolidatedWizard } from './oceanConsolidatedWizard.component';

export type OceanConsolidatedWizardDialogProperties = {
  title: string;
  className?: string;
  onOceanOrderCreated?: (orderId: number) => void;
  closeDialog?: () => void;
};

export class OceanConsolidatedWizardDialog extends Dialog<OceanConsolidatedWizardDialogProperties> {
  constructor(props: OceanConsolidatedWizardDialogProperties) {
    super(props);
    this.closeDialog = this.closeDialog?.bind(this);
    this.onOceanOrderCreated = props.onOceanOrderCreated?.bind(this);
  }

  onOceanOrderCreated = () => {};

  closeDialog = () => {
    this.close();
  };

  renderContent(): any {
    return (
      <OceanConsolidatedWizard
        onOceanOrderCreated={this.onOceanOrderCreated}
        closeDialog={this.closeDialog}
      />
    );
  }
}
