import React from 'react';
import thinkImage from '../assets/think.svg';
import { Helmet } from 'react-helmet';
import { Panel } from '../../common/components/panel/panel.component';
import { logout } from '../../auth/auth.store';
import { useNavigate } from 'react-router-dom';

export type NotFoundScreenProps = { pageTitle: string };

export const AccessDeniedScreen = ({ pageTitle }: NotFoundScreenProps) => {
  const navigate = useNavigate();

  const onLogoutClicked = () => {
    if (logout) {
      logout();
    }
    navigate('/login');
  };

  return (
    <div className="background-gray h-100">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="container py-5 background-register">
        <div className="d-flex justify-content-center">
          <Panel style={{ maxWidth: '500px' }}>
            <h1 className="text-center register-header font-weight-light mb-5">
              Access Denied
            </h1>
            Please request access to TMS from your administrator.
            <br />
            <a href="javascript:" onClick={onLogoutClicked}>
              Logout
            </a>
          </Panel>
        </div>
      </div>
    </div>
  );
};
