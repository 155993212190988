import { DecimalPlace } from '../models/data.models';

export const getFormattedDate = (
  date: string | Date,
  time: boolean = true,
  smooth: boolean = false,
): string => {
  if (date) {
    const browserLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    if (time) {
      if (smooth) {
        return new Date(date).toLocaleString(browserLocale, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      } else return new Date(date).toLocaleString(browserLocale);
    } else {
      return new Date(date).toLocaleString(browserLocale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
  }
};

export const getMaxDecimals = (decimalPlaces?: DecimalPlace) => {
  switch (decimalPlaces) {
    case DecimalPlace.Two:
      return 2;
    case DecimalPlace.Four:
      return 4;
    default:
      return null;
  }
};

export const getFormattedPrice = (
  price?: number,
  decimalPlaces?: DecimalPlace,
  currencySymbol: string = '',
) => {
  const decimals = getMaxDecimals(decimalPlaces);
  const formattedPrice = `${currencySymbol}${price
    ?.toFixed(decimals ?? 0)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  return formattedPrice;
};

export const getSingleCommaFormattedPrice = (
  price?: number,
  decimalPlaces?: DecimalPlace,
) => {
  const decimals = getMaxDecimals(decimalPlaces);
  return price?.toFixed(decimals ?? 0);
};

export const getFormattedTime = (date: string | Date) => {
  if (date)
    return new Date(date).toLocaleTimeString('en-US', {
      hour12: false,
    });
  return '';
};
