import {
  UserSettingDto,
  LinkDto,
  LinkResourceBaseDto,
  UserSettingDtoPagedResult,
  CreateUserSettingCommandValues,
  UpdateUserSettingCommandValues,
  UpdateDefaultViewCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_USERSETTINGS_LINK_KEY = 'get-usersettings';
export const CREATE_USERSETTING_LINK_KEY = 'create-usersetting';
export const GET_USERSETTING_LINK_KEY = 'get-usersetting';
export const UPDATE_USERSETTING_LINK_KEY = 'update-usersetting';
export const UPDATE_DEFAULTVIEW_LINK_KEY = 'default-view';
export const DELETE_USERSETTING_LINK_KEY = 'delete-usersetting';

export interface GetUserSettingParams {
  userSettingId: number;
}

export const getUserSettingRequest = async (
  resource: LinkResourceBaseDto | null,
  { userSettingId }: GetUserSettingParams,
): Promise<UserSettingDto> => {
  if (resource && userSettingId) {
    const getUserSettingLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_USERSETTING_LINK_KEY,
    );
    if (getUserSettingLink) {
      const result = await execLink(getUserSettingLink, { userSettingId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_USERSETTING_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getUserSettingsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<UserSettingDtoPagedResult> => {
  if (resource) {
    const getUserSettingsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_USERSETTINGS_LINK_KEY,
    );
    if (getUserSettingsListLink) {
      const result = await execLink(getUserSettingsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_USERSETTINGS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createUserSettingRequest = async (
  resource: LinkResourceBaseDto,
  userSetting: CreateUserSettingCommandValues,
) => {
  const createUserSettingLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_USERSETTING_LINK_KEY,
  );
  if (createUserSettingLink) {
    const result = await execLink(createUserSettingLink, userSetting);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_USERSETTING_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateUserSettingRequest = async (
  resource: LinkResourceBaseDto,
  userSetting: UpdateUserSettingCommandValues,
) => {
  const updateUserSettingLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_USERSETTING_LINK_KEY,
  );
  if (updateUserSettingLink) {
    const result = await execLink(updateUserSettingLink, userSetting);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_USERSETTING_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateDefaultViewRequest = async (
  resource: LinkResourceBaseDto,
  updateDefaultViewCommand: UpdateDefaultViewCommandValues,
) => {
  const updateDefaultViewLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_DEFAULTVIEW_LINK_KEY,
  );
  if (updateDefaultViewLink) {
    const result = await execLink(
      updateDefaultViewLink,
      updateDefaultViewCommand,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_DEFAULTVIEW_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteUserSettingRequest = async (userSetting: UserSettingDto) => {
  const deleteUserSettingLink = userSetting.links?.find(
    (x: LinkDto) => x.rel === DELETE_USERSETTING_LINK_KEY,
  );
  if (deleteUserSettingLink) {
    const result = await execLink(deleteUserSettingLink, userSetting);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_USERSETTING_LINK_KEY} is not allowed or missing link action`,
  );
};
