import React, { useEffect, useState } from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ConsolidatedShipmentScreen } from './screens/consolidatedShipment.screen';
import { OrderTypes } from '../../models/data.models';
import { setCurrentPagePath } from '../common/nav.store';
import { getEnumKeyByValue } from '../../utils/helper.utils';

export const CONSOLIDATED_SHIPMENT_EDIT_PATH = '/:orderId';
export const CONSOLIDATED_SHIPMENTS_PATH =
  '/org/:organizationId/consolidatedShipments/:orderType';
export const CONSOLIDATED_SHIPMENTS_LIST_PATH =
  '/consolidatedShipments/:orderType';

export const ConsolidatedShipmentRoute = (props: RouteProps) => {
  const match = useMatch(
    CONSOLIDATED_SHIPMENTS_PATH + CONSOLIDATED_SHIPMENT_EDIT_PATH,
  );
  const group = 'Shipment';
  const pathStart = ['Shipment', 'Parcel Shipments'];
  const [pageTitle, setPageTitle] = useState('Consolidated Shipment');

  useEffect(() => {
    switch ((match.params as any)?.orderType) {
      case getEnumKeyByValue(OrderTypes.AirShipmentOrder, OrderTypes): {
        const title = 'Air Consolidated Shipment';
        setPageTitle(title);
        setCurrentPagePath([...pathStart, title]);
        break;
      }
      case getEnumKeyByValue(OrderTypes.OceanShipmentOrder, OrderTypes): {
        const title = 'Ocean Consolidated Shipment';
        setPageTitle(title);
        setCurrentPagePath([...pathStart, title]);
        break;
      }
      default:
        setCurrentPagePath([...pathStart, pageTitle]);
        break;
    }
  }, [props, pageTitle]);

  return (
    <Routes>
      <Route
        path={'/:orderId'}
        element={
          <PrivateRoute>
            <ConsolidatedShipmentScreen pageTitle={pageTitle} group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
