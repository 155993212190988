import {
  Column,
  GenerateInvoiceCommandValues,
  LinkDto,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  generateInvoiceRequest,
  getUninvoicedOrdersListRequest,
} from './invoiceManager.service';

export const getUnInvoicedOrders = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUninvoicedOrdersListRequest(currentOrganization, params);
});

export const generateInvoice = createEffect(
  (invoice: GenerateInvoiceCommandValues) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return generateInvoiceRequest(currentOrganization!, invoice);
  },
);

type InvoiceManagerStoreState = {
  links: LinkDto[];
  uninvoicedOrderColumns: Column[];
};

const defaultState: InvoiceManagerStoreState = {
  links: [],
  uninvoicedOrderColumns: [
    {
      name: 'orderNumber',
      visible: true,
      title: 'Order Number',
      sortName: 'orderNumber~ToInt32',
    },
    {
      name: 'orderType',
      visible: true,
      title: 'Order Type',
    },
    {
      name: 'shippingDateString',
      visible: true,
      title: 'Shipping Date',
    },
    {
      name: 'carrierDriverNamesString',
      visible: true,
      title: 'Carriers / Drivers',
    },
    {
      name: 'customerNamesString',
      visible: true,
      title: 'Customers',
    },
  ],
};
export const updateUninvoicedOrdersColumns = createEvent<Column[]>();

export const invoiceManagerStore: Store<InvoiceManagerStoreState> = createStore(
  defaultState,
)?.on(updateUninvoicedOrdersColumns, (state, payload) => {
  return { ...state, orderColumns: payload };
});
