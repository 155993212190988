import { OrderDto, OrderTypes } from '../../../models/data.models';
import { Dialog } from '../../common/components/dialog/dialog.component';
import { ConsolidatedShipmentCreate } from './consolidatedShipment-create.component';
export type ConsolidatedShipmentCreateDialogProperties = {
  orderId?: number | null;
  defaultState?: ConsolidatedShipmentStateProperties | null;
  onConsolidatedShipmentCreated: (
    orderId: number,
    orderType: OrderTypes,
  ) => void;
  onCancel: () => void;
  title: string;
  orderType: OrderTypes | null;
};

export type ConsolidatedShipmentStateProperties = {
  divisionId?: number | null;
  currentPallet?: number | null;
  modeOfTransportationId?: number | null;
  countries?: string | null;
  orderType?: OrderTypes | null;
};

export class ConsolidatedShipmentCreateDialog extends Dialog<ConsolidatedShipmentCreateDialogProperties> {
  orderType?: OrderTypes;

  constructor(props: ConsolidatedShipmentCreateDialogProperties) {
    super(props);
    this.state = { orderId: 0 };
    this.orderType = props.defaultState?.orderType;
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { defaultState, onConsolidatedShipmentCreated } = this
      .props as ConsolidatedShipmentCreateDialogProperties;
    return (
      <ConsolidatedShipmentCreate
        defaultState={defaultState}
        onConsolidatedShipmentCreated={onConsolidatedShipmentCreated}
        onCancel={this.onCancel}
        isDialog={true}
        orderType={this.orderType}
      />
    );
  }
}
