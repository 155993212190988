import { Dialog } from '../../common/components/dialog/dialog.component';
import { ContactAddressEdit } from './contactAddress-edit.component';
import {
  AddressType,
  ContactAddressDto,
  ContactType,
} from '../../../models/data.models';

export type ContactAddressesDialogProperties = {
  contactAddressId: number;
  contactId?: number;
  contactAddressType: AddressType;
  contactType: ContactType;
  title: string;
};

export class ContactAddressesDialog extends Dialog<ContactAddressesDialogProperties> {
  constructor(props: ContactAddressesDialogProperties) {
    super(props);
    this.onContactAddressSaved = this.onContactAddressSaved.bind(this);
  }

  onContactAddressSaved = (contactAddress: ContactAddressDto) => {
    this.close(contactAddress);
  };

  renderContent(): any {
    const {
      contactAddressId,
      contactId,
      contactAddressType,
      contactType,
    } = this.props as ContactAddressesDialogProperties;
    return (
      <ContactAddressEdit
        contactAddressId={contactAddressId}
        contactId={contactId}
        addressType={contactAddressType}
        contactType={contactType}
        onContactAddressCreated={this.onContactAddressSaved}
        onContactAddressUpdated={this.onContactAddressSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
