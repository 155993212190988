import React from 'react';
import { AccountingTransactionStatusesColors } from '../../../models/data.models';

export type AccountingTransactionStatusProps = {
  accountingTransactionStatus: string;
};

export const AccountingTransactionStatus = ({
  accountingTransactionStatus = '',
}: AccountingTransactionStatusProps) => {
  return (
    <div className="justify-content-between mt-2 mb-4">
      <div
        className="accounting-transaction-status d-flex align-items-center justify-content-center py-3 col-1 offset-11"
        style={{
          backgroundColor:
            AccountingTransactionStatusesColors[accountingTransactionStatus],
        }}
      >
        {accountingTransactionStatus}
      </div>
    </div>
  );
};
