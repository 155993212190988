import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CustomFieldsListScreen } from './screens/customFields-list.screen';
import { CustomFieldScreen } from './screens/customField.screen';

export const CUSTOM_FIELDS_LIST_PATH = '/customFields';
export const CUSTOM_FIELDS_EDIT_PATH = '/customFields/:fieldId';
export const CUSTOM_FIELDS_CREATE_PATH = '/customFields/0';

export const CustomFieldRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CustomFieldsListScreen pageTitle="Custom Fields" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CustomFieldScreen pageTitle="Add New Custom Field" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':fieldId'}
        element={
          <PrivateRoute>
            <CustomFieldScreen pageTitle="Update Custom Field" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
