import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ActionEventsListScreen } from './screens/actionEvents-list.screen';
import { ActionEventScreen } from './screens/actionEvent.screen';

export const ACTIONEVENTS_LIST_PATH = '/action-events';
export const ACTIONEVENT_EDIT_PATH = '/action-events/:eventId';
export const ACTIONEVENT_CREATE_PATH = '/action-events/create';

export const ActionEventsRoute = () => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ActionEventsListScreen pageTitle="Action Events" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <ActionEventScreen pageTitle="Add New Action Event" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':eventId'}
        element={
          <PrivateRoute>
            <ActionEventScreen pageTitle="Update Action Event" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
