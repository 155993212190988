import {
  AccountingAccountDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateAccountingAccountCommandValues,
  UpdateAccountingAccountCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAccountingAccountRequest,
  getAccountingAccountsListRequest,
  getAccountingAccountRequest,
  deleteAccountingAccountRequest,
  updateAccountingAccountRequest,
  importAccountingAccountsRequest,
  exportAccountingAccountsRequest,
  GetAccountingAccountParams,
} from './accountingAccounts.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type AccountingAccountsStoreState = {
  links: LinkDto[];
  accountingAccountColumns: EntityFieldDto[];
  defaultAccountingAccountColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getAccountingAccountsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AccountingAccount',
  });
});

addEffectStatusHandling(getAccountingAccountsColumnsFx);

export const getAccountingAccountsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AccountingAccount',
  });
});

addEffectStatusHandling(getAccountingAccountsDefaultColumnsFx);

export const updateAccountingAccountsColumns = createEvent<EntityFieldDto[]>();

export const getAccountingAccountsFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingAccountsListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getAccountingAccountsFx);

export const createAccountingAccountFx = createEffect(
  (accountingAccountData: AccountingAccountDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createAccountingAccountCommand: CreateAccountingAccountCommandValues = {
      ...accountingAccountData,
    };

    return createAccountingAccountRequest(
      currentOrganization!,
      createAccountingAccountCommand,
    );
  },
);

addEffectStatusHandling(createAccountingAccountFx, {
  completeMessage: 'Accounting account was created successfully',
  errorMessage: 'Accounting account was not created',
});

export const getAccountingAccountFx = createEffect(
  (accountingAccountParams: GetAccountingAccountParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingAccountRequest(
      currentOrganization as LinkResourceBaseDto,
      accountingAccountParams,
    );
  },
);

addEffectStatusHandling(getAccountingAccountFx);

export const updateAccountingAccountFx = createEffect(
  (accountingAccount: AccountingAccountDto) => {
    const updateAccountingAccountCommand: UpdateAccountingAccountCommandValues = {
      ...accountingAccount,
    };
    return updateAccountingAccountRequest(
      accountingAccount,
      updateAccountingAccountCommand,
    );
  },
);

addEffectStatusHandling(updateAccountingAccountFx, {
  completeMessage: 'Accounting account was updated successfully',
  errorMessage: 'Accounting account was not updated',
});

export const deleteAccountingAccountFx = createEffect(
  (accountingAccount: AccountingAccountDto) => {
    return deleteAccountingAccountRequest(accountingAccount);
  },
);

addEffectStatusHandling(deleteAccountingAccountFx, {
  completeMessage: 'Accounting account was deleted successfully',
  errorMessage: 'Accounting account was not deleted',
});

export const exportAccountingAccountsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportAccountingAccountsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportAccountingAccountsFx, {
  completeMessage: 'Accounting accounts were exported successfully',
  errorMessage: 'Accounting accounts were not exported',
});

export const importAccountingAccountsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importAccountingAccountsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importAccountingAccountsFx, {
  completeMessage: 'Accounting accounts were imported successfully',
  errorMessage: 'Accounting accounts were not imported',
  inFlightMessage: 'Importing accounting accounts...',
});

const defaultState: AccountingAccountsStoreState = {
  links: [],
  accountingAccountColumns: [],
  defaultAccountingAccountColumns: [],
  defaultSort: '-accountNumber',
  defaultLimit: 20,
};

export const accountingAccountStore: Store<AccountingAccountsStoreState> = createStore(
  defaultState,
)
  .on(getAccountingAccountsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      accountingAccountColumns: payload.result.items,
      defaultAccountingAccountColumns: payload.result.items,
    };
  })
  .on(getAccountingAccountsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultAccountingAccountColumns: payload.result.items,
    };
  })
  .on(updateAccountingAccountsColumns, (state, payload) => {
    return { ...state, accountingAccountColumns: payload };
  });
