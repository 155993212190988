import React from 'react';
import {
  HiArrowLeft,
  HiArrowRight,
  HiOutlineChevronDown,
} from 'react-icons/hi';

export type PaginationProps = {
  offset?: number;
  limit?: number;
  total?: number;
  pagesToLeft?: number;
  pagesToRight?: number;
  goToPage?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  itemsName?: string;
  additionalTotal?: number;
  totalQuantity?: number;
};

export const Pagination = ({
  offset = 0,
  limit = 20,
  total = 0,
  pagesToLeft = 5,
  pagesToRight = 5,
  goToPage = () => {},
  onLimitChanged = () => {},
  itemsName = null,
  additionalTotal = 0,
  totalQuantity = null,
}: PaginationProps) => {
  const limitOptions = [5, 10, 15, 20, 30, 50, 100];

  const changePage = (page: number) => {
    goToPage(page);
  };
  const lastPage = Math.ceil(total / limit) - 1;
  const pages = (() => {
    const pages = [];
    const currentPage = Math.ceil(offset / limit);
    const additionalPagesToRight =
      currentPage - pagesToLeft < 0 ? pagesToLeft - currentPage : 0;
    const additionalPagesToLeft =
      currentPage + pagesToRight > lastPage
        ? currentPage + pagesToRight - lastPage
        : 0;
    const start =
      currentPage - pagesToLeft - additionalPagesToLeft > 0
        ? currentPage - pagesToLeft - additionalPagesToLeft
        : 0;
    const end =
      currentPage + pagesToRight + additionalPagesToRight > lastPage
        ? lastPage
        : currentPage + pagesToRight + additionalPagesToRight;
    for (let index = start; index <= end; index++) {
      pages.push(index);
    }
    return pages;
  })();
  const leftPagesSkipped = pages[0] > 0;
  const showLeftDots = pages[0] > 1;
  const rightPagesSkipped = lastPage - pages[pages.length - 1] > 0;
  const showRightDots = lastPage - pages[pages.length - 1] > 1;

  return (
    <nav
      className="d-flex justify-content-between align-items-center w-100"
      aria-label="Page navigation example"
    >
      <div className="d-flex flex-column">
        {totalQuantity !== null ? (
          <>
            <div className={'pagination-span text-nowrap'}>
              Total packages: {total}
            </div>
            <div className={'pagination-span text-nowrap mt-2'}>
              Total items: {totalQuantity}
            </div>
          </>
        ) : (
          <>
            <div className={'pagination-span text-nowrap'}>
              Total: {total} items
            </div>
            {itemsName && (
              <div className={'pagination-span text-nowrap mt-2'}>
                Total: {additionalTotal} {itemsName}
              </div>
            )}
          </>
        )}
      </div>

      <ul className="pagination">
        <li className="page-item">
          <a
            className="page-link"
            aria-label="Previous"
            onClick={() => {
              let currentPage = Math.ceil(offset / limit);
              if (currentPage > 0) {
                changePage(--currentPage);
              }
            }}
          >
            <span aria-hidden="true">
              <HiArrowLeft />
            </span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {leftPagesSkipped && (
          <li key={0} className={`page-item`}>
            <a
              onClick={() => {
                changePage(0);
              }}
              className="page-link"
            >
              {1}
            </a>
          </li>
        )}
        {showLeftDots && (
          <li key={'dots-left'} className={`page-item disabled`}>
            <a className="page-link">&hellip;</a>
          </li>
        )}
        {pages.map((index) => (
          <li
            key={index}
            className={`page-item ${
              Math.ceil(offset / limit) === index ? 'active' : ''
            }`}
          >
            <a
              onClick={() => {
                changePage(index);
              }}
              className="page-link"
            >
              {index + 1}
            </a>
          </li>
        ))}
        {showRightDots && (
          <li key={'dots-right'} className={`page-item disabled`}>
            <a className="page-link">&hellip;</a>
          </li>
        )}
        {rightPagesSkipped && (
          <li key={lastPage} className={`page-item`}>
            <a
              onClick={() => {
                changePage(lastPage);
              }}
              className="page-link"
            >
              {lastPage + 1}
            </a>
          </li>
        )}
        <li className="page-item">
          <a
            className="page-link"
            aria-label="Next"
            onClick={() => {
              let currentPage = Math.ceil(offset / limit);
              const totalPages = Math.ceil(total / limit);
              if (currentPage < totalPages - 1) {
                changePage(++currentPage);
              }
            }}
          >
            <span aria-hidden="true">
              <HiArrowRight />
            </span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>

      <div className={'d-flex align-items-center'}>
        <div className={'pagination-span text-nowrap pr-3'}>Rows</div>
        <div
          data-cy="pagination-select"
          className="form-control dropdown pagination-select d-flex align-items-center"
        >
          <div
            className={'pointer no-select'}
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle="dropdown"
          >
            {limit}

            <HiOutlineChevronDown className={'ml-2'} />
          </div>
          <div className={'dropdown-menu dropdown-menu-sm'}>
            {limitOptions.map((l) => {
              return (
                <div
                  key={l}
                  className={'dropdown-item no-select pointer'}
                  onClick={() => {
                    onLimitChanged(l);
                  }}
                >
                  {l}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};
