import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  WAREHOUSE_RECEIPT_CREATE_PATH,
  WAREHOUSE_RECEIPT_LIST_PATH,
} from '../warehouseReceipt.route';
import { WarehouseReceiptEdit } from '../components/warehouseReceipt-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../../orders/orders.service';
import { userHas } from '../../auth/auth.store';

export type WarehouseReceiptScreenProps = { pageTitle: string; group: string };

export const WarehouseReceiptScreen = ({
  pageTitle,
  group,
}: WarehouseReceiptScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, warehouseReceiptId } = useParams();

  const createPath = generatePath(
    INTERNAL_PATH + WAREHOUSE_RECEIPT_CREATE_PATH,
    {
      organizationId,
    },
  );

  const onWarehouseReceiptCreated = () => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'warehouseReceipt-created',
      type: 'success',
      autoHide: true,
      message: 'Warehouse Receipt successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onWarehouseReceiptUpdated = () => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'warehouseReceipt-updated',
      type: 'success',
      autoHide: true,
      message: 'Warehouse Receipt successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Warehouse Receipts'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <WarehouseReceiptEdit
        orderId={warehouseReceiptId}
        onWarehouseReceiptUpdated={onWarehouseReceiptUpdated}
        onWarehouseReceiptCreated={onWarehouseReceiptCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
