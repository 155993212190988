import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PaymentTermsListScreen } from './screens/paymentTerm-list.screen';
import { PaymentTermScreen } from './screens/paymentTerm.screen';

export const PAYMENTTERMS_LIST_PATH = '/payment-terms';
export const PAYMENTTERM_EDIT_PATH = '/payment-terms/:paymentTermId';
export const PAYMENTTERM_CREATE_PATH = '/payment-terms/0';

export const PaymentTermRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <PaymentTermsListScreen pageTitle="Payment Terms" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <PaymentTermScreen pageTitle="Add New Payment Term" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':paymentTermId'}
        element={
          <PrivateRoute>
            <PaymentTermScreen pageTitle="Update Payment Term" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
