import React, { useState, useEffect } from 'react';
import { OrderEditFormDto } from '../../../../models/custom.models';
import { Button } from '../../../common/components/button/button.component';
import { Field } from 'formik';
import {
  getFormattedDate,
  getFormattedTime,
} from '../../../../utils/formatting.utils';
import { getCommodities } from '../../../commodities/commodities.store';
import { getDocumentTemplatesFx } from '../../../documentTemplates/documentTemplates.store';
import {
  DocumentTemplateDtoPagedResult,
  CommodityDtoPagedResult,
} from '../../../../models/data.models';
import { addMessage } from '../../../common/messages.store';
import { v4 } from 'uuid';

export type CompletingFragmentProps = {
  oceanOrderContextFormValue: OrderEditFormDto;
  commodityIds: number[];
  documentsIds: number[];
  handleBack: () => void;
};

export const CompletingFragment = ({
  oceanOrderContextFormValue,
  documentsIds,
  commodityIds,
  handleBack,
}: CompletingFragmentProps) => {
  const [templates, setTemplates] = useState<DocumentTemplateDtoPagedResult>(
    null,
  );
  const [commodities, setCommodities] = useState<CommodityDtoPagedResult>(null);
  const [information, setInformation] = useState<string>('');

  useEffect(() => {
    if (documentsIds?.length > 0) {
      getDocumentTemplatesFx({
        search: '',
        limit: 1000,
        offset: 0,
        sort: '',
        filter: documentsIds
          .map((id) => `documentTemplateId: ${id}`)
          .join(' OR '),
      })
        .then((result) => {
          if (result) setTemplates(result);
        })
        .catch((error) =>
          addMessage({
            message: error,
            type: 'danger',
            id: v4(),
          }),
        );
    }
  }, [documentsIds]);

  useEffect(() => {
    if (commodityIds?.length > 0) {
      getCommodities({
        search: '',
        limit: 1000,
        offset: 0,
        sort: '',
        filter: commodityIds.map((id) => `commodityId: ${id}`).join(' OR '),
      })
        .then((result) => {
          if (result) setCommodities(result);
        })
        .catch((error) =>
          addMessage({
            message: error,
            type: 'danger',
            id: v4(),
          }),
        );
    }
  }, [commodityIds]);

  useEffect(() => {
    setInformation(getCompletedInformation());
  }, [templates, commodities]);

  const getCompletedInformation = () => `
    Shipment Name: ${
      oceanOrderContextFormValue?.customValues['shipmentName'] || ''
    }
    Employee: ${oceanOrderContextFormValue?.employeeContactName || ''}
    Division: ${oceanOrderContextFormValue?.divisionName || ''}
    Executed date: ${
      getFormattedDate(
        oceanOrderContextFormValue?.customValues['executedDate'],
        false,
      ) || ''
    }
    Executed place: ${
      oceanOrderContextFormValue?.customValues['portIdName'] || ''
    }
    Bill of lading number: ${
      oceanOrderContextFormValue.customValues['billOfLandingNumber'] || ''
    }
    Booking number: ${
      oceanOrderContextFormValue.customValues['bookingNumber'] || ''
    }
    Departure date: ${
      getFormattedDate(
        oceanOrderContextFormValue?.customValues['departureDate'],
        false,
      ) || ''
    }
    Departure time: ${getFormattedTime(
      oceanOrderContextFormValue?.customValues['departureTime'],
    )}
    Arrival date: ${
      getFormattedDate(
        oceanOrderContextFormValue?.customValues['arrivalDate'],
        false,
      ) || ''
    }
    Arrival time: ${getFormattedTime(
      oceanOrderContextFormValue?.customValues['arrivalTime'],
    )}
    Declared value: ${
      oceanOrderContextFormValue.customValues['declaredValue'] || ''
    }
    Shipper: ${oceanOrderContextFormValue?.shipper?.contactName || ''}
    Shipper address: ${
      oceanOrderContextFormValue?.shipper?.contactAddressName || ''
    }
    Ultimate consignee: ${
      oceanOrderContextFormValue?.ultimateConsignee?.contactName || ''
    }
    Ultimate consignee address: ${
      oceanOrderContextFormValue?.ultimateConsignee?.contactAddressName || ''
    }
    Consignee: ${oceanOrderContextFormValue?.consignee?.contactName || ''}
    Consignee address: ${
      oceanOrderContextFormValue?.consignee?.contactAddressName || ''
    }
    Notify parry: ${oceanOrderContextFormValue?.notifyParty?.contactName || ''}
    Notify parry address: ${
      oceanOrderContextFormValue?.notifyParty?.contactAddressName || ''
    }
    Intermediate: ${oceanOrderContextFormValue?.intermediate?.contactName || ''}
    Intermediate address: ${
      oceanOrderContextFormValue?.intermediate?.contactAddressName || ''
    }
    Forwarding agent: ${
      oceanOrderContextFormValue?.forwardingAgent?.contactName || ''
    }
    Forwarding agent address: ${
      oceanOrderContextFormValue?.forwardingAgent?.contactAddressName || ''
    }
    Destination agent: ${
      oceanOrderContextFormValue?.destinationAgent?.contactName || ''
    }
    Destination agent address: ${
      oceanOrderContextFormValue?.destinationAgent?.contactAddressName || ''
    }
    Service type: ${
      oceanOrderContextFormValue?.customValues['serviceTypeOcean'] || ''
    }
    Transportation mode: ${
      oceanOrderContextFormValue?.customValues[
        'modeOdTransportationDescription'
      ] || ''
    }
    Point of origin or FTZ number: ${
      oceanOrderContextFormValue?.customValues['portOfOrigin'] || ''
    }
    Pre-Carriage by: ${
      oceanOrderContextFormValue?.customValues['preCarriageBy'] || ''
    }
    Place of receipt by Pre-Carrier: ${
      oceanOrderContextFormValue?.customValues['placeOfReceiptByPreCarrier'] ||
      ''
    }
    Loading pier or terminal: ${
      oceanOrderContextFormValue?.customValues['loadingPier'] || ''
    }
    Port of loading: ${
      oceanOrderContextFormValue?.customValues['portOfLoading'] || ''
    }
    Exporting Carrier: ${
      oceanOrderContextFormValue?.customValues['exportingCarrierName'] || ''
    }
    Voyage Identification: ${
      oceanOrderContextFormValue?.customValues['voyageIdentification'] || ''
    }
    Vessel name: ${oceanOrderContextFormValue?.customValues['vesselName'] || ''}
    Vessel flag: ${oceanOrderContextFormValue?.customValues['vesselFlag'] || ''}
    Port of unloading: ${
      oceanOrderContextFormValue?.customValues['portOfUnloading'] || ''
    }
    On-Carriage by: ${
      oceanOrderContextFormValue?.customValues['onCarriageByName'] || ''
    }
    Place of delivery by On-Carrier: ${
      oceanOrderContextFormValue?.customValues['placeOfReceiptByPreCarrier'] ||
      ''
    }
    Origin custom code: ${
      oceanOrderContextFormValue?.customValues['originCustomCode'] || ''
    }
    Destination custom code: ${
      oceanOrderContextFormValue?.customValues['destinationCustomCode'] || ''
    }
    Route transportation: ${
      oceanOrderContextFormValue?.customValues['routeTransportation'] || ''
    }
    Related companies: ${
      oceanOrderContextFormValue?.customValues['relatedCompanies'] || ''
    }
    General Delivering Carrier: ${
      oceanOrderContextFormValue?.deliveringCarrier.contactName || ''
    }
    Available date: ${
      getFormattedDate(
        oceanOrderContextFormValue?.customValues['availableDate'],
        false,
      ) || ''
    }
    Last free day: ${
      getFormattedDate(
        oceanOrderContextFormValue?.customValues['lastFreeday'],
        false,
      ) || ''
    }
    Pickup from: ${oceanOrderContextFormValue?.pickupFrom.contactName || ''}
    Pickup from address: ${
      oceanOrderContextFormValue?.pickupFrom.contactAddressName || ''
    }
    Deliver to: ${oceanOrderContextFormValue?.deliverTo.contactName || ''}
    Deliver to address: ${
      oceanOrderContextFormValue?.deliverTo.contactAddressName || ''
    }
    Received by: ${oceanOrderContextFormValue?.receivedBy.contactName || ''}
    Delivery date: ${
      getFormattedDate(
        oceanOrderContextFormValue?.receivedBy?.customValues['receivedByDate'],
        false,
      ) || ''
    }
    Delivery time: ${getFormattedTime(
      oceanOrderContextFormValue?.receivedBy?.customValues['receivedByTime'],
    )}
    Comments: ${
      oceanOrderContextFormValue?.customValues['deliveryComments'] || ''
    }
    Internal notes: ${
      oceanOrderContextFormValue?.customValues['deliveryInternalNotes'] || ''
    }
    Documents:
    ${
      templates?.items?.length > 0
        ? templates?.items?.map(
            (template) => `${template?.documentTemplateType} `,
          )
        : ''
    }
    Commodities:
    ${
      commodities?.items?.length > 0
        ? commodities?.items?.map(
            (commodity) =>
              `${commodity?.description} ${commodity?.packageTypeName} `,
          )
        : ''
    }
  `;
  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <h2>Completing the ocean consolidated wizard</h2>
        </div>
      </div>
      <Field
        as={'textarea'}
        className={`form-control form-control-md`}
        style={{ resize: 'none' }}
        name={`values`}
        id={`values`}
        label={''}
        required={false}
        type={'textarea'}
        disabled={true}
        defaultValue={information}
        key={'test'}
        rows={15}
        useFormikContext={false}
      />
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button
          type="submit"
          form={'oceanOrderForm'}
          color="primary"
          disabled={false}
          isSending={false}
          data-testid={'save-ocean-shipment'}
        >
          Save Ocean Shipment
        </Button>
        <Button className="mr-3" onClick={handleBack} data-testid={'back'}>
          Back
        </Button>
      </div>
    </div>
  );
};
