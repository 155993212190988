export const getFileFromUser = (): Promise<File> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        resolve(file);
      } else {
        resolve(null);
      }
    };
    input.onerror = () => {
      reject(new Error('Error with the input element'));
    };
    input.click();
  });
};
