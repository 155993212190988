import React, {
  CSSProperties,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import { Pagination } from '../pagination/pagination.component';
import {
  getAdditionalColumnsData,
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import { OrderForListDto } from '../../../../models/custom.models';
import { Column, CustomFieldType } from '../../../../models/data.models';
import { unescapeString } from '../../../../utils/helper.utils';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { getContact } from '../../../contacts/contacts.store';
import { Button } from '../button/button.component';
import {
  FilteredField,
  FiltersTab,
  GridContextValue,
} from '../filters/filtersTab.component';
import { IAction } from '../actions/actions';
import { useScanner } from '../../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../../barcodeScanner/scanner.store';
import lodash from 'lodash';

export type WarehouseReceiptGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  actions?: IAction[];
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: OrderForListDto[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSelect?: (item: any, keys: any) => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  onChangeItem?: (items: any[]) => void;
  showAllFilters?: boolean;
  onFindCommodities?: () => void;
  defaultColumns?: Column[];
  defaultSort?: string;
  defaultLimit?: number;
  showToolbar?: boolean;
  entityType?: string;
};

const GridContext = createContext<GridContextValue | null>(null);

export const useGridContext = (): GridContextValue => {
  const contextValue = useContext(GridContext);

  if (!contextValue) {
    throw new Error(
      'useGridContext must be used within a GridContext.Provider',
    );
  }

  return contextValue;
};

export const WarehouseReceiptGrid = ({
  data = [],
  actions = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onSort = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = false,
  showAllStore = false,
  showAllFilters = true,
  onFindCommodities,
  defaultColumns,
  defaultSort,
  defaultLimit,
  showToolbar = true,
  entityType = null,
}: WarehouseReceiptGridProps) => {
  const colByName: { [key: string]: any } = {};

  const [gridColumns, setGridColumns] = useState<Column[]>([...columns]);
  const [filters, setFilters] = useState<FilteredField[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<FilteredField[]>([]);

  const onColumnsChangedHandler = (columnsChanged: Column[]): void => {
    setGridColumns([...columnsChanged]);
  };

  useEffect(() => {
    if (columns.length === 0) return;
    setGridColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    setFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: filters.find((y) => y.name === x.name)?.visible ?? false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
    setDefaultFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
  }, [gridColumns]);

  const [addData, setAddData] = useState({});

  useEffect(() => {
    const filterParts = new URLSearchParams(window.location.search)
      .get('filter')
      ?.split(' AND ');

    const billToContactId = filterParts
      ?.find((x) => x.includes('BillToContactId:'))
      ?.split(':')[1];

    let createdByUserName = filterParts
      ?.find((x) => x.includes('CreatedUser.UserName:'))
      ?.split(':')[1];

    if (createdByUserName) {
      createdByUserName = unescapeString(createdByUserName, false);
    }

    const customFieldsNames = gridColumns
      ?.filter((column) => column.type === 'customField')
      .map((column) => column.name);

    const customFieldsValues = customFieldsNames
      .map((name) => {
        return filterParts?.find((x) => x.includes(name))?.split(':')[1];
      })
      .filter((field) => field !== null && field !== undefined)
      .map((field) => {
        return unescapeString(field, true);
      });

    let customFields = {};
    for (let i = 0; i < customFieldsValues.length; i++) {
      customFields[`__${customFieldsNames[i]}`] = customFieldsValues[i];
    }

    if (billToContactId) {
      getContact({ contactId: Number(billToContactId) }).then((contact) => {
        setAddData({
          ...addData,
          __BillToContactName: contact.name,
          __BillToContactType: contact.contactType,
          __createdByUserName: createdByUserName ?? '',
          ...customFields,
        });
      });
    } else {
      setAddData({
        ...addData,
        __createdByUserName: createdByUserName ?? '',
        ...customFields,
      });
    }
  }, [gridColumns]);

  const updateCols = (colName: string) => {
    setGridColumns((prev) =>
      prev.map((x) => {
        const col = x;
        if (x.name === colName) {
          x.visible = !col.visible;
        }
        return x;
      }),
    );
  };

  const updateSort = (colName: string) => {
    const sortName = gridColumns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.startsWith('-') ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    (document.querySelector(
      '.filter-dropdown .dropdown-toggle',
    ) as HTMLSpanElement).click();
    setAddData(getAdditionalColumnsData(filters));
    return onFilter(getSearchQuery(filters, gridColumns));
  };

  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  const getTdClass = (itemName?: any) => {
    let resultClasses = ['cursor-pointer'];
    if (itemName === 'firstOrderPickupsShipperAddressName') {
      resultClasses.push('three-dots order-grid-origin');
    } else if (itemName === 'firstOrderDeliveriesConsigneeAddressName') {
      resultClasses.push('three-dots order-grid-destination');
    } else if (itemName === 'orderStatus') {
      resultClasses.push('order-grid-order-status');
    }
    return resultClasses.join(' ');
  };

  const getFilteredRowsFilter = (): Column[] => {
    return gridColumns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  gridColumns?.forEach((col) => {
    colByName[col.name] = col;
  });

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
  };

  const onFiltersChangedHandler = (filtersChanged: FilteredField[]): void => {
    if (filtersChanged) setFilters([...filtersChanged]);
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) =>
      gridColumns.some((column) => column.showFilter) && (
        <span
          className="dropdown-toggle pointer"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          <BsFilter />
          &nbsp;
          <small className="align-middle pl-1 text-uppercase">Filters</small>
          &nbsp;
          <small className="align-middle text-primary">
            {getReadableQueryString(filter, getFilteredRowsFilter(), addData)}
          </small>
        </span>
      ),
  );

  const [gridContext, setGridContext] = useState<GridContextValue>({
    columns: gridColumns,
    filters,
    search,
    defaultColumns,
    defaultFilters,
    defaultSort,
    defaultLimit,
    onSearch,
    onFilter,
    onColumnsChanged: onColumnsChangedHandler,
    onFiltersChanged: onFiltersChangedHandler,
    onViewChanged,
    actions,
    entityType: entityType,
  });

  useScanner((scannerResult: ScanningResult) => {
    if (scannerResult.data) {
      onSearch(scannerResult.data);
    }
  });

  useEffect(() => {
    setGridContext({
      columns: gridColumns,
      filters,
      search,
      defaultColumns,
      defaultFilters,
      defaultSort,
      defaultLimit,
      onSearch,
      onFilter,
      onColumnsChanged: onColumnsChangedHandler,
      onFiltersChanged: onFiltersChangedHandler,
      onViewChanged,
      actions,
      entityType: entityType,
    });
  }, [gridColumns, filters, search, defaultColumns, defaultFilters]);

  return (
    <>
      <div className="mx-3">
        {(filters.length > 0 || gridColumns.length > 0) && (
          <GridContext.Provider value={gridContext}>
            <FiltersTab useGridContext={useGridContext} />
          </GridContext.Provider>
        )}
      </div>

      <div className={`grid mx-3 my-4 ${className}`} style={style}>
        <div className="grid-toolbar d-flex pl-3">
          {showToolbar && (
            <>
              {onSearch ? (
                <input
                  type="search"
                  className="form-control my-2 w-50"
                  placeholder="Search"
                  onChange={updateSearch}
                  value={decodeURIComponent(search)}
                />
              ) : null}
              {onFilter ? (
                <div className="my-3 px-3 w-50 filter-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu>
                      {showAllFilters === true ||
                      rowIncludeFilterKeys?.length > 0 ? (
                        <div className="px-5" style={{ minHeight: '300px' }}>
                          <div className="filters-form-block">
                            <Filters
                              query={filter}
                              columns={getFilteredRowsFilter()}
                              onFilter={updateFilter}
                              addData={addData}
                            />
                          </div>
                        </div>
                      ) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null}
              <div className="dropdown dropdown-columns my-3 px-3 ml-auto pointer">
                <div
                  className="dropdown-toggle"
                  id="dropdownColumnsButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BsListTask className="" />
                  &nbsp;
                  <small className="align-middle pl-1 text-uppercase">
                    Columns
                  </small>
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownColumnsButton"
                  style={{ width: 'max-content', maxWidth: 'unset' }}
                >
                  {gridColumns
                    .filter((item) => {
                      if (showAllStore) {
                        return true;
                      }
                      return rowKeys?.includes(item.name);
                    })
                    .map((col) => {
                      return (
                        <label key={col.name} className="dropdown-item pointer">
                          <input
                            type="checkbox"
                            onChange={() => updateCols(col.name)}
                            defaultChecked={col.visible}
                          />{' '}
                          {col.title}
                        </label>
                      );
                    })}
                </div>
              </div>
            </>
          )}

          {onFindCommodities && (
            <div
              className={`filter-dropdown row justify-content-around ${
                showToolbar ? 'mr-3' : 'px-3'
              }`}
            >
              <Button
                type={'button'}
                onClick={onFindCommodities}
                style={{ minWidth: '11rem' }}
              >
                Find Commodities
              </Button>
            </div>
          )}
        </div>
        {total > 0 || showEmptyTable === true ? (
          <div className="bg-white horizontal-scrollbar">
            <table className="table">
              <thead>
                <tr>
                  {gridColumns
                    .filter((col) => {
                      if (showAllStore && col.visible) {
                        return true;
                      }
                      return col.visible && rowKeys?.includes(col.name);
                    })
                    .map((col) => {
                      return (
                        <th scope="col" key={col.name}>
                          <a
                            className={col.sortName ? 'link' : 'inactive-link'}
                            onClick={() =>
                              col.sortName ? updateSort(col.name) : null
                            }
                          >
                            {col.title}
                            {sort === col.name ||
                            sort === col.sortName ||
                            sort === col.sortName + '~ToInt32' ? (
                              <FaArrowDown />
                            ) : null}
                            {sort === '-' + col.name ||
                            sort === '-' + col.sortName ||
                            sort === '-' + col.sortName + '~ToInt32' ? (
                              <FaArrowUp />
                            ) : null}
                          </a>
                        </th>
                      );
                    })}
                  <th colSpan={2}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => {
                  return (
                    <tr
                      data-cy-tracking-number={row.trackingNumber}
                      key={rowKeys?.map((x) => row[x]).join('_')}
                    >
                      {Object.values(gridColumns)
                        .filter((item) => {
                          if (showAllStore && item.visible) {
                            return true;
                          }
                          return item.visible && rowKeys?.includes(item.name);
                        })
                        .map((item, index) => {
                          switch (item.name) {
                            case 'carrierContactName':
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  onClick={(event) => {
                                    if (onSelect) {
                                      onSelect(
                                        row,
                                        rowKeys?.reduce((keyObj, field) => {
                                          return row[field];
                                        }),
                                      );
                                    }
                                  }}
                                  style={{
                                    width: `calc(100% / ${
                                      gridColumns.filter(
                                        (column) => column.visible === true,
                                      ).length
                                    })`,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {row?.carriers.map((carrier) => (
                                    <span style={{ whiteSpace: 'pre' }}>
                                      {carrier.name} - {carrier.contactType}
                                      {'\n'}
                                    </span>
                                  ))}
                                </td>
                              );
                            default:
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  onClick={(event) => {
                                    if (onSelect) {
                                      onSelect(
                                        row,
                                        rowKeys?.reduce((keyObj, field) => {
                                          return row[field];
                                        }),
                                      );
                                    }
                                  }}
                                  style={{
                                    width: `calc(100% / ${
                                      gridColumns.filter(
                                        (column) => column.visible === true,
                                      ).length
                                    })`,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {gridColumns?.find(
                                    (column) =>
                                      column.name === item.name &&
                                      column.type === 'customField' &&
                                      column.customFieldType ===
                                        CustomFieldType.Boolean,
                                  ) ? (
                                    lodash.get(row, item.name) === true ||
                                    lodash.get(row, item.name) === 'true' ? (
                                      <>&#x2713;</>
                                    ) : (
                                      <></>
                                    )
                                  ) : gridColumns?.find(
                                      (column) =>
                                        column.name === item.name &&
                                        column.type === 'customField' &&
                                        column.customFieldType ===
                                          CustomFieldType.Date,
                                    ) ? (
                                    getFormattedDate(
                                      lodash.get(row, item.name),
                                      false,
                                    )
                                  ) : gridColumns?.find(
                                      (column) =>
                                        column.name === item.name &&
                                        column.type === 'Date',
                                    ) ? (
                                    getFormattedDate(
                                      lodash.get(row, item.name),
                                      true,
                                    )
                                  ) : (
                                    <>{lodash.get(row, item.name)}</>
                                  )}
                                </td>
                              );
                          }
                        })}
                      <td style={{ textAlign: 'center' }}>
                        <div className="dropdown">
                          <BsThreeDotsVertical
                            className="dropdown-toggle dropdown-dots-vertical pointer"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          />
                          <div
                            className="dropdown-menu dropdown-menu-grid"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {onDelete && (
                              <span
                                title={
                                  row.charges?.length > 0
                                    ? 'Warehouse Receipt contains charges'
                                    : null
                                }
                              >
                                <a
                                  role="button"
                                  className="dropdown-item"
                                  onClick={() => {
                                    onDelete(row);
                                  }}
                                  style={
                                    row.charges?.length > 0
                                      ? {
                                          pointerEvents: 'none',
                                          color: 'gray',
                                        }
                                      : null
                                  }
                                >
                                  Delete
                                </a>
                              </span>
                            )}
                            <a
                              role="button"
                              className="dropdown-item"
                              onClick={() => {
                                onEdit(row);
                              }}
                            >
                              Edit
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center mt-4">Nothing Found</p>
        )}
        {showPagination === true ? (
          <div className="grid-footer">
            <Pagination
              goToPage={onPageChanged}
              offset={offset}
              limit={limit}
              total={total}
              onLimitChanged={onLimitChanged}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
