import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { OrderTypes, StatusStage } from '../../../models/data.models';
import { CustomValuesInputProps } from '../../common/components/input/customFields-input.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';

export const OrderStatusForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
  validateOnChange,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
    >
      {children}
    </Form>
  );
};

OrderStatusForm.OrderStatusDescription = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'orderStatusDescription'}
      label={'Order Status Description'}
      placeholder={'Order Status Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
OrderStatusForm.OrderStatusName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'orderStatusName'}
      label={'Order Status Name'}
      placeholder={'Order Status Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
OrderStatusForm.OrderType = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'orderType'}
      label={'Order Type'}
      placeholder={''}
      required={true}
      type={'enum-select'}
      enumName={OrderTypes}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
OrderStatusForm.Priority = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'priority'}
      label={'Priority'}
      placeholder={'Priority'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
OrderStatusForm.StatusStage = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'statusStage'}
      label={'Status Stage'}
      placeholder={''}
      required={true}
      type={'enum-select'}
      enumName={StatusStage}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};

OrderStatusForm.RequireConfirmation = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'requireConfirmation'}
      label={'Require Confirmation'}
      placeholder={''}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

OrderStatusForm.CustomFields = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      isSending={isSending}
      saveButtonRenderCondition={saveButtonRenderCondition}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
