import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { JobsListScreen } from './screens/jobs-list.screen';
import { JobScreen } from './screens/job.screen';

export const JOBS_LIST_PATH = '/jobs';
export const JOB_EDIT_PATH = '/jobs/:jobId';
export const JOB_CREATE_PATH = '/jobs/create';

export const JobsRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <JobsListScreen pageTitle="Jobs" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <JobScreen pageTitle="Add New Job" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':jobId'}
        element={
          <PrivateRoute>
            <JobScreen pageTitle="Update Job" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
