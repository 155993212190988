import { useStore } from 'effector-react';
import React, { useRef } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
import { scannerStore } from '../scanner.store';
import { connect, disconnect, scan } from '../scanner.manager';
import { USE_SCANNER_EMULATOR } from '../../../properties';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { showDialog } from '../../common/dialog.store';
import { TakePhotoDialog } from '../../common/takePhoto/takePhoto.dialog';

export const ScannerButton = () => {
  const { status, progress, device } = useStore(scannerStore);
  const inputCode = useRef(null);
  const emulateScan = () => {
    if (inputCode.current) {
      scan(inputCode.current.value, 'scanner');
    }
  };
  const takePhoto = async () => {
    const imageUrl = await showDialog({
      dialog: TakePhotoDialog,
      props: { title: 'Take a Photo', className: 'delete-modal' },
    });
    if (!('BarcodeDetector' in window)) {
      console.error('Barcode Detector is not supported by this browser.');
    } else {
      window.BarcodeDetector.getSupportedFormats().then((supportedFormats) => {
        const barcodeDetector = new window.BarcodeDetector({
          formats: supportedFormats,
        });
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          barcodeDetector
            .detect(image)
            .then((barcodes) => {
              if (barcodes.length) {
                const firstBarcode = barcodes[0];
                scan(firstBarcode.rawValue, 'scanner');
                scan(firstBarcode.rawValue);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        };
      });
    }
  };

  return (
    <div className="dropdown">
      <div data-toggle="dropdown" className="position-relative">
        <BiBarcodeReader className="h1 text-light mt-1 cursor-pointer" />
        {/* <div
          className={`scanner-indicator ${status ? 'bg-info' : 'bg-danger'}`}
        ></div> */}
      </div>
      <div
        className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        {/* <div className="mb-2 dropdown-item">
          {!status || !device ? (
            <div>
              <div className="mb-3">
                <b>Scanner Disconnected</b>
              </div>
              {progress ? (
                <div className="badge bg-secondary">
                  {progress.split('_').join(' ')}
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <div className="mb-3">
                <b>{device ? device.kdcName : null}</b>
              </div>
              <div className="badge bg-info">
                {progress.split('_').join(' ')}
              </div>
            </div>
          )}
        </div> */}
        <div className="dropdown-item">
          {/* {status ? (
            <>
              <button
                className="btn btn-danger btn-block btn-sm"
                onClick={() => disconnect()}
              >
                Disconnect
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-success btn-block btn-sm"
                onClick={() => connect()}
              >
                Connect
              </button>
            </>
          )} */}
          {USE_SCANNER_EMULATOR ? (
            <div className="my-2 p-2 border border-radius">
              <input
                ref={inputCode}
                className="form-control mb-2"
                placeholder="Code"
              />
              <button
                onClick={emulateScan}
                className="btn btn-block btn-primary"
              >
                Scan
              </button>
            </div>
          ) : null}
          <div className="mt-2">
            <button onClick={takePhoto} className="btn btn-block btn-primary">
              <MdOutlinePhotoCamera className="mr-2" /> Scan with Camera
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
