import { Dialog } from '../../common/components/dialog/dialog.component';
import { ContainerTypeEdit } from './containerType-edit.component';
import { ContainerTypeDto } from '../../../models/data.models';

export type ContainerTypeDialogProperties = {
  containerTypeCode?: string | null;
  title: string;
};

export class ContainerTypeDialog extends Dialog<ContainerTypeDialogProperties> {
  constructor(props: ContainerTypeDialogProperties) {
    super(props);
    this.onContainerTypeSaved = this.onContainerTypeSaved.bind(this);
  }

  onContainerTypeSaved = (containerType: ContainerTypeDto) => {
    this.close(containerType);
  };

  renderContent(): any {
    const { containerTypeCode } = this.props as ContainerTypeDialogProperties;
    return (
      <ContainerTypeEdit
        containerTypeCode={containerTypeCode}
        onContainerTypeCreated={this.onContainerTypeSaved}
        onContainerTypeUpdated={this.onContainerTypeSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
