import {
  EntityTypes,
  OrderDocumentDto,
  OrderDto,
  OrderTypes,
} from '../../../models/data.models';
import {
  regenerateOrderDocumentFx,
  RegenerateOrderDocumentsParams,
} from '../../orderDocuments/orderDocuments.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { updateOrder } from '../../orders/orders.store';
import { OrderEditFormDto } from '../../../models/custom.models';
import { Pagination } from '../../common/components/pagination/pagination.component';
import { OrderDocumentCard } from '../../orderDocuments/components/orderDocumentCard.component';
import { getOrderDocumentLink } from '../../orderDocuments/orderDocuments.service';

export type OrderDocumentsListProps = {
  airOrderContextFormValue: OrderEditFormDto;
  onSaveAirOrder: (data: OrderDto) => OrderDto;
  setIsSending: (sending: boolean) => void;
  showPagination?: boolean;
  total?: number;
  offset?: number;
  limit?: number;
  filter?: string;
  onLimitChange?: (limit: number) => void;
  onPageChanged?: (page: number) => void;
  orderDocuments?: OrderDocumentDto[];
  setSelectedDocuments?: (selectedItem: any) => void;
  selectedDocuments?: OrderDocumentDto[];
  orderType?: OrderTypes;
  onDeleteDocument?: (document: OrderDocumentDto) => void;
  onEditDocument?: (document: OrderDocumentDto) => void;
};

export const OrderDocumentsList = ({
  airOrderContextFormValue,
  onSaveAirOrder,
  setIsSending,
  showPagination = false,
  onLimitChange = () => {},
  onPageChanged = () => {},
  total,
  offset,
  limit,
  filter,
  orderDocuments,
  orderType,
  setSelectedDocuments,
  selectedDocuments,
  onDeleteDocument,
  onEditDocument,
}: OrderDocumentsListProps) => {
  const regenerateDocument = (document: OrderDocumentDto) => {
    const data = onSaveAirOrder(airOrderContextFormValue);
    updateOrder(data)
      .then(() => {
        const params: RegenerateOrderDocumentsParams = {
          orderDocument: document,
          values: {
            documentTemplateId: document.documentTemplateId,
            metadata: {
              orderId: document.orderId.toString(),
              orderPickupId: airOrderContextFormValue?.orderEntities
                .find((entity) => entity.entityType === EntityTypes.Shipper)
                ?.orderEntityId.toString(),
              orderDeliveryId: airOrderContextFormValue?.orderEntities
                .find((entity) => entity.entityType === EntityTypes.Consignee)
                ?.orderEntityId.toString(),
              thirdPartyContactId: '',
              carrierId: '',
            },
          },
        };
        regenerateOrderDocumentFx(params).then((result) => {
          if (result) {
            addMessage({
              message: 'Document has been succesfully updated',
              type: 'success',
              id: v4(),
            });
          }
        });
      })
      .catch((error) =>
        addMessage({
          message: error,
          type: 'danger',
          id: v4(),
        }),
      )
      .finally(() => setIsSending(false));
  };

  const isRegeneratingEnabled =
    airOrderContextFormValue &&
    airOrderContextFormValue.shipper &&
    airOrderContextFormValue.shipper.contactId !== null &&
    !isNaN(airOrderContextFormValue.shipper.contactId) &&
    airOrderContextFormValue.consignee &&
    airOrderContextFormValue.consignee.contactId !== null &&
    !isNaN(airOrderContextFormValue.consignee.contactId);

  const openDocument = async (document: OrderDocumentDto) => {
    const s3link = await getOrderDocumentLink(document);
    window.open(s3link ?? document?.lastGeneratedFile, '_blank');
  };

  return (
    <div className="order-document-tabs mb-4">
      <div className="container pl-0 pr-0">
        <div className="custom-cards-columns pt-5">
          {orderDocuments?.map((document) => {
            return (
              <OrderDocumentCard
                document={document}
                openDocument={openDocument}
                isRegeneratingEnabled={
                  orderType === OrderTypes.ParcelShipment
                    ? false
                    : isRegeneratingEnabled
                }
                regenerateDocument={regenerateDocument}
                setSelectedDocuments={setSelectedDocuments}
                onDeleteDocument={onDeleteDocument}
                documentName={document?.orderDocumentName}
                isRegenerable={true}
                isAttachment={false}
                isSelected={selectedDocuments
                  .map((item) => item.orderDocumentId)
                  .includes(document.orderDocumentId)}
                onEditDocument={onEditDocument}
              />
            );
          })}
        </div>
      </div>
      <div className="px-6 py-4" style={{ borderTop: '1px solid #eaecf0' }}>
        {showPagination === true ? (
          <div className="d-flex justify-content-between grid-footer">
            <Pagination
              goToPage={onPageChanged}
              offset={offset}
              limit={limit}
              total={total}
              onLimitChanged={onLimitChange}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
