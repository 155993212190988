import { createEffect, createEvent, createStore, Event, Store } from 'effector';
import { Dialog } from './components/dialog/dialog.component';
export type DialogStoreState = {
  dialogs: Modal[];
};

export type Modal = {
  modal: any;
  props: any;
  _resolve?: Function;
  _promise?: Promise<any>;
};

const defaultState: DialogStoreState = {
  dialogs: [],
};

export const showDialog = createEffect(
  ({ dialog, props }: { dialog: any; props: any }) => {
    let _resolve = null;
    const _promise: Promise<any> = new Promise((resolve) => {
      _resolve = resolve;
    });
    insertDialog({ modal: dialog, props, _resolve, _promise });
    return _promise;
  },
);

export const insertDialog: Event<Modal> = createEvent();
export const clearDialogs: Event<void> = createEvent();
export const closeDialog: Event<Modal> = createEvent();

export const dialogStore: Store<DialogStoreState> = createStore(defaultState)
  .on(insertDialog, (state, payload) => {
    return { ...state, dialogs: [...state.dialogs, payload] };
  })
  .on(clearDialogs, (state) => {
    return { ...state, dialogs: [] };
  })
  .on(closeDialog, (state, payload) => {
    const index = state.dialogs.indexOf(payload);
    state.dialogs.splice(index, 1);
    return { ...state, dialogs: [...state.dialogs] };
  });
