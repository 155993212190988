import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DivisionScreen } from './screens/division.screen';
import { DivisionsListScreen } from './screens/divisions-list.screen';

export const DIVISIONS_LIST_PATH = '/divisions';
export const DIVISION_EDIT_PATH = '/divisions/:divisionId';
export const DIVISION_CREATE_PATH = '/divisions/0';

export const DivisionRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <DivisionsListScreen pageTitle="Divisions" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <DivisionScreen pageTitle="Add New Division" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':divisionId'}
        element={
          <PrivateRoute>
            <DivisionScreen pageTitle="Update Division" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
