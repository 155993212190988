import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContainerTypesListScreen } from './screens/containerTypes-list.screen';
import { ContainerTypeScreen } from './screens/containerType.screen';

export const CONTAINER_TYPES_LIST_PATH = '/container-types';
export const CONTAINER_TYPE_EDIT_PATH =
  '/container-types/edit/:containerTypeCode';
export const CONTAINER_TYPE_CREATE_PATH = '/container-types/0';

export const ContainerTypesRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ContainerTypesListScreen
              pageTitle="Container Types"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ContainerTypeScreen group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'edit/:containerTypeCode'}
        element={
          <PrivateRoute>
            <ContainerTypeScreen group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
