import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const ClauseForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  onKeyPress,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      initialValues={initialValues}
      innerRef={innerRef}
      onKeyPress={onKeyPress}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

ClauseForm.Category = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'category'}
      label={'Category'}
      placeholder={'Category'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ClauseForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ClauseForm.Name = ({ className, style, size, disabled }: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
