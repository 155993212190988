import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PurchaseScreen } from './screen/purchase.screen';
import { PurchasesListScreen } from './screen/purchases-list.screen';

export const PURCHASE_LIST_PATH = '/purchases';
export const PURCHASE_EDIT_PATH = '/purchases/:purchaseId';
export const PURCHASE_CREATE_PATH = '/purchases/0';

export const PurchaseRoute = (props: RouteProps) => {
  const group = 'Operations';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <PurchasesListScreen pageTitle="Purchases" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <PurchaseScreen pageTitle="Add new purchase" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':purchaseId'}
        element={
          <PrivateRoute>
            <PurchaseScreen pageTitle="Update purchase" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
