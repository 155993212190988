import { AirShipmentOrderDialog } from '../modules/airShipmentOrders/components/airShipmentOrder.dialog';
import { OceanShipmentOrderDialog } from '../modules/oceanShipment/components/oceanShipmentOrder.dialog';
import { OrderDialog } from '../modules/orders/components/order.dialog';
import { ParcelShipmentDialog } from '../modules/parcelShipments/components/parcelShipment.dialog';
import { PurchaseDialog } from '../modules/purchases/components/purchase.dialog';
import { QuoteDialog } from '../modules/quotes/components/quote.dialog';
import { WarehouseReceiptDialog } from '../modules/warehouseReceipts/components/warehouseReceipt.dialog';
import { OrderDialog as CargoMovementDialog } from '../modules/cargoMovement/components/cargoMovement.dialog';

const dialogMap = {
  Order: OrderDialog,
  Quote: QuoteDialog,
  WarehouseReceipt: WarehouseReceiptDialog,
  Purchase: PurchaseDialog,
  ParcelShipment: ParcelShipmentDialog,
  AirShipmentOrder: AirShipmentOrderDialog,
  OceanShipmentOrder: OceanShipmentOrderDialog,
  CargoMovement: CargoMovementDialog,
};

export const getDialog = (orderType) => {
  return dialogMap[orderType] || OrderDialog;
};
