import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { ErrorMessage, Field } from 'formik';
import { FormContext } from '../form/form.component';
import { clearMessages } from '../../messages.store';

type ReactSelectProps = {
  id?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  options?: any[];
  multiple?: boolean;
  value?: any;
  defaultValue?: any;
  error?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  onChange?: (data: any, context?: any) => void;
  useContext?: boolean;
  readonly?: 'readonly' | '';
  isSearchable?: boolean;
  classNamePrefix?: string;
  validate?: (data: any) => Promise<any>;
  header?: string;
  getOptionLabel?: (option: any) => any;
  getOptionValue?: (option: any) => any;
  onClick?: (event) => any;
  tabIndex?: number;
  customStyles?: any;
};

export const ReactSelect = ({
  id,
  name,
  disabled,
  required,
  options,
  multiple,
  value,
  defaultValue,
  isClearable = false,
  onChange,
  placeholder,
  useContext = true,
  readonly = '',
  isSearchable = false,
  classNamePrefix = 'select',
  header,
  getOptionLabel = (option) => option?.label,
  getOptionValue = (option) => option?.value,
  onClick = (event) => {},
  tabIndex,
  customStyles,
}: ReactSelectProps) => {
  const [stateValue, setStateValue] = useState(defaultValue);

  useEffect(() => {
    setStateValue(defaultValue);
  }, [defaultValue]);

  const onChangeInput = (option, contextDto?) => {
    if (typeof onChange === 'function') {
      onChange(option, contextDto);
    }
  };

  if (!useContext) {
    return (
      <div>
        <div>
          <Select
            id={id}
            name={name}
            isDisabled={disabled}
            required={required}
            options={options}
            isMulti={multiple}
            isSearchable={readonly === 'readonly' ? false : isSearchable}
            isClearable={readonly === 'readonly' ? false : isClearable}
            menuIsOpen={readonly === 'readonly' ? false : undefined}
            classNamePrefix={`${id} ${classNamePrefix}`}
            value={defaultValue}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder={placeholder}
            onChange={(selected) => {
              onChangeInput(selected);
            }}
            onFocus={clearMessages}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onClick={onClick}
            tabIndex={tabIndex}
            styles={customStyles}
          />
        </div>
      </div>
    );
  } else {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div>
            {header ? (
              <label className="input-label" htmlFor={name}>
                <span className="d-flex justify-content-between align-items-center">
                  <span className="input-label-primary">{header}</span>
                </span>
              </label>
            ) : null}
            <Field
              required={required}
              type="hidden"
              name={name}
              value={
                multiple
                  ? stateValue?.map((value) => getOptionValue(value))
                  : getOptionValue(stateValue)
              }
            />
            <Select
              id={id}
              name={name}
              isDisabled={disabled}
              isClearable={readonly === 'readonly' ? false : isClearable}
              required={required}
              options={options}
              isMulti={multiple}
              classNamePrefix={`${id} ${classNamePrefix}`}
              isSearchable={readonly === 'readonly' ? false : isSearchable}
              menuIsOpen={readonly === 'readonly' ? false : undefined}
              defaultValue={stateValue}
              value={value ? value : stateValue}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder={placeholder}
              onChange={(selected) => {
                setStateValue(selected);
                if (multiple) {
                  context?.setFieldValue(
                    name,
                    selected?.map((option) => getOptionValue(option) ?? null),
                  );
                } else {
                  context?.setFieldValue(
                    name,
                    getOptionValue(selected) ?? null,
                  );
                }
                onChangeInput(selected, context);
              }}
              onFocus={clearMessages}
              onBlur={() => {
                context.setFieldTouched(name, true);
              }}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onClick={onClick}
              tabIndex={tabIndex}
              styles={customStyles}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  }
};
