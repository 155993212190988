import React, { useEffect, useState } from 'react';
import {
  WarehouseZoneDto,
  WarehouseZoneDtoPagedResult,
} from '../../../models/data.models';
import { getWarehouseZonesFx } from '../warehouseInspector.store';
import {
  CREATE_WAREHOUSEZONE_LINK_KEY,
  GetWarehouseZoneParams,
} from '../warehouseInspector.service';
import { ZoneView } from './zoneView.component';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';

export const ZONE_INSPECTOR_ROOT_ZONES = 'inspect_zones';

export type WarehouseZonesListProps = {
  goToDetails?: (warehouseZoneParams: GetWarehouseZoneParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  filter?: string;
  sort?: any;
  onFilter?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSearch?: (query: string) => void;
  onSelect?: (
    warehouseZone: WarehouseZoneDto,
    warehouseZoneParams: GetWarehouseZoneParams,
  ) => void;
  isDropDownList?: boolean;
};

export const WarehouseInspectorList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = '',
  filter = null,
  sort = null,
  onFilter = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onSearch,
  isDropDownList = false,
}: WarehouseZonesListProps) => {
  const [
    warehouseZones,
    setWarehouseZones,
  ] = useState<WarehouseZoneDtoPagedResult | null>({
    items: [],
    totalCount: 0,
    limit: 20,
    offset: 0,
    links: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWarehouseZonesFx({
      offset,
      limit,
      sort,
      search: ZONE_INSPECTOR_ROOT_ZONES,
    }).then((warehouseZonesData) => {
      setWarehouseZones(warehouseZonesData);
      setLoading(false);
    });
  }, [offset, limit, sort]);

  const onCreateNewWarehouseZone = () => {
    goToDetails({ warehouseZoneId: 0 });
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <ul className="pt-5 pl-5 bg-white list-group">
          {warehouseZones.items.map((el) => {
            return <ZoneView key={el.warehouseZoneId} zoneDto={el} />;
          })}
        </ul>
        {(!warehouseZones || warehouseZones?.totalCount == 0) &&
          !filter &&
          !search && (
            <ListElementsNotFound
              entityName="Warehouse Zone"
              entityLinkKey={CREATE_WAREHOUSEZONE_LINK_KEY}
              onClick={onCreateNewWarehouseZone}
            />
          )}
      </>
    );
  }
};
