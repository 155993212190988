import React, { useEffect, useState } from 'react';
import { AccountingAccountDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { v4 as uuidv4 } from 'uuid';
import {
  getAccountingAccountFx,
  getAccountingAccountsFx,
} from '../accountingAccounts.store';
import { showDialog } from '../../common/dialog.store';
import { AccountingAccountDialog } from './accountingAccount.dialog';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import {
  ACCOUNTINGACCOUNT_ENTITY_NAME,
  CREATE_ACCOUNTINGACCOUNT_LINK_KEY,
  GET_ACCOUNTINGACCOUNT_LINK_KEY,
} from '../accountingAccounts.service';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { splitByCapitals } from '../../../utils/helper.utils';

export const SelectAccountingAccountPaginate = ({
  id = 'accountId' + uuidv4(),
  nameId = 'accountName' + uuidv4(),
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  showAccountType = false,
  onChangeAccountingAccount = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  readonly = false,
  showCreateDialogTitle = 'Create Accounting Account',
}): JSX.Element => {
  if (selectedFilter.trim().length > 0) {
    selectedFilter = `${selectedFilter} AND isInactive: false`;
  } else {
    selectedFilter = `isInactive: false`;
  }

  const formikContext = useFormikContext();
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const [context, setContext] = useState(null);
  useEffect(() => {
    setContext(useContext);
  }, [useContext]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(`${selectedFilter} AND isInactive: false`);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseContactDtoPagedResult: AccountingAccountDtoPagedResult = await getAccountingAccountsFx(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseContactDtoPagedResult.items,
      hasMore: responseContactDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeAccountingAccount === 'function') {
      onChangeAccountingAccount(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const onChangeWithContext = (accountingAccount, currentContext) => {
    onChange(accountingAccount, currentContext);
    if (currentContext) {
      if (isMulti === true) {
        if (accountingAccount?.length > 0) {
          currentContext.setFieldValue(id, accountingAccount?.length);
          currentContext.setFieldError(id, undefined);
        } else {
          if (required) {
            currentContext.setFieldValue(id, '');
          }
        }
      } else {
        currentContext.setFieldValue(id, accountingAccount?.accountId);
        currentContext.setFieldValue(nameId, accountingAccount?.accountName);
        currentContext.setFieldError(id, undefined);
        const accountName = showAccountType
          ? `${accountingAccount?.accountName} — ${splitByCapitals(
              accountingAccount?.accountType?.toString(),
            )}`
          : `${accountingAccount?.accountName}`;
        setValue({
          accountId: accountingAccount?.accountId,
          accountName,
        });
        if (required && !accountingAccount) {
          currentContext.setFieldValue(id, '');
        }
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onAccountingAccountEditIconClick = () => {
    if (value?.accountId) {
      showDialog({
        dialog: AccountingAccountDialog,
        props: {
          accountId: value.accountId,
          title: 'Update Accounting Account',
        },
      }).then(
        (accountingAccount) => {
          if (accountingAccount !== null) {
            getAccountingAccountFx({
              accountId: Number(value.accountId) || null,
            }).then(
              (accountingAccountDto) => {
                onChangeWithContext(accountingAccountDto, formikContext);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  if (context) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti &&
                value &&
                userHas(GET_ACCOUNTINGACCOUNT_LINK_KEY) ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onAccountingAccountEditIconClick}
                    data-cy={`accountingAccount-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={ACCOUNTINGACCOUNT_ENTITY_NAME}
              canCreateNewEntity={userHas(CREATE_ACCOUNTINGACCOUNT_LINK_KEY)}
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: AccountingAccountDialog,
                  props: {
                    title: showCreateDialogTitle,
                    accountId: 0,
                  },
                }).then(
                  (accountingAccount) => {
                    if (
                      accountingAccount.accountId &&
                      accountingAccount.accountName
                    ) {
                      setClearLoadOptionsCache(!clearLoadOptionsCache);
                      return accountingAccount;
                    }
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.accountId}
              getOptionLabel={(option) =>
                showAccountType
                  ? `${option?.accountName} — ${splitByCapitals(
                      option?.accountType?.toString(),
                    )}`
                  : `${option?.accountName}`
              }
              onChange={(accountingAccount?: any) => {
                onChangeWithContext(accountingAccount, context);
              }}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              isDisabled={isDisabled}
              isClearable={readonly ? false : isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={readonly ? false : isSearchable}
              placeholder={placeholder}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuIsOpen={readonly ? false : undefined}
              classNamePrefix={classNamePrefix}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={ACCOUNTINGACCOUNT_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_ACCOUNTINGACCOUNT_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: AccountingAccountDialog,
            props: {
              title: showCreateDialogTitle,
              accountId: 0,
            },
          }).then(
            (accountingAccount) => {
              if (
                accountingAccount.accountId &&
                accountingAccount.accountName
              ) {
                setClearLoadOptionsCache(!clearLoadOptionsCache);
                return accountingAccount;
              }
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.accountId}
        getOptionLabel={(option) => `${option?.accountName}`}
        onChange={(accountingAccount?: any) => {
          onChange(accountingAccount, context);
        }}
        onFocus={clearMessages}
        isDisabled={isDisabled}
        isClearable={readonly ? false : isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={readonly ? false : isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        menuIsOpen={readonly ? false : undefined}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
