import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import {
  ContactType,
  CustomFieldEntityType,
  GetContactsQuery,
} from '../../../models/data.models';
import {
  CARRIERS_CREATE_PATH,
  CARRIERS_EDIT_PATH,
  CARRIERS_LIST_PATH,
} from '../carrier.route';
import { CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS } from '../../contacts/contact.route';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { userHas } from '../../auth/auth.store';
import { ContactsList } from '../../contacts/components/contacts-list.component';
import {
  contactStore,
  getContactsDefaultColumns,
} from '../../contacts/contacts.store';
import { useStore } from 'effector-react';
import { CarrierDialog } from '../components/carrier.dialog';

export type CarrierListScreenProps = { pageTitle: string; group: string };

const generateHistoryPath = (
  organizationId: string,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    view: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + CARRIERS_LIST_PATH, {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

const contactType = ContactType.Carrier;
const customFieldEntityType = CustomFieldEntityType.Carrier;

export const CarrierListScreen = ({
  pageTitle,
  group,
}: CarrierListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();

  const { defaultSort, defaultLimit } = useStore(contactStore);

  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `contactType:${contactType}`,
    search = '',
    view = '',
  } = useQuery() as GetContactsQuery;
  const navigate = useNavigate();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + CARRIERS_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const goToDetails = (contactId: number = 0) => {
    let path;
    if (window.location.href.includes('contacts')) {
      path = generatePath(INTERNAL_PATH + CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS, {
        organizationId,
        contactId,
      });
    } else {
      path = generatePath(INTERNAL_PATH + CARRIERS_EDIT_PATH, {
        organizationId,
        contactId,
      });
    }
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const params = { offset, limit, sort, filter, search, view };
  const onQueryChange = (newParams: Partial<typeof params>) => {
    const mergedParams = { ...params, ...newParams };
    const newPath = generateHistoryPath(organizationId, mergedParams);
    navigate(newPath);
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + CARRIERS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getContactsDefaultColumns(contactType);
  };

  return (
    <InternalLayout
      group={group}
      title={pageTitle}
      createPath={userHas(CREATE_CONTACT_LINK_KEY) ? createPath : null}
      createLabel={'Carrier'}
    >
      <ContactsList
        search={search}
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        contactType={contactType}
        customFieldEntityType={customFieldEntityType}
        selectDialogType={CarrierDialog}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onViewChanged={onViewChanged}
        onPageChanged={(page) => onQueryChange({ offset: page * limit })}
        onLimitChanged={(limit) => onQueryChange({ limit })}
        onSearch={(search) => onQueryChange({ search, offset: 0 })}
        onSort={(sort) => onQueryChange({ sort })}
        onFilter={(filter) => onQueryChange({ filter, offset: 0 })}
      />
    </InternalLayout>
  );
};
