import React from 'react';
import { TransportationMethod } from '../../../../models/data.models';
import { ImAirplane } from 'react-icons/im';
import { FaShip } from 'react-icons/fa';

export interface StatusBadgeProps {
  label: string;
  stage?: TransportationMethod;
}

export const TransportationMethodBadge = ({
  label,
  stage,
}: StatusBadgeProps) => {
  const getTransportationMethodIcon = () => {
    if (stage) {
      switch (stage) {
        case TransportationMethod.Air:
          return <ImAirplane />;
        case TransportationMethod.Ocean:
          return <FaShip />;
        default:
          return null;
      }
    } else {
      if (label.toLowerCase().includes('air')) return <ImAirplane />;
      else if (label.toLowerCase().includes('ocean')) return <FaShip />;
      else return null;
    }
  };

  return (
    <div className={`transportation-method-badge`}>
      <div className={'transportation-method-badge-img'}>
        {getTransportationMethodIcon()}
      </div>
      {label}
    </div>
  );
};
