import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CARRIERS_CREATE_PATH,
  CARRIERS_LIST_PATH,
  CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS,
} from '../carrier.route';
import { CarrierEdit } from '../components/carrier-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import {
  CREATE_CONTACT_LINK_KEY,
  GetContactParams,
} from '../../contacts/contacts.service';
import { userHas } from '../../auth/auth.store';

export type CarrierScreenProps = { pageTitle: string; group: string };

export const CarrierScreen = ({ pageTitle, group }: CarrierScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, contactId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + CARRIERS_CREATE_PATH, {
      organizationId,
    });
  }
  const onCarrierCreated = () => {
    const path = generatePath(INTERNAL_PATH + CARRIERS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'carrier-created',
      type: 'success',
      autoHide: true,
      message: 'Carrier successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCarrierUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CARRIERS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'carrier-updated',
      type: 'success',
      autoHide: true,
      message: 'Carrier successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CARRIERS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };
  const goToDetails = (contactParams: GetContactParams) => {
    const createOrEdit = CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId: organizationId,
      contactId: contactParams.contactId,
      customerId: contactId,
    });
    navigate(path);
  };
  return (
    <InternalLayout
      group={group}
      title={'Carriers'}
      createPath={userHas(CREATE_CONTACT_LINK_KEY) ? createPath() : null}
      createLabel={'Carrier'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CarrierEdit
        contactId={contactId}
        onCarrierUpdated={onCarrierUpdated}
        onCarrierCreated={onCarrierCreated}
        onCancel={onCancel}
        goToDetails={goToDetails}
      />
    </InternalLayout>
  );
};
