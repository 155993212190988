import {
  ContactAddressDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  ContactAddressDtoPagedResult,
  CreateContactAddressCommandValues,
  UpdateContactAddressCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContactAddressRequest,
  getContactAddressesListRequest,
  getContactAddressRequest,
  deleteContactAddressRequest,
  updateContactAddressRequest,
  importContactAddressesRequest,
  exportContactAddressesRequest,
  GetContactAddressParams,
} from './contactAddresses.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type ContactAddressesStoreState = {
  links: LinkDto[];
  contactAddressColumns: EntityFieldDto[];
  defaultContactAddressColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getContactAddressesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContactAddress',
  });
});

addEffectStatusHandling(getContactAddressesColumnsFx);

export const getContactAddressesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContactAddress',
  });
});

addEffectStatusHandling(getContactAddressesDefaultColumnsFx);

export const updateContactAddressesColumns = createEvent<EntityFieldDto[]>();

export const getContactAddressesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContactAddressesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getContactAddressesFx);

export const createContactAddressFx = createEffect(
  (contactAddressData: ContactAddressDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createContactAddressCommand: CreateContactAddressCommandValues = {
      ...contactAddressData,
    };

    return createContactAddressRequest(
      currentOrganization!,
      createContactAddressCommand,
    );
  },
);

addEffectStatusHandling(createContactAddressFx, {
  completeMessage: 'Contact address was created successfully',
  errorMessage: 'Contact address was not created',
});

export const getContactAddressFx = createEffect(
  (contactAddressParams: GetContactAddressParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContactAddressRequest(
      currentOrganization as LinkResourceBaseDto,
      contactAddressParams,
    );
  },
);

addEffectStatusHandling(getContactAddressFx);

export const updateContactAddressFx = createEffect(
  (contactAddress: ContactAddressDto) => {
    const updateContactAddressCommand: UpdateContactAddressCommandValues = {
      ...contactAddress,
    };
    return updateContactAddressRequest(
      contactAddress,
      updateContactAddressCommand,
    );
  },
);

addEffectStatusHandling(updateContactAddressFx, {
  completeMessage: 'Contact address was updated successfully',
  errorMessage: 'Contact address was not updated',
});

export const deleteContactAddressFx = createEffect(
  (contactAddress: ContactAddressDto) => {
    return deleteContactAddressRequest(contactAddress);
  },
);

addEffectStatusHandling(deleteContactAddressFx, {
  completeMessage: 'Contact address was deleted successfully',
  errorMessage: 'Contact address was not deleted',
});

export const exportContactAddressesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportContactAddressesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportContactAddressesFx, {
  completeMessage: 'Contact addresses were exported successfully',
  errorMessage: 'Contact addresses were not exported',
});

export const importContactAddressesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importContactAddressesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importContactAddressesFx, {
  completeMessage: 'Contact addresses were imported successfully',
  errorMessage: 'Contact addresses were not imported',
  inFlightMessage: 'Importing contact addresses...',
});

const defaultState: ContactAddressesStoreState = {
  links: [],
  contactAddressColumns: [],
  defaultContactAddressColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const contactAddressStore: Store<ContactAddressesStoreState> = createStore(
  defaultState,
)
  .on(getContactAddressesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      contactAddressColumns: payload.result.items,
      defaultContactAddressColumns: payload.result.items,
    };
  })
  .on(getContactAddressesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultContactAddressColumns: payload.result.items,
    };
  })
  .on(updateContactAddressesColumns, (state, payload) => {
    return { ...state, contactAddressColumns: payload };
  });
