import React, { useState, useEffect } from 'react';
import {
  ContactType,
  EntityTypes,
  CustomFieldDto,
  CommodityDto,
  CustomFieldEntityType,
  OrderDto,
  OrderTypes,
} from '../../../../models/data.models';
import { OrderEditFormDto } from '../../../../models/custom.models';
import { OrderDefaultValues } from '../../../common/DefaultValues';
import { getInitialStateOrderEntities } from '../../../airShipmentOrders/wizard/components/airConsolidationWizard.component';
import * as Yup from 'yup';
import { authStore } from '../../../auth/auth.store';
import { getCustomFieldsFx } from '../../../customFields/customFields.store';
import { getContacts } from '../../../contacts/contacts.store';
import { PatchOrderParams } from '../../../orders/orders.service';
import { patchOrder } from '../../../orders/orders.store';
import { createOrderDocumentsFx } from '../../../orderDocuments/orderDocuments.store';
import { CreateOrderDocumentsCommandValues } from '../../../../models/data.models';
import { getEnumKeyByValue } from '../../../../utils/helper.utils';
import { CommodityStatuses } from '../../../../models/data.models';
import { createOrder } from '../../../orders/orders.store';
import { getCommodity } from '../../../commodities/commodities.store';
import { addMessage } from '../../../common/messages.store';
import { updateCommodity } from '../../../commodities/commodities.store';
import { OrderForm } from '../../../orders/components/order.form';
import { FormContext } from '../../../common/components/form/form.component';
import { GeneralFragment } from './generalFragment.component';
import { EntitiesFragment } from './entitiesFragment.component';
import { RoutingFragment } from './routingFragment.component';
import { DeliveryFragment } from './deliveryFragment.component';
import { DocumentsFragment } from '../../../airShipmentOrders/wizard/components/documentsFragment.component';
import { CargoFragment } from '../../../airShipmentOrders/wizard/components/cargoFragment.component';
import { RepackFragment } from '../../../airShipmentOrders/wizard/components/repackFragment.component';
import { CompletingFragment } from './completingFragment.component';
import { v4 } from 'uuid';

export const getInitialState = () => {
  const initialState: OrderEditFormDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: null,
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntityCarriers: [
      getInitialStateOrderEntities(ContactType.Carrier, EntityTypes.Carrier),
    ],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    orderEntities: [],
    links: [],
    customValues: {},
    shipper: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Shipper,
    ),
    ultimateConsignee: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.UltimateConsignee,
    ),
    consignee: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Consignee,
    ),
    notifyParty: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.NotifyParty,
    ),
    intermediate: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Intermediate,
    ),
    forwardingAgent: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.ForwardingAgent,
    ),
    destinationAgent: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.DestinationAgent,
    ),
    deliveringCarrier: getInitialStateOrderEntities(
      ContactType.Carrier,
      EntityTypes.DeliveryCarrier,
    ),
    pickupFrom: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.PickupFrom,
    ),
    deliverTo: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.DeliverTo,
    ),
    receivedBy: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.ReceivedBy,
    ),
    orderDocuments: [],
  };
  return initialState;
};

// validation
let oceanOrderSchema = Yup.object().shape({
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

export type OceanConsolidatedWizardProps = {
  onOceanOrderSaved?: () => void;
  closeDialog?: () => void;
};

export const OceanConsolidatedWizard = ({
  onOceanOrderCreated,
  closeDialog,
}) => {
  const [step, setStep] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [systemCustomFields, setSystemCustomFields] = useState<
    CustomFieldDto[]
  >([]);
  const { user: currentUser } = authStore.getState();
  const [
    oceanOrderContextFormValue,
    setOceanOrderContextFormValue,
  ] = useState<OrderEditFormDto | null>(getInitialState());

  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    getInitialState(),
  );
  const [commodities, setCommodities] = useState<CommodityDto[] | null>([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [commodityIds, setCommodityIds] = useState([]);

  const [container, setContainer] = useState<CommodityDto>(null);
  const [needRepack, setNeedRepack] = useState(false);

  const handleNext = () => {
    if (step < 7) setStep(step + 1);
  };

  const handleBack = () => {
    if (step !== 0) setStep(step - 1);
  };

  const documentTemplateFilter =
    'documentTemplateType:BillOfLadingDocument OR documentTemplateType:ExportManifestDocument OR  documentTemplateType:MasterBillOfLadingDocument OR documentTemplateType:DockReceiptDocument OR documentTemplateType:ExportManifestExcelDocument';

  useEffect(() => {
    setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
      oceanOrderContextFormValueDto = getInitialState();
      return { ...oceanOrderContextFormValueDto };
    });
  }, []);

  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.OceanShipmentOrder} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        setCustomFields(
          customFields.filter((field) => field.isSystem === false),
        );
        setSystemCustomFields(
          customFields.filter((field) => field.isSystem === true),
        );
      },
      () => {},
    );

    getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
      (result) => {
        const defaultDispatcher = result.items.find(
          (employee) => employee.userEmployee?.userId == currentUser?.userId,
        );
        setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
          oceanOrderContextFormValueDto.employeeContactName =
            defaultDispatcher?.name;
          oceanOrderContextFormValueDto.employeeContactId =
            defaultDispatcher?.contactId;
          setInitialValues({ ...oceanOrderContextFormValueDto });
          return { ...oceanOrderContextFormValueDto };
        });
      },
      () => {},
    );

    setIsLoading(false);
  }, []);

  const linkCommodities = (createdOrder: OrderDto) => {
    const commodityParams = commodityIds.map((id) => {
      return {
        commodityId: id,
        orderId: createdOrder?.orderId,
      };
    });

    const patchOrderParams: PatchOrderParams = {
      resource: { links: createdOrder?.links },
      patchOrderCommand: {
        patchItems: [
          {
            path: `/OrderCommodities`,
            op: 'replace',
            value: commodityParams,
          },
        ],
      },
    };

    return patchOrder(patchOrderParams);
  };

  const createOrderDocumentsForOrder = (order: OrderDto) => {
    if (documentIds.length > 0) {
      const values = documentIds.map((id) => {
        const createValues: CreateOrderDocumentsCommandValues = {
          documentTemplateId: id,
          regenerateOnOrderChange: false,
          metadata: {
            orderId: order?.orderId.toString(),
            orderPickupId: order?.orderEntities
              .find((entity) => entity.entityType === EntityTypes.Shipper)
              ?.orderEntityId.toString(),
            orderDeliveryId: order?.orderEntities
              .find((entity) => entity.entityType === EntityTypes.Consignee)
              ?.orderEntityId.toString(),
            thirdPartyContactId: '',
            carrierId: '',
          },
        };
        return createValues;
      });

      return createOrderDocumentsFx({ order, values });
    }
    return Promise.resolve();
  };

  const onSubmit = async (data: OrderDto) => {
    data.commodities = commodities;
    data.commodities = data.commodities.map((item) => {
      return {
        ...item,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        weightTotal: item.pieces * item.weight,
        containerCommodityId: null,
      };
    });

    data.carriers = oceanOrderContextFormValue?.carriers;
    data.customValues = oceanOrderContextFormValue?.customValues;

    data.orderEntities = [];

    data.orderEntities.push(...oceanOrderContextFormValue?.orderEntityCarriers);
    data.orderEntities.push(oceanOrderContextFormValue?.shipper);
    data.orderEntities.push(oceanOrderContextFormValue?.ultimateConsignee);
    data.orderEntities.push(oceanOrderContextFormValue?.consignee);
    data.orderEntities.push(oceanOrderContextFormValue?.notifyParty);
    data.orderEntities.push(oceanOrderContextFormValue?.intermediate);
    data.orderEntities.push(oceanOrderContextFormValue?.forwardingAgent);
    data.orderEntities.push(oceanOrderContextFormValue?.destinationAgent);
    data.orderEntities.push(oceanOrderContextFormValue?.deliveringCarrier);
    data.orderEntities.push(oceanOrderContextFormValue?.pickupFrom);
    data.orderEntities.push(oceanOrderContextFormValue?.deliverTo);
    data.orderEntities.push(oceanOrderContextFormValue?.receivedBy);
    data.orderEntities = data.orderEntities.filter(
      (entity) => entity.contactId,
    );
    data.orderType = OrderTypes.OceanShipmentOrder;

    setIsSending(true);

    if (needRepack) {
      const containerCommodities = data.commodities;
      data.commodities = [container];
      try {
        const createdOrder = await createOrder(data);
        for (const containerCommodity of containerCommodities) {
          const commodity = await getCommodity({
            commodityId: containerCommodity.commodityId,
          });
          await updateCommodity({
            ...commodity,
            containerCommodityId: createdOrder?.commodities[0]?.commodityId,
          });
        }
        await createOrderDocumentsForOrder(createdOrder);
        onOceanOrderCreated(createdOrder.orderId);
        closeDialog();
      } catch (error) {
        addMessage({
          message: error.message,
          type: 'danger',
          id: v4(),
        });
      } finally {
        setIsSending(false);
      }
    } else {
      createOrder(data)
        .then((result) => {
          linkCommodities(result).then(() => {
            createOrderDocumentsForOrder(result).then(() => {
              onOceanOrderCreated(result.orderId);
              closeDialog();
            });
          });
        })
        .catch((error) => {
          addMessage({
            message: error.message,
            type: 'danger',
            id: v4(),
          });
        })
        .finally(() => setIsSending(false));
    }
  };

  const isBOLCreationDisabled = (): boolean => {
    return (
      !oceanOrderContextFormValue?.shipper?.contactId ||
      !oceanOrderContextFormValue?.consignee?.contactId
    );
  };

  return (
    <div>
      <OrderForm
        id={'oceanOrderForm'}
        initialValues={initialValues}
        validationSchema={oceanOrderSchema}
        onSubmit={onSubmit}
      >
        <FormContext.Consumer>
          {(context) => (
            <div>
              {step === 0 && (
                <GeneralFragment
                  handleNext={handleNext}
                  oceanOrderContextFormValue={oceanOrderContextFormValue}
                  setOceanOrderContextFormValue={setOceanOrderContextFormValue}
                  context={context}
                  customFields={systemCustomFields}
                />
              )}
              {step === 1 && (
                <EntitiesFragment
                  oceanOrderContextFormValue={oceanOrderContextFormValue}
                  setOceanOrderContextFormValue={setOceanOrderContextFormValue}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {step === 2 && (
                <RoutingFragment
                  oceanOrderContextFormValue={oceanOrderContextFormValue}
                  setOceanOrderContextFormValue={setOceanOrderContextFormValue}
                  customFields={systemCustomFields}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {step === 3 && (
                <DeliveryFragment
                  oceanOrderContextFormValue={oceanOrderContextFormValue}
                  setOceanOrderContextFormValue={setOceanOrderContextFormValue}
                  customFields={systemCustomFields}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {step === 4 && (
                <DocumentsFragment
                  documentIds={documentIds}
                  setDocumentIds={setDocumentIds}
                  documentTemplateFilter={documentTemplateFilter}
                  isBolCreationDisabled={isBOLCreationDisabled}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {step === 5 && (
                <CargoFragment
                  commodities={commodities}
                  setCommodities={setCommodities}
                  commodityIds={commodityIds}
                  setCommodityIds={setCommodityIds}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {step === 6 && (
                <RepackFragment
                  needRepack={needRepack}
                  setNeedRepack={setNeedRepack}
                  commodityIds={commodityIds}
                  setCommodityIds={setCommodityIds}
                  container={container}
                  setContainer={setContainer}
                  isSending={isSending}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {step === 7 && (
                <CompletingFragment
                  oceanOrderContextFormValue={oceanOrderContextFormValue}
                  commodityIds={commodityIds}
                  documentsIds={documentIds}
                  handleBack={handleBack}
                />
              )}
            </div>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
