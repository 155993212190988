import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { WorkflowTriggerScreen } from './screens/workflowTrigger.screen';

export const EXECUTE_WORKFLOWTRIGGER_LINK_KEY = '/workflowTriggers/:workflowId';

export const WorkflowTriggersRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <WorkflowTriggerScreen pageTitle="Execute Workflow" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
