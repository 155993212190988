import React, { useEffect, useState } from 'react';
import { ContactDto } from '../../../models/data.models';
import { ReactSelect } from '../../common/components/input/input.select';
import { Button } from '../../common/components/button/button.component';

export type SelectContactProps = {
  className?: string;
  contacts?: ContactDto[];
  header?: string;
  placeholder?: string;
  multiple?: boolean;
  required?: boolean;
  onContactsSelected?: (contacts: ContactDto[]) => void;
  onCancel?: () => void;
};

export const SelectContactComponent = ({
  className,
  contacts,
  onContactsSelected,
  onCancel,
  header,
  placeholder,
  multiple,
  required = false,
}: SelectContactProps) => {
  useEffect(() => {
    if (contacts?.length === 1 && required) {
      onContactsSelected(contacts);
    }
  }, []);
  const [selectedContacts, setSelectedContacts] = useState<ContactDto[]>([]);
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      <div className="mb-3">
        <ReactSelect
          useContext={false}
          name={'contactId'}
          id={'contactId'}
          header={header}
          placeholder={placeholder}
          required={required}
          defaultValue={selectedContacts}
          multiple={multiple}
          disabled={false}
          onChange={(values) =>
            multiple
              ? setSelectedContacts(values)
              : setSelectedContacts([values])
          }
          options={contacts}
          getOptionValue={(option) => option}
          getOptionLabel={(option) => option.name}
        />
        {submitted && required && selectedContacts?.length == 0 ? (
          <div className="invalid-feedback">Can't be blank</div>
        ) : (
          <div />
        )}
      </div>
      <Button
        onClick={() => {
          setSubmitted(true);
          if (!required || selectedContacts.length > 0) {
            onContactsSelected(selectedContacts);
          }
        }}
        type="button"
        color="primary"
        className="btn-block ml-auto"
        name={'select'}
      >
        Select
      </Button>
      <Button
        onClick={onCancel}
        type="button"
        color="secondary"
        className="btn-block ml-auto"
      >
        Cancel
      </Button>
    </>
  );
};
