import React, { CSSProperties, useEffect, useState } from 'react';
import { Field } from 'formik';

export type CheckBoxProps = {
  label: string;
  name: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  required?: boolean;
  defaultChecked?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (value: boolean) => void;
};

export const Checkbox = ({
  label,
  name,
  id = null,
  disabled = false,
  defaultChecked = false,
  size = 'md',
  className = '',
  style = {},
  onClick = () => {},
  onChange = () => {},
  required,
}: CheckBoxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);
  const fieldId = id ? id : name;

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className={`form-group ${className}`} style={style}>
      <div className="custom-control custom-checkbox" onClick={onClick}>
        <Field
          type="checkbox"
          disabled={disabled}
          id={fieldId}
          name={name}
          required={required}
          checked={isChecked}
        >
          {({ field }: any) => {
            return (
              <input
                {...field}
                required={required}
                id={fieldId}
                name={name}
                disabled={disabled}
                checked={isChecked}
                className="custom-control-input"
                type="checkbox"
                onChange={(event) => {
                  handleCheckboxChange();
                  field.onChange(event);
                }}
              />
            );
          }}
        </Field>
        <label
          className={`custom-control-label font-size-${size} text-muted`}
          htmlFor={fieldId}
        >
          {label}
        </label>
      </div>
    </div>
  );
};
