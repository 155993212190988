import {
  EquipmentTypeDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  EquipmentTypeDtoPagedResult,
  CreateEquipmentTypeCommandValues,
  UpdateEquipmentTypeCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_EQUIPMENTTYPES_LINK_KEY = 'get-equipmenttypes';
export const CREATE_EQUIPMENTTYPE_LINK_KEY = 'create-equipmenttype';
export const GET_EQUIPMENTTYPE_LINK_KEY = 'get-equipmenttype';
export const UPDATE_EQUIPMENTTYPE_LINK_KEY = 'update-equipmenttype';
export const DELETE_EQUIPMENTTYPE_LINK_KEY = 'delete-equipmenttype';
export const IMPORT_EQUIPMENTTYPES_LINK_KEY = 'import-equipmenttypes';
export const EXPORT_EQUIPMENTTYPES_LINK_KEY = 'export-equipmenttypes';
export const EQUIPMENTTYPE_ENTITY_NAME = 'Equipment Type';

export interface GetEquipmentTypeParams {
  equipmentTypeId: number;
}

export interface ImportEquipmentTypesParams {
  organizationId: number;
}

export interface ExportEquipmentTypesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getEquipmentTypeRequest = async (
  resource: LinkResourceBaseDto | null,
  { equipmentTypeId }: GetEquipmentTypeParams,
): Promise<EquipmentTypeDto> => {
  if (resource && equipmentTypeId) {
    const getEquipmentTypeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_EQUIPMENTTYPE_LINK_KEY,
    );
    if (getEquipmentTypeLink) {
      const result = await execLink(getEquipmentTypeLink, { equipmentTypeId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getEquipmentTypesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<EquipmentTypeDtoPagedResult> => {
  if (resource) {
    const getEquipmentTypesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_EQUIPMENTTYPES_LINK_KEY,
    );
    if (getEquipmentTypesListLink) {
      const result = await execLink(getEquipmentTypesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_EQUIPMENTTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createEquipmentTypeRequest = async (
  resource: LinkResourceBaseDto,
  equipmentType: CreateEquipmentTypeCommandValues,
) => {
  const createEquipmentTypeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_EQUIPMENTTYPE_LINK_KEY,
  );
  if (createEquipmentTypeLink) {
    const result = await execLink(createEquipmentTypeLink, equipmentType);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateEquipmentTypeRequest = async (
  resource: LinkResourceBaseDto,
  equipmentType: UpdateEquipmentTypeCommandValues,
) => {
  const updateEquipmentTypeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_EQUIPMENTTYPE_LINK_KEY,
  );
  if (updateEquipmentTypeLink) {
    const result = await execLink(updateEquipmentTypeLink, equipmentType);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteEquipmentTypeRequest = async (
  equipmentType: EquipmentTypeDto,
) => {
  const deleteEquipmentTypeLink = equipmentType.links?.find(
    (x: LinkDto) => x.rel === DELETE_EQUIPMENTTYPE_LINK_KEY,
  );
  if (deleteEquipmentTypeLink) {
    const result = await execLink(deleteEquipmentTypeLink, equipmentType);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importEquipmentTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportEquipmentTypesParams,
  file: File,
) => {
  const importEquipmentTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_EQUIPMENTTYPES_LINK_KEY,
  );
  if (importEquipmentTypesLink) {
    const result = await postFormData(importEquipmentTypesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_EQUIPMENTTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportEquipmentTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportEquipmentTypesParams,
) => {
  const exportEquipmentTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_EQUIPMENTTYPES_LINK_KEY,
  );
  if (exportEquipmentTypesLink) {
    const result = await downloadLinkFile(exportEquipmentTypesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_EQUIPMENTTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};
