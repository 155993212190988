import {
  AppModuleDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  AppModuleListItemDtoPagedResult,
  CreateAppModuleCommandValues,
  UpdateAppModuleCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAppModuleRequest,
  getAppModulesListRequest,
  getAppModuleRequest,
  deleteAppModuleRequest,
  updateAppModuleRequest,
  importAppModulesRequest,
  exportAppModulesRequest,
  GetAppModuleParams,
} from './appModules.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type AppModulesStoreState = {
  links: LinkDto[];
  appModuleColumns: EntityFieldDto[];
  defaultAppModuleColumns: EntityFieldDto[];
};

export const getAppModulesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AppModule',
  });
});

addEffectStatusHandling(getAppModulesColumnsFx);

export const getAppModulesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AppModule',
  });
});

addEffectStatusHandling(getAppModulesDefaultColumnsFx);

export const updateAppModulesColumns = createEvent<EntityFieldDto[]>();

export const getAppModulesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getAppModulesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getAppModulesFx);

export const createAppModuleFx = createEffect((appModuleData: AppModuleDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createAppModuleCommand: CreateAppModuleCommandValues = {
    ...appModuleData,
  };

  return createAppModuleRequest(currentOrganization!, createAppModuleCommand);
});

addEffectStatusHandling(createAppModuleFx, {
  completeMessage: 'App module was created successfully',
  errorMessage: 'App module was not created',
});

export const getAppModuleFx = createEffect(
  (appModuleParams: GetAppModuleParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAppModuleRequest(
      currentOrganization as LinkResourceBaseDto,
      appModuleParams,
    );
  },
);

addEffectStatusHandling(getAppModuleFx);

export const updateAppModuleFx = createEffect((appModule: AppModuleDto) => {
  const updateAppModuleCommand: UpdateAppModuleCommandValues = { ...appModule };
  return updateAppModuleRequest(appModule, updateAppModuleCommand);
});

addEffectStatusHandling(updateAppModuleFx, {
  completeMessage: 'App module was updated successfully',
  errorMessage: 'App module was not updated',
});

export const deleteAppModuleFx = createEffect((appModule: AppModuleDto) => {
  return deleteAppModuleRequest(appModule);
});

addEffectStatusHandling(deleteAppModuleFx, {
  completeMessage: 'App module was deleted successfully',
  errorMessage: 'App module was not deleted',
});

export const exportAppModulesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportAppModulesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportAppModulesFx, {
  completeMessage: 'App modules were exported successfully',
  errorMessage: 'App modules were not exported',
});

export const importAppModulesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importAppModulesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importAppModulesFx, {
  completeMessage: 'App modules were imported successfully',
  errorMessage: 'App modules were not imported',
  inFlightMessage: 'Importing app modules...',
});

const defaultState: AppModulesStoreState = {
  links: [],
  appModuleColumns: [],
  defaultAppModuleColumns: [],
};

export const appModuleStore: Store<AppModulesStoreState> = createStore(
  defaultState,
)
  .on(getAppModulesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      appModuleColumns: payload.result.items,
      defaultAppModuleColumns: payload.result.items,
    };
  })
  .on(getAppModulesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultAppModuleColumns: payload.result.items,
    };
  })
  .on(updateAppModulesColumns, (state, payload) => {
    return { ...state, appModuleColumns: payload };
  });
