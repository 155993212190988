import { UiContextProvider } from '../../../context/uiContextProvider.provider';
import { AppComponent } from '../../../screens/appComponent.component';
import { DebugView } from '../../../screens/appComponentScreen.screen';
import { Dialog } from '../../dialog/dialog.component';
import { parseTemplate } from '../component-hooks';

export type DialogComponentProps = {
  title: string;
  dialog: any;
};

export class DialogComponent extends Dialog<DialogComponentProps> {
  dialogProps: DialogComponentProps;
  component: { component: string };
  variables: {};

  actions: {
    close: (actionProps: any, data: any, latestStoreValues: any) => void;
  };

  constructor(props: DialogComponentProps) {
    super(props);
    this.dialogProps = props;
    this.variables = {
      //  isDevelopment: true, // TODO: remove this
      isDialog: true, // this one is for the correct tabs rendering in dialog
      ...props.dialog.props,
    };
    this.actions = {
      close: (actionProps, data, latestStoreValues) => {
        const result = parseTemplate(actionProps.close?.result, {
          ...latestStoreValues,
          ...data,
        });
        this.close(result);
      },
    };
  }

  // set params to state
  setParams = (newParams: any, options) => {
    this.setState({ ...this.state, ...newParams });
  };

  renderContent(): any {
    return (
      <UiContextProvider
        setParams={this.setParams}
        additionalActions={this.actions}
      >
        {(props) => (
          <>
            <AppComponent
              component={this.dialogProps.dialog.component}
              variables={this.variables}
            />
            <DebugView variables={this.variables} />
          </>
        )}
      </UiContextProvider>
    );
  }
}
