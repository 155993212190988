import React from 'react';
import { useStore } from 'effector-react';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, Link } from 'react-router-dom';
import { ActionItem } from '../../common/components/action-item/action-item.component';
import { INTERNAL_PATH } from '../../../app.router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { CLAUSES_LIST_PATH } from '../clauses.route';
import { GET_CLAUSES_LINK_KEY } from '../clauses.service';
import { navigationStore } from '../../common/nav.store';

export const ClauseMenu = ({ className }) => {
  const { currentOrganization } = useStore(organizationsStore);
  const navStore = useStore(navigationStore);

  return (
    <ActionItem
      context={currentOrganization}
      actionKey={GET_CLAUSES_LINK_KEY}
      render={({ available }) => {
        if (available) {
          return (
            <li className={className}>
              <Link
                to={generatePath(INTERNAL_PATH + CLAUSES_LIST_PATH, {
                  organizationId: currentOrganization?.organizationId,
                })}
                className={`nav-link nav-icon-size-medium ${
                  navStore.path[navStore.path?.length - 1] === 'Clauses'
                    ? 'active'
                    : ''
                }`}
                data-testid="link-clauses"
              >
                <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faStickyNote} />
                </div>
                <div className="nav-link-text">Clauses</div>
              </Link>
            </li>
          );
        }
        return null;
      }}
    />
  );
};
