import React, { useEffect, useState } from 'react';
import {
  CommodityDto,
  PickingActivityStatuses,
  WarehouseZoneDto,
  ScanningResultFrom,
} from '../../../models/data.models';
import { PackageLocation } from '../../common/components/location/packageLocation.component';
import { CargoMovementForm } from '../components/cargoMovement.form';
import { Button } from '../../common/components/button/button.component';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { createActionEventFx } from '../../actionEvents/actionEvents.store';

export type PickingActivityProps = {
  commodity: CommodityDto;
  warehouseZones: WarehouseZoneDto[];
  commodityStatus: string;
  setPickingStatus: (status: PickingActivityStatuses) => void;
  isLoading: boolean;
  parcelTrackingNumber?: string;
};

export const PickingActivity = ({
  commodity,
  warehouseZones,
  commodityStatus,
  setPickingStatus,
  isLoading,
  parcelTrackingNumber = '',
}: PickingActivityProps) => {
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [enteredNumber, setEnteredNumber] = useState('');
  const [warning, setWarning] = useState('');
  const [areNumbersEqual, setAreNumbersEqual] = useState<boolean>(false);

  useEffect(() => {
    if (commodity) {
      setTrackingNumbers(
        commodity?.commodityTrackingNumbers?.map((x) => x.trackingNumber),
      );
    }
  }, [commodity]);

  useEffect(() => {
    const checkNumber = async () => {
      if (
        trackingNumbers?.some((trackingNumber: string) => {
          const comparison = trackingNumber?.localeCompare(enteredNumber);
          return comparison === 0;
        })
      ) {
        setAreNumbersEqual(true);
        await createActionEventFx({
          eventName: 'package.picked',
          eventData: {
            commodities: [commodity],
          },
        });
        setWarning('');
        if (commodityStatus !== PickingActivityStatuses.Picked) {
          setPickingStatus(PickingActivityStatuses.Picked);
          setEnteredNumber('');
        }
      } else {
        if (enteredNumber) {
          setWarning('Wrong tracking number');
        }
        setAreNumbersEqual(false);
      }
    };
    checkNumber();
  }, [enteredNumber]);

  const onStatusButtonClick = (newStatus: PickingActivityStatuses) => {
    if (newStatus !== commodityStatus) {
      setPickingStatus(newStatus);
      setEnteredNumber('');
    }
  };

  useScanner((scannerResult: ScanningResult) => {
    catchScanResult(scannerResult);
  });

  const catchScanResult = (scanningResult: ScanningResult) => {
    if (scanningResult.from === ScanningResultFrom.Scanner) {
      if (scanningResult.data) {
        setEnteredNumber(scanningResult.data);
      }
    }
  };

  return (
    <div className={'h-100'}>
      <div className="box h-100">
        <PackageLocation
          commodity={commodity}
          warehouseZones={warehouseZones}
          commodityStatus={commodityStatus}
          showHeader={true}
          emphasisedPath={true}
          pathTextSize={'md'}
          parcelTrackingNumber={parcelTrackingNumber}
        />
        <div className="mt-4">
          <div className={'text-h3 mb-3'}>
            Scan barcode or Enter Tracking number manually
          </div>
        </div>
        <div>
          <CargoMovementForm>
            <CargoMovementForm.EnteredNumber
              onChange={(value) => {
                setEnteredNumber(value);
              }}
              defaultValue={enteredNumber}
              barcode={true}
            />
          </CargoMovementForm>
          {warning && <span className="text-danger">{warning}</span>}
          <div className="w-100 d-flex flex-sm-nowrap flex-wrap justify-content-between">
            <div className={'w-100'}>
              <Button
                className={'w-100'}
                color={'primary'}
                outline={false}
                onClick={() =>
                  onStatusButtonClick(PickingActivityStatuses.Picked)
                }
                disabled={
                  commodityStatus === PickingActivityStatuses.Picked ||
                  !areNumbersEqual ||
                  isLoading
                }
              >
                Picked
              </Button>
            </div>
            <div className={'mx-sm-3 mx-0 my-sm-0 my-2 w-100'}>
              <Button
                className={'w-100'}
                color={'action-secondary'}
                outline={false}
                onClick={() =>
                  onStatusButtonClick(PickingActivityStatuses.NotFound)
                }
                disabled={
                  commodityStatus === PickingActivityStatuses.NotFound ||
                  isLoading
                }
              >
                Not Found
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
