import React from 'react';
import { BusinessRuleForm } from '../businessRule.form';
import { Trigger, TriggerEvents } from '../../../../models/data.models';
import { AiOutlineMinusCircle } from 'react-icons/ai';

type ConditionFormProps = {
  setCondition: (value: any) => void;
  setTriggerEvent: (value: any) => void;
  setTrigger: (value: any) => void;
  onDeleteCondition: () => void;
  allowDeleteCondition: boolean;
  index: number;
};

export const ConditionForm = ({
  setCondition,
  setTriggerEvent,
  setTrigger,
  onDeleteCondition,
  allowDeleteCondition,
  index,
}: ConditionFormProps) => {
  return (
    <>
      <div className="d-flex">
        <h3 className={'font-weight-normal mt-2'}>Condition {index + 1}</h3>
        {allowDeleteCondition && (
          <AiOutlineMinusCircle
            className="i-top-bar ml-2 mt-1 h-100 align-middle font-size-sm i-top-bar-blue"
            data-testid="btn-create-new-carrier"
            onClick={onDeleteCondition}
          />
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <BusinessRuleForm.Condition
            name={`conditions[${index}].condition`}
            id={`conditions[${index}].condition`}
            onChange={(event) => setCondition(event?.target?.value)}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-6">
          <BusinessRuleForm.TriggerEvent
            name={`conditions[${index}].triggerEvent`}
            id={`conditions[${index}].triggerEvent`}
            onChange={(value) => setTriggerEvent(value)}
            enumName={TriggerEvents}
          />
        </div>
        <div className="col-6">
          <BusinessRuleForm.Trigger
            enumName={Trigger}
            name={`conditions[${index}].trigger`}
            id={`conditions[${index}].trigger`}
            onChange={(value) => setTrigger(value)}
            valueInput={'before'}
          />
        </div>
      </div>
    </>
  );
};
