import { Dialog } from '../../common/components/dialog/dialog.component';
import { ContactEdit } from './contact-edit.component';
import { ContactDto } from '../../../models/data.models';

export type ContactDialogProperties = {
  contactId?: number | null;
  title: string;
  isTab?: boolean;
  parentId?: number;
};

export class ContactDialog extends Dialog<ContactDialogProperties> {
  constructor(props: ContactDialogProperties) {
    super(props);
    this.onContactSaved = this.onContactSaved.bind(this);
  }

  onContactSaved = (contact: ContactDto) => {
    this.close(contact);
  };

  renderContent(): any {
    const { contactId, isTab, parentId } = this
      .props as ContactDialogProperties;
    return (
      <ContactEdit
        contactId={contactId}
        onContactCreated={this.onContactSaved}
        onContactUpdated={this.onContactSaved}
        onCancel={this.close.bind(this)}
        isTab={isTab}
        parentId={parentId}
      />
    );
  }
}
