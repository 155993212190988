import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto, PackageCategoryDto } from '../../../models/data.models';
import { ReceiveCarrierPay } from './receiveCarrierPay.component';

export type ReceiveCarrierPayDialogProperties = {
  balanceOwning?: number;
  title: string;
  className?: string;
  orderCarrierId: number;
  transactionNumber: string;
  onCarrierPayReceived: () => void;
};

export class ReceiveCarrierPayDialog extends Dialog<ReceiveCarrierPayDialogProperties> {
  onCarrierPayReceived: () => void;

  constructor(props: ReceiveCarrierPayDialogProperties) {
    super(props);
    this.onCarrierPayReceived = props.onCarrierPayReceived.bind(this);
  }

  // onCarrierPayReceived = (order: any) => {
  //   this.close(order);
  // };

  renderContent(): any {
    const { balanceOwning, orderCarrierId, transactionNumber } = this
      .props as ReceiveCarrierPayDialogProperties;
    return (
      <ReceiveCarrierPay
        balanceOwning={balanceOwning}
        orderCarrierId={orderCarrierId}
        onCarrierPayReceived={() => {
          this.onCarrierPayReceived();
          this.close();
        }}
        transactionNumber={transactionNumber}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
