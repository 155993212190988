import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  OrderStatusDto,
  OrderStatusDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  orderStatusStore,
  getOrderStatusesColumnsFx,
  getOrderStatusesFx,
  deleteOrderStatusFx,
  exportOrderStatusesFx,
  importOrderStatusesFx,
} from '../orderStatuses.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_ORDERSTATUS_LINK_KEY,
  DELETE_ORDERSTATUS_LINK_KEY,
  IMPORT_ORDERSTATUSES_LINK_KEY,
  EXPORT_ORDERSTATUSES_LINK_KEY,
  GetOrderStatusParams,
} from '../orderStatuses.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { OrderStatusDialog } from './orderStatus.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type OrderStatusesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (orderStatusParams: GetOrderStatusParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    orderStatus: OrderStatusDto,
    orderStatusParams: GetOrderStatusParams,
  ) => void;
};

export const OrderStatusesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: OrderStatusesListProps) => {
  const [
    orderStatuses,
    setOrderStatuses,
  ] = useState<OrderStatusDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    orderStatusColumns: columns,
    defaultOrderStatusColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(orderStatusStore);

  useEffect(() => {
    getOrderStatusesColumnsFx();
  }, []);

  const getOrderStatuses = useCallback(async () => {
    const orderStatusesData = await getOrderStatusesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(orderStatusesData));
    setOrderStatuses(orderStatusesData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: OrderStatusDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_ORDERSTATUSES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importOrderStatusesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getOrderStatuses();
          },
          result?.links,
        ),
        getAction(
          EXPORT_ORDERSTATUSES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportOrderStatusesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getOrderStatuses],
  );

  useEffect(() => {
    getOrderStatuses();
  }, [getOrderStatuses]);

  const onDeleteOrderStatus = useCallback(
    async (orderStatus: OrderStatusDto) => {
      if (
        userHas(DELETE_ORDERSTATUS_LINK_KEY, orderStatuses?.items[0]?.links)
      ) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Order status`,
            message: 'Are you sure you want to delete order status?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteOrderStatusFx(orderStatus);
            getOrderStatuses();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, orderStatuses],
  );

  const onEditOrderStatus = (orderStatus: OrderStatusDto) => {
    if (orderStatus) goToDetails({ orderStatusId: orderStatus.orderStatusId });
  };

  const onOrderStatusSelect = useCallback(
    async (orderStatus) => {
      const selectedOrderStatus = await showDialog({
        dialog: OrderStatusDialog,
        props: {
          orderStatusId: orderStatus.orderStatusId,
          title: 'Update Order status',
        },
      });
      if (selectedOrderStatus) {
        getOrderStatuses();
      }
    },
    [getOrderStatuses],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['orderStatusId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={orderStatuses.items}
          onLimitChanged={onLimitChanged}
          total={orderStatuses.totalCount}
          onDelete={onDeleteOrderStatus}
          onSort={onSort}
          onEdit={onEditOrderStatus}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onOrderStatusSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {orderStatuses?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Order status"
            entityLinkKey={CREATE_ORDERSTATUS_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
