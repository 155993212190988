import {
  EventDefinitionDto,
  LinkDto,
  LinkResourceBaseDto,
  EventDefinitionDtoPagedResult,
  CreateEventDefinitionCommandValues,
  UpdateEventDefinitionCommandValues,
  FileType,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_EVENTDEFINITIONS_LINK_KEY = 'get-eventdefinitions';
export const CREATE_EVENTDEFINITION_LINK_KEY = 'create-eventdefinition';
export const GET_EVENTDEFINITION_LINK_KEY = 'get-eventdefinition';
export const UPDATE_EVENTDEFINITION_LINK_KEY = 'update-eventdefinition';
export const DELETE_EVENTDEFINITION_LINK_KEY = 'delete-eventdefinition';
export const IMPORT_EVENTDEFINITIONS_LINK_KEY = 'import-eventdefinitions';
export const EXPORT_EVENTDEFINITIONS_LINK_KEY = 'export-eventdefinitions';

export const EVENTDEFINITION_ENTITY_NAME = 'Event Definition';

export interface GetEventDefinitionParams {
  eventDefinitionId: number;
}

export interface ImportEventDefinitionsParams {
  organizationId: number;
}

export interface ExportEventDefinitionsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getEventDefinitionRequest = async (
  resource: LinkResourceBaseDto | null,
  { eventDefinitionId }: GetEventDefinitionParams,
): Promise<EventDefinitionDto> => {
  if (resource && eventDefinitionId) {
    const getEventDefinitionLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_EVENTDEFINITION_LINK_KEY,
    );
    if (getEventDefinitionLink) {
      const result = await execLink(getEventDefinitionLink, {
        eventDefinitionId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getEventDefinitionsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<EventDefinitionDtoPagedResult> => {
  if (resource) {
    const getEventDefinitionsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_EVENTDEFINITIONS_LINK_KEY,
    );
    if (getEventDefinitionsListLink) {
      const result = await execLink(getEventDefinitionsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_EVENTDEFINITIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createEventDefinitionRequest = async (
  resource: LinkResourceBaseDto,
  eventDefinition: CreateEventDefinitionCommandValues,
) => {
  const createEventDefinitionLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_EVENTDEFINITION_LINK_KEY,
  );
  if (createEventDefinitionLink) {
    const result = await execLink(createEventDefinitionLink, eventDefinition);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateEventDefinitionRequest = async (
  resource: LinkResourceBaseDto,
  eventDefinition: UpdateEventDefinitionCommandValues,
) => {
  const updateEventDefinitionLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_EVENTDEFINITION_LINK_KEY,
  );
  if (updateEventDefinitionLink) {
    const result = await execLink(updateEventDefinitionLink, eventDefinition);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteEventDefinitionRequest = async (
  eventDefinition: EventDefinitionDto,
) => {
  const deleteEventDefinitionLink = eventDefinition.links?.find(
    (x: LinkDto) => x.rel === DELETE_EVENTDEFINITION_LINK_KEY,
  );
  if (deleteEventDefinitionLink) {
    const result = await execLink(deleteEventDefinitionLink, eventDefinition);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importEventDefinitionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportEventDefinitionsParams,
  file: File,
) => {
  const importEventDefinitionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_EVENTDEFINITIONS_LINK_KEY,
  );
  if (importEventDefinitionsLink) {
    const result = await postFormData(importEventDefinitionsLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_EVENTDEFINITIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportEventDefinitionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportEventDefinitionsParams,
) => {
  const exportEventDefinitionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_EVENTDEFINITIONS_LINK_KEY,
  );
  if (exportEventDefinitionsLink) {
    const result = await downloadLinkFile(exportEventDefinitionsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_EVENTDEFINITIONS_LINK_KEY} is not allowed or missing link action`,
  );
};
