import { OrderEntitiesForm } from '../../components/form-items/orderEntitiesForm.component';
import { OrderDto } from '../../../../models/data.models';
import { Button } from '../../../common/components/button/button.component';

type EntitiesFragmentProps = {
  oceanOrderContextFormValue: OrderDto;
  setOceanOrderContextFormValue: (order: any) => void;
  handleNext: () => void;
  handleBack: () => void;
};

export const EntitiesFragment = ({
  oceanOrderContextFormValue,
  setOceanOrderContextFormValue,
  handleNext,
  handleBack,
}: EntitiesFragmentProps) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-3">
          <h2>Select the entities</h2>
        </div>
      </div>
      <OrderEntitiesForm
        oceanOrderContextFormValue={oceanOrderContextFormValue}
        setOceanOrderContextFormValue={setOceanOrderContextFormValue}
      />
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button className="mr-2" color="primary" onClick={handleNext}>
          Next
        </Button>
        <Button className="mr-3" onClick={handleBack}>
          Back
        </Button>
      </div>
    </div>
  );
};
