import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { WorkflowsListScreen } from './screens/workflows-list.screen';
import { WorkflowScreen } from './screens/workflow.screen';

export const WORKFLOWS_LIST_PATH = '/workflows';
export const WORKFLOW_EDIT_PATH = '/workflows/:workflowId';
export const WORKFLOW_CREATE_PATH = '/workflows/create';

export const WorkflowsRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <WorkflowsListScreen pageTitle="Workflows" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <WorkflowScreen pageTitle="Add New Workflow" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':workflowId'}
        element={
          <PrivateRoute>
            <WorkflowScreen pageTitle="Update Workflow" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
