import {
  AccountingTransactionDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  AccountingTransactionDtoPagedResult,
  CreateAccountingTransactionCommandValues,
  UpdateAccountingTransactionCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_ACCOUNTINGTRANSACTIONS_LINK_KEY = 'get-accountingtransactions';
export const CREATE_ACCOUNTINGTRANSACTION_LINK_KEY =
  'create-accountingtransaction';
export const GET_ACCOUNTINGTRANSACTION_LINK_KEY = 'get-accountingtransaction';
export const UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY =
  'update-accountingtransaction';
export const DELETE_ACCOUNTINGTRANSACTION_LINK_KEY =
  'delete-accountingtransaction';
export const IMPORT_ACCOUNTINGTRANSACTIONS_LINK_KEY =
  'import-accountingtransactions';
export const EXPORT_ACCOUNTINGTRANSACTIONS_LINK_KEY =
  'export-accountingtransactions';
export const ACCOUNTINGTRANSACTION_ENTITY_NAME = 'Accounting Transaction';

export interface GetAccountingTransactionParams {
  accountingTransactionId: number;
}

export interface ImportAccountingTransactionsParams {
  organizationId: number;
}

export interface ExportAccountingTransactionsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getAccountingTransactionRequest = async (
  resource: LinkResourceBaseDto | null,
  { accountingTransactionId }: GetAccountingTransactionParams,
): Promise<AccountingTransactionDto> => {
  if (resource && accountingTransactionId) {
    const getAccountingTransactionLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGTRANSACTION_LINK_KEY,
    );
    if (getAccountingTransactionLink) {
      const result = await execLink(getAccountingTransactionLink, {
        accountingTransactionId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getAccountingTransactionsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<AccountingTransactionDtoPagedResult> => {
  if (resource) {
    const getAccountingTransactionsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGTRANSACTIONS_LINK_KEY,
    );
    if (getAccountingTransactionsListLink) {
      const result = await execLink(getAccountingTransactionsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACCOUNTINGTRANSACTIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createAccountingTransactionRequest = async (
  resource: LinkResourceBaseDto,
  accountingTransaction: CreateAccountingTransactionCommandValues,
) => {
  const createAccountingTransactionLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  );
  if (createAccountingTransactionLink) {
    const result = await execLink(
      createAccountingTransactionLink,
      accountingTransaction,
    );
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateAccountingTransactionRequest = async (
  resource: LinkResourceBaseDto,
  accountingTransaction: UpdateAccountingTransactionCommandValues,
) => {
  const updateAccountingTransactionLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  );
  if (updateAccountingTransactionLink) {
    const result = await execLink(
      updateAccountingTransactionLink,
      accountingTransaction,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteAccountingTransactionRequest = async (
  accountingTransaction: AccountingTransactionDto,
) => {
  const deleteAccountingTransactionLink = accountingTransaction.links?.find(
    (x: LinkDto) => x.rel === DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
  );
  if (deleteAccountingTransactionLink) {
    const result = await execLink(
      deleteAccountingTransactionLink,
      accountingTransaction,
    );
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importAccountingTransactionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportAccountingTransactionsParams,
  file: File,
) => {
  const importAccountingTransactionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_ACCOUNTINGTRANSACTIONS_LINK_KEY,
  );
  if (importAccountingTransactionsLink) {
    const result = await postFormData(
      importAccountingTransactionsLink,
      params,
      [file],
    );
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_ACCOUNTINGTRANSACTIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportAccountingTransactionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportAccountingTransactionsParams,
) => {
  const exportAccountingTransactionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_ACCOUNTINGTRANSACTIONS_LINK_KEY,
  );
  if (exportAccountingTransactionsLink) {
    const result = await downloadLinkFile(
      exportAccountingTransactionsLink,
      params,
    );
    return result;
  }
  throw new Error(
    `Action ${EXPORT_ACCOUNTINGTRANSACTIONS_LINK_KEY} is not allowed or missing link action`,
  );
};
