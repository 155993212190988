import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { getOrders, deleteOrder } from '../../orders/orders.store';
import {
  getOrdersColumns,
  oceanShipmentOrdersStore,
} from '../oceanShipmentOrders.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ORDER_LINK_KEY,
  DELETE_ORDER_LINK_KEY,
} from '../../orders/orders.service';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { OCEAN_ORDER_EDIT_PATH } from '../oceanShipmentOrders.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { userHas } from '../../auth/auth.store';
import { OceanShipmentOrderGrid } from '../../common/components/grid/oceanShipmentOrder-grid.component';
import { Message, addMessage } from '../../common/messages.store';
import { OceanConsolidatedWizardDialog } from '../wizard/components/oceanConsolidatedWizard.dialog';
import { hidenFilter } from '../../../utils/query.utils';
import { ConsolidatedShipmentDialog } from '../../consolidatedShipments/components/consolidatedShipment.dialog';
import { setActionButtons, clearActionButtons } from '../../common/nav.store';
import { ConsolidatedShipmentCreateDialog } from '../../consolidatedShipments/components/consolidatedShipmentCreate.dialog';

export type OceanShipmentOrdersListProps = {
  goToDetails?: (orderId: number, orderType?: OrderTypes) => void;
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (purchase: OrderDto, purchaseId: string) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const onOpenOceanShipmentWizard = (
  organizationId: number,
  navigate: any,
) => {
  const onOceanOrderCreated = (orderId: number) => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_EDIT_PATH, {
      organizationId,
      orderId,
    });

    const message: Message = {
      id: 'air-order-created',
      type: 'success',
      autoHide: true,
      message: 'Ocean Shipment successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  showDialog({
    dialog: OceanConsolidatedWizardDialog,
    props: {
      className: 'order-modal',
      title: 'Consolidation Wizard',
      onOceanOrderCreated: onOceanOrderCreated,
    },
  });
};

export const OceanShipmentOrdersList = ({
  goToDetails = () => {},
  filter = null,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch = () => {},
}: OceanShipmentOrdersListProps) => {
  const [oceanOrders, setOceanOrders] = useState<OrderDtoPagedResult | null>(
    null,
  );

  const [loading, setLoading] = useState(true);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  useEffect(() => {
    getOrdersColumns().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  useEffect(() => {
    getOceanOrdersData();
  }, [offset, limit, sort, filter, search]);

  const getOceanOrdersData = async () => {
    const additionalFilter = hidenFilter(
      `orderType:${OrderTypes.OceanShipmentOrder}`,
      filter,
    );
    const oceanOrdersData = await getOrders({
      offset,
      limit,
      sort,
      filter: additionalFilter,
      search,
    });

    if (oceanOrdersData) {
      const oceanOrderDtoResult: OrderDtoForListPagedResult = {
        limit: oceanOrdersData.limit,
        offset: oceanOrdersData.offset,
        links: oceanOrdersData.links,
        totalCount: oceanOrdersData.totalCount,
        items: oceanOrdersData.items.map<OrderForListDto>((item) => {
          const resultOrder: OrderForListDto = item;
          if (item?.customValues) {
            Object.keys(item.customValues).forEach(
              (customFieldInternalName) => {
                if (!resultOrder.hasOwnProperty(customFieldInternalName))
                  resultOrder[customFieldInternalName] =
                    item.customValues[customFieldInternalName];
              },
            );
          }
          return item;
        }),
      };
      setOceanOrders(oceanOrderDtoResult);
      setGetDataSuccess(true);
    }
  };

  const {
    orderColumns: columns,
    defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(oceanShipmentOrdersStore);

  const onDeleteOceanOrder = async (oceanOrder: OrderDto) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${oceanOrder.orderNumber} Ocean Shipment Order`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteOrder(oceanOrder);
        onDelete();
        getOceanOrdersData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditOceanOrder = (oceanOrder: OrderDto) => {
    goToDetails(oceanOrder.orderId, oceanOrder.orderType);
  };

  const navigate = useNavigate();

  const onChangeItem = (data?: any[]) => {
    setOceanOrders((purchasesDto) => {
      purchasesDto.items = data;
      return { ...purchasesDto };
    });
  };

  const onOceanOrderSelect = async (purchase, orderId) => {
    onSelect?.(purchase, orderId);
    if (purchase?.orderId) {
      const selectedOceanShipment = await showDialog({
        dialog: ConsolidatedShipmentDialog,
        props: {
          orderId: purchase?.orderId,
          onEditComplete: getOceanOrdersData,
        },
      });
      if (selectedOceanShipment !== null) {
        getOceanOrdersData();
      }
    }
  };

  const openConsolidatedShipmentDialog = (values: any) => {
    showDialog({
      dialog: ConsolidatedShipmentCreateDialog,
      props: {
        title: 'Consolidated Shipment',
        orderId: 0,
        onConsolidatedShipmentCreated: goToDetails,
        defaultState: {
          countries: values?.['OrderEntities.ContactAddress.CountryCode'],
          divisionId: values?.divisionId,
          modeOfTransportationId:
            values?.['customValues.modeOfTransportationId'],
          orderType: values?.orderType,
          finalMileCarrierId: values?.['OrderEntities.ContactId'],
        },
      },
    });
  };

  useEffect(() => {
    setActionButtons([
      <Button
        color="primary"
        className="btn btn-md btn-primary btn-button btn-block"
        onClick={() =>
          openConsolidatedShipmentDialog({
            ...{},
            orderType: OrderTypes.OceanShipmentOrder,
          })
        }
      >
        Create Consolidated Ocean Shipment
      </Button>,
    ]);
    return () => clearActionButtons();
  }, []);

  // @ts-ignore
  const { organizationId } = useParams();

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <OceanShipmentOrderGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={null}
          data={oceanOrders?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={oceanOrders?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_ORDER_LINK_KEY, oceanOrders?.items[0]?.links)
              ? onDeleteOceanOrder
              : null
          }
          onSort={(field) => {
            onSort(field);
          }}
          search={search}
          onEdit={onEditOceanOrder}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onLimitChanged={onLimitChanged}
          onSelect={goToDetails}
          onChangeItem={onChangeItem}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          showToolbar={false}
          entityType={OrderTypes.OceanShipmentOrder}
        />
        {!search && !oceanOrders.items.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Ocean Shipment Orders Found</h3>
            {userHas(CREATE_ORDER_LINK_KEY) && (
              <Button
                size={'lg'}
                color="primary"
                onClick={() => {
                  onOpenOceanShipmentWizard(organizationId, navigate);
                }}
              >
                Create Ocean Shipment
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
