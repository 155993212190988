import { forwardRef, useCallback } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

export type CustomToggleProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const CustomMenu = forwardRef(({ children }, ref) => {
  return <div ref={ref}>{children}</div>;
});

export const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  (props, ref) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        // Assuming dropdown-toggle is a common parent class,
        // this will only target dropdowns that are currently shown.
        document
          .querySelectorAll('.dropdown-toggle.show')
          .forEach((dropdown) => {
            dropdown.classList.remove('show');
          });
        props.onClick(e);
      },
      [props.onClick],
    );

    return (
      <div
        onClick={handleClick}
        ref={ref}
        className="dropdown-toggle dropdown-dots-vertical pointer pl-3"
        id="dropdownMenuButton"
      >
        <BsThreeDotsVertical />
      </div>
    );
  },
);
