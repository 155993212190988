import {
  CommodityStatusDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateCommodityStatusCommandValues,
  UpdateCommodityStatusCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCommodityStatusRequest,
  getCommodityStatusesListRequest,
  getCommodityStatusRequest,
  deleteCommodityStatusRequest,
  updateCommodityStatusRequest,
  importCommodityStatusesRequest,
  exportCommodityStatusesRequest,
  GetCommodityStatusParams,
} from './commodityStatuses.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type CommodityStatusesStoreState = {
  links: LinkDto[];
  commodityStatusColumns: EntityFieldDto[];
  defaultCommodityStatusColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getCommodityStatusesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CommodityStatus',
  });
});

addEffectStatusHandling(getCommodityStatusesColumnsFx);

export const getCommodityStatusesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CommodityStatus',
  });
});

addEffectStatusHandling(getCommodityStatusesDefaultColumnsFx);

export const updateCommodityStatusesColumns = createEvent<EntityFieldDto[]>();

export const getCommodityStatusesFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCommodityStatusesListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getCommodityStatusesFx);

export const createCommodityStatusFx = createEffect(
  (commodityStatusData: CommodityStatusDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createCommodityStatusCommand: CreateCommodityStatusCommandValues = {
      ...commodityStatusData,
    };

    return createCommodityStatusRequest(
      currentOrganization!,
      createCommodityStatusCommand,
    );
  },
);

addEffectStatusHandling(createCommodityStatusFx, {
  completeMessage: 'Commodity status was created successfully',
  errorMessage: 'Commodity status was not created',
});

export const getCommodityStatusFx = createEffect(
  (commodityStatusParams: GetCommodityStatusParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCommodityStatusRequest(
      currentOrganization as LinkResourceBaseDto,
      commodityStatusParams,
    );
  },
);

addEffectStatusHandling(getCommodityStatusFx);

export const updateCommodityStatusFx = createEffect(
  (commodityStatus: CommodityStatusDto) => {
    const updateCommodityStatusCommand: UpdateCommodityStatusCommandValues = {
      ...commodityStatus,
    };
    return updateCommodityStatusRequest(
      commodityStatus,
      updateCommodityStatusCommand,
    );
  },
);

addEffectStatusHandling(updateCommodityStatusFx, {
  completeMessage: 'Commodity status was updated successfully',
  errorMessage: 'Commodity status was not updated',
});

export const deleteCommodityStatusFx = createEffect(
  (commodityStatus: CommodityStatusDto) => {
    return deleteCommodityStatusRequest(commodityStatus);
  },
);

addEffectStatusHandling(deleteCommodityStatusFx, {
  completeMessage: 'Commodity status was deleted successfully',
  errorMessage: 'Commodity status was not deleted',
});

export const exportCommodityStatusesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportCommodityStatusesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportCommodityStatusesFx, {
  completeMessage: 'Commodity statuses were exported successfully',
  errorMessage: 'Commodity statuses were not exported',
});

export const importCommodityStatusesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importCommodityStatusesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importCommodityStatusesFx, {
  completeMessage: 'Commodity statuses were imported successfully',
  errorMessage: 'Commodity statuses were not imported',
  inFlightMessage: 'Importing commodity statuses...',
});

const defaultState: CommodityStatusesStoreState = {
  links: [],
  commodityStatusColumns: [],
  defaultCommodityStatusColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const commodityStatusStore: Store<CommodityStatusesStoreState> = createStore(
  defaultState,
)
  .on(getCommodityStatusesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      commodityStatusColumns: payload.result.items,
      defaultCommodityStatusColumns: payload.result.items,
    };
  })
  .on(getCommodityStatusesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultCommodityStatusColumns: payload.result.items,
    };
  })
  .on(updateCommodityStatusesColumns, (state, payload) => {
    return { ...state, commodityStatusColumns: payload };
  });
