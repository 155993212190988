import { AppComponent } from '../../screens/appComponent.component';
import { UIContextType } from './layout-interfaces';
import { MainLayoutComponent } from './mainlayout-component';

export const ComponentResolver = (modules: any) => {
  return {
    resolver: ({
      component,
      props,
      variables,
      name,
      context,
    }: {
      component: string;
      props?: any;
      variables?: any;
      name?: string;
      context?: UIContextType;
    }) => {
      if (modules) {
        for (const module of modules) {
          if (module?.components)
            for (const componentModule of module.components) {
              if (componentModule?.name === component) {
                return (
                  <MainLayoutComponent
                    {...componentModule}
                    {...props}
                    variables={variables}
                    context={context}
                  />
                );
              }
            }
        }
      }
      return (
        <div>
          <AppComponent
            component={component}
            variables={variables}
            props={props}
          />
        </div>
      );
    },
  };
};
