import {
  OrderCommodityDto,
  LinkDto,
  LinkResourceBaseDto,
  OrderCommodityDtoPagedResult,
  CreateOrderCommodityCommandValues,
  UpdateOrderCommodityCommandValues,
  CommodityDto,
} from '../../models/data.models';
import { Column } from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrderCommodityRequest,
  getOrderCommoditiesListRequest,
  getOrderCommodityRequest,
  deleteOrderCommodityRequest,
  updateOrderCommodityRequest,
  GetOrderCommodityParams,
  getCommodityOrderRequest,
  GetCommodityOrderQueryValues,
} from './orderCommodities.service';

type OrderCommoditiesStoreState = {
  links: LinkDto[];
  orderCommodityColumns: Column[];
};

export const updateOrderCommoditiesColumns = createEvent<Column[]>();

export const getOrderCommodities = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getOrderCommoditiesListRequest(currentOrganization, params);
});

export const createOrderCommodity = createEffect(
  (orderCommodityData: OrderCommodityDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createOrderCommodityCommand: CreateOrderCommodityCommandValues = {
      ...orderCommodityData,
    };

    return createOrderCommodityRequest(
      currentOrganization!,
      createOrderCommodityCommand,
    );
  },
);

export const getOrderCommodity = createEffect(
  (orderCommodityParams: GetOrderCommodityParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrderCommodityRequest(
      currentOrganization as LinkResourceBaseDto,
      orderCommodityParams,
    );
  },
);
export type UpdateOrderCommodityParams = {
  commodity: CommodityDto;
  orderCommodity: OrderCommodityDto;
};

export const updateOrderCommodity = createEffect(
  (params: UpdateOrderCommodityParams) => {
    const updateOrderCommodityCommand: UpdateOrderCommodityCommandValues = {
      ...params.orderCommodity,
    };
    return updateOrderCommodityRequest(
      params.commodity,
      updateOrderCommodityCommand,
    );
  },
);

export const deleteOrderCommodity = createEffect(
  (orderCommodity: OrderCommodityDto) => {
    return deleteOrderCommodityRequest(orderCommodity);
  },
);

export interface GetCommodityOrderParams {
  commodity: CommodityDto;
  values: GetCommodityOrderQueryValues;
}

export const getCommodityOrderFx = createEffect(
  (params: GetCommodityOrderParams) => {
    return getCommodityOrderRequest(params.commodity, params.values);
  },
);

const defaultState: OrderCommoditiesStoreState = {
  links: [],
  orderCommodityColumns: [
    { name: 'commodityId', visible: true, title: 'Commodity Id' },
    { name: 'orderId', visible: true, title: 'Order Id' },
    { name: 'created', visible: true, title: 'Created' },
    { name: 'createdBy', visible: true, title: 'Created By' },
    { name: 'customValues', visible: true, title: 'Custom Values' },
    { name: 'lastModified', visible: true, title: 'Last Modified' },
    { name: 'lastModifiedBy', visible: true, title: 'Last Modified By' },
    {
      name: 'commodityDescription',
      visible: true,
      title: 'Commodity Description',
    },
  ],
};

export const orderCommodityStore: Store<OrderCommoditiesStoreState> = createStore(
  defaultState,
).on(updateOrderCommoditiesColumns, (state, payload) => {
  return { ...state, orderCommodityColumns: payload };
});
