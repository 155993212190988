import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import {
  AccountingTransactionType,
  GetContactsQuery,
} from '../../../models/data.models';
import { InvoicesList } from '../components/invoice-list.component';
import {
  CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  GetAccountingTransactionParams,
} from '../../accountingTransactions/accountingTransactions.service';
import {
  INVOICES_CREATE_PATH,
  INVOICES_EDIT_PATH,
  INVOICES_LIST_PATH,
} from '../invoices.route';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';
import {
  accountingTransactionStore,
  getAccountingTransactionsDefaultColumnsFx,
} from '../../accountingTransactions/accountingTransactions.store';
import { useStore } from 'effector-react';

export type InvoicesListScreenProps = { pageTitle: string; group: string };

const generateHistoryPath = (
  organizationId: string,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    view: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + INVOICES_LIST_PATH, {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

export const InvoicesListScreen = ({
  pageTitle,
  group,
}: InvoicesListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(accountingTransactionStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `accountingTransactionType: ${AccountingTransactionType.Invoice}`,
    search = '',
    view = '',
  } = useQuery() as GetContactsQuery;
  const navigate = useNavigate();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + INVOICES_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const goToDetails = (params: GetAccountingTransactionParams) => {
    const path = generatePath(INTERNAL_PATH + INVOICES_EDIT_PATH, {
      organizationId,
      accountingTransactionId: params.accountingTransactionId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const params = { offset, limit, sort, filter, search, view };
  const onQueryChange = (newParams: Partial<typeof params>) => {
    const mergedParams = { ...params, ...newParams };
    const newPath = generateHistoryPath(organizationId, mergedParams);
    navigate(newPath);
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + INVOICES_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getAccountingTransactionsDefaultColumnsFx();
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + INVOICES_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  return (
    <InternalLayout
      group={group}
      title={pageTitle}
      createPath={
        userHas(CREATE_ACCOUNTINGTRANSACTION_LINK_KEY) ? createPath : null
      }
    >
      <InvoicesList
        search={search}
        offset={offset}
        limit={limit}
        filter={filter}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={(page) => onQueryChange({ offset: page * limit })}
        onViewChanged={onViewChanged}
        onLimitChanged={onLimitChanged}
        onSort={(sort) => onQueryChange({ sort })}
        onFilter={(filter) => onQueryChange({ filter, offset: 0 })}
        onSearch={(search) => onQueryChange({ search, offset: 0 })}
      />
    </InternalLayout>
  );
};
