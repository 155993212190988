import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { API_ENDPOINT } from '../../properties';
import { renewToken } from '../api/api.service';

let apolloClient;

const getApolloClient = (organizationId?: number) => {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      uri: `${API_ENDPOINT}/api/graphql?organizationId=${organizationId}`,
      cache: new InMemoryCache(),
    });
  }
  return apolloClient;
};

const isTokenExpired = (token: string): boolean => {
  if (!token) return true;

  try {
    const payloadBase64 = token.split('.')[1];
    const decodedPayload: { exp?: number } = JSON.parse(atob(payloadBase64));
    const expirationDate = new Date(decodedPayload.exp! * 1000);
    return new Date() > expirationDate;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};

const checkAndRefreshToken = async () => {
  if (
    isTokenExpired(
      localStorage.getItem('token') || sessionStorage.getItem('token'),
    )
  ) {
    await renewToken();
  }
};

const getTokenWithRenewal = async () => {
  await checkAndRefreshToken();
  return localStorage.getItem('token') || sessionStorage.getItem('token');
};

export const queryGraphQl = async (q: {
  query: string;
  variables: any;
  fetchPolicy?: string | undefined;
}) => {
  const token = await getTokenWithRenewal();

  const client = getApolloClient(q.variables?.organizationId);
  const result = await client.query({
    context: {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    },
    query: gql`
      ${q.query}
    `,
    variables: q.variables,
    fetchPolicy: q.fetchPolicy || 'no-cache',
  });
  return result.data;
};

export const executeMutation = async (q: {
  mutation: string;
  variables: any;
  fetchPolicy?: string | undefined;
}) => {
  const organizationId =
    q.variables?.input?.organizationId ?? q.variables?.organizationId;
  const token = await getTokenWithRenewal();

  const client = getApolloClient(organizationId);
  const result = await client.mutate({
    context: {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    },
    mutation: gql`
      ${q.mutation}
    `,
    variables: q.variables,
    fetchPolicy: q.fetchPolicy || 'no-cache',
  });
  return result.data;
};
