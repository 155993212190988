import {
  ApplyBy,
  CustomFieldEntityType,
  CustomFieldType,
  DocumentTemplateType,
  OrderStatuses,
  PaidAs,
  PurposeOfCommodityValues,
  QuoteStatuses,
} from '../../models/data.models';
import { getEnumKeyByValue } from '../../utils/helper.utils';

export class OrderDefaultValues {
  static readonly billToContactId = null;
  static readonly employeeContactId = null;
  static readonly salespersonContactId = null;
  static readonly orderStatusId = null;
  static readonly orderStatus = null;
  static readonly quoteStatus = getEnumKeyByValue(
    QuoteStatuses.Posted,
    QuoteStatuses,
  );
  static readonly warehouseReceiptStatus = getEnumKeyByValue(
    OrderStatuses.Pending,
    OrderStatuses,
  );
  static readonly carrierContactId = null;
  static readonly divisionId = null;
}

export class EntityDefaultValues {
  static readonly contactAddressId = null;
  static readonly contactAddressName = null;
  static readonly contactId = null;
  static readonly contactName = null;
}

export class CommodityDefaultValues {
  static readonly note = null;
  static readonly quantity = 0;
  static readonly unit = null;
  static readonly unitaryValue = null;
  static readonly packageTypeName = null;
  static readonly commodityStatus = null;
  static readonly description = '';
  static readonly dimensionsUnit = null;
  static readonly height = null;
  static readonly commodityLength = null;
  static readonly packageTypeId = null;
  static readonly pieces = 1;
  static readonly volumePiece = 0;
  static readonly volumeTotal = 0;
  static readonly valueTotal = 0;
  static readonly volumeUnit = null;
  static readonly weightTotal = 0;
  static readonly weight = null;
  static readonly weightByTotal = false;
  static readonly weightUnit = null;
  static readonly width = null;
  static readonly commodityTypeId = null;
  static readonly customValues = {
    purposeOfCommodity: PurposeOfCommodityValues.Gift,
    dangerousItems: '',
  };
  static readonly warehouseLocationId = null;
  static readonly warehouseLocation = null;
  static readonly containerCommodities = null;
  static readonly valueByTotal = false;
}

export class ProfitDefaultValues {
  static readonly expense = 0;
  static readonly income = 0;
  static readonly profit = 0;
}

export class ChargeDefaultValues {
  static readonly note = null;
  static readonly amountAndTaxAmount = null;
  static readonly currency = null;
  static readonly expense = null;
  static readonly income = null;
  static readonly prepaid = null;
  static readonly chargeStatus = null;
  static readonly salesTaxAmount = 0;
  static readonly salesTaxCode = '';
  static readonly salesTaxId = null;
  static readonly salesTaxRate = 0;
  static readonly totalAmount = 0;
  static readonly accountingItemId = null;
  static readonly accountingItemName = '';
  static readonly amount = 0;
  static readonly applyBy = ApplyBy['Pieces'];
  static readonly chargeType = null;
  static readonly description = '';
  static readonly freightServiceClassId = 0;
  static readonly grossVolume = 0;
  static readonly grossWeight = 0;
  static readonly isConsolidated = false;
  static readonly paidAs = PaidAs['Prepaid'];
  static readonly pieces = 0;
  static readonly price = 0;
  static readonly quantity = 1;
  static readonly showInDocuments = false;
  static readonly unit = '';
}

export class CountryDefaultValues {
  static readonly countryCode = '';
  static readonly countryName = '';
}

export class StateDefaultValues {
  static readonly stateCode = '';
  static readonly countryCode = null;
  static readonly stateName = '';
}

export class PortDefaultValues {
  static readonly countryCode = '';
  static readonly portId = null;
  static readonly portName = '';
  static readonly stateCode = null;
  static readonly isRail = false;
  static readonly isAir = false;
  static readonly isBorderCrossingPoint = false;
  static readonly isMail = false;
  static readonly isMariTime = false;
  static readonly isRoad = false;
  static readonly isMyCompany = false;
  static readonly portRemarks = null;
}

export class DivisionDefaultValues {
  static readonly assignDivisionToEntities = false;
  static readonly comments = null;
  static readonly divisionName = '';
  static readonly email = null;
  static readonly faxNumber = null;
  static readonly phoneNumber = null;
  static readonly useDivisionInDocumentHeaders = false;
  static readonly streetAndNumber = '';
  static readonly city = '';
  static readonly countryCode = null;
  static readonly countryName = null;
  static readonly zipCode = null;
  static readonly stateCode = null;
  static readonly stateName = null;
  static readonly portId = null;
  static readonly portName = null;
  static readonly airAMSOriginatorCode = '';
}

export class PackageTypeDefaultValues {
  static readonly air = false;
  static readonly containerDescriptionCode = null;
  static readonly containerTypeCode = null;
  static readonly ground = false;
  static readonly height = 0;
  static readonly packageTypeLength = 0;
  static readonly maximumWeight = 0;
  static readonly packageTypeName = null;
  static readonly ocean = false;
  static readonly packageCategoryCode = null;
  static readonly volume = 0;
  static readonly weight = 0;
  static readonly width = 0;
}

export class PackageCategoryDefaultValues {
  static readonly packageCategoryCode = '';
  static readonly isContainer = false;
  static readonly packageCategoryName = '';
}

export class ContactDefaultValues {
  static readonly contactId = 0;
  static readonly contactName = null;
  static readonly divisionId = null;
  static readonly accountNumber = null;
  static readonly contactAddresses = [];
  static readonly shippingAddress = null;
  static readonly billingAddress = null;
  static readonly contactFirstName = null;
  static readonly contactLastName = null;
  static readonly contactType = null;
  static readonly divisionName = null;
  static readonly emailAddress = null;
  static readonly faxNumber = null;
  static readonly idNumber = null;
  static readonly idNumberType = null;
  static readonly mobilePhoneNumber = null;
  static readonly linkToContactId = null;
  static readonly contactLinkType = null;
  static readonly phoneNumber = null;
  static readonly website = null;
  static readonly customerPaidAs = PaidAs['Prepaid'];
  static readonly carrierPaidAs = PaidAs['Collect'];
  static readonly creditLimit = null;
  static readonly contactStatusId = null;
  static readonly tags = [];
  static readonly discounts = [];
}

export class ContactAddressesDefaultValues {
  static readonly contactId = 0;
  static readonly addressType = null;
  static readonly stateName = null;
  static readonly stateCode = null;
  static readonly countryName = null;
  static readonly countryCode = null;
  static readonly addressLine = null;
  static readonly contactAddressId = null;
  static readonly addressLine2 = null;
  static readonly city = null;
  static readonly postalCode = null;
  static readonly latitude = null;
  static readonly longitude = null;
}

export class ContainerTypeDefaultValues {
  static readonly containerTypeCode = '';
  static readonly description = '';
}

export class ContainerDescriptionDefaultValues {
  static readonly containerDescriptionCode = '';
  static readonly description = '';
}

export class EquipmentTypeDefaultValues {
  static readonly equipmentTypeName = '';
}

export class AccountingAccountDefaultValues {
  static readonly parentAccountName = null;
  static readonly accountName = '';
  static readonly accountNumber = null;
  static readonly accountType = null;
  static readonly isInactive = false;
  static readonly parentAccountId = null;
}

export class SalesTaxesDefaultValues {
  static readonly authorityContactName = '';
  static readonly parentSalesTaxName = '';
  static readonly authorityContactId = null;
  static readonly description = '';
  static readonly isGroupTax = false;
  static readonly isInactive = false;
  static readonly parentSalesTaxId = null;
  static readonly rate = null;
  static readonly taxCode = '';
}

export class AccountingItemDefaultValues {
  static readonly accountId = null;
  static readonly description = '';
  static readonly isInactive = false;
  static readonly itemCode = null;
  static readonly itemType = null;
  static readonly price = null;
  static readonly salesTaxId = null;
  static readonly tariff = null;
  static readonly accountName = '';
  static readonly salesTaxCode = '';
}

export class PaymentTermDefaultValues {
  static readonly description = '';
  static readonly discountPaidWithinDays = null;
  static readonly discountPercentage = null;
  static readonly isInactive = false;
  static readonly netDueDays = 0;
}

export class AccountingTransactionDefaultValues {
  static readonly divisionId = null;
  static readonly divisionName = '';
  static readonly paidAs = PaidAs['Prepaid'];
  static readonly amountDue = 0;
  static readonly amountPaid = 0;
  static readonly applyToContactName = '';
  static readonly billToContactAddressName = '';
  static readonly paymentTermsDescription = '';
  static readonly accountName = '';
  static readonly accountId = null;
  static readonly accountingTransactionStatus = null;
  static readonly accountingTransactionType = null;
  static readonly applyToContactID = null;
  static readonly billToContactAddressId = null;
  static readonly dueDate = new Date();
  static readonly note = null;
  static readonly paymentTermsId = null;
  static readonly transactionDate = new Date();
  static readonly transactionNumber = '';
  static readonly applyToContactType = null;
  static readonly totalAmount = 0;
}

export class ContactAddressDefaultValues {
  static readonly addressType = null;
  static readonly contactId = null;
  static readonly stateName = null;
  static readonly stateCode = null;
  static readonly countryName = null;
  static readonly countryCode = null;
  static readonly addressLine = null;
  static readonly addressLine2 = null;
  static readonly city = null;
  static readonly postalCode = null;
  static readonly latitude = null;
  static readonly longitude = null;
}

export class PaymentDefaultValues {
  static readonly accountingAccountId = null;
  static readonly amountReceived = null;
  static readonly amountPaid = null;
  static readonly applyToContactId = null;
  static readonly checkNumber = null;
  static readonly currencyId = null;
  static readonly divisionId = null;
  static readonly memo = '';
  static readonly paymentDate = new Date();
  static readonly accountingAccountName = '';
  static readonly applyToContactName = '';
  static readonly currencyName = '';
  static readonly currency = null;
  static readonly divisionName = '';
}

export class CustomFieldDefaultValues {
  static readonly category = null;
  static readonly customFieldEntityType = CustomFieldEntityType.Order;
  static readonly customFieldType = CustomFieldType.Text;
  static readonly description = null;
  static readonly displayName = '';
  static readonly internalName = '';
  static readonly isInactive = false;
}

export class DocumentTemplateDefaultValues {
  static readonly bodyHtmlTemplate = null;
  static readonly bodyTextTemplate = null;
  static readonly description = '';
  static readonly documentTemplateType =
    DocumentTemplateType.RateConfirmationDocument;
  static readonly fileNameTemplate = null;
  static readonly isDefault = false;
  static readonly isInactive = false;
  static readonly documentTemplateName = '';
  static readonly subjectTemplate = null;
}

export class ModeOfTransportationDefaultValues {
  static readonly description = null;
  static readonly transportationMethod = null;
  static readonly transportationMethodDescription = null;
  static readonly usCustomsCode = '';
}

export class CurrencyDefaultValues {
  static readonly currencyCode = '';
  static readonly currencyName = '';
  static readonly decimalPlaces = null;
  static readonly exchangeRateLastUpdate = undefined;
  static readonly exchangeRateToPrimary = null;
  static readonly gainLossAccountDescription = '';
  static readonly gainLossAccountId = null;
  static readonly isInactive = false;
  static readonly symbol = '';
}

export class UserGroupDefaultValues {
  static readonly description = '';
  static readonly userGroupName = '';
}

export class UserDefaultValues {
  static readonly email = null;
  static readonly firstName = '';
  static readonly lastName = '';
  static readonly userName = null;
  static readonly isPassword = false;
  static readonly isConfirmPassword = false;
  static readonly confirmPassword = null;
}

export class EventDefinitionDefaultValues {
  static readonly eventDefinitionId = 0;
  static readonly eventName = null;
  static readonly automaticCreate = null;
  static readonly trigger = null;
  static readonly description = null;
  static readonly location = null;
  static readonly isInactive = false;
  static readonly includeInTracking = false;
  static readonly sendEmail = false;
  static readonly organizationId = null;
  static readonly isAutomaticCreate = false;
  static readonly sendEmailDocumentId = null;
}

export class TrackingEventDefaultValues {
  static readonly trackingEventId = null;
  static readonly description = null;
  static readonly location = null;
  static readonly eventDate = new Date();
  static readonly eventDefinitionId = null;
  static readonly eventName = null;
  static readonly isInactive = false;
  static readonly includeInTracking = false;
  static readonly sendEmail = false;
}

export class CommodityTypeDefaultValues {
  static readonly description = '';
  static readonly code = '';
  static readonly isActive = false;
}

export class WarehouseLocationDefaultValues {
  static readonly description = null;
  static readonly code = null;
  static readonly customerId = null;
  static readonly width = null;
  static readonly height = null;
  static readonly locationLength = null;
  static readonly customerName = null;
  static readonly isInactive = false;
  static readonly locationType = null;
}

export class CityDefaultValues {
  static readonly cityName = '';
  static readonly stateCode = '';
  static readonly stateName = '';
  static readonly countryCode = null;
  static readonly countryName = '';
  static readonly latitude = null;
  static readonly longitude = null;
}

export class OrganizationConfigDefaultValues {
  static readonly configName = '';
  static readonly customValues = {};
}
