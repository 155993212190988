import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { getOrders, deleteOrder } from '../../orders/orders.store';
import { getPurchasesColumns, purchasesStore } from '../purchases.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ORDER_LINK_KEY,
  DELETE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../../orders/orders.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PURCHASE_CREATE_PATH } from '../purchase.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { PurchaseDialog } from './purchase.dialog';
import { userHas } from '../../auth/auth.store';
import { PurchaseGrid } from '../../common/components/grid/purchase-grid.component';
import { hidenFilter } from '../../../utils/query.utils';

export type PurchasesListProps = {
  goToDetails?: (purchaseParams: GetOrderParams) => void;
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  summary?: boolean;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (purchase: OrderDto, purchaseId: string) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const PurchasesList = ({
  goToDetails = () => {},
  filter = null,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  summary = true,
  onDelete = () => {},
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch = () => {},
}: PurchasesListProps) => {
  const [purchases, setPurchases] = useState<OrderDtoPagedResult | null>(null);
  const [loading, setLoading] = useState(true);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  useEffect(() => {
    getPurchasesColumns().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    getPurchasesData();
  }, [offset, limit, sort, filter, search, summary]);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const getPurchasesData = async () => {
    const additionalFilter = hidenFilter(
      `orderType:${OrderTypes.Purchase}`,
      filter,
    );
    const purchasesData = await getOrders({
      offset,
      limit,
      sort,
      filter: additionalFilter,
      search,
      summary,
    });
    if (purchasesData) {
      const purchaseDtoResult: OrderDtoForListPagedResult = {
        limit: purchasesData.limit,
        offset: purchasesData.offset,
        links: purchasesData.links,
        totalCount: purchasesData.totalCount,
        items: purchasesData.items.map<OrderForListDto>((item) => {
          const resultPurchase: OrderForListDto = item;
          if (item?.customValues) {
            Object.keys(item.customValues).forEach(
              (customFieldInternalName) => {
                if (!resultPurchase.hasOwnProperty(customFieldInternalName))
                  resultPurchase[customFieldInternalName] =
                    item.customValues[customFieldInternalName];
              },
            );
          }
          return item;
        }),
      };
      setPurchases(purchaseDtoResult);
      setGetDataSuccess(true);
    }
  };

  const {
    purchaseColumns: columns,
    defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(purchasesStore);

  const onDeletePurchase = async (purchase: OrderDto) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${purchase.orderNumber} Purchase`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteOrder(purchase);
        onDelete();
        getPurchasesData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditPurchase = (purchase: OrderDto) => {
    goToDetails({ orderId: purchase.orderId });
  };

  const navigate = useNavigate();

  const onCreateNewPurchase = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + PURCHASE_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(createPath);
  };

  const onChangeItem = (data?: any[]) => {
    setPurchases((purchasesDto) => {
      purchasesDto.items = data;
      return { ...purchasesDto };
    });
  };

  const onPurchaseSelect = async (purchase, orderId) => {
    onSelect?.(purchase, orderId);
    if (purchase?.orderId) {
      const selectedPurchase = await showDialog({
        dialog: PurchaseDialog,
        props: {
          className: 'order-modal',
          title: 'Update Purchase',
          orderId: purchase?.orderId,
        },
      });
      if (selectedPurchase !== null) {
        getPurchasesData();
      }
    }
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <PurchaseGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={['orderId']}
          data={purchases?.items}
          filterContextValues={{ orderType: OrderTypes.Purchase }}
          columns={columns}
          offset={offset}
          limit={limit}
          total={purchases?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_ORDER_LINK_KEY, purchases?.items[0]?.links)
              ? onDeletePurchase
              : null
          }
          onSort={onSort}
          search={search}
          onEdit={onEditPurchase}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onLimitChanged={onLimitChanged}
          onSelect={onPurchaseSelect}
          onChangeItem={onChangeItem}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
          enableScanner={true}
          entityType={OrderTypes.Purchase}
        />
        {!search && !purchases.items.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Purchases Found</h3>
            {userHas(CREATE_ORDER_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewPurchase}>
                Create Purchase
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
