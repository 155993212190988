import { createEffect, createEvent, createStore, guard } from 'effector';
import { queryGraphQl } from '../graphql/graphql.service';
import { organizationsStore } from '../organization/organization.store';
import { platform } from 'os';

type AppComponent = {
  AppComponentId: string;
  name: string;
  component: string;
  path: string;
  parentAppComponentId: number;
};

type AppComponentMap = {
  [key: string]: AppComponent;
};

export const pendingRequestsStore = createStore({});
export const setPendingRequest = createEvent<string>();
export const clearPendingRequest = createEvent<string>();

pendingRequestsStore
  .on(setPendingRequest, (state, componentName) => ({
    ...state,
    [componentName]: true,
  }))
  .on(clearPendingRequest, (state, componentName) => {
    const { [componentName]: _, ...rest } = state;
    return rest;
  });

export const appComponentsStore = createStore<AppComponentMap>({});

export const getAppComponentFx = createEffect(async ({ name }) => {
  const { currentOrganization } = organizationsStore.getState();

  const data = await queryGraphQl({
    query: `query($organizationId: Int!, $name: String!) {
      appComponent(organizationId: $organizationId, name: $name, platform: "web", applicationName: "System") {
        componentDefinition
      }
    }`,
    variables: {
      organizationId: currentOrganization?.organizationId,
      name,
    },
    fetchPolicy: 'cache-first',
  });

  return data.appComponent?.componentDefinition;
});

getAppComponentFx.watch((params) => {
  setPendingRequest(params.name);
});
getAppComponentFx.finally.watch(({ params }) => {
  clearPendingRequest(params.name);
});

guard({
  source: getAppComponentFx,
  filter: (params) => !pendingRequestsStore.getState()[params.name],
  target: getAppComponentFx,
});

appComponentsStore.on(getAppComponentFx.done, (state, payload) => {
  return {
    ...state,
    [payload?.result?.name]: payload.result,
  };
});
