import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { DiscountForm } from './discount.form';
import { DiscountDto, DiscountType } from '../../../models/data.models';
import {
  createDiscountFx,
  getDiscountFx,
  updateDiscountFx,
} from '../discounts.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ContactType } from '../../../models/data.models';
import { FormContext } from '../../common/components/form/form.component';
import * as Yup from 'yup';

export type DiscountEditProps = {
  discountId?: number | null;
  onDiscountCreated?: (discount: DiscountDto) => void;
  onDiscountUpdated?: (discount: DiscountDto) => void;
  onDiscountLoaded?: (discount: DiscountDto) => void;
  onCancel?: () => void;
};

const initialState: DiscountDto = {
  discountId: 0,
  activationCount: 0,
  activationLimit: null,
  created: null,
  createdBy: '',
  customValues: null,
  description: null,
  endDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000), // Add 30 days to the current date
  isActive: true,
  lastModified: new Date(),
  lastModifiedBy: '',
  minimumChargeAmount: null,
  name: null,
  organizationId: 0,
  promoCode: null,
  startDate: new Date(),
  targetContactTags: null,
  type: DiscountType.Percentage,
  usageLimit: null,
  usedCount: 0,
  value: null,
  links: [],
};

const discountSchema = Yup.object().shape({
  name: Yup.string()
    .required("Can't be blank")
    .max(100, 'Max length is 100')
    .nullable(true),
  description: Yup.string().max(700, 'Max length is 700').nullable(true),
  promocode: Yup.string().max(20, 'Max length is 20').nullable(true),
  value: Yup.number().required("Can't be blank").nullable(true),
  endDate: Yup.date().required('is required.').nullable(true),
});

export const DiscountEdit = ({
  discountId,
  onDiscountLoaded = () => {},
  onDiscountCreated = () => {},
  onDiscountUpdated = () => {},
  onCancel = () => {},
}: DiscountEditProps) => {
  const isCreateMode = !discountId || discountId === 0;
  const [isLoading, setIsLoading] = useState(true);
  const [discount, setDiscount] = useState<DiscountDto | null>(initialState);
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (discountId) {
      getDiscountFx({ discountId }).then((discountDto: DiscountDto) => {
        setDiscount(discountDto);
        setIsLoading(false);
        onDiscountLoaded(discountDto);
      });
    } else {
      throw new Error('Discount keys were not provided');
    }
  }, [discountId]);

  const onSubmit = (data: DiscountDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createDiscountFx(data).then((result) => {
        onDiscountCreated(result.data);
      });
    } else {
      updateDiscountFx(data).then((result) => {
        onDiscountUpdated(result.data);
      });
      setIsSending(false);
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <div className="row">
        <div className="col-12 col-lg-6">
          <DiscountForm
            initialValues={discount || initialState}
            onSubmit={onSubmit}
            validationSchema={discountSchema}
          >
            <FormContext.Consumer>
              {(context) => (
                <>
                  <div className="row">
                    <div className="col-6">
                      <DiscountForm.Name />
                    </div>

                    <div className="col-6">
                      <DiscountForm.PromoCode />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DiscountForm.Description />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <DiscountForm.Value />
                    </div>
                    <div className="col-6">
                      <DiscountForm.Type />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <DiscountForm.UsageLimit />
                    </div>
                    <div className="col-6">
                      <DiscountForm.ActivationLimit />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <DiscountForm.UsedCount />
                    </div>
                    <div className="col-6">
                      <DiscountForm.ActivationCount disabled={true} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <DiscountForm.MinimumChargeAmount />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <DiscountForm.StartDate
                        defaultValue={context?.values?.startDate}
                        onChange={(date?: any) => {
                          context.setFieldValue('startDate', new Date(date));
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <DiscountForm.EndDate
                        defaultValue={context?.values?.endDate}
                        onChange={(date?: any) => {
                          context.setFieldValue('endDate', new Date(date));
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DiscountForm.TargetContactTags />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DiscountForm.CarrierContactSelect
                        contactTypes={[ContactType.Carrier]}
                        selectedFilter={`contactType: ${ContactType.Carrier}`}
                        defaultValue={context?.values?.carriers}
                        onChange={(contacts?: any) => {
                          context?.setFieldValue('carriers', contacts);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DiscountForm.ModeOfTransportations
                        defaultValue={context?.values?.transportationModes}
                        onChange={(modes?: any) => {
                          context?.setFieldValue('transportationModes', modes);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DiscountForm.IsActive
                        defaultValue={discount?.isActive}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end row">
                    <div className="col-5">
                      <Button
                        name="save-discount"
                        type="submit"
                        color="primary"
                        className="btn-block"
                      >
                        Save Discount
                      </Button>
                    </div>
                    <div className="col-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </FormContext.Consumer>
          </DiscountForm>
        </div>
      </div>
    </Panel>
  );
};
