import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import {
  ContactType,
  CustomFieldEntityType,
  GetContactsQuery,
} from '../../../models/data.models';
import {
  FORWARDING_AGENTS_CREATE_PATH,
  FORWARDING_AGENTS_EDIT_PATH,
  FORWARDING_AGENTS_LIST_PATH,
} from '../forwardingAgent.route';
import { CONTACTS_TAB_EDIT_PATH_FOR_FORWARDING_AGENTS } from '../../contacts/contact.route';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { authStore, userHas } from '../../auth/auth.store';
import {
  contactStore,
  getContactsDefaultColumns,
} from '../../contacts/contacts.store';
import { ContactsList } from '../../contacts/components/contacts-list.component';
import { useStore } from 'effector-react';
import { ForwardingAgentDialog } from '../components/forwardingAgent..dialog';

export type ForwardingAgentListScreenProps = {
  pageTitle: string;
  group: string;
};

const contactType = ContactType.ForwardingAgent;
const customFieldEntityType = CustomFieldEntityType.ForwardingAgent;

export const ForwardingAgentListScreen = ({
  pageTitle,
  group,
}: ForwardingAgentListScreenProps) => {
  const { user: currentUser } = authStore.getState();
  // @ts-ignore
  const { organizationId } = useParams();

  const { defaultSort, defaultLimit } = useStore(contactStore);

  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `contactType:${contactType}`,
    search = '',
    view = '',
  } = useQuery() as GetContactsQuery;

  const navigate = useNavigate();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + FORWARDING_AGENTS_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (contactId: number = 0) => {
    let path;
    if (window.location.href.includes('contacts')) {
      path = generatePath(
        INTERNAL_PATH + CONTACTS_TAB_EDIT_PATH_FOR_FORWARDING_AGENTS,
        {
          organizationId,
          contactId,
        },
      );
    } else {
      path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_EDIT_PATH, {
        organizationId,
        contactId,
      });
    }
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getContactsDefaultColumns(contactType);
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}&view=${view}`,
    );
  };
  return (
    <InternalLayout
      group={group}
      title={pageTitle}
      createPath={
        userHas(CREATE_CONTACT_LINK_KEY) && currentUser?.isInOrgAdminRole
          ? createPath()
          : null
      }
    >
      <ContactsList
        filter={filter}
        search={search}
        offset={offset}
        limit={limit}
        sort={sort}
        contactType={contactType}
        customFieldEntityType={customFieldEntityType}
        selectDialogType={ForwardingAgentDialog}
        goToDetails={goToDetails}
        onSearch={onSearch}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onViewChanged={onViewChanged}
        onLimitChanged={onLimitChanged}
        onSort={onSort}
        onFilter={onFilter}
      />
    </InternalLayout>
  );
};
