import { FileType } from '../../../../models/data.models';
import { queryGraphQl } from '../../../graphql/graphql.service';

export const fileUploadAction = ({
  actionProps,
  data,
  latestStoreValues,
  source,
  onAction,
}) => {
  const promise = new Promise((resolve, reject) => {
    // trigger file dialog
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = actionProps.accept || '*';
    fileInput.style.display = 'none';
    document.body.appendChild(fileInput);

    // handle cancel/close
    fileInput.oncancel = async (e) => {
      reject(null);
    };

    // handle file selection
    fileInput.onchange = async (e) => {
      try {
        const file = e.target.files[0];
        if (!file) {
          console.error('No file selected');
          resolve(null);
        }

        // get pre-signed URL for file upload
        const presignedUrl = await queryGraphQl({
          query: `query($organizationId: Int!, $fileName: String!, $contentType: String!) {
            uploadUrl( organizationId: $organizationId, fileName: $fileName, contentType: $contentType){
              presignedUrl
            }
         }`,
          variables: {
            organizationId: data.organizationId,
            fileName: file.name,
            contentType: file.type,
          },
        });

        if (!presignedUrl.uploadUrl?.presignedUrl) {
          throw new Error('No pre-signed URL found');
        }

        // upload file to S3
        const res = await fetch(presignedUrl.uploadUrl?.presignedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        });
        if (!res.ok) {
          throw new Error(`Failed to upload file: ${res.statusText}`);
        }

        resolve({
          url: presignedUrl.uploadUrl?.presignedUrl,
          fileName: file.name,
        });
      } catch (e) {
        console.error('Error uploading file', e);
        reject(e);
      } finally {
        // remove file input
        document.body.removeChild(fileInput);
      }
    };

    fileInput.click(); // trigger file dialog
  });

  promise
    .then((result) => {
      if (actionProps.fileUpload.onSuccess) {
        onAction(
          actionProps.fileUpload.onSuccess,
          { ...data, result },
          latestStoreValues,
          source,
        );
      }
    })
    .catch((e) => {
      if (actionProps.fileUpload.onError) {
        onAction(
          actionProps.fileUpload.onError,
          { error: e, ...data },
          latestStoreValues,
          source,
        );
      }
    });

  return promise;
};
