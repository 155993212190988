import React from 'react';
import { useState } from 'react';
import { getOrderEntitySelects } from './orderEntitiesForm.component';
import { SelectContactPaginate } from '../../../contacts/components/contact-react-select.component';
import { ContactType } from '../../../../models/data.models';
import { CustomFieldsLayout } from '../../../common/components/form/customFields-layout.component';
import { OrderEditFormDto } from '../../../../models/custom.models';
import { CustomFieldDto } from '../../../../models/data.models';
import { Input } from '../../../common/components/input/input.component';

type OrderEntitiesFormProps = {
  airOrderContextFormValue: OrderEditFormDto;
  setAirOrderContextFormValue: (orderDto: any) => void;
  customFields: CustomFieldDto[];
};

export const DeliveryOrderEntitiesForm = ({
  airOrderContextFormValue,
  setAirOrderContextFormValue,
  customFields,
}: OrderEntitiesFormProps) => {
  const carrier = airOrderContextFormValue?.deliveringCarrier;
  const receivedBy = airOrderContextFormValue?.receivedBy;
  const [isProof, setIsProof] = useState(false);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <h5 className="font-weight-normal">General</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <SelectContactPaginate
            header={'Final Mile Carrier'}
            placeholder={'Select Carrier'}
            id={`${carrier?.contactId}`}
            nameId={`${carrier?.contactId}`}
            selectedValue={
              carrier?.contactId && carrier?.contactName && carrier?.contactType
                ? {
                    contactId: carrier?.contactId,
                    name: carrier?.contactName,
                    contactType: carrier?.contactType,
                  }
                : ''
            }
            onChangeContact={(data: any) => {
              setAirOrderContextFormValue((orderDto) => {
                orderDto.deliveringCarrier.contactId = Number(data?.contactId);
                orderDto.deliveringCarrier.contactName = data?.name;
                orderDto.deliveringCarrier.contactType = data?.contactType;
                return { ...orderDto };
              });
            }}
            contactTypes={[ContactType.Carrier]}
            selectedFilter={`contactType: ${ContactType.Carrier}`}
            isMulti={false}
            closeMenuOnSelect={true}
          />
        </div>
        <CustomFieldsLayout
          inputNamePrefix=""
          rows={[{ rowNumber: 1 }]}
          filter={'delivery'}
          customFields={customFields}
          defaultValue={carrier.customValues}
          onChange={(result) => {
            setAirOrderContextFormValue((orderContextFormValue) => {
              orderContextFormValue.deliveringCarrier.customValues = result;
              return orderContextFormValue;
            });
          }}
        />
      </div>

      <div className="row">
        <div className="col-3">
          <h5 className="font-weight-normal">Pickup from</h5>
        </div>
      </div>
      {getOrderEntitySelects(
        airOrderContextFormValue.pickupFrom,
        'Name',
        '',
        [
          ContactType.Customer,
          ContactType.Vendor,
          ContactType.ForwardingAgent,
          ContactType.Contact,
        ],
        `contactType: ${ContactType.Customer} OR
                       contactType: ${ContactType.Vendor} OR
                       contactType: ${ContactType.ForwardingAgent} OR
                       contactType: ${ContactType.Contact}`,
        (data: any) => {
          setAirOrderContextFormValue((orderDto) => {
            if (orderDto.pickupFrom.contactId !== data?.contactId) {
              orderDto.pickupFrom.contactAddressId = null;
              orderDto.pickupFrom.contactAddressName = null;
            }
            orderDto.pickupFrom.contactId = Number(data?.contactId);
            orderDto.pickupFrom.contactName = data?.name;
            orderDto.pickupFrom.contactType = data?.contactType;

            return { ...orderDto };
          });
        },
        (data: any) => {
          setAirOrderContextFormValue((orderDto) => {
            orderDto.pickupFrom.contactAddressId = Number(
              data?.contactAddressId,
            );
            orderDto.pickupFrom.contactAddressName = data?.addressLine;
            return { ...orderDto };
          });
        },
      )}

      <div className="row">
        <div className="col-3">
          <h5 className="font-weight-normal">Deliver to</h5>
        </div>
      </div>
      {getOrderEntitySelects(
        airOrderContextFormValue.deliverTo,
        'Name',
        '',
        [
          ContactType.Customer,
          ContactType.Vendor,
          ContactType.ForwardingAgent,
          ContactType.Contact,
        ],
        `contactType: ${ContactType.Customer} OR
                       contactType: ${ContactType.Vendor} OR
                       contactType: ${ContactType.ForwardingAgent} OR
                       contactType: ${ContactType.Contact}`,
        (data: any) => {
          setAirOrderContextFormValue((orderDto) => {
            if (orderDto.deliverTo.contactId !== data?.contactId) {
              orderDto.deliverTo.contactAddressId = null;
              orderDto.deliverTo.contactAddressName = null;
            }
            orderDto.deliverTo.contactId = Number(data?.contactId);
            orderDto.deliverTo.contactName = data?.name;
            orderDto.deliverTo.contactType = data?.contactType;

            return { ...orderDto };
          });
        },
        (data: any) => {
          setAirOrderContextFormValue((orderDto) => {
            orderDto.deliverTo.contactAddressId = Number(
              data?.contactAddressId,
            );
            orderDto.deliverTo.contactAddressName = data?.addressLine;
            return { ...orderDto };
          });
        },
      )}

      <hr />

      <Input
        name={'isProof'}
        onChange={(value) => setIsProof(value)}
        type={'checkbox'}
        size={'sm'}
      />

      <div className="row">
        <div className="col-4">
          <SelectContactPaginate
            header={'Received by'}
            placeholder={'Select contact'}
            isDisabled={!isProof}
            id={`${receivedBy?.contactId}`}
            nameId={`${receivedBy?.contactId}`}
            selectedValue={
              receivedBy?.contactId &&
              receivedBy?.contactName &&
              receivedBy?.contactType
                ? {
                    contactId: receivedBy?.contactId,
                    name: receivedBy?.contactName,
                    contactType: receivedBy?.contactType,
                  }
                : ''
            }
            onChangeContact={(data: any) => {
              setAirOrderContextFormValue((orderDto) => {
                orderDto.receivedBy.contactId = Number(data?.contactId);
                orderDto.receivedBy.contactName = data?.name;
                orderDto.receivedBy.contactType = data?.contactType;
                return { ...orderDto };
              });
            }}
            contactTypes={[ContactType.Contact]}
            selectedFilter={`contactType: ${ContactType.Contact}`}
            isMulti={false}
            closeMenuOnSelect={true}
          />
        </div>
        <CustomFieldsLayout
          inputNamePrefix=""
          isFieldsDisabled={!isProof}
          rows={[{ rowNumber: 1 }]}
          filter={'deliveryReceivedBy'}
          customFields={customFields}
          defaultValue={receivedBy.customValues}
          onChange={(result) => {
            setAirOrderContextFormValue((orderContextFormValue) => {
              orderContextFormValue.receivedBy.customValues = result;
              return orderContextFormValue;
            });
          }}
        />
      </div>
    </>
  );
};
