import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ComponentProps } from '../layout-interfaces';
import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  parseObjectTemplate,
  parseTemplate,
  useComponentQueries,
  useComponentVariables,
} from '../component-hooks';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { debounce } from 'lodash';
import logger from '../../../logger';
import { ComponentRender } from '../component-render';

export const AutocompleteComponentAsync = ({
  field,
  form,
  componentProps,
  options,
  variables,
  onKeyDown,
  disabled,
  ...props
}) => {
  const [selected, setSelected] = useState(
    field.value !== undefined ? [field.value] : [],
  );
  const [items, setItems] = useState(componentProps.props?.items ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const { variables: compVariables } = useComponentVariables(componentProps);
  const [isLoaded, setIsLoaded] = useState(false);

  const { getPropertyValue, query } = useComponentQueries(
    componentProps,
    compVariables,
  );

  useEffect(() => {
    if (query) {
      setIsLoaded(true);
    }
  }, [query]);

  const onSearch = useCallback(
    async (search) => {
      setIsLoading(true);
      if (!search) return;

      try {
        if (!options?.searchQuery?.name) {
          logger.error('options.searchQuery.name is required');
          return;
        }
        if (!options?.searchQuery?.path) {
          logger.error('options.searchQuery.path is required');
          return;
        }

        const filter = parseTemplate(
          componentProps.props?.filter ?? '',
          {
            ...compVariables,
            ...componentProps?.context?.store,
            ...form.values,
          },
          // prefix,
        );
        const result = await getPropertyValue(
          {
            fromQuery: options?.searchQuery,
          },
          {}, // default value
          {
            ...form.values, // additional params
            // additional params
            search: search ?? '',
            skip: 0,
            pageSize: 10,
            filter,
          },
        );

        const transformedOptions = result?.map((o: any) => {
          return {
            ...o,
            label: parseTemplate(options?.itemLabelTemplate, o),
          };
        });

        setItems(transformedOptions);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoaded],
  );

  const searchBounced = useCallback(debounce(onSearch, 300), [onSearch]);

  const onInputChange = useCallback(
    async (input) => {
      if (componentProps.props?.multiple) {
        return;
      }
      form.setFieldValue(field.name, input);
    },
    [componentProps, form, field.name],
  );

  const onChange = useCallback(
    (selectedVals) => {
      if (!selectedVals[0]) return;
      if (componentProps.props?.multiple) {
        form.setFieldValue(
          field.name,
          selected.map((item) => item),
        );
        return;
      }

      form.setFieldValue(
        field.name,
        selectedVals[0]?.[componentProps.props?.valueKey ?? 'label'],
      );

      if (componentProps?.props?.onSelectValue) {
        const prefix = componentProps?.props?.prefix?.toString()?.trim() ?? '';
        const componentPrefix = parseTemplate(prefix, { ...variables });

        const formValues = {
          ...form.values,
          selectedValue: selectedVals[0],
        };

        const onSelectValue = parseObjectTemplate(
          componentProps?.props?.onSelectValue,
          {
            ...variables,
            ...formValues,
            prefix: componentPrefix,
          },
        );

        componentProps.context?.action(
          onSelectValue,
          {
            selectedItem: selectedVals[0],
            ...componentProps?.variables,
          },
          {
            sender: field.name,
            formikContext: form,
          },
        );
      }
    },
    [componentProps, selected, variables],
  );

  const onBlur = useCallback(
    () => () => {
      form.setFieldTouched(field.name, true);
    },
    [],
  );

  const filterBy = useCallback(() => true, []);

  const renderMenuItemChildren = useMemo(
    () =>
      componentProps.props?.options?.menuItemComponent
        ? (option) => (
            <>
              {componentProps.props?.options?.menuItemComponent?.map(
                (item, ix) => {
                  return (
                    <ComponentRender
                      {...item}
                      key={ix}
                      context={componentProps.context}
                      variables={{ ...option }}
                    />
                  );
                },
              )}
            </>
          )
        : undefined,
    [componentProps.props?.options?.menuItemComponent],
  );

  return (
    <AsyncTypeahead
      id={field.name + '-autocomplete-async'}
      disabled={disabled}
      labelKey={componentProps.props?.labelKey ?? 'label'}
      options={items}
      multiple={componentProps.props?.multiple}
      placeholder={componentProps.props?.placeholder}
      minLength={componentProps.props?.minLength ?? 1}
      filterBy={filterBy} // bypass filterBy
      onBlur={onBlur}
      onChange={onChange}
      isLoading={isLoading}
      renderMenuItemChildren={renderMenuItemChildren}
      defaultSelected={selected}
      onSearch={searchBounced}
      allowNew={componentProps.props?.allowNew}
      onInputChange={onInputChange}
      // {...componentProps.props?.options}
    />
  );
};
