import {
  OrganizationConfigDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateOrganizationConfigCommandValues,
  UpdateOrganizationConfigCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrganizationConfigRequest,
  getOrganizationConfigsListRequest,
  getOrganizationConfigRequest,
  deleteOrganizationConfigRequest,
  updateOrganizationConfigRequest,
  importOrganizationConfigsRequest,
  exportOrganizationConfigsRequest,
  GetOrganizationConfigParams,
} from './organizationConfigs.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type OrganizationConfigsStoreState = {
  links: LinkDto[];
  organizationConfigColumns: EntityFieldDto[];
  defaultOrganizationConfigColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getOrganizationConfigsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'OrganizationConfig',
  });
});

addEffectStatusHandling(getOrganizationConfigsColumnsFx);

export const getOrganizationConfigsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'OrganizationConfig',
  });
});

addEffectStatusHandling(getOrganizationConfigsDefaultColumnsFx);

export const updateOrganizationConfigsColumns = createEvent<EntityFieldDto[]>();

export const getOrganizationConfigsFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrganizationConfigsListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getOrganizationConfigsFx);

export const createOrganizationConfigFx = createEffect(
  (organizationConfigData: OrganizationConfigDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createOrganizationConfigCommand: CreateOrganizationConfigCommandValues = {
      ...organizationConfigData,
      organizationId: currentOrganization?.organizationId,
    };

    return createOrganizationConfigRequest(
      currentOrganization!,
      createOrganizationConfigCommand,
    );
  },
);

addEffectStatusHandling(createOrganizationConfigFx, {
  completeMessage: 'Organization config was created successfully',
  errorMessage: 'Organization config was not created',
});

export const getOrganizationConfigFx = createEffect(
  (organizationConfigParams: GetOrganizationConfigParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrganizationConfigRequest(
      currentOrganization as LinkResourceBaseDto,
      organizationConfigParams,
    );
  },
);

addEffectStatusHandling(getOrganizationConfigFx);

export const updateOrganizationConfigFx = createEffect(
  (organizationConfig: OrganizationConfigDto) => {
    const updateOrganizationConfigCommand: UpdateOrganizationConfigCommandValues = {
      ...organizationConfig,
    };
    return updateOrganizationConfigRequest(
      organizationConfig,
      updateOrganizationConfigCommand,
    );
  },
);

addEffectStatusHandling(updateOrganizationConfigFx, {
  completeMessage: 'Organization config was updated successfully',
  errorMessage: 'Organization config was not updated',
});

export const deleteOrganizationConfigFx = createEffect(
  (organizationConfig: OrganizationConfigDto) => {
    return deleteOrganizationConfigRequest(organizationConfig);
  },
);

addEffectStatusHandling(deleteOrganizationConfigFx, {
  completeMessage: 'Organization config was deleted successfully',
  errorMessage: 'Organization config was not deleted',
});

export const exportOrganizationConfigsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportOrganizationConfigsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportOrganizationConfigsFx, {
  completeMessage: 'Organization configs were exported successfully',
  errorMessage: 'Organization configs were not exported',
});

export const importOrganizationConfigsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importOrganizationConfigsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importOrganizationConfigsFx, {
  completeMessage: 'Organization configs were imported successfully',
  errorMessage: 'Organization configs were not imported',
  inFlightMessage: 'Importing organization configs...',
});

const defaultState: OrganizationConfigsStoreState = {
  links: [],
  organizationConfigColumns: [],
  defaultOrganizationConfigColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const organizationConfigStore: Store<OrganizationConfigsStoreState> = createStore(
  defaultState,
)
  .on(getOrganizationConfigsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      organizationConfigColumns: payload.result.items,
      defaultOrganizationConfigColumns: payload.result.items,
    };
  })
  .on(getOrganizationConfigsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultOrganizationConfigColumns: payload.result.items,
    };
  })
  .on(updateOrganizationConfigsColumns, (state, payload) => {
    return { ...state, organizationConfigColumns: payload };
  });
