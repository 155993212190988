import { LinkDto } from '../../models/data.models';
import { execLink } from '../api/api.service';
import { API_ENDPOINT } from '../../properties';

export const GET_DASHBOARD_LINK_KEY = 'get-dashboard';

export interface GetDashboardParams {
  organizationId: number;
}

export const getDashboardRequest = async ({
  organizationId,
}: GetDashboardParams): Promise<any> => {
  if (organizationId) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/dashboard`;
    const getDashboardLink: LinkDto = {
      href: url,
      templated: false,
      type: 'get',
    };
    const result = await execLink(getDashboardLink);
    return result.data;
  }
  return Promise.reject();
};
