import React, { useEffect, useRef, useState } from 'react';
import { RiCameraSwitchLine } from 'react-icons/ri';
import { addMessage } from '../messages.store';

export function TakePhoto({ onChange }) {
  const video = useRef(null);
  const canvas = useRef(null);
  const previewCanvas = useRef(null);
  const [dataUrl, setDataUrl] = useState(null);

  const storedCameraIndex = localStorage.getItem('selectedCamera')
    ? parseInt(localStorage.getItem('selectedCamera'), 10)
    : 0;
  const [currentCameraIndex, setCurrentCameraIndex] = useState(
    storedCameraIndex,
  );
  const [cameras, setCameras] = useState([]);
  const streamRef = useRef(null);

  async function getCamerasList() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const availableCameras = devices.filter(
        (device) => device.kind === 'videoinput',
      );
      return availableCameras;
    } catch (err) {
      console.error('Error opening camera', err);
      addMessage({
        type: 'warning',
        message: 'Camera permission denied. Allow access to camera.',
      });
      return [];
    }
  }

  async function openCamera(index) {
    if (!cameras || !cameras.length) return;

    // stop any active streams in the current video element
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
    }

    const constraints = {
      video: {
        width: { ideal: 1024 },
        height: { ideal: 786 },
        deviceId: cameras[index]?.deviceId
          ? { exact: cameras[index].deviceId }
          : undefined,
      },
      audio: false,
    };

    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia(
        constraints,
      );
      video.current.srcObject = streamRef.current;
      localStorage.setItem('selectedCamera', index.toString());
    } catch (err) {
      console.error('Error opening camera', err);
      addMessage({
        type: 'warning',
        message: 'Camera permission denied. Allow access to camera.',
      });
    }
  }

  async function switchCamera() {
    let nextCameraIndex = 0;
    if (currentCameraIndex < cameras.length - 1) {
      nextCameraIndex = currentCameraIndex + 1;
    }
    setCurrentCameraIndex(nextCameraIndex);
    await openCamera(nextCameraIndex);
  }

  const takePhoto = () => {
    if (canvas.current) {
      // Set both canvas width and height to match video's client dimensions
      canvas.current.width = video.current.videoWidth;
      canvas.current.height = video.current.videoHeight;

      // For the preview canvas, we can use a fixed size or any other size according to the requirement
      previewCanvas.current.width = video.current.clientWidth;
      previewCanvas.current.height = video.current.clientHeight;

      const context = canvas.current.getContext('2d');
      context.drawImage(
        video.current,
        0,
        0,
        canvas.current.width,
        canvas.current.height,
      );

      const previewContext = previewCanvas.current.getContext('2d');
      previewContext.drawImage(
        video.current,
        0,
        0,
        previewCanvas.current.width,
        previewCanvas.current.height,
      );

      setDataUrl(canvas.current.toDataURL('image/jpeg'));
    }
  };

  const clearPhoto = () => {
    setDataUrl(null);
  };
  const applyPhoto = () => {
    onChange(dataUrl);
  };
  useEffect(() => {
    if (!cameras || !cameras.length) {
      getCamerasList().then((cameras) => {
        setCameras(cameras);
      });
    }
  }, []);

  useEffect(() => {
    if (video.current && cameras && cameras.length) {
      openCamera(currentCameraIndex);
    }
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [video, cameras]);

  const handleChangeCamera = async (event) => {
    const selectedCameraIndex = event.target.value;
    setCurrentCameraIndex(selectedCameraIndex);
    await openCamera(selectedCameraIndex);
  };

  return (
    <div>
      <div
        className="position-relative"
        style={{ display: dataUrl ? 'none' : 'block' }}
      >
        <video
          ref={video}
          style={{ width: 408, objectFit: 'contain' }}
          autoPlay
        ></video>
        {cameras.length > 1 && (
          <select
            className="camera-select w100"
            value={currentCameraIndex}
            onChange={handleChangeCamera}
          >
            {cameras.map((camera, index) => (
              <option key={camera.deviceId} value={index}>
                {camera.label || `Camera ${index + 1}`}
              </option>
            ))}
          </select>
        )}

        {cameras.length > 1 ? (
          <button
            style={{ top: 0, left: 0 }}
            className="btn btn-default position-absolute"
            onClick={switchCamera}
          >
            <RiCameraSwitchLine className="h1" />
          </button>
        ) : null}
      </div>
      <canvas
        ref={previewCanvas}
        style={{ display: dataUrl ? 'block' : 'none' }}
      ></canvas>
      <canvas ref={canvas} style={{ display: 'none' }}></canvas>

      <div className="mt-3">
        {dataUrl ? (
          <div className="d-flex">
            <button onClick={clearPhoto} className="btn btn-block btn-default">
              Retake Photo
            </button>
            <button
              onClick={applyPhoto}
              className="btn btn-success btn-block mt-0"
            >
              OK
            </button>
          </div>
        ) : (
          <button onClick={takePhoto} className="btn btn-primary btn-block">
            Take Photo
          </button>
        )}
      </div>
    </div>
  );
}
