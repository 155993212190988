import { Dialog } from '../../common/components/dialog/dialog.component';
import { PackageTypeEdit } from './packageType-edit.component';
import { PackageTypeDto } from '../../../models/data.models';

export type PackageTypeDialogProperties = {
  packageTypeId?: number | null;
  title: string;
};

export class PackageTypeDialog extends Dialog<PackageTypeDialogProperties> {
  constructor(props: PackageTypeDialogProperties) {
    super(props);
    this.onPackageTypeSaved = this.onPackageTypeSaved.bind(this);
  }

  onPackageTypeSaved = (packageType: PackageTypeDto) => {
    this.close(packageType);
  };

  renderContent(): any {
    const { packageTypeId } = this.props as PackageTypeDialogProperties;
    return (
      <PackageTypeEdit
        packageTypeId={packageTypeId}
        onPackageTypeCreated={this.onPackageTypeSaved}
        onPackageTypeUpdated={this.onPackageTypeSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
