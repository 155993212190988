import {
  CurrencyDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  CurrencyDtoPagedResult,
  CreateCurrencyCommandValues,
  UpdateCurrencyCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CURRENCIES_LINK_KEY = 'get-currencies';
export const CREATE_CURRENCY_LINK_KEY = 'create-currency';
export const GET_CURRENCY_LINK_KEY = 'get-currency';
export const UPDATE_CURRENCY_LINK_KEY = 'update-currency';
export const DELETE_CURRENCY_LINK_KEY = 'delete-currency';
export const IMPORT_CURRENCIES_LINK_KEY = 'import-currencies';
export const EXPORT_CURRENCIES_LINK_KEY = 'export-currencies';
export const CURRENCY_ENTITY_NAME = 'Currency';

export interface GetCurrencyParams {
  currencyId: number;
}

export interface ImportCurrenciesParams {
  organizationId: number;
}

export interface ExportCurrenciesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getCurrencyRequest = async (
  resource: LinkResourceBaseDto | null,
  { currencyId }: GetCurrencyParams,
): Promise<CurrencyDto> => {
  if (resource && currencyId) {
    const getCurrencyLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CURRENCY_LINK_KEY,
    );
    if (getCurrencyLink) {
      const result = await execLink(getCurrencyLink, { currencyId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CURRENCY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCurrenciesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CurrencyDtoPagedResult> => {
  if (resource) {
    const getCurrenciesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CURRENCIES_LINK_KEY,
    );
    if (getCurrenciesListLink) {
      const result = await execLink(getCurrenciesListLink, params);
      return result.data;
    }
  }
  console.error(
    `Action ${GET_CURRENCIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCurrencyRequest = async (
  resource: LinkResourceBaseDto,
  currency: CreateCurrencyCommandValues,
) => {
  const createCurrencyLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CURRENCY_LINK_KEY,
  );
  if (createCurrencyLink) {
    const result = await execLink(createCurrencyLink, currency);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CURRENCY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateCurrencyRequest = async (
  resource: LinkResourceBaseDto,
  currency: UpdateCurrencyCommandValues,
) => {
  const updateCurrencyLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CURRENCY_LINK_KEY,
  );
  if (updateCurrencyLink) {
    const result = await execLink(updateCurrencyLink, currency);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CURRENCY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteCurrencyRequest = async (currency: CurrencyDto) => {
  const deleteCurrencyLink = currency.links?.find(
    (x: LinkDto) => x.rel === DELETE_CURRENCY_LINK_KEY,
  );
  if (deleteCurrencyLink) {
    const result = await execLink(deleteCurrencyLink, currency);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CURRENCY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importCurrenciesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportCurrenciesParams,
  file: File,
) => {
  const importCurrenciesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CURRENCIES_LINK_KEY,
  );
  if (importCurrenciesLink) {
    const result = await postFormData(importCurrenciesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CURRENCIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportCurrenciesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportCurrenciesParams,
) => {
  const exportCurrenciesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CURRENCIES_LINK_KEY,
  );
  if (exportCurrenciesLink) {
    const result = await downloadLinkFile(exportCurrenciesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CURRENCIES_LINK_KEY} is not allowed or missing link action`,
  );
};
