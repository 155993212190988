import React, { useState } from 'react';
import { FormContext } from '../form/form.component';
import { Field, FormikProps, FormikValues } from 'formik';
import { clearMessages } from '../../messages.store';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';

export type PlaceAutocompleteInputProps = {
  name?: string;
  header?: string;
  placeholder?: string;
  defaultValue?: string;
  onSelect?: (results: any, context: FormikProps<FormikValues>) => string;
};

export const PlaceAutocompleteInput = ({
  name,
  header,
  placeholder = '',
  defaultValue = '',
  onSelect,
}: PlaceAutocompleteInputProps) => {
  const [stateValue, setStateValue] = useState(defaultValue);
  return (
    <FormContext.Consumer>
      {(context) => (
        <>
          <Field type="hidden" name={name} value={stateValue} />
          <PlacesAutocomplete
            value={stateValue}
            onChange={(address) => {
              setStateValue(address);
              context.setFieldValue(name, address);
            }}
            onSelect={
              onSelect &&
              ((address: string, placeId?: string) => {
                if (placeId) {
                  geocodeByPlaceId(placeId)
                    .then((results) =>
                      setStateValue(onSelect(results[0], context)),
                    )
                    .catch((error) => console.error(error));
                }
              })
            }
            searchOptions={{ language: 'en', offset: 3 }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              return (
                <div className="form-group">
                  <label className="input-label" htmlFor={name}>
                    <span className="input-label-primary">{header}</span>
                  </label>
                  <input
                    {...getInputProps({
                      name,
                      id: name,
                      placeholder,
                      className:
                        'location-search-input form-control form-control-md',
                      onFocus: clearMessages,
                    })}
                  />

                  {suggestions.length > 0 ? (
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              title: suggestion.description,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            }}
          </PlacesAutocomplete>
        </>
      )}
    </FormContext.Consumer>
  );
};
