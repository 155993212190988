import React, { useState } from 'react';
import {
  Column,
  CommodityDto,
  WarehouseLocationDto,
  WarehouseZoneDto,
} from '../../../../models/data.models';
import { getWarehouseZonesFx } from '../../../warehouseInspector/warehouseInspector.store';
import { getWarehouseLocationsFx } from '../../../warehouseLocations/warehouseLocations.store';
import { CommoditySelector } from './commoditySelector.component';
import { getCommodities } from '../../../commodities/commodities.store';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export interface WizardZoneViewProps {
  zoneDto?: WarehouseZoneDto;
  locationDto?: WarehouseLocationDto;
  level: number;
  columns: Column[];
  getStatusClassName: any;
  isSelected: any;
  isCheckBoxDisabled: any;
  setIds: any;
  defaultSelected?: boolean;
}

export const WizardZoneView = ({
  zoneDto = null,
  locationDto = null,
  level,
  columns,
  getStatusClassName,
  isSelected,
  isCheckBoxDisabled,
  setIds,
  defaultSelected = false,
}: WizardZoneViewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [nestedZones, setNestedZones] = useState<WarehouseZoneDto[]>([]);
  const [nestedLocations, setNestedLocations] = useState<
    WarehouseLocationDto[]
  >([]);
  const [locationCommodities, setLocationCommodities] = useState<
    CommodityDto[] | null
  >(null);
  const [isChecked, setIsChecked] = useState(defaultSelected);

  const getNestedZonesData = async (parentZoneId) => {
    if (!isOpen) {
      try {
        const zones = getWarehouseZonesFx({
          filter: `parentZoneId:${parentZoneId}`,
          search: 'include_commodities',
        });
        const locations = getWarehouseLocationsFx({
          filter: `parentZoneId:${parentZoneId}`,
        });
        const result = await Promise.all([zones, locations]);
        setNestedZones(result[0].items);
        setNestedLocations(result[1].items);
      } catch {
        setNestedZones([]);
        setNestedLocations([]);
      } finally {
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
  };

  const getLocationCommodities = async (locationId) => {
    if (!isOpen) {
      try {
        const commodities = getCommodities({
          filter: `warehouseLocationId:${locationId}`,
        });
        const result = await Promise.all([commodities]);
        setLocationCommodities(result[0].items);
      } catch {
        setLocationCommodities([]);
      } finally {
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td
          style={{
            width: `calc(100% / ${
              columns.filter((column) => column.visible === true).length
            })`,
          }}
        >
          <React.Fragment>
            <span
              className="cursor-pointer d-flex align-items-center"
              style={{
                marginLeft: `calc(20px * ${level})`,
              }}
            >
              <label className="custom-control custom-checkbox d-inline-block mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input m-0"
                  onChange={(event) => {
                    if (zoneDto) {
                      setIds(zoneDto?.commodities, event?.target?.checked);
                    }
                    if (locationDto) {
                      setIds(locationDto?.commodities, event?.target?.checked);
                    }
                  }}
                  checked={
                    (zoneDto && isSelected(zoneDto?.commodities)) ||
                    (locationDto && isSelected(locationDto?.commodities))
                  }
                />
                <span className="custom-control-label"></span>
              </label>

              <button
                type={'button'}
                className="btn m-0 pl-3 pr-3 pb-2 pt-0 d-flex align-items-center border-0 shadow-none"
                onClick={() => {
                  if (zoneDto) getNestedZonesData(zoneDto.warehouseZoneId);
                  else if (locationDto)
                    getLocationCommodities(locationDto.warehouseLocationId);
                }}
              >
                {isOpen ? (
                  <FaChevronUp color="#16467D" />
                ) : (
                  <FaChevronDown color="#16467D" />
                )}

                <p className={'m-0 ml-2 no-select'}>
                  {zoneDto?.name ?? locationDto?.description}
                </p>
              </button>
            </span>
          </React.Fragment>
        </td>
        <td
          style={{
            width: `calc(100% / ${
              columns.filter((column) => column.visible === true).length
            })`,
          }}
        >
          {zoneDto?.customerName ?? locationDto?.customerName ?? ''}
        </td>
        <td></td>
      </tr>
      {isOpen && (
        <>
          {zoneDto &&
            nestedZones?.map((zone) => (
              <WizardZoneView
                zoneDto={zone}
                level={level + 1}
                columns={columns}
                getStatusClassName={getStatusClassName}
                isSelected={isSelected}
                isCheckBoxDisabled={isCheckBoxDisabled}
                setIds={setIds}
                defaultSelected={isChecked}
              />
            ))}
          {zoneDto &&
            nestedLocations.map((location) => (
              <WizardZoneView
                locationDto={location}
                level={level + 1}
                columns={columns}
                getStatusClassName={getStatusClassName}
                isSelected={isSelected}
                isCheckBoxDisabled={isCheckBoxDisabled}
                setIds={setIds}
                defaultSelected={isChecked}
              />
            ))}

          {locationDto &&
            locationCommodities &&
            locationCommodities.length > 0 && (
              <tr>
                <td colSpan={columns?.length + 1}>
                  {locationCommodities?.map((commodity: CommodityDto) => (
                    <CommoditySelector
                      commodity={commodity}
                      columns={columns}
                      getStatusClassName={getStatusClassName}
                      isSelected={isSelected}
                      isCheckBoxDisabled={isCheckBoxDisabled}
                      setIds={setIds}
                      level={level + 1}
                    ></CommoditySelector>
                  ))}
                </td>
              </tr>
            )}
        </>
      )}
    </React.Fragment>
  );
};
