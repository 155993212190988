import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';

export const CountryForm = ({
  id = 'countryForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

CountryForm.CountryCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'countryCode'}
      label={'Country Code'}
      placeholder={'Enter Country Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};
CountryForm.Name = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Country Name'}
      placeholder={'Enter Country Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CountryForm.CustomFields = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      isSending={isSending}
      saveButtonRenderCondition={saveButtonRenderCondition}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
