import { Dialog } from '../../common/components/dialog/dialog.component';
import { ContactDto, ContactType } from '../../../models/data.models';
import { MultiTypeContactEdit } from './multitype-contact-edit.component';

export type ContactDialogProperties = {
  contactId?: number | null;
  title: string;
  contactTypes: ContactType[];
};

export class MultiTypeContactDialog extends Dialog<ContactDialogProperties> {
  constructor(props: ContactDialogProperties) {
    super(props);
    this.onContactSaved = this.onContactSaved.bind(this);
  }

  onContactSaved = (contact: ContactDto) => {
    this.close(contact);
  };

  renderContent(): any {
    const { contactId, contactTypes } = this.props as ContactDialogProperties;
    return (
      <MultiTypeContactEdit
        contactId={contactId}
        contactTypes={contactTypes}
        onContactCreated={this.onContactSaved}
        onContactUpdated={this.onContactSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
