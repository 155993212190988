import React, { CSSProperties, useEffect, useState } from 'react';
import { BsListTask, BsFilter } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import { Pagination } from '../pagination/pagination.component';
import {
  getAdditionalColumnsData,
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import {
  Column,
  CustomFieldType,
  IDNumberType,
} from '../../../../models/data.models';
import { Dropdown } from 'react-bootstrap';
import { unescapeString } from '../../../../utils/helper.utils';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

export type ContactsPaymentMethodGridProps = {
  contactType?: string;
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: any[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  disableDots?: boolean;
  isTab?: boolean;
  parentId?: number;
  addButtonRenderCondition?: boolean;
  showSortColumns?: boolean;
};

export const ContactPaymentMethodsGrid = ({
  contactType,
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onColumnsChanged = () => {},
  onSort = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
  loading,
  showSortColumns = true,
}: ContactsPaymentMethodGridProps) => {
  const colByName: { [key: string]: any } = {};

  const [addData, setAddData] = useState({});

  useEffect(() => {
    const filterParts = new URLSearchParams(window.location.search)
      .get('filter')
      ?.split(' AND ');

    let accountNumber = filterParts
      ?.find((x) => x.includes('accountNumber:'))
      ?.split(':')[1];

    if (accountNumber) {
      accountNumber = unescapeString(accountNumber, false);
    }

    let idNumber = filterParts
      ?.find((x) => x.includes('idNumber:'))
      ?.split(':')[1];

    if (idNumber) {
      idNumber = unescapeString(idNumber, false);
    }

    const idNumberType = filterParts
      ?.find((x) => x.includes('idNumberType:'))
      ?.split(':')[1];

    const customFieldsNames = columns
      ?.filter((column) => column.type === 'customField')
      .map((column) => column.name);

    const customFieldsValues = customFieldsNames
      .map((name) => {
        return filterParts?.find((x) => x.includes(name))?.split(':')[1];
      })
      .filter((field) => field !== null && field !== undefined)
      .map((field) => {
        return unescapeString(field, true);
      });

    let customFields = {};
    for (let i = 0; i < customFieldsValues.length; i++) {
      customFields[`__${customFieldsNames[i]}`] = customFieldsValues[i];
    }

    setAddData({
      ...addData,
      __accountNumber: accountNumber ?? '',
      __idNumber: idNumber ?? '',
      __idNumberType: idNumberType ? IDNumberType[idNumberType] : null,
      ...customFields,
    });
  }, [columns]);

  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return onColumnsChanged(columns);
  };

  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    filters['contactType'] = contactType;
    (document.querySelector(
      '.filter-dropdown .dropdown-toggle',
    ) as HTMLSpanElement).click();
    setAddData(getAdditionalColumnsData(filters));
    return onFilter(getSearchQuery(filters, columns));
  };
  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) =>
      columns.some((column) => column.showFilter) && (
        <span
          className="dropdown-toggle pointer"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          <BsFilter />
          &nbsp;
          <small className="align-middle pl-1 text-uppercase">Filters</small>
          &nbsp;
          <small className="align-middle text-primary">
            {getReadableQueryString(filter, getFilteredRowsFilter(), addData)}
          </small>
        </span>
      ),
  );

  return (
    <div className={`grid ${className}`} style={style}>
      <div
        className={
          isDropDownList === true
            ? 'grid-toolbar d-flex flex-wrap px-3 mx-3 justify-content-center'
            : 'grid-toolbar d-flex pl-3 mx-3'
        }
      >
        {onSearch ? (
          <input
            type="search"
            className={
              (isDropDownList === true ? 'w-100 col-12 ' : ' ') +
              'form-control my-2'
            }
            placeholder="Search"
            value={decodeURIComponent(search)}
            onChange={updateSearch}
          />
        ) : null}
        {onFilter ? (
          <div className="my-3 px-3 w-100 filter-dropdown">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
                  <div className="px-5" style={{ minHeight: '300px' }}>
                    <div className="filters-form-block">
                      <Filters
                        query={filter}
                        columns={getFilteredRowsFilter()}
                        onFilter={updateFilter}
                        addData={addData}
                      />
                    </div>
                  </div>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
        {hideColumnsSelect ? null : (
          <div
            className={
              isDropDownList === true
                ? 'dropdown dropdown-columns my-3 px-3 mx-auto pointer col-auto'
                : 'dropdown dropdown-columns my-3 px-3 ml-auto pointer'
            }
          >
            <div
              className="dropdown-toggle"
              id="dropdownColumnsButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsListTask className="" />
              &nbsp;
              <small className="align-middle pl-1 text-uppercase">
                Columns
              </small>
            </div>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownColumnsButton"
              style={{ width: 'max-content', maxWidth: 'unset' }}
            >
              {columns
                .filter((item) => {
                  if (showAllStore) {
                    return true;
                  }
                  return rowKeys?.includes(item.name);
                })
                .map((col) => {
                  return (
                    <label key={col.name} className="dropdown-item pointer">
                      <input
                        type="checkbox"
                        onChange={() => updateCols(col.name)}
                        defaultChecked={col.visible}
                      />{' '}
                      {col.title}
                    </label>
                  );
                })}
            </div>
          </div>
        )}
        {actionBar && <div className={'mr-3 py-2'}>{actionBar}</div>}
      </div>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white mx-3">
          <table className="table">
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th
                        scope="col"
                        key={col.name}
                        style={
                          col.name === 'currencySymbol'
                            ? { textAlign: 'center' }
                            : {}
                        }
                      >
                        <a
                          className={
                            col.sortName && showSortColumns
                              ? 'link'
                              : 'inactive-link'
                          }
                          onClick={() =>
                            col.sortName ? updateSort(col.name) : null
                          }
                        >
                          {col.title}
                          {sort === col.name || sort === col.sortName ? (
                            <FaArrowDown />
                          ) : null}
                          {sort === '-' + col.name ||
                          sort === '-' + col.sortName ? (
                            <FaArrowUp />
                          ) : null}
                        </a>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="m-5 text-center">
                  <h3 className="text-muted mb-4">Loading...</h3>
                </div>
              ) : (
                data.map((row, index) => {
                  return (
                    <tr
                      key={
                        rowKeys ? rowKeys?.map((x) => row[x]).join('_') : index
                      }
                    >
                      {Object.values(columns)
                        .filter((item) => {
                          if (showAllStore && item.visible) {
                            return true;
                          }
                          return item.visible && rowKeys?.includes(item.name);
                        })
                        .map((item, index) => {
                          return (
                            <td
                              key={`${rowKeys?.map((x) => row[x]).join('_')}_${
                                item.name
                              }`}
                              onClick={() => {
                                if (onSelect) {
                                  onSelect(
                                    row,
                                    rowKeys?.reduce((keyObj, field) => {
                                      return row[field];
                                    }),
                                  );
                                }
                              }}
                              className={'cursor-pointer'}
                              style={{
                                width: `calc(100% / ${
                                  columns.filter(
                                    (column) => column.visible === true,
                                  ).length
                                })`,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {typeof row[item.name] === 'boolean' ||
                              columns?.find(
                                (column) =>
                                  column.name === item.name &&
                                  column.type === 'customField' &&
                                  column.customFieldType ===
                                    CustomFieldType.Boolean,
                              ) ? (
                                row[item.name] === true ||
                                row[item.name] === 'true' ? (
                                  <>&#x2713;</>
                                ) : (
                                  <></>
                                )
                              ) : item.name == 'created' ||
                                item.name == 'lastModified' ||
                                item.name == 'updated' ||
                                columns?.find(
                                  (column) =>
                                    column.name === item.name &&
                                    column.type === 'customField' &&
                                    column.customFieldType ===
                                      CustomFieldType.Date,
                                ) ? (
                                getFormattedDate(row[item.name], false)
                              ) : (
                                <>{row[item.name]}</>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > limit && showPagination === true ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
