import { Dialog } from '../../common/components/dialog/dialog.component';
import { PortEdit } from './port-edit.component';
import { PortDto } from '../../../models/data.models';

export type PortDialogProperties = {
  portId?: string | null;
  countryCode?: string | null;
  title: string;
};

export class PortDialog extends Dialog<PortDialogProperties> {
  constructor(props: PortDialogProperties) {
    super(props);
    this.onPortSaved = this.onPortSaved.bind(this);
  }

  onPortSaved = (port: PortDto) => {
    this.close(port);
  };

  onPortDeleted = (port: PortDto) => {
    this.close(port);
  };

  onCancel = () => {
    this.close();
  };

  renderContent(): any {
    const { portId, countryCode } = this.props as PortDialogProperties;
    return (
      <PortEdit
        portId={portId}
        countryCode={countryCode}
        onPortCreated={this.onPortSaved}
        onPortUpdated={this.onPortSaved}
        onDelete={this.onPortDeleted}
        onCancel={this.onCancel}
      />
    );
  }
}
