import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { SalesPersonListScreen } from './screens/salesPerson-list.screen';
import { SalesPersonScreen } from './screens/salesPerson.screen';

export const SALES_PERSONS_LIST_PATH = '/sales-persons';
export const SALES_PERSONS_EDIT_PATH = '/sales-persons/:contactId';
export const SALES_PERSONS_CREATE_PATH = '/sales-persons/0';

export const SalesPersonsRoute = (props: RouteProps) => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <SalesPersonListScreen pageTitle="Sales Persons" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <SalesPersonScreen pageTitle="Add New Sales Person" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <SalesPersonScreen pageTitle="Update Sales Person" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
