import { Field } from 'formik';
import { InputPublicProps } from './input.component';

const FormikTextEditor = ({ field, form, ...props }) => {
  return (
    <div className={`form-group ${props.className}`} style={props.style}>
      <label htmlFor={props.name} className="input-label">
        <span className="input-label-title">
          {props.required && <span className="text-danger">*</span>}
        </span>
        {props.label || props.displayName || field.name}
      </label>
      <input {...field} {...props} className="form-control" />
      {form.errors[field.name] && form.touched[field.name] ? (
        <div className="text-danger">{form.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export const TextField = (props: InputPublicProps) => {
  return <Field {...props} component={FormikTextEditor} />;
};
