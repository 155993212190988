import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ContactStatusForm } from './contactStatus.form';
import {
  ContactStatusDto,
  ContactType,
  ContactStatusStage,
} from '../../../models/data.models';
import {
  createContactStatusFx,
  getContactStatusFx,
  updateContactStatusFx,
} from '../contactStatuses.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';

export type ContactStatusEditProps = {
  statusId?: number | null;
  onContactStatusCreated?: (contactStatus: ContactStatusDto) => void;
  onContactStatusUpdated?: (contactStatus: ContactStatusDto) => void;
  onContactStatusLoaded?: (contactStatus: ContactStatusDto) => void;
  onCancel?: () => void;
};

const initialState: ContactStatusDto = {
  statusId: 0,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  statusDescription: null,
  statusName: '',
  contactType: ContactType.Customer,
  organizationId: 0,
  priority: 0,
  statusStage: ContactStatusStage.Active,
  links: [],
};

const contactStatusSchema = Yup.object().shape({
  statusName: Yup.string()
    .required("Can't be blank")
    .max(50, 'Max length is 100')
    .nullable(true),
  statusDescription: Yup.string().max(255, 'Max length is 255').nullable(true),
  priority: Yup.number().typeError('Priority must be a number').nullable(true),
});

export const ContactStatusEdit = ({
  statusId,
  onContactStatusLoaded = () => {},
  onContactStatusCreated = () => {},
  onContactStatusUpdated = () => {},
  onCancel = () => {},
}: ContactStatusEditProps) => {
  const isCreateMode = !statusId || statusId === 0;
  const [isLoading, setIsLoading] = useState(true);
  const [contactStatus, setContactStatus] = useState<ContactStatusDto | null>(
    null,
  );
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (statusId) {
      getContactStatusFx({ statusId }).then(
        (contactStatusDto: ContactStatusDto) => {
          setContactStatus(contactStatusDto);
          setIsLoading(false);
          onContactStatusLoaded(contactStatusDto);
        },
      );
    } else {
      throw new Error('ContactStatus keys were not provided');
    }
  }, [statusId]);

  const onSubmit = (data: ContactStatusDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createContactStatusFx(data)
        .then((result) => {
          onContactStatusCreated(result.data);
        })
        .finally(() => setIsSending(false));
    } else {
      updateContactStatusFx(data)
        .then((result) => {
          onContactStatusUpdated(result.data);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <div className="row">
        <div className="col-6">
          <ContactStatusForm
            initialValues={contactStatus || initialState}
            onSubmit={onSubmit}
            id={'contactStatusForm'}
            validationSchema={contactStatusSchema}
          >
            <ContactStatusForm.StatusName />
            <ContactStatusForm.StatusDescription />
            <ContactStatusForm.ContactType />
            <ContactStatusForm.Priority />
            <ContactStatusForm.StatusStage />
            <div className="d-flex justify-content-end row">
              <div className="col-8">
                <Button
                  name="save-contactStatus"
                  type="submit"
                  color="primary"
                  className="btn-block"
                  form="contactStatusForm"
                >
                  Save Contact Status
                </Button>
              </div>
              <div className="col-4">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </ContactStatusForm>
        </div>
      </div>
    </Panel>
  );
};
