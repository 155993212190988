import { LinkDto, EntityFieldDto } from '../../models/data.models';
import { createStore, Store, createEvent, createEffect } from 'effector';
import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { organizationsStore } from '../organization/organization.store';

type orderStoreState = {
  links: LinkDto[];
  orderColumns: EntityFieldDto[];
  defaultColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getOrdersColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'OceanShipment',
  });
});

export const getOrdersDefaultColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'OceanShipment',
  });
});

export const updateOceanOrdersColumns = createEvent<EntityFieldDto[]>();

const defaultState: orderStoreState = {
  links: [],
  orderColumns: [],
  defaultColumns: [],
  defaultSort: '-orderNumber',
  defaultLimit: 20,
};

export const oceanShipmentOrdersStore: Store<orderStoreState> = createStore(
  defaultState,
)
  .on(getOrdersColumns.done, (state, payload) => {
    return {
      ...state,
      orderColumns: payload.result.items,
      defaultColumns: payload.result.items,
    };
  })
  .on(getOrdersDefaultColumns.done, (state, payload) => {
    return {
      ...state,
      defaultColumns: payload.result.items,
    };
  })
  .on(updateOceanOrdersColumns, (state, payload) => {
    return { ...state, orderColumns: payload };
  });
