import React from 'react';
import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';

export const HeaderComponent: React.FC<ComponentProps> = ({
  props,
  children,
}) => {
  const { className, title, subtitle } = props || {};

  const headerClassName = `col-12 ${className || ''}`.trim();

  return (
    <div className={headerClassName}>
      <h3>{title}</h3>
      {subtitle && (
        <>
          <hr />
          <h4>{subtitle}</h4>
        </>
      )}
      {children?.map((child, index) => (
        <ComponentRender
          key={index}
          {...child}
          context={props.context}
          variables={props.variables}
        />
      ))}
    </div>
  );
};
