import { Dialog } from '../../common/components/dialog/dialog.component';

export type AttachmentPreviewDialogProperties = {
  url: string;
  title: string;
  mainModalClassName: string;
};

export class AttachmentPreviewDialog extends Dialog<AttachmentPreviewDialogProperties> {
  constructor(props: AttachmentPreviewDialogProperties) {
    super(props);
  }

  renderContent(): any {
    const { url } = this.props as AttachmentPreviewDialogProperties;
    return (
      <div className="d-flex justify-content-center align-items-center">
        <img
          className={'w-auto'}
          alt="attachment-preview"
          src={url}
          style={{ maxWidth: '100%' }}
        />
      </div>
    );
  }
}
