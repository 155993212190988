import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { OrderForm } from '../../orders/components/order.form';
import { OrderEntitiesForm } from './form-items/orderEntitiesForm.component';
import { DeliveryOrderEntitiesForm } from './form-items/deliveryOrderEntitiesForm.component';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { IAction, getAction } from '../../common/components/actions/actions';

import {
  AttachmentParentType,
  ChargeDto,
  ChargeStatuses,
  ChargeType,
  CommodityDto,
  CommodityStatuses,
  ContactDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
  DimensionsUnit,
  DivisionDto,
  DocumentTemplateType,
  EntityTypes,
  OrderDto,
  OrderEntityDto,
  OrderStatusDto,
  OrderTypes,
  PackageTypeDto,
  PaidAs,
  StatusStage,
  TrackingEventDto,
  VisibleTransactions,
  VolumeUnit,
  WeightUnit,
  WorkflowTriggerDtoListResult,
} from '../../../models/data.models';
import { Prompt } from '../../common/components/prompt/prompt.component';
import { createOrder, getOrder, updateOrder } from '../../orders/orders.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CommodityForm } from '../../commodities/components/commodity.form';
import { organizationsStore } from '../../organization/organization.store';
import { CommoditiesForOrderList } from '../../commodities/components/commodities-forOrder-list.component';
import {
  FormattedProfitValues,
  OrderEditFormDto,
  ProfitValues,
} from '../../../models/custom.models';
import { GetCommodityParams } from '../../commodities/commodities.service';
import { showDialog } from '../../common/dialog.store';
import { CommodityDialog } from '../../commodities/components/commodity.dialog';
import { ChargeForOrderList } from '../../charges/components/charge-forOrder-list.component';
import {
  CREATE_CHARGE_LINK_KEY,
  GetChargeParams,
} from '../../charges/charges.service';
import { ChargeDialog } from '../../charges/components/charge.dialog';
import { ChargeForm } from '../../charges/components/charge.form';
import { FormContext } from '../../common/components/form/form.component';
import { SendEmailDialog } from '../../sendEmail/components/sendEmail.dialog';
import {
  countTotalContainerCommodityDeclaredValue,
  countTotalContainerCommodityWeight,
  generateValidationSchemaWithCustomFields,
  getEnumKeyByValue,
  getPaidAs,
  validateNumberInput,
  validatePositiveNumberInput,
} from '../../../utils/helper.utils';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import {
  getDocumentTemplatesFx,
  getRenderedDocumentLink,
} from '../../documentTemplates/documentTemplates.store';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import {
  ChargeDefaultValues,
  CommodityDefaultValues,
  OrderDefaultValues,
  ProfitDefaultValues,
} from '../../common/DefaultValues';
import * as Yup from 'yup';
import { getFormattedPrice } from '../../../utils/formatting.utils';
import { currencyStore } from '../../currencies/currencies.store';
import { getContacts } from '../../contacts/contacts.store';
import { authStore, userHas } from '../../auth/auth.store';
import { FormikProps, FormikValues } from 'formik';
import { getEventDefinitionFx } from '../../eventDefinitions/eventDefinitions.store';
import { CustomFieldsLayout } from '../../common/components/form/customFields-layout.component';
import { TrackingEventForOrderList } from '../../trackingEvents/components/trackingEvents-forOrder-list.component';
import { GetTrackingEventParams } from '../../trackingEvents/trackingEvents.service';
import { TrackingEventDialog } from '../../trackingEvents/components/trackingEvent.dialog';
import { ConfirmationDialog } from '../../common/components/confirmation-dialog/confirmation.dialog';
import { getUnInvoicedOrders } from '../../invoiceManager/invoiceManager.store';
import { SelectContactDialog } from '../../contacts/components/select-contact.dialog';
import { GenerateInvoiceDialog } from '../../invoiceManager/components/generateInvoice.dialog';
import { addMessage, Message } from '../../common/messages.store';
import { OrderDocumentsFragment } from '../../orderDocuments/components/orderDocumentsFragment';
import { AiFillCode } from 'react-icons/ai';
import { WorkflowTriggerDialog } from '../../workflowTriggers/components/workflowTrigger.dialog';
import { EXECUTE_WORKFLOWTRIGGER_LINK_KEY } from '../../workflowTriggers/workflowTriggers.service';
import { getWorkflowTriggersFx } from '../../workflowTriggers/workflowTriggers.store';

export type OceanOrderEditProps = {
  orderId: number | null;
  onOceanOrderCreated?: (oceanOrder: OrderDto) => void;
  onOceanOrderUpdated?: (oceanOrder: OrderDto) => void;
  onOceanOrderLoaded?: (oceanOrder: OrderDto) => void;
  onCancel?: () => void;
  onInvoiceGenerated?: () => void;
};

const getInitialStateOrderEntities = (
  contactType: ContactType,
  entityType: EntityTypes,
) => {
  const initialStateOrderCarrier: OrderEntityDto = {
    orderEntityId: 0,
    contactAddressId: null,
    contactAddressName: null,
    contactId: null,
    contactName: null,
    contactType,
    created: null,
    createdBy: null,
    date: null,
    notes: '',
    lastModified: null,
    lastModifiedBy: null,
    orderEntitySequence: 0,
    orderId: 0,
    contactCityName: null,
    contactStateCode: null,
    entityType,
    customValues: {},
    links: [],
  };
  return initialStateOrderCarrier;
};

// initial state
const initialStateCommodity: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  commodityTypeId: null,
  commodityTypeCode: CommodityDefaultValues.description,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  customValues: CommodityDefaultValues.customValues,
  valueByTotal: CommodityDefaultValues.valueByTotal,
};

const initialProfitValues: ProfitValues = {
  expense: ProfitDefaultValues.expense,
  income: ProfitDefaultValues.income,
  profit: ProfitDefaultValues.profit,
};

const getInitialState = () => {
  const initialState: OrderEditFormDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: null,
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntityCarriers: [
      getInitialStateOrderEntities(ContactType.Carrier, EntityTypes.Carrier),
    ],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    orderEntities: [],
    links: [],
    customValues: {},
    shipper: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Shipper,
    ),
    consignee: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Consignee,
    ),
    ultimateConsignee: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.UltimateConsignee,
    ),
    notifyParty: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.NotifyParty,
    ),
    intermediate: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Intermediate,
    ),
    forwardingAgent: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.ForwardingAgent,
    ),
    destinationAgent: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.DestinationAgent,
    ),
    deliveringCarrier: getInitialStateOrderEntities(
      ContactType.Carrier,
      EntityTypes.DeliveryCarrier,
    ),
    pickupFrom: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.PickupFrom,
    ),
    deliverTo: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.DeliverTo,
    ),
    receivedBy: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.ReceivedBy,
    ),
  };
  return initialState;
};

// validation
let oceanOrderSchema = Yup.object().shape({
  orderStatusId: Yup.number().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

const numberSchema = Yup.string()
  .transform((value) => (value === null ? '' : value))
  .test('numberFormat', 'Incorrect number format', (value) => {
    return (
      (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
        value?.toString(),
      ) &&
        Number(value) < Number.MAX_SAFE_INTEGER &&
        Number(value) > Number.MIN_SAFE_INTEGER) ||
      value === '' ||
      value === undefined
    );
  })
  .test('length', 'Max value is 999999', (value) => {
    return value === undefined || Number(value) <= 999999;
  })
  .nullable(true);

const commoditySchema = Yup.object().shape({
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
});

// component
export const OceanShipmentOrderEdit = ({
  orderId = 0,
  onOceanOrderLoaded = () => {},
  onOceanOrderCreated = () => {},
  onOceanOrderUpdated = () => {},
  onCancel = () => {},
  onInvoiceGenerated = () => {},
}: OceanOrderEditProps) => {
  const { user: currentUser } = authStore.getState();
  const [actions, setActions] = useState([]);
  const limit = 20;
  const isCreateMode = !orderId || orderId === 0;

  const commodityFormRef = useRef<HTMLFormElement>();
  const orderFormRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const forceUpdate: () => void = useState()[1].bind(null, {});

  const [
    oceanOrderContextFormValue,
    setOceanOrderContextFormValue,
  ] = useState<OrderEditFormDto | null>(null);

  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    getInitialState(),
  );

  const [
    applyToContactPaidAs,
    setApplyToContactPaidAs,
  ] = useState<PaidAs | null>(null);

  const [
    applyToCarrierPaidAs,
    setApplyToCarrierPaidAs,
  ] = useState<PaidAs | null>(null);

  const [commodity, setCommodity] = useState<CommodityDto | null>(null);

  const [packageType, setPackageType] = useState<{
    packageTypeId: string;
    name: string;
  }>(null);

  const [
    profitValues,
    setProfitValues,
  ] = useState<FormattedProfitValues | null>(null);

  const [commodityType, setCommodityType] = useState<{
    commodityTypeId: number;
    code: string;
  }>(null);

  const [commodities, setCommodities] = useState<CommodityDto[] | null>([]);

  const [fakeCommodityIndex, setFakeCommodityIndex] = useState<number>(null);

  const [charges, setCharges] = useState<ChargeDto[] | null>([]);

  const [trackingEvents, setTrackingEvents] = useState<
    TrackingEventDto[] | null
  >([]);

  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [systemCustomFields, setSystemCustomFields] = useState<
    CustomFieldDto[]
  >([]);

  const [
    oceanOrderDocumentTemplatesCount,
    setOceanOrderDocumentTemplatesCount,
  ] = useState<number>(0);

  const isOrgAdminOrOperation: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;

  const isOrgAdminOrAccounting: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInAccountingRole;

  // fill carriers and vendors and single entities
  const parseOrderEntities = (
    oceanOrderContextFormValueDto: OrderEditFormDto,
  ): void => {
    const carriers: OrderEntityDto[] = [];
    oceanOrderContextFormValueDto.orderEntities.forEach(
      (entity: OrderEntityDto) => {
        switch (entity.entityType) {
          case EntityTypes.Shipper:
            oceanOrderContextFormValueDto.shipper = entity;
            break;
          case EntityTypes.UltimateConsignee:
            oceanOrderContextFormValueDto.ultimateConsignee = entity;
            break;
          case EntityTypes.Consignee:
            oceanOrderContextFormValueDto.consignee = entity;
            break;
          case EntityTypes.NotifyParty:
            oceanOrderContextFormValueDto.notifyParty = entity;
            break;
          case EntityTypes.Intermediate:
            oceanOrderContextFormValueDto.intermediate = entity;
            break;
          case EntityTypes.ForwardingAgent:
            oceanOrderContextFormValueDto.forwardingAgent = entity;
            break;
          case EntityTypes.DestinationAgent:
            oceanOrderContextFormValueDto.destinationAgent = entity;
            break;
          case EntityTypes.DeliveryCarrier:
            oceanOrderContextFormValueDto.deliveringCarrier = entity;
            break;
          case EntityTypes.PickupFrom:
            oceanOrderContextFormValueDto.pickupFrom = entity;
            break;
          case EntityTypes.DeliverTo:
            oceanOrderContextFormValueDto.deliverTo = entity;
            break;
          case EntityTypes.ReceivedBy:
            oceanOrderContextFormValueDto.receivedBy = entity;
            break;
        }
      },
    );
    if (carriers.length > 0)
      oceanOrderContextFormValueDto.orderEntityCarriers = [...carriers];
  };

  // document templates
  useEffect(() => {
    getDocumentTemplatesFx({
      filter: `documentTemplateType:QuoteConfirmationDocument AND isInactive: false`,
    }).then((documentTemplates) => {
      setOceanOrderDocumentTemplatesCount(documentTemplates.items?.length);
    });
  }, []);

  const documentTemplateFilter =
    'documentTemplateType:BillOfLadingDocument OR documentTemplateType:ExportManifestDocument OR  documentTemplateType:MasterBillOfLadingDocument OR documentTemplateType:DockReceiptDocument OR documentTemplateType:ExportManifestExcelDocument';

  // profitValues
  useEffect(() => {
    initialProfitValues.profit =
      initialProfitValues.income - initialProfitValues.expense;
    const income = getFormattedPrice(
      initialProfitValues.income,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const expense = getFormattedPrice(
      initialProfitValues.expense,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const profit = getFormattedPrice(
      initialProfitValues.profit,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    setProfitValues({ income, expense, profit });
    setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
      oceanOrderContextFormValueDto = getInitialState();
      return { ...oceanOrderContextFormValueDto };
    });
  }, []);

  // oceanOrder custom fields and contacts
  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.OceanShipmentOrder} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        oceanOrderSchema = generateValidationSchemaWithCustomFields(
          customFields,
          oceanOrderSchema,
        );
        setCustomFields(
          customFields.filter((field) => field.isSystem === false),
        );
        setSystemCustomFields(
          customFields.filter((field) => field.isSystem === true),
        );
      },
      () => {},
    );

    getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
      (result) => {
        const defaultDispatcher = result.items.find(
          (employee) => employee.userEmployee?.userId === currentUser?.userId,
        );
        setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
          oceanOrderContextFormValueDto.employeeContactName =
            defaultDispatcher?.name;
          oceanOrderContextFormValueDto.employeeContactId =
            defaultDispatcher?.contactId;
          setInitialValues({ ...oceanOrderContextFormValueDto });
          return { ...oceanOrderContextFormValueDto };
        });
      },
      () => {},
    );

    if (isCreateMode) {
      setFakeCommodityIndex(0);
      setIsLoading(false);
    } else if (orderId) {
      getOrder({ orderId }).then(
        (orderDto: OrderDto) => {
          if (orderDto.trackingEvents) {
            orderDto.trackingEvents.forEach((trackingEvent) => {
              if (trackingEvent.eventDefinitionId) {
                getEventDefinitionFx({
                  eventDefinitionId: trackingEvent.eventDefinitionId,
                }).then(
                  (eventDefinition) =>
                    (trackingEvent.eventName = eventDefinition.eventName),
                );
              }
            });
          }

          setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
            if (!oceanOrderContextFormValueDto) {
              oceanOrderContextFormValueDto = getInitialState();
            }
            oceanOrderContextFormValueDto.orderId = orderDto.orderId;
            oceanOrderContextFormValueDto.orderStatus = orderDto.orderStatus;
            oceanOrderContextFormValueDto.orderStatusId =
              orderDto.orderStatusId;
            oceanOrderContextFormValueDto.orderNumber = orderDto.orderNumber;
            orderDto.charges.forEach((chargeDto) => {
              if (chargeDto.chargeType === ChargeType.IncomeCharge) {
                chargeDto.income = chargeDto.amount;
              } else {
                chargeDto.expense = chargeDto.amount;
              }
            });
            oceanOrderContextFormValueDto.charges = orderDto.charges;
            oceanOrderContextFormValueDto.trackingEvents =
              orderDto.trackingEvents;

            oceanOrderContextFormValueDto.billToContactId =
              orderDto.billToContactId;
            oceanOrderContextFormValueDto.billToContactName =
              orderDto.billToContactName;
            oceanOrderContextFormValueDto.billToContactType =
              orderDto.billToContactType;
            oceanOrderContextFormValueDto.carrierContactId =
              orderDto.carriers[0]?.contactId;
            oceanOrderContextFormValueDto.carrierContactName =
              orderDto.carriers[0]?.name;
            oceanOrderContextFormValueDto.commodities = orderDto.commodities;
            oceanOrderContextFormValueDto.commodities.map((commodity) => {
              commodity.commodityStatus = commodity?.commodityStatus;
            });
            oceanOrderContextFormValueDto.weighTotal = orderDto.weighTotal;
            oceanOrderContextFormValueDto.volumeTotal = orderDto.volumeTotal;
            oceanOrderContextFormValueDto.totalPcsCrt = orderDto.totalPcsCrt;
            oceanOrderContextFormValueDto.salespersonContactName =
              orderDto.salespersonContactName;
            oceanOrderContextFormValueDto.salespersonContactId =
              orderDto.salespersonContactId;
            oceanOrderContextFormValueDto.organizationId =
              orderDto.organizationId;

            oceanOrderContextFormValueDto.links = orderDto.links;
            oceanOrderContextFormValueDto.lastModifiedBy =
              orderDto.lastModifiedBy;
            oceanOrderContextFormValueDto.lastModified = orderDto.lastModified;
            if (orderDto.employeeContactName && orderDto.employeeContactId) {
              oceanOrderContextFormValueDto.employeeContactName =
                orderDto.employeeContactName;
              oceanOrderContextFormValueDto.employeeContactId =
                orderDto.employeeContactId;
            }
            oceanOrderContextFormValueDto.divisionName = orderDto.divisionName;
            oceanOrderContextFormValueDto.divisionId = orderDto.divisionId;
            oceanOrderContextFormValueDto.equipmentTypeId =
              orderDto.equipmentTypeId;
            oceanOrderContextFormValueDto.equipmentTypeName =
              orderDto.equipmentTypeName;
            oceanOrderContextFormValueDto.createdBy = orderDto.createdBy;
            oceanOrderContextFormValueDto.created = orderDto.created;
            oceanOrderContextFormValueDto.carriers = orderDto.carriers;
            oceanOrderContextFormValueDto.customValues = orderDto.customValues;
            oceanOrderContextFormValueDto.trackingNumber =
              orderDto.trackingNumber;
            oceanOrderContextFormValueDto.orderEntities =
              orderDto.orderEntities;
            parseOrderEntities(oceanOrderContextFormValueDto);
            oceanOrderContextFormValueDto.orderDocuments =
              orderDto.orderDocuments;

            setInitialValues({ ...oceanOrderContextFormValueDto });
            return { ...oceanOrderContextFormValueDto };
          });

          orderDto.billToContactId
            ? getPaidAs(orderDto.billToContactId).then((paidAs) => {
                setApplyToContactPaidAs(paidAs);
              })
            : setApplyToContactPaidAs(ChargeDefaultValues.paidAs);
          orderDto.carriers[0]?.contactId
            ? setApplyToCarrierPaidAs(orderDto.carriers[0].paidAs)
            : setApplyToCarrierPaidAs(ChargeDefaultValues.paidAs);
          onOceanOrderLoaded(orderDto);
          setCommodities(
            countTotalContainerCommodityWeight(orderDto.commodities),
          );
          setFakeCommodityIndex(
            (orderDto.commodities[orderDto.commodities.length - 1]
              ?.commodityId +
              1) |
              0,
          );
          setCharges(orderDto.charges);
          countProfitValue(orderDto.charges);
          setTrackingEvents(orderDto.trackingEvents);
          setIsLoading(false);
        },
        () => {},
      );
    } else {
      throw new Error('Order keys were not provided');
    }
  }, [orderId]);

  const getWorkflowActions = useCallback(
    (triggers: WorkflowTriggerDtoListResult = {}) => {
      const workflowActions: IAction[] = [];
      for (const trigger of triggers.items) {
        const actionItem = getAction(
          EXECUTE_WORKFLOWTRIGGER_LINK_KEY,
          trigger.workflowName,
          <AiFillCode />,
          async () => {
            await showDialog({
              dialog: WorkflowTriggerDialog,
              props: {
                workflowId: trigger?.workflowId,
                title: trigger?.workflowName || 'Execute Workflow',
                defaultVariables: {
                  order: oceanOrderContextFormValue,
                },
              },
            });
          },
          trigger?.links,
        );
        if (actionItem) workflowActions.push(actionItem);
      }
      return workflowActions;
    },
    [oceanOrderContextFormValue],
  );

  const loadWorkflowTriggers = useCallback(async () => {
    const triggers = await getWorkflowTriggersFx({
      triggerType: 'Manual',
      organizationId: oceanOrderContextFormValue?.organizationId,
      entityName: 'AirShipmentOrder',
    });

    setActions(getWorkflowActions(triggers));
  }, [oceanOrderContextFormValue]);

  useEffect(() => {
    loadWorkflowTriggers();
  }, [oceanOrderContextFormValue]);

  useEffect(() => {
    if (commodities)
      setOceanOrderContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.commodities = commodities;

        orderEditFormDto.customValues[
          'declaredValueCarriage'
        ] = getFormattedPrice(
          countTotalContainerCommodityDeclaredValue(
            orderEditFormDto.commodities,
          ),
          2,
        );

        return orderEditFormDto;
      });
  }, [commodities]);

  useEffect(() => {
    if (isLoading === false) {
      setOceanOrderContextFormValue((orderDto) => {
        if (!orderDto) {
          orderDto = getInitialState();
        }
        orderDto.charges = charges;
        return { ...orderDto };
      });
      countProfitValue(charges);
    }
  }, [charges]);

  useEffect(() => {
    if (isLoading === false)
      setOceanOrderContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.trackingEvents = trackingEvents;
        return orderEditFormDto;
      });
  }, [trackingEvents]);

  const goToDetailsCommodity = (commodityParams: GetCommodityParams) => {
    const cloneCommodity = { ...commodityParams.commodity };
    if (commodityParams.commodity) {
      showDialog({
        dialog: CommodityDialog,
        props: {
          title: 'Update Commodity',
          commodity: commodityParams?.commodity,
          commodityId: commodityParams?.commodity?.commodityId,
          className: 'commodity-modal',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          isEditMode: true,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: CommodityDto[] = commodities.map((obj) => {
              const compareCommodityResult =
                obj.commodityId === commodityParams?.commodity.commodityId;
              if (compareCommodityResult) obj = result;
              return obj;
            });
            const data = countTotalContainerCommodityWeight(itemsResult);
            setCommodities(data.sort((a, b) => a.commodityId - b.commodityId));
          } else {
            setCommodities((oldCommodities) => {
              const index = oldCommodities.findIndex(
                (commodity) =>
                  commodity.commodityId === cloneCommodity.commodityId,
              );
              oldCommodities[index] = cloneCommodity;
              return countTotalContainerCommodityWeight(oldCommodities);
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToDetailsCharge = (chargeParams: GetChargeParams) => {
    const cloneCharge = { ...chargeParams.charge };
    if (chargeParams.charge) {
      showDialog({
        dialog: ChargeDialog,
        props: {
          title: 'Update Charge',
          charge: chargeParams?.charge,
          chargeId: chargeParams?.charge?.chargeId,
          chargeType: chargeParams?.charge?.chargeType,
          className: 'charge-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          charges: charges,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: ChargeDto[] = charges.map((obj) => {
              const compareChargeResult =
                obj.chargeId === chargeParams?.charge.chargeId;
              if (compareChargeResult) obj = result;
              return obj;
            });
            setCharges(itemsResult.sort((a, b) => a.chargeId - b.chargeId));
          } else {
            setCharges((oldCharges) => {
              const index = oldCharges.findIndex(
                (charge) => charge.chargeId === cloneCharge.chargeId,
              );
              oldCharges[index] = cloneCharge;
              return oldCharges;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToDetailsTrackingEvent = (
    trackingEventParams: GetTrackingEventParams,
  ) => {
    const cloneEvent = Object.assign({}, trackingEventParams.trackingEvent);
    if (trackingEventParams.trackingEvent) {
      showDialog({
        dialog: TrackingEventDialog,
        props: {
          title: 'Update Event',
          trackingEvent: trackingEventParams?.trackingEvent,
          trackingEventId: trackingEventParams?.trackingEvent?.trackingEventId,
          className: 'trackingEvent-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: TrackingEventDto[] = trackingEvents.map(
              (obj) => {
                const compareTrackingEventResult =
                  obj === trackingEventParams?.trackingEvent;
                if (compareTrackingEventResult) obj = result;
                return obj;
              },
            );
            setTrackingEvents(
              itemsResult.sort((a, b) => a.trackingEventId - b.trackingEventId),
            );
          } else {
            setTrackingEvents((oldTrackingEvents) => {
              const index = oldTrackingEvents.findIndex(
                (trackingEvent) =>
                  trackingEvent.trackingEventId === cloneEvent.trackingEventId,
              );
              oldTrackingEvents[index] = cloneEvent;
              return oldTrackingEvents;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  // TODO: refactor this
  const countProfitValue = (charges: ChargeDto[]) => {
    const { defaultCurrency } = currencyStore?.getState();
    setDefaultCurrency(defaultCurrency);
    setProfitValues((profitValue) => {
      const initialZero = getFormattedPrice(0, defaultCurrency?.decimalPlaces);
      profitValue.income = initialZero;
      profitValue.expense = initialZero;
      profitValue.profit = initialZero;
      charges.forEach((item) => {
        if (item.isDeleted) return;

        const totalPrice = item.price * item.quantity;
        if (item.chargeType === ChargeType.IncomeCharge)
          profitValue.income = (
            Number(profitValue.income) + Number(totalPrice)
          ).toString();
        else
          profitValue.expense = (
            Number(profitValue.expense) + Number(totalPrice)
          ).toString();
      });
      profitValue.profit = getFormattedPrice(
        Number(profitValue.income) - Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.income = getFormattedPrice(
        Number(profitValue.income),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.expense = getFormattedPrice(
        Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      return { ...profitValue };
    });
  };

  const getCommodities = (): CommodityDto[] => {
    const data = countTotalContainerCommodityWeight(commodities);
    return data?.sort((a, b) => a.commodityId - b.commodityId);
  };

  const getCharges = (): ChargeDto[] => {
    return charges?.sort((a, b) => a.chargeId - b.chargeId);
  };

  const getTrackingEvents = (): TrackingEventDto[] => {
    let events = trackingEvents;
    events?.forEach((event) => {
      if (typeof event.eventDate === 'object') {
        event.eventDate = event.eventDate.toISOString();
      }
    });
    setTrackingEvents(events);
    return events?.sort(
      (a, b) =>
        new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
    );
  };

  const getTotalPcsCrt = () => {
    let sumPieces: number = 0;
    commodities?.forEach((commodity) => {
      sumPieces += Number(commodity.pieces);
    });
    return sumPieces;
  };

  const getWeightTotal = () => {
    let weightTotal = 0;
    commodities?.forEach((commodity) => {
      weightTotal += commodity.weight * commodity.pieces;
    });
    return weightTotal;
  };

  const getVolumeTotal = () => {
    let volumeTotal = 0;
    commodities?.forEach((commodity) => {
      volumeTotal += commodity?.volumeTotal;
    });
    return volumeTotal;
  };

  const onChargeCreated = (charge: ChargeDto) => {
    if (!charges) {
      const initialCharge: ChargeDto[] = [];
      setCharges(initialCharge);
    }
    setCharges((chargesDto) => [...chargesDto, charge]);
  };

  const onTrackingEventCreated = (trackingEvent: TrackingEventDto) => {
    if (!trackingEvents) {
      const initialTrackingEvent: TrackingEventDto[] = [];
      setTrackingEvents(initialTrackingEvent);
    }
    setTrackingEvents((trackingEventsDto) => [
      ...trackingEventsDto,
      trackingEvent,
    ]);
  };

  //Carrier form handlers
  const getButtons = () => {
    return (
      <div className="justify-content-end row">
        {isOrgAdminOrOperation && (
          <div className="col-3 pt-4">
            <Button
              type="submit"
              form={'oceanOrderForm'}
              color="primary"
              className="btn-block"
              disabled={isSending}
              isSending={isSending}
            >
              Save Ocean Order
            </Button>
          </div>
        )}
        <div className="col-3 pt-4">
          <Button
            type="button"
            color="secondary"
            onClick={onCancel}
            className="col-12"
            disabled={isSending}
          >
            Close
          </Button>
        </div>
      </div>
    );
  };

  const onSaveOceanOrder = (data: OrderDto) => {
    data.commodities = commodities;
    data.charges = charges;
    data.trackingEvents = trackingEvents;

    data.carriers = oceanOrderContextFormValue?.carriers;
    data.customValues = oceanOrderContextFormValue?.customValues;

    data.orderEntities = [];

    data.orderEntities.push(...oceanOrderContextFormValue?.orderEntityCarriers);
    data.orderEntities.push(oceanOrderContextFormValue?.shipper);
    data.orderEntities.push(oceanOrderContextFormValue?.consignee);
    data.orderEntities.push(oceanOrderContextFormValue?.ultimateConsignee);
    data.orderEntities.push(oceanOrderContextFormValue?.notifyParty);
    data.orderEntities.push(oceanOrderContextFormValue?.intermediate);
    data.orderEntities.push(oceanOrderContextFormValue?.forwardingAgent);
    data.orderEntities.push(oceanOrderContextFormValue?.destinationAgent);
    data.orderEntities.push(oceanOrderContextFormValue?.deliveringCarrier);
    data.orderEntities.push(oceanOrderContextFormValue?.pickupFrom);
    data.orderEntities.push(oceanOrderContextFormValue?.deliverTo);
    data.orderEntities.push(oceanOrderContextFormValue?.receivedBy);

    data.orderEntities = data.orderEntities.filter(
      (entity) => entity.contactId,
    );

    data.orderType = OrderTypes.OceanShipmentOrder;

    return data;
  };

  // submit form
  const onSubmit = (data: OrderDto) => {
    data.commodities = commodities;
    data.charges = charges;
    data.trackingEvents = trackingEvents;
    data.commodities = data.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        warehouseLocationId: item.warehouseLocation?.warehouseLocationId,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    data.carriers = oceanOrderContextFormValue?.carriers;
    data.customValues = oceanOrderContextFormValue?.customValues;

    data.orderEntities = [];

    data.orderEntities.push(...oceanOrderContextFormValue?.orderEntityCarriers);
    data.orderEntities.push(oceanOrderContextFormValue?.shipper);
    data.orderEntities.push(oceanOrderContextFormValue?.consignee);
    data.orderEntities.push(oceanOrderContextFormValue?.ultimateConsignee);
    data.orderEntities.push(oceanOrderContextFormValue?.notifyParty);
    data.orderEntities.push(oceanOrderContextFormValue?.intermediate);
    data.orderEntities.push(oceanOrderContextFormValue?.forwardingAgent);
    data.orderEntities.push(oceanOrderContextFormValue?.destinationAgent);
    data.orderEntities.push(oceanOrderContextFormValue?.deliveringCarrier);
    data.orderEntities.push(oceanOrderContextFormValue?.pickupFrom);
    data.orderEntities.push(oceanOrderContextFormValue?.deliverTo);
    data.orderEntities.push(oceanOrderContextFormValue?.receivedBy);

    data.orderEntities = data.orderEntities.filter(
      (entity) => entity.contactId,
    );

    data.orderType = OrderTypes.OceanShipmentOrder;

    setIsSending(true);
    if (isCreateMode) {
      createOrder(data)
        .then(
          (result) => {
            onOceanOrderCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateOrder(data)
        .then(
          (result) => {
            onOceanOrderUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };

  const onSubmitCommodityDto = (
    data: CommodityDto,
    { resetForm, setFieldValue },
  ) => {
    const { currentOrganization } = organizationsStore.getState();
    data.commodityId = fakeCommodityIndex;
    data.organizationId = currentOrganization.organizationId;
    data.commodityStatus = null;
    data.dimensionsUnit = DimensionsUnit.In;
    data.weightUnit = WeightUnit.Lb;
    data.volumeUnit = VolumeUnit.Vkg;
    data.weightTotal = data.pieces * data.weight;
    data.volumePiece = data.length * data.height * data.width;
    data.volumeTotal = data.pieces * data.volumePiece;
    setCommodities((commoditiesDto) =>
      countTotalContainerCommodityWeight([...commoditiesDto, data]),
    );
    setPackageType((packageTypeDto) => {
      packageTypeDto = {
        name: null,
        packageTypeId: null,
      };
      return { ...packageTypeDto };
    });
    setCommodityType({
      commodityTypeId: 0,
      code: null,
    });
    resetForm();
    setFieldValue('description', '');
    setFieldValue('pieces', '1');
    setFieldValue('length', '');
    setFieldValue('width', '');
    setFieldValue('height', '');
    setFieldValue('weight', '');
    setFieldValue('packageTypeId', '');
    setFieldValue('packageTypeSelect', '');
    setFakeCommodityIndex(fakeCommodityIndex + 1);
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const isNoOceanOrderTemplate = (): boolean => {
    return oceanOrderDocumentTemplatesCount === 0;
  };

  const getOceanOrderDocument = () => {
    const oceanOrderData: OrderDto = oceanOrderContextFormValue;
    oceanOrderData.orderType = OrderTypes.OceanShipmentOrder;
    oceanOrderData.commodities = oceanOrderData.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    updateOrder(oceanOrderData).then(
      () => {
        window.open(
          getRenderedDocumentLink(
            DocumentTemplateType.QuoteConfirmationDocument,
            {
              quoteId: oceanOrderData.orderId,
            },
          ),
          '_blank',
        );
      },
      () => {},
    );
  };

  const sendOceanOrderEmail = () => {
    const oceanOrderData: OrderDto = oceanOrderContextFormValue;
    oceanOrderData.orderType = OrderTypes.OceanShipmentOrder;
    oceanOrderData.commodities = oceanOrderData.commodities.map((item) => {
      const commodityDto: CommodityDto = {
        note: item.note,
        quantity: item.quantity,
        unit: item.unit,
        unitaryValue: item.unitaryValue,
        height: item.height,
        width: item.width,
        length: item.length,
        weight: item.weight,
        pieces: item.pieces,
        packageTypeName: item.packageTypeName,
        commodityId: item.commodityId,
        organizationId: item.organizationId,
        commodityStatus: getEnumKeyByValue(
          item.commodityStatus,
          CommodityStatuses,
        ),
        description: item.description,
        dimensionsUnit: item.dimensionsUnit,
        links: item.links,
        packageTypeId: item.packageTypeId,
        volumePiece: item.volumePiece,
        volumeTotal: item.volumeTotal,
        valueTotal: item.valueTotal,
        volumeUnit: item.volumeUnit,
        weightByTotal: item.weightByTotal,
        weightTotal: item.pieces * item.weight,
        weightUnit: item.weightUnit,
        commodityTypeId: item.commodityTypeId,
        commodityTypeCode: item.commodityTypeCode,
        isRemoved: item.isRemoved,
        valueByTotal: item.valueByTotal,
      };
      return commodityDto;
    });

    updateOrder(oceanOrderData).then(
      () => {
        showDialog({
          dialog: SendEmailDialog,
          props: {
            title: 'Send Ocean Order Confirmation',
            className: 'send-email-modal',
            selectedRecipientId: 0,
            recipientIds: [],
            metadata: {
              oceanOrderId: oceanOrderData.orderId,
            },
            emailTemplate: getEnumKeyByValue(
              DocumentTemplateType.QuoteConfirmationEmail,
              DocumentTemplateType,
            ),
            documentTypes: getEnumKeyByValue(
              [DocumentTemplateType.QuoteConfirmationDocument],
              DocumentTemplateType,
            ),
            onEmailSent: () => {},
            onEmailTemplateLoaded: () => {},
          },
        }).then(() => {});
      },
      () => {},
    );
  };

  const isRestrictedGetAndSendDocs = (): boolean => {
    return currentUser?.isInOrgUserRole;
  };

  const onOceanShipmentStatusChange = (
    newValueOrderStatus: OrderStatusDto,
    context: FormikProps<FormikValues>,
  ) => {
    let newOrderStatusId = newValueOrderStatus?.orderStatusId;
    if (newValueOrderStatus?.requireConfirmation === true) {
      showDialog({
        dialog: Prompt,
        props: {
          title: `Cancel ${oceanOrderContextFormValue?.orderNumber} Order`,
          message: `Please type order number (${oceanOrderContextFormValue?.orderNumber}) to confirm its cancelling.`,
          className: 'cancel-order-modal',
          orderNumber: oceanOrderContextFormValue?.orderNumber,
        },
      }).then((result) => {
        if (!result) {
          newOrderStatusId = oceanOrderContextFormValue?.orderStatusId;
        }
        setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
          if (!oceanOrderContextFormValueDto) {
            oceanOrderContextFormValueDto = getInitialState();
          }
          oceanOrderContextFormValueDto.orderStatusId = newOrderStatusId;
          context.setFieldValue(
            'orderStatusId',
            oceanOrderContextFormValueDto?.orderStatusId,
          );
          setInitialValues({ ...oceanOrderContextFormValueDto });
          return { ...oceanOrderContextFormValueDto };
        });
      });
    } else {
      setOceanOrderContextFormValue((oceanOrderContextFormValueDto) => {
        if (!oceanOrderContextFormValueDto) {
          oceanOrderContextFormValueDto = getInitialState();
        }
        oceanOrderContextFormValueDto.orderStatusId =
          newValueOrderStatus?.orderStatusId;
        context.setFieldValue(
          'orderStatusId',
          newValueOrderStatus?.orderStatusId,
        );
        oceanOrderContextFormValue.orderStatus = newValueOrderStatus;
        setInitialValues({ ...oceanOrderContextFormValueDto });
        return { ...oceanOrderContextFormValueDto };
      });
    }
  };

  const generateInvoice = () => {
    showDialog({
      dialog: ConfirmationDialog,
      props: {
        className: 'confirmation-dialog-modal',
        title: 'Save changes',
        actionText: 'creating an invoice',
      },
    }).then((confirmResult) => {
      if (confirmResult === true) {
        const filter = null;
        const sort = null;
        const search = '';
        const data = onSaveOceanOrder(oceanOrderContextFormValue);
        updateOrder(data).then(() => {
          const message: Message = {
            id: 'air-order-updated',
            type: 'success',
            autoHide: true,
            message: 'Ocean Shipment successfully updated!',
          };
          addMessage(message);
          setIsSending(false);
          getUnInvoicedOrders({
            offset,
            limit: 10000,
            sort,
            filter,
            search,
          }).then((ordersResult) => {
            const order = ordersResult.items?.find(
              (x) => x.orderId === oceanOrderContextFormValue.orderId,
            );

            getContacts({
              filter: order?.customerIds
                .map((x) => `contactId: ${x}`)
                .join(' OR '),
              limit:
                order?.customerIds.length == 0 ? 99 : order?.customerIds.length,
              search: null,
              offset: 0,
              sort: null,
            }).then((contactsResult) => {
              showDialog({
                dialog: SelectContactDialog,
                props: {
                  title: 'Select Customer',
                  className: 'select-contact-modal',
                  contacts: contactsResult.items,
                  multiple: false,
                  header: 'Select Customer',
                  placeholder: 'Select Customer',
                  required: true,
                  onContactsSelected: () => {},
                },
              }).then((selectedContacts) => {
                if (selectedContacts) {
                  showDialog({
                    dialog: GenerateInvoiceDialog,
                    props: {
                      className: 'generate-invoice-modal',
                      title: 'Generate Invoice',
                      orderId: oceanOrderContextFormValue.orderId,
                      customerId: selectedContacts[0].contactId,
                      transactionNumber: oceanOrderContextFormValue.orderId,
                      onInvoiceGenerated: () => {
                        const message: Message = {
                          id: 'air-order-invoice-generated',
                          type: 'success',
                          autoHide: true,
                          message: 'Invoice successfully created!',
                        };
                        addMessage(message);
                      },
                    },
                  }).then(() => {
                    getOrder({
                      orderId,
                    }).then((orderDto) => {
                      setCharges(orderDto.charges);
                      setOceanOrderContextFormValue({
                        ...oceanOrderContextFormValue,
                        orderStatus: orderDto.orderStatus,
                      });
                      onInvoiceGenerated();
                    });
                  });
                }
              });
            });
          });
        });
      }
    });
  };

  const isGenerateInvoiceEnabled = charges.some(
    (charge) => charge.chargeStatus === ChargeStatuses.Open,
  );

  return (
    <div className={'oceanOrder-edit-form'}>
      <OrderForm
        id={'oceanOrderForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={orderFormRef}
        validationSchema={oceanOrderSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <div className="d-flex justify-content-between">
                  <span>
                    <Tab data-cy={'general'}>Ocean Shipment Information</Tab>
                    <Tab data-cy={'entities'}>Entities</Tab>
                    <Tab data-cy={'routing'}>Routing</Tab>
                    <Tab data-cy={'delivery'}>Delivery</Tab>
                    <Tab data-cy={'charges'}>Charges</Tab>
                    <Tab data-cy={'events'}>Events</Tab>
                    <Tab data-cy={'additional'}>Additional</Tab>
                    <Tab data-cy={'attachments'} disabled={isCreateMode}>
                      Attachments
                    </Tab>
                    <Tab data-cy={'orderDocuments'}>Order Documents</Tab>
                  </span>
                  {!isCreateMode && (
                    <>
                      <ActionsDropdown size="sm" actions={actions} />
                      {/*<ActionBarComponent
                        buttonText={<>&middot;&middot;&middot;</>}
                        style={{
                          minWidth: 'fit-content',
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                          height: '37px',
                        }}
                      >
                        <button
                          disabled={
                            isRestrictedGetAndSendDocs() ||
                            isNoOceanOrderTemplate()
                          }
                          type={'button'}
                          onClick={getOceanOrderDocument}
                          title={
                            isRestrictedGetAndSendDocs() ||
                            isNoOceanOrderTemplate()
                              ? isRestrictedGetAndSendDocs()
                                ? 'Restricted'
                                : 'There is no active quotation document template'
                              : null
                          }
                        >
                          Get Ocean Order Confirmation
                        </button>
                        <button
                          disabled={isRestrictedGetAndSendDocs()}
                          type={'button'}
                          onClick={sendOceanOrderEmail}
                          title={
                            isRestrictedGetAndSendDocs() ? 'Restricted' : null
                          }
                        >
                          Send Ocean Order Confirmation
                        </button>
                        <button
                          disabled={
                            !isGenerateInvoiceEnabled ||
                            charges.length == 0 ||
                            !isOrgAdminOrAccounting
                          }
                          type={'button'}
                          onClick={generateInvoice}
                          title={
                            isOrgAdminOrAccounting
                              ? ''
                              : 'You have no permissions'
                          }
                        >
                          Generate Invoice
                        </button>
                      </ActionBarComponent>
                      */}
                    </>
                  )}
                </div>
              </TabList>
              <div>
                <TabPanel forceRender={true}>
                  <Panel className="m-3">
                    <h2>Ocean Shipment Information</h2>
                    <div className="row">
                      {isCreateMode ? (
                        <div />
                      ) : (
                        <>
                          <div className="col-2">
                            <OrderForm.OrderNumber label="Ocean Order Number" />
                          </div>
                        </>
                      )}
                      <div className={isCreateMode ? 'col-3' : 'col-2'}>
                        <CustomFieldsLayout
                          inputNamePrefix=""
                          rows={[{ rowNumber: 1 }]}
                          filter={'shipmentName'}
                          customFields={systemCustomFields}
                          defaultValue={{
                            ...oceanOrderContextFormValue.customValues,
                          }}
                          onChange={(result) => {
                            setOceanOrderContextFormValue(
                              (oceanOrderContextFormValue) => {
                                oceanOrderContextFormValue.customValues = {
                                  ...oceanOrderContextFormValue.customValues,
                                  ...result,
                                };
                                return { ...oceanOrderContextFormValue };
                              },
                            );
                          }}
                        />
                      </div>

                      <div className="col-3 employee-contact-input">
                        <OrderForm.EmployeeContactSelect
                          id={'employeeContactId'}
                          header={'Executed by(Employee)'}
                          contactTypes={[ContactType.Employee]}
                          selectedFilter={`contactType: ${ContactType.Employee}`}
                          required={false}
                          defaultValue={
                            oceanOrderContextFormValue &&
                            oceanOrderContextFormValue.employeeContactId !=
                              null &&
                            oceanOrderContextFormValue.employeeContactName !=
                              null
                              ? {
                                  contactId:
                                    oceanOrderContextFormValue.employeeContactId,
                                  name:
                                    oceanOrderContextFormValue.employeeContactName,
                                  contactType: ContactType.Employee,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setOceanOrderContextFormValue(
                              (oceanOrderContextFormValueDto) => {
                                if (!oceanOrderContextFormValueDto) {
                                  oceanOrderContextFormValueDto = getInitialState();
                                }
                                oceanOrderContextFormValueDto.employeeContactId =
                                  data?.contactId;
                                oceanOrderContextFormValueDto.employeeContactName =
                                  data?.name;
                                return oceanOrderContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'employeeContactName'}
                        />
                      </div>

                      <div className={isCreateMode ? 'col-3' : 'col-2'}>
                        <OrderForm.Division
                          id={'divisionId'}
                          header={'Division'}
                          required={true}
                          defaultValue={
                            oceanOrderContextFormValue &&
                            oceanOrderContextFormValue.divisionId != null &&
                            oceanOrderContextFormValue.divisionName != null
                              ? {
                                  divisionId:
                                    oceanOrderContextFormValue.divisionId,
                                  divisionName:
                                    oceanOrderContextFormValue.divisionName,
                                }
                              : ''
                          }
                          onChange={(data?: DivisionDto) => {
                            setOceanOrderContextFormValue(
                              (oceanOrderContextFormValueDto) => {
                                if (!oceanOrderContextFormValueDto) {
                                  oceanOrderContextFormValueDto = getInitialState();
                                }
                                oceanOrderContextFormValueDto.divisionId =
                                  data?.divisionId;
                                oceanOrderContextFormValueDto.divisionName =
                                  data?.divisionName;
                                return oceanOrderContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'divisionName'}
                          defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                          disabled={
                            !(
                              (currentUser?.isInOrgAdminRole ||
                                currentUser?.isInOperationRole) &&
                              VisibleTransactions[
                                currentUser?.visibleTransactions
                              ] === VisibleTransactions.AllTransactions
                            )
                          }
                        />
                      </div>
                      <div className="col-3">
                        <OrderForm.OrderStatus
                          defaultValue={oceanOrderContextFormValue.orderStatus}
                          onChange={(value) =>
                            onOceanShipmentStatusChange(value, context)
                          }
                          isClearable={false}
                          header={'Ocean Shipment Status'}
                          selectedFilter={`OrderType:${OrderTypes.OceanShipmentOrder}`}
                          defaultValueFilter={`OrderType:${OrderTypes.OceanShipmentOrder}`}
                        />
                      </div>
                    </div>

                    <CustomFieldsLayout
                      inputNamePrefix=""
                      rows={[
                        { rowNumber: 1 },
                        { rowNumber: 2 },
                        { rowNumber: 3 },
                        { rowNumber: 4 },
                      ]}
                      filter={'general'}
                      customFields={systemCustomFields}
                      defaultValue={{
                        ...oceanOrderContextFormValue.customValues,
                      }}
                      onChange={(result) => {
                        setOceanOrderContextFormValue(
                          (oceanOrderContextFormValue) => {
                            oceanOrderContextFormValue.customValues = {
                              ...oceanOrderContextFormValue.customValues,
                              ...result,
                            };
                            return { ...oceanOrderContextFormValue };
                          },
                        );
                      }}
                    />

                    <CommodityForm
                      id={'commodityForm'}
                      initialValues={commodity || initialStateCommodity}
                      onSubmit={onSubmitCommodityDto}
                      innerRef={commodityFormRef}
                      validationSchema={commoditySchema}
                      onKeyPress={(event, formikParams) => {
                        if (
                          event.code === 'Enter' &&
                          (currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole)
                        ) {
                          formikParams.submitForm();
                        }
                      }}
                    >
                      <h2>Commodities</h2>
                      <div className="row">
                        <div className={'col-1'}>
                          <CommodityForm.Pieces
                            onKeyDown={validateNumberInput}
                          />
                        </div>
                        <div className="col-2">
                          <CommodityForm.PackageTypeSelect
                            required={false}
                            defaultValue={
                              packageType &&
                              packageType.packageTypeId !== undefined &&
                              packageType.packageTypeId != null &&
                              packageType.name !== undefined &&
                              packageType.name != null
                                ? {
                                    packageTypeId: packageType?.packageTypeId,
                                    name: packageType?.name,
                                  }
                                : ''
                            }
                            onChange={(
                              data?: PackageTypeDto,
                              context?: any,
                            ) => {
                              setPackageType((packageTypeData) => {
                                if (!packageTypeData) {
                                  packageTypeData = {
                                    name: null,
                                    packageTypeId: null,
                                  };
                                }
                                packageTypeData.packageTypeId = data?.packageTypeId?.toString();
                                packageTypeData.name = data?.name;
                                return { ...packageTypeData };
                              });
                              context?.setFieldValue(
                                'packageTypeName',
                                data?.name,
                              );
                            }}
                            nameId={'packageTypeName'}
                          />
                        </div>
                        {isOrgAdminOrOperation ? (
                          <div className="col-4">
                            <CommodityForm.Description />
                          </div>
                        ) : (
                          <div className="col-5">
                            <CommodityForm.Description />
                          </div>
                        )}
                        <div className="col-1">
                          <CommodityForm.Length
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Width
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Height
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Weight
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        {(currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole) && (
                          <div className="col-1 pt-4">
                            <Button
                              form={'commodityForm'}
                              name={'create-commodity'}
                              type="button"
                              onClick={() => {
                                if (commodityFormRef.current) {
                                  commodityFormRef.current.submitForm();
                                }
                              }}
                              color="secondary"
                              className="btn-block text-break"
                              data-testid={'add-commodity-button'}
                            >
                              +
                            </Button>
                          </div>
                        )}
                      </div>
                      <CommoditiesForOrderList
                        className={'mb-4 commodities-for-order-list'}
                        limit={limit}
                        showPagination={false}
                        onPageChanged={(page) => setOffset(page * limit)}
                        offset={offset}
                        items={getCommodities()}
                        goToDetails={goToDetailsCommodity}
                        changeItems={setCommodities}
                        userCanDelete={
                          currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole
                        }
                        orderType={OrderTypes.OceanShipmentOrder}
                      />
                      {getCommodities().length === 0 && (
                        <h4 className="text-center m-5 text-muted">
                          No commodities
                        </h4>
                      )}
                      <div className="row">
                        <div className="col-2">
                          <OrderForm.TotalPcsCrt
                            selectedName={getTotalPcsCrt()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.WeightTotal
                            selectedName={getWeightTotal()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.VolumeTotal
                            selectedName={getVolumeTotal()}
                          />
                        </div>
                        <div className="justify-content-end d-flex col-6">
                          {(currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole) && (
                            <div className="col-6 pt-4">
                              <Button
                                type="submit"
                                form={'oceanOrderForm'}
                                color="primary"
                                className="btn-block"
                                disabled={isSending}
                                isSending={isSending}
                              >
                                Save Ocean Order
                              </Button>
                            </div>
                          )}
                          <div className="col-6 pt-4">
                            <Button
                              type="button"
                              color="secondary"
                              onClick={onCancel}
                              className="col-12"
                              disabled={isSending}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CommodityForm>
                  </Panel>
                </TabPanel>

                {/* entities tab */}
                <TabPanel>
                  <Panel className="m-3">
                    <h2>Entities</h2>
                    <OrderEntitiesForm
                      oceanOrderContextFormValue={oceanOrderContextFormValue}
                      setOceanOrderContextFormValue={
                        setOceanOrderContextFormValue
                      }
                    />

                    {getButtons()}
                  </Panel>
                </TabPanel>

                {/* routing tab */}
                <TabPanel>
                  <Panel className="m-3">
                    <h2>Routing</h2>
                    <CustomFieldsLayout
                      inputNamePrefix=""
                      rows={[{ rowNumber: 1 }]}
                      filter={'routing'}
                      customFields={systemCustomFields}
                      defaultValue={{
                        ...oceanOrderContextFormValue.customValues,
                      }}
                      onChange={(result) => {
                        setOceanOrderContextFormValue(
                          (oceanOrderContextFormValue) => {
                            oceanOrderContextFormValue.customValues = {
                              ...oceanOrderContextFormValue.customValues,
                              ...result,
                            };
                            return { ...oceanOrderContextFormValue };
                          },
                        );
                      }}
                    />

                    <hr className="my-4" />
                    <h2>Origin</h2>

                    <CustomFieldsLayout
                      inputNamePrefix=""
                      rows={[{ rowNumber: 1 }]}
                      filter={'routingOrigin'}
                      customFields={systemCustomFields}
                      defaultValue={{
                        ...oceanOrderContextFormValue.customValues,
                      }}
                      onChange={(result) => {
                        setOceanOrderContextFormValue(
                          (oceanOrderContextFormValue) => {
                            oceanOrderContextFormValue.customValues = {
                              ...oceanOrderContextFormValue.customValues,
                              ...result,
                            };
                            return { ...oceanOrderContextFormValue };
                          },
                        );
                      }}
                    />

                    <hr className="my-4" />
                    <h2>Export</h2>
                    <CustomFieldsLayout
                      inputNamePrefix=""
                      rows={[{ rowNumber: 1 }, { rowNumber: 2 }]}
                      filter={'routingExport'}
                      customFields={systemCustomFields}
                      defaultValue={{
                        ...oceanOrderContextFormValue.customValues,
                      }}
                      onChange={(result) => {
                        setOceanOrderContextFormValue(
                          (oceanOrderContextFormValue) => {
                            oceanOrderContextFormValue.customValues = {
                              ...oceanOrderContextFormValue.customValues,
                              ...result,
                            };
                            return { ...oceanOrderContextFormValue };
                          },
                        );
                      }}
                    />
                    <hr className="my-4" />
                    <h2>Destination</h2>

                    <CustomFieldsLayout
                      inputNamePrefix=""
                      rows={[{ rowNumber: 1 }, { rowNumber: 2 }]}
                      filter={'routingDestination'}
                      customFields={systemCustomFields}
                      defaultValue={{
                        ...oceanOrderContextFormValue.customValues,
                      }}
                      onChange={(result) => {
                        setOceanOrderContextFormValue(
                          (oceanOrderContextFormValue) => {
                            oceanOrderContextFormValue.customValues = {
                              ...oceanOrderContextFormValue.customValues,
                              ...result,
                            };
                            return { ...oceanOrderContextFormValue };
                          },
                        );
                      }}
                    />

                    <hr className="my-4" />
                    <h2>
                      US Custom Codes for Origin and Destination: Schedule D and
                      K
                    </h2>

                    <CustomFieldsLayout
                      inputNamePrefix=""
                      rows={[{ rowNumber: 1 }, { rowNumber: 2 }]}
                      filter={'routingCustomCodes'}
                      customFields={systemCustomFields}
                      defaultValue={{
                        ...oceanOrderContextFormValue.customValues,
                      }}
                      onChange={(result) => {
                        setOceanOrderContextFormValue(
                          (oceanOrderContextFormValue) => {
                            oceanOrderContextFormValue.customValues = {
                              ...oceanOrderContextFormValue.customValues,
                              ...result,
                            };
                            return { ...oceanOrderContextFormValue };
                          },
                        );
                      }}
                    />

                    {getButtons()}
                  </Panel>
                </TabPanel>

                {/* delivery tab */}
                <TabPanel>
                  <Panel className="m-3">
                    <h2>Delivery</h2>
                    <DeliveryOrderEntitiesForm
                      oceanOrderContextFormValue={oceanOrderContextFormValue}
                      setOceanOrderContextFormValue={
                        setOceanOrderContextFormValue
                      }
                      customFields={systemCustomFields}
                    />
                    {getButtons()}
                  </Panel>
                </TabPanel>

                <TabPanel>
                  <Panel className="m-3">
                    <ChargeForOrderList
                      limit={limit}
                      showPagination={false}
                      showIncomeCharge={userHas(CREATE_CHARGE_LINK_KEY)}
                      showExpenseCharge={userHas(CREATE_CHARGE_LINK_KEY)}
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onChargeCreated={onChargeCreated}
                      items={getCharges()}
                      goToDetails={goToDetailsCharge}
                      changeItems={setCharges}
                      selectedApplyToContact={{
                        contactId: oceanOrderContextFormValue.billToContactId,
                        name: oceanOrderContextFormValue.billToContactName,
                        contactType:
                          oceanOrderContextFormValue.billToContactType,
                        paidAs: applyToContactPaidAs,
                      }}
                      selectedApplyToCarrier={{
                        contactId: oceanOrderContextFormValue.carrierContactId,
                        name: oceanOrderContextFormValue.carrierContactName,
                        contactType: ContactType.Carrier,
                        paidAs: applyToCarrierPaidAs,
                      }}
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                      charges={oceanOrderContextFormValue.charges}
                    />
                    <div className="mt-3">
                      <h2>Totals</h2>
                    </div>
                    <ChargeForm initialValues={profitValues} id={'ChargeForm'}>
                      <div className={'row'}>
                        <div className={'col-2'}>
                          <ChargeForm.Income disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Expense disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Profit disabled={true} />
                        </div>
                      </div>
                    </ChargeForm>
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'oceanOrderForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Ocean Order
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <TrackingEventForOrderList
                      limit={limit}
                      showPagination={false}
                      showAddButton={isOrgAdminOrOperation ? true : false}
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onTrackingEventCreated={onTrackingEventCreated}
                      items={getTrackingEvents()}
                      goToDetails={goToDetailsTrackingEvent}
                      changeItems={setTrackingEvents}
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'oceanOrderForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Ocean Order
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel forceRender={isCreateMode ? false : true}>
                  <OrderForm.CustomValues
                    customFields={customFields}
                    entityType={CustomFieldEntityType.OceanShipmentOrder}
                    defaultValue={{
                      ...oceanOrderContextFormValue.customValues,
                    }}
                    onChange={(result) => {
                      setOceanOrderContextFormValue(
                        (oceanOrderContextFormValue) => {
                          oceanOrderContextFormValue.customValues = {
                            ...oceanOrderContextFormValue.customValues,
                            ...result,
                          };
                          return { ...oceanOrderContextFormValue };
                        },
                      );
                    }}
                    saveButtonRenderCondition={
                      currentUser?.isInOrgAdminRole ||
                      currentUser?.isInOperationRole
                    }
                    isSending={isSending}
                    formName={'oceanOrderForm'}
                    entityName={'OceanShipmentOrder'}
                    onCancel={onCancel}
                    context={context}
                  />
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <AttachmentsFilesList
                      parentId={orderId}
                      parentType={AttachmentParentType.Order}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'oceanOrderForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Order
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="mt-3">
                    <OrderDocumentsFragment
                      orderContextFormValue={oceanOrderContextFormValue}
                      onSaveOrder={onSaveOceanOrder}
                      setIsSending={setIsSending}
                      documentTemplatesFilter={documentTemplateFilter}
                      setOrderContextFormValue={() =>
                        setOceanOrderContextFormValue
                      }
                      allowCreateOrderDocument={false}
                      orderType={OrderTypes.OceanShipmentOrder}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'oceanOrderForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Order
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              </div>
            </Tabs>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
