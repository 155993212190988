import {
  CurrencyDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateCurrencyCommandValues,
  UpdateCurrencyCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCurrencyRequest,
  getCurrenciesListRequest,
  getCurrencyRequest,
  deleteCurrencyRequest,
  updateCurrencyRequest,
  importCurrenciesRequest,
  exportCurrenciesRequest,
  GetCurrencyParams,
} from './currencies.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type CurrenciesStoreState = {
  links: LinkDto[];
  currencyColumns: EntityFieldDto[];
  defaultCurrencyColumns: EntityFieldDto[];
  defaultCurrency: CurrencyDto;
  defaultSort: string;
  defaultLimit: number;
};

export const getCurrenciesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Currency',
  });
});

addEffectStatusHandling(getCurrenciesColumnsFx);

export const getCurrenciesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Currency',
  });
});

addEffectStatusHandling(getCurrenciesDefaultColumnsFx);

export const updateCurrenciesColumns = createEvent<EntityFieldDto[]>();

export const getCurrenciesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCurrenciesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getCurrenciesFx);

export const createCurrencyFx = createEffect((currencyData: CurrencyDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createCurrencyCommand: CreateCurrencyCommandValues = {
    currencyCode: currencyData.currencyCode,
    currencyName: currencyData.currencyName,
    decimalPlaces: currencyData.decimalPlaces,
    exchangeRateLastUpdate: currencyData.exchangeRateLastUpdate,
    exchangeRateToPrimary: currencyData.exchangeRateToPrimary,
    gainLossAccountId: currencyData.gainLossAccountId,
    isInactive: currencyData.isInactive,
    symbol: currencyData.symbol,
  };

  return createCurrencyRequest(currentOrganization!, createCurrencyCommand);
});

addEffectStatusHandling(createCurrencyFx, {
  completeMessage: 'Currency was created successfully',
  errorMessage: 'Currency was not created',
});

export const getCurrencyFx = createEffect(
  (currencyParams: GetCurrencyParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCurrencyRequest(
      currentOrganization as LinkResourceBaseDto,
      currencyParams,
    );
  },
);

addEffectStatusHandling(getCurrencyFx);

export const updateCurrencyFx = createEffect((currency: CurrencyDto) => {
  const updateCurrencyCommand: UpdateCurrencyCommandValues = {
    currencyCode: currency.currencyCode,
    currencyName: currency.currencyName,
    decimalPlaces: currency.decimalPlaces,
    exchangeRateLastUpdate: currency.exchangeRateLastUpdate,
    exchangeRateToPrimary: currency.exchangeRateToPrimary,
    gainLossAccountId: currency.gainLossAccountId,
    isInactive: currency.isInactive,
    symbol: currency.symbol,
  };
  return updateCurrencyRequest(currency, updateCurrencyCommand);
});

addEffectStatusHandling(updateCurrencyFx, {
  completeMessage: 'Currency was updated successfully',
  errorMessage: 'Currency was not updated',
});

export const deleteCurrencyFx = createEffect((currency: CurrencyDto) => {
  return deleteCurrencyRequest(currency);
});

addEffectStatusHandling(deleteCurrencyFx, {
  completeMessage: 'Currency was deleted successfully',
  errorMessage: 'Currency was not deleted',
});

export const exportCurrenciesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportCurrenciesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportCurrenciesFx, {
  completeMessage: 'Currencies were exported successfully',
  errorMessage: 'Currencies were not exported',
});

export const importCurrenciesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importCurrenciesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importCurrenciesFx, {
  completeMessage: 'Currencies were imported successfully',
  errorMessage: 'Currencies were not imported',
  inFlightMessage: 'Importing currencies...',
});

const defaultState: CurrenciesStoreState = {
  links: [],
  currencyColumns: [],
  defaultCurrencyColumns: [],
  defaultCurrency: null,
  defaultSort: '',
  defaultLimit: 20,
};

export const currencyStore: Store<CurrenciesStoreState> = createStore(
  defaultState,
)
  .on(getCurrenciesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      currencyColumns: payload.result.items,
      defaultCurrencyColumns: payload.result.items,
    };
  })
  .on(getCurrenciesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultCurrencyColumns: payload.result.items,
    };
  })
  .on(updateCurrenciesColumns, (state, payload) => {
    return { ...state, currencyColumns: payload };
  })
  .on(getCurrenciesFx.done, (state, payload) => {
    const currencies = payload.result;
    const usdCurrency: CurrencyDto = currencies?.items?.find(
      (currency) => currency.currencyCode === 'USD' && !currency.isInactive,
    );
    state.defaultCurrency = usdCurrency ?? null;
  });
