import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AddressInfo } from '../components/common/addressInfo';
import { CustomerInformation } from '../../customers/components/common/customerInformation.component';
import { TrackingNumbers } from '../../warehouseReceipts/components/common/trackingNumbers.component';
import { ParcelShipmentInformationFormik } from '../components/common/parcelShipmentInformation.formik.component';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { HiCheck, HiX } from 'react-icons/hi';
import printJS from 'print-js';
import { FiDownload } from 'react-icons/fi';
import { BsArrowRepeat } from 'react-icons/bs';
import { useFormikContext } from 'formik';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { triggerWorkflowByConfig } from '../../workflowTriggers/workflowTriggerHook';

export type PackingLabelFragmentProps = {
  handleNext: () => void;
  handleBack: () => void;
  orderDocumentId?: number | null;
  orderDocumentLink?: string | null;
};

export const PackingLabelFragment = ({
  handleBack,
  handleNext,
  orderDocumentId,
  orderDocumentLink,
}: PackingLabelFragmentProps) => {
  const formikContext = useFormikContext<any>();
  const [shippingLabeLSrc, setShippingLabeLSrc] = useState<string>(null);
  const [
    shippingLabeLSrcPreview,
    setShippingLabeLSrcPreview,
  ] = useState<string>(null);
  const [isSending, setIsSending] = useState<boolean>(false);

  const onCompletePacking = async () => {
    const workflowExecutionVariables = {
      orderId: formikContext.values.parcelShipment.orderId,
      customerId: formikContext.values.parcelShipment.billToContactId,
      appendToExistingInvoice: true,
      includePending: true,
    };
    setIsSending(true);
    try {
      const result = await triggerWorkflowByConfig({
        configName: 'tms.modules.packing',
        workflowIdConfigKey: 'generateInvoiceWorkflowId',
        workflowVariables: workflowExecutionVariables,
      });
      setIsSending(false);
      if (result) {
        if (result.outputs.success) {
          addMessage({
            message: 'Invoice created',
            type: 'success',
            autoHide: true,
            id: v4(),
          });
        }
        handleNext();
      }
    } catch (error) {
      addMessage({
        message: error?.message || error,
        type: 'danger',
        id: v4(),
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setShippingLabeLSrc(orderDocumentLink);

    setShippingLabeLSrcPreview(orderDocumentLink);
  }, [orderDocumentId]);

  useEffect(() => {
    if (handleBack)
      setActionButtons([
        <Button
          key={'packing-label-complete'}
          color="secondary"
          className={'mr-4 d-flex align-items-center justify-content-center'}
          outline
          onClick={handleBack}
          style={{ minWidth: '8.5rem' }}
          isSending={isSending}
          disabled={isSending}
        >
          <HiX size={16} className={'mr-2'} />
          Cancel
        </Button>,
        <Button
          key={'packing-label-back'}
          className={'d-flex align-items-center justify-content-center'}
          color="primary"
          onClick={onCompletePacking}
          style={{ minWidth: '8.5rem' }}
          isSending={isSending}
          disabled={isSending}
        >
          <HiCheck size={18} className={'mr-2'} />
          Complete Packing
        </Button>,
      ]);
  }, [isSending]);

  useEffect(() => {
    if (shippingLabeLSrc) printJS({ printable: shippingLabeLSrc, type: 'pdf' });
  }, [shippingLabeLSrc]);

  useEffect(() => () => clearActionButtons(), []);

  return (
    <div className={'px-3'}>
      <div className="row d-flex justify-content-between">
        <div className="col-6">
          <div className="box h-100">
            <div className={'mb-4'}>
              <CustomerInformation
                contactId={formikContext.values?.purchase?.billToContactId}
              />
            </div>
            <div className="box">
              <TrackingNumbers
                commodityTrackingNumbers={formikContext.values?.trackingNumbers}
                allowEdit={false}
                commodityId={
                  formikContext.values?.purchase?.commodities[0]?.commodityId
                }
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="box h-100">
            <ParcelShipmentInformationFormik />
            {formikContext.values?.parcelShipment && (
              <AddressInfo
                parcelShipment={formikContext.values?.parcelShipment}
              />
            )}
          </div>
        </div>
      </div>

      {formikContext.values?.parcelShipment ? (
        <div>
          <div className={'document-preview-toolbar mt-5'}>
            <div className={'text-label-reverse'}>Label preview</div>
            <div className={'text-label-reverse'}>
              <span
                className="pointer"
                onClick={() => window.open(shippingLabeLSrc, '_blank')}
              >
                <FiDownload size="18" className="mr-2" />
                Download
              </span>
              <span
                className="pointer pl-2"
                onClick={() =>
                  printJS({ printable: shippingLabeLSrc, type: 'pdf' })
                }
              >
                <BsArrowRepeat size="18" className="ml-4 mr-2" />
                Print again
              </span>
            </div>
          </div>
          <div
            className="py-6 d-flex justify-content-center"
            style={{ backgroundColor: '#E9E9E9' }}
          >
            {shippingLabeLSrcPreview ? (
              <embed
                width={'100%'}
                height={'100%'}
                style={{ minHeight: '700px' }}
                type={'application/pdf'}
                src={shippingLabeLSrcPreview}
              />
            ) : (
              <div className="d-flex justify-content-center">
                Creating Label
              </div>
            )}
          </div>
        </div>
      ) : (
        <div />
      )}

      <div className="row pt-5 d-flex justify-content-end"></div>
    </div>
  );
};
