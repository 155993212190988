import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const PaymentTermForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

PaymentTermForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
PaymentTermForm.DiscountPaidWithinDays = ({
  className,
  style,
  size,
  disabled,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'discountPaidWithinDays'}
      label={'Discount if paid within (days)'}
      placeholder={'Discount if paid within (days)'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};
PaymentTermForm.DiscountPercentage = ({
  className,
  style,
  size,
  disabled,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'discountPercentage'}
      label={'Discount percentage'}
      placeholder={'Discount percentage'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};
PaymentTermForm.IsInactive = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      id={'isInactivePaymentTermCheckbox'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
PaymentTermForm.NetDueDays = ({
  className,
  style,
  size,
  disabled,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'netDueDays'}
      label={'Net Due (days)'}
      placeholder={'Net Due (days)'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};
