import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { OrderEdit } from './order-edit.component';

export type OrderDialogProperties = {
  orderId?: number | null;
  title: string;
  className?: string;
  onOrderSaved?: (order: OrderDto) => void;
  onInvoiceGenerated?: () => void;
};

export class OrderDialog extends Dialog<OrderDialogProperties> {
  onInvoiceGenerated?: () => void;

  constructor(props: OrderDialogProperties) {
    super(props);
    this.onInvoiceGenerated = props.onInvoiceGenerated?.bind(this);
    this.onOrderSaved = this.onOrderSaved.bind(this);
  }

  onOrderSaved = (order: OrderDto) => {
    this.close(order);
  };

  renderContent(): any {
    const { orderId } = this.props as OrderDialogProperties;
    const onInvoiceGenerated = this.onInvoiceGenerated;
    return (
      <OrderEdit
        orderId={orderId}
        onOrderCreated={this.onOrderSaved}
        onOrderUpdated={this.onOrderSaved}
        onCancel={this.close.bind(this)}
        onInvoiceGenerated={onInvoiceGenerated && this.onInvoiceGenerated}
      />
    );
  }
}
