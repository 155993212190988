import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AccountingAccountForm } from './accountingAccount.form';
import { AccountingAccountDto, AccountType } from '../../../models/data.models';
import {
  createAccountingAccountFx,
  getAccountingAccountFx,
  updateAccountingAccountFx,
} from '../accountingAccounts.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import { getEnumValues } from '../../../utils/helper.utils';
import { AccountingAccountDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { userHas } from '../../auth/auth.store';
import { UPDATE_ACCOUNTINGACCOUNT_LINK_KEY } from '../accountingAccounts.service';

export type AccountingAccountEditProps = {
  accountId?: number | null;
  onAccountingAccountCreated?: (
    accountingAccount: AccountingAccountDto,
  ) => void;
  onAccountingAccountUpdated?: (
    accountingAccount: AccountingAccountDto,
  ) => void;
  onAccountingAccountLoaded?: (accountingAccount: AccountingAccountDto) => void;
  onCancel?: () => void;
};

const initialState: AccountingAccountDto = {
  parentAccountName: AccountingAccountDefaultValues.parentAccountName,
  organizationId: null,
  accountId: null,
  accountName: AccountingAccountDefaultValues.accountName,
  accountNumber: AccountingAccountDefaultValues.accountNumber,
  accountType: AccountingAccountDefaultValues.accountType,
  isInactive: AccountingAccountDefaultValues.isInactive,
  parentAccountId: AccountingAccountDefaultValues.parentAccountId,
  links: [],
};

const accountingAccountSchema = Yup.object().shape({
  accountName: Yup.string().required("Can't be blank").nullable(true),
  accountNumber: Yup.string().max(20, 'Max length is 20').nullable(true),
  accountType: Yup.string().required("Can't be blank").nullable(true),
});

export const AccountingAccountEdit = ({
  accountId,
  onAccountingAccountLoaded = () => {},
  onAccountingAccountCreated = () => {},
  onAccountingAccountUpdated = () => {},
  onCancel = () => {},
}: AccountingAccountEditProps) => {
  const isCreateMode = !accountId || accountId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    accountingAccount,
    setAccountingAccount,
  ] = useState<AccountingAccountDto | null>(null);
  const [
    initialValues,
    setInitialValues,
  ] = useState<AccountingAccountDto | null>(initialState);
  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (accountId) {
      getAccountingAccountFx({ accountId }).then(
        (accountingAccountDto: AccountingAccountDto) => {
          setAccountingAccount(accountingAccountDto);
          setInitialValues(accountingAccountDto);
          setIsLoading(false);
          onAccountingAccountLoaded(accountingAccountDto);
        },
      );
    } else {
      throw new Error('AccountingAccount keys were not provided');
    }
  }, [accountId]);

  const onSubmit = (data: AccountingAccountDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createAccountingAccountFx(data)
        .then((result) => {
          onAccountingAccountCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updateAccountingAccountFx(data)
        .then((result) => {
          onAccountingAccountUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  const onAccountTypeChange = (accountType: ReactSelectItem) => {
    setAccountingAccount((accountingAccount) => {
      if (!accountingAccount) {
        accountingAccount = initialState;
      }
      accountingAccount.accountType = AccountType[accountType?.label];
      return { ...accountingAccount };
    });
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="accountingAccount">Accounting Account</Tab>
      </TabList>
      <AccountingAccountForm
        id={'accounting-account-form'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={ref}
        validationSchema={accountingAccountSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Accounting Account</h2>
            ) : (
              <h2>Update Accounting Account</h2>
            )}
            <div className="row">
              <div className="col-4">
                <AccountingAccountForm.AccountName />
                <AccountingAccountForm.AccountNumber />
                <AccountingAccountForm.AccountTypeSelect
                  placeholder={'Select Account Type'}
                  id={'accountType'}
                  header={'Account Type'}
                  name={'accountType'}
                  disabled={false}
                  required={true}
                  defaultValue={
                    accountingAccount?.accountType
                      ? {
                          label: AccountType[accountingAccount?.accountType],
                          value: accountingAccount?.accountType,
                        }
                      : null
                  }
                  onChange={onAccountTypeChange}
                  multiple={false}
                  options={getEnumValues(AccountType)}
                />
                <AccountingAccountForm.IsInactive
                  defaultValue={accountingAccount?.isInactive ?? false}
                />
                <AccountingAccountForm.ParentAccountSelect
                  selectedName={accountingAccount?.parentAccountName}
                  header="Parent Account"
                  required={false}
                  placeholder={'Select Parent Account'}
                  defaultValue={
                    accountingAccount &&
                    accountingAccount.parentAccountId != null &&
                    accountingAccount.parentAccountName != null
                      ? {
                          accountId: accountingAccount.parentAccountId,
                          accountName: accountingAccount.parentAccountName,
                        }
                      : ''
                  }
                  onChange={(accountingAccountDto?: AccountingAccountDto) => {
                    setAccountingAccount((accountingAccount) => {
                      if (!accountingAccount) {
                        accountingAccount = initialState;
                      }
                      accountingAccount.parentAccountId =
                        accountingAccountDto?.accountId;
                      accountingAccount.parentAccountName =
                        accountingAccountDto?.accountName;
                      return { ...accountingAccount };
                    });
                  }}
                />
              </div>
            </div>
            <div className="justify-content-end row">
              {(userHas(
                UPDATE_ACCOUNTINGACCOUNT_LINK_KEY,
                accountingAccount?.links,
              ) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'accounting-account-form'}
                    name="save-accountingAccount"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Account
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </AccountingAccountForm>
    </Tabs>
  );
};
