import React, { useContext, useEffect, useState } from 'react';
import { ComponentProps } from '../layout-interfaces';
import { parseTemplate } from '../component-hooks';
import { FormikContext } from 'formik';

type BadgeColor = {
  label: string;
  bgcolor: string;
  dot: string;
};

export const BadgeComponent = (props: ComponentProps) => {
  const formikContext = useContext(FormikContext);
  const [parsedLabel, setParsedLabel] = useState('');
  const [colors, setColors] = useState<BadgeColor>(null);

  useEffect(() => {
    if (props?.props?.label && props?.variables) {
      setParsedLabel(
        parseTemplate(props.props.label, {
          ...props.variables,
          ...formikContext?.values,
        }),
      );
    }
  }, [props?.props, props?.variables]);

  useEffect(() => {
    if (props?.props?.options?.colors && parsedLabel) {
      const formattedLabel = parsedLabel.toLowerCase().replace(' ', '-');
      setColors(
        props.props.options.colors[formattedLabel] ??
          props.props.options.colors['default'],
      );
    }
  }, [props?.props?.options?.colors, parsedLabel]);

  return parsedLabel && colors ? (
    <div
      className={'status-badge'}
      style={{ backgroundColor: colors.bgcolor, color: colors.label }}
    >
      <div
        className={'status-badge-dot'}
        style={{ backgroundColor: colors.dot }}
      />
      {parsedLabel}
    </div>
  ) : null;
};
