import {
  ContactAddressDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  ContactAddressDtoPagedResult,
  CreateContactAddressCommandValues,
  UpdateContactAddressCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CONTACTADDRESSES_LINK_KEY = 'get-contactaddresses';
export const CREATE_CONTACTADDRESS_LINK_KEY = 'create-contactaddress';
export const GET_CONTACTADDRESS_LINK_KEY = 'get-contactaddress';
export const UPDATE_CONTACTADDRESS_LINK_KEY = 'update-contactaddress';
export const DELETE_CONTACTADDRESS_LINK_KEY = 'delete-contactaddress';
export const IMPORT_CONTACTADDRESSES_LINK_KEY = 'import-contactaddresses';
export const EXPORT_CONTACTADDRESSES_LINK_KEY = 'export-contactaddresses';
export const CONTACTADDRESS_ENTITY_NAME = 'Contact Address';

export interface GetContactAddressParams {
  contactAddressId: number;
}

export interface ImportContactAddressesParams {
  organizationId: number;
}

export interface ExportContactAddressesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getContactAddressRequest = async (
  resource: LinkResourceBaseDto | null,
  { contactAddressId }: GetContactAddressParams,
): Promise<ContactAddressDto> => {
  if (resource && contactAddressId) {
    const getContactAddressLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTADDRESS_LINK_KEY,
    );
    if (getContactAddressLink) {
      const result = await execLink(getContactAddressLink, {
        contactAddressId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getContactAddressesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContactAddressDtoPagedResult> => {
  if (resource) {
    const getContactAddressesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTADDRESSES_LINK_KEY,
    );
    if (getContactAddressesListLink) {
      const result = await execLink(getContactAddressesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTACTADDRESSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createContactAddressRequest = async (
  resource: LinkResourceBaseDto,
  contactAddress: CreateContactAddressCommandValues,
) => {
  const createContactAddressLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTACTADDRESS_LINK_KEY,
  );
  if (createContactAddressLink) {
    const result = await execLink(createContactAddressLink, contactAddress);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateContactAddressRequest = async (
  resource: LinkResourceBaseDto,
  contactAddress: UpdateContactAddressCommandValues,
) => {
  const updateContactAddressLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTACTADDRESS_LINK_KEY,
  );
  if (updateContactAddressLink) {
    const result = await execLink(updateContactAddressLink, contactAddress);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteContactAddressRequest = async (
  contactAddress: ContactAddressDto,
) => {
  const deleteContactAddressLink = contactAddress.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTACTADDRESS_LINK_KEY,
  );
  if (deleteContactAddressLink) {
    const result = await execLink(deleteContactAddressLink, contactAddress);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importContactAddressesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportContactAddressesParams,
  file: File,
) => {
  const importContactAddressesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CONTACTADDRESSES_LINK_KEY,
  );
  if (importContactAddressesLink) {
    const result = await postFormData(importContactAddressesLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CONTACTADDRESSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportContactAddressesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportContactAddressesParams,
) => {
  const exportContactAddressesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CONTACTADDRESSES_LINK_KEY,
  );
  if (exportContactAddressesLink) {
    const result = await downloadLinkFile(exportContactAddressesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CONTACTADDRESSES_LINK_KEY} is not allowed or missing link action`,
  );
};
