import React, { useEffect, useState } from 'react';
import {
  AccountingTransactionPaymentDto,
  CurrencyDto,
  PaymentDto,
  AccountingTransactionStatus,
  AccountingTransactionDto,
  Column,
} from '../../../models/data.models';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Pagination } from '../../common/components/pagination/pagination.component';
import {
  getAccountingTransactionsFx,
  updateAccountingTransactionsColumns,
} from '../../accountingTransactions/accountingTransactions.store';
import { showDialog } from '../../common/dialog.store';
import { InvoiceDialog } from '../../invoice/components/invoice.dialog';
import { Input } from '../../common/components/input/input.component';
import { useFormikContext } from 'formik';
import {
  getFormattedDate,
  getFormattedPrice,
} from '../../../utils/formatting.utils';

export type AccountingTransactionPaymentsListProps = {
  applyToContactId?: number;
  onChange: (
    accountingTransactionPayments: AccountingTransactionPaymentDto[],
  ) => void;
  accountingTransactionPayments: AccountingTransactionPaymentDto[];
  setAccountingTransactionPayments: React.Dispatch<
    React.SetStateAction<AccountingTransactionPaymentDto[]>
  >;
  isCreateMode: boolean;
  selectedFilter?: string;
};

export const AccountingTransactionPaymentsListComponent = ({
  applyToContactId = null,
  onChange = (data) => {},
  accountingTransactionPayments = [],
  setAccountingTransactionPayments = (data) => {},
  isCreateMode,
  selectedFilter = '',
}: AccountingTransactionPaymentsListProps) => {
  const rowKeys = ['accountingTransactionId'];
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyDto>(null);

  const columns: Column[] = [
    {
      name: 'transactionNumber',
      visible: true,
      title: 'Transaction No',
      sortName: 'transactionNumber',
    },
    {
      name: 'dueDate',
      visible: true,
      title: 'Due Date',
      sortName: 'dueDate',
    },
    {
      name: 'totalAmount',
      visible: true,
      title: 'Amount',
    },
    {
      name: 'amountDue',
      visible: true,
      title: 'Payment Due',
    },
  ];

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(null);
  const [sort, setSort] = useState('transactionNumber');
  const [filter, setFilter] = useState(selectedFilter);
  const [search, setSearch] = useState('');
  const context = useFormikContext<PaymentDto>();

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const onFilter = (query: string) => {
    setFilter(query);
  };

  const onSort = (field: string) => {
    setSort(field);
  };

  const onSearch = (query: string) => {
    setSearch(query);
  };

  useEffect(() => {
    getAccountingTransactionsData();
  }, [offset, limit, sort, filter, search, applyToContactId]);

  useEffect(() => {
    setOffset(0);
  }, [applyToContactId]);

  useEffect(() => {
    setSelectedCurrency(context.values.currency);
  }, [context.values.currency]);

  const getAccountingTransactionsData = () => {
    if (applyToContactId) {
      const filterAddition = `applyToContactID: ${applyToContactId}`;
      const resultFilter = filter
        ? `${filter} AND ${filterAddition}`
        : filterAddition;
      getAccountingTransactionsFx({
        offset,
        limit,
        sort,
        filter: resultFilter,
        search,
      }).then(
        (accountingTransactionsData) => {
          const accountingTransactions = accountingTransactionsData.items.map(
            (item) => {
              item.dueDate = getFormattedDate(item.dueDate, false);
              return item;
            },
          );

          let accountingTransactionsToRender: AccountingTransactionDto[];
          if (isCreateMode) {
            accountingTransactionsToRender = accountingTransactions.filter(
              (accountingTransaction) => {
                return (
                  accountingTransaction.accountingTransactionStatus ===
                    AccountingTransactionStatus.Open &&
                  accountingTransaction.amountDue !== 0
                );
              },
            );
          } else {
            accountingTransactionsToRender = accountingTransactions.filter(
              (accountingTransaction) => {
                return accountingTransactionPayments.some(
                  (accountingTransactionPayment) =>
                    accountingTransactionPayment.accountingTransactionId ===
                    accountingTransaction.accountingTransactionId,
                );
              },
            );
          }
          setData(accountingTransactionsToRender);
          setTotal(accountingTransactionsToRender.length);
        },
        () => {},
      );
    } else {
      setData([]);
      setTotal(0);
    }
  };

  const colByName: { [key: string]: any } = {};
  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return updateAccountingTransactionsColumns(columns);
  };
  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col?.name === colName)?.sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateSearch = (event) => {
    return onSearch(event.target.value);
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const onInvoiceSelect = (invoice, invoiceId) => {
    showDialog({
      dialog: InvoiceDialog,
      props: {
        accountingTransactionId: invoice.accountingTransactionId,
        title: 'Update Invoice',
        className: '',
      },
    }).then(
      (invoice) => {
        if (invoice !== null) {
          getAccountingTransactionsData();
        }
      },
      () => {},
    );
  };

  const onSelectionChanged = (
    accountingTransactionId,
    transactionNumber,
    amountDue,
    value,
  ) => {
    if (value) {
      setAccountingTransactionPayments((accountingTransactionPayments) => {
        const newAccountingTransactionPayment: AccountingTransactionPaymentDto = {
          accountingTransactionId,
          transactionNumber,
          amountApplied: amountDue,
          amountDue,
        };
        onChange(accountingTransactionPayments);
        context.setFieldValue(
          'amountPaid',
          context.values.amountPaid + amountDue,
          true,
        );
        context.setFieldTouched('amountReceived', true, true);
        context.setFieldValue('accountingTransactionPayments', [
          ...accountingTransactionPayments,
          newAccountingTransactionPayment,
        ]);
        return [
          ...accountingTransactionPayments,
          newAccountingTransactionPayment,
        ];
      });
    } else {
      setAccountingTransactionPayments((accountingTransactionPayments) => {
        accountingTransactionPayments = accountingTransactionPayments.filter(
          (item) => item.accountingTransactionId !== accountingTransactionId,
        );
        onChange(accountingTransactionPayments);
        const amountPaid = accountingTransactionPayments?.reduce(
          (prev, cur) => prev + cur.amountApplied,
          0,
        );
        context.setFieldValue('amountPaid', amountPaid, true);
        context.setFieldTouched('amountReceived', true, true);
        context.setFieldValue(
          'accountingTransactionPayments',
          accountingTransactionPayments,
        );
        return [...accountingTransactionPayments];
      });
    }
  };

  const onAmountChanged = (accountingTransactionId, value) => {
    setAccountingTransactionPayments((accountingTransactionPayments) => {
      const accountingTransactionPayment = accountingTransactionPayments.find(
        (item) => item.accountingTransactionId === accountingTransactionId,
      );
      accountingTransactionPayment.amountApplied = value;
      onChange(accountingTransactionPayments);
      const amountPaid = accountingTransactionPayments?.reduce(
        (prev, cur) => prev + cur.amountApplied,
        0,
      );
      context.setFieldValue('amountPaid', amountPaid, false);
      context.setFieldTouched('amountReceived', true, false);
      context.setFieldValue(
        'accountingTransactionPayments',
        accountingTransactionPayments,
      );
      return [...accountingTransactionPayments];
    });
  };

  return (
    <div
      className={`grid outstanding-transactions`}
      style={{ paddingTop: '35px' }}
    >
      <div style={{ fontWeight: 700, fontStyle: '14px' }}>
        Outstanding Transactions
      </div>
      <div className={'grid-toolbar d-flex my-2'}>
        {onSearch ? (
          <input
            type="search"
            className={'form-control my-2'}
            placeholder="Search"
            onChange={updateSearch}
            value={search}
          />
        ) : null}
      </div>
      <div
        className="bg-white"
        style={{ borderRadius: '0.3125rem', border: '1px solid #E8ECEF' }}
      >
        <table className="table">
          <thead>
            <tr>
              <th />
              {columns
                .filter((col) => {
                  if (col.visible) {
                    return true;
                  }
                  return col.visible && rowKeys?.includes(col.name);
                })
                .map((col) => (
                  <th scope="col" key={col.name}>
                    <a
                      className={col.sortName ? 'link' : 'inactive-link'}
                      onClick={() =>
                        col.sortName ? updateSort(col.name) : null
                      }
                    >
                      {col.title}
                      {sort === col.name || sort === col.sortName ? (
                        <FaArrowDown />
                      ) : null}
                      {sort === '-' + col.name ||
                      sort === '-' + col.sortName ? (
                        <FaArrowUp />
                      ) : null}
                    </a>
                  </th>
                ))}
              <th>
                <a className="inactive-link">Payment</a>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={rowKeys?.map((x) => row[x]).join('_')}>
                <td>
                  <label
                    key={row.accountingTransactionId}
                    className="pointer d-flex align-items-center"
                  >
                    <input
                      className={'pointer'}
                      type={'checkbox'}
                      checked={accountingTransactionPayments.some(
                        (item) =>
                          item.accountingTransactionId ===
                          row.accountingTransactionId,
                      )}
                      onChange={(value) =>
                        onSelectionChanged(
                          row.accountingTransactionId,
                          row.transactionNumber,
                          row.amountDue,
                          value.target.checked,
                        )
                      }
                      disabled={!isCreateMode}
                    />
                  </label>
                </td>
                {Object.values(columns)
                  .filter((item) => {
                    if (item.visible) {
                      return true;
                    }
                    return item.visible && rowKeys?.includes(item.name);
                  })
                  .map((item, index) => (
                    <td
                      key={`${rowKeys?.map((x) => row[x]).join('_')}_${
                        item.name
                      }`}
                      onClick={() => {
                        onInvoiceSelect(
                          row,
                          rowKeys?.reduce((keyObj, field) => {
                            return row[field];
                          }),
                        );
                      }}
                      className={'cursor-pointer'}
                    >
                      {typeof row[item.name] === 'boolean' ? (
                        row[item.name] ? (
                          <>&#x2713;</>
                        ) : (
                          <></>
                        )
                      ) : (
                        <>
                          {item.name === 'totalAmount' ||
                          item.name === 'amountDue'
                            ? getFormattedPrice(
                                row[item.name],
                                selectedCurrency?.decimalPlaces,
                                selectedCurrency?.symbol,
                              )
                            : row[item.name]}
                        </>
                      )}
                    </td>
                  ))}
                <td className={'p-0'}>
                  <label
                    key={row.accountingTransactionId}
                    className="pointer align-items-center d-flex m-0"
                  >
                    <Input
                      className={'my-auto'}
                      required={true}
                      type={'input-OnChange-number-with-value'}
                      name={row.accountingTransactionId}
                      valueInput={
                        accountingTransactionPayments.find(
                          (item) =>
                            item.accountingTransactionId ==
                            row.accountingTransactionId,
                        )?.amountApplied
                      }
                      onChange={(value) => {
                        onAmountChanged(row.accountingTransactionId, value);
                      }}
                      disabled={
                        !accountingTransactionPayments.some(
                          (item) =>
                            item.accountingTransactionId ===
                            row.accountingTransactionId,
                        ) || !isCreateMode
                      }
                    />
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.length == 0 ? (
        <p className="text-center mt-4">No Invoices Found</p>
      ) : (
        <></>
      )}
      {total > limit ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
