import { parseTemplate } from '../../components/layout/component-hooks';

export const forEachAction = async ({
  actionProps,
  data,
  latestStoreValues,
  source,
  onAction,
}) => {
  const forEachProps = actionProps?.forEach;

  if (!forEachProps) {
    throw new Error('forEach action requires forEach property');
  }

  const { items, actions, item = 'item' } = actionProps.forEach;

  const itemCollection = parseTemplate(items, {
    ...latestStoreValues,
    ...data,
  });

  if (!itemCollection) return true;

  if (!Array.isArray(itemCollection)) {
    throw new Error('forEach action requires items to be an array');
  }

  await Promise.all(
    itemCollection?.map(async (itemData) => {
      const itemStoreValues = { ...latestStoreValues, [item]: itemData };

      await Promise.all(
        actions.map((action) =>
          onAction(action, data, itemStoreValues, source),
        ),
      );
    }) ?? [],
  );

  return true;
};
