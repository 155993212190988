import { Dialog } from '../../common/components/dialog/dialog.component';
import { EventDefinitionEdit } from './eventDefinition-edit.component';
import { EventDefinitionDto } from '../../../models/data.models';

export type EventDefinitionDialogProperties = {
  eventDefinitionId?: number | null;
  title: string;
};

export class EventDefinitionDialog extends Dialog<EventDefinitionDialogProperties> {
  constructor(props: EventDefinitionDialogProperties) {
    super(props);
    this.onEventDefinitionSaved = this.onEventDefinitionSaved.bind(this);
  }

  onEventDefinitionSaved = (eventDefinition: EventDefinitionDto) => {
    this.close(eventDefinition);
  };

  renderContent(): any {
    const { eventDefinitionId } = this.props as EventDefinitionDialogProperties;
    return (
      <EventDefinitionEdit
        eventDefinitionId={eventDefinitionId}
        onEventDefinitionCreated={this.onEventDefinitionSaved}
        onEventDefinitionUpdated={this.onEventDefinitionSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
