import React, {
  useState,
  CSSProperties,
  createContext,
  useContext,
  useEffect,
} from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import { Button } from '../button/button.component';
import { showDialog } from '../../../common/dialog.store';
import { ContactDialog } from '../../../contacts/components/contact.dialog';
import { Pagination } from '../pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import {
  Column,
  LinkDto,
  OrderTypes,
  SummaryItems,
} from '../../../../models/data.models';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import {
  FilteredField,
  FiltersTab,
  GridContextValue,
} from '../filters/filtersTab.component';
import lodash from 'lodash';
import { TotalItems } from '../totalItems/totalItems';
import { IAction } from '../actions/actions';
import { useScanner } from '../../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../../barcodeScanner/scanner.store';

export type GridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  actions?: IAction[];
  filters?: FilteredField[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  viewName?: string;
  data?: any[];
  links?: LinkDto[];
  showPagination?: boolean;
  className?: string;
  summary?: SummaryItems | undefined;
  style?: CSSProperties;
  contactType?: string;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSelect?: (item: any, keys: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  disableDots?: boolean;
  isTab?: boolean;
  parentId?: number;
  hasProgressBar?: boolean;
  addButtonRenderCondition?: boolean;
  isSystemCustomField?: (row: any) => {};
  onRecreateSystemCustomFields?: () => void;
  showToolbar?: boolean;
  showGridPanel?: boolean;
  defaultColumns?: Column[];
  defaultSort?: string;
  defaultLimit?: number;
  enableScanner?: boolean;
  entityType?: string;
  additionalTotal?: number;
  isIntegratedGrid?: boolean;
};

const GridContext = createContext<GridContextValue | null>(null);

export const useGridContext = (): GridContextValue => {
  const contextValue = useContext(GridContext);

  if (!contextValue) {
    throw new Error(
      'useGridContext must be used within a GridContext.Provider',
    );
  }

  return contextValue;
};

export const Grid = ({
  data = [],
  actions = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  viewName = null,
  search = '',
  summary,
  style = {},
  showPagination = true,
  contactType,
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  getContactsData,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
  loading,
  disableDots,
  isTab,
  parentId,
  hasProgressBar,
  addButtonRenderCondition = true,
  isSystemCustomField,
  onRecreateSystemCustomFields,
  showToolbar = true,
  showGridPanel = false,
  defaultColumns,
  defaultSort,
  defaultLimit,
  enableScanner = false,
  entityType = null,
  additionalTotal,
  isIntegratedGrid = false,
}: GridProps) => {
  const colByName: { [key: string]: any } = {};

  const [gridColumns, setGridColumns] = useState<Column[]>([
    ...columns.filter((col) => !!col),
  ]);
  const [filters, setFilters] = useState<FilteredField[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<FilteredField[]>([]);

  const onColumnsChangedHandler = (columnsChanged: Column[]): void => {
    setGridColumns([...columnsChanged.filter((col) => !!col)]);
  };

  useEffect(() => {
    if (columns.length === 0) return;
    setGridColumns([...columns.filter((col) => !!col)]);
  }, [columns]);

  useEffect(() => {
    setFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: filters.find((y) => y.name === x.name)?.visible ?? false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
    setDefaultFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
  }, [gridColumns]);

  const updateCols = (colName: string) => {
    const newGridColumns = gridColumns.map((x) => {
      const col = x;
      if (x.name === colName) {
        x.visible = !col.visible;
      }
      return x;
    });
    setGridColumns(newGridColumns);
  };

  const updateSort = (colName: string) => {
    const sortName = gridColumns.find((col) => col?.name === colName)?.sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.startsWith('-') ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (updateFilters: any) => {
    return onFilter(getSearchQuery(updateFilters));
  };

  const getFilteredRowsFilter = (): Column[] => {
    return gridColumns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  const updateSearch = (event) => {
    return onSearch(event.target.value);
  };

  gridColumns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const onFiltersChangedHandler = (filtersChanged: FilteredField[]): void => {
    if (filtersChanged) setFilters([...filtersChanged.filter((f) => !!f)]);
  };

  const [gridContext, setGridContext] = useState<GridContextValue>({
    columns: gridColumns,
    filters,
    search,
    defaultColumns,
    defaultFilters,
    defaultSort,
    defaultLimit,
    onSearch,
    onFilter,
    onColumnsChanged: onColumnsChangedHandler,
    onFiltersChanged: onFiltersChangedHandler,
    onViewChanged,
    actions,
    enableScanner,
    entityType: entityType,
  });

  useEffect(() => {
    setGridContext({
      columns: gridColumns,
      filters,
      selectedView: viewName,
      search,
      defaultColumns,
      defaultFilters,
      defaultSort,
      defaultLimit,
      onSearch,
      onFilter,
      onColumnsChanged: onColumnsChangedHandler,
      onFiltersChanged: onFiltersChangedHandler,
      onViewChanged,
      actions,
      enableScanner,
      entityType: entityType,
    });
  }, [gridColumns, filters, search, defaultColumns, defaultFilters, viewName]);

  useScanner((scannerResult: ScanningResult) => {
    if (scannerResult.data && enableScanner) {
      updateSearch(scannerResult.data);
    }
  });

  return (
    <>
      <div className="mx-3">
        {showGridPanel && (
          <>
            {(filters.length > 0 || gridColumns.length > 0) && (
              <GridContext.Provider value={gridContext}>
                <FiltersTab useGridContext={useGridContext} />
              </GridContext.Provider>
            )}
          </>
        )}

        {summary && (
          <div className={'box px-6 py-4 my-4'}>
            <TotalItems summary={summary} />
          </div>
        )}
      </div>
      <div className={`grid mx-3 my-4 ${className}`} style={style}>
        {showToolbar || (isTab && addButtonRenderCondition) ? (
          <div
            className={
              isDropDownList === true
                ? 'grid-toolbar d-flex flex-wrap px-3 justify-content-center'
                : 'grid-toolbar d-flex pl-3'
            }
          >
            {isTab && addButtonRenderCondition ? (
              <div className="offset-9 col-2">
                <Button
                  size={'sm'}
                  color="secondary"
                  className="w-100 h-100"
                  name="create-contact"
                  onClick={() => {
                    showDialog({
                      dialog: ContactDialog,
                      props: {
                        contactId: 0,
                        title: 'Create Contact',
                        isTab: true,
                        parentId: parentId,
                      },
                    }).then((contact) => {
                      if (contact !== null) {
                        getContactsData();
                      }
                    });
                  }}
                >
                  Add New Contact
                </Button>
              </div>
            ) : null}
            {onSearch && showToolbar ? (
              <input
                type="search"
                className={
                  (isDropDownList === true ? 'w-100 col-12 ' : ' ') +
                  'form-control my-2'
                }
                placeholder="Search"
                value={isIntegratedGrid ? search : decodeURIComponent(search)}
                onChange={updateSearch}
              />
            ) : null}
            {onRecreateSystemCustomFields && showToolbar ? (
              <div className="mt-2 w-100 ml-3">
                <Button
                  size="sm"
                  color="secondary"
                  onClick={onRecreateSystemCustomFields}
                >
                  Recreate system fields
                </Button>
              </div>
            ) : null}
            {onFilter && showToolbar ? (
              <div
                className={
                  isDropDownList === true
                    ? 'dropdown dropdown-columns my-3 px-auto mx-auto w-auto col-auto'
                    : 'dropdown dropdown-columns my-3 px-3 w-100'
                }
              >
                {gridColumns.some((column) => column.showFilter) && (
                  <span
                    className="dropdown-toggle pointer"
                    id="dropdownFilterButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <BsFilter />
                    &nbsp;
                    <small className="align-middle pl-1 text-uppercase">
                      Filters
                    </small>
                    &nbsp;
                    <small className="align-middle text-primary">
                      {getReadableQueryString(filter, getFilteredRowsFilter())}
                    </small>
                  </span>
                )}
                {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
                  <div
                    className="dropdown-menu px-5"
                    aria-labelledby="dropdownFilterButton"
                  >
                    <div>
                      <Filters
                        query={filter}
                        columns={getFilteredRowsFilter()}
                        onFilter={updateFilter}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {hideColumnsSelect ? null : (
              <div
                className={
                  isDropDownList === true
                    ? 'dropdown dropdown-columns my-3 px-3 mx-auto pointer col-auto'
                    : 'dropdown dropdown-columns my-3 px-3 ml-auto pointer'
                }
              >
                <div
                  className="dropdown-toggle"
                  id="dropdownColumnsButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BsListTask className="" />
                  &nbsp;
                  <small className="align-middle pl-1 text-uppercase">
                    Columns
                  </small>
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownColumnsButton"
                  style={{ width: 'max-content', maxWidth: 'unset' }}
                >
                  {gridColumns
                    .filter((item) => {
                      if (showAllStore) {
                        return true;
                      }
                      return rowKeys?.includes(item.name);
                    })
                    .map((col) => {
                      return (
                        <label key={col.name} className="dropdown-item pointer">
                          <input
                            type="checkbox"
                            onChange={() => updateCols(col.name)}
                            defaultChecked={col.visible}
                          />{' '}
                          {col.title}
                        </label>
                      );
                    })}
                </div>
              </div>
            )}
            {actionBar && showToolbar && (
              <div className={'mr-3 py-2'}>{actionBar}</div>
            )}
          </div>
        ) : null}

        {total > 0 || showEmptyTable === true ? (
          <div className="bg-white horizontal-scrollbar">
            <table className="table">
              <thead>
                <tr>
                  {gridColumns
                    .filter((col) => {
                      if (showAllStore && col.visible) {
                        return true;
                      }
                      return col.visible && rowKeys?.includes(col.name);
                    })
                    .map((col) => {
                      return (
                        <th
                          scope="col"
                          key={col.name}
                          style={
                            col.name === 'currencySymbol'
                              ? { textAlign: 'center' }
                              : {}
                          }
                        >
                          <a
                            className={col.sortName ? 'link' : 'inactive-link'}
                            onClick={() =>
                              col.sortName ? updateSort(col.name) : null
                            }
                          >
                            {col.title}
                            {sort === col.name ||
                            sort === col.sortName ||
                            sort === col.sortName + '~ToInt32' ? (
                              <FaArrowDown />
                            ) : null}
                            {sort === '-' + col.name ||
                            sort === '-' + col.sortName ||
                            sort === '-' + col.sortName + '~ToInt32' ? (
                              <FaArrowUp />
                            ) : null}
                          </a>
                        </th>
                      );
                    })}
                  {hasProgressBar && (
                    <th
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <a className={'inactive-link'}>Progress</a>
                    </th>
                  )}
                  <th colSpan={2}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="m-5 text-center">
                    <h3 className="text-muted mb-4">Loading...</h3>
                  </div>
                ) : (
                  data.map((row, index) => {
                    return (
                      <tr
                        key={
                          rowKeys
                            ? rowKeys?.map((x) => row[x]).join('_')
                            : index
                        }
                      >
                        {Object.values(gridColumns)
                          .filter((item) => {
                            if (showAllStore && item.visible) {
                              return true;
                            }
                            return item.visible && rowKeys?.includes(item.name);
                          })
                          .map((item, index) => {
                            const itemValue = lodash.get(row, item.name);
                            return (
                              <td
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={() => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                                className={'cursor-pointer'}
                                style={
                                  item.name === 'currencySymbol'
                                    ? { textAlign: 'center' }
                                    : {
                                        width: `calc(100% / ${
                                          gridColumns.filter(
                                            (column) => column.visible === true,
                                          ).length
                                        })`,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }
                                }
                              >
                                {typeof itemValue === 'boolean' ? (
                                  itemValue ? (
                                    <>&#x2713;</>
                                  ) : (
                                    <></>
                                  )
                                ) : item.name === 'created' ||
                                  item.name === 'lastModified' ||
                                  item.name === 'updated' ||
                                  item.type === 'date' ||
                                  item.type === 'Date' ? (
                                  getFormattedDate(
                                    (itemValue as string)?.split(',')[0],
                                    false,
                                  )
                                ) : typeof itemValue !== 'object' ? (
                                  <>{itemValue}</>
                                ) : item.type === 'List' ? (
                                  <>
                                    <span>
                                      {row?.[item.name]?.length
                                        ? row?.[item.name]?.map((x, index) => (
                                            <span
                                              key={index}
                                              style={{
                                                borderRadius: '4px',
                                                fontSize: '0.825rem',
                                                backgroundColor:
                                                  'rgba(211, 211, 211, 0.3)',
                                                padding: '3px',
                                                marginRight: '4px',
                                              }}
                                            >
                                              {row?.[item.name]?.[index]}
                                            </span>
                                          ))
                                        : ''}
                                    </span>
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </td>
                            );
                          })}
                        {hasProgressBar ? (
                          <td
                            style={{
                              cursor: 'pointer',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() => {
                              if (onSelect) {
                                onSelect(
                                  row,
                                  rowKeys?.reduce((keyObj, field) => {
                                    return row[field];
                                  }),
                                );
                              }
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {(() => {
                                const allCommoditiesLength =
                                  row.orderCommoditiesWithStatus?.length;
                                const completedCommoditiesLength = row.orderCommoditiesWithStatus?.filter(
                                  (x) => x.customValues['activityStatus'],
                                ).length;

                                const percent =
                                  completedCommoditiesLength > 0
                                    ? Math.floor(
                                        (completedCommoditiesLength * 100) /
                                          allCommoditiesLength,
                                      )
                                    : 0;

                                switch (row.customValues['movementType']) {
                                  case 'PutAway':
                                  case 'Picking':
                                    return (
                                      <>
                                        <div className="mr-3">
                                          <div
                                            className="progress"
                                            style={{
                                              width: '244px',
                                            }}
                                          >
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: `${percent}%`,
                                                borderRadius: '15px',
                                                backgroundColor:
                                                  percent == 100 && '#44B284',
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                        <div>{`${percent}%`}</div>
                                      </>
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </div>
                          </td>
                        ) : null}
                        <td style={{ textAlign: 'center' }}>
                          <div className="dropdown">
                            <BsThreeDotsVertical
                              className="dropdown-toggle dropdown-dots-vertical pointer"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            />
                            <div
                              className="dropdown-menu dropdown-menu-grid"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {onDelete &&
                                (isSystemCustomField != undefined ? (
                                  !isSystemCustomField(row) && (
                                    <a
                                      role="button"
                                      className="dropdown-item"
                                      onClick={() => {
                                        onDelete(row);
                                      }}
                                    >
                                      Delete
                                    </a>
                                  )
                                ) : (
                                  <a
                                    role="button"
                                    className="dropdown-item"
                                    onClick={() => {
                                      onDelete(row);
                                    }}
                                  >
                                    Delete
                                  </a>
                                ))}
                              {!disableDots && (
                                <a
                                  role="button"
                                  className="dropdown-item"
                                  onClick={() => {
                                    onEdit(row);
                                  }}
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center mt-4">Nothing Found</p>
        )}
        {showPagination === true ? (
          <div className="d-flex justify-content-center grid-footer">
            <Pagination
              goToPage={onPageChanged}
              offset={offset}
              limit={limit}
              total={total}
              additionalTotal={additionalTotal}
              onLimitChanged={onLimitChanged}
              itemsName={
                entityType === OrderTypes.CargoMovement ? 'packages' : null
              }
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
