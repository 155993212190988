import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  CustomFieldDto,
  CustomFieldDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  customFieldStore,
  getCustomFieldsColumnsFx,
  getCustomFieldsFx,
  deleteCustomFieldFx,
  exportCustomFieldsFx,
  importCustomFieldsFx,
  recreateSystemCustomFieldsFx,
} from '../customFields.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_CUSTOMFIELD_LINK_KEY,
  DELETE_CUSTOMFIELD_LINK_KEY,
  IMPORT_CUSTOMFIELDS_LINK_KEY,
  EXPORT_CUSTOMFIELDS_LINK_KEY,
  GetCustomFieldParams,
  RECREATE_SYSTEM_CUSTOMFIELDS_KEY,
} from '../customFields.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { CustomFieldDialog } from './customField.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiRefreshCw, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type CustomFieldsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  goToDetails?: (customFieldParams: GetCustomFieldParams | any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
};

export const CustomFieldsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  goToDetails = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
}: CustomFieldsListProps) => {
  const [
    customFields,
    setCustomFields,
  ] = useState<CustomFieldDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  const {
    customFieldColumns: columns,
    defaultCustomFieldColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(customFieldStore);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  useEffect(() => {
    getCustomFieldsColumnsFx().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  const getActions = (result: CustomFieldDtoPagedResult): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        IMPORT_CUSTOMFIELDS_LINK_KEY,
        'Import',
        <FiUpload />,
        async () => {
          await importCustomFieldsFx({
            file: await getFileFromUser(),
            listResource: result,
          });
          await getCustomFields();
        },
        result?.links,
      ),
      getAction(
        EXPORT_CUSTOMFIELDS_LINK_KEY,
        'Export',
        <FiDownload />,
        () => exportCustomFieldsFx({ listResource: result }),
        result?.links,
      ),
      getAction(
        RECREATE_SYSTEM_CUSTOMFIELDS_KEY,
        'Recreate',
        <FiRefreshCw />,
        async () => {
          await recreateSystemCustomFieldsFx({ listResource: result });
          await getCustomFields();
        },
        result?.links,
      ),
    ];
    return activeActions.filter(Boolean) as IAction[];
  };

  const getCustomFields = async () => {
    const customFieldsData = await getCustomFieldsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });

    if (customFieldsData) {
      setActions(getActions(customFieldsData));
      setCustomFields(customFieldsData);
      setGetDataSuccess(true);
    }
  };

  useEffect(() => {
    getCustomFields();
  }, [offset, limit, sort, filter, search]);

  const onDeleteCustomField = async (customField: CustomFieldDto) => {
    if (userHas(DELETE_CUSTOMFIELD_LINK_KEY, customFields?.items[0]?.links)) {
      const result = await showDialog({
        dialog: Confirm,
        props: {
          title: `Delete Custom field`,
          message: 'Are you sure you want to delete custom field?',
          className: 'delete-modal',
        },
      });
      if (result) {
        try {
          await deleteCustomFieldFx(customField);
          getCustomFields();
          onDelete();
        } catch (error) {
          // Handle error here
        }
      }
    }
  };

  const onEditCustomField = (customField: CustomFieldDto) => {
    goToDetails({ fieldId: customField.fieldId });
  };

  const onCreateNewCustomField = () => {
    goToDetails('create');
  };

  const onCustomFieldSelect = async (customField) => {
    const selectedCustomField = await showDialog({
      dialog: CustomFieldDialog,
      props: {
        fieldId: customField.fieldId,
        title: 'Update Custom field',
      },
    });
    if (selectedCustomField !== null) {
      getCustomFields();
    }
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['fieldId']}
          data={customFields.items}
          columns={columns}
          actions={actions}
          offset={offset}
          limit={limit}
          onLimitChanged={onLimitChanged}
          total={customFields.totalCount}
          sort={sort}
          onDelete={onDeleteCustomField}
          onSort={onSort}
          onEdit={onEditCustomField}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onCustomFieldSelect}
          onSearch={onSearch}
          showAllStore={true}
          search={search}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          filter={filter}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {(!customFields || customFields?.totalCount === 0) && !search && (
          <ListElementsNotFound
            entityName="Custom field"
            entityLinkKey={CREATE_CUSTOMFIELD_LINK_KEY}
            onClick={onCreateNewCustomField}
          />
        )}
      </div>
    );
  }
};
