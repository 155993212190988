import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  WarehouseLocationDto,
  WarehouseLocationDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  warehouseLocationStore,
  getWarehouseLocationsColumnsFx,
  getWarehouseLocationsFx,
  deleteWarehouseLocationFx,
  exportWarehouseLocationsFx,
  importWarehouseLocationsFx,
} from '../warehouseLocations.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_WAREHOUSELOCATION_LINK_KEY,
  DELETE_WAREHOUSELOCATION_LINK_KEY,
  IMPORT_WAREHOUSELOCATIONS_LINK_KEY,
  EXPORT_WAREHOUSELOCATIONS_LINK_KEY,
  GetWarehouseLocationParams,
} from '../warehouseLocations.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { WarehouseLocationDialog } from './warehouseLocation.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type WarehouseLocationsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (warehouseLocationParams: any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    warehouseLocation: WarehouseLocationDto,
    warehouseLocationParams: GetWarehouseLocationParams,
  ) => void;
};

export const WarehouseLocationsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: WarehouseLocationsListProps) => {
  const [
    warehouseLocations,
    setWarehouseLocations,
  ] = useState<WarehouseLocationDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    warehouseLocationColumns: columns,
    defaultWarehouseLocationColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(warehouseLocationStore);

  useEffect(() => {
    getWarehouseLocationsColumnsFx();
  }, []);

  const getWarehouseLocations = useCallback(async () => {
    const warehouseLocationsData = await getWarehouseLocationsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(warehouseLocationsData));
    setWarehouseLocations(warehouseLocationsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: WarehouseLocationDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_WAREHOUSELOCATIONS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importWarehouseLocationsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getWarehouseLocations();
          },
          result?.links,
        ),
        getAction(
          EXPORT_WAREHOUSELOCATIONS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportWarehouseLocationsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getWarehouseLocations],
  );

  useEffect(() => {
    getWarehouseLocations();
  }, [getWarehouseLocations]);

  const onDeleteWarehouseLocation = useCallback(
    async (warehouseLocation: WarehouseLocationDto) => {
      if (
        userHas(
          DELETE_WAREHOUSELOCATION_LINK_KEY,
          warehouseLocations?.items[0]?.links,
        )
      ) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Warehouse location`,
            message: 'Are you sure you want to delete warehouse location?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteWarehouseLocationFx(warehouseLocation);
            getWarehouseLocations();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, warehouseLocations],
  );

  const onEditWarehouseLocation = (warehouseLocation: WarehouseLocationDto) => {
    if (warehouseLocation)
      goToDetails({
        warehouseLocationId: warehouseLocation.warehouseLocationId,
      });
  };

  const onWarehouseLocationSelect = useCallback(
    async (warehouseLocation) => {
      const selectedWarehouseLocation = await showDialog({
        dialog: WarehouseLocationDialog,
        props: {
          warehouseLocationId: warehouseLocation.warehouseLocationId,
          title: 'Update Warehouse location',
        },
      });
      if (selectedWarehouseLocation) {
        getWarehouseLocations();
      }
    },
    [getWarehouseLocations],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['warehouseLocationId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={warehouseLocations.items}
          onLimitChanged={onLimitChanged}
          total={warehouseLocations.totalCount}
          onDelete={onDeleteWarehouseLocation}
          onSort={onSort}
          onEdit={onEditWarehouseLocation}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onWarehouseLocationSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {warehouseLocations?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Warehouse location"
            entityLinkKey={CREATE_WAREHOUSELOCATION_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
