import {
  CityDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  CityDtoPagedResult,
  CreateCityCommandValues,
  UpdateCityCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CITIES_LINK_KEY = 'get-cities';
export const CREATE_CITY_LINK_KEY = 'create-city';
export const GET_CITY_LINK_KEY = 'get-city';
export const UPDATE_CITY_LINK_KEY = 'update-city';
export const DELETE_CITY_LINK_KEY = 'delete-city';
export const IMPORT_CITIES_LINK_KEY = 'import-cities';
export const EXPORT_CITIES_LINK_KEY = 'export-cities';
export const CITY_ENTITY_NAME = 'City';

export interface GetCityParams {
  cityId: number;
}

export interface ImportCitiesParams {
  organizationId: number;
}

export interface ExportCitiesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getCityRequest = async (
  resource: LinkResourceBaseDto | null,
  { cityId }: GetCityParams,
): Promise<CityDto> => {
  if (resource && cityId) {
    const getCityLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CITY_LINK_KEY,
    );
    if (getCityLink) {
      const result = await execLink(getCityLink, { cityId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CITY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCitiesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CityDtoPagedResult> => {
  if (resource) {
    const getCitiesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CITIES_LINK_KEY,
    );
    if (getCitiesListLink) {
      const result = await execLink(getCitiesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CITIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCityRequest = async (
  resource: LinkResourceBaseDto,
  city: CreateCityCommandValues,
) => {
  const createCityLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CITY_LINK_KEY,
  );
  if (createCityLink) {
    const result = await execLink(createCityLink, city);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CITY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateCityRequest = async (
  resource: LinkResourceBaseDto,
  city: UpdateCityCommandValues,
) => {
  const updateCityLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CITY_LINK_KEY,
  );
  if (updateCityLink) {
    const result = await execLink(updateCityLink, city);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CITY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteCityRequest = async (city: CityDto) => {
  const deleteCityLink = city.links?.find(
    (x: LinkDto) => x.rel === DELETE_CITY_LINK_KEY,
  );
  if (deleteCityLink) {
    const result = await execLink(deleteCityLink, city);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CITY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importCitiesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportCitiesParams,
  file: File,
) => {
  const importCitiesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CITIES_LINK_KEY,
  );
  if (importCitiesLink) {
    const result = await postFormData(importCitiesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CITIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportCitiesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportCitiesParams,
) => {
  const exportCitiesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CITIES_LINK_KEY,
  );
  if (exportCitiesLink) {
    const result = await downloadLinkFile(exportCitiesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CITIES_LINK_KEY} is not allowed or missing link action`,
  );
};
