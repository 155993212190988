import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  ContainerDescriptionDto,
  ContainerDescriptionDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  containerDescriptionStore,
  getContainerDescriptionsColumnsFx,
  getContainerDescriptionsFx,
  deleteContainerDescriptionFx,
  exportContainerDescriptionsFx,
  importContainerDescriptionsFx,
} from '../containerDescriptions.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_CONTAINERDESCRIPTION_LINK_KEY,
  DELETE_CONTAINERDESCRIPTION_LINK_KEY,
  IMPORT_CONTAINERDESCRIPTIONS_LINK_KEY,
  EXPORT_CONTAINERDESCRIPTIONS_LINK_KEY,
  GetContainerDescriptionParams,
} from '../containerDescriptions.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { ContainerDescriptionDialog } from './containerDescription.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type ContainerDescriptionsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (containerDescriptionParams: any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    containerDescription: ContainerDescriptionDto,
    containerDescriptionParams: GetContainerDescriptionParams,
  ) => void;
};

export const ContainerDescriptionsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: ContainerDescriptionsListProps) => {
  const [
    containerDescriptions,
    setContainerDescriptions,
  ] = useState<ContainerDescriptionDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    containerDescriptionColumns: columns,
    defaultContainerDescriptionColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(containerDescriptionStore);

  useEffect(() => {
    getContainerDescriptionsColumnsFx();
  }, []);

  const getContainerDescriptions = useCallback(async () => {
    const containerDescriptionsData = await getContainerDescriptionsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(containerDescriptionsData));
    setContainerDescriptions(containerDescriptionsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: ContainerDescriptionDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_CONTAINERDESCRIPTIONS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importContainerDescriptionsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getContainerDescriptions();
          },
          result?.links,
        ),
        getAction(
          EXPORT_CONTAINERDESCRIPTIONS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportContainerDescriptionsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getContainerDescriptions],
  );

  useEffect(() => {
    getContainerDescriptions();
  }, [getContainerDescriptions]);

  const onDeleteContainerDescription = useCallback(
    async (containerDescription: ContainerDescriptionDto) => {
      if (
        userHas(
          DELETE_CONTAINERDESCRIPTION_LINK_KEY,
          containerDescriptions?.items[0]?.links,
        )
      ) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Container description`,
            message: 'Are you sure you want to delete container description?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteContainerDescriptionFx(containerDescription);
            await getContainerDescriptions();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getContainerDescriptions, onDelete, containerDescriptions],
  );

  const onEditContainerDescription = (
    containerDescription: ContainerDescriptionDto,
  ) => {
    if (containerDescription)
      goToDetails({
        containerDescriptionCode: containerDescription.containerDescriptionCode,
      });
  };

  const onContainerDescriptionSelect = useCallback(
    async (containerDescription) => {
      const selectedContainerDescription = await showDialog({
        dialog: ContainerDescriptionDialog,
        props: {
          containerDescriptionCode:
            containerDescription.containerDescriptionCode,
          title: 'Update Container description',
        },
      });
      if (selectedContainerDescription) {
        getContainerDescriptions();
      }
    },
    [getContainerDescriptions],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['containerDescriptionCode']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={containerDescriptions.items}
          onLimitChanged={onLimitChanged}
          total={containerDescriptions.totalCount}
          onDelete={
            userHas(
              DELETE_CONTAINERDESCRIPTION_LINK_KEY,
              containerDescriptions?.items[0]?.links,
            )
              ? onDeleteContainerDescription
              : null
          }
          onSort={onSort}
          onEdit={onEditContainerDescription}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onContainerDescriptionSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {containerDescriptions?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Container description"
            entityLinkKey={CREATE_CONTAINERDESCRIPTION_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
