import React from 'react';
import * as Yup from 'yup';
import {
  ChargeDto,
  ChargeStatuses,
  CommodityDto,
  CommodityStatuses,
  ContactType,
  DimensionsUnit,
  ItemType,
  LocationType,
  OrderDto,
  OrderTypes,
  OrganizationDto,
  UserDto,
} from '../../../../models/data.models';
import { GetMovementByPalletNumberParams } from '../../../orders/orders.service';
import { getCargoMovementByPalletNumber } from '../../../orders/orders.store';
import { Button } from '../../../common/components/button/button.component';
import { getWarehouseLocationsFx } from '../../../warehouseLocations/warehouseLocations.store';
import { updateCommodities } from '../../../commodities/commodities.store';
import {
  CommodityDefaultValues,
  OrderDefaultValues,
} from '../../../common/DefaultValues';
import { addMessage, Message } from '../../../common/messages.store';
import { useFormikContext } from 'formik';
import {
  linkCommodities,
  setSubmitAction,
} from '../../../parcelShipments/packingWizard/packingWizard.component';
import { PackingForm } from '../../../parcelShipments/packingWizard/packing.form';
import { v4 } from 'uuid';
import { triggerWorkflowByConfig } from '../../../workflowTriggers/workflowTriggerHook';

const commodityParams: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  commodityTypeId: null,
  commodityTypeCode: CommodityDefaultValues.description,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  customValues: CommodityDefaultValues.customValues,
};

export type PalletProps = {
  locationType: LocationType;
  currentLocation: LocationType;
  buttonName: string;
  buttonColor?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light'
    | 'action-waiting'
    | 'action-secondary'
    | 'dark';
  buttonOutline?: boolean;
  label: string;
};

export const palletSchema = (
  locationType: LocationType,
  currentLocation: LocationType,
) => {
  return {
    [`${locationType}PalletNumber`]: Yup.string()
      .typeError("Can't be blank")
      .when('_submitAction', (val, schema) => {
        return val === currentLocation
          ? schema.required("Can't be blank")
          : schema.nullable();
      }),
  };
};

const handleCargoMovement = (currentLocation: LocationType) => async (
  params: GetMovementByPalletNumberParams,
  createdCommodities?: CommodityDto[],
) => {
  const resultCargoMovement = await getCargoMovementByPalletNumber(params);
  if (resultCargoMovement) {
    const resultLinkCommodities = await linkCommodities(
      resultCargoMovement,
      createdCommodities,
    );
    const resultWarehouseLocations = await getWarehouseLocationsFx({
      filter: `locationType:${currentLocation}`,
    });

    if (resultWarehouseLocations?.items.length > 0) {
      const updatedCommodities = createdCommodities.map((commodity) => {
        return {
          ...commodity,
          commodityStatus: null,
          warehouseLocationId:
            resultWarehouseLocations.items[0].warehouseLocationId,
        };
      });

      await updateCommodities(updatedCommodities);
      const message: Message = {
        id: 'receiving-success',
        type: 'success',
        autoHide: true,
        message: 'Completed successfully!',
      };
      addMessage(message);
    }
  }
};

export const onSendToService = (
  onComplete: (data?: any, handleBack?: () => void) => Promise<void>,
  handleBack: () => void,
  locationType: LocationType,
  currentLocation: LocationType,
  currentUser?: UserDto,
  currentOrganization?: OrganizationDto,
) => async (data: any) => {
  try {
    const params: GetMovementByPalletNumberParams = {
      palletNumber: data[`${locationType}PalletNumber`],
      locationType: locationType,
      employeeContactId: data?.defaultDispatcher?.dispatcherId,
      divisionId: currentUser?.divisionId,
      movementType: locationType,
    };

    const workflowExecutionVariables = {
      cargoMovementByPalletQuery: params,
      commodities: data?.purchase?.commodities,
    };

    const result = await triggerWorkflowByConfig({
      configName: 'tms.modules.packing',
      workflowIdConfigKey: 'serviceWorkflowId',
      workflowVariables: workflowExecutionVariables,
    });

    if (result) {
      const messageReason: Message = {
        id: 'status-set-problem',
        type: 'success',
        autoHide: true,
        message: 'Status set to Problem with reason ' + result.outputs.reason,
      };
      addMessage(messageReason);

      await onComplete(
        { commodities: data?.purchase?.commodities },
        handleBack,
      );
    }
  } catch (error) {
    addMessage({
      message: error?.message || error,
      type: 'danger',
      autoHide: true,
      id: v4(),
    });
  }
};

export const getOrderInitialState = (orderContextValues?: any) => {
  const initialState: OrderDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: null,
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderId: null,
    billToContactId: orderContextValues?.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    orderEntities: [],
    links: [],
  };
  return initialState;
};

export const palletInitialState = (locationType: string) => {
  const initialState = {
    [`${locationType}PalletNumber`]: sessionStorage.getItem(
      `${locationType}PalletNumber`,
    ),
  };
  return initialState;
};

export const PalletFormikComponent = ({
  locationType,
  buttonName,
  buttonColor = 'primary',
  buttonOutline = false,
  label,
  currentLocation,
}: PalletProps) => {
  const formikContext = useFormikContext<any>();

  return (
    <>
      <div>
        <div>
          <PackingForm.PalletNumber
            label={label}
            name={`${locationType}PalletNumber`}
            onChange={(event) =>
              sessionStorage.setItem(
                `${locationType}PalletNumber`,
                event?.target?.value,
              )
            }
            barcode={true}
          />
        </div>
      </div>
      <div className="align-self-center">
        <Button
          className="mt-3 w-100"
          color={buttonColor}
          outline={buttonOutline}
          style={{ minWidth: '9.5rem' }}
          disabled={
            !formikContext.values?.purchase?.commodities?.length ||
            formikContext.isSubmitting
          }
          isSending={formikContext.isSubmitting}
          onClick={() => {
            setSubmitAction(formikContext, currentLocation);
          }}
        >
          {buttonName}
        </Button>
      </div>
    </>
  );
};
