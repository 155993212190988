import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { HiOutlineArrowSmRight, HiX } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { v4 } from 'uuid';
import { FiEdit2 } from 'react-icons/fi';
import { Button } from '../../common/components/button/button.component';
import { OrderForm } from '../../orders/components/order.form';
import {
  CommodityDto,
  ContactType,
  DimensionsUnit,
  EntityTypes,
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
  PackageTypeDto,
  VolumeUnit,
  WeightUnit,
} from '../../../models/data.models';
import { getOrder, getOrders } from '../../orders/orders.store';
import { OrderEditFormDto } from '../../../models/custom.models';
import { GetCommodityParams } from '../../commodities/commodities.service';
import { showDialog } from '../../common/dialog.store';
import { CommodityDialog } from '../../commodities/components/commodity.dialog';
import { FormContext } from '../../common/components/form/form.component';
import { authStore } from '../../auth/auth.store';
import { CommoditiesForCargoMovementList } from '../../cargoMovement/components/commodities-forCargoMovement-list.component';
import { getCommodityTrackingNumbers } from '../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import { getCommodities } from '../../commodities/commodities.store';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { TotalItems } from '../../common/components/totalItems/totalItems';
import { validatePositiveNumberInput } from '../../../utils/helper.utils';
import { addMessage } from '../../common/messages.store';
import { getPackageTypesFx } from '../../packageTypes/packageTypes.store';
import { useStore } from 'effector-react';
import { ConsolidatedShipmentStateProperties } from './consolidatedShipment.dialog';
import { AirShipmentOrderDialog } from '../../airShipmentOrders/components/airShipmentOrder.dialog';
import { OceanShipmentOrderDialog } from '../../oceanShipment/components/oceanShipmentOrder.dialog';
import { generatePath, useLocation } from 'react-router-dom';
import { AIR_ORDER_EDIT_PATH } from '../../airShipmentOrders/airShipmentOrders.route';
import { INTERNAL_PATH } from '../../../app.router';
import { OCEAN_ORDER_EDIT_PATH } from '../../oceanShipment/oceanShipmentOrders.route';
import { consolidatedShipmentParcelColumns } from '../../parcelShipments/parcelShipments.store';
import { ParcelShipmentDialog } from '../../parcelShipments/components/parcelShipment.dialog';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { commodityStoreForConsolidatedShipment } from '../../commodities/commodities.store';
import {
  executeWorkflowTriggerFx,
  getWorkflowTriggerFx,
} from '../../workflowTriggers/workflowTriggers.store';
import { getOrganizationConfigsFx } from '../../organizationConfigs/organizationConfigs.store';

const destinationCountries = (codes, names) => {
  const countryCodes = codes.split(';');
  const countryNames = names.split(';');

  return countryCodes.map((countryCode, idx) => {
    return {
      countryCode,
      name: countryNames[idx],
    };
  });
};

const checkIncludedCommodities = (
  pallet: CommodityDto,
  newCommodity: CommodityDto,
) => {
  const commodityIndex = pallet.containerCommodities.findIndex(
    (commodity) => commodity.commodityId === newCommodity.commodityId,
  );
  return commodityIndex > -1;
};

export type ConsolidatedShipmentEditProps = {
  orderId: number | null;
  isDialog?: boolean | null;
  onConsolidatedShipmentCreated?: (order: OrderDto) => void;
  onConsolidatedShipmentUpdated?: (order: OrderDto) => void;
  onConsolidatedShipmentLoaded?: (order: OrderDto) => void;
  onEditComplete?: () => void;
  onCancel?: () => void;
  onInvoiceGenerated?: () => void;
  defaultState?: ConsolidatedShipmentStateProperties | null;
  goToDetails?: () => void;
};

const initialCommodityContainer: () => CommodityDto = () => {
  return {
    description: '1',
    containerCommodities: [],
    pieces: 1,
    unit: 1,
    commodityId: 0,
    commodityStatusId: null,
    dimensionsUnit: DimensionsUnit.In,
    height: 0,
    length: 0,
    organizationId: 0,
    packageTypeId: 0,
    packageTypeName: null,
    note: '',
    unitaryValue: 0,
    quantity: 0,
    valueTotal: 0,
    volumePiece: 0,
    volumeTotal: 0,
    volumeUnit: 'Ft',
    weightTotal: 0,
    weight: 0,
    weightByTotal: false,
    weightUnit: WeightUnit.Lb,
    width: 0,
    lastModified: new Date(),
    lastModifiedBy: '',
    created: new Date(),
    createdBy: '',
    valueByTotal: false,
  };
};

const getTotalPcsCrt = (commodities: Array<CommodityDto>) => {
  return commodities?.length ?? 0;
};

const getWeightTotal = (commodities: Array<CommodityDto>) => {
  return (
    commodities?.reduce((weightTotal, commodity) => {
      weightTotal += Number(commodity.weightTotal);
      return weightTotal;
    }, 0) ?? 0
  );
};

const getVolumeTotal = (commodities: Array<CommodityDto>) => {
  return (
    commodities?.reduce((volumeTotal, commodity) => {
      volumeTotal += Number(commodity.volumeTotal);
      return volumeTotal;
    }, 0) ?? 0
  );
};

//validation
let orderSchema = Yup.object().shape({
  billToContactId: Yup.string().notRequired().nullable(true),
  commodities: Yup.array().min(1),
});

const fetchCommodity = async (commodityTrackingNumberId: number) => {
  const commodityTrackingNumbers = await getCommodityTrackingNumbers({
    filter: `CommodityTrackingNumberId:${commodityTrackingNumberId}`,
    limit: 1000,
  });
  const commodityIds = commodityTrackingNumbers.items.map((x) => x.commodityId);
  if (commodityIds?.length > 0) {
    const commodityFilter = commodityIds
      .map((id) => `CommodityId:${id}`)
      .join(' OR ');
    const commodities = (
      await getCommodities({ filter: commodityFilter, limit: 1000 })
    ).items;
    const commodity = commodities?.[0];
    return commodity;
  }
  return null;
};

const initialOrder = (data: OrderDto): OrderDto => {
  const order = { ...data };
  if (order.orderType === OrderTypes.AirShipmentOrder) {
    order.customValues = {
      ...data.customValues,
      arrivePortId: data?.customValues?.arrivePortId,
      arrivePortIdName: data?.customValues?.arrivePortIdName,
      departureDate: data?.customValues?.departureDate,
    };
  } else if (order.orderType === OrderTypes.OceanShipmentOrder) {
    order.customValues = {
      ...data.customValues,
      arrivePortId: data?.customValues?.portOfUnloading,
      arrivePortIdName: data?.customValues?.portOfUnloadingName,
      departureDate: data?.customValues?.arrivalDate,
    };
  }
  (order as any).containerCommodities = order.commodities.flatMap(
    (com) => com.containerCommodities,
  );
  return order;
};

const applyLocationState = (
  data: OrderDto,
  state: ConsolidatedShipmentStateProperties,
  userDivisionId: number,
): OrderDto => {
  const order = {
    ...data,
    orderType: state?.orderType ?? data.orderType,
    divisionId: state?.divisionId ?? data.divisionId ?? userDivisionId,
    currentPallet: state?.currentPallet,
    deliveringCarrier:
      data.orderEntities?.find(
        (x) => x.entityType === EntityTypes.DeliveryCarrier,
      ) || data.orderEntities?.[0],
    finalMileCarrier: data.orderEntities?.find(
      (x) => x.entityType === EntityTypes.DeliveryCarrier,
    ),
    customValues: {
      ...data.customValues,
      destinationCountries:
        state?.countries ?? data.customValues?.destinationCountries,
      modeOfTransportationId:
        state?.modeOfTransportationId ??
        data.customValues?.modeOfTransportationId,
    },
  };
  return order;
};

const fetchPackageTypes = async (setPackType) => {
  const packType = (await getPackageTypesFx({ search: `Pallet` })).items?.find(
    (type) => type.name === 'Pallet',
  );
  setPackType(packType);
};

//component
export const ConsolidatedShipmentEdit = ({
  orderId = 0,
  defaultState,
  isDialog,
  onEditComplete = () => {},
  onCancel = () => {},
  goToDetails = () => {},
}: ConsolidatedShipmentEditProps) => {
  const { user: currentUser } = authStore.getState();
  const { commodityColumns: columns } = useStore(
    commodityStoreForConsolidatedShipment,
  );

  const limit = 20;
  const orderFormRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [packageType, setPackageType] = useState<PackageTypeDto>(null);
  const [
    parcelShipments,
    setParcelShipments,
  ] = useState<OrderDtoPagedResult | null>(null);
  const [lastAddedCommodity, setLastAddedCommodity] = useState<CommodityDto>(
    null,
  );
  const [palletCounter, setPalletCounter] = useState<number>(0);

  const { user } = useStore(authStore);

  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    null,
  );
  const [hasInitialized, setHasInitialized] = useState(false);
  const [parcelsLimit, setParcelsLimit] = useState<number>(5);
  const [parcelsOffset, setParcelsOffset] = useState<number>(0);
  const [parcelsSort, setParcelsSort] = useState('orderNumber');

  useEffect(() => {
    fetchPackageTypes(setPackageType);
  }, []);

  //order custom fields and contacts
  useEffect(() => {
    if (orderId) {
      setIsSending(true);
      getOrder({ orderId })
        .then(
          (orderDto: OrderDto) => {
            const order = initialOrder(orderDto);
            const orderWithState = applyLocationState(
              order,
              defaultState,
              user.divisionId,
            );
            setInitialValues(orderWithState);
            setIsLoading(false);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      throw new Error('Order keys were not provided');
    }
  }, [orderId, defaultState, user, lastAddedCommodity]);

  const goToDetailsCommodity = (commodityParams: GetCommodityParams) => {
    if (commodityParams.commodity) {
      showDialog({
        dialog: CommodityDialog,
        props: {
          title: 'Update Commodity',
          commodity: commodityParams?.commodity,
          commodityId: commodityParams?.commodity?.commodityId,
          className: 'commodity-modal',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          isEditMode: true,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
          }
        },
        () => {},
      );
    }
  };

  const goToParcelDetails = async (commodity) => {
    const orderId = commodity?.shipments?.find(
      (x) => x.orderType === OrderTypes.ParcelShipment,
    )?.orderId;
    if (orderId) {
      await showDialog({
        dialog: ParcelShipmentDialog,
        props: {
          className: 'order-modal',
          title: 'Update Parcel Shipment',
          orderId: orderId,
        },
      });
    }
  };

  const getPortsPart = (value) => {
    if (value)
      return ` AND (OrderEntities.EntityType: ${EntityTypes.Carrier} AND OrderEntities.CustomValues.portId:${value})`;
    return '';
  };

  const getCarrierPart = (value) => {
    if (value && value.contactId)
      return ` AND (OrderEntities.ContactId:${value.contactId} AND OrderEntities.EntityType:${EntityTypes.Carrier})`;
    return '';
  };

  const getModePart = (value) => {
    if (value) return ` AND CustomValues.modeOfTransportationId:${value}`;
    return '';
  };

  const getDivisionPart = (value) => {
    if (value) return ` AND DivisionId:${value}`;
    return '';
  };

  const getCountriesPart = (sortedCountries) => {
    if (sortedCountries) {
      const countriesPart = sortedCountries
        .split(';')
        .join(' OR OrderEntities.ContactAddress.CountryCode:');
      return ` AND (OrderEntities.EntityType: ${EntityTypes.Consignee} AND OrderEntities.ContactAddress.CountryCode:${countriesPart})`;
    }
    return '';
  };

  const getAvailableParcels = async () => {
    if (initialValues) {
      setHasInitialized(true);
      const countriesPart = getCountriesPart(
        initialValues?.customValues?.destinationCountries,
      );
      const modePart = getModePart(
        initialValues?.customValues?.modeOfTransportationId,
      );
      const carrierPart = getCarrierPart(
        (initialValues as any)?.finalMileCarrier,
      );
      const divisionPart = getDivisionPart(initialValues?.divisionId);
      const portPart = getPortsPart(initialValues?.customValues?.arrivePortId);
      const initialFilter = `OrderType:${OrderTypes.ParcelShipment} AND OrderStatus.OrderStatusName: "Ready To Ship"`;
      const filter = `${initialFilter}${countriesPart}${modePart}${divisionPart}${carrierPart}${portPart}`;

      try {
        const parcelShipmentsData = await getOrders({
          offset: parcelsOffset,
          limit: parcelsLimit,
          sort: parcelsSort,
          summary: true,
          filter: filter,
        });

        setParcelShipments(parcelShipmentsData);
      } catch (error) {
        addMessage({
          message: error?.Message || error,
          id: v4(),
          type: 'danger',
        });
      }
    }
  };

  useEffect(() => {
    const getParcels = async () => {
      await getAvailableParcels();
    };
    if (!hasInitialized) getParcels();
  }, [hasInitialized, initialValues]);

  useEffect(() => {
    const getParcels = async () => {
      await getAvailableParcels();
    };
    getParcels();
  }, [parcelsSort, parcelsLimit, parcelsOffset]);

  const onEdit = async () => {
    if (initialValues?.orderId) {
      const editedOrder = await showDialog({
        dialog:
          (initialValues as any).orderType === OrderTypes.AirShipmentOrder
            ? AirShipmentOrderDialog
            : OceanShipmentOrderDialog,
        props: {
          className: 'order-modal',
          title: `Update ${
            (initialValues as any).orderType === OrderTypes.AirShipmentOrder
              ? 'Air'
              : 'Ocean'
          } Shipment`,
          orderId: initialValues.orderId,
        },
      });
      if (editedOrder !== null) {
        onEditComplete();
      }

      onCancel();
    }
  };

  const onTrackingNumberChanged = async (trackingNumber: string) => {
    if (palletCounter === 0) {
      addMessage({
        message: `Enter pallet number`,
        type: 'danger',
        id: v4(),
      });
      return;
    }
    if (trackingNumber) {
      const workflowExecutionVariables = {
        orderId,
        trackingNumber,
        palletNumber: palletCounter,
        packageTypeId: packageType.packageTypeId,
      };
      const organizationConfigs = await getOrganizationConfigsFx({
        filter: `configName:tms.modules.packing`,
        limit: 1,
      });
      if (
        organizationConfigs &&
        organizationConfigs.items &&
        organizationConfigs.items.length > 0
      ) {
        const workflowId = (organizationConfigs.items[0].customValues as {
          shipmentLoadingWorkflowId: string;
        }).shipmentLoadingWorkflowId;

        const workflowTriggerDto = await getWorkflowTriggerFx({ workflowId });
        await executeWorkflowTriggerFx({
          workflowTrigger: workflowTriggerDto,
          variables: workflowExecutionVariables,
        }).then(async (result) => {
          if (result.outputs.commodities.length > 0) {
            addMessage({
              message: `Commodity successfully added. Pallet number ${palletCounter}`,
              type: 'success',
              id: v4(),
            });
            setLastAddedCommodity(initialCommodityContainer());
          } else {
            addMessage({
              message: `Commodity with such tracking number not found`,
              type: 'danger',
              id: v4(),
            });
          }
        });
      }
    } else {
      addMessage({
        message: `Tracking number can't be empty`,
        type: 'danger',
        id: v4(),
      });
    }
  };

  useScanner((scannerResult: ScanningResult) => {
    onTrackingNumberChanged(scannerResult?.data);
  });

  const actionButtons = [
    <Button
      className="mr-4"
      color={'action-primary'}
      onClick={isDialog === true ? onEdit : goToDetails}
      disabled={isSending}
    >
      <div className={'d-flex align-items-center'}>
        <FiEdit2 className={'mr-2'} size={16} />
        Edit
      </div>
    </Button>,
    <Button color={'secondary'} outline onClick={onCancel} disabled={isSending}>
      <div className={'d-flex align-items-center'}>
        <HiX size={16} className={'mr-2'} />
        Cancel
      </div>
    </Button>,
  ];

  const onParcelShipmentSelect = async (parcelShipment) => {
    if (parcelShipment?.orderId) {
      const selectedParcelShipment = await showDialog({
        dialog: ParcelShipmentDialog,
        props: {
          className: 'order-modal',
          title: 'Update Parcel Shipment',
          orderId: parcelShipment?.orderId,
        },
      });
      if (selectedParcelShipment !== null) {
        await getAvailableParcels();
      }
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <div className={'order-edit-form mx-3'}>
      <div className="row">
        <div className="col-5">
          <div className="row p-3 mt-2">
            <h3>{`${
              (initialValues as any)?.orderType === OrderTypes.AirShipmentOrder
                ? 'Air'
                : 'Ocean'
            } Consolidated Shipment # ${initialValues?.orderNumber}`}</h3>
          </div>
        </div>
        <div className="col-7">
          <div className="row d-flex justify-content-end p-3">
            {actionButtons.map((x) => {
              return x;
            })}
          </div>
        </div>
      </div>
      <OrderForm
        id={'order-Form'}
        initialValues={initialValues}
        innerRef={orderFormRef}
        validationSchema={orderSchema}
      >
        <FormContext.Consumer>
          {() => (
            <>
              <div className="d-flex flex-wrap box mb-5 px-5 pb-5">
                <div className={'row w-100 m-auto'}>
                  <div className="col-8">
                    <SelectCountryPaginate
                      readonly={false}
                      selectedSort={'name'}
                      required={false}
                      isMulti={true}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={'Destination Countries'}
                      placeholder={'Destination Countries'}
                      selectedFilter={''}
                      selectedValue={
                        initialValues?.customValues?.destinationCountries
                          ? initialValues?.customValues
                              ?.destinationCountriesName
                            ? destinationCountries(
                                initialValues?.customValues
                                  ?.destinationCountries,
                                initialValues?.customValues
                                  ?.destinationCountriesName,
                              )
                            : initialValues?.customValues?.destinationCountries
                          : []
                      }
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-4">
                    <OrderForm.Port
                      header={'Destination Port'}
                      id={'customValues.arrivePortId'}
                      nameId={'customValues.arrivePortIdName'}
                      defaultValue={
                        initialValues?.customValues?.arrivePortId
                          ? {
                              portId: initialValues?.customValues?.arrivePortId,
                              name:
                                initialValues?.customValues?.arrivePortIdName,
                            }
                          : null
                      }
                      disabled={true}
                      canUpdateEntity={false}
                    />
                  </div>
                  <div className="col-4 carrier-contact-input">
                    <OrderForm.CarrierContactSelect
                      id={'finalMileCarrier.contactId'}
                      nameId={'finalMileCarrier.contactName'}
                      header={'Final Mile Carrier'}
                      contactTypes={[ContactType.Carrier]}
                      selectedFilter={`contactType: ${ContactType.Carrier}`}
                      multiple={false}
                      closeMenuOnSelect={true}
                      showContactType={false}
                      defaultValue={
                        (initialValues as any)?.finalMileCarrier?.contactId
                          ? (initialValues as any)?.finalMileCarrier
                              ?.contactName
                            ? {
                                contactId: (initialValues as any)
                                  ?.finalMileCarrier?.contactId,
                                name: (initialValues as any)?.finalMileCarrier
                                  ?.contactName,
                              }
                            : (initialValues as any)?.finalMileCarrier
                                ?.contactId
                          : null
                      }
                      disabled={true}
                      canUpdateEntity={false}
                    />
                  </div>
                  <div className="col-4">
                    <OrderForm.Date
                      header={'Planned Shipping Date'}
                      nameId={'customValues.departureDate'}
                      defaultValue={initialValues?.customValues?.departureDate}
                      disabled={true}
                    />
                  </div>
                  <div className="col-4">
                    <OrderForm.Division
                      id={'divisionId'}
                      header={'Division'}
                      required={true}
                      defaultValue={
                        initialValues?.divisionId != null
                          ? initialValues?.divisionName != null
                            ? {
                                divisionId: initialValues?.divisionId,
                                divisionName: initialValues?.divisionName,
                              }
                            : initialValues?.divisionId ||
                              currentUser.divisionId
                          : ''
                      }
                      disabled={true}
                      canUpdateEntity={false}
                    />
                  </div>
                </div>
              </div>
              <>
                <div className="d-flex row mx-0 mb-5 justify-content-between align-items-center">
                  <div className="col-md-6 col-12 px-0 align-items-center h-100">
                    <div className={'box mr-md-3 mr-0 pb-4 mb-md-0 mb-5'}>
                      <div className="d-flex row align-items-end">
                        <div className="flex-grow-1 flex-fill">
                          <OrderForm.ParcelTrackingNumber
                            barcode={true}
                            barcodeButtonClassName={'input-prepend'}
                            onChange={(event: any) => {
                              setInitialValues((values) => {
                                values.parcelTrackingNumber =
                                  event?.target?.value;
                                return { ...values };
                              });
                            }}
                            autoSubmit={onTrackingNumberChanged}
                            appendElement={
                              <Button
                                className="w-25 d-flex align-items-center justify-content-center input-append"
                                color="primary"
                                style={{ minWidth: '2.5rem' }}
                                onClick={() => {
                                  onTrackingNumberChanged(
                                    initialValues?.parcelTrackingNumber,
                                  );
                                }}
                                disabled={
                                  !initialValues?.parcelTrackingNumber ||
                                  !palletCounter
                                }
                              >
                                <HiOutlineArrowSmRight
                                  size={20}
                                  className={'mr-2'}
                                />
                                Load
                              </Button>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0 align-items-center h-100">
                    <div className={'box ml-md-3 ml-0 pb-4'}>
                      <div>
                        <h3 className={'mb-3'}>Pallet Number</h3>
                        <OrderForm.SelectPallet
                          onKeyDown={validatePositiveNumberInput}
                          onChange={(data) =>
                            setPalletCounter(
                              !isNaN(parseInt(data.target.value))
                                ? parseInt(data.target.value)
                                : 0,
                            )
                          }
                          appendElement={
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => {
                                const newCurrentPallet = palletCounter + 1;
                                setInitialValues((values) => {
                                  values.currentPallet = newCurrentPallet;
                                  return { ...values };
                                });
                                setPalletCounter(newCurrentPallet);
                              }}
                              disabled={isSending}
                              className={'input-append'}
                            >
                              <div
                                className={
                                  'd-flex align-items-center flex-nowrap text-nowrap px-sm-2'
                                }
                              >
                                <FaArrowRight size={12} className={'mr-2'} />
                                Next Pallet
                              </div>
                            </Button>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'box px-6 py-4 mb-5'}>
                  <TotalItems
                    title="Total Available to Load"
                    summary={parcelShipments?.summary}
                    expandable={true}
                    orders={parcelShipments}
                    onOrderSelect={onParcelShipmentSelect}
                    columns={consolidatedShipmentParcelColumns}
                    offset={parcelsOffset}
                    setOffset={setParcelsOffset}
                    limit={parcelsLimit}
                    setLimit={setParcelsLimit}
                    sort={parcelsSort}
                    setSort={setParcelsSort}
                  />
                </div>
                <div className={'box px-6 py-4 mb-5'}>
                  <TotalItems
                    title="Total Loaded"
                    color={'action-positive'}
                    summary={{
                      totalCount: getTotalPcsCrt(
                        initialValues?.containerCommodities,
                      ),
                      totalWeight: getWeightTotal(
                        initialValues?.containerCommodities,
                      ),
                      totalWeightUnits: WeightUnit.Kg,
                      totalCube: getVolumeTotal(
                        initialValues?.containerCommodities,
                      ),
                      totalCubeUnits: VolumeUnit.Ft,
                    }}
                  />
                </div>
                <CommoditiesForCargoMovementList
                  className={'mb-4 commodities-for-order-list'}
                  limit={limit}
                  showPagination={false}
                  onPageChanged={(page) => setOffset(page * limit)}
                  offset={offset}
                  items={initialValues?.containerCommodities}
                  goToDetails={goToDetailsCommodity}
                  changeItems={null}
                  orderType={OrderTypes.AirShipmentOrder}
                  isLoading={isSending}
                  columns={columns}
                  goToParcelDetails={goToParcelDetails}
                />
              </>
            </>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
