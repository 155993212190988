import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { OrderStatusForm } from './orderStatus.form';
import {
  OrderStatusDto,
  OrderTypes,
  StatusStage,
  CustomFieldDto,
  CustomFieldEntityType,
  CustomFieldDtoPagedResult,
} from '../../../models/data.models';
import {
  createOrderStatusFx,
  getOrderStatusFx,
  getOrderStatusesFx,
  updateOrderStatusFx,
} from '../orderStatuses.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';

export type OrderStatusEditProps = {
  orderStatusId?: number | null;
  onOrderStatusCreated?: (orderStatus: OrderStatusDto) => void;
  onOrderStatusUpdated?: (orderStatus: OrderStatusDto) => void;
  onOrderStatusLoaded?: (orderStatus: OrderStatusDto) => void;
  onCancel?: () => void;
};

const initialState: OrderStatusDto = {
  orderStatusId: 0,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  orderStatusDescription: null,
  orderStatusName: '',
  orderType: OrderTypes.Order,
  organizationId: 0,
  priority: 0,
  statusStage: StatusStage.Pending,
  requireConfirmation: false,
  customValues: {},
  links: [],
};

const orderStatusSchema = Yup.object().shape({
  orderStatusName: Yup.string()
    .required("Can't be blank")
    .max(50, 'Max length is 100')
    .nullable(true),
  orderStatusDescription: Yup.string()
    .max(255, 'Max length is 255')
    .nullable(true),
  priority: Yup.number().typeError('Priority must be a number').nullable(true),
  orderType: Yup.string().required().nullable(true),
});

export const OrderStatusEdit = ({
  orderStatusId,
  onOrderStatusLoaded = () => {},
  onOrderStatusCreated = () => {},
  onOrderStatusUpdated = () => {},
  onCancel = () => {},
}: OrderStatusEditProps) => {
  const isCreateMode = !orderStatusId || orderStatusId === 0;
  const [isLoading, setIsLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState<OrderStatusDto | null>(
    initialState,
  );
  const [isSending, setIsSending] = useState(false);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  const getOrderStatusCustomFields = async () => {
    try {
      const result: CustomFieldDtoPagedResult = await getCustomFieldsFx({
        limit: 100,
        offset: 0,
        filter: `CustomFieldEntityType: ${CustomFieldEntityType.OrderStatus} AND IsInactive: false`,
      });
      if (result) setCustomFields(result?.items);
    } catch (error) {
      addMessage({
        message: error,
        type: 'danger',
        id: v4(),
      });
    }
  };

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (orderStatusId) {
      getOrderStatusFx({ orderStatusId }).then(
        (orderStatusDto: OrderStatusDto) => {
          setOrderStatus(orderStatusDto);
          setIsLoading(false);
          onOrderStatusLoaded(orderStatusDto);
        },
      );
    } else {
      throw new Error('OrderStatus keys were not provided');
    }
  }, [orderStatusId]);

  useEffect(() => {
    getOrderStatusCustomFields();
  }, []);

  const onSubmit = (data: OrderStatusDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createOrderStatusFx(data)
        .then((result) => {
          onOrderStatusCreated(result.data);
        })
        .finally(() => setIsSending(false));
    } else {
      updateOrderStatusFx(data)
        .then((result) => {
          onOrderStatusUpdated(result.data);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <OrderStatusForm
      initialValues={orderStatus || initialState}
      onSubmit={onSubmit}
      id={'orderStatusForm'}
      validationSchema={orderStatusSchema}
      validateOnChange={true}
    >
      <Tabs>
        <TabList>
          <span>
            <Tab>General</Tab>
            <Tab>Additional</Tab>
          </span>
        </TabList>

        <TabPanel forceRender={true}>
          <Panel className="m-3">
            <div className="row">
              <div className="col-3">
                <OrderStatusForm.OrderStatusName />
                <OrderStatusForm.OrderStatusDescription />
                <OrderStatusForm.OrderType />
                <OrderStatusForm.Priority />
                <OrderStatusForm.StatusStage />
                <OrderStatusForm.RequireConfirmation
                  defaultValue={orderStatus?.requireConfirmation || false}
                />
              </div>
            </div>
            <div className="d-flex justify-content-start row">
              <div className="col-3">
                <Button
                  name="save-orderStatus"
                  type="submit"
                  color="primary"
                  className="btn-block"
                  form="orderStatusForm"
                >
                  Save Order Status
                </Button>
              </div>
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>

        <TabPanel forceRender={true}>
          <OrderStatusForm.CustomFields
            customFields={customFields}
            entityType={CustomFieldEntityType.OrderStatus}
            defaultValue={isCreateMode ? {} : orderStatus?.customValues}
            onChange={(result) => {
              setOrderStatus((status) => {
                if (status) status.customValues = result;
                return { ...status };
              });
            }}
            saveButtonRenderCondition={true}
            isSending={isSending}
            formName={'orderStatusForm'}
            entityName={'OrderStatus'}
            onCancel={onCancel}
          />
        </TabPanel>
      </Tabs>
    </OrderStatusForm>
  );
};
