import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { Helmet } from 'react-helmet';
import { PackingWizard } from '../packingWizard/packingWizard.component';
import { setCurrentPagePath } from '../../common/nav.store';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PARCEL_SHIPMENT_LIST_PATH } from '../parcelShipment.route';

export type PackingWizardScreenProps = {
  pageTitle: string;
  group: string;
};

export const PackingWizardScreen = ({
  pageTitle,
  group,
}: PackingWizardScreenProps) => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  useEffect(() => {
    setCurrentPagePath(['Operations', 'Parcel Shipments', 'Packing']);
  });
  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + PARCEL_SHIPMENT_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout group={group} title={'Packing the parcel'}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PackingWizard onCancel={onCancel} />
    </InternalLayout>
  );
};
