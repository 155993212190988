import React, { Fragment } from 'react';
import { components, MenuProps } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

export const SelectEntityAsyncPaginate = (selectProps) => {
  const {
    entityName,
    canCreateNewEntity,
    onCreateNewEntity,
    isMulti,
    onChange,
    value,
    styles,
  } = selectProps;

  const entityNameElement = <>{entityName}</>;

  const onNewCreateHandler = async () => {
    const result = await onCreateNewEntity();
    if (result) {
      if (isMulti) {
        onChange([...value, result]);
      } else {
        onChange(result);
      }
    }
  };

  const Menu = (props: MenuProps) => {
    return (
      <components.Menu {...props} className="prevent-drag">
        {props.children}
        {canCreateNewEntity ? (
          <button
            type={'button'}
            data-cy={'create-new-entity'}
            className={'btn btn-sm btn-primary w-100'}
            style={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}
            onClick={onNewCreateHandler}
            onTouchStart={onNewCreateHandler}
          >
            Create new {entityNameElement}
          </button>
        ) : (
          <></>
        )}
      </components.Menu>
    );
  };
  return (
    <AsyncPaginate
      {...selectProps}
      components={{
        IndicatorSeparator: () => null,
        ...(selectProps?.hideDropdownIndicator && {
          DropdownIndicator: () => null,
        }),
        Menu,
      }}
      styles={styles ? styles : {}}
    />
  );
};
