import React, { useRef, useState, useEffect } from 'react';
import { FiEdit, FiMenu, FiNavigation, FiSave } from 'react-icons/fi';
import Dropdown from 'react-bootstrap/Dropdown';
import { getUserSettings } from '../../../../userSettings/userSettings.store';
import { authStore } from '../../../../auth/auth.store';
import { showDialog } from '../../../dialog.store';
import { UserSettingDialog } from '../../../../userSettings/components/userSetting.dialog';
import { FormikProps, FormikValues } from 'formik';
import { getRouteFeature } from '../../../../../utils/query.utils';
import { addMessage } from '../../../messages.store';
import { v4 } from 'uuid';
import { GridContextValue } from '../../filters/filtersTab.component';
import { useQuery } from '../../../../../app.router';

type GridSavedViewsSetupProperties = {
  context: FormikProps<FormikValues>;
  className?: string;
  useGridContext?: () => GridContextValue;
};

export const GridSavedViewsSetup = ({
  context,
  className = '',
  useGridContext,
}: GridSavedViewsSetupProperties) => {
  const {
    columns,
    filters,
    defaultColumns,
    defaultFilters,
    defaultSort,
    defaultLimit,
    onColumnsChanged,
    onFiltersChanged,
    onViewChanged,
  } = useGridContext();

  const dropdownRef = useRef(null);

  const [selectedViewName, setSelectedViewName] = useState<
    string | null | undefined
  >('');

  const [views, setViews] = useState<any[]>([]);
  const [orgViews, setOrgViews] = useState<any[]>([]);
  const [userSettingId, setUserSettingId] = useState<number | null | undefined>(
    null,
  );
  const [orgSettingId, setOrgSettingId] = useState<number | null | undefined>(
    null,
  );
  const [selectedDefaultView, setSelectedDefaultView] = useState<any>();
  const [isAlreadySelected, setIsAlreadySelected] = useState<boolean>(false);

  const {
    view: viewFromURL,
    sort: sortFromURL,
    limit: limitFromURL,
  } = useQuery();

  const { user: currentUser } = authStore.getState();

  useEffect(() => {
    setCommonViews();
  }, []);

  useEffect(() => {
    if (isAlreadySelected == false && selectedViewName?.length > 0) {
      setIsAlreadySelected(true);
    }
  }, [selectedViewName]);

  useEffect(() => {
    if (selectedDefaultView && !isAlreadySelected) {
      onViewChanged(
        selectedDefaultView?.name,
        selectedDefaultView?.sort ?? defaultSort,
        selectedDefaultView?.limit ?? defaultLimit,
      );
    }
  }, [selectedDefaultView]);

  useEffect(() => {
    const defaultView = orgViews?.find((view) => view.isDefault === true);
    if (defaultView && (!viewFromURL || viewFromURL.length == 0)) {
      setSelectedDefaultView(defaultView);
    }
  }, [orgViews]);

  useEffect(() => {
    const selectedView =
      views?.find((view) => view.name === viewFromURL) ??
      orgViews?.find((view) => view.name === viewFromURL);

    setSelectedViewName(selectedView?.name);

    handleViewFromQuery();
  }, [viewFromURL]);

  const mergeColumns = (viewColumns: any[], defaultColumns: any[]) => {
    const mergedColumns = [...defaultColumns];
    // set all columns to visible false
    mergedColumns.forEach((column) => {
      column.visible = false;
    });
    viewColumns?.forEach((viewColumn) => {
      const defaultColumn = defaultColumns?.find(
        (column) => column.name === viewColumn.name,
      );
      if (defaultColumn) {
        mergedColumns[defaultColumns.indexOf(defaultColumn)] = viewColumn;
        viewColumn.type = defaultColumn.type;
        viewColumn.position = defaultColumn.position;
        viewColumn.filterFieldName = defaultColumn.filterFieldName;
        viewColumn.title = defaultColumn.title;
        viewColumn.sortName = defaultColumn.sortName;
      }
    });
    return mergedColumns;
  };

  const orderColumns = (columns: any[], viewColumns?: any[]) => {
    const orderedColumns = [...columns];

    if (viewColumns) {
      const updatedColumns = [];

      columns?.forEach((column) => {
        const sameView = viewColumns?.find((x) => x?.name === column?.name);

        if (sameView) {
          updatedColumns[viewColumns?.indexOf(sameView)] = column;
        }
      });

      const updatedColumnsFixed = updatedColumns.filter((column) => column);
      return updatedColumnsFixed;
    }

    orderedColumns.sort((a, b) => a.order - b.order);
    return orderedColumns;
  };

  const handleViewFromQuery = () => {
    const selectedView =
      views?.find((view) => view.name === viewFromURL) ??
      orgViews?.find((view) => view.name === viewFromURL);

    if (viewFromURL?.length > 0) {
      if (selectedView) {
        onColumnsChanged(
          orderColumns(
            mergeColumns(selectedView.columns, defaultColumns),
            selectedView.columns,
          ),
        );
        onFiltersChanged(
          orderColumns(
            mergeColumns(selectedView.filters, defaultFilters),
            selectedView.filters,
          ),
        );
        context.setValues(selectedView.contextValues);
      }
    } else {
      onColumnsChanged(orderColumns(defaultColumns));
      onFiltersChanged(orderColumns(defaultFilters));
      context.resetForm({ values: {} });
    }
  };

  const handleViewSelect = (view: any) => {
    const isSameView = view.name === viewFromURL;
    if (isSameView) {
      onViewChanged('', defaultSort, defaultLimit);
    } else {
      onViewChanged(
        view.name,
        view.sort ?? defaultSort,
        view.limit ?? defaultLimit,
      );
    }
  };

  const setCommonViews = async () => {
    const settingKey = `tms:${getRouteFeature()}:grid`;

    const response = await getUserSettings({
      filter: `name:"${settingKey}"`,
    });

    if (response) {
      const feature = getRouteFeature();

      if (feature && response.items.length > 0) {
        setUserSettingId(
          response.items.find(
            (item) =>
              item.userId === currentUser.userId && item.name === settingKey,
          )?.userSettingId,
        );
        setOrgSettingId(
          response.items.find(
            (item) => !item.userId && item.name === settingKey,
          )?.userSettingId,
        );
        setViews(
          response.items.find(
            (item) =>
              item.userId === currentUser.userId && item.name === settingKey,
          )?.settings?.views,
        );
        setOrgViews(
          response.items.find(
            (item) => !item.userId && item.name === settingKey,
          )?.settings?.views,
        );
      }
    }
  };

  const submitView = async (settingId?: number | null, viewName?: string) => {
    try {
      if (settingId) {
        await showDialog({
          dialog: UserSettingDialog,
          props: {
            className: 'modal-save-view',
            settingId: settingId,
            name: viewName,
            sort: sortFromURL,
            limit: limitFromURL,
            isVisibleToEveryone: settingId === orgSettingId,
            views: views,
            orgViews: orgViews,
            onViewChanged: viewName === viewFromURL && onViewChanged,
            onSaveHandle: setCommonViews,
          },
        });
      } else {
        await showDialog({
          dialog: UserSettingDialog,
          props: {
            className: 'modal-save-view',
            columns: columns,
            filters: filters,
            sort: sortFromURL,
            limit: limitFromURL,
            contextValues: context.values,
            views: views,
            orgViews: orgViews,
            userSettingId: userSettingId,
            orgSettingId: orgSettingId,
            onViewChanged: onViewChanged,
            onSaveHandle: setCommonViews,
          },
        });
      }
    } catch (error) {
      addMessage({
        message: error.message,
        type: 'danger',
        id: v4(),
      });
    }
  };

  const handleEditClick = (
    e: any,
    viewName: any,
    settingId?: number | null,
  ) => {
    e.stopPropagation();
    submitView(settingId, viewName);
    dropdownRef.current.click();
  };

  return (
    <div className={className}>
      <Dropdown ref={dropdownRef}>
        <Dropdown.Toggle
          id="dropdownColumnsButton"
          className="pointer btn-toggle"
          variant="light"
        >
          <FiMenu size="20px" />
          &nbsp;
          <span className="px-1">{selectedViewName ?? 'Select View'}</span>
          &nbsp;
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            width: 'max-content',
            maxWidth: 'unset',
            minWidth: '180px',
          }}
        >
          <div
            className="scrollbar"
            style={{
              height: `${
                views?.length || orgViews?.length ? 'max-content' : '50px'
              }`,
            }}
          >
            {orgViews?.length > 0 && (
              <>
                {orgViews?.map((orgView) => (
                  <Dropdown.Item
                    key={orgView.name}
                    className={`pointer hover-bg ${
                      orgView.name === viewFromURL && 'selected-item-bg'
                    }`}
                    onClick={() => handleViewSelect(orgView)}
                  >
                    <div className="row justify-content-between">
                      <div
                        className="pr-4 align-self-center btn-toggle-text"
                        style={{ userSelect: 'none' }}
                      >
                        {orgView.name}
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {orgView.isDefault && (
                          <div className="default-badge mt-1 mr-3">default</div>
                        )}
                        <FiEdit
                          size={'20px'}
                          color="#066FFC"
                          className="pointer"
                          onClick={(e) => {
                            handleEditClick(e, orgView.name, orgSettingId);
                          }}
                        />
                      </div>
                    </div>
                  </Dropdown.Item>
                ))}
                {views && views.length > 0 && <hr className="mx-2 my-1" />}
              </>
            )}
            {views && views.length > 0 && (
              <>
                {views.map((view) => (
                  <Dropdown.Item
                    key={view.name}
                    className={`pointer hover-bg ${
                      view.name === viewFromURL && 'selected-item-bg'
                    }`}
                    onClick={() => handleViewSelect(view)}
                  >
                    <div className="row justify-content-between">
                      <div
                        className="pr-4 align-self-center btn-toggle-text"
                        style={{ userSelect: 'none' }}
                      >
                        {view.name}
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <FiEdit
                          size={'20px'}
                          color="#066FFC"
                          className="pointer"
                          onClick={(e) => {
                            handleEditClick(e, view.name, userSettingId);
                          }}
                        />
                      </div>
                    </div>
                  </Dropdown.Item>
                ))}
              </>
            )}

            {(!views || views.length === 0) &&
              (!orgViews || orgViews?.length === 0) && (
                <label className="dropdown-item">
                  <div className="pt-2 d-flex justify-content-center align-items-center">
                    <span className="btn-toggle-text">Views not found</span>
                  </div>
                </label>
              )}
          </div>
          <hr style={{ margin: '0' }} />
          <Dropdown.Item
            className="p-2 dropdown-item hover-bg pointer"
            onClick={() => submitView()}
          >
            <div className="pt-2 d-flex justify-content-center align-items-center">
              <FiSave size="20px" />
              &nbsp;
              <span className="px-1 btn-toggle-text">Save View</span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
