import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CustomCodeForm } from './customCode.form';
import { CustomCodeDto, CustomCodeType } from '../../../models/data.models';
import {
  createCustomCode,
  getCustomCode,
  updateCustomCode,
} from '../customCodes.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import { UPDATE_CUSTOMCODE_LINK_KEY } from '../customCodes.service';
import { userHas } from '../../auth/auth.store';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';

export type CustomCodeEditProps = {
  code?: string;
  onCustomCodeCreated?: (code: CustomCodeDto) => void;
  onCustomCodeUpdated?: (code: CustomCodeDto) => void;
  onCustomCodeLoaded?: (code: CustomCodeDto) => void;
  onCancel?: () => void;
};

const initialState: CustomCodeDto = {
  links: [],
  organizationId: 0,
  code: '',
  description: '',
  isCommonlyUsed: false,
  codeType: CustomCodeType.ScheduleD,
};

const customCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required("Can't be blank")
    .max(5, 'Max length is 5')
    .nullable(true),
  description: Yup.string()
    .required("Can't be blank")
    .max(256, 'Max length is 256')
    .nullable(true),
  codeType: Yup.mixed<CustomCodeType>()
    .oneOf(Object.values(CustomCodeType))
    .required(),
});

export const CustomCodeEdit = ({
  code,
  onCustomCodeLoaded = () => {},
  onCustomCodeCreated = () => {},
  onCustomCodeUpdated = () => {},
  onCancel = () => {},
}: CustomCodeEditProps) => {
  const isCreateMode = !code || code === 'create';
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customCode, setCustomCode] = useState<CustomCodeDto | null>(null);

  useEffect(() => {
    const fetchCode = async () => {
      if (isCreateMode) {
        setIsLoading(false);
        setCustomCode(initialState);
      } else if (code) {
        try {
          const codeDto: CustomCodeDto = await getCustomCode({
            code,
          });
          setCustomCode(codeDto);
          onCustomCodeLoaded(codeDto);
        } catch {
        } finally {
          setIsLoading(false);
        }
      } else {
        throw new Error('Customs code was not provided');
      }
    };
    fetchCode();
  }, [code]);

  const onSubmit = (data: CustomCodeDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createCustomCode(data)
        .then((result) => {
          onCustomCodeCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updateCustomCode(data)
        .then((result) => {
          onCustomCodeUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <CustomCodeForm
      id={'customCode-form'}
      initialValues={customCode || initialState}
      onSubmit={onSubmit}
      validationSchema={customCodeSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <Tab>General</Tab>
            </TabList>

            <TabPanel forceRender={true}>
              <Panel className="m-3">
                {isCreateMode ? (
                  <h3 className="header-form">Add New Code</h3>
                ) : (
                  <h3 className="header-form">Update Code</h3>
                )}
                <div className="row">
                  <div className="col-4">
                    <CustomCodeForm.Code disabled={!isCreateMode} />
                    <CustomCodeForm.Description />
                    <CustomCodeForm.IsCommonlyUsed
                      defaultValue={context?.values?.isCommonlyUsed ?? false}
                    />
                    <CustomCodeForm.CustomCodeType />
                  </div>
                </div>
                <div className="d-flex justify-content-end row">
                  {(userHas(UPDATE_CUSTOMCODE_LINK_KEY, customCode?.links) ||
                    isCreateMode) && (
                    <div className="col-3">
                      <Button
                        name="save-customCode"
                        type="submit"
                        color="primary"
                        form={'customCode-form'}
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Code
                      </Button>
                    </div>
                  )}
                  <div className="col-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Panel>
            </TabPanel>
          </Tabs>
        )}
      </FormContext.Consumer>
    </CustomCodeForm>
  );
};
