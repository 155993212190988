import { Typeahead } from 'react-bootstrap-typeahead';
import { FormikContext } from 'formik';
import { useContext, useState } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';

export const AutocompleteComponent = ({
  field,
  form,
  componentProps,
  disabled,
  ...props
}) => {
  const formikContext = useContext(FormikContext);
  const [selected, setSelected] = useState([field.value] ?? []);
  const [options, setOptions] = useState(componentProps.props?.items ?? []);

  const onInputChange = async (input) => {
    if (componentProps.props?.multiple) {
      return;
    }
    form.setFieldValue(field.name, input);
  };

  const onChange = (selectedVals) => {
    if (componentProps.props?.multiple) {
      form.setFieldValue(
        field.name,
        selected.map((item) => item),
      );
      return;
    }

    form.setFieldValue(field.name, selectedVals[0]);
  };

  const onBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  return (
    <Typeahead
      id={field.name}
      disabled={disabled}
      labelKey={componentProps.props?.labelKey}
      options={options}
      multiple={componentProps.props?.multiple}
      placeholder={componentProps.props?.placeholder}
      filterBy={componentProps.props?.filterBy}
      onBlur={onBlur}
      onChange={onChange}
      defaultSelected={selected}
      allowNew={componentProps.props?.allowNew}
      onInputChange={onInputChange}
      {...componentProps.props?.options}
    />
  );
};
