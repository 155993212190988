import {
  ClauseDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateClauseCommandValues,
  UpdateClauseCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createClauseRequest,
  getClausesListRequest,
  getClauseRequest,
  deleteClauseRequest,
  updateClauseRequest,
  importClausesRequest,
  exportClausesRequest,
  GetClauseParams,
} from './clauses.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type ClausesStoreState = {
  links: LinkDto[];
  clauseColumns: EntityFieldDto[];
  defaultClauseColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getClausesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Clause',
  });
});

addEffectStatusHandling(getClausesColumnsFx);

export const getClausesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Clause',
  });
});

addEffectStatusHandling(getClausesDefaultColumnsFx);

export const updateClausesColumns = createEvent<EntityFieldDto[]>();

export const getClausesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getClausesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getClausesFx);

export const createClauseFx = createEffect((clauseData: ClauseDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createClauseCommand: CreateClauseCommandValues = { ...clauseData };

  return createClauseRequest(currentOrganization!, createClauseCommand);
});

addEffectStatusHandling(createClauseFx, {
  completeMessage: 'Clause was created successfully',
  errorMessage: 'Clause was not created',
});

export const getClauseFx = createEffect((clauseParams: GetClauseParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getClauseRequest(
    currentOrganization as LinkResourceBaseDto,
    clauseParams,
  );
});

addEffectStatusHandling(getClauseFx);

export const updateClauseFx = createEffect((clause: ClauseDto) => {
  const updateClauseCommand: UpdateClauseCommandValues = { ...clause };
  return updateClauseRequest(clause, updateClauseCommand);
});

addEffectStatusHandling(updateClauseFx, {
  completeMessage: 'Clause was updated successfully',
  errorMessage: 'Clause was not updated',
});

export const deleteClauseFx = createEffect((clause: ClauseDto) => {
  return deleteClauseRequest(clause);
});

addEffectStatusHandling(deleteClauseFx, {
  completeMessage: 'Clause was deleted successfully',
  errorMessage: 'Clause was not deleted',
});

export const exportClausesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportClausesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportClausesFx, {
  completeMessage: 'Clauses were exported successfully',
  errorMessage: 'Clauses were not exported',
});

export const importClausesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importClausesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importClausesFx, {
  completeMessage: 'Clauses were imported successfully',
  errorMessage: 'Clauses were not imported',
  inFlightMessage: 'Importing clauses...',
});

const defaultState: ClausesStoreState = {
  links: [],
  clauseColumns: [],
  defaultClauseColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const clauseStore: Store<ClausesStoreState> = createStore(defaultState)
  .on(getClausesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      clauseColumns: payload.result.items,
      defaultClauseColumns: payload.result.items,
    };
  })
  .on(getClausesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultClauseColumns: payload.result.items,
    };
  })
  .on(updateClausesColumns, (state, payload) => {
    return { ...state, clauseColumns: payload };
  });
