import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { DocumentTemplateForm } from './documentTemplate.form';
import {
  DocumentTemplateDto,
  DocumentTemplateType,
  TemplatingEngines,
} from '../../../models/data.models';
import {
  createDocumentTemplateFx,
  getDocumentTemplateFx,
  updateDocumentTemplateFx,
} from '../documentTemplates.store';
import { Panel } from '../../common/components/panel/panel.component';
import { DocumentTemplateEditFormDto } from '../../../models/custom.models';
import { FormContext } from '../../common/components/form/form.component';
import { getEnumKeyByValue, getEnumValues } from '../../../utils/helper.utils';
import { DocumentTemplateDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import beautify from 'beautify';
import { userHas } from '../../auth/auth.store';
import { UPDATE_DOCUMENTTEMPLATE_LINK_KEY } from '../documentTemplates.service';
import {
  JsonField,
  JsonInput,
} from '../../common/components/input/ace-input.component';
import { queryGraphQlFx } from '../../graphql/graphql.store';
import { doc } from 'prettier';
import Handlebars from 'handlebars';

export type DocumentTemplateEditProps = {
  documentTemplateId?: number | null;
  onDocumentTemplateCreated?: (documentTemplate: DocumentTemplateDto) => void;
  onDocumentTemplateUpdated?: (documentTemplate: DocumentTemplateDto) => void;
  onDocumentTemplateLoaded?: (documentTemplate: DocumentTemplateDto) => void;
  onCancel?: () => void;
};

const initialState: DocumentTemplateDto = {
  documentTemplateId: 0,
  bodyHtmlTemplate: DocumentTemplateDefaultValues.bodyHtmlTemplate,
  bodyTextTemplate: DocumentTemplateDefaultValues.bodyTextTemplate,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: DocumentTemplateDefaultValues.description,
  documentTemplateType: DocumentTemplateDefaultValues.documentTemplateType,
  fileNameTemplate: DocumentTemplateDefaultValues.fileNameTemplate,
  isDefault: DocumentTemplateDefaultValues.isDefault,
  isInactive: DocumentTemplateDefaultValues.isInactive,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: DocumentTemplateDefaultValues.documentTemplateName,
  organizationId: 0,
  subjectTemplate: DocumentTemplateDefaultValues.subjectTemplate,
  createdByUserName: '',
  updatedByUserName: '',
  gqlQuery: '',
  templatingEngine: TemplatingEngines.Handlebars,
  gqlVariables: {},
  links: [],
};

const documentTemplatesSchema = Yup.object().shape(
  {
    bodyHtmlTemplate: Yup.string().when('bodyTextTemplate', {
      is: (bodyTextTemplate) => bodyTextTemplate?.length > 0,
      then: Yup.string().nullable(true),
      otherwise: Yup.string()
        .required(
          'Either Body Html Template or Body Text Template should not be blank',
        )
        .nullable(true),
    }),
    bodyTextTemplate: Yup.string().when('bodyHtmlTemplate', {
      is: (bodyHtmlTemplate) => bodyHtmlTemplate?.length > 0,
      then: Yup.string().nullable(true),
      otherwise: Yup.string()
        .required(
          'Either Body Html Template or Body Text Template should not be blank',
        )
        .nullable(true),
    }),
    description: Yup.string().required("Can't be blank").nullable(true),
    documentTemplateType: Yup.string()
      .required("Can't be blank")
      .nullable(true),
    name: Yup.string().required("Can't be blank").nullable(true),
  },
  [['bodyHtmlTemplate', 'bodyTextTemplate']],
);

export const DocumentTemplateEdit = ({
  documentTemplateId,
  onDocumentTemplateLoaded = () => {},
  onDocumentTemplateCreated = () => {},
  onDocumentTemplateUpdated = () => {},
  onCancel = () => {},
}: DocumentTemplateEditProps) => {
  const isCreateMode = !documentTemplateId || documentTemplateId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bodyHtmlPreview, setBodyHtmlPreview] = useState('');
  const [gqlData, setGqlData] = useState('');

  const [
    documentTemplateContextFormValue,
    setDocumentTemplateContextFormValue,
  ] = useState<DocumentTemplateEditFormDto | null>(null);
  const [
    initialValues,
    setInitialValues,
  ] = useState<DocumentTemplateEditFormDto | null>(initialState);

  const renderPreview = (template) => {
    if (documentTemplateContextFormValue?.templatingEngine === 'Handlebars') {
      try {
        const handlebarsTemplate = Handlebars.compile(template);
        const gqlDataObj = JSON.parse(gqlData || '{}');
        setBodyHtmlPreview(handlebarsTemplate(gqlDataObj));
      } catch (error) {
        setBodyHtmlPreview(error.message);
      }
    } else {
      setBodyHtmlPreview(template);
    }
  };

  useEffect(() => {
    renderPreview(documentTemplateContextFormValue?.bodyHtmlTemplate);
  }, [gqlData, documentTemplateContextFormValue?.templatingEngine]);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (documentTemplateId) {
      getDocumentTemplateFx({ documentTemplateId }).then(
        (documentTemplateDto: DocumentTemplateDto) => {
          setInitialValues(documentTemplateDto);
          setDocumentTemplateContextFormValue((contextFormValue) => {
            if (!documentTemplateDto) {
              contextFormValue = initialState;
            } else {
              contextFormValue = documentTemplateDto;
            }
            return contextFormValue;
          });
          renderPreview(documentTemplateDto.bodyHtmlTemplate);
          setIsLoading(false);
          onDocumentTemplateLoaded(documentTemplateDto);
        },
        () => {},
      );
    } else {
      throw new Error('DocumentTemplate keys were not provided');
    }
  }, [documentTemplateId]);

  const onSubmit = async (data: DocumentTemplateDto) => {
    setIsSending(true);

    try {
      const result = isCreateMode
        ? await createDocumentTemplateFx(data)
        : await updateDocumentTemplateFx(data);

      if (isCreateMode) {
        onDocumentTemplateCreated(result);
      } else {
        onDocumentTemplateUpdated(result);
      }
    } catch (error) {
      // Handle error here if necessary
      console.error('Error while processing document template:', error);
    } finally {
      setIsSending(false);
    }
  };

  const onPrettierIconClick = (context) => {
    const prettiedBodyHtmlTemplate = beautify(context.values.bodyHtmlTemplate, {
      format: 'html',
    });
    context.setFieldValue('bodyHtmlTemplate', prettiedBodyHtmlTemplate);
  };

  const setContextValue = (fieldName: string, value: any) => {
    setDocumentTemplateContextFormValue((documentType) => {
      if (!documentType) {
        documentType = initialState;
      }
      documentType[fieldName] = value;
      return { ...documentType };
    });
  };

  const onDocumentTemplateTypeChange = (newValueDocumentTemplateType) => {
    setContextValue(
      'documentTemplateType',
      newValueDocumentTemplateType?.value,
    );
  };

  const onQueryData = async () => {
    try {
      const data = await queryGraphQlFx({
        query: documentTemplateContextFormValue?.gqlQuery,
        variables: documentTemplateContextFormValue?.gqlVariables,
      });
      setGqlData(JSON.stringify(data, null, 2));
    } catch (error) {
      setGqlData(JSON.stringify(error, null, 2));
    }
  };

  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <>
      <Tabs>
        <TabList>
          <span>
            <Tab name="documentTemplate">Document Template</Tab>
            <Tab name="htmlTemplate">HTML Template</Tab>
            <Tab name="textTemplate">Text Template</Tab>
            {documentTemplateContextFormValue?.templatingEngine ===
              TemplatingEngines.Handlebars && <Tab name="graphQl">Data</Tab>}
          </span>
        </TabList>
        <DocumentTemplateForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          id={'document-templates-form'}
          validationSchema={documentTemplatesSchema}
        >
          <TabPanel>
            <Panel className="m-3">
              {isCreateMode ? (
                <h3 className="header-form">Add Document Template</h3>
              ) : (
                <h3 className="header-form">Update Document Template</h3>
              )}
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <DocumentTemplateForm.Name />
                    </div>
                    <div className="col-6">
                      <DocumentTemplateForm.Description />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DocumentTemplateForm.TemplatingEngine
                        placeholder={'Template Engine'}
                        id={'templatingEngine'}
                        header={'Template Engine'}
                        name={'templatingEngine'}
                        disabled={false}
                        required={true}
                        onChange={(newValue) =>
                          setContextValue('templatingEngine', newValue?.value)
                        }
                        defaultValue={
                          documentTemplateContextFormValue?.templatingEngine
                            ? {
                                label:
                                  documentTemplateContextFormValue.templatingEngine,
                                value:
                                  documentTemplateContextFormValue.paperSize,
                              }
                            : null
                        }
                        multiple={false}
                        options={getEnumValues(TemplatingEngines)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <DocumentTemplateForm.DocumentTemplateType
                        placeholder={'Document Template Type'}
                        id={'documentTemplateType'}
                        header={'Document Template Type'}
                        name={'documentTemplateType'}
                        disabled={false}
                        required={true}
                        defaultValue={
                          documentTemplateContextFormValue?.documentTemplateType
                            ? {
                                label:
                                  DocumentTemplateType[
                                    documentTemplateContextFormValue
                                      ?.documentTemplateType
                                  ],
                                value:
                                  documentTemplateContextFormValue?.documentTemplateType,
                              }
                            : {
                                label:
                                  DocumentTemplateDefaultValues.documentTemplateType,
                                value: getEnumKeyByValue(
                                  DocumentTemplateDefaultValues.documentTemplateType,
                                  DocumentTemplateType,
                                ),
                              }
                        }
                        onChange={onDocumentTemplateTypeChange}
                        multiple={false}
                        options={getEnumValues(DocumentTemplateType)}
                      />
                    </div>
                    <div className="col-6">
                      <DocumentTemplateForm.FileNameTemplate />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DocumentTemplateForm.SubjectTemplate />
                    </div>
                  </div>
                  <DocumentTemplateForm.IsDefault
                    defaultValue={
                      documentTemplateContextFormValue?.isDefault ?? false
                    }
                  />
                  <DocumentTemplateForm.IsInactive
                    defaultValue={
                      documentTemplateContextFormValue?.isInactive ?? false
                    }
                  />
                  <div className="row">
                    <div className="col-4">
                      <DocumentTemplateForm.PaperSize
                        placeholder={'Paper Size'}
                        id={'paperSize'}
                        header={'Paper Size'}
                        name={'paperSize'}
                        defaultValue={
                          documentTemplateContextFormValue?.paperSize
                            ? {
                                label:
                                  documentTemplateContextFormValue.paperSize,
                                value:
                                  documentTemplateContextFormValue.paperSize,
                              }
                            : null
                        }
                      />
                    </div>
                    <div className="col-4">
                      <DocumentTemplateForm.CustomPaperSize />
                    </div>
                    <div className="col-4">
                      <DocumentTemplateForm.Margins />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <iframe
                    title="HTML Preview"
                    srcDoc={bodyHtmlPreview}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: '1px solid #ccc',
                      backgroundColor: '#fff',
                    }}
                  />
                </div>
              </div>
            </Panel>
          </TabPanel>
          <TabPanel>
            <Panel className="m-3">
              <div className="row">
                <div className="col-6">
                  <DocumentTemplateForm.BodyHtmlTemplate
                    onChange={(e: any) => {
                      renderPreview(e);
                    }}
                    height={400}
                  />
                </div>
                <div className="col-6">
                  <iframe
                    title="HTML Preview"
                    srcDoc={bodyHtmlPreview}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: '1px solid #ccc',
                      backgroundColor: '#fff',
                    }}
                  />
                </div>
              </div>
            </Panel>
          </TabPanel>
          <TabPanel>
            <Panel className="m-3">
              <div className="row">
                <div className="col-6">
                  <DocumentTemplateForm.BodyTextTemplate height={400} />
                </div>
              </div>
            </Panel>
          </TabPanel>
          <TabPanel>
            <Panel className="m-3">
              <div className="row">
                <div className="col-6">
                  <DocumentTemplateForm.GqlQuery
                    height={300}
                    onChange={(e) => setContextValue('gqlQuery', e)}
                  />
                  <DocumentTemplateForm.GqlVariables
                    height={100}
                    onChange={(e) => setContextValue('gqlVariables', e)}
                  />
                </div>
                <div className="col-6">
                  <Button onClick={onQueryData}>Query</Button>
                  <JsonInput
                    name={'gqlData'}
                    label={'Data'}
                    height={400}
                    valueInput={gqlData}
                  />
                </div>
              </div>
            </Panel>
          </TabPanel>
        </DocumentTemplateForm>
      </Tabs>
      <div className="row m-3">
        <div className="col-auto text-right">
          {(userHas(UPDATE_DOCUMENTTEMPLATE_LINK_KEY, initialValues?.links) ||
            isCreateMode) && (
            <Button
              name="save-documentTemplate"
              type="submit"
              color="primary"
              className="mr-2"
              form={'document-templates-form'}
              isSending={isSending}
            >
              Save Document Template
            </Button>
          )}
          <Button
            type="button"
            color="secondary"
            onClick={onCancel}
            disabled={isSending}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  );
};
