import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  ModeOfTransportationDto,
  ModeOfTransportationDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  modeOfTransportationStore,
  getModeOfTransportationsColumnsFx,
  getModeOfTransportationsFx,
  deleteModeOfTransportationFx,
  exportModeOfTransportationsFx,
  importModeOfTransportationsFx,
} from '../modeOfTransportations.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_MODEOFTRANSPORTATION_LINK_KEY,
  DELETE_MODEOFTRANSPORTATION_LINK_KEY,
  IMPORT_MODEOFTRANSPORTATIONS_LINK_KEY,
  EXPORT_MODEOFTRANSPORTATIONS_LINK_KEY,
  GetModeOfTransportationParams,
} from '../modeOfTransportations.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { ModeOfTransportationDialog } from './modeOfTransportation.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type ModeOfTransportationsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (modeOfTransportationParams: any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    modeOfTransportation: ModeOfTransportationDto,
    modeOfTransportationParams: GetModeOfTransportationParams,
  ) => void;
};

export const ModeOfTransportationsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: ModeOfTransportationsListProps) => {
  const [
    modeOfTransportations,
    setModeOfTransportations,
  ] = useState<ModeOfTransportationDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    modeOfTransportationColumns: columns,
    defaultModeOfTransportationColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(modeOfTransportationStore);

  useEffect(() => {
    getModeOfTransportationsColumnsFx();
  }, []);

  const getModeOfTransportations = useCallback(async () => {
    const modeOfTransportationsData = await getModeOfTransportationsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(modeOfTransportationsData));
    setModeOfTransportations(modeOfTransportationsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: ModeOfTransportationDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_MODEOFTRANSPORTATIONS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importModeOfTransportationsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getModeOfTransportations();
          },
          result?.links,
        ),
        getAction(
          EXPORT_MODEOFTRANSPORTATIONS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportModeOfTransportationsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getModeOfTransportations],
  );

  useEffect(() => {
    getModeOfTransportations();
  }, [getModeOfTransportations]);

  const onDeleteModeOfTransportation = useCallback(
    async (modeOfTransportation: ModeOfTransportationDto) => {
      if (
        userHas(
          DELETE_MODEOFTRANSPORTATION_LINK_KEY,
          modeOfTransportations?.items[0]?.links,
        )
      ) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Mode of transportation`,
            message: 'Are you sure you want to delete mode of transportation?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteModeOfTransportationFx(modeOfTransportation);
            getModeOfTransportations();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, modeOfTransportations],
  );

  const onEditModeOfTransportation = (
    modeOfTransportation: ModeOfTransportationDto,
  ) => {
    if (modeOfTransportation)
      goToDetails({
        modeOfTransportationId: modeOfTransportation.modeOfTransportationId,
      });
  };

  const onModeOfTransportationSelect = useCallback(
    async (modeOfTransportation) => {
      const selectedModeOfTransportation = await showDialog({
        dialog: ModeOfTransportationDialog,
        props: {
          modeOfTransportationId: modeOfTransportation.modeOfTransportationId,
          title: 'Update Mode of transportation',
        },
      });
      if (selectedModeOfTransportation) {
        getModeOfTransportations();
      }
    },
    [getModeOfTransportations],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['modeOfTransportationId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={modeOfTransportations.items}
          onLimitChanged={onLimitChanged}
          total={modeOfTransportations.totalCount}
          onDelete={onDeleteModeOfTransportation}
          onSort={onSort}
          onEdit={onEditModeOfTransportation}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onModeOfTransportationSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {modeOfTransportations?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Mode of transportation"
            entityLinkKey={CREATE_MODEOFTRANSPORTATION_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
