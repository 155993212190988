import React, { BaseSyntheticEvent, CSSProperties, useState } from 'react';
import { useStore } from 'effector-react';
import {
  clearMessages,
  messagesStore,
  removeMessage,
} from '../../messages.store';
import { localized } from '../layout/component-hooks';

export type Message = {
  id?: string;
  type: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger';
  message: string;
};

export type MessagesProps = {
  className?: string;
  style?: CSSProperties;
};

export const MessagesComponent = ({ style, className }: MessagesProps) => {
  const { messages } = useStore(messagesStore);

  return (
    <div
      style={style}
      className={`${className} position-fixed right-0 d-flex flex-column align-items-end overflow-hidden`}
    >
      {messages.map((messageObject) => {
        const { id, message, type = 'info' } = messageObject;
        return (
          <div
            key={id}
            id={id}
            className={`alert alert-${type} cursor-pointer m-2`}
            style={{ width: 'fit-content', whiteSpace: 'pre-wrap' }}
            role="alert"
            onClick={() => removeMessage(messageObject)}
          >
            {localized(message)}
          </div>
        );
      })}
    </div>
  );
};

export const Messages = React.memo(MessagesComponent);
