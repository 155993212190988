import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { OrganizationScreen } from './screens/organization.screen';

export const ORGANIZATION_PATH = 'organization';

export const InnerOrganizationRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <OrganizationScreen pageTitle="Update Organization" group={group} />
        }
      />
    </Routes>
  );
};
