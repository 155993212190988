import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { EmployeeListScreen } from './screens/employee-list.screen';
import { EmployeeScreen } from './screens/employee.screen';

export const EMPLOYEES_LIST_PATH = '/employees';
export const EMPLOYEES_EDIT_PATH = '/employees/:contactId';
export const EMPLOYEES_CREATE_PATH = '/employees/0';

export const EmployeesRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <EmployeeListScreen pageTitle="Employees" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <EmployeeScreen pageTitle="Add New Employee" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <EmployeeScreen pageTitle="Update Employee" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
