import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { ContactSelectInputProps } from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { ContactType, ServiceType } from '../../../models/data.models';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { SelectAccountingItemPaginate } from '../../accountingItems/components/accountingItem-react-select.component';
import { SelectCommodityTypePaginate } from '../../commodityTypes/components/commodityType-react-select.component';
import { SelectCurrencyPaginate } from '../../currencies/components/currencies-react-select.component';
import { SelectModeOfTransportationPaginate } from '../../modeOfTransportations/components/modeOfTransportation-react-select.component';
import { SelectPortPaginate } from '../../ports/components/port-react-select.component';
import {
  FormikEnumSelectProps,
  FormikEnumSelect,
} from '../../tariffs/components/FormikEnumSelect';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';

export const RateForm = ({
  children,
  onSubmit,
  initialValues,
  id,
}: FormProps) => {
  return (
    <Form id={id} onSubmit={onSubmit} initialValues={initialValues}>
      {children}
    </Form>
  );
};
RateForm.AmendmendNumber = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'amendmendNumber'}
      label={'Amendmend Number'}
      placeholder={'Amendmend Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
RateForm.AutomaticallyCreateCharge = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'automaticallyCreateCharge'}
      label={'Automatically Create Charge'}
      defaultValue={defaultValue}
      placeholder={'Automatically Create Charge'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
RateForm.IsHazardous = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'IsHazardous'}
      label={'Is Hazardous'}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={'Is Hazardous'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
RateForm.ContractNumber = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'contractNumber'}
      label={'Contract Number'}
      placeholder={'Contract Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
RateForm.Notes = ({ className, style, size, disabled }: InputPublicProps) => {
  return (
    <Input
      name={'notes'}
      label={'Notes'}
      placeholder={'Notes'}
      required={false}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
RateForm.TransitDaysMin = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <Input
      name={'transitDaysMin'}
      label={'Transit Days Minimum'}
      placeholder={'Transit Days Minimum'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};
RateForm.TransitDaysMax = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <Input
      name={'transitDaysMax'}
      label={'Transit Days Maximum'}
      placeholder={'Transit Days Maximum'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

RateForm.CarrierContactSelect = ({
  selectedFilter = `contactType: ${ContactType.Carrier}`,
  placeholder = 'Select Carrier',
  sort = 'name',
  searchKey = 'name',
  defaultValue,
  id = 'carrierId',
  nameId = '',
  header = 'Final Mile Carrier',
  required = false,
  onChange = () => {},
  readonly,
  contactTypes = [ContactType.Carrier],
  multiple = false,
  closeMenuOnSelect = true,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      showContactType={true}
      useContext={true}
      isMulti={multiple}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      selectedSearchKey={searchKey}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};

RateForm.ClientContactSelect = ({
  selectedFilter = `contactType: ${ContactType.Customer}`,
  placeholder = 'Select Customer',
  sort = 'name',
  searchKey = 'name',
  defaultValue,
  id = 'clientId',
  nameId = '',
  header = 'Customer',
  required = false,
  onChange = () => {},
  readonly,
  contactTypes = [ContactType.Customer],
  multiple = false,
  closeMenuOnSelect = true,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      showContactType={true}
      useContext={true}
      isMulti={multiple}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      selectedSearchKey={searchKey}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};

RateForm.CountryOfDestination = ({
  selectedFilter = ``,
  placeholder = 'Select Destination Country',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'countryOfDestinationCode',
  header = 'Country Of Destination',
  onChange = () => {},
  readonly,
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.CountryOfOrigin = ({
  selectedFilter = ``,
  placeholder = 'Select Origin Country',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'countryOfOriginCode',
  header = 'Country Of Origin',
  onChange = () => {},
  readonly,
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.AccountingItemSelect = ({
  selectedFilter = ` `,
  placeholder = 'Select Service',
  required = true,
  defaultValue,
  sort = 'description',
  id = 'accountingItemId',
  header = 'Service',
  onChange = () => {},
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectAccountingItemPaginate
      nameId={nameId}
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeAccountingItem={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.CommodityTypeSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Commodity Type',
  required = true,
  defaultValue,
  sort = '',
  id = 'commodityTypeId',
  header = 'Description/Commodity',
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <SelectCommodityTypePaginate
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCommodityType={onChange}
    />
  );
};

RateForm.CurrencySelect = ({
  selectedFilter = ``,
  placeholder = 'Select Currency',
  required = true,
  defaultValue,
  sort = 'currencyId',
  id = 'currencyId',
  header = 'Currency',
  onChange = () => {},
  nameId = '',
  isClearable = false,
  disabled,
}: InputPublicProps) => {
  return (
    <SelectCurrencyPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCurrency={onChange}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};

RateForm.ModeOfTransportation = ({
  selectedFilter = ``,
  placeholder = 'Select Mode of Transportation',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'modeOfTransportationId',
  header = 'Mode of Transportation',
  onChange = () => {},
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectModeOfTransportationPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeModeOfTransportation={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.PortOfReceipt = ({
  selectedFilter = ``,
  placeholder = 'Select Place Of Receipt',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'portOfReceiptId',
  header = 'Place Of Receipt',
  onChange = () => {},
  readonly,
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectPortPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePort={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.PortOfLoading = ({
  selectedFilter = ``,
  placeholder = 'Select Port Of Loading',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'portOfLoadingId',
  header = 'Port Of Loading',
  onChange = () => {},
  readonly,
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectPortPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePort={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.PortOfUnloading = ({
  selectedFilter = ``,
  placeholder = 'Select Port Of Unloading',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'portOfUnloadingId',
  header = 'Port Of Unloading',
  onChange = () => {},
  readonly,
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectPortPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePort={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.PortOfDelivery = ({
  selectedFilter = ``,
  placeholder = 'Select Place Of delivery',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'portOfDeliveryId',
  header = 'Place Of Delivery',
  onChange = () => {},
  readonly,
  nameId = '',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectPortPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePort={onChange}
      isDisabled={disabled}
    />
  );
};

RateForm.EnumSelect = ({
  id,
  name,
  enumType,
  onChange,
  placeholder,
  required = false,
  disabled = false,
  header,
  readonly,
  defaultValue,
}: FormikEnumSelectProps) => {
  return (
    <FormikEnumSelect
      id={id}
      name={name}
      enumType={enumType}
      error={false}
      isSearchable={true}
      isClearable={false}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={onChange}
      tabIndex={5}
      defaultValue={defaultValue}
    />
  );
};

RateForm.Date = ({
  required = false,
  defaultValue,
  header = 'Effective Date',
  nameId = 'date',
  placeholder = 'Select Date',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

RateForm.CustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      isSending={isSending}
      saveButtonRenderCondition={saveButtonRenderCondition}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
