import {
  AttachmentDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateAttachmentCommandValues,
  UpdateAttachmentCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAttachmentRequest,
  getAttachmentsListRequest,
  getAttachmentRequest,
  deleteAttachmentRequest,
  updateAttachmentRequest,
  GetAttachmentParams,
  getAttachmentContentRequest,
} from './attachments.service';
import { FilteredField } from '../common/components/filters/filtersTab.component';
import { addEffectStatusHandling } from '../common/messages.store';

type AttachmentsStoreState = {
  links: LinkDto[];
  attachmentColumns: Column[];
  filters: FilteredField[];
};

export const updateAttachmentsColumns = createEvent<Column[]>();
export const updateAttachmentFilters = createEvent<FilteredField[]>();

export const getAttachments = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getAttachmentsListRequest(currentOrganization, params);
});

export const createAttachment = createEffect(({ attachmentData, file }) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createAttachmentCommand: CreateAttachmentCommandValues = {
    ...attachmentData,
  };

  return createAttachmentRequest(
    currentOrganization!,
    createAttachmentCommand,
    file,
  );
});

export const getAttachment = createEffect(
  (attachmentParams: GetAttachmentParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAttachmentRequest(
      currentOrganization as LinkResourceBaseDto,
      attachmentParams,
    );
  },
);

export const updateAttachment = createEffect((attachment: AttachmentDto) => {
  const updateAttachmentCommand: UpdateAttachmentCommandValues = {
    ...attachment,
  };
  return updateAttachmentRequest(attachment, updateAttachmentCommand);
});

export const deleteAttachment = createEffect((attachment: AttachmentDto) => {
  return deleteAttachmentRequest(attachment);
});

export const getAttachmentContentFx = createEffect(
  ({ attachment, uriType }) => {
    return getAttachmentContentRequest({ attachment, uriType });
  },
);
addEffectStatusHandling(getAttachmentContentFx);

const defaultState: AttachmentsStoreState = {
  links: [],
  attachmentColumns: [
    {
      name: 'attachmentType',
      visible: true,
      title: 'Type',
      sortName: 'attachmentType',
    },
    { name: 'created', visible: true, title: 'Created', sortName: 'created' },
    {
      name: 'fileName',
      visible: true,
      title: 'File Name',
      sortName: 'fileName',
    },
    {
      name: 'createdByUserName',
      visible: true,
      title: 'Created By',
      sortName: 'createdByUserName',
    },
  ],
  filters: [],
};

export const attachmentStore: Store<AttachmentsStoreState> = createStore(
  defaultState,
).on(updateAttachmentsColumns, (state, payload) => {
  return { ...state, attachmentColumns: payload };
});

const defaultStateAttachmentsFilters: AttachmentsStoreState = {
  ...defaultState,
  filters: [
    {
      type: 'attachmentType',
      title: 'File type',
      name: 'attachmentType',
    },
    {
      name: 'createdUser.UserName',
      title: 'Created by',
      type: 'user',
    },
    {
      type: 'date',
      title: 'Create date',
      name: 'created',
    },
  ],
};

export const attachmentsFiltersStore: Store<AttachmentsStoreState> = createStore(
  defaultStateAttachmentsFilters,
).on(updateAttachmentFilters, (state, payload) => {
  return { ...state, commodityColumns: payload };
});
