import React, { useEffect } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PackingForm } from './packing.form';
import * as Yup from 'yup';
import { GetOrderByTrackingNumbersParams } from '../../orders/orders.service';
import {
  OrderDto,
  OrderTypes,
  PackingStatuses,
  ScanningResultFrom,
} from '../../../models/data.models';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { HiOutlineArrowSmRight, HiX } from 'react-icons/hi';
import {
  getOrderGQL,
  getOrdersByTrackingNumbers,
} from '../../orders/orders.store';
import { Message, addMessage } from '../../common/messages.store';
import { useFormikContext } from 'formik';
import { v4 } from 'uuid';
import {
  ACTION_ADD_TRACKING_NUMBER,
  ACTION_PACKAGE_SCAN,
} from '../../constants/api';
import { setSubmitAction } from './packingWizard.component';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { createActionEventFx } from '../../actionEvents/actionEvents.store';
import { updateCommodities } from '../../commodities/commodities.store';
import { triggerWorkflowByConfig } from '../../workflowTriggers/workflowTriggerHook';

export type PackingScanFragmentProps = {
  isHandleNext?: boolean | null;
  onCancel: () => void;
  isSending?: boolean | null;
  notification?: string;
};

export const packingScanSchema = {
  trackingNumber: Yup.string().when('_submitAction', (val, schema) => {
    return val === ACTION_PACKAGE_SCAN || val === ACTION_ADD_TRACKING_NUMBER
      ? schema.required("Can't be blank")
      : schema;
  }),
};

export const getPackingScanInitialState = () => {
  const initialState = {
    trackingNumbers: [],
    trackingNumber: '',
  };

  return initialState;
};

export const onPackingScanFragment = (onComplete: () => void) => async (
  data,
  { setFieldValue },
) => {
  const trackingNumber = data.trackingNumber;
  try {
    const params: GetOrderByTrackingNumbersParams = {
      trackingNumbers: [],
    };
    params.trackingNumbers.push(trackingNumber?.trim());

    const ordersByTrackingNumbers: OrderDto[] = await getOrdersByTrackingNumbers(
      params,
    );
    let purchase = ordersByTrackingNumbers?.find(
      (order: OrderDto) => order.orderType === OrderTypes.Purchase,
    );

    const parcelShipment = ordersByTrackingNumbers?.find(
      (order: OrderDto) => order.orderType === OrderTypes.ParcelShipment,
    );

    // Hack for parcel shipment
    if (!purchase && parcelShipment) {
      purchase = parcelShipment;
    }

    if (purchase) {
      await createActionEventFx({
        eventName: 'purchase.packing',
        eventData: {
          trackingNumber: trackingNumber,
          location: 'PCK',
        },
      });

      setFieldValue('purchase', purchase);

      if (parcelShipment) {
        const scannedInnerPurchase = parcelShipment.commodities?.find((x) =>
          x.commodityTrackingNumbers.some(
            (y) =>
              y.trackingNumber.toLowerCase() === trackingNumber.toLowerCase(),
          ),
        );

        if (parcelShipment.commodities) {
          parcelShipment.commodities.forEach((item) => {
            if (item.commodityId === scannedInnerPurchase.commodityId) {
              item.customValues = {
                ...item.customValues,
                packingStatus: PackingStatuses.Scanned,
              };
            } else {
              if (
                item.customValues?.packingStatus !== PackingStatuses.Scanned
              ) {
                item.customValues = {
                  ...item.customValues,
                  packingStatus: PackingStatuses.NotScanned,
                };
              }
            }
          });
          await updateCommodities(parcelShipment.commodities);
        }

        setFieldValue('parcelShipment', parcelShipment);
        const workflowExecutionVariables = {
          orderIds: [purchase.orderId, parcelShipment.orderId],
        };
        await triggerWorkflowByConfig({
          configName: 'tms.modules.packing',
          workflowIdConfigKey: 'packerScannedWorkflowId',
          workflowVariables: workflowExecutionVariables,
        });
        //get parcel shipment with changed status after workflow
        const updatedParcelShipment = await getOrderGQL({
          orderId: parcelShipment.orderId,
        });
        if (updatedParcelShipment) {
          setFieldValue('parcelShipment', updatedParcelShipment);
        }
        onComplete();
      } else {
        const message: Message = {
          id: 'tracking-number-deleted',
          type: 'success',
          autoHide: true,
          message: 'Parcel shipment is not found for commodity',
        };
        addMessage(message);
      }
    } else {
      setFieldValue('purchase', null);

      const message: Message = {
        id: 'tracking-number-deleted',
        type: 'success',
        autoHide: true,
        message: 'Purchase is not found for commodity',
      };
      addMessage(message);
    }

    const trackingNumbers = [...data.trackingNumbers];
    trackingNumbers.push(trackingNumber);
    setFieldValue('trackingNumbers', trackingNumbers);

    setFieldValue('trackingNumber', '');
  } catch (error) {
    addMessage({
      message: error?.message || error,
      type: 'danger',
      autoHide: true,
      id: v4(),
    });
  }
};

export const PackingScanFragment = ({
  isHandleNext,
  onCancel,
  isSending,
  notification,
}: PackingScanFragmentProps) => {
  const formikContext = useFormikContext<any>();

  useEffect(() => {
    if (onCancel)
      setActionButtons([
        <Button
          key={'package-packing-scan-fragment-cancel-btn'}
          color="secondary"
          className={'d-flex align-items-center justify-content-center'}
          outline={true}
          onClick={onCancel}
          style={{ minWidth: '8.5rem' }}
        >
          <HiX size={16} className={'mr-2'} />
          Cancel
        </Button>,
      ]);
  }, []);

  useEffect(() => () => clearActionButtons(), []);

  useScanner((scannerResult: ScanningResult) => {
    catchScanResult(scannerResult);
  });

  const catchScanResult = (scanningResult: ScanningResult) => {
    if (scanningResult.from === ScanningResultFrom.Scanner) {
      if (scanningResult.data) {
        formikContext.setFieldValue('trackingNumber', scanningResult.data);
        setSubmitAction(formikContext, ACTION_PACKAGE_SCAN);
      }
    }
  };

  return (
    <>
      {notification && (
        <div
          className="d-flex align-items-center justify-content-center mt-5"
          style={{ minHeight: '6vh' }}
        >
          <div
            className="box col-6 p-3 shadow-none text-center text-white"
            style={{ backgroundColor: '#00C714' }}
          >
            {notification}
          </div>
        </div>
      )}
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: notification ? '35vh' : '50vh' }}
      >
        <div className={'box col-6'}>
          <h3 className="pb-4 text-left">
            Scan Tracking Number Barcode or Enter Manually
          </h3>
          <div className="py-3">
            <h3 className={'text-label pb-2'}>Tracking Number</h3>
            <PackingForm.TrackingNumber
              barcode={false}
              autoSubmit={() =>
                setSubmitAction(formikContext, ACTION_PACKAGE_SCAN)
              }
            />
          </div>
          {isSending ? (
            <div className="row justify-content-center">
              <div className=" spinner-border text-primary" role="status" />
            </div>
          ) : (
            <div className="row justify-content-center">
              {isHandleNext && (
                <Button
                  className="w-100 mx-2 d-flex align-items-center justify-content-center"
                  color="primary"
                  style={{ minWidth: '8.5rem' }}
                  onClick={() => {
                    setSubmitAction(formikContext, ACTION_PACKAGE_SCAN);
                  }}
                >
                  <HiOutlineArrowSmRight size={20} className={'mr-2'} />
                  Next
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
