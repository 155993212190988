import { Dialog } from '../../common/components/dialog/dialog.component';
import { TrackingEventEdit } from './trackingEvent-edit.component';
import { TrackingEventDto } from '../../../models/data.models';

export type TrackingEventDialogProperties = {
  trackingEventId?: number | null;
  trackingEvent?: TrackingEventDto;
  title: string;
  className?: string;
  isEditMode?: boolean | undefined;
  saveButtonRenderCondition?: boolean;
};

export class TrackingEventDialog extends Dialog<TrackingEventDialogProperties> {
  constructor(props: TrackingEventDialogProperties) {
    super(props);
    this.isEditMode = props.isEditMode;
    this.onTrackingEventSaved = this.onTrackingEventSaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onTrackingEventSaved = (trackingEvent: TrackingEventDto) => {
    this.close(trackingEvent);
  };

  onCancel = () => {
    this.close(null);
  };

  isEditMode: boolean | undefined = false;

  renderContent(): any {
    const { trackingEventId, trackingEvent, saveButtonRenderCondition } = this
      .props as TrackingEventDialogProperties;
    return (
      <TrackingEventEdit
        trackingEventId={trackingEventId}
        defaultTrackingEvent={trackingEvent}
        onTrackingEventCreated={this.onTrackingEventSaved}
        onTrackingEventUpdated={this.onTrackingEventSaved}
        isEditMode={this.isEditMode}
        saveButtonRenderCondition={saveButtonRenderCondition}
        onCancel={this.onCancel}
      />
    );
  }
}
