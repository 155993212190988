import {
  ContainerTypeDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  ContainerTypeDtoPagedResult,
  CreateContainerTypeCommandValues,
  UpdateContainerTypeCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CONTAINERTYPES_LINK_KEY = 'get-containertypes';
export const CREATE_CONTAINERTYPE_LINK_KEY = 'create-containertype';
export const GET_CONTAINERTYPE_LINK_KEY = 'get-containertype';
export const UPDATE_CONTAINERTYPE_LINK_KEY = 'update-containertype';
export const DELETE_CONTAINERTYPE_LINK_KEY = 'delete-containertype';
export const IMPORT_CONTAINERTYPES_LINK_KEY = 'import-containertypes';
export const EXPORT_CONTAINERTYPES_LINK_KEY = 'export-containertypes';
export const CONTAINERTYPE_ENTITY_NAME = 'Container Type';

export interface GetContainerTypeParams {
  containerTypeCode: string;
}

export interface ImportContainerTypesParams {
  organizationId: number;
}

export interface ExportContainerTypesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getContainerTypeRequest = async (
  resource: LinkResourceBaseDto | null,
  { containerTypeCode }: GetContainerTypeParams,
): Promise<ContainerTypeDto> => {
  if (resource && containerTypeCode) {
    const getContainerTypeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERTYPE_LINK_KEY,
    );
    if (getContainerTypeLink) {
      const result = await execLink(getContainerTypeLink, {
        containerTypeCode,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getContainerTypesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContainerTypeDtoPagedResult> => {
  if (resource) {
    const getContainerTypesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERTYPES_LINK_KEY,
    );
    if (getContainerTypesListLink) {
      const result = await execLink(getContainerTypesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTAINERTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createContainerTypeRequest = async (
  resource: LinkResourceBaseDto,
  containerType: CreateContainerTypeCommandValues,
) => {
  const createContainerTypeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTAINERTYPE_LINK_KEY,
  );
  if (createContainerTypeLink) {
    const result = await execLink(createContainerTypeLink, containerType);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateContainerTypeRequest = async (
  resource: LinkResourceBaseDto,
  containerType: UpdateContainerTypeCommandValues,
) => {
  const updateContainerTypeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTAINERTYPE_LINK_KEY,
  );
  if (updateContainerTypeLink) {
    const result = await execLink(updateContainerTypeLink, containerType);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteContainerTypeRequest = async (
  containerType: ContainerTypeDto,
) => {
  const deleteContainerTypeLink = containerType.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTAINERTYPE_LINK_KEY,
  );
  if (deleteContainerTypeLink) {
    const result = await execLink(deleteContainerTypeLink, containerType);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importContainerTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportContainerTypesParams,
  file: File,
) => {
  const importContainerTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CONTAINERTYPES_LINK_KEY,
  );
  if (importContainerTypesLink) {
    const result = await postFormData(importContainerTypesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CONTAINERTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportContainerTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportContainerTypesParams,
) => {
  const exportContainerTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CONTAINERTYPES_LINK_KEY,
  );
  if (exportContainerTypesLink) {
    const result = await downloadLinkFile(exportContainerTypesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CONTAINERTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};
