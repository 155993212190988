import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { AccountingAccountScreen } from './screens/accountingAccount.screen';
import { AccountingAccountsListScreen } from './screens/accountingAccount-list.screen';

export const ACCOUNTINGACCOUNTS_LIST_PATH = '/accounting-account';
export const ACCOUNTINGACCOUNT_EDIT_PATH = '/accounting-account/:accountId';
export const ACCOUNTINGACCOUNT_CREATE_PATH = '/accounting-account/0';

export const AccountingAccountsRoute = () => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <AccountingAccountsListScreen
              pageTitle="Accounting Accounts"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <AccountingAccountScreen
              pageTitle="Add New Accounting Account"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':accountId'}
        element={
          <PrivateRoute>
            <AccountingAccountScreen
              pageTitle="Update Accounting Account"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
