import {
  JobStatusDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  JobStatusListItemDtoPagedResult,
  CreateJobStatusCommandValues,
  UpdateJobStatusCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createJobStatusRequest,
  getJobStatusesListRequest,
  getJobStatusRequest,
  deleteJobStatusRequest,
  updateJobStatusRequest,
  importJobStatusesRequest,
  exportJobStatusesRequest,
  GetJobStatusParams,
} from './jobStatuses.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type JobStatusesStoreState = {
  links: LinkDto[];
  jobStatusColumns: EntityFieldDto[];
  defaultJobStatusColumns: EntityFieldDto[];
};

export const getJobStatusesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'JobStatus',
  });
});

addEffectStatusHandling(getJobStatusesColumnsFx);

export const getJobStatusesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'JobStatus',
  });
});

addEffectStatusHandling(getJobStatusesDefaultColumnsFx);

export const updateJobStatusesColumns = createEvent<EntityFieldDto[]>();

export const getJobStatusesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getJobStatusesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getJobStatusesFx);

export const createJobStatusFx = createEffect((jobStatusData: JobStatusDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createJobStatusCommand: CreateJobStatusCommandValues = {
    ...jobStatusData,
  };

  return createJobStatusRequest(currentOrganization!, createJobStatusCommand);
});

addEffectStatusHandling(createJobStatusFx, {
  completeMessage: 'Job status was created successfully',
  errorMessage: 'Job status was not created',
});

export const getJobStatusFx = createEffect(
  (jobStatusParams: GetJobStatusParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getJobStatusRequest(
      currentOrganization as LinkResourceBaseDto,
      jobStatusParams,
    );
  },
);

addEffectStatusHandling(getJobStatusFx);

export const updateJobStatusFx = createEffect((jobStatus: JobStatusDto) => {
  const updateJobStatusCommand: UpdateJobStatusCommandValues = { ...jobStatus };
  return updateJobStatusRequest(jobStatus, updateJobStatusCommand);
});

addEffectStatusHandling(updateJobStatusFx, {
  completeMessage: 'Job status was updated successfully',
  errorMessage: 'Job status was not updated',
});

export const deleteJobStatusFx = createEffect((jobStatus: JobStatusDto) => {
  return deleteJobStatusRequest(jobStatus);
});

addEffectStatusHandling(deleteJobStatusFx, {
  completeMessage: 'Job status was deleted successfully',
  errorMessage: 'Job status was not deleted',
});

export const exportJobStatusesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportJobStatusesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportJobStatusesFx, {
  completeMessage: 'Job statuses were exported successfully',
  errorMessage: 'Job statuses were not exported',
});

export const importJobStatusesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importJobStatusesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importJobStatusesFx, {
  completeMessage: 'Job statuses were imported successfully',
  errorMessage: 'Job statuses were not imported',
  inFlightMessage: 'Importing job statuses...',
});

const defaultState: JobStatusesStoreState = {
  links: [],
  jobStatusColumns: [],
  defaultJobStatusColumns: [],
};

export const jobStatusStore: Store<JobStatusesStoreState> = createStore(
  defaultState,
)
  .on(getJobStatusesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      jobStatusColumns: payload.result.items,
      defaultJobStatusColumns: payload.result.items,
    };
  })
  .on(getJobStatusesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultJobStatusColumns: payload.result.items,
    };
  })
  .on(updateJobStatusesColumns, (state, payload) => {
    return { ...state, jobStatusColumns: payload };
  });
