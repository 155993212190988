import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { Helmet } from 'react-helmet';
import { ConsolidatedShipmentEdit } from '../components/consolidatedShipment-edit.component';
import { ConsolidatedShipmentStateProperties } from '../components/consolidatedShipment.dialog';
import { OrderTypes } from '../../../models/data.models';
import {
  AIR_ORDER_EDIT_PATH,
  AIR_ORDER_LIST_PATH,
} from '../../airShipmentOrders/airShipmentOrders.route';
import {
  OCEAN_ORDER_EDIT_PATH,
  OCEAN_ORDER_LIST_PATH,
} from '../../oceanShipment/oceanShipmentOrders.route';

export const ConsolidatedShipmentScreen = ({ pageTitle, group }) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderId, orderType } = useParams();
  const { state } = useLocation();

  const onCancel = () => {
    const listPath =
      orderType === OrderTypes.AirShipmentOrder
        ? AIR_ORDER_LIST_PATH
        : OCEAN_ORDER_LIST_PATH;
    const path = generatePath(INTERNAL_PATH + listPath, {
      organizationId,
    });
    navigate(path);
  };

  const goToDetails = () => {
    const editPath =
      orderType === OrderTypes.AirShipmentOrder
        ? AIR_ORDER_EDIT_PATH
        : OCEAN_ORDER_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + editPath, {
      organizationId: organizationId,
      orderId,
    });
    navigate(path);
  };

  const defaultState: ConsolidatedShipmentStateProperties = {
    orderType: OrderTypes[orderType],
    countries: state?.countries,
    currentPallet: state?.currentPallet,
    divisionId: state?.divisionId,
    modeOfTransportationId: state?.modeOfTransportationId,
  };

  return (
    <InternalLayout
      group={group}
      title={pageTitle}
      createPath={null}
      createLabel={'Shipment'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ConsolidatedShipmentEdit
        orderId={orderId}
        defaultState={defaultState}
        isDialog={false}
        onCancel={onCancel}
        goToDetails={goToDetails}
      />
    </InternalLayout>
  );
};
