import React, { useEffect, useState } from 'react';
import { CommodityDto } from '../../../../models/data.models';
import { FormContext } from '../../../common/components/form/form.component';
import { CargoFragment } from '../components/cargoFragment.component';
import { Button } from 'react-bootstrap';

export type CargoFragmentSelectCommodities = {
  onAirOrderSaved?: () => void;
  closeDialog?: () => void;
  onCancel?: () => void;
  saveBtnLabel?: string;
  isDialog?: boolean;
  onCommoditiesSelect?: (commodities: CommodityDto[] | null) => void;
  selectedCommodities: CommodityDto[];
  setSelectedCommodities?: () => {};
};

export const CargoFragmentSelectCommodities = ({
  onCancel,
  onCommoditiesSelect = (commodities: CommodityDto[]) => {},
  saveBtnLabel,
  selectedCommodities,
}) => {
  const [commodities, setCommodities] = useState<CommodityDto[] | null>([]);
  const [commodityIds, setCommodityIds] = useState([]);

  useEffect(() => {
    if (selectedCommodities.length > 0) {
      const newCommodityIds = selectedCommodities
        .map((x) => x.commodityId)
        .filter((id) => !commodityIds.includes(id));
      setCommodityIds((prevCommodityIds) => [
        ...prevCommodityIds,
        ...newCommodityIds,
      ]);

      const newCommodities = selectedCommodities.filter(
        (selectedCommodity) =>
          !commodities.some(
            (commodity) =>
              commodity.commodityId === selectedCommodity.commodityId,
          ),
      );
      setCommodities((prevCommodities) => [
        ...prevCommodities,
        ...newCommodities,
      ]);
    }
  }, []);

  return (
    <div>
      <FormContext.Consumer>
        {(context) => (
          <div>
            <CargoFragment
              commodities={commodities}
              setCommodities={setCommodities}
              commodityIds={commodityIds}
              setCommodityIds={setCommodityIds}
            />
            <div className="justify-content-end d-flex row col-12 p-0">
              <div className="col-2 pl-4">
                <Button
                  form={'select-commodity-form'}
                  type="submit"
                  color="primary"
                  className="btn-block"
                  onClick={() => onCommoditiesSelect(commodities)}
                >
                  {saveBtnLabel}
                </Button>
              </div>
              <div className="col-2">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        )}
      </FormContext.Consumer>
    </div>
  );
};
