import React, { useEffect, useState } from 'react';
import {
  CustomFieldDto,
  CustomFieldEntityType,
  CustomFieldType,
  DangerousItemsValues,
  PurposeOfCommodityValues,
} from '../../../../models/data.models';
import { getCustomFieldsFx } from '../../../customFields/customFields.store';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Panel } from '../panel/panel.component';
import { Input } from './input.component';
import { Button } from '../button/button.component';
import { validateNumberInput } from '../../../../utils/helper.utils';
import { Field, FormikProps, FormikValues } from 'formik';
import { Checkbox } from '../checkbox/checkbox.component';

export type CustomValuesInputProps = {
  entityType?: CustomFieldEntityType;
  defaultValue?: {
    [key: string]: any;
  };
  customFields?: CustomFieldDto[];
  inputNamePrefix?: string;
  saveButtonRenderCondition?: boolean;
  isSending?: boolean;
  formName?: string;
  entityName?: string;
  context?: FormikProps<FormikValues>;
  onChange?: (result: object) => void;
  onSubmit?: (result: object) => void;
  onCancel?: (result: object) => void;
  colSize?: number;
};

export const CustomValuesInput = ({
  entityType,
  defaultValue = {},
  customFields = [],
  inputNamePrefix = null,
  isSending,
  saveButtonRenderCondition,
  formName,
  entityName,
  context,
  onSubmit,
  onChange,
  onCancel,
  colSize = 12,
}: CustomValuesInputProps) => {
  const [fieldsByCategories, setFieldsByCategories] = useState([]);

  for (let el in defaultValue) {
    if (defaultValue[el] === 'true') {
      defaultValue[el] = true;
    } else if (defaultValue[el] === 'false') {
      defaultValue[el] = false;
    }
  }
  const [stateValue, setStateValue] = useState(defaultValue);

  const handleCheckboxChange = (key: string, value: boolean) => {
    const { DangerousItems } = stateValue;
    if (value) {
      setStateValue({
        ...stateValue,
        DangerousItems: {
          ...DangerousItems,
          value: DangerousItems?.value?.length
            ? [...DangerousItems?.value, key]
            : [key],
        },
      });
    } else {
      setStateValue({
        ...stateValue,
        DangerousItems: {
          ...DangerousItems,
          value: DangerousItems?.value.filter((item) => item !== key),
        },
      });
    }
  };

  const getFields = async () => {
    if (!customFields) {
      getCustomFieldsFx({
        filter: `customFieldEntityType: ${entityType}`,
      }).then((fields) => {
        setFieldsByCategories((fieldsByCategories) => {
          fieldsByCategories = [];
          fields.items.forEach((field) => {
            let currentCategory = fieldsByCategories.find(
              (group) => group.category === field.category,
            );
            if (!currentCategory) {
              currentCategory = {
                category: field.category,
                fields: [],
              };
              fieldsByCategories.push(currentCategory);
            }
            currentCategory.fields.push(field);
          });
          return [...fieldsByCategories];
        });
      });
    } else {
      setFieldsByCategories((fieldsByCategories) => {
        fieldsByCategories = [];
        customFields.forEach((field) => {
          let currentCategory = fieldsByCategories.find(
            (group) =>
              group.category === field.category ||
              (!group.category && !field.category),
          );
          if (!currentCategory) {
            currentCategory = {
              category: field.category,
              fields: [],
            };
            fieldsByCategories.push(currentCategory);
          }
          currentCategory.fields.push(field);
        });
        return [...fieldsByCategories];
      });
    }
  };

  useEffect(() => {
    getFields();
  }, [customFields]);

  useEffect(() => {
    if (onChange) onChange(stateValue);
  }, [stateValue]);

  const namePrefix = !!inputNamePrefix ? `${inputNamePrefix}.` : '';

  const setContextValue = (val: any, fieldName: string) => {
    context?.setFieldValue(`${namePrefix}customValues.${fieldName}`, val);
  };

  return (
    <Tabs>
      <TabList>
        {fieldsByCategories.map((group, groupIndex) => {
          return (
            <Tab key={`tab-${groupIndex}`}>
              {group.category ? group.category : 'General'}
            </Tab>
          );
        })}
      </TabList>
      <div>
        {fieldsByCategories.map((group, groupIndex) => {
          return (
            <TabPanel forceRender={true} key={`tabpanel-${groupIndex}`}>
              <Panel className="m-3">
                <div className="row">
                  <div className={`col-${colSize}`}>
                    {group.fields.map((field, fieldIndex) => {
                      switch (field.customFieldType) {
                        case 'text':
                        case CustomFieldType.Text:
                          return (
                            <Input
                              type={'input-OnChange-text-with-value'}
                              name={`${namePrefix}customValues.${field.internalName}`}
                              valueInput={
                                stateValue[field.internalName]
                                  ? stateValue[field.internalName]
                                  : null
                              }
                              placeholder={field.displayName}
                              header={field.displayName}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] = value;
                                  return { ...stateValue };
                                });
                                setContextValue(value, field.internalName);
                              }}
                              maxLength={200}
                              key={`input-${groupIndex}-${fieldIndex}`}
                            />
                          );
                        case CustomFieldType.Number:
                          return (
                            <Input
                              type={'text'}
                              name={`${namePrefix}customValues.${field.internalName}`}
                              defaultValue={
                                stateValue[field.internalName]
                                  ? stateValue[field.internalName]
                                  : null
                              }
                              placeholder={field.displayName}
                              header={field.displayName}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] =
                                    value.target.value;
                                  return { ...stateValue };
                                });
                                setContextValue(
                                  value.target.value,
                                  field.internalName,
                                );
                              }}
                              onKeyDown={validateNumberInput}
                              key={`input-${groupIndex}-${fieldIndex}`}
                            />
                          );
                        case CustomFieldType.Date:
                          return (
                            <Input
                              type={'date'}
                              name={`${namePrefix}customValues.${field.internalName}`}
                              defaultValue={
                                stateValue[field.internalName]
                                  ? stateValue[field.internalName]
                                  : null
                              }
                              placeholder={field.displayName}
                              header={field.displayName}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] = value;
                                  return { ...stateValue };
                                });
                                setContextValue(value, field.internalName);
                              }}
                              key={`input-${groupIndex}-${fieldIndex}`}
                            />
                          );
                        case CustomFieldType.Boolean:
                          return (
                            <Input
                              defaultValue={stateValue[field.internalName]}
                              type={'checkbox'}
                              name={`${namePrefix}customValues.${field.internalName}`}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] = value;
                                  return { ...stateValue };
                                });
                                setContextValue(value, field.internalName);
                              }}
                              key={`input-${groupIndex}-${fieldIndex}`}
                            />
                          );
                        case CustomFieldType.TextArea:
                          return (
                            <Input
                              name={`${namePrefix}customValues[${field.internalName}]`}
                              id={`${namePrefix}customValues.${field.internalName}`}
                              label={field.displayName}
                              placeholder={field.displayName}
                              required={false}
                              type={'textarea'}
                              disabled={false}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] =
                                    value.target.value;
                                  return { ...stateValue };
                                });
                                setContextValue(
                                  value.target.value,
                                  field.internalName,
                                );
                              }}
                              key={`input-${groupIndex}-${fieldIndex}`}
                            />
                          );
                        case CustomFieldType.PurposeOfCommodity:
                          return (
                            <div className={`col-12`} key={field.internalName}>
                              <div className="input-label font-weight-bold mb-3">
                                {field.displayName}
                              </div>
                              <Input
                                type="radio-button"
                                name={`${namePrefix}customValues.${field.internalName}`}
                                enumName={PurposeOfCommodityValues}
                                onChange={(value) => {
                                  setContextValue(value, field.internalName);
                                }}
                              />
                            </div>
                          );
                        case CustomFieldType.DangerousItems:
                          return (
                            <div className={`col-12`} key={field.internalName}>
                              <div className="input-label font-weight-bold mb-3">
                                {field.displayName}:{' '}
                              </div>
                              <Input
                                type="checkbox-group"
                                name={`${namePrefix}customValues.${field.internalName}`}
                                id={`${namePrefix}customValues.${field.internalName}`}
                                enumName={DangerousItemsValues}
                              />
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>
                <div className="justify-content-end row mt-3">
                  {saveButtonRenderCondition && (
                    <div className="col-3">
                      <Button
                        form={formName}
                        type="submit"
                        color="primary"
                        className="w-100 btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save {entityName}
                      </Button>
                    </div>
                  )}
                  {saveButtonRenderCondition && (
                    <div className="col-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  )}
                </div>
              </Panel>
            </TabPanel>
          );
        })}
        {customFields.length === 0 && (
          <Panel className="m-3">
            <div className="mb-0 text-center">
              <h3 className="text-muted">
                There are no custom fields for this entity
              </h3>
            </div>
            <div className="justify-content-end row mt-3">
              {saveButtonRenderCondition && (
                <div className="col-3">
                  <Button
                    form={formName}
                    type="submit"
                    color="primary"
                    className="w-100 btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save {entityName}
                  </Button>
                </div>
              )}
              {saveButtonRenderCondition && onCancel && (
                <div className="col-3">
                  <Button
                    type="button"
                    color="primary"
                    onClick={onCancel}
                    className="w-100 btn-secondary"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
          </Panel>
        )}
      </div>
    </Tabs>
  );
};
