import React, { useState } from 'react';
import { BsXDiamondFill, BsXDiamond } from 'react-icons/bs';
import {
  WarehouseLocationDto,
  WarehouseZoneDto,
} from '../../../models/data.models';
import { getWarehouseLocationsFx } from '../../warehouseLocations/warehouseLocations.store';
import { getWarehouseZonesFx } from '../warehouseInspector.store';

export const ZoneView = ({ zoneDto }: { zoneDto: WarehouseZoneDto }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [nestedZones, setNestedZones] = useState<WarehouseZoneDto[]>([]);
  const [nestedLocations, setNesteLocations] = useState<WarehouseLocationDto[]>(
    [],
  );

  const getonNestedZonesData = async (parentZoneId) => {
    if (!isOpen) {
      try {
        const zones = getWarehouseZonesFx({
          filter: `parentZoneId:${parentZoneId}`,
        });
        const locations = getWarehouseLocationsFx({
          filter: `parentZoneId:${parentZoneId}`,
        });
        const result = await Promise.all([zones, locations]);
        setNestedZones(result[0].items);
        setNesteLocations(result[1].items);
      } catch {
        setNestedZones([]);
        setNesteLocations([]);
      } finally {
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
  };

  const spreadIcon =
    zoneDto?.isHasNestedZones && (isOpen ? <BsXDiamond /> : <BsXDiamondFill />);

  return (
    <li className="d-flex">
      <span
        className="cursor-pointer"
        onClick={() => getonNestedZonesData(zoneDto.warehouseZoneId)}
      >
        {spreadIcon}
      </span>
      <p>{zoneDto.name}</p>
      <ul className="mt-5 pl-2">
        {isOpen && (
          <>
            {nestedZones?.map((el) => (
              <ZoneView zoneDto={el} />
            ))}
            {nestedLocations.map((location) => (
              <li>{location.code}</li>
            ))}
          </>
        )}
      </ul>
    </li>
  );
};
