import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { CarrierEdit } from './carrier-edit.component';

export type CarrierDialogProperties = {
  contactId: number;
  title: string;
  disableDots?: boolean;
};

export class CarrierDialog extends Dialog<CarrierDialogProperties> {
  constructor(props: CarrierDialogProperties) {
    super(props);
    this.onCarrierSaved = this.onCarrierSaved.bind(this);
  }

  onCarrierSaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId, disableDots } = this.props as CarrierDialogProperties;
    return (
      <CarrierEdit
        contactId={contactId}
        onCarrierCreated={this.onCarrierSaved}
        onCarrierUpdated={this.onCarrierSaved}
        onCancel={this.close.bind(this)}
        disableDots={disableDots}
      />
    );
  }
}
