import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  CountryDto,
  CityDto,
  StateDto,
  CustomFieldEntityType,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
} from '../../../models/data.models';
import { createCityFx, getCityFx, updateCityFx } from '../cities.store';
import { CityForm } from './city.form';
import { Panel } from '../../common/components/panel/panel.component';
import { CityDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_CITY_LINK_KEY } from '../cities.service';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { UPDATE_COUNTRY_LINK_KEY } from '../../countries/countries.service';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { getCustomFieldsFx } from '../../customFields/customFields.store';

export type CityEditProps = {
  cityId?: number;
  onCityCreated?: (city: CityDto) => void;
  onCityUpdated?: (city: CityDto) => void;
  onCancel?: () => void;
};

const initialCity: CityDto = {
  cityName: CityDefaultValues.cityName,
  stateCode: CityDefaultValues.stateCode,
  stateName: CityDefaultValues.stateName,
  countryCode: CityDefaultValues.countryCode,
  countryName: CityDefaultValues.countryName,
  latitude: CityDefaultValues.latitude,
  longitude: CityDefaultValues.longitude,
  customValues: {},
  links: [],
  organizationId: 0,
};

let citySchema = Yup.object().shape({
  cityName: Yup.string().required("Can't be blank").nullable(true),
  stateCode: Yup.string()
    .required("Can't be blank")
    .max(3, 'Max length is 3')
    .nullable(true),
  countryCode: Yup.string().required("Can't be blank").nullable(true),
});

export const CityEdit = ({
  cityId,
  onCityCreated = () => {},
  onCityUpdated = () => {},
  onCancel = () => {},
}: CityEditProps) => {
  const isCreateMode = !cityId || cityId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [city, setCity] = useState<CityDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [initialValues, setInitialValues] = useState<CityDto | null>(
    initialCity,
  );
  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    const fetchCity = async () => {
      let loadedCustomFields: CustomFieldDto[] = [];
      try {
        const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
          filter: `customFieldEntityType: ${CustomFieldEntityType.City} AND isInactive: false`,
        });
        if (
          fields.items.find(
            (i) =>
              i.internalName === 'isActive' && i.customFieldType === 'Boolean',
          )
        ) {
          initialCity.customValues['isActive'] = true;
        }
        loadedCustomFields = fields.items;
        citySchema = generateValidationSchemaWithCustomFields(
          loadedCustomFields,
          citySchema,
        );
        setCustomFields(loadedCustomFields);
      } catch {}

      if (isCreateMode) {
        setIsLoading(false);
      } else if (cityId) {
        getCityFx({ cityId }).then(
          (cityData: CityDto) => {
            setCity(cityData);
            setInitialValues(cityData);
            setIsLoading(false);
          },
          () => {},
        );
      }
    };
    fetchCity();
  }, [cityId]);

  const onSubmit = (data: CityDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createCityFx(data)
        .then(
          (result) => {
            onCityCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateCityFx(data)
        .then(
          (result) => {
            onCityUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <CityForm
      id={'city-form'}
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={ref}
      validationSchema={citySchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <span>
                <Tab data-cy={'general'}>General</Tab>
                <Tab data-cy={'additional'}>Additional</Tab>
              </span>
            </TabList>
            <TabPanel forceRender={true}>
              <Panel className="m-3">
                <div className="row">
                  <div className="col-4">
                    <CityForm.Name />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <CityForm.CountryCode
                      id={'countryCode'}
                      header={'Country'}
                      required={true}
                      defaultValue={
                        city && city.countryName && city.countryCode
                          ? {
                              countryCode: city.countryCode,
                              name: city.countryName,
                            }
                          : ''
                      }
                      onChange={(country?: CountryDto) => {
                        setCity((oldCity) => {
                          if (!oldCity) {
                            oldCity = initialCity;
                          }
                          if (
                            oldCity.countryCode !== country?.countryCode &&
                            oldCity.countryName !== country?.name
                          ) {
                            oldCity.countryCode = country?.countryCode;
                            oldCity.countryName = country?.name;
                            oldCity.stateCode = null;
                            oldCity.stateName = '';
                          }
                          return { ...oldCity };
                        });
                      }}
                      nameId={'countryName'}
                    />{' '}
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <CityForm.StateCode
                      id={'stateCode'}
                      header={'State'}
                      required={true}
                      defaultValue={
                        city && city.stateName && city.stateCode
                          ? {
                              stateCode: city.stateCode,
                              name: city.stateName,
                            }
                          : ''
                      }
                      onChange={(state?: StateDto) => {
                        setCity((oldCity) => {
                          if (!oldCity) {
                            oldCity = initialCity;
                          }
                          oldCity.stateCode = state?.stateCode;
                          oldCity.stateName = state?.name;
                          return { ...oldCity };
                        });
                      }}
                      nameId={'stateName'}
                      selectedFilter={`countryCode: ${city?.countryCode}`}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <CityForm.Latitude />{' '}
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <CityForm.Longitude />{' '}
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel forceRender={isCreateMode ? false : true}>
              <CityForm.CustomValues
                customFields={customFields}
                entityType={CustomFieldEntityType.City}
                defaultValue={isCreateMode ? {} : city?.customValues}
                onChange={(result) => {
                  setCity((cityDto) => {
                    cityDto.customValues = result;
                    return { ...cityDto };
                  });
                }}
                saveButtonRenderCondition={
                  userHas(UPDATE_COUNTRY_LINK_KEY, city?.links) || isCreateMode
                }
                isSending={isSending}
                formName={'city-form'}
                entityName={'City'}
                onCancel={onCancel}
                context={context}
              />
            </TabPanel>
            <div className="d-flex justify-content-end row">
              {(userHas(UPDATE_CITY_LINK_KEY, city?.links) || isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'city-form'}
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save City
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Tabs>
        )}
      </FormContext.Consumer>
    </CityForm>
  );
};
