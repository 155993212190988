import React, { useMemo, useCallback } from 'react';
import { useStore } from 'effector-react';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, Link } from 'react-router-dom';
import { ActionItem } from '../../common/components/action-item/action-item.component';
import { INTERNAL_PATH } from '../../../app.router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { WORKFLOWS_LIST_PATH } from '../workflows.route';
import { GET_WORKFLOWS_LINK_KEY } from '../workflows.service';
import { navigationStore } from '../../common/nav.store';
import classNames from 'classnames';

export const WorkflowMenu = ({ className }) => {
  const { currentOrganization } = useStore(organizationsStore);
  const navStore = useStore(navigationStore);

  const linkPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + WORKFLOWS_LIST_PATH, {
        organizationId: currentOrganization?.organizationId,
      }),
    [currentOrganization],
  );

  const renderLink = useCallback(
    ({ available }) => {
      if (!available) return null;

      const linkClass = classNames('nav-link', 'nav-icon-size-medium', {
        active: navStore.path[navStore.path?.length - 1] === 'Workflows',
      });

      return (
        <li className={className}>
          <Link
            to={linkPath}
            className={linkClass}
            data-testid="link-workflows"
          >
            <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faGripHorizontal} />
            </div>
            <div className="nav-link-text">Workflows</div>
          </Link>
        </li>
      );
    },
    [className, linkPath, navStore],
  );

  return (
    <ActionItem
      context={currentOrganization}
      actionKey={GET_WORKFLOWS_LINK_KEY}
      render={renderLink}
    />
  );
};
