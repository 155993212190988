import React from 'react';
import { LinkDto, LinkResourceBaseDto } from '../../../../models/data.models';
export type ActionItemParams = {
  context?: LinkResourceBaseDto | null;
  actionKey: string;
  render: (params: { available: boolean; link?: LinkDto | null }) => any;
};

export const ActionItem = ({
  context,
  actionKey,
  render,
}: ActionItemParams) => {
  const link = context && context.links?.find((link) => link.rel === actionKey);
  return render({ available: !!link, link });
};
