import { createEvent, createStore, Store } from 'effector';
import { StateDto } from '../../../models/data.models';

type StateReactSelectStore = {
  clearLoadOptionsCache: boolean;
  lastEditedState: StateDto;
};

export const clearStatesLoadOptionsCache = createEvent();
export const updateLastEditedState = createEvent<StateDto>();

const defaultState: StateReactSelectStore = {
  clearLoadOptionsCache: false,
  lastEditedState: null,
};

export const stateReactSelectStore: Store<StateReactSelectStore> = createStore(
  defaultState,
)
  .on(clearStatesLoadOptionsCache, (state, payload) => {
    return { ...state, clearLoadOptionsCache: !state.clearLoadOptionsCache };
  })
  .on(updateLastEditedState, (state, payload) => {
    return { ...state, lastEditedState: payload };
  });
