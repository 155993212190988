import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CommodityStatusesListScreen } from './screens/commodityStatuses-list.screen';
import { CommodityStatusScreen } from './screens/commodityStatus.screen';

export const COMMODITYSTATUSES_LIST_PATH = '/commodity-statuses';
export const COMMODITYSTATUS_EDIT_PATH = '/commodity-statuses/:statusId';
export const COMMODITYSTATUS_CREATE_PATH = '/commodity-statuses/create';

export const CommodityStatusesRoute = () => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CommodityStatusesListScreen
              pageTitle="Commodity Statuses"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <CommodityStatusScreen
              pageTitle="Add New Commodity Status"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':statusId'}
        element={
          <PrivateRoute>
            <CommodityStatusScreen
              pageTitle="Update Commodity Status"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
