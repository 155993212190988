import {
  AddressType,
  ContactAddressDto,
  ContactDto,
  ContactType,
  OrderEntityDto,
} from '../../../../models/data.models';
import { OrderForm } from '../order.form';
import React from 'react';
import { FormContext } from '../../../common/components/form/form.component';

export type ShipperFormProps = {
  orderPickupDto: OrderEntityDto | null;
  index: number;
  allowDelete: boolean;
  onShipperCreated?: (orderPickupDto: OrderEntityDto) => void;
  onShipperUpdated?: (orderPickupDto: OrderEntityDto) => void;
  onShipperLoaded?: (orderPickupDto: OrderEntityDto) => void;
  onShipperDeleted?: () => void;
  onShipperDateChange: (date?: any) => void;
  onShipperNoteChange: (note?: string) => void;
  setShipperContact: (
    contactId: number,
    name: string,
    contactType: ContactType,
  ) => void;
  setShipperFilter: (filter: string) => void;
  setShipperContactAddress: (
    contactAddressId: number,
    addressLine: string,
  ) => void;
  shipperFilter: string;
  shipperContact: { contactId: number; name: string; contactType: ContactType };
  shipperContactAddress: { contactAddressId: string; addressLine: string };
};
export const ShipperForm = ({
  orderPickupDto,
  index,
  onShipperDateChange,
  setShipperContact,
  setShipperFilter,
  setShipperContactAddress,
  shipperFilter,
  shipperContact,
  shipperContactAddress,
  allowDelete = false,
  onShipperDeleted,
  onShipperNoteChange,
}: ShipperFormProps) => {
  const isDisabledShipperContact = (): boolean => {
    if (shipperContact?.contactId == null || isNaN(shipperContact?.contactId)) {
      return true;
    }
    return false;
  };
  return (
    <div className="row">
      <div className="col-3">
        <OrderForm.ShipperCompany
          id={`orderPickups[${index}].contactId`}
          header={`Shipper ${index + 1}`}
          contactTypes={[
            ContactType.Customer,
            ContactType.Vendor,
            ContactType.ForwardingAgent,
            ContactType.Contact,
          ]}
          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
          required={false}
          defaultValue={
            shipperContact &&
            shipperContact.contactId != null &&
            shipperContact.name != null &&
            shipperContact.contactType != null
              ? {
                  contactId: shipperContact.contactId,
                  name: shipperContact.name,
                  contactType: shipperContact.contactType,
                }
              : ''
          }
          onChange={(data?: ContactDto, context?: any) => {
            setShipperContact(data?.contactId, data?.name, data?.contactType);
            setShipperFilter(
              data?.contactId ? `contactId:${data?.contactId}` : ' ',
            );
            setShipperContactAddress(null, null);

            context?.setFieldValue(
              `orderPickups[${index}].shipperAddressId`,
              null,
            );

            if (data === null) {
              orderPickupDto.customValues['pickDate'] = null;
              orderPickupDto.customValues['shippingNotes'] = '';
              context?.setFieldValue(`orderPickups[${index}].pickDate`, null);
              context?.setFieldValue(
                `orderPickups[${index}].shippingNotes`,
                '',
              );
            }
          }}
          nameId={`orderPickups[${index}].shipperContactName`}
        />
      </div>
      <div className="col-3">
        <OrderForm.ShipperLocation
          selectedFilter={shipperFilter}
          id={`orderPickups[${index}].shipperAddressId`}
          header={'Location'}
          required={false}
          disabled={isDisabledShipperContact()}
          defaultValue={
            shipperContactAddress &&
            shipperContactAddress.contactAddressId != null &&
            shipperContactAddress.addressLine != null
              ? {
                  contactAddressId: shipperContactAddress.contactAddressId,
                  addressLine: shipperContactAddress.addressLine,
                }
              : ''
          }
          onChange={(data?: ContactAddressDto) => {
            setShipperContactAddress(data?.contactAddressId, data?.addressLine);
          }}
          nameId={`orderPickups[${index}].shipperAddressName`}
          contactId={shipperContact.contactId}
          contactType={shipperContact.contactType}
          addressType={AddressType.Other}
        />
      </div>
      <div className="col-3">
        <OrderForm.ShipperDate
          defaultValue={orderPickupDto?.customValues['pickDate']}
          onChange={onShipperDateChange}
          name={`orderPickups[${index}].pickDate`}
          disabled={isDisabledShipperContact()}
        />
      </div>
      {allowDelete ? (
        <div className="col-3 d-flex">
          <div className={'w-100 mw-100 pr-3'}>
            <FormContext.Consumer>
              {(context) => (
                <OrderForm.ShipperNotes
                  name={`orderPickups[${index}].shippingNotes`}
                  disabled={isDisabledShipperContact()}
                  onChange={onShipperNoteChange}
                  defaultValue={orderPickupDto?.customValues['shippingNotes']}
                />
              )}
            </FormContext.Consumer>
          </div>
          <div className={'d-flex align-items-center pt-3'}>
            <div
              data-cy={`delete-shipper[${index}]`}
              onClick={(event) => {
                onShipperDeleted();
              }}
              color="primary"
              className="btn-close icon-close"
            />
          </div>
        </div>
      ) : (
        <div className="col-3 d-flex">
          <div className={'w-100'}>
            <FormContext.Consumer>
              {(context) => (
                <OrderForm.ShipperNotes
                  name={`orderPickups[${index}].shippingNotes`}
                  disabled={isDisabledShipperContact()}
                  onChange={onShipperNoteChange}
                  defaultValue={orderPickupDto?.customValues['shippingNotes']}
                />
              )}
            </FormContext.Consumer>
          </div>
        </div>
      )}
    </div>
  );
};
