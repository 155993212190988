import { Dialog } from '../../../common/components/dialog/dialog.component';
import { AirConsolidationWizard } from './airConsolidationWizard.component';

export type AirConsolidationWizardDialogProperties = {
  title: string;
  className?: string;
  onAirOrderCreated?: (orderId: number) => void;
  closeDialog?: () => void;
};

export class AirConsolidationWizardDialog extends Dialog<AirConsolidationWizardDialogProperties> {
  constructor(props: AirConsolidationWizardDialogProperties) {
    super(props);
    this.closeDialog = this.closeDialog?.bind(this);
    this.onAirOrderCreated = props.onAirOrderCreated?.bind(this);
  }

  onAirOrderCreated = () => {};

  closeDialog = () => {
    this.close();
  };

  renderContent(): any {
    return (
      <AirConsolidationWizard
        onAirOrderCreated={this.onAirOrderCreated}
        closeDialog={this.closeDialog}
      />
    );
  }
}
