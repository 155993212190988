import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { CITY_CREATE_PATH, CITIES_LIST_PATH } from '../cities.route';
import { CityEdit } from '../components/city-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_CITY_LINK_KEY } from '../cities.service';
import { userHas } from '../../auth/auth.store';

export type CityScreenProps = { pageTitle: string; group: string };

export const CityScreen = ({ pageTitle, group }: CityScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, cityId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + CITY_CREATE_PATH, {
    organizationId,
  });

  const onCityUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CITIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'city-created',
      type: 'success',
      autoHide: true,
      message: 'City successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCityCreated = () => {
    const path = generatePath(INTERNAL_PATH + CITIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'city-updated',
      type: 'success',
      autoHide: true,
      message: 'City successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CITIES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'City'}
      createPath={userHas(CREATE_CITY_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CityEdit
        cityId={cityId}
        onCityCreated={onCityCreated}
        onCityUpdated={onCityUpdated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
