import React from 'react';
import { OrganizationForm } from './organizations.form';
import { Button } from '../../common/components/button/button.component';
import { OrganizationDto } from '../../../models/data.models';
import * as Yup from 'yup';

export type CreateOrganizationParam = {
  initialValues?: OrganizationDto;
  onSubmit: (data: OrganizationDto) => void;
};

const initialState: OrganizationDto = {
  created: new Date(),
  createdBy: '',
  lastModified: new Date(),
  lastModifiedBy: '',
  links: [],
  organizationId: 0,
  addressLine: '',
  addressLine2: '',
  cityName: '',
  companyName: '',
  countryCode: '',
  faxNumber: '',
  phoneNumber: '',
  postalCode: '',
  stateCode: '',
};

const organizationSchema = Yup.object().shape({
  companyName: Yup.string().required("Can't be blank").nullable(true),
});

export const CreateOrganization = ({
  initialValues = initialState,
  onSubmit = () => {},
}: CreateOrganizationParam) => {
  return (
    <OrganizationForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      id={'organization-create-form'}
      validationSchema={organizationSchema}
    >
      <div className="mb-5">
        <OrganizationForm.CompanyName />
      </div>
      <OrganizationForm.AddressLine />
      <OrganizationForm.AddressLine2 />
      <div className="row mb-5">
        <div className="col-6">
          <OrganizationForm.City />
        </div>
        <div className="col-6">
          <OrganizationForm.StateCode />
        </div>
        <div className="col-6">
          <OrganizationForm.CountryCode />
        </div>
        <div className="col-6">
          <OrganizationForm.PostalCode />
        </div>
      </div>

      <div className="mb-5">
        <OrganizationForm.PhoneNumber />
        <OrganizationForm.FaxNumber />
      </div>
      <Button
        name="create-organization"
        type="submit"
        color="primary"
        className="btn-block"
      >
        Create Organization
      </Button>
    </OrganizationForm>
  );
};
