import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AccountingItemForm } from './accountingItem.form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  AccountingItemDto,
  ApplyBy,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  CustomFieldType,
  ItemType,
} from '../../../models/data.models';
import {
  createAccountingItemFx,
  getAccountingItemFx,
  updateAccountingItemFx,
} from '../accountingItems.store';
import { Panel } from '../../common/components/panel/panel.component';
import {
  fixRateDataForSubmit,
  generateValidationSchemaWithCustomFields,
  getEnumValues,
  validateNumberInput,
} from '../../../utils/helper.utils';
import { AccountingItemDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_ACCOUNTINGITEM_LINK_KEY } from '../accountingItems.service';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { FormContext } from '../../common/components/form/form.component';
import { TariffEdit } from '../../tariffs/components/tariff-edit.component';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';

export type AccountingItemEditProps = {
  accountingItemId?: number | null;
  onAccountingItemCreated?: (accountingItem: AccountingItemDto) => void;
  onAccountingItemUpdated?: (accountingItem: AccountingItemDto) => void;
  onAccountingItemLoaded?: (accountingItem: AccountingItemDto) => void;
  onCancel?: () => void;
};

const initialState: AccountingItemDto = {
  accountingItemId: 0,
  organizationId: null,
  accountId: AccountingItemDefaultValues.accountId,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: AccountingItemDefaultValues.description,
  isInactive: AccountingItemDefaultValues.isInactive,
  itemCode: AccountingItemDefaultValues.itemCode,
  itemType: AccountingItemDefaultValues.itemType,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  price: AccountingItemDefaultValues.price,
  salesTaxId: AccountingItemDefaultValues.salesTaxId,
  tariff: AccountingItemDefaultValues.tariff,
  accountName: AccountingItemDefaultValues.accountName,
  salesTaxCode: AccountingItemDefaultValues.salesTaxCode,
  customValues: {},
  links: [],
};

let accountingItemsSchema = Yup.object().shape({
  itemType: Yup.string().required("Can't be blank").nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  accountId: Yup.string().required("Can't be blank").nullable(true),
  price: Yup.string()
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        !value
      );
    })
    .test('length', 'Max number length is 17', (value) => {
      return value?.replace('-', '').length <= 17 || !value;
    })

    .nullable(true),
});

export const AccountingItemEdit = ({
  accountingItemId,
  onAccountingItemLoaded = () => {},
  onAccountingItemCreated = () => {},
  onAccountingItemUpdated = () => {},
  onCancel = () => {},
}: AccountingItemEditProps) => {
  const isCreateMode = !accountingItemId || accountingItemId == 0;
  const [isLoading, setIsLoading] = useState(true);
  const [
    initialAccountingItem,
    setInitialAccountingItem,
  ] = useState<AccountingItemDto | null>(initialState);

  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    const fetchAccountingItem = async () => {
      let loadedCustomFields: CustomFieldDto[] = [];
      try {
        const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
          filter: `customFieldEntityType: ${CustomFieldEntityType.AccountingItem} AND isInactive: false`,
        });
        loadedCustomFields = fields.items;
        accountingItemsSchema = generateValidationSchemaWithCustomFields(
          loadedCustomFields,
          accountingItemsSchema,
        );
        setCustomFields(loadedCustomFields);
      } catch {
        addMessage({
          message: `Can't load Custom Fields.`,
          type: 'danger',
          id: v4(),
        });
      }

      if (isCreateMode) {
        setIsLoading(false);
      } else if (accountingItemId) {
        try {
          const accountingItemDto: AccountingItemDto = await getAccountingItemFx(
            {
              accountingItemId,
            },
          );
          const booleanFields = loadedCustomFields.filter(
            (field) => field.customFieldType === CustomFieldType.Boolean,
          );

          booleanFields.forEach((el) => {
            const val = accountingItemDto.customValues[el.internalName];
            accountingItemDto.customValues[el.internalName] =
              val === 'true' ? true : false;
          });

          setInitialAccountingItem(accountingItemDto);
          onAccountingItemLoaded(accountingItemDto);
        } catch {
          addMessage({
            message: `Can't update Accounting Item ${accountingItemId}.`,
            type: 'danger',
            id: v4(),
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        throw new Error('AccountingItem keys were not provided');
      }
    };

    fetchAccountingItem();
  }, [accountingItemId]);

  const onSubmit = async (data: AccountingItemDto) => {
    fixRateDataForSubmit(data);
    if (isCreateMode) {
      try {
        const result = await createAccountingItemFx(data);
        onAccountingItemCreated(result);
      } catch {
        addMessage({
          message: `Can't create Accounting Item.`,
          type: 'danger',
          id: v4(),
        });
      }
    } else {
      try {
        const result = await updateAccountingItemFx(data);
        onAccountingItemUpdated(result);
      } catch {
        addMessage({
          message: `Can't update Accounting Item ${data.accountingItemId}.`,
          type: 'danger',
          id: v4(),
        });
      }
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <AccountingItemForm
      initialValues={initialAccountingItem}
      onSubmit={onSubmit}
      innerRef={ref}
      id={'accounting-item-form'}
      validationSchema={accountingItemsSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <Tab>Definition</Tab>
              <Tab>Automatic Creation</Tab>
              <Tab>Notes</Tab>
              <Tab>Additional</Tab>
            </TabList>
            <TabPanel>
              <Panel className="m-3">
                <div className="row">
                  <div className="col-4">
                    <AccountingItemForm.ItemType
                      placeholder={'Select Item Type'}
                      id={'itemType'}
                      header={'Item Type'}
                      name={'itemType'}
                      disabled={false}
                      required={true}
                      multiple={false}
                      options={getEnumValues(ItemType)}
                      defaultValue={
                        context.values?.itemType
                          ? {
                              label: ItemType[context.values?.itemType],
                              value: context.values?.itemType,
                            }
                          : null
                      }
                    />
                  </div>
                  <div className="col-4">
                    <AccountingItemForm.SalesTaxId
                      id={'salesTaxId'}
                      header={'Tax Code'}
                      selectedFilter={` `}
                      required={false}
                      defaultValue={
                        context.values?.salesTaxId != null &&
                        context.values?.salesTaxCode != null
                          ? {
                              salesTaxId: context.values?.salesTaxId,
                              taxCode: context.values?.salesTaxCode,
                            }
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <AccountingItemForm.Description />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <AccountingItemForm.ItemCode />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <AccountingItemForm.AccountId
                      id={'accountId'}
                      header={'Account'}
                      placeholder={'Select Account'}
                      required={true}
                      defaultValue={
                        context.values?.accountId != null &&
                        context.values?.accountName != null
                          ? {
                              accountId: context.values?.accountId,
                              accountName: context.values?.accountName,
                            }
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <AccountingItemForm.Price
                      required={false}
                      onKeyDown={validateNumberInput}
                    />
                  </div>
                </div>

                <TariffEdit />

                <div className="row">
                  <div className="col-4">
                    <AccountingItemForm.IsInactive
                      defaultValue={context.values?.isInactive ?? false}
                    />
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel>
              <div></div>
            </TabPanel>
            <TabPanel>
              <div></div>
            </TabPanel>
            <TabPanel forceRender={isCreateMode ? false : true}>
              <Panel className="m-3">
                <CustomValuesInput
                  context={context}
                  customFields={customFields}
                  defaultValue={context.values?.customValues}
                  entityName={'AccountingItem'}
                  onCancel={onCancel}
                  entityType={CustomFieldEntityType.AccountingItem}
                />
              </Panel>
            </TabPanel>
            <div className="justify-content-end row">
              {(userHas(
                UPDATE_ACCOUNTINGITEM_LINK_KEY,
                initialAccountingItem?.links,
              ) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'accounting-item-form'}
                    name="save-accountingItem"
                    type="submit"
                    color="primary"
                    className="btn-block btn-md"
                    disabled={context.isSubmitting}
                    isSending={context.isSubmitting}
                  >
                    Save Item
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={context.isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </Tabs>
        )}
      </FormContext.Consumer>
    </AccountingItemForm>
  );
};
