import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import {
  CargoMovementStatus,
  CommodityDto,
  OrderDto,
  OrderTypes,
  PickingActivityStatuses,
  WarehouseZoneDto,
  WarehouseZoneDtoPagedResult,
} from '../../../models/data.models';
import { OrderDefaultValues } from '../../common/DefaultValues';
import { getOrder } from '../cargoMovements.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { ActivityHeader } from '../../common/components/header/activityHeader.component';
import { CargoMovementForm } from '../components/cargoMovement.form';
import { Button } from '../../common/components/button/button.component';
import { getWarehouseZonesFx } from '../../warehouseZones/warehouseZones.store';
import { CustomerInformation } from '../../customers/components/common/customerInformation.component';
import { PickingActivity } from './pickingActivity.component';
import {
  ActivityCommodityDto,
  CargoMovementDto,
} from '../../../models/custom.models';
import { PackageLocation } from '../../common/components/location/packageLocation.component';
import { pickingCommoditiyTableStore } from '../../commodities/commodities.store';
import { ActivityCommoditiesTable } from '../../common/components/table/activityCommoditiesTable.component';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { HiX } from 'react-icons/hi';
import { FiEdit2 } from 'react-icons/fi';
import { CARGO_MOVEMENT_EDIT_PATH } from '../cargoMovement.route';
import { INTERNAL_PATH } from '../../../app.router';
import { TotalStatuses } from '../../common/components/totalStatuses/totalStatuses';
import { showDialog } from '../../common/dialog.store';
import { ReportPackageDialog } from './reportPackage.dialog';
import { FaCheck } from 'react-icons/fa';
import { ParcelShipmentDialog } from '../../parcelShipments/components/parcelShipment.dialog';
import { triggerWorkflowByConfig } from '../../workflowTriggers/workflowTriggerHook';

export type PickingActivityFlowProps = {
  orderId: number;
  onOrderUpdated?: (order: CargoMovementDto) => void;
  onCancel?: () => void;
  isDialog?: boolean;
};

const getInitialState = () => {
  const initialState: CargoMovementDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: [],
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntities: [],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    links: [],
    customValues: {
      endMovement: '',
      startMovement: '',
      movementStatus: CargoMovementStatus.Created,
    },
    orderCommoditiesWithStatus: [],
  };
  return initialState;
};

export const PickingActivityFlow = ({
  orderId,
  onCancel = () => {},
  onOrderUpdated = () => {},
  isDialog = false,
}: PickingActivityFlowProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [warehouseZones, setWareHouseZones] = useState<WarehouseZoneDto[]>([]);
  const [initialValues, setInitialValues] = useState<CargoMovementDto | null>(
    getInitialState(),
  );
  const [
    chosenCommodity,
    setChosenCommodity,
  ] = useState<ActivityCommodityDto | null>(null);
  const [parcel, setParcel] = useState<OrderDto | null>(null);
  const [commodityStatus, setCommodityStatus] = useState<string | null>(null);
  const [parcelCommodities, setParcelCommodities] = useState<CommodityDto[]>(
    [],
  );
  const [activityCommodities, setAtivityCommodities] = useState<
    ActivityCommodityDto[]
  >([]);
  const [parcelActiveCommodityIndex, setParcelActiveCommodityIndex] = useState(
    1,
  );
  const [allPackagesProcessed, setAllPackagesProcessed] = useState<boolean>(
    false,
  );

  const { commodityColumns: columns } = useStore(pickingCommoditiyTableStore);

  useEffect(() => {
    if (activityCommodities) {
      const findCommodity = (condition) => activityCommodities.find(condition);

      const consolidatedCommodity = findCommodity(
        (item) =>
          item.parcelId === chosenCommodity?.parcelId &&
          item.activityStatus === PickingActivityStatuses.AwaitingPicking,
      );

      setChosenCommodity(
        consolidatedCommodity ||
          findCommodity(
            (item) =>
              item.activityStatus === PickingActivityStatuses.AwaitingPicking,
          ),
      );
    }
  }, [activityCommodities]);

  useEffect(() => {
    if (orderId) {
      getOrder({ orderId })
        .then((orderDto) => {
          setInitialValues(orderDto);
        })
        .catch((error) => {
          addMessage({
            message: error,
            type: 'danger',
            id: v4(),
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [orderId]);

  useEffect(() => {
    getWarehouseZonesFx({
      limit: 1000,
      offset: 0,
    }).then((result: WarehouseZoneDtoPagedResult) => {
      setWareHouseZones(result?.items);
    });
  }, []);

  useEffect(() => {
    if (chosenCommodity) {
      const parcelShipment = chosenCommodity?.shipments.find(
        (x) => x?.orderType === OrderTypes.ParcelShipment,
      );
      if (parcelShipment && parcelShipment.orderId) {
        getOrder({ orderId: parcelShipment?.orderId })
          .then((result) => {
            setParcel(result);
            setParcelCommodities(result?.commodities);
          })
          .catch((error) => {
            addMessage({
              message: error,
              type: 'danger',
              id: v4(),
            });
          });
      } else {
        setParcel(null);
        setParcelCommodities([]);
      }
    }
  }, [chosenCommodity]);

  useEffect(() => {
    const commodities: ActivityCommodityDto[] = initialValues.commodities;
    const statusCommodity = commodities?.find(
      (x) => x?.commodityId === chosenCommodity?.commodityId,
    );
    setCommodityStatus(statusCommodity?.activityStatus);
  }, [initialValues, chosenCommodity]);

  useEffect(() => {
    const commodities: ActivityCommodityDto[] = initialValues.commodities;
    initialValues?.orderCommoditiesWithStatus?.forEach((x) => {
      commodities?.forEach((y) => {
        if (x.commodityId === y.commodityId) {
          y.activityStatus =
            x?.customValues['activityStatus'] || 'Awaiting Picking';
        }
      });
    });
    commodities.forEach((commodity) => {
      const parcel = commodity?.shipments?.find(
        (x) => x.orderType === OrderTypes.ParcelShipment,
      );
      commodity.parcelId = parcel?.orderId;
      commodity.parcelTrackingNumber = parcel?.trackingNumber;
    });
    setAtivityCommodities(
      commodities.sort((a, b) => {
        const parcelIdComparison = a.parcelId - b.parcelId;
        if (parcelIdComparison !== 0) {
          return parcelIdComparison;
        }
        const locationCodeA = a.warehouseLocation?.code || '';
        const locationCodeB = b.warehouseLocation?.code || '';
        return locationCodeA.localeCompare(locationCodeB);
      }),
    );
  }, [initialValues]);

  useEffect(() => {
    if (activityCommodities.length > 0) {
      if (
        !activityCommodities.some(
          (x) => x.activityStatus === PickingActivityStatuses.AwaitingPicking,
        )
      ) {
        setAllPackagesProcessed(true);
      }
    }
  }, [activityCommodities]);

  useEffect(() => {
    if (parcelCommodities && chosenCommodity) {
      const index = parcelCommodities.findIndex(
        (x) => x.commodityId === chosenCommodity.commodityId,
      );
      if (index !== -1) {
        setParcelActiveCommodityIndex(index);
      }
    }
  }, [parcelCommodities, chosenCommodity]);

  const setPickingStatus = async (status: PickingActivityStatuses) => {
    const workflowExecutionVariables = {
      orderId: initialValues.orderId,
      commodityId: chosenCommodity.commodityId,
      activityStatus: status,
    };

    try {
      await triggerWorkflowByConfig({
        configName: 'tms.modules.picking',
        workflowIdConfigKey: 'pickingWorkflowId',
        workflowVariables: workflowExecutionVariables,
      });

      const order = await getOrder({ orderId });
      setInitialValues(order);

      showSuccess(status);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showSuccess = (status: PickingActivityStatuses) => {
    addMessage({
      message: `Package status is set to ${status}`,
      type: 'success',
      id: v4(),
    });
  };

  const showError = (error: any) => {
    addMessage({
      message: error?.message || error,
      type: 'danger',
      id: v4(),
    });
  };

  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId } = useParams();

  const editMovement = () => {
    const edit = CARGO_MOVEMENT_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + edit, {
      organizationId,
      orderId,
    });
    navigate(path);
  };

  const actionButtons = [
    <Button color={'action-primary'} className="mr-4" onClick={editMovement}>
      <div className={'d-flex align-items-center'}>
        <FiEdit2 className={'mr-2'} size={16} />
        Edit
      </div>
    </Button>,
    <Button color={'secondary'} outline onClick={onCancel}>
      <div className={'d-flex align-items-center'}>
        <HiX size={16} className={'mr-2'} />
        Cancel
      </div>
    </Button>,
  ];

  const onReportPackage = () => {
    showDialog({
      dialog: ReportPackageDialog,
      props: {
        order: initialValues,
        bodyClassName: 'modal-md',
        className: 'modal-sm',
      },
    }).then((order: CargoMovementDto) => {
      if (order?.orderId) {
        getOrder({ orderId }).then((order) => setInitialValues(order));
      }
    });
  };

  const goToParcelDetails = async (commodity) => {
    const orderId = commodity?.shipments?.find(
      (x) => x.orderType === OrderTypes.ParcelShipment,
    )?.orderId;
    if (orderId) {
      await showDialog({
        dialog: ParcelShipmentDialog,
        props: {
          className: 'order-modal',
          title: 'Update Parcel Shipment',
          orderId: orderId,
        },
      });
    }
  };

  useEffect(() => {
    if (onCancel) setActionButtons([...actionButtons]);
  }, []);

  useEffect(() => () => clearActionButtons(), []);

  const selectNext = (commodityId: number) => {
    const commodity = activityCommodities.find(
      (x) => x.commodityId === commodityId,
    );
    if (
      commodity &&
      commodity?.activityStatus !== PickingActivityStatuses.Picked
    ) {
      setChosenCommodity(commodity);
    }
  };

  return (
    <div>
      <div className="mx-3">
        {isDialog ? (
          <div className="row d-flex justify-content-end p-3">
            {actionButtons.map((x) => {
              return x;
            })}
          </div>
        ) : null}
        <CargoMovementForm initialValues={initialValues}>
          <div className="w-100 mx-auto mb-4">
            <div className="w-100">
              <ActivityHeader
                orderNumber={initialValues?.orderNumber}
                type={'Picking'}
                assignedTo={initialValues?.employeeContactName}
                destination={
                  initialValues?.customValues['destinationLocationDescription']
                }
                showCartNumber={true}
              />
            </div>
          </div>
        </CargoMovementForm>
        <div className="row">
          <div className="col-md-6 col-12 mb-md-0 mb-4">
            {allPackagesProcessed ? (
              <div>
                <div className="box h-100">
                  <div className={'d-flex'}>
                    <FaCheck size={20} className={'mr-2 color-text-success'} />
                    <h3 className={'color-text-success mb-0 mt-1'}>
                      All packages picked
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              <PickingActivity
                commodity={chosenCommodity}
                warehouseZones={warehouseZones}
                commodityStatus={commodityStatus}
                setPickingStatus={setPickingStatus}
                isLoading={isLoading}
                parcelTrackingNumber={parcel?.trackingNumber?.toString()}
              />
            )}
          </div>
          <div className="col-md-6 col-12">
            <div className="box h-100">
              {!allPackagesProcessed && (
                <>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="">
                          <div className="text-label">Parcel information</div>
                        </div>
                        <div className="my-sm-0 my-3">
                          <div
                            className={
                              'text-h3-tracking-number rounded-pill py-2 px-4 color-bg-secondary'
                            }
                            style={{}}
                          >
                            {parcel?.trackingNumber}
                          </div>
                        </div>
                        <div className=" d-flex justify-content-end">
                          {parcel?.commodities?.length > 1 && (
                            <div className="text-label">
                              Total: {parcelCommodities?.length} packages
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {parcel?.billToContactId && (
                    <CustomerInformation contactId={parcel.billToContactId} />
                  )}
                  <div className="row mt-3">
                    <div className="col-12">
                      {parcel?.commodities?.length > 1 && (
                        <h3 className="text-label mb-5">Parcel packages</h3>
                      )}
                      {parcelCommodities
                        .filter((x, index) => {
                          return !allPackagesProcessed
                            ? index !== parcelActiveCommodityIndex
                            : true;
                        })
                        .map((commodity) => {
                          return (
                            <>
                              <div className="p-3 box shadow-none">
                                <PackageLocation
                                  commodity={commodity}
                                  warehouseZones={warehouseZones}
                                  commodityStatus={commodityStatus}
                                  statusCommodities={
                                    initialValues.orderCommoditiesWithStatus
                                  }
                                  showHeader={false}
                                  parcelTrackingNumber={parcel.trackingNumber}
                                  emphasisedPath={true}
                                  emphasisedTrackingNumber={true}
                                  pathTextSize={'md'}
                                />
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <TotalStatuses
          activityStatuses={activityCommodities.map(
            (commodity) => commodity.activityStatus,
          )}
          statusesType={PickingActivityStatuses}
        />
        <div className="mt-6">
          <ActivityCommoditiesTable
            commodities={activityCommodities}
            columns={columns}
            warehouseZones={warehouseZones}
            sortField={'-parcelId,-warehouseLocation.code'}
            onReportPackage={onReportPackage}
            selectNext={selectNext}
            goToParcelDetails={goToParcelDetails}
          />
        </div>
      </div>
    </div>
  );
};
