import React from 'react';
import Form from 'react-bootstrap/Form';

export type RadioGroupProps = {
  label: string;
  purposeObject?: object;
  onChange?: (value: string) => void;
};
export const RadioGroupComponent = ({
  label,
  purposeObject = {},
  onChange = () => {},
}: RadioGroupProps) => {
  return (
    <div>
      <div className="input-label">{label}</div>
      <div style={{ gap: '10px' }}>
        {Object.keys(purposeObject).map((key, index) => {
          const stringKey = key[0].toLowerCase() + key.slice(1);
          if (stringKey != 'value') {
            return (
              <Form.Check
                inline
                defaultChecked={
                  purposeObject['value']
                    ? stringKey === purposeObject['value']
                    : index == 0
                }
                key={stringKey}
                name={`customFields-purpose-checkbox`}
                type="radio"
                label={purposeObject[key]}
                onChange={() => onChange(stringKey)}
                data-testid="radio-button"
              />
            );
          }
        })}
      </div>
    </div>
  );
};
