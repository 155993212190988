import { useCallback, useState } from 'react';
import { Icon } from './icon-component';

export const PasswordInputComponent = ({
  field,
  disabled,
  className,
  placeholder,
  autoComplete,
  onChange,
}) => {
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState('eye-slash');

  const handleToggle = useCallback(() => {
    if (type === 'password') {
      setIcon('eye');
      setType('text');
    } else {
      setIcon('eye-slash');
      setType('password');
    }
  }, [type]);

  return (
    <div className="d-flex align-items-center">
      <input
        type={type}
        name={field.name}
        placeholder={placeholder}
        value={field.value}
        onChange={onChange}
        autoComplete={autoComplete ?? 'off'}
        disabled={disabled}
        className={className}
      />
      <span className={'password-input-eye'} onClick={handleToggle}>
        <Icon icon={icon} color={'#cccccc'} />
      </span>
    </div>
  );
};
