import {
  PortDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  PortDtoPagedResult,
  CreatePortCommandValues,
  UpdatePortCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPortRequest,
  getPortsListRequest,
  getPortRequest,
  deletePortRequest,
  updatePortRequest,
  importPortsRequest,
  exportPortsRequest,
  GetPortParams,
  updateAllPortsRequest,
} from './ports.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type PortsStoreState = {
  links: LinkDto[];
  portColumns: EntityFieldDto[];
  defaultPortColumns: EntityFieldDto[];
};

export const getPortsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Port',
  });
});

addEffectStatusHandling(getPortsColumnsFx);

export const getPortsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Port',
  });
});

addEffectStatusHandling(getPortsDefaultColumnsFx);

export const updatePortsColumns = createEvent<EntityFieldDto[]>();

export const getPortsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPortsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getPortsFx);

export const createPortFx = createEffect((portData: PortDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createPortCommand: CreatePortCommandValues = { ...portData };

  return createPortRequest(currentOrganization!, createPortCommand);
});

addEffectStatusHandling(createPortFx, {
  completeMessage: 'Port was created successfully',
  errorMessage: 'Port was not created',
});

export const getPortFx = createEffect((portParams: GetPortParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPortRequest(currentOrganization as LinkResourceBaseDto, portParams);
});

addEffectStatusHandling(getPortFx);

export const updatePortFx = createEffect((port: PortDto) => {
  const updatePortCommand: UpdatePortCommandValues = { ...port };
  return updatePortRequest(port, updatePortCommand);
});

addEffectStatusHandling(updatePortFx, {
  completeMessage: 'Port was updated successfully',
  errorMessage: 'Port was not updated',
});

export const deletePortFx = createEffect((port: PortDto) => {
  return deletePortRequest(port);
});

addEffectStatusHandling(deletePortFx, {
  completeMessage: 'Port was deleted successfully',
  errorMessage: 'Port was not deleted',
});

export const updateAllPortsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return updateAllPortsRequest(currentOrganization);
});

addEffectStatusHandling(updateAllPortsFx, {
  completeMessage: 'Ports were updated successfully',
  errorMessage: 'Ports were not updated',
});

export const exportPortsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportPortsRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportPortsFx, {
  completeMessage: 'Ports were exported successfully',
  errorMessage: 'Ports were not exported',
});

export const importPortsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importPortsRequest(listResource as LinkResourceBaseDto, params, file);
  },
);
addEffectStatusHandling(importPortsFx, {
  completeMessage: 'Ports were imported successfully',
  errorMessage: 'Ports were not imported',
  inFlightMessage: 'Importing ports...',
});

const defaultState: PortsStoreState = {
  links: [],
  portColumns: [],
  defaultPortColumns: [],
};

export const portStore: Store<PortsStoreState> = createStore(defaultState)
  .on(getPortsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      portColumns: payload.result.items,
      defaultPortColumns: payload.result.items,
    };
  })
  .on(getPortsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultPortColumns: payload.result.items,
    };
  })
  .on(updatePortsColumns, (state, payload) => {
    return { ...state, portColumns: payload };
  });
