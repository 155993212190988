import {
  WorkflowTriggerDto,
  LinkDto,
  LinkResourceBaseDto,
  ExecuteWorkflowCommandValues,
  GetWorkflowTriggersQuery,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { organizationsStore } from '../organization/organization.store';
import {
  executeWorkflowRequest,
  getWorkflowTriggersListRequest,
  getWorkflowTriggerRequest,
  GetWorkflowTriggerParams,
} from './workflowTriggers.service';

import { addEffectStatusHandling } from '../common/messages.store';

type WorkflowTriggersStoreState = {
  links: LinkDto[];
};

export const getWorkflowTriggersFx = createEffect(
  (params: GetWorkflowTriggersQuery = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getWorkflowTriggersListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getWorkflowTriggersFx);

export const executeWorkflowTriggerFx = createEffect(
  ({
    workflowTrigger,
    variables,
    options,
  }: {
    workflowTrigger: WorkflowTriggerDto;
    variables: { [key: string]: any };
    options?: { silent: boolean | null; notification?: boolean };
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createWorkflowTriggerCommand: ExecuteWorkflowCommandValues = {
      variables,
    };

    return executeWorkflowRequest(
      workflowTrigger!,
      createWorkflowTriggerCommand,
    );
  },
);

addEffectStatusHandling(executeWorkflowTriggerFx, {
  errorMessage: 'Workflow execution failed',
});

export const getWorkflowTriggerFx = createEffect(
  (workflowTriggerParams: GetWorkflowTriggerParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getWorkflowTriggerRequest(
      currentOrganization as LinkResourceBaseDto,
      workflowTriggerParams,
    );
  },
);

addEffectStatusHandling(getWorkflowTriggerFx);

const defaultState: WorkflowTriggersStoreState = {
  links: [],
};

export const workflowTriggerStore: Store<WorkflowTriggersStoreState> = createStore(
  defaultState,
);
