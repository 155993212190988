import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { FormContext } from '../form/form.component';
import { ErrorMessage, Field, FormikContext } from 'formik';
import { clearMessages } from '../../messages.store';

export type QuillInputProps = {
  onChange?: (data: any, context?: any) => void;
  name?: string;
  header?: string;
  required?: boolean;
  validate?: (data: any) => Promise<any>;
  error?: boolean;
  placeholder?: string;
  readonly?: boolean;
  defaultValue?: string;
  componentProps?: any;
};

export const QuillInput = ({
  onChange,
  name,
  header,
  required,
  placeholder = '',
  readonly,
  defaultValue = '',
  componentProps,
}: QuillInputProps) => {
  const formikContext = useContext(FormikContext);

  const [_name, setName] = useState(name);
  const [_header, setHeader] = useState('');
  const [_placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    if (componentProps && componentProps?.props) {
      setName(name ?? componentProps.name);
      setHeader(header ?? componentProps.props.label);
      setPlaceholder(placeholder ?? componentProps.props.placeholder);
    }
  }, [componentProps]);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      ['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const [stateValue, setStateValue] = useState(defaultValue);

  const onChangeInput = (option, contextDto?) => {
    if (typeof onChange === 'function') {
      onChange(option, contextDto);
    }
  };

  useEffect(() => setStateValue(defaultValue), [defaultValue]);

  return (
    <FormContext.Consumer>
      {(context) => (
        <>
          {!componentProps && (
            <label className="input-label" htmlFor={_name}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{_header}</span>
              </span>
            </label>
          )}
          <Field
            required={required}
            type="hidden"
            name={_name}
            value={stateValue}
          />
          <ReactQuill
            className={_name}
            style={{ height: '200px', marginBottom: '50px' }}
            onFocus={() => clearMessages()}
            onBlur={() => formikContext.setFieldTouched(_name, true)}
            readOnly={readonly}
            placeholder={_placeholder}
            value={stateValue}
            onChange={(value, delta, sources, editor) => {
              value = value === '<p><br></p>' ? '' : value;
              setStateValue(value);
              formikContext.setFieldValue(_name, value);
              onChangeInput(value);
            }}
            modules={modules}
          />
          {!componentProps && (
            <ErrorMessage
              name={_name}
              component="div"
              className="invalid-feedback"
            />
          )}
        </>
      )}
    </FormContext.Consumer>
  );
};
