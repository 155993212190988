import {
  PackageTypeDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  PackageTypeDtoPagedResult,
  CreatePackageTypeCommandValues,
  UpdatePackageTypeCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_PACKAGETYPES_LINK_KEY = 'get-packagetypes';
export const CREATE_PACKAGETYPE_LINK_KEY = 'create-packagetype';
export const GET_PACKAGETYPE_LINK_KEY = 'get-packagetype';
export const UPDATE_PACKAGETYPE_LINK_KEY = 'update-packagetype';
export const DELETE_PACKAGETYPE_LINK_KEY = 'delete-packagetype';
export const IMPORT_PACKAGETYPES_LINK_KEY = 'import-packagetypes';
export const EXPORT_PACKAGETYPES_LINK_KEY = 'export-packagetypes';
export const PACKAGETYPE_ENTITY_NAME = 'Package Type';

export interface GetPackageTypeParams {
  packageTypeId: number;
}

export interface ImportPackageTypesParams {
  organizationId: number;
}

export interface ExportPackageTypesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getPackageTypeRequest = async (
  resource: LinkResourceBaseDto | null,
  { packageTypeId }: GetPackageTypeParams,
): Promise<PackageTypeDto> => {
  if (resource && packageTypeId) {
    const getPackageTypeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGETYPE_LINK_KEY,
    );
    if (getPackageTypeLink) {
      const result = await execLink(getPackageTypeLink, { packageTypeId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getPackageTypesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PackageTypeDtoPagedResult> => {
  if (resource) {
    const getPackageTypesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGETYPES_LINK_KEY,
    );
    if (getPackageTypesListLink) {
      const result = await execLink(getPackageTypesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PACKAGETYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createPackageTypeRequest = async (
  resource: LinkResourceBaseDto,
  packageType: CreatePackageTypeCommandValues,
) => {
  const createPackageTypeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PACKAGETYPE_LINK_KEY,
  );
  if (createPackageTypeLink) {
    const result = await execLink(createPackageTypeLink, packageType);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updatePackageTypeRequest = async (
  resource: LinkResourceBaseDto,
  packageType: UpdatePackageTypeCommandValues,
) => {
  const updatePackageTypeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PACKAGETYPE_LINK_KEY,
  );
  if (updatePackageTypeLink) {
    const result = await execLink(updatePackageTypeLink, packageType);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deletePackageTypeRequest = async (packageType: PackageTypeDto) => {
  const deletePackageTypeLink = packageType.links?.find(
    (x: LinkDto) => x.rel === DELETE_PACKAGETYPE_LINK_KEY,
  );
  if (deletePackageTypeLink) {
    const result = await execLink(deletePackageTypeLink, packageType);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importPackageTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportPackageTypesParams,
  file: File,
) => {
  const importPackageTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_PACKAGETYPES_LINK_KEY,
  );
  if (importPackageTypesLink) {
    const result = await postFormData(importPackageTypesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_PACKAGETYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportPackageTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportPackageTypesParams,
) => {
  const exportPackageTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_PACKAGETYPES_LINK_KEY,
  );
  if (exportPackageTypesLink) {
    const result = await downloadLinkFile(exportPackageTypesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_PACKAGETYPES_LINK_KEY} is not allowed or missing link action`,
  );
};
