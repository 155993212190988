import {
  AccountingItemDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateAccountingItemCommandValues,
  UpdateAccountingItemCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAccountingItemRequest,
  getAccountingItemsListRequest,
  getAccountingItemRequest,
  deleteAccountingItemRequest,
  updateAccountingItemRequest,
  importAccountingItemsRequest,
  exportAccountingItemsRequest,
  GetAccountingItemParams,
} from './accountingItems.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type AccountingItemsStoreState = {
  links: LinkDto[];
  accountingItemColumns: EntityFieldDto[];
  defaultAccountingItemColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getAccountingItemsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AccountingItem',
  });
});

addEffectStatusHandling(getAccountingItemsColumnsFx);

export const getAccountingItemsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AccountingItem',
  });
});

addEffectStatusHandling(getAccountingItemsDefaultColumnsFx);

export const updateAccountingItemsColumns = createEvent<EntityFieldDto[]>();

export const getAccountingItemsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getAccountingItemsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getAccountingItemsFx);

export const createAccountingItemFx = createEffect(
  (accountingItemData: AccountingItemDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createAccountingItemCommand: CreateAccountingItemCommandValues = {
      ...accountingItemData,
    };

    return createAccountingItemRequest(
      currentOrganization!,
      createAccountingItemCommand,
    );
  },
);

addEffectStatusHandling(createAccountingItemFx, {
  completeMessage: 'Accounting item was created successfully',
  errorMessage: 'Accounting item was not created',
});

export const getAccountingItemFx = createEffect(
  (accountingItemParams: GetAccountingItemParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingItemRequest(
      currentOrganization as LinkResourceBaseDto,
      accountingItemParams,
    );
  },
);

addEffectStatusHandling(getAccountingItemFx);

export const updateAccountingItemFx = createEffect(
  (accountingItem: AccountingItemDto) => {
    const updateAccountingItemCommand: UpdateAccountingItemCommandValues = {
      ...accountingItem,
    };
    return updateAccountingItemRequest(
      accountingItem,
      updateAccountingItemCommand,
    );
  },
);

addEffectStatusHandling(updateAccountingItemFx, {
  completeMessage: 'Accounting item was updated successfully',
  errorMessage: 'Accounting item was not updated',
});

export const deleteAccountingItemFx = createEffect(
  (accountingItem: AccountingItemDto) => {
    return deleteAccountingItemRequest(accountingItem);
  },
);

addEffectStatusHandling(deleteAccountingItemFx, {
  completeMessage: 'Accounting item was deleted successfully',
  errorMessage: 'Accounting item was not deleted',
});

export const exportAccountingItemsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportAccountingItemsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportAccountingItemsFx, {
  completeMessage: 'Accounting items were exported successfully',
  errorMessage: 'Accounting items were not exported',
});

export const importAccountingItemsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importAccountingItemsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importAccountingItemsFx, {
  completeMessage: 'Accounting items were imported successfully',
  errorMessage: 'Accounting items were not imported',
  inFlightMessage: 'Importing accounting items...',
});

const defaultState: AccountingItemsStoreState = {
  links: [],
  accountingItemColumns: [],
  defaultAccountingItemColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const accountingItemStore: Store<AccountingItemsStoreState> = createStore(
  defaultState,
)
  .on(getAccountingItemsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      accountingItemColumns: payload.result.items,
      defaultAccountingItemColumns: payload.result.items,
    };
  })
  .on(getAccountingItemsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultAccountingItemColumns: payload.result.items,
    };
  })
  .on(updateAccountingItemsColumns, (state, payload) => {
    return { ...state, accountingItemColumns: payload };
  });
