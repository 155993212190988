import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  AttachmentInputProps,
  ContactSelectInputProps,
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';
import { SelectContactAddressesPaginate } from '../../contactAddresses/components/contactAddresses-react-select.component';
import { SelectEquipmentTypePaginate } from '../../equipmentTypes/components/equipmentType-react-select.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { ContactAddressSelectInputProps } from '../../../models/custom.models';
import { SelectModeOfTransportationPaginate } from '../../modeOfTransportations/components/modeOfTransportation-react-select.component';
import { SelectPaymentTermPaginate } from '../../paymentTerms/components/paymentTerm-react-select.component';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';
import { CustomFieldsForm } from '../../common/components/form/customFields-form.component';
import { SelectOrderStatusPaginate } from '../../orderStatuses/components/orderStatus-react-select.component';
import { OrderTypes } from '../../../models/data.models';
import { SelectPortPaginate } from '../../ports/components/port-react-select.component';
import { SelectCommodityTrackingNumberPaginate } from '../../consolidatedShipments/components/trakingNumber-react-select.component';

export const OrderForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

OrderForm.BillToContactSelect = ({
  selectedFilter = ``,
  nameId = 'billToContactName',
  placeholder = 'Select Bill To Customer',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'billToContactId',
  header = 'Customer',
  onChange = () => {},
  readonly,
  contactTypes = [],
  labelStyles,
  showContactType,
  showContactName,
  canCreateNewEntity,
  canUpdateEntity,
  getOptionLabel,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={showContactType}
      showContactName={showContactName}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      labelStyles={labelStyles}
      canCreateNewEntity={canCreateNewEntity}
      canUpdateEntity={canUpdateEntity}
      getOptionLabel={getOptionLabel}
    />
  );
};

OrderForm.CarrierContactSelect = ({
  selectedFilter = '',
  placeholder = 'Select Carrier',
  sort = 'name',
  searchKey = 'name',
  defaultValue,
  id = 'carrierContactId',
  nameId = 'carrierContactName',
  header = 'Carrier / Driver',
  required = false,
  onChange = () => {},
  readonly,
  contactTypes,
  multiple = true,
  closeMenuOnSelect = false,
  showContactType = true,
  disabled,
  canUpdateEntity = true,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      showContactType={showContactType}
      useContext={true}
      isMulti={multiple}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      selectedSearchKey={searchKey}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      closeMenuOnSelect={closeMenuOnSelect}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};

OrderForm.PaymentTermsSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  id = 'paymentTermId',
  nameId = 'paymentTermDescription',
  header = 'Payment Terms',
  required = false,
  readonly,
  disabled,
  sort = 'description',
  placeholder = 'Select Payment Term',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectPaymentTermPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePaymentTerm={onChange}
    />
  );
};

OrderForm.BondedWHSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  id = 'bondedWHId',
  nameId = 'bondedWHDescription',
  header = 'Bonded WH',
  required = false,
  readonly,
  disabled,
  sort = '',
  placeholder = 'Select Bonded WH',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectPaymentTermPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePaymentTerm={onChange}
    />
  );
};

OrderForm.ExpDate = ({
  required = false,
  defaultValue,
  header = 'Exp Date',
  nameId = 'expDate',
  placeholder = 'Select Exp Date',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

OrderForm.Date = ({
  required = false,
  defaultValue,
  header = 'Date',
  nameId = 'date',
  placeholder = 'Select Date',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

OrderForm.Time = ({
  required = false,
  defaultValue,
  header = 'Time',
  nameId = 'time',
  placeholder = 'Select Time',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
      showTimeSelect={true}
      showTimeSelectOnly={true}
      timeIntervals={15}
      timeCaption={'Time'}
      dateFormat={'h:mm aa'}
    />
  );
};

OrderForm.EntryDate = ({
  required = false,
  defaultValue,
  header = 'Entry Date',
  nameId = 'entryDate',
  placeholder = 'Select Entry Date',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

OrderForm.EntryNumber = ({
  className,
  style,
  size,
  placeholder = 'Entry Number',
  label = 'Entry Number',
}: InputPublicProps) => {
  return (
    <Input
      name={'entryNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={false}
    />
  );
};

OrderForm.Division = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
  canUpdateEntity = true,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};

OrderForm.EquipmentType = ({
  selectedFilter = ``,
  placeholder = 'Select EquipmentType',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'equipmentType',
  header = 'Equipment Type',
  onChange = () => {},
  readonly,
  nameId = 'name',
}: InputPublicProps) => {
  return (
    <SelectEquipmentTypePaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeEquipmentType={onChange}
    />
  );
};

OrderForm.EmployeeContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Dispatcher',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'employeeContactId',
  nameId = 'employeeContactName',
  header = 'Dispatchers',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ModeOfTransportation = ({
  selectedFilter = ``,
  placeholder = 'Select Mode of Transportation',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'modeOfTransportationId',
  header = 'Mode of Transportation',
  onChange = () => {},
  nameId = 'modeOfTransportationDescription',
  disabled,
  canUpdateEntity = true,
}: InputPublicProps) => {
  return (
    <SelectModeOfTransportationPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeModeOfTransportation={onChange}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};

OrderForm.OrderNumber = ({
  className,
  style,
  size,
  placeholder = 'Order Number',
  label = 'Order Number',
}: InputPublicProps) => {
  return (
    <Input
      name={'orderNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={true}
    />
  );
};

OrderForm.TrackingNumber = ({
  className,
  style,
  size,
  name,
  readonly,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={'Tracking Number'}
      placeholder={'Tracking Number'}
      required={false}
      readonly={readonly}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
    />
  );
};

OrderForm.SalespersonContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Sales Person',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'salespersonContactId',
  nameId = 'salespersonContactName',
  header = 'Sales Persons',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.IssuedByContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Issued By',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'issuedByContactId',
  nameId = 'issuedByContactName',
  header = 'Issued By',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.DestinationAgentContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Destination Agent',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'destinationAgentContactId',
  nameId = 'destinationAgentContactName',
  header = 'Destination Agent',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ShipperCompany = ({
  selectedFilter = ``,
  placeholder = 'Select Shipper',
  required = true,
  defaultValue,
  sort = 'name',
  nameId = 'orderPickups[0].shipperContactName',
  id = 'orderPickups[0].shipperContactId',
  header = 'Shipper',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ShipperLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Location',
  required = true,
  defaultValue,
  sort = 'addressLine',
  nameId = 'orderPickups[0].shipperAddressName',
  id = 'orderPickups[0].shipperAddressId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  readonly,
  contactId,
  contactType,
  addressType,
}: ContactAddressSelectInputProps) => {
  return (
    <SelectContactAddressesPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContactAddress={onChange}
      contactId={contactId}
      contactType={contactType}
      contactAddressType={addressType}
    />
  );
};

OrderForm.ShipperDate = ({
  className,
  style,
  size,
  defaultValue,
  onChange,
  readonly,
  name = 'orderPickups[0].pickDate',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Pickup Date'}
      placeholder={'Pickup Date'}
      required={false}
      type={'date'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
OrderForm.ShipperBroker = ({
  className,
  style,
  size,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={'shipperBroker'}
      label={'Customs Broker'}
      placeholder={'Customs Broker'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.ShipperNotes = ({
  defaultValue,
  className,
  style,
  size,
  readonly,
  name = 'orderPickups[0].shippingNotes',
  onChange,
  type = 'filterText',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Shipping Notes'}
      placeholder={'Shipping Notes'}
      required={false}
      type={type}
      className={className}
      onChange={onChange}
      style={style}
      size={size}
      disabled={disabled}
      valueInput={defaultValue}
    />
  );
};

OrderForm.ConsigneeCompany = ({
  selectedFilter = ``,
  placeholder = 'Select Consignee',
  required = true,
  defaultValue,
  sort = 'name',
  nameId = 'orderDeliveries[0].consigneeContactName',
  id = 'orderDeliveries[0].consigneeContactId',
  header = 'Consignee',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ConsigneeLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Location',
  required = true,
  defaultValue,
  sort = 'addressLine',
  nameId = 'orderDeliveries[0].consigneeAddressName',
  id = 'orderDeliveries[0].consigneeAddressId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  readonly,
  contactId,
  addressType,
  contactType,
}: ContactAddressSelectInputProps) => {
  return (
    <SelectContactAddressesPaginate
      nameId={nameId}
      readonly={readonly === 'readonly'}
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      isDisabled={disabled}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContactAddress={onChange}
      contactId={contactId}
      contactType={contactType}
      contactAddressType={addressType}
    />
  );
};

OrderForm.ConsigneeDate = ({
  className,
  style,
  size,
  defaultValue,
  onChange,
  readonly,
  name = 'orderDeliveries[0].deliveryDate',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Delivery Date'}
      placeholder={'Delivery Date'}
      required={false}
      type={'date'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};
OrderForm.ConsigneeNotes = ({
  defaultValue,
  type = 'filterText',
  className,
  style,
  size,
  readonly,
  name = 'orderDeliveries[0].deliveryNotes',
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Delivery Notes'}
      placeholder={'Delivery Notes'}
      required={false}
      type={type}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
      valueInput={defaultValue}
      onChange={onChange}
    />
  );
};
OrderForm.OrderStatus = ({
  onChange,
  options,
  name = 'orderStatusName',
  id = 'orderStatusId',
  placeholder = 'Select Order Status',
  required = true,
  multiple = false,
  disabled = false,
  header = 'Order Status',
  defaultValue = '',
  readonly,
  isClearable,
  selectedFilter,
  defaultValueFilter,
}: InputPublicProps) => {
  return (
    <SelectOrderStatusPaginate
      onChangeStatus={onChange}
      nameId={name}
      id={id}
      placeholder={placeholder}
      required={required}
      isMulti={multiple}
      isDisabled={disabled}
      header={header}
      selectedValue={defaultValue}
      isClearable={isClearable}
      closeMenuOnSelect={true}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
    />
  );
};
OrderForm.TotalPcsCrt = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'totalPcsCrt'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'totalPcsCrt'}
      label={'Total Pcs/Crt'}
      placeholder={'Total Pcs/Crt'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.WeightTotal = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'weightTotal'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'weightTotal'}
      label={'Weight Total'}
      placeholder={'Weight Total'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.VolumeTotal = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'volumeTotal'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'volumeTotal'}
      label={'Volume Total'}
      placeholder={'Volume Total'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.CustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      saveButtonRenderCondition={saveButtonRenderCondition}
      isSending={isSending}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};

OrderForm.CarrierCompany = ({
  selectedFilter = ``,
  placeholder = 'Select Carrier',
  required = true,
  defaultValue,
  sort = 'name',
  nameId = 'orderEntities[0].contactName',
  id = 'orderEntities[0].contactId',
  header = 'Carrier',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ProNumber = ({
  className,
  style,
  size,
  placeholder = 'Pro Number',
  label = 'PRO Number',
  name,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={false}
      onChange={(data, context) => {
        onChange(data);
      }}
    />
  );
};

OrderForm.VendorContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Vendor',
  required = true,
  defaultValue,
  sort = 'name',
  nameId = 'orderEntityVendors[0].contactId',
  id = 'orderEntityVendors[0].contactName',
  header = 'Vendor',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.VendorLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Location',
  required = true,
  defaultValue,
  sort = 'addressLine',
  id = 'orderEntityVendors[0].contactAddressName',
  nameId = 'orderEntityVendors[0].contactAddressId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  readonly,
  contactId,
  contactType,
  addressType,
}: ContactAddressSelectInputProps) => {
  return (
    <SelectContactAddressesPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContactAddress={onChange}
      contactId={contactId}
      contactType={contactType}
      contactAddressType={addressType}
    />
  );
};

OrderForm.VendorNotes = ({
  className,
  style,
  size,
  readonly,
  name = 'orderEntityVendors[0].customValues.shippingNotes',
  onChange,
  type = 'text',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Shipping Notes'}
      placeholder={'Shipping Notes'}
      required={false}
      type={type}
      className={className}
      onChange={onChange}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};

OrderForm.VendorDate = ({
  className,
  style,
  size,
  defaultValue,
  onChange,
  readonly,
  name = 'orderEntityVendors[0].customValues.vendorDate',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Date'}
      placeholder={'Date'}
      required={false}
      type={'date'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

OrderForm.VendorTrackingNumber = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'orderVendors[]'}
      label={'Tracking Number'}
      placeholder={'Tracking Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrderForm.CustomFields = ({
  customFields,
  customValues,
  onChange,
  isFieldDisable,
}) => {
  return (
    <CustomFieldsForm
      customFields={customFields}
      defaultValue={customValues}
      inputNamePrefix={`orderEntity`}
      onChange={onChange}
      isFieldsDisabled={isFieldDisable}
    />
  );
};

OrderForm.NonContactName = ({
  className,
  style,
  size,
  placeholder = 'Non contact name',
  label = 'Non contact name',
  name,
  id,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      id={id}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={false}
      onChange={onChange}
    />
  );
};

OrderForm.CustomerStatus = ({
  className,
  style,
  size,
  placeholder = 'Customer Status',
  label = 'Customer Status',
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'status'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
      disabled={true}
      valueInput={defaultValue}
    />
  );
};

OrderForm.AccountNumber = ({
  className,
  style,
  size,
  placeholder = 'Account Number',
  label = 'Account Number',
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'accountNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
      disabled={true}
      valueInput={defaultValue}
    />
  );
};

OrderForm.AddedCommodity = ({
  className,
  style,
  size,
  placeholder = 'Tracking Number',
  defaultValue,
  appendElement,
}: InputPublicProps) => {
  return (
    <Input
      name={'addedCommodity'}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={false}
      valueInput={defaultValue}
      appendElement={appendElement}
    />
  );
};

OrderForm.SelectPallet = ({
  className,
  style,
  size,
  placeholder = 'Select Pallet',
  defaultValue,
  onKeyDown,
  onChange,
  appendElement,
}: InputPublicProps) => {
  return (
    <Input
      name={'currentPallet'}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={false}
      onKeyDown={onKeyDown}
      appendElement={appendElement}
      onChange={onChange}
    />
  );
};

OrderForm.ComputedField = ({
  className,
  style,
  size,
  defaultValue,
  dependencies,
  callback,
  name,
  label,
  placeholder,
}: InputPublicProps) => {
  return (
    <Input
      dependencies={dependencies}
      callback={callback}
      name={name}
      label={label}
      placeholder={placeholder}
      required={false}
      disabled={true}
      valueInput={defaultValue}
      type={'computed-input'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrderForm.ShipmentType = ({
  className,
  style,
  size,
  ref,
  name = 'orderType',
  label = 'Transportation Mode',
  required = true,
  enumName = OrderTypes,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      required={required}
      type={'enum-select'}
      enumName={enumName}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrderForm.Port = ({
  selectedFilter = ``,
  placeholder = 'Select Port',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'portId',
  header = 'Port',
  onChange = () => {},
  readonly,
  nameId = 'portName',
  disabled,
  canUpdateEntity = true,
}: InputPublicProps) => {
  return (
    <SelectPortPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePort={onChange}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};

OrderForm.PackageDescription = ({
  className,
  style,
  size,
  readonly,
  name = 'packageDescription',
  onChange,
  type = 'input-OnChange-text',
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={null}
      placeholder={'Enter package description'}
      required={false}
      type={type}
      className={className}
      onChange={onChange}
      style={style}
      size={size}
      disabled={disabled}
      defaultValue={defaultValue}
    />
  );
};

OrderForm.SelectCommodityTrackingNumberPaginate = ({
  selectedFilter = ``,
  placeholder = 'Tracking Number',
  required = false,
  defaultValue = null,
  sort = '',
  id = 'commodityTrackingNumberId',
  options = [],
  header = 'Scan Parcel Tracking Number',
  onChange = () => {},
  className = '',
  barcode,
}: InputPublicProps) => {
  return (
    <SelectCommodityTrackingNumberPaginate
      id={id}
      classNamePrefix={className}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      options={options}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeWarehouseZone={onChange}
      barcode={barcode}
    />
  );
};

OrderForm.ProblemReason = ({
  className,
  style,
  size,
  name,
  readonly,
  onChange,
  defaultValue,
  options,
}: InputPublicProps) => {
  return (
    <Input
      id={name}
      name={name}
      label={'Problem Reason'}
      placeholder={'Reason'}
      required={false}
      readonly={readonly}
      type={'react-select'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
      options={options}
    />
  );
};

const labelStyles = {
  fontWeight: 500,
  lineHeight: '19px',
  fontSize: '16px',
};

OrderForm.ParcelTrackingNumber = ({
  id,
  className,
  style,
  size,
  type = 'text',
  name = 'parcelTrackingNumber',
  label = 'Scan Parcel Tracking Number',
  required = true,
  selectedFieldName,
  onChange,
  barcode,
  placeholder = 'Tracking Number',
  appendElement,
  barcodeButtonClassName = '',
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={name}
      label={label}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      barcode={barcode}
      placeholder={placeholder}
      appendElement={appendElement}
      barcodeButtonClassName={barcodeButtonClassName}
    />
  );
};
