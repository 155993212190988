import React, { useState } from 'react';
import { useStore } from 'effector-react';
import {
  DELETE_COMMODITYTRACKINGNUMBER_LINK_KEY,
  GetCommodityTrackingNumberParams,
} from '../commodityTrackingNumbers.service';
import { CommodityTrackingNumberDto } from '../../../models/data.models';
import {
  commodityTrackingNumberStoreForOrder,
  updateCommodityTrackingNumbersOrderColumns,
} from '../commodityTrackingNumbers.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { showDialog } from '../../common/dialog.store';
import { userHas } from '../../auth/auth.store';
import { Button } from 'react-bootstrap';
import { CommodityTrackingNumberDialog } from './commodityTrackingNumbers.dialog';
import { CommodityTrackingNumbersGrid } from '../../common/components/grid/commodityTrackingNumbers-grid.component';

export type CommodityTrackingNumbersForOrderListProps = {
  goToDetails?: (commodityParams: GetCommodityTrackingNumberParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  items?: CommodityTrackingNumberDto[];
  showPagination?: boolean;
  className?: string;
  changeItems?: React.Dispatch<
    React.SetStateAction<CommodityTrackingNumberDto[]>
  >;
  userCanDelete?: boolean;
  onCommodityTrackingNumberCreated?: (
    commodityTrackingNumber: CommodityTrackingNumberDto,
  ) => void;
  isCreateMode?: boolean;
  isLoading?: boolean;
  commodityId: number;
};

export const CommodityTrackingNumbersForOrderList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = 'commodityTrackingNumberId',
  showPagination = true,
  onPageChanged = () => {},
  onSort = () => {},
  items,
  changeItems,
  className = '',
  userCanDelete = false,
  isCreateMode = true,
  isLoading = true,
  onCommodityTrackingNumberCreated = () => {},
  commodityId,
}: CommodityTrackingNumbersForOrderListProps) => {
  const { commodityTrackingNumberColumns: columns } = useStore(
    commodityTrackingNumberStoreForOrder,
  );
  const [
    fakeTrackingNumberIndex,
    setFakeTrackingNumberIndex,
  ] = useState<number>(
    !isLoading &&
      (isCreateMode
        ? 0
        : (items[items.length - 1]?.commodityTrackingNumberId + 1) | 0),
  );

  const onEditCommodity = (commodity: CommodityTrackingNumberDto) => {
    goToDetails({
      commodityTrackingNumber: commodity,
      commodityTrackingNumberId: commodity?.commodityTrackingNumberId,
    });
  };

  const onCreateNewTrackingNumber = () => {
    const trackingNumber: CommodityTrackingNumberDto = {
      commodityId: commodityId,
      trackingNumber: '',
      syncOrderId: null,
      commodityTrackingNumberId: 0,
      isPrimary: false,
    };
    showDialog({
      dialog: CommodityTrackingNumberDialog,
      props: {
        title: `Add new tracking number`,
        commodityTrackingNumberId: 0,
        commodityId: commodityId,
        defaultCommodityTrackingNumber: trackingNumber,
      },
    }).then((result) => {
      if (result) {
        onCommodityTrackingNumberCreated(result);
        setFakeTrackingNumberIndex(fakeTrackingNumberIndex + 1);
      }
    });
  };

  const onEditCommodityTrackingNumber = (
    trackingNumber: CommodityTrackingNumberDto,
  ) => {
    goToDetails({
      commodityTrackingNumber: trackingNumber,
      commodityTrackingNumberId: trackingNumber.commodityTrackingNumberId,
    });
  };

  const onDeleteCommodityTrackingNumber = (
    commodityTrackingNumber: CommodityTrackingNumberDto,
  ) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${commodityTrackingNumber.trackingNumber}" tracking number`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        let isDeleted = false;
        items = items.filter((obj) => {
          const compareChargeResult =
            obj.commodityTrackingNumberId !==
            commodityTrackingNumber.commodityTrackingNumberId;
          if (!compareChargeResult && isDeleted === false) {
            isDeleted = true;
            return false;
          }
          return true;
        });
        changeItems(items);
      }
    });
  };

  return (
    <div className="row">
      <div className="col-8">
        <CommodityTrackingNumbersGrid
          className={className}
          showAllStore={true}
          showEmptyTable={true}
          rowKeys={['commodityTrackingNumberId']}
          data={items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={items?.length}
          sort={sort}
          showPagination={showPagination}
          onDelete={
            userHas(DELETE_COMMODITYTRACKINGNUMBER_LINK_KEY, items[0]?.links) ||
            userCanDelete
              ? onDeleteCommodityTrackingNumber
              : null
          }
          onSort={onSort}
          onEdit={onEditCommodityTrackingNumber}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateCommodityTrackingNumbersOrderColumns}
          onSelect={onEditCommodityTrackingNumber}
          hideColumnsSelect={true}
        />
      </div>
      <div className="ml-4 col-2">
        <Button
          size={'sm'}
          color="secondary"
          type="button"
          className="btn-block text-break"
          name="create-trackingNumber"
          onClick={(event) => onCreateNewTrackingNumber()}
        >
          Add tracking number
        </Button>
      </div>
    </div>
  );
};
