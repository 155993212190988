import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const JobStatusForm = ({
  id = 'jobStatusForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

JobStatusForm.CustomValues = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'customValues'}
      id={'customValues'}
      label={'Custom Values'}
      placeholder={'Custom Values'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
JobStatusForm.JobStatusName = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'jobStatusName'}
      id={'jobStatusName'}
      label={'Job Status Name'}
      placeholder={'Job Status Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
JobStatusForm.Priority = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'priority'}
      id={'priority'}
      label={'Priority'}
      placeholder={'Priority'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
JobStatusForm.StatusStage = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'statusStage'}
      id={'statusStage'}
      label={'Status Stage'}
      placeholder={'Status Stage'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
