import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CurrenciesListScreen } from './screens/currencies-list.screen';
import { CurrencyScreen } from './screens/currencies.screen';

export const CURRENCIES_LIST_PATH = '/currencies';
export const CURRENCY_EDIT_PATH = '/currencies/:currencyId';
export const CURRENCY_CREATE_PATH = '/currencies/0';

export const CurrencyRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CurrenciesListScreen pageTitle="Currencies" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CurrencyScreen pageTitle="Add New Currency" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':currencyId'}
        element={
          <PrivateRoute>
            <CurrencyScreen pageTitle="Update Currency" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
