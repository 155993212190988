import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { VesselForm } from './vessel.form';
import { ContactType, VesselDto } from '../../../models/data.models';
import { createVesselFx, getVesselFx, updateVesselFx } from '../vessels.store';
import { Panel } from '../../common/components/panel/panel.component';
import { userHas } from '../../auth/auth.store';
import { UPDATE_VESSEL_LINK_KEY } from '../vessels.service';

export type VesselEditProps = {
  vesselId?: number | null;
  onVesselCreated?: (vessel: VesselDto) => void;
  onVesselUpdated?: (vessel: VesselDto) => void;
  onVesselLoaded?: (vessel: VesselDto) => void;
  onCancel?: () => void;
};

const initialState: VesselDto = {
  vesselId: 0,
  carrierId: null,
  countryCode: null,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: '',
  organizationId: 0,
  vesselCode: null,
  carrierName: '',
  countryName: '',
  links: [],
};

export const VesselEdit = ({
  vesselId,
  onVesselLoaded = () => {},
  onVesselCreated = () => {},
  onVesselUpdated = () => {},
  onCancel = () => {},
}: VesselEditProps) => {
  const isCreateMode = !vesselId || vesselId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vessel, setVessel] = useState<VesselDto | null>(null);
  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (vesselId) {
      getVesselFx({ vesselId }).then(
        (vesselDto: VesselDto) => {
          setVessel(vesselDto);
          setIsLoading(false);
          onVesselLoaded(vesselDto);
        },
        () => {},
      );
    } else {
      throw new Error('Vessel keys were not provided');
    }
  }, [vesselId]);

  const onSubmit = (data: VesselDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createVesselFx(data)
        .then((result) => {
          onVesselCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updateVesselFx(data)
        .then((result) => {
          onVesselUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <VesselForm
        id={'vessel-form'}
        initialValues={vessel || initialState}
        onSubmit={onSubmit}
      >
        {`${isCreateMode ? 'Add New' : 'Update'} Vessel`}
        <div className="row">
          <div className="col-4">
            <VesselForm.Carrier
              contactTypes={[ContactType.Carrier]}
              defaultValue={
                vessel?.carrierId
                  ? {
                      contactId: vessel.carrierId,
                      name: vessel.carrierName,
                      contactType: ContactType.Carrier,
                    }
                  : null
              }
            />
            <VesselForm.CountryCode
              defaultValue={
                vessel?.countryCode
                  ? {
                      countryCode: vessel.countryCode,
                      name: vessel.countryName,
                    }
                  : null
              }
            />
            <VesselForm.Name />
            <VesselForm.VesselCode />
          </div>
        </div>
        <div className="d-flex justify-content-end row">
          {(userHas(UPDATE_VESSEL_LINK_KEY, vessel?.links) || isCreateMode) && (
            <div className="col-3">
              <Button
                name="save-vessel"
                type="submit"
                form={'vessel-form'}
                color="primary"
                className="btn-block"
                disabled={isSending}
                isSending={isSending}
              >
                {`${isCreateMode ? 'Save' : 'Update'} Vessel`}
              </Button>
            </div>
          )}
          <div className="col-3">
            <Button
              type="button"
              color="primary"
              onClick={onCancel}
              className="w-100 btn-secondary"
              disabled={isSending}
            >
              Close
            </Button>
          </div>
        </div>
      </VesselForm>
    </Panel>
  );
};
