import {
  WarehouseZoneDto,
  LinkDto,
  LinkResourceBaseDto,
  WarehouseZoneDtoPagedResult,
  CreateWarehouseZoneCommandValues,
  UpdateWarehouseZoneCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createWarehouseZoneRequest,
  GetWarehouseZoneParams,
  getWarehouseZoneRequest,
  updateWarehouseZoneRequest,
  deleteWarehouseZoneRequest,
  getWarehouseZonesListRequest,
} from './warehouseInspector.service';

type WarehouseInspectorStoreState = {
  links: LinkDto[];
  warehouseZoneColumns: Column[];
};

export const updateWarehouseInspectorColumns = createEvent<Column[]>();

export const getWarehouseZonesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getWarehouseZonesListRequest(currentOrganization, params);
});

export const createWarehouseZone = createEffect(
  (warehouseZoneData: WarehouseZoneDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createWarehouseZoneCommand: CreateWarehouseZoneCommandValues = {
      ...warehouseZoneData,
    };

    return createWarehouseZoneRequest(
      currentOrganization!,
      createWarehouseZoneCommand,
    );
  },
);

export const getWarehouseZone = createEffect(
  (warehouseZoneParams: GetWarehouseZoneParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getWarehouseZoneRequest(
      currentOrganization as LinkResourceBaseDto,
      warehouseZoneParams,
    );
  },
);

export const updateWarehouseZone = createEffect(
  (warehouseZone: WarehouseZoneDto) => {
    const updateWarehouseZoneCommand: UpdateWarehouseZoneCommandValues = {
      ...warehouseZone,
    };
    return updateWarehouseZoneRequest(
      warehouseZone,
      updateWarehouseZoneCommand,
    );
  },
);

export const deleteWarehouseZone = createEffect(
  (warehouseZone: WarehouseZoneDto) => {
    return deleteWarehouseZoneRequest(warehouseZone);
  },
);

const defaultState: WarehouseInspectorStoreState = {
  links: [],
  warehouseZoneColumns: [
    { name: 'name', visible: true, title: 'Name' },
    { name: 'description', visible: true, title: 'Description' },
    { name: 'parentWarehouseZoneName', visible: true, title: 'Parent Zone' },
    { name: 'nestedZonesCount', visible: true, title: 'Zones' },
    { name: 'customerName', visible: true, title: 'Customer' },
  ],
};

export const warehouseZoneStore: Store<WarehouseInspectorStoreState> = createStore(
  defaultState,
).on(updateWarehouseInspectorColumns, (state, payload) => {
  return { ...state, warehouseZoneColumns: payload };
});
