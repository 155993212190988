import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { CommodityStatusStage } from '../../../models/data.models';

export const CommodityStatusForm = ({
  children,
  onSubmit,
  initialValues,
  validationSchema,
  id,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      id={id}
    >
      {children}
    </Form>
  );
};

CommodityStatusForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CommodityStatusForm.Priority = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'priority'}
      label={'Priority'}
      placeholder={'Priority'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CommodityStatusForm.StatusName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'statusName'}
      label={'Status Name'}
      placeholder={'Status Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CommodityStatusForm.StatusStage = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'statusStage'}
      label={'Status Stage'}
      placeholder={''}
      required={true}
      type={'enum-select'}
      enumName={CommodityStatusStage}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
