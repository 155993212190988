import {
  BusinessRuleDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  BusinessRuleDtoPagedResult,
  CreateBusinessRuleCommandValues,
  UpdateBusinessRuleCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_BUSINESSRULES_LINK_KEY = 'get-businessrules';
export const CREATE_BUSINESSRULE_LINK_KEY = 'create-businessrule';
export const GET_BUSINESSRULE_LINK_KEY = 'get-businessrule';
export const UPDATE_BUSINESSRULE_LINK_KEY = 'update-businessrule';
export const DELETE_BUSINESSRULE_LINK_KEY = 'delete-businessrule';
export const IMPORT_BUSINESSRULES_LINK_KEY = 'import-businessrules';
export const EXPORT_BUSINESSRULES_LINK_KEY = 'export-businessrules';
export const BUSINESSRULE_ENTITY_NAME = 'Business Rule';

export interface GetBusinessRuleParams {
  businessRuleId: number;
}

export interface ImportBusinessRulesParams {
  organizationId: number;
}

export interface ExportBusinessRulesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getBusinessRuleRequest = async (
  resource: LinkResourceBaseDto | null,
  { businessRuleId }: GetBusinessRuleParams,
): Promise<BusinessRuleDto> => {
  if (resource && businessRuleId) {
    const getBusinessRuleLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_BUSINESSRULE_LINK_KEY,
    );
    if (getBusinessRuleLink) {
      const result = await execLink(getBusinessRuleLink, { businessRuleId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_BUSINESSRULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getBusinessRulesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<BusinessRuleDtoPagedResult> => {
  if (resource) {
    const getBusinessRulesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_BUSINESSRULES_LINK_KEY,
    );
    if (getBusinessRulesListLink) {
      const result = await execLink(getBusinessRulesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_BUSINESSRULES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createBusinessRuleRequest = async (
  resource: LinkResourceBaseDto,
  businessRule: CreateBusinessRuleCommandValues,
) => {
  const createBusinessRuleLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_BUSINESSRULE_LINK_KEY,
  );
  if (createBusinessRuleLink) {
    const result = await execLink(createBusinessRuleLink, businessRule);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_BUSINESSRULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateBusinessRuleRequest = async (
  resource: LinkResourceBaseDto,
  businessRule: UpdateBusinessRuleCommandValues,
) => {
  const updateBusinessRuleLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_BUSINESSRULE_LINK_KEY,
  );
  if (updateBusinessRuleLink) {
    const result = await execLink(updateBusinessRuleLink, businessRule);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_BUSINESSRULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteBusinessRuleRequest = async (
  businessRule: BusinessRuleDto,
) => {
  const deleteBusinessRuleLink = businessRule.links?.find(
    (x: LinkDto) => x.rel === DELETE_BUSINESSRULE_LINK_KEY,
  );
  if (deleteBusinessRuleLink) {
    const result = await execLink(deleteBusinessRuleLink, businessRule);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_BUSINESSRULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importBusinessRulesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportBusinessRulesParams,
  file: File,
) => {
  const importBusinessRulesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_BUSINESSRULES_LINK_KEY,
  );
  if (importBusinessRulesLink) {
    const result = await postFormData(importBusinessRulesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_BUSINESSRULES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportBusinessRulesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportBusinessRulesParams,
) => {
  const exportBusinessRulesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_BUSINESSRULES_LINK_KEY,
  );
  if (exportBusinessRulesLink) {
    const result = await downloadLinkFile(exportBusinessRulesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_BUSINESSRULES_LINK_KEY} is not allowed or missing link action`,
  );
};
