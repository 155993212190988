import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { TrackingForm } from './tracking.form';
import { Panel } from '../../common/components/panel/panel.component';
import { FormContext } from '../../common/components/form/form.component';
import { TrackingDto } from '../../../models/data.models';
import { useParams } from 'react-router-dom';
import { getTrackingInfoRequest } from '../tracking.service';
import * as Yup from 'yup';

export type TrackingProps = {
  orderSelectorValue?: string;
};

export const trackingInitialState = {
  orderSelector: '',
};

const trackingSchema = Yup.object().shape({
  orderSelector: Yup.string().trim().required("Can't be blank").nullable(true),
});

export const Tracking = ({ orderSelectorValue }: TrackingProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [orderSelector, setOrderSelector] = useState<string>(
    orderSelectorValue,
  );
  const [trackingInfo, setTrackingInfo] = useState<TrackingDto>(null);
  const [initialValues, setInitialValues] = useState(trackingInitialState);

  const { organizationId } = useParams<{ organizationId: string }>();

  const getTrackingData = () => {
    setIsLoading(true);
    getTrackingInfoRequest({ organizationId, orderSelector })
      .then(
        (trackingDto) => {
          const dateLocaleOptions: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          };
          const formatter = new Intl.DateTimeFormat('en-us', dateLocaleOptions);
          trackingDto?.events?.forEach((event) => {
            event.eventName = event.eventDefinition.eventName;
            event.eventDate = formatter
              .format(new Date(event.eventDate))
              .replace(' at', ',');
          });
          setTrackingInfo(trackingDto);
          setInitialValues((oldValues) => {
            oldValues.orderSelector = orderSelectorValue;
            return { ...oldValues };
          });
        },
        () => {},
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getTrackingData();
  }, [orderSelector]);

  const onSubmit = (data: any) => {
    const url = `${window.location.href.split('?')[0]}?orderSelector=${
      data.orderSelector
    }`;

    if (data.orderSelector === orderSelector) {
      getTrackingData();
    } else {
      setOrderSelector(data.orderSelector);
      navigate.pushState(null, null, url);
    }
  };

  return (
    <TrackingForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      id={'TrackingForm'}
      validationSchema={trackingSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <div className="container d-flex justify-content-center">
            <Panel className="m-3 w-75">
              <div className="tracking-header-row row w-100 m-0 flex-row">
                <div className="tracking-header-left col-6 p-0">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <TrackingForm.OrderSelector />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <Button
                        name="track-order"
                        data-cy="track-order"
                        type="submit"
                        color="primary"
                        className="btn-block"
                      >
                        Track
                      </Button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="mt-5 text-left">
                        <h3 className="text-muted mb-0">
                          Order Tracking Information
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tracking-logo-container col-6 p-0 d-flex p-0 align-items-center justify-content-center">
                  <img className="logo-TrtExpress-tracking " />
                </div>
              </div>

              <hr />

              {trackingInfo && !isLoading ? (
                <>
                  <div className="row">
                    <div className="col-6">
                      <div className="text-left">
                        <h5 className="text-muted mb-0">From</h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="text-left">
                        <h5 className="text-muted mb-0">To</h5>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="text-left">
                        <p className="font-weight-bold text-dark text-uppercase">
                          {trackingInfo?.from}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="text-left">
                        <p className="font-weight-bold text-dark text-uppercase">
                          {trackingInfo?.to}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="text-left">
                        <h5 className="text-muted mb-0">Pieces</h5>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-left">
                        <h5 className="text-muted mb-0">Weight</h5>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-left">
                        <h5 className="text-muted mb-0">Volume</h5>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="text-left">
                        <p className="font-weight-bold text-dark">
                          {trackingInfo?.pieces}
                        </p>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-left">
                        <p className="font-weight-bold text-dark">
                          {trackingInfo?.weight} {trackingInfo?.weightUnit}
                        </p>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-left">
                        <p className="font-weight-bold text-dark">
                          {trackingInfo?.volume} {trackingInfo?.volumeUnit}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="ext-left">
                        <h3 className="text-muted mb-0">Tracking navigate</h3>
                      </div>
                    </div>
                  </div>

                  <hr className="mb-0" />

                  {trackingInfo &&
                    trackingInfo.events.map((event) => {
                      return (
                        <>
                          <div className="my-3" style={{ fontSize: '1rem' }}>
                            <div
                              className="text-dark"
                              style={{ fontWeight: '500' }}
                            >
                              {event.eventDate}
                            </div>
                            <div>{event.eventName}</div>
                            <div className="text-uppercase">
                              {event.location}
                            </div>
                            <div>{event.description}</div>
                          </div>
                          <hr className="m-0  w-75" />
                        </>
                      );
                    })}
                </>
              ) : isLoading ? (
                <div className="mt-5 text-center">
                  <h3 className="text-muted m-0">Loading...</h3>
                </div>
              ) : (
                <div className="mt-5 text-center">
                  <h3 className="text-muted m-0">Nothing Found</h3>
                </div>
              )}
            </Panel>
          </div>
        )}
      </FormContext.Consumer>
    </TrackingForm>
  );
};
