import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContainerDescriptionPaginate } from '../../containerDescriptions/components/countainerDescription-react-select.component';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { SelectContainerTypePaginate } from '../../containerTypes/components/countainerType-react-select.component';
import { SelectPackageCategoryPaginate } from '../../packageCategories/components/packageCategory-react-select.component';

export const PackageTypeForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

PackageTypeForm.Air = ({
  className,
  defaultValue,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'air'}
      id={'airCheckbox'}
      label={'Air'}
      placeholder={'Air'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

PackageTypeForm.ContainerDescriptionCodeSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Container Description',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'containerDescriptionCode',
  header = 'Container Description',
  onChange = () => {},
  readonly,
  nameId = 'containerDescriptionCodeDescription',
}: InputPublicProps) => {
  return (
    <SelectContainerDescriptionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContainerDescription={onChange}
    />
  );
};

PackageTypeForm.ContainerTypeCodeSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Container Type',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'containerTypeCode',
  header = 'Container Type',
  onChange = () => {},
  readonly,
  nameId = 'containerTypeDescription',
}: InputPublicProps) => {
  return (
    <SelectContainerTypePaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContainerType={onChange}
    />
  );
};
PackageTypeForm.Ground = ({
  className,
  defaultValue,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'ground'}
      id={'groundCheckbox'}
      label={'Ground'}
      placeholder={'Ground'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
PackageTypeForm.Height = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'height'}
      label={'Height'}
      placeholder={'Height'}
      required={true}
      type={'unitName'}
      unitName={'in'}
      className={className}
      style={style}
      size={size}
    />
  );
};
PackageTypeForm.Length = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'length'}
      label={'Length'}
      placeholder={'Length'}
      required={true}
      type={'unitName'}
      unitName={'in'}
      className={className}
      style={style}
      size={size}
    />
  );
};
PackageTypeForm.MaximumWeight = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'maximumWeight'}
      label={'Maximum Weight'}
      placeholder={'Maximum Weight'}
      required={true}
      type={'unitName'}
      unitName={'lb'}
      className={className}
      style={style}
      size={size}
    />
  );
};
PackageTypeForm.Name = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
PackageTypeForm.Ocean = ({
  className,
  defaultValue,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'ocean'}
      id={'oceanCheckbox'}
      label={'Ocean'}
      placeholder={'Ocean'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
PackageTypeForm.PackageCategoryCode = ({
  selectedFilter = ``,
  placeholder = 'Select Package Category',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'packageCategoryCode',
  header = 'Package Category',
  onChange = () => {},
  readonly,
  nameId = 'packageCategoryCode',
}: InputPublicProps) => {
  return (
    <SelectPackageCategoryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePackageCategory={onChange}
    />
  );
};
PackageTypeForm.Volume = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'volume'}
      label={'Volume'}
      placeholder={'Volume'}
      required={true}
      type={'unitName'}
      unitName={'ft³'}
      className={className}
      style={style}
      size={size}
    />
  );
};
PackageTypeForm.Weight = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'weight'}
      label={'Weight'}
      placeholder={'Weight'}
      required={true}
      type={'unitName'}
      unitName={'lb'}
      className={className}
      style={style}
      size={size}
    />
  );
};
PackageTypeForm.Width = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'width'}
      label={'Width'}
      placeholder={'Width'}
      required={true}
      type={'unitName'}
      unitName={'in'}
      className={className}
      style={style}
      size={size}
    />
  );
};
