import {
  ClauseDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  ClauseDtoPagedResult,
  CreateClauseCommandValues,
  UpdateClauseCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CLAUSES_LINK_KEY = 'get-clauses';
export const CREATE_CLAUSE_LINK_KEY = 'create-clause';
export const GET_CLAUSE_LINK_KEY = 'get-clause';
export const UPDATE_CLAUSE_LINK_KEY = 'update-clause';
export const DELETE_CLAUSE_LINK_KEY = 'delete-clause';
export const IMPORT_CLAUSES_LINK_KEY = 'import-clauses';
export const EXPORT_CLAUSES_LINK_KEY = 'export-clauses';
export const CLAUSE_ENTITY_NAME = 'Clause';

export interface GetClauseParams {
  clauseId: number;
}

export interface ImportClausesParams {
  organizationId: number;
}

export interface ExportClausesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getClauseRequest = async (
  resource: LinkResourceBaseDto | null,
  { clauseId }: GetClauseParams,
): Promise<ClauseDto> => {
  if (resource && clauseId) {
    const getClauseLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CLAUSE_LINK_KEY,
    );
    if (getClauseLink) {
      const result = await execLink(getClauseLink, { clauseId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CLAUSE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getClausesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ClauseDtoPagedResult> => {
  if (resource) {
    const getClausesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CLAUSES_LINK_KEY,
    );
    if (getClausesListLink) {
      const result = await execLink(getClausesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CLAUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createClauseRequest = async (
  resource: LinkResourceBaseDto,
  clause: CreateClauseCommandValues,
) => {
  const createClauseLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CLAUSE_LINK_KEY,
  );
  if (createClauseLink) {
    const result = await execLink(createClauseLink, clause);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CLAUSE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateClauseRequest = async (
  resource: LinkResourceBaseDto,
  clause: UpdateClauseCommandValues,
) => {
  const updateClauseLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CLAUSE_LINK_KEY,
  );
  if (updateClauseLink) {
    const result = await execLink(updateClauseLink, clause);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CLAUSE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteClauseRequest = async (clause: ClauseDto) => {
  const deleteClauseLink = clause.links?.find(
    (x: LinkDto) => x.rel === DELETE_CLAUSE_LINK_KEY,
  );
  if (deleteClauseLink) {
    const result = await execLink(deleteClauseLink, clause);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CLAUSE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importClausesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportClausesParams,
  file: File,
) => {
  const importClausesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CLAUSES_LINK_KEY,
  );
  if (importClausesLink) {
    const result = await postFormData(importClausesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CLAUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportClausesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportClausesParams,
) => {
  const exportClausesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CLAUSES_LINK_KEY,
  );
  if (exportClausesLink) {
    const result = await downloadLinkFile(exportClausesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CLAUSES_LINK_KEY} is not allowed or missing link action`,
  );
};
