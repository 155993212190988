import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { BusinessRuleForm } from './businessRule.form';
import {
  BusinessRuleAction,
  BusinessRuleCondition,
  BusinessRuleDto,
  Trigger,
  TriggerEvents,
} from '../../../models/data.models';
import {
  createBusinessRuleFx,
  getBusinessRuleFx,
  updateBusinessRuleFx,
} from '../businessRules.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import { ActionForm } from './formItems/actionForm.component';
import { ConditionForm } from './formItems/conditionForm.component';
import { AiFillPlusCircle } from 'react-icons/ai';
import _ from 'lodash';

export type BusinessRuleEditProps = {
  businessRuleId?: number | null;
  onBusinessRuleCreated?: (businessRule: BusinessRuleDto) => void;
  onBusinessRuleUpdated?: (businessRule: BusinessRuleDto) => void;
  onBusinessRuleLoaded?: (businessRule: BusinessRuleDto) => void;
  onCancel?: () => void;
};

const newAction: BusinessRuleAction = { action: '' };
const newConditon: BusinessRuleCondition = {
  trigger: Trigger.After,
  triggerEvent: TriggerEvents.OrderCreated,
  condition: '',
};

const getInitialState = () => {
  const initialState: BusinessRuleDto = {
    businessRuleId: 0,
    actions: [{ ...newAction }],
    conditions: [{ ...newConditon }],
    description: null,
    isEnabled: false,
    isSystem: false,
    name: '',
    organizationId: 0,
    priority: 0,
    links: [],
  };
  return initialState;
};

const newActionSchema = Yup.object().shape({
  action: Yup.string().required('At least one action is required'),
});

const newConditonSchema = Yup.object().shape({
  condition: Yup.string().nullable(true),
  trigger: Yup.string()
    .required('Condition must contain trigger')
    .nullable(false),
  triggerEvent: Yup.string()
    .required('Condition must contain trigger event')
    .nullable(false),
});

const businessRuleSchema = Yup.object().shape({
  name: Yup.string()
    .required("Can't be blank")
    .max(100, 'Max length is 100')
    .nullable(true),
  description: Yup.string().max(500, 'Max length is 500').nullable(true),
  actions: Yup.array().of(newActionSchema).min(1),
  conditions: Yup.array().of(newConditonSchema).min(1),
});

export const BusinessRuleEdit = ({
  businessRuleId,
  onBusinessRuleLoaded = () => {},
  onBusinessRuleCreated = () => {},
  onBusinessRuleUpdated = () => {},
  onCancel = () => {},
}: BusinessRuleEditProps) => {
  const isCreateMode = !businessRuleId || businessRuleId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contextValue, setContextValue] = useState(getInitialState());

  const addAction = () => {
    const newContext = { ...contextValue };
    newContext.actions.push({ ...newAction });
    setContextValue(newContext);
  };

  const deleteActionItem = (indexItemToDelete?: number) => {
    const newContext = { ...contextValue };
    newContext?.actions?.splice(indexItemToDelete, 1);
    setContextValue(newContext);
  };

  const setAction = (index, value) => {
    setContextValue((context) => {
      context.actions[index].action = value;
      return { ...context };
    });
  };

  const getActions = () => {
    return contextValue?.actions?.map((item, index) => (
      <ActionForm
        index={index}
        setAction={(value) => {
          setAction(index, value);
        }}
        allowDeleteAction={contextValue?.actions?.length > 0 && index >= 1}
        onDeleteAction={() => deleteActionItem(index)}
        key={index}
      />
    ));
  };

  const addCondition = () => {
    const newContext = { ...contextValue };
    newContext?.conditions?.push({ ...newConditon });
    setContextValue(newContext);
  };

  const deleteConditionItem = (indexItemToDelete?: number) => {
    const newContext = { ...contextValue };
    newContext?.conditions?.splice(indexItemToDelete, 1);
    setContextValue(newContext);
  };

  const setConditon = (index, value) => {
    const newContext = _.cloneDeep(contextValue);
    newContext.conditions[index].condition = value;
    setContextValue(newContext);
  };

  const setTriggerEvent = (index, value) => {
    setContextValue((context) => {
      context.conditions[index].triggerEvent = value;
      return { ...context };
    });
  };

  const setTrigger = (index, value) => {
    setContextValue((context) => {
      context.conditions[index].trigger = value;
      return { ...context };
    });
  };

  const getConditions = () => {
    return contextValue?.conditions?.map((item, index) => (
      <ConditionForm
        index={index}
        setCondition={(value) => setConditon(index, value)}
        setTriggerEvent={(value) => setTriggerEvent(index, value)}
        setTrigger={(value) => setTrigger(index, value)}
        allowDeleteCondition={
          contextValue?.conditions?.length > 0 && index >= 1
        }
        onDeleteCondition={deleteConditionItem}
        key={index}
      />
    ));
  };

  const setName = (value) => {
    setContextValue((contextValue) => {
      contextValue.name = value;
      return { ...contextValue };
    });
  };

  const setPriority = (value) => {
    setContextValue((contextValue) => {
      contextValue.priority = value;
      return { ...contextValue };
    });
  };

  const setDescription = (event) => {
    setContextValue((contextValue) => {
      contextValue.description = event?.target?.value;
      return { ...contextValue };
    });
  };

  const setIsEnabled = (value) => {
    setContextValue((contextValue) => {
      contextValue.isEnabled = value;
      return { ...contextValue };
    });
  };

  const setIsSynchronized = (value) => {
    setContextValue((contextValue) => {
      contextValue.isSynchronized = value;
      return { ...contextValue };
    });
  };

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (businessRuleId) {
      getBusinessRuleFx({ businessRuleId }).then(
        (businessRuleDto: BusinessRuleDto) => {
          setContextValue(businessRuleDto);
          setIsLoading(false);
          onBusinessRuleLoaded(businessRuleDto);
        },
      );
    } else {
      throw new Error('BusinessRule keys were not provided');
    }
  }, [businessRuleId]);

  const onSubmit = (data: BusinessRuleDto) => {
    setIsSending(true);

    if (data) {
      data.actions = contextValue?.actions?.filter(
        (item) => item.action !== '',
      );
    }

    if (isCreateMode) {
      createBusinessRuleFx(data)
        .then((result) => {
          onBusinessRuleCreated(result.data);
        })
        .finally(() => setIsSending(false));
    } else {
      updateBusinessRuleFx(data)
        .then((result) => {
          onBusinessRuleUpdated(result.data);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Panel className="m-4">
      <div className="row">
        <div className="col-12">
          <BusinessRuleForm
            initialValues={contextValue}
            onSubmit={onSubmit}
            validationSchema={businessRuleSchema}
            id={'businessRuleForm'}
          >
            <div className="row">
              <div className="col-6">
                <BusinessRuleForm.Name
                  onChange={(value) => setName(value)}
                  defaultValue={contextValue.name}
                />
              </div>
              <div className="col-6">
                <BusinessRuleForm.Priority
                  onChange={(value) => setPriority(value)}
                  defaultValue={contextValue.priority}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <BusinessRuleForm.Description
                  onChange={(event) => setDescription(event)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <BusinessRuleForm.IsEnabled
                  defaultValue={contextValue?.isEnabled || false}
                  onChange={(value) => setIsEnabled(value)}
                />
              </div>
              <div className="col-2">
                <BusinessRuleForm.IsSynchronized
                  defaultValue={contextValue?.isSynchronized || false}
                  onChange={(value) => setIsSynchronized(value)}
                />
              </div>
              <div className="col-3">
                {!isCreateMode && (
                  <BusinessRuleForm.IsSystem
                    defaultValue={contextValue?.isSystem || false}
                    disabled={true}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="d-flex">
                  <h3 className={'font-weight-normal mt-1 mb-5'}>Actions</h3>
                  <AiFillPlusCircle
                    onClick={addAction}
                    className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                    data-testid="btn-create-new-carrier"
                  />
                </div>
                {getActions()}
              </div>

              <div className="col-6">
                <div className="d-flex">
                  <h3 className={'font-weight-normal mt-1 mb-5'}>Conditions</h3>
                  <AiFillPlusCircle
                    onClick={addCondition}
                    className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                    data-testid="btn-create-new-carrier"
                  />
                </div>
                {getConditions()}
              </div>
            </div>

            <div className="d-flex justify-content-end row">
              <div className="col-5">
                <Button
                  name="save-businessRule"
                  type="submit"
                  color="primary"
                  className="btn-block"
                  form="businessRuleForm"
                >
                  Save Business Rule
                </Button>
              </div>
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </BusinessRuleForm>
        </div>
      </div>
    </Panel>
  );
};
