import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectDocumentTemplatePaginate } from '../../documentTemplates/components/documentTemplate-react-select.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';

export const EventDefinitionForm = ({
  children,
  onSubmit,
  initialValues,
  innerRef,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

EventDefinitionForm.EventName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'eventName'}
      label={'Name'}
      placeholder={'Event Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};

EventDefinitionForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Details'}
      placeholder={'Event Details'}
      required={false}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};

EventDefinitionForm.Location = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'location'}
      label={'Location'}
      placeholder={'Location'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};

EventDefinitionForm.OrderType = ({
  onChange,
  options,
  name = 'orderType',
  id = 'orderType',
  placeholder = 'All Order Types',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Order Type',
  defaultValue = 'All',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      useContext={true}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

EventDefinitionForm.OrderEvent = ({
  onChange,
  options,
  name = 'orderEvent',
  id = 'orderEvent',
  placeholder = 'Select Order Event',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Order Event',
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      useContext={true}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

EventDefinitionForm.IsInactive = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      id={'isInactiveEventDefinitionCheckbox'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

EventDefinitionForm.IncludeInTracking = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'includeInTracking'}
      id={'includeInTrackingEventDefinitionCheckbox'}
      label={'Include in Tracking'}
      placeholder={'Include in Tracking'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

EventDefinitionForm.SendEmail = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'sendEmail'}
      id={'sendEmailEventDefinitionCheckbox'}
      label={'Send in Transaction Tracking Email'}
      placeholder={'Send in Transaction Tracking Email'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

EventDefinitionForm.AutomaticCreate = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'automaticCreateCheckbox'}
      id={'automaticCreateEventDefinitionCheckbox'}
      label={'Automatic Create'}
      placeholder={''}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

EventDefinitionForm.DocumentTemplateSelect = ({
  selectedFilter = ``,
  placeholder = 'Select document template',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'sendEmailDocumentId',
  header = 'Document Template',
  onChange = () => {},
  nameId = 'sendEmailDocumentId',
  isClearable = true,
  disabled,
  readonly,
}: InputPublicProps) => {
  return (
    <SelectDocumentTemplatePaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeDocumentTemplate={onChange}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};

EventDefinitionForm.CustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      saveButtonRenderCondition={saveButtonRenderCondition}
      isSending={isSending}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
