import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Icon } from './icon-component';
import { FormikContext } from 'formik';
import { parseTemplate } from '../component-hooks';

export const SummaryComponent = (props: ComponentProps) => {
  const formikContext = useContext(FormikContext);
  const [summaryItems, setSummaryItems] = useState([]);

  useEffect(() => {
    if (props.props?.items) {
      const items = props.props?.items.map((item) => {
        return {
          label: item.label,
          value: parseTemplate(item.value, {
            ...props.variables,
            ...props.context.store,
            ...formikContext?.values,
          }),
          icon: item.icon,
          iconColor: item.iconColor,
        };
      });
      setSummaryItems(items);
    }
    if (props.props?.query) {
      // TODO: implement query
    }
  }, [props.props?.items, props.context, formikContext?.values]);

  const classesData = useMemo(
    () => ({
      dataTarget: `#${props.name}`,
      collapsedClass: props.props?.options?.collapsed
        ? 'collapse show'
        : 'collapse',
      labelClassName:
        props.props?.options?.labelClassName ?? 'mb-0 font-weight-bold',
      labelItemClassName:
        props.props?.options?.labelItemClassName ?? 'mb-0 ml-1',
      iconItemClassName: props.props?.options?.iconItemClassName ?? 'mr-2',
      valueItemClassName:
        props.props?.options?.valueItemClassName ??
        'mb-0 ml-2 font-weight-bold',
    }),
    [props.props],
  );

  return (
    <div className="accordion">
      <div>
        <div
          className={'d-flex justify-content-between'}
          data-toggle="collapse"
          data-target={classesData.dataTarget}
          aria-controls={props.name}
        >
          <div className={classesData.labelClassName}>{props.props?.label}</div>
          <div className={'d-flex'}>
            {summaryItems.map((item: any, i: number) => {
              return (
                <div key={i} className="d-flex">
                  <div className={'d-flex ml-3'}>
                    {item.icon && (
                      <div className={classesData.iconItemClassName}>
                        <Icon
                          icon={item.icon}
                          color={item.iconColor}
                          size="1x"
                        />
                      </div>
                    )}
                    <div className={classesData.labelItemClassName}>
                      {item.label}
                    </div>
                    <div className={classesData.valueItemClassName}>
                      {item.value}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {props.props?.options?.allowExpand && (
          <div id={props.name} className={classesData.collapsedClass}>
            {props.children?.map((child: ComponentProps, i) => (
              <ComponentRender
                key={i}
                {...child}
                variables={props.variables}
                context={props.context}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
