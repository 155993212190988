import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { ReportPackage } from './reportPackage.component';
import { CargoMovementDto } from '../../../models/custom.models';

export type ReportPackageDialogProperties = {
  order: CargoMovementDto;
  title: string;
  className?: string;
  onOrderUpdated?: (order: OrderDto) => void;
};

export class ReportPackageDialog extends Dialog<ReportPackageDialogProperties> {
  constructor(props: ReportPackageDialogProperties) {
    super(props);
    this.onOrderSaved = this.onOrderSaved.bind(this);
  }

  onOrderSaved = (order: OrderDto) => {
    this.close(order);
  };

  renderContent(): any {
    const { order } = this.props as ReportPackageDialogProperties;
    return (
      <ReportPackage
        order={order}
        onOrderUpdated={this.onOrderSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
