import React from 'react';
import { OrderEntitiesForm } from '../../components/form-items/orderEntitiesForm.component';
import { OrderDto } from '../../../../models/data.models';
import { Button } from '../../../common/components/button/button.component';

export type EntitiesFragmentProps = {
  airOrderContextFormValue: OrderDto;
  setAirOrderContextFormValue: (order: any) => void;
  handleNext: () => void;
  handleBack: () => void;
};

export const EntitiesFragment = ({
  airOrderContextFormValue,
  setAirOrderContextFormValue,
  handleNext,
  handleBack,
}: EntitiesFragmentProps) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-3">
          <h2>Select the entities</h2>
        </div>
      </div>
      <OrderEntitiesForm
        airOrderContextFormValue={airOrderContextFormValue}
        setAirOrderContextFormValue={setAirOrderContextFormValue}
      />
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button
          className="mr-2"
          color="primary"
          onClick={handleNext}
          style={{ minWidth: '8.5rem' }}
        >
          Next
        </Button>
        <Button
          className="mr-3"
          onClick={handleBack}
          style={{ minWidth: '8.5rem' }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};
