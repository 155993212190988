import { Dialog } from '../../common/components/dialog/dialog.component';
import { JobEdit } from './job-edit.component';
import { JobDto } from '../../../models/data.models';

export type JobDialogProperties = {
  jobId?: string | null;
  title: string;
};

export class JobDialog extends Dialog<JobDialogProperties> {
  constructor(props: JobDialogProperties) {
    super(props);
    this.onJobSaved = this.onJobSaved.bind(this);
  }

  onJobSaved = (job: JobDto) => {
    this.close(job);
  };

  onJobDeleted = (job: JobDto) => {
    this.close(job);
  };

  onCancel = () => {
    this.close();
  };

  renderContent(): any {
    const { jobId } = this.props as JobDialogProperties;
    return (
      <JobEdit
        jobId={jobId}
        onJobCreated={this.onJobSaved}
        onJobUpdated={this.onJobSaved}
        onDelete={this.onJobDeleted}
        onCancel={this.onCancel}
      />
    );
  }
}
