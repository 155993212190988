import {
  Column,
  LinkDto,
  ReceiveCarrierPayCommandValues,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { updateOrdersColumns } from '../orders/orders.store';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  getCarrierPaysListRequest,
  receiveCarrierPayRequest,
} from './carrierPayManager.service';

export const getCarrierPays = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCarrierPaysListRequest(currentOrganization, params);
});

export const receiveCarrierPay = createEffect(
  (carrierPay: ReceiveCarrierPayCommandValues) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return receiveCarrierPayRequest(currentOrganization!, carrierPay);
  },
);

type CarrierPayManagerStoreState = {
  links: LinkDto[];
  orderCarrierPayColumns: Column[];
};

const defaultState: CarrierPayManagerStoreState = {
  links: [],
  orderCarrierPayColumns: [
    {
      name: 'orderNumber',
      visible: true,
      title: 'Load',
      sortName: 'Order.OrderNumber~ToInt32',
    },
    {
      name: 'carrierName',
      visible: true,
      title: 'Carrier',
      sortName: 'Carrier.Name',
    },
    {
      name: 'customerName',
      visible: true,
      title: 'Customer',
      sortName: 'Order.BillToContact.Name',
    },
    { name: 'origin', visible: true, title: 'Origin' },
    {
      name: 'shippedDate',
      visible: true,
      title: 'Shipped',
    },
    {
      name: 'destination',
      visible: true,
      title: 'Destination',
    },
    {
      name: 'deliveredDate',
      visible: true,
      title: 'Delivered',
    },
    {
      name: 'carrierFee',
      visible: true,
      title: 'Carrier Fee',
    },
    {
      name: 'balanceOwning',
      visible: true,
      title: 'Balance Owning',
    },
    {
      name: 'billReceived',
      visible: true,
      title: 'Bill Received',
    },
    {
      name: 'receivedDate',
      visible: true,
      title: 'Received Date',
    },
    { name: 'aging', visible: true, title: 'Aging' },
  ],
};
export const updateCarrierPayColumns = createEvent<Column[]>();

export const carrierPayManagerStore: Store<CarrierPayManagerStoreState> = createStore(
  defaultState,
)?.on(updateCarrierPayColumns, (state, payload) => {
  return { ...state, orderColumns: payload };
});
