import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { WarehouseLocationForm } from './warehouseLocation.form';
import {
  ContactDto,
  ContactType,
  LocationType,
  WarehouseLocationDto,
  WarehouseZoneDto,
} from '../../../models/data.models';
import {
  createWarehouseLocationFx,
  getWarehouseLocationFx,
  updateWarehouseLocationFx,
} from '../warehouseLocations.store';
import { Panel } from '../../common/components/panel/panel.component';
import { WarehouseLocationDefaultValues } from '../../common/DefaultValues';
import { userHas } from '../../auth/auth.store';
import { UPDATE_WAREHOUSELOCATION_LINK_KEY } from '../warehouseLocations.service';
import { getEnumKeyByValue, getEnumValues } from '../../../utils/helper.utils';
import { ReactSelectItem } from '../../../models/custom.models';
import * as Yup from 'yup';

export type WarehouseLocationEditProps = {
  warehouseLocationId?: number | null;
  onWarehouseLocationCreated?: (
    warehouseLocation: WarehouseLocationDto,
  ) => void;
  onWarehouseLocationUpdated?: (
    warehouseLocation: WarehouseLocationDto,
  ) => void;
  onWarehouseLocationLoaded?: (warehouseLocation: WarehouseLocationDto) => void;
  onCancel?: () => void;
};

const initialState: WarehouseLocationDto = {
  warehouseLocationId: 0,
  code: WarehouseLocationDefaultValues.code,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  customerId: WarehouseLocationDefaultValues.customerId,
  description: WarehouseLocationDefaultValues.description,
  height: WarehouseLocationDefaultValues.height,
  isInactive: WarehouseLocationDefaultValues.isInactive,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  length: WarehouseLocationDefaultValues.locationLength,
  locationType: WarehouseLocationDefaultValues.locationType,
  maximumWeight: null,
  organizationId: 0,
  width: WarehouseLocationDefaultValues.width,
  customerName: WarehouseLocationDefaultValues.customerName,
  links: [],
};

const warehouseLocatioSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code can't be blank")
    .max(100, 'Max length is 100')
    .nullable(true),
  locationType: Yup.mixed()
    .oneOf(Object.values(LocationType), "Can't be blank")
    .defined("Can't be blank"),
  isInactive: Yup.boolean(),
});

export const WarehouseLocationEdit = ({
  warehouseLocationId,
  onWarehouseLocationLoaded = () => {},
  onWarehouseLocationCreated = () => {},
  onWarehouseLocationUpdated = () => {},
  onCancel = () => {},
}: WarehouseLocationEditProps) => {
  const isCreateMode = !warehouseLocationId || warehouseLocationId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    warehouseLocation,
    setWarehouseLocation,
  ] = useState<WarehouseLocationDto | null>(initialState);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (warehouseLocationId) {
      getWarehouseLocationFx({ warehouseLocationId }).then(
        (warehouseLocationDto: WarehouseLocationDto) => {
          setWarehouseLocation(warehouseLocationDto);
          setIsLoading(false);
          onWarehouseLocationLoaded(warehouseLocationDto);
        },
      );
    } else {
      throw new Error('WarehouseLocation keys were not provided');
    }
  }, [warehouseLocationId]);

  const onLocationTypeChange = (newValueLocationType: ReactSelectItem) => {
    setWarehouseLocation((warehouseLocationDto) => {
      if (newValueLocationType) {
        warehouseLocationDto.locationType = getEnumKeyByValue(
          newValueLocationType?.label,
          LocationType,
        );
      } else {
        warehouseLocationDto.locationType = null;
      }
      return { ...warehouseLocationDto };
    });
  };

  const onSubmit = (data: WarehouseLocationDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createWarehouseLocationFx(data)
        .then(
          (result) => {
            onWarehouseLocationCreated(result.data);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateWarehouseLocationFx(data)
        .then(
          (result) => {
            onWarehouseLocationUpdated(result.data);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <WarehouseLocationForm
        id={'warehouseLocation-form'}
        validationSchema={warehouseLocatioSchema}
        initialValues={warehouseLocation || initialState}
        onSubmit={onSubmit}
      >
        {isCreateMode ? (
          <h2 className="header-form">Add Warehouse Location</h2>
        ) : (
          <h2>Update Warehouse Location</h2>
        )}
        <div className="row">
          <div className="col-4">
            <WarehouseLocationForm.Code disabled={!isCreateMode} />
            <WarehouseLocationForm.Description />
            <WarehouseLocationForm.LocationType
              defaultValue={
                warehouseLocation?.locationType
                  ? {
                      label: LocationType[warehouseLocation?.locationType],
                      value: warehouseLocation.locationType,
                    }
                  : null
              }
              options={getEnumValues(LocationType)}
            />
            <WarehouseLocationForm.ParentZoneId
              options={[warehouseLocation?.parentZoneId]}
              defaultValue={
                warehouseLocation?.parentZoneId
                  ? {
                      warehouseZoneId: warehouseLocation?.parentZoneId,
                      name: warehouseLocation?.parentWarehouseZoneName,
                    }
                  : null
              }
            />
            <WarehouseLocationForm.CustomerSelect
              defaultValue={
                warehouseLocation &&
                warehouseLocation?.customerId != null &&
                warehouseLocation?.customerName != null
                  ? {
                      contactId: warehouseLocation.customerId,
                      name: warehouseLocation.customerName,
                    }
                  : ''
              }
            />
            <div className="row">
              <div className="col">
                <WarehouseLocationForm.Length />
                <WarehouseLocationForm.Width />
                <WarehouseLocationForm.Height />
              </div>
              <div className="col">
                <WarehouseLocationForm.MaximumWeight />
              </div>
            </div>
            <WarehouseLocationForm.IsInactive
              defaultValue={warehouseLocation?.isInactive ?? false}
            />
            <div className="justify-content-end row">
              {(userHas(
                UPDATE_WAREHOUSELOCATION_LINK_KEY,
                warehouseLocation?.links,
              ) ||
                isCreateMode) && (
                <div>
                  <Button
                    form={'warehouseLocation-form'}
                    name="save-warehouseLocation"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Warehouse Location
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </WarehouseLocationForm>
    </Panel>
  );
};
