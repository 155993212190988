import {
  ChargeDto,
  LinkDto,
  LinkResourceBaseDto,
  ChargeDtoPagedResult,
  CreateChargeCommandValues,
  UpdateChargeCommandValues,
  ChargeType,
  CommodityDto,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CHARGES_LINK_KEY = 'get-charges';
export const CREATE_CHARGE_LINK_KEY = 'create-charge';
export const GET_CHARGE_LINK_KEY = 'get-charge';
export const UPDATE_CHARGE_LINK_KEY = 'update-charge';
export const DELETE_CHARGE_LINK_KEY = 'delete-charge';
export const CALCULATE_CHARGES_AMOUNT_LINK_KEY = 'calculate-charges-amount';
export const COMPLETE_CHARGE_LINK_KEY = 'complete-charge';

export interface CompleteChargeCommandValues {
  organizationId: number;
  chargeId: number;
}

export interface GetChargeParams {
  organizationId?: number;
  chargeId: number;
  charge?: ChargeDto;
  chargeType?: ChargeType;
}

export interface CalculateChargesAmountParams {
  commodities: CommodityDto[];
  charges: ChargeDto[];
}

export const getChargeRequest = async (
  resource: LinkResourceBaseDto | null,
  { organizationId, chargeId }: GetChargeParams,
): Promise<ChargeDto> => {
  if (resource && chargeId) {
    const getChargeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CHARGE_LINK_KEY,
    );
    if (getChargeLink) {
      const result = await execLink(getChargeLink, {
        organizationId,
        chargeId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CHARGE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getChargesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ChargeDtoPagedResult> => {
  if (resource) {
    const getChargesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CHARGES_LINK_KEY,
    );
    if (getChargesListLink) {
      const result = await execLink(getChargesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CHARGES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createChargeRequest = async (
  resource: LinkResourceBaseDto,
  charge: CreateChargeCommandValues,
  options?: { dryRun?: boolean },
) => {
  const createChargeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CHARGE_LINK_KEY,
  );
  if (createChargeLink) {
    const result = await execLink(createChargeLink, charge, options);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CHARGE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateChargeRequest = async (
  resource: LinkResourceBaseDto,
  charge: UpdateChargeCommandValues,
  options?: { dryRun?: boolean },
) => {
  const updateChargeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CHARGE_LINK_KEY,
  );
  if (updateChargeLink) {
    const result = await execLink(updateChargeLink, charge, options);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CHARGE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteChargeRequest = async (charge: ChargeDto) => {
  const deleteChargeLink = charge.links?.find(
    (x: LinkDto) => x.rel === DELETE_CHARGE_LINK_KEY,
  );
  if (deleteChargeLink) {
    const result = await execLink(deleteChargeLink, charge);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CHARGE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const CalculateChargesAmountRequest = async (
  resource: LinkResourceBaseDto | null,
  request: CalculateChargesAmountParams,
): Promise<ChargeDto> => {
  if (resource) {
    const calculateChargesAmountLink = resource.links?.find(
      (x: LinkDto) => x.rel === CALCULATE_CHARGES_AMOUNT_LINK_KEY,
    );

    if (calculateChargesAmountLink) {
      const result = await execLink(calculateChargesAmountLink, request);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CHARGE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const completeChargeRequest = async (
  resource?: LinkResourceBaseDto | null,
  request?: CompleteChargeCommandValues,
) => {
  if (resource && request) {
    const completeChargeLink = resource?.links?.find(
      (x: LinkDto) => x.rel === COMPLETE_CHARGE_LINK_KEY,
    );

    if (completeChargeLink) {
      await execLink(completeChargeLink, request);
      return;
    }
  }
  addMessage({
    message: `Action ${COMPLETE_CHARGE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
