import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { RateForm } from './rate.form';
import {
  ContactDto,
  RateDto,
  CountryDto,
  AccountingItemDto,
  CommodityTypeDto,
  CurrencyDto,
  ModeOfTransportationDto,
  PortDto,
  ServiceType,
  Frequency,
  RateType,
  CustomFieldEntityType,
  CustomFieldDto,
} from '../../../models/data.models';
import { createRateFx, getRateFx, updateRateFx } from '../rates.store';
import { Panel } from '../../common/components/panel/panel.component';
import { FormContext } from '../../common/components/form/form.component';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { TariffEdit } from '../../tariffs/components/tariff-edit.component';
import { userHas } from '../../auth/auth.store';
import { UPDATE_RATE_LINK_KEY } from '../../rates/rates.service';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { fixRateDataForSubmit } from '../../../utils/helper.utils';

export type RateEditProps = {
  rateId?: number | null;
  onRateCreated?: (rate: RateDto) => void;
  onRateUpdated?: (rate: RateDto) => void;
  onRateLoaded?: (rate: RateDto) => void;
  onCancel?: () => void;
  rateType: RateType | null;
};

const initialState: RateDto = {
  rateId: 0,
  accountingItemId: null,
  amendmendNumber: null,
  applyToDestinationCountry: false,
  applyToOriginCountry: false,
  automaticallyCreateCharge: false,
  finalMileCarrierId: null,
  clientId: null,
  commodityTypeId: null,
  contractNumber: null,
  countryOfDestinationCode: null,
  countryOfOriginCode: null,
  created: null,
  createdBy: '',
  currencyId: null,
  effectiveDate: null,
  expirationDate: null,
  frequency: null,
  isHazardous: false,
  lastModified: null,
  lastModifiedBy: '',
  modeOfTransportationId: null,
  notes: null,
  organizationId: 0,
  portOfDeliveryId: null,
  portOfLoadingId: null,
  portOfReceiptId: null,
  portOfUnloadingId: null,
  rateType: RateType.StandardClientRate,
  serviceType: null,
  tariff: null,
  links: [],
  customValues: {},
  transitDaysMin: null,
  transitDaysMax: null,
};

export const RateEdit = ({
  rateId,
  onRateLoaded = () => {},
  onRateCreated = () => {},
  onRateUpdated = () => {},
  onCancel = () => {},
  rateType,
}: RateEditProps) => {
  const isCreateMode = !rateId || rateId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rate, setRate] = useState<RateDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Rate} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        setCustomFields(customFields);
      },
      () => {},
    );
    if (isCreateMode) {
      setIsLoading(false);
      initialState.rateType = rateType;
      setRate(initialState);
    } else if (rateId) {
      getRateFx({ rateId })
        .then((rateDto: RateDto) => {
          setRate(rateDto);
          setIsLoading(false);
          onRateLoaded(rateDto);
        })
        .finally(() => setIsLoading(false));
    } else {
      throw new Error('Rate keys were not provided');
    }
  }, [rateId]);

  const onSubmit = (data: RateDto) => {
    fixRateDataForSubmit(data);
    setIsSending(true);
    if (isCreateMode) {
      createRateFx(data)
        .then((result) => {
          onRateCreated(result.data);
        })
        .finally(() => setIsSending(false));
    } else {
      updateRateFx(data)
        .then((result) => {
          onRateUpdated(result.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <RateForm
      initialValues={rate || initialState}
      onSubmit={onSubmit}
      id={'rate-form'}
    >
      <FormContext.Consumer>
        {(context) => {
          const isClientRate = isCreateMode
            ? rateType === RateType.ClientRate
            : context?.values?.rateType === RateType.ClientRate;

          return (
            <Tabs>
              <TabList>
                <span>
                  <Tab>General</Tab>
                  <Tab>Contract</Tab>
                  <Tab>Notes</Tab>
                  <Tab>Additional</Tab>
                </span>
              </TabList>

              <TabPanel forceRender={false}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New Rate</h3>
                  ) : (
                    <h3 className="header-form">Update Rate</h3>
                  )}

                  <div className="row">
                    <div className="col col-md-3 col-lg-3">
                      <RateForm.EnumSelect
                        id={'rateType'}
                        enumType={RateType}
                        name={'rateType'}
                        header={'Rate Type'}
                        disabled={true}
                      />
                    </div>
                    {isClientRate && (
                      <div className="col col-md-3 col-lg-3">
                        <RateForm.ClientContactSelect
                          defaultValue={rate?.client ? rate.client : ''}
                          onChange={(contact: ContactDto) => {
                            setRate((rate) => {
                              rate.clientId = contact?.contactId;
                              rate.client = contact;
                              return { ...rate };
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.ModeOfTransportation
                        defaultValue={
                          rate?.modeOfTransportation
                            ? rate.modeOfTransportation
                            : ''
                        }
                        onChange={(mode: ModeOfTransportationDto) => {
                          setRate((rate) => {
                            rate.modeOfTransportationId =
                              mode?.modeOfTransportationId;
                            rate.modeOfTransportation = mode;
                            return { ...rate };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.AccountingItemSelect
                        defaultValue={
                          rate?.accountingItem ? rate.accountingItem : ''
                        }
                        onChange={(item: AccountingItemDto) => {
                          setRate((rate) => {
                            rate.accountingItemId = item?.accountingItemId;
                            rate.accountingItem = item;
                            return { ...rate };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.TransitDaysMin
                        name="transitDaysMin"
                        defaultValue={rate?.transitDaysMin}
                        onChange={(event) => {
                          const transitDaysMin = Number(event.target.value);
                          setRate((prevRate) => {
                            return { ...prevRate, transitDaysMin };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.TransitDaysMax
                        name="transitDaysMax"
                        defaultValue={rate?.transitDaysMax}
                        onChange={(event) => {
                          const transitDaysMax = Number(event.target.value);
                          setRate((prevRate) => {
                            return { ...prevRate, transitDaysMax };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.EnumSelect
                        defaultValue={rate.serviceType}
                        id={'serviceType'}
                        name={'serviceType'}
                        enumType={ServiceType}
                        header={'Service Type'}
                        placeholder={'Select Service Type'}
                        onChange={(serviceType) => {
                          setRate((rate) => {
                            rate.serviceType = serviceType.value;
                            return { ...rate };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.CarrierContactSelect
                        defaultValue={
                          rate?.finalMileCarrier ? rate.finalMileCarrier : ''
                        }
                        onChange={(contact: ContactDto) => {
                          setRate((rate) => {
                            rate.finalMileCarrierId = contact?.contactId;
                            rate.finalMileCarrier = contact;
                            return { ...rate };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.EnumSelect
                        defaultValue={rate?.frequency}
                        id={'frequency'}
                        name={'frequency'}
                        enumType={Frequency}
                        header={'Frequency'}
                        placeholder={'Select frequency'}
                        onChange={(frequency) => {
                          setRate((rate) => {
                            rate.frequency = frequency.value;
                            return { ...rate };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.CurrencySelect
                        defaultValue={rate?.currency ? rate.currency : ''}
                        onChange={(currency: CurrencyDto) => {
                          setRate((rate) => {
                            rate.currencyId = currency?.currencyId;
                            rate.currency = currency;
                            return { ...rate };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3"></div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.CarrierContactSelect
                        defaultValue={rate?.carrier ? rate.carrier : ''}
                        onChange={(contact: ContactDto) => {
                          setRate((rate) => {
                            rate.carrierId = contact?.contactId;
                            rate.carrier = contact;
                            return { ...rate };
                          });
                        }}
                        header={'Carrier'}
                      />
                    </div>
                  </div>
                  <hr className="mb-7" />
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="row">
                        <div className="col-3">
                          <h5>Origin</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <RateForm.PortOfReceipt
                            defaultValue={
                              rate?.portOfReceipt ? rate.portOfReceipt : ''
                            }
                            onChange={(port: PortDto) => {
                              setRate((rate) => {
                                rate.portOfReceiptId = port?.portId?.toString();
                                rate.portOfReceipt = port;
                                return { ...rate };
                              });
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <RateForm.PortOfLoading
                            defaultValue={
                              rate?.portOfLoading ? rate.portOfLoading : ''
                            }
                            onChange={(port: PortDto) => {
                              setRate((rate) => {
                                rate.portOfLoadingId = port?.portId?.toString();
                                rate.portOfLoading = port;
                                return { ...rate };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                          <RateForm.CountryOfOrigin
                            defaultValue={
                              rate?.countryOfOrigin ? rate.countryOfOrigin : ''
                            }
                            onChange={(country: CountryDto) => {
                              setRate((rate) => {
                                rate.countryOfOriginCode = country?.countryCode;
                                rate.countryOfOrigin = country;
                                return { ...rate };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="row">
                        <div className="col-3">
                          <h5>Destination</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <RateForm.PortOfUnloading
                            defaultValue={
                              rate?.portOfUnloading ? rate.portOfUnloading : ''
                            }
                            onChange={(port: PortDto) => {
                              setRate((rate) => {
                                rate.portOfUnloadingId = port?.portId?.toString();
                                rate.portOfUnloading = port;
                                return { ...rate };
                              });
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <RateForm.PortOfDelivery
                            defaultValue={
                              rate?.portOfDelivery ? rate.portOfDelivery : ''
                            }
                            onChange={(port: PortDto) => {
                              setRate((rate) => {
                                rate.portOfDeliveryId = port?.portId?.toString();
                                rate.portOfDelivery = port;
                                return { ...rate };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                          <RateForm.CountryOfDestination
                            defaultValue={
                              rate?.countryOfDestination
                                ? rate.countryOfDestination
                                : ''
                            }
                            onChange={(country: CountryDto) => {
                              setRate((rate) => {
                                rate.countryOfDestinationCode =
                                  country?.countryCode;
                                rate.countryOfDestination = country;
                                return { ...rate };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <RateForm.CommodityTypeSelect
                        defaultValue={
                          rate?.commodityType ? rate.commodityType : ''
                        }
                        onChange={(type: CommodityTypeDto) => {
                          setRate((rate) => {
                            rate.commodityTypeId = type?.commodityTypeId;
                            rate.commodityType = type;
                            return { ...rate };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3">
                      <RateForm.IsHazardous
                        defaultValue={rate?.isHazardous ?? false}
                      />
                    </div>
                  </div>

                  <hr className="mb-7" />

                  <TariffEdit />

                  <div className="row mt-5">
                    <div className="col-12 col-md-6 col-lg-6"></div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <Button
                        form={'rate-form'}
                        name="save-rate"
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Rate
                      </Button>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <Button
                        type="button"
                        color="secondary"
                        onClick={onCancel}
                        className="w-100 mt-3 mt-md-0 mt-lg-0"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>

              {/* contract tab */}
              <TabPanel>
                <Panel className="m-3">
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-4">
                      <RateForm.Date
                        header={'Effective Date'}
                        nameId={'effectiveDate'}
                        defaultValue={rate?.effectiveDate}
                        onChange={(value) => {
                          setRate((rate) => {
                            if (!rate) {
                              rate = initialState;
                            }
                            rate.effectiveDate = new Date(value);
                            return rate;
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                      <RateForm.Date
                        header={'Expiration Date'}
                        nameId={'expirationDate'}
                        defaultValue={rate?.expirationDate}
                        onChange={(value) => {
                          setRate((rate) => {
                            if (!rate) {
                              rate = initialState;
                            }
                            rate.expirationDate = new Date(value);
                            return rate;
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <RateForm.ContractNumber />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <RateForm.AmendmendNumber />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12 col-md-6 col-lg-6"></div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <Button
                        form={'rate-form'}
                        name="save-rate-contract"
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Rate
                      </Button>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary mt-3 mt-md-0 mt-lg-0"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>

              {/* Notes tab */}
              <TabPanel>
                <Panel className="m-3">
                  <div className="row">
                    <div className="col-12">
                      <RateForm.Notes />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12 col-md-6 col-lg-6"></div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <Button
                        form={'rate-form'}
                        name="save-rate-notes"
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Rate
                      </Button>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary mt-3 mt-md-0 mt-lg-0"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>

              {/* Additional tab */}
              <TabPanel forceRender={!isCreateMode}>
                <RateForm.CustomValues
                  customFields={customFields}
                  entityType={CustomFieldEntityType.Rate}
                  defaultValue={isCreateMode ? {} : rate?.customValues}
                  onChange={(result) => {
                    setRate((rate) => {
                      rate.customValues = result;
                      return { ...rate };
                    });
                  }}
                  saveButtonRenderCondition={
                    userHas(UPDATE_RATE_LINK_KEY, rate?.links) || isCreateMode
                  }
                  isSending={isSending}
                  formName={'rate-form'}
                  entityName={'Rate'}
                  onCancel={onCancel}
                  context={context}
                />
              </TabPanel>
            </Tabs>
          );
        }}
      </FormContext.Consumer>
    </RateForm>
  );
};
