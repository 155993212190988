import {
  ScanningResult,
  onScannerResult,
} from '../../../modules/barcodeScanner/scanner.store';

// https://pe.usps.com/text/dmm300/204.htm#:~:text=An%20Intelligent%20Mail%20barcode%20consists,routing%20code%20(when%20required).
export function extractTrackingCodeFromUSPSBarcode(
  payload: ScanningResult,
): ScanningResult {
  if (payload.data.indexOf('\u2194') > -1) {
    // split string by left-right arrow ↔
    payload.data = payload.data.split('\u2194')[1];
  } else if (payload.data.indexOf('\u001d') > -1) {
    // split string by left-right arrow ↔
    payload.data = payload.data.split('\u001d')[1];
  } else if (payload.data.length === 34 || payload.data.length === 30) {
    // split string by length
    const data = payload.data.slice(8);
    // check for tracking application identifier 92, 93, 94 or 95
    if (['92', '93', '94', '95'].includes(data.slice(0, 2))) {
      payload.data = data;
    }
  }
  return payload;
}

export const UspsBarcodeScannerHook = () => {
  onScannerResult.watch((payload) => {
    // process fedex label
    extractTrackingCodeFromUSPSBarcode(payload);
  });
};
