import { Dialog } from '../../common/components/dialog/dialog.component';
import { ChargeEdit } from './charge-edit.component';
import { ChargeDto, ChargeType } from '../../../models/data.models';

export type ChargeDialogProperties = {
  chargeId?: number | null;
  charge?: ChargeDto;
  title: string;
  chargeType?: ChargeType | null;
  className?: string;
  isEditMode?: boolean | undefined;
  isCreateModeForTransaction?: boolean | undefined;
  saveButtonRenderCondition?: boolean;
  charges?: ChargeDto[] | undefined;
};

export class ChargeDialog extends Dialog<ChargeDialogProperties> {
  constructor(props: ChargeDialogProperties) {
    super(props);
    this.isEditMode = props.isEditMode;
    this.onChargeSaved = this.onChargeSaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onChargeSaved = (charge: ChargeDto) => {
    this.close(charge);
  };

  onCancel = () => {
    this.close(null);
  };

  isEditMode: boolean | undefined = false;

  renderContent(): any {
    const {
      chargeId,
      chargeType,
      charge,
      isCreateModeForTransaction,
      saveButtonRenderCondition,
      charges,
    } = this.props as ChargeDialogProperties;
    return (
      <ChargeEdit
        chargeId={chargeId}
        chargeType={chargeType}
        defaultCharge={charge}
        onChargeCreated={this.onChargeSaved}
        onChargeUpdated={this.onChargeSaved}
        isEditMode={this.isEditMode}
        isCreateModeForTransaction={isCreateModeForTransaction}
        saveButtonRenderCondition={saveButtonRenderCondition}
        onCancel={this.onCancel}
        charges={charges}
      />
    );
  }
}
