import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { BILLS_CREATE_PATH, BILLS_LIST_PATH } from '../bills.route';
import { BillEdit } from '../components/bill-edit.component';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_ACCOUNTINGTRANSACTION_LINK_KEY } from '../../accountingTransactions/accountingTransactions.service';
import { userHas } from '../../auth/auth.store';

export type BillsScreenProps = { pageTitle: string; group: string };

export const BillsScreen = ({ pageTitle, group }: BillsScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, accountingTransactionId } = useParams();

  function createPath() {
    return generatePath(INTERNAL_PATH + BILLS_CREATE_PATH, {
      organizationId,
    });
  }
  const onAccountingTransactionCreated = () => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'bill-created',
      type: 'success',
      autoHide: true,
      message: 'Bill successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onAccountingTransactionUpdated = () => {
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'bill-updated',
      type: 'success',
      autoHide: true,
      message: 'Bill successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + BILLS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };
  return (
    <InternalLayout
      group={group}
      title={'Bills'}
      createPath={
        userHas(CREATE_ACCOUNTINGTRANSACTION_LINK_KEY) ? createPath() : null
      }
      createLabel={'Bill'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <BillEdit
        accountingTransactionId={accountingTransactionId}
        onAccountingTransactionUpdated={onAccountingTransactionUpdated}
        onAccountingTransactionCreated={onAccountingTransactionCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
