import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetOrdersQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import {
  ORDER_CREATE_PATH,
  ORDER_EDIT_PATH,
  ORDER_LIST_PATH,
} from '../order.route';
import { OrdersList } from '../components/orders-list.component';
import { CREATE_ORDER_LINK_KEY, GetOrderParams } from '../orders.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';
import { getOrdersDefaultColumns, ordersStore } from '../orders.store';
import { useStore } from 'effector-react';

export type OrdersListScreenProps = { pageTitle: string; group: string };

export const OrdersListScreen = ({
  pageTitle,
  group,
}: OrdersListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(ordersStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `orderType:${OrderTypes.Order}`,
    search = '',
    view = '',
  } = useQuery() as GetOrdersQuery;
  const navigate = useNavigate();

  const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (orderParams: GetOrderParams) => {
    const orderId = orderParams.orderId;
    const createOrEdit = orderId === 0 ? ORDER_CREATE_PATH : ORDER_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      orderId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getOrdersDefaultColumns();
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}&view=${view}`,
    );
  };

  return (
    <InternalLayout
      group={group}
      title={'Orders'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
      createLabel={'Order'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrdersList
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onViewChanged={onViewChanged}
        onLimitChanged={onLimitChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
        filter={filter}
      />
    </InternalLayout>
  );
};
