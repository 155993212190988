import React, { useEffect, useState } from 'react';
import { CommodityStatusDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import {
  getCommodityStatusFx,
  getCommodityStatusesFx,
} from '../commodityStatuses.store';
import { showDialog } from '../../common/dialog.store';
import { CommodityStatusDialog } from './commodityStatus.dialog';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { COMMODITYSTATUS_ENTITY_NAME } from '../commodityStatuses.service';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export const SelectCommodityStatusPaginate = ({
  id = 'statusId',
  nameId = 'statusName',
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  defaultValueFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeStatus = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  readonly = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Commodity Status',
}): JSX.Element => {
  const [value, setValue] = useState(null);
  const context = useFormikContext();
  useEffect(() => {
    if (
      (!Number.isNaN(selectedValue) && selectedValue > 0) ||
      selectedValue?.length > 0
    ) {
      getCommodityStatusFx({ statusId: selectedValue }).then(
        (data) => setValue(data),
        () => {},
      );
    } else {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  useEffect(() => {
    if (!selectedValue && required) {
      setDefaultCommodityStatus();
    }
  }, []);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseCommodityStatusDtoPagedResult: CommodityStatusDtoPagedResult = await getCommodityStatusesFx(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseCommodityStatusDtoPagedResult.items,
      hasMore: responseCommodityStatusDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeStatus === 'function') {
      onChangeStatus(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const setDefaultCommodityStatus = () => {
    getCommodityStatusesFx({
      limit: 1,
      offset: 0,
      sort,
      filter: defaultValueFilter,
    }).then(
      (statuses) => {
        if (statuses.items.length > 0) {
          const defaultStatus = statuses.items[0];
          setValue(defaultStatus);
          onSelect(defaultStatus);
        }
      },
      () => {},
    );
  };

  const onSelect = (status) => {
    onChange(status, context);
    if (context) {
      if (isMulti === true) {
        if (status?.length > 0) {
          context.setFieldValue(id, status?.length);
          context.setFieldError(id, undefined);
        } else {
          if (required) {
            context.setFieldValue(id, '');
          }
        }
      } else {
        context.setFieldValue(id, status?.statusId);
        context.setFieldValue(nameId, status?.statusName);
        context.setFieldError(id, undefined);
        setValue({
          statusId: status?.statusId,
          statusName: status?.statusName,
        });
        if (required && !status) {
          context.setFieldValue(id, '');
        }
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onOrderStatusEditIconClick = () => {
    if (value?.orderStatusId) {
      showDialog({
        dialog: CommodityStatusDialog,
        props: {
          statusId: value.statusId,
          title: 'Update Commodity Status',
        },
      }).then(
        (division) => {
          if (division !== null) {
            getCommodityStatusFx({
              statusId: Number(value.statusId) || null,
            }).then(
              (commodityStatusDto) => {
                onSelect(commodityStatusDto);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  if (useContext) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={COMMODITYSTATUS_ENTITY_NAME}
              canCreateNewEntity={false}
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: CommodityStatusDialog,
                  props: {
                    title: showCreateDialogTitle,
                    statusId: 0,
                  },
                }).then(
                  (commodityStatus) => {
                    setClearLoadOptionsCache(!clearLoadOptionsCache);
                    return commodityStatus;
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.statusId}
              getOptionLabel={(option) => `${option?.statusName}`}
              onChange={onSelect}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              isDisabled={isDisabled}
              isClearable={readonly ? false : isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={readonly ? false : isSearchable}
              placeholder={placeholder}
              menuIsOpen={readonly ? false : undefined}
              classNamePrefix={classNamePrefix}
              components={{
                IndicatorSeparator: () => null,
              }}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={COMMODITYSTATUS_ENTITY_NAME}
        canCreateNewEntity={false}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: CommodityStatusDialog,
            props: {
              title: showCreateDialogTitle,
              statusId: 0,
            },
          }).then(
            (commodityStatus) => {
              setClearLoadOptionsCache(!clearLoadOptionsCache);
              return commodityStatus;
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.statusId}
        getOptionLabel={(option) => `${option?.statusName}`}
        onChange={(division?: any) => {
          onChange(division, context);
        }}
        onFocus={clearMessages}
        classNamePrefix={classNamePrefix}
        menuIsOpen={readonly ? false : undefined}
        isDisabled={isDisabled}
        isClearable={readonly ? false : isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={readonly ? false : isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
