import React, { createContext, useState } from 'react';
import * as Yup from 'yup';

import {
  CommodityDto,
  LocationType,
  OrderDto,
  OrganizationDto,
  UserDto,
} from '../../../models/data.models';
import { PackingForm } from './packing.form';
import {
  onMoveToShipping,
  shippingPalletInitialState,
  shippingPalletSchema,
} from '../../warehouseReceipts/components/common/shippingPallet';
import { PatchOrderParams } from '../../orders/orders.service';
import { patchOrder } from '../../orders/orders.store';
import { FormContext } from '../../common/components/form/form.component';
import { CatchSubmitActionHOC } from './catchSubmitAction.hoc';
import {
  getPackingScanInitialState,
  onPackingScanFragment,
  packingScanSchema,
} from './packingScanFragment.component';
import { packageSchema } from '../components/common/dimensionsUpdate.component';
import {
  getOrderInitialState,
  onSendToService,
  palletInitialState,
  palletSchema,
} from '../../warehouseReceipts/components/common/pallet.formik.component';
import { updateCommodity } from '../../commodities/commodities.store';
import { scanTrackingNumber } from '../../warehouseReceipts/components/common/trackingNumbers.formik.component';
import { authStore } from '../../auth/auth.store';
import { organizationsStore } from '../../organization/organization.store';
import { FormikContextType } from 'formik';
import {
  ACTION_DELETE_TRACKING_NUMBER,
  ACTION_PACKAGE_SCAN,
  ACTION_PACKING_SCAN_TRACKING_NUMBER,
  ACTION_SHIPPING_PALLET,
  ACTION_UPDATE_COMMODITY,
  PACKING_CURRENT_LOCATION_TYPE,
  PACKING_LOCATION_TYPE,
} from '../../constants/api';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { addMessage, Message } from '../../common/messages.store';
import { deleteCommodityTrackingNumber } from '../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import { ReportProblemReasonDialog } from '../../cargoMovement/picking/reportProblemReason.dialog';

export const setSubmitAction = (
  formikContext: FormikContextType<any>,
  action: string,
  payload?: any,
) => {
  formikContext.setErrors({});
  formikContext.setFieldValue('_payload', payload);
  formikContext.setFieldValue('_submitAction', action);
};

export const linkCommodities = (
  createdOrder: OrderDto,
  commodities?: CommodityDto[],
) => {
  const commodityParams = commodities?.map((commodity) => {
    return {
      commodityId: commodity.commodityId,
      orderId: createdOrder?.orderId,
    };
  });

  const patchItems = [];
  const commodityIds = createdOrder.commodities?.map(
    (commodity) => commodity.commodityId,
  );

  commodityParams.forEach((commodity) => {
    if (!commodityIds.includes(commodity.commodityId)) {
      patchItems.push({
        path: '/OrderCommodities/-',
        op: 'add',
        value: commodity,
      });
    }
  });

  const patchOrderParams: PatchOrderParams = {
    resource: { links: createdOrder?.links },
    patchOrderCommand: {
      patchItems: patchItems,
    },
  };

  return patchOrder(patchOrderParams);
};

export type PackingWizardProps = {
  onCancel: () => void;
};

export const PackingContext = createContext(null);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const initialStateFactory = () => {
  return {
    ...shippingPalletInitialState(),
    ...getPackingScanInitialState(),
    ...palletInitialState(PACKING_LOCATION_TYPE),
    purchase: getOrderInitialState(),
    parcelShipment: getOrderInitialState(),
    _submitAction: '',
    destination: '',
  };
};

const validationSchema = Yup.object().shape({
  ...packingScanSchema,
  ...palletSchema(PACKING_LOCATION_TYPE, PACKING_CURRENT_LOCATION_TYPE),
  ...shippingPalletSchema,
  ...packageSchema,
});

export const PackingWizard = ({ onCancel = null }: PackingWizardProps) => {
  const { user: currentUser } = authStore.getState();
  const { currentOrganization } = organizationsStore.getState();

  const [step, setStep] = useState<number>(0);

  const [initialState, setInitialState] = useState<any>(initialStateFactory());
  const [notification, setNotification] = useState<string>('');
  const roundToDecimalPlaces = (
    number: number,
    decimalPlaces: number,
  ): number => {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.ceil(number * multiplier) / multiplier;
  };

  const onSubmit = (
    handleNext: (resetForm: () => void, setNotification?: () => void) => any,
    handleBack: (resetForm?: () => void, setNotification?: () => void) => any,
    currentLocation: LocationType,
    currentUser?: UserDto,
    currentOrganization?: OrganizationDto,
  ) => async (data: any, formikHandlers: any) => {
    formikHandlers.setFieldValue('_submitAction', '');

    if (data._submitAction === ACTION_PACKAGE_SCAN) {
      await onPackingScanFragment(handleNext(formikHandlers.resetForm))(data, {
        setFieldValue: formikHandlers.setFieldValue,
      });
    }

    if (data._submitAction === ACTION_UPDATE_COMMODITY) {
      data.purchase.commodities[0].weight = roundToDecimalPlaces(
        data.purchase.commodities[0].weight,
        1,
      );
      await updateCommodity(data.purchase.commodities[0]);
    }

    if (data._submitAction === ACTION_PACKING_SCAN_TRACKING_NUMBER) {
      await scanTrackingNumber(data, {
        setFieldValue: formikHandlers.setFieldValue,
      });
    }

    if (data._submitAction === ACTION_DELETE_TRACKING_NUMBER) {
      const commodityTrackingNumber = {
        ...data._payload.commodityTrackingNumber,
      };
      const confirmation = await showDialog({
        dialog: Confirm,
        props: {
          title: `Delete ${commodityTrackingNumber.trackingNumber} Tracking Number`,
          message: 'Are you sure you want to delete?',
          className: 'delete-modal',
        },
      });
      if (confirmation) {
        if (data.trackingNumbers.length === 1) {
          const message: Message = {
            id: 'last-tracking-number',
            type: 'warning',
            autoHide: true,
            message: 'There must be at least one tracking number!',
          };
          addMessage(message);
        } else {
          if (commodityTrackingNumber.commodityTrackingNumberId > 0) {
            try {
              await deleteCommodityTrackingNumber(commodityTrackingNumber);
              const message: Message = {
                id: 'tracking-number-deleted',
                type: 'success',
                autoHide: true,
                message: 'Tracking Number deleted successfully!',
              };
              addMessage(message);
              const clearedTrakingNumbers = data?.trackingNumbers.filter(
                (value) =>
                  value.commodityTrackingNumberId !==
                  commodityTrackingNumber.commodityTrackingNumberId,
              );
              formikHandlers.setFieldValue(
                'trackingNumbers',
                clearedTrakingNumbers,
              );
            } catch {
              const message: Message = {
                id: 'tracking-number-deleted',
                type: 'danger',
                autoHide: true,
                message: "Tracking Number was'nt deleted!",
              };
              addMessage(message);
            }
          } else {
            const clearedTrakingNumbers = data?.trackingNumbers.filter(
              (value) =>
                value.commodityTrackingNumberId !==
                commodityTrackingNumber.commodityTrackingNumberId,
            );
            formikHandlers.setFieldValue(
              'trackingNumbers',
              clearedTrakingNumbers,
            );
            const message: Message = {
              id: 'tracking-number-deleted',
              type: 'success',
              autoHide: true,
              message: 'Tracking Number deleted successfully!',
            };
            addMessage(message);
          }
        }
      }
    }
    const onSendToServiceComplete = async (
      data: any,
      handleBack?: () => void,
    ) => {
      showDialog({
        dialog: ReportProblemReasonDialog,
        props: {
          className: 'modal-report-damaged',
          commodities: data?.commodities,
        },
      }).then(async () => {
        handleBack();
      });
    };

    if (data._submitAction === currentLocation) {
      await onSendToService(
        async (data?: any, handleBack?: () => void) => {
          handleBack();
        },
        handleBack(formikHandlers.resetForm, () =>
          setNotification(
            `Parcel ${data?.parcelShipment?.trackingNumber} was sent to Service. Pallet Number #${data?.ServicePalletNumber}`,
          ),
        ),
        PACKING_LOCATION_TYPE,
        currentLocation,
        currentUser,
        currentOrganization,
      )(data);
    }

    if (data._submitAction === ACTION_SHIPPING_PALLET) {
      await onMoveToShipping(
        handleNext(formikHandlers.resetForm, () =>
          setNotification(
            `Parcel ${data?.parcelShipment?.trackingNumber} was sent to Shipping. Pallet Number #${data?.shippingPallet}`,
          ),
        ),
        currentUser,
      )(data, { setFieldValue: formikHandlers.setFieldValue });
    }
  };

  const handleNext = (
    resetForm: () => void,
    setNotification?: () => void,
  ) => () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      setInitialState(initialStateFactory());
      resetForm();
      setStep(0);
    }

    if (setNotification) {
      setNotification();
    }

    scrollToTop();
  };

  const handleBack = (
    resetForm: () => void,
    setNotification?: () => void,
  ) => () => {
    if (step >= 1) {
      setStep(step - 1);
      if (step === 1) {
        setInitialState(initialStateFactory());
        resetForm();
      }
    }

    if (setNotification) {
      setNotification();
    }

    scrollToTop();
  };

  return (
    <PackingForm
      id={'ShippingForm'}
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onSubmit(
        handleNext,
        handleBack,
        LocationType.Packing,
        currentUser,
        currentOrganization,
      )}
    >
      <FormContext.Consumer>
        {({ resetForm }) => (
          <CatchSubmitActionHOC
            step={step}
            onCancel={onCancel}
            handleNext={handleNext(resetForm)}
            handleBack={handleBack(resetForm)}
            setInitialState={setInitialState}
            notification={notification}
          />
        )}
      </FormContext.Consumer>
    </PackingForm>
  );
};
