import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDocumentEdit } from './orderDocumentEdit';
import {
  LinkResourceBaseDto,
  OrderDocumentDto,
} from '../../../models/data.models';

export type OrderDocumentDialogProperties = {
  orderDocumentId?: number | null;
  order?: LinkResourceBaseDto | null;
  title: string;
};

export class OrderDocumentDialog extends Dialog<OrderDocumentDialogProperties> {
  constructor(props: OrderDocumentDialogProperties) {
    super(props);
    this.onOrderDocumentSaved = this.onOrderDocumentSaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onOrderDocumentSaved = (orderDocument: OrderDocumentDto) => {
    this.close(orderDocument);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { orderDocumentId } = this.props as OrderDocumentDialogProperties;
    const { order } = this.props as OrderDocumentDialogProperties;
    return (
      <OrderDocumentEdit
        orderDocumentId={orderDocumentId}
        order={order}
        onOrderDocumentCreated={this.onOrderDocumentSaved}
        onOrderDocumentUpdated={this.onOrderDocumentSaved}
        onCancel={this.onCancel}
      />
    );
  }
}
