import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CommodityTypesListScreen } from './screens/commodityType-list.screen';
import { CommodityTypeScreen } from './screens/commodityType.screen';

export const COMMODITY_TYPES_LIST_PATH = '/commodityTypes';
export const COMMODITY_TYPES_EDIT_PATH = '/commodityTypes/:commodityTypeId';
export const COMMODITY_TYPES_CREATE_PATH = '/commodityTypes/0';

export const CommodityTypeRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CommodityTypesListScreen
              pageTitle="Commodity Types"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CommodityTypeScreen
              pageTitle="Add New Commodity Type"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':commodityTypeId'}
        element={
          <PrivateRoute>
            <CommodityTypeScreen
              pageTitle="Update Commodity Type"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
