import React, { useEffect, useState } from 'react';
import {
  ContactDto,
  ContactStatusStage,
  ContactType,
  LocationType,
} from '../../../../models/data.models';
import { FiEdit2 } from 'react-icons/fi';
import { MdOutlineClose } from 'react-icons/md';
import { showDialog } from '../../../common/dialog.store';
import { CustomerDialog } from '../customer.dialog';
import { getContact } from '../../../contacts/contacts.store';
import { Spinner } from '../../../common/components/spinner/spinner';
import { StatusBadge } from '../../../common/components/status-badge/status-badge';
import { OrderForm } from '../../../orders/components/order.form';
import { FormikProps, FormikValues } from 'formik';
import { UnassignedCheckbox } from '../../../warehouseReceipts/components/common/unassignedCheckbox';

type CustomerInformationProps = {
  contactId?: number;
  context?: FormikProps<FormikValues>;
  setOrderContextValues?: any;
  isCustomerAccountNotAssigned?: boolean;
  trackingNumber?: string;
  additionalLoading?: boolean;
};

const labelStyles = {
  fontWeight: 600,
  lineHeight: '28px',
  fontSize: '16px',
};

export const CustomerInformation = ({
  contactId,
  context,
  setOrderContextValues,
  isCustomerAccountNotAssigned,
  trackingNumber,
  additionalLoading = false,
}: CustomerInformationProps) => {
  const [contactData, setContactData] = useState<ContactDto | null>(null);
  const [customerId, setCustomerId] = useState<number | null>(contactId);
  const [getContactIsLoading, setGetContactIsLoading] = useState<boolean>(
    false,
  );

  useEffect(() => {
    if (contactId) {
      handleGetContact(contactId);
    }
  }, [contactId]);

  const handleGetContact = (contactId) => {
    setGetContactIsLoading(true);
    getContact({
      contactId: contactId,
    })
      .then(
        (contact) => {
          setContactData(contact);
        },
        () => {},
      )
      .finally(() => {
        setGetContactIsLoading(false);
      });
  };

  const handleCustomerEdit = () => {
    showDialog({
      dialog: CustomerDialog,
      props: {
        contactId: contactId,
        title: `Update ${ContactType.Customer}`,
        disableDots: true,
      },
    }).then(
      () => {
        handleGetContact(contactId);
      },
      () => {},
    );
  };

  const getOptionLabel = (selectedOption) => {
    return selectedOption['accountNumber'];
  };

  const onSelectCustomer = (data?: ContactDto) => {
    context?.setFieldValue('accountNumber', data?.accountNumber);
    context?.setFieldValue('billToContactId', data?.contactId);
    setOrderContextValues((prevData) => ({
      ...prevData,
      billToContactId: data?.contactId ?? null,
    }));
    setCustomerId(data?.contactId ?? null);
    const putAwayPalletInput = document.querySelector(
      `[name="${LocationType.PutAway}PalletNumber"]`,
    ) as HTMLInputElement;
    putAwayPalletInput?.focus();
  };

  const renderCustomerInfoContent = () => {
    if (getContactIsLoading || additionalLoading) {
      return <Spinner />;
    } else if (contactId) {
      return (
        <>
          <div className="row mt-2 d-flex justify-content-between align-items-start">
            <div className="col-6">
              <div className="d-flex">
                <h3 className="text-sublabel">{`${
                  contactData?.name ?? '-'
                }`}</h3>
                <FiEdit2
                  className={'ml-3 buttonIcon'}
                  color="gray"
                  size={'15px'}
                  onClick={handleCustomerEdit}
                />
              </div>
              <h3 className="text-sublabel">
                {`${contactData?.accountNumber ?? '-'}`}
              </h3>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <StatusBadge
                label={contactData?.contactStatus?.statusDescription ?? '-'}
                stage={contactData?.contactStatus?.statusStage}
                enumType={ContactStatusStage}
                style={{ marginRight: 0 }}
              />
            </div>
          </div>
          {trackingNumber && (
            <>
              <h3 className="text-label my-3">Tracking Numbers</h3>
              <div className="row mt-2 d-flex justify-content-between">
                <div className="col-6">
                  <h3 className="text-sublabel">{`${
                    trackingNumber ?? '-'
                  }`}</h3>
                </div>
              </div>
            </>
          )}
        </>
      );
    } else {
      return (
        <div>
          <div
            className="row py-2 pl-2 justify-content-between align-items-center"
            style={{ paddingRight: '8px' }}
          >
            <div className="d-flex align-items-center">
              <MdOutlineClose className="text-danger" />
              &nbsp;
              <h3 className="text-danger m-0">Customer Not Found</h3>
            </div>
            <div className="cursor-pointer d-flex justify-content-end">
              <UnassignedCheckbox
                selectedCustomerId={customerId}
                onChange={onSelectCustomer}
                isInvalid={isCustomerAccountNotAssigned}
              />
            </div>
          </div>
          <div className="pt-2">
            <OrderForm.BillToContactSelect
              id={'billToContactId'}
              header={'Add Customer Account Number'}
              placeholder={'Search account number'}
              contactTypes={[ContactType.Customer]}
              selectedFilter={`contactType: ${ContactType.Customer}`}
              showContactName={true}
              required={true}
              nameId={'billToContactName'}
              labelStyles={labelStyles}
              canCreateNewEntity={false}
              canUpdateEntity={true}
              getOptionLabel={getOptionLabel}
              onChange={(data?: ContactDto) => onSelectCustomer(data)}
              defaultValue={customerId}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <h3 className="text-label mb-3">Customer Information</h3>
      {renderCustomerInfoContent()}
    </>
  );
};
