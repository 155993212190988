import React from 'react';
import classNames from 'classnames';

export const ActionBarComponent = ({
  children,
  buttonText,
  className = '',
  style = {},
}) => {
  const getChildren = () => {
    if (Array.isArray(children)) {
      return children.map((element, index) => {
        return (
          <span key={index} title={element.props.title}>
            {React.cloneElement(element, {
              ...element.props,
              className: classNames(element.props.className, 'dropdown-item'),
            })}
          </span>
        );
      });
    } else {
      return (
        <span title={children.props.title}>
          {React.cloneElement(children, {
            ...children.props,
            className: classNames(children.props.className, 'dropdown-item'),
          })}
        </span>
      );
    }
  };

  return (
    <div className="btn-group">
      <button
        type="button"
        className={`btn btn-secondary dropdown-toggle ${className}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={style}
        data-cy="button-dropdown-menu"
      >
        {buttonText}
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        style={{
          minWidth: 'fit-content',
        }}
      >
        {getChildren()}
      </div>
    </div>
  );
};
