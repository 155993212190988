import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CarrierPayForm } from './carrierPay.form';
import {
  AccountingAccountDto,
  DivisionDto,
  ReceiveCarrierPayCommandValues,
  VisibleTransactions,
} from '../../../models/data.models';
import { Panel } from '../../common/components/panel/panel.component';
import { ReceiveCarrierPayFormDto } from '../../../models/custom.models';
import { FormContext } from '../../common/components/form/form.component';
import { receiveCarrierPay } from '../carrierPayManager.store';
import { getFormattedPrice } from '../../../utils/formatting.utils';
import { currencyStore } from '../../currencies/currencies.store';
import * as Yup from 'yup';
import { authStore } from '../../auth/auth.store';
import { validatePositiveNumberInput } from '../../../utils/helper.utils';

export type ReceiveCarrierPayProps = {
  balanceOwning: number;
  orderCarrierId: number;
  transactionNumber: string;
  onCarrierPayReceived?: () => void;
  onCancel?: () => void;
};

const carrierPaySchema = Yup.object().shape({
  transactionNumber: Yup.string()
    .required("Can't be blank")
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === '' ||
        value === undefined
      );
    })
    .test('length', 'Max length is 20', (value) => {
      return value === undefined || value.length <= 20;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  receivedDate: Yup.date().required("Can't be blank").nullable(true),
  accountId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

export const ReceiveCarrierPay = ({
  orderCarrierId,
  balanceOwning,
  onCarrierPayReceived = () => {},
  transactionNumber,
  onCancel = () => {},
}: ReceiveCarrierPayProps) => {
  const { user: currentUser } = authStore.getState();

  const isCreateMode = true;

  const [isLoading, setIsLoading] = useState(true);
  const [accountingAccount, setAccountingAccount] = useState(null);

  const { defaultCurrency } = currencyStore?.getState();

  const [
    carrierPayContextFormValue,
    setCarrierPayContextFormValue,
  ] = useState<ReceiveCarrierPayFormDto | null>({
    transactionNumber,
    receivedDate: new Date(),
    balanceOwning: getFormattedPrice(
      balanceOwning,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    ),
    accountId: null,
    accountName: null,
    accountType: null,
    divisionId: null,
    divisionName: null,
  });

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    }
  }, []);

  const onSubmit = (data: ReceiveCarrierPayFormDto) => {
    const receiveCarrierPayCommandValues: ReceiveCarrierPayCommandValues = {
      ...data,
      orderCarrierId,
    };
    return receiveCarrierPay(receiveCarrierPayCommandValues).then(
      () => {
        onCarrierPayReceived();
      },
      () => {},
    );
  };

  const nextStep = () => {
    setStep(1);
  };

  const showMessage = () => {
    window.alert(
      'Please either adjust the payable rate on the load confirmation to match the carrier invoice, or contact the carrier to discuss.',
    );
  };

  const onReceivedDateChange = (date: Date) => {
    carrierPayContextFormValue.receivedDate = date;
    setCarrierPayContextFormValue({ ...carrierPayContextFormValue });
  };

  const onAccountChange = (account: AccountingAccountDto) => {
    carrierPayContextFormValue.accountId = account?.accountId;
    carrierPayContextFormValue.accountName = account?.accountName;
    carrierPayContextFormValue.accountType = account?.accountType;
    setCarrierPayContextFormValue({ ...carrierPayContextFormValue });
  };

  const onDivisionChange = (division: DivisionDto) => {
    carrierPayContextFormValue.divisionId = division?.divisionId;
    carrierPayContextFormValue.divisionName = division?.divisionName;
    setCarrierPayContextFormValue({ ...carrierPayContextFormValue });
  };

  return (
    <div className={'order-edit-form'}>
      <Panel className="m-3">
        <div className="row">
          <div className="col-12">
            <CarrierPayForm
              id={'carrier-pay-form'}
              initialValues={carrierPayContextFormValue}
              onSubmit={onSubmit}
              validationSchema={carrierPaySchema}
              // innerRef={orderFormRef}
            >
              <FormContext.Consumer>
                {(context) => (
                  <>
                    {step === 0 ? (
                      <>
                        <p>Does the carrier bill match the agreed amount?</p>
                        <p style={{ fontSize: '1.375rem' }}>
                          {context.values.balanceOwning}
                        </p>
                        <div className="row">
                          <div className="ml-auto col-4">
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-block"
                              onClick={onCancel}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="ml-auto col-4">
                            <Button
                              type="button"
                              color="primary"
                              className="btn-block"
                              onClick={showMessage}
                            >
                              No
                            </Button>
                          </div>
                          <div className="ml-auto col-4" data-cy={'yes'}>
                            <Button
                              form={'carrier-pay-form'}
                              type="button"
                              color="primary"
                              className="btn-block"
                              onClick={nextStep}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <CarrierPayForm.TransactionNumber
                          onKeyDown={validatePositiveNumberInput}
                        />
                        <CarrierPayForm.ReceivedDate
                          defaultValue={
                            carrierPayContextFormValue?.receivedDate
                          }
                          onChange={onReceivedDateChange}
                        />
                        <CarrierPayForm.AccountingAccountSelect
                          id="accountId"
                          nameId="accountName"
                          header="Account"
                          required={true}
                          listHeader={'List Account'}
                          placeholder={'Select Account'}
                          defaultValue={
                            carrierPayContextFormValue &&
                            carrierPayContextFormValue.accountId &&
                            carrierPayContextFormValue.accountName &&
                            carrierPayContextFormValue.accountType
                              ? {
                                  accountId:
                                    carrierPayContextFormValue?.accountId,
                                  accountName:
                                    carrierPayContextFormValue?.accountName,
                                  accountType:
                                    carrierPayContextFormValue?.accountType,
                                }
                              : ''
                          }
                          onChange={onAccountChange}
                        />
                        <CarrierPayForm.DivisionId
                          id={'divisionId'}
                          header={'Division'}
                          required={true}
                          onChange={onDivisionChange}
                          nameId={'divisionName'}
                          defaultValue={
                            carrierPayContextFormValue &&
                            carrierPayContextFormValue.divisionId &&
                            carrierPayContextFormValue.divisionName
                              ? {
                                  divisionId:
                                    carrierPayContextFormValue?.divisionId,
                                  divisionName:
                                    carrierPayContextFormValue?.divisionName,
                                }
                              : ''
                          }
                          defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                          disabled={
                            VisibleTransactions[
                              currentUser?.visibleTransactions
                            ] !== VisibleTransactions.AllTransactions
                          }
                        />
                        <div className="row">
                          <div className="col-4 offset-4">
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-block"
                              onClick={onCancel}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="ml-auto col-4">
                            <Button
                              form={'carrier-pay-form'}
                              type="submit"
                              color="primary"
                              className="btn-block"
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </FormContext.Consumer>
            </CarrierPayForm>
          </div>
        </div>
      </Panel>
    </div>
  );
};
