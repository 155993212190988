import React, { CSSProperties } from 'react';

import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import { Pagination } from '../pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import {
  ApplyBy,
  ChargeDto,
  ChargeStatuses,
  Column,
  ItemType,
} from '../../../../models/data.models';
import { CompletionCheckbox } from '../../../parcelShipments/components/common/completionCheckbox';
import { StatusBadge } from '../status-badge/status-badge';

export type ChargeGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: any[];
  showPagination?: boolean;
  showDropdown?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onOpenInvoice?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  canDelete?: boolean;
  onLimitChange?: (limit: number) => void;
  allData?: ChargeDto[];
  isCompleteAllCharges?: boolean;
  setIsCompleteAllCharges?: any;
};

export const ChargeGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  showDropdown = true,
  onColumnsChanged = () => {},
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onOpenInvoice = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  getContactsData,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
  loading,
  canDelete,
  onLimitChange,
  allData,
  isCompleteAllCharges,
  setIsCompleteAllCharges,
}: ChargeGridProps) => {
  const colByName: { [key: string]: any } = {};
  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return onColumnsChanged(columns);
  };
  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };
  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };
  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  return (
    <div className={`grid ${className}`} style={style}>
      <div
        className={
          isDropDownList === true
            ? 'grid-toolbar d-flex flex-wrap px-3 mx-3 justify-content-center'
            : 'grid-toolbar d-flex pl-3 mx-3'
        }
      >
        {onSearch ? (
          <input
            type="search"
            className={
              (isDropDownList === true ? 'w-100 col-12 ' : ' ') +
              'form-control my-2'
            }
            placeholder="Search"
            value={decodeURIComponent(search)}
            onChange={updateSearch}
          />
        ) : null}
        {onFilter ? (
          <div
            className={
              isDropDownList === true
                ? 'dropdown dropdown-columns my-3 px-auto mx-auto w-auto col-auto'
                : 'dropdown dropdown-columns my-3 px-3 w-100'
            }
          >
            {columns.some((column) => column.showFilter) && (
              <span
                className="dropdown-toggle pointer"
                id="dropdownFilterButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <BsFilter />
                &nbsp;
                <small className="align-middle pl-1 text-uppercase">
                  Filters
                </small>
                &nbsp;
                <small className="align-middle text-primary">
                  {getReadableQueryString(filter, getFilteredRowsFilter())}
                </small>
              </span>
            )}
            {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
              <div
                className="dropdown-menu px-5"
                aria-labelledby="dropdownFilterButton"
              >
                <div>
                  <Filters
                    query={filter}
                    columns={getFilteredRowsFilter()}
                    onFilter={updateFilter}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {hideColumnsSelect ? null : (
          <div
            className={
              isDropDownList === true
                ? 'dropdown dropdown-columns my-3 px-3 mx-auto pointer col-auto'
                : 'dropdown dropdown-columns my-3 px-3 ml-auto pointer'
            }
          >
            <div
              className="dropdown-toggle"
              id="dropdownColumnsButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsListTask className="" />
              &nbsp;
              <small className="align-middle pl-1 text-uppercase">
                Columns
              </small>
            </div>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownColumnsButton"
              style={{ width: 'max-content', maxWidth: 'unset' }}
            >
              {columns
                .filter((item) => {
                  if (showAllStore) {
                    return true;
                  }
                  return rowKeys?.includes(item.name);
                })
                .map((col) => {
                  return (
                    <label key={col.name} className="dropdown-item pointer">
                      <input
                        type="checkbox"
                        onChange={() => updateCols(col.name)}
                        defaultChecked={col.visible}
                      />{' '}
                      {col.title}
                    </label>
                  );
                })}
            </div>
          </div>
        )}
        {actionBar && <div className={'mr-3 py-2'}>{actionBar}</div>}
      </div>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white horizontal-scrollbar">
          <table className="table">
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th
                        scope="col"
                        key={col.name}
                        style={
                          col.name === 'currencySymbol'
                            ? { textAlign: 'center' }
                            : {}
                        }
                      >
                        <div className={'d-flex justify-content-center'}>
                          {col.name == 'completion' &&
                          setIsCompleteAllCharges ? (
                            <CompletionCheckbox
                              rows={allData}
                              isCompleteAll={isCompleteAllCharges}
                              setIsCompleteAll={setIsCompleteAllCharges}
                              onEdit={onEdit}
                              styles={'mx-2'}
                            />
                          ) : null}
                          <a
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                            className={
                              col.sortName
                                ? 'link w-auto'
                                : 'inactive-link w-auto'
                            }
                            onClick={() =>
                              col.sortName ? updateSort(col.name) : null
                            }
                          >
                            {col.title}
                            {sort === col.name || sort === col.sortName ? (
                              <FaArrowDown className={'ml-2'} size={12} />
                            ) : null}
                            {sort === '-' + col.name ||
                            sort === '-' + col.sortName ? (
                              <FaArrowUp className={'ml-2'} size={12} />
                            ) : null}
                          </a>
                        </div>
                      </th>
                    );
                  })}
                {showDropdown && <th colSpan={2}>&nbsp;</th>}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="m-5 text-center">
                  <h3 className="text-muted mb-4">Loading...</h3>
                </div>
              ) : (
                data.map((row, rowIndex) => {
                  const invoice = row?.accountingTransactions?.find(
                    (el) => el.accountingTransactionType === 'Invoice',
                  );
                  const getInvoiceLink = invoice?.links?.find(
                    (link) => link.type === 'GET',
                  );

                  return (
                    <tr
                      key={
                        rowKeys
                          ? rowKeys?.map((x) => row[x]).join('_')
                          : rowIndex
                      }
                      onClick={
                        rowKeys.includes('completion')
                          ? () => {
                              const completionCheckbox = document.querySelector(
                                `.checkbox-charge-complete-${rowIndex}`,
                              ) as HTMLDivElement;
                              completionCheckbox?.click();
                            }
                          : null
                      }
                    >
                      {Object.values(columns)
                        .filter((item) => {
                          if (showAllStore && item.visible) {
                            return true;
                          }
                          return item.visible && rowKeys?.includes(item.name);
                        })
                        .map((item, index) => {
                          return (
                            <td
                              key={`${rowKeys?.map((x) => row[x]).join('_')}_${
                                item.name
                              }`}
                              onClick={() => {
                                if (onSelect) {
                                  onSelect(
                                    row,
                                    rowKeys?.reduce((keyObj, field) => {
                                      return row[field];
                                    }),
                                  );
                                }
                              }}
                              className={`cursor-pointer ${
                                index == 0 && 'col-1'
                              }`}
                              style={
                                item.name === 'currencySymbol'
                                  ? {
                                      textAlign: 'center',
                                      whiteSpace: 'nowrap',
                                      width: item?.width,
                                    }
                                  : {
                                      whiteSpace: 'nowrap',
                                      width: item?.width,
                                    }
                              }
                            >
                              {typeof row[item.name] === 'boolean' ? (
                                row[item.name] ? (
                                  <>&#x2713;</>
                                ) : (
                                  <></>
                                )
                              ) : item.name == 'chargeStatus' ? (
                                <StatusBadge
                                  label={row[item.name]}
                                  stage={row[item.name]}
                                  enumType={ChargeStatuses}
                                />
                              ) : item.name == 'currencySymbol' ? (
                                <>{`${row.currencyName} (${row.currencySymbol})`}</>
                              ) : item.name == 'quantity' ? (
                                <>{`${row.quantity.toFixed(2)}`}</>
                              ) : item.name == 'formattedPrice' &&
                                row.applyBy === ApplyBy.Calculated ? (
                                <>{`${row.price * 100}%`}</>
                              ) : item.name == 'completion' ? (
                                <CompletionCheckbox
                                  row={row}
                                  onEdit={onEdit}
                                  index={rowIndex}
                                />
                              ) : (
                                <>{row[item.name]}</>
                              )}
                            </td>
                          );
                        })}
                      {showDropdown && (
                        <td>
                          <div className="dropdown">
                            <BsThreeDotsVertical
                              className="dropdown-toggle dropdown-dots-vertical pointer"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            />
                            <div
                              className="dropdown-menu dropdown-menu-grid"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {row?.chargeStatus === ChargeStatuses.Open &&
                                onDelete && (
                                  <a
                                    role="button"
                                    className="dropdown-item"
                                    onClick={() => {
                                      onDelete(row);
                                    }}
                                  >
                                    Delete
                                  </a>
                                )}
                              <a
                                role="button"
                                className="dropdown-item"
                                onClick={() => {
                                  onEdit(row);
                                }}
                              >
                                Edit
                              </a>
                              {getInvoiceLink?.href && (
                                <a
                                  role="button"
                                  className="dropdown-item"
                                  onClick={() => {
                                    onOpenInvoice(invoice);
                                  }}
                                >
                                  Open Invoice
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > limit && showPagination === true ? (
        <div className="d-flex justify-content-center grid-footer">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
            onLimitChanged={onLimitChange}
          />
        </div>
      ) : null}
    </div>
  );
};
