import React from 'react';

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg' | null;
  className?: string | null;
  color?: string | null;
  style?: {};
};

export const Spinner = ({
  size = 'md',
  className = '',
  color,
  style,
}: SpinnerProps) => {
  let sizeStyles = {};

  switch (size) {
    case 'sm':
      sizeStyles = { maxHeight: '20px', maxWidth: '20px' };
      break;
    case 'md':
      sizeStyles = { maxHeight: '40px', maxWidth: '40px' };
      break;
    case 'lg':
      sizeStyles = { maxHeight: '60px', maxWidth: '60px' };
      break;
    default:
      sizeStyles = {};
      break;
  }

  return (
    <div className={`row justify-content-center ${className}`} style={style}>
      <div
        className={`spinner-border ${color ?? 'text-primary'}`}
        style={sizeStyles}
        role="status"
      />
    </div>
  );
};
