import {
  UserDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  UserDtoPagedResult,
  CreateUserCommandValues,
  UpdateUserCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';

export const GET_USERS_LINK_KEY = 'get-users';
export const CREATE_USER_LINK_KEY = 'create-user';
export const GET_USER_LINK_KEY = 'get-user';
export const UPDATE_USER_LINK_KEY = 'update-user';
export const DELETE_USER_LINK_KEY = 'delete-user';
export const IMPORT_USERS_LINK_KEY = 'import-users';
export const EXPORT_USERS_LINK_KEY = 'export-users';
export const USER_ENTITY_NAME = 'User';

export const canSeeUserList = () => {
  const { currentOrganization } = organizationsStore.getState();
  return currentOrganization?.links?.some(
    (x: LinkDto) => x.rel === GET_USERS_LINK_KEY,
  );
};

export interface GetUserParams {
  userId: string;
}

export interface ImportUsersParams {}

export interface ExportUsersParams extends ListParams {
  fileType: FileType;
}

export const getUserRequest = async (
  resource: LinkResourceBaseDto | null,
  { userId }: GetUserParams,
): Promise<UserDto> => {
  if (resource && userId) {
    const getUserLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_USER_LINK_KEY,
    );
    if (getUserLink) {
      const result = await execLink(getUserLink, { userId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_USER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getUsersListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<UserDtoPagedResult> => {
  if (resource) {
    const getUsersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_USERS_LINK_KEY,
    );
    if (getUsersListLink) {
      const result = await execLink(getUsersListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_USERS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createUserRequest = async (
  resource: LinkResourceBaseDto,
  user: CreateUserCommandValues,
) => {
  const createUserLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_USER_LINK_KEY,
  );
  if (createUserLink) {
    const result = await execLink(createUserLink, user);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_USER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateUserRequest = async (
  resource: LinkResourceBaseDto,
  user: UpdateUserCommandValues,
) => {
  const updateUserLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_USER_LINK_KEY,
  );
  if (updateUserLink) {
    const result = await execLink(updateUserLink, user);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_USER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteUserRequest = async (user: UserDto) => {
  const deleteUserLink = user.links?.find(
    (x: LinkDto) => x.rel === DELETE_USER_LINK_KEY,
  );
  if (deleteUserLink) {
    const result = await execLink(deleteUserLink, user);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_USER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importUsersRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportUsersParams,
  file: File,
) => {
  const importUsersLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_USERS_LINK_KEY,
  );
  if (importUsersLink) {
    const result = await postFormData(importUsersLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_USERS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportUsersRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportUsersParams,
) => {
  const exportUsersLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_USERS_LINK_KEY,
  );
  if (exportUsersLink) {
    const result = await downloadLinkFile(exportUsersLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_USERS_LINK_KEY} is not allowed or missing link action`,
  );
};
