import { Dialog } from '../../common/components/dialog/dialog.component';
import { AccountingItemEdit } from './accountingItem-edit.component';
import { AccountingItemDto } from '../../../models/data.models';

export type AccountingItemDialogProperties = {
  accountingItemId?: number | null;
  title: string;
};

export class AccountingItemDialog extends Dialog<AccountingItemDialogProperties> {
  constructor(props: AccountingItemDialogProperties) {
    super(props);
    this.onAccountingItemSaved = this.onAccountingItemSaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onAccountingItemSaved = (accountingItem: AccountingItemDto) => {
    this.close(accountingItem);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { accountingItemId } = this.props as AccountingItemDialogProperties;
    return (
      <AccountingItemEdit
        accountingItemId={accountingItemId}
        onAccountingItemCreated={this.onAccountingItemSaved}
        onAccountingItemUpdated={this.onAccountingItemSaved}
        onCancel={this.onCancel}
      />
    );
  }
}
