import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { SalesPersonEdit } from './salesPerson-edit.component';

export type SalesPersonDialogProperties = {
  contactId: number;
  title: string;
};

export class SalesPersonDialog extends Dialog<SalesPersonDialogProperties> {
  constructor(props: SalesPersonDialogProperties) {
    super(props);
    this.onSalesPersonSaved = this.onSalesPersonSaved.bind(this);
  }

  onSalesPersonSaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId } = this.props as SalesPersonDialogProperties;
    return (
      <SalesPersonEdit
        contactId={contactId}
        onSalesPersonCreated={this.onSalesPersonSaved}
        onSalesPersonUpdated={this.onSalesPersonSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
