import { Ref, useEffect, useRef, useState } from 'react';
import {
  columnBuilder,
  IColumn as IColumnDef,
  ITableColumn as IColumnRender,
} from './column';
import { localized } from '../layout/component-hooks';
import logger from '../../logger';

export interface IFilterColumn {
  name: string;
  values: string[];
  component?: any;
  componentProps?: any;
}

export interface IOrderByColumn {
  name: string;
  direction: 'ASC' | 'DESC';
}

export interface ITableViewProps {
  id?: string;
  name: string;
  displayName?: string;
  columns: any;
  enableEdit?: boolean;
  childViews?: ITableViewProps[];
  filter?: string;
  filters?: IFilterColumn[];
  orderBy?: IOrderByColumn[];
  showHeader?: boolean;
  onRowClick?: (row: any) => void;
  storeView?: (view: ITableView) => void;
}

export interface ITableConfig {
  availableColumns: IColumnDef[];
  viewColumns: IColumnRender[];
  childViews?: ITableView[];
  filterColumns?: IFilterColumn[];
  filterValues?: any;
  orderByColumns?: IOrderByColumn[];
}
export interface ITableView {
  addVisibleColumn: (column: IColumnDef) => void;
  addFilterColumn: (column: IColumnDef) => void;
  removeFilterColumn: (column: IColumnDef) => void;
  id: string;
  name: string;
  displayName?: string;
  setColumns: (cols: IColumnDef[]) => void;
  enableEdit?: boolean;
  filter?: string;
  setAvailableColumns: (cols: IColumnDef[]) => void;
  showHeader?: boolean;
  onRowClick?: (row: any) => void;
  config?: ITableConfig;
  setOrderBy: (orderBys: IOrderByColumn[]) => void;
}

export const tableView = (
  props: ITableViewProps,
  refreshView?: () => void,
): ITableView => {
  const config = {
    availableColumns: [] as IColumnDef[],
    viewColumns: [] as IColumnRender[],
    childViews: [] as ITableView[],
    filterColumns: [] as IFilterColumn[],
    filterValues: {},
    orderByColumns: [] as IOrderByColumn[],
  };

  const onColumnClick = (column: IColumnDef) => {
    if (column.props?.allowOrderBy !== false) {
      const orderByColumns = config.orderByColumns ?? ([] as IOrderByColumn[]);
      const orderByColumn = orderByColumns.find((c) => c.name === column.name);

      // If the column is already in the orderByColumns, toggle the direction and remove the column
      const direction = orderByColumn
        ? orderByColumn.direction === 'ASC'
          ? 'DESC'
          : 'OFF'
        : 'ASC';

      // single column sorting
      if (direction === 'OFF') {
        config.orderByColumns = [];
      } else {
        config.orderByColumns = [{ name: column.name, direction }];
      }

      // multiple column sorting
      // if (direction === 'OFF') {
      //   // remove the column from the orderByColumns
      //   config.orderByColumns = orderByColumns.filter(
      //     (c) => c.name !== column.name,
      //   );
      // } else {
      //   // add the column to the orderByColumns
      //   config.orderByColumns = [
      //     ...orderByColumns.filter((c) => c.name !== column.name),
      //     { name: column.name, direction },
      //   ];
      // }

      props?.storeView?.(instanceView);
      refreshView?.();
    } else {
      logger.log('Column sorting is disabled for this column');
    }
  };

  const setColumns = (cols: IColumnDef[]) => {
    config.viewColumns = cols.map((column: any) => {
      // if column is a string, find the column definition in availableColumns
      if (typeof column === 'string') {
        return columnBuilder({
          name: column,
          accessor: column,
          ...config.availableColumns.find((c) => c.accessor === column),
          onColumnClick,
        });
      }

      return columnBuilder({
        ...column,
        onColumnClick,
      });
    });
    props?.storeView?.(instanceView);
  };

  const setAvailableColumns = (cols: IColumnDef[]) => {
    config.availableColumns =
      cols?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    props?.storeView?.(instanceView);

    setFilters(props.filters);
  };

  const setChildViews = (views: ITableViewProps[]) => {
    config.childViews =
      views?.map((view) => {
        return tableView(view);
      }) ?? [];
    props?.storeView?.(instanceView);
  };

  const addVisibleColumn = (column: IColumnDef) => {
    // check if column is already in viewColumns
    if (config.viewColumns.find((c) => c.name === column.name)) {
      return;
    }

    const col =
      config.availableColumns.find((c) => c.name === column.name) ?? column;

    config.viewColumns = [
      ...config.viewColumns,
      columnBuilder({ ...col, onColumnClick }),
    ];

    props?.storeView?.(instanceView);
  };

  const addFilterColumn = (column: IColumnDef) => {
    if (config.filterColumns.find((c) => c.name === column.name)) {
      return;
    }
    const col =
      config.availableColumns.find((c) => c.name === column.name) ?? column;

    if (col) {
      let colDef = null;
      if (col.props?.filter?.component) {
        colDef = {
          name: col.props?.filter?.fieldName ?? col.name,
          component: col.props?.filter?.component,
          componentProps: {
            ...col.props?.filter?.props,
            label: col.label,
            options: {
              ...col.props?.filter?.props?.options,
              allowMultiple: true,
            },
          },
          values: [],
        };
      } else {
        colDef = {
          name: col.name,
          component: 'field',
          componentProps: {
            ...col.props,
            type: col.type,
            label: col.label,
          },
          values: [],
        };
      }

      config.filterColumns = [...config.filterColumns, colDef].sort((a, b) =>
        a.name.localeCompare(b.name),
      );
    }
    props?.storeView?.(instanceView);
  };

  const removeFilterColumn = (column: IColumnDef) => {
    config.filterColumns = config.filterColumns.filter(
      (c) => c.name !== column.name,
    );
    delete config.filterValues[column.name];
    props?.storeView?.(instanceView);
  };

  const setFilters = (filters: IFilterColumn[]) => {
    const updatedFiltersMap = new Map<string, IFilterColumn>();

    config.filterColumns?.forEach((filter) => {
      updatedFiltersMap.set(filter.name, filter);
    });

    filters?.forEach((filter) => {
      const fieldDef: IColumnDef = config.availableColumns.find(
        (c) => c.name === filter.name,
      ) ?? {
        name: filter.name,
        type: filter.componentProps?.type ?? 'text',
        label: filter.componentProps?.label ?? filter.name,
      };

      updatedFiltersMap.set(filter.name, {
        name: fieldDef.name,
        values: filter.values,
        component: filter.component ?? 'field',
        componentProps: filter.componentProps ?? {},
      });
    });

    config.filterColumns = Array.from(updatedFiltersMap.values());

    config.filterValues = {
      ...config.filterValues,
      ...filters?.reduce((acc, filter) => {
        acc[filter.name] = filter.values;
        return acc;
      }, {}),
    };

    props?.storeView?.(instanceView);
  };

  const setOrderBy = (orderBys: IOrderByColumn[]) => {
    config.orderByColumns = orderBys;
    props?.storeView?.(instanceView);
  };

  const instanceView: ITableView = {
    id: props.id ?? props.name,
    name: props.name,
    displayName: localized(props.displayName),
    setColumns,
    setAvailableColumns,
    filter: props.filter,
    enableEdit: props.enableEdit,
    showHeader: props.showHeader,
    onRowClick: props.onRowClick,
    addVisibleColumn,
    addFilterColumn,
    removeFilterColumn,
    config,
    setOrderBy,
  };

  setColumns(props.columns);
  setChildViews(props.childViews);
  setFilters(props.filters);
  setOrderBy(props.orderBy);

  return instanceView;
};
