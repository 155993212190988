import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import {
  CargoMovementStatus,
  CargoMovementType,
  CommodityDto,
  OrderCommodityDto,
  OrderDto,
  OrderTypes,
  PutAwayActivityStatuses,
  WarehouseLocationDto,
  WarehouseZoneDto,
  WarehouseZoneDtoPagedResult,
} from '../../../models/data.models';
import { getOrder, getOrders, updateMovement } from '../cargoMovements.store';
import { addMessage } from '../../common/messages.store';
import { Button } from '../../common/components/button/button.component';
import { getWarehouseZonesFx } from '../../warehouseZones/warehouseZones.store';
import { CustomerInformation } from '../../customers/components/common/customerInformation.component';
import {
  ActivityCommodityDto,
  CargoMovementDto,
} from '../../../models/custom.models';
import { allPutAwayCommoditiyTableStore } from '../../commodities/commodities.store';
import { ActivityCommoditiesTable } from '../../common/components/table/activityCommoditiesTable.component';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { HiX } from 'react-icons/hi';
import { getWarehouseLocationsFx } from '../../warehouseLocations/warehouseLocations.store';
import { LocationInfo } from '../../common/components/location/locationInfo.component';
import { PutAwayActivity } from './putAwayActivity.component';
import {
  getCommodityOrderFx,
  updateOrderCommodity,
  UpdateOrderCommodityParams,
} from '../../orderCommodities/orderCommodities.store';
import { TotalStatuses } from '../../common/components/totalStatuses/totalStatuses';
import { v4 as uuidv4 } from 'uuid';
import { showDialog } from '../../common/dialog.store';
import { ReportProblemReasonDialog } from '../picking/reportProblemReason.dialog';

export type AllPutAwayActivityFlowProps = {
  onCancel?: () => void;
  isDialog?: boolean;
};

export const AllPutAwayActivityFlow = ({
  onCancel,
  isDialog = false,
}: AllPutAwayActivityFlowProps) => {
  const [warehouseZones, setWareHouseZones] = useState<WarehouseZoneDto[]>([]);
  const [
    chosenCommodity,
    setChosenCommodity,
  ] = useState<ActivityCommodityDto | null>(null);
  const [lastCommodity, setLastCommodity] = useState<CommodityDto | null>(null);
  const [activityCommodities, setActivityCommodities] = useState<
    ActivityCommodityDto[]
  >([]);
  const [purchase, setPurchase] = useState<OrderDto | null>(null);
  const [
    customerLocation,
    setCustomerLocation,
  ] = useState<WarehouseLocationDto>(null);
  const [putAwayOrders, setPutAwayOrders] = useState<OrderDto[]>([]);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(false);

  const { commodityColumns: columns } = useStore(
    allPutAwayCommoditiyTableStore,
  );

  useEffect(() => {
    const getPutAwayOrdersParams = {
      limit: 1000,
      offset: 0,
      filter: `OrderType: ${OrderTypes.CargoMovement} AND CustomValues.movementType:${CargoMovementType.PutAway} AND (CustomValues.movementStatus:Created OR CustomValues.movementStatus:Start)`,
    };
    getOrders(getPutAwayOrdersParams)
      .then((result) => {
        setPutAwayOrders(result?.items);
      })
      .catch((error) => {
        addMessage({
          message: error,
          type: 'danger',
          id: uuidv4(),
        });
      });
  }, []);

  useEffect(() => {
    putAwayOrders?.forEach((order: CargoMovementDto) => {
      const commodities: ActivityCommodityDto[] = [...order.commodities];
      order?.orderCommoditiesWithStatus?.forEach((x) => {
        commodities?.forEach((y) => {
          if (x.commodityId === y.commodityId) {
            y.activityStatus =
              x?.customValues['activityStatus'] ||
              PutAwayActivityStatuses.InMovement;
            y.lastModifiedOrderCommodity = new Date(x.lastModified);
            y.orderId = order?.orderId;
          }
        });
      });

      commodities.forEach((commodity) => {
        if (commodity.activityStatus === PutAwayActivityStatuses.InMovement) {
          setActivityCommodities((prevCommodities) => {
            if (
              !prevCommodities.some(
                (activityCommodity) =>
                  activityCommodity.commodityId === commodity.commodityId,
              )
            ) {
              commodity.palletNumber = order?.trackingNumber;
              prevCommodities.push(commodity);
            }
            return [...prevCommodities];
          });
        }
      });
    });
  }, [putAwayOrders]);

  useEffect(() => {
    getWarehouseZonesFx({
      limit: 1000,
      offset: 0,
    }).then((result: WarehouseZoneDtoPagedResult) => {
      setWareHouseZones(result?.items);
    });
  }, []);

  //get commodity shipment to show customer
  useEffect(() => {
    if (lastCommodity) {
      setIsPurchaseLoading(true);
      getCommodityOrderFx({
        commodity: lastCommodity,
        values: {
          commodityId: lastCommodity.commodityId,
          orderType: OrderTypes.Purchase,
        },
      })
        .then((result) => {
          setPurchase(result);
          setIsPurchaseLoading(false);
        })
        .catch((error) => {
          addMessage({
            message: error,
            type: 'danger',
            id: uuidv4(),
          });
        });
    } else {
      setPurchase(null);
    }
  }, [lastCommodity]);

  //show customer primary location
  useEffect(() => {
    getWarehouseLocationsFx({ limit: 1000 }).then((result) => {
      if (result?.items?.length > 0) {
        if (purchase?.billToContactId) {
          const location = result.items.find(
            (x) => x.customerId === purchase?.billToContactId,
          );
          setCustomerLocation(location);
        } else {
          setCustomerLocation(null);
        }
      }
    });
  }, [purchase]);

  //change commodity status
  const setPutAwayStatus = (status: PutAwayActivityStatuses) => {
    const orderCommdodity: OrderCommodityDto = {
      commodityId: chosenCommodity.commodityId,
      orderId: chosenCommodity.orderId,
      customValues: {
        activityStatus: status,
      },
    };

    const updateParams: UpdateOrderCommodityParams = {
      commodity: chosenCommodity,
      orderCommodity: orderCommdodity,
    };

    const isAllCommoditiesProcessed = (orderDto: CargoMovementDto) => {
      return orderDto.orderCommoditiesWithStatus.every(
        (x) =>
          x?.customValues['activityStatus'] ===
          PutAwayActivityStatuses.Completed,
      );
    };

    updateOrderCommodity(updateParams)
      .then(() => {
        showSuccess(status);
        getOrder({ orderId: updateParams?.orderCommodity?.orderId })
          .then((orderDto: CargoMovementDto) => {
            const updatedCommodityWithStatus = orderDto?.orderCommoditiesWithStatus?.find(
              (x) => x.commodityId === updateParams?.commodity?.commodityId,
            );
            const updatedCommodity = orderDto?.commodities?.find(
              (x) => x.commodityId === updateParams?.commodity?.commodityId,
            );
            setActivityCommodities((commodities) => {
              commodities?.forEach((x) => {
                if (x.commodityId === updatedCommodityWithStatus?.commodityId) {
                  x.activityStatus =
                    updatedCommodityWithStatus?.customValues?.[
                      'activityStatus'
                    ];
                  x.warehouseLocation = updatedCommodity?.warehouseLocation;
                  x.lastModifiedOrderCommodity = new Date(
                    updatedCommodityWithStatus?.lastModified,
                  );
                  return;
                }
              });
              return [...commodities];
            });

            //set cargo movement status to started after changing first commodity status
            if (!lastCommodity && !isAllCommoditiesProcessed(orderDto)) {
              orderDto.customValues.movementStatus =
                CargoMovementStatus.InProgress;
              updateMovement(orderDto);
            }
            //set cargo movement status to  finished after changing last commodity status
            else if (isAllCommoditiesProcessed(orderDto)) {
              orderDto.customValues.movementStatus =
                CargoMovementStatus.Finished;
              updateMovement(orderDto);
            }
          })
          .catch((error) => {
            addMessage({
              message: error?.message || error,
              type: 'danger',
              id: uuidv4(),
            });
          });
      })
      .catch((error) => {
        showError(error?.message || error);
      });
  };

  const showSuccess = (status: PutAwayActivityStatuses) => {
    addMessage({
      message: `Package status is set to ${status}`,
      type: 'success',
      id: uuidv4(),
    });
  };

  const showError = (error: any) => {
    addMessage({
      message: error,
      type: 'danger',
      id: uuidv4(),
    });
  };

  const onReportPackage = () => {
    showDialog({
      dialog: ReportProblemReasonDialog,
      props: {
        onSelect: () => {},
        commodities: null,
        className: 'modal-report-damaged',
      },
    }).then(() => {});
  };

  const actionButtons = [
    <Button color={'secondary'} outline onClick={onCancel}>
      <div className={'d-flex align-items-center'}>
        <HiX size={16} className={'mr-2'} />
        Cancel
      </div>
    </Button>,
  ];

  useEffect(() => {
    if (onCancel) setActionButtons([...actionButtons]);
  }, []);

  useEffect(() => () => clearActionButtons(), []);

  return (
    <div>
      <div className="mx-3">
        {isDialog ? (
          <div className="row d-flex justify-content-end p-3">
            {actionButtons.map((x) => {
              return x;
            })}
          </div>
        ) : null}
      </div>
      <div className="w-100 mx-auto mb-4"></div>
      <div className="row">
        <div className="col-md-6 col-12 mb-md-0 mb-4">
          <PutAwayActivity
            setCommodity={setChosenCommodity}
            setLastCommodity={setLastCommodity}
            setPutAwayStatus={setPutAwayStatus}
            activityCommodities={activityCommodities}
            setAtivityCommodities={setActivityCommodities}
          />
        </div>
        <div className="col-md-6 col-12">
          <div className="box h-100">
            {lastCommodity && (
              <CustomerInformation
                contactId={purchase?.billToContactId}
                trackingNumber={lastCommodity.commodityTrackingNumber}
                additionalLoading={
                  isPurchaseLoading || !purchase?.billToContactId
                }
              />
            )}
            {lastCommodity?.warehouseLocation && (
              <div className="text-label mt-3">Current Location</div>
            )}
            <div className="d-flex justify-content-start mt-3">
              {lastCommodity?.warehouseLocation && (
                <LocationInfo
                  warehouseLocation={lastCommodity?.warehouseLocation}
                  locationZones={warehouseZones}
                  showIcon={true}
                  size={'lg'}
                />
              )}
            </div>
            {customerLocation && (
              <div className="text-label mt-3">
                Primary Customer Package Location
              </div>
            )}
            <div className="d-flex justify-content-start mt-3">
              {customerLocation && (
                <LocationInfo
                  warehouseLocation={customerLocation}
                  locationZones={warehouseZones}
                  showIcon={true}
                  size={'lg'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <TotalStatuses
        activityStatuses={activityCommodities.map(
          (commodity) => commodity.activityStatus,
        )}
        statusesType={PutAwayActivityStatuses}
      />
      <div className="mt-6">
        <ActivityCommoditiesTable
          commodities={[...activityCommodities]}
          columns={columns}
          warehouseZones={warehouseZones}
          sortField={'lastModifiedOrderCommodity'}
          onReportPackage={onReportPackage}
        />
      </div>
    </div>
  );
};
