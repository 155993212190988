import { onDeviceConnectionStatus, onScannerResult } from './scanner.store';
import type { KomcatDevice, ScanningResult } from './scanner.store';

declare type KoamtacOptions = {
  KdcConnectionCallback: (data: {
    device: KomcatDevice;
    status: boolean;
    progress: string;
  }) => void;
  KdcBarcodeDataReceivedCallback: (data: ScanningResult) => void;
};
declare type KoamtacType = {
  checkSupported: () => boolean;
  init: (data: KoamtacOptions) => void;
  connect: (deviceNames: string[]) => void;
  getKdcDeviceInfo: () => KomcatDevice;
  disconnect: () => void;
  cmd: {
    softwareTrigger: () => Promise<ScanningResult>;
  };
};

declare var Koamtac: KoamtacType;

export function supported() {
  return Koamtac.checkSupported();
}
export function init() {
  const options = {
    KdcConnectionCallback: (data) => {
      onDeviceConnectionStatus(data);
    },
    KdcBarcodeDataReceivedCallback: (data: any) => {
      onScannerResult({ ...data, from: 'scanner' });
    },
  };
  if (typeof Koamtac !== 'undefined') {
    Koamtac.init(options);
  }
}

export function connect() {
  Koamtac.connect([]);
}

export function connected() {
  return !!Koamtac.getKdcDeviceInfo();
}

export function disconnect() {
  let infoDevice = Koamtac.getKdcDeviceInfo();
  if (infoDevice) {
    return Koamtac.disconnect();
  }
}

export async function scan(code?: string, from?: string) {
  if (code) {
    const event = {
      data: code,
      status: true,
      msg: '',
      from,
    };
    onScannerResult(event);
    return Promise.resolve(event);
  } else {
    const result = await Koamtac.cmd.softwareTrigger();
    onScannerResult({ ...result, from: 'input' });
    return result;
  }
}

init();
