import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { ActionEventEdit } from '../components/actionEvent-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  ACTIONEVENTS_LIST_PATH,
  ACTIONEVENT_CREATE_PATH,
} from '../actionEvents.route';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_ACTIONEVENT_LINK_KEY } from '../actionEvents.service';
import { userHas } from '../../auth/auth.store';

export type ActionEventScreenProps = { pageTitle: string; group: string };
export type ActionEventRouteParams = {
  organizationId: string;
  eventId: string | string;
};

export const ActionEventScreen = ({
  pageTitle,
  group,
}: ActionEventScreenProps) => {
  const navigate = useNavigate();

  const { organizationId, eventId }: ActionEventRouteParams = useParams();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + ACTIONEVENT_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const closeScreen = useMemo(
    () => () => {
      // check if we can go back
      if (navigate.length > 2) {
        navigate(navigate.length - 1);
      } else {
        const { currentOrganization } = organizationsStore.getState();
        const path = generatePath(INTERNAL_PATH + ACTIONEVENTS_LIST_PATH, {
          organizationId: currentOrganization.organizationId,
        });
        navigate(path);
      }
    },
    [navigate],
  );

  const eventIdMemo = useMemo(() => (eventId !== 'create' ? eventId : null), [
    eventId,
  ]);

  return (
    <InternalLayout
      group={group}
      title={'Action Event'}
      createPath={userHas(CREATE_ACTIONEVENT_LINK_KEY) ? createPath : null}
      createLabel={'Action Event'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ActionEventEdit
        eventId={eventIdMemo}
        onCancel={closeScreen}
        onActionEventCreated={closeScreen}
        onActionEventUpdated={closeScreen}
        onDelete={closeScreen}
      />
    </InternalLayout>
  );
};
