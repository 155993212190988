import {
  CustomFieldDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  CustomFieldDtoPagedResult,
  CreateCustomFieldCommandValues,
  UpdateCustomFieldCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CUSTOMFIELDS_LINK_KEY = 'get-customfields';
export const CREATE_CUSTOMFIELD_LINK_KEY = 'create-customfield';
export const GET_CUSTOMFIELD_LINK_KEY = 'get-customfield';
export const UPDATE_CUSTOMFIELD_LINK_KEY = 'update-customfield';
export const DELETE_CUSTOMFIELD_LINK_KEY = 'delete-customfield';
export const RECREATE_SYSTEM_CUSTOMFIELDS_KEY = 'recreate-system-customfields';
export const IMPORT_CUSTOMFIELDS_LINK_KEY = 'import-customfields';
export const EXPORT_CUSTOMFIELDS_LINK_KEY = 'export-customfields';
export const CUSTOMFIELD_ENTITY_NAME = 'Custom Field';

export interface GetCustomFieldParams {
  fieldId?: number;
}

export interface RecreateSystemCustomFieldsParams {
  organizationId: number;
}

export interface ImportCustomFieldsParams {
  organizationId: number;
}

export interface ExportCustomFieldsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getCustomFieldRequest = async (
  resource: LinkResourceBaseDto | null,
  { fieldId }: GetCustomFieldParams,
): Promise<CustomFieldDto> => {
  if (resource && fieldId) {
    const getCustomFieldLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CUSTOMFIELD_LINK_KEY,
    );
    if (getCustomFieldLink) {
      const result = await execLink(getCustomFieldLink, { fieldId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CUSTOMFIELD_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCustomFieldsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CustomFieldDtoPagedResult> => {
  if (resource) {
    const getCustomFieldsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CUSTOMFIELDS_LINK_KEY,
    );
    if (getCustomFieldsListLink) {
      const result = await execLink(getCustomFieldsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CUSTOMFIELDS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCustomFieldRequest = async (
  resource: LinkResourceBaseDto,
  customField: CreateCustomFieldCommandValues,
) => {
  const createCustomFieldLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CUSTOMFIELD_LINK_KEY,
  );
  if (createCustomFieldLink) {
    const result = await execLink(createCustomFieldLink, customField);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CUSTOMFIELD_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateCustomFieldRequest = async (
  resource: LinkResourceBaseDto,
  customField: UpdateCustomFieldCommandValues,
) => {
  const updateCustomFieldLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CUSTOMFIELD_LINK_KEY,
  );
  if (updateCustomFieldLink) {
    const result = await execLink(updateCustomFieldLink, customField);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CUSTOMFIELD_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteCustomFieldRequest = async (customField: CustomFieldDto) => {
  const deleteCustomFieldLink = customField.links?.find(
    (x: LinkDto) => x.rel === DELETE_CUSTOMFIELD_LINK_KEY,
  );
  if (deleteCustomFieldLink) {
    const result = await execLink(deleteCustomFieldLink, customField);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CUSTOMFIELD_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importCustomFieldsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportCustomFieldsParams,
  file: File,
) => {
  const importCustomFieldsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CUSTOMFIELDS_LINK_KEY,
  );
  if (importCustomFieldsLink) {
    const result = await postFormData(importCustomFieldsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CUSTOMFIELDS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportCustomFieldsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportCustomFieldsParams,
) => {
  const exportCustomFieldsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CUSTOMFIELDS_LINK_KEY,
  );
  if (exportCustomFieldsLink) {
    const result = await downloadLinkFile(exportCustomFieldsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CUSTOMFIELDS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const recreateSystemCustomFieldsRequest = async (
  resource: LinkResourceBaseDto | null,
  { organizationId }: RecreateSystemCustomFieldsParams,
): Promise<void> => {
  if (resource && organizationId) {
    const recreateSystemCustomFieldsLink = resource.links?.find(
      (x: LinkDto) => x.rel === RECREATE_SYSTEM_CUSTOMFIELDS_KEY,
    );
    if (recreateSystemCustomFieldsLink) {
      await execLink(recreateSystemCustomFieldsLink, { organizationId });
      return;
    }
  }
  throw new Error(
    `Action ${RECREATE_SYSTEM_CUSTOMFIELDS_KEY} is not allowed or missing link action`,
  );
};
