import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputProps,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectAccountingAccountPaginate } from '../../accountingAccounts/components/accountingAccount-react-select.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectCurrencyPaginate } from '../../currencies/components/currencies-react-select.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';

export const PaymentForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

PaymentForm.AccountingAccountId = ({
  selectedFilter,
  onChange,
  id = 'accountingAccountId',
  nameId = 'accountingAccountName',
  header = 'Accounting Account',
  required = true,
  placeholder,
  readonly,
  disabled,
  sort = 'accountName',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectAccountingAccountPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      showAccountType={true}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeAccountingAccount={onChange}
    />
  );
};
PaymentForm.AmountReceived = ({
  className,
  style,
  size,
  disabled,
  onKeyDown,
}: InputProps) => {
  return (
    <Input
      name={'amountReceived'}
      label={'Amount Received'}
      placeholder={'Amount Received'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};
PaymentForm.ApplyToContactId = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  readonly,
  id = 'applyToContactId',
  nameId = 'applyToContactName',
  header = 'Apply To Contact Id',
  placeholder = 'Select Apply To Contact',
  required = true,
  sort = 'name',
  defaultValue,
  disabled,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};
PaymentForm.CheckNumber = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'checkNumber'}
      label={'Check Number'}
      placeholder={'Check Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
PaymentForm.CurrencyId = ({
  selectedFilter = ``,
  placeholder = 'Select Currency',
  required = true,
  defaultValue,
  sort = 'currencyCode',
  id = 'currencyId',
  header = 'Currency',
  onChange = () => {},
  nameId = 'currencyCode',
}: InputPublicProps) => {
  return (
    <SelectCurrencyPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCurrency={onChange}
    />
  );
};
PaymentForm.DivisionId = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isClearable={false}
      isDisabled={disabled}
    />
  );
};
PaymentForm.Memo = ({ className, style, size, disabled }: InputPublicProps) => {
  return (
    <Input
      name={'memo'}
      label={'Memo'}
      placeholder={'Memo'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
PaymentForm.PaymentDate = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={'paymentDate'}
      label={'Payment Date'}
      placeholder={'Payment Date'}
      required={true}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
PaymentForm.AmountPaid = ({ defaultValue }: InputPublicProps) => {
  return (
    <Input
      name={'amountPaid'}
      label={'Amount to Apply'}
      placeholder={'Amount to Apply'}
      type={'input-OnChange-number-with-value'}
      valueInput={defaultValue}
      disabled={true}
      required={false}
    />
  );
};
