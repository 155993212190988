import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { authStore } from '../auth/auth.store';
import { useStore } from 'effector-react';
import { organizationsStore } from '../organization/organization.store';

export type PrivateRouteProps = {
  path: string;
};

export const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useStore(authStore);
  const { organizations } = useStore(organizationsStore);
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (organizations.length === 0) {
    return <Navigate to="/createOrganization" />;
  }

  return children;
};
