import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  CustomCodeDto,
  CustomCodeDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  customCodesStore,
  getCustomCodes,
  updateColumns,
  deleteCustomCode,
} from '../customCodes.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_CUSTOMCODE_LINK_KEY,
  DELETE_CUSTOMCODE_LINK_KEY,
  GetCustomCodeParams,
} from '../customCodes.service';
import { CustomCodeDialog } from './customCode.dialog';
import { userHas } from '../../auth/auth.store';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';

export type CustomCodesListProps = {
  goToDetails?: (id?: string) => void;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  sort?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onFilter?: (query: string) => void;
  onSelect?: (
    customCode: CustomCodeDto,
    customCodeParams: GetCustomCodeParams,
  ) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};
export const CustomCodesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  filter = null,
  search = '',
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
  onSelect = () => {},
  onSearch,
  isDropDownList = false,
}: CustomCodesListProps) => {
  const [codes, setCodes] = useState<CustomCodeDtoPagedResult | null>({
    items: [],
    totalCount: 0,
    limit: 20,
    offset: 0,
    links: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetCustomCodes();
  }, [offset, limit, sort, filter, search]);
  function GetCustomCodes() {
    getCustomCodes({ offset, limit, sort, filter, search }).then(
      (codesData) => {
        setCodes(codesData);
        setLoading(false);
      },
      () => {},
    );
  }
  const { customCodesColumns: columns } = useStore(customCodesStore);

  const onDeleteCustomCode = (customCode: CustomCodeDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${customCode.code} Custom Code`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteCustomCode(customCode).then(() => {
          onDelete();
          GetCustomCodes();
        });
      }
    });
  };

  const onEditCode = (customCode: CustomCodeDto) => {
    goToDetails(customCode.code);
  };

  const onCreateNewCustomCode = () => {
    goToDetails('create');
  };
  const onCustomCodeSelect = (customCode, code) => {
    if (onSelect) onSelect(customCode, code);
    showDialog({
      dialog: CustomCodeDialog,
      props: {
        code: customCode.code,
        title: 'Update Custom Code',
      },
    }).then((customCode) => {
      if (customCode !== null) {
        GetCustomCodes();
      }
    });
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          isDropDownList={isDropDownList}
          showAllStore={true}
          rowKeys={null}
          data={codes?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={codes?.totalCount}
          sort={sort}
          filter={filter}
          onFilter={onFilter}
          search={search}
          onDelete={
            userHas(DELETE_CUSTOMCODE_LINK_KEY, codes?.items[0]?.links)
              ? onDeleteCustomCode
              : null
          }
          onSort={onSort}
          showEmptyTable={true}
          onEdit={onEditCode}
          onPageChanged={onPageChanged}
          onSelect={onCustomCodeSelect}
          onSearch={onSearch}
          className={'customCode-list'}
        />
        {(!codes || codes?.totalCount == 0) && !filter && !search && (
          <ListElementsNotFound
            entityName="Custom Code"
            entityLinkKey={CREATE_CUSTOMCODE_LINK_KEY}
            onClick={onCreateNewCustomCode}
          />
        )}
      </div>
    );
  }
};
