import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PaymentScreen } from './screen/payment.screen';
import { PaymentsListScreen } from './screen/payment-list.screen';

export const PAYMENTS_LIST_PATH = '/payments';
export const PAYMENT_EDIT_PATH = '/payments/:paymentId';
export const PAYMENT_CREATE_PATH = '/payments/0';

export const PaymentRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <PaymentsListScreen pageTitle="Payments" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <PaymentScreen pageTitle="Add New Payment" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':paymentId'}
        element={
          <PrivateRoute>
            <PaymentScreen pageTitle="Update Payment" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
