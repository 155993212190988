import React, { useEffect, useState } from 'react';
import {
  CommodityTrackingNumberDto,
  CommodityTrackingNumberDtoPagedResult,
} from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { v4 as uuidv4 } from 'uuid';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { clearMessages } from '../../common/messages.store';
import { getCommodityTrackingNumbers } from '../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import { ScanButton } from '../../barcodeScanner/components/scan-button.component';

const TRACKING_NUMBER_TYPE_ENTITY_NAME = 'Tracking Number';

export const SelectCommodityTrackingNumberPaginate = ({
  id = 'commodityTrackingNumberId' + uuidv4(),
  nameId = 'commodityTrackingNumber' + uuidv4(),
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  options = [],
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeWarehouseZone = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  className = '',
  barcode = false,
}): JSX.Element => {
  const formikContext = useFormikContext();
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const [context, setContext] = useState(null);
  useEffect(() => {
    setContext(useContext);
  }, [useContext]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseCommodityTrackingNumbersDtoPagedResult: CommodityTrackingNumberDtoPagedResult = await getCommodityTrackingNumbers(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseCommodityTrackingNumbersDtoPagedResult.items.filter(
        (el) => el.commodityTrackingNumberId !== options[0],
      ),
      hasMore: responseCommodityTrackingNumbersDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeWarehouseZone === 'function') {
      onChangeWarehouseZone(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const onChangeWithContext = (
    commodityTrackingNumber: CommodityTrackingNumberDto,
    currentContext,
  ) => {
    onChange(commodityTrackingNumber, currentContext);
    if (currentContext) {
      currentContext.setFieldValue(
        id,
        commodityTrackingNumber?.commodityTrackingNumberId,
      );
      currentContext.setFieldError(id, undefined);
      setValue(() => {
        return (
          commodityTrackingNumber && {
            commodityTrackingNumberId:
              commodityTrackingNumber?.commodityTrackingNumberId,
            trackingNumber: commodityTrackingNumber?.trackingNumber,
          }
        );
      });
      if (required && !commodityTrackingNumber) {
        currentContext.setFieldValue(id, null);
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const barcodeStyles = {
    control: (provided, state) => ({
      ...provided,
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      height: 34,
      minHeight: 34,
    }),
  };

  if (context) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <h3 className={'mb-3'}>{header}</h3>
            </label>
            <div className="d-flex">
              <div className={'w-100'}>
                <Field
                  required={required}
                  type="hidden"
                  name={id}
                  value={getContextValue(context, id)}
                />
                <SelectEntityAsyncPaginate
                  className={className}
                  entityName={TRACKING_NUMBER_TYPE_ENTITY_NAME}
                  canCreateNewEntity={false}
                  key={filter}
                  value={value}
                  loadOptions={loadOptions}
                  getOptionValue={(option: CommodityTrackingNumberDto) =>
                    option?.commodityTrackingNumberId
                  }
                  getOptionLabel={(option: CommodityTrackingNumberDto) =>
                    `${option?.trackingNumber}`
                  }
                  onChange={(
                    commodityTrackingNumber: CommodityTrackingNumberDto,
                  ) => {
                    onChangeWithContext(commodityTrackingNumber, context);
                  }}
                  onFocus={clearMessages}
                  onBlur={() => context.setFieldTouched(id, true)}
                  isDisabled={isDisabled}
                  isClearable={isClearable}
                  closeMenuOnSelect={closeMenuOnSelect}
                  isSearchable={isSearchable}
                  placeholder={placeholder}
                  classNamePrefix={classNamePrefix}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  additional={{
                    page: 1,
                  }}
                  cacheUniqs={[clearLoadOptionsCache]}
                  hideDropdownIndicator={true}
                  styles={
                    barcode
                      ? barcodeStyles
                      : {
                          control: (base) => ({
                            ...base,
                            height: 34,
                            minHeight: 34,
                            'border-radius': '4px 0 0 4px',
                          }),
                        }
                  }
                />
              </div>
              {barcode ? (
                <ScanButton
                  disabled={false}
                  name={id}
                  onChange={(value) => {
                    if (value) {
                      getCommodityTrackingNumbers({
                        limit: 1,
                        filter: `TrackingNumber:"${value}" `,
                      }).then((result) => {
                        if (result.items.length > 0) {
                          onChangeWithContext(result.items[0], context);
                        }
                      });
                    }
                  }}
                  setFieldValue={formikContext.setFieldValue}
                />
              ) : null}
              <ErrorMessage
                name={id}
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={TRACKING_NUMBER_TYPE_ENTITY_NAME}
        canCreateNewEntity={false}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.warehouseZoneId}
        getOptionLabel={(option) => option?.name}
        onChange={(warehouseZone?: any) => {
          onChange(warehouseZone, context);
        }}
        onFocus={clearMessages}
        isDisabled={isDisabled}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
