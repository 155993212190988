import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';

export const StateForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      innerRef={innerRef}
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

StateForm.StateCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'stateCode'}
      label={'State Code'}
      placeholder={'Enter State Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};

StateForm.Name = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'State Name'}
      placeholder={'Enter State Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

StateForm.CountryCode = ({
  selectedFilter = ``,
  placeholder = 'Select Country',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'countryCode',
  header = 'Country',
  onChange = () => {},
  readonly,
  nameId = 'name',
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
    />
  );
};
StateForm.CountryCodeId = ({
  className,
  style,
  size,
  disabled,
  selectedName,
  selectedFieldName,
  nameId,
}: InputPublicProps) => {
  return (
    <Input
      name={'countryName'}
      label={'Country'}
      placeholder={'Enter Country Code'}
      required={true}
      type={'text'}
      disabled={disabled}
      className={className}
      style={style}
      size={size}
    />
  );
};

StateForm.CustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      isSending={isSending}
      saveButtonRenderCondition={saveButtonRenderCondition}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
