import React, { useEffect, useState } from 'react';
import { initApi } from './modules/api/api.store';
import { getUserAdditionalData, getUserInfo } from './modules/auth/auth.store';
import { getOrganizationsFx } from './modules/organization/organization.store';
import { AppRouter } from './app.router';
import { DialogsHolder } from './modules/common/components/dialogs-holder/dialogs-holder.component';
import { Messages } from './modules/common/components/messages/messages.component';
import { getCurrenciesFx } from './modules/currencies/currencies.store';
import useJsScript, { GOOGLE_PLACES_SCRIPT } from './utils/useJsScript.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const HEADER_MIN_WIDTH = 1453;

export const App = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (setInitialized) {
      // load apps
      import('./apps/usps').then((usps) => {
        usps.init();
      });
      import('./apps/fedex').then((fedexApp) => {
        fedexApp.init();
      });
    }
  }, [setInitialized]);

  useJsScript(GOOGLE_PLACES_SCRIPT);
  useEffect(() => {
    initApi()
      .then((ignore) => {
        return getUserInfo()
          .then(() => {
            return getOrganizationsFx({
              sort: 'organizationId',
            });
          })
          .then(async (orgData) => {
            getUserAdditionalData();
            await getCurrenciesFx();
            return orgData;
          })
          .catch(() => {
            return {};
          });
      })
      .finally(() => {
        setInitialized(true);
      });
  }, []);
  return (
    <main style={{ minWidth: HEADER_MIN_WIDTH }}>
      {initialized ? (
        <div>
          <AppRouter />
          <Messages className="message-wrapper" />
          <DialogsHolder />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center text-primary"
          style={{ height: '100vh' }}
        >
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      )}
    </main>
  );
};
