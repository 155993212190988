import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const PortForm = ({
  id = 'portForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

PortForm.PortId = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'portId'}
      id={'portId'}
      label={'Port Id'}
      placeholder={'Port Id'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.CountryCode = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'countryCode'}
      id={'countryCode'}
      label={'Country Code'}
      placeholder={'Country Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.IsAir = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isAir'}
      id={'isAir'}
      label={'Is Air'}
      placeholder={'Is Air'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.IsBorderCrossingPoint = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isBorderCrossingPoint'}
      id={'isBorderCrossingPoint'}
      label={'Is Border Crossing Point'}
      placeholder={'Is Border Crossing Point'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.IsMail = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isMail'}
      id={'isMail'}
      label={'Is Mail'}
      placeholder={'Is Mail'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.IsMariTime = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isMariTime'}
      id={'isMariTime'}
      label={'Is Mari Time'}
      placeholder={'Is Mari Time'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.IsMyCompany = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isMyCompany'}
      id={'isMyCompany'}
      label={'Is My Company'}
      placeholder={'Is My Company'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.IsRail = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isRail'}
      id={'isRail'}
      label={'Is Rail'}
      placeholder={'Is Rail'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.IsRoad = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isRoad'}
      id={'isRoad'}
      label={'Is Road'}
      placeholder={'Is Road'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.Name = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      id={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.PortRemarks = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'portRemarks'}
      id={'portRemarks'}
      label={'Port Remarks'}
      placeholder={'Port Remarks'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
PortForm.StateCode = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'stateCode'}
      id={'stateCode'}
      label={'State Code'}
      placeholder={'State Code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
