import { Dialog, DialogProps } from '../dialog/dialog.component';
import { Button } from '../button/button.component';
import PropTypes from 'prop-types';

export type ConfirmProperties = DialogProps & {
  title: string;
  message: string;
  className?: string;
};

export class Confirm extends Dialog<ConfirmProperties> {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    className: PropTypes.string,
  };
  constructor(props: ConfirmProperties) {
    super(props);
  }

  renderContent(): any {
    const { message } = this.props as ConfirmProperties;
    return (
      <div>
        <p className={'confirm-message w-100 d-flex justify-content-center'}>
          {message}
        </p>
        <div className={'col-12 p-0 d-flex w-100'}>
          <div className={'col-6'}>
            <Button
              className={'w-100'}
              color={'primary'}
              onClick={() => {
                this.close(true);
              }}
            >
              Ok
            </Button>
          </div>
          <div className={'col-6'}>
            <Button
              className={'w-100'}
              color={'secondary'}
              onClick={() => {
                this.close(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
