import React from 'react';

export const LoadingIndicator = ({ message = 'Loading...' }) => {
  return (
    <div className="m-5 text-center">
      <h3 className="text-muted mb-4">
        <i className="fa fa-spinner fa-spin mr-2" />
        {message}
      </h3>
    </div>
  );
};
