import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  executeWorkflowTriggerFx,
  getWorkflowTriggerFx,
} from '../../workflowTriggers/workflowTriggers.store';

const OAuth2CallBackPage = () => {
  // retrieve the code from search params
  const location = useLocation();
  const [tokenResult, setTokenResult] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const realmId = urlParams.get('realmId');

    // retrieve the tokenUrl from local session storage
    const config = JSON.parse(localStorage.getItem(state));
    if (!config) {
      console.error('No oAuth session found');
      return;
    }

    // remove the tokenUrl from local session storage
    // sessionStorage.removeItem(state);

    const headers = [{ name: 'Accept', value: 'application/json' }];

    // add additionalHeaders
    if (config.additionalHeaders) {
      for (const [key, value] of Object.entries(config.additionalHeaders)) {
        headers[key] = value;
      }
    }

    if (config.workflowId) {
      getWorkflowTriggerFx({ workflowId: config.workflowId }).then(
        (workflowTriggerDto) => {
          const workflowExecutionVariables = {
            credentials: {
              code,
              grant_type: 'authorization_code',
              redirect_uri: config.redirectUri,
            },
            tokenUrl: config.tokenUrl,
            headers,
          };

          // quickBooks oauth request crushes if body contains unnecessary fields
          if (!config.tokenUrl.includes('intuit')) {
            workflowExecutionVariables.credentials = {
              ...workflowExecutionVariables.credentials,
              client_id: config.clientId,
              client_secret: config.clientSecret,
              scope: config.scope,
            };
          }

          executeWorkflowTriggerFx({
            workflowTrigger: workflowTriggerDto,
            variables: workflowExecutionVariables,
          }).then((response) => {
            const data = { ...response.outputs.token, realmId };
            setTokenResult(data);
            // push the token to parent window using message event
            window.opener.postMessage(
              {
                type: 'oAuth2Callback',
                data,
              },
              window.location.origin,
            );
          });
        },
      );
    } else {
      // send the code to the tokenUrl
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      };

      fetch(config.tokenUrl, {
        method: 'POST',
        headers,
        body: new URLSearchParams({
          code,
          grant_type: 'authorization_code',
          client_id: config.clientId,
          client_secret: config.clientSecret,
          scope: config.scope,
          redirect_uri: config.redirectUri,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data = { ...data, realmId };
          setTokenResult(data);
          // push the token to parent window using message event
          window.opener.postMessage(
            {
              type: 'oAuth2Callback',
              data,
            },
            window.location.origin,
          );
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [location]);

  return (
    <div>
      <h1>Authorizing</h1>
      {tokenResult && (
        <pre>
          <h3>Token Result</h3>
          {JSON.stringify(tokenResult, null, 2)}
        </pre>
      )}
      <Button onClick={() => window.close()}>Close</Button>
    </div>
  );
};

export default OAuth2CallBackPage;
