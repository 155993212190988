import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { OceanShipmentOrderEdit } from './oceanShipmentOrder-edit.component';

export type OceanOrderDialogProperties = {
  orderId?: number | null;
  title: string;
  className?: string;
  onOceanOrderSaved?: (oceanOrder: OrderDto) => void;
  onInvoiceGenerated?: () => void;
};

export class OceanShipmentOrderDialog extends Dialog<OceanOrderDialogProperties> {
  onInvoiceGenerated?: () => void;

  constructor(props: OceanOrderDialogProperties) {
    super(props);
    this.onInvoiceGenerated = props.onInvoiceGenerated?.bind(this);
    this.onOceanOrderSaved = this.onOceanOrderSaved.bind(this);
  }

  onOceanOrderSaved = (oceanOrder: OrderDto) => {
    this.close(oceanOrder);
  };

  renderContent(): any {
    const { orderId } = this.props as OceanOrderDialogProperties;
    const onInvoiceGenerated = this.onInvoiceGenerated;
    return (
      <OceanShipmentOrderEdit
        orderId={orderId}
        onOceanOrderUpdated={this.onOceanOrderSaved}
        onCancel={this.close.bind(this)}
        onInvoiceGenerated={onInvoiceGenerated && this.onInvoiceGenerated}
      />
    );
  }
}
