import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  ReactNode,
} from 'react';
import { onScannerResult } from './scanner.store';
import { ScanningResultFrom } from '../../models/data.models';

type ScannerContextType = string | null;

const ScannerContext = createContext<ScannerContextType>(null);

interface ScannerProviderProps {
  children: ReactNode;
}

const minBarcodeLength = 4;

export const ScannerProvider: React.FC<ScannerProviderProps> = ({
  children,
}) => {
  const [keyPressed, setKeyPressed] = useState<string>('');
  const accumulatedKeysRef = useRef<string>(''); // Using ref to get the latest state inside the event handler
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Using ref to persist timer across renders

  function processAccumulatedKeys() {
    if (accumulatedKeysRef.current.length >= minBarcodeLength) {
      setKeyPressed(accumulatedKeysRef.current);
      onScannerResult({
        data: accumulatedKeysRef.current,
        from: ScanningResultFrom.Scanner,
      });
    }
    accumulatedKeysRef.current = '';
  }

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      const target = event.target as HTMLElement;
      const { tagName, isContentEditable } = target;
      const ignoredElements = [
        'INPUT',
        'TEXTAREA',
        'SELECT',
        'OPTION',
        'BUTTON',
      ];

      if (ignoredElements.includes(tagName) || isContentEditable) {
        accumulatedKeysRef.current = '';
        if (timerRef.current) clearTimeout(timerRef.current);
        return;
      }
      console.log('event.key: ', event.key);
      if (
        event.key === 'Enter' &&
        accumulatedKeysRef.current.length >= minBarcodeLength
      ) {
        processAccumulatedKeys();
      } else {
        if (event.key !== 'Enter') accumulatedKeysRef.current += event.key;

        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(processAccumulatedKeys, 200);
      }
    }

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <ScannerContext.Provider value={keyPressed}>
      {children}
    </ScannerContext.Provider>
  );
};
