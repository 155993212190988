import {
  OrderEntityDto,
  ContactType,
  ContactDto,
  CustomFieldDto,
  CustomFieldEntityType,
} from '../../../../models/data.models';
import { OrderForm } from '../../../orders/components/order.form';
import { CustomFieldsLayout } from '../../../common/components/form/customFields-layout.component';
import { useEffect, useState } from 'react';

export type CarrierFormProps = {
  index: number;
  allowDelete: boolean;
  onCarrierCreated?: (carrierDto: OrderEntityDto) => void;
  onCarrierUpdated?: (orderEntityDto: OrderEntityDto) => void;
  onCarrierLoaded?: (orderEntityDto: OrderEntityDto) => void;
  onCarrierDeleted?: () => void;
  carrierContact: {
    contactId: number;
    name: string;
    contactType: ContactType;
  };
  setCarrierContact: (
    contactId: number,
    name: string,
    contactType: ContactType,
  ) => void;
  setCarrierFilter: (filter: string) => void;
  carrierFilter: string;
  customValues: object;
  onChange?: (result: object) => void;
  customFields?: CustomFieldDto[];
};

export const CarrierForm = ({
  index,
  setCarrierContact,
  setCarrierFilter,
  carrierContact,
  allowDelete = false,
  onCarrierDeleted,
  customValues,
  onChange = (result) => {},
  customFields,
}: CarrierFormProps) => {
  const carrier =
    carrierContact &&
    carrierContact.contactId != null &&
    carrierContact.name != null &&
    carrierContact.contactType != null
      ? {
          contactId: carrierContact?.contactId,
          name: carrierContact?.name,
          contactType: carrierContact?.contactType,
        }
      : '';

  const carrierCustomFields = customFields?.filter(
    (field) =>
      field.customFieldEntityType == CustomFieldEntityType.PurchaseCarrier,
  );

  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  useEffect(() => {
    setIsFieldsDisabled(!carrierContact?.contactId || false);
  }, [carrierContact.contactId]);

  return (
    <div className="row">
      <div className="col-3">
        <OrderForm.CarrierCompany
          id={`orderEntityCarriers[${index}].contactId`}
          nameId={`orderEntityCarriers[${index}].contactName`}
          header={`Transfer ${index + 1}`}
          contactTypes={[ContactType.Carrier]}
          selectedFilter={`contactType: ${ContactType.Carrier}`}
          required={false}
          defaultValue={carrier}
          onChange={(data?: ContactDto, context?: any) => {
            setCarrierContact(data?.contactId, data?.name, data?.contactType);
            setCarrierFilter(
              data?.contactId ? `contactId:${data?.contactId}` : ' ',
            );
          }}
        />
      </div>
      <div className={allowDelete ? 'col-8' : 'col-9'}>
        <div className={'w-100'}>
          <CustomFieldsLayout
            inputNamePrefix={`orderEntityCarriers[${index}]`}
            rows={[{ rowNumber: 1 }]}
            filter={'routingCarrier'}
            customFields={customFields}
            defaultValue={customValues}
            onChange={onChange}
          />
        </div>
      </div>
      {allowDelete && (
        <div className="col-1 d-flex">
          <div className={'d-flex align-items-center pt-3'}>
            <div
              data-testid={`delete-carrier[${index}]`}
              onClick={(event) => {
                onCarrierDeleted();
              }}
              color="primary"
              className="btn-close icon-close"
            />
          </div>
        </div>
      )}
    </div>
  );
};
