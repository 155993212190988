import { Card } from 'react-bootstrap';
import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';

export const CardComponent = (props: ComponentProps) => {
  return (
    <Card
      {...props.props?.options}
      className={`default-card ${props.props.className} ${props.props?.options?.className}`}
    >
      {props.children?.map((child: ComponentProps, i) => (
        <ComponentRender
          key={'card-component-' + i}
          {...child}
          variables={props.variables}
          context={props.context}
        />
      ))}
    </Card>
  );
};
