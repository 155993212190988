import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AttachmentForm } from './attachment.form';
import { AttachmentDto } from '../../../models/data.models';
import { getAttachment, updateAttachment } from '../attachments.store';

export type AttachmentEditProps = {
  attachmentId?: number | null;
  onAttachmentCreated?: (attachment: AttachmentDto) => void;
  onAttachmentUpdated?: (attachment: AttachmentDto) => void;
  onAttachmentLoaded?: (attachment: AttachmentDto) => void;
  onCancel?: () => void;
};

const initialState: AttachmentDto = {
  attachmentId: 0,
  attachmentType: null,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: null,
  fileName: '',
  fileUri: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  parentId: 0,
  parentType: null,
  previewUri: null,
  thumbnailUri: null,
  createdByUserName: '',
  lastModifiedByUserName: '',
  presignedPreviewUri: null,
  presignedThumbnailUri: null,
  presignedFileUri: null,
  links: [],
};

export const AttachmentEdit = ({
  attachmentId,
  onAttachmentLoaded = () => {},
  onAttachmentCreated = () => {},
  onAttachmentUpdated = () => {},
  onCancel = () => {},
}: AttachmentEditProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [attachment, setAttachment] = useState<AttachmentDto | null>(null);
  const [initialValues, setInitialValues] = useState<AttachmentDto | null>(
    initialState,
  );

  useEffect(() => {
    if (attachmentId) {
      getAttachment({ attachmentId }).then((attachmentDto: AttachmentDto) => {
        setAttachment(attachmentDto);
        setInitialValues(attachmentDto);
        setIsLoading(false);
        onAttachmentLoaded(attachmentDto);
      });
    } else {
      throw new Error('Attachment keys were not provided');
    }
  }, [attachmentId]);

  const onSubmit = (data: AttachmentDto) => {
    setIsSending(true);
    updateAttachment(data)
      .then((result) => {
        getAttachment(data).then((value) => {
          onAttachmentUpdated(value);
        });
      })
      .finally(() => setIsSending(false));
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <div className="m-1">
      <div className="col-12">
        <AttachmentForm initialValues={initialValues} onSubmit={onSubmit}>
          <div className="row">
            <div className="mr-4">
              <img className="edit-pen-icon"></img>
            </div>
            <div>
              <h2 className="mb-2">Edit attachment</h2>
              <p color="#475467">Please enter new name for attachment</p>
            </div>
          </div>
          <div className="mb-4 mt-4">
            <AttachmentForm.FileName />
          </div>
          <div className="justify-space-between row">
            <div className="col-6">
              <Button
                color="primary"
                name="save-attachment"
                className="btn-block"
                type="submit"
              >
                Save
              </Button>
            </div>
            <div className="col-6">
              <Button
                className="btn-block btn-outline-secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </AttachmentForm>
      </div>
    </div>
  );
};
