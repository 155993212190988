import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ContactDto, OrderDto, OrderTypes } from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  UninvoicedOrderDtoForListPagedResult,
  UninvoicedOrderForListDto,
} from '../../../models/custom.models';
import { GetOrderParams } from '../../orders/orders.service';
import { deleteOrder } from '../../orders/orders.store';
import { ORDER_CREATE_PATH } from '../../orders/order.route';
import { InvoiceManagerGrid } from '../../common/components/grid/invoiceManager-grid.component';
import {
  invoiceManagerStore,
  getUnInvoicedOrders,
  updateUninvoicedOrdersColumns,
} from '../invoiceManager.store';
import { GenerateInvoiceDialog } from './generateInvoice.dialog';
import { OrderDialog } from '../../orders/components/order.dialog';
import { SelectContactDialog } from '../../contacts/components/select-contact.dialog';
import { getContacts } from '../../contacts/contacts.store';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { ParcelShipmentDialog } from '../../parcelShipments/components/parcelShipment.dialog';
import { QuoteDialog } from '../../quotes/components/quote.dialog';
import { WarehouseReceiptDialog } from '../../warehouseReceipts/components/warehouseReceipt.dialog';
import { PurchaseDialog } from '../../purchases/components/purchase.dialog';
import { CommodityDialog } from '../../commodities/components/commodity.dialog';
import { AirShipmentOrderDialog } from '../../airShipmentOrders/components/airShipmentOrder.dialog';
import { OceanShipmentOrderDialog } from '../../oceanShipment/components/oceanShipmentOrder.dialog';

export type InvoiceManagerListProps = {
  goToDetails?: (orderParams: GetOrderParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (order: OrderDto, orderId: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const InvoiceManagerList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch = () => {},
}: InvoiceManagerListProps) => {
  const [
    uninvoicedOrders,
    setUninvoicedOrders,
  ] = useState<UninvoicedOrderDtoForListPagedResult | null>(null);

  useEffect(() => {
    getOrdersData();
  }, [offset, limit, sort, filter, search]);
  const getOrdersData = () => {
    getUnInvoicedOrders({ offset, limit, sort, filter, search }).then(
      (ordersData) => {
        const uninvoicedOrderDtoResult: UninvoicedOrderDtoForListPagedResult = {
          limit: ordersData.limit,
          offset: ordersData.offset,
          links: ordersData.links,
          totalCount: ordersData.totalCount,
          items: ordersData.items.map<UninvoicedOrderForListDto>((item) => {
            const resultOrder: UninvoicedOrderForListDto = item;
            resultOrder.shippingDateString = resultOrder.shippingDate
              ? getFormattedDate(resultOrder.shippingDate, false)
              : null;
            resultOrder.carrierDriverNamesString = resultOrder.carrierDriverNames.join(
              ', ',
            );
            resultOrder.customerNamesString = resultOrder.customerNames.join(
              ', ',
            );
            return resultOrder;
          }),
        };
        setUninvoicedOrders(uninvoicedOrderDtoResult);
      },
      () => {},
    );
  };
  const { uninvoicedOrderColumns: columns } = useStore(invoiceManagerStore);

  const onDeleteOrder = (order: OrderDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${order.orderId} Order`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteOrder(order).then(
            () => {
              onDelete();
              getOrdersData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditOrder = (order: OrderDto) => {
    goToDetails({ orderId: order.orderId });
  };
  const navigate = useNavigate();
  const onCreateNewOrder = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(createPath);
  };
  const onChangeItem = (data?: any[]) => {
    setUninvoicedOrders((ordersDto) => {
      ordersDto.items = data;
      return { ...ordersDto };
    });
  };

  const onInvoiceGenerated = () => {
    getOrdersData();
  };

  const generateInvoice = (order) => {
    getContacts({
      filter: order.customerIds.map((x) => `contactId: ${x}`).join(' OR '),
      limit: order.customerIds.length,
      search: null,
      offset: 0,
      sort: null,
    }).then(
      (result) => {
        showDialog({
          dialog: SelectContactDialog,
          props: {
            title: 'Select Customer',
            className: 'select-contact-modal',
            contacts: result.items,
            multiple: false,
            header: 'Select Order Carrier',
            placeholder: 'Select Order Carrier',
            required: true,
            onContactsSelected: (contacts: ContactDto[]) => {},
          },
        }).then(
          (contacts) => {
            if (contacts) {
              showDialog({
                dialog: GenerateInvoiceDialog,
                props: {
                  className: 'generate-invoice-modal',
                  title: 'Generate Invoice',
                  orderId: order.orderId,
                  customerId: contacts[0].contactId,
                  transactionNumber: order.orderId.toString(),
                  onInvoiceGenerated: onInvoiceGenerated,
                },
              }).then(
                (result) => {},
                () => {},
              );
            }
          },
          () => {},
        );
      },
      () => {},
    );
  };
  const onOrderSelect = (order, orderId) => {
    let dialogType;
    let orderType;

    switch (order.orderType) {
      case OrderTypes.ParcelShipment:
        dialogType = ParcelShipmentDialog;
        orderType = 'Parcel Shipment';
        break;
      case OrderTypes.Order:
        dialogType = OrderDialog;
        orderType = 'Order';
        break;
      case OrderTypes.Quote:
        dialogType = QuoteDialog;
        orderType = 'Quote';
        break;
      case OrderTypes.WarehouseReceipt:
        dialogType = WarehouseReceiptDialog;
        orderType = 'Warehouse Receipt';
        break;
      case OrderTypes.Purchase:
        dialogType = PurchaseDialog;
        orderType = 'Purchase';
        break;
      case OrderTypes.Commodity:
        dialogType = CommodityDialog;
        orderType = 'Commodity';
        break;
      case OrderTypes.AirShipmentOrder:
        dialogType = AirShipmentOrderDialog;
        orderType = 'Air Shipment Order';
        break;
      case OrderTypes.OceanShipmentOrder:
        dialogType = OceanShipmentOrderDialog;
        orderType = 'Ocean Shipment Order';
        break;
      default:
        throw new Error(`Unknown order type: ${order.orderType}`);
    }

    if (onSelect) onSelect(order, orderId);
    showDialog({
      dialog: dialogType,
      props: {
        orderId: order.orderId,
        title: `Update ${orderType}`,
        className: '',
        onInvoiceGenerated: onInvoiceGenerated,
      },
    }).then((invoice) => {
      if (invoice !== null) {
        getOrdersData();
      }
    });
  };

  return (
    <>
      <InvoiceManagerGrid
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={null}
        data={uninvoicedOrders?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={uninvoicedOrders?.totalCount}
        sort={sort}
        onDelete={onDeleteOrder}
        onSort={(field) => {
          onSort(field);
        }}
        search={search}
        onEdit={onEditOrder}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateUninvoicedOrdersColumns}
        onSelect={onOrderSelect}
        onChangeItem={onChangeItem}
        filter={filter}
        onFilter={onFilter}
        onSearch={onSearch}
        generateInvoice={generateInvoice}
      />
    </>
  );
};
