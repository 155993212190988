import { LinkDto, ContactType, Column } from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';

type QuotesStoreState = {
  links: LinkDto[];
  quoteColumns: Column[];
};

export const updateQuotesColumns = createEvent<Column[]>();

const defaultState: QuotesStoreState = {
  links: [],
  quoteColumns: [
    {
      name: 'orderNumber',
      visible: true,
      title: 'Load',
      showFilter: false,
      sortName: 'orderNumber~ToInt32',
    },
    {
      name: 'billToContactName',
      visible: true,
      title: 'Customer / Lead',
      type: 'contact',
      contactType: ContactType.Customer,
      contactTypes: [
        ContactType.Customer,
        ContactType.Vendor,
        ContactType.ForwardingAgent,
        ContactType.Contact,
      ],
      dataField: 'BillToContactId',
      showFilter: true,
      sortName: 'BillToContact.Name',
    },
    {
      name: 'carrierContactName',
      visible: true,
      title: 'Carrier',
      type: 'contact',
      contactType: ContactType.Carrier,
      contactTypes: [ContactType.Carrier, ContactType.Driver],
      showFilter: false,
    },
    {
      name: 'firstOrderPickupsPickDate',
      visible: true,
      title: 'Ship Date',
      showFilter: false,
      type: 'date',
      dataField: 'OrderPickups[0].PickDate',
    },
    {
      name: 'firstOrderDeliveriesDeliveryDate',
      visible: true,
      title: 'Del Date',
      showFilter: false,
      type: 'date',
      dataField: 'OrderDeliveries[0].DeliveryDate',
    },
    {
      name: 'firstOrderPickupsShipperAddressName',
      visible: true,
      title: 'Origin',
      showFilter: false,
    },
    {
      name: 'firstOrderDeliveriesConsigneeAddressName',
      visible: true,
      title: 'Destination',
      showFilter: false,
    },
    {
      name: 'createdByUserName',
      visible: true,
      title: 'User',
      dataField: 'CreatedUser.UserName',
      showFilter: true,
      sortName: 'CreatedUser.UserName',
    },
  ],
};

export const quotesStore: Store<QuotesStoreState> = createStore(
  defaultState,
).on(updateQuotesColumns, (state, payload) => {
  return { ...state, quoteColumns: payload };
});
