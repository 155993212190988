import {
  LinkDto,
  LinkResourceBaseDto,
  ReceiveDriverPayCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { DriverPayManagerForListDtoPagedResult } from '../../models/custom.models';
import { CREATE_ORDER_LINK_KEY } from '../orders/orders.service';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_DRIVER_PAYS_LINK_KEY = 'get-driver-pays';
export const RECEIVE_DRIVER_PAY_LINK_KEY = 'receive-driver-pay';

export const getDriverPaysListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<DriverPayManagerForListDtoPagedResult> => {
  if (resource) {
    const getOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_DRIVER_PAYS_LINK_KEY,
    );
    if (getOrdersListLink) {
      const result = await execLink(getOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_DRIVER_PAYS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const receiveDriverPayRequest = async (
  resource: LinkResourceBaseDto,
  driverPay: ReceiveDriverPayCommandValues,
) => {
  const receiveDriverPayLink = resource?.links?.find(
    (x: LinkDto) => x.rel === RECEIVE_DRIVER_PAY_LINK_KEY,
  );
  if (receiveDriverPayLink) {
    const result = await execLink(receiveDriverPayLink, driverPay);
    return result.data;
  }
  addMessage({
    message: `Action ${RECEIVE_DRIVER_PAY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
