import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  EventDefinitionDto,
  EventDefinitionDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  eventDefinitionStore,
  getEventDefinitionsColumnsFx,
  getEventDefinitionsFx,
  deleteEventDefinitionFx,
  exportEventDefinitionsFx,
  importEventDefinitionsFx,
} from '../eventDefinitions.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_EVENTDEFINITION_LINK_KEY,
  DELETE_EVENTDEFINITION_LINK_KEY,
  IMPORT_EVENTDEFINITIONS_LINK_KEY,
  EXPORT_EVENTDEFINITIONS_LINK_KEY,
  GetEventDefinitionParams,
} from '../eventDefinitions.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { EventDefinitionDialog } from './eventDefinition.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type EventDefinitionsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (eventDefinitionParams: GetEventDefinitionParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (viewName: string) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    eventDefinition: EventDefinitionDto,
    eventDefinitionParams: GetEventDefinitionParams,
  ) => void;
};

export const EventDefinitionsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: EventDefinitionsListProps) => {
  const [
    eventDefinitions,
    setEventDefinitions,
  ] = useState<EventDefinitionDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    eventDefinitionColumns: columns,
    defaultEventDefinitionColumns: defaultColumns,
  } = useStore(eventDefinitionStore);

  useEffect(() => {
    getEventDefinitionsColumnsFx();
  }, []);

  const getEventDefinitions = useCallback(async () => {
    const eventDefinitionsData = await getEventDefinitionsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(eventDefinitionsData));
    setEventDefinitions(eventDefinitionsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: EventDefinitionDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_EVENTDEFINITIONS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importEventDefinitionsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getEventDefinitions();
          },
          result?.links,
        ),
        getAction(
          EXPORT_EVENTDEFINITIONS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportEventDefinitionsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getEventDefinitions],
  );

  useEffect(() => {
    getEventDefinitions();
  }, [getEventDefinitions]);

  const onDeleteEventDefinition = useCallback(
    async (eventDefinition: EventDefinitionDto) => {
      if (
        userHas(
          DELETE_EVENTDEFINITION_LINK_KEY,
          eventDefinitions?.items[0]?.links,
        )
      ) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Event definition`,
            message: 'Are you sure you want to delete event definition?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteEventDefinitionFx(eventDefinition);
            getEventDefinitions();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getEventDefinitions, onDelete, eventDefinitions],
  );

  const onEditEventDefinition = (eventDefinition: EventDefinitionDto) => {
    if (eventDefinition)
      goToDetails({ eventDefinitionId: eventDefinition.eventDefinitionId });
  };

  const onEventDefinitionSelect = useCallback(
    async (eventDefinition) => {
      const selectedEventDefinition = await showDialog({
        dialog: EventDefinitionDialog,
        props: {
          eventDefinitionId: eventDefinition.eventDefinitionId,
          title: 'Update Event definition',
        },
      });
      if (selectedEventDefinition) {
        getEventDefinitions();
      }
    },
    [getEventDefinitions],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          rowKeys={['eventDefinitionId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={eventDefinitions.items}
          onLimitChanged={onLimitChanged}
          total={eventDefinitions.totalCount}
          onDelete={onDeleteEventDefinition}
          onSort={onSort}
          onEdit={onEditEventDefinition}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onEventDefinitionSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {eventDefinitions?.totalCount === 0 && !search && (
          <ListElementsNotFound
            entityName="Event definition"
            entityLinkKey={CREATE_EVENTDEFINITION_LINK_KEY}
            onClick={goToCreate}
          />
        )}
      </>
    );
  }
};
