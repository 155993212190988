import React from 'react';

import {
  Column,
  CommodityDto,
  CommodityStatuses,
  OrderDto,
} from '../../../../models/data.models';
import { StatusBadge } from '../../../common/components/status-badge/status-badge';

export type GridProps = {
  order?: OrderDto;
  commodity: CommodityDto;
  columns?: Column[];
  setIds?: (selectedItem: any, toggleState: boolean, order: any) => void;
  isSelected: (selectedValue: any) => boolean;
  isCheckBoxDisabled?: (value: any) => boolean | null;
  getStatusClassName?: (value: any) => string;
  level?: number;
};

export const CommoditySelector = ({
  order,
  commodity,
  columns,
  setIds,
  isSelected,
  isCheckBoxDisabled,
  getStatusClassName,
  level = null,
}: GridProps) => {
  return (
    <div className={'pt-2'}>
      <div className="d-flex">
        <div
          className={'pl-4'}
          style={{
            width: `calc(100% / ${columns.length})`,
          }}
        >
          <label
            key={commodity?.commodityId}
            className="custom-control custom-checkbox"
            style={{
              marginBottom: '12px',
              marginLeft: level && `calc(20px * ${level})`,
            }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              onChange={(event) => {
                setIds([commodity], event.target.checked, order);
              }}
              checked={isSelected([commodity])}
              disabled={
                isCheckBoxDisabled ? isCheckBoxDisabled([commodity]) : false
              }
            />
            <span
              className="pl-3 custom-control-label"
              style={{ fontSize: '12px' }}
            >
              {commodity?.description}
            </span>
          </label>
        </div>
        <div style={{ marginBottom: '12px' }} className="pl-2">
          {commodity?.commodityStatus && (
            <StatusBadge
              label={commodity?.commodityStatus?.statusName}
              stage={commodity?.commodityStatus?.statusStage}
              enumType={CommodityStatuses}
            />
          )}
        </div>
      </div>
    </div>
  );
};
