import React, { useEffect, useState } from 'react';
import { PortDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { showDialog } from '../../common/dialog.store';
import { getPortsFx, getPortFx } from '../ports.store';
import {
  PORT_ENTITY_NAME,
  CREATE_PORT_LINK_KEY,
  GET_PORT_LINK_KEY,
} from '../ports.service';
import { PortDialog } from './port.dialog';
import { userHas } from '../../auth/auth.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useStore } from 'effector-react';
import {
  clearPortsLoadOptionsCache,
  portReactSelectStore,
  updateLastEditedPort,
} from './port-react-select.store';
import { clearMessages } from '../../common/messages.store';

export const SelectPortPaginate = ({
  id = 'portId',
  nameId = 'portName',
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangePort = (option, context) => {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  readonly = false,
  classNamePrefix = 'select',
  validate = () => Promise.resolve(''),
  showCreateDialogTitle = 'Create Port',
  canUpdateEntity = true,
}) => {
  const context = useContext ? useFormikContext() : null;
  const [value, setValue] = useState(null);
  const [valueIsLoaded, setValueIsLoaded] = useState(false);
  useEffect(() => {
    if (
      (!Number.isNaN(selectedValue) && selectedValue > 0) ||
      selectedValue?.length > 0
    ) {
      getPortFx(selectedValue).then(
        (data) => setValue(data),
        () => {},
      );
    } else {
      setValue(selectedValue);
    }
    setValueIsLoaded(true);
  }, [selectedValue]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responsePortDtoPagedResult: PortDtoPagedResult = await getPortsFx({
      limit: 10,
      offset: (page - 1) * 10,
      sort,
      filter,
      search: searchQuery,
    });

    return {
      options: responsePortDtoPagedResult.items,
      hasMore: responsePortDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto = null) => {
    if (typeof onChangePort === 'function') {
      onChangePort(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };
  const onChangeWithContext = (port, context) => {
    onChange(port, context);
    if (context) {
      if (isMulti === true) {
        if (port?.length > 0) {
          context.setFieldValue(id, port?.length);
          context.setFieldError(id, undefined);
        } else {
          if (required) {
            context.setFieldValue(id, '');
          }
        }
      } else {
        context.setFieldValue(id, port?.portId);
        context.setFieldValue(nameId, port?.name);
        context.setFieldError(id, undefined);
        if (required && !port) {
          context.setFieldValue(id, '');
        }
      }
    }
  };

  const { clearLoadOptionsCache, lastEditedPort } = useStore(
    portReactSelectStore,
  );

  const onPortEditIconClick = () => {
    if (value?.portId) {
      showDialog({
        dialog: PortDialog,
        props: {
          portId: value.portId,
          countryCode: value.countryCode,
          title: 'Update Port',
        },
      }).then(
        async (port) => {
          if (port !== null) {
            clearPortsLoadOptionsCache();
            updateLastEditedPort(
              await getPortFx({
                portId: port.portId,
                countryCode: port.countryCode,
              }),
            );
          }
        },
        () => {},
      );
    }
  };

  useEffect(() => {
    if (
      valueIsLoaded &&
      lastEditedPort &&
      value?.countryCode &&
      value?.portId &&
      lastEditedPort.countryCode === value.countryCode &&
      lastEditedPort.portId === value.portId
    ) {
      onChangeWithContext(lastEditedPort, context);
    }
  }, [lastEditedPort, valueIsLoaded]);

  if (useContext) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti &&
                value &&
                userHas(GET_PORT_LINK_KEY) &&
                canUpdateEntity ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onPortEditIconClick}
                    data-cy={`port-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={PORT_ENTITY_NAME}
              canCreateNewEntity={userHas(CREATE_PORT_LINK_KEY)}
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: PortDialog,
                  props: {
                    title: showCreateDialogTitle,
                    portId: 0,
                  },
                }).then(
                  (port) => {
                    clearPortsLoadOptionsCache();
                    return port;
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.portId}
              getOptionLabel={(option) => `${option?.portId} - ${option?.name}`}
              onChange={(port?: any) => onChangeWithContext(port, context)}
              isDisabled={isDisabled}
              isClearable={readonly ? false : isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={readonly ? false : isSearchable}
              placeholder={placeholder}
              menuIsOpen={readonly ? false : undefined}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              classNamePrefix={classNamePrefix}
              components={{
                IndicatorSeparator: () => null,
              }}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={PORT_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_PORT_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: PortDialog,
            props: {
              title: showCreateDialogTitle,
              portId: 0,
            },
          }).then(
            (port) => {
              clearPortsLoadOptionsCache();
              return port;
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.portId}
        getOptionLabel={(option) => `${option?.name}`}
        onChange={(port?: any) => {
          onChange(port);
        }}
        classNamePrefix={classNamePrefix}
        menuIsOpen={readonly ? false : undefined}
        isDisabled={isDisabled}
        isClearable={readonly ? false : isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={readonly ? false : isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
