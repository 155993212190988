import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CommodityForm } from './commodity.form';
import {
  CommodityDto,
  CommodityStatuses,
  CommodityTrackingNumberDto,
  CommodityTypeDto,
  CustomFieldDto,
  CustomFieldEntityType,
  DangerousItemsValues,
  DimensionsUnit,
  JobDto,
  OrderTypes,
  PackageTypeDto,
  PurposeOfCommodityValues,
  VolumeUnit,
  WarehouseLocationDto,
  WeightUnit,
} from '../../../models/data.models';
import {
  createCommodity,
  deleteCommodity,
  getCommodity,
  updateCommodity,
} from '../commodities.store';
import { Panel } from '../../common/components/panel/panel.component';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { ReactSelectItem } from '../../../models/custom.models';
import {
  countTotalContainerCommodityWeight,
  generateValidationSchemaWithCustomFields,
  getEnumKeyByValue,
  getEnumValues,
  validateNumberInput,
  validatePositiveNumberInput,
} from '../../../utils/helper.utils';
import { CommodityDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { authStore } from '../../auth/auth.store';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { FormContext } from '../../common/components/form/form.component';
import { CommoditiesForOrderList } from './commodities-forOrder-list.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { GetCommodityParams } from '../commodities.service';
import { CommodityDialog } from './commodity.dialog';
import {
  convertDimension,
  convertWeight,
  getVolume,
} from '../../../utils/converter.utils';
import { FormikProps } from 'formik';
import { organizationsStore } from '../../organization/organization.store';
import { CommodityTrackingNumbersForOrderList } from '../../commodityTrackingNumbers/components/commodityTrackingNumbers-forOrder-list.component';
import {
  CreateTrackingNumbersForCommodityParams,
  createTrackingNumbersForCommodity,
  getCommodityTrackingNumbers,
} from '../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import { CommodityTrackingNumberDialog } from '../../commodityTrackingNumbers/components/commodityTrackingNumbers.dialog';
import { GetCommodityTrackingNumberParams } from '../../commodityTrackingNumbers/commodityTrackingNumbers.service';
import { $enum } from 'ts-enum-util';

export type CommodityEditProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  organizationId?: number | null;
  filter?: string;
  commodityId?: number | null;
  defaultCommodity?: CommodityDto | null;
  onCommodityCreated?: (commodity: CommodityDto) => void;
  onCommodityUpdated?: (commodity: CommodityDto) => void;
  onCommodityLoaded?: (commodity: CommodityDto) => void;
  onCancel?: () => void;
  saveButtonRenderCondition?: boolean;
  isEditMode?: boolean | null;
  isEditModeInCommodities?: boolean | null;
  saveBtnLabel?: string;
};

const initialState: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  commodityTypeId: CommodityDefaultValues.commodityTypeId,
  commodityTypeCode: CommodityDefaultValues.description,
  links: [],
  customValues: CommodityDefaultValues.customValues,
  warehouseLocationId: CommodityDefaultValues.warehouseLocationId,
  warehouseLocation: CommodityDefaultValues.warehouseNavigate,
  containerCommodities: CommodityDefaultValues.containerCommodities,
  valueByTotal: CommodityDefaultValues.valueByTotal,
};

const containerCommodityInitialState: CommodityDto = {
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  note: CommodityDefaultValues.note,
  commodityTypeId: CommodityDefaultValues.commodityTypeId,
  customValues: CommodityDefaultValues.customValues,
  containerCommodityId: null,
  warehouseLocationId: CommodityDefaultValues.warehouseLocationId,
  containerCommodities: CommodityDefaultValues.containerCommodities,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  organizationId: null,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  weightTotal: CommodityDefaultValues.weightTotal,
  commodityTypeCode: CommodityDefaultValues.description,
  valueByTotal: false,
  links: [],
};

const numberSchema = Yup.string()
  .transform((value) => (value === null ? '' : value))
  .test('numberFormat', 'Incorrect number format', (value) => {
    return (
      (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
        value?.toString(),
      ) &&
        Number(value) < Number.MAX_SAFE_INTEGER &&
        Number(value) > Number.MIN_SAFE_INTEGER) ||
      value === '' ||
      value === undefined
    );
  })
  .test('length', 'Max value is 999999', (value) => {
    return value === undefined || Number(value) <= 999999;
  })
  .nullable(true);

let commoditySchema = Yup.object().shape({
  description: Yup.string().required("Can't be blank").nullable(true),
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return value === undefined || Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
  quantity: numberSchema,
  unit: numberSchema,
  unitaryValue: numberSchema,
  dimensionsUnit: Yup.string().required("Can't be blank").nullable(true),
  weightUnit: Yup.string().required("Can't be blank").nullable(true),
  volumeUnit: Yup.string().required("Can't be blank").nullable(true),
});

export const newContainerCommoditySchema = Yup.object().shape({
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
});

export const CommodityEdit = ({
  commodityId,
  isEditMode = false,
  defaultCommodity = null,
  onCommodityCreated = () => {},
  onCommodityUpdated = () => {},
  onCancel = () => {},
  saveButtonRenderCondition = false,
  saveBtnLabel = 'Save Commodity',
  isEditModeInCommodities = false,
}: CommodityEditProps) => {
  const limit = 20;

  const { user: currentUser } = authStore.getState();
  if (saveButtonRenderCondition === null) {
    saveButtonRenderCondition =
      currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;
  }

  defaultCommodity &&
    (defaultCommodity.valueTotal =
      (defaultCommodity?.quantity || 0) *
      (defaultCommodity?.unitaryValue || 0));
  const isCreateModeForEntity = !commodityId || commodityId == 0;
  const isCreateMode = isCreateModeForEntity && !isEditMode;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [commodity, setCommodity] = useState<CommodityDto | null>(
    defaultCommodity || initialState,
  );
  const [initialValues, setInitialValues] = useState<CommodityDto | null>(
    defaultCommodity || initialState,
  );
  const [packageType, setPackageType] = useState<{
    packageTypeId: string;
    name: string;
  }>(null);
  const [commodityType, setCommodityType] = useState<any>(null);
  const [
    warehouseLocation,
    setWarehouseLocation,
  ] = useState<WarehouseLocationDto>(null);
  const [jobId, setJobId] = useState<any>(null);

  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [offset, setOffset] = useState(0);

  const [containerCommodities, setContainerCommodities] = useState<
    CommodityDto[] | null
  >([]);

  const [commodityTrackingNumbers, setCommodityTrackingNumbers] = useState<
    CommodityTrackingNumberDto[] | null
  >([]);
  const commodityFormRef = useRef<HTMLFormElement>();

  const forceUpdate: () => void = useState()[1].bind(null, {});

  const getCustomValues = (isCreateMode: boolean = false) => {
    const purposeOfcommoditiesValues = {
      ...PurposeOfCommodityValues,
      value: '',
    };
    const dangerousItemsValues = { ...DangerousItemsValues, value: [] };

    if (!isCreateMode && !isEditMode) {
      purposeOfcommoditiesValues.value =
        initialValues.customValues?.purposeOfCommodity;
      if (initialValues.customValues?.dangerousItems) {
        dangerousItemsValues.value = initialValues.customValues
          ?.dangerousItems as string[];
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Commodity} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        commoditySchema = generateValidationSchemaWithCustomFields(
          customFields,
          commoditySchema,
        );
        setCustomFields(customFields);
      },
      () => {},
    );
    if (isCreateMode) {
      getCustomValues(true);
      setIsLoading(false);
      if (defaultCommodity != null) {
        setPackageType((packageTypeData) => {
          if (!packageTypeData) {
            packageTypeData = {
              name: null,
              packageTypeId: null,
            };
          }
          packageTypeData.packageTypeId = defaultCommodity?.packageTypeId?.toString();
          packageTypeData.name = defaultCommodity?.packageTypeName;
          return { ...packageTypeData };
        });
        setWarehouseLocation(defaultCommodity?.warehouseLocation);
        setContainerCommodities(defaultCommodity?.containerCommodities);
      }
    } else if (commodityId || isEditMode) {
      if (defaultCommodity === null) {
        getCommodity({ commodityId, commodity: null }).then(
          (commodityDto: CommodityDto) => {
            const dangerous = (commodityDto?.customValues
              ?.dangerousItems as string)?.split(',');
            const parsedCommodityDto: CommodityDto = {
              ...commodityDto,
              customValues: {
                ...commodityDto.customValues,
                dangerousItems: dangerous as DangerousItemsValues[],
              },
            };
            setCommodity(parsedCommodityDto);
            setInitialValues(parsedCommodityDto);
            setCommodityType(commodityDto?.commodityType);
            setPackageType((packageTypeData) => {
              if (!packageTypeData) {
                packageTypeData = {
                  name: null,
                  packageTypeId: null,
                };
              }
              packageTypeData.packageTypeId = commodityDto?.packageTypeId?.toString();
              packageTypeData.name = commodityDto?.packageTypeName;
              return { ...packageTypeData };
            });
            setWarehouseLocation(parsedCommodityDto?.warehouseLocation);
            setContainerCommodities(parsedCommodityDto?.containerCommodities);
          },
        );
      } else {
        getCustomValues();
        setIsLoading(false);
        setCommodityType(defaultCommodity?.commodityType);
        setPackageType((packageTypeData) => {
          if (!packageTypeData) {
            packageTypeData = {
              name: null,
              packageTypeId: null,
            };
          }
          packageTypeData.packageTypeId = defaultCommodity?.packageTypeId?.toString();
          packageTypeData.name = defaultCommodity?.packageTypeName;
          return { ...packageTypeData };
        });
        setWarehouseLocation(defaultCommodity?.warehouseLocation);
        setContainerCommodities(defaultCommodity?.containerCommodities);
      }
    } else {
      throw new Error('Commodity keys were not provided');
    }
  }, [commodityId]);

  useEffect(() => {
    if (commodityId > 0) {
      getCommodityTrackingNumbers({
        filter: `commodityId:${commodityId}`,
      }).then((trackingNumbers) => {
        setCommodityTrackingNumbers(trackingNumbers?.items);
      });
    }
  }, [commodityId]);

  const getCommodityTrackingNumbersForOrder = (): CommodityTrackingNumberDto[] => {
    return commodityTrackingNumbers?.sort(
      (a, b) => a.commodityTrackingNumberId - b.commodityTrackingNumberId,
    );
  };

  useEffect(() => {
    if (commodityId && initialValues.commodityId) {
      getCustomValues();
    }
  }, [commodityId, initialValues]);

  const onSubmit = (data: CommodityDto) => {
    if (Array.isArray(data?.customValues?.dangerousItems)) {
      data.customValues.dangerousItems = data.customValues.dangerousItems.join(
        ',',
      );
    }

    if (data && packageType?.packageTypeId) {
      data.packageTypeId = parseInt(packageType.packageTypeId);
      data.packageTypeName = packageType?.name;
    }
    if (data?.containerCommodities)
      data.containerCommodities = containerCommodities;
    if (defaultCommodity === null || isEditModeInCommodities) {
      setIsSending(true);
      if (isCreateMode) {
        createCommodity(data)
          .then((result) => {
            createCommodityTrackingNumbers(result.commodityId);
            onCommodityCreated(result);
          })
          .finally(() => setIsSending(false));
      } else {
        updateCommodity(data)
          .then((result) => {
            createCommodityTrackingNumbers(data.commodityId);
            onCommodityUpdated(data);
          })
          .finally(() => setIsSending(false));
      }
    } else {
      setIsSending(true);
      const parsedContainerCommodities: CommodityDto[] = data.containerCommodities?.map(
        (el) => {
          return {
            ...el,
            containerCommodityId:
              el.containerCommodityId || el.containerCommodity?.commodityId,
            containerCommodity: null,
          };
        },
      );
      const commodity: CommodityDto = {
        ...data,
        containerCommodities: parsedContainerCommodities,
      };

      if (isCreateMode || commodity.commodityId === null) {
        createCommodity(commodity)
          .then((result) => {
            onCommodityCreated(result);
          })
          .finally(() => setIsSending(false));
      } else {
        updateCommodity(commodity)
          .then((result) => {
            createCommodityTrackingNumbers(result.commodityId);
            //add package name, cause it isn't retutned from backed due to EF problems
            //TODO: fix reseting linked entity after changing id in update commodity command
            onCommodityUpdated({
              ...result,
              packageTypeName: packageType.name,
              commodityTypeName: commodityType?.description,
            });
          })
          .finally(() => setIsSending(false));
      }
    }
  };

  const createCommodityTrackingNumbers = (commodityId: number) => {
    if (commodityId != null) {
      const trackingNumbers: CreateTrackingNumbersForCommodityParams = {
        commodityId: commodityId,
        values: commodityTrackingNumbers,
      };
      createTrackingNumbersForCommodity(trackingNumbers);
    }
  };

  const onCommodityTrackingNumberCreated = (
    trackingNumber: CommodityTrackingNumberDto,
  ) => {
    if (!commodityTrackingNumbers) {
      setCommodityTrackingNumbers([]);
    }
    setCommodityTrackingNumbers((trackingNumbersDto) => {
      if (trackingNumber.isPrimary)
        trackingNumbersDto.forEach((x) => (x.isPrimary = false));
      trackingNumbersDto.push(trackingNumber);
      return [...trackingNumbersDto];
    });
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const goToDetailsCommodityOfContainer = async (
    commodityParams: GetCommodityParams,
  ) => {
    if (commodityParams.commodity) {
      let innerCommodity;
      if (commodityParams.commodity.commodityId) {
        innerCommodity = await getCommodity({
          commodityId: commodityParams.commodity.commodityId,
        });
      } else {
        innerCommodity = commodityParams.commodity;
      }
      if (innerCommodity) {
        showDialog({
          dialog: CommodityDialog,
          props: {
            key: innerCommodity.commodityId,
            title: `Commodity of Container ${commodity.description}`,
            commodity: innerCommodity,
            commodityId: innerCommodity?.commodityId,
            saveButtonRenderCondition: true,
            isEditMode: true,
          },
        }).then(
          async (result) => {
            if (result) {
              updateCommodity(result).then(() => {
                const updatedContainerCommodities = containerCommodities.map(
                  (containerCommodity) => {
                    if (
                      containerCommodity.commodityId == result?.commodityId ||
                      (!containerCommodity.commodityId &&
                        !commodityParams.commodity.commodityId)
                    ) {
                      return result;
                    }
                    return containerCommodity;
                  },
                );
                setCommodity({
                  ...commodity,
                  containerCommodities: [...updatedContainerCommodities],
                });
                setContainerCommodities(updatedContainerCommodities);
                forceUpdate();
              });
            }
          },
          () => {},
        );
      }
    }
  };

  const goToDetailsCommodityTrackingNumbers = (
    commodityTrackingNumberParams: GetCommodityTrackingNumberParams,
  ) => {
    const cloneCommodityTrackingNumber = Object.assign(
      {},
      commodityTrackingNumberParams.commodityTrackingNumber,
    );
    if (commodityTrackingNumberParams.commodityTrackingNumber) {
      showDialog({
        dialog: CommodityTrackingNumberDialog,
        props: {
          title: 'Update tracking numbers',
          commodityTrackingNumberId:
            commodityTrackingNumberParams?.commodityTrackingNumber
              ?.commodityTrackingNumberId,
          commodityId:
            commodityTrackingNumberParams?.commodityTrackingNumber?.commodityId,
          isEditMode: true,
          defaultCommodityTrackingNumber:
            commodityTrackingNumberParams?.commodityTrackingNumber,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const updatedTrackingNumbers = commodityTrackingNumbers.map(
              (trackingNumber) => {
                if (
                  trackingNumber.commodityTrackingNumberId ===
                  result.commodityTrackingNumberId
                ) {
                  return result;
                } else {
                  return {
                    ...trackingNumber,
                    isPrimary: false,
                  };
                }
              },
            );

            setCommodityTrackingNumbers(updatedTrackingNumbers);
          } else {
            setCommodityTrackingNumbers((oldTrackingNumbers) => {
              const index = oldTrackingNumbers.findIndex(
                (trackingNumber) =>
                  trackingNumber.commodityTrackingNumberId ===
                  cloneCommodityTrackingNumber.commodityTrackingNumberId,
              );
              oldTrackingNumbers[index] = cloneCommodityTrackingNumber;
              return oldTrackingNumbers;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const onDeleteContainerCommodity = (commodity: CommodityDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${commodity.description}" Commodity`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        if (commodity.commodityId) {
          deleteCommodity(commodity).then(() => {
            setContainerCommodities(
              containerCommodities.filter(
                (containerCommodity) =>
                  commodity.commodityId != containerCommodity.commodityId,
              ),
            );
          });
        } else {
          let isDeleted = false;
          const items = containerCommodities.filter((obj) => {
            const compareCommodityResult = obj !== commodity;
            if (!compareCommodityResult && isDeleted === false) {
              isDeleted = true;
              return false;
            }
            return true;
          });
          setContainerCommodities(countTotalContainerCommodityWeight(items));
        }
      }
      return;
    });
  };

  const onCommodityStatusChange = (newValueOrderStatus: ReactSelectItem) => {};

  const onDimensionsUnitChange = (
    data?: { label: string; value: DimensionsUnit },
    context?: FormikProps<CommodityDto>,
  ) => {
    const newLength = convertDimension(
      context.values.length,
      context.values.dimensionsUnit,
      data?.value,
    );
    const newWidth = convertDimension(
      context.values.width,
      context.values.dimensionsUnit,
      data?.value,
    );
    const newHeight = convertDimension(
      context.values.height,
      context.values.dimensionsUnit,
      data?.value,
    );
    setCommodity({
      ...context.values,
      dimensionsUnit: data?.value,
      length: newLength,
      width: newWidth,
      height: newHeight,
    });
    context.setFieldValue('length', newLength);
    context.setFieldValue('width', newWidth);
    context.setFieldValue('height', newHeight);
  };

  const onVolumeUnitChange = (
    data?: { label: string; value: VolumeUnit },
    context?: FormikProps<CommodityDto>,
  ) => {
    setCommodity({
      ...context.values,
      volumeUnit: data?.value,
    });
  };

  const onWarehouseLocationChange = (
    data?: WarehouseLocationDto,
    context?: FormikProps<CommodityDto>,
  ) => {
    setWarehouseLocation(data);
  };

  const onWeightUnitChange = (
    data?: { label: string; value: WeightUnit },
    context?: FormikProps<CommodityDto>,
  ) => {
    const newWeight = convertWeight(
      context.values.weight,
      context.values.weightUnit,
      data?.value,
    );
    setCommodity({
      ...context.values,
      weightUnit: data?.value,
      weight: newWeight,
    });
    context.setFieldValue('weight', newWeight);
  };

  const onSubmitCommodityDto = (
    data: CommodityDto,
    { resetForm, setFieldValue },
  ) => {
    const { currentOrganization } = organizationsStore.getState();
    data.organizationId = currentOrganization.organizationId;
    data.dimensionsUnit = getEnumKeyByValue(DimensionsUnit.In, DimensionsUnit);
    data.weightUnit = getEnumKeyByValue(WeightUnit.Lb, WeightUnit);
    data.volumeUnit = getEnumKeyByValue(VolumeUnit.Vkg, VolumeUnit);
    data.weightTotal = data.pieces * data.weight;
    data.volumePiece = data.length * data.height * data.width;
    data.volumeTotal = data.pieces * data.volumePiece;
    data.length = Number(data.length);
    data.width = Number(data.width);
    data.height = Number(data.height);
    data.weight = Number(data.weight);
    data.containerCommodity = commodity;
    data.containerCommodityId = commodity.commodityId;
    setContainerCommodities((commoditiesDto) => [
      ...(commoditiesDto ?? []),
      data,
    ]);
    setPackageType({
      name: null,
      packageTypeId: null,
    });
    setCommodityType(null);
    resetForm();
    setFieldValue('description', '');
    setFieldValue('pieces', '1');
    setFieldValue('length', '');
    setFieldValue('width', '');
    setFieldValue('height', '');
    setFieldValue('weight', '');
    setFieldValue('packageTypeId', '');
    setFieldValue('packageTypeSelect', '');
  };

  const parseNumberInput = (value: string) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };

  return (
    <div>
      <CommodityForm
        id={`commodity-form-${commodityId}`}
        key={`commodity-form-${commodityId}`}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={commoditySchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <span>
                  <Tab data-cy={'general'}>General</Tab>
                  <Tab
                    data-cy={'commodities'}
                    disabled={isCreateMode && defaultCommodity}
                  >
                    Commodities
                  </Tab>
                  <Tab>Additional</Tab>
                  <Tab>Tracking Numbers</Tab>
                </span>
              </TabList>
              <div>
                <TabPanel>
                  <Panel className={'m-3'}>
                    <div className="row">
                      <div className="col-4">
                        <CommodityForm.Description />
                      </div>
                      <div className={'col-4'}>
                        <CommodityForm.PackageTypeSelect
                          required={false}
                          defaultValue={
                            packageType &&
                            packageType.packageTypeId !== undefined &&
                            packageType.packageTypeId != null &&
                            packageType.name !== undefined &&
                            packageType.name != null
                              ? {
                                  packageTypeId: packageType?.packageTypeId,
                                  name: packageType?.name,
                                }
                              : ''
                          }
                          onChange={(data?: PackageTypeDto, context?: any) => {
                            setPackageType((packageTypeData) => {
                              if (!packageTypeData) {
                                packageTypeData = {
                                  name: null,
                                  packageTypeId: null,
                                };
                              }
                              packageTypeData.packageTypeId = data?.packageTypeId?.toString();
                              packageTypeData.name = data?.name;
                              return { ...packageTypeData };
                            });
                            setInitialValues(() => {
                              return {
                                ...context.values,
                                weight: data?.weight,
                                height: data?.height,
                                length: data?.length,
                                width: data?.width,
                                weightUnit: data?.weight && WeightUnit.Lb,
                                volumeUnit: $enum(VolumeUnit).getKeyOrDefault(
                                  VolumeUnit.Ft,
                                ),
                                dimensionsUnit:
                                  (data?.height ||
                                    data?.length ||
                                    data?.width) &&
                                  DimensionsUnit.In,
                              };
                            });
                            setCommodity(() => {
                              return {
                                ...context.values,
                                weight: data?.weight,
                                height: data?.height,
                                length: data?.length,
                                width: data?.width,
                                weightUnit: data?.weight && WeightUnit.Lb,
                                volumeUnit: $enum(VolumeUnit).getKeyOrDefault(
                                  VolumeUnit.Ft,
                                ),
                                dimensionsUnit:
                                  (data?.height ||
                                    data?.length ||
                                    data?.width) &&
                                  DimensionsUnit.In,
                              };
                            });
                          }}
                          nameId={'packageTypeName'}
                        />
                      </div>
                      <div className="col-4">
                        <CommodityForm.WarehouseLocation
                          defaultValue={warehouseLocation}
                          onChange={(
                            data?: WarehouseLocationDto,
                            context?: FormikProps<CommodityDto>,
                          ) => {
                            onWarehouseLocationChange(data, context);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      {defaultCommodity ? (
                        <div />
                      ) : (
                        <div className={'col-4'}>
                          <CommodityForm.CommodityStatus
                            required={true}
                            defaultValue={commodity?.commodityStatus || null}
                            onChange={onCommodityStatusChange}
                          />
                        </div>
                      )}
                      <div className={'col-4'}>
                        <CommodityForm.CommodityTypeSelect
                          defaultValue={
                            commodity?.commodityTypeId
                              ? {
                                  commodityTypeId: commodity?.commodityTypeId,
                                  code: commodity?.commodityTypeCode,
                                  description: commodity?.commodityTypeName,
                                }
                              : null
                          }
                          onChange={(data?: CommodityTypeDto) => {
                            setCommodityType(data);
                            setCommodity((commodityDto) => {
                              return {
                                ...commodityDto,
                                commodityTypeId: data?.commodityTypeId,
                                commodityTypeCode: data?.code,
                                commodityTypeName: data?.description,
                              };
                            });
                          }}
                        />
                      </div>
                      <div className={'col-4'}>
                        <CommodityForm.JobSelect
                          defaultValue={commodity?.jobId}
                          onChange={(data?: JobDto) => {
                            setJobId(data?.jobId);
                            setCommodity((commodityDto) => {
                              return {
                                ...commodityDto,
                                jobId: data?.jobId,
                              };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-3'}>
                        <CommodityForm.Pieces onKeyDown={validateNumberInput} />
                      </div>
                    </div>

                    <hr className="my-4" />

                    <div className="row">
                      <div className={'col-3'}>
                        <CommodityForm.Length
                          onKeyDown={validatePositiveNumberInput}
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.Width
                          onKeyDown={validatePositiveNumberInput}
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.Height
                          onKeyDown={validatePositiveNumberInput}
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.DimensionsUnit
                          options={getEnumValues(DimensionsUnit)}
                          required={true}
                          defaultValue={
                            commodity?.dimensionsUnit
                              ? {
                                  label:
                                    DimensionsUnit[commodity?.dimensionsUnit],
                                  value: commodity?.dimensionsUnit,
                                }
                              : null
                          }
                          valueInput={
                            commodity?.volumeUnit
                              ? {
                                  label:
                                    DimensionsUnit[
                                      context?.values?.dimensionsUnit
                                    ],
                                  value: context?.values?.dimensionsUnit,
                                }
                              : null
                          }
                          onChange={(
                            data?: { label: string; value: DimensionsUnit },
                            context?: FormikProps<CommodityDto>,
                          ) => {
                            onDimensionsUnitChange(data, context);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className={'offset-3 col-3'}>
                        <div className={'form-border-text'}>PIECE</div>
                      </div>
                      <div className={'col-3'}>
                        <div className={'form-border-text'}>TOTAL</div>
                      </div>
                      <div className={'col-3'}>
                        <div className={'form-border-text'}>MEASURE</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={'col-3 pt-2'}>
                        <div className={'form-border-text'}>WEIGHT</div>
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.Weight
                          onKeyDown={validatePositiveNumberInput}
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.WeightTotal
                          dependencies={['weight', 'pieces']}
                          callback={({ weight, pieces }) =>
                            parseNumberInput(weight) * parseNumberInput(pieces)
                          }
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.WeightUnit
                          options={getEnumValues(WeightUnit)}
                          required={true}
                          defaultValue={
                            commodity?.weightUnit
                              ? {
                                  label: WeightUnit[commodity?.weightUnit],
                                  value: commodity?.weightUnit,
                                }
                              : null
                          }
                          valueInput={
                            commodity?.weightUnit
                              ? {
                                  label:
                                    WeightUnit[context?.values?.weightUnit],
                                  value: context?.values?.weightUnit,
                                }
                              : null
                          }
                          onChange={(
                            data?: { label: string; value: WeightUnit },
                            context?: FormikProps<CommodityDto>,
                          ) => {
                            onWeightUnitChange(data, context);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={'col-3 pt-2'}>
                        <div className={'form-border-text'}>VOLUME</div>
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.VolumePiece
                          dependencies={[
                            'length',
                            'width',
                            'height',
                            'weight',
                            'dimensionsUnit',
                            'weightUnit',
                            'volumeUnit',
                          ]}
                          callback={({
                            length,
                            width,
                            height,
                            dimensionsUnit,
                            volumeUnit,
                          }) =>
                            getVolume(
                              length,
                              width,
                              height,
                              dimensionsUnit,
                              volumeUnit,
                            )
                          }
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.VolumeTotal
                          dependencies={['volumePiece', 'pieces']}
                          callback={({ volumePiece, pieces }) =>
                            parseNumberInput(volumePiece) *
                            parseNumberInput(pieces)
                          }
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.VolumeUnit
                          options={getEnumValues(VolumeUnit)}
                          required={true}
                          defaultValue={
                            commodity?.volumeUnit
                              ? {
                                  label: VolumeUnit[commodity?.volumeUnit],
                                  value: commodity?.volumeUnit,
                                }
                              : null
                          }
                          valueInput={
                            commodity?.volumeUnit
                              ? {
                                  label:
                                    VolumeUnit[context?.values?.volumeUnit],
                                  value: context?.values?.volumeUnit,
                                }
                              : null
                          }
                          onChange={(
                            data?: { label: string; value: VolumeUnit },
                            context?: FormikProps<CommodityDto>,
                          ) => {
                            onVolumeUnitChange(data, context);
                          }}
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-3'}>
                        <CommodityForm.Quantity
                          onKeyDown={validatePositiveNumberInput}
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.Unit
                          onKeyDown={validatePositiveNumberInput}
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.UnitaryValue
                          onKeyDown={validatePositiveNumberInput}
                        />
                      </div>
                      <div className={'col-3'}>
                        <CommodityForm.TotalValue
                          dependencies={['quantity', 'unitaryValue']}
                          callback={({ quantity, unitaryValue }) =>
                            parseNumberInput(quantity) *
                            parseNumberInput(unitaryValue)
                          }
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-3'}>
                        <CommodityForm.ValueByTotal
                          defaultValue={commodity.valueByTotal}
                        />
                      </div>
                    </div>
                    <div className={'pb-3'}>
                      <CommodityForm.Note />
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className={'m-3'}>
                    <CommodityForm
                      id={'newContainercommodityForm'}
                      initialValues={containerCommodityInitialState}
                      onSubmit={onSubmitCommodityDto}
                      innerRef={commodityFormRef}
                      validationSchema={newContainerCommoditySchema}
                      onKeyPress={(event, formikParams) => {
                        if (
                          event.code === 'Enter' &&
                          (currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole)
                        ) {
                          formikParams.submitForm();
                        }
                      }}
                    >
                      <div className="row m-2">
                        <div className={'col-1'}>
                          <CommodityForm.Pieces
                            onKeyDown={validateNumberInput}
                          />
                        </div>
                        <div className="col-2">
                          <CommodityForm.PackageTypeSelect
                            required={false}
                            defaultValue={
                              packageType &&
                              packageType.packageTypeId !== undefined &&
                              packageType.packageTypeId != null &&
                              packageType.name !== undefined &&
                              packageType.name != null
                                ? {
                                    packageTypeId: packageType?.packageTypeId,
                                    name: packageType?.name,
                                  }
                                : ''
                            }
                            onChange={(
                              data?: PackageTypeDto,
                              context?: any,
                            ) => {
                              setPackageType((packageTypeData) => {
                                if (!packageTypeData) {
                                  packageTypeData = {
                                    name: null,
                                    packageTypeId: null,
                                  };
                                }
                                packageTypeData.packageTypeId = data?.packageTypeId?.toString();
                                packageTypeData.name = data?.name;
                                return { ...packageTypeData };
                              });
                              context?.setFieldValue(
                                'packageTypeName',
                                data?.name,
                              );
                            }}
                            nameId={'packageTypeName'}
                          />
                        </div>
                        {currentUser?.isInOrgAdminRole ||
                        currentUser?.isInOperationRole ? (
                          <div className="col-4">
                            <CommodityForm.Description />
                          </div>
                        ) : (
                          <div className="col-5">
                            <CommodityForm.Description />
                          </div>
                        )}
                        <div className="col-1">
                          <CommodityForm.Length
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Width
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Height
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        <div className="col-1">
                          <CommodityForm.Weight
                            onKeyDown={validatePositiveNumberInput}
                          />
                        </div>
                        {(currentUser?.isInOrgAdminRole ||
                          currentUser?.isInOperationRole) && (
                          <div className="col-1 pt-4">
                            <Button
                              form={'commodityForm'}
                              name={'create-commodity'}
                              type="button"
                              onClick={(event) => {
                                if (commodityFormRef.current) {
                                  commodityFormRef.current.submitForm();
                                }
                              }}
                              color="secondary"
                              className="btn-block text-break"
                              data-testid={'add-commodity-button'}
                            >
                              +
                            </Button>
                          </div>
                        )}
                      </div>
                      <CommoditiesForOrderList
                        items={containerCommodities}
                        changeItems={setContainerCommodities}
                        onDelete={onDeleteContainerCommodity}
                        showPagination={false}
                        userCanDelete={true}
                        goToDetails={goToDetailsCommodityOfContainer}
                        orderType={OrderTypes.Commodity}
                      />
                    </CommodityForm>
                  </Panel>
                </TabPanel>
                <TabPanel forceRender={isCreateMode ? false : true}>
                  <Panel className="m-3">
                    <CustomValuesInput
                      context={context}
                      customFields={customFields}
                      defaultValue={commodity?.customValues}
                      entityName={'Commodity'}
                      onCancel={onCancel}
                      entityType={CustomFieldEntityType.PurposeOfCommodity}
                      colSize={12}
                    />
                  </Panel>
                </TabPanel>
                <TabPanel forceRender={isCreateMode ? false : true}>
                  <Panel className="m-3">
                    <CommodityTrackingNumbersForOrderList
                      className={'mb-4 commodities-for-order-list'}
                      limit={limit}
                      showPagination={false}
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      items={getCommodityTrackingNumbersForOrder()}
                      goToDetails={goToDetailsCommodityTrackingNumbers}
                      changeItems={setCommodityTrackingNumbers}
                      userCanDelete={
                        currentUser?.isInOrgAdminRole ||
                        currentUser?.isInOperationRole
                      }
                      commodityId={commodityId}
                      onCommodityTrackingNumberCreated={
                        onCommodityTrackingNumberCreated
                      }
                      isCreateMode={isCreateMode}
                      isLoading={isLoading}
                    />
                  </Panel>
                </TabPanel>
                <div className="justify-content-end d-flex row col-12 p-0 mt-4">
                  {(saveButtonRenderCondition || isCreateMode) && (
                    <div className="col pl-3 ml-4">
                      <Button
                        form={`commodity-form-${commodityId}`}
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        {saveBtnLabel}
                      </Button>
                    </div>
                  )}
                  <div className="col pl-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Tabs>
          )}
        </FormContext.Consumer>
      </CommodityForm>
    </div>
  );
};
