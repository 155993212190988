import {
  CustomFieldDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CustomFieldDtoPagedResult,
  CreateCustomFieldCommandValues,
  UpdateCustomFieldCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCustomFieldRequest,
  getCustomFieldsListRequest,
  getCustomFieldRequest,
  deleteCustomFieldRequest,
  updateCustomFieldRequest,
  importCustomFieldsRequest,
  exportCustomFieldsRequest,
  GetCustomFieldParams,
  recreateSystemCustomFieldsRequest,
  RecreateSystemCustomFieldsParams,
} from './customFields.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type CustomFieldsStoreState = {
  links: LinkDto[];
  customFieldColumns: EntityFieldDto[];
  defaultCustomFieldColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getCustomFieldsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CustomField',
  });
});

addEffectStatusHandling(getCustomFieldsColumnsFx);

export const getCustomFieldsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CustomField',
  });
});

addEffectStatusHandling(getCustomFieldsDefaultColumnsFx);

export const updateCustomFieldsColumns = createEvent<EntityFieldDto[]>();

export const getCustomFieldsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCustomFieldsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getCustomFieldsFx);

export const createCustomFieldFx = createEffect(
  (customFieldData: CustomFieldDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createCustomFieldCommand: CreateCustomFieldCommandValues = {
      ...customFieldData,
    };

    return createCustomFieldRequest(
      currentOrganization!,
      createCustomFieldCommand,
    );
  },
);

addEffectStatusHandling(createCustomFieldFx, {
  completeMessage: 'Custom field was created successfully',
  errorMessage: 'Custom field was not created',
});

export const getCustomFieldFx = createEffect(
  (customFieldParams: GetCustomFieldParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCustomFieldRequest(
      currentOrganization as LinkResourceBaseDto,
      customFieldParams,
    );
  },
);

addEffectStatusHandling(getCustomFieldFx);

export const updateCustomFieldFx = createEffect(
  (customField: CustomFieldDto) => {
    const updateCustomFieldCommand: UpdateCustomFieldCommandValues = {
      ...customField,
    };
    return updateCustomFieldRequest(customField, updateCustomFieldCommand);
  },
);

addEffectStatusHandling(updateCustomFieldFx, {
  completeMessage: 'Custom field was updated successfully',
  errorMessage: 'Custom field was not updated',
});

export const deleteCustomFieldFx = createEffect(
  (customField: CustomFieldDto) => {
    return deleteCustomFieldRequest(customField);
  },
);

addEffectStatusHandling(deleteCustomFieldFx, {
  completeMessage: 'Custom field was deleted successfully',
  errorMessage: 'Custom field was not deleted',
});

export const recreateSystemCustomFieldsFx = createEffect(
  ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');
    const params: RecreateSystemCustomFieldsParams = {
      organizationId: currentOrganization.organizationId,
    };
    return recreateSystemCustomFieldsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);

addEffectStatusHandling(recreateSystemCustomFieldsFx, {
  completeMessage: 'Custom fields were recreated',
  errorMessage: 'Custom fields were not recreated',
});

export const exportCustomFieldsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportCustomFieldsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportCustomFieldsFx, {
  completeMessage: 'Custom fields were exported successfully',
  errorMessage: 'Custom fields were not exported',
});

export const importCustomFieldsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importCustomFieldsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importCustomFieldsFx, {
  completeMessage: 'Custom fields were imported successfully',
  errorMessage: 'Custom fields were not imported',
  inFlightMessage: 'Importing custom fields...',
});

const defaultState: CustomFieldsStoreState = {
  links: [],
  customFieldColumns: [],
  defaultCustomFieldColumns: [],
  defaultSort: 'fieldId',
  defaultLimit: 20,
};

export const customFieldStore: Store<CustomFieldsStoreState> = createStore(
  defaultState,
)
  .on(getCustomFieldsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      customFieldColumns: payload.result.items,
      defaultCustomFieldColumns: payload.result.items,
    };
  })
  .on(getCustomFieldsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultCustomFieldColumns: payload.result.items,
    };
  })
  .on(updateCustomFieldsColumns, (state, payload) => {
    return { ...state, customFieldColumns: payload };
  });
