import {
  RateDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  RateDtoPagedResult,
  CreateRateCommandValues,
  UpdateRateCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_RATES_LINK_KEY = 'get-rates';
export const CREATE_RATE_LINK_KEY = 'create-rate';
export const GET_RATE_LINK_KEY = 'get-rate';
export const UPDATE_RATE_LINK_KEY = 'update-rate';
export const DELETE_RATE_LINK_KEY = 'delete-rate';
export const IMPORT_RATES_LINK_KEY = 'import-rates';
export const EXPORT_RATES_LINK_KEY = 'export-rates';
export const RATE_ENTITY_NAME = 'Rate';

export interface GetRateParams {
  rateId: number;
}

export interface ImportRatesParams {
  organizationId: number;
}

export interface ExportRatesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getRateRequest = async (
  resource: LinkResourceBaseDto | null,
  { rateId }: GetRateParams,
): Promise<RateDto> => {
  if (resource && rateId) {
    const getRateLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_RATE_LINK_KEY,
    );
    if (getRateLink) {
      const result = await execLink(getRateLink, { rateId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_RATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getRatesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<RateDtoPagedResult> => {
  if (resource) {
    const getRatesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_RATES_LINK_KEY,
    );
    if (getRatesListLink) {
      const result = await execLink(getRatesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_RATES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createRateRequest = async (
  resource: LinkResourceBaseDto,
  rate: CreateRateCommandValues,
) => {
  const createRateLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_RATE_LINK_KEY,
  );
  if (createRateLink) {
    const result = await execLink(createRateLink, rate);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_RATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateRateRequest = async (
  resource: LinkResourceBaseDto,
  rate: UpdateRateCommandValues,
) => {
  const updateRateLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_RATE_LINK_KEY,
  );
  if (updateRateLink) {
    const result = await execLink(updateRateLink, rate);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_RATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteRateRequest = async (rate: RateDto) => {
  const deleteRateLink = rate.links?.find(
    (x: LinkDto) => x.rel === DELETE_RATE_LINK_KEY,
  );
  if (deleteRateLink) {
    const result = await execLink(deleteRateLink, rate);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_RATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importRatesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportRatesParams,
  file: File,
) => {
  const importRatesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_RATES_LINK_KEY,
  );
  if (importRatesLink) {
    const result = await postFormData(importRatesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_RATES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportRatesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportRatesParams,
) => {
  const exportRatesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_RATES_LINK_KEY,
  );
  if (exportRatesLink) {
    const result = await downloadLinkFile(exportRatesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_RATES_LINK_KEY} is not allowed or missing link action`,
  );
};
