import {
  SalesTaxDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateSalesTaxCommandValues,
  UpdateSalesTaxCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createSalesTaxRequest,
  getSalesTaxesListRequest,
  getSalesTaxRequest,
  deleteSalesTaxRequest,
  updateSalesTaxRequest,
  importSalesTaxesRequest,
  exportSalesTaxesRequest,
  GetSalesTaxParams,
} from './salesTaxes.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type SalesTaxesStoreState = {
  links: LinkDto[];
  salesTaxColumns: EntityFieldDto[];
  defaultSalesTaxColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getSalesTaxesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'SalesTax',
  });
});

addEffectStatusHandling(getSalesTaxesColumnsFx);

export const getSalesTaxesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'SalesTax',
  });
});

addEffectStatusHandling(getSalesTaxesDefaultColumnsFx);

export const updateSalesTaxesColumns = createEvent<EntityFieldDto[]>();

export const getSalesTaxesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getSalesTaxesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getSalesTaxesFx);

export const createSalesTaxFx = createEffect((salesTaxData: SalesTaxDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createSalesTaxCommand: CreateSalesTaxCommandValues = {
    ...salesTaxData,
  };

  return createSalesTaxRequest(currentOrganization!, createSalesTaxCommand);
});

addEffectStatusHandling(createSalesTaxFx, {
  completeMessage: 'Sales tax was created successfully',
  errorMessage: 'Sales tax was not created',
});

export const getSalesTaxFx = createEffect(
  (salesTaxParams: GetSalesTaxParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getSalesTaxRequest(
      currentOrganization as LinkResourceBaseDto,
      salesTaxParams,
    );
  },
);

addEffectStatusHandling(getSalesTaxFx);

export const updateSalesTaxFx = createEffect((salesTax: SalesTaxDto) => {
  const updateSalesTaxCommand: UpdateSalesTaxCommandValues = { ...salesTax };
  return updateSalesTaxRequest(salesTax, updateSalesTaxCommand);
});

addEffectStatusHandling(updateSalesTaxFx, {
  completeMessage: 'Sales tax was updated successfully',
  errorMessage: 'Sales tax was not updated',
});

export const deleteSalesTaxFx = createEffect((salesTax: SalesTaxDto) => {
  return deleteSalesTaxRequest(salesTax);
});

addEffectStatusHandling(deleteSalesTaxFx, {
  completeMessage: 'Sales tax was deleted successfully',
  errorMessage: 'Sales tax was not deleted',
});

export const exportSalesTaxesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportSalesTaxesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportSalesTaxesFx, {
  completeMessage: 'Sales taxes were exported successfully',
  errorMessage: 'Sales taxes were not exported',
});

export const importSalesTaxesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importSalesTaxesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importSalesTaxesFx, {
  completeMessage: 'Sales taxes were imported successfully',
  errorMessage: 'Sales taxes were not imported',
  inFlightMessage: 'Importing sales taxes...',
});

const defaultState: SalesTaxesStoreState = {
  links: [],
  salesTaxColumns: [],
  defaultSalesTaxColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const salesTaxStore: Store<SalesTaxesStoreState> = createStore(
  defaultState,
)
  .on(getSalesTaxesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      salesTaxColumns: payload.result.items,
      defaultSalesTaxColumns: payload.result.items,
    };
  })
  .on(getSalesTaxesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultSalesTaxColumns: payload.result.items,
    };
  })
  .on(updateSalesTaxesColumns, (state, payload) => {
    return { ...state, salesTaxColumns: payload };
  });
