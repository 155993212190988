import React from 'react';
import { BusinessRuleForm } from '../businessRule.form';
import { AiOutlineMinusCircle } from 'react-icons/ai';

type ActionFormProps = {
  setAction: (value: any) => void;
  onDeleteAction: () => void;
  allowDeleteAction: boolean;
  index: number;
};

export const ActionForm = ({
  index,
  onDeleteAction,
  allowDeleteAction,
  setAction,
}: ActionFormProps) => {
  return (
    <>
      <div className="d-flex">
        <h3 className={'font-weight-normal mt-2'}>Action {index + 1}</h3>
        {allowDeleteAction && (
          <AiOutlineMinusCircle
            className="i-top-bar ml-2 mt-1 h-100 align-middle font-size-sm i-top-bar-blue"
            data-testid="btn-create-new-carrier"
            onClick={onDeleteAction}
          />
        )}
      </div>
      <div className="row">
        <div className="col-12 m-0 pr-0">
          <BusinessRuleForm.Action
            name={`actions[${index}].action`}
            id={`actions[${index}].action`}
            label={''}
            placeholder={'Enter an action'}
            key={index}
            onChange={(event) => setAction(event?.target?.value)}
          />
        </div>
      </div>
    </>
  );
};
