import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { OrderStatusesListScreen } from './screens/orderStatuses-list.screen';
import { OrderStatusScreen } from './screens/orderStatus.screen';

export const ORDERSTATUSES_LIST_PATH = '/order-statuses';
export const ORDERSTATUSES_EDIT_PATH = '/order-statuses/:orderStatusId';
export const ORDERSTATUSES_CREATE_PATH = '/order-statuses/create';

export const OrderStatusesRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <OrderStatusesListScreen pageTitle="Order Statuses" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <OrderStatusScreen pageTitle="Add New Order Status" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':orderStatusId'}
        element={
          <PrivateRoute>
            <OrderStatusScreen pageTitle="Update Order Status" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
