import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { StatesListScreen } from './screens/states-list.screen';
import { StateScreen } from './screens/state.screen';
import React from 'react';

export const STATES_LIST_PATH = '/states';
export const STATE_EDIT_PATH = '/states/:stateCode/:countryCode';
export const STATE_CREATE_PATH = '/states/0';

export const StatesRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <StatesListScreen pageTitle="States" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <StateScreen pageTitle="Add New State" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':stateCode/:countryCode'}
        element={
          <PrivateRoute>
            <StateScreen pageTitle="Update State" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
