import { createEffect, createStore } from 'effector';
import { queryGraphQl } from '../graphql/graphql.service';
import { organizationsStore } from '../organization/organization.store';

type AppRoute = {
  appRouteId: string;
  name: string;
  component: string;
  path: string;
  parentAppRouteId: number;
};

export const appRoutesStore = createStore<AppRoute[]>([]);

// effects

export const getAppRoutesFx = createEffect(async () => {
  const { currentOrganization } = organizationsStore.getState();

  const data = await queryGraphQl({
    query: `query($organizationId: Int!) {
      appRoutes(organizationId: $organizationId, take: 10000) {
        items{
          appRouteId
          name
          component
          path
          parentAppRouteId
          props
        }
      }
    }`,
    variables: {
      organizationId: currentOrganization?.organizationId,
    },
  });

  return data.appRoutes;
});

appRoutesStore.on(getAppRoutesFx.done, (state, payload) => {
  // reduce routes by hierarchy of routes based parentAppRouteId
  const routes = payload.result.items.reduce((acc, route) => {
    const parentRoute = payload.result.items.find(
      (r) => r.appRouteId === route.parentAppRouteId,
    );
    if (parentRoute) {
      parentRoute.children = [...(parentRoute.children || []), route];
    } else {
      acc.push(route);
    }
    return acc;
  }, [] as AppRoute[]);

  return routes;
});
