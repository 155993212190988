import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryEdit } from './country-edit.component';
import { CountryDto } from '../../../models/data.models';

export type CountryDialogProperties = {
  countryCode: string;
  title: string;
};

export class CountryDialog extends Dialog<CountryDialogProperties> {
  constructor(props: CountryDialogProperties) {
    super(props);
    this.onCountrySaved = this.onCountrySaved.bind(this);
  }

  onCountrySaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { countryCode } = this.props as CountryDialogProperties;
    return (
      <CountryEdit
        countryCode={countryCode}
        onCountryCreated={this.onCountrySaved}
        onCountryUpdated={this.onCountrySaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
