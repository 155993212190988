import {
  ActionEventDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  ActionEventDtoPagedResult,
  CreateActionEventCommandValues,
  UpdateActionEventCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_ACTIONEVENTS_LINK_KEY = 'get-actionevents';
export const CREATE_ACTIONEVENT_LINK_KEY = 'create-actionevent';
export const GET_ACTIONEVENT_LINK_KEY = 'get-actionevent';
export const UPDATE_ACTIONEVENT_LINK_KEY = 'update-actionevent';
export const DELETE_ACTIONEVENT_LINK_KEY = 'delete-actionevent';
export const IMPORT_ACTIONEVENTS_LINK_KEY = 'import-actionevents';
export const EXPORT_ACTIONEVENTS_LINK_KEY = 'export-actionevents';
export const ACTIONEVENT_ENTITY_NAME = 'Action Event';

export interface GetActionEventParams {
  eventId: string;
}

export interface ImportActionEventsParams {
  organizationId: number;
}

export interface ExportActionEventsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getActionEventRequest = async (
  resource: LinkResourceBaseDto | null,
  { eventId }: GetActionEventParams,
): Promise<ActionEventDto> => {
  if (resource && eventId) {
    const getActionEventLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ACTIONEVENT_LINK_KEY,
    );
    if (getActionEventLink) {
      const result = await execLink(getActionEventLink, { eventId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACTIONEVENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getActionEventsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ActionEventDtoPagedResult> => {
  if (resource) {
    const getActionEventsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ACTIONEVENTS_LINK_KEY,
    );
    if (getActionEventsListLink) {
      const result = await execLink(getActionEventsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACTIONEVENTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createActionEventRequest = async (
  resource: LinkResourceBaseDto,
  actionEvent: CreateActionEventCommandValues,
) => {
  const createActionEventLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ACTIONEVENT_LINK_KEY,
  );
  if (createActionEventLink) {
    const result = await execLink(createActionEventLink, actionEvent);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ACTIONEVENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateActionEventRequest = async (
  resource: LinkResourceBaseDto,
  actionEvent: UpdateActionEventCommandValues,
) => {
  const updateActionEventLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ACTIONEVENT_LINK_KEY,
  );
  if (updateActionEventLink) {
    const result = await execLink(updateActionEventLink, actionEvent);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_ACTIONEVENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteActionEventRequest = async (actionEvent: ActionEventDto) => {
  const deleteActionEventLink = actionEvent.links?.find(
    (x: LinkDto) => x.rel === DELETE_ACTIONEVENT_LINK_KEY,
  );
  if (deleteActionEventLink) {
    const result = await execLink(deleteActionEventLink, actionEvent);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ACTIONEVENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importActionEventsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportActionEventsParams,
  file: File,
) => {
  const importActionEventsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_ACTIONEVENTS_LINK_KEY,
  );
  if (importActionEventsLink) {
    const result = await postFormData(importActionEventsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_ACTIONEVENTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportActionEventsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportActionEventsParams,
) => {
  const exportActionEventsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_ACTIONEVENTS_LINK_KEY,
  );
  if (exportActionEventsLink) {
    const result = await downloadLinkFile(exportActionEventsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_ACTIONEVENTS_LINK_KEY} is not allowed or missing link action`,
  );
};
