import React, { useEffect, useState } from 'react';
import { ChargeDto, ChargeStatuses } from '../../../../models/data.models';
import { completeChargeFx } from '../../../charges/charges.store';
import { RoundCheckbox } from '../../../common/components/checkbox/roundCheckbox';

type CompletionCheckboxProps = {
  row?: ChargeDto;
  rows?: ChargeDto[];
  isCompleteAll?: boolean;
  setIsCompleteAll?: any;
  onEdit?: (row: ChargeDto) => any;
  styles?: string;
  index?: number;
};

export const CompletionCheckbox = ({
  row,
  rows,
  isCompleteAll,
  setIsCompleteAll,
  onEdit,
  styles,
  index,
}: CompletionCheckboxProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chargeStatus, setChargeStatus] = useState<boolean>(
    row
      ? row.chargeStatus != ChargeStatuses.Pending
      : setIsCompleteAll
      ? isCompleteAll
      : false,
  );

  const handleChange = () => {
    if (
      row &&
      (row.chargeStatus == ChargeStatuses.Pending ||
        row.chargeStatus == ChargeStatuses.Open)
    ) {
      setIsLoading(true);
      completeChargeFx(row.chargeId)
        .then(() => {
          const newStatus = !chargeStatus;
          setChargeStatus(newStatus);
          onEdit({
            ...row,
            chargeStatus: newStatus
              ? ChargeStatuses.Open
              : ChargeStatuses.Pending,
          });
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else if (setIsCompleteAll) {
      const newStatus = !chargeStatus;
      rows
        .filter((row) =>
          newStatus
            ? row.chargeStatus == ChargeStatuses.Pending
            : row.chargeStatus == ChargeStatuses.Open,
        )
        .forEach((row) => {
          setIsLoading(true);
          completeChargeFx(row.chargeId)
            .then(() => {
              onEdit({
                ...row,
                chargeStatus: newStatus
                  ? ChargeStatuses.Open
                  : ChargeStatuses.Pending,
              });
            })
            .catch(() => {})
            .finally(() => {
              setIsLoading(false);
            });
          setChargeStatus(newStatus);
        });
    }
  };

  useEffect(() => {
    if (setIsCompleteAll) setChargeStatus(isCompleteAll);
  }, [isCompleteAll]);

  return (
    <>
      <div
        className={`w-auto d-flex justify-content-center align-items-center ${styles}`}
        style={{ minWidth: '20px', minHeight: '20px' }}
      >
        {chargeStatus ? (
          <RoundCheckbox
            chkBoxClassName={`checkbox-charge-complete checkbox-charge-complete-checked checkbox-charge-complete-${index}`}
            onClick={handleChange}
            size={15}
            isLoading={isLoading}
          />
        ) : (
          <RoundCheckbox
            chkBoxClassName={`checkbox-charge-complete checkbox-charge-complete-${index}`}
            onClick={handleChange}
            size={15}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};
