import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { userHas } from '../../auth/auth.store';
import {
  COMMODITY_TYPES_CREATE_PATH,
  COMMODITY_TYPES_LIST_PATH,
} from '../commodityTypes.route';
import { CREATE_COMMODITYTYPE_LINK_KEY } from '../commodityTypes.service';
import { CommodityTypeEdit } from '../components/commodityType-edit.component';

export type CommodityTypeScreenProps = { pageTitle: string; group: string };

export const CommodityTypeScreen = ({
  pageTitle,
  group,
}: CommodityTypeScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, commodityTypeId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + COMMODITY_TYPES_CREATE_PATH, {
      organizationId,
    });
  }
  const onCommodityTypeCreated = () => {
    const path = generatePath(INTERNAL_PATH + COMMODITY_TYPES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'commodityType-created',
      type: 'success',
      autoHide: true,
      message: 'Commodity Type successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCommodityTypeUpdated = () => {
    const path = generatePath(INTERNAL_PATH + COMMODITY_TYPES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'commodityType-updated',
      type: 'success',
      autoHide: true,
      message: 'Commodity Type successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + COMMODITY_TYPES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Commodity Type'}
      createPath={userHas(CREATE_COMMODITYTYPE_LINK_KEY) ? createPath() : null}
      createLabel={'Type'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CommodityTypeEdit
        commodityTypeId={commodityTypeId}
        onCommodityTypeUpdated={onCommodityTypeUpdated}
        onCommodityTypeCreated={onCommodityTypeCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
