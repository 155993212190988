import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  COMMODITY_CREATE_PATH,
  COMMODITIES_LIST_PATH,
} from '../commodities.route';
import { CommodityEdit } from '../components/commodity-edit.component';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_COMMODITY_LINK_KEY } from '../commodities.service';
import { userHas } from '../../auth/auth.store';
import { GetPortsQuery } from '../../../models/data.models';

export type CommodityScreenProps = { pageTitle: string; group: string };

export const CommodityScreen = ({ pageTitle, group }: CommodityScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, commodityId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + COMMODITY_CREATE_PATH, {
      organizationId,
    });
  }
  const onCommodityCreated = () => {
    const path = generatePath(INTERNAL_PATH + COMMODITIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'commodity-created',
      type: 'success',
      autoHide: true,
      message: 'Commodity successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCommodityUpdated = () => {
    const path = generatePath(INTERNAL_PATH + COMMODITIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'commodity-updated',
      type: 'success',
      autoHide: true,
      message: 'Commodity successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + COMMODITIES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };
  const {
    offset = 0,
    limit = 20,
    sort = '-orderNumber~ToInt32',
    filter = ``,
    search = '',
  } = useQuery() as GetPortsQuery;

  return (
    <InternalLayout
      group={group}
      title={'Commodities'}
      createPath={userHas(CREATE_COMMODITY_LINK_KEY) ? createPath() : null}
      createLabel={'Commodity'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CommodityEdit
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        filter={filter}
        organizationId={organizationId}
        commodityId={commodityId}
        onCommodityUpdated={onCommodityUpdated}
        onCommodityCreated={onCommodityCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
