import React, { useEffect, useState } from 'react';
import { PackageReceiveForm } from '../../packageReceiveWizard/packageReceive.form';
import { AddTrackingNumberParams } from '../../../orders/orders.service';
import * as Yup from 'yup';
import { useTrackingNumberHandler } from '../../hooks/useTrackingNumberHandler';
import { addMessage, Message } from '../../../common/messages.store';
import { Button } from '../../../common/components/button/button.component';
import { FaBarcode, FaTrashAlt } from 'react-icons/fa';
import { deleteCommodityTrackingNumber } from '../../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import { CommodityTrackingNumberDto } from '../../../../models/data.models';
import { Spinner } from '../../../common/components/spinner/spinner';
import { Confirm } from '../../../common/components/confirm/confirm.component';
import { showDialog } from '../../../common/dialog.store';
import { FiX, FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';

type TrackingNumbersProps = {
  commodityTrackingNumbers: CommodityTrackingNumberDto[];
  setCommodityTrackingNumbers?: any;
  allowEdit?: boolean | null;
  commodityId?: number | null;
};

export const getInitialState = () => {
  const initialState: AddTrackingNumberParams = {
    trackingNumber: null,
  };

  return initialState;
};

const addTrackingNumberSchema = Yup.object().shape({
  trackingNumber: Yup.string()
    .required('Tracking number is required')
    .nullable(true),
});

export const TrackingNumbers = ({
  commodityTrackingNumbers,
  setCommodityTrackingNumbers,
  allowEdit = false,
  commodityId,
}: TrackingNumbersProps) => {
  const { onTrackingNumberSubmit } = useTrackingNumberHandler();

  const [overflowTrackingNumberIdx, setOverflowTrackingNumberIdx] = useState<
    number | null
  >(null);
  const [
    displayAllTrackingNumbers,
    setDisplayAllTrackingNumbers,
  ] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteLoadingNumber, setIsDeleteLoadingNumber] = useState<
    string | null
  >(null);

  const addTrackingNumber = (data, context) => {
    if (
      commodityTrackingNumbers.some(
        (item) => item.trackingNumber === data.trackingNumber,
      )
    ) {
      const message: Message = {
        id: 'tracking-number-exists',
        type: 'danger',
        autoHide: true,
        message: `Tracking Number (${data.trackingNumber}) already exists!`,
      };
      addMessage(message);
    } else if (commodityId) {
      setIsLoading(true);
      onTrackingNumberSubmit({
        commodityId: commodityId,
        trackingNumber: data.trackingNumber,
        isPrimary: false,
      })
        .then((commodityTrackingNumber: CommodityTrackingNumberDto) => {
          const message: Message = {
            id: 'tracking-number-added',
            type: 'success',
            autoHide: true,
            message: 'Tracking Number added successfully!',
          };
          addMessage(message);

          setCommodityTrackingNumbers((prevNumbers) => [
            ...prevNumbers,
            commodityTrackingNumber,
          ]);
          context?.setFieldValue('trackingNumber', '');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setCommodityTrackingNumbers((prevNumbers) => [
        ...prevNumbers,
        {
          trackingNumber: data.trackingNumber,
          commodityId: 0,
          commodityTrackingNumberId: 0,
        },
      ]);
      const message: Message = {
        id: 'tracking-number-added',
        type: 'success',
        autoHide: true,
        message: 'Tracking Number added successfully!',
      };
      addMessage(message);
    }
  };

  const handleDeleteTrackingNumber = (
    commodityTrackingNumber: CommodityTrackingNumberDto,
  ) => {
    setIsDeleteLoadingNumber(commodityTrackingNumber.trackingNumber);
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${commodityTrackingNumber.trackingNumber} Tracking Number`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    })
      .then((result) => {
        if (result) {
          if (commodityTrackingNumbers.length === 1) {
            const message: Message = {
              id: 'last-tracking-number',
              type: 'warning',
              autoHide: true,
              message: 'There must be at least one tracking number!',
            };
            addMessage(message);
          } else {
            if (commodityTrackingNumber.commodityTrackingNumberId > 0) {
              deleteCommodityTrackingNumber(commodityTrackingNumber)
                .then(() => {
                  const message: Message = {
                    id: 'tracking-number-deleted',
                    type: 'success',
                    autoHide: true,
                    message: 'Tracking Number deleted successfully!',
                  };
                  addMessage(message);

                  setCommodityTrackingNumbers((prevNumbers) =>
                    prevNumbers.filter(
                      (value) =>
                        value.commodityTrackingNumberId !==
                        commodityTrackingNumber.commodityTrackingNumberId,
                    ),
                  );
                })
                .catch(() => {});
            } else {
              setCommodityTrackingNumbers((prevNumbers) =>
                prevNumbers.filter(
                  (number) =>
                    number.trackingNumber !==
                    commodityTrackingNumber.trackingNumber,
                ),
              );
              const message: Message = {
                id: 'tracking-number-deleted',
                type: 'success',
                autoHide: true,
                message: 'Tracking Number deleted successfully!',
              };
              addMessage(message);
            }
          }
        }
      })
      .finally(() => {
        setIsDeleteLoadingNumber(null);
      });
  };

  const sortedTrackingNumbers = [...commodityTrackingNumbers].sort((a, b) => {
    if (a.isPrimary && !b.isPrimary) return -1;
    if (!a.isPrimary && b.isPrimary) return 1;
    return 0;
  });

  useEffect(() => {
    if (sortedTrackingNumbers?.length > 0) {
      const ctr = document.getElementById('tracking-number-container');
      const ert = Array.from(ctr.children).sort((a, b) =>
        a.id.localeCompare(b.id),
      );

      for (let i = 0; i < ert?.length; i++) {
        if (
          ert[i].getBoundingClientRect().right + 100 >
          ctr.getBoundingClientRect().right
        ) {
          setOverflowTrackingNumberIdx(i);
          break;
        }
      }
    }
  }, [sortedTrackingNumbers?.length]);

  const displayedTrackingNumbers = sortedTrackingNumbers.filter(
    (_, idx) =>
      displayAllTrackingNumbers ||
      overflowTrackingNumberIdx === null ||
      idx < overflowTrackingNumberIdx,
  );

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="w-100 d-flex flex-row align-items-center">
        <div className="text-label d-flex flex-row align-items-center">
          <FaBarcode className={'mr-2'} />
          Tracking Numbers
        </div>
        <div
          id={`tracking-number-container`}
          className={`col-9 white font-weight-bold ${
            !displayAllTrackingNumbers && 'text-nowrap overflow-hidden'
          }`}
        >
          {displayedTrackingNumbers.map(
            (
              commodityTrackingNumber: CommodityTrackingNumberDto,
              idx: number,
            ) => (
              <div
                key={commodityTrackingNumber.commodityTrackingNumberId}
                id={`tracking-number-badge-${idx}`}
                className="m-2 d-inline-block"
              >
                <div className={`tracking-number-badge`}>
                  {commodityTrackingNumber.trackingNumber}
                  {isDeleteLoadingNumber ===
                  commodityTrackingNumber.trackingNumber ? (
                    <Spinner size="sm" className="px-5 py-2" />
                  ) : (
                    allowEdit && (
                      <FiX
                        className={'ml-2 buttonIcon'}
                        color="black"
                        size={'20px'}
                        onClick={() =>
                          handleDeleteTrackingNumber(commodityTrackingNumber)
                        }
                      />
                    )
                  )}
                </div>
              </div>
            ),
          )}
          {sortedTrackingNumbers.length > 1 &&
            (displayedTrackingNumbers.length < sortedTrackingNumbers.length ||
              displayAllTrackingNumbers) && (
              <div
                id={`tracking-number-badge-more`}
                className="m-2 d-inline-block"
              >
                <div className={`tracking-number-badge`}>
                  {displayAllTrackingNumbers
                    ? 'hide'
                    : `${
                        sortedTrackingNumbers.length - overflowTrackingNumberIdx
                      } more`}
                  {displayAllTrackingNumbers ? (
                    <FiMoreVertical
                      className={'ml-2 buttonIcon'}
                      color="black"
                      size={'20px'}
                      onClick={() =>
                        setDisplayAllTrackingNumbers((prev) => !prev)
                      }
                    />
                  ) : (
                    <FiMoreHorizontal
                      className={'ml-2 buttonIcon'}
                      color="black"
                      size={'20px'}
                      onClick={() =>
                        setDisplayAllTrackingNumbers((prev) => !prev)
                      }
                    />
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
      {allowEdit && (
        <PackageReceiveForm
          id={'AddTrackingNumberForm'}
          initialValues={getInitialState}
          validationSchema={addTrackingNumberSchema}
          className="col-3"
          onSubmit={addTrackingNumber}
        >
          <div className="input-group d-flex flex-row flex-nowrap">
            <PackageReceiveForm.TrackingNumber
              className={'h-100 input-tracking-number  mb-0'}
              placeholder="Tracking number"
              style={{ width: '100%' }}
            />
            <Button
              disabled={isLoading}
              color={'secondary'}
              outline
              type="submit"
              form={'AddTrackingNumberForm'}
              className={
                'input-group-append btn-add-barcode d-flex justify-content-center align-items-center'
              }
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                width: 110,
              }}
            >
              <FaBarcode className={'mr-2'} />
              Add
            </Button>
          </div>
        </PackageReceiveForm>
      )}
    </div>
  );
};
