import {
  JobDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  JobListItemDtoPagedResult,
  CreateJobCommandValues,
  UpdateJobCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_JOBS_LINK_KEY = 'get-jobs';
export const CREATE_JOB_LINK_KEY = 'create-job';
export const GET_JOB_LINK_KEY = 'get-job';
export const UPDATE_JOB_LINK_KEY = 'update-job';
export const DELETE_JOB_LINK_KEY = 'delete-job';
export const IMPORT_JOBS_LINK_KEY = 'import-jobs';
export const EXPORT_JOBS_LINK_KEY = 'export-jobs';
export const JOB_ENTITY_NAME = 'Job';

export interface GetJobParams {
  jobId: string;
}

export interface ImportJobsParams {
  organizationId: number;
}

export interface ExportJobsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getJobRequest = async (
  resource: LinkResourceBaseDto | null,
  { jobId }: GetJobParams,
): Promise<JobDto> => {
  if (resource && jobId) {
    const getJobLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_JOB_LINK_KEY,
    );
    if (getJobLink) {
      const result = await execLink(getJobLink, { jobId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_JOB_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getJobsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<JobListItemDtoPagedResult> => {
  if (resource) {
    const getJobsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_JOBS_LINK_KEY,
    );
    if (getJobsListLink) {
      const result = await execLink(getJobsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_JOBS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createJobRequest = async (
  resource: LinkResourceBaseDto,
  job: CreateJobCommandValues,
) => {
  const createJobLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_JOB_LINK_KEY,
  );
  if (createJobLink) {
    const result = await execLink(createJobLink, job);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_JOB_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateJobRequest = async (
  resource: LinkResourceBaseDto,
  job: UpdateJobCommandValues,
) => {
  const updateJobLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_JOB_LINK_KEY,
  );
  if (updateJobLink) {
    const result = await execLink(updateJobLink, job);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_JOB_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteJobRequest = async (job: JobDto) => {
  const deleteJobLink = job.links?.find(
    (x: LinkDto) => x.rel === DELETE_JOB_LINK_KEY,
  );
  if (deleteJobLink) {
    const result = await execLink(deleteJobLink, job);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_JOB_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importJobsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportJobsParams,
  file: File,
) => {
  const importJobsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_JOBS_LINK_KEY,
  );
  if (importJobsLink) {
    const result = await postFormData(importJobsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_JOBS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportJobsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportJobsParams,
) => {
  const exportJobsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_JOBS_LINK_KEY,
  );
  if (exportJobsLink) {
    const result = await downloadLinkFile(exportJobsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_JOBS_LINK_KEY} is not allowed or missing link action`,
  );
};
