import React, { CSSProperties, useRef, useState } from 'react';
import { Input } from '../input/input.component';

export type ConfirmPasswordProps = {
  label: string;
  name: string;
  required?: boolean;
  className?: string;
  style?: CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  updatingCurrentPassword?: boolean;
};

export const ConfirmPassword = ({
  label,
  name,
  required = true,
  className = '',
  size = 'md',
  style = {},
  updatingCurrentPassword = false,
}: ConfirmPasswordProps) => {
  const el = useRef(null);

  return (
    <div className={className} style={style}>
      <div ref={el}>
        <Input
          name={name}
          placeholder={updatingCurrentPassword ? 'New Password' : 'Password'}
          label={label}
          type={'password'}
          required={required}
          size={size}
          readonly={'readonly'}
          onFocus={(e) => {
            e.target.removeAttribute('readonly');
          }}
        />
        <Input
          placeholder={'Confirm Password'}
          label={'Confirm Password'}
          name={'confirmPassword'}
          required={required}
          size={size}
          type={'password'}
          readonly={'readonly'}
          onFocus={(e) => {
            e.target.removeAttribute('readonly');
          }}
        />
      </div>
    </div>
  );
};
