import { Dialog } from '../../common/components/dialog/dialog.component';
import { StateEdit } from './state-edit.component';
import { StateDto } from '../../../models/data.models';

export type StateDialogProperties = {
  countryCode?: string | null;
  stateCode?: string | null;
  title: string;
};

export class StateDialog extends Dialog<StateDialogProperties> {
  constructor(props: StateDialogProperties) {
    super(props);
    this.onStateSaved = this.onStateSaved.bind(this);
  }

  onStateSaved = (state: StateDto) => {
    this.close(state);
  };

  renderContent(): any {
    const { countryCode, stateCode } = this.props as StateDialogProperties;
    return (
      <StateEdit
        countryCode={countryCode}
        stateCode={stateCode}
        onStateCreated={this.onStateSaved}
        onStateUpdated={this.onStateSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
