import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { AppModulesListScreen } from './screens/appModules-list.screen';
import { AppModuleScreen } from './screens/appModule.screen';

export const APPMODULES_LIST_PATH = '/appModules';
export const APPMODULE_EDIT_PATH = '/appModules/:appModuleId';
export const APPMODULE_CREATE_PATH = '/appModules/create';

export const AppModulesRoute = () => {
  const group = 'Configuration';

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <AppModulesListScreen pageTitle="AppModules" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path="create"
        element={
          <PrivateRoute>
            <AppModuleScreen pageTitle="Add New AppModule" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path=":appModuleId"
        element={
          <PrivateRoute>
            <AppModuleScreen pageTitle="Update AppModule" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
