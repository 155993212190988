import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { PaymentDto, PaymentDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  paymentStore,
  getPaymentsColumnsFx,
  getPaymentsFx,
  deletePaymentFx,
  exportPaymentsFx,
  importPaymentsFx,
} from '../payments.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_PAYMENT_LINK_KEY,
  DELETE_PAYMENT_LINK_KEY,
  IMPORT_PAYMENTS_LINK_KEY,
  EXPORT_PAYMENTS_LINK_KEY,
  GetPaymentParams,
} from '../payments.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { PaymentDialog } from './payment.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type PaymentsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (paymentParams: GetPaymentParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
};

export const PaymentsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
}: PaymentsListProps) => {
  const [payments, setPayments] = useState<PaymentDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  const {
    paymentColumns: columns,
    defaultPaymentColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(paymentStore);

  useEffect(() => {
    getPaymentsColumnsFx().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const getPayments = useCallback(async () => {
    const paymentsData = await getPaymentsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });

    if (paymentsData) {
      setActions(getActions(paymentsData));
      setPayments(paymentsData);
      setGetDataSuccess(true);
    }
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: PaymentDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_PAYMENTS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importPaymentsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getPayments();
          },
          result?.links,
        ),
        getAction(
          EXPORT_PAYMENTS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportPaymentsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getPayments],
  );

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  const onDeletePayment = useCallback(
    async (payment: PaymentDto) => {
      if (userHas(DELETE_PAYMENT_LINK_KEY, payments?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Payment`,
            message: 'Are you sure you want to delete payment?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deletePaymentFx(payment);
            await getPayments();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getPayments, onDelete, payments],
  );

  const onEditPayment = (payment: PaymentDto) => {
    if (payment) goToDetails({ paymentId: payment.paymentId });
  };

  const onPaymentSelect = useCallback(
    async (payment) => {
      const selectedPayment = await showDialog({
        dialog: PaymentDialog,
        props: {
          paymentId: payment.paymentId,
          title: 'Update Payment',
        },
      });
      if (selectedPayment) {
        getPayments();
      }
    },
    [getPayments],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['paymentId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={payments.items}
          onLimitChanged={onLimitChanged}
          total={payments.totalCount}
          onDelete={
            userHas(DELETE_PAYMENT_LINK_KEY, payments?.items[0]?.links)
              ? onDeletePayment
              : null
          }
          onSort={onSort}
          onEdit={onEditPayment}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onPaymentSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {payments?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Payment"
            entityLinkKey={CREATE_PAYMENT_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
