import { Dialog } from '../../common/components/dialog/dialog.component';
import { CityEdit } from './city-edit.component';
import { CityDto } from '../../../models/data.models';

export type CityDialogProperties = {
  cityId?: number | null;
  title: string;
};

export class CityDialog extends Dialog<CityDialogProperties> {
  constructor(props: CityDialogProperties) {
    super(props);
    this.onCitySaved = this.onCitySaved.bind(this);
  }

  onCitySaved = (city: CityDto) => {
    this.close(city);
  };

  renderContent(): any {
    const { cityId } = this.props as CityDialogProperties;
    return (
      <CityEdit
        cityId={cityId}
        onCityCreated={this.onCitySaved}
        onCityUpdated={this.onCitySaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
