import React, { useEffect, useState } from 'react';
import {
  EntityTypes,
  OrderDto,
  StatusStage,
} from '../../../../models/data.models';
import { MdOutlineClose, MdCheckCircle } from 'react-icons/md';
import { StatusBadge } from '../../../common/components/status-badge/status-badge';
import { TransportationMethodBadge } from '../../../common/components/status-badge/transportation-method-badge';
import { OrderForm } from '../../../orders/components/order.form';

type ParcelShipmentInformationProps = {
  order: OrderDto;
  purchase: OrderDto;
  setOrderContextValues?: any;
};

export const ParcelShipmentInformation = ({
  order,
  purchase,
  setOrderContextValues,
}: ParcelShipmentInformationProps) => {
  const [destination, setDestination] = useState<string | null>(null);
  const [packageDescription, setPackageDescription] = useState<string | null>(
    purchase?.commodities?.[0].description ?? '',
  );

  useEffect(() => {
    const consignee = order?.orderEntities?.find(
      (x) => x.entityType === EntityTypes.Consignee,
    );

    if (consignee?.contactCityName && consignee?.contactCountryName) {
      const destinationValue = `${consignee.contactCityName}, ${consignee.contactCountryName}`;
      setDestination(destinationValue);
    } else {
      setDestination(null);
    }
  }, [order]);

  const isConsolidated =
    order?.customValues?.['isConsolidated'] === 'true' || false;
  const receivedCount = order?.commodities?.filter(
    (x) => x.commodityStatus?.statusName === 'OnHand',
  )?.length;

  const style = { fontSize: '0.875rem' };

  const renderParcelShipmentInfoContent = () => {
    if (order) {
      return (
        <>
          <div className="row mt-2 d-flex justify-content-between ml-1">
            <div className="col-8">
              {isConsolidated && (
                <>
                  <div className="row d-flex mb-1">
                    <div>
                      <h3 className="text-secondary" style={style}>
                        Type:
                      </h3>
                    </div>
                    <div className="ml-2">
                      <h3 style={style}>Consolidated</h3>
                    </div>
                  </div>
                  <div className="row d-flex mb-1">
                    <div>
                      <h3 className="text-secondary" style={style}>
                        Received:
                      </h3>
                    </div>
                    <div className="ml-2">
                      <h3
                        style={style}
                      >{`${receivedCount}/${order?.commodities?.length}`}</h3>
                    </div>
                  </div>
                </>
              )}
              <div className="row d-flex mb-1">
                <div>
                  <h3 className="text-secondary" style={style}>
                    Parcel Track:
                  </h3>
                </div>
                <div className="ml-2">
                  <h3 className="text-primary" style={style}>
                    {order?.trackingNumber ?? '-'}
                  </h3>
                </div>
              </div>
              <div className="row d-flex">
                <div>
                  <h3 className="text-secondary" style={style}>
                    Destination:
                  </h3>
                </div>
                <div className="ml-2">
                  <h3 style={style}>{destination ?? '-'}</h3>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-end mb-2">
              <div className="row" style={{ maxHeight: 22 }}>
                <StatusBadge
                  label={order?.orderStatus?.orderStatusName}
                  stage={order?.orderStatus?.statusStage}
                  enumType={StatusStage}
                  style={{ marginRight: 0 }}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <div className="row py-3 pl-2">
            <MdOutlineClose className="text-danger" />
            &nbsp;
            <h3 className="text-danger">No Parcel Shipment Available</h3>
          </div>
          <div>
            <OrderForm.PackageDescription
              id={'packageDescription'}
              onChange={(data?: any) => {
                setOrderContextValues((prevData) => ({
                  ...prevData,
                  packageDescription: data,
                }));
                setPackageDescription(data);
              }}
              nameId={'packageDescription'}
              className="mb-0"
              defaultValue={packageDescription}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <MdCheckCircle className="text-primary mr-2" />
          <div className="pb-2 text-label text-primary">
            Parcel Shipment Information
          </div>
        </div>
        <TransportationMethodBadge
          label={order?.customValues['modeOfTransportationIdDescription'] ?? ''}
        />
      </div>
      {renderParcelShipmentInfoContent()}
    </>
  );
};
