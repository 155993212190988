import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  CountryDto,
  CustomFieldDto,
  CustomFieldEntityType,
  StateDto,
} from '../../../models/data.models';
import { createStateFx, getStateFx, updateStateFx } from '../states.store';
import { StateForm } from './state.form';
import { Panel } from '../../common/components/panel/panel.component';
import { StateDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_STATE_LINK_KEY } from '../states.service';
import { FormContext } from '../../common/components/form/form.component';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';

export type StateEditProps = {
  stateCode?: string;
  countryCode?: string;
  onStateCreated?: (state: StateDto) => void;
  onStateUpdated?: (state: StateDto) => void;
  onCancel?: () => void;
};

const initialState: StateDto = {
  stateCode: StateDefaultValues.stateCode,
  countryCode: StateDefaultValues.countryCode,
  name: StateDefaultValues.stateName,
  links: [],
  organizationId: 0,
  customValues: {},
};

let stateSchema = Yup.object().shape({
  stateCode: Yup.string()
    .required("Can't be blank")
    .max(3, 'Max length is 3')
    .nullable(true),
  name: Yup.string().required("Can't be blank").nullable(true),
  countryCode: Yup.string().required("Can't be blank").nullable(true),
});

export const StateEdit = ({
  stateCode,
  countryCode,
  onStateCreated = () => {},
  onStateUpdated = () => {},
  onCancel = () => {},
}: StateEditProps) => {
  const isCreateMode = !stateCode && !countryCode;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState<StateDto | null>(null);
  const [initialValues, setInitialValues] = useState<StateDto | null>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.State} AND isInactive: false`,
    }).then((fields) => {
      const customFields: CustomFieldDto[] = fields.items;
      stateSchema = generateValidationSchemaWithCustomFields(
        customFields,
        stateSchema,
      );
      setCustomFields(customFields);
    });
    if (isCreateMode) {
      setIsLoading(false);
    } else if (stateCode) {
      getStateFx({ stateCode, countryCode }).then(
        (stateData: StateDto) => {
          setState(stateData);
          setInitialValues(stateData);
          setIsLoading(false);
        },
        () => {},
      );
    }
  }, [stateCode, countryCode]);

  const onSubmit = (data: StateDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createStateFx(data)
        .then(
          (result) => {
            onStateCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateStateFx(data)
        .then(
          (result) => {
            onStateUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <StateForm
      id={'state-form'}
      innerRef={ref}
      initialValues={state || initialValues}
      onSubmit={onSubmit}
      validationSchema={stateSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <span>
                <Tab name="general">General</Tab>
                <Tab name="additional">Additional</Tab>
              </span>
            </TabList>
            <TabPanel forceRender={true}>
              <Panel className="m-3">
                {isCreateMode ? (
                  <h3 className="header-form">Add New State</h3>
                ) : (
                  <h3 className="header-form">Update State</h3>
                )}
                <div className="row">
                  <div className="col-4">
                    <StateForm.StateCode disabled={!isCreateMode} />
                    <StateForm.Name />
                    {isCreateMode ? (
                      <StateForm.CountryCode
                        id={'countryCode'}
                        header={'Country'}
                        required={true}
                        defaultValue={
                          state && state.countryName && state.countryCode
                            ? {
                                countryCode: state.countryCode,
                                name: state.countryName,
                              }
                            : ''
                        }
                        onChange={(country?: CountryDto) => {
                          setState((oldState) => {
                            if (!oldState) {
                              oldState = initialState;
                            }
                            oldState.countryCode = country?.countryCode;
                            oldState.countryName = country?.name;
                            return { ...oldState };
                          });
                        }}
                        nameId={'countryName'}
                      />
                    ) : (
                      <StateForm.CountryCodeId disabled={true} />
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end row">
                  <div className="col-3">
                    <Button
                      form={'state-form'}
                      type="submit"
                      color="primary"
                      className="btn-block"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save State
                    </Button>
                  </div>
                  <div className="col-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel forceRender={isCreateMode ? false : true}>
              <StateForm.CustomValues
                customFields={customFields}
                entityType={CustomFieldEntityType.State}
                defaultValue={{ ...state?.customValues }}
                onChange={(result) => {
                  setState((type) => {
                    if (type) {
                      type.customValues = result;
                      return { ...type };
                    }
                    return type;
                  });
                }}
                saveButtonRenderCondition={
                  userHas(UPDATE_STATE_LINK_KEY, state?.links) || isCreateMode
                }
                isSending={isSending}
                formName={'state-form'}
                entityName={'State'}
                onCancel={onCancel}
                context={context}
              />
            </TabPanel>
          </Tabs>
        )}
      </FormContext.Consumer>
    </StateForm>
  );
};
