import React from 'react';
import ReactDOM from 'react-dom';
import './style/theme.scss';
import { App } from './app.component';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import BarcodeDetector from 'barcode-detector';
import * as Sentry from '@sentry/react';
import { ScannerProvider } from './modules/barcodeScanner/scanner.provider';

// @ts-ignore
window.jQuery = window.$ = require('jquery');
require('popper.js');
require('bootstrap');
if (!('BarcodeDetector' in window)) {
  window.BarcodeDetector = BarcodeDetector;
}

Sentry.init({
  dsn: 'https://ab993dc033f9f939213b1645cbd83807@sentry.ankocorp.com/4',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/tms-dev\.usatrt\.com\/api/,
        /^https:\/\/tms\.usatrt\.com\/api/,
      ],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'development',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <ScannerProvider>
      <GoogleOAuthProvider clientId="659256994041-fpb6j9nq27q24fcbtrc94m11q41t8p9r.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </ScannerProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
