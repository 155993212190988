import React, {
  CSSProperties,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import { Pagination } from '../pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import {
  Column,
  OrderTypes,
  StatusStage,
  WarehouseZoneDto,
  CommodityStatusStage,
  CommodityDto,
  PackingStatuses,
  PickingActivityStatuses,
} from '../../../../models/data.models';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import { showDialog } from '../../dialog.store';
import { getDialog } from '../../../../utils/orderDialogSelector.utils';
import { StatusBadge } from '../status-badge/status-badge';
import {
  FaArrowDown,
  FaArrowUp,
  FaChevronRight,
  FaChevronUp,
} from 'react-icons/fa';
import { LocationInfo } from '../location/locationInfo.component';
import { getOrder } from '../../../orders/orders.store';
import { GetOrderParams } from '../../../orders/orders.service';
import {
  FilteredField,
  FiltersTab,
  GridContextValue,
} from '../filters/filtersTab.component';
import { getActivityStatusStage } from '../../../../utils/helper.utils';
import { VerifyContentCheckbox } from '../../../parcelShipments/components/common/verifyContentCheckbox';
import { authStore } from '../../../auth/auth.store';
import { Spinner } from '../spinner/spinner';
import { DangerousToggle } from '../../../parcelShipments/components/common/dangerousToggle';
import lodash from 'lodash';

export type CommodityGridProps = {
  name?: string;
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  totalQuantity?: number;
  data?: any[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSelect?: (item: any, keys: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  showSortColumns?: boolean;
  containerCommodityId?: number;
  parentOrderType?: OrderTypes | null | undefined;
  showToolbar?: boolean;
  showGridPanel?: boolean;
  expandable?: boolean;
  defaultExpanded?: boolean;
  warehouseZones?: WarehouseZoneDto[];
  setOrder?: any;
  defaultColumns?: Column[];
  defaultSort?: string;
  defaultLimit?: number;
  setIsVerifiedAll?: any;
  isLoading?: boolean;
  tableLayout?: 'auto' | 'fixed';
  goToParcelDetails?: (commodity: CommodityDto) => void;
  selectNext?: (commodityId: number) => void;
  shouldRenderShipments?: boolean;
};

const GridContext = createContext<GridContextValue | null>(null);

export const useGridContext = (): GridContextValue => {
  const contextValue = useContext(GridContext);

  if (!contextValue) {
    throw new Error(
      'useGridContext must be used within a GridContext.Provider',
    );
  }

  return contextValue;
};

export const CommodityGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  totalQuantity = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onSort = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onColumnsChanged,
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
  loading,
  showSortColumns = true,
  containerCommodityId = 0,
  parentOrderType,
  showToolbar = true,
  showGridPanel = false,
  expandable = false,
  defaultExpanded = false,
  warehouseZones = [],
  setOrder,
  defaultColumns,
  defaultSort,
  defaultLimit,
  setIsVerifiedAll,
  isLoading,
  tableLayout = 'auto',
  goToParcelDetails,
  selectNext,
  shouldRenderShipments = true,
}: CommodityGridProps) => {
  const { user: currentUser } = authStore.getState();
  const colByName: { [key: string]: any } = {};

  const [gridColumns, setGridColumns] = useState<Column[]>([...columns]);
  const [filters, setFilters] = useState<FilteredField[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<FilteredField[]>([]);

  const onColumnsChangedHandler = (columnsChanged: Column[]): void => {
    onColumnsChanged
      ? onColumnsChanged([...columnsChanged])
      : setGridColumns([...columnsChanged]);
  };

  useEffect(() => {
    if (columns.length === 0) return;
    setGridColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    setFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: filters.find((y) => y.name === x.name)?.visible ?? false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
    setDefaultFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
  }, [gridColumns]);

  const [expandedRows, setExpandedRows] = useState([]);

  const isRowExpanded = (key) => {
    return defaultExpanded
      ? !expandedRows?.includes(key)
      : expandedRows?.includes(key);
  };

  const toggleExpandRow = (key) => {
    if (!expandedRows?.includes(key)) setExpandedRows([...expandedRows, key]);
    else setExpandedRows(expandedRows.filter((x) => x != key));
  };

  const updateCols = (colName: string) => {
    setGridColumns((prev) =>
      prev.map((x) => {
        const col = x;
        if (x.name === colName) {
          x.visible = !col.visible;
        }
        return x;
      }),
    );
  };

  const updateSort = (colName: string) => {
    const sortName = gridColumns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort) onSort(sort.startsWith('-') ? colName : '-' + colName);
    else onSort(colName);
  };

  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };

  const getFilteredRowsFilter = (): Column[] => {
    return gridColumns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  gridColumns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const onFiltersChangedHandler = (filtersChanged: FilteredField[]): void => {
    if (filtersChanged) setFilters([...filtersChanged]);
  };

  const [gridContext, setGridContext] = useState<GridContextValue>({
    columns: gridColumns,
    filters,
    search,
    defaultColumns,
    defaultFilters,
    defaultSort,
    defaultLimit,
    onSearch,
    onFilter,
    onColumnsChanged: onColumnsChangedHandler,
    onFiltersChanged: onFiltersChangedHandler,
    onViewChanged,
  });

  useEffect(() => {
    setGridContext({
      columns: gridColumns,
      filters,
      search,
      defaultColumns,
      defaultFilters,
      defaultSort,
      defaultLimit,
      onSearch,
      onFilter,
      onColumnsChanged: onColumnsChangedHandler,
      onFiltersChanged: onFiltersChangedHandler,
      onViewChanged,
    });
  }, [gridColumns, filters, search, defaultColumns, defaultFilters]);

  return (
    <>
      {showGridPanel && (
        <div>
          {(filters.length > 0 || gridColumns.length > 0) && (
            <GridContext.Provider value={gridContext}>
              <FiltersTab useGridContext={useGridContext} />
            </GridContext.Provider>
          )}
        </div>
      )}

      <div
        className={`grid my-4 ${className} ${expandable ? 'expandable' : ''}`}
        style={style}
      >
        {showToolbar ? (
          <div
            className={
              isDropDownList === true
                ? 'grid-toolbar d-flex flex-wrap px-3 justify-content-center'
                : 'grid-toolbar d-flex pl-3'
            }
          >
            {onSearch ? (
              <input
                type="search"
                className={
                  (isDropDownList === true ? 'w-100 col-12 ' : ' ') +
                  'form-control my-2'
                }
                placeholder="Search"
                value={decodeURIComponent(search)}
                onChange={updateSearch}
              />
            ) : null}
            {onFilter ? (
              <div
                className={
                  isDropDownList === true
                    ? 'dropdown dropdown-columns my-3 px-auto mx-auto w-auto col-auto'
                    : 'dropdown dropdown-columns my-3 px-3 w-100'
                }
              >
                {gridColumns.some((column) => column.showFilter) && (
                  <span
                    className="dropdown-toggle pointer"
                    id="dropdownFilterButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <BsFilter />
                    &nbsp;
                    <small className="align-middle pl-1 text-uppercase">
                      Filters
                    </small>
                    &nbsp;
                    <small className="align-middle text-primary">
                      {getReadableQueryString(filter, getFilteredRowsFilter())}
                    </small>
                  </span>
                )}
                {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
                  <div
                    className="dropdown-menu px-5"
                    aria-labelledby="dropdownFilterButton"
                  >
                    <div>
                      <Filters
                        query={filter}
                        columns={getFilteredRowsFilter()}
                        onFilter={updateFilter}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {hideColumnsSelect ? null : (
              <div
                className={
                  isDropDownList === true
                    ? 'dropdown dropdown-columns my-3 px-3 mx-auto pointer col-auto'
                    : 'dropdown dropdown-columns my-3 px-3 ml-auto pointer'
                }
              >
                <div
                  className="dropdown-toggle"
                  id="dropdownColumnsButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BsListTask className="" />
                  &nbsp;
                  <small className="align-middle pl-1 text-uppercase">
                    Columns
                  </small>
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownColumnsButton"
                  style={{ width: 'max-content', maxWidth: 'unset' }}
                >
                  {gridColumns
                    .filter((item) => {
                      if (showAllStore) {
                        return true;
                      }
                      return rowKeys?.includes(item.name);
                    })
                    .map((col) => {
                      return (
                        <label key={col.name} className="dropdown-item pointer">
                          <input
                            type="checkbox"
                            onChange={() => updateCols(col.name)}
                            defaultChecked={col.visible}
                          />{' '}
                          {col.title}
                        </label>
                      );
                    })}
                </div>
              </div>
            )}
            {actionBar && <div className={'mr-3 py-2'}>{actionBar}</div>}
          </div>
        ) : null}
        {total > 0 || showEmptyTable === true ? (
          <div className="bg-white horizontal-scrollbar">
            <table
              className="table"
              style={tableLayout === 'fixed' ? { tableLayout: 'fixed' } : null}
            >
              <thead>
                <tr>
                  {gridColumns
                    .filter((col) => {
                      if (
                        col.visible &&
                        (!col.visibleForOrderTypes ||
                          col.visibleForOrderTypes.includes(parentOrderType))
                      ) {
                        return showAllStore || rowKeys?.includes(col.name);
                      }
                    })
                    .map((col) => {
                      return (
                        <th
                          scope="col"
                          key={col.name}
                          style={
                            col?.name === 'currencySymbol' ||
                            col.name == 'dangerous'
                              ? { textAlign: 'center' }
                              : {
                                  width:
                                    col?.width ??
                                    `calc(100% / ${
                                      gridColumns.filter(
                                        (column) =>
                                          column.visible &&
                                          (!column.visibleForOrderTypes ||
                                            column.visibleForOrderTypes.includes(
                                              parentOrderType,
                                            )),
                                      ).length
                                    })`,
                                }
                          }
                        >
                          <a
                            className={
                              col.sortName && showSortColumns
                                ? 'link'
                                : 'inactive-link'
                            }
                            onClick={() =>
                              col.sortName ? updateSort(col.name) : null
                            }
                          >
                            {col.title}
                            {sort === col.name || sort === col.sortName ? (
                              <FaArrowDown className={'ml-2'} size={12} />
                            ) : null}
                            {sort === '-' + col.name ||
                            sort === '-' + col.sortName ? (
                              <FaArrowUp className={'ml-2'} size={12} />
                            ) : null}
                          </a>
                        </th>
                      );
                    })}
                  {onEdit || onDelete ? <th colSpan={2}>&nbsp;</th> : null}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="m-5 text-center">
                    <h3 className="text-muted mb-4">Loading...</h3>
                  </div>
                ) : (
                  data
                    ?.filter((x) => x.isRemoved !== true)
                    .map((row, rowIndex) => {
                      return (
                        <>
                          <tr
                            key={
                              rowKeys
                                ? rowKeys?.map((x) => row[x]).join('_')
                                : rowIndex
                            }
                          >
                            {Object.values(gridColumns)
                              ?.filter((item) => {
                                if (
                                  item.visible &&
                                  (!item.visibleForOrderTypes ||
                                    item.visibleForOrderTypes.includes(
                                      parentOrderType,
                                    ))
                                ) {
                                  return (
                                    showAllStore || rowKeys?.includes(item.name)
                                  );
                                }
                              })
                              ?.map((item, index) => {
                                return (
                                  <>
                                    <td
                                      key={`${rowKeys
                                        ?.map((x) => row[x])
                                        .join('_')}_${item?.name}`}
                                      onClick={
                                        item?.name === 'dangerous'
                                          ? () => {
                                              const toggle = document.querySelector(
                                                `.custom-toggle-checkmark-${rowIndex}`,
                                              ) as HTMLSpanElement;
                                              toggle.click();
                                            }
                                          : () => {
                                              if (
                                                onSelect &&
                                                item?.selectable !== false
                                              ) {
                                                onSelect(
                                                  row,
                                                  rowKeys?.reduce(
                                                    (keyObj, field) => {
                                                      return row[field];
                                                    },
                                                  ),
                                                );
                                              }
                                            }
                                      }
                                      className={
                                        item?.name !== 'shipments'
                                          ? 'cursor-pointer'
                                          : ''
                                      }
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        maxWidth:
                                          item?.name === 'description' ||
                                          item?.name === 'commodityTypeName'
                                            ? '200px'
                                            : 'none',
                                      }}
                                      title={
                                        item?.name === 'description' ||
                                        item?.name === 'commodityTypeName'
                                          ? row[item?.name]
                                          : null
                                      }
                                    >
                                      {typeof row[item?.name] === 'boolean' ? (
                                        row[item?.name] ? (
                                          <>&#x2713;</>
                                        ) : (
                                          <></>
                                        )
                                      ) : (item.name === 'commodityStatus' ||
                                          item.name ===
                                            'commodityStatusName') &&
                                        row[item?.name] ? (
                                        <StatusBadge
                                          label={
                                            row[item?.name][item?.dataField] ||
                                            row[item?.name]
                                          }
                                          stage={getActivityStatusStage(
                                            row.commodityStatus?.[
                                              'statusStage'
                                            ],
                                          )}
                                          enumType={StatusStage}
                                        />
                                      ) : item.name == 'activityStatus' ? (
                                        <StatusBadge
                                          label={row[item?.name]}
                                          stage={getActivityStatusStage(
                                            row[item?.name],
                                          )}
                                          enumType={StatusStage}
                                        />
                                      ) : item.name == 'packingStatus' ? (
                                        <StatusBadge
                                          label={
                                            row.customValues?.packingStatus
                                          }
                                          stage={getActivityStatusStage(
                                            row.customValues?.packingStatus,
                                          )}
                                          enumType={StatusStage}
                                        />
                                      ) : item.name == 'commodityAction' ? (
                                        <span
                                          style={
                                            row?.['activityStatus'] ===
                                            PickingActivityStatuses.Picked
                                              ? {
                                                  color: '#868e96',
                                                  cursor: 'default',
                                                }
                                              : { color: '#005CC7' }
                                          }
                                          onClick={
                                            selectNext
                                              ? () =>
                                                  selectNext(row['commodityId'])
                                              : null
                                          }
                                        >
                                          Select Next
                                        </span>
                                      ) : item.name == 'created' ||
                                        item.name == 'lastModified' ||
                                        item.name == 'updated' ? (
                                        getFormattedDate(
                                          (row[item?.name] as string).split(
                                            ',',
                                          )[0],
                                          false,
                                        )
                                      ) : item?.name ==
                                        'containerCommodityId' ? (
                                        <input
                                          className={'pointer'}
                                          type={'checkbox'}
                                          checked={
                                            row[item?.name] ==
                                            (containerCommodityId ?? 0)
                                          }
                                          onChange={() =>
                                            onSelect(row, rowKeys)
                                          }
                                          disabled={
                                            row[item?.name] &&
                                            (row[item?.name] !=
                                              containerCommodityId ||
                                              row.commodityId ==
                                                containerCommodityId)
                                          }
                                          onClick={() => {}}
                                        />
                                      ) : item.name ==
                                        'commodityTrackingNumber' ? (
                                        <>
                                          {isLoading ? (
                                            <Spinner
                                              size="sm"
                                              style={{ marginRight: '100px' }}
                                            />
                                          ) : (
                                            row[item?.name]
                                          )}
                                        </>
                                      ) : item.name == 'shipments' ? (
                                        <>
                                          {isLoading ? (
                                            <Spinner
                                              size="sm"
                                              style={{ marginRight: '100px' }}
                                            />
                                          ) : (
                                            shouldRenderShipments &&
                                            row[item?.name] &&
                                            row[item?.name]
                                              .filter((order) => {
                                                return item.orderTypes?.find(
                                                  (orderType) =>
                                                    orderType ==
                                                    order.orderType,
                                                );
                                              })
                                              .map((order, index) => (
                                                <>
                                                  <u
                                                    className="font-weight-bold cursor-pointer"
                                                    onClick={() => {
                                                      if (order.orderId) {
                                                        showDialog({
                                                          dialog: getDialog(
                                                            order.orderType,
                                                          ),
                                                          props: {
                                                            orderId:
                                                              order.orderId,
                                                            className:
                                                              'order-modal',
                                                            title: `Update ${order.orderType.replace(
                                                              /([a-z])([A-Z])/g,
                                                              `$1 $2`,
                                                            )}`,
                                                          },
                                                        }).then(() => {
                                                          if (setOrder) {
                                                            const params: GetOrderParams = {
                                                              orderId:
                                                                order.orderId,
                                                            };
                                                            getOrder(
                                                              params,
                                                            ).then((result) => {
                                                              if (result)
                                                                setOrder(
                                                                  result,
                                                                );
                                                            });
                                                          }
                                                        });
                                                      }
                                                    }}
                                                  >
                                                    {parentOrderType ===
                                                    'Purchase'
                                                      ? order.trackingNumber
                                                      : order.orderId}
                                                  </u>
                                                  {index <
                                                    row[item?.name].filter(
                                                      (order) => {
                                                        return item.orderTypes?.find(
                                                          (orderType) =>
                                                            orderType ==
                                                            order.orderType,
                                                        );
                                                      },
                                                    ).length -
                                                      1 && <> | </>}
                                                </>
                                              ))
                                          )}
                                        </>
                                      ) : item.name ==
                                        'parcelTrackingNumber' ? (
                                        <>
                                          {isLoading ? (
                                            <Spinner
                                              size="sm"
                                              style={{ marginRight: '100px' }}
                                            />
                                          ) : (
                                            row[item?.name] && (
                                              <>
                                                <u
                                                  className="font-weight-bold cursor-pointer"
                                                  onClick={() => {
                                                    if (goToParcelDetails)
                                                      goToParcelDetails(row);
                                                  }}
                                                >
                                                  {row[item?.name]}
                                                </u>
                                              </>
                                            )
                                          )}
                                        </>
                                      ) : item.name == 'received' ||
                                        item.name == 'expand' ? (
                                        <>
                                          {row[item?.dataField] &&
                                            row[item?.dataField]
                                              .filter((order) => {
                                                return item.orderTypes?.find(
                                                  (orderType) =>
                                                    orderType ==
                                                    order.orderType,
                                                );
                                              })
                                              .map((order, index) => (
                                                <>
                                                  {index == 0 &&
                                                    getFormattedDate(
                                                      order?.createdDate,
                                                      true,
                                                      true,
                                                    )}
                                                </>
                                              ))}
                                        </>
                                      ) : item.name == 'dangerous' ? (
                                        <DangerousToggle
                                          row={row}
                                          index={rowIndex}
                                        />
                                      ) : item.dataField ? (
                                        <>
                                          {row[item?.name]
                                            ? row[item?.name][item?.dataField]
                                            : null}
                                        </>
                                      ) : item.type === 'activityLocation' ? (
                                        <LocationInfo
                                          warehouseLocation={lodash.get(
                                            row,
                                            item.name,
                                          )}
                                          locationZones={warehouseZones}
                                          showIcon={false}
                                        />
                                      ) : expandable &&
                                        index == 0 &&
                                        row?.containerCommodities?.length ? (
                                        <div
                                          onClick={() =>
                                            toggleExpandRow(row?.commodityId)
                                          }
                                        >
                                          {isRowExpanded(row?.commodityId) ? (
                                            <FaChevronUp
                                              className={'grid-expand-chevron'}
                                              size={14}
                                            />
                                          ) : (
                                            <FaChevronRight
                                              className={'grid-expand-chevron'}
                                              size={14}
                                            />
                                          )}
                                        </div>
                                      ) : item.name == 'weight' ||
                                        item.name == 'weightTotal' ? (
                                        <>
                                          {Number(
                                            lodash.get(row, item.name),
                                          )?.toFixed(2)}
                                        </>
                                      ) : (
                                        <>{lodash.get(row, item.name)}</>
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                            {onDelete && onEdit && (
                              <td>
                                <div className="dropdown">
                                  <BsThreeDotsVertical
                                    className="dropdown-toggle dropdown-dots-vertical pointer"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  />
                                  <div
                                    className="dropdown-menu dropdown-menu-grid"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    {onDelete && (
                                      <a
                                        role="button"
                                        className="dropdown-item"
                                        onClick={() => {
                                          onDelete(row);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    )}
                                    {onEdit && (
                                      <a
                                        role="button"
                                        className="dropdown-item"
                                        onClick={() => {
                                          onEdit(row);
                                        }}
                                      >
                                        Edit
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                          {expandable && isRowExpanded(row?.commodityId)
                            ? row?.containerCommodities?.map(
                                (containerCommodity, commodityIndex) => {
                                  return (
                                    <tr
                                      key={`nested-row-${row?.commodityId}-${containerCommodity?.commodityId}`}
                                      className={'nested-row cursor-pointer'}
                                      onClick={() => {
                                        if (
                                          row.customValues?.packingStatus ===
                                          PackingStatuses.Scanned
                                        ) {
                                          const contentVerifyCheckbox = document.querySelector(
                                            `.checkbox-content-verify-${rowIndex}-${commodityIndex}`,
                                          ) as HTMLDivElement;
                                          contentVerifyCheckbox?.click();
                                        }
                                      }}
                                    >
                                      {gridColumns.map((col) => {
                                        if (col?.name == 'description')
                                          return (
                                            <td className="commodity-name">
                                              {containerCommodity?.description}
                                            </td>
                                          );
                                        else if (
                                          col?.name == 'shipments' &&
                                          setIsVerifiedAll
                                        )
                                          return (
                                            <td>
                                              <VerifyContentCheckbox
                                                row={containerCommodity}
                                                rows={row?.containerCommodities}
                                                rowIndex={rowIndex}
                                                commodityIndex={commodityIndex}
                                                userId={currentUser?.userId}
                                                setIsVerifiedAll={
                                                  setIsVerifiedAll
                                                }
                                                disabled={
                                                  row.customValues
                                                    ?.packingStatus !==
                                                  PackingStatuses.Scanned
                                                }
                                              />
                                            </td>
                                          );
                                        else if (col?.name == 'quantity') {
                                          return (
                                            <td>
                                              {containerCommodity?.quantity}
                                            </td>
                                          );
                                        } else return <td></td>;
                                      })}
                                    </tr>
                                  );
                                },
                              )
                            : null}
                        </>
                      );
                    })
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center mt-4">Nothing Found</p>
        )}
        {showPagination === true ? (
          <div className="d-flex justify-content-between grid-footer">
            <Pagination
              goToPage={onPageChanged}
              offset={offset}
              limit={limit}
              total={total}
              onLimitChanged={onLimitChanged}
              totalQuantity={totalQuantity}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
