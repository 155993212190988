import React, { useEffect } from 'react';
import {
  EntityTypes,
  OrderTypes,
  PackingStatuses,
  StatusStage,
} from '../../../../models/data.models';
import { MdCheckCircle, MdOutlineClose } from 'react-icons/md';
import { StatusBadge } from '../../../common/components/status-badge/status-badge';
import { TransportationMethodBadge } from '../../../common/components/status-badge/transportation-method-badge';
import { OrderForm } from '../../../orders/components/order.form';
import { useFormikContext } from 'formik';
import { getDialog } from '../../../../utils/orderDialogSelector.utils';
import { showDialog } from '../../../common/dialog.store';
import { GetOrderParams } from '../../../orders/orders.service';
import { getOrder } from '../../../orders/orders.store';

type ParcelShipmentInformationProps = {
  setOrderContextValues?: any;
  setParcelShipment?: any;
};

export const ParcelShipmentInformationFormik = ({
  setOrderContextValues,
  setParcelShipment,
}: ParcelShipmentInformationProps) => {
  const formikContext = useFormikContext<any>();

  useEffect(() => {
    const consignee = formikContext.values?.parcelShipment?.orderEntities?.find(
      (x) => x.entityType === EntityTypes.Consignee,
    );

    if (
      consignee?.contactAddress?.cityName &&
      consignee?.contactAddress?.country.name
    ) {
      const destinationValue = `${consignee.contactAddress.cityName}, ${consignee.contactAddress.country.name}`;
      formikContext.setFieldValue('destination', destinationValue);
    } else {
      formikContext.setFieldValue('destination', null);
    }
  }, [formikContext.values?.parcelShipment?.orderId]);

  const style = { fontSize: '0.875rem' };

  const parcelShipment = formikContext.values?.parcelShipment;
  const isConsolidated =
    parcelShipment?.customValues?.['isConsolidated'] === 'true' || false;

  const scannedCount = parcelShipment?.commodities?.filter(
    (x) => x.customValues?.packingStatus === PackingStatuses.Scanned,
  )?.length;

  const renderParcelShipmentInfoContent = () => {
    if (parcelShipment) {
      return (
        <>
          <div className="row mt-2 d-flex justify-content-between ml-1">
            <div className="col-8">
              {isConsolidated && (
                <>
                  <div className="row d-flex mb-1">
                    <div>
                      <h3 className="text-secondary" style={style}>
                        Type:
                      </h3>
                    </div>
                    <div className="ml-2">
                      <h3 style={style}>Consolidated</h3>
                    </div>
                  </div>
                  <div className="row d-flex mb-1">
                    <div>
                      <h3 className="text-secondary" style={style}>
                        Scanned:
                      </h3>
                    </div>
                    <div className="ml-2">
                      <h3
                        style={style}
                      >{`${scannedCount}/${parcelShipment?.commodities?.length}`}</h3>
                    </div>
                  </div>
                </>
              )}
              <div className="row d-flex mb-1">
                <div>
                  <h3 className="text-secondary" style={style}>
                    Parcel #:
                  </h3>
                </div>
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() => {
                    if (parcelShipment.orderId) {
                      showDialog({
                        dialog: getDialog(OrderTypes.ParcelShipment),
                        props: {
                          orderId: parcelShipment.orderId,
                          className: 'order-modal',
                          title: `Update ${OrderTypes.ParcelShipment.replace(
                            /([a-z])([A-Z])/g,
                            `$1 $2`,
                          )}`,
                        },
                      }).then(() => {
                        if (setParcelShipment) {
                          const params: GetOrderParams = {
                            orderId: parcelShipment.orderId,
                          };
                          getOrder(params).then((result) => {
                            if (result) setParcelShipment(result);
                          });
                        }
                      });
                    }
                  }}
                >
                  <u className="text-primary">
                    <h3 className="text-primary" style={style}>
                      {parcelShipment?.trackingNumber ?? '-'}
                    </h3>
                  </u>
                </div>
              </div>
              <div className="row d-flex">
                <div>
                  <h3 className="text-secondary" style={style}>
                    Destination:
                  </h3>
                </div>
                <div className="ml-2">
                  <h3 style={style}>
                    {formikContext.values?.destination ?? '-'}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-end mb-2">
              <div className="row" style={{ maxHeight: 22 }}>
                <StatusBadge
                  label={parcelShipment?.orderStatus?.orderStatusName}
                  stage={parcelShipment?.orderStatus?.statusStage}
                  enumType={StatusStage}
                  style={{ marginRight: 0 }}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <div className="row py-3 pl-2">
            <MdOutlineClose className="text-danger" />
            &nbsp;
            <h3 className="text-danger">No Parcel Shipment Available</h3>
          </div>
          <div>
            <OrderForm.PackageDescription
              id={'packageDescription'}
              onChange={(data?: any) => {
                if (data?.target?.value) {
                  setOrderContextValues((prevData) => ({
                    ...prevData,
                    packageDescription: data?.target?.value,
                  }));
                } else {
                  setOrderContextValues((prevData) => {
                    delete prevData.packageDescription;
                    return prevData;
                  });
                }
              }}
              nameId={'packageDescription'}
              className="mb-0"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <MdCheckCircle className="text-primary mr-2" />
          <div className="pb-2 text-label text-primary">
            Parcel Shipment Information
          </div>
        </div>
        <TransportationMethodBadge
          label={
            parcelShipment?.customValues?.[
              'modeOfTransportationIdDescription'
            ] ?? ''
          }
        />
      </div>
      {renderParcelShipmentInfoContent()}
    </>
  );
};
