import React from 'react';
import * as Yup from 'yup';
import {
  CommodityDto,
  DimensionsUnit,
  WeightUnit,
} from '../../../../models/data.models';
import {
  getEnumValues,
  validatePositiveNumberInput,
} from '../../../../utils/helper.utils';
import { FormikProps, useFormikContext } from 'formik';
import { Spinner } from '../../../common/components/spinner/spinner';
import { HiX } from 'react-icons/hi';
import { PackingForm } from '../../packingWizard/packing.form';
import {
  ACTION_SHIPPING_PALLET,
  ACTION_UPDATE_COMMODITY,
} from '../../../constants/api';

//validation
const numberSchema = Yup.number()
  .typeError('Incorrect number format')
  .min(0, 'Min value is 0')
  .max(999999, 'Max value is 999999');

export const packageSchema = {
  purchase: Yup.object().when('_submitAction', (val, schema) => {
    return val === ACTION_SHIPPING_PALLET || val === ACTION_UPDATE_COMMODITY
      ? schema.shape({
          commodities: Yup.array().of(
            Yup.object().shape({
              weight: numberSchema.required("Can't be blank").nullable(),
              weightUnit: Yup.string().nullable().required("Can't be blank"),
              length: numberSchema.required("Can't be blank").nullable(),
              width: numberSchema.required("Can't be blank").nullable(),
              height: numberSchema.required("Can't be blank").nullable(),
              dimensionsUnit: Yup.string()
                .nullable()
                .required("Can't be blank"),
            }),
          ),
        })
      : schema;
  }),
};

const onDimensionsUnitChange = async (
  data?: { label: string; value: DimensionsUnit },
  context?: FormikProps<any>,
) => {
  const updatedCommodity: CommodityDto = {
    ...context.values.purchase.commodities[0],
    dimensionsUnit: data?.value,
  };

  context?.setFieldValue('purchase.commodities[0]', updatedCommodity);
};

const onWeightUnitChange = async (
  data?: { label: string; value: WeightUnit },
  context?: FormikProps<any>,
) => {
  const updatedCommodity: CommodityDto = {
    ...context.values.purchase.commodities[0],
    weightUnit: data?.value,
  };

  context?.setFieldValue('purchase.commodities[0]', updatedCommodity);
};

export const DimensionsUpdate = () => {
  const formikContext = useFormikContext<any>();
  const commodity: CommodityDto =
    formikContext.values?.purchase?.commodities?.[0];

  return commodity ? (
    <div className="row">
      <div className={'col-lg-6 col-sm-12 d-flex'}>
        <div className="col-3">
          <h3 className={'text-nowrap h-100 d-flex align-items-center'}>
            Total Weight
          </h3>
        </div>
        <div className="col-4">
          <div className={'h-100 d-flex align-items-center'}>
            <PackingForm.Weight
              id={'purchase.commodities[0].weight'}
              label={null}
              onKeyDown={validatePositiveNumberInput}
              onChange={(data) => {
                formikContext.setFieldValue(
                  'purchase.commodities.[0].weight',
                  Number(data.target.value),
                );
              }}
              className={'mb-0'}
              labelStyles={{ height: 0 }}
            />
          </div>
        </div>
        <div className="col-2">
          <div className={'h-100 d-flex align-items-center'}>
            <PackingForm.WeightUnit
              id={'purchase.commodities[0].weightUnit'}
              label={null}
              isClearable={false}
              options={getEnumValues(WeightUnit)}
              defaultValue={
                commodity?.weightUnit
                  ? {
                      label: WeightUnit[commodity?.weightUnit],
                      value: commodity?.weightUnit,
                    }
                  : null
              }
              className={'w-100 mb-0'}
              valueInput={
                commodity?.weightUnit
                  ? {
                      label: WeightUnit[commodity.weightUnit],
                      value: commodity.weightUnit,
                    }
                  : null
              }
              onChange={(data?: { label: string; value: WeightUnit }) => {
                onWeightUnitChange(data, formikContext);
              }}
            />
          </div>
        </div>
      </div>

      <div className={'col-lg-6 col-sm-12 d-flex'}>
        <div className="col-5">
          <h3 className={'text-nowrap h-100 d-flex align-items-center'}>
            Total Dimensions (LxWxH)
          </h3>
        </div>
        <div className="col-5 d-flex align-items-center">
          <PackingForm.Length
            id={'purchase.commodities[0].length'}
            label={null}
            onChange={(data) => {
              formikContext.setFieldValue(
                'purchase.commodities.[0].length',
                Number(data.target.value),
              );
            }}
            style={{ marginBottom: 0 }}
          />
          <HiX size={40} className={'mx-2'} />
          <PackingForm.Width
            id={'purchase.commodities[0].width'}
            label={null}
            onChange={(data) => {
              formikContext.setFieldValue(
                'purchase.commodities.[0].width',
                Number(data.target.value),
              );
            }}
            style={{ marginBottom: 0 }}
          />
          <HiX size={40} className={'mx-2'} />
          <PackingForm.Height
            id={'purchase.commodities[0].height'}
            label={null}
            onChange={(data) => {
              formikContext.setFieldValue(
                'purchase.commodities.[0].height',
                Number(data.target.value),
              );
            }}
            style={{ marginBottom: 0 }}
          />
        </div>
        <div className="col-2">
          <div className={'h-100 d-flex align-items-center'}>
            <PackingForm.DimensionsUnit
              id={'purchase.commodities[0].dimensionsUnit'}
              label={null}
              isClearable={false}
              options={getEnumValues(DimensionsUnit)}
              defaultValue={
                commodity?.dimensionsUnit
                  ? {
                      label: DimensionsUnit[commodity?.dimensionsUnit],
                      value: commodity?.dimensionsUnit,
                    }
                  : null
              }
              valueInput={
                commodity?.volumeUnit
                  ? {
                      label: DimensionsUnit[commodity.dimensionsUnit],
                      value: commodity.dimensionsUnit,
                    }
                  : null
              }
              onChange={(data?: { label: string; value: DimensionsUnit }) => {
                onDimensionsUnitChange(data, formikContext);
              }}
              className={'w-100 mb-0'}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};
