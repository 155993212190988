import { useCallback, useMemo } from 'react';
import { parseTemplate } from '../component-hooks';
import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';

export const LayoutComponent = (props: ComponentProps) => {
  const { orientation, cols, className, childClassName, id } =
    props?.props || {};

  const rowColsClass = useMemo(() => {
    if (orientation === 'vertical') {
      return `row-cols-${cols ?? 1}`;
    } else if (cols > 0) {
      return `row-cols-${cols ?? 1}`;
    } else if (cols === 0) {
      return '';
    } else if (orientation === 'flex') {
      return '';
    } else {
      return 'row-cols-1';
    }
  }, [orientation, cols]);

  const onClick = useCallback(() => {
    if (props?.props?.onClick && props.context?.action) {
      props.context?.action(props.props.onClick, props.variables, {
        sender: props.name,
        actions: props.actions,
      });
    }
  }, [props]);

  const classNameMemo = useMemo(() => {
    const cls = parseTemplate(className, {
      ...props?.variables,
      ...props?.context?.store,
    });
    if (cls) return cls;
    return '';
  }, [props]);

  const combinedClasses = useMemo(() => {
    let orientationClass = '';

    switch (orientation) {
      case 'vertical':
        orientationClass = `d-flex flex-column`;
        break;
      case 'flex':
        orientationClass = `d-flex flex-row`;
        break;
      case 'horizontal':
      default:
        orientationClass = `d-flex flex-row flex-wrap row-gap`;
        break;
    }

    return `${orientationClass}  ${rowColsClass}  ${classNameMemo}`.trim();
  }, [orientation, classNameMemo]);

  const childClassNameMemo = useMemo(() => {
    switch (orientation) {
      case 'flex':
        return `m-1`;
      default:
        return ``;
    }
  }, [props]);

  const idMemo = useMemo(() => {
    return parseTemplate(id, props?.variables);
  }, [props]);

  return (
    <div className={combinedClasses} id={idMemo} onClick={onClick}>
      {typeof props.children?.map === 'function' &&
        props.children?.map((child: ComponentProps, i) => {
          if (!child) return null;

          const childWithPrefix = {
            ...child,
            props: {
              prefix: props.props?.prefix,
              parentId: props?.props?.parentId,
              parentType: props?.props?.parentType,
              ...child.props,
            },
            context: props.context,
            variables: props.variables,
          };

          const parsedChildClass = parseTemplate(child.props?.className, {
            ...props?.variables,
            ...props?.context?.store,
          });

          const combinedClass = [
            childClassNameMemo,
            childClassName,
            parsedChildClass,
          ]
            .filter(Boolean)
            .join(' ')
            .trim();

          const componentKey = child.name ? `${child.name}_${i}` : i;

          return (
            <ComponentRender
              key={componentKey}
              {...childWithPrefix}
              wrapper={<div className={combinedClass} />}
            />
          );
        })}
    </div>
  );
};
