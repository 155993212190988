import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { ChargeForm } from './charge.form';
import {
  AccountingItemDto,
  ApplyBy,
  CalculatedOf,
  ChargeDto,
  ChargeStatuses,
  ChargeType,
  ContactDto,
  ContactType,
  CurrencyDto,
  DecimalPlace,
  ItemType,
  PaidAs,
  RateDataDto,
  RateDto,
  RatePerType,
  SalesTaxDto,
  TariffOptions,
} from '../../../models/data.models';
import { createChargeFx, getChargeFx, updateChargeFx } from '../charges.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import { FormikProps, FormikValues } from 'formik';
import { getEnumValues } from '../../../utils/helper.utils';
import {
  getFormattedPrice,
  getMaxDecimals,
  getSingleCommaFormattedPrice,
} from '../../../utils/formatting.utils';
import { getCurrencyFx } from '../../currencies/currencies.store';
import * as Yup from 'yup';
import { organizationsStore } from '../../organization/organization.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { getRateFx } from '../../rates/rates.store';
import { getAccountingItemFx } from '../../accountingItems/accountingItems.store';
import { ChargeStatus } from './charge-status.component';

export type ChargeEditProps = {
  chargeId?: number | null;
  chargeType?: ChargeType | null;
  defaultCharge?: ChargeDto | null;
  onChargeCreated?: (charge: ChargeDto) => void;
  onChargeUpdated?: (charge: ChargeDto) => void;
  onChargeLoaded?: (charge: ChargeDto) => void;
  isEditMode?: boolean | null;
  isCreateModeForTransaction?: boolean | null;
  saveButtonRenderCondition?: boolean;
  onCancel?: () => void;
  charges?: ChargeDto[] | undefined;
};

const initialState: ChargeDto = {
  currencyName: '',
  currencyId: null,
  currencyDecimals: null,
  currencySymbol: null,
  note: undefined,
  amountAndTaxAmount: null,
  currency: null,
  expense: null,
  income: null,
  prepaid: null,
  chargeStatus: undefined,
  salesTaxAmount: null,
  salesTaxCode: '',
  salesTaxId: null,
  salesTaxRate: null,
  totalAmount: null,
  accountingItemName: '',
  applyToContactName: '',
  applyToContactType: null,
  chargeId: null,
  accountingItemId: null,
  amount: null,
  applyBy: null,
  applyToContactId: null,
  chargeType: null,
  description: null,
  freightServiceClassId: null,
  grossVolume: null,
  grossWeight: null,
  isConsolidated: false,
  allowAutomaticUpdate: false,
  paidAs: null,
  pieces: null,
  price: null,
  quantity: null,
  showInDocuments: false,
  unit: null,
  rateId: null,
  links: [],
};

const chargeSchema = Yup.object().shape({
  accountingItemId: Yup.string().required("Can't be blank").nullable(true),
  applyToContactId: Yup.string().required("Can't be blank").nullable(true),
  quantity: Yup.number()
    .positive("Can't be less than or equal to 0")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Can't be blank")
    .test('numberFormat', 'The value is too big', (value) => {
      return value < Number.MAX_SAFE_INTEGER;
    })
    .nullable(true),
  unit: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .nullable(true),
  price: Yup.number()
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === undefined
      );
    })
    .test('length', 'Max number length is 17', (value) => {
      return value?.toString().replace('-', '').length <= 17;
    })
    .required("Can't be blank")
    .nullable(true),
  amount: Yup.number().required("Can't be blank").nullable(true),
});

export const ChargeEdit = ({
  chargeId,
  defaultCharge = null,
  chargeType,
  onChargeLoaded = () => {},
  onChargeCreated = () => {},
  onChargeUpdated = () => {},
  onCancel = () => {},
  isEditMode = false,
  isCreateModeForTransaction = false,
  saveButtonRenderCondition = false,
  charges = [],
}: ChargeEditProps) => {
  const isCreateModeForEnity = !chargeId || chargeId == 0;
  const isCreateMode = isCreateModeForEnity && !isEditMode;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<ChargeDto | null>(
    defaultCharge || initialState,
  );
  const [charge, setCharge] = useState<ChargeDto | null>(initialValues);
  const [chargeStatus, setChargeStatus] = useState<ChargeStatuses>(null);
  const [isCalculated, setIsCalculated] = useState(false);
  const [defaultApplyBy, setDefaultApplyBy] = useState(
    charge?.applyBy
      ? {
          label: ApplyBy[charge?.applyBy],
          value: charge?.applyBy,
        }
      : '',
  );
  const [accountItem, setAccountItem] = useState(null);
  const [isLoadedAccountItem, setIsLoadedAccountItem] = useState(false);
  const [defaultCalculatedOf, setDefaultCalculatedOf] = useState(null);
  const [qValue, setQValue] = useState(
    charge.quantity ? charge.quantity.toFixed(2) : '',
  );
  const [loaded, setLoaded] = useState(true);
  const [isDisabled, setIsDisabled] = useState(
    chargeStatus === ChargeStatuses.Paid,
  );
  const [salesTax, setSalesTax] = useState<{
    salesTaxId: string;
    salesTaxCode: string;
  }>(null);
  const [rateForSelect, setRateForSelect] = useState<RateDto>(null);

  useEffect(() => {
    if (defaultCharge && defaultCharge.accountingItemId) {
      getAccountingItemFx({
        accountingItemId: defaultCharge.accountingItemId,
      }).then((data) => {
        setAccountItem(data);
        setFormattedPrice(
          getSingleCommaFormattedPrice(
            initialValues?.price *
              (data.tariff?.applyBy === ApplyBy.Calculated ? 100 : 1),
            2,
          ),
        );
        if (
          data.tariff?.applyBy === ApplyBy.Calculated &&
          data.tariff?.calculatedOf
        ) {
          setDefaultCalculatedOf({
            label: data.tariff.calculatedOf,
            value: data.tariff.calculatedOf,
          });
        }
      });
    } else {
      setIsLoadedAccountItem(true);
    }
  }, []);

  useEffect(() => {
    if (accountItem) {
      if (!isLoadedAccountItem) {
        const chargeIsCalculated =
          accountItem?.tariff?.applyBy === ApplyBy.Calculated;
        setIsCalculated(chargeIsCalculated);
      }
      if (isCalculated && isLoadedAccountItem && !charge.rate?.tariff) {
        setFormattedPrice(
          getSingleCommaFormattedPrice(
            accountItem?.tariff?.rateMultiplier * 100,
            2,
          ),
        );
        const rate = Number(accountItem?.tariff?.rateMultiplier || 1);
        const quantity = calculatedTotal(accountItem?.tariff);
        setCharge((chargeDto) => {
          chargeDto.price = Number(rate);
          chargeDto.quantity = quantity;
          chargeDto.amount = Number(getFormattedChargeAmount());
          return { ...chargeDto };
        });
      }
      if (!isLoadedAccountItem) setIsLoadedAccountItem(true);
    }
  }, [accountItem]);

  const calculatedTotal = (tariff = null) => {
    let totalIncome = 0;
    let totalExpense = 0;
    charges.forEach((chargeItem) => {
      if (chargeItem.applyBy !== ApplyBy.Calculated) {
        switch (chargeItem.chargeType) {
          case ChargeType.IncomeCharge:
            totalIncome += chargeItem.amount;
            break;
          case ChargeType.ExpenseCharge:
            totalExpense += chargeItem.amount;
            break;
        }
      }
    });

    const totalProfit = totalIncome - totalExpense;

    switch (tariff ? tariff?.calculatedOf : accountItem?.tariff?.calculatedOf) {
      case CalculatedOf.Income:
        return totalIncome;
      case CalculatedOf.Expense:
        return totalExpense;
      case CalculatedOf.Profit:
        return totalProfit;
      default:
        return 1;
    }
  };

  const hasFreightRate =
    charge?.rateId !== null &&
    charge?.accountingItem?.itemType === ItemType.Freight;

  const isAmountDisabled =
    (chargeStatus !== null &&
      !(
        chargeStatus === ChargeStatuses.Open ||
        chargeStatus === ChargeStatuses.Posted
      )) ||
    hasFreightRate ||
    rateForSelect != null;

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (chargeId || isEditMode) {
      if (defaultCharge) {
        setChargeStatus(defaultCharge?.chargeStatus);
        setCharge(defaultCharge);
        setInitialValues(defaultCharge);
        setSalesTax((salesTaxDto) => {
          if (!salesTaxDto) {
            salesTaxDto = {
              salesTaxId: null,
              salesTaxCode: null,
            };
          }
          salesTaxDto.salesTaxId = defaultCharge?.salesTaxId?.toString();
          salesTaxDto.salesTaxCode = defaultCharge?.salesTaxCode;
          return { ...salesTaxDto };
        });

        if (defaultCharge.rateId) {
          getRateFx({ rateId: defaultCharge?.rateId })
            .then((rate: RateDto) => {
              setRateForSelect(rate);
              defaultCharge.unit = defaultCharge.unit ?? rate?.tariff?.unitType;
              setCharge({
                ...defaultCharge,
              });
            })
            .finally(() => {
              setIsLoading(false);
              onChargeLoaded(defaultCharge);
            });
        } else {
          setIsLoading(false);
          onChargeLoaded(defaultCharge);
        }
      } else {
        getChargeFx({ chargeId }).then((chargeDto: ChargeDto) => {
          setChargeStatus(chargeDto?.chargeStatus);
          setCharge(chargeDto);
          setInitialValues(chargeDto);
          setSalesTax((salesTaxDto) => {
            if (!salesTaxDto) {
              salesTaxDto = {
                salesTaxId: null,
                salesTaxCode: null,
              };
            }
            salesTaxDto.salesTaxId = chargeDto?.salesTaxId?.toString();
            salesTaxDto.salesTaxCode = chargeDto?.salesTaxCode;
            return { ...salesTaxDto };
          });
          setIsLoading(false);
          onChargeLoaded(chargeDto);
        });
      }
    } else {
      throw new Error('Charge keys were not provided');
    }
  }, [chargeId]);

  const [formattedPrice, setFormattedPrice] = useState(
    getSingleCommaFormattedPrice(
      initialValues?.price,
      initialValues?.accountingItemName === 'Insurance'
        ? DecimalPlace.Four
        : initialValues?.currencyDecimals,
    ),
  );

  const onSubmitFunction = async (data: ChargeDto) => {
    if (chargeType && data) {
      data = {
        ...data,
        chargeType,
        currencyDecimals: charge.currencyDecimals,
        price: Number(data.price),
        amount:
          data.rateId != null &&
          data.accountingItem?.itemType === ItemType.Freight
            ? data.amount
            : (data.quantity ?? 0) * (data.price ?? 0),
        [data.chargeType === ChargeType.IncomeCharge
          ? 'income'
          : 'expense']: data.amount,
      };

      const { symbol, decimalPlaces, currencyName } = await getCurrencyFx({
        currencyId: data.currencyId,
      });

      data = {
        ...data,
        currencySymbol: symbol,
        currencyDecimals: decimalPlaces,
        currencyName,
      };

      if (!defaultCharge) {
        setIsSending(true);

        try {
          const result = isCreateMode
            ? await createChargeFx({
                data: { ...data, chargeStatus: ChargeStatuses.Open },
              })
            : await updateChargeFx({ data });

          isCreateMode ? onChargeCreated(result) : onChargeUpdated(result);
        } finally {
          setIsSending(false);
        }
      } else {
        // if we are editing charge in the context of order or invoice
        // do a dry run to check if we can update the charge
        setIsSending(true);

        try {
          const options = { dryRun: true };
          const result = isCreateMode
            ? await createChargeFx({
                data: { ...data, chargeStatus: ChargeStatuses.Open },
                options,
              })
            : await updateChargeFx({ data, options });

          isCreateMode
            ? onChargeCreated({ ...result, chargeStatus: ChargeStatuses.Open })
            : onChargeUpdated(result);
        } finally {
          setIsSending(false);
        }
      }
    }
  };

  const onSubmit = (data: ChargeDto) => {
    const organizationId = organizationsStore.getState().currentOrganization
      ?.organizationId;
    const chargeId = data?.chargeId;
    const chargeCreateMode = data?.createMode;
    if (!chargeCreateMode) {
      getChargeFx({ organizationId, chargeId }).then((chargeDto: ChargeDto) => {
        if (
          !(chargeDto?.chargeStatus !== ChargeStatuses.Paid) &&
          isDisabled === false
        ) {
          addMessage({
            message: `${chargeDto?.chargeStatus} charge has limited update capabilities. Please, refresh the page.`,
            type: 'danger',
            id: v4(),
          });
        } else {
          onSubmitFunction(data);
        }
      });
    } else {
      onSubmitFunction(data);
    }
  };

  const getFormattedChargeAmount = (): string => {
    if (
      charge?.rateId === null ||
      charge?.accountingItem?.itemType !== ItemType.Freight
    ) {
      return getFormattedPrice(
        (charge?.quantity ? charge?.quantity : 0) *
          (charge?.price ? charge?.price : 0),
        charge?.currencyDecimals,
      );
    }
    return getFormattedPrice(charge.amount, charge?.currencyDecimals);
  };
  const quantityOnChange = (value, context) => {
    setLoaded(false);
    context.setFieldValue('quantity', value ? Number(value) : 0);
    setCharge((chargeDto) => {
      chargeDto.quantity = value ? Number(value) : 0;

      if (rateForSelect) {
        setPrice(rateForSelect, chargeDto, context);
      }

      chargeDto.amount =
        (Number(value) ? Number(value) : 0) *
        (chargeDto?.price ? chargeDto?.price : 0);
      context.setFieldValue('amount', chargeDto.amount);

      return { ...chargeDto };
    });
  };
  const priceOnChange = (value, context) => {
    context.setFieldValue('price', value ? Number(value) : '');
    setFormattedPrice(value ?? '');
    setCharge((chargeDto) => {
      chargeDto.price = value ? Number(value) : null;
      return { ...chargeDto };
    });
  };

  const applyOnChange = (value, context) => {
    const fixedRate = getSingleCommaFormattedPrice(value / 100, 2);
    context.setFieldValue('price', Number(fixedRate));
    setFormattedPrice(value);
    setCharge((chargeDto) => {
      chargeDto.price = Number(fixedRate);
      return { ...chargeDto };
    });
  };

  const accountingItemOnChange = (
    data: AccountingItemDto,
    context: FormikProps<FormikValues>,
  ) => {
    setCharge((chargeDto) => {
      if (!chargeDto) {
        chargeDto = initialValues;
      }
      chargeDto.accountingItemId = data?.accountingItemId;
      chargeDto.accountingItemName = data?.description;
      chargeDto.salesTaxId = data?.salesTaxId;
      chargeDto.salesTaxCode = data?.salesTaxCode;
      chargeDto.price = data?.price;
      chargeDto.description = data?.description;
      setFormattedPrice(
        getSingleCommaFormattedPrice(
          chargeDto.price,
          initialValues?.currencyDecimals,
        ),
      );
      setSalesTax((salesTaxDto) => {
        if (!salesTaxDto) {
          salesTaxDto = {
            salesTaxCode: null,
            salesTaxId: null,
          };
        }
        salesTaxDto.salesTaxId = chargeDto.salesTaxId?.toString();
        salesTaxDto.salesTaxCode = chargeDto.salesTaxCode;
        return { ...salesTaxDto };
      });
      context.setFieldValue('description', chargeDto.description ?? '');
      context.setFieldValue('price', chargeDto.price);
      context.setFieldValue('amount', chargeDto.amount);
      context.setFieldValue('salesTaxId', chargeDto.salesTaxId);
      context.setFieldValue('salesTaxCode', chargeDto.salesTaxCode);

      setAccountItem(data);
      if (data?.tariff?.applyBy && !charge.rate?.tariff) {
        const { quantity, rate, amount } = applyTariff(data?.tariff, context);
        if (quantity || rate || amount) {
          chargeDto.quantity = quantity;
          chargeDto.price = rate;
          chargeDto.amount = amount;
        }
      }

      return { ...chargeDto };
    });
  };

  const applyTariff = (tariff, context) => {
    const chargeIsCalculated = tariff?.applyBy === ApplyBy.Calculated;
    setIsCalculated(chargeIsCalculated);

    setFormattedPrice(
      getSingleCommaFormattedPrice(tariff?.rateMultiplier * 100, 2),
    );

    setDefaultApplyBy({
      label: tariff.applyBy,
      value: tariff.applyBy,
    });
    context.setFieldValue('applyBy', tariff.applyBy);

    if (chargeIsCalculated) {
      setDefaultCalculatedOf({
        label: tariff.calculatedOf,
        value: tariff.calculatedOf,
      });
      const rate = Number(tariff?.rateMultiplier || 1);
      const quantity = calculatedTotal(tariff);
      const amount = Number((rate * quantity).toFixed(2));
      context.setFieldValue('price', rate);
      context.setFieldValue('amount', amount);
      context.setFieldValue('quantity', quantity);
      return { quantity, rate, amount };
    }
    return { quantity: null, rate: null, amount: null };
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const onChangeApplyBy = (newValueApplyBy?: ReactSelectItem) => {
    setIsCalculated(newValueApplyBy?.value === ApplyBy.Calculated);
  };

  const onChangeCalculatedOf = (newValueApplyBy?: ReactSelectItem) => {};
  const onChangePaidAs = (newValuePaidAs?: ReactSelectItem) => {};

  const calculatePrice = (tariff: TariffOptions, quantity?: number): number => {
    switch (tariff?.ratePerType) {
      case RatePerType.Unit: {
        return tariff.ratePer;
      }
      case RatePerType.Range: {
        if (
          tariff?.rateData &&
          tariff?.applyBy !== ApplyBy.Container &&
          tariff?.applyBy !== ApplyBy.Calculated
        ) {
          const rate = chooseRate(tariff?.rateData, quantity);
          return rate;
        }
        return;
      }
      default:
        return;
    }
  };

  const chooseRate = (rates: RateDataDto[], quantity: number) => {
    let selectedRate = rates[0].rateValue ?? 0;
    rates.forEach((rate) => {
      if (
        rate.rateIndex != null &&
        rate.rateValue != null &&
        rate.rateIndex / 1000 < quantity
      ) {
        selectedRate = rate.rateValue;
      }
    });
    return selectedRate;
  };

  function setPrice(rate: RateDto, chargeDto: ChargeDto, context) {
    const calculatedPrice = calculatePrice(rate.tariff, chargeDto?.quantity);
    context.setFieldValue('price', Number(calculatedPrice));
    setFormattedPrice(
      getSingleCommaFormattedPrice(calculatedPrice, charge?.currencyDecimals),
    );
    chargeDto.price = Number(calculatedPrice);
  }

  const rateOnChange = (data?: RateDto, context?) => {
    setCharge((chargeDto) => {
      if (!chargeDto) {
        chargeDto = initialValues;
      }

      chargeDto.rateId = data?.rateId;
      chargeDto.rate = data;
      if (data?.tariff?.applyBy) {
        context.setFieldValue('applyBy', data?.tariff?.applyBy);
        chargeDto.applyBy = data?.tariff?.applyBy;
      }

      if (data?.tariff?.unitType) {
        context.setFieldValue('unit', data?.tariff?.unitType);
        chargeDto.unit = data?.tariff?.unitType;
      }

      if (data?.tariff) {
        setPrice(data, chargeDto, context);
        const { quantity, rate, amount } = applyTariff(data?.tariff, context);
        if (quantity || rate || amount) {
          chargeDto.quantity = quantity;
          chargeDto.price = rate;
          chargeDto.amount = amount;
        }
      } else if (accountItem?.tariff) {
        const { quantity, rate, amount } = applyTariff(
          accountItem?.tariff,
          context,
        );
        if (quantity || rate || amount) {
          chargeDto.quantity = quantity;
          chargeDto.price = rate;
          chargeDto.amount = amount;
        }
      }
      return { ...chargeDto };
    });

    setRateForSelect(data);
  };

  if (chargeType === ChargeType.IncomeCharge) {
    return (
      <ChargeForm
        id={'ChargeForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={chargeSchema}
      >
        <Tabs>
          <TabList>
            <Tab>Income Charge</Tab>
          </TabList>
          <TabPanel>
            <Panel className="mt-3 mx-3">
              <div className="row p-0 row-charge">
                <div className={'col-12 d-flex p-0'}>
                  <div className={'col mr-3 p-0'}>
                    <ChargeForm.AccountingItemSelect
                      selectedFilter={` `}
                      required={true}
                      nameId={'accountingItemName'}
                      id={'accountingItemId'}
                      header={'Charge / Accounting Item'}
                      placeholder={'Select Charge'}
                      defaultValue={
                        charge?.accountingItemId && charge?.accountingItemName
                          ? {
                              accountingItemId: charge?.accountingItemId,
                              description: charge?.accountingItemName,
                            }
                          : ''
                      }
                      onChange={accountingItemOnChange}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={`col mr-3 p-0`}>
                    <ChargeForm.TaxCodeSelect
                      id={'salesTaxId'}
                      header={'Tax Code'}
                      selectedFilter={` `}
                      required={false}
                      defaultValue={
                        salesTax &&
                        salesTax.salesTaxId != null &&
                        salesTax.salesTaxCode != null
                          ? {
                              salesTaxId: salesTax.salesTaxId,
                              taxCode: salesTax.salesTaxCode,
                            }
                          : ''
                      }
                      onChange={(data?: SalesTaxDto) => {
                        setSalesTax((salesTaxDto) => {
                          if (!salesTaxDto) {
                            salesTaxDto = {
                              salesTaxCode: null,
                              salesTaxId: null,
                            };
                          }
                          salesTaxDto.salesTaxId = data?.salesTaxId?.toString();
                          salesTaxDto.salesTaxCode = data?.taxCode;
                          return { ...salesTaxDto };
                        });
                      }}
                      nameId={'salesTaxCode'}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={'col p-0'}>
                    <ChargeForm.CurrencySelect
                      selectedFilter={` `}
                      required={false}
                      nameId={'currencyCode'}
                      id={'currencyId'}
                      header={'Currency'}
                      defaultValue={
                        charge?.currencyId &&
                        charge?.currencyName &&
                        charge?.currencyDecimals
                          ? {
                              currencyId: charge.currencyId,
                              currencyCode: charge.currencyName,
                            }
                          : ''
                      }
                      onChange={(data?: CurrencyDto) => {
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.currencyId = data?.currencyId;
                          chargeDto.currencyName = data?.currencyCode;
                          chargeDto.currencyDecimals = data?.decimalPlaces;
                          const price = getSingleCommaFormattedPrice(
                            chargeDto?.price,
                            data?.decimalPlaces,
                          );
                          chargeDto.price = Number(price);
                          setFormattedPrice(price);
                          return { ...chargeDto };
                        });
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className={'row col-12 p-0'}>
                  <div className={'col-9 pr-3'}>
                    <ChargeForm.Description />
                  </div>
                  {chargeStatus === ChargeStatuses.Pending && (
                    <div className={'col-3 p-0'}>
                      <ChargeForm.ChargeStatus
                        defaultValue={defaultCalculatedOf}
                        options={getEnumValues(ChargeStatuses)}
                      />
                    </div>
                  )}
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col p-0 mr-3'}>
                    <ChargeForm.ApplyToContactSelect
                      id={'applyToContactId'}
                      header={'Bill to Account'}
                      contactTypes={[
                        ContactType.Customer,
                        ContactType.Vendor,
                        ContactType.ForwardingAgent,
                        ContactType.Contact,
                      ]}
                      selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
                      required={false}
                      defaultValue={
                        charge.applyToContactId &&
                        charge.applyToContactName != null &&
                        charge.applyToContactType !== null
                          ? {
                              contactId: charge.applyToContactId,
                              name: charge.applyToContactName,
                              contactType: charge.applyToContactType,
                            }
                          : ''
                      }
                      onChange={(
                        data?: ContactDto,
                        context?: FormikProps<FormikValues>,
                      ) => {
                        context?.setFieldValue(
                          'applyToContactType',
                          data?.contactType,
                        );
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.applyToContactName = data?.name;
                          chargeDto.applyToContactId = data?.contactId;
                          chargeDto.applyToContactType = data?.contactType;
                          return { ...chargeDto };
                        });
                      }}
                      nameId={'applyToContactName'}
                      disabled={
                        (isCreateModeForTransaction &&
                          !!defaultCharge?.applyToContactId) ||
                        isDisabled
                      }
                    />
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.PaidAs
                      defaultValue={
                        charge?.paidAs
                          ? {
                              label: PaidAs[charge?.paidAs],
                              value: charge?.paidAs,
                            }
                          : ''
                      }
                      options={getEnumValues(PaidAs)}
                      onChange={onChangePaidAs}
                      disabled={
                        isCreateModeForTransaction && !!defaultCharge?.paidAs
                      }
                    />
                  </div>
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className="col-3 p-0">
                    <ChargeForm.RateSelect
                      defaultValue={rateForSelect || ''}
                      onChange={rateOnChange}
                    />
                  </div>
                  {!rateForSelect && (
                    <div className={'col-12 p-0 d-flex pl-3'}>
                      <div className={'col-3 p-0 pr-3'}>
                        <ChargeForm.ApplyBy
                          defaultValue={defaultApplyBy}
                          options={getEnumValues(ApplyBy)}
                          onChange={onChangeApplyBy}
                        />
                      </div>
                      {isCalculated && (
                        <div className={'col-3 p-0 '}>
                          <ChargeForm.CalculatedOf
                            defaultValue={defaultCalculatedOf}
                            options={getEnumValues(CalculatedOf)}
                            onChange={onChangeCalculatedOf}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.IsConsolidated
                    defaultValue={charge?.isConsolidated ?? false}
                  />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.ShowInDocuments
                    defaultValue={charge?.showInDocuments ?? false}
                  />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.AllowAutomaticUpdate
                    defaultValue={charge?.allowAutomaticUpdate ?? false}
                  />
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Quantity
                      defaultValue={loaded ? qValue : charge?.quantity}
                      onChange={quantityOnChange}
                      label={
                        rateForSelect?.tariff?.applyBy
                          ? `Quantity (${
                              ApplyBy[rateForSelect?.tariff?.applyBy]
                            })`
                          : 'Quantity'
                      }
                      disabled={isCalculated}
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Unit
                      defaultValue={charge?.unit}
                      disabled={rateForSelect != null || !!charge?.unit}
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    {!isCalculated ? (
                      <ChargeForm.Price
                        defaultValue={formattedPrice}
                        onChange={priceOnChange}
                        maxDecimals={getMaxDecimals(
                          charge?.description === 'Insurance'
                            ? DecimalPlace.Four
                            : charge?.currencyDecimals,
                        )}
                        onBlur={() =>
                          setFormattedPrice(
                            getSingleCommaFormattedPrice(
                              charge?.price,
                              charge?.currencyDecimals,
                            ),
                          )
                        }
                        disabled={rateForSelect != null}
                      />
                    ) : (
                      <ChargeForm.Apply
                        defaultValue={formattedPrice}
                        onChange={applyOnChange}
                        maxDecimals={getMaxDecimals(charge?.currencyDecimals)}
                        disabled={isDisabled}
                      />
                    )}
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.Amount
                      defaultValue={getFormattedChargeAmount()}
                      disabled={isAmountDisabled || isCalculated}
                    />
                  </div>
                </div>
                <div className={'col-12 d-flex p-0 pb-3'}>
                  <ChargeForm.Note className={'w-100'} />
                </div>
                <div className="justify-content-end d-flex col-12 p-0">
                  {(saveButtonRenderCondition || isCreateModeForEnity) && (
                    <div className="col-6 pl-0">
                      <Button
                        name="save-charge"
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Charge
                      </Button>
                    </div>
                  )}

                  <div className="col-6 pr-0">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={onCancel}
                      className="col-12"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Panel>
          </TabPanel>
        </Tabs>
      </ChargeForm>
    );
  } else if (chargeType === ChargeType.ExpenseCharge) {
    return (
      <ChargeForm
        id={'ChargeForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={chargeSchema}
      >
        <Tabs>
          <TabList>
            <Tab>Expense Charge</Tab>
          </TabList>
          <TabPanel>
            <Panel className="mt-3 mx-3">
              <div className="row p-0 row-charge">
                <div className={'col-12 d-flex p-0'}>
                  <div className={'col mr-3 p-0'}>
                    <ChargeForm.AccountingItemSelect
                      selectedFilter={` `}
                      required={true}
                      nameId={'accountingItemName'}
                      id={'accountingItemId'}
                      header={'Charge'}
                      placeholder={'Select Charge'}
                      defaultValue={
                        charge?.accountingItemId && charge?.accountingItemName
                          ? {
                              accountingItemId: charge?.accountingItemId,
                              description: charge?.accountingItemName,
                            }
                          : ''
                      }
                      onChange={accountingItemOnChange}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={`col mr-3 p-0`}>
                    <ChargeForm.TaxCodeSelect
                      id={'salesTaxId'}
                      header={'Tax Code'}
                      selectedFilter={` `}
                      required={false}
                      defaultValue={
                        salesTax &&
                        salesTax.salesTaxId != null &&
                        salesTax.salesTaxCode != null
                          ? {
                              salesTaxId: salesTax.salesTaxId,
                              taxCode: salesTax.salesTaxCode,
                            }
                          : ''
                      }
                      onChange={(data?: SalesTaxDto) => {
                        setSalesTax((salesTaxDto) => {
                          if (!salesTaxDto) {
                            salesTaxDto = {
                              salesTaxCode: null,
                              salesTaxId: null,
                            };
                          }
                          salesTaxDto.salesTaxId = data?.salesTaxId?.toString();
                          salesTaxDto.salesTaxCode = data?.taxCode;
                          return { ...salesTaxDto };
                        });
                      }}
                      nameId={'salesTaxCode'}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={'col p-0'}>
                    <ChargeForm.CurrencySelect
                      selectedFilter={` `}
                      required={true}
                      nameId={'currencyName'}
                      id={'currencyId'}
                      header={'Currency'}
                      defaultValue={
                        charge?.currencyId &&
                        charge?.currencyName &&
                        charge?.currencyDecimals
                          ? {
                              currencyId: charge.currencyId,
                              currencyCode: charge.currencyName,
                            }
                          : ''
                      }
                      onChange={(data?: CurrencyDto) => {
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.currencyId = data?.currencyId;
                          chargeDto.currencyName = data?.currencyCode;
                          chargeDto.currencyDecimals = data?.decimalPlaces;
                          const price = getSingleCommaFormattedPrice(
                            chargeDto?.price,
                            data?.decimalPlaces,
                          );
                          chargeDto.price = Number(price);
                          setFormattedPrice(price);
                          return { ...chargeDto };
                        });
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.Description />
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col p-0 mr-3'}>
                    <ChargeForm.ApplyToContactSelect
                      id={'applyToContactId'}
                      header={'Apply To Contact'}
                      contactTypes={[
                        ContactType.SalesPerson,
                        ContactType.Carrier,
                        ContactType.Driver,
                      ]}
                      selectedFilter={`contactType: ${ContactType.SalesPerson} OR contactType: ${ContactType.Carrier} OR contactType: ${ContactType.Driver}`}
                      required={false}
                      defaultValue={
                        charge.applyToContactId &&
                        charge.applyToContactName != null &&
                        charge.applyToContactType !== null
                          ? {
                              contactId: charge.applyToContactId,
                              name: charge.applyToContactName,
                              contactType: charge.applyToContactType,
                            }
                          : ''
                      }
                      onChange={(
                        data?: ContactDto,
                        context?: FormikProps<FormikValues>,
                      ) => {
                        context?.setFieldValue(
                          'applyToCarrierType',
                          data?.contactType,
                        );
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.applyToContactName = data?.name;
                          chargeDto.applyToContactId = data?.contactId;
                          chargeDto.applyToContactType = data?.contactType;
                          return { ...chargeDto };
                        });
                      }}
                      nameId={'applyToContactName'}
                      disabled={
                        (isCreateModeForTransaction &&
                          !!defaultCharge?.applyToContactId) ||
                        isDisabled
                      }
                    />
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.PaidAs
                      defaultValue={
                        charge?.paidAs
                          ? {
                              label: PaidAs[charge?.paidAs],
                              value: charge?.paidAs,
                            }
                          : ''
                      }
                      options={getEnumValues(PaidAs)}
                      onChange={onChangePaidAs}
                      disabled={
                        isCreateModeForTransaction && !!defaultCharge?.paidAs
                      }
                    />
                  </div>
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className="col-5 p-0">
                    <ChargeForm.RateSelect
                      defaultValue={rateForSelect || ''}
                      onChange={rateOnChange}
                    />
                  </div>
                  {rateForSelect && (
                    <div className={'col-12 p-0 d-flex pl-3'}>
                      <div className={'col-3 p-0 pr-3'}>
                        <ChargeForm.ApplyBy
                          defaultValue={defaultApplyBy}
                          options={getEnumValues(ApplyBy)}
                          onChange={onChangeApplyBy}
                          disabled={isCalculated}
                        />
                      </div>
                      {isCalculated && (
                        <div className={'col-3 p-0 pr-3'}>
                          <ChargeForm.CalculatedOf
                            defaultValue={defaultCalculatedOf}
                            options={getEnumValues(CalculatedOf)}
                            onChange={onChangeCalculatedOf}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.IsConsolidated
                    defaultValue={charge?.isConsolidated ?? false}
                  />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.ShowInDocuments
                    defaultValue={charge?.showInDocuments ?? false}
                  />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.AllowAutomaticUpdate
                    defaultValue={charge?.allowAutomaticUpdate ?? false}
                  />
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Quantity
                      defaultValue={charge?.quantity?.toFixed(2)}
                      onChange={quantityOnChange}
                      disabled={isDisabled || isCalculated}
                      label={
                        rateForSelect?.tariff?.applyBy
                          ? `Quantity (${
                              ApplyBy[rateForSelect?.tariff?.applyBy]
                            })`
                          : 'Quantity'
                      }
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Unit
                      defaultValue={charge?.unit}
                      disabled={isDisabled || rateForSelect != null}
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    {!isCalculated ? (
                      <ChargeForm.Price
                        defaultValue={formattedPrice}
                        onChange={priceOnChange}
                        maxDecimals={getMaxDecimals(
                          charge?.description === 'Insurance'
                            ? DecimalPlace.Four
                            : charge?.currencyDecimals,
                        )}
                        onBlur={() =>
                          setFormattedPrice(
                            getSingleCommaFormattedPrice(
                              charge?.price,
                              charge?.currencyDecimals,
                            ),
                          )
                        }
                        disabled={isDisabled}
                      />
                    ) : (
                      <ChargeForm.Apply
                        defaultValue={formattedPrice}
                        onChange={applyOnChange}
                        maxDecimals={getMaxDecimals(charge?.currencyDecimals)}
                        disabled={isDisabled}
                      />
                    )}
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.Amount
                      defaultValue={getFormattedChargeAmount()}
                      disabled={isDisabled || isCalculated}
                    />
                  </div>
                </div>
                <div className={'col-12 d-flex p-0 pb-3'}>
                  <ChargeForm.Note className={'w-100'} />
                </div>
                <div className="justify-content-end d-flex col-12 p-0">
                  {(saveButtonRenderCondition || isCreateModeForEnity) && (
                    <div className="col-6 pl-0">
                      <Button
                        name="save-charge"
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Charge
                      </Button>
                    </div>
                  )}

                  <div className="col-6 pr-0">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={onCancel}
                      className="col-12"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Panel>
          </TabPanel>
        </Tabs>
      </ChargeForm>
    );
  } else return <div></div>;
};
