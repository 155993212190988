import React, { useEffect, useRef, useState } from 'react';
import { FilteredField } from '../../common/components/filters/filtersTab.component';
import { FilterField } from '../../common/components/filters/filterField.component';
import { FiltersForm } from '../../common/components/filters/filters.form';
import { FormContext } from '../../common/components/form/form.component';
import { useFormikContext } from 'formik';
import lodash from 'lodash';
import { Button } from '../../common/components/button/button.component';
import { FaTrashAlt } from 'react-icons/fa';

export interface OrderDocumentsFiltersProps {
  onFilter: (string) => void;
  filters: FilteredField[];
}

const AutoSaveFilter = () => {
  const formikContext = useFormikContext();
  useEffect(() => {
    if (formikContext.values !== null) {
      formikContext.submitForm();
    }
  }, [formikContext.values]);
  return null;
};

export const OrderDocumentsFilters = ({
  onFilter = () => {},
  filters,
}: OrderDocumentsFiltersProps) => {
  const [initialValues, setInitialValues] = useState({});
  const formikRef = useRef<HTMLFormElement>();

  return (
    <div>
      <FiltersForm
        initialValues={initialValues}
        className={'d-flex flex-wrap row filters-form w-100'}
        onSubmit={async (data) => {
          const queryFilter: string = filters
            .filter((filter) => {
              if (Array.isArray(data[filter.name])) {
                return data[filter.name].length > 0;
              } else {
                return lodash.get(data, filter.name);
              }
            })
            .map((filter) => {
              if (filter.type === 'date') {
                const time = lodash.get(data, filter.name);
                const isRange = Array.isArray(time);

                const minDate = isRange ? time?.[0] : time;
                const min = new Date(minDate).toLocaleDateString('en-US');

                const maxDate = isRange ? time?.[1] : time;
                const max = new Date(
                  new Date(maxDate ?? minDate).setHours(24),
                ).toLocaleDateString('en-US');
                return `${filter.name}:["${min}" TO "${max}"]`;
              } else {
                return `${filter.name}:${lodash.get(data, filter.name)}`;
              }
            })
            .join(' AND ');
          onFilter(queryFilter);
        }}
        id={'filters-form'}
        innerRef={formikRef}
      >
        <FormContext.Consumer>
          {(context) => {
            return (
              <>
                <div className="row col-12 w-100 ml-0 mr-0 pl-0 pr-0">
                  <div className="col-10">
                    <div className="row">
                      <AutoSaveFilter></AutoSaveFilter>
                      {filters.map((filter, index) => {
                        return (
                          <FilterField
                            filter={filter}
                            context={context}
                            index={index}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <Button
                      className={'mt-4 ml-2 d-flex align-items center'}
                      outline
                      color="secondary"
                      onClick={() => {
                        context.resetForm({ values: {} });
                      }}
                    >
                      <FaTrashAlt size="15" className="mr-3" />
                      Clear filters
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </FormContext.Consumer>
      </FiltersForm>
    </div>
  );
};
