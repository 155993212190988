import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { organizationsStore } from '../../organization/organization.store';
import { getDashboardRequest } from '../dashboard.service';

export const Dashboard = () => {
  const [dashboardEmbedUrl, setDashboardEmbedUrl] = useState<any>(null);
  const { currentOrganization } = useStore(organizationsStore);
  useEffect(() => {
    getDashboardRequest({
      organizationId: currentOrganization?.organizationId,
    }).then(
      (dashboard) => {
        setDashboardEmbedUrl(dashboard);
      },
      () => {},
    );
  }, [currentOrganization?.organizationId]);

  return dashboardEmbedUrl ? (
    <iframe
      src={dashboardEmbedUrl?.embedUrl}
      frameBorder="0"
      width="100%"
      height="100%"
      allowTransparency
    />
  ) : (
    <div className="m-5 text-center">
      <h3 className="text-muted mb-4">Loading...</h3>
    </div>
  );
};
