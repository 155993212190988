import React, { ChangeEvent, useEffect } from 'react';
import {
  Form,
  FormProps,
} from '../../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  InputProps,
} from '../../../common/components/input/input.component';
import { SelectContactPaginate } from '../../../contacts/components/contact-react-select.component';
import { ReactSelect } from '../../../common/components/input/input.select';

export const BOLManagerForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

BOLManagerForm.ShipperSelect = ({
  name = 'shipperId',
  id = 'shipperId',
  placeholder = 'Select Shipper',
  required = true,
  header = 'Shipper',
  options,
  onChange = () => {},
  defaultValue,
}: InputProps) => {
  return (
    <ReactSelect
      useContext={true}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={false}
      disabled={false}
      onChange={onChange}
      options={options}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      isSearchable={true}
      isClearable={true}
    />
  );
};

BOLManagerForm.ConsigneeSelect = ({
  name = 'consigneeId',
  id = 'consigneeId',
  placeholder = 'Select Consignee',
  required = true,
  header = 'Consignee',
  options,
  onChange = () => {},
  defaultValue,
}: InputProps) => {
  return (
    <ReactSelect
      useContext={true}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={false}
      disabled={false}
      onChange={onChange}
      options={options}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      isSearchable={true}
      isClearable={true}
    />
  );
};

BOLManagerForm.ThirdPartySelect = ({
  selectedFilter = ``,
  nameId = 'thirdPartyContactName',
  placeholder = 'Select 3rd Party Billing',
  required = false,
  defaultValue,
  sort = 'contactType',
  id = 'thirdPartyContactId',
  header = '3rd Party Billing',
  onChange = () => {},
  readonly,
  contactTypes = [],
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

BOLManagerForm.CarrierSelect = ({
  name = 'carrierId',
  id = 'carrierId',
  placeholder = 'Select Transportation Company',
  required = false,
  header = 'Transportation Company',
  options,
  onChange = () => {},
}: InputProps) => {
  return (
    <ReactSelect
      useContext={true}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={null}
      multiple={false}
      disabled={false}
      onChange={onChange}
      options={options}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      isSearchable={true}
      isClearable={true}
    />
  );
};
