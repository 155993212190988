import React, { useEffect, useState } from 'react';
import {
  CommodityDto,
  OrderTypes,
  StatusStage,
  WarehouseZoneDto,
} from '../../../../models/data.models';
import { LocationInfo } from './locationInfo.component';
import { StatusBadge } from '../status-badge/status-badge';
import { getActivityStatusStage } from '../../../../utils/helper.utils';
import { OrderCommodityWithStatus } from '../../../../models/custom.models';

export type PackageLocationPorps = {
  commodity: CommodityDto;
  warehouseZones: WarehouseZoneDto[];
  commodityStatus: string;
  showHeader: boolean;
  statusCommodities?: OrderCommodityWithStatus[] | undefined;
  parcelTrackingNumber?: string | number | undefined;
  emphasisedPath?: boolean;
  emphasisedTrackingNumber?: boolean;
  pathTextSize?: 'sm' | 'md' | 'lg';
};

export const PackageLocation = ({
  commodity,
  warehouseZones,
  commodityStatus,
  showHeader,
  statusCommodities,
  parcelTrackingNumber,
  emphasisedPath = true,
  emphasisedTrackingNumber = false,
  pathTextSize = 'sm',
}: PackageLocationPorps) => {
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    if (statusCommodities) {
      const statusCommodity = statusCommodities.find(
        (x) => x.commodityId === commodity.commodityId,
      );
      setStatus(
        statusCommodity?.customValues['activityStatus'] || 'Awaiting Picking',
      );
    } else {
      setStatus(commodityStatus);
    }
  }, [commodityStatus, status, statusCommodities, commodity]);

  const shipmentTrackingNumber = parcelTrackingNumber || '';

  const styledString = (inputString: string) => {
    if (!inputString?.includes(',')) {
      const lastSixCharacters = inputString?.slice(-6);
      const restOfString = inputString?.slice(0, -6);

      return (
        <>
          {restOfString}
          <span style={{ fontWeight: 700 }}>{lastSixCharacters}</span>
        </>
      );
    }

    return inputString;
  };

  const trackingNumber = commodity?.commodityTrackingNumbers
    ?.filter((x) => x.trackingNumber !== shipmentTrackingNumber)
    ?.map((t) => t.trackingNumber)
    .join(' ,');

  return (
    <div>
      {showHeader && (
        <div className="row">
          <div className="col-12">
            <div className={'text-label'}>
              Package Tracking Numbers And Location
            </div>
          </div>
        </div>
      )}
      <div className="row mt-3">
        <div className="col-6">
          <div className={'text-h3-tracking-number mb-2'}>
            {emphasisedTrackingNumber
              ? styledString(trackingNumber)
              : trackingNumber}
          </div>
        </div>
        {status ? (
          <div className="col-6 d-flex justify-content-end">
            <StatusBadge
              label={status}
              stage={getActivityStatusStage(status)}
              enumType={StatusStage}
              style={{ marginRight: 0 }}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-start">
        <LocationInfo
          warehouseLocation={commodity?.warehouseLocation}
          locationZones={warehouseZones}
          showIcon={true}
          size={pathTextSize}
          emphasisedPath={emphasisedPath}
        />
      </div>
    </div>
  );
};
