import React, { createContext, useState } from 'react';
import { PackageScanFragment } from './packageScanFragment.component';
import { PackageInfoFragment } from './packageInfoFragment.component';
import {
  CommodityTrackingNumberDto,
  OrderDto,
} from '../../../models/data.models';

export type PackageReceiveWizardProps = {
  onCancel: () => void;
};

export const PackageReceiveContext = createContext(null);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const PackageReceiveWizard = ({
  onCancel = null,
}: PackageReceiveWizardProps) => {
  const [step, setStep] = useState<number>(0);
  const [purchase, setPurchase] = useState<OrderDto | null>(null);
  const [parcelShipment, setParcelShipment] = useState<OrderDto | null>(null);
  const [notification, setNotification] = useState<string>('');
  const [trackingNumbers, setTrackingNumbers] = useState<
    CommodityTrackingNumberDto[]
  >([]);

  const handleNext = () => {
    if (step < 1) setStep(step + 1);

    scrollToTop();
  };

  const handleBack = () => {
    setPurchase(null);
    setParcelShipment(null);
    setTrackingNumbers([]);
    if (step > 0) setStep(step - 1);

    scrollToTop();
  };

  return (
    <PackageReceiveContext.Provider
      value={{ purchase, parcelShipment, trackingNumbers }}
    >
      {step === 0 && (
        <PackageScanFragment
          handleNext={handleNext}
          onCancel={onCancel}
          setPurchase={setPurchase}
          setParcelShipment={setParcelShipment}
          setTrackingNumbers={setTrackingNumbers}
          notification={notification}
        />
      )}
      {step === 1 && (
        <PackageInfoFragment
          handleBack={handleBack}
          setTrackingNumbers={setTrackingNumbers}
          setNotification={setNotification}
          setPurchase={setPurchase}
          setParcelShipment={setParcelShipment}
        />
      )}
    </PackageReceiveContext.Provider>
  );
};
