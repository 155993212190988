import { Dialog } from '../../common/components/dialog/dialog.component';
import { UserSettingEdit } from './userSetting-edit.component';
import { Column, UserSettingDto } from '../../../models/data.models';
import { FilteredField } from '../../common/components/filters/filtersTab.component';
import { FormikValues } from 'formik';

export type UserSettingDialogProperties = {
  settingId?: number | null;
  settings?: { [key: string]: any } | null;
  name?: string;
  columns?: Column[] | null;
  filters: FilteredField[];
  sort?: string;
  limit?: number;
  contextValues: FormikValues;
  isVisibleToEveryone?: boolean;
  title: string;
  views?: any[];
  orgViews?: any[];
  userSettingId?: number | null;
  orgSettingId?: number | null;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSaveHandle?: () => Promise<void>;
};

export class UserSettingDialog extends Dialog<UserSettingDialogProperties> {
  onSaveHandle?: () => Promise<void>;

  constructor(props: UserSettingDialogProperties) {
    super(props);
    if (props.onSaveHandle) {
      this.onSaveHandle = props.onSaveHandle?.bind(this);
    }
    this.onUserSettingSaved = this.onUserSettingSaved.bind(this);
  }

  onUserSettingSaved = (userSetting: UserSettingDto) => {
    this._resolve(userSetting);
    this.close(userSetting);
  };

  renderContent(): any {
    const {
      settingId,
      name,
      columns,
      filters,
      sort,
      limit,
      contextValues,
      isVisibleToEveryone,
      views,
      orgViews,
      userSettingId,
      orgSettingId,
      onViewChanged,
    } = this.props as UserSettingDialogProperties;
    const onSaveHandle = this.onSaveHandle;
    return (
      <UserSettingEdit
        settingId={settingId}
        name={name}
        columns={columns}
        filters={filters}
        sort={sort}
        limit={limit}
        contextValues={contextValues}
        isVisibleToEveryone={isVisibleToEveryone}
        views={views}
        orgViews={orgViews}
        userSettingId={userSettingId}
        orgSettingId={orgSettingId}
        onUserSettingCreated={this.onUserSettingSaved}
        onUserSettingUpdated={this.onUserSettingSaved}
        onViewChanged={onViewChanged}
        onSaveHandle={onSaveHandle && this.onSaveHandle}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
