import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { OrderScreen } from './screen/order.screen';
import { OrdersListScreen } from './screen/orders-list.screen';

export const ORDER_LIST_PATH = '/orders';
export const ORDER_EDIT_PATH = '/orders/:orderId';
export const ORDER_CREATE_PATH = '/orders/0';

export const OrderRoute = (props: RouteProps) => {
  const group = 'Operations';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <OrdersListScreen pageTitle="Orders" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <OrderScreen pageTitle="Add New Order" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':orderId'}
        element={
          <PrivateRoute>
            <OrderScreen pageTitle="Update Order" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
