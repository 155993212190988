import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { CustomCodeType } from '../../../models/data.models';

export const CustomCodeForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

CustomCodeForm.Code = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'code'}
      label={'Code'}
      placeholder={'Enter Custom Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};
CustomCodeForm.Description = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Enter Description'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CustomCodeForm.IsCommonlyUsed = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isCommonlyUsed'}
      label={'Is Commonly Used'}
      placeholder={'Is Commonly Used'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
CustomCodeForm.CustomCodeType = ({
  className,
  style,
  size,
  ref,
  name = 'codeType',
  label = 'Code Type',
  placeholder = '',
  required = true,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'enum-select'}
      enumName={CustomCodeType}
      className={className}
      style={style}
      size={size}
    />
  );
};
