import React from 'react';
import { useStore } from 'effector-react';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, Link } from 'react-router-dom';
import { ActionItem } from '../../common/components/action-item/action-item.component';
import { INTERNAL_PATH } from '../../../app.router';
import { ORGANIZATION_CONFIGS_LIST_PATH } from '../organizationConfigs.route';
import { FaTools } from 'react-icons/fa';
import { GET_ORGANIZATIONCONFIG_LINK_KEY } from '../organizationConfigs.service';
import { navigationStore } from '../../common/nav.store';

export const OrganizationConfigMenu = ({ className }) => {
  const { currentOrganization } = useStore(organizationsStore);
  const navStore = useStore(navigationStore);

  return (
    <ActionItem
      context={currentOrganization}
      actionKey={GET_ORGANIZATIONCONFIG_LINK_KEY}
      render={({ available }) => {
        if (available) {
          return (
            <li className={className}>
              <Link
                to={generatePath(
                  INTERNAL_PATH + ORGANIZATION_CONFIGS_LIST_PATH,
                  {
                    organizationId: currentOrganization?.organizationId,
                  },
                )}
                className={`nav-link nav-icon-size-medium ${
                  navStore.path[navStore.path.length - 1] ===
                  'Organization Configs'
                    ? 'active'
                    : ''
                }`}
              >
                <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
                  <FaTools />
                </div>
                <div className="nav-link-text">Organization Configs</div>
              </Link>
            </li>
          );
        }
        return null;
      }}
    />
  );
};
