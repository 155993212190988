import {
  WarehouseZoneDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateWarehouseZoneCommandValues,
  UpdateWarehouseZoneCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createWarehouseZoneRequest,
  getWarehouseZonesListRequest,
  getWarehouseZoneRequest,
  deleteWarehouseZoneRequest,
  updateWarehouseZoneRequest,
  importWarehouseZonesRequest,
  exportWarehouseZonesRequest,
  GetWarehouseZoneParams,
} from './warehouseZones.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type WarehouseZonesStoreState = {
  links: LinkDto[];
  warehouseZoneColumns: EntityFieldDto[];
  defaultWarehouseZoneColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getWarehouseZonesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'WarehouseZone',
  });
});

addEffectStatusHandling(getWarehouseZonesColumnsFx);

export const getWarehouseZonesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'WarehouseZone',
  });
});

addEffectStatusHandling(getWarehouseZonesDefaultColumnsFx);

export const updateWarehouseZonesColumns = createEvent<EntityFieldDto[]>();

export const getWarehouseZonesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getWarehouseZonesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getWarehouseZonesFx);

export const createWarehouseZoneFx = createEffect(
  (warehouseZoneData: WarehouseZoneDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createWarehouseZoneCommand: CreateWarehouseZoneCommandValues = {
      ...warehouseZoneData,
    };

    return createWarehouseZoneRequest(
      currentOrganization!,
      createWarehouseZoneCommand,
    );
  },
);

addEffectStatusHandling(createWarehouseZoneFx, {
  completeMessage: 'Warehouse zone was created successfully',
  errorMessage: 'Warehouse zone was not created',
});

export const getWarehouseZoneFx = createEffect(
  (warehouseZoneParams: GetWarehouseZoneParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getWarehouseZoneRequest(
      currentOrganization as LinkResourceBaseDto,
      warehouseZoneParams,
    );
  },
);

addEffectStatusHandling(getWarehouseZoneFx);

export const updateWarehouseZoneFx = createEffect(
  (warehouseZone: WarehouseZoneDto) => {
    const updateWarehouseZoneCommand: UpdateWarehouseZoneCommandValues = {
      ...warehouseZone,
    };
    return updateWarehouseZoneRequest(
      warehouseZone,
      updateWarehouseZoneCommand,
    );
  },
);

addEffectStatusHandling(updateWarehouseZoneFx, {
  completeMessage: 'Warehouse zone was updated successfully',
  errorMessage: 'Warehouse zone was not updated',
});

export const deleteWarehouseZoneFx = createEffect(
  (warehouseZone: WarehouseZoneDto) => {
    return deleteWarehouseZoneRequest(warehouseZone);
  },
);

addEffectStatusHandling(deleteWarehouseZoneFx, {
  completeMessage: 'Warehouse zone was deleted successfully',
  errorMessage: 'Warehouse zone was not deleted',
});

export const exportWarehouseZonesFx = createEffect(
  ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    exportWarehouseZonesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportWarehouseZonesFx, {
  completeMessage: 'Warehouse zones were exported successfully',
  errorMessage: 'Warehouse zones were not exported',
});

export const importWarehouseZonesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importWarehouseZonesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importWarehouseZonesFx, {
  completeMessage: 'Warehouse zones were imported successfully',
  errorMessage: 'Warehouse zones were not imported',
  inFlightMessage: 'Importing warehouse zones...',
});

const defaultState: WarehouseZonesStoreState = {
  links: [],
  warehouseZoneColumns: [],
  defaultWarehouseZoneColumns: [],
  defaultSort: 'warehouseZoneId',
  defaultLimit: 20,
};

export const warehouseZoneStore: Store<WarehouseZonesStoreState> = createStore(
  defaultState,
)
  .on(getWarehouseZonesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      warehouseZoneColumns: payload.result.items,
      defaultWarehouseZoneColumns: payload.result.items,
    };
  })
  .on(getWarehouseZonesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultWarehouseZoneColumns: payload.result.items,
    };
  })
  .on(updateWarehouseZonesColumns, (state, payload) => {
    return { ...state, warehouseZoneColumns: payload };
  });
