import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  ordersStore,
  getOrders,
  deleteOrder,
  createOrder,
  getOrdersColumns,
} from '../orders.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ORDER_LINK_KEY,
  DELETE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../orders.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { ORDER_CREATE_PATH } from '../order.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { OrderGrid } from '../../common/components/grid/order-grid.component';
import { OrderDialog } from './order.dialog';
import { userHas } from '../../auth/auth.store';
import { hidenFilter } from '../../../utils/query.utils';

export type OrdersListProps = {
  goToDetails?: (orderParams: GetOrderParams) => void;
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (order: OrderDto, orderId: string) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const OrdersList = ({
  goToDetails = () => {},
  filter = null,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch = () => {},
}: OrdersListProps) => {
  const [orders, setOrders] = useState<OrderDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  useEffect(() => {
    getOrdersColumns().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    getOrdersData();
  }, [offset, limit, sort, filter, search]);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const getOrdersData = async () => {
    const additionalFilter = hidenFilter(
      `orderType:${OrderTypes.Order}`,
      filter,
    );
    const ordersData = await getOrders({
      offset,
      limit,
      sort,
      filter: additionalFilter,
      search,
    });
    if (ordersData) {
      const orderDtoResult: OrderDtoForListPagedResult = {
        limit: ordersData.limit,
        offset: ordersData.offset,
        links: ordersData.links,
        totalCount: ordersData.totalCount,
        items: ordersData.items.map<OrderForListDto>((item) => {
          const resultOrder: OrderForListDto = item;
          if (item?.customValues) {
            Object.keys(item.customValues).forEach(
              (customFieldInternalName) => {
                if (!resultOrder.hasOwnProperty(customFieldInternalName))
                  resultOrder[customFieldInternalName] =
                    item.customValues[customFieldInternalName];
              },
            );
          }
          return item;
        }),
      };
      setOrders(orderDtoResult);
      setGetDataSuccess(true);
    }
  };

  const {
    orderColumns: columns,
    defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(ordersStore);

  const onDeleteOrder = async (order: OrderDto) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${order.orderNumber} Order`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteOrder(order);
        onDelete();
        getOrdersData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditOrder = (order: OrderDto) => {
    goToDetails({ orderId: order.orderId });
  };

  const onCopyOrder = (order: OrderDto) => {
    const orderCopy = { ...order };
    delete orderCopy.charges;
    createOrder(orderCopy).then(
      (result) => {
        getOrdersData();
      },
      () => {},
    );
  };

  const navigate = useNavigate();

  const onCreateNewOrder = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(createPath);
  };

  const onChangeItem = (data?: any[]) => {
    setOrders((ordersDto) => {
      ordersDto.items = data;
      return { ...ordersDto };
    });
  };

  const onOrderSelect = async (order, orderId) => {
    onSelect?.(order, orderId);
    if (order?.orderId) {
      const selectedOrder = await showDialog({
        dialog: OrderDialog,
        props: {
          className: 'order-modal',
          title: 'Update Order',
          orderId: order?.orderId,
        },
      });
      if (selectedOrder !== null) {
        getOrdersData();
      }
    }
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <OrderGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={['orderId']}
          data={orders?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={orders?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_ORDER_LINK_KEY, orders?.items[0]?.links)
              ? onDeleteOrder
              : null
          }
          onSort={(field) => {
            onSort(field);
          }}
          search={search}
          onEdit={onEditOrder}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onLimitChanged={onLimitChanged}
          onSelect={onOrderSelect}
          onChangeItem={onChangeItem}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
          onCopy={userHas(CREATE_ORDER_LINK_KEY) ? onCopyOrder : null}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          showToolbar={false}
          entityType={OrderTypes.Order}
        />
        {!search && !orders?.items?.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Orders Found</h3>
            {userHas(CREATE_ORDER_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewOrder}>
                Create Order
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
