import React, { KeyboardEvent } from 'react';
import {
  Formik,
  Form as HTMLForm,
  FormikProps,
  FormikValues,
  isObject,
  isEmptyArray,
} from 'formik';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import {
  createErrorMessage,
  findInputName,
} from '../../../../utils/helper.utils';
import { clearMessages } from '../../messages.store';

export type FormProps = {
  children: any;
  id?: string;
  ref?: string;
  method?: string;
  initialValues?: any;
  validateOnChange?: boolean;
  innerRef?: any;
  validationSchema?: Yup.ObjectSchema<AnyObject>;
  onSubmit?: (data: any, form: any) => void;
  render?: any;
  className?: string;
  onKeyPress?: (
    event: KeyboardEvent<HTMLFormElement>,
    formikParams: FormikProps<FormikValues>,
  ) => void;
};

export const Form = ({
  children,
  id,
  innerRef,
  method,
  validateOnChange = false,
  initialValues = {},
  onSubmit = () => {},
  render,
  className,
  onKeyPress = (event, formikParams) => {},
  validationSchema,
}: FormProps) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      method={method}
      innerRef={innerRef}
      validateOnChange={validateOnChange}
      validateOnBlur={true}
      validationSchema={validationSchema}
      render={render}
    >
      {(params) => {
        if (params.isSubmitting) {
          const errors = Object.entries(params.errors);
          !isEmptyArray(errors) && clearMessages();
          errors.forEach((error) => {
            if (isObject(error[1])) {
              const subErrors = Object.entries(error[1]);
              subErrors.forEach((subError) => {
                let inputName = '';
                if (error[0] === 'customValues') {
                  inputName = `ADDITIONAL - ${findInputName(
                    id,
                    `${error[0]}.${subError[0]}`,
                  )}`;
                  createErrorMessage(inputName, subError[1].toString());
                } else if (error[0] === 'actions') {
                  createErrorMessage('Actions', subError[1]['action']);
                  return;
                } else if (
                  id === 'ShippingForm' &&
                  error[0] === 'purchase' &&
                  subError[0] === 'commodities' &&
                  isObject(subError[1])
                ) {
                  const subSubErrors = Object.entries(subError[1]);
                  if (subSubErrors[0][1]) {
                    const keys = Object.keys(subSubErrors[0][1]);
                    keys.forEach((key) => {
                      inputName = key.charAt(0).toUpperCase() + key.slice(1);
                      createErrorMessage(inputName, subSubErrors[0][1][key]);
                    });
                  }
                } else {
                  inputName = findInputName(
                    id,
                    `${error[0]}.${subError[0]}`,
                    error[0],
                  );
                  if (inputName?.includes('undefined')) {
                    const deepError = internalError(error);
                    createErrorMessage(deepError[0], deepError[1]);
                  } else {
                    createErrorMessage(inputName, subError[1].toString());
                  }
                }
              });
            } else {
              const htmlInputName = findInputName(id, `${error[0]}`);
              const inputName =
                htmlInputName !== 'undefined'
                  ? htmlInputName
                  : error[0].charAt(0).toUpperCase() + error[0].slice(1);

              if (inputName?.includes('undefined')) {
                const deepError = internalError(error);
                createErrorMessage(deepError[0], deepError[1]);
              } else {
                createErrorMessage(inputName, error[1]);
              }
            }
          });
        }
        return (
          <FormContext.Provider value={params}>
            <HTMLForm
              onKeyPress={(event) => onKeyPress(event, params)}
              className={className}
              id={id}
              noValidate={true}
            >
              {children}
            </HTMLForm>
          </FormContext.Provider>
        );
      }}
    </Formik>
  );
};

export const FormContext = React.createContext<FormikProps<FormikValues> | null>(
  null,
);

const internalError = (error: Array<any>) => {
  let subErr: any = [...error];
  while (
    !Array.isArray(subErr) ||
    (Array.isArray(subErr) &&
      (subErr.length > 1 ||
        subErr.some(
          (x) => !Array.isArray(x) || x.some((e) => typeof e !== 'string'),
        )))
  ) {
    if (Array.isArray(subErr)) {
      subErr = subErr[subErr.length - 1];
    } else {
      subErr = Object.entries(subErr);
    }
  }
  return subErr[0];
};
