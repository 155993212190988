import { Dialog } from '../../common/components/dialog/dialog.component';
import { CommodityTrackingNumberDto } from '../../../models/data.models';
import { CommodityTrackingNumberEdit } from './commodityTrackingNumbers-edit.component';

export type CommodityTrackingNumberDialogProperties = {
  commodityTrackingNumberId?: number | null;
  commodityId: number;
  title: string;
  isEditMode?: boolean;
  defaultCommodityTrackingNumber?: CommodityTrackingNumberDto;
};

export class CommodityTrackingNumberDialog extends Dialog<CommodityTrackingNumberDialogProperties> {
  constructor(props: CommodityTrackingNumberDialogProperties) {
    super(props);
    this.onCommodityTrackingNumberSaved = this.onCommodityTrackingNumberSaved.bind(
      this,
    );
    this.isEditMode = props.isEditMode;
  }

  isEditMode: boolean | undefined = false;

  onCommodityTrackingNumberSaved = (
    commodityTrackingNumber: CommodityTrackingNumberDto,
  ) => {
    this.close(commodityTrackingNumber);
  };

  renderContent(): any {
    const {
      commodityTrackingNumberId,
      commodityId,
      defaultCommodityTrackingNumber,
    } = this.props as CommodityTrackingNumberDialogProperties;
    return (
      <CommodityTrackingNumberEdit
        commodityId={commodityId}
        commodityTrackingNumberId={commodityTrackingNumberId}
        onCommodityTrackingNumberCreated={this.onCommodityTrackingNumberSaved}
        onCommodityTrackingNumberUpdated={this.onCommodityTrackingNumberSaved}
        isEditMode={this.isEditMode}
        defaultCommodityTrackingNumber={defaultCommodityTrackingNumber}
      />
    );
  }
}
