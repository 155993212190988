import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { EquipmentTypeScreen } from './screens/equipmentType.screen';
import { EquipmentTypesListScreen } from './screens/equipmentType-list.screen';

export const EQUIPMENTTYPES_LIST_PATH = '/equipment-types';
export const EQUIPMENTTYPE_EDIT_PATH = '/equipment-types/:equipmentTypeId';
export const EQUIPMENTTYPE_CREATE_PATH = '/equipment-types/0';

export const EquipmentTypeRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <EquipmentTypesListScreen
              pageTitle="Equipment Types"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <EquipmentTypeScreen
              pageTitle="Add New Equipment Type"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':equipmentTypeId'}
        element={
          <PrivateRoute>
            <EquipmentTypeScreen
              pageTitle="Update Equipment Type"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
