import { useCallback, useEffect, useState } from 'react';
import { queryGraphQlFx } from '../../../graphql/graphql.store';
import logger from '../../logger';

export const useViews = ({ name, views, context, variables, filterValues }) => {
  const [viewsMap, setViewsMap] = useState(
    views?.reduce((acc, v) => {
      const id = v.id ?? v.name;
      acc[id] = v;
      return acc;
    }, {}) ?? {},
  );

  const refreshViews = useCallback(async () => {
    const res = await queryGraphQlFx({
      query: `
        query ($organizationId: Int!, $filter: String!, $take: Int!) {
          userSettings(
            organizationId: $organizationId
            filter: $filter
            take: $take
          ) {
            items {
              name
              settings
            }
          }
        }
      `,
      variables: {
        filter: `name:"tms:datagrids:${name}:views:*\"`,
        take: 100,
      },
    });

    if (!res.userSettings) {
      logger.error('Failed to get views', res);
      return;
    }

    // merge the views from the server with the views from the props using id
    setViewsMap((prev) => ({
      ...prev,
      ...res.userSettings.items.reduce((acc, v) => {
        acc[v.settings.view.name] = v.settings.view; // name is the view id in settings
        return acc;
      }, {}),
    }));
  }, [name]);

  useEffect(() => {
    refreshViews();
  }, [refreshViews]);

  const saveView = useCallback(
    async (view) => {
      if (!name) {
        // notify user that name is required
        context?.action(
          {
            action: 'notification',
            props: {
              message: 'Grid name is required to save view',
              type: 'danger',
            },
          },
          {
            ...variables,
          },
        );
        return;
      }
      const viewDef = {
        id: view?.id,
        name: view?.name,
        displayName: view?.displayName,
        filter: view?.filter,
        columns:
          view?.config?.viewColumns?.map((col) => {
            return {
              name: col.name,
              label: col.Header,
              isHidden: col.isHidden,
              accessor: col.accessor,
              path: col.path,
              props: col.props,
              showAs: col.showAs,
            };
          }) ?? [],
        filters:
          view?.config?.filterColumns?.map((col) => {
            return {
              name: col.name,
              component: col.component,
              componentProps: col.componentProps,
              values: filterValues[col.name],
            };
          }) ?? [],
        orderBy: view?.config?.orderByColumns ?? [],
      };

      // call dialog to save view
      const result = await context?.action(
        {
          action: 'dialog',
          props: {
            props: {
              title: 'Save View',
              dataGridName: name, // pass unique name for the data grid
              $raw: { view: viewDef }, // pass view to dialog without template
            },
            component: 'DataGrid/SaveView',
          },
        },
        {
          ...variables,
        },
      );

      if (result) {
        // find view in views
        const updatedView = views[result.view.name];
        if (updatedView) {
          updatedView.displayName = result.view.displayName;
          setViewsMap((prev) => {
            prev[result.view.name] = updatedView;
            return prev;
          });
        } else {
          // add new view
          setViewsMap((prev) => {
            prev[result.view.name] = {
              ...result.view,
              id: result.view.id ?? result.view.name,
            };
            return prev;
          });
          // change view to new view
        }

        return result.view;
      }

      throw new Error('View not saved');
    },
    [filterValues, variables],
  );

  return {
    views: viewsMap,
    refreshViews,
    saveView,
  };
};
