import {
  ContainerDescriptionDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  ContainerDescriptionDtoPagedResult,
  CreateContainerDescriptionCommandValues,
  UpdateContainerDescriptionCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CONTAINERDESCRIPTIONS_LINK_KEY = 'get-containerdescriptions';
export const CREATE_CONTAINERDESCRIPTION_LINK_KEY =
  'create-containerdescription';
export const GET_CONTAINERDESCRIPTION_LINK_KEY = 'get-containerdescription';
export const UPDATE_CONTAINERDESCRIPTION_LINK_KEY =
  'update-containerdescription';
export const DELETE_CONTAINERDESCRIPTION_LINK_KEY =
  'delete-containerdescription';
export const IMPORT_CONTAINERDESCRIPTIONS_LINK_KEY =
  'import-containerdescriptions';
export const EXPORT_CONTAINERDESCRIPTIONS_LINK_KEY =
  'export-containerdescriptions';
export const CONTAINERDESCRIPTION_ENTITY_NAME = 'Container Description';

export interface GetContainerDescriptionParams {
  containerDescriptionCode: string;
}

export interface ImportContainerDescriptionsParams {
  organizationId: number;
}

export interface ExportContainerDescriptionsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getContainerDescriptionRequest = async (
  resource: LinkResourceBaseDto | null,
  { containerDescriptionCode }: GetContainerDescriptionParams,
): Promise<ContainerDescriptionDto> => {
  if (resource && containerDescriptionCode) {
    const getContainerDescriptionLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERDESCRIPTION_LINK_KEY,
    );
    if (getContainerDescriptionLink) {
      const result = await execLink(getContainerDescriptionLink, {
        containerDescriptionCode,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getContainerDescriptionsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContainerDescriptionDtoPagedResult> => {
  if (resource) {
    const getContainerDescriptionsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERDESCRIPTIONS_LINK_KEY,
    );
    if (getContainerDescriptionsListLink) {
      const result = await execLink(getContainerDescriptionsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTAINERDESCRIPTIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createContainerDescriptionRequest = async (
  resource: LinkResourceBaseDto,
  containerDescription: CreateContainerDescriptionCommandValues,
) => {
  const createContainerDescriptionLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTAINERDESCRIPTION_LINK_KEY,
  );
  if (createContainerDescriptionLink) {
    const result = await execLink(
      createContainerDescriptionLink,
      containerDescription,
    );
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateContainerDescriptionRequest = async (
  resource: LinkResourceBaseDto,
  containerDescription: UpdateContainerDescriptionCommandValues,
) => {
  const updateContainerDescriptionLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTAINERDESCRIPTION_LINK_KEY,
  );
  if (updateContainerDescriptionLink) {
    const result = await execLink(
      updateContainerDescriptionLink,
      containerDescription,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteContainerDescriptionRequest = async (
  containerDescription: ContainerDescriptionDto,
) => {
  const deleteContainerDescriptionLink = containerDescription.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTAINERDESCRIPTION_LINK_KEY,
  );
  if (deleteContainerDescriptionLink) {
    const result = await execLink(
      deleteContainerDescriptionLink,
      containerDescription,
    );
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importContainerDescriptionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportContainerDescriptionsParams,
  file: File,
) => {
  const importContainerDescriptionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CONTAINERDESCRIPTIONS_LINK_KEY,
  );
  if (importContainerDescriptionsLink) {
    const result = await postFormData(importContainerDescriptionsLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CONTAINERDESCRIPTIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportContainerDescriptionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportContainerDescriptionsParams,
) => {
  const exportContainerDescriptionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CONTAINERDESCRIPTIONS_LINK_KEY,
  );
  if (exportContainerDescriptionsLink) {
    const result = await downloadLinkFile(
      exportContainerDescriptionsLink,
      params,
    );
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CONTAINERDESCRIPTIONS_LINK_KEY} is not allowed or missing link action`,
  );
};
