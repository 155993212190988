import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { SalesTaxesScreen } from './screens/salesTaxes.screen';
import { SalesTaxesListScreen } from './screens/salesTaxes-list.screen';
import React from 'react';

export const SALESTAXES_LIST_PATH = '/sales-tax';
export const SALESTAX_EDIT_PATH = '/sales-tax/:salesTaxId';
export const SALESTAX_CREATE_PATH = '/sales-tax/0';

export const SalesTaxRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <SalesTaxesListScreen pageTitle="Sales Taxes" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <SalesTaxesScreen pageTitle="Add New Sales Tax" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':salesTaxId'}
        element={
          <PrivateRoute>
            <SalesTaxesScreen pageTitle="Update Sales Tax" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
