import React from 'react';
import {
  ContactType,
  CustomFieldDto,
  ServiceType,
  CustomFieldType,
  CustomCodeType,
} from '../../../../models/data.models';
import { SelectModeOfTransportationPaginate } from '../../../modeOfTransportations/components/modeOfTransportation-react-select.component';
import { SelectEquipmentTypePaginate } from '../../../equipmentTypes/components/equipmentType-react-select.component';
import { SelectPortPaginate } from '../../../ports/components/port-react-select.component';
import { ReactEnumInputSelect } from '../../../common/components/input/enum-input.select';
import { Input } from '../input/input.component';
import { useState, useEffect } from 'react';
import {
  getEnumValues,
  getEnumKeyByValue,
} from '../../../../utils/helper.utils';
import { ReactDatepickerComponent } from '../react-datepicker/react-datepicker.component';
import { SelectContactPaginate } from '../../../contacts/components/contact-react-select.component';
import { SelectVesselPaginate } from '../../../vessels/components/vessel-react-select.component';
import { SelectCustomCodePaginate } from '../../../customCodes/components/customCode-react-select.component';
import { SelectContactPaymentMethodPaginate } from '../../../contactPaymentMethods/components/contactPaymentMethods-react-select.component';
import { SelectCountryPaginate } from '../../../countries/components/country-react-select.component';

export type LayoutRow = {
  rowNumber: number;
  header?: string | undefined;
};

export type CustomFieldsLayoutProps = {
  rows: LayoutRow[];
  customFields: CustomFieldDto[];
  defaultValue?: object;
  inputNamePrefix: string;
  onChange?: (result: object) => void;
  isFieldsDisabled?: boolean;
  filter?: string;
};

function getFilterByCodeType(fieldConfigElement: CustomCodeType | null) {
  if (fieldConfigElement === CustomCodeType.ScheduleD) {
    return `codeType: ${CustomCodeType.ScheduleD}`;
  }
  if (fieldConfigElement === CustomCodeType.ScheduleK) {
    return `codeType: ${CustomCodeType.ScheduleK}`;
  }
  return '';
}

export const destinationCountries = (field, stateValue) => {
  const countryCodes = stateValue[field.internalName].split(';');
  const names = stateValue[`${field.internalName}Name`].split(';');

  return countryCodes.map((countryCode, idx) => {
    return {
      countryCode,
      name: names[idx],
    };
  });
};

export const CustomFieldsLayout = ({
  rows = [],
  customFields = [],
  defaultValue = {},
  inputNamePrefix,
  onChange = () => {},
  isFieldsDisabled,
  filter,
}: CustomFieldsLayoutProps) => {
  const [stateValue, setStateValue] = useState(defaultValue);

  if (filter) {
    customFields = customFields.filter(
      (field) => field.fieldConfig?.['filter'] === filter,
    );
  }

  customFields.sort(
    (prev, next) =>
      Number(prev?.fieldConfig?.['pos']) - Number(next?.fieldConfig?.['pos']),
  );

  useEffect(() => {
    onChange(stateValue);
  }, [stateValue]);

  const renderFields = (customFields: CustomFieldDto[], row: number) => {
    return (
      <>
        {customFields.map((field) => {
          switch (field.customFieldType) {
            case CustomFieldType.ModeOfTransportation:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectModeOfTransportationPaginate
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      required={false}
                      isMulti={false}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={field.displayName}
                      selectedSort={'description'}
                      placeholder={field.displayName}
                      selectedValue={
                        stateValue[field.internalName]
                          ? stateValue[`${field.internalName}Description`]
                            ? {
                                modeOfTransportationId:
                                  stateValue[field.internalName],
                                description:
                                  stateValue[
                                    `${field.internalName}Description`
                                  ],
                              }
                            : stateValue[field.internalName]
                          : ''
                      }
                      selectedFilter={''}
                      isClearable={true}
                      onChangeModeOfTransportation={(value) => {
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] =
                            value?.modeOfTransportationId;
                          stateValue[`${field.internalName}Description`] =
                            value?.description;
                          return { ...stateValue };
                        });
                      }}
                      isDisabled={isFieldsDisabled || false}
                    />
                  </div>
                )
              );

            case CustomFieldType.EquipmentType:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectEquipmentTypePaginate
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      readonly={false}
                      selectedSort={'name'}
                      required={false}
                      isMulti={false}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={field.displayName}
                      placeholder={field.displayName}
                      isDisabled={isFieldsDisabled || false}
                      selectedValue={
                        stateValue[field.internalName] && stateValue['name']
                          ? {
                              equipmentTypeId: stateValue[field.internalName],
                              name: stateValue['name'],
                            }
                          : ''
                      }
                      selectedFilter={''}
                      onChangeEquipmentType={(value) =>
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] =
                            value?.equipmentTypeId;
                          stateValue['name'] = value?.name;
                          return { ...stateValue };
                        })
                      }
                    />
                  </div>
                )
              );

            case CustomFieldType.Text:

            case CustomFieldType.Number:
              if (field?.fieldConfig?.['disable'] === 'true') {
                return (
                  field?.fieldConfig?.['row'] == row && (
                    <div
                      key={field.fieldId}
                      className={`col-${field?.fieldConfig?.['col']}`}
                    >
                      <Input
                        valueInput={stateValue[field.internalName]}
                        name={`${inputNamePrefix}customValues.${field.internalName}`}
                        label={field.displayName}
                        placeholder={field.displayName}
                        required={false}
                        type={'input-OnChange-text-with-value'}
                        disabled={true}
                      />
                    </div>
                  )
                );
              } else {
                return (
                  field?.fieldConfig?.['row'] == row && (
                    <div
                      key={field.fieldId}
                      className={`col-${field?.fieldConfig?.['col']}`}
                    >
                      <Input
                        name={`${inputNamePrefix}customValues.${field.internalName}`}
                        label={field.displayName}
                        placeholder={field.displayName}
                        required={false}
                        type={'text'}
                        disabled={isFieldsDisabled || false}
                        onChange={(ev) => {
                          setStateValue((stateValue) => {
                            stateValue[field.internalName] = ev?.target?.value;
                            return { ...stateValue };
                          });
                        }}
                      />
                    </div>
                  )
                );
              }

            case CustomFieldType.Date:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <Input
                      name={`${inputNamePrefix}customValues.${field.internalName}`}
                      label={field.displayName}
                      placeholder={field.displayName}
                      required={false}
                      type={'date'}
                      defaultValue={
                        stateValue[field.internalName]
                          ? stateValue[field.internalName]
                          : ''
                      }
                      onChange={(data) => {
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] = data;
                          return { ...stateValue };
                        });
                      }}
                      disabled={isFieldsDisabled || false}
                    />
                  </div>
                )
              );

            case CustomFieldType.Port:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectPortPaginate
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      readonly={false}
                      selectedSort={'name'}
                      required={false}
                      isMulti={false}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={field?.displayName}
                      placeholder={field?.displayName}
                      selectedFilter={''}
                      selectedValue={
                        stateValue[field.internalName] &&
                        stateValue[`${field.internalName}Name`]
                          ? {
                              portId: stateValue[field.internalName],
                              name: stateValue[`${field.internalName}Name`],
                            }
                          : ''
                      }
                      onChangePort={(value) =>
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] = value?.portId;
                          stateValue[`${field.internalName}Name`] = value?.name;
                          return { ...stateValue };
                        })
                      }
                    />
                  </div>
                )
              );

            case CustomFieldType.TextArea:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <Input
                      name={`${inputNamePrefix}customValues[${field.internalName}]`}
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      label={field.displayName}
                      placeholder={field.displayName}
                      required={false}
                      type={'textarea'}
                      disabled={isFieldsDisabled || false}
                      onChange={(ev) => {
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] = ev?.target?.value;
                          return { ...stateValue };
                        });
                      }}
                    />
                  </div>
                )
              );

            case CustomFieldType.ServiceType:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <ReactEnumInputSelect
                      name={`${inputNamePrefix}customValues[${field.internalName}]`}
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      error={false}
                      isSearchable={true}
                      isClearable={false}
                      readonly={''}
                      header={field.displayName}
                      placeholder={field.displayName}
                      required={false}
                      defaultValue={
                        stateValue[field.internalName]
                          ? {
                              label: stateValue[field.internalName],
                              value: getEnumKeyByValue(
                                stateValue[field.internalName],
                                ServiceType,
                              ),
                            }
                          : null
                      }
                      multiple={false}
                      disabled={false}
                      onChange={(value) => {
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] = value.value;
                          return { ...stateValue };
                        });
                      }}
                      options={getEnumValues(ServiceType)}
                      tabIndex={5}
                      useContext={true}
                    />
                  </div>
                )
              );

            case CustomFieldType.Time:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <ReactDatepickerComponent
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      placeholder={field.displayName}
                      label={field.displayName}
                      disabled={isFieldsDisabled || false}
                      defaultValue={stateValue[field.internalName]}
                      onChange={(data) =>
                        (stateValue[field.internalName] = data)
                      }
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      dateFormat={'h:mm:ss'}
                      timeIntervals={15}
                    />
                  </div>
                )
              );
            case CustomFieldType.Contact:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectContactPaginate
                      header={field?.displayName}
                      placeholder={field?.displayName}
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      selectedValue={
                        stateValue[`${field.internalName}Id`] &&
                        stateValue[`${field.internalName}Name`]
                          ? {
                              contactId: stateValue[`${field.internalName}Id`],
                              name: stateValue[`${field.internalName}Name`],
                              contactType: ContactType.Carrier,
                            }
                          : ''
                      }
                      onChangeContact={(value) =>
                        setStateValue((stateValue) => {
                          stateValue[`${field.internalName}Id`] =
                            value?.contactId;
                          stateValue[`${field.internalName}Name`] = value?.name;
                          return { ...stateValue };
                        })
                      }
                      contactTypes={[ContactType.Carrier]}
                      selectedFilter={`contactType: ${ContactType.Carrier}`}
                      isMulti={false}
                      closeMenuOnSelect={true}
                    />
                  </div>
                )
              );
            case CustomFieldType.Vessel:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectVesselPaginate
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      required={false}
                      isMulti={false}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={field.displayName}
                      selectedSort={'name'}
                      placeholder={field.displayName}
                      selectedValue={
                        stateValue[`${field.internalName}Id`] &&
                        stateValue[`${field.internalName}Name`]
                          ? {
                              vesselId: stateValue[`${field.internalName}Id`],
                              name: stateValue[`${field.internalName}Name`],
                            }
                          : ''
                      }
                      selectedFilter={''}
                      isClearable={true}
                      onChangeVessel={(value) => {
                        setStateValue((stateValue) => {
                          stateValue[`${field.internalName}Id`] =
                            value?.vesselId;
                          stateValue[`${field.internalName}Name`] = value?.name;
                          stateValue['vesselFlag'] = value?.countryName;
                          return { ...stateValue };
                        });
                      }}
                      isDisabled={isFieldsDisabled || false}
                    />
                  </div>
                )
              );
            case CustomFieldType.CustomCode:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectCustomCodePaginate
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      required={false}
                      isMulti={false}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={field.displayName}
                      placeholder={field.displayName}
                      selectedValue={
                        stateValue[field.internalName]
                          ? {
                              code: stateValue[field.internalName],
                            }
                          : ''
                      }
                      selectedFilter={getFilterByCodeType(
                        field?.fieldConfig?.['codeType'],
                      )}
                      isClearable={true}
                      onChangeCustomCode={(value) => {
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] = value?.code;
                          return { ...stateValue };
                        });
                      }}
                      isDisabled={isFieldsDisabled || false}
                    />
                  </div>
                )
              );
            case CustomFieldType.Boolean:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <Input
                      defaultValue={stateValue[field.internalName]}
                      type={'checkbox'}
                      name={`${inputNamePrefix}customValues.${field.internalName}`}
                      label={field.displayName}
                      onChange={(value) => {
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] = value;
                          return { ...stateValue };
                        });
                      }}
                    />
                  </div>
                )
              );
            case CustomFieldType.ContactPaymentMethod:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectContactPaymentMethodPaginate
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      required={false}
                      isMulti={false}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={field.displayName}
                      selectedSort={'description'}
                      placeholder={field.displayName}
                      selectedValue={
                        stateValue[field.internalName] &&
                        stateValue[`${field.internalName}Description`]
                          ? {
                              contactPaymentMethodId:
                                stateValue[field.internalName],
                              description:
                                stateValue[`${field.internalName}Description`],
                            }
                          : ''
                      }
                      selectedFilter={''}
                      isClearable={true}
                      onChangeContactPaymentMethod={(value) =>
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] =
                            value?.contactPaymentMethodId;
                          stateValue[`${field.internalName}Description`] =
                            value?.description;
                          return { ...stateValue };
                        })
                      }
                      isDisabled={isFieldsDisabled || false}
                    />
                  </div>
                )
              );
            case CustomFieldType.DestinationCountries:
              return (
                field?.fieldConfig?.['row'] == row && (
                  <div
                    key={field.fieldId}
                    className={`col-${field?.fieldConfig?.['col']}`}
                  >
                    <SelectCountryPaginate
                      id={`${inputNamePrefix}customValues.${field.internalName}`}
                      nameId={`${inputNamePrefix}customValues.${field.internalName}`}
                      readonly={false}
                      selectedSort={'name'}
                      required={false}
                      isMulti={true}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={field?.displayName}
                      placeholder={field?.displayName}
                      selectedFilter={''}
                      selectedValue={
                        stateValue[field.internalName] &&
                        stateValue[`${field.internalName}Name`]
                          ? destinationCountries(field, stateValue)
                          : []
                      }
                      onChangeCountry={(value) => {
                        const sortedCountries = value.sort((a, b) =>
                          a.name.localeCompare(b.name),
                        );
                        setStateValue((stateValue) => {
                          stateValue[field.internalName] = sortedCountries
                            ?.map((el) => el.countryCode)
                            .join(';');
                          stateValue[
                            `${field.internalName}Name`
                          ] = sortedCountries?.map((el) => el.name).join(';');
                          return { ...stateValue };
                        });
                      }}
                    />
                  </div>
                )
              );
          }
        })}
      </>
    );
  };

  return (
    <>
      {rows.map((item) => (
        <div key={item.rowNumber}>
          {item.header && (
            <h2 className="font-weight-normal mb-3">{item.header}</h2>
          )}
          <div className="row mb-3">
            {renderFields(customFields, item.rowNumber)}
          </div>
        </div>
      ))}
    </>
  );
};
