import { Dialog } from '../../common/components/dialog/dialog.component';
import { EquipmentTypeEdit } from './equipmentType-edit.component';
import { EquipmentTypeDto } from '../../../models/data.models';

export type EquipmentTypeDialogProperties = {
  equipmentTypeId?: number | null;
  title: string;
};

export class EquipmentTypeDialog extends Dialog<EquipmentTypeDialogProperties> {
  constructor(props: EquipmentTypeDialogProperties) {
    super(props);
    this.onEquipmentTypeSaved = this.onEquipmentTypeSaved.bind(this);
  }

  onEquipmentTypeSaved = (equipmentType: EquipmentTypeDto) => {
    this.close(equipmentType);
  };

  renderContent(): any {
    const { equipmentTypeId } = this.props as EquipmentTypeDialogProperties;
    return (
      <EquipmentTypeEdit
        equipmentTypeId={equipmentTypeId}
        onEquipmentTypeCreated={this.onEquipmentTypeSaved}
        onEquipmentTypeUpdated={this.onEquipmentTypeSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
