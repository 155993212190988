import React from 'react';
import { Button } from '../../../common/components/button/button.component';
import { OrderForm } from '../../../orders/components/order.form';
import {
  ContactType,
  OrderDto,
  ContactDto,
  DivisionDto,
  VisibleTransactions,
  CustomFieldDto,
} from '../../../../models/data.models';
import { ChargeDefaultValues } from '../../../common/DefaultValues';
import { authStore } from '../../../auth/auth.store';
import { FormikProps, FormikValues } from 'formik';
import { CustomFieldsLayout } from '../../../common/components/form/customFields-layout.component';

export type GeneralFragmentProps = {
  handleNext: () => void;
  airOrderContextFormValue: OrderDto;
  setAirOrderContextFormValue: (airOrder: any) => void;
  setApplyToContactPaidAs: (paidAs: any) => void;
  setCarriers: (contact: any) => void;
  setApplyToCarrierPaidAs: (paidAs: any) => void;
  setInitialValues: (orderDto: any) => void;
  context: FormikProps<FormikValues>;
  customFields: CustomFieldDto[];
};

export const GeneralFragment = ({
  handleNext,
  airOrderContextFormValue,
  setAirOrderContextFormValue,
  setCarriers,
  setApplyToCarrierPaidAs,
  customFields,
}: GeneralFragmentProps) => {
  const { user: currentUser } = authStore.getState();

  return (
    <div>
      <div className="row mb-3">
        <div className="col-4">
          <h2 className="font-weight-medium">Air shipment information</h2>
        </div>
      </div>
      <div className="row mb-5">
        <div className={'col-4'}>
          <OrderForm.CarrierContactSelect
            id={'carrierContactId'}
            nameId={'carrierContactName'}
            header={'Carrier'}
            contactTypes={[ContactType.Carrier]}
            selectedFilter={`contactType: ${ContactType.Carrier}`}
            multiple={false}
            closeMenuOnSelect={true}
            defaultValue={(): any => {
              return airOrderContextFormValue.carriers
                ? airOrderContextFormValue?.carriers[0]
                : null;
            }}
            onChange={(contact?: any) => {
              setAirOrderContextFormValue((orderContextFormValueDto) => {
                orderContextFormValueDto.carriers = [contact];
                return orderContextFormValueDto;
              });
              setCarriers(contact);
              if (contact) {
                contact[0]?.contactId
                  ? setApplyToCarrierPaidAs(contact[0]?.paidAs)
                  : setApplyToCarrierPaidAs(ChargeDefaultValues.paidAs);
              }
            }}
          />
        </div>

        <div className="col-4">
          <OrderForm.EmployeeContactSelect
            id={'employeeContactId'}
            header={'Employee'}
            contactTypes={[ContactType.Employee]}
            selectedFilter={`contactType: ${ContactType.Employee}`}
            required={false}
            defaultValue={
              airOrderContextFormValue &&
              airOrderContextFormValue.employeeContactId != null &&
              airOrderContextFormValue.employeeContactName != null
                ? {
                    contactId: airOrderContextFormValue.employeeContactId,
                    name: airOrderContextFormValue.employeeContactName,
                    contactType: ContactType.Employee,
                  }
                : ''
            }
            onChange={(data?: ContactDto) => {
              setAirOrderContextFormValue((airOrderContextFormValueDto) => {
                airOrderContextFormValueDto.employeeContactId = data?.contactId;
                airOrderContextFormValueDto.employeeContactName = data?.name;
                return airOrderContextFormValueDto;
              });
            }}
            nameId={'employeeContactName'}
          />
        </div>

        <div className="col-4">
          <OrderForm.Division
            id={'divisionId'}
            header={'Division'}
            required={true}
            defaultValue={
              airOrderContextFormValue &&
              airOrderContextFormValue.divisionId != null &&
              airOrderContextFormValue.divisionName != null
                ? {
                    divisionId: airOrderContextFormValue.divisionId,
                    divisionName: airOrderContextFormValue.divisionName,
                  }
                : ''
            }
            onChange={(data?: DivisionDto) => {
              setAirOrderContextFormValue((airOrderContextFormValueDto) => {
                airOrderContextFormValueDto.divisionId = data?.divisionId;
                airOrderContextFormValueDto.divisionName = data?.divisionName;
                return airOrderContextFormValueDto;
              });
            }}
            nameId={'divisionName'}
            defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
            disabled={
              !(
                (currentUser?.isInOrgAdminRole ||
                  currentUser?.isInOperationRole) &&
                VisibleTransactions[currentUser?.visibleTransactions] ===
                  VisibleTransactions.AllTransactions
              )
            }
          />
        </div>
      </div>
      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[
          { rowNumber: 1 },
          { rowNumber: 2 },
          { rowNumber: 3 },
          { rowNumber: 4 },
        ]}
        filter={'general'}
        customFields={customFields}
        defaultValue={airOrderContextFormValue.customValues}
        onChange={(result) => {
          setAirOrderContextFormValue((orderContextFormValue) => {
            orderContextFormValue.customValues = result;
            return { ...orderContextFormValue };
          });
        }}
      />
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button
          className="mr-2"
          color="primary"
          onClick={handleNext}
          style={{ minWidth: '8.5rem' }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
