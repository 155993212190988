import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { TariffForm } from './tariff.form';
import {
  ApplyBy,
  CalculatedOf,
  RateDataDto,
  RatePerType,
  TariffDto,
  VolumeUnit,
  WeightUnit,
} from '../../../models/data.models';
import { getEnumValueByValue } from '../../../utils/helper.utils';
import { FormContext } from '../../common/components/form/form.component';
import { FieldArray, useFormikContext } from 'formik';
import { getPackageTypeFx } from '../../packageTypes/packageTypes.store';

export function tariffInitialState(): { tariff: TariffDto } {
  return {
    tariff: {
      baseCharge: 0,
      maximum: 0,
      minimum: 0,
      applyBy: ApplyBy.Weight,
      unitType: WeightUnit.Lb,
      ratePerType: RatePerType.Unit,
      ratePer: 1.0,
      rateData: [{ rateIndex: 0, rateValue: 0 }],
      calculatedOf: CalculatedOf.Income,
    },
  };
}

export const TariffEdit = () => {
  const [removeIndex, setRemoveIndex] = useState(null);
  const [isLoadedRates, setIsLoadedRates] = useState(false);
  const [preUnitType, setPreUnitType] = useState(false);

  const formikContext = useFormikContext();

  useEffect(() => {
    if (!isLoadedRates) {
      setIsLoadedRates(true);
      loadRates(formikContext);
    }
  }, []);

  const applyOnChange = (value, context) => {
    const rate = value / 100;
    const rateFixedValue = rate.toFixed(2);
    context.setFieldValue('tariff.rateMultiplier', rateFixedValue);
  };

  const [rateData, setRateData] = useState<RateDataDto[]>([]);

  const rateIndexChange = (value, context) => {
    const newRateData = rateData;
    newRateData[value.index].rateIndex = value.data;
    setRateData(newRateData);
    context.setFieldValue(
      `tariff.rateData[${value.index}].rateIndex`,
      value.data * 1000,
    );
  };

  const containerTypeChange = (value, context) => {
    const newRateData = rateData;
    newRateData[value.index].rateIndex = value.data;
    setRateData(newRateData);
    context.setFieldValue(
      `tariff.rateData[${value.index}].rateIndex`,
      value.data?.packageTypeId,
    );
  };

  const loadRates = async (context) => {
    const isContainer = context.values?.tariff?.applyBy === ApplyBy.Container;
    const newRateData = rateData;
    if (isContainer) {
      for (const rate of context.values?.tariff?.rateData || []) {
        const containerInfo = await getPackageTypeFx({
          packageTypeId: rate.rateIndex,
        });
        newRateData.push({
          ...rate,
          rateIndex: containerInfo,
        });
      }
    } else {
      context.values?.tariff?.rateData?.map((rate) =>
        newRateData?.push({
          ...rate,
          rateIndex: (rate.rateIndex / 1000).toFixed(2),
        }),
      );
    }

    if (newRateData.length > 0) {
      context.setFieldValue(`tariff.applyBy`, context.values?.tariff?.applyBy);
      setRateData(newRateData);
    } else {
      setDefaultRate();
    }
  };

  const setDefaultRate = () => {
    const defaultArray = [];
    formikContext.setFieldValue('tariff.rateData', defaultArray);
    setRateData(defaultArray);
  };

  const setDefaultUnitType = (context, newUnitType) => {
    if (newUnitType !== preUnitType) {
      const defaultUnitType =
        newUnitType === WeightUnit ? WeightUnit.Kg : VolumeUnit.Vkg;
      context.setFieldValue('tariff.unitType', defaultUnitType);
      setPreUnitType(newUnitType);
    }
  };

  return (
    <FormContext.Consumer>
      {(context) => {
        const unitType =
          context.values?.tariff?.applyBy === ApplyBy.Weight ||
          context.values?.tariff?.applyBy === 'ChargeableWeight'
            ? WeightUnit
            : VolumeUnit;
        const unitTypeHeader =
          unitType === WeightUnit ? 'Weight Unit' : 'Volume Unit';
        const isPieces = context.values?.tariff?.applyBy === ApplyBy.Pieces;
        const isContainer =
          context.values?.tariff?.applyBy === ApplyBy.Container;
        const ratesCount = context.values?.tariff?.rateData?.length;
        const isCalculated =
          context.values?.tariff?.applyBy === ApplyBy.Calculated;
        const rateValue = context.values?.tariff?.rateMultiplier * 100 || 100;
        const rateFixedValue = rateValue.toFixed(0);
        return (
          <div className="row">
            <div className="col-3">
              <div className="row">
                <div className="col-6">
                  <TariffForm.Enums
                    id={'tariff.applyBy'}
                    name={'tariff.applyBy'}
                    enumType={ApplyBy}
                    onChange={(data) => {
                      const newUnitType =
                        data.value === ApplyBy.Weight ||
                        data.value === 'ChargeableWeight'
                          ? WeightUnit
                          : VolumeUnit;
                      setDefaultUnitType(context, newUnitType);
                    }}
                    header={'Apply By'}
                  />
                </div>
                {!isContainer && !isPieces && !isCalculated && (
                  <div className="col-6">
                    <TariffForm.Enums
                      id={'tariff.unitType'}
                      name={'tariff.unitType'}
                      enumType={unitType}
                      placeholder={'Select Rate Per'}
                      header={unitTypeHeader}
                    />
                  </div>
                )}
                {isCalculated && (
                  <>
                    <div className="col-6">
                      <TariffForm.Enums
                        id={'tariff.calculatedOf'}
                        name={'tariff.calculatedOf'}
                        enumType={CalculatedOf}
                        placeholder={'Select Calculated Of'}
                        header={'% Of The'}
                      />
                    </div>
                    <div className="col-6">
                      <TariffForm.Apply
                        onChange={applyOnChange}
                        defaultValue={rateFixedValue}
                      />
                    </div>
                  </>
                )}
              </div>
              {!isCalculated && (
                <>
                  <label className="input-label input-label-primary">
                    <span className="input-label-primary">Rate per</span>
                  </label>
                  <div className="row">
                    <div className="col-6">
                      <TariffForm.Enums
                        id={'tariff.ratePerType'}
                        name={'tariff.ratePerType'}
                        enumType={RatePerType}
                      />
                    </div>
                    {context.values?.tariff?.ratePerType ===
                      RatePerType.Unit && (
                      <>
                        <div className="col-5">
                          <TariffForm.RatePer />
                        </div>
                        <div className="d-flex col-1 align-items-end pl-0">
                          <p>
                            {getEnumValueByValue(
                              context.values?.tariff?.unitType,
                              unitType,
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {(context.values?.tariff?.applyBy === ApplyBy.Weight ||
                context.values?.tariff?.applyBy ===
                  ApplyBy.ChargeableWeight) && (
                <>
                  <div className="row">
                    <div className="col-5">
                      <TariffForm.MinimumWeight />
                    </div>
                    <div className="d-flex col-1 align-items-end pl-0">
                      <p>
                        {getEnumValueByValue(
                          context.values?.tariff?.unitType,
                          unitType,
                        )}
                      </p>
                    </div>
                    <div className="col-5">
                      <TariffForm.MaximumWeight />
                    </div>
                    <div className="d-flex col-1 align-items-end pl-0">
                      <p>
                        {getEnumValueByValue(
                          context.values?.tariff?.unitType,
                          unitType,
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <TariffForm.MinimumChargeableWeight />
                    </div>
                    <div className="d-flex col-1 align-items-end pl-0">
                      <p>
                        {getEnumValueByValue(
                          context.values?.tariff?.unitType,
                          unitType,
                        )}
                      </p>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-6">
                  <TariffForm.Minimum />
                </div>
                <div className="col-6">
                  <TariffForm.Maximum />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <TariffForm.BaseCharge />
                </div>
              </div>
            </div>
            {(context.values?.tariff?.ratePerType === RatePerType.Range ||
              context.values?.tariff?.ratePerType === RatePerType.Unit) &&
              !isCalculated && (
                <FieldArray name={'tariff.rateData'}>
                  {({ push, remove, move }) => {
                    return (
                      <>
                        <div className="col-3 ml-5 d-flex flex-column">
                          <div className="row">
                            <label className="input-label input-label-primary col">
                              <span className="input-label-primary">
                                {isContainer ? 'Container Type' : 'More than'}
                              </span>
                            </label>
                            <label className="input-label input-label-primary col">
                              <span className="input-label-primary">Rate</span>
                            </label>
                          </div>
                          {rateData?.map((rate, index) => (
                            <div
                              className={`row ${
                                index === removeIndex &&
                                (index > 0 || isContainer)
                                  ? 'border rounded border-danger bg-light'
                                  : ''
                              }`}
                              key={`tariff.rateData.${index}`}
                              onClick={() => {
                                index > 0 || isContainer
                                  ? setRemoveIndex(index)
                                  : setRemoveIndex(null);
                              }}
                            >
                              {isContainer ? (
                                <TariffForm.ContainerTypeSelect
                                  defaultValue={rateData[index].rateIndex}
                                  onChange={containerTypeChange}
                                  index={index}
                                  className={'col m-1'}
                                />
                              ) : (
                                <TariffForm.Rates
                                  defaultValue={rateData[index].rateIndex}
                                  onChange={rateIndexChange}
                                  index={index}
                                  className={'col m-1'}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="col-2 pt-5">
                          <Button
                            name="add-rate"
                            color="primary"
                            className="btn-block"
                            onClick={() => {
                              const newRateData = {
                                rateIndex: 0,
                                rateValue: 0,
                              };
                              push(newRateData);
                              const arr = rateData;
                              arr.push(newRateData);
                              setRateData(arr);
                            }}
                          >
                            Add
                          </Button>
                          <Button
                            name="remove-rate"
                            color="danger"
                            className="btn-block"
                            onClick={() => {
                              setRemoveIndex((prevIndex) => {
                                if (ratesCount > 2) {
                                  if (prevIndex !== ratesCount - 1) {
                                    return prevIndex;
                                  } else {
                                    return prevIndex - 1;
                                  }
                                }
                                return null;
                              });
                              remove(removeIndex);
                              const newRateData = rateData;
                              newRateData.splice(removeIndex, 1);
                              setRateData(newRateData);
                            }}
                            disabled={removeIndex === null}
                          >
                            Remove
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </FieldArray>
              )}
          </div>
        );
      }}
    </FormContext.Consumer>
  );
};
