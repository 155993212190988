import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { WorkflowTriggerExecute } from '../components/workflowTrigger-execute.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';

import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { EXECUTE_WORKFLOWTRIGGER_LINK_KEY } from '../workflowTriggers.service';
import { userHas } from '../../auth/auth.store';
import { WORKFLOWS_LIST_PATH } from '../../workflows/workflows.route';

export type WorkflowTriggerScreenProps = { pageTitle: string; group: string };
export type WorkflowTriggerRouteParams = {
  organizationId: string;
  workflowId: string;
};

export const WorkflowTriggerScreen = ({
  pageTitle,
  group,
}: WorkflowTriggerScreenProps) => {
  const navigate = useNavigate();
  const {
    organizationId,
    workflowId,
  }: WorkflowTriggerRouteParams = useParams();

  const closeScreen = useMemo(
    () => () => {
      // check if we can go back
      if (navigate.length > 2) {
        navigate(navigate.length - 1);
      } else {
        const { currentOrganization } = organizationsStore.getState();
        const path = generatePath(INTERNAL_PATH + WORKFLOWS_LIST_PATH, {
          organizationId: currentOrganization.organizationId,
        });
        navigate(path);
      }
    },
    [navigate],
  );

  const workflowIdMemo = useMemo(
    () => (workflowId !== 'create' ? workflowId : null),
    [workflowId],
  );

  return (
    <InternalLayout
      group={group}
      title={'WorkflowTrigger'}
      createPath={null}
      createLabel={'Workflow'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <WorkflowTriggerExecute
        workflowId={workflowIdMemo}
        onCancel={closeScreen}
        onWorkflowTriggerCreated={closeScreen}
        onWorkflowTriggerUpdated={closeScreen}
        onDelete={closeScreen}
      />
    </InternalLayout>
  );
};
