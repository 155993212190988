import { Dialog } from '../../common/components/dialog/dialog.component';
import {
  AttachmentDto,
  AttachmentParentType,
  AttachmentType,
} from '../../../models/data.models';
import { AddAttachments } from './add-attachments.component';

export type AddAttachmentsDialogProperties = {
  parentId: number;
  parentType: AttachmentParentType;
  files?: File[] | null;
  title: string;
  childIds?: number[];
  attachmentType?: AttachmentType;
  prefix?: string;
};

export class AddAttachmentsDialog extends Dialog<AddAttachmentsDialogProperties> {
  constructor(props: AddAttachmentsDialogProperties) {
    super(props);
    this.onAttachmentSaved = this.onAttachmentSaved.bind(this);
  }

  onAttachmentSaved = (attachments: AttachmentDto[]) => {
    this.close(attachments);
  };

  renderContent(): any {
    const {
      files,
      parentId,
      parentType,
      childIds,
      attachmentType,
      prefix,
    } = this.props as AddAttachmentsDialogProperties;
    return (
      <AddAttachments
        files={files}
        parentId={parentId}
        parentType={parentType}
        childIds={childIds}
        onAttachmentCreated={this.onAttachmentSaved}
        onAttachmentsUpdated={this.onAttachmentSaved}
        attachmentType={attachmentType}
        prefix={prefix}
      />
    );
  }
}
