import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { CustomFieldsForm } from '../../common/components/form/customFields-form.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';

export const WorkflowTriggerForm = ({
  id = 'workflowTriggerForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};
