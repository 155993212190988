import {
  ContainerDescriptionDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateContainerDescriptionCommandValues,
  UpdateContainerDescriptionCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContainerDescriptionRequest,
  getContainerDescriptionsListRequest,
  getContainerDescriptionRequest,
  deleteContainerDescriptionRequest,
  updateContainerDescriptionRequest,
  importContainerDescriptionsRequest,
  exportContainerDescriptionsRequest,
  GetContainerDescriptionParams,
} from './containerDescriptions.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type ContainerDescriptionsStoreState = {
  links: LinkDto[];
  containerDescriptionColumns: EntityFieldDto[];
  defaultContainerDescriptionColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getContainerDescriptionsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContainerDescription',
  });
});

addEffectStatusHandling(getContainerDescriptionsColumnsFx);

export const getContainerDescriptionsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContainerDescription',
  });
});

addEffectStatusHandling(getContainerDescriptionsDefaultColumnsFx);

export const updateContainerDescriptionsColumns = createEvent<
  EntityFieldDto[]
>();

export const getContainerDescriptionsFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContainerDescriptionsListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getContainerDescriptionsFx);

export const createContainerDescriptionFx = createEffect(
  (containerDescriptionData: ContainerDescriptionDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createContainerDescriptionCommand: CreateContainerDescriptionCommandValues = {
      ...containerDescriptionData,
    };

    return createContainerDescriptionRequest(
      currentOrganization!,
      createContainerDescriptionCommand,
    );
  },
);

addEffectStatusHandling(createContainerDescriptionFx, {
  completeMessage: 'Container description was created successfully',
  errorMessage: 'Container description was not created',
});

export const getContainerDescriptionFx = createEffect(
  (containerDescriptionParams: GetContainerDescriptionParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContainerDescriptionRequest(
      currentOrganization as LinkResourceBaseDto,
      containerDescriptionParams,
    );
  },
);

addEffectStatusHandling(getContainerDescriptionFx);

export const updateContainerDescriptionFx = createEffect(
  (containerDescription: ContainerDescriptionDto) => {
    const updateContainerDescriptionCommand: UpdateContainerDescriptionCommandValues = {
      ...containerDescription,
    };
    return updateContainerDescriptionRequest(
      containerDescription,
      updateContainerDescriptionCommand,
    );
  },
);

addEffectStatusHandling(updateContainerDescriptionFx, {
  completeMessage: 'Container description was updated successfully',
  errorMessage: 'Container description was not updated',
});

export const deleteContainerDescriptionFx = createEffect(
  (containerDescription: ContainerDescriptionDto) => {
    return deleteContainerDescriptionRequest(containerDescription);
  },
);

addEffectStatusHandling(deleteContainerDescriptionFx, {
  completeMessage: 'Container description was deleted successfully',
  errorMessage: 'Container description was not deleted',
});

export const exportContainerDescriptionsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportContainerDescriptionsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportContainerDescriptionsFx, {
  completeMessage: 'Container descriptions were exported successfully',
  errorMessage: 'Container descriptions were not exported',
});

export const importContainerDescriptionsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importContainerDescriptionsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importContainerDescriptionsFx, {
  completeMessage: 'Container descriptions were imported successfully',
  errorMessage: 'Container descriptions were not imported',
  inFlightMessage: 'Importing container descriptions...',
});

const defaultState: ContainerDescriptionsStoreState = {
  links: [],
  containerDescriptionColumns: [],
  defaultContainerDescriptionColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const containerDescriptionStore: Store<ContainerDescriptionsStoreState> = createStore(
  defaultState,
)
  .on(getContainerDescriptionsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      containerDescriptionColumns: payload.result.items,
      defaultContainerDescriptionColumns: payload.result.items,
    };
  })
  .on(getContainerDescriptionsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultContainerDescriptionColumns: payload.result.items,
    };
  })
  .on(updateContainerDescriptionsColumns, (state, payload) => {
    return { ...state, containerDescriptionColumns: payload };
  });
