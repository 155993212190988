import { LocationType } from '../../models/data.models';

export const MAX_FETCH_LIMIT = 2 ** 31 - 1;

//packing
export const ACTION_UPDATE_COMMODITY = 'UpdateCommodity';
export const ACTION_ADD_TRACKING_NUMBER = 'AddTrackingNumber';
export const ACTION_PACKAGE_SCAN = 'PackageScan';
export const ACTION_SHIPPING_PALLET = 'ShippingPallet';
export const ACTION_DELETE_TRACKING_NUMBER = 'DeleteTrackingNumber';
export const PACKING_LOCATION_TYPE = LocationType.Service;
export const PACKING_CURRENT_LOCATION_TYPE = LocationType.Packing;
export const ACTION_PACKING_SCAN_TRACKING_NUMBER = 'PackingScan';
