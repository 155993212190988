import { Dialog } from '../../common/components/dialog/dialog.component';
import { DocumentTemplateEdit } from './documentTemplate-edit.component';
import { DocumentTemplateDto } from '../../../models/data.models';

export type DocumentTemplateDialogProperties = {
  documentTemplateId?: number | null;
  title: string;
};

export class DocumentTemplateDialog extends Dialog<DocumentTemplateDialogProperties> {
  constructor(props: DocumentTemplateDialogProperties) {
    super(props);
    this.onDocumentTemplateSaved = this.onDocumentTemplateSaved.bind(this);
  }

  onDocumentTemplateSaved = (documentTemplate: DocumentTemplateDto) => {
    this.close(documentTemplate);
  };

  renderContent(): any {
    const { documentTemplateId } = this
      .props as DocumentTemplateDialogProperties;
    return (
      <DocumentTemplateEdit
        documentTemplateId={documentTemplateId}
        onDocumentTemplateCreated={this.onDocumentTemplateSaved}
        onDocumentTemplateUpdated={this.onDocumentTemplateSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
