import React from 'react';
import { Helmet } from 'react-helmet';
import { Tracking } from '../components/tracking.component';

export type TrackingScreenProps = { pageTitle: string };

export const TrackingScreen = ({ pageTitle }: TrackingScreenProps) => {
  const orderSelector = new URLSearchParams(window.location.search).get(
    'orderSelector',
  );
  // @ts-ignore

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Tracking orderSelectorValue={orderSelector} />
    </>
  );
};
