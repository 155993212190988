import { v4 } from 'uuid';
import {
  ContactPaymentMethodDto,
  ContactPaymentMethodDtoPagedResult,
  LinkDto,
  LinkResourceBaseDto,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { addMessage } from '../common/messages.store';
import { ListParams } from '../common/models/ListParams';
export const GET_CONTACT_PAYMENT_METHODS_LINK_KEY = 'get-payment-methods';
export const GET_CONTACT_PAYMENT_METHOD_LINK_KEY = 'get-payment-method';
export const CONTACT_PAYMENT_METHOD_ENTITY_NAME = 'Contact Payment Method';

export interface GetContactPaymentMethodParams {
  contactPaymentMethodId: number;
}

export const getContactPaymentMethodsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContactPaymentMethodDtoPagedResult> => {
  if (resource) {
    const getContactPaymentMethodsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACT_PAYMENT_METHODS_LINK_KEY,
    );
    if (getContactPaymentMethodsListLink) {
      const result = await execLink(getContactPaymentMethodsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTACT_PAYMENT_METHODS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getContactPaymentMethodRequest = async (
  resource: LinkResourceBaseDto | null,
  { contactPaymentMethodId }: GetContactPaymentMethodParams,
): Promise<ContactPaymentMethodDto> => {
  if (resource && contactPaymentMethodId) {
    const getContactLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACT_PAYMENT_METHOD_LINK_KEY,
    );
    if (getContactLink) {
      const result = await execLink(getContactLink, { contactPaymentMethodId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTACT_PAYMENT_METHOD_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
