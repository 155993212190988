import { Dialog } from '../../common/components/dialog/dialog.component';
import { CustomFieldEdit } from './customField-edit.component';
import { CustomFieldDto } from '../../../models/data.models';

export type CustomFieldDialogProperties = {
  fieldId?: number | null;
  title: string;
};

export class CustomFieldDialog extends Dialog<CustomFieldDialogProperties> {
  constructor(props: CustomFieldDialogProperties) {
    super(props);
    this.onCustomFieldSaved = this.onCustomFieldSaved.bind(this);
  }

  onCustomFieldSaved = (customField: CustomFieldDto) => {
    this.close(customField);
  };

  renderContent(): any {
    const { fieldId } = this.props as CustomFieldDialogProperties;
    return (
      <CustomFieldEdit
        fieldId={fieldId}
        onCustomFieldCreated={this.onCustomFieldSaved}
        onCustomFieldUpdated={this.onCustomFieldSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
