import {
  OrderStatusDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateOrderStatusCommandValues,
  UpdateOrderStatusCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrderStatusRequest,
  getOrderStatusesListRequest,
  getOrderStatusRequest,
  deleteOrderStatusRequest,
  updateOrderStatusRequest,
  importOrderStatusesRequest,
  exportOrderStatusesRequest,
  GetOrderStatusParams,
} from './orderStatuses.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type OrderStatusesStoreState = {
  links: LinkDto[];
  orderStatusColumns: EntityFieldDto[];
  defaultOrderStatusColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getOrderStatusesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'OrderStatus',
  });
});

addEffectStatusHandling(getOrderStatusesColumnsFx);

export const getOrderStatusesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'OrderStatus',
  });
});

addEffectStatusHandling(getOrderStatusesDefaultColumnsFx);

export const updateOrderStatusesColumns = createEvent<EntityFieldDto[]>();

export const getOrderStatusesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getOrderStatusesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getOrderStatusesFx);

export const createOrderStatusFx = createEffect(
  (orderStatusData: OrderStatusDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createOrderStatusCommand: CreateOrderStatusCommandValues = {
      ...orderStatusData,
    };

    return createOrderStatusRequest(
      currentOrganization!,
      createOrderStatusCommand,
    );
  },
);

addEffectStatusHandling(createOrderStatusFx, {
  completeMessage: 'Order status was created successfully',
  errorMessage: 'Order status was not created',
});

export const getOrderStatusFx = createEffect(
  (orderStatusParams: GetOrderStatusParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrderStatusRequest(
      currentOrganization as LinkResourceBaseDto,
      orderStatusParams,
    );
  },
);

addEffectStatusHandling(getOrderStatusFx);

export const updateOrderStatusFx = createEffect(
  (orderStatus: OrderStatusDto) => {
    const updateOrderStatusCommand: UpdateOrderStatusCommandValues = {
      ...orderStatus,
    };
    return updateOrderStatusRequest(orderStatus, updateOrderStatusCommand);
  },
);

addEffectStatusHandling(updateOrderStatusFx, {
  completeMessage: 'Order status was updated successfully',
  errorMessage: 'Order status was not updated',
});

export const deleteOrderStatusFx = createEffect(
  (orderStatus: OrderStatusDto) => {
    return deleteOrderStatusRequest(orderStatus);
  },
);

addEffectStatusHandling(deleteOrderStatusFx, {
  completeMessage: 'Order status was deleted successfully',
  errorMessage: 'Order status was not deleted',
});

export const exportOrderStatusesFx = createEffect(
  ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    exportOrderStatusesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportOrderStatusesFx, {
  completeMessage: 'Order statuses were exported successfully',
  errorMessage: 'Order statuses were not exported',
});

export const importOrderStatusesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importOrderStatusesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importOrderStatusesFx, {
  completeMessage: 'Order statuses were imported successfully',
  errorMessage: 'Order statuses were not imported',
  inFlightMessage: 'Importing order statuses...',
});

const defaultState: OrderStatusesStoreState = {
  links: [],
  orderStatusColumns: [],
  defaultOrderStatusColumns: [],
  defaultSort: 'orderStatusName',
  defaultLimit: 20,
};

export const orderStatusStore: Store<OrderStatusesStoreState> = createStore(
  defaultState,
)
  .on(getOrderStatusesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      orderStatusColumns: payload.result.items,
      defaultOrderStatusColumns: payload.result.items,
    };
  })
  .on(getOrderStatusesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultOrderStatusColumns: payload.result.items,
    };
  })
  .on(updateOrderStatusesColumns, (state, payload) => {
    return { ...state, orderStatusColumns: payload };
  });
