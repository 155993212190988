import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { OrganizationConfigScreen } from './screen/organizationConfig.screen';
import { OrganizationConfigsListScreen } from './screen/organizationConfigs-list.screen';

export const ORGANIZATION_CONFIGS_LIST_PATH = '/organization-configs';
export const ORGANIZATION_CONFIG_EDIT_PATH =
  '/organization-configs/:organizationConfigId';
export const ORGANIZATION_CONFIG_CREATE_PATH = '/organization-configs/0';

export const OrganizationConfigsRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <OrganizationConfigsListScreen
              pageTitle="Organization Configs"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <OrganizationConfigScreen
              pageTitle="Add New Organization Config"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':organizationConfigId'}
        element={
          <PrivateRoute>
            <OrganizationConfigScreen
              pageTitle="Update Organization Config"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
