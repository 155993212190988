import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const ActionEventForm = ({
  id = 'actionEventForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

ActionEventForm.ErrorMessage = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'errorMessage'}
      id={'errorMessage'}
      label={'Error Message'}
      placeholder={'Error Message'}
      required={false}
      type={'textarea'}
      rows={5}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
ActionEventForm.EventData = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'eventData'}
      id={'eventData'}
      label={'Event Data'}
      placeholder={'Event Data'}
      required={true}
      type={'json'}
      rows={10}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
ActionEventForm.EventName = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'eventName'}
      id={'eventName'}
      label={'Event Name'}
      placeholder={'Event Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
ActionEventForm.Status = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'status'}
      id={'status'}
      label={'Status'}
      placeholder={'Status'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
