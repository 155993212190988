import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';
import { SelectEventDefinitionPaginate } from '../../eventDefinitions/components/eventDefinitions-react-select.component';

export const TrackingEventForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  className,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      className={className}
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

TrackingEventForm.EventDate = ({
  required = false,
  defaultValue,
  header = 'Event Date',
  nameId = 'eventDate',
  placeholder = 'Select Event Date',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

TrackingEventForm.EventName = ({
  selectedFilter = ``,
  placeholder = 'Select Event Type',
  required = true,
  defaultValue,
  sort = 'eventDefinitionId',
  id = 'eventDefinitionId',
  header = 'Event Type',
  onChange = () => {},
  nameId = 'eventName',
  disabled = false,
}: InputPublicProps) => {
  return (
    <SelectEventDefinitionPaginate
      nameId={nameId}
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeEventDefinition={onChange}
      isDisabled={disabled}
    />
  );
};

TrackingEventForm.Description = ({
  required = false,
  defaultValue,
  header = 'Details',
  nameId = 'description',
  placeholder = 'Describe the event',
  className,
  style,
  readonly,
  disabled,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      type={'textarea'}
      className={className}
      style={style}
      readonly={readonly}
      disabled={disabled}
      size={size}
      valueInput={defaultValue}
    />
  );
};

TrackingEventForm.Location = ({
  required = false,
  defaultValue,
  header = 'Location',
  nameId = 'location',
  placeholder = 'Select location',
  className,
  style,
  readonly,
  disabled,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      readonly={readonly}
      disabled={disabled}
      size={size}
      valueInput={defaultValue}
    />
  );
};

TrackingEventForm.IsInactive = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      id={'isInactiveTrackingEventCheckbox'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

TrackingEventForm.IncludeInTracking = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'includeInTracking'}
      id={'includeInTrackingTrackingEventCheckbox'}
      label={'Include in Tracking'}
      placeholder={'Include in Tracking'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

TrackingEventForm.SendEmail = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'sendEmail'}
      id={'sendEmailTrackingEventCheckbox'}
      label={'Send in Transaction Tracking Email'}
      placeholder={'Send in Transaction Tracking Email'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
