import React, { useEffect } from 'react';
import { logout } from '../../auth/auth.store';
import { useNavigate } from 'react-router-dom';

export type LogoutScreenProps = { pageTitle: string };

export const LogoutScreen = ({ pageTitle }: LogoutScreenProps) => {
  const navigate = useNavigate();

  const onLogout = () => {
    if (logout) {
      logout();
    }
    navigate('/login');
  };

  useEffect(() => {
    onLogout();
  }, []);

  return <div>Logging out</div>;
};
