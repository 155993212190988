import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  OCEAN_ORDER_CREATE_PATH,
  OCEAN_ORDER_LIST_PATH,
} from '../oceanShipmentOrders.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../../orders/orders.service';
import { userHas } from '../../auth/auth.store';
import { OceanShipmentOrderEdit } from '../components/oceanShipmentOrder-edit.component';

export const OceanShipmentOrderScreen = ({ pageTitle, group }) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + OCEAN_ORDER_CREATE_PATH, {
    organizationId,
  });

  const onOceanOrderCreated = () => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'ocean-order-created',
      type: 'success',
      autoHide: true,
      message: 'Ocean Shipment successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onOceanOrderUpdated = () => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'ocean-shipment-updated',
      type: 'success',
      autoHide: true,
      message: 'Ocean Shipment successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Ocean Shipments'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OceanShipmentOrderEdit
        orderId={orderId}
        onOceanOrderCreated={onOceanOrderCreated}
        onOceanOrderUpdated={onOceanOrderUpdated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
