import { Dialog } from '../../common/components/dialog/dialog.component';
import { ActionEventEdit } from './actionEvent-edit.component';
import { ActionEventDto } from '../../../models/data.models';

export type ActionEventDialogProperties = {
  eventId?: string | null;
  title: string;
};

export class ActionEventDialog extends Dialog<ActionEventDialogProperties> {
  constructor(props: ActionEventDialogProperties) {
    super(props);
    this.onActionEventSaved = this.onActionEventSaved.bind(this);
  }

  onActionEventSaved = (actionEvent: ActionEventDto) => {
    this.close(actionEvent);
  };

  onActionEventDeleted = (actionEvent: ActionEventDto) => {
    this.close(actionEvent);
  };

  onCancel = () => {
    this.close();
  };

  renderContent(): any {
    const { eventId } = this.props as ActionEventDialogProperties;
    return (
      <ActionEventEdit
        eventId={eventId}
        onActionEventCreated={this.onActionEventSaved}
        onActionEventUpdated={this.onActionEventSaved}
        onDelete={this.onActionEventDeleted}
        onCancel={this.onCancel}
      />
    );
  }
}
