import { Dialog } from '../../common/components/dialog/dialog.component';
import { SendEmailComponent } from './sendEmail.component';
import { DocumentTemplateType } from '../../../models/data.models';

export type SendEmailDialogProperties = {
  title: string;
  className?: string;
  filter?: string;
  selectedRecipientId?: string | number;
  recipientIds?: number[];
  documentTypes?: DocumentTemplateType[];
  metadata?: object;
  emailTemplate: DocumentTemplateType;
  onEmailSent?: (data: any) => void;
  onEmailTemplateLoaded?: () => void;
};

export class SendEmailDialog extends Dialog<SendEmailDialogProperties> {
  constructor(props: SendEmailDialogProperties) {
    super(props);
    this.onEmailSent = this.onEmailSent.bind(this);
  }

  onEmailSent = (result: any) => {
    this.close(result);
  };

  renderContent(): any {
    const {
      className,
      filter,
      selectedRecipientId,
      recipientIds,
      documentTypes,
      metadata,
      emailTemplate,
      onEmailTemplateLoaded,
    } = this.props as SendEmailDialogProperties;
    return (
      <SendEmailComponent
        className={className}
        selectedRecipientId={selectedRecipientId}
        recipientIds={recipientIds}
        documentTypes={documentTypes}
        metadata={metadata}
        filter={filter}
        emailType={emailTemplate}
        onEmailSent={this.onEmailSent.bind(this)}
        onEmailTemplateLoaded={onEmailTemplateLoaded}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
