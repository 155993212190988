import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { WarehouseInspectorListScreen } from './screens/warehouseInspector-list.screen';

export const WAREHOUSEZONE_LIST_PATH = '/warehouseZones';
export const WAREHOUSEZONE_EDIT_PATH = '/warehouseZones/:warehouseZoneId';
export const WAREHOUSEZONE_CREATE_PATH = '/warehouseZones/0';
export const ZONES_INSPECTOR_LIST_PATH = '/zoneInspector';

export const WarehouseInspectorRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <WarehouseInspectorListScreen
              pageTitle="Zone Inspector"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
