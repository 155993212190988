import React, { useEffect, useState } from 'react';
import {
  LocationType,
  WarehouseLocationDto,
  WarehouseLocationDtoPagedResult,
} from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { v4 as uuidv4 } from 'uuid';
import { showDialog } from '../../common/dialog.store';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import {
  getWarehouseLocationFx,
  getWarehouseLocationsFx,
} from '../warehouseLocations.store';
import { WarehouseLocationDialog } from './warehouseLocation.dialog';
import {
  CREATE_WAREHOUSELOCATION_LINK_KEY,
  GET_WAREHOUSELOCATION_LINK_KEY,
  WAREHOUSELOCATION_ENTITY_NAME,
} from '../warehouseLocations.service';
import { ScanButton } from '../../barcodeScanner/components/scan-button.component';

export const getWarehouseLocationOptionValue = (
  location: WarehouseLocationDto,
): string => {
  if (!location) return '';
  return `${
    location?.description ?? LocationType[location?.locationType] ?? 'Location'
  } (${location?.code})`;
};

export const SelectWarehouseLocationPaginate = ({
  id = 'warehouseLocationId' + uuidv4(),
  nameId = 'warehouseLocation',
  required = false,
  limit = 10,
  useContext = false,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeLocation = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Warehouse Location',
  barcode = false,
  setWarning = null,
}): JSX.Element => {
  const formikContext = useFormikContext();
  const [value, setValue] = useState(selectedValue);

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const [context, setContext] = useState(null);
  useEffect(() => {
    setContext(useContext);
  }, [useContext]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseWarehouseLocationDtoPagedResult: WarehouseLocationDtoPagedResult = await getWarehouseLocationsFx(
      {
        limit: limit,
        offset: (page - 1) * limit,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseWarehouseLocationDtoPagedResult.items,
      hasMore: responseWarehouseLocationDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeLocation === 'function') {
      onChangeLocation(option, contextDto);
    }
  };

  const getContextValue = (context: FormikProps<FormikValues>) => {
    if (!barcode) {
      setValue(context?.values?.warehouseLocation);
      return getWarehouseLocationOptionValue(
        context?.values?.warehouseNavigate,
      );
    } else {
      return getWarehouseLocationOptionValue(value);
    }
  };

  const onChangeWithContext = (location, currentContext) => {
    onChange(location, currentContext);
    if (currentContext) {
      if (isMulti === true) {
        if (location?.length > 0) {
          currentContext.setFieldValue(id, location?.warehouseLocationId);
          currentContext.setFieldError(id, undefined);
        } else {
          if (required) {
            currentContext.setFieldValue(id, '');
          }
        }
      } else {
        currentContext.setFieldValue(id, location?.warehouseLocationId);
        currentContext.setFieldValue(nameId, location);
        currentContext.setFieldError(id, undefined);
        if (required && !location) {
          currentContext.setFieldValue(id, '');
          currentContext.setFieldValue(nameId, null);
        }
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onLocationEditIconClick = () => {
    if (value?.warehouseLocationId) {
      showDialog({
        dialog: WarehouseLocationDialog,
        props: {
          warehouseLocationId: value.warehouseLocationId,
          title: 'Update Warehouse Location',
        },
      }).then(
        (location) => {
          if (location !== null) {
            getWarehouseLocationFx({
              warehouseLocationId: Number(value.warehouseLocationId) || null,
            }).then(
              (warehouseLocationDto) => {
                onChangeWithContext(warehouseLocationDto, formikContext);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  const barcodeStyles = {
    control: (provided, state) => ({
      ...provided,
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      height: '34px',
    }),
  };

  if (context) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti &&
                value &&
                userHas(GET_WAREHOUSELOCATION_LINK_KEY) ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onLocationEditIconClick}
                    data-cy={`warehouse-location-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <div className="d-flex">
              <div className={'w-100'}>
                <Field
                  required={required}
                  type="hidden"
                  name={id}
                  value={getContextValue(context)}
                />
                <SelectEntityAsyncPaginate
                  className={id}
                  entityName={WAREHOUSELOCATION_ENTITY_NAME}
                  canCreateNewEntity={userHas(
                    CREATE_WAREHOUSELOCATION_LINK_KEY,
                  )}
                  onCreateNewEntity={() => {
                    return showDialog({
                      dialog: WarehouseLocationDialog,
                      props: {
                        title: showCreateDialogTitle,
                        warehouseLocationId: 0,
                      },
                    }).then(
                      (location) => {
                        setClearLoadOptionsCache(!clearLoadOptionsCache);
                        return location;
                      },
                      () => {},
                    );
                  }}
                  key={filter}
                  value={value}
                  loadOptions={loadOptions}
                  getOptionValue={(option) => option?.warehouseLocationId}
                  getOptionLabel={(option) =>
                    getWarehouseLocationOptionValue(option)
                  }
                  onChange={(location?: any) => {
                    onChangeWithContext(location, context);
                  }}
                  onFocus={clearMessages}
                  onBlur={() => context.setFieldTouched(id, true)}
                  isDisabled={isDisabled}
                  isClearable={isClearable}
                  isMulti={isMulti}
                  closeMenuOnSelect={closeMenuOnSelect}
                  isSearchable={isSearchable}
                  placeholder={placeholder}
                  classNamePrefix={classNamePrefix}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  additional={{
                    page: 1,
                  }}
                  cacheUniqs={[clearLoadOptionsCache]}
                  styles={barcode ? barcodeStyles : null}
                />
              </div>
              {barcode ? (
                <ScanButton
                  disabled={false}
                  name={id}
                  onChange={(value) => {
                    if (value) {
                      getWarehouseLocationsFx({
                        limit: 1,
                        filter: `Code: "${value}" `,
                      }).then((result) => {
                        if (result.items.length > 0) {
                          onChangeWithContext(result.items[0], context);
                          if (setWarning) setWarning('');
                        } else {
                          if (setWarning)
                            setWarning('No location with specified code found');
                        }
                      });
                    }
                  }}
                  setFieldValue={formikContext.setFieldValue}
                />
              ) : null}
              <ErrorMessage
                name={id}
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={WAREHOUSELOCATION_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_WAREHOUSELOCATION_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: WarehouseLocationDialog,
            props: {
              title: showCreateDialogTitle,
              warehouseLocationId: 0,
            },
          }).then(
            (location) => {
              setClearLoadOptionsCache(!clearLoadOptionsCache);
              return location;
            },
            () => {},
          );
        }}
        key={filter}
        value={selectedValue}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.warehouseLocationId}
        getOptionLabel={(option) => getWarehouseLocationOptionValue(option)}
        onChange={(location?: any) => {
          onChange(location, context);
        }}
        onFocus={clearMessages}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
