import React from 'react';
import { CustomFieldDto, OrderDto } from '../../../../models/data.models';
import { CustomFieldsLayout } from '../../../common/components/form/customFields-layout.component';
import { Button } from '../../../common/components/button/button.component';

type RoutingFragmentProps = {
  oceanOrderContextFormValue: OrderDto;
  setOceanOrderContextFormValue: (order: any) => void;
  customFields: CustomFieldDto[];
  handleNext: () => void;
  handleBack: () => void;
};

export const RoutingFragment = ({
  oceanOrderContextFormValue,
  setOceanOrderContextFormValue,
  customFields,
  handleNext,
  handleBack,
}: RoutingFragmentProps) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-3">
          <h2>Routing information</h2>
        </div>
      </div>
      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[{ rowNumber: 1 }]}
        filter={'routing'}
        customFields={customFields}
        defaultValue={{
          ...oceanOrderContextFormValue.customValues,
        }}
        onChange={(result) => {
          setOceanOrderContextFormValue((oceanOrderContextFormValue) => {
            oceanOrderContextFormValue.customValues = {
              ...oceanOrderContextFormValue.customValues,
              ...result,
            };
            return { ...oceanOrderContextFormValue };
          });
        }}
      />

      <hr className="my-4" />
      <h2>Origin</h2>

      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[{ rowNumber: 1 }]}
        filter={'routingOrigin'}
        customFields={customFields}
        defaultValue={{
          ...oceanOrderContextFormValue.customValues,
        }}
        onChange={(result) => {
          setOceanOrderContextFormValue((oceanOrderContextFormValue) => {
            oceanOrderContextFormValue.customValues = {
              ...oceanOrderContextFormValue.customValues,
              ...result,
            };
            return { ...oceanOrderContextFormValue };
          });
        }}
      />

      <hr className="my-4" />
      <h2>Export</h2>
      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[{ rowNumber: 1 }, { rowNumber: 2 }]}
        filter={'routingExport'}
        customFields={customFields}
        defaultValue={{
          ...oceanOrderContextFormValue.customValues,
        }}
        onChange={(result) => {
          setOceanOrderContextFormValue((oceanOrderContextFormValue) => {
            oceanOrderContextFormValue.customValues = {
              ...oceanOrderContextFormValue.customValues,
              ...result,
            };
            return { ...oceanOrderContextFormValue };
          });
        }}
      />
      <hr className="my-4" />
      <h2>Destination</h2>

      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[{ rowNumber: 1 }]}
        filter={'routingDestination'}
        customFields={customFields}
        defaultValue={{
          ...oceanOrderContextFormValue.customValues,
        }}
        onChange={(result) => {
          setOceanOrderContextFormValue((oceanOrderContextFormValue) => {
            oceanOrderContextFormValue.customValues = {
              ...oceanOrderContextFormValue.customValues,
              ...result,
            };
            return { ...oceanOrderContextFormValue };
          });
        }}
      />

      <hr className="my-4" />
      <h2>US Custom Codes for Origin and Destination: Schedule D and K</h2>

      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[{ rowNumber: 1 }, { rowNumber: 2 }]}
        filter={'routingCustomCodes'}
        customFields={customFields}
        defaultValue={{
          ...oceanOrderContextFormValue.customValues,
        }}
        onChange={(result) => {
          setOceanOrderContextFormValue((oceanOrderContextFormValue) => {
            oceanOrderContextFormValue.customValues = {
              ...oceanOrderContextFormValue.customValues,
              ...result,
            };
            return { ...oceanOrderContextFormValue };
          });
        }}
      />
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button className="mr-2" color="primary" onClick={handleNext}>
          Next
        </Button>
        <Button className="mr-3" onClick={handleBack}>
          Back
        </Button>
      </div>
    </div>
  );
};
