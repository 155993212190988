import {
  ModeOfTransportationDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  ModeOfTransportationDtoPagedResult,
  CreateModeOfTransportationCommandValues,
  UpdateModeOfTransportationCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_MODEOFTRANSPORTATIONS_LINK_KEY = 'get-modeoftransportations';
export const CREATE_MODEOFTRANSPORTATION_LINK_KEY =
  'create-modeoftransportation';
export const GET_MODEOFTRANSPORTATION_LINK_KEY = 'get-modeoftransportation';
export const UPDATE_MODEOFTRANSPORTATION_LINK_KEY =
  'update-modeoftransportation';
export const DELETE_MODEOFTRANSPORTATION_LINK_KEY =
  'delete-modeoftransportation';
export const IMPORT_MODEOFTRANSPORTATIONS_LINK_KEY =
  'import-modeoftransportations';
export const EXPORT_MODEOFTRANSPORTATIONS_LINK_KEY =
  'export-modeoftransportations';
export const MODEOFTRANSPORTATION_ENTITY_NAME = 'Mode of Transportation';

export interface GetModeOfTransportationParams {
  modeOfTransportationId: number;
}

export interface ImportModeOfTransportationsParams {
  organizationId: number;
}

export interface ExportModeOfTransportationsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getModeOfTransportationRequest = async (
  resource: LinkResourceBaseDto | null,
  { modeOfTransportationId }: GetModeOfTransportationParams,
): Promise<ModeOfTransportationDto> => {
  if (resource && modeOfTransportationId) {
    const getModeOfTransportationLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_MODEOFTRANSPORTATION_LINK_KEY,
    );
    if (getModeOfTransportationLink) {
      const result = await execLink(getModeOfTransportationLink, {
        modeOfTransportationId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getModeOfTransportationsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ModeOfTransportationDtoPagedResult> => {
  if (resource) {
    const getModeOfTransportationsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_MODEOFTRANSPORTATIONS_LINK_KEY,
    );
    if (getModeOfTransportationsListLink) {
      const result = await execLink(getModeOfTransportationsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_MODEOFTRANSPORTATIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createModeOfTransportationRequest = async (
  resource: LinkResourceBaseDto,
  modeOfTransportation: CreateModeOfTransportationCommandValues,
) => {
  const createModeOfTransportationLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_MODEOFTRANSPORTATION_LINK_KEY,
  );
  if (createModeOfTransportationLink) {
    const result = await execLink(
      createModeOfTransportationLink,
      modeOfTransportation,
    );
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateModeOfTransportationRequest = async (
  resource: LinkResourceBaseDto,
  modeOfTransportation: UpdateModeOfTransportationCommandValues,
) => {
  const updateModeOfTransportationLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_MODEOFTRANSPORTATION_LINK_KEY,
  );
  if (updateModeOfTransportationLink) {
    const result = await execLink(
      updateModeOfTransportationLink,
      modeOfTransportation,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteModeOfTransportationRequest = async (
  modeOfTransportation: ModeOfTransportationDto,
) => {
  const deleteModeOfTransportationLink = modeOfTransportation.links?.find(
    (x: LinkDto) => x.rel === DELETE_MODEOFTRANSPORTATION_LINK_KEY,
  );
  if (deleteModeOfTransportationLink) {
    const result = await execLink(
      deleteModeOfTransportationLink,
      modeOfTransportation,
    );
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importModeOfTransportationsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportModeOfTransportationsParams,
  file: File,
) => {
  const importModeOfTransportationsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_MODEOFTRANSPORTATIONS_LINK_KEY,
  );
  if (importModeOfTransportationsLink) {
    const result = await postFormData(importModeOfTransportationsLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_MODEOFTRANSPORTATIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportModeOfTransportationsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportModeOfTransportationsParams,
) => {
  const exportModeOfTransportationsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_MODEOFTRANSPORTATIONS_LINK_KEY,
  );
  if (exportModeOfTransportationsLink) {
    const result = await downloadLinkFile(
      exportModeOfTransportationsLink,
      params,
    );
    return result;
  }
  throw new Error(
    `Action ${EXPORT_MODEOFTRANSPORTATIONS_LINK_KEY} is not allowed or missing link action`,
  );
};
