import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { WarehouseReceiptScreen } from './screens/warehouseReceipt.screen';
import { WarehouseReceiptsListScreen } from './screens/warehouseReceipts-list.screen';
import { PackageReceiveWizardScreen } from './screens/packageReceive-wizard.screen';

export const WAREHOUSE_RECEIPT_LIST_PATH = '/warehouseReceipts';
export const WAREHOUSE_RECEIPT_EDIT_PATH =
  '/warehouseReceipts/:warehouseReceiptId';
export const WAREHOUSE_RECEIPT_CREATE_PATH = '/warehouseReceipts/0';
export const WAREHOUSE_PACKAGE_RECEIVE_PATH = '/warehouseReceipts/receive';

export const WarehouseReceiptRoute = (props: RouteProps) => {
  const group = 'Operations';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <WarehouseReceiptsListScreen
              pageTitle="Warehouse Receipts"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <WarehouseReceiptScreen
              pageTitle="Add New Warehouse Receipt"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':warehouseReceiptId'}
        element={
          <PrivateRoute>
            <WarehouseReceiptScreen
              pageTitle="Update Warehouse Receipt"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'receive'}
        element={
          <PrivateRoute>
            <PackageReceiveWizardScreen
              pageTitle="Receive Package"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
