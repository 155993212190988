import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  MODE_OF_TRANSPORTATION_CREATE_PATH,
  MODE_OF_TRANSPORTATIONS_LIST_PATH,
} from '../modeOfTransportation.route';
import { ModeOfTransportationEdit } from '../components/modeOfTransportation-edit.component';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_MODEOFTRANSPORTATION_LINK_KEY } from '../modeOfTransportations.service';
import { userHas } from '../../auth/auth.store';

export type ModeOfTransportationScreenProps = {
  pageTitle: string;
  group: string;
};

export const ModeOfTransportationScreen = ({
  pageTitle,
  group,
}: ModeOfTransportationScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, modeOfTransportationId } = useParams();

  const createPath = generatePath(
    INTERNAL_PATH + MODE_OF_TRANSPORTATION_CREATE_PATH,
    {
      organizationId,
    },
  );

  const onModeOfTransportationCreated = () => {
    const path = generatePath(
      INTERNAL_PATH + MODE_OF_TRANSPORTATIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    const message: Message = {
      id: 'mode-of-transportation-created',
      type: 'success',
      autoHide: true,
      message: 'Mode of transportation successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onModeOfTransportationUpdated = () => {
    const path = generatePath(
      INTERNAL_PATH + MODE_OF_TRANSPORTATIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    const message: Message = {
      id: 'mode-of-transportation-updated',
      type: 'success',
      autoHide: true,
      message: 'Mode of transportation successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(
      INTERNAL_PATH + MODE_OF_TRANSPORTATIONS_LIST_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Mode of Transportations'}
      createPath={
        userHas(CREATE_MODEOFTRANSPORTATION_LINK_KEY) ? createPath : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ModeOfTransportationEdit
        modeOfTransportationId={modeOfTransportationId}
        onModeOfTransportationUpdated={onModeOfTransportationUpdated}
        onModeOfTransportationCreated={onModeOfTransportationCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
