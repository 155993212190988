import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { localized } from '../layout/component-hooks';
import { FiMenu } from 'react-icons/fi';

export const ViewSelector = ({
  context,
  views,
  currentView,
  onViewChanged,
  onSaveView,
  defaultViewName,
  dataGridName,
  filterValues,
  variables,
  ...dropdownProps
}) => {
  const [selectedView, setSelectedView] = useState('Select View');

  useEffect(() => {
    const view = views[currentView?.id];
    setSelectedView(localized(view?.displayName));
  }, [currentView, views]);

  const handleViewChanged = (eventKey) => {
    if (eventKey === 'save-view') {
      onSaveView();
      return;
    }
    const view = views[eventKey];
    if (view?.name !== currentView?.id) {
      setSelectedView(localized(view.displayName));
      onViewChanged(view);
    }
  };

  return (
    <>
      <Dropdown onSelect={handleViewChanged} {...dropdownProps}>
        <Dropdown.Toggle id="view-selector" variant="light">
          <FiMenu size="20px" />
          &nbsp;
          <span className="px-1"> {selectedView}</span>
          &nbsp;
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.keys(views).map((viewId) => {
            const view = views[viewId];
            return (
              <Dropdown.Item eventKey={view.name} key={view.name}>
                {localized(view.displayName)}
              </Dropdown.Item>
            );
          })}
          <Dropdown.Divider />
          <Dropdown.Item eventKey="save-view">
            {localized('View Settings')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
