import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  AIR_ORDER_CREATE_PATH,
  AIR_ORDER_LIST_PATH,
} from '../airShipmentOrders.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../../orders/orders.service';
import { userHas } from '../../auth/auth.store';
import { AirShipmentOrderEdit } from '../components/airShipmentOrder-edit.component';

export const AirShipmentOrderScreen = ({ pageTitle, group }) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + AIR_ORDER_CREATE_PATH, {
    organizationId,
  });

  const onAirOrderCreated = () => {
    const path = generatePath(INTERNAL_PATH + AIR_ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'air-order-created',
      type: 'success',
      autoHide: true,
      message: 'Air Shipment successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onAirOrderUpdated = () => {
    const path = generatePath(INTERNAL_PATH + AIR_ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'air-shipment-updated',
      type: 'success',
      autoHide: true,
      message: 'Air Shipment successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + AIR_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Air Shipment'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
      createLabel={'Shipment'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AirShipmentOrderEdit
        orderId={orderId}
        onAirOrderCreated={onAirOrderCreated}
        onAirOrderUpdated={onAirOrderUpdated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
