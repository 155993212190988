import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { FormikEnumSelect, FormikEnumSelectProps } from './FormikEnumSelect';
import { SelectPackageTypePaginate } from '../../packageTypes/components/packageType-react-select.component';

export const TariffForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

TariffForm.Enums = ({
  id,
  name,
  enumType,
  onChange,
  placeholder,
  required = true,
  disabled = false,
  header,
  readonly,
}: FormikEnumSelectProps) => {
  return (
    <FormikEnumSelect
      id={id}
      name={name}
      enumType={enumType}
      error={false}
      isSearchable={true}
      isClearable={false}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={onChange}
      tabIndex={5}
    />
  );
};
TariffForm.Minimum = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.minimum'}
      label={'$ Rate Minimum'}
      placeholder={'Minimum'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};
TariffForm.Maximum = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.maximum'}
      label={'$ Rate Maximum'}
      placeholder={'Maximum'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};
TariffForm.MinimumWeight = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.minimumWeight'}
      label={'Minimum Weight'}
      placeholder={'Minimum Weight'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};
TariffForm.MaximumWeight = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.maximumWeight'}
      label={'Maximum Weight'}
      placeholder={'Maximum Weight'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};
TariffForm.MinimumChargeableWeight = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.minimumChargeableWeight'}
      label={'Minimum Chargeable Weight'}
      placeholder={'Minimum Chargeable Weight'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};
TariffForm.Apply = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.rateMultiplier'}
      label={'Apply %'}
      placeholder={'Apply'}
      required={false}
      type={'input-OnChange-number-with-value'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
      valueInput={defaultValue}
    />
  );
};
TariffForm.RatePer = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.ratePer'}
      placeholder={'Rate Per'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};

TariffForm.BaseCharge = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      minNumber={0}
      name={'tariff.baseCharge'}
      label={'$ Base Charge'}
      placeholder={'Base Charge'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};

TariffForm.Rates = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
  defaultValue,
  index,
}: InputPublicProps) => {
  return (
    <>
      <Input
        minNumber={0}
        name={`tariff.rateData[${index}].rateIndex`}
        valueInput={defaultValue}
        required={false}
        type={'input-OnChange-number-index-with-value'}
        className={className}
        style={style}
        disabled={disabled || index === 0}
        size={size}
        onChange={onChange}
        readonly={readonly}
        index={index}
      />
      <Input
        minNumber={0}
        name={`tariff.rateData[${index}].rateValue`}
        required={false}
        type={'number'}
        className={className}
        style={style}
        disabled={disabled}
        size={size}
        onChange={onChange}
        readonly={readonly}
      />
    </>
  );
};

TariffForm.ContainerTypeSelect = ({
  selectedFilter = `PackageCategory.Name: Container`,
  placeholder = 'Select Container Type',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'packageTypeId',
  onChange = () => {},
  className,
  style,
  size,
  disabled,
  readonly,
  index,
}: InputPublicProps) => {
  return (
    <>
      <div className={'col'}>
        <SelectPackageTypePaginate
          id={id}
          required={required}
          isMulti={false}
          useContext={true}
          closeMenuOnSelect={true}
          isSearchable={true}
          selectedSort={sort}
          placeholder={placeholder}
          selectedValue={defaultValue}
          selectedFilter={selectedFilter}
          onChangePackageType={onChange}
          index={index}
        />
      </div>
      <Input
        minNumber={0}
        name={`tariff.rateData[${index}].rateValue`}
        required={false}
        type={'number'}
        className={className}
        style={style}
        disabled={disabled}
        size={size}
        onChange={onChange}
        readonly={readonly}
      />
    </>
  );
};
