import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  SalesTaxDto,
  SalesTaxDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  salesTaxStore,
  getSalesTaxesColumnsFx,
  getSalesTaxesFx,
  deleteSalesTaxFx,
  exportSalesTaxesFx,
  importSalesTaxesFx,
} from '../salesTaxes.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_SALESTAX_LINK_KEY,
  DELETE_SALESTAX_LINK_KEY,
  IMPORT_SALESTAXES_LINK_KEY,
  EXPORT_SALESTAXES_LINK_KEY,
  GetSalesTaxParams,
} from '../salesTaxes.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { SalesTaxDialog } from './salesTax.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type SalesTaxesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (salesTaxParams: GetSalesTaxParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (salesTax: SalesTaxDto, salesTaxParams: GetSalesTaxParams) => void;
};

export const SalesTaxesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: SalesTaxesListProps) => {
  const [salesTaxes, setSalesTaxes] = useState<SalesTaxDtoPagedResult | null>(
    null,
  );

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    salesTaxColumns: columns,
    defaultSalesTaxColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(salesTaxStore);

  useEffect(() => {
    getSalesTaxesColumnsFx();
  }, []);

  const getSalesTaxes = useCallback(async () => {
    const salesTaxesData = await getSalesTaxesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(salesTaxesData));
    setSalesTaxes(salesTaxesData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: SalesTaxDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_SALESTAXES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importSalesTaxesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getSalesTaxes();
          },
          result?.links,
        ),
        getAction(
          EXPORT_SALESTAXES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportSalesTaxesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getSalesTaxes],
  );

  useEffect(() => {
    getSalesTaxes();
  }, [getSalesTaxes]);

  const onDeleteSalesTax = useCallback(
    async (salesTax: SalesTaxDto) => {
      if (userHas(DELETE_SALESTAX_LINK_KEY, salesTaxes?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Sales tax`,
            message: 'Are you sure you want to delete sales tax?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteSalesTaxFx(salesTax);
            getSalesTaxes();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, salesTaxes],
  );

  const onEditSalesTax = (salesTax: SalesTaxDto) => {
    if (salesTax) goToDetails({ salesTaxId: salesTax.salesTaxId });
  };

  const onSalesTaxSelect = useCallback(
    async (salesTax) => {
      const selectedSalesTax = await showDialog({
        dialog: SalesTaxDialog,
        props: {
          salesTaxId: salesTax.salesTaxId,
          title: 'Update Sales tax',
        },
      });
      if (selectedSalesTax) {
        getSalesTaxes();
      }
    },
    [getSalesTaxes],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['salesTaxId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={salesTaxes.items}
          onLimitChanged={onLimitChanged}
          total={salesTaxes.totalCount}
          onDelete={onDeleteSalesTax}
          onSort={onSort}
          onEdit={onEditSalesTax}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onSalesTaxSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {salesTaxes?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Sales tax"
            entityLinkKey={CREATE_SALESTAX_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
