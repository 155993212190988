import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundScreen } from './screens/not-found.screen';

export const NOT_FOUND_PATH = '/404';

export const NotFoundRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<NotFoundScreen pageTitle="Not Found" />} />
    </Routes>
  );
};
