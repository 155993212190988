import React, { useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetOrdersQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import {
  AIR_ORDER_CREATE_PATH,
  AIR_ORDER_LIST_PATH,
} from '../airShipmentOrders.route';
import { AirShipmentOrdersList } from '../components/airShipmentOrder-list.component';
import {
  CONSOLIDATED_SHIPMENTS_PATH,
  CONSOLIDATED_SHIPMENT_EDIT_PATH,
} from '../../consolidatedShipments/consolidatedShipments.route';
import {
  airShipmentOrdersStore,
  getAirOrdersDefaultColumns,
} from '../airShipmentOrders.store';
import { useStore } from 'effector-react';

export type AirShipmentOrdersListScreenProps = {
  pageTitle: string;
  group: string;
};

const generateHistoryPath = (
  organizationId: string,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    view: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + AIR_ORDER_LIST_PATH, {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

export const AirShipmentOrderListScreen = ({
  pageTitle,
  group,
}: AirShipmentOrdersListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(airShipmentOrdersStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `orderType:${OrderTypes.AirShipmentOrder}`,
    search = '',
    view = '',
  } = useQuery() as GetOrdersQuery;
  const navigate = useNavigate();

  const goToDetails = (orderId: number, orderType?: OrderTypes) => {
    const path = generatePath(
      CONSOLIDATED_SHIPMENTS_PATH + CONSOLIDATED_SHIPMENT_EDIT_PATH,
      {
        organizationId,
        orderType,
        orderId,
      },
    );
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const params = { offset, limit, sort, filter, search, view };
  const onQueryChange = (newParams: Partial<typeof params>) => {
    const mergedParams = { ...params, ...newParams };
    const newPath = generateHistoryPath(organizationId, mergedParams);
    navigate(newPath);
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + AIR_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getAirOrdersDefaultColumns();
  };

  return (
    <InternalLayout
      group={group}
      title={'Air Shipments'}
      createPath={null}
      createAction={null}
      createLabel={'Shipment'}
    >
      <AirShipmentOrdersList
        filter={filter}
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onViewChanged={onViewChanged}
        onPageChanged={(page) => onQueryChange({ offset: page * limit })}
        onLimitChanged={(limit) => onQueryChange({ limit })}
        onSort={(sort) => onQueryChange({ sort })}
        onFilter={(filter) => onQueryChange({ filter, offset: 0 })}
        onSearch={(search) => onQueryChange({ search, offset: 0 })}
      />
    </InternalLayout>
  );
};
