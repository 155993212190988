import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  ORGANIZATION_CONFIG_CREATE_PATH,
  ORGANIZATION_CONFIGS_LIST_PATH,
} from '../organizationConfigs.route';
import { OrganizationConfigEdit } from '../components/organizationConfig-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { GET_ORGANIZATIONCONFIG_LINK_KEY } from '../organizationConfigs.service';
import { userHas } from '../../auth/auth.store';

export type OrganizationConfigScreenProps = {
  pageTitle: string;
  group: string;
};

export const OrganizationConfigScreen = ({
  pageTitle,
  group,
}: OrganizationConfigScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, organizationConfigId } = useParams();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + ORGANIZATION_CONFIG_CREATE_PATH, {
      organizationId,
    });
  };

  const onOrganizationConfigCreated = () => {
    const path = generatePath(INTERNAL_PATH + ORGANIZATION_CONFIGS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'organization-config-created',
      type: 'success',
      autoHide: true,
      message: 'Organization Config successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onOrganizationConfigUpdated = () => {
    const path = generatePath(INTERNAL_PATH + ORGANIZATION_CONFIGS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'organization-config-updated',
      type: 'success',
      autoHide: true,
      message: 'Organization Config successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + ORGANIZATION_CONFIGS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Organization Config'}
      createPath={
        userHas(GET_ORGANIZATIONCONFIG_LINK_KEY) ? createPath() : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrganizationConfigEdit
        organizationConfigId={organizationConfigId}
        onOrganizationConfigUpdated={onOrganizationConfigUpdated}
        onOrganizationConfigCreated={onOrganizationConfigCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
