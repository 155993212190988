import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';

import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { YamlField } from '../../common/components/input/ace-input.component';

export const WorkflowForm = ({
  id = 'workflowForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

WorkflowForm.IsActive = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      id={'isActive'}
      name={'isActive'}
      label={'Is Active'}
      placeholder={'Is Active'}
      required={true}
      type={'checkbox'}
      defaultValue={defaultValue}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};

WorkflowForm.WorkflowYamlDocument = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <YamlField
      name="workflowYamlDocument"
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
