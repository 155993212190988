import { TrackingDto, LinkDto } from '../../models/data.models';
import { execLink } from '../api/api.service';
import { API_ENDPOINT } from '../../properties';

export const GET_TRACKING_LINK_KEY = 'get-tracking';

export interface GetTrackingParams {
  organizationId: string;
  orderSelector: string;
}

export const getTrackingInfoRequest = async ({
  organizationId,
  orderSelector,
}: GetTrackingParams): Promise<TrackingDto> => {
  if (organizationId && orderSelector) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/tracking?orderSelector=${orderSelector}`;
    const getTrackingLink: LinkDto = {
      href: url,
      templated: false,
      type: 'get',
    };
    const result = await execLink(getTrackingLink);
    return result.data;
  }
  return Promise.reject();
};
