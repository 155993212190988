import React, { useEffect, useState } from 'react';
import {
  CommodityDto,
  EntityFieldDto,
  OrderTypes,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { CommodityGrid } from '../../common/components/grid/commodity-grid.component';
import {
  commodityStoreForConsolidatedShipment,
  updateCommoditiesForOrderColumns,
} from '../../commodities/commodities.store';
import { GetCommodityParams } from '../../commodities/commodities.service';
import { ConsolidatedShipmentCommodityDto } from '../../../models/custom.models';

export type CommoditiesForOrderListProps = {
  goToDetails?: (commodityParams: GetCommodityParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  items?: ConsolidatedShipmentCommodityDto[];
  showPagination?: boolean;
  className?: string;
  changeItems?: (index: number) => void;
  onSelect?: (
    commodity: CommodityDto,
    commodityParams: GetCommodityParams,
  ) => void;
  name?: string;
  isDeleteable?: boolean;
  orderType?: OrderTypes | null | undefined;
  isLoading?: boolean;
  columns: EntityFieldDto[];
  goToParcelDetails?: (commodity: CommodityDto) => void;
};

export const CommoditiesForCargoMovementList = ({
  name = 'commodities',
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = 'commodityId',
  showPagination = true,
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  items,
  changeItems,
  className = '',
  isDeleteable = true,
  orderType = OrderTypes.CargoMovement,
  isLoading,
  columns,
  goToParcelDetails = () => {},
}: CommoditiesForOrderListProps) => {
  const [commodityItems, setCommodityItems] = useState<
    ConsolidatedShipmentCommodityDto[]
  >([]);

  useEffect(() => {
    if (items && orderType != OrderTypes.CargoMovement) {
      items.forEach((item) => {
        if (item.customValues['pallet']) {
          item.commodityTrackingNumber = item.customValues['pallet'].toString();
        }
        const parcelTrackingNumber = item?.shipments?.find(
          (x) => x.orderType === OrderTypes.ParcelShipment,
        )?.trackingNumber;
        item.parcelTrackingNumber = parcelTrackingNumber;
      });
    }
    setCommodityItems(items);
  }, [items]);

  const onDeleteCommodity = (commodity: CommodityDto) => {
    const index = items.findIndex(
      (el) => commodity.commodityId === el.commodityId,
    );
    if (changeItems) changeItems(index);
  };

  const onEditCommodity = (commodity: CommodityDto) => {
    goToDetails({ commodity, commodityId: commodity?.commodityId });
  };

  return (
    <CommodityGrid
      name={name}
      className={className}
      showAllStore={true}
      showEmptyTable={true}
      rowKeys={['commodityId']}
      data={commodityItems}
      columns={columns}
      offset={offset}
      limit={limit}
      total={items?.length}
      sort={sort}
      showPagination={showPagination}
      onDelete={isDeleteable ? onDeleteCommodity : null}
      onSort={onSort}
      onEdit={onEditCommodity}
      onPageChanged={onPageChanged}
      onColumnsChanged={updateCommoditiesForOrderColumns}
      onSelect={(commodity, commodityId) =>
        goToDetails({ commodity, commodityId })
      }
      hideColumnsSelect={true}
      showSortColumns={false}
      parentOrderType={orderType}
      isLoading={isLoading}
      goToParcelDetails={goToParcelDetails}
    />
  );
};
