import { Dialog } from '../../common/components/dialog/dialog.component';
import { ContactStatusEdit } from './contactStatus-edit.component';
import { ContactStatusDto } from '../../../models/data.models';

export type ContactStatusDialogProperties = {
  statusId?: number | null;
  title: string;
};

export class ContactStatusDialog extends Dialog<ContactStatusDialogProperties> {
  constructor(props: ContactStatusDialogProperties) {
    super(props);
    this.onContactStatusSaved = this.onContactStatusSaved.bind(this);
  }

  onContactStatusSaved = (contactStatus: ContactStatusDto) => {
    this.close(contactStatus);
  };

  renderContent(): any {
    const { statusId } = this.props as ContactStatusDialogProperties;
    return (
      <ContactStatusEdit
        statusId={statusId}
        onContactStatusCreated={this.onContactStatusSaved}
        onContactStatusUpdated={this.onContactStatusSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
