import { useEffect } from 'react';

export const GOOGLE_PLACES_SCRIPT =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyCOq0W4DA8u3AvgtGgMLqGiWvF8c1MDakA&libraries=places';

export const GOOGLE_MAPS_SCRIPT =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyDxYtyuWkst0uDfs3EnzfFJ6On1rRDivIY&v=3.exp&libraries=geometry,drawing,places';

const useJsScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useJsScript;
