import React, { useEffect, useState } from 'react';
import { ChargeGrid } from '../../../common/components/grid/charge-grid.component';
import { useStore } from 'effector-react';
import {
  chargeStoreForServices,
  getChargeFx,
} from '../../../charges/charges.store';
import { ChargeDto, ChargeStatuses } from '../../../../models/data.models';
import { showDialog } from '../../../common/dialog.store';
import { sortFn } from '../../../../utils/query.utils';
import { ChargeDialog } from '../../../charges/components/charge.dialog';
import { authStore } from '../../../auth/auth.store';
import { useFormikContext } from 'formik';

export const ServicesTable = ({
  services,
  isCompleteAllServices,
  setIsCompleteAllServices,
}) => {
  const { chargeColumns: columns } = useStore(chargeStoreForServices);
  const { user: currentUser } = authStore.getState();

  const [limit, setLimit] = useState(1000);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [charges, setCharges] = useState(services);

  const [sortedCharges, setSortedCharges] = useState<ChargeDto[]>([]);

  const formikContext = useFormikContext<any>();

  useEffect(() => {
    if (charges) {
      setIsCompleteAllServices(
        charges.every((s) => s.chargeStatus != ChargeStatuses.Pending),
      );
    }
  }, [charges]);

  const onChargeSelect = async (charge, chargeId) => {
    if (chargeId) {
      const chargeToUpdate = await getChargeFx({
        chargeId: charge?.chargeId,
      });

      showDialog({
        dialog: ChargeDialog,
        props: {
          charge: chargeToUpdate,
          chargeId: chargeToUpdate.chargeId,
          title: 'Update Charge',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          charges: charges,
        },
      }).then((charge) => {
        if (charge !== null) {
          const i = charges.findIndex((x) => x?.chargeId == charge?.chargeId);
          const newCharges = charges;
          newCharges[i] = charge;
          setCharges(newCharges);
          sortCharges();
        }
      });
    }
  };

  const onLimitChange = (limit: number) => {
    setLimit(limit);
    setOffset(0);
  };

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const onEdit = (charge: ChargeDto) => {
    const i = charges.findIndex((x) => x?.chargeId == charge?.chargeId);
    const newCharges = charges;
    newCharges[i] = charge;
    setCharges(newCharges);
    setIsCompleteAllServices(
      newCharges.every((s) => s.chargeStatus != ChargeStatuses.Pending),
    );
    formikContext.setFieldValue('parcelShipment.charges', newCharges);
    sortCharges();
  };

  const sortCharges = () => {
    const sorted = charges && [...charges]?.sort((a, b) => sortFn(a, b, sort));
    const sliced = sorted?.slice(offset, offset + limit);
    setSortedCharges(sliced);
  };

  useEffect(() => {
    sortCharges();
  }, [limit, offset, sort, charges]);

  return (
    <ChargeGrid
      className="commodities-for-order-list"
      showAllStore={false}
      rowKeys={['chargeId', 'chargeStatus', 'description', 'completion']}
      showEmptyTable={true}
      data={sortedCharges}
      columns={columns}
      offset={offset}
      limit={limit}
      total={charges?.length}
      sort={sort}
      showPagination={false}
      showDropdown={false}
      onDelete={null}
      onSort={setSort}
      onEdit={onEdit}
      onOpenInvoice={null}
      onPageChanged={onPageChanged}
      onColumnsChanged={null}
      onSelect={onChargeSelect}
      hideColumnsSelect={true}
      onLimitChange={onLimitChange}
      allData={charges}
      isCompleteAllCharges={isCompleteAllServices}
      setIsCompleteAllCharges={setIsCompleteAllServices}
    />
  );
};
