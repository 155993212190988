import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { QuoteScreen } from './screen/quote.screen';
import { QuotesListScreen } from './screen/quotes-list.screen';

export const QUOTE_LIST_PATH = '/quotes';
export const QUOTE_EDIT_PATH = '/quotes/:quoteId';
export const QUOTE_CREATE_PATH = '/quotes/0';

export const QuoteRoute = (props: RouteProps) => {
  const group = 'Sales';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <QuotesListScreen pageTitle="Quotes" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <QuoteScreen pageTitle="Add New Quote" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':quoteId'}
        element={
          <PrivateRoute>
            <QuoteScreen pageTitle="Update Quote" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
