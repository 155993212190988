import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { WarehouseLocationsListScreen } from './screens/warehouseLocation-list.screen';
import { WarehouseLocationScreen } from './screens/warehouseLocation.screen';

export const WAREHOUSE_LOCATIONS_LIST_PATH = '/warehouseLocations';
export const WAREHOUSE_LOCATIONS_EDIT_PATH =
  '/warehouseLocations/:warehouseLocationId';
export const WAREHOUSE_LOCATIONS_CREATE_PATH = '/warehouseLocations/0';

export const WarehouseLocationRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <WarehouseLocationsListScreen
              pageTitle="Warehouse Locations"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <WarehouseLocationScreen
              pageTitle="Add New Warehouse Location"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':warehouseLocationId'}
        element={
          <PrivateRoute>
            <WarehouseLocationScreen
              pageTitle="Update Warehouse Location"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
