import { useCallback, useEffect, useState } from 'react';
import { localized, parseTemplate } from '../component-hooks';
import { ComponentProps } from '../layout-interfaces';

export const TextComponent = (props: ComponentProps) => {
  const { value, type, options } = props.props;
  const variant = type ?? 'span';
  const [text, setText] = useState(null as string | null);

  const onClick = useCallback(
    (e) => {
      if (props?.props?.stopPropagation) e.stopPropagation();
      if (props?.props?.onClick && props.context?.action) {
        props.context?.action(props.props.onClick, props.variables, {
          sender: props.name,
          actions: props.actions,
        });
      }
    },
    [props],
  );

  // parseTemplate for text
  useEffect(() => {
    if (value) {
      setText(
        localized(
          parseTemplate(value, {
            ...props.variables,
            ...props.context?.store,
          }),
        ),
      );
    }
  }, [value, props.variables, props.context?.store]);

  // Define a mapping between variant and corresponding HTML element
  const VariantTag: any = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'p',
    block: 'div', // Assuming you intended 'block' to be a div tag
    span: 'span', // Default case
  };

  // Get the correct tag name or default to 'span'
  const TagName = VariantTag[variant] || VariantTag.span;

  return (
    <TagName {...options} onClick={onClick}>
      {text}
    </TagName>
  );
};
