import React from 'react';
import { useStore } from 'effector-react';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, Link } from 'react-router-dom';
import { ActionItem } from '../../common/components/action-item/action-item.component';
import { INTERNAL_PATH } from '../../../app.router';
import { GET_DASHBOARD_LINK_KEY } from '../dashboard.service';
import { RiDashboardFill } from 'react-icons/ri';

export const DashboardMenu = ({ className }) => {
  const { currentOrganization } = useStore(organizationsStore);
  return (
    <ActionItem
      context={currentOrganization}
      actionKey={GET_DASHBOARD_LINK_KEY}
      render={({ available }) => {
        if (available) {
          return (
            <li className={className}>
              <Link
                to={generatePath(INTERNAL_PATH, {
                  organizationId: currentOrganization?.organizationId,
                })}
                className="nav-link nav-icon-size-medium"
                data-cy="link-dashboard"
              >
                <div className="menu-icon-wrapper d-flex justify-content-center align-items-center d-lg-none">
                  <RiDashboardFill fontSize={'18px'} />
                </div>
                <div className="nav-link-text">Dashboard</div>
              </Link>
            </li>
          );
        }
        return null;
      }}
    />
  );
};
