import React from 'react';
import { FilteredField } from '../../common/components/filters/filtersTab.component';
import { Button } from '../../common/components/button/button.component';
import { BsFilter } from 'react-icons/bs';

export interface OrderDocumentsFiltersSelectorProps {
  filters: FilteredField[];
  checkedFilters: FilteredField[];
  setCheckedFilters: (filter: any) => void;
}

export const OrderDocumentsFiltersSelector = ({
  filters,
  setCheckedFilters = () => {},
  checkedFilters,
}: OrderDocumentsFiltersSelectorProps) => {
  const handleCheckboxChange = (filter) => {
    if (checkedFilters.some((f) => f.title === filter.title)) {
      setCheckedFilters(checkedFilters.filter((f) => f.title !== filter.title));
    } else {
      setCheckedFilters([...checkedFilters, filter]);
    }
  };

  return (
    <div className={'dropdown dropdown-columns ml-2'}>
      <div
        className=""
        key="dropdownFilterButton"
        data-toggle="dropdown"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <Button
          color={'secondary'}
          outline
          style={{ fontSize: '14px', minHeight: '40px' }}
          className={'d-flex align-items-center'}
        >
          <BsFilter className={'mr-2'} size={20} />
          Filters
        </Button>
      </div>
      <div
        className="dropdown-menu py-3"
        aria-labelledby="dropdownFilterButton"
        style={{ minWidth: '280px', minHeight: '130px' }}
      >
        {filters.map((filter, index) => (
          <a key={index} className="dropdown-item" href="#">
            <div
              key={index}
              className="dropdown-item d-flex justify-content-between"
            >
              <label htmlFor={`filterCheckbox${index}`}>{filter.title}</label>
              <input
                type="checkbox"
                id={`filterCheckbox${index}`}
                className="pr-3"
                onClick={() => handleCheckboxChange(filter)}
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};
