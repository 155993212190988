import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PaymentTermForm } from './paymentTerm.form';
import { PaymentTermDto } from '../../../models/data.models';
import {
  createPaymentTermFx,
  getPaymentTermFx,
  updatePaymentTermFx,
} from '../paymentTerms.store';
import { Panel } from '../../common/components/panel/panel.component';
import { PaymentTermDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { userHas } from '../../auth/auth.store';
import { UPDATE_PAYMENTTERM_LINK_KEY } from '../paymentTerms.service';
import { validateNumberInput } from '../../../utils/helper.utils';

export type PaymentTermEditProps = {
  paymentTermId?: number | null;
  onPaymentTermCreated?: (paymentTerm: PaymentTermDto) => void;
  onPaymentTermUpdated?: (paymentTerm: PaymentTermDto) => void;
  onPaymentTermLoaded?: (paymentTerm: PaymentTermDto) => void;
  onCancel?: () => void;
};

const initialState: PaymentTermDto = {
  paymentTermId: 0,
  description: PaymentTermDefaultValues.description,
  discountPaidWithinDays: PaymentTermDefaultValues.discountPaidWithinDays,
  discountPercentage: PaymentTermDefaultValues.discountPercentage,
  isInactive: PaymentTermDefaultValues.isInactive,
  netDueDays: PaymentTermDefaultValues.netDueDays,
  organizationId: 0,
  links: [],
};

const paymentTermsSchema = Yup.object().shape({
  description: Yup.string().required("Can't be blank").nullable(true),
  netDueDays: Yup.string()
    .required("Can't be blank")
    .max(10, 'Max length is 10')
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) ||
        value === undefined ||
        value === ''
      );
    })
    .test('positive', "Value can't be less than zero", (value) => {
      return Number(value) >= 0;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  discountPercentage: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) ||
        value === undefined ||
        value === ''
      );
    })
    .test('percent', 'Value should be between 0 and 100', (value) => {
      return (
        (Number(value) >= 0 && Number(value) <= 100) || value === undefined
      );
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  discountPaidWithinDays: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) ||
        value === undefined ||
        value === ''
      );
    })
    .test('percent', 'Value should be between 0 and 100', (value) => {
      return (
        (Number(value) >= 0 && Number(value) <= 100) || value === undefined
      );
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
});

export const PaymentTermEdit = ({
  paymentTermId,
  onPaymentTermLoaded = () => {},
  onPaymentTermCreated = () => {},
  onPaymentTermUpdated = () => {},
  onCancel = () => {},
}: PaymentTermEditProps) => {
  const isCreateMode = !paymentTermId || paymentTermId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentTerm, setPaymentTerm] = useState<PaymentTermDto | null>(null);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (paymentTermId) {
      getPaymentTermFx({ paymentTermId }).then(
        (paymentTermDto: PaymentTermDto) => {
          setPaymentTerm(paymentTermDto);
          setIsLoading(false);
          onPaymentTermLoaded(paymentTermDto);
        },
      );
    } else {
      throw new Error('PaymentTerm keys were not provided');
    }
  }, [paymentTermId]);

  const onSubmit = (data: PaymentTermDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createPaymentTermFx(data)
        .then((result) => {
          onPaymentTermCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updatePaymentTermFx(data)
        .then((result) => {
          onPaymentTermUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="paymentTerm">Payment Term</Tab>
      </TabList>
      <PaymentTermForm
        initialValues={paymentTerm || initialState}
        onSubmit={onSubmit}
        validationSchema={paymentTermsSchema}
        id="paymentTermsForm"
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Payment Term</h2>
            ) : (
              <h2>Update Payment Term</h2>
            )}
            <div className="row">
              <div className="col-4">
                <PaymentTermForm.Description />
                <PaymentTermForm.NetDueDays onKeyDown={validateNumberInput} />
                <PaymentTermForm.DiscountPercentage
                  onKeyDown={validateNumberInput}
                />
                <PaymentTermForm.DiscountPaidWithinDays
                  onKeyDown={validateNumberInput}
                />
                <PaymentTermForm.IsInactive
                  defaultValue={paymentTerm?.isInactive ?? false}
                />
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(userHas(UPDATE_PAYMENTTERM_LINK_KEY, paymentTerm?.links) ||
                    isCreateMode) && (
                    <Button
                      name="save-paymentTerm"
                      type="submit"
                      color="primary"
                      className="btn-block mr-3"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Payment Term
                    </Button>
                  )}
                  <Button
                    type="button"
                    color="secondary"
                    onClick={onCancel}
                    className=""
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </PaymentTermForm>
    </Tabs>
  );
};
