import React, { useEffect, useState } from 'react';
import { ComponentRender } from './component-render';
import { ComponentDefinitionProps } from './layout-interfaces';
import { parseTemplate } from './component-hooks';

export const MainLayoutComponent = ({
  layout,
  context,
  props,
  variables,
}: ComponentDefinitionProps) => {
  const [componentVariables, setComponentVariables] = useState(variables);

  // parse variables from component props
  useEffect(() => {
    if (props?.variables) {
      setComponentVariables({
        ...variables,
        ...parseTemplate(props?.variables, variables),
      });
    } else {
      setComponentVariables(variables);
    }
  }, [variables, props]);

  return (
    <ComponentRender
      context={context}
      props={props}
      {...layout}
      variables={componentVariables}
    />
  );
};
