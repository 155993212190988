import {
  DiscountDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateDiscountCommandValues,
  UpdateDiscountCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createDiscountRequest,
  getDiscountsListRequest,
  getDiscountRequest,
  deleteDiscountRequest,
  updateDiscountRequest,
  importDiscountsRequest,
  exportDiscountsRequest,
  GetDiscountParams,
} from './discounts.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type DiscountsStoreState = {
  links: LinkDto[];
  discountColumns: EntityFieldDto[];
  defaultDiscountColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getDiscountsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Discount',
  });
});

addEffectStatusHandling(getDiscountsColumnsFx);

export const getDiscountsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Discount',
  });
});

addEffectStatusHandling(getDiscountsDefaultColumnsFx);

export const updateDiscountsColumns = createEvent<EntityFieldDto[]>();

export const getDiscountsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getDiscountsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getDiscountsFx);

export const createDiscountFx = createEffect((discountData: DiscountDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createDiscountCommand: CreateDiscountCommandValues = {
    ...discountData,
  };

  return createDiscountRequest(currentOrganization!, createDiscountCommand);
});

addEffectStatusHandling(createDiscountFx, {
  completeMessage: 'Discount was created successfully',
  errorMessage: 'Discount was not created',
});

export const getDiscountFx = createEffect(
  (discountParams: GetDiscountParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getDiscountRequest(
      currentOrganization as LinkResourceBaseDto,
      discountParams,
    );
  },
);

addEffectStatusHandling(getDiscountFx);

export const updateDiscountFx = createEffect((discount: DiscountDto) => {
  const updateDiscountCommand: UpdateDiscountCommandValues = { ...discount };
  return updateDiscountRequest(discount, updateDiscountCommand);
});

addEffectStatusHandling(updateDiscountFx, {
  completeMessage: 'Discount was updated successfully',
  errorMessage: 'Discount was not updated',
});

export const deleteDiscountFx = createEffect((discount: DiscountDto) => {
  return deleteDiscountRequest(discount);
});

addEffectStatusHandling(deleteDiscountFx, {
  completeMessage: 'Discount was deleted successfully',
  errorMessage: 'Discount was not deleted',
});

export const exportDiscountsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportDiscountsRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportDiscountsFx, {
  completeMessage: 'Discounts were exported successfully',
  errorMessage: 'Discounts were not exported',
});

export const importDiscountsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importDiscountsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importDiscountsFx, {
  completeMessage: 'Discounts were imported successfully',
  errorMessage: 'Discounts were not imported',
  inFlightMessage: 'Importing discounts...',
});

const defaultState: DiscountsStoreState = {
  links: [],
  discountColumns: [],
  defaultDiscountColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const discountStore: Store<DiscountsStoreState> = createStore(
  defaultState,
)
  .on(getDiscountsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      discountColumns: payload.result.items,
      defaultDiscountColumns: payload.result.items,
    };
  })
  .on(getDiscountsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultDiscountColumns: payload.result.items,
    };
  })
  .on(updateDiscountsColumns, (state, payload) => {
    return { ...state, discountColumns: payload };
  });
