import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CitiesListScreen } from './screens/cities-list.screen';
import { CityScreen } from './screens/city.screen';
import React from 'react';

export const CITIES_LIST_PATH = '/cities';
export const CITY_EDIT_PATH = '/cities/:cityId';
export const CITY_CREATE_PATH = '/cities/0';

export const CitiesRoute = () => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CitiesListScreen pageTitle="Cities" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CityScreen pageTitle="Add New City" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':cityId'}
        element={
          <PrivateRoute>
            <CityScreen pageTitle="Update City" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
