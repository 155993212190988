import {
  AccountingAccountDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  AccountingAccountDtoPagedResult,
  CreateAccountingAccountCommandValues,
  UpdateAccountingAccountCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_ACCOUNTINGACCOUNTS_LINK_KEY = 'get-accountingaccounts';
export const CREATE_ACCOUNTINGACCOUNT_LINK_KEY = 'create-accountingaccount';
export const GET_ACCOUNTINGACCOUNT_LINK_KEY = 'get-accountingaccount';
export const UPDATE_ACCOUNTINGACCOUNT_LINK_KEY = 'update-accountingaccount';
export const DELETE_ACCOUNTINGACCOUNT_LINK_KEY = 'delete-accountingaccount';
export const IMPORT_ACCOUNTINGACCOUNTS_LINK_KEY = 'import-accountingaccounts';
export const EXPORT_ACCOUNTINGACCOUNTS_LINK_KEY = 'export-accountingaccounts';
export const ACCOUNTINGACCOUNT_ENTITY_NAME = 'Accounting Account';

export interface GetAccountingAccountParams {
  accountId: number;
}

export interface ImportAccountingAccountsParams {
  organizationId: number;
}

export interface ExportAccountingAccountsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getAccountingAccountRequest = async (
  resource: LinkResourceBaseDto | null,
  { accountId }: GetAccountingAccountParams,
): Promise<AccountingAccountDto> => {
  if (resource && accountId) {
    const getAccountingAccountLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGACCOUNT_LINK_KEY,
    );
    if (getAccountingAccountLink) {
      const result = await execLink(getAccountingAccountLink, { accountId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getAccountingAccountsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<AccountingAccountDtoPagedResult> => {
  if (resource) {
    const getAccountingAccountsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGACCOUNTS_LINK_KEY,
    );
    if (getAccountingAccountsListLink) {
      const result = await execLink(getAccountingAccountsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACCOUNTINGACCOUNTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createAccountingAccountRequest = async (
  resource: LinkResourceBaseDto,
  accountingAccount: CreateAccountingAccountCommandValues,
) => {
  const createAccountingAccountLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ACCOUNTINGACCOUNT_LINK_KEY,
  );
  if (createAccountingAccountLink) {
    const result = await execLink(
      createAccountingAccountLink,
      accountingAccount,
    );
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateAccountingAccountRequest = async (
  resource: LinkResourceBaseDto,
  accountingAccount: UpdateAccountingAccountCommandValues,
) => {
  const updateAccountingAccountLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ACCOUNTINGACCOUNT_LINK_KEY,
  );
  if (updateAccountingAccountLink) {
    const result = await execLink(
      updateAccountingAccountLink,
      accountingAccount,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteAccountingAccountRequest = async (
  accountingAccount: AccountingAccountDto,
) => {
  const deleteAccountingAccountLink = accountingAccount.links?.find(
    (x: LinkDto) => x.rel === DELETE_ACCOUNTINGACCOUNT_LINK_KEY,
  );
  if (deleteAccountingAccountLink) {
    const result = await execLink(
      deleteAccountingAccountLink,
      accountingAccount,
    );
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importAccountingAccountsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportAccountingAccountsParams,
  file: File,
) => {
  const importAccountingAccountsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_ACCOUNTINGACCOUNTS_LINK_KEY,
  );
  if (importAccountingAccountsLink) {
    const result = await postFormData(importAccountingAccountsLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_ACCOUNTINGACCOUNTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportAccountingAccountsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportAccountingAccountsParams,
) => {
  const exportAccountingAccountsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_ACCOUNTINGACCOUNTS_LINK_KEY,
  );
  if (exportAccountingAccountsLink) {
    const result = await downloadLinkFile(exportAccountingAccountsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_ACCOUNTINGACCOUNTS_LINK_KEY} is not allowed or missing link action`,
  );
};
