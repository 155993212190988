import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { CountryDto, CountryDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  countryStore,
  getCountriesColumnsFx,
  getCountriesFx,
  deleteCountryFx,
  exportCountriesFx,
  importCountriesFx,
} from '../countries.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_COUNTRY_LINK_KEY,
  DELETE_COUNTRY_LINK_KEY,
  IMPORT_COUNTRIES_LINK_KEY,
  EXPORT_COUNTRIES_LINK_KEY,
  GetCountryParams,
} from '../countries.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { CountryDialog } from './country.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type CountriesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (countryParams: GetCountryParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (country: CountryDto, countryParams: GetCountryParams) => void;
};

export const CountriesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: CountriesListProps) => {
  const [countries, setCountries] = useState<CountryDtoPagedResult | null>(
    null,
  );

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    countryColumns: columns,
    defaultCountryColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(countryStore);

  useEffect(() => {
    getCountriesColumnsFx();
  }, []);

  const getCountries = useCallback(async () => {
    const countriesData = await getCountriesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(countriesData));
    setCountries(countriesData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: CountryDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_COUNTRIES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importCountriesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getCountries();
          },
          result?.links,
        ),
        getAction(
          EXPORT_COUNTRIES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportCountriesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getCountries],
  );

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const onDeleteCountry = useCallback(
    async (country: CountryDto) => {
      if (userHas(DELETE_COUNTRY_LINK_KEY, countries?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Country`,
            message: 'Are you sure you want to delete country?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteCountryFx(country);
            getCountries();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, countries],
  );

  const onEditCountry = (country: CountryDto) => {
    if (country) goToDetails({ countryCode: country.countryCode });
  };

  const onCountrySelect = useCallback(
    async (country) => {
      const selectedCountry = await showDialog({
        dialog: CountryDialog,
        props: {
          countryCode: country.countryCode,
          title: 'Update Country',
        },
      });
      if (selectedCountry) {
        getCountries();
      }
    },
    [getCountries],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['countryCode']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={countries.items}
          onLimitChanged={onLimitChanged}
          total={countries.totalCount}
          onDelete={onDeleteCountry}
          onSort={onSort}
          onEdit={onEditCountry}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onCountrySelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {countries?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Country"
            entityLinkKey={CREATE_COUNTRY_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
