import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { userHas } from '../../auth/auth.store';
import {
  WAREHOUSE_LOCATIONS_CREATE_PATH,
  WAREHOUSE_LOCATIONS_LIST_PATH,
} from '../warehouseLocation.route';
import { WarehouseLocationEdit } from '../components/warehouseLocation-edit.component';
import { CREATE_WAREHOUSELOCATION_LINK_KEY } from '../warehouseLocations.service';

export type WarehouseLocationScreenProps = { pageTitle: string; group: string };

export const WarehouseLocationScreen = ({
  pageTitle,
  group,
}: WarehouseLocationScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, warehouseLocationId } = useParams();

  function createPath() {
    return generatePath(INTERNAL_PATH + WAREHOUSE_LOCATIONS_CREATE_PATH, {
      organizationId,
    });
  }
  const onWarehouseLocationCreated = () => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_LOCATIONS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'warehouseLocation-created',
      type: 'success',
      autoHide: true,
      message: 'Warehouse Location successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onWarehouseLocationUpdated = () => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_LOCATIONS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'warehouseLocation-updated',
      type: 'success',
      autoHide: true,
      message: 'Warehouse Location successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_LOCATIONS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Warehouse Location'}
      createPath={
        userHas(CREATE_WAREHOUSELOCATION_LINK_KEY) ? createPath() : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <WarehouseLocationEdit
        warehouseLocationId={warehouseLocationId}
        onWarehouseLocationUpdated={onWarehouseLocationUpdated}
        onWarehouseLocationCreated={onWarehouseLocationCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
