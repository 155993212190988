import { createEffect } from 'effector';
import { executeMutation, queryGraphQl } from './graphql.service';
import { addEffectStatusHandling } from '../common/messages.store';
import { organizationsStore } from '../organization/organization.store';

export const queryGraphQlFx = createEffect(
  (q: { query: string; variables: any }) => {
    const { currentOrganization } = organizationsStore.getState();
    const query = {
      query: q.query,
      variables: {
        ...q.variables,
        organizationId: currentOrganization?.organizationId,
      },
    };
    return queryGraphQl(query);
  },
);

addEffectStatusHandling(queryGraphQlFx, {
  errorMessage: 'Error while executing GraphQL query',
});

export const executeMutationFx = createEffect(
  (q: { mutation: string; variables: any }) => {
    const { currentOrganization } = organizationsStore.getState();

    const mutation = {
      mutation: q.mutation,
      variables: {
        ...q.variables,
        organizationId: currentOrganization?.organizationId,
      },
    };

    return executeMutation(mutation);
  },
);

addEffectStatusHandling(executeMutationFx, {
  errorMessage: 'Error while executing GraphQL mutation',
});
