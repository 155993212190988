import { Dialog } from '../../common/components/dialog/dialog.component';
import { AccountingAccountEdit } from './accountingAccount-edit.component';
import { AccountingAccountDto } from '../../../models/data.models';

export type AccountingAccountDialogProperties = {
  accountId?: number | null;
  title: string;
};

export class AccountingAccountDialog extends Dialog<AccountingAccountDialogProperties> {
  constructor(props: AccountingAccountDialogProperties) {
    super(props);
    this.onAccountingAccountSaved = this.onAccountingAccountSaved.bind(this);
  }

  onAccountingAccountSaved = (accountingAccount: AccountingAccountDto) => {
    this.close(accountingAccount);
  };

  renderContent(): any {
    const { accountId } = this.props as AccountingAccountDialogProperties;
    return (
      <AccountingAccountEdit
        accountId={accountId}
        onAccountingAccountCreated={this.onAccountingAccountSaved}
        onAccountingAccountUpdated={this.onAccountingAccountSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
