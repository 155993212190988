import React, { useCallback, useEffect, useState } from 'react';
import { GridColumnsSetup } from './grid-columns-setup.component';
import { FiSearch } from 'react-icons/fi';
import { GridFiltersSetup } from './grid-filters-setup.component';
import { GridSavedViewsSetup } from './grid-views-setup.component';
import { FormikProps, FormikValues } from 'formik';
import { GridContextValue } from '../../filters/filtersTab.component';
import { ActionsDropdown } from '../../actions/actions.component';
import { useScanner } from '../../../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../../../barcodeScanner/scanner.store';
import { debounce } from 'lodash';

type GridPanelProperties = {
  rowKeys?: string[] | null;
  context: FormikProps<FormikValues>;
  useGridContext?: () => GridContextValue;
};

type PositionValue = 'absolute' | 'relative' | 'fixed';

const searchIconStyle = {
  position: 'absolute' as PositionValue,
  left: '14px',
  top: '50%',
  transform: 'translateY(-50%)',
};

export const GridPanel = ({
  rowKeys = ['id'],
  context,
  useGridContext,
}: GridPanelProperties) => {
  const {
    filters,
    search,
    onSearch,
    actions,
    enableScanner,
  } = useGridContext();

  const [searchTerm, setSearchTerm] = useState(search);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 500),
    [onSearch],
  );

  const updateSearch = useCallback((event: any) => {
    setSearchTerm(event.target.value);
  }, []);

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
    } else {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm, debouncedSearch]);

  useScanner((scannerResult: ScanningResult) => {
    if (scannerResult.data && enableScanner) {
      debouncedSearch(scannerResult.data);
    }
  });

  return (
    <div className="row rounded align-items-center justify-content-start pl-2">
      <GridSavedViewsSetup
        className="mr-1 ml-2 mt-3"
        context={context}
        useGridContext={useGridContext}
      />
      <div className="col-4 mt-3">
        {onSearch ? (
          <div style={{ position: 'relative' }}>
            <input
              type="search"
              className="form-control input-main"
              style={{ paddingLeft: '36px' }}
              placeholder="Search"
              onChange={updateSearch}
              value={searchTerm}
            />
            <div style={searchIconStyle}>
              <FiSearch size={'15px'} color="#667085" />
            </div>
          </div>
        ) : null}
      </div>

      {filters.length > 0 && (
        <GridFiltersSetup
          useGridContext={useGridContext}
          className="mr-1 ml-2 mt-3"
        />
      )}
      <GridColumnsSetup
        className="mr-1 ml-2 mt-3"
        showAllStore={true}
        rowKeys={rowKeys}
        useGridContext={useGridContext}
      />

      <ActionsDropdown className="ml-2 mt-3" actions={actions} />
    </div>
  );
};
