import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  PACKAGE_CATEGORY_CREATE_PATH,
  PACKAGE_CATEGORIES_LIST_PATH,
} from '../packageCategories.route';
import { PackageCategoryEdit } from '../components/packageCategory-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_PACKAGECATEGORY_LINK_KEY } from '../packageCategories.service';
import { userHas } from '../../auth/auth.store';

export type PackageCategoryScreenProps = { pageTitle: string; group: string };

export const PackageCategoryScreen = ({
  pageTitle,
  group,
}: PackageCategoryScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, packageCategoryCode } = useParams();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + PACKAGE_CATEGORY_CREATE_PATH, {
      organizationId,
    });
  };

  const onPackageCategoryCreated = () => {
    const path = generatePath(INTERNAL_PATH + PACKAGE_CATEGORIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'package-category-created',
      type: 'success',
      autoHide: true,
      message: 'Package Category successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onPackageCategoryUpdated = () => {
    const path = generatePath(INTERNAL_PATH + PACKAGE_CATEGORIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'package-category-updated',
      type: 'success',
      autoHide: true,
      message: 'Package Category successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + PACKAGE_CATEGORIES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Package Category'}
      createPath={
        userHas(CREATE_PACKAGECATEGORY_LINK_KEY) ? createPath() : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PackageCategoryEdit
        packageCategoryCode={packageCategoryCode}
        onPackageCategoryUpdated={onPackageCategoryUpdated}
        onPackageCategoryCreated={onPackageCategoryCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
