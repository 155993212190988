import { Dialog } from '../../common/components/dialog/dialog.component';
import { ContainerDescriptionEdit } from './containerDescription-edit.component';
import { ContainerDescriptionDto } from '../../../models/data.models';

export type ContainerDescriptionDialogProperties = {
  containerDescriptionCode?: string | null;
  title: string;
};

export class ContainerDescriptionDialog extends Dialog<ContainerDescriptionDialogProperties> {
  constructor(props: ContainerDescriptionDialogProperties) {
    super(props);
    this.onContainerDescriptionSaved = this.onContainerDescriptionSaved.bind(
      this,
    );
  }

  onContainerDescriptionSaved = (
    containerDescription: ContainerDescriptionDto,
  ) => {
    this.close(containerDescription);
  };

  renderContent(): any {
    const { containerDescriptionCode } = this
      .props as ContainerDescriptionDialogProperties;
    return (
      <ContainerDescriptionEdit
        containerDescriptionCode={containerDescriptionCode}
        onContainerDescriptionCreated={this.onContainerDescriptionSaved}
        onContainerDescriptionUpdated={this.onContainerDescriptionSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
