import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../../common/components/button/button.component';
import { OrderForm } from '../../../orders/components/order.form';
import {
  ChargeDto,
  ChargeType,
  CommodityDto,
  CommodityStatuses,
  ContactDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
  DivisionDto,
  EntityTypes,
  ModeOfTransportationDto,
  OrderDto,
  OrderTypes,
  PaidAs,
  PaymentTermDto,
  TrackingEventDto,
  VisibleTransactions,
} from '../../../../models/data.models';
import { createOrder, patchOrder } from '../../../orders/orders.store';
import { Panel } from '../../../common/components/panel/panel.component';
import { CommodityForm } from '../../../commodities/components/commodity.form';
import { CommoditiesForOrderList } from '../../../commodities/components/commodities-forOrder-list.component';
import {
  FormattedProfitValues,
  OrderEditFormDto,
  OrderEntityEditFormDto,
  ProfitValues,
} from '../../../../models/custom.models';
import { GetCommodityParams } from '../../../commodities/commodities.service';
import { showDialog } from '../../../common/dialog.store';
import { CommodityDialog } from '../../../commodities/components/commodity.dialog';
import { ChargeForOrderList } from '../../../charges/components/charge-forOrder-list.component';
import {
  CREATE_CHARGE_LINK_KEY,
  GetChargeParams,
} from '../../../charges/charges.service';
import { ChargeDialog } from '../../../charges/components/charge.dialog';
import { ChargeForm } from '../../../charges/components/charge.form';
import { ShipperForm } from '../../../orders/components/form-items/shipper-form';
import { FormContext } from '../../../common/components/form/form.component';
import { ConsigneeForm } from '../../../orders/components/form-items/consignee-form';
import { generateValidationSchemaWithCustomFields } from '../../../../utils/helper.utils';
import { getCustomFieldsFx } from '../../../customFields/customFields.store';
import { getDocumentTemplatesFx } from '../../../documentTemplates/documentTemplates.store';
import {
  ChargeDefaultValues,
  CommodityDefaultValues,
  EntityDefaultValues,
  OrderDefaultValues,
  ProfitDefaultValues,
} from '../../../common/DefaultValues';
import * as Yup from 'yup';
import { getFormattedPrice } from '../../../../utils/formatting.utils';
import { currencyStore } from '../../../currencies/currencies.store';
import { getContacts } from '../../../contacts/contacts.store';
import { authStore, userHas } from '../../../auth/auth.store';
import { FormikProps, FormikValues } from 'formik';
import { TrackingEventForOrderList } from '../../../trackingEvents/components/trackingEvents-forOrder-list.component';
import { TrackingEventDialog } from '../../../trackingEvents/components/trackingEvent.dialog';
import { GetTrackingEventParams } from '../../../trackingEvents/trackingEvents.service';
import { PatchOrderParams } from '../../../orders/orders.service';
import {
  getCommodity,
  updateCommodities,
  updateCommodity,
} from '../../../commodities/commodities.store';
import { addMessage } from '../../../common/messages.store';
import { v4 } from 'uuid';
import { AiFillPlusCircle } from 'react-icons/ai';

export type WarehouseReceiptEditProps = {
  commodities: CommodityDto[];
  onWarehouseReceiptCreated?: (warehouseReceipt: OrderDto) => void;
  onCancel?: () => void;
  order?: OrderDto;
};
const getInitialStateOrderPickupEditForm = () => {
  const initialStateOrderPickupEditForm: OrderEntityEditFormDto = {
    created: null,
    createdBy: null,
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderId: 0,
    orderEntityId: 0,
    orderEntitySequence: 0,
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    customValues: { pickDate: null, shipperNotes: '', shipperFilter: '' },
    entityType: EntityTypes.Shipper,
  };
  return initialStateOrderPickupEditForm;
};

const getInitialStateOrderDeliveryEditForm = () => {
  const initialStateOrderDeliveryEditForm: OrderEntityEditFormDto = {
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    created: null,
    createdBy: null,
    entityType: EntityTypes.Consignee,
    customValues: {
      deliveryDate: null,
      deliveryNotes: '',
      consigneeFilter: '',
    },
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderEntityId: 0,
    orderEntitySequence: 0,
    orderId: 0,
  };
  return initialStateOrderDeliveryEditForm;
};
const initialStateCommodity: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  customValues: CommodityDefaultValues.customValues,
};
const initialProfitValues: ProfitValues = {
  expense: ProfitDefaultValues.expense,
  income: ProfitDefaultValues.income,
  profit: ProfitDefaultValues.profit,
};

const getInitialState = () => {
  const initialState: OrderEditFormDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: null,
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntities: [
      getInitialStateOrderPickupEditForm(),
      getInitialStateOrderDeliveryEditForm(),
    ],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    orderStatusId: OrderDefaultValues.orderStatusId,
    warehouseReceiptStatus: OrderDefaultValues.warehouseReceiptStatus,
    links: [],
  };
  return initialState;
};

let warehouseReceiptSchema = Yup.object().shape({
  billToContactId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

const numberSchema = Yup.string()
  .transform((value) => (value === null ? '' : value))
  .test('numberFormat', 'Incorrect number format', (value) => {
    return (
      (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
        value?.toString(),
      ) &&
        Number(value) < Number.MAX_SAFE_INTEGER &&
        Number(value) > Number.MIN_SAFE_INTEGER) ||
      value === '' ||
      value === undefined
    );
  })
  .test('length', 'Max value is 999999', (value) => {
    return value === undefined || Number(value) <= 999999;
  })
  .nullable(true);

const commoditySchema = Yup.object().shape({
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
});

export const WarehouseReceiptForm = ({
  commodities: selectedCommodities,
  onWarehouseReceiptCreated = () => {},
  onCancel = () => {},
  order = null,
}: WarehouseReceiptEditProps) => {
  const { user: currentUser } = authStore.getState();
  const limit = 20;

  const commodityFormRef = useRef<HTMLFormElement>();
  const orderFormRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const forceUpdate: () => void = useState()[1].bind(null, {});

  const [
    warehouseReceiptContextFormValue,
    setWarehouseReceiptContextFormValue,
  ] = useState<OrderEditFormDto | null>(getInitialState());
  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    getInitialState(),
  );

  const [
    applyToContactPaidAs,
    setApplyToContactPaidAs,
  ] = useState<PaidAs | null>(null);
  const [
    applyToCarrierPaidAs,
    setApplyToCarrierPaidAs,
  ] = useState<PaidAs | null>(null);

  const [commodity, setCommodity] = useState<CommodityDto | null>(null);
  const [packageType, setPackageType] = useState<{
    packageTypeId: string;
    name: string;
  }>(null);

  const [
    profitValues,
    setProfitValues,
  ] = useState<FormattedProfitValues | null>(null);

  const [commodities, setCommodities] = useState<CommodityDto[] | null>(
    selectedCommodities,
  );
  const [fakeCommodityIndex, setFakeCommodityIndex] = useState<number>(null);

  const [charges, setCharges] = useState<ChargeDto[] | null>([]);
  const [trackingEvents, setTrackingEvents] = useState<
    TrackingEventDto[] | null
  >([]);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [carrier, setCarrier] = useState<ContactDto>(null);

  const [
    warehouseReceiptDocumentTemplatesCount,
    setWarehouseReceiptDocumentTemplatesCount,
  ] = useState<number>(0);

  const isOrgAdminOrOperation: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;

  useEffect(() => {
    getDocumentTemplatesFx({
      filter: `documentTemplateType:QuoteConfirmationDocument AND isInactive: false`,
    }).then((documentTemplates) => {
      setWarehouseReceiptDocumentTemplatesCount(
        documentTemplates.items?.length,
      );
    });
  }, []);

  useEffect(() => {
    initialProfitValues.profit =
      initialProfitValues.income - initialProfitValues.expense;
    const income = getFormattedPrice(
      initialProfitValues.income,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const expense = getFormattedPrice(
      initialProfitValues.expense,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    const profit = getFormattedPrice(
      initialProfitValues.profit,
      defaultCurrency?.decimalPlaces,
      defaultCurrency?.symbol,
    );
    setProfitValues({ income, expense, profit });
    setWarehouseReceiptContextFormValue(
      (warehouseReceiptContextFormValueDto) => {
        warehouseReceiptContextFormValueDto = getInitialState();
        return { ...warehouseReceiptContextFormValueDto };
      },
    );
  }, []);
  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Order} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        warehouseReceiptSchema = generateValidationSchemaWithCustomFields(
          customFields,
          warehouseReceiptSchema,
        );
        setCustomFields(customFields);
      },
      () => {},
    );
    getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
      (result) => {
        const defaultDispatcher = result.items.find(
          (employee) => employee.userEmployee?.userId == currentUser?.userId,
        );

        setWarehouseReceiptContextFormValue(
          (warehouseReceiptContextFormValueDto) => {
            warehouseReceiptContextFormValueDto.employeeContactName =
              defaultDispatcher?.name;
            warehouseReceiptContextFormValueDto.employeeContactId =
              defaultDispatcher?.contactId;
            setInitialValues({ ...warehouseReceiptContextFormValueDto });
            return { ...warehouseReceiptContextFormValueDto };
          },
        );
      },
      () => {},
    );

    setFakeCommodityIndex(0);
    order &&
      setWarehouseReceiptContextFormValue(
        (warehouseReceiptContextFormValue) => {
          warehouseReceiptContextFormValue.billToContactId =
            order.billToContactId;
          warehouseReceiptContextFormValue.billToContactName =
            order.billToContactName;
          warehouseReceiptContextFormValue.billToContactType =
            order.billToContactType;
          return { ...warehouseReceiptContextFormValue };
        },
      );
    setCommodities(selectedCommodities);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isLoading === false)
      setWarehouseReceiptContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.commodities = commodities;
        return orderEditFormDto;
      });
  }, [commodities]);

  useEffect(() => {
    if (isLoading === false) {
      setWarehouseReceiptContextFormValue((orderDto) => {
        if (!orderDto) {
          orderDto = getInitialState();
        }
        orderDto.charges = charges;
        return { ...orderDto };
      });
      countProfitValue(charges);
    }
  }, [charges]);

  useEffect(() => {
    if (isLoading === false)
      setWarehouseReceiptContextFormValue((orderEditFormDto) => {
        if (!orderEditFormDto) {
          orderEditFormDto = getInitialState();
        }
        orderEditFormDto.trackingEvents = trackingEvents;
        return orderEditFormDto;
      });
  }, [trackingEvents]);

  const goToDetailsCommodity = async (commodityParams: GetCommodityParams) => {
    const cloneCommodity = Object.assign({}, commodityParams.commodity);
    if (commodityParams?.commodity) {
      const innerCommodity = await getCommodity({
        commodityId: commodityParams?.commodity?.commodityId,
      });
      if (innerCommodity) {
        showDialog({
          dialog: CommodityDialog,
          props: {
            title: 'Update Commodity',
            commodity: innerCommodity,
            commodityId: innerCommodity?.commodityId,
            className: 'commodity-modal',
            saveButtonRenderCondition: true,
            isEditMode: true,
          },
        }).then(
          (result) => {
            setOffset(0);
            if (result !== null) {
              updateCommodity(result).then(() => {
                const itemsResult: CommodityDto[] = commodities.map((obj) => {
                  const compareCommodityResult =
                    obj.commodityId === commodityParams?.commodity.commodityId;
                  if (compareCommodityResult) obj = result;
                  return obj;
                });
                setCommodities(
                  itemsResult.sort((a, b) => a.commodityId - b.commodityId),
                );
              });
            } else {
              setCommodities((oldCommodities) => {
                const index = oldCommodities.findIndex(
                  (commodity) =>
                    commodity.commodityId === cloneCommodity.commodityId,
                );
                oldCommodities[index] = cloneCommodity;
                return oldCommodities;
              });
              forceUpdate();
            }
          },
          () => {},
        );
      }
    }
  };

  const goToDetailsCharge = (chargeParams: GetChargeParams) => {
    const cloneCharge = Object.assign({}, chargeParams.charge);
    if (chargeParams.charge) {
      showDialog({
        dialog: ChargeDialog,
        props: {
          title: 'Update Charge',
          charge: chargeParams?.charge,
          chargeId: chargeParams?.charge?.chargeId,
          chargeType: chargeParams?.charge?.chargeType,
          className: 'charge-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          charges: charges,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: ChargeDto[] = charges.map((obj) => {
              const compareChargeResult =
                obj.chargeId === chargeParams?.charge.chargeId;
              if (compareChargeResult) obj = result;
              return obj;
            });
            setCharges(itemsResult.sort((a, b) => a.chargeId - b.chargeId));
          } else {
            setCharges((oldCharges) => {
              const index = oldCharges.findIndex(
                (charge) => charge.chargeId === cloneCharge.chargeId,
              );
              oldCharges[index] = cloneCharge;
              return oldCharges;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const goToDetailsTrackingEvent = (
    trackingEventParams: GetTrackingEventParams,
  ) => {
    const cloneEvent = Object.assign({}, trackingEventParams.trackingEvent);
    if (trackingEventParams.trackingEvent) {
      showDialog({
        dialog: TrackingEventDialog,
        props: {
          title: 'Update Event',
          trackingEvent: trackingEventParams?.trackingEvent,
          trackingEventId: trackingEventParams?.trackingEvent?.trackingEventId,
          className: 'trackingEvent-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: TrackingEventDto[] = trackingEvents.map(
              (obj) => {
                const compareTrackingEventResult =
                  obj === trackingEventParams?.trackingEvent;
                if (compareTrackingEventResult) obj = result;
                return obj;
              },
            );
            setTrackingEvents(
              itemsResult.sort((a, b) => a.trackingEventId - b.trackingEventId),
            );
          } else {
            setTrackingEvents((oldTrackingEvents) => {
              const index = oldTrackingEvents.findIndex(
                (trackingEvent) =>
                  trackingEvent.trackingEventId === cloneEvent.trackingEventId,
              );
              oldTrackingEvents[index] = cloneEvent;
              return oldTrackingEvents;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const countProfitValue = (charges: ChargeDto[]) => {
    const { defaultCurrency } = currencyStore?.getState();
    setDefaultCurrency(defaultCurrency);
    setProfitValues((profitValue) => {
      const initialZero = getFormattedPrice(0, defaultCurrency?.decimalPlaces);
      profitValue.income = initialZero;
      profitValue.expense = initialZero;
      profitValue.profit = initialZero;
      charges.forEach((item) => {
        if (item.isDeleted) return;
        let totalPrice = item.price * item.quantity;
        if (item.chargeType === ChargeType.IncomeCharge)
          profitValue.income = (
            Number(profitValue.income) + Number(totalPrice)
          ).toString();
        else
          profitValue.expense = (
            Number(profitValue.expense) + Number(totalPrice)
          ).toString();
      });
      profitValue.profit = getFormattedPrice(
        Number(profitValue.income) - Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.income = getFormattedPrice(
        Number(profitValue.income),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      profitValue.expense = getFormattedPrice(
        Number(profitValue.expense),
        defaultCurrency?.decimalPlaces,
        defaultCurrency?.symbol,
      );
      return { ...profitValue };
    });
  };
  const getCommodities = (): CommodityDto[] => {
    return commodities?.sort((a, b) => a.commodityId - b.commodityId);
  };
  const getCharges = (): ChargeDto[] => {
    return charges?.sort((a, b) => a.chargeId - b.chargeId);
  };
  const getTrackingEvents = (): TrackingEventDto[] => {
    let events = trackingEvents;
    events?.forEach((event) => {
      if (typeof event.eventDate === 'object') {
        event.eventDate = event.eventDate.toISOString();
      }
    });
    setTrackingEvents(events);
    return events?.sort(
      (a, b) =>
        new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
    );
  };
  const getTotalPcsCrt = () => {
    let sumPieces: number = 0;
    commodities?.forEach((commodity) => {
      sumPieces += Number(commodity.pieces);
    });
    return sumPieces;
  };
  const getWeightTotal = () => {
    let weightTotal = 0;
    commodities?.forEach((commodity) => {
      weightTotal += commodity.weightTotal;
    });
    return weightTotal;
  };
  const getVolumeTotal = () => {
    let volumeTotal = 0;
    commodities?.forEach((commodity) => {
      volumeTotal += commodity?.volumeTotal;
    });
    return volumeTotal;
  };

  const onChargeCreated = (charge: ChargeDto) => {
    if (!charges) {
      const initialCharge: ChargeDto[] = [];
      setCharges(initialCharge);
    }
    setCharges((chargesDto) => [...chargesDto, charge]);
  };

  const onTrackingEventCreated = (trackingEvent: TrackingEventDto) => {
    if (!trackingEvents) {
      const initialTrackingEvent: TrackingEventDto[] = [];
      setTrackingEvents(initialTrackingEvent);
    }
    setTrackingEvents((trackingEventsDto) => [
      ...trackingEventsDto,
      trackingEvent,
    ]);
  };

  const onShipperDateChange = (
    { setFieldValue },
    date?: any,
    setShipperDateIndex?: number,
  ) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperDateIndex]) {
        orderPickups[
          setShipperDateIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperDateIndex].customValues['pickDate'] = new Date(
        date,
      );
      setFieldValue(
        `orderPickups[${setShipperDateIndex}].pickDate`,
        new Date(date),
      );
      return { ...orderDto };
    });
  };
  const onDeliveryDateChange = (
    { setFieldValue },
    date?: any,
    setDeliveryDateIndex?: number,
  ) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setDeliveryDateIndex]) {
        orderDeliveries[
          setDeliveryDateIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setDeliveryDateIndex].customValues[
        'deliveryDate'
      ] = new Date(date);
      setFieldValue(
        `orderDeliveries[${setDeliveryDateIndex}].deliveryDate`,
        new Date(date),
      );
      return { ...orderDto };
    });
  };
  const onShipperNoteChange = (data?: any, setShipperNoteIndex?: number) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperNoteIndex]) {
        orderPickups[
          setShipperNoteIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperNoteIndex].customValues['shippingNotes'] =
        data.target.value;
      return { ...orderDto };
    });
  };
  const onDeliveryNoteChange = (data?: any, setConsigneeNoteIndex?: number) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setConsigneeNoteIndex]) {
        orderDeliveries[
          setConsigneeNoteIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setConsigneeNoteIndex].customValues['deliveryNotes'] =
        data.target.value;
      return { ...orderDto };
    });
  };
  const setShipperFilter = (data?: any, setShipperFilterIndex?: number) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[setShipperFilterIndex]) {
        orderPickups[
          setShipperFilterIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[setShipperFilterIndex].filter = data;
      return { ...orderDto };
    });
  };
  const setDeliveryFilter = (data?: any, setDeliveryFilterIndex?: number) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[setDeliveryFilterIndex]) {
        orderDeliveries[
          setDeliveryFilterIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[setDeliveryFilterIndex].filter = data;
      return { ...orderDto };
    });
  };
  const setShipperContactAddress = (
    contactAddressId: number,
    addressLine: string,
    shipperContactAddressFilterIndex?: number,
  ) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups) {
        orderPickups = [];
      }
      if (!orderPickups[shipperContactAddressFilterIndex]) {
        orderPickups[
          shipperContactAddressFilterIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[
        shipperContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderPickups[
        shipperContactAddressFilterIndex
      ].contactAddressName = addressLine;
      return { ...orderDto };
    });
  };
  const setDeliveryContactAddress = (
    contactAddressId: number,
    addressLine: string,
    deliveryContactAddressFilterIndex?: number,
  ) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries) {
        orderDeliveries = [];
      }
      if (!orderDeliveries[deliveryContactAddressFilterIndex]) {
        orderDeliveries[
          deliveryContactAddressFilterIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressId = contactAddressId;
      orderDeliveries[
        deliveryContactAddressFilterIndex
      ].contactAddressName = addressLine;
      return { ...orderDto };
    });
  };
  const setShipperContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    shipperContactIndex?: number,
  ) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderPickups = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderPickups[shipperContactIndex]) {
        orderPickups[
          shipperContactIndex
        ] = getInitialStateOrderPickupEditForm();
      }
      orderPickups[shipperContactIndex].contactId = Number(contactId);
      orderPickups[shipperContactIndex].contactName = name;
      orderPickups[shipperContactIndex].contactType = contactType;
      return { ...orderDto };
    });
  };
  const setDeliveryContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    consigneeContactIndex?: number,
  ) => {
    setWarehouseReceiptContextFormValue((orderDto) => {
      let orderDeliveries = orderDto?.orderEntities?.filter(
        (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
      );
      if (!orderDto) {
        orderDto = getInitialState();
      }
      if (!orderDeliveries[consigneeContactIndex]) {
        orderDeliveries[
          consigneeContactIndex
        ] = getInitialStateOrderDeliveryEditForm();
      }
      orderDeliveries[consigneeContactIndex].contactId = Number(contactId);
      orderDeliveries[consigneeContactIndex].contactName = name;
      orderDeliveries[consigneeContactIndex].contactType = contactType;
      return { ...orderDto };
    });
  };

  const pickupDeliveryFilter = (data: OrderDto) => {
    let orderPickups = data?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    let orderDeliveries = data?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );

    if (orderPickups.length > 1) {
      orderPickups = orderPickups.filter((item) => {
        return item?.contactId != null && !isNaN(item?.contactId);
      });
    }
    if (orderPickups.length === 0) {
      orderPickups.push({
        ...getInitialStateOrderPickupEditForm(),
      });
    }

    if (orderDeliveries.length > 1) {
      orderDeliveries = orderDeliveries.filter((item) => {
        return item?.contactId != null && !isNaN(item?.contactId);
      });
    }
    if (orderDeliveries.length === 0) {
      orderDeliveries.push({
        ...getInitialStateOrderDeliveryEditForm(),
      });
    }

    data.orderEntities = [...orderPickups, ...orderDeliveries];
  };

  const linkCommodities = (createdOrder: OrderDto) => {
    const commodityParams = commodities.map((commodity) => {
      return {
        commodityId: commodity.commodityId,
        orderId: createdOrder?.orderId,
      };
    });

    const patchOrderParams: PatchOrderParams = {
      resource: { links: createdOrder?.links },
      patchOrderCommand: {
        patchItems: [
          {
            path: `/OrderCommodities`,
            op: 'replace',
            value: commodityParams,
          },
        ],
      },
    };

    return patchOrder(patchOrderParams);
  };

  const changeCommodityStatus = async (commodities: CommodityDto[]) => {
    if (commodities !== null && commodities.length !== 0) {
      try {
        commodities.forEach((commodity) => {
          commodity.commodityStatus = null;
        });
        await updateCommodities(commodities);
      } catch (error) {
        addMessage({
          message: error,
          type: 'danger',
          id: v4(),
        });
      }
    }
  };

  const onSubmit = async (data: OrderDto) => {
    data.commodities = [];
    data.charges = charges;
    data.trackingEvents = trackingEvents;
    data.customValues = warehouseReceiptContextFormValue?.customValues;
    pickupDeliveryFilter(data);
    data.orderType = OrderTypes.WarehouseReceipt;
    setIsSending(true);

    try {
      const result = await createOrder(data);
      const linkedResult = await linkCommodities(result);
      await changeCommodityStatus(linkedResult.commodities);
      onWarehouseReceiptCreated(result);
    } catch (error) {
      addMessage(error);
    } finally {
      setIsSending(false);
    }
  };

  const createNewItemOrderPickup = () => {
    setWarehouseReceiptContextFormValue(
      (warehouseReceiptContextFormValueDto) => {
        warehouseReceiptContextFormValueDto?.orderEntities?.push({
          ...getInitialStateOrderPickupEditForm(),
        });
        return { ...warehouseReceiptContextFormValueDto };
      },
    );
  };
  const createNewItemOrderDelivery = () => {
    setWarehouseReceiptContextFormValue(
      (warehouseReceiptContextFormValueDto) => {
        warehouseReceiptContextFormValueDto?.orderEntities?.push({
          ...getInitialStateOrderDeliveryEditForm(),
        });
        return { ...warehouseReceiptContextFormValueDto };
      },
    );
  };
  const deleteItemOrderPickup = (indexItemToDelete?: number, context?: any) => {
    setWarehouseReceiptContextFormValue(
      (warehouseReceiptContextFormValueDto) => {
        const orderPickups = warehouseReceiptContextFormValueDto?.orderEntities?.filter(
          (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
        );
        const orderDeliveries = warehouseReceiptContextFormValueDto?.orderEntities?.filter(
          (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
        );
        orderPickups?.splice(indexItemToDelete, 1);
        warehouseReceiptContextFormValueDto.orderEntities = [
          ...orderPickups,
          ...orderDeliveries,
        ];
        context.setFieldValue(
          'orderEntities',
          warehouseReceiptContextFormValueDto.orderEntities,
        );
        return { ...warehouseReceiptContextFormValueDto };
      },
    );
  };

  const deleteItemOrderDelivery = (
    indexItemToDelete?: number,
    context?: any,
  ) => {
    setWarehouseReceiptContextFormValue(
      (warehouseReceiptContextFormValueDto) => {
        const orderPickups = warehouseReceiptContextFormValueDto?.orderEntities?.filter(
          (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
        );
        const orderDeliveries = warehouseReceiptContextFormValueDto?.orderEntities?.filter(
          (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
        );
        orderDeliveries?.splice(indexItemToDelete, 1);
        warehouseReceiptContextFormValueDto.orderEntities = [
          ...orderPickups,
          ...orderDeliveries,
        ];
        context.setFieldValue(
          'orderEntities',
          warehouseReceiptContextFormValueDto.orderEntities,
        );
        return { ...warehouseReceiptContextFormValueDto };
      },
    );
  };

  const getItemsOrderPickup = (context?: any) => {
    const orderPickups = warehouseReceiptContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
    );
    if (orderPickups == null || orderPickups.length === 0) {
      return (
        <ShipperForm
          shipperFilter={orderPickups[0]?.filter}
          allowDelete={false}
          shipperContact={{
            contactId: orderPickups[0]?.contactId,
            name: orderPickups[0]?.contactName,
            contactType: orderPickups[0]?.contactType,
          }}
          onShipperNoteChange={(note: string) => onShipperNoteChange(note, 0)}
          shipperContactAddress={{
            contactAddressId: orderPickups[0]?.contactAddressId?.toString(),
            addressLine: orderPickups[0]?.contactAddressName,
          }}
          onShipperDateChange={(data) => onShipperDateChange(context, data, 0)}
          index={0}
          setShipperContact={(contactId, name, contactType) =>
            setShipperContact(contactId, name, contactType, 0)
          }
          orderPickupDto={orderPickups[0]}
          setShipperFilter={(data) => setShipperFilter(data, 0)}
          setShipperContactAddress={(contactAddressId, addressLine) =>
            setShipperContactAddress(contactAddressId, addressLine, 0)
          }
        />
      );
    }
    if (orderPickups != null && orderPickups !== undefined) {
      return orderPickups?.map((item, index) => (
        <ShipperForm
          shipperFilter={orderPickups[index]?.filter}
          onShipperNoteChange={(note: string) =>
            onShipperNoteChange(note, index)
          }
          allowDelete={orderPickups?.length > 1}
          shipperContact={{
            contactId: orderPickups[index]?.contactId,
            name: orderPickups[index]?.contactName,
            contactType: orderPickups[index]?.contactType,
          }}
          shipperContactAddress={{
            contactAddressId: orderPickups[index]?.contactAddressId?.toString(),
            addressLine: orderPickups[index]?.contactAddressName,
          }}
          onShipperDeleted={() => deleteItemOrderPickup(index, context)}
          onShipperDateChange={(data) =>
            onShipperDateChange(context, data, index)
          }
          index={index}
          setShipperContact={(contactId, name, contactType) =>
            setShipperContact(contactId, name, contactType, index)
          }
          orderPickupDto={orderPickups[index]}
          setShipperFilter={(data) => setShipperFilter(data, index)}
          setShipperContactAddress={(contactAddressId, addressLine) =>
            setShipperContactAddress(contactAddressId, addressLine, index)
          }
          key={index}
        />
      ));
    } else return '';
  };

  const getItemsOrderDelivery = (context?: any) => {
    const orderDeliveries = warehouseReceiptContextFormValue?.orderEntities?.filter(
      (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
    );
    if (orderDeliveries == null || orderDeliveries.length === 0) {
      return (
        <ConsigneeForm
          deliveryFilter={orderDeliveries[0]?.filter}
          allowDelete={false}
          deliveryContact={{
            contactId: orderDeliveries[0]?.contactId,
            name: orderDeliveries[0]?.contactName,
            contactType: orderDeliveries[0]?.contactType,
          }}
          deliveryContactAddress={{
            contactAddressId: orderDeliveries[0]?.contactAddressId?.toString(),
            addressLine: orderDeliveries[0]?.contactAddressName,
          }}
          onDeliveryDateChange={(data) =>
            onDeliveryDateChange(context, data, 0)
          }
          index={0}
          onDeliveryNoteChange={(note) => onDeliveryNoteChange(note, 0)}
          setDeliveryContact={(contactId, name, contactType) =>
            setDeliveryContact(contactId, name, contactType, 0)
          }
          orderDeliveryDto={orderDeliveries[0]}
          setDeliveryFilter={(data) => setDeliveryFilter(data, 0)}
          setDeliveryContactAddress={(contactAddressId, addressLine) =>
            setDeliveryContactAddress(contactAddressId, addressLine, 0)
          }
        />
      );
    }
    if (orderDeliveries != null && orderDeliveries !== undefined) {
      return orderDeliveries?.map((item, index) => (
        <ConsigneeForm
          deliveryFilter={orderDeliveries[index]?.filter}
          allowDelete={orderDeliveries?.length > 1}
          deliveryContact={{
            contactId: orderDeliveries[index]?.contactId,
            name: orderDeliveries[index]?.contactName,
            contactType: orderDeliveries[index]?.contactType,
          }}
          deliveryContactAddress={{
            contactAddressId: orderDeliveries[
              index
            ]?.contactAddressId?.toString(),
            addressLine: orderDeliveries[index]?.contactAddressName,
          }}
          onDeliveryNoteChange={(note) => onDeliveryNoteChange(note, index)}
          onDeliveryDeleted={() => deleteItemOrderDelivery(index, context)}
          onDeliveryDateChange={(data) =>
            onDeliveryDateChange(context, data, index)
          }
          index={index}
          setDeliveryContact={(contactId, name, contactType) =>
            setDeliveryContact(contactId, name, contactType, index)
          }
          orderDeliveryDto={orderDeliveries[index]}
          setDeliveryFilter={(data) => setDeliveryFilter(data, index)}
          setDeliveryContactAddress={(contactAddressId, addressLine) =>
            setDeliveryContactAddress(contactAddressId, addressLine, index)
          }
          key={index}
        />
      ));
    } else return '';
  };

  const onDateTimeChange = (
    date: any,
    context: FormikProps<FormikValues>,
    fieldName: string,
  ) => {
    setWarehouseReceiptContextFormValue(
      (warehouseReceiptContextFormValueDto) => {
        if (!warehouseReceiptContextFormValueDto) {
          warehouseReceiptContextFormValueDto = getInitialState();
        }
        // warehouseReceiptContextFormValueDto.date = new Date(date);
        warehouseReceiptContextFormValueDto[fieldName] = new Date(date);
        context.setFieldValue(
          fieldName,
          warehouseReceiptContextFormValueDto[fieldName],
        );
        return warehouseReceiptContextFormValueDto;
      },
    );
  };

  return (
    <div className={'warehouseReceipt-edit-form'}>
      <OrderForm
        id={'WarehouseReceiptForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={orderFormRef}
        validationSchema={warehouseReceiptSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <div className="d-flex justify-content-between">
                  <span>
                    <Tab data-cy={'general'}>Warehouse Receipt Information</Tab>
                    <Tab data-cy={'charges'}>Charges</Tab>
                    <Tab data-cy={'events'}>Events</Tab>
                    <Tab data-cy={'additional'}>Additional</Tab>
                  </span>
                </div>
              </TabList>
              <div>
                <TabPanel forceRender={true}>
                  <Panel className="m-3">
                    <h2>Warehouse Receipt Information</h2>
                    <div className="row">
                      <div />
                      <div className={'col-3'}>
                        <OrderForm.BillToContactSelect
                          id={'billToContactId'}
                          header={'Customer/Lead'}
                          placeholder={'Select Customer/Lead'}
                          contactTypes={[
                            ContactType.Customer,
                            ContactType.Vendor,
                            ContactType.ForwardingAgent,
                            ContactType.Contact,
                            ContactType.Lead,
                          ]}
                          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact} OR contactType: ${ContactType.Lead}`}
                          required={true}
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.billToContactId !=
                              null &&
                            warehouseReceiptContextFormValue.billToContactName !=
                              null &&
                            warehouseReceiptContextFormValue.billToContactType !=
                              null
                              ? {
                                  contactId:
                                    warehouseReceiptContextFormValue.billToContactId,
                                  name:
                                    warehouseReceiptContextFormValue.billToContactName,
                                  contactType:
                                    warehouseReceiptContextFormValue.billToContactType,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.billToContactId =
                                  data?.contactId;
                                warehouseReceiptContextFormValueDto.billToContactName =
                                  data?.name;
                                warehouseReceiptContextFormValueDto.billToContactType =
                                  data?.contactType;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                            data?.contactId
                              ? setApplyToContactPaidAs(data?.paidAs)
                              : setApplyToContactPaidAs(
                                  ChargeDefaultValues.paidAs,
                                );
                          }}
                          nameId={'billToContactName'}
                          showContactType={true}
                        />
                      </div>
                      <div className={'col-3 employee-contact-input'}>
                        <OrderForm.EmployeeContactSelect
                          id={'employeeContactId'}
                          header={'Employee'}
                          contactTypes={[ContactType.Employee]}
                          selectedFilter={`contactType: ${ContactType.Employee}`}
                          required={false}
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.employeeContactId !=
                              null &&
                            warehouseReceiptContextFormValue.employeeContactName !=
                              null
                              ? {
                                  contactId:
                                    warehouseReceiptContextFormValue.employeeContactId,
                                  name:
                                    warehouseReceiptContextFormValue.employeeContactName,
                                  contactType: ContactType.Employee,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.employeeContactId =
                                  data?.contactId;
                                warehouseReceiptContextFormValueDto.employeeContactName =
                                  data?.name;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'employeeContactName'}
                        />
                      </div>
                      <div className="col-3 sales-person-contact-input">
                        <OrderForm.DestinationAgentContactSelect
                          contactTypes={[ContactType.ForwardingAgent]}
                          selectedFilter={`contactType: ${ContactType.ForwardingAgent}`}
                          required={false}
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.destinationAgentContactId !=
                              null &&
                            warehouseReceiptContextFormValue.destinationAgentContactName !=
                              null
                              ? {
                                  contactId:
                                    warehouseReceiptContextFormValue.destinationAgentContactId,
                                  name:
                                    warehouseReceiptContextFormValue.destinationAgentContactName,
                                  contactType: ContactType.ForwardingAgent,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.destinationAgentContactId =
                                  data?.contactId;
                                warehouseReceiptContextFormValueDto.destinationAgentContactName =
                                  data?.name;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <OrderForm.ModeOfTransportation
                          header={'Mode of Transportation'}
                          selectedFilter={` `}
                          required={false}
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.modeOfTransportationId !=
                              null &&
                            warehouseReceiptContextFormValue.modeOfTransportationDescription !=
                              null
                              ? {
                                  modeOfTransportationId:
                                    warehouseReceiptContextFormValue.modeOfTransportationId,
                                  description:
                                    warehouseReceiptContextFormValue.modeOfTransportationDescription,
                                }
                              : ''
                          }
                          onChange={(data?: ModeOfTransportationDto) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.modeOfTransportationId =
                                  data?.modeOfTransportationId;
                                warehouseReceiptContextFormValueDto.modeOfTransportationDescription =
                                  data?.description;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                          }}
                        />
                      </div>
                      <div className="col-3 carrier-contact-input">
                        <OrderForm.CarrierContactSelect
                          header={'Carrier'}
                          multiple={false}
                          contactTypes={[ContactType.Carrier]}
                          selectedFilter={`contactType: ${ContactType.Carrier}`}
                          closeMenuOnSelect={true}
                          defaultValue={(): any => {
                            return warehouseReceiptContextFormValue
                              ? warehouseReceiptContextFormValue.carriers
                              : null;
                          }}
                          onChange={(contact?: any) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.carriers = contact;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                            setCarrier(contact);
                            contact?.contactId
                              ? setApplyToCarrierPaidAs(contact?.paidAs)
                              : setApplyToCarrierPaidAs(
                                  ChargeDefaultValues.paidAs,
                                );
                          }}
                        />
                      </div>
                      <div className="col-3 sales-person-contact-input">
                        <OrderForm.IssuedByContactSelect
                          contactTypes={[ContactType.SalesPerson]}
                          selectedFilter={`contactType: ${ContactType.SalesPerson}`}
                          required={false}
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.issuedByContactId !=
                              null &&
                            warehouseReceiptContextFormValue.issuedByContactName !=
                              null
                              ? {
                                  contactId:
                                    warehouseReceiptContextFormValue.issuedByContactId,
                                  name:
                                    warehouseReceiptContextFormValue.issuedByContactName,
                                  contactType: ContactType.SalesPerson,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.issuedByContactId =
                                  data?.contactId;
                                warehouseReceiptContextFormValueDto.issuedByContactName =
                                  data?.name;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                          }}
                        />
                      </div>
                      <div className={'col-3 equipmentType-input'}>
                        <OrderForm.Date
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.date != null
                              ? warehouseReceiptContextFormValue?.date
                              : new Date()
                          }
                          onChange={(date) =>
                            onDateTimeChange(date, context, 'date')
                          }
                        />
                      </div>
                      <div className={'col-3 equipmentType-input'}>
                        <OrderForm.Time
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.time != null
                              ? warehouseReceiptContextFormValue?.time
                              : new Date()
                          }
                          onChange={(date) =>
                            onDateTimeChange(date, context, 'time')
                          }
                        />
                      </div>
                      <div className="col-3">
                        <OrderForm.EntryNumber />
                      </div>
                      <div className="col-3">
                        <OrderForm.BondedWHSelect
                          selectedFilter={` `}
                          required={false}
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.paymentTermId !=
                              null &&
                            warehouseReceiptContextFormValue.paymentTermDescription !=
                              null
                              ? {
                                  paymentTermId:
                                    warehouseReceiptContextFormValue.paymentTermId,
                                  description:
                                    warehouseReceiptContextFormValue.paymentTermDescription,
                                }
                              : ''
                          }
                          onChange={(paymentTermDto?: PaymentTermDto) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.paymentTermId =
                                  paymentTermDto?.paymentTermId;
                                warehouseReceiptContextFormValueDto.paymentTermDescription =
                                  paymentTermDto?.description;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                          }}
                        />
                      </div>
                      <div className={'col-3 equipmentType-input'}>
                        <OrderForm.EntryDate
                          defaultValue={
                            warehouseReceiptContextFormValue?.entryDate
                          }
                          onChange={(date) =>
                            onDateTimeChange(date, context, 'entryDate')
                          }
                        />
                      </div>
                      <div className="col-3 division-input">
                        <OrderForm.Division
                          id={'divisionId'}
                          header={'Division'}
                          required={true}
                          defaultValue={
                            warehouseReceiptContextFormValue &&
                            warehouseReceiptContextFormValue.divisionId !=
                              null &&
                            warehouseReceiptContextFormValue.divisionName !=
                              null
                              ? {
                                  divisionId:
                                    warehouseReceiptContextFormValue.divisionId,
                                  divisionName:
                                    warehouseReceiptContextFormValue.divisionName,
                                }
                              : ''
                          }
                          onChange={(data?: DivisionDto) => {
                            setWarehouseReceiptContextFormValue(
                              (warehouseReceiptContextFormValueDto) => {
                                if (!warehouseReceiptContextFormValueDto) {
                                  warehouseReceiptContextFormValueDto = getInitialState();
                                }
                                warehouseReceiptContextFormValueDto.divisionId =
                                  data?.divisionId;
                                warehouseReceiptContextFormValueDto.divisionName =
                                  data?.divisionName;
                                return warehouseReceiptContextFormValueDto;
                              },
                            );
                          }}
                          nameId={'divisionName'}
                          defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                          disabled={
                            !(
                              (currentUser?.isInOrgAdminRole ||
                                currentUser?.isInOperationRole) &&
                              VisibleTransactions[
                                currentUser?.visibleTransactions
                              ] === VisibleTransactions.AllTransactions
                            )
                          }
                        />
                      </div>
                    </div>
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Shipper</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderPickup}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-cy="btn-create-new-shipper"
                      />
                    </div>
                    {getItemsOrderPickup(context)}
                    <hr className="my-4" />

                    <div className="d-flex">
                      <h2>Consignee</h2>
                      <AiFillPlusCircle
                        onClick={createNewItemOrderDelivery}
                        className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
                        data-cy="btn-create-new-consignee"
                      />
                    </div>
                    {getItemsOrderDelivery(context)}
                    <hr className="my-4" />

                    <CommodityForm
                      id={`commodityForm-wr-0`}
                      initialValues={commodity || initialStateCommodity}
                      innerRef={commodityFormRef}
                      validationSchema={commoditySchema}
                      onKeyPress={(event, formikParams) => {
                        if (
                          event.code === 'Enter' &&
                          (currentUser?.isInOrgAdminRole ||
                            currentUser?.isInOperationRole)
                        ) {
                          formikParams.submitForm();
                        }
                      }}
                    >
                      <CommoditiesForOrderList
                        className={'mb-4 commodities-for-order-list'}
                        limit={limit}
                        showPagination={false}
                        onPageChanged={(page) => setOffset(page * limit)}
                        offset={offset}
                        items={getCommodities()}
                        goToDetails={goToDetailsCommodity}
                        changeItems={setCommodities}
                        userCanDelete={false}
                        orderType={OrderTypes.WarehouseReceipt}
                      />
                      {getCommodities().length === 0 && (
                        <h4 className="text-center m-5 text-muted">
                          No commodities
                        </h4>
                      )}
                      <div className="row">
                        <div className="col-2">
                          <OrderForm.TotalPcsCrt
                            selectedName={getTotalPcsCrt()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.WeightTotal
                            selectedName={getWeightTotal()}
                          />
                        </div>
                        <div className="col-2">
                          <OrderForm.VolumeTotal
                            selectedName={getVolumeTotal()}
                          />
                        </div>
                      </div>
                    </CommodityForm>
                    <div className="justify-content-end row">
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Back
                        </Button>
                      </div>
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'WarehouseReceiptForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Warehouse Receipt
                          </Button>
                        </div>
                      )}
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <ChargeForOrderList
                      limit={limit}
                      showPagination={false}
                      showIncomeCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      showExpenseCharge={
                        userHas(CREATE_CHARGE_LINK_KEY) ? true : false
                      }
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onChargeCreated={onChargeCreated}
                      items={getCharges()}
                      goToDetails={goToDetailsCharge}
                      changeItems={setCharges}
                      selectedApplyToContact={{
                        contactId:
                          warehouseReceiptContextFormValue.billToContactId,
                        name:
                          warehouseReceiptContextFormValue.billToContactName,
                        contactType:
                          warehouseReceiptContextFormValue.billToContactType,
                        paidAs: applyToContactPaidAs,
                      }}
                      selectedApplyToCarrier={{
                        contactId:
                          warehouseReceiptContextFormValue.carrierContactId,
                        name:
                          warehouseReceiptContextFormValue.carrierContactName,
                        contactType: ContactType.Carrier,
                        paidAs: applyToCarrierPaidAs,
                      }}
                      isCreateMode={true}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                      charges={warehouseReceiptContextFormValue.charges}
                    />
                    <div className="mt-3">
                      <h2>Totals</h2>
                    </div>
                    <ChargeForm initialValues={profitValues} id={'ChargeForm'}>
                      <div className={'row'}>
                        <div className={'col-2'}>
                          <ChargeForm.Income disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Expense disabled={true} />
                        </div>
                        <div className={'col-2'}>
                          <ChargeForm.Profit disabled={true} />
                        </div>
                      </div>
                    </ChargeForm>
                    <div className="justify-content-end row">
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Back
                        </Button>
                      </div>
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'WarehouseReceiptForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Warehouse Receipt
                          </Button>
                        </div>
                      )}
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel className="m-3">
                    <TrackingEventForOrderList
                      limit={limit}
                      showPagination={false}
                      showAddButton={isOrgAdminOrOperation ? true : false}
                      onPageChanged={(page) => setOffset(page * limit)}
                      offset={offset}
                      onTrackingEventCreated={onTrackingEventCreated}
                      items={getTrackingEvents()}
                      goToDetails={goToDetailsTrackingEvent}
                      changeItems={setTrackingEvents}
                      isCreateMode={true}
                      isLoading={isLoading}
                      userCanDelete={isOrgAdminOrOperation}
                    />
                    <div className="justify-content-end row">
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          className="col-12"
                          disabled={isSending}
                        >
                          Back
                        </Button>
                      </div>
                      {isOrgAdminOrOperation && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'WarehouseReceiptForm'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Warehouse Receipt
                          </Button>
                        </div>
                      )}
                    </div>
                  </Panel>
                  `
                </TabPanel>
                <TabPanel forceRender={false}>
                  <OrderForm.CustomValues
                    customFields={customFields}
                    entityType={CustomFieldEntityType.Order}
                    defaultValue={{
                      ...warehouseReceiptContextFormValue.customValues,
                    }}
                    onChange={(result) => {
                      setWarehouseReceiptContextFormValue(
                        (warehouseReceiptContextFormValue) => {
                          warehouseReceiptContextFormValue.customValues = result;
                          return { ...warehouseReceiptContextFormValue };
                        },
                      );
                    }}
                    saveButtonRenderCondition={false}
                    isSending={isSending}
                    formName={'WarehouseReceiptForm'}
                    entityName={'Warehouse Receipt'}
                    onCancel={onCancel}
                    context={context}
                  />
                  <div className="justify-content-end row">
                    <div className="col-3 pt-4">
                      <Button
                        type="button"
                        color="secondary"
                        onClick={onCancel}
                        className="col-12"
                        disabled={isSending}
                      >
                        Back
                      </Button>
                    </div>
                    {isOrgAdminOrOperation && (
                      <div className="col-3 pt-4">
                        <Button
                          type="submit"
                          form={'WarehouseReceiptForm'}
                          color="primary"
                          className="btn-block"
                          disabled={isSending}
                          isSending={isSending}
                        >
                          Save Warehouse Receipt
                        </Button>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
