import React, { useEffect, useState, createContext } from 'react';
import { CommodityDto } from '../../../models/data.models';
import { Button } from '../../common/components/button/button.component';
import { CargoFragment } from '../../airShipmentOrders/wizard/components/cargoFragment.component';

export type CommoditiesSelectorProps = {
  onCommoditiesSelect?: (commodities: CommodityDto[]) => void;
  onCancel?: () => void;
};

export const CommoditiesSelector = ({
  onCommoditiesSelect,
  onCancel,
}: CommoditiesSelectorProps) => {
  const CommodityLOcationContext = createContext(null);
  const [commodityIds, setCommodityIds] = useState<number[]>([]);
  const [commodities, setCommodities] = useState<CommodityDto[]>([]);

  return (
    <div>
      <CargoFragment
        commodities={commodities}
        setCommodities={setCommodities}
        commodityIds={commodityIds}
        setCommodityIds={setCommodityIds}
      />
      <CommodityLOcationContext.Provider
        value={{ commodities, setCommodities }}
      >
        <div className="justify-content-end row">
          <div className="col-3">
            <Button
              type="button"
              color="primary"
              onClick={() => onCommoditiesSelect(commodities)}
              className="w-100 btn-secondary"
            >
              Save
            </Button>
          </div>
          <div className="col-3">
            <Button
              type="button"
              color="primary"
              onClick={onCancel}
              className="w-100 btn-secondary"
            >
              Close
            </Button>
          </div>
        </div>
      </CommodityLOcationContext.Provider>
    </div>
  );
};
