import React, { useState } from 'react';
import { PackageReceiveForm } from '../../packageReceiveWizard/packageReceive.form';
import * as Yup from 'yup';
import {
  CargoMovementType,
  ChargeDto,
  ChargeStatuses,
  CommodityDto,
  CommodityStatuses,
  ContactType,
  DimensionsUnit,
  ItemType,
  LocationType,
  OrderContextValues,
  OrderDto,
  OrderTypes,
  VolumeUnit,
  WeightUnit,
} from '../../../../models/data.models';
import {
  GetMovementByPalletNumberParams,
  PatchOrderParams,
} from '../../../orders/orders.service';
import {
  createOrder,
  getCargoMovementByPalletNumber,
  patchOrder,
  updateOrder,
} from '../../../orders/orders.store';
import { Button } from '../../../common/components/button/button.component';
import { getWarehouseLocationsFx } from '../../../warehouseLocations/warehouseLocations.store';
import { updateCommodities } from '../../../commodities/commodities.store';
import {
  CommodityDefaultValues,
  OrderDefaultValues,
} from '../../../common/DefaultValues';
import { authStore } from '../../../auth/auth.store';
import { getContacts } from '../../../contacts/contacts.store';
import { organizationsStore } from '../../../organization/organization.store';
import { addMessage, Message } from '../../../common/messages.store';
import { getEnumKeyByValue } from '../../../../utils/helper.utils';
import { useTrackingNumberHandler } from '../../hooks/useTrackingNumberHandler';
import { v4 } from 'uuid';
import { createActionEventFx } from '../../../actionEvents/actionEvents.store';
import { triggerWorkflowByConfig } from '../../../workflowTriggers/workflowTriggerHook';

const commodityParams: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  commodityTypeId: null,
  commodityTypeCode: CommodityDefaultValues.description,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  customValues: CommodityDefaultValues.customValues,
  valueByTotal: CommodityDefaultValues.valueByTotal,
};

export type PalletProps = {
  locationType: LocationType;
  movementType: CargoMovementType;
  currentLocation: LocationType;
  buttonName: string;
  buttonColor?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light'
    | 'action-waiting'
    | 'action-secondary'
    | 'dark';
  buttonOutline?: boolean;
  label: string;
  commodities: CommodityDto[];
  dispatcherId: number;
  divisionId: number;
  orderContextValues?: any;
  trackingNumbers?: any;
  parcelShipment?: OrderDto | null;
  purchase?: OrderDto | null;
  onComplete?: (data: any) => void;
  onUpdateAttachments?: (order: OrderDto) => void;
  setNotification?: (notification: string) => void;
  setOrderContextValues?: React.Dispatch<
    React.SetStateAction<OrderContextValues>
  >;
  isVerifiedAll?: boolean;
  attachments?: any;
};

const palletSchema = (locationType) =>
  Yup.object().shape({
    [`${locationType}PalletNumber`]: Yup.string()
      .required("Can't be blank")
      .nullable(true),
  });

export const PalletComponent = ({
  locationType,
  movementType,
  currentLocation,
  buttonName,
  buttonColor = 'primary',
  buttonOutline = false,
  label,
  commodities,
  dispatcherId,
  divisionId,
  onComplete,
  orderContextValues,
  trackingNumbers,
  parcelShipment,
  purchase,
  onUpdateAttachments,
  setNotification,
  setOrderContextValues,
  isVerifiedAll,
  attachments,
}: PalletProps) => {
  const palletInitialState = () => {
    const initialState = {
      [`${locationType}PalletNumber`]:
        sessionStorage.getItem(`${locationType}PalletNumber`) == 'undefined'
          ? ''
          : sessionStorage.getItem(`${locationType}PalletNumber`),
    };
    return initialState;
  };

  const { user: currentUser } = authStore.getState();
  const { currentOrganization } = organizationsStore.getState();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initialState, setInitialState] = useState<any>(palletInitialState());

  const { onTrackingNumberSubmit } = useTrackingNumberHandler();

  const linkCommodities = (
    createdOrder: OrderDto,
    createdCommodities?: CommodityDto[],
  ) => {
    let commodityParams;

    if (commodities) {
      commodityParams = commodities?.map((commodity) => {
        return {
          commodityId: commodity.commodityId,
          orderId: createdOrder?.orderId,
        };
      });
    } else {
      commodityParams = createdCommodities?.map((commodity) => {
        return {
          commodityId: commodity.commodityId,
          orderId: createdOrder?.orderId,
        };
      });
    }

    const patchItems = [];
    const commodityIds = createdOrder.commodities.map(
      (commodity) => commodity.commodityId,
    );

    commodityParams.forEach((commodity) => {
      if (!commodityIds.includes(commodity.commodityId)) {
        patchItems.push({
          path: '/OrderCommodities/-',
          op: 'add',
          value: commodity,
        });
      }
    });

    const patchOrderParams: PatchOrderParams = {
      resource: { links: createdOrder?.links },
      patchOrderCommand: {
        patchItems: patchItems,
      },
    };

    return patchOrder(patchOrderParams);
  };

  const getOrderInitialState = () => {
    const initialState: OrderDto = {
      createdByUserName: '',
      lastModifiedByUserName: '',
      carriers: null,
      totalPcsCrt: 0,
      weighTotal: 0,
      volumeTotal: 0,
      orderId: null,
      billToContactId: orderContextValues?.billToContactId,
      carrierContactId: OrderDefaultValues.carrierContactId,
      orderStatusId: OrderDefaultValues.orderStatusId,
      created: new Date(),
      createdBy: '',
      employeeContactId: OrderDefaultValues.employeeContactId,
      lastModified: new Date(),
      lastModifiedBy: '',
      orderNumber: '',
      organizationId: null,
      salespersonContactId: OrderDefaultValues.salespersonContactId,
      commodities: [],
      charges: [],
      trackingEvents: [],
      divisionId: OrderDefaultValues.divisionId,
      orderStatus: OrderDefaultValues.orderStatus,
      orderEntities: [],
      links: [],
    };
    return initialState;
  };

  const onSubmit = async (
    data: any,
    submitLocationType: LocationType,
    submitMovementType: CargoMovementType,
  ) => {
    if (
      !orderContextValues?.billToContactId &&
      submitLocationType === LocationType.PutAway
    ) {
      setOrderContextValues((prev) => {
        prev.billToContactId = undefined;
        return { ...prev };
      });
      addMessage({
        message:
          'Please add a customer account or check that the purchase is unassigned',
        type: 'danger',
        id: v4(),
      });
      return;
    }

    if (submitLocationType === LocationType.Packing && !isVerifiedAll) {
      addMessage({
        message: `Packages Verification Required: Packages are Not Verified`,
        type: 'warning',
        id: v4(),
      });
      return;
    }

    setIsLoading(true);

    const workflowExecutionVariables = {
      purchaseOrderId: purchase?.orderId,
      commodities: commodities || [],
      currentLocationWarehouse: currentLocation,
      billToContactId: orderContextValues?.billToContactId,
      movementType: submitMovementType,
      locationType: submitLocationType,
      palletNumber: data[`${submitLocationType}PalletNumber`],
      trackingNumbers,
      description:
        orderContextValues?.packageDescription &&
        orderContextValues?.packageDescription?.trim() != ''
          ? orderContextValues?.packageDescription
          : trackingNumbers?.length > 0
          ? trackingNumbers[0]?.trackingNumber
          : 'No description',
      packageDescription:
        orderContextValues.packageDescription &&
        orderContextValues.packageDescription.trim(),
      attachments: attachments || [],
      divisionId: currentUser.divisionId,
    };

    let result;
    if (submitMovementType === CargoMovementType.Service) {
      result = await triggerWorkflowByConfig({
        configName: 'tms.modules.receiving',
        workflowIdConfigKey: 'serviceWorkflowId',
        workflowVariables: workflowExecutionVariables,
      });
    } else {
      result = await triggerWorkflowByConfig({
        configName: 'tms.modules.receiving',
        workflowIdConfigKey: 'receivingWorkflowId',
        workflowVariables: workflowExecutionVariables,
      });
    }

    if (result) {
      if (setNotification) {
        const trackingNumber =
          purchase?.trackingNumber || trackingNumbers[0]?.trackingNumber;
        setNotification(
          `Purchase #${trackingNumber} was sent to ${submitLocationType}. Pallet Number #${
            data[`${submitLocationType}PalletNumber`]
          }`,
        );
      }

      const message: Message = {
        id: 'receiving-success',
        type: 'success',
        autoHide: true,
        message: 'Completed successfully!',
      };
      addMessage(message);

      if (submitMovementType === CargoMovementType.Service) {
        const messageReason: Message = {
          id: 'status-set-problem',
          type: 'success',
          autoHide: true,
          message: 'Status set to Problem with reason ' + result.outputs.reason,
        };
        addMessage(messageReason);
      }

      setIsLoading(false);
      onComplete({
        commodities: JSON.stringify(commodities),
      });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <PackageReceiveForm
          id={`PalletForm${locationType}`}
          initialValues={initialState}
          validationSchema={palletSchema(locationType)}
          onSubmit={(data) => onSubmit(data, locationType, movementType)}
        >
          <div>
            <PackageReceiveForm.PalletNumber
              label={label}
              name={`${locationType}PalletNumber`}
              onChange={(event) =>
                sessionStorage.setItem(
                  `${locationType}PalletNumber`,
                  event?.target?.value,
                )
              }
              barcode={true}
            />
          </div>
        </PackageReceiveForm>
      </div>
      <div className="align-self-center">
        <Button
          className="mt-3 w-100"
          color={buttonColor}
          outline={buttonOutline}
          type="submit"
          form={`PalletForm${locationType}`}
          style={{ minWidth: '9.5rem' }}
          disabled={isLoading}
          isSending={isLoading}
        >
          {buttonName}
        </Button>
      </div>
    </>
  );
};
