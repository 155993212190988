import {
  ChargeDto,
  Column,
  CreateChargeCommandValues,
  LinkDto,
  LinkResourceBaseDto,
  OrderDto,
  UpdateChargeCommandValues,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  CalculateChargesAmountParams,
  CalculateChargesAmountRequest,
  CompleteChargeCommandValues,
  completeChargeRequest,
  createChargeRequest,
  deleteChargeRequest,
  GetChargeParams,
  getChargeRequest,
  getChargesListRequest,
  updateChargeRequest,
} from './charges.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';
type ChargesStoreState = {
  links: LinkDto[];
  chargeColumns: Column[];
};

export const updateChargesColumns = createEvent<Column[]>();
export const updateChargesServicesColumns = createEvent<Column[]>();

export const getChargesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getChargesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getChargesFx);

export const createChargeFx = createEffect(
  (createChargeArgs: {
    data: CreateChargeCommandValues;
    options?: { dryRun?: boolean };
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createChargeCommand: CreateChargeCommandValues = {
      ...createChargeArgs.data,
    };

    return createChargeRequest(
      currentOrganization,
      createChargeCommand,
      createChargeArgs.options,
    );
  },
);

addEffectStatusHandling(createChargeFx, {
  completeMessage: (params: {
    data: CreateChargeCommandValues;
    options?: { dryRun?: boolean };
  }) => {
    return params.options?.dryRun ? null : 'Charge was created successfully';
  },
  errorMessage: (params: {
    data: CreateChargeCommandValues;
    options?: { dryRun?: boolean };
  }) =>
    params.options?.dryRun ? 'Validation Failed' : 'Charge was not created',
});

export const getChargeFx = createEffect((chargeParams: GetChargeParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getChargeRequest(
    currentOrganization as LinkResourceBaseDto,
    chargeParams,
  );
});

addEffectStatusHandling(getChargeFx);

export const updateChargeFx = createEffect(
  (updateChargeArgs: { data: ChargeDto; options?: { dryRun?: boolean } }) => {
    const updateChargeCommand: UpdateChargeCommandValues = {
      ...updateChargeArgs.data,
    };

    return updateChargeRequest(
      updateChargeArgs.data,
      updateChargeCommand,
      updateChargeArgs.options,
    );
  },
);

addEffectStatusHandling(updateChargeFx, {
  completeMessage: (params: {
    data: ChargeDto;
    options?: { dryRun?: boolean };
  }) => {
    return params.options?.dryRun ? null : 'Charge was updated successfully';
  },
  errorMessage: (params: { data: ChargeDto; options?: { dryRun?: boolean } }) =>
    params.options?.dryRun ? 'Validation Failed' : 'Charge was not updated',
});

export const deleteChargeFx = createEffect((charge: ChargeDto) => {
  return deleteChargeRequest(charge);
});

addEffectStatusHandling(deleteChargeFx, {
  completeMessage: 'Charge was deleted successfully',
  errorMessage: 'Charge was not deleted',
});

export const calculateChargesAmountFx = createEffect(
  (request: CalculateChargesAmountParams) => {
    const { currentOrganization } = organizationsStore.getState();
    return CalculateChargesAmountRequest(currentOrganization, request);
  },
);

export const completeChargeFx = createEffect((chargeId: number) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const completeChargeCommand: CompleteChargeCommandValues = {
    organizationId: currentOrganization.organizationId,
    chargeId,
  };
  return completeChargeRequest(
    currentOrganization as LinkResourceBaseDto,
    completeChargeCommand,
  );
});

const defaultState: ChargesStoreState = {
  links: [],
  chargeColumns: [
    { name: 'chargeStatus', visible: true, title: 'Status' },
    { name: 'description', visible: true, title: 'Description' },
    { name: 'prepaid', visible: true, title: 'Prepaid' },
    { name: 'quantity', visible: true, title: 'Quantity' },
    { name: 'formattedPrice', visible: true, title: 'Price' },
    { name: 'formattedAmount', visible: true, title: 'Amount' },
    { name: 'salesTaxCode', visible: false, title: 'Tax Code' },
    { name: 'salesTaxRate', visible: true, title: 'Tax Rate' },
    { name: 'salesTaxAmount', visible: true, title: 'Tax Amount' },
    { name: 'amountAndTaxAmount', visible: true, title: 'Amount + Tax Amount' },
    { name: 'currencySymbol', visible: true, title: 'Currency' },
    { name: 'formattedExpense', visible: true, title: 'Expense' },
    { name: 'formattedIncome', visible: true, title: 'Income' },
    { name: 'applyToContactName', visible: true, title: 'Apply To' },
  ],
};

const chargeForAccountingTransactionState: ChargesStoreState = {
  links: [],
  chargeColumns: [
    { name: 'chargeStatus', visible: true, title: 'Status' },
    { name: 'description', visible: true, title: 'Description' },
    { name: 'prepaid', visible: true, title: 'Prepaid' },
    { name: 'quantity', visible: true, title: 'Quantity' },
    { name: 'formattedPrice', visible: true, title: 'Price' },
    { name: 'formattedAmount', visible: true, title: 'Amount' },
    { name: 'salesTaxCode', visible: true, title: 'Tax Code' },
  ],
};

const chargeColumnsForServices: ChargesStoreState = {
  links: [],
  chargeColumns: [
    {
      name: 'chargeStatus',
      visible: true,
      title: 'Status',
      width: '30%',
      sortName: 'chargeStatus',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      width: '30%',
      sortName: 'description',
    },
    {
      name: 'completion',
      visible: true,
      title: 'Completion',
      type: 'checkBox',
      width: '20%',
      sortName: 'chargeStatus',
    },
  ],
};

export const chargeStore: Store<ChargesStoreState> = createStore(
  defaultState,
).on(updateChargesColumns, (state, payload) => {
  return { ...state, chargeColumns: payload };
});

export const chargeStoreForAccountingTransaction: Store<ChargesStoreState> = createStore(
  chargeForAccountingTransactionState,
).on(updateChargesColumns, (state, payload) => {
  return { ...state, chargeColumns: payload };
});

export const chargeStoreForServices: Store<ChargesStoreState> = createStore(
  chargeColumnsForServices,
).on(updateChargesServicesColumns, (state, payload) => {
  return { ...state, chargeColumns: payload };
});
