import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { Checkbox } from '../../common/components/checkbox/checkbox.component';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { SelectStatePaginate } from '../../states/components/state-react-select.component';
import { SelectPortPaginate } from '../../ports/components/port-react-select.component';

export const DivisionForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};
DivisionForm.DivisionName = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'divisionName'}
      label={'Division Name'}
      placeholder={'Enter Division Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.Phone = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'phoneNumber'}
      label={'Phone'}
      placeholder={'Enter Phone'}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.Fax = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'faxNumber'}
      label={'Fax'}
      placeholder={'Enter Fax'}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.Email = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'email'}
      label={'Email'}
      placeholder={'Enter Email'}
      type={'email'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.UseDivisionInDocumentHeaders = ({
  className,
  defaultValue,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'useDivisionInDocumentHeaders'}
      label={'Use this division in document headers'}
      className={className}
      type={'checkbox'}
      style={style}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
DivisionForm.AssignDivisionToEntities = ({
  className,
  defaultValue,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'assignDivisionToEntities'}
      label={'Assign this division to entities created by it’s employees'}
      className={className}
      type={'checkbox'}
      style={style}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
DivisionForm.Comments = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'comments'}
      label={'Comments'}
      placeholder={'Enter Comments'}
      type={'textarea'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.StreetAndNumber = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'streetAndNumber'}
      label={'Street & Number'}
      placeholder={'Enter Street & Number'}
      type={'textarea'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.City = ({
  className,
  style,
  size,
  ref,
  name = 'city',
  label = 'City',
  placeholder = 'City',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.Country = ({
  selectedFilter = ``,
  placeholder = 'Select Country',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'countryCode',
  header = 'Country',
  onChange = () => {},
  readonly,
  nameId = 'countryName',
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
    />
  );
};
DivisionForm.State = ({
  selectedFilter = ``,
  placeholder = 'Select State',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'stateCode',
  header = 'State',
  onChange = () => {},
  readonly,
  nameId = 'stateName',
}: InputPublicProps) => {
  return (
    <SelectStatePaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeState={onChange}
    />
  );
};
DivisionForm.ZipCode = ({
  className,
  style,
  size,
  ref,
  name = 'zipCode',
  label = 'Zip Code',
  placeholder = 'Zip Code',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
DivisionForm.Port = ({
  selectedFilter = ``,
  placeholder = 'Select Port',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'portId',
  header = 'Port',
  onChange = () => {},
  readonly,
  nameId = 'portName',
}: InputPublicProps) => {
  return (
    <SelectPortPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePort={onChange}
    />
  );
};
DivisionForm.AirAMSOriginatorCode = ({
  className,
  style,
  size,
  ref,
  name = 'airAmsOriginatorCode',
  label = 'Air AMS originator code',
  placeholder = 'Air AMS originator code',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
