import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DiscountScreen } from './screens/discount.screen';
import { DiscountsListScreen } from './screens/discounts-list.screen';

export const DISCOUNT_LIST_PATH = '/discounts';
export const DISCOUNT_EDIT_PATH = '/discounts/:discountId';
export const DISCOUNT_CREATE_PATH = '/discounts/0';

export const DiscountsRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <DiscountsListScreen pageTitle="Discounts" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <DiscountScreen pageTitle="Add New Discount" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':discountId'}
        element={
          <PrivateRoute>
            <DiscountScreen pageTitle="Update Discount" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
