import {
  VesselDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateVesselCommandValues,
  UpdateVesselCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createVesselRequest,
  getVesselsListRequest,
  getVesselRequest,
  deleteVesselRequest,
  updateVesselRequest,
  importVesselsRequest,
  exportVesselsRequest,
  GetVesselParams,
} from './vessels.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type VesselsStoreState = {
  links: LinkDto[];
  vesselColumns: EntityFieldDto[];
  defaultVesselColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getVesselsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Vessel',
  });
});

addEffectStatusHandling(getVesselsColumnsFx);

export const getVesselsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Vessel',
  });
});

addEffectStatusHandling(getVesselsDefaultColumnsFx);

export const updateVesselsColumns = createEvent<EntityFieldDto[]>();

export const getVesselsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getVesselsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getVesselsFx);

export const createVesselFx = createEffect((vesselData: VesselDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createVesselCommand: CreateVesselCommandValues = { ...vesselData };

  return createVesselRequest(currentOrganization!, createVesselCommand);
});

addEffectStatusHandling(createVesselFx, {
  completeMessage: 'Vessel was created successfully',
  errorMessage: 'Vessel was not created',
});

export const getVesselFx = createEffect((vesselParams: GetVesselParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getVesselRequest(
    currentOrganization as LinkResourceBaseDto,
    vesselParams,
  );
});

addEffectStatusHandling(getVesselFx);

export const updateVesselFx = createEffect((vessel: VesselDto) => {
  const updateVesselCommand: UpdateVesselCommandValues = { ...vessel };
  return updateVesselRequest(vessel, updateVesselCommand);
});

addEffectStatusHandling(updateVesselFx, {
  completeMessage: 'Vessel was updated successfully',
  errorMessage: 'Vessel was not updated',
});

export const deleteVesselFx = createEffect((vessel: VesselDto) => {
  return deleteVesselRequest(vessel);
});

addEffectStatusHandling(deleteVesselFx, {
  completeMessage: 'Vessel was deleted successfully',
  errorMessage: 'Vessel was not deleted',
});

export const exportVesselsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportVesselsRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportVesselsFx, {
  completeMessage: 'Vessels were exported successfully',
  errorMessage: 'Vessels were not exported',
});

export const importVesselsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importVesselsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importVesselsFx, {
  completeMessage: 'Vessels were imported successfully',
  errorMessage: 'Vessels were not imported',
  inFlightMessage: 'Importing vessels...',
});

const defaultState: VesselsStoreState = {
  links: [],
  vesselColumns: [],
  defaultVesselColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const vesselStore: Store<VesselsStoreState> = createStore(defaultState)
  .on(getVesselsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      vesselColumns: payload.result.items,
      defaultVesselColumns: payload.result.items,
    };
  })
  .on(getVesselsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultVesselColumns: payload.result.items,
    };
  })
  .on(updateVesselsColumns, (state, payload) => {
    return { ...state, vesselColumns: payload };
  });
