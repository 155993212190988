import {
  PortDto,
  LinkDto,
  LinkResourceBaseDto,
  PortDtoPagedResult,
  FileType,
  CreatePortCommandValues,
  UpdatePortCommandValues,
} from '../../models/data.models';
import { downloadLinkFile, execLink, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_PORTS_LINK_KEY = 'get-ports';
export const CREATE_PORT_LINK_KEY = 'create-port';
export const GET_PORT_LINK_KEY = 'get-port';
export const UPDATE_PORT_LINK_KEY = 'update-port';
export const DELETE_PORT_LINK_KEY = 'delete-port';
export const UPDATEALL_PORTS_LINK_KEY = 'updateall-ports';
export const IMPORT_PORTS_LINK_KEY = 'import-ports';
export const EXPORT_PORTS_LINK_KEY = 'export-ports';
export const PORT_ENTITY_NAME = 'Port';

export interface GetPortParams {
  portId?: string | number;
  countryCode: string;
}

export interface ImportPortsParams {
  organizationId: number;
}

export interface ExportPortsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getPortRequest = async (
  resource: LinkResourceBaseDto | null,
  { portId, countryCode }: GetPortParams,
): Promise<PortDto> => {
  if (resource && portId && countryCode) {
    const getPortLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PORT_LINK_KEY,
    );
    if (getPortLink) {
      const result = await execLink(getPortLink, { portId, countryCode });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PORT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getPortsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PortDtoPagedResult> => {
  if (resource) {
    const getPortsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PORTS_LINK_KEY,
    );
    if (getPortsListLink) {
      const result = await execLink(getPortsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PORTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createPortRequest = async (
  resource: LinkResourceBaseDto,
  port: CreatePortCommandValues,
) => {
  const createPortLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PORT_LINK_KEY,
  );
  if (createPortLink) {
    const result = await execLink(createPortLink, port);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_PORT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updatePortRequest = async (
  resource: LinkResourceBaseDto,
  port: UpdatePortCommandValues,
) => {
  const updatePortLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PORT_LINK_KEY,
  );
  if (updatePortLink) {
    const result = await execLink(updatePortLink, port);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_PORT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deletePortRequest = async (port: PortDto) => {
  const deletePortLink = port.links?.find(
    (x: LinkDto) => x.rel === DELETE_PORT_LINK_KEY,
  );
  if (deletePortLink) {
    const result = await execLink(deletePortLink, port);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_PORT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importPortsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportPortsParams,
  file: File,
) => {
  const importPortsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_PORTS_LINK_KEY,
  );
  if (importPortsLink) {
    const result = await postFormData(importPortsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_PORTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportPortsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportPortsParams,
) => {
  const exportPortsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_PORTS_LINK_KEY,
  );
  if (exportPortsLink) {
    const result = await downloadLinkFile(exportPortsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_PORTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateAllPortsRequest = async (
  resource?: LinkResourceBaseDto | null,
): Promise<void> => {
  if (resource) {
    const updateAllPortsLink = resource?.links?.find(
      (x: LinkDto) => x.rel === UPDATEALL_PORTS_LINK_KEY,
    );
    if (updateAllPortsLink) {
      await execLink(updateAllPortsLink);
      return;
    }
  }
  addMessage({
    message: `Action ${UPDATEALL_PORTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
