import React, { useEffect, useState } from 'react';
import { OrderEditFormDto } from '../../../../models/custom.models';
import { Button } from '../../../common/components/button/button.component';
import { Field } from 'formik';
import {
  getFormattedDate,
  getFormattedTime,
} from '../../../../utils/formatting.utils';
import { getCommodities } from '../../../commodities/commodities.store';
import { getDocumentTemplatesFx } from '../../../documentTemplates/documentTemplates.store';
import {
  CommodityDtoPagedResult,
  DocumentTemplateDtoPagedResult,
} from '../../../../models/data.models';

export type CompletingFragmentProps = {
  airOrderContextFormValue: OrderEditFormDto;
  commodityIds: number[];
  documentsIds: number[];
  handleBack: () => void;
};

export const CompletingFragment = ({
  airOrderContextFormValue,
  documentsIds,
  commodityIds,
  handleBack,
}: CompletingFragmentProps) => {
  const [templates, setTemplates] = useState<DocumentTemplateDtoPagedResult>(
    null,
  );
  const [commodities, setCommodities] = useState<CommodityDtoPagedResult>(null);
  const [information, setInformation] = useState<string>('');

  useEffect(() => {
    if (documentsIds?.length > 0) {
      getDocumentTemplatesFx({
        search: '',
        limit: 1000,
        offset: 0,
        sort: '',
        filter: documentsIds
          .map((id) => `documentTemplateId: ${id}`)
          .join(' OR '),
      }).then((result) => {
        setTemplates(result);
      });
    }
  }, []);

  useEffect(() => {
    if (commodityIds?.length > 0) {
      getCommodities({
        search: '',
        limit: 1000,
        offset: 0,
        sort: '',
        filter: commodityIds.map((id) => `commodityId: ${id}`).join(' OR '),
      }).then((result) => {
        setCommodities(result);
      });
    }
  }, []);

  useEffect(() => {
    setInformation(getCompletedInformation());
  }, [templates, commodities]);

  const getCompletedInformation = () => `
    Carrier: ${
      airOrderContextFormValue?.carriers
        ? `${airOrderContextFormValue?.carriers[0]?.contactFirstName} ${airOrderContextFormValue?.carriers[0]?.contactLastName}`
        : ''
    }
    Employee: ${airOrderContextFormValue?.employeeContactName || ''}
    Division: ${airOrderContextFormValue?.divisionName || ''}
    Air waybill number: ${
      airOrderContextFormValue?.customValues['waybillNumber'] || ''
    }
    Booking number: ${
      airOrderContextFormValue?.customValues['bookingNumber'] || ''
    }
    Executed place: ${
      airOrderContextFormValue?.customValues['portIdName'] || ''
    }
    Executed date: ${
      getFormattedDate(
        airOrderContextFormValue?.customValues['executedDate'],
        false,
      ) || ''
    }
    Declared value for carriage: ${
      airOrderContextFormValue.customValues['declaredValueCarriage'] || ''
    }
    Declared value for customs: ${
      airOrderContextFormValue.customValues['declaredValueCustoms'] || ''
    }
    Shipper: ${airOrderContextFormValue?.shipper?.contactName || ''}
    Shipper address: ${
      airOrderContextFormValue?.shipper?.contactAddressName || ''
    }
    Ultimate consignee: ${
      airOrderContextFormValue?.ultimateConsignee?.contactName || ''
    }
    Ultimate consignee address: ${
      airOrderContextFormValue?.ultimateConsignee?.contactAddressName || ''
    }
    Consignee: ${airOrderContextFormValue?.consignee?.contactName || ''}
    Consignee address: ${
      airOrderContextFormValue?.consignee?.contactAddressName || ''
    }
    Notify parry: ${airOrderContextFormValue?.notifyParty?.contactName || ''}
    Notify parry address: ${
      airOrderContextFormValue?.notifyParty?.contactAddressName || ''
    }
    Intermediate: ${airOrderContextFormValue?.intermediate?.contactName || ''}
    Intermediate address: ${
      airOrderContextFormValue?.intermediate?.contactAddressName || ''
    }
    Forwarding agent: ${
      airOrderContextFormValue?.forwardingAgent?.contactName || ''
    }
    Forwarding agent address: ${
      airOrderContextFormValue?.forwardingAgent?.contactAddressName || ''
    }
    Destination agent: ${
      airOrderContextFormValue?.destinationAgent?.contactName || ''
    }
    Destination agent address: ${
      airOrderContextFormValue?.destinationAgent?.contactAddressName || ''
    }
    Service type: ${airOrderContextFormValue?.customValues['serviceType'] || ''}
    Transportation mode: ${
      airOrderContextFormValue?.customValues[
        'modeOdTransportationDescription'
      ] || ''
    }
    Flight: ${airOrderContextFormValue?.customValues['flightNumber'] || ''}
    Departure from: ${
      airOrderContextFormValue?.customValues['departurePortId'] || ''
    }
    Departure date: ${
      getFormattedDate(
        airOrderContextFormValue?.customValues['departureDate'],
        false,
      ) || ''
    }
    Departure time: ${getFormattedTime(
      airOrderContextFormValue?.customValues['departureTime'],
    )}
    Arrive to: ${airOrderContextFormValue?.customValues['arrivePortId'] || ''}
    Arrival date: ${
      getFormattedDate(
        airOrderContextFormValue?.customValues['arriveDate'],
        false,
      ) || ''
    }
    Arrival time: ${getFormattedTime(
      airOrderContextFormValue?.customValues['arriveTime'],
    )}
    Carriers:
    ${airOrderContextFormValue?.orderEntityCarriers?.map(
      (carrier) =>
        `${carrier?.contactName || ''} ${
          carrier?.customValues['carrierAirportId'] || ''
        } ${carrier?.customValues['carrierAirportId'] || ''} ${
          carrier?.customValues['carrierFlightNumber'] || ''
        } ${
          getFormattedDate(carrier?.customValues['carrierFlightDate'], false) ||
          ''
        } `,
    )}
    Documents:
    ${
      templates?.items?.length > 0
        ? templates?.items?.map(
            (template) => `${template?.documentTemplateType} `,
          )
        : ''
    }
    Commodities:
    ${
      commodities?.items?.length > 0
        ? commodities?.items?.map((commodity) => {
            return commodity?.description + ' ';
          })
        : ''
    }
  `;
  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <h2>Completing the air consolidation wizard</h2>
        </div>
      </div>
      <Field
        as={'textarea'}
        className={`form-control form-control-md`}
        style={{ resize: 'none' }}
        name={`values`}
        id={`values`}
        label={''}
        required={false}
        type={'textarea'}
        disabled={true}
        defaultValue={information}
        key={'test'}
        rows={15}
        useFormikContext={false}
      />
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button
          type="submit"
          form={'airOrderForm'}
          color="primary"
          disabled={false}
          isSending={false}
          data-testid={'save-air-order'}
        >
          Save Air Order
        </Button>
        <Button className="mr-3" onClick={handleBack} data-testid={'back'}>
          Back
        </Button>
      </div>
    </div>
  );
};
