import React, { useRef } from 'react';
import Select from 'react-select';
import { ErrorMessage, Field } from 'formik';
import { FormContext } from '../form/form.component';
import { clearMessages } from '../../messages.store';

type ReactSelectProps = {
  id?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  options?: any[];
  multiple?: boolean;
  value?: any;
  defaultValue?: any;
  error?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  onChange?: (data: any, context?: any) => void;
  useContext?: boolean;
  readonly?: 'readonly' | '';
  isSearchable?: boolean;
  classNamePrefix?: string;
  validate?: (data: any) => Promise<any>;
  header?: string;
  getOptionLabel?: (option: any) => any;
  getOptionValue?: (option: any) => any;
  onClick?: (event) => any;
  tabIndex?: number;
  customStyles?: any;
  size?: any;
};

export const ReactEnumInputSelect = ({
  id,
  name,
  disabled,
  required,
  options,
  multiple,
  defaultValue,
  isClearable = false,
  onChange,
  placeholder,
  useContext = true,
  readonly = '',
  isSearchable = false,
  classNamePrefix = 'select',
  header,
  getOptionLabel = (option) => option?.label,
  getOptionValue = (option) => option?.value,
  onClick = (event) => {},
  tabIndex,
  customStyles,
  size,
}: ReactSelectProps) => {
  const onChangeInput = (option, contextDto?) => {
    if (typeof onChange === 'function') {
      onChange(option, contextDto);
    }
  };

  let restoredValue = defaultValue;
  if (!defaultValue?.label && typeof defaultValue === 'string') {
    restoredValue = options.find((el) => el.value === defaultValue);
  }

  if (!useContext) {
    return (
      <div style={size}>
        <div>
          <Select
            styles={customStyles}
            id={id}
            name={name}
            isDisabled={disabled}
            required={required}
            options={options}
            isMulti={multiple}
            isSearchable={readonly === 'readonly' ? false : isSearchable}
            isClearable={readonly === 'readonly' ? false : isClearable}
            menuIsOpen={readonly === 'readonly' ? false : undefined}
            classNamePrefix={`${id} ${classNamePrefix}`}
            value={restoredValue}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder={placeholder}
            onChange={(selected) => {
              onChangeInput(selected);
            }}
            onFocus={clearMessages}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onClick={onClick}
            tabIndex={tabIndex}
          />
        </div>
      </div>
    );
  } else {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div>
            <label className="input-label" htmlFor={name}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={name}
              value={
                multiple
                  ? defaultValue?.map((value) => getOptionValue(value))
                  : getOptionValue(restoredValue)
              }
            />
            <Select
              styles={customStyles}
              id={id}
              name={name}
              isDisabled={disabled}
              isClearable={readonly === 'readonly' ? false : isClearable}
              required={required}
              options={options}
              isMulti={multiple}
              classNamePrefix={`${id} ${classNamePrefix}`}
              isSearchable={readonly === 'readonly' ? false : isSearchable}
              menuIsOpen={readonly === 'readonly' ? false : undefined}
              value={restoredValue}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={clearMessages}
              onBlur={() => {
                context.setFieldTouched(name, true);
              }}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onClick={onClick}
              tabIndex={tabIndex}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  }
};
