import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { QUOTE_CREATE_PATH, QUOTE_LIST_PATH } from '../quote.route';
import { QuoteEdit } from '../components/quote-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../../orders/orders.service';
import { userHas } from '../../auth/auth.store';

export type QuoteScreenProps = { pageTitle: string; group: string };

export const QuoteScreen = ({ pageTitle, group }: QuoteScreenProps) => {
  const navigate = useNavigate();

  // @ts-ignore
  const { organizationId, quoteId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + QUOTE_CREATE_PATH, {
    organizationId,
  });

  const onQuoteCreated = () => {
    const path = generatePath(INTERNAL_PATH + QUOTE_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'quote-created',
      type: 'success',
      autoHide: true,
      message: 'Quote successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onQuoteUpdated = () => {
    const path = generatePath(INTERNAL_PATH + QUOTE_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'quote-updated',
      type: 'success',
      autoHide: true,
      message: 'Quote successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + QUOTE_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Quotes'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <QuoteEdit
        orderId={quoteId}
        onQuoteUpdated={onQuoteUpdated}
        onQuoteCreated={onQuoteCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
