import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { DISCOUNT_CREATE_PATH, DISCOUNT_LIST_PATH } from '../discounts.route';
import { DiscountEdit } from '../components/discount-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_DISCOUNT_LINK_KEY } from '../discounts.service';
import { userHas } from '../../auth/auth.store';

export type OrderScreenProps = { pageTitle: string; group: string };

export const DiscountScreen = ({ pageTitle, group }: OrderScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, discountId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + DISCOUNT_CREATE_PATH, {
    organizationId,
  });

  const onDiscountCreated = () => {
    const path = generatePath(INTERNAL_PATH + DISCOUNT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-created',
      type: 'success',
      autoHide: true,
      message: 'Discount successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onDiscountUpdated = () => {
    const path = generatePath(INTERNAL_PATH + DISCOUNT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-updated',
      type: 'success',
      autoHide: true,
      message: 'Discount successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + DISCOUNT_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Discounts'}
      createPath={userHas(CREATE_DISCOUNT_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <DiscountEdit
        discountId={discountId}
        onDiscountUpdated={onDiscountUpdated}
        onDiscountCreated={onDiscountCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
