import React, {
  useState,
  useEffect,
  CSSProperties,
  createContext,
  useContext,
} from 'react';

import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import { Button } from '../button/button.component';
import { showDialog } from '../../../common/dialog.store';
import { ContactDialog } from '../../../contacts/components/contact.dialog';
import { Pagination } from '../pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import { Column } from '../../../../models/data.models';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import {
  FilteredField,
  FiltersTab,
  GridContextValue,
} from '../filters/filtersTab.component';

export type GridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  filterContextValues?: any;
  total?: number;
  data?: any[];
  showPagination?: boolean;
  showToolbar?: boolean;
  showGridPanel?: boolean;
  className?: string;
  style?: CSSProperties;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSelect?: (item: any, keys: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  disableDots?: boolean;
  isTab?: boolean;
  parentId?: number;
  defaultColumns?: Column[];
  defaultSort?: string;
  defaultLimit?: number;
};

const GridContext = createContext<GridContextValue | null>(null);

export const useGridContext = (): GridContextValue => {
  const contextValue = useContext(GridContext);

  if (!contextValue) {
    throw new Error(
      'useGridContext must be used within a GridContext.Provider',
    );
  }

  return contextValue;
};

export const AccountingTransactionGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  filterContextValues,
  style = {},
  showPagination = true,
  showToolbar = true,
  showGridPanel = false,
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  getContactsData,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
  loading,
  disableDots,
  isTab,
  parentId,
  defaultColumns,
  defaultSort,
  defaultLimit,
}: GridProps) => {
  const colByName: { [key: string]: any } = {};

  const [gridColumns, setGridColumns] = useState<Column[]>([...columns]);
  const [filters, setFilters] = useState<FilteredField[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<FilteredField[]>([]);

  const onColumnsChangedHandler = (columnsChanged: Column[]): void => {
    setGridColumns([...columnsChanged]);
  };

  useEffect(() => {
    if (columns.length === 0) return;
    setGridColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    setFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: filters.find((y) => y.name === x.name)?.visible ?? false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
    setDefaultFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
  }, [gridColumns]);

  const updateCols = (colName: string) => {
    setGridColumns((prev) =>
      prev.map((x) => {
        const col = x;
        if (x.name === colName) {
          x.visible = !col.visible;
        }
        return x;
      }),
    );
  };

  const updateSort = (colName: string) => {
    const sortName = gridColumns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.startsWith('-') ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };

  const getFilteredRowsFilter = (): Column[] => {
    return gridColumns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  gridColumns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const onFiltersChangedHandler = (filtersChanged: FilteredField[]): void => {
    if (filtersChanged) setFilters([...filtersChanged]);
  };

  const [gridContext, setGridContext] = useState<GridContextValue>({
    columns: gridColumns,
    filters,
    search,
    defaultColumns,
    filterContextValues,
    defaultFilters,
    defaultSort,
    defaultLimit,
    onSearch,
    onFilter,
    onColumnsChanged: onColumnsChangedHandler,
    onFiltersChanged: onFiltersChangedHandler,
    onViewChanged,
  });

  useEffect(() => {
    setGridContext({
      columns: gridColumns,
      filters,
      search,
      filterContextValues,
      defaultColumns,
      defaultFilters,
      defaultSort,
      defaultLimit,
      onSearch,
      onFilter,
      onColumnsChanged: onColumnsChangedHandler,
      onFiltersChanged: onFiltersChangedHandler,
      onViewChanged,
    });
  }, [gridColumns, filters, search, defaultColumns, defaultFilters]);

  return (
    <>
      <div className="mx-3">
        {showGridPanel && (
          <>
            {(filters.length > 0 || gridColumns.length > 0) && (
              <GridContext.Provider value={gridContext}>
                <FiltersTab useGridContext={useGridContext} />
              </GridContext.Provider>
            )}
          </>
        )}
      </div>
      <div className={`grid mx-3 my-4 ${className}`} style={style}>
        {showToolbar ? (
          <div
            className={
              isDropDownList === true
                ? 'grid-toolbar d-flex flex-wrap px-3 justify-content-center'
                : 'grid-toolbar d-flex pl-3'
            }
          >
            {isTab ? (
              <div className="offset-9 col-2">
                <Button
                  size={'sm'}
                  color="secondary"
                  className="w-100 h-100"
                  name="create-contact"
                  onClick={() => {
                    showDialog({
                      dialog: ContactDialog,
                      props: {
                        contactId: 0,
                        title: 'Create Contact',
                        isTab: true,
                        parentId: parentId,
                      },
                    }).then((contact) => {
                      if (contact !== null) {
                        getContactsData();
                      }
                    });
                  }}
                >
                  Add New Contact
                </Button>
              </div>
            ) : null}

            {onSearch ? (
              <input
                type="search"
                className={
                  (isDropDownList === true ? 'w-100 col-12 ' : ' ') +
                  'form-control my-2'
                }
                placeholder="Search"
                value={decodeURIComponent(search)}
                onChange={updateSearch}
              />
            ) : null}
            {onFilter ? (
              <div
                className={
                  isDropDownList === true
                    ? 'dropdown dropdown-columns my-3 px-auto mx-auto w-auto col-auto'
                    : 'dropdown dropdown-columns my-3 px-3 w-100'
                }
              >
                {gridColumns.some((column) => column.showFilter) && (
                  <span
                    className="dropdown-toggle pointer"
                    id="dropdownFilterButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <BsFilter />
                    &nbsp;
                    <small className="align-middle pl-1 text-uppercase">
                      Filters
                    </small>
                    &nbsp;
                    <small className="align-middle text-primary">
                      {getReadableQueryString(filter, getFilteredRowsFilter())}
                    </small>
                  </span>
                )}
                {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
                  <div
                    className="dropdown-menu px-5"
                    aria-labelledby="dropdownFilterButton"
                  >
                    <div>
                      <Filters
                        query={filter}
                        columns={getFilteredRowsFilter()}
                        onFilter={updateFilter}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {hideColumnsSelect ? null : (
              <div
                className={
                  isDropDownList === true
                    ? 'dropdown dropdown-columns my-3 px-3 mx-auto pointer col-auto'
                    : 'dropdown dropdown-columns my-3 px-3 ml-auto pointer'
                }
              >
                <div
                  className="dropdown-toggle"
                  id="dropdownColumnsButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BsListTask className="" />
                  &nbsp;
                  <small className="align-middle pl-1 text-uppercase">
                    Columns
                  </small>
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownColumnsButton"
                  style={{ width: 'max-content', maxWidth: 'unset' }}
                >
                  {gridColumns
                    .filter((item) => {
                      if (showAllStore) {
                        return true;
                      }
                      return rowKeys?.includes(item.name);
                    })
                    .map((col) => {
                      return (
                        <label key={col.name} className="dropdown-item pointer">
                          <input
                            type="checkbox"
                            onChange={() => updateCols(col.name)}
                            defaultChecked={col.visible}
                          />{' '}
                          {col.title}
                        </label>
                      );
                    })}
                </div>
              </div>
            )}
            {actionBar && <div className={'mr-3 py-2'}>{actionBar}</div>}
          </div>
        ) : null}

        {total > 0 || showEmptyTable === true ? (
          <div className="bg-white horizontal-scrollbar">
            <table className="table">
              <thead>
                <tr>
                  {gridColumns
                    .filter((col) => {
                      if (showAllStore && col.visible) {
                        return true;
                      }
                      return col.visible && rowKeys?.includes(col.name);
                    })
                    .map((col) => {
                      return (
                        <th
                          scope="col"
                          key={col.name}
                          style={
                            col.name === 'currencySymbol'
                              ? { textAlign: 'center' }
                              : {}
                          }
                        >
                          <a
                            className={col.sortName ? 'link' : 'inactive-link'}
                            onClick={() =>
                              col.sortName ? updateSort(col.name) : null
                            }
                          >
                            {col.title}
                            {sort === col.name || sort === col.sortName ? (
                              <FaArrowDown />
                            ) : null}
                            {sort === '-' + col.name ||
                            sort === '-' + col.sortName ? (
                              <FaArrowUp />
                            ) : null}
                          </a>
                        </th>
                      );
                    })}
                  <th colSpan={2}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="m-5 text-center">
                    <h3 className="text-muted mb-4">Loading...</h3>
                  </div>
                ) : (
                  data.map((row, index) => {
                    return (
                      <tr
                        key={
                          rowKeys
                            ? rowKeys?.map((x) => row[x]).join('_')
                            : index
                        }
                      >
                        {Object.values(gridColumns)
                          .filter((item) => {
                            if (showAllStore && item.visible) {
                              return true;
                            }
                            return item.visible && rowKeys?.includes(item.name);
                          })
                          .map((item, index) => {
                            return (
                              <td
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={() => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                                className={'cursor-pointer'}
                                style={
                                  item.name === 'currencySymbol'
                                    ? { textAlign: 'center' }
                                    : {
                                        width: `calc(100% / ${
                                          gridColumns.filter(
                                            (column) => column.visible === true,
                                          ).length
                                        })`,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }
                                }
                              >
                                {typeof row[item.name] === 'boolean' ? (
                                  row[item.name] ? (
                                    <>&#x2713;</>
                                  ) : (
                                    <></>
                                  )
                                ) : item.name == 'created' ||
                                  item.name == 'lastModified' ||
                                  item.name == 'updated' ||
                                  item.name == 'dueDate' ||
                                  item.name == 'paidDate' ||
                                  item.name == 'transactionDate' ? (
                                  getFormattedDate(
                                    (row[item.name] as string)?.split(',')[0],
                                    false,
                                  )
                                ) : (
                                  <>{row[item.name]}</>
                                )}
                              </td>
                            );
                          })}
                        <td style={{ textAlign: 'center' }}>
                          <div className="dropdown">
                            <BsThreeDotsVertical
                              className="dropdown-toggle dropdown-dots-vertical pointer"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            />
                            <div
                              className="dropdown-menu dropdown-menu-grid"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {onDelete && (
                                <span
                                  title={
                                    row.amountPaid !== 0
                                      ? 'This transaction has a partial or a full payment'
                                      : null
                                  }
                                >
                                  <a
                                    role="button"
                                    className="dropdown-item"
                                    onClick={() => {
                                      onDelete(row);
                                    }}
                                    style={
                                      row.amountPaid !== 0
                                        ? {
                                            pointerEvents: 'none',
                                            color: 'gray',
                                          }
                                        : null
                                    }
                                  >
                                    Delete
                                  </a>
                                </span>
                              )}
                              {!disableDots && (
                                <a
                                  role="button"
                                  className="dropdown-item"
                                  onClick={() => {
                                    onEdit(row);
                                  }}
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center mt-4">Nothing Found</p>
        )}
        {showPagination === true ? (
          <div className="d-flex justify-content-center grid-footer">
            <Pagination
              goToPage={onPageChanged}
              offset={offset}
              limit={limit}
              total={total}
              onLimitChanged={onLimitChanged}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
