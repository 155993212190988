import { Dialog } from '../../common/components/dialog/dialog.component';
import { CurrencyEdit } from './currency-edit.component';
import { CurrencyDto } from '../../../models/data.models';

export type CurrencyDialogProperties = {
  currencyId?: number | null;
  title: string;
};

export class CurrencyDialog extends Dialog<CurrencyDialogProperties> {
  constructor(props: CurrencyDialogProperties) {
    super(props);
    this.onCurrencySaved = this.onCurrencySaved.bind(this);
  }

  onCurrencySaved = (currency: CurrencyDto) => {
    this.close(currency);
  };

  renderContent(): any {
    const { currencyId } = this.props as CurrencyDialogProperties;
    return (
      <CurrencyEdit
        currencyId={currencyId}
        onCurrencyCreated={this.onCurrencySaved}
        onCurrencyUpdated={this.onCurrencySaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
