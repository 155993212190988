import {
  DiscountDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  DiscountDtoPagedResult,
  CreateDiscountCommandValues,
  UpdateDiscountCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_DISCOUNTS_LINK_KEY = 'get-discounts';
export const CREATE_DISCOUNT_LINK_KEY = 'create-discount';
export const GET_DISCOUNT_LINK_KEY = 'get-discount';
export const UPDATE_DISCOUNT_LINK_KEY = 'update-discount';
export const DELETE_DISCOUNT_LINK_KEY = 'delete-discount';
export const IMPORT_DISCOUNTS_LINK_KEY = 'import-discounts';
export const EXPORT_DISCOUNTS_LINK_KEY = 'export-discounts';
export const DISCOUNT_ENTITY_NAME = 'Discount';

export interface GetDiscountParams {
  discountId: number;
}

export interface ImportDiscountsParams {
  organizationId: number;
}

export interface ExportDiscountsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getDiscountRequest = async (
  resource: LinkResourceBaseDto | null,
  { discountId }: GetDiscountParams,
): Promise<DiscountDto> => {
  if (resource && discountId) {
    const getDiscountLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_DISCOUNT_LINK_KEY,
    );
    if (getDiscountLink) {
      const result = await execLink(getDiscountLink, { discountId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_DISCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getDiscountsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<DiscountDtoPagedResult> => {
  if (resource) {
    const getDiscountsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_DISCOUNTS_LINK_KEY,
    );
    if (getDiscountsListLink) {
      const result = await execLink(getDiscountsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_DISCOUNTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createDiscountRequest = async (
  resource: LinkResourceBaseDto,
  discount: CreateDiscountCommandValues,
) => {
  const createDiscountLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_DISCOUNT_LINK_KEY,
  );
  if (createDiscountLink) {
    const result = await execLink(createDiscountLink, discount);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_DISCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateDiscountRequest = async (
  resource: LinkResourceBaseDto,
  discount: UpdateDiscountCommandValues,
) => {
  const updateDiscountLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_DISCOUNT_LINK_KEY,
  );
  if (updateDiscountLink) {
    const result = await execLink(updateDiscountLink, discount);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_DISCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteDiscountRequest = async (discount: DiscountDto) => {
  const deleteDiscountLink = discount.links?.find(
    (x: LinkDto) => x.rel === DELETE_DISCOUNT_LINK_KEY,
  );
  if (deleteDiscountLink) {
    const result = await execLink(deleteDiscountLink, discount);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_DISCOUNT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importDiscountsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportDiscountsParams,
  file: File,
) => {
  const importDiscountsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_DISCOUNTS_LINK_KEY,
  );
  if (importDiscountsLink) {
    const result = await postFormData(importDiscountsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_DISCOUNTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportDiscountsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportDiscountsParams,
) => {
  const exportDiscountsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_DISCOUNTS_LINK_KEY,
  );
  if (exportDiscountsLink) {
    const result = await downloadLinkFile(exportDiscountsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_DISCOUNTS_LINK_KEY} is not allowed or missing link action`,
  );
};
