import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import {
  CargoMovementStatus,
  OrderTypes,
  WarehouseZoneDto,
  WarehouseZoneDtoPagedResult,
  CommodityDto,
  OrderDto,
  WarehouseLocationDto,
  PutAwayActivityStatuses,
  OrderCommodityDto,
  ContactType,
} from '../../../models/data.models';
import { OrderDefaultValues } from '../../common/DefaultValues';
import { getOrder, updateMovement } from '../cargoMovements.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { ActivityHeader } from '../../common/components/header/activityHeader.component';
import { Button } from '../../common/components/button/button.component';
import { getWarehouseZonesFx } from '../../warehouseZones/warehouseZones.store';
import { CustomerInformation } from '../../customers/components/common/customerInformation.component';
import {
  CargoMovementDto,
  ActivityCommodityDto,
  CreateContactAndContactAddressesCommand,
} from '../../../models/custom.models';
import { putAwayCommoditiyTableStore } from '../../commodities/commodities.store';
import { ActivityCommoditiesTable } from '../../common/components/table/activityCommoditiesTable.component';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { FiEdit2 } from 'react-icons/fi';
import { HiX } from 'react-icons/hi';
import { getWarehouseLocationsFx } from '../../warehouseLocations/warehouseLocations.store';
import { LocationInfo } from '../../common/components/location/locationInfo.component';
import { PutAwayActivity } from './putAwayActivity.component';
import { INTERNAL_PATH } from '../../../app.router';
import { CARGO_MOVEMENT_EDIT_PATH } from '../cargoMovement.route';
import { CargoMovementForm } from '../components/cargoMovement.form';
import {
  updateOrderCommodity,
  UpdateOrderCommodityParams,
} from '../../orderCommodities/orderCommodities.store';
import { TotalStatuses } from '../../common/components/totalStatuses/totalStatuses';
import {
  createContactAndContactAddresses,
  getContacts,
} from '../../contacts/contacts.store';
import { authStore } from '../../auth/auth.store';

export type PutAwayActivityFlowProps = {
  orderId: number;
  onCancel?: () => void;
  isDialog?: boolean;
};

const getInitialState = () => {
  const initialState: CargoMovementDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: [],
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntities: [],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    links: [],
    customValues: {
      endMovement: '',
      startMovement: '',
      movementStatus: CargoMovementStatus.Created,
    },
    orderCommoditiesWithStatus: [],
  };
  return initialState;
};

export const PutAwayActivityFlow = ({
  orderId,
  onCancel,
  isDialog = false,
}: PutAwayActivityFlowProps) => {
  const [warehouseZones, setWareHouseZones] = useState<WarehouseZoneDto[]>([]);
  const [initialValues, setInitialValues] = useState<CargoMovementDto | null>(
    getInitialState(),
  );
  const [chosenCommodity, setChosenCommodity] = useState<CommodityDto | null>(
    null,
  );
  const [lastCommodity, setLastCommodity] = useState<CommodityDto | null>(null);
  const [activityCommodities, setAtivityCommodities] = useState<
    ActivityCommodityDto[]
  >([]);
  const [purchase, setPurchase] = useState<OrderDto | null>(null);
  const [
    customerLocation,
    setCustomerLocation,
  ] = useState<WarehouseLocationDto>(null);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(false);

  const { commodityColumns: columns } = useStore(putAwayCommoditiyTableStore);
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId } = useParams();

  const editMovement = () => {
    const edit = CARGO_MOVEMENT_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + edit, {
      organizationId,
      orderId,
    });
    navigate(path);
  };

  useEffect(() => {
    if (orderId) {
      getOrder({ orderId })
        .then((orderDto) => {
          setInitialValues(orderDto);
        })
        .catch((error) => {
          addMessage({
            message: error,
            type: 'danger',
            id: v4(),
          });
        });
    }
  }, [orderId]);

  useEffect(() => {
    getWarehouseZonesFx({
      limit: 1000,
      offset: 0,
    }).then((result: WarehouseZoneDtoPagedResult) => {
      setWareHouseZones(result?.items);
    });
  }, []);

  //add status and lastModified to commodities
  useEffect(() => {
    const commodities: ActivityCommodityDto[] = [...initialValues.commodities];
    initialValues?.orderCommoditiesWithStatus?.forEach((x) => {
      commodities?.forEach((y) => {
        if (x.commodityId === y.commodityId) {
          y.activityStatus =
            x?.customValues['activityStatus'] ||
            PutAwayActivityStatuses.InMovement;
          y.lastModifiedOrderCommodity = new Date(x.lastModified);
        }
      });
    });
    setAtivityCommodities(commodities);
  }, [initialValues]);

  //get commodity shipment to show customer
  useEffect(() => {
    if (lastCommodity) {
      setIsPurchaseLoading(true);
      const parcelShipment = lastCommodity?.shipments.find(
        (x) => x?.orderType === OrderTypes.Purchase,
      );
      if (parcelShipment && parcelShipment.orderId) {
        getOrder({ orderId: parcelShipment?.orderId })
          .then((result) => {
            setPurchase(result);
            setIsPurchaseLoading(false);
          })
          .catch((error) => {
            addMessage({
              message: error,
              type: 'danger',
              id: v4(),
            });
          });
      } else {
        setPurchase(null);
      }
    } else {
      setPurchase(null);
    }
  }, [lastCommodity]);

  //show customer primary location
  useEffect(() => {
    getWarehouseLocationsFx({ limit: 1000 }).then((result) => {
      if (result?.items?.length > 0) {
        if (purchase?.billToContactId) {
          const location = result.items.find(
            (x) => x.customerId === purchase?.billToContactId,
          );
          setCustomerLocation(location);
        } else {
          setCustomerLocation(null);
        }
      }
    });
  }, [purchase]);

  //change commodity status
  const setPutAwayStatus = (status: PutAwayActivityStatuses) => {
    const orderCommdodity: OrderCommodityDto = {
      commodityId: chosenCommodity.commodityId,
      orderId: initialValues.orderId,
      customValues: {
        activityStatus: status,
      },
    };

    const updateParams: UpdateOrderCommodityParams = {
      commodity: chosenCommodity,
      orderCommodity: orderCommdodity,
    };

    const isAllCommoditiesProcessed = (orderDto: CargoMovementDto) => {
      return orderDto.orderCommoditiesWithStatus.every(
        (x) =>
          x?.customValues['activityStatus'] ===
          PutAwayActivityStatuses.Completed,
      );
    };

    updateOrderCommodity(updateParams)
      .then(() => {
        showSuccess(status);
        getOrder({ orderId })
          .then((orderDto: CargoMovementDto) => {
            setInitialValues(orderDto);
            //set cargo movement status to started after changing first commodity status
            if (!lastCommodity && !isAllCommoditiesProcessed(orderDto)) {
              orderDto.customValues.movementStatus =
                CargoMovementStatus.InProgress;
              updateMovement(orderDto);
            }
            //set cargo movement status to  finished after changing last commodity status
            else if (isAllCommoditiesProcessed(orderDto)) {
              orderDto.customValues.movementStatus =
                CargoMovementStatus.Finished;
              updateMovement(orderDto);
            }
          })
          .catch((error) => {
            addMessage({
              message: error,
              type: 'danger',
              id: v4(),
            });
          });
      })
      .catch((error) => {
        showError(error.message || error);
      });
  };

  const showSuccess = (status: PutAwayActivityStatuses) => {
    addMessage({
      message: `Package status is set to ${status}`,
      type: 'success',
      id: v4(),
    });
  };

  const showError = (error: any) => {
    addMessage({
      message: error,
      type: 'danger',
      id: v4(),
    });
  };

  const actionButtons = [
    <Button
      color={'action-primary'}
      className="mr-4"
      type="button"
      onClick={editMovement}
    >
      <div className={'d-flex align-items-center'}>
        <FiEdit2 className={'mr-2'} size={16} />
        Edit
      </div>
    </Button>,
    <Button color={'secondary'} outline onClick={onCancel}>
      <div className={'d-flex align-items-center'}>
        <HiX size={16} className={'mr-2'} />
        Cancel
      </div>
    </Button>,
  ];

  useEffect(() => {
    if (onCancel) setActionButtons([...actionButtons]);
  }, []);

  useEffect(() => () => clearActionButtons(), []);

  return (
    <div>
      <div className="mx-3">
        {isDialog ? (
          <div className="row d-flex justify-content-end p-3">
            {actionButtons.map((x) => {
              return x;
            })}
          </div>
        ) : null}
      </div>
      <div className="w-100 mx-auto mb-4">
        <div className="w-100">
          <CargoMovementForm initialValues={initialValues}>
            <ActivityHeader
              orderNumber={initialValues?.orderNumber}
              type={'Picking'}
              assignedTo={initialValues?.employeeContactName}
              destination={
                initialValues.customValues['destinationLocationDescription']
              }
              showCartNumber={true}
            />
          </CargoMovementForm>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12 mb-md-0 mb-4">
          <PutAwayActivity
            setCommodity={setChosenCommodity}
            setLastCommodity={setLastCommodity}
            setPutAwayStatus={setPutAwayStatus}
            activityCommodities={activityCommodities}
            setAtivityCommodities={setAtivityCommodities}
          />
        </div>
        <div className="col-md-6 col-12">
          <div className="box h-100">
            {lastCommodity && (
              <CustomerInformation
                contactId={purchase?.billToContactId}
                trackingNumber={lastCommodity.commodityTrackingNumbers
                  ?.map((x) => x.trackingNumber)
                  .join(', ')}
                additionalLoading={
                  isPurchaseLoading || !purchase?.billToContactId
                }
              />
            )}
            {lastCommodity?.warehouseLocation && (
              <div className="text-label mt-3">Current Location</div>
            )}
            <div className="d-flex justify-content-start mt-3">
              {lastCommodity?.warehouseLocation && (
                <LocationInfo
                  warehouseLocation={lastCommodity?.warehouseLocation}
                  locationZones={warehouseZones}
                  showIcon={true}
                  size={'lg'}
                />
              )}
            </div>
            {customerLocation && (
              <div className="text-label mt-3">
                Primary Customer Package Location
              </div>
            )}
            <div className="d-flex justify-content-start mt-3">
              {customerLocation && (
                <LocationInfo
                  warehouseLocation={customerLocation}
                  locationZones={warehouseZones}
                  showIcon={true}
                  size={'lg'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <TotalStatuses
        activityStatuses={activityCommodities.map(
          (commodity) => commodity.activityStatus,
        )}
        statusesType={PutAwayActivityStatuses}
      />
      <div className="mt-6">
        <ActivityCommoditiesTable
          commodities={[...activityCommodities]}
          columns={columns}
          warehouseZones={warehouseZones}
          sortField={'lastModifiedOrderCommodity'}
        />
      </div>
    </div>
  );
};
