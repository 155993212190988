import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { WorkflowTriggerForm } from './workflowTrigger.form';
import {
  WorkflowTriggerDto,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  CustomFieldType,
  WorkflowExecutionResult,
} from '../../../models/data.models';
import {
  executeWorkflowTriggerFx,
  getWorkflowTriggerFx,
} from '../workflowTriggers.store';
import { Panel } from '../../common/components/panel/panel.component';

import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { IAction, getAction } from '../../common/components/actions/actions';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { get } from 'lodash';
import { Input } from '../../common/components/input/input.component';
import { CustomFieldInput } from '../../common/components/form/customField.input.component';

export type WorkflowTriggerEditProps = {
  workflowId?: string | null | undefined;
  onWorkflowTriggerCreated?: (workflowTrigger: WorkflowTriggerDto) => void;
  onWorkflowTriggerUpdated?: (workflowTrigger: WorkflowTriggerDto) => void;
  onWorkflowTriggerLoaded?: (workflowTrigger: WorkflowTriggerDto) => void;
  onCancel?: () => void;
  onDelete?: (workflowTrigger: WorkflowTriggerDto) => void;
  defaultVariables?: any;
  setResult?: any;
  autoClose?: boolean;
};

const initialState: any = {};

export const WorkflowTriggerExecute = ({
  workflowId,
  onWorkflowTriggerLoaded,
  onWorkflowTriggerCreated,
  onWorkflowTriggerUpdated,
  onCancel,
  onDelete,
  defaultVariables,
  setResult,
  autoClose,
}: WorkflowTriggerEditProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    workflowTrigger,
    setWorkflowTrigger,
  ] = useState<WorkflowTriggerDto | null>(null);

  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [actions, setActions] = useState([]);
  const [variables, setVariables] = useState<any>({});
  const [workflowResult, setWorkflowResult] = useState<WorkflowExecutionResult>(
    null,
  );

  const workflowTriggerSchema = useMemo(() => {
    let schema = Yup.object().shape({});

    if (customFields.length > 0) {
      schema = generateValidationSchemaWithCustomFields(customFields, schema);
    }

    return schema;
  }, [customFields]);

  useEffect(() => {
    // read custom fields from inputs
    const customFieldsArr: CustomFieldDto[] = [];
    if (workflowTrigger?.inputs?.length > 0)
      for (const input of workflowTrigger.inputs) {
        if (input?.props?.additionalProperties?.visible === false) continue;

        const customFieldDto: CustomFieldDto = {
          customFieldType: input.type,
          description: input.props?.description,
          displayName: input.props?.displayName || input.name,
          internalName: input.name,
        };

        customFieldsArr.push(customFieldDto);
      }
    setCustomFields(customFieldsArr);
  }, [workflowTrigger]);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [];
    return activeActions.filter(Boolean);
  }, [workflowTrigger]);

  useEffect(() => {
    const vars = { ...variables };
    for (const input of workflowTrigger?.inputs || []) {
      if (input.type === 'object') {
        vars[input.name] = JSON.stringify(
          get(defaultVariables, input.props.additionalProperties?.mapping) ||
            input.props.defaultValue,
        );
      } else {
        vars[input.name] =
          get(defaultVariables, input.props.additionalProperties?.mapping) ||
          input.props.defaultValue;
      }
    }
    setVariables(vars);
  }, [defaultVariables, workflowTrigger]);

  const fetchWorkflowTriggerData = useCallback(async () => {
    const workflowTriggerDto = await getWorkflowTriggerFx({ workflowId });
    setWorkflowTrigger(workflowTriggerDto);
    if (onWorkflowTriggerLoaded) onWorkflowTriggerLoaded(workflowTriggerDto);
    setIsLoading(false);
  }, [workflowId, onWorkflowTriggerLoaded]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    fetchWorkflowTriggerData();
  }, [fetchWorkflowTriggerData]);

  useEffect(() => {
    if (setResult) setResult(workflowResult);
  }, [workflowResult]);

  const onSubmit = useCallback(
    async (data: any) => {
      setIsSubmitting(true);
      try {
        const vars = {};
        for (const input of workflowTrigger?.inputs || []) {
          if (input.type === 'object') {
            vars[input.name] = JSON.parse(data[input.name]);
          } else if (input.type === 'boolean') {
            vars[input.name] = data[input.name] || false;
          } else if (input.type === 'number') {
            vars[input.name] = Number(data[input.name]);
          } else {
            vars[input.name] = data[input.name];
          }
          // if the input is required and has a default value, set the default value
          if (
            !vars[input.name] &&
            input.props?.required &&
            input.props?.defaultValue
          ) {
            vars[input.name] = input.props?.defaultValue;
          }
        }
        const wfResult = await executeWorkflowTriggerFx({
          workflowTrigger,
          variables: vars,
        });
        setWorkflowResult(wfResult);

        if (autoClose) {
          onCancel();
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      onWorkflowTriggerCreated,
      onWorkflowTriggerUpdated,
      workflowTrigger,
      variables,
    ],
  );

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <WorkflowTriggerForm
      id={'workflowTrigger-form'}
      initialValues={variables}
      onSubmit={onSubmit}
      validationSchema={workflowTriggerSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            {workflowTrigger?.workflowDescription && (
              <div className="pt-3 pb-3">
                <h5>{workflowTrigger.workflowDescription}</h5>
              </div>
            )}
            {isSubmitting && <LoadingIndicator message={'Executing...'} />}
            {!workflowResult &&
              !isSubmitting &&
              customFields.map((customField) => {
                return (
                  <CustomFieldInput
                    key={`customField-${customField.internalName}`}
                    customFieldType={customField.customFieldType}
                    name={`${customField.internalName}`}
                    internalName={customField.internalName}
                    namePrefix={''}
                    displayName={
                      customField.displayName || customField.internalName
                    }
                    placeholder={customField.description}
                    context={context}
                  />
                );
              })}

            {workflowResult && (
              <>
                <h3 className="header-form">Result</h3>
                <div className="row">
                  <pre>{JSON.stringify(workflowResult, null, 2)}</pre>
                </div>
              </>
            )}
            <div className="row m-3">
              <div className="text-right">
                {!workflowResult && (
                  <Button
                    name="save-workflowTrigger"
                    type="submit"
                    color="primary"
                    form={'workflowTrigger-form'}
                    disabled={isSubmitting || !context.isValid}
                  >
                    {workflowTrigger?.props?.additionalProperties
                      .executeButtonText || 'Execute'}
                  </Button>
                )}

                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="ml-2"
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </WorkflowTriggerForm>
  );
};
