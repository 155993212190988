import { Dialog } from '../../common/components/dialog/dialog.component';
import { CommodityEdit } from './commodity-edit.component';
import { CommodityDto } from '../../../models/data.models';

export type CommodityDialogProperties = {
  commodityId?: number | null;
  commodity?: CommodityDto | null;
  title: string;
  className?: string;
  saveButtonRenderCondition?: boolean;
  isEditMode?: boolean;
  saveBtnLabel?: string;
  isEditModeInCommodities?: boolean;
};

export class CommodityDialog extends Dialog<CommodityDialogProperties> {
  constructor(props: CommodityDialogProperties) {
    super(props);
    this.onCommoditySaved = this.onCommoditySaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isEditMode = props.isEditMode;
    this.isEditModeInCommodities = props.isEditModeInCommodities;
    this.saveBtnLabel = props.saveBtnLabel ?? 'Save Commodity';
  }

  isEditMode: boolean | undefined = false;
  isEditModeInCommodities: boolean | undefined = false;
  saveBtnLabel: string;

  onCommoditySaved = (commodity: CommodityDto) => {
    this.close(commodity);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { commodityId, commodity, saveButtonRenderCondition } = this
      .props as CommodityDialogProperties;
    return (
      <CommodityEdit
        defaultCommodity={{
          ...commodity,
          pieces: commodity?.pieces ?? 1,
          description: commodity?.description ?? '',
          weightUnit: commodity?.weightUnit ?? null,
          dimensionsUnit: commodity?.dimensionsUnit ?? null,
          volumeUnit: commodity?.volumeUnit ?? null,
        }}
        commodityId={commodityId}
        onCommodityCreated={this.onCommoditySaved}
        onCommodityUpdated={this.onCommoditySaved}
        onCancel={this.onCancel}
        saveButtonRenderCondition={saveButtonRenderCondition}
        isEditMode={this.isEditMode}
        saveBtnLabel={this.saveBtnLabel}
        isEditModeInCommodities={this.isEditModeInCommodities}
      />
    );
  }
}
