import React, { CSSProperties, useEffect, useState } from 'react';

import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';

import { Pagination } from '../pagination/pagination.component';
import {
  getAdditionalColumnsData,
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import { OrderForListDto } from '../../../../models/custom.models';
import { Column, CustomFieldType } from '../../../../models/data.models';
import { unescapeString } from '../../../../utils/helper.utils';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { getContact } from '../../../contacts/contacts.store';

export type QuoteGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: OrderForListDto[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  onChangeItem?: (items: any[]) => void;
  showAllFilters?: boolean;
  entityType?: string;
};

export const QuoteGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onColumnsChanged = () => {},
  onSort = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = false,
  showAllStore = false,
  showAllFilters = true,
  entityType = null,
}: QuoteGridProps) => {
  const colByName: { [key: string]: any } = {};

  const [addData, setAddData] = useState({});

  useEffect(() => {
    const filterParts = new URLSearchParams(window.location.search)
      .get('filter')
      ?.split(' AND ');

    const billToContactId = filterParts
      ?.find((x) => x.includes('BillToContactId:'))
      ?.split(':')[1];

    let createdByUserName = filterParts
      ?.find((x) => x.includes('CreatedUser.UserName:'))
      ?.split(':')[1];

    if (createdByUserName) {
      createdByUserName = unescapeString(createdByUserName, false);
    }

    const customFieldsNames = columns
      ?.filter((column) => column.type === 'customField')
      .map((column) => column.name);

    const customFieldsValues = customFieldsNames
      .map((name) => {
        return filterParts?.find((x) => x.includes(name))?.split(':')[1];
      })
      .filter((field) => field !== null && field !== undefined)
      .map((field) => {
        return unescapeString(field, true);
      });

    let customFields = {};
    for (let i = 0; i < customFieldsValues.length; i++) {
      customFields[`__${customFieldsNames[i]}`] = customFieldsValues[i];
    }

    if (billToContactId) {
      getContact({ contactId: Number(billToContactId) }).then((contact) => {
        setAddData({
          ...addData,
          __BillToContactName: contact.name,
          __BillToContactType: contact.contactType,
          __createdByUserName: createdByUserName ?? '',
          ...customFields,
        });
      });
    } else {
      setAddData({
        ...addData,
        __createdByUserName: createdByUserName ?? '',
        ...customFields,
      });
    }
  }, [columns]);

  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return onColumnsChanged(columns);
  };

  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    (document.querySelector(
      '.filter-dropdown .dropdown-toggle',
    ) as HTMLSpanElement).click();
    setAddData(getAdditionalColumnsData(filters));
    return onFilter(getSearchQuery(filters, columns));
  };

  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  const getTdClass = (itemName?: any) => {
    let resultClasses = ['cursor-pointer'];
    if (itemName === 'firstOrderPickupsShipperAddressName') {
      resultClasses.push('three-dots order-grid-origin');
    } else if (itemName === 'firstOrderDeliveriesConsigneeAddressName') {
      resultClasses.push('three-dots order-grid-destination');
    } else if (itemName === 'orderStatus') {
      resultClasses.push('order-grid-order-status');
    }
    return resultClasses.join(' ');
  };

  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) =>
      columns.some((column) => column.showFilter) && (
        <span
          className="dropdown-toggle pointer"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          <BsFilter />
          &nbsp;
          <small className="align-middle pl-1 text-uppercase">Filters</small>
          &nbsp;
          <small className="align-middle text-primary">
            {getReadableQueryString(filter, getFilteredRowsFilter(), addData)}
          </small>
        </span>
      ),
  );

  return (
    <div className={`grid mx-3 ${className}`} style={style}>
      <div className="grid-toolbar d-flex pl-3">
        {onSearch ? (
          <input
            type="search"
            className="form-control my-2"
            placeholder="Search"
            onChange={updateSearch}
            value={decodeURIComponent(search)}
          />
        ) : null}
        {onFilter ? (
          <div className="my-3 px-3 w-100 filter-dropdown">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
                  <div className="px-5" style={{ minHeight: '300px' }}>
                    <div className="filters-form-block">
                      <Filters
                        query={filter}
                        columns={getFilteredRowsFilter()}
                        onFilter={updateFilter}
                        addData={addData}
                      />
                    </div>
                  </div>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}

        <div className="dropdown dropdown-columns my-3 px-3 ml-auto pointer">
          <div
            className="dropdown-toggle"
            id="dropdownColumnsButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <BsListTask className="" />
            &nbsp;
            <small className="align-middle pl-1 text-uppercase">Columns</small>
          </div>
          <div
            className="dropdown-menu"
            aria-labelledby="dropdownColumnsButton"
            style={{ width: 'max-content', maxWidth: 'unset' }}
          >
            {columns
              .filter((item) => {
                if (showAllStore) {
                  return true;
                }
                return rowKeys?.includes(item.name);
              })
              .map((col) => {
                return (
                  <label key={col.name} className="dropdown-item pointer">
                    <input
                      type="checkbox"
                      onChange={() => updateCols(col.name)}
                      defaultChecked={col.visible}
                    />{' '}
                    {col.title}
                  </label>
                );
              })}
          </div>
        </div>
      </div>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white">
          <table className="table">
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th scope="col" key={col.name}>
                        <a
                          className={col.sortName ? 'link' : 'inactive-link'}
                          onClick={() =>
                            col.sortName ? updateSort(col.name) : null
                          }
                        >
                          {col.title}
                          {sort === col.name || sort === col.sortName ? (
                            <FaArrowDown />
                          ) : null}
                          {sort === '-' + col.name ||
                          sort === '-' + col.sortName ? (
                            <FaArrowUp />
                          ) : null}
                        </a>
                      </th>
                    );
                  })}
                <th colSpan={2}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => {
                return (
                  <tr
                    data-cy-tracking-number={row.trackingNumber}
                    key={rowKeys?.map((x) => row[x]).join('_')}
                  >
                    {Object.values(columns)
                      .filter((item) => {
                        if (showAllStore && item.visible) {
                          return true;
                        }
                        return item.visible && rowKeys?.includes(item.name);
                      })
                      .map((item, index) => {
                        switch (item.name) {
                          case 'carrierContactName':
                            return (
                              <td
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={(event) => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                                style={{
                                  width: `calc(100% / ${
                                    columns.filter(
                                      (column) => column.visible === true,
                                    ).length
                                  })`,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {row?.carriers.map((carrier) => (
                                  <span style={{ whiteSpace: 'pre' }}>
                                    {carrier.name} - {carrier.contactType}
                                    {'\n'}
                                  </span>
                                ))}
                              </td>
                            );
                          default:
                            return (
                              <td
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={(event) => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                                style={{
                                  width: `calc(100% / ${
                                    columns.filter(
                                      (column) => column.visible === true,
                                    ).length
                                  })`,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {columns?.find(
                                  (column) =>
                                    column.name === item.name &&
                                    column.type === 'customField' &&
                                    column.customFieldType ===
                                      CustomFieldType.Boolean,
                                ) ? (
                                  row[item.name] === true ||
                                  row[item.name] === 'true' ? (
                                    <>&#x2713;</>
                                  ) : (
                                    <></>
                                  )
                                ) : columns?.find(
                                    (column) =>
                                      column.name === item.name &&
                                      column.type === 'customField' &&
                                      column.customFieldType ===
                                        CustomFieldType.Date,
                                  ) ? (
                                  getFormattedDate(row[item.name], false)
                                ) : (
                                  <>{row[item.name]}</>
                                )}
                              </td>
                            );
                        }
                      })}
                    <td style={{ textAlign: 'center' }}>
                      <div className="dropdown">
                        <BsThreeDotsVertical
                          className="dropdown-toggle dropdown-dots-vertical pointer"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu dropdown-menu-grid"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {onDelete && (
                            <span
                              title={
                                row.charges?.length > 0
                                  ? 'Quote contains charges'
                                  : null
                              }
                            >
                              <a
                                role="button"
                                className="dropdown-item"
                                onClick={() => {
                                  onDelete(row);
                                }}
                                style={
                                  row.charges?.length > 0
                                    ? {
                                        pointerEvents: 'none',
                                        color: 'gray',
                                      }
                                    : null
                                }
                              >
                                Delete
                              </a>
                            </span>
                          )}
                          <a
                            role="button"
                            className="dropdown-item"
                            onClick={() => {
                              onEdit(row);
                            }}
                          >
                            Edit
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > limit && showPagination === true ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
