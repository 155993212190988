import { ComponentProps } from '../layout-interfaces';
import { useContext, useEffect, useState } from 'react';
import { parseTemplate } from '../component-hooks';
import { useFormikContext } from 'formik';

export const EmbedComponent = (props: ComponentProps) => {
  const className = props.props?.className;

  const [embedUrl, setEmbedUrl] = useState();
  const [embedType, setEmbedType] = useState();

  useEffect(() => {
    const url = parseTemplate(props.props.url, {
      ...props?.variables,
      ...props?.context?.store,
    });
    const type = parseTemplate(props.props.type, {
      ...props?.variables,
      ...props?.context?.store,
    });
    setEmbedUrl(url);
    setEmbedType(type);
  }, [props]);

  return (
    <div {...props.props} className={className}>
      <embed src={embedUrl} type={embedType} height="100%" width="100%" />
    </div>
  );
};
