import { useEffect, useCallback } from 'react';
import { ComponentProps } from '../layout-interfaces';
import logger from '../../../logger';

const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    // check if script is already loaded
    const scripts = Array.from(document.head.getElementsByTagName('script'));
    const isLoaded = scripts.some((s) => s.src === src);
    if (isLoaded) {
      logger.log(`Script already loaded: ${src}`);
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.head.appendChild(script);
  });
};

const unloadScript = (src: string): void => {
  const scripts = Array.from(document.head.getElementsByTagName('script'));
  scripts.forEach((script) => {
    if (script.src === src) {
      document.head.removeChild(script);
      logger.log(`Script unloaded: ${src}`);
    }
  });
};

export const ScriptComponent = ({ props: { src } }: ComponentProps) => {
  const memoizedLoadScript = useCallback(() => loadScript(src), [src]);
  const memoizedUnloadScript = useCallback(() => unloadScript(src), [src]);
  useEffect(() => {
    if (!src) {
      logger.error('Script src is not defined');
      return;
    }

    memoizedLoadScript()
      .then(() => logger.log(`Script loaded: ${src}`))
      .catch((error) => logger.error(error.message));

    return () => {
      memoizedUnloadScript();
    };
  }, [src, memoizedLoadScript, memoizedUnloadScript]);

  return null;
};
