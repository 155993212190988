import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetOrdersQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import {
  PURCHASE_CREATE_PATH,
  PURCHASE_EDIT_PATH,
  PURCHASE_LIST_PATH,
} from '../purchase.route';
import {
  CREATE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../../orders/orders.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';
import { PurchasesList } from '../components/purchases-list.component';
import { getPurchasesDefaultColumns, purchasesStore } from '../purchases.store';
import { WAREHOUSE_PACKAGE_RECEIVE_PATH } from '../../warehouseReceipts/warehouseReceipt.route';
import { useStore } from 'effector-react';

export type PurchasesListScreenProps = { pageTitle: string; group: string };

export const PurchasesListScreen = ({ pageTitle, group }) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(purchasesStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `orderType:${OrderTypes.Purchase}`,
    search = '',
    view = '',
  } = useQuery() as GetOrdersQuery;
  const navigate = useNavigate();

  const createPath = generatePath(INTERNAL_PATH + PURCHASE_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (purchaseParams: GetOrderParams) => {
    const purchaseId = purchaseParams.orderId;
    const createOrEdit =
      purchaseId === 0 ? PURCHASE_CREATE_PATH : PURCHASE_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      purchaseId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getPurchasesDefaultColumns();
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}&view=${view}`,
    );
  };
  const addPath = generatePath(INTERNAL_PATH + WAREHOUSE_PACKAGE_RECEIVE_PATH, {
    organizationId,
  });

  return (
    <InternalLayout
      group={group}
      title={'Purchases'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
      addPath={addPath}
      addPathLabel="Receive"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PurchasesList
        filter={filter}
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onViewChanged={onViewChanged}
        onLimitChanged={onLimitChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
