import {
  ActionEventDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateActionEventCommandValues,
  UpdateActionEventCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createActionEventRequest,
  getActionEventsListRequest,
  getActionEventRequest,
  deleteActionEventRequest,
  updateActionEventRequest,
  importActionEventsRequest,
  exportActionEventsRequest,
  GetActionEventParams,
} from './actionEvents.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type ActionEventsStoreState = {
  links: LinkDto[];
  actionEventColumns: EntityFieldDto[];
  defaultActionEventColumns: EntityFieldDto[];
};

export const getActionEventsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ActionEvent',
  });
});

addEffectStatusHandling(getActionEventsColumnsFx);

export const getActionEventsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ActionEvent',
  });
});

addEffectStatusHandling(getActionEventsDefaultColumnsFx);

export const updateActionEventsColumns = createEvent<EntityFieldDto[]>();

export const getActionEventsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getActionEventsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getActionEventsFx);

export const createActionEventFx = createEffect(
  (actionEventData: ActionEventDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createActionEventCommand: CreateActionEventCommandValues = {
      ...actionEventData,
    };

    return createActionEventRequest(
      currentOrganization!,
      createActionEventCommand,
    );
  },
);

addEffectStatusHandling(createActionEventFx, {
  completeMessage: '',
  errorMessage: 'Action Error',
});

export const getActionEventFx = createEffect(
  (actionEventParams: GetActionEventParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getActionEventRequest(
      currentOrganization as LinkResourceBaseDto,
      actionEventParams,
    );
  },
);

addEffectStatusHandling(getActionEventFx);

export const updateActionEventFx = createEffect(
  (actionEvent: ActionEventDto) => {
    const updateActionEventCommand: UpdateActionEventCommandValues = {
      ...actionEvent,
    };
    return updateActionEventRequest(actionEvent, updateActionEventCommand);
  },
);

addEffectStatusHandling(updateActionEventFx, {
  completeMessage: 'Action event was updated successfully',
  errorMessage: 'Action event was not updated',
});

export const deleteActionEventFx = createEffect(
  (actionEvent: ActionEventDto) => {
    return deleteActionEventRequest(actionEvent);
  },
);

addEffectStatusHandling(deleteActionEventFx, {
  completeMessage: 'Action event was deleted successfully',
  errorMessage: 'Action event was not deleted',
});

export const exportActionEventsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportActionEventsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportActionEventsFx, {
  completeMessage: 'Action events were exported successfully',
  errorMessage: 'Action events were not exported',
});

export const importActionEventsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importActionEventsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importActionEventsFx, {
  completeMessage: 'Action events were imported successfully',
  errorMessage: 'Action events were not imported',
  inFlightMessage: 'Importing action events...',
});

const defaultState: ActionEventsStoreState = {
  links: [],
  actionEventColumns: [],
  defaultActionEventColumns: [],
};

export const actionEventStore: Store<ActionEventsStoreState> = createStore(
  defaultState,
)
  .on(getActionEventsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      actionEventColumns: payload.result.items,
      defaultActionEventColumns: payload.result.items,
    };
  })
  .on(getActionEventsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultActionEventColumns: payload.result.items,
    };
  })
  .on(updateActionEventsColumns, (state, payload) => {
    return { ...state, actionEventColumns: payload };
  });
