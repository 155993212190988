import React from 'react';
import { UIContextType } from '../components/layout/layout-interfaces';

type ContextType = {
  context: UIContextType;
  storeValues: any;
  refreshHandlers: any;
};

const UiContext = React.createContext<ContextType>({
  context: {},
  storeValues: {},
  refreshHandlers: {},
});
export default UiContext;
