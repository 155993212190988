import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { LeadListScreen } from './screens/lead-list.screen';
import { LeadScreen } from './screens/lead.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_LEADS } from '../contacts/contact.route';

export const LEADS_LIST_PATH = '/leads';
export const LEADS_EDIT_PATH = '/leads/:contactId';
export const LEADS_CREATE_PATH = '/leads/0';

export const LeadRoute = (props: RouteProps) => {
  const group = 'Sales';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <LeadListScreen pageTitle="Leads" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <LeadScreen pageTitle="Add New Lead" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <LeadScreen pageTitle="Update Lead" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':leadId/contacts/:contactId'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Contacts" isTab={true} group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
