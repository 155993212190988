import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CargoMovementScreen } from './screen/cargoMovement.screen';
import { CargoMovementsListScreen } from './screen/cargoMovements-list.screen';
import { PickingActivityScreen } from './picking/screen/pickingActivity.screen';
import { PutAwayActivityScreen } from './putAway/screen/putAwayActivity.screen';
import { AllPutAwayActivityScreen } from './putAway/screen/allPutAwayActivity.screen';

export const CARGO_MOVEMENT_LIST_PATH = '/cargoMovements';
export const CARGO_MOVEMENT_EDIT_PATH = '/cargoMovements/:orderId';
export const CARGO_MOVEMENT_CREATE_PATH = '/cargoMovements/0';
export const CARGO_MOVEMENT_EDIT_PICKING_PATH =
  '/cargoMovements/:orderId/picking';
export const CARGO_MOVEMENT_EDIT_PUTAWAY_PATH =
  '/cargoMovements/:orderId/putAway';
export const ALL_PUTAWAY_ACTIVITY_FLOW_PATH = '/cargoMovements/0/putAwayAll';

export const CargoMovementRoute = () => {
  const group = 'Operations';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CargoMovementsListScreen
              pageTitle="Cargo Movements"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CargoMovementScreen
              pageTitle="Add New Cargo Movement"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':orderId'}
        element={
          <PrivateRoute>
            <CargoMovementScreen
              pageTitle="Update Cargo Movement"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':orderId/picking'}
        element={
          <PrivateRoute>
            <PickingActivityScreen
              pageTitle="Update Picking List"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':orderId/putAway'}
        element={
          <PrivateRoute>
            <PutAwayActivityScreen
              pageTitle="Update PutAway List"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0/putAwayAll'}
        element={
          <PrivateRoute>
            <AllPutAwayActivityScreen
              pageTitle="Update PutAway List"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
