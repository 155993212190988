import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CARGO_MOVEMENT_CREATE_PATH,
  CARGO_MOVEMENT_LIST_PATH,
} from '../cargoMovement.route';
import { CargoMovementEdit } from '../components/cargoMovement-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_CARGO_MOVEMENT_LINK_KEY } from '../cargoMovements.service';
import { userHas } from '../../auth/auth.store';

export type OrderScreenProps = { pageTitle: string; group: string };

export const CargoMovementScreen = ({ pageTitle, group }: OrderScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_CREATE_PATH, {
    organizationId,
  });

  const onOrderCreated = () => {
    const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-created',
      type: 'success',
      autoHide: true,
      message: 'Cargo Movement successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onOrderUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-updated',
      type: 'success',
      autoHide: true,
      message: 'Cargo Movement successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Cargo Movements'}
      createPath={userHas(CREATE_CARGO_MOVEMENT_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CargoMovementEdit
        orderId={orderId}
        onOrderUpdated={onOrderUpdated}
        onOrderCreated={onOrderCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
