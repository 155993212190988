import {
  AddressType,
  ContactDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
} from '../../../../models/data.models';
import { OrderForm } from '../../../orders/components/order.form';
import { CustomFieldsForm } from '../../../common/components/form/customFields-form.component';
import { useEffect, useState } from 'react';

export type VendorFormProps = {
  index: number;
  allowDelete: boolean;
  onVendorDeleted?: () => void;
  setVendorContact: (
    contactId: number,
    name: string,
    contactType: ContactType,
  ) => void;
  setVendorFilter: (filter: string) => void;
  setVendorContactAddress: (
    contactAddressId: number,
    addressLine: string,
  ) => void;
  vendorFilter: string;
  vendorContact: {
    contactId: number;
    name: string;
    contactType: ContactType;
    contactAddressId: number;
    addressLine: string;
  };
  customValues?: object;
  onChange?: (result: object) => void;
  customFields: CustomFieldDto[];
  setNotes?: (value: any) => void;
  showVendorLocation?: boolean;
};

export const VendorForm = ({
  index,
  setVendorContact,
  setVendorFilter,
  setVendorContactAddress,
  vendorContact,
  allowDelete = false,
  onVendorDeleted,
  customValues,
  customFields,
  onChange = (result) => {},
  setNotes,
  showVendorLocation = false,
}: VendorFormProps) => {
  const vendor =
    vendorContact &&
    vendorContact.contactId != null &&
    vendorContact.name != null &&
    vendorContact.contactType != null
      ? {
          contactId: vendorContact.contactId,
          name: vendorContact.name,
          contactType: vendorContact.contactType,
        }
      : '';

  const contactAddress =
    vendorContact &&
    vendorContact.contactAddressId != null &&
    vendorContact.addressLine != null
      ? {
          contactAddressId: vendorContact.contactAddressId,
          addressLine: vendorContact.addressLine,
        }
      : '';

  const vendorCustomFields = customFields?.filter(
    (field) =>
      field.customFieldEntityType == CustomFieldEntityType.PurchaseVendor,
  );

  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  useEffect(() => {
    setIsFieldsDisabled(!vendorContact?.contactId || false);
  }, [vendorContact?.contactId]);

  return (
    <div className="row">
      <div className="col-5">
        <OrderForm.VendorContactSelect
          id={`orderEntityVendors[${index}].contactId`}
          header={`Vendor ${index + 1}`}
          contactTypes={[ContactType.Vendor]}
          selectedFilter={`contactType: ${ContactType.Vendor}`}
          required={false}
          defaultValue={vendor}
          onChange={(data?: ContactDto, context?: any) => {
            setVendorContact(data?.contactId, data?.name, data?.contactType);
            setVendorFilter(
              data?.contactId ? `contactId:${data?.contactId}` : ' ',
            );
          }}
          nameId={`orderEntityVendors[${index}].contactName`}
        />
      </div>
      {showVendorLocation && (
        <div className="col-3">
          <OrderForm.VendorLocation
            contactId={vendorContact.contactId}
            contactType={vendorContact.contactType}
            addressType={AddressType.Other}
            defaultValue={contactAddress}
            onChange={(data: any) => {
              setVendorContactAddress(
                data?.contactAddressId,
                data?.addressLine,
              );
            }}
            disabled={isFieldsDisabled}
          />
        </div>
      )}
      <div className="col-4">
        <OrderForm.NonContactName
          name={`orderEntityVendors[${index}].nonContactName`}
          id={`orderEntityVendors[${index}].nonContactName`}
          label={'Non-Contact Name'}
          placeholder={'Non-contact name'}
          onChange={(ev) => {
            setNotes(ev?.target?.value);
          }}
        />
      </div>
      <div className={allowDelete ? 'col-2' : 'col-3'}>
        <div className={'w-100'}>
          <CustomFieldsForm
            customFields={vendorCustomFields}
            defaultValue={customValues}
            inputNamePrefix={`orderEntityVendors[${index}]`}
            onChange={onChange}
            isFieldsDisabled={isFieldsDisabled}
          />
        </div>
      </div>
      {allowDelete && (
        <div className="col-1 d-flex">
          <div className={'d-flex align-items-center pt-3'}>
            <div
              data-testid={`delete-vendor[${index}]`}
              onClick={(event) => {
                onVendorDeleted();
              }}
              color="primary"
              className="btn-close icon-close"
            />
          </div>
        </div>
      )}
    </div>
  );
};
