import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FiActivity } from 'react-icons/fi';

library.add(fab, fas, far);

export const Icon = ({ icon, ...props }) => {
  if (icon === 'activity') return <FiActivity {...props} />;
  return <FontAwesomeIcon icon={icon} {...props} />;
};
