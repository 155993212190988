import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CommodityListScreen } from './screens/commodity-list.screen';
import { CommodityScreen } from './screens/commodity.screen';

export const COMMODITIES_LIST_PATH = '/commodities';
export const COMMODITY_EDIT_PATH = '/commodities/:commodityId';
export const COMMODITY_CREATE_PATH = '/commodities/0';

export const CommoditiesRoute = (props: RouteProps) => {
  const group = 'Operations';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CommodityListScreen pageTitle="Commodities" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CommodityScreen pageTitle="Add New Commodity" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':commodityId'}
        element={
          <PrivateRoute>
            <CommodityScreen pageTitle="Update Commodity" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
