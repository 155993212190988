import {
  CommodityStatuses,
  CreateOrderCommandValues,
  DimensionsUnit,
  LinkDto,
  LinkResourceBaseDto,
  LocationType,
  OrderDto,
  OrderDtoPagedResult,
  PatchCommandBase,
  UpdateOrderCommandValues,
  WeightUnit,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_ORDERS_LINK_KEY = 'get-orders';
export const CREATE_ORDER_LINK_KEY = 'create-order';
export const GET_ORDER_LINK_KEY = 'get-order';
export const GET_ORDER_GQL_LINK_KEY = 'get-order-gql';
export const UPDATE_ORDER_LINK_KEY = 'update-order';
export const PATCH_ORDER_LINK_KEY = 'patch-order';
export const DELETE_ORDER_LINK_KEY = 'delete-order';
export const REPACK_ORDER_LINK_KEY = 'repack-order';
export const GET_ORDER_CONFIRMATION_LINK_KEY = 'get-order-confirmation';
export const GET_ORDERS_CONTACT_LINK_KEY = 'get-orders-contact-commodities';
export const GET_ORDERS_BY_TRACKING_NUMBERS_LINK_KEY =
  'get-orders-by-tracking-numbers';
export const GET_GARGOMOVEMENT_BY_PALLET_NUMBER_LINK_KEY =
  'get-movement-by-pallet-number';

export interface GetOrderParams {
  orderId: number;
}
export interface GetOrderByTrackingNumbersParams {
  trackingNumbers?: string[] | null;
}
export interface AddTrackingNumberParams {
  trackingNumber: string | null;
}
export interface ChangeCommodityParams {
  weight?: string | null;
  weightUnit?: WeightUnit | null;
  width?: string | null;
  length?: string | null;
  height?: string | null;
  dimensionsUnit?: DimensionsUnit | null;
}
export interface GetOrderConfirmationParams {
  orderId: number;
}
export interface PatchOrderParams {
  resource: LinkResourceBaseDto;
  patchOrderCommand: PatchCommandBase;
}

export interface GetMovementByPalletNumberParams {
  palletNumber: string;
  locationType: LocationType;
  divisionId: number;
  employeeContactId?: number | undefined;
  movementType: string;
}

export interface RepackOrderParams {
  orderId: number;
  dimensionsUnit: DimensionsUnit;
  height: number;
  length: number;
  width: number;
  weightUnit: WeightUnit;
  weight: number;
}

export const getOrderRequest = async (
  resource: LinkResourceBaseDto | null,
  { orderId }: GetOrderParams,
): Promise<OrderDto> => {
  if (resource && orderId) {
    const getOrderLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDER_LINK_KEY,
    );
    if (getOrderLink) {
      const result = await execLink(getOrderLink, { orderId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrderGQLRequest = async (
  resource: LinkResourceBaseDto | null,
  { orderId }: GetOrderParams,
): Promise<OrderDto> => {
  if (resource && orderId) {
    const getOrderGqlLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDER_GQL_LINK_KEY,
    );
    if (getOrderGqlLink) {
      const result = await execLink(getOrderGqlLink, { orderId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDER_GQL_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrdersByTrackingNumbersRequest = async (
  resource: LinkResourceBaseDto | null,
  { trackingNumbers }: GetOrderByTrackingNumbersParams,
): Promise<OrderDto[]> => {
  if (resource && trackingNumbers) {
    const getOrderLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERS_BY_TRACKING_NUMBERS_LINK_KEY,
    );
    if (getOrderLink) {
      const result = await execLink(getOrderLink, {
        trackingNumbers,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERS_BY_TRACKING_NUMBERS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrdersListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderDtoPagedResult> => {
  if (resource) {
    const getOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERS_LINK_KEY,
    );
    if (getOrdersListLink) {
      const result = await execLink(getOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: CreateOrderCommandValues,
) => {
  const createOrderLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORDER_LINK_KEY,
  );
  if (createOrderLink) {
    const result = await execLink(createOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: UpdateOrderCommandValues,
) => {
  const updateOrderLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORDER_LINK_KEY,
  );
  if (updateOrderLink) {
    const result = await execLink(updateOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const patchOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: PatchCommandBase,
) => {
  const patchOrderLink = resource.links?.find((x: LinkDto) => {
    return x.rel === PATCH_ORDER_LINK_KEY;
  });
  if (patchOrderLink) {
    const result = await execLink(patchOrderLink, order?.patchItems);
    return result.data;
  }
  addMessage({
    message: `Action ${PATCH_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteOrderRequest = async (order: OrderDto) => {
  const deleteOrderLink = order.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORDER_LINK_KEY,
  );
  if (deleteOrderLink) {
    const result = await execLink(deleteOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const repackOrderRequest = async (
  resource: LinkResourceBaseDto | null,
  repackParams: RepackOrderParams,
) => {
  if (resource && repackParams) {
    const repackOrderLink = resource.links?.find(
      (x: LinkDto) => x.rel === REPACK_ORDER_LINK_KEY,
    );
    if (repackOrderLink) {
      const result = await execLink(repackOrderLink, repackParams);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${REPACK_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrdersContactCommoditiesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderDtoPagedResult> => {
  if (resource) {
    const getOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERS_CONTACT_LINK_KEY,
    );
    if (getOrdersListLink) {
      const result = await execLink(getOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERS_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getCargoMovementByPalletNumberRequest = async (
  resource: LinkResourceBaseDto | null,
  params: GetMovementByPalletNumberParams,
): Promise<OrderDto> => {
  if (resource && params) {
    const getOrderLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_GARGOMOVEMENT_BY_PALLET_NUMBER_LINK_KEY,
    );
    if (getOrderLink) {
      const result = await execLink(getOrderLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_GARGOMOVEMENT_BY_PALLET_NUMBER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
