import { isArray } from 'lodash';

type QueryField = string | { [key: string]: QueryField[] };

type QueryParameters = {
  organizationId: string;
  search?: string;
  filter?: string;
  take?: string | number;
  skip?: string | number;
  orderBy?: string;
};
type QueryOptions = {
  tableName: string;
  columns: QueryField[];
  entityKeys: string[];
  parameters: QueryParameters;
};

export const foldColumns = (columns: string[]): any[] => {
  const buildNestedObject = (path: string[], value: any): object => {
    if (path.length === 0) return value;
    const [first, ...rest] = path;
    if (first === 'customValues' || first === 'eventData') return first;
    return { [first]: buildNestedObject(rest, value) };
  };

  const splitColumn = (column: string): string[] => {
    const result: string[] = [];
    let current = '';
    let insideQuotes = false;

    for (let i = 0; i < column.length; i++) {
      const char = column[i];
      if (char === '"' && (i === 0 || column[i - 1] !== '\\')) {
        insideQuotes = !insideQuotes;
        current += char;
      } else if (char === '.' && !insideQuotes) {
        result.push(current);
        current = '';
      } else {
        current += char;
      }
    }

    if (current) {
      result.push(current);
    }

    return result;
  };

  const foldedColumns = columns.map((column) => {
    if (typeof column === 'string' && column.includes('.')) {
      const path = splitColumn(column);
      const last = path.pop();
      return buildNestedObject(path, [last]);
    }
    return column;
  });

  // remove duplicates
  return foldedColumns.filter(
    (item, index) => foldedColumns.indexOf(item) === index,
  );
};

const generateFields = (fields: QueryField[], entityKeys: string[]): string => {
  let completeFields = fields;
  if (entityKeys?.length) {
    completeFields = [
      ...entityKeys.filter((key) => !fields.includes(key)),
      ...fields,
    ];
  }

  if (!isArray(completeFields)) completeFields = [completeFields];
  return completeFields
    .map((field) => {
      if (typeof field === 'string') {
        return field;
      } else {
        const [nestedFieldName, nestedFields] = Object.entries(field)[0];
        // hack for nestedFieldName 0, we need to improve splitString and buildNestedObject
        if (nestedFieldName === '0') {
          return `${generateFields(nestedFields, [])}`;
        }

        return `${nestedFieldName} { ${generateFields(nestedFields, [])} }`;
      }
    })
    .join(' ');
};

export const generateGraphQLQuery = ({
  tableName,
  columns,
  entityKeys,
  parameters,
}: QueryOptions): string => {
  const foldedColumns = foldColumns(columns);
  const fields = generateFields(foldedColumns, entityKeys);

  // Construct query parameters string
  let queryParams = `($organizationId: ${parameters.organizationId}`;

  let queryArgument = '(organizationId: $organizationId';
  if (parameters.search) {
    queryParams += `, $search: ${parameters.search}`;
    queryArgument += ', search: $search';
  }
  if (parameters.filter) {
    queryParams += `, $filter: ${parameters.filter}`;
    queryArgument += ', filter: $filter';
  }
  if (parameters.take !== undefined) {
    queryParams += `, $take: ${parameters.take}`;
    queryArgument += ', take: $take';
  }
  if (parameters.skip !== undefined) {
    queryParams += `, $skip: ${parameters.skip}`;
    queryArgument += ', skip: $skip';
  }

  if (parameters.orderBy) {
    queryParams += `, $orderBy: ${parameters.orderBy}`;
    queryArgument += ', orderBy: $orderBy';
  }

  queryParams += `)`;
  queryArgument += ')';

  // Construct keys

  const query = `
        query${queryParams} {
            ${tableName}${queryArgument} {
                items {
                  ${fields}
                }
                totalCount
            }
        }
    `;

  return query;
};

export const toLuceneTerm = (value: any) => {
  if (typeof value === 'string') {
    return `"${value}*"`;
  } else if (value instanceof Date) {
    return `"${new Date(value).toISOString()}"`;
  }
  return value;
};
