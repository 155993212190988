import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ModeOfTransportationForm } from './modeOfTransportation.form';
import {
  CustomFieldDto,
  CustomFieldEntityType,
  DocumentTemplateType,
  ModeOfTransportationDto,
  OrderStatuses,
  TransportationMethod,
  TransportationMethodDescription,
} from '../../../models/data.models';
import {
  createModeOfTransportationFx,
  getModeOfTransportationFx,
  updateModeOfTransportationFx,
} from '../modeOfTransportations.store';
import { Panel } from '../../common/components/panel/panel.component';
import {
  Air,
  Ground,
  Mail,
  Ocean,
  Pipe,
  Rail,
  ReactSelectItem,
  Unknown,
} from '../../../models/custom.models';
import {
  generateValidationSchemaWithCustomFields,
  getEnumValues,
} from '../../../utils/helper.utils';
import { ModeOfTransportationDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { UPDATE_MODEOFTRANSPORTATION_LINK_KEY } from '../modeOfTransportations.service';
import { userHas } from '../../auth/auth.store';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { CustomFieldsForm } from '../../common/components/form/customFields-form.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { FormContext } from '../../common/components/form/form.component';

export type ModeOfTransportationEditProps = {
  modeOfTransportationId?: number | null;
  onModeOfTransportationCreated?: (
    modeOfTransportation: ModeOfTransportationDto,
  ) => void;
  onModeOfTransportationUpdated?: (
    modeOfTransportation: ModeOfTransportationDto,
  ) => void;
  onModeOfTransportationLoaded?: (
    modeOfTransportation: ModeOfTransportationDto,
  ) => void;
  onCancel?: () => void;
};

const initialState: ModeOfTransportationDto = {
  modeOfTransportationId: 0,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: ModeOfTransportationDefaultValues.description,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  transportationMethod: ModeOfTransportationDefaultValues.transportationMethod,
  transportationMethodDescription:
    ModeOfTransportationDefaultValues.transportationMethodDescription,
  usCustomsCode: ModeOfTransportationDefaultValues.usCustomsCode,
  customValues: {},
  links: [],
};

let modeOfTransportationSchema = Yup.object().shape({
  description: Yup.string().required("Can't be blank").nullable(true),
  transportationMethod: Yup.string().required("Can't be blank").nullable(true),
  transportationMethodDescription: Yup.string().when('transportationMethod', {
    is: (transportationMethod) => transportationMethod?.length > 0,
    then: Yup.string().required("Can't be blank").nullable(true),
    otherwise: Yup.string().notRequired().nullable(true),
  }),
  usCustomsCode: Yup.string()
    .max(2, 'Max length is 2')
    .required("Can't be blank")
    .nullable(true),
});

export const ModeOfTransportationEdit = ({
  modeOfTransportationId,
  onModeOfTransportationLoaded = () => {},
  onModeOfTransportationCreated = () => {},
  onModeOfTransportationUpdated = () => {},
  onCancel = () => {},
}: ModeOfTransportationEditProps) => {
  const isCreateMode = !modeOfTransportationId || modeOfTransportationId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    modeOfTransportation,
    setModeOfTransportation,
  ] = useState<ModeOfTransportationDto>();
  const [initialValues, setInitialValues] = useState<ModeOfTransportationDto>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();
  const [
    transportationMethodDescription,
    setTransportationMethodDescription,
  ] = useState<
    | typeof Ocean
    | typeof Rail
    | typeof Mail
    | typeof Pipe
    | typeof Unknown
    | typeof Air
    | typeof Ground
    | null
  >(null);

  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.ModeOfTransportation} AND isInactive: false`,
    }).then(
      (fields) => {
        const customFields: CustomFieldDto[] = fields.items;
        modeOfTransportationSchema = generateValidationSchemaWithCustomFields(
          customFields,
          modeOfTransportationSchema,
        );
        setCustomFields(customFields);
      },
      () => {},
    );
    if (isCreateMode) {
      setIsLoading(false);
    } else if (modeOfTransportationId) {
      getModeOfTransportationFx({ modeOfTransportationId }).then(
        (modeOfTransportationDto: ModeOfTransportationDto) => {
          setModeOfTransportation(modeOfTransportationDto);
          setInitialValues(modeOfTransportationDto);
          setIsLoading(false);
          onModeOfTransportationLoaded(modeOfTransportationDto);
          updateTransportationMethodDescriptionState(
            modeOfTransportationDto.transportationMethod,
          );
        },
      );
    } else {
      throw new Error('ModeOfTransportation keys were not provided');
    }
  }, [modeOfTransportationId]);

  const onSubmit = (data: ModeOfTransportationDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createModeOfTransportationFx(data)
        .then((result) => {
          onModeOfTransportationCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updateModeOfTransportationFx(data)
        .then((result) => {
          onModeOfTransportationUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  const updateTransportationMethodDescriptionState = (
    transportationMethod: any,
  ) => {
    switch (transportationMethod) {
      case TransportationMethod.Ocean:
        return setTransportationMethodDescription(Ocean);
      case TransportationMethod.Air:
        return setTransportationMethodDescription(Air);
      case TransportationMethod.Unknown:
        return setTransportationMethodDescription(Unknown);
      case TransportationMethod.Ground:
        return setTransportationMethodDescription(Ground);
      case TransportationMethod.Rail:
        return setTransportationMethodDescription(Rail);
      case TransportationMethod.Mail:
        return setTransportationMethodDescription(Mail);
      case TransportationMethod.Pipe:
        return setTransportationMethodDescription(Pipe);
      default:
        return setTransportationMethodDescription(null);
    }
  };
  useEffect(() => {
    setModeOfTransportation((modeOfTransportationDto) => {
      if (!modeOfTransportationDto) {
        modeOfTransportationDto = initialState;
      }
      return { ...modeOfTransportationDto };
    });
  }, []);
  const onTransportationMethodChange = (
    transportationMethod: ReactSelectItem,
    context,
  ) => {
    updateTransportationMethodDescriptionState(transportationMethod?.label);
    setModeOfTransportation((modeOfTransportation) => {
      if (!modeOfTransportation) {
        modeOfTransportation = initialState;
      }
      modeOfTransportation.transportationMethod = transportationMethod?.value as TransportationMethod;
      context.setFieldValue('transportationMethodDescription', null);
      delete modeOfTransportation.transportationMethodDescription;
      return { ...modeOfTransportation };
    });
  };
  const onTransportationMethodDescriptionChange = (
    transportationMethodDescription: ReactSelectItem,
  ) => {
    setModeOfTransportation((modeOfTransportation) => {
      if (!modeOfTransportation) {
        modeOfTransportation = initialState;
      }
      modeOfTransportation.transportationMethodDescription = transportationMethodDescription?.value as TransportationMethodDescription;
      return { ...modeOfTransportation };
    });
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <ModeOfTransportationForm
      initialValues={initialValues}
      innerRef={ref}
      onSubmit={onSubmit}
      id={'mode-of-transportation-form'}
      validationSchema={modeOfTransportationSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <span>
                <Tab>Mode Of Transportation</Tab>
                <Tab>Additional</Tab>
              </span>
            </TabList>
            <TabPanel>
              <Panel className="m-3">
                {isCreateMode ? (
                  <h2 className="header-form">Add Mode of Transportation</h2>
                ) : (
                  <h2>Update Mode of Transportation</h2>
                )}
                <div className="row">
                  <div className="col-4">
                    <ModeOfTransportationForm.Description
                      name={'description'}
                      label={'Description'}
                      placeholder={'Description'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <ModeOfTransportationForm.TransportationMethod
                      placeholder={'Select Transportation Method'}
                      id={'transportationMethod'}
                      header={'Transportation Method'}
                      name={'transportationMethod'}
                      disabled={false}
                      required={true}
                      defaultValue={
                        modeOfTransportation?.transportationMethod
                          ? {
                              label:
                                TransportationMethod[
                                  modeOfTransportation?.transportationMethod
                                ],
                              value: modeOfTransportation?.transportationMethod,
                            }
                          : null
                      }
                      onChange={onTransportationMethodChange}
                      multiple={false}
                      options={getEnumValues(TransportationMethod)}
                    />
                  </div>
                </div>
                {transportationMethodDescription == null || false ? (
                  <div />
                ) : (
                  <div className="row">
                    <div className="col-4">
                      <ModeOfTransportationForm.TransportationMethodDescription
                        placeholder={'Select Transportation Method Description'}
                        id={'transportationMethodDescription'}
                        header={'Transportation Method Description'}
                        name={'transportationMethodDescription'}
                        disabled={false}
                        required={true}
                        defaultValue={
                          modeOfTransportation?.transportationMethodDescription
                            ? {
                                label:
                                  transportationMethodDescription[
                                    modeOfTransportation
                                      ?.transportationMethodDescription
                                  ],
                                value:
                                  modeOfTransportation?.transportationMethodDescription,
                              }
                            : null
                        }
                        onChange={onTransportationMethodDescriptionChange}
                        multiple={false}
                        options={getEnumValues(transportationMethodDescription)}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-4">
                    <ModeOfTransportationForm.USCustomsCode
                      name={'usCustomsCode'}
                      label={'US Customs Code'}
                      placeholder={'US Customs Code'}
                      required={true}
                    />
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel forceRender={isCreateMode ? false : true}>
              <Panel className="m-3">
                <CustomValuesInput
                  context={context}
                  customFields={customFields}
                  defaultValue={modeOfTransportation?.customValues}
                  entityName={'ModeOfTransportation'}
                  onCancel={onCancel}
                  entityType={CustomFieldEntityType.ModeOfTransportation}
                />
              </Panel>
            </TabPanel>
            <div className="justify-content-end row">
              {(userHas(
                UPDATE_MODEOFTRANSPORTATION_LINK_KEY,
                modeOfTransportation?.links,
              ) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    name="save-modeOfTransportation"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    form={'mode-of-transportation-form'}
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Mode Of Transportation
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Tabs>
        )}
      </FormContext.Consumer>
    </ModeOfTransportationForm>
  );
};
