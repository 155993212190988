import React from 'react';
import { LoginForm } from './login.form';
import { Button } from '../../common/components/button/button.component';
import { Link } from 'react-router-dom';
import { forgotPassword, ForgotPasswordData } from '../auth.store';
import { addMessage, Message } from '../../common/messages.store';
import * as Yup from 'yup';

const initialValues = {
  username: null,
};

const forgotPasswordSchema = Yup.object().shape({
  username: Yup.string().required("Can't be blank").nullable(true),
});

export const ForgotPasswordComponent = () => {
  const onSubmit = (data: ForgotPasswordData) => {
    forgotPassword(data).then((result) => {
      const message: Message = {
        id: 'email-sent',
        type: 'success',
        autoHide: true,
        message: 'Password reset link has been sent to your email!',
      };
      addMessage(message);
    });
  };
  return (
    <div>
      <LoginForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        id={'ForgotPasswordForm'}
        validationSchema={forgotPasswordSchema}
      >
        <LoginForm.Username required={true} />
        <Button
          name="sign-in"
          type="submit"
          color="primary"
          className="btn-block"
        >
          Send reset password email
        </Button>
        <div className="mt-5">
          <div className="d-flex justify-content-center">
            <span>
              <Link to="/login" data-cy="link-sign-up">
                &nbsp;Back to login
              </Link>
            </span>
          </div>
        </div>
      </LoginForm>
    </div>
  );
};
