import { Column } from '../models/data.models';
import { organizationsStore } from '../modules/organization/organization.store';
import lodash from 'lodash';

export const getSearchQuery = (filter: any, columns?: Column[]) => {
  const keys = Object.keys(filter);
  return keys
    .filter(
      (key) =>
        filter[key] !== '' && filter[key] !== null && !key.startsWith('__'),
    )
    .map((key) => {
      const column = columns?.find((c) => c.name === key);
      const value = filter[key];
      return `${column?.dataField ?? key}:${value}`;
    })
    .join(' AND ');
};

export const getAdditionalColumnsData = (filter: any) => {
  const additionalColumnsData = {};
  Object.keys(filter)
    .filter(
      (key) =>
        filter[key] !== '' && filter[key] !== null && key.startsWith('__'),
    )
    .map((key) => {
      const value = filter[key];
      return (additionalColumnsData[key] = value);
    });

  return additionalColumnsData;
};

const deepReduceObject = (keys, value) => {
  return keys
    .split('.')
    .reverse()
    .reduce((acc, current) => {
      const obj = {};
      obj[current] = acc;
      return obj;
    }, value);
};

export const parseSearchQuery = (query: string) => {
  let result = {};
  if (query) {
    const params = query.split('AND');
    params.forEach((param) => {
      let [key, value] = param.split(':');
      if (key && value) {
        key = key.trim();
        value = value.trim();
        const numberValue = Number(value);
        const parsedValue = isNaN(numberValue) ? value : numberValue;
        const filterObject = deepReduceObject(key, parsedValue);
        result = lodash.merge(result, filterObject);
      }
    });
  }
  return result;
};

export const parseParcelShipmentsQuery = (query: string) => {
  let result = {};
  if (query) {
    const params = query
      .split('AND')
      .flatMap((p) => p.replace(/\(|\)/, '').split('OR'));
    params.forEach((param) => {
      let [key, value] = param.split(':');
      if (key && value) {
        key = key.trim();
        value = value.trim();
        const numberValue = Number(value);
        const prevValue = lodash.get(result, key);
        const parsedValue = !isNaN(numberValue)
          ? numberValue
          : prevValue
          ? `${prevValue};${value}`
          : value;

        const filterObject = deepReduceObject(key, parsedValue);
        result = lodash.merge(result, filterObject);
      }
    });
  }
  return result;
};

export const getReadableQueryString = (
  query: string,
  columns: Column[],
  addData?: any,
) => {
  if (query) {
    const filters = parseSearchQuery(query);
    const keys = Object.keys(filters).filter((key) =>
      columns?.some((col) => col?.name === key || col?.dataField === key),
    );
    return keys
      .map((key) => {
        const column = columns?.find(
          (col) => col?.name === key || col?.dataField === key,
        );
        if (column?.type === 'customField') {
          return addData[`__${key}`]
            ? `${column?.title}: ${addData[`__${key}`]}`
            : '';
        } else {
          switch (key) {
            case 'BillToContactId':
              return addData['__BillToContactName']
                ? `${column?.title}: ${addData['__BillToContactName']}`
                : '';
            case 'modeOfTransportationId':
              return addData['__Description']
                ? `${column?.title}: ${addData['__Description']}`
                : '';
            default:
              return column?.title
                ? `${column?.title}: ${addData[`__${column?.name}`]}`
                : '';
          }
        }
      })
      .join(', ');
  }
  return '';
};

export const hidenFilter = (
  includedFilterQuery: string,
  filterQuery: string,
) => {
  if (filterQuery.includes(includedFilterQuery)) {
    return filterQuery;
  }

  if (filterQuery.length > 0) {
    return `${filterQuery} AND ${includedFilterQuery}`;
  }

  return includedFilterQuery;
};

export const getObjectStrings = (obj) => {
  let strings = [];
  if (obj)
    if (typeof obj == 'object') {
      const values = Object.values(obj);
      values?.map((v) => (strings = [...strings, ...getObjectStrings(v)]));
    } else if (typeof obj == 'string') return [obj];
    else return strings;
  return strings;
};

export const sortFn = (a, b, sort) => {
  if (sort && sort?.length) {
    let keys = [];
    let reverse = false;
    if (sort[0] == '-') {
      reverse = true;
      keys = sort.slice(1, sort.length).split('.');
    } else keys = sort.split('.');

    for (let key of keys) {
      if (key.includes('[') && key.includes(']')) {
        const regex = /(?<=\[)(.*?)(?=])/;
        const matches = key.match(regex);
        if (matches && matches.length > 0) {
          key = key?.split('[')[0];
          const filterValue = matches[0];
          const filterParts = filterValue.split('=');

          a =
            a &&
            a[key] &&
            a[key].filter((x) => x[filterParts[0]] == filterParts[1]);
          b =
            b &&
            b[key] &&
            b[key].filter((x) => x[filterParts[0]] == filterParts[1]);
        }
      } else {
        a = a && a[key];
        b = b && b[key];
      }
    }
    a = a ?? '';
    b = b ?? '';
    if (a < b) {
      return reverse ? -1 : 1;
    } else if (a > b) {
      return reverse ? 1 : -1;
    }
  }
  return 0;
};

export const getRouteFeature = () => {
  const currentPath = window.location.pathname;
  var prefix = '/';

  const { currentOrganization } = organizationsStore.getState();

  if (currentPath != '/users') {
    prefix = `/org/${currentOrganization.organizationId}/`;
  }

  if (currentPath.startsWith(prefix)) {
    const feature = currentPath.slice(prefix.length);

    return feature;
  }
  return null;
};
