import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DriverPayManagerListScreen } from './screens/driverPayManager-list.screen';

export const DRIVER_PAY_MANAGER_LIST_PATH = '/driver-pay-manager';

export const DriverPayManagerRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <DriverPayManagerListScreen
              pageTitle="Driver Pay Manager"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
