import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  WorkflowDto,
  WorkflowDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  workflowStore,
  getWorkflowsColumnsFx,
  getWorkflowsFx,
  deleteWorkflowFx,
  exportWorkflowsFx,
  importWorkflowsFx,
} from '../workflows.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_WORKFLOW_LINK_KEY,
  DELETE_WORKFLOW_LINK_KEY,
  IMPORT_WORKFLOWS_LINK_KEY,
  EXPORT_WORKFLOWS_LINK_KEY,
  GetWorkflowParams,
} from '../workflows.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { WorkflowDialog } from './workflow.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiGithub, FiTerminal, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';
import { triggerWorkflowByConfig } from '../../workflowTriggers/workflowTriggerHook';

export type WorkflowsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (workflowParams: GetWorkflowParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (viewName: string) => void;
  onSort?: (field: string) => void;
  onSelect?: (workflow: WorkflowDto, workflowParams: GetWorkflowParams) => void;
};

export const WorkflowsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: WorkflowsListProps) => {
  const [workflows, setWorkflows] = useState<WorkflowDtoPagedResult | null>(
    null,
  );

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    workflowColumns: columns,
    defaultWorkflowColumns: defaultColumns,
  } = useStore(workflowStore);

  useEffect(() => {
    getWorkflowsColumnsFx();
  }, []);

  const getWorkflows = useCallback(async () => {
    const workflowsData = await getWorkflowsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(workflowsData));
    setWorkflows(workflowsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: WorkflowDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_WORKFLOWS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importWorkflowsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getWorkflows();
          },
          result?.links,
        ),
        getAction(
          EXPORT_WORKFLOWS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportWorkflowsFx({ listResource: result }),
          result?.links,
        ),
        getAction(
          IMPORT_WORKFLOWS_LINK_KEY,
          'Republish Workflows',
          <FiGithub />,
          async () => {
            await triggerWorkflowByConfig({
              configName: 'tms.github.integration',
              workflowIdConfigKey: 'republishWorkflowId',
            });
          },
          result?.links,
        ),
        getAction(
          IMPORT_WORKFLOWS_LINK_KEY,
          'Reload Workflows',
          <FiGithub />,
          async () => {
            await triggerWorkflowByConfig({
              configName: 'tms.github.integration',
              workflowIdConfigKey: 'reloadWorkflowId',
            });
          },
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getWorkflows],
  );

  useEffect(() => {
    getWorkflows();
  }, [getWorkflows]);

  const onDeleteWorkflow = useCallback(
    async (workflow: WorkflowDto) => {
      if (userHas(DELETE_WORKFLOW_LINK_KEY, workflows?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Workflow`,
            message: 'Are you sure you want to delete workflow?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteWorkflowFx(workflow);
            getWorkflows();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getWorkflows, onDelete, workflows],
  );

  const onEditWorkflow = (workflow: WorkflowDto) => {
    if (workflow) goToDetails({ workflowId: workflow.workflowId });
  };

  const onWorkflowSelect = useCallback(
    async (workflow) => {
      const selectedWorkflow = await showDialog({
        dialog: WorkflowDialog,
        props: {
          workflowId: workflow.workflowId,
          title: 'Update Workflow',
        },
      });
      if (selectedWorkflow) {
        getWorkflows();
      }
    },
    [getWorkflows],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          rowKeys={['workflowId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={workflows.items}
          onLimitChanged={onLimitChanged}
          total={workflows.totalCount}
          onDelete={onDeleteWorkflow}
          onSort={onSort}
          onEdit={onEditWorkflow}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onWorkflowSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {workflows?.totalCount === 0 && !search && (
          <ListElementsNotFound
            entityName="Workflow"
            entityLinkKey={CREATE_WORKFLOW_LINK_KEY}
            onClick={goToCreate}
          />
        )}
      </>
    );
  }
};
