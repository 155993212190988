import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContainerDescriptionsListScreen } from './screens/containerDescriptions-list.screen';
import { ContainerDescriptionScreen } from './screens/containerDescription.screen';

export const CONTAINER_DESCRIPTIONS_LIST_PATH = '/container-descriptions';
export const CONTAINER_DESCRIPTION_EDIT_PATH =
  '/container-descriptions/:containerDescriptionCode';
export const CONTAINER_DESCRIPTION_CREATE_PATH = '/container-descriptions/0';

export const ContainerDescriptionsRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ContainerDescriptionsListScreen
              pageTitle="Container Descriptions"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ContainerDescriptionScreen group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':containerDescriptionCode'}
        element={
          <PrivateRoute>
            <ContainerDescriptionScreen group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
