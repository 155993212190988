import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { UserSettingForm } from './userSetting.form';
import {
  Column,
  UpdateDefaultViewCommandValues,
  UserSettingDto,
} from '../../../models/data.models';
import {
  createUserSetting,
  getUserSetting,
  updateDefaultView,
  updateUserSetting,
} from '../userSettings.store';
import { authStore } from '../../auth/auth.store';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { FiSave } from 'react-icons/fi';
import { FilteredField } from '../../common/components/filters/filtersTab.component';
import { MdDeleteOutline } from 'react-icons/md';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { FormikValues } from 'formik';
import { getRouteFeature } from '../../../utils/query.utils';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { Spinner } from '../../common/components/spinner/spinner';

export type UserSettingEditProps = {
  settingId?: number | null;
  name?: string;
  columns?: Column[] | null;
  filters: FilteredField[];
  sort?: string;
  limit?: number;
  filterParams?: string | null;
  contextValues: FormikValues;
  isVisibleToEveryone?: boolean;
  views?: any[];
  orgViews?: any[];
  userSettingId?: number | null;
  orgSettingId?: number | null;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onUserSettingCreated?: (userSetting: UserSettingDto) => void;
  onUserSettingUpdated?: (userSetting: UserSettingDto) => void;
  onUserSettingLoaded?: (userSetting: UserSettingDto) => void;
  onCancel?: () => void;
  onSaveHandle?: () => Promise<void>;
};

const initialState: UserSettingDto = {
  userSettingId: 0,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: '',
  organizationId: 0,
  settings: null,
  userId: null,
  links: [],
};

const nameValidation = Yup.string()
  .required("Name can't be blank")
  .matches(/^[A-Za-z0-9!: _-]+$/, 'Invalid characters')
  .max(150, 'Name must be 150 characters or fewer')
  .test(
    'no-whitespace',
    'Name cannot be empty',
    (value) => !/^\s*$/.test(value),
  );

let userSettingSchema = Yup.object().shape({
  name: nameValidation,
});

const modalConfirmMessage = (fieldName: string) => (
  <div>
    <div className="mb-2">View with the name "{fieldName}" already exists.</div>
    <div className="d-flex justify-content-center align-items-center">
      Do you want to overwrite it?
    </div>
  </div>
);

export const UserSettingEdit = ({
  settingId,
  name,
  columns,
  filters,
  sort,
  limit,
  contextValues,
  isVisibleToEveryone,
  views,
  orgViews,
  userSettingId,
  orgSettingId,
  onViewChanged = () => {},
  onUserSettingLoaded = () => {},
  onUserSettingCreated = () => {},
  onUserSettingUpdated = () => {},
  onCancel = () => {},
  onSaveHandle,
}: UserSettingEditProps) => {
  const isCreateMode = !settingId || settingId === 0;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDefault, setIsLoadingDefault] = useState(false);
  const [userSetting, setUserSetting] = useState<UserSettingDto | null>(null);
  const [
    isVisibleToEveryoneValue,
    setIsVisibleToEveryoneValue,
  ] = useState<boolean>(isVisibleToEveryone ?? false);
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const { user: currentUser } = authStore.getState();

  useEffect(() => {
    const views = userSetting?.settings?.['views'];
    if (views) {
      const currentView = views.find((view) => view['name'] === name);
      const isDefaultValue = currentView?.isDefault;
      setIsDefault(isDefaultValue);
    }
  }, [userSetting]);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (settingId) {
      getUserSetting({ userSettingId: settingId }).then(
        (userSettingDto: UserSettingDto) => {
          userSettingDto.name = name;
          setUserSetting(userSettingDto);
          setIsLoading(false);
          onUserSettingLoaded(userSettingDto);
        },
      );
    } else {
      throw new Error('UserSetting keys were not provided');
    }
  }, [settingId]);

  const handleMakeDefault = async (fieldName?: string) => {
    setIsLoadingDefault(true);

    try {
      const dialogResult = await showDialog({
        dialog: Confirm,
        props: {
          className: 'modal-confirm-existed-view',
          message: `Are you sure you want to make '${fieldName}' the default?`,
          topPosition: 50,
        },
      });
      if (dialogResult) {
        const updateDefaultViewData: UpdateDefaultViewCommandValues = {
          viewName: fieldName,
          defaultValue: !isDefault,
          userSettingId: settingId,
        };
        const updatedSetting = await updateDefaultView(updateDefaultViewData);

        if (updatedSetting) {
          setIsDefault(true);
        }

        onSaveHandle();
      }
    } catch (error) {
      // Handle error here
    } finally {
      setIsLoadingDefault(false);
    }
  };

  const handleRemoveDefault = async (fieldName) => {
    setIsLoadingDefault(true);

    try {
      const dialogResult = await showDialog({
        dialog: Confirm,
        props: {
          className: 'modal-confirm-existed-view',
          message: `Are you sure you want to remove default state for '${fieldName}'?`,
          topPosition: 50,
        },
      });
      if (dialogResult) {
        const updateDefaultViewData: UpdateDefaultViewCommandValues = {
          viewName: fieldName,
          defaultValue: !isDefault,
          userSettingId: settingId,
        };
        const updatedSetting = await updateDefaultView(updateDefaultViewData);

        if (updatedSetting) {
          setIsDefault(false);
        }

        onSaveHandle();
      }
    } catch (error) {
      // Handle error herre
    } finally {
      setIsLoadingDefault(false);
    }
  };

  const handleVisibility = () => {
    setIsVisibleToEveryoneValue((prevValue) => {
      if (prevValue) {
        setIsDefault(false);
      }
      return !prevValue;
    });
  };

  const handleDefaultState = () => {
    setIsDefault((prevValue) => !prevValue);
  };

  const handleViewUpdate = async (fieldName: string) => {
    const existedPrivateView = views?.some((view) => view.name === fieldName);
    const existedOrgView = orgViews?.some((view) => view.name === fieldName);

    if (settingId && (existedPrivateView || existedOrgView)) {
      if (fieldName === name) {
        return false;
      }
      addMessage({
        message: `View with the name "${fieldName}" already exists`,
        type: 'danger',
        id: v4(),
      });

      return true;
    }

    if (existedPrivateView || existedOrgView) {
      const data: UserSettingDto = await getUserSetting({
        userSettingId: existedPrivateView
          ? userSettingId
          : existedOrgView && orgSettingId,
      });

      const indexToUpdate = data.settings?.views?.findIndex(
        (view) => view.name === fieldName,
      );

      if (indexToUpdate !== undefined && indexToUpdate !== -1) {
        data.settings.views[indexToUpdate] = {
          name: fieldName,
          columns: columns,
          filters: filters,
          contextValues: contextValues,
          sort: sort,
          limit: limit,
          isDefault: data.settings.views[indexToUpdate].isDefault,
        };
      }

      const dialogResult = await showDialog({
        dialog: Confirm,
        props: {
          className: 'modal-confirm-existed-view',
          message: modalConfirmMessage(fieldName),
        },
      });
      if (dialogResult) {
        const response = await updateUserSetting(data);
        onUserSettingUpdated(response.data);

        onSaveHandle();
      }
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (data: UserSettingDto) => {
    const feature = getRouteFeature();
    if (feature) {
      let result: any;
      const fieldName = data.name;

      const existedView = await handleViewUpdate(fieldName);

      if (!existedView) {
        if (isCreateMode) {
          if (!isVisibleToEveryoneValue) {
            data.userId = currentUser.userId;
          }
          data.settings = {
            views: [
              {
                name: fieldName,
                columns: columns,
                filters: filters,
                contextValues: contextValues,
                sort: sort,
                limit: limit,
                isDefault: isDefault,
              },
            ],
          };

          data.name = `tms:${feature}:grid`;
          try {
            result = await createUserSetting(data);

            if (result?.userSettingId && isDefault) {
              const updateDefaultViewData: UpdateDefaultViewCommandValues = {
                viewName: fieldName,
                defaultValue: isDefault,
                userSettingId: result.userSettingId,
              };
              await updateDefaultView(updateDefaultViewData);
            }
          } catch {
            data.name = '';
          }
          onUserSettingCreated(result);
        } else {
          if (isVisibleToEveryoneValue) {
            data.userId = null;
          } else {
            data.userId = currentUser.userId;
          }

          const viewToUpdate = data.settings['views']?.find(
            (x) => x.name === name,
          );
          if (viewToUpdate) {
            if (isDefault) {
              viewToUpdate.isDefault = true;
              data.settings['views'].forEach((view) => {
                if (view.name != name) {
                  view.isDefault = false;
                }
              });
            } else {
              data.settings['views'].forEach((view) => {
                if (view.name === name) {
                  view.isDefault = false;
                }
              });
            }
            viewToUpdate.name = data.name;
          }

          data.name = `tms:${feature}:grid`;
          try {
            result = await updateUserSetting(data);
          } catch {
            data.name = '';
          }
          onUserSettingUpdated(result);
        }

        await onSaveHandle();

        if (onViewChanged) {
          onViewChanged(fieldName, sort, limit);
        }
      }
    }
  };

  const deleteView = (name: string) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${name} View`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          getUserSetting({ userSettingId: settingId }).then(
            (userSettingDto: UserSettingDto) => {
              userSettingDto.settings = {
                views: userSettingDto.settings['views'].filter(
                  (x) => x.name !== name,
                ),
              };

              updateUserSetting(userSettingDto).then(
                () => {
                  onSaveHandle();
                  onCancel();
                  addMessage({
                    message: 'View deleted successfully',
                    type: 'success',
                    id: v4(),
                  });
                },
                () => {},
              );
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <div className="row">
      <div className="w-100 px-3 pb-2">
        <div className="row">
          <div className="col-1">
            <div className="circle circle-outer">
              <div className="circle circle-inner">
                <FiSave color="#175CD3" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="px-3">
              <span className="table-header-text">Save table view</span>
            </div>
            <div className="px-3">
              <span className="table-subtext">
                Please enter name for new table view
              </span>
            </div>
          </div>
          {isLoadingDefault ? (
            <Spinner
              size="sm"
              className="mt-1 mr-2"
              color="color-action-positive"
            />
          ) : (
            <>
              {!isCreateMode &&
                isVisibleToEveryoneValue &&
                (isDefault ? (
                  <div className="pb-4">
                    <div
                      className="default-clickable-badge"
                      onClick={() => handleRemoveDefault(name)}
                    >
                      default
                    </div>
                  </div>
                ) : (
                  <div className="pb-4">
                    <button
                      name="make-default"
                      type="button"
                      className="btn btn-make-default"
                      onClick={() => handleMakeDefault(name)}
                    >
                      Make default
                    </button>
                  </div>
                ))}
            </>
          )}

          <div className="col-1">
            {name && (
              <MdDeleteOutline
                size={'25px'}
                color="red"
                className="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteView(name);
                }}
              />
            )}
          </div>
        </div>
        <UserSettingForm
          initialValues={userSetting || initialState}
          validationSchema={userSettingSchema}
          onSubmit={onSubmit}
        >
          <div className="pt-3">
            <UserSettingForm.Name label={null} />
          </div>
          {!settingId && isCreateMode && (
            <div className="row py-1">
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ userSelect: 'none' }}
              >
                {isVisibleToEveryoneValue ? (
                  <ImCheckboxChecked
                    onClick={handleVisibility}
                    size={'20px'}
                    className="pointer mr-2"
                    color="#066FFC"
                  />
                ) : (
                  <ImCheckboxUnchecked
                    onClick={handleVisibility}
                    size={'20px'}
                    className="pointer mr-2"
                    color="#D0D5DD"
                  />
                )}
                &nbsp;Visible to everyone
              </div>
              {isVisibleToEveryoneValue && (
                <div
                  className="d-flex justify-content-start align-items-center ml-3"
                  style={{ userSelect: 'none' }}
                >
                  {isDefault ? (
                    <ImCheckboxChecked
                      onClick={handleDefaultState}
                      size={'20px'}
                      className="pointer mr-2"
                      color="#066FFC"
                    />
                  ) : (
                    <ImCheckboxUnchecked
                      onClick={handleDefaultState}
                      size={'20px'}
                      className="pointer mr-2"
                      color="#D0D5DD"
                    />
                  )}
                  &nbsp;Make default
                </div>
              )}
            </div>
          )}
          <div className={`row ${!settingId ? 'pt-4' : 'pt-2'}`}>
            <div className="col-6">
              <Button
                name="save-userSetting"
                type="submit"
                color="primary"
                className="btn-dialog-save"
              >
                {settingId ? 'Update' : 'Save'}
              </Button>
            </div>
            <div className="col-6">
              <Button
                name="save-userSetting"
                type="button"
                color="secondary"
                onClick={onCancel}
                className="btn-dialog-cancel"
              >
                Cancel
              </Button>
            </div>
          </div>
        </UserSettingForm>
      </div>
    </div>
  );
};
