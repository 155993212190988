import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { JobStatusForm } from './jobStatus.form';
import {
  JobStatusDto,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  StatusStage,
} from '../../../models/data.models';
import {
  createJobStatusFx,
  deleteJobStatusFx,
  getJobStatusFx,
  updateJobStatusFx,
} from '../jobStatuses.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import {
  DELETE_JOBSTATUS_LINK_KEY,
  UPDATE_JOBSTATUS_LINK_KEY,
} from '../jobStatuses.service';
import { userHas } from '../../auth/auth.store';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { Dropdown } from 'react-bootstrap';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { FiDelete, FiUpload } from 'react-icons/fi';
import { IAction, getAction } from '../../common/components/actions/actions';
import { AiFillDelete } from 'react-icons/ai';

export type JobStatusEditProps = {
  jobStatusId?: number | string | null | undefined;
  onJobStatusCreated?: (jobStatus: JobStatusDto) => void;
  onJobStatusUpdated?: (jobStatus: JobStatusDto) => void;
  onJobStatusLoaded?: (jobStatus: JobStatusDto) => void;
  onCancel?: () => void;
  onDelete?: (jobStatus: JobStatusDto) => void;
};

const initialState: JobStatusDto = {
  customValues: null,
  jobStatusName: '',
  priority: 0,
  statusStage: StatusStage.Pending,
  links: [],
};

export const JobStatusEdit = ({
  jobStatusId,
  onJobStatusLoaded,
  onJobStatusCreated,
  onJobStatusUpdated,
  onCancel,
  onDelete,
}: JobStatusEditProps) => {
  const [isCreateMode, setIsCreateMode] = useState(jobStatusId == null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [jobStatus, setJobStatus] = useState<JobStatusDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setIsCreateMode(jobStatusId == null);
  }, [jobStatusId]);

  const jobStatusSchema = useMemo(() => {
    let schema = Yup.object().shape({
      jobStatusName: Yup.string()
        .max(50)
        .required('Job Status Name is required'),
      priority: Yup.number().required('Priority is required'),
      statusStage: Yup.number().required('Status Stage is required'),
    });

    if (customFields.length > 0) {
      schema = generateValidationSchemaWithCustomFields(customFields, schema);
    }

    return schema;
  }, [customFields]);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        DELETE_JOBSTATUS_LINK_KEY,
        'Delete',
        <AiFillDelete />,
        async () => {
          const dialogResult = await showDialog({
            dialog: Confirm,
            props: {
              title: `Delete JobStatus`,
              message: `Are you sure you want to delete ${jobStatus.jobStatusName}?`,
              className: 'delete-modal',
            },
          });
          if (dialogResult) {
            try {
              await deleteJobStatusFx(jobStatus);
              if (onCancel) onCancel();
            } catch (error) {
              // Handle error here
            }
          }
        },
        jobStatus?.links,
      ),
    ];
    return activeActions.filter(Boolean);
  }, [jobStatus]);

  const fetchCustomFields = useCallback(async () => {
    const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.JobStatus} AND isInactive: false`,
    });
    setCustomFields(fields.items);
  }, []);

  const fetchJobStatusData = useCallback(async () => {
    const jobStatusDto = await getJobStatusFx({
      jobStatusId: Number(jobStatusId),
    });
    setJobStatus(jobStatusDto);
    if (onJobStatusLoaded) onJobStatusLoaded(jobStatusDto);
    setIsLoading(false);
  }, [jobStatusId, onJobStatusLoaded]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    if (!isCreateMode) {
      fetchJobStatusData();
    } else {
      setJobStatus({ ...initialState });
      setIsLoading(false);
    }
  }, [isCreateMode, fetchJobStatusData]);

  const onSubmit = useCallback(
    async (data: JobStatusDto) => {
      setIsSubmitting(true);
      try {
        const result = isCreateMode
          ? await createJobStatusFx(data)
          : await updateJobStatusFx(data);
        isCreateMode
          ? onJobStatusCreated?.(result)
          : onJobStatusUpdated?.(result);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isCreateMode, onJobStatusCreated, onJobStatusUpdated],
  );

  const onCustomFieldChange = (result) => {
    setJobStatus((prevState) => ({
      ...prevState,
      customValues: result,
    }));
  };

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <JobStatusForm
      id={'jobStatus-form'}
      initialValues={jobStatus || initialState}
      onSubmit={onSubmit}
      validationSchema={jobStatusSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Additional</Tab>
                <li className="react-tabs__tab react-tabs__tab--button">
                  <ActionsDropdown size="sm" actions={actions} />
                </li>
              </TabList>

              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New Job Status</h3>
                  ) : (
                    <h3 className="header-form">Update Job Status</h3>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <JobStatusForm.JobStatusName
                        defaultValue={jobStatus.jobStatusName}
                      />

                      <JobStatusForm.StatusStage
                        defaultValue={jobStatus.statusStage}
                      />
                      <JobStatusForm.Priority
                        defaultValue={jobStatus.priority}
                      />
                    </div>
                  </div>
                </Panel>
              </TabPanel>

              <TabPanel forceRender={!isCreateMode}>
                {/* <JobStatusForm.CustomFields
                  customFields={customFields}
                  entityType={CustomFieldEntityType.JobStatus}
                  defaultValue={isCreateMode ? {} : jobStatus?.customValues}
                  onChange={onCustomFieldChange}
                  saveButtonRenderCondition={
                    userHas(UPDATE_JOBSTATUS_LINK_KEY, jobStatus?.links) ||
                    isCreateMode
                  }
                  isSending={isSubmitting}
                  formName={'jobStatus-form'}
                  entityName={'JobStatus'}
                  onCancel={onCancel}
                  context={context}
                /> */}
              </TabPanel>
            </Tabs>
            <div className="row m-3">
              <div className="col-auto text-right">
                {(userHas(UPDATE_JOBSTATUS_LINK_KEY, jobStatus?.links) ||
                  isCreateMode) && (
                  <Button
                    name="save-jobStatus"
                    type="submit"
                    color="primary"
                    form={'jobStatus-form'}
                    disabled={isSubmitting}
                  >
                    Save JobStatus
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="ml-2"
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </JobStatusForm>
  );
};
