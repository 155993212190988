import { Dialog } from '../../common/components/dialog/dialog.component';
import { ModeOfTransportationEdit } from './modeOfTransportation-edit.component';
import { ModeOfTransportationDto } from '../../../models/data.models';

export type ModeOfTransportationDialogProperties = {
  modeOfTransportationId?: number | null;
  title: string;
};

export class ModeOfTransportationDialog extends Dialog<ModeOfTransportationDialogProperties> {
  constructor(props: ModeOfTransportationDialogProperties) {
    super(props);
    this.onModeOfTransportationSaved = this.onModeOfTransportationSaved.bind(
      this,
    );
  }

  onModeOfTransportationSaved = (
    modeOfTransportation: ModeOfTransportationDto,
  ) => {
    this.close(modeOfTransportation);
  };

  renderContent(): any {
    const { modeOfTransportationId } = this
      .props as ModeOfTransportationDialogProperties;
    return (
      <ModeOfTransportationEdit
        modeOfTransportationId={modeOfTransportationId}
        onModeOfTransportationCreated={this.onModeOfTransportationSaved}
        onModeOfTransportationUpdated={this.onModeOfTransportationSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
