import { useEffect, useState } from 'react';
import {
  parseKeys,
  useComponentQueries,
  useComponentVariables,
} from '../component-hooks';
import { ComponentProps } from '../layout-interfaces';
import { useFormikContext } from 'formik';

export const DataSourceComponent = (props: ComponentProps) => {
  const formikContext = useFormikContext<any>();
  const { variables } = useComponentVariables(props);
  const { getPropertyValue, queries } = useComponentQueries(props, variables);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (queries) {
      // for each query, get the data and set the store
      for (const key in queries) {
        if (queries.hasOwnProperty(key)) {
          getPropertyValue({ fromQuery: key }, null, {
            ...formikContext?.values,
            ...props.context.store,
            ...variables,
          })
            .then((res) => {
              if (props.context?.setStore) props.context?.setStore(key, res);
              // invoke onLoad action
              if (props?.props.onSuccess) {
                // parse keys
                const onLoadResult = parseKeys(props?.props.onSuccess, {
                  loadedResult: res,
                  ...variables,
                });
                props.context?.action(
                  onLoadResult,
                  {
                    loadedResult: res,
                  },
                  {
                    sender: props.name,
                    formikContext,
                  },
                );
              }
            })
            .catch((e) => {
              console.log('error', e);
            })
            .finally(() => {
              setIsLoaded(true);
            });
        }
      }
    }
  }, [
    queries,
    variables,
    props.context?.refreshHandlers?.[props.props.refreshHandler],
  ]);

  return <></>;
};
