import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { JobForm } from './job.form';
import {
  JobDto,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  CustomFieldType,
} from '../../../models/data.models';
import { createJobFx, deleteJobFx, getJobFx, updateJobFx } from '../jobs.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import { DELETE_JOB_LINK_KEY, UPDATE_JOB_LINK_KEY } from '../jobs.service';
import { userHas } from '../../auth/auth.store';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { Dropdown } from 'react-bootstrap';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { FiDelete, FiUpload } from 'react-icons/fi';
import { IAction, getAction } from '../../common/components/actions/actions';
import { AiFillDelete } from 'react-icons/ai';

export type JobEditProps = {
  jobId?: string | null | undefined;
  onJobCreated?: (job: JobDto) => void;
  onJobUpdated?: (job: JobDto) => void;
  onJobLoaded?: (job: JobDto) => void;
  onCancel?: () => void;
  onDelete?: (job: JobDto) => void;
};

const initialState: JobDto = {
  customValues: null,
  customerId: null,
  description: null,
  divisionId: null,
  employeeId: 0,
  jobNumber: '',
  jobStatusId: 0,
  links: [],
};

export const JobEdit = ({
  jobId,
  onJobLoaded,
  onJobCreated,
  onJobUpdated,
  onCancel,
  onDelete,
}: JobEditProps) => {
  const [isCreateMode, setIsCreateMode] = useState(jobId == null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [job, setJob] = useState<JobDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setIsCreateMode(jobId == null);
  }, [jobId]);

  const jobSchema = useMemo(() => {
    let schema = Yup.object().shape({
      description: Yup.string().max(700).required('Description is required'),
      customerId: Yup.number().required('Customer is required'),
      divisionId: Yup.number().required('Division is required'),
      employeeId: Yup.number().required('Employee is required'),
      jobStatusId: Yup.number().required('Job Status is required'),
    });

    if (customFields.length > 0) {
      schema = generateValidationSchemaWithCustomFields(customFields, schema);
    }

    return schema;
  }, [customFields]);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        DELETE_JOB_LINK_KEY,
        'Delete',
        <AiFillDelete />,
        async () => {
          const dialogResult = await showDialog({
            dialog: Confirm,
            props: {
              title: `Delete Job`,
              message: `Are you sure you want to delete ${job.description}?`,
              className: 'delete-modal',
            },
          });
          if (dialogResult) {
            try {
              await deleteJobFx(job);
              if (onCancel) onCancel();
            } catch (error) {
              // Handle error here
            }
          }
        },
        job?.links,
      ),
    ];
    return activeActions.filter(Boolean);
  }, [job]);

  const fetchCustomFields = useCallback(async () => {
    const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Job} AND isInactive: false`,
    });
    setCustomFields(fields.items);
  }, []);

  const fetchJobData = useCallback(async () => {
    const jobDto = await getJobFx({ jobId });
    setJob(jobDto);
    if (onJobLoaded) onJobLoaded(jobDto);
    setIsLoading(false);
  }, [jobId, onJobLoaded]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    if (!isCreateMode) {
      fetchJobData();
    } else {
      setJob({ ...initialState });
      setIsLoading(false);
    }
  }, [isCreateMode, fetchJobData]);

  const onSubmit = useCallback(
    async (data: JobDto) => {
      setIsSubmitting(true);
      try {
        const result = isCreateMode
          ? await createJobFx(data)
          : await updateJobFx(data);
        isCreateMode ? onJobCreated?.(result) : onJobUpdated?.(result);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isCreateMode, onJobCreated, onJobUpdated],
  );

  const onCustomFieldChange = (result) => {
    setJob((prevState) => ({
      ...prevState,
      customValues: result,
    }));
  };

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <JobForm
      id={'job-form'}
      initialValues={job || initialState}
      onSubmit={onSubmit}
      validationSchema={jobSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Additional</Tab>
                <li className="react-tabs__tab react-tabs__tab--button">
                  <ActionsDropdown size="sm" actions={actions} />
                </li>
              </TabList>

              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New Job</h3>
                  ) : (
                    <h3 className="header-form">Update Job</h3>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <JobForm.JobNumber
                        defaultValue={job.jobNumber}
                        disabled={true}
                      />
                      <JobForm.CustomerId defaultValue={job.customerId} />
                      <JobForm.Description defaultValue={job.description} />
                      <JobForm.DivisionId defaultValue={job.divisionId} />
                      <JobForm.EmployeeId defaultValue={job.employeeId} />
                      <JobForm.JobStatusId defaultValue={job.jobStatusId} />
                    </div>
                  </div>
                </Panel>
              </TabPanel>

              <TabPanel forceRender={!isCreateMode}>
                {/* <JobForm.CustomFields
                  customFields={customFields}
                  entityType={CustomFieldEntityType.Job}
                  defaultValue={isCreateMode ? {} : job?.customValues}
                  onChange={onCustomFieldChange}
                  saveButtonRenderCondition={
                    userHas(UPDATE_JOB_LINK_KEY, job?.links) || isCreateMode
                  }
                  isSending={isSubmitting}
                  formName={'job-form'}
                  entityName={'Job'}
                  onCancel={onCancel}
                  context={context}
                /> */}
              </TabPanel>
            </Tabs>
            <div className="row m-3">
              <div className="col-auto text-right">
                {(userHas(UPDATE_JOB_LINK_KEY, job?.links) || isCreateMode) && (
                  <Button
                    name="save-job"
                    type="submit"
                    color="primary"
                    form={'job-form'}
                    disabled={isSubmitting}
                  >
                    Save Job
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="ml-2"
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </JobForm>
  );
};
