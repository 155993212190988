import {
  WarehouseLocationDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateWarehouseLocationCommandValues,
  UpdateWarehouseLocationCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createWarehouseLocationRequest,
  getWarehouseLocationsListRequest,
  getWarehouseLocationRequest,
  deleteWarehouseLocationRequest,
  updateWarehouseLocationRequest,
  importWarehouseLocationsRequest,
  exportWarehouseLocationsRequest,
  GetWarehouseLocationParams,
} from './warehouseLocations.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type WarehouseLocationsStoreState = {
  links: LinkDto[];
  warehouseLocationColumns: EntityFieldDto[];
  defaultWarehouseLocationColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getWarehouseLocationsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'WarehouseLocation',
  });
});

addEffectStatusHandling(getWarehouseLocationsColumnsFx);

export const getWarehouseLocationsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'WarehouseLocation',
  });
});

addEffectStatusHandling(getWarehouseLocationsDefaultColumnsFx);

export const updateWarehouseLocationsColumns = createEvent<EntityFieldDto[]>();

export const getWarehouseLocationsFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getWarehouseLocationsListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getWarehouseLocationsFx);

export const createWarehouseLocationFx = createEffect(
  (warehouseLocationData: WarehouseLocationDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createWarehouseLocationCommand: CreateWarehouseLocationCommandValues = {
      ...warehouseLocationData,
    };

    return createWarehouseLocationRequest(
      currentOrganization!,
      createWarehouseLocationCommand,
    );
  },
);

addEffectStatusHandling(createWarehouseLocationFx, {
  completeMessage: 'Warehouse location was created successfully',
  errorMessage: 'Warehouse location was not created',
});

export const getWarehouseLocationFx = createEffect(
  (warehouseLocationParams: GetWarehouseLocationParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getWarehouseLocationRequest(
      currentOrganization as LinkResourceBaseDto,
      warehouseLocationParams,
    );
  },
);

addEffectStatusHandling(getWarehouseLocationFx);

export const updateWarehouseLocationFx = createEffect(
  (warehouseLocation: WarehouseLocationDto) => {
    const updateWarehouseLocationCommand: UpdateWarehouseLocationCommandValues = {
      ...warehouseLocation,
    };
    return updateWarehouseLocationRequest(
      warehouseLocation,
      updateWarehouseLocationCommand,
    );
  },
);

addEffectStatusHandling(updateWarehouseLocationFx, {
  completeMessage: 'Warehouse location was updated successfully',
  errorMessage: 'Warehouse location was not updated',
});

export const deleteWarehouseLocationFx = createEffect(
  (warehouseLocation: WarehouseLocationDto) => {
    return deleteWarehouseLocationRequest(warehouseLocation);
  },
);

addEffectStatusHandling(deleteWarehouseLocationFx, {
  completeMessage: 'Warehouse location was deleted successfully',
  errorMessage: 'Warehouse location was not deleted',
});

export const exportWarehouseLocationsFx = createEffect(
  ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    exportWarehouseLocationsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportWarehouseLocationsFx, {
  completeMessage: 'Warehouse locations were exported successfully',
  errorMessage: 'Warehouse locations were not exported',
});

export const importWarehouseLocationsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importWarehouseLocationsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importWarehouseLocationsFx, {
  completeMessage: 'Warehouse locations were imported successfully',
  errorMessage: 'Warehouse locations were not imported',
  inFlightMessage: 'Importing warehouse locations...',
});

const defaultState: WarehouseLocationsStoreState = {
  links: [],
  warehouseLocationColumns: [],
  defaultWarehouseLocationColumns: [],
  defaultSort: 'warehouseLocationId',
  defaultLimit: 20,
};

export const warehouseLocationStore: Store<WarehouseLocationsStoreState> = createStore(
  defaultState,
)
  .on(getWarehouseLocationsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      warehouseLocationColumns: payload.result.items,
      defaultWarehouseLocationColumns: payload.result.items,
    };
  })
  .on(getWarehouseLocationsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultWarehouseLocationColumns: payload.result.items,
    };
  })
  .on(updateWarehouseLocationsColumns, (state, payload) => {
    return { ...state, warehouseLocationColumns: payload };
  });
