import {
  AccountingTransactionDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateAccountingTransactionCommandValues,
  UpdateAccountingTransactionCommandValues,
  CreateChargeCommand,
  UpdateChargeCommand,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAccountingTransactionRequest,
  getAccountingTransactionsListRequest,
  getAccountingTransactionRequest,
  deleteAccountingTransactionRequest,
  updateAccountingTransactionRequest,
  importAccountingTransactionsRequest,
  exportAccountingTransactionsRequest,
  GetAccountingTransactionParams,
} from './accountingTransactions.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type AccountingTransactionsStoreState = {
  links: LinkDto[];
  accountingTransactionColumns: EntityFieldDto[];
  defaultAccountingTransactionColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getAccountingTransactionsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AccountingTransaction',
  });
});

addEffectStatusHandling(getAccountingTransactionsColumnsFx);

export const getAccountingTransactionsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'AccountingTransaction',
  });
});

addEffectStatusHandling(getAccountingTransactionsDefaultColumnsFx);

export const updateAccountingTransactionsColumns = createEvent<
  EntityFieldDto[]
>();

export const getAccountingTransactionsFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingTransactionsListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getAccountingTransactionsFx);

export const createAccountingTransactionFx = createEffect(
  (accountingTransactionData: AccountingTransactionDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const charges: any = accountingTransactionData.charges.map<CreateChargeCommand>(
      (item) => {
        const createChargeCommand: CreateChargeCommand = {
          values: {
            amount: item.amount,
            currencyId: item.currencyId,
            accountingItemId: item.accountingItemId,
            applyBy: item.applyBy,
            applyToContactId: item.applyToContactId,
            chargeType: item.chargeType,
            chargeStatus: item.chargeStatus,
            description: item.description,
            freightServiceClassId: item.freightServiceClassId,
            grossVolume: item.grossVolume,
            grossWeight: item.grossWeight,
            isConsolidated: item.isConsolidated,
            note: item.note,
            paidAs: item.paidAs,
            pieces: item.pieces,
            price: item.price,
            quantity: item.quantity,
            showInDocuments: item.showInDocuments,
            unit: item.unit,
            salesTaxRate: item.salesTaxRate ?? 0,
            salesTaxAmount: item.salesTaxAmount,
            salesTaxId: item.salesTaxId,
            totalAmount: item.totalAmount,
            allowAutomaticUpdate: item.allowAutomaticUpdate,
          },
        };
        return createChargeCommand;
      },
    );
    const createAccountingTransactionCommand: CreateAccountingTransactionCommandValues = {
      divisionId: accountingTransactionData.divisionId,
      accountId: accountingTransactionData.accountId,
      accountingTransactionStatus:
        accountingTransactionData.accountingTransactionStatus,
      accountingTransactionType:
        accountingTransactionData.accountingTransactionType,
      applyToContactID: accountingTransactionData.applyToContactID,
      billToContactAddressId: accountingTransactionData.billToContactAddressId,
      dueDate: accountingTransactionData.dueDate,
      note: accountingTransactionData.note,
      paidAs: accountingTransactionData.paidAs,
      paymentTermsId: accountingTransactionData.paymentTermsId,
      transactionDate: accountingTransactionData.transactionDate,
      transactionNumber: accountingTransactionData.transactionNumber,
      charges,
      ...accountingTransactionData,
    };

    return createAccountingTransactionRequest(
      currentOrganization!,
      createAccountingTransactionCommand,
    );
  },
);

addEffectStatusHandling(createAccountingTransactionFx, {
  completeMessage: 'Accounting transaction was created successfully',
  errorMessage: 'Accounting transaction was not created',
});

export const getAccountingTransactionFx = createEffect(
  (accountingTransactionParams: GetAccountingTransactionParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingTransactionRequest(
      currentOrganization as LinkResourceBaseDto,
      accountingTransactionParams,
    );
  },
);

addEffectStatusHandling(getAccountingTransactionFx);

export const updateAccountingTransactionFx = createEffect(
  (accountingTransaction: AccountingTransactionDto) => {
    const charges: any = accountingTransaction.charges.map<UpdateChargeCommand>(
      (item) => {
        const updateChargeCommand: UpdateChargeCommand = {
          chargeId: item.chargeId,
          values: {
            amount: item.amount,
            currencyId: item.currencyId,
            accountingItemId: item.accountingItemId,
            applyBy: item.applyBy,
            applyToContactId: item.applyToContactId,
            chargeType: item.chargeType,
            chargeStatus: item.chargeStatus,
            description: item.description,
            freightServiceClassId: item.freightServiceClassId,
            grossVolume: item.grossVolume,
            grossWeight: item.grossWeight,
            isConsolidated: item.isConsolidated,
            isDeleted: item.isDeleted,
            note: item.note,
            paidAs: item.paidAs,
            pieces: item.pieces,
            price: item.price,
            quantity: item.quantity,
            showInDocuments: item.showInDocuments,
            unit: item.unit,
            salesTaxRate: item.salesTaxRate ?? 0,
            salesTaxAmount: item.salesTaxAmount,
            totalAmount: item.totalAmount,
            salesTaxId: item.salesTaxId,
            allowAutomaticUpdate: item.allowAutomaticUpdate,
          },
        };
        return updateChargeCommand;
      },
    );

    const updateAccountingTransactionCommand: UpdateAccountingTransactionCommandValues = {
      ...accountingTransaction,
      divisionId: accountingTransaction.divisionId,
      accountId: accountingTransaction.accountId,
      accountingTransactionStatus:
        accountingTransaction.accountingTransactionStatus,
      accountingTransactionType:
        accountingTransaction.accountingTransactionType,
      applyToContactID: accountingTransaction.applyToContactID,
      billToContactAddressId: accountingTransaction.billToContactAddressId,
      dueDate: accountingTransaction.dueDate,
      note: accountingTransaction.note,
      paidDate: accountingTransaction.paidDate,
      paidAs: accountingTransaction.paidAs,
      paymentTermsId: accountingTransaction.paymentTermsId,
      transactionDate: accountingTransaction.transactionDate,
      transactionNumber: accountingTransaction.transactionNumber,
    };
    return updateAccountingTransactionRequest(
      accountingTransaction,
      updateAccountingTransactionCommand,
    );
  },
);

addEffectStatusHandling(updateAccountingTransactionFx, {
  completeMessage: 'Accounting transaction was updated successfully',
  errorMessage: 'Accounting transaction was not updated',
});

export const deleteAccountingTransactionFx = createEffect(
  (accountingTransaction: AccountingTransactionDto) => {
    return deleteAccountingTransactionRequest(accountingTransaction);
  },
);

addEffectStatusHandling(deleteAccountingTransactionFx, {
  completeMessage: 'Accounting transaction was deleted successfully',
  errorMessage: 'Accounting transaction was not deleted',
});

export const exportAccountingTransactionsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportAccountingTransactionsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportAccountingTransactionsFx, {
  completeMessage: 'Accounting transactions were exported successfully',
  errorMessage: 'Accounting transactions were not exported',
});

export const importAccountingTransactionsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importAccountingTransactionsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importAccountingTransactionsFx, {
  completeMessage: 'Accounting transactions were imported successfully',
  errorMessage: 'Accounting transactions were not imported',
  inFlightMessage: 'Importing accounting transactions...',
});

const defaultState: AccountingTransactionsStoreState = {
  links: [],
  accountingTransactionColumns: [],
  defaultAccountingTransactionColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const accountingTransactionStore: Store<AccountingTransactionsStoreState> = createStore(
  defaultState,
)
  .on(getAccountingTransactionsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      accountingTransactionColumns: payload.result.items,
      defaultAccountingTransactionColumns: payload.result.items,
    };
  })
  .on(getAccountingTransactionsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultAccountingTransactionColumns: payload.result.items,
    };
  })
  .on(updateAccountingTransactionsColumns, (state, payload) => {
    return { ...state, accountingTransactionColumns: payload };
  });
