import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { authStore } from '../../auth/auth.store';
import { CommoditiesTable } from '../../warehouseReceipts/components/common/commoditiesTable';
import { AddTrackingNumberParams } from '../../orders/orders.service';
import { ShippingPallet } from '../../warehouseReceipts/components/common/shippingPallet';
import { ServicesTable } from '../../warehouseReceipts/components/common/servicesTable';
import {
  AttachmentParentType,
  ChargeStatuses,
  CommodityDto,
  CommodityTrackingNumberDto,
  ContactType,
  ScanningResultFrom,
} from '../../../models/data.models';
import { getDocumentTemplatesFx } from '../../documentTemplates/documentTemplates.store';
import { PalletFormikComponent } from '../../warehouseReceipts/components/common/pallet.formik.component';
import { getContacts } from '../../contacts/contacts.store';
import { TrackingNumbersFormik } from '../../warehouseReceipts/components/common/trackingNumbers.formik.component';
import { CustomerInformation } from '../../customers/components/common/customerInformation.component';
import { ParcelShipmentInformationFormik } from '../components/common/parcelShipmentInformation.formik.component';
import { getCommodityTrackingNumbers } from '../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import { DimensionsUpdate } from '../components/common/dimensionsUpdate.component';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { HiX } from 'react-icons/hi';
import { packingCommodityTableStore } from '../../commodities/commodities.store';
import { useStore } from 'effector-react';
import {
  ACTION_PACKING_SCAN_TRACKING_NUMBER,
  MAX_FETCH_LIMIT,
  PACKING_CURRENT_LOCATION_TYPE,
  PACKING_LOCATION_TYPE,
} from '../../constants/api';
import { useFormikContext } from 'formik';
import { setSubmitAction } from './packingWizard.component';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import { destructurizeGQLCustomValues } from '../../../utils/helper.utils';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { Input } from '../../common/components/input/input.component';
import { RoundCheckbox } from '../../common/components/checkbox/roundCheckbox';
import { getOrganizationConfigsFx } from '../../organizationConfigs/organizationConfigs.store';
import { Spinner } from '../../common/components/spinner/spinner';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { executeMutation } from '../../graphql/graphql.service';

const cardStyle = { backgroundColor: 'white', borderRadius: '12px' };

export type PackingInfoFragmentProps = {
  handleBack: () => void;
};

export const getInitialState = () => {
  const initialState: AddTrackingNumberParams = {
    trackingNumber: null,
  };

  return initialState;
};

const documentTemplateFilter = `isDefault: true AND isInActive: false AND documentTemplateType:ShippingLabelDocument`;

const getTemplatesData = async (formikContext) => {
  const documentTemplatesData = await getDocumentTemplatesFx({
    offset: 0,
    limit: 1,
    sort: '',
    filter: documentTemplateFilter,
    search: '',
  });
  if (
    documentTemplatesData?.items &&
    documentTemplatesData?.items?.length > 0
  ) {
    const firstDocumentTemplate = documentTemplatesData.items[0];
    const firstDocumentTemplateId = firstDocumentTemplate.documentTemplateId;
    formikContext.setFieldValue('documentTemplateId', firstDocumentTemplateId);
  }
};

const fetchCommodityTrackingNumbers = async (formikContext, commodities) => {
  const commodityTrackingNumbers = await getCommodityTrackingNumbers({
    limit: MAX_FETCH_LIMIT,
  });
  const trackingNumbers = formikContext.values?.trackingNumbers;
  let foundedTrackingNumbers: CommodityTrackingNumberDto[] = [];
  const existedCommoditiesIds = commodities?.map((x) => x.commodityId);
  commodityTrackingNumbers?.items?.forEach((commodityTrackingNumber) => {
    const isDuplicate = [...trackingNumbers, ...foundedTrackingNumbers].some(
      (item) => item.trackingNumber === commodityTrackingNumber.trackingNumber,
    );
    if (
      !existedCommoditiesIds.includes(commodityTrackingNumber.commodityId) ||
      isDuplicate ||
      commodityTrackingNumber.trackingNumber ===
        formikContext.values?.parcelShipment.trackingNumber
    ) {
      return;
    } else {
      foundedTrackingNumbers.push(commodityTrackingNumber);
    }
  });

  formikContext.setFieldValue('trackingNumbers', [
    ...trackingNumbers,
    ...foundedTrackingNumbers,
  ]);
};

export const PackingInfoFragment = ({
  handleBack,
}: PackingInfoFragmentProps) => {
  const formikContext = useFormikContext<any>();

  const services = formikContext.values?.parcelShipment?.charges?.filter(
    (x) =>
      x.description != 'Shipping' &&
      x.accountingItem.itemCode != 'INS' &&
      x.description != 'Insurance' &&
      x.accountingItem.itemCode != 'DISCOUNT' &&
      x.description != 'Discount' &&
      x.accountingItem?.customValues &&
      'display_as_service' in x.accountingItem.customValues &&
      x.accountingItem.customValues['display_as_service'] == 'true',
  );

  const [isCompleteAllServices, setIsCompleteAllServices] = useState(
    services.every((s) => s.chargeStatus != ChargeStatuses.Pending),
  );

  const [isVerifiedAll, setIsVerifiedAll] = useState(true);

  const [isLoadingCustomerNotes, setIsLoadingCustomerNotes] = useState(false);

  const { user: currentUser } = authStore.getState();
  const { commodityColumns: columns } = useStore(packingCommodityTableStore);

  useEffect(() => {
    const parcelShipment = { ...formikContext.values?.parcelShipment };
    if (parcelShipment) {
      formikContext.setFieldValue(
        'parcelShipment.customValues',
        destructurizeGQLCustomValues(parcelShipment.customValues),
      );

      if (parcelShipment.orderCommodities) {
        const commodities: CommodityDto[] = parcelShipment.orderCommodities.map(
          (x) => x.commodity,
        );
        if (commodities) {
          commodities.forEach((x) => {
            if (x.customValues) {
              x.customValues = destructurizeGQLCustomValues(x.customValues);
            }
            if (x.containerCommodities) {
              x.containerCommodities.forEach((y) => {
                if (y.customValues) {
                  y.customValues = destructurizeGQLCustomValues(y.customValues);
                }
              });
            }
          });
          formikContext.setFieldValue(
            'parcelShipment.commodities',
            commodities,
          );
        }

        fetchCommodityTrackingNumbers(formikContext, commodities);
      }

      if (parcelShipment.orderEntities) {
        parcelShipment.orderEntities.forEach((x) => {
          if (x.customValues) {
            x.customValues = destructurizeGQLCustomValues(x.customValues);
          }
          if (x.contactAddress?.customValues) {
            x.contactAddress.customValues = destructurizeGQLCustomValues(
              x.contactAddress.customValues,
            );
          }
        });
        formikContext.setFieldValue(
          'parcelShipment.orderEntities',
          parcelShipment.orderEntities,
        );
      }
    }
    getTemplatesData(formikContext);
  }, []);

  useEffect(() => {
    if (handleBack)
      setActionButtons([
        <Button
          key={'package-packing-info-fragment-cancel-btn'}
          color="secondary"
          className={'d-flex align-items-center justify-content-center'}
          outline={true}
          onClick={handleBack}
          style={{ minWidth: '8.5rem' }}
        >
          <HiX size={16} className={'mr-2'} />
          Cancel
        </Button>,
      ]);
  }, []);

  useEffect(() => () => clearActionButtons(), []);

  useEffect(() => {
    if (!formikContext.values?.defaultDispatcher) {
      getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
        (result) => {
          const defaultDispatcher = result.items.find(
            (employee) => employee.userEmployee?.userId === currentUser?.userId,
          );
          formikContext.setFieldValue('defaultDispatcher', defaultDispatcher);
        },
        () => {},
      );
    }
  }, []);

  useScanner((scannerResult: ScanningResult) => {
    catchScanResult(scannerResult);
  });

  const catchScanResult = (scanningResult: ScanningResult) => {
    if (scanningResult.from === ScanningResultFrom.Scanner) {
      if (scanningResult.data) {
        formikContext.setFieldValue('trackingNumber', scanningResult.data);
        setSubmitAction(formikContext, ACTION_PACKING_SCAN_TRACKING_NUMBER);
      }
    }
  };

  return (
    <div className="px-3 pb-3">
      <h3 className="pb-3">Purchase Information</h3>
      <div className="row d-flex justify-content-between mb-5">
        <div className="col-12 mb-5">
          <div className="box" style={cardStyle}>
            <TrackingNumbersFormik allowEdit={true} allowDelete={false} />
          </div>
        </div>
        <div className="col-6">
          <div className="box mr-1 h-100">
            <CustomerInformation
              contactId={formikContext.values?.purchase?.billToContactId}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="box ml-1 h-100">
            <ParcelShipmentInformationFormik
              setParcelShipment={(order) =>
                formikContext.setFieldValue('parcelShipment', order)
              }
            />
          </div>
        </div>
      </div>

      {formikContext.values?.parcelShipment?.commodities && (
        <div>
          <CommoditiesTable
            commodities={formikContext.values?.parcelShipment?.commodities}
            setCommodities={(commodities) =>
              formikContext.setFieldValue(
                'parcelShipment.commodities',
                commodities,
              )
            }
            setPurchaseCommodity={(commodity) =>
              formikContext.setFieldValue('purchase.commodities[0]', commodity)
            }
            columns={columns}
            setParcelShipment={(order) =>
              formikContext.setFieldValue('parcelShipment', order)
            }
            setIsVerifiedAll={setIsVerifiedAll}
            shouldRenderShipments={false}
          />
        </div>
      )}

      {formikContext.values?.parcelShipment?.customValues?.customer_notes && (
        <div className="box my-6 py-3">
          <div className="row">
            <div className={'col-lg-12 col-sm-12 d-flex'}>
              <div className="col-2">
                <h3 className={'text-nowrap h-100 d-flex mt-3'}>
                  Customer Notes
                </h3>
              </div>
              <div className="col-9">
                <div className={'h-100 d-flex align-items-center'}>
                  <Input
                    type={'textarea'}
                    name={'parcelShipment.customValues.customer_notes'}
                    className={'w-100'}
                    readonly={'readonly'}
                    rows={4}
                    style={{ resize: 'vertical' }}
                  />
                </div>
              </div>
              <div className="col-1">
                <div className={'cursor-pointer p-2 user-select-none'}>
                  {isLoadingCustomerNotes ? (
                    <Spinner size="sm" className="px-5 py-2" />
                  ) : (
                    <RoundCheckbox
                      chkBoxClassName={`cursor-pointer checkbox-content-verify ${
                        formikContext.values?.parcelShipment?.customValues
                          ?.customer_notes_verified === 'true'
                          ? 'checkbox-content-verify-checked'
                          : 'checkbox-content-verify'
                      }`}
                      isLoading={isLoadingCustomerNotes}
                      size={15}
                      onClick={async () => {
                        setIsLoadingCustomerNotes(true);
                        const customerNotesVerifiedValue =
                          formikContext.values?.parcelShipment?.customValues
                            ?.customer_notes_verified === 'true'
                            ? 'false'
                            : 'true';
                        const workflowExecutionVariables = {
                          orderId:
                            formikContext.values?.parcelShipment?.orderId,
                          customValues: {
                            customer_notes_verified: customerNotesVerifiedValue,
                          },
                        };
                        const organizationConfigs = await getOrganizationConfigsFx(
                          {
                            filter: `configName:tms.modules.packing`,
                            limit: 1,
                          },
                        );
                        if (
                          organizationConfigs &&
                          organizationConfigs.items &&
                          organizationConfigs.items.length > 0
                        ) {
                          const workflowId = (organizationConfigs.items[0]
                            .customValues as {
                            orderCustomValuesUpdateWorkflowId: string;
                          }).orderCustomValuesUpdateWorkflowId;

                          try {
                            await executeMutation({
                              mutation: `mutation ExecuteWorkflowMutation($input: ExecuteWorkflowInput!) {
                                            executeWorkflow(input: $input) {
                                               workflowExecutionResult {
                                                  outputs
                                              }
                                            }
                                          }`,
                              variables: {
                                input: {
                                  organizationId:
                                    formikContext.values?.parcelShipment
                                      ?.organizationId,
                                  workflowId,
                                  variables: workflowExecutionVariables,
                                },
                              },
                            });
                          } catch (e) {
                            addMessage({
                              message: `Error while executing workflow.`,
                              type: 'danger',
                              id: v4(),
                            });
                          } finally {
                            setIsLoadingCustomerNotes(false);
                          }
                        }
                        formikContext.setFieldValue(
                          'parcelShipment.customValues.customer_notes_verified',
                          customerNotesVerifiedValue,
                        );
                        setIsLoadingCustomerNotes(false);
                      }}
                      spinnerClassName="mx-0"
                      label="Verified"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {services.length > 0 && (
        <ServicesTable
          services={services}
          isCompleteAllServices={isCompleteAllServices}
          setIsCompleteAllServices={setIsCompleteAllServices}
        />
      )}

      {formikContext.values?.parcelShipment?.commodities && (
        <div className="box my-6 py-3">
          <DimensionsUpdate />
        </div>
      )}

      <div className={'row mb-6'}>
        <div className={'col-6'}>
          <div className={'box h-100 mr-1'}>
            <PalletFormikComponent
              buttonColor={'action-secondary'}
              locationType={PACKING_LOCATION_TYPE}
              currentLocation={PACKING_CURRENT_LOCATION_TYPE}
              label="Service Pallet #"
              buttonName={'Move To Service'}
            />
          </div>
        </div>
        <div className={'col-6'}>
          <div className={'box h-100 mr-1'}>
            <ShippingPallet
              isCompleteAllServices={isCompleteAllServices}
              isVerifiedAll={isVerifiedAll}
              isCustomerNotesVerified={
                formikContext.values?.parcelShipment?.customValues
                  ?.customer_notes_verified === 'true'
              }
            />
          </div>
        </div>
      </div>

      {formikContext.values?.parcelShipment?.orderId && (
        <AttachmentsFilesList
          parentId={formikContext.values?.parcelShipment?.orderId}
          parentType={AttachmentParentType.Order}
          isReceivingOrPacking={true}
          prefix={formikContext.values?.parcelShipment?.trackingNumber}
        />
      )}
    </div>
  );
};
