import React from 'react';
import Select from 'react-select';
import { ErrorMessage, Field, FormikProps, FormikValues } from 'formik';
import { clearMessages } from '../../common/messages.store';
import { FormContext } from '../../common/components/form/form.component';
import {
  getEnumKeyByValue,
  getEnumValueByValue,
  getEnumValues,
} from '../../../utils/helper.utils';

type enumValueProps = {
  label: string;
  value: string;
};

export type FormikEnumSelectProps = {
  id?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  enumType: any;
  error?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  onChange?: (data: any, context?: any) => void;
  readonly?: 'readonly' | '';
  isSearchable?: boolean;
  classNamePrefix?: string;
  validate?: (data: any) => Promise<any>;
  header?: string;
  getOptionLabel?: (option: any) => any;
  getOptionValue?: (option: any) => any;
  onClick?: (event) => any;
  tabIndex?: number;
  customStyles?: any;
  defaultValue?: any;
};

export const FormikEnumSelect = ({
  id,
  name,
  enumType,
  disabled,
  required,
  isClearable = false,
  onChange,
  placeholder,
  readonly = '',
  isSearchable = false,
  classNamePrefix = 'select',
  header,
  getOptionLabel = (option) => option?.label,
  getOptionValue = (option) => option?.value,
  onClick = (event) => {},
  tabIndex,
  customStyles,
  defaultValue,
}: FormikEnumSelectProps) => {
  const onChangeInput = (
    enumValue: enumValueProps,
    contextDto: FormikProps<FormikValues>,
  ) => {
    if (typeof onChange === 'function') {
      onChange(enumValue, contextDto);
    }
    contextDto.setFieldValue(name, enumValue.value);
  };

  return (
    <FormContext.Consumer>
      {(context) => (
        <div className={`form-group`}>
          {!!header && (
            <label className="input-label" htmlFor={name}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
              </span>
            </label>
          )}
          <Field required={required} type="hidden" name={name} />
          <Select
            id={id}
            name={name}
            defaultValue={defaultValue}
            styles={customStyles}
            isDisabled={disabled}
            isClearable={readonly === 'readonly' ? false : isClearable}
            required={required}
            placeholder={placeholder}
            options={getEnumValues(enumType)}
            isMulti={false}
            classNamePrefix={`${id} ${classNamePrefix}`}
            isSearchable={readonly === 'readonly' ? false : isSearchable}
            menuIsOpen={readonly === 'readonly' ? false : undefined}
            value={{
              label: getEnumValueByValue(
                context.getFieldMeta(name)?.value,
                enumType,
              ),
              value: context.getFieldMeta(name)?.value,
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(value: enumValueProps) => onChangeInput(value, context)}
            onFocus={clearMessages}
            onBlur={() => {
              context.setFieldTouched(name, true);
            }}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onClick={onClick}
            tabIndex={tabIndex}
          />
          <ErrorMessage
            name={name}
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
    </FormContext.Consumer>
  );
};
