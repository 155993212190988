import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';

export const ColComponent = (props: ComponentProps) => {
  const className = props.props?.className || 'col-12';

  return (
    <div {...props.props} className={className}>
      {props.children?.map((child: ComponentProps, i) => (
        <ComponentRender key={i} {...child} context={props.context} />
      ))}
    </div>
  );
};
