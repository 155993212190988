import React, { useEffect, useRef, useState } from 'react';
import {
  AttachmentDto,
  AttachmentDtoPagedResult,
  AttachmentParentType,
  AttachmentType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  attachmentStore,
  getAttachments,
  deleteAttachment,
  attachmentsFiltersStore,
} from '../attachments.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { AttachmentsGrid } from './attachments-grid.component';
import { AttachmentDialog } from './attachment.dialog';
import { getSearchQuery, parseSearchQuery } from '../../../utils/query.utils';
import { FiSearch } from 'react-icons/fi';
import { Dropdown } from 'react-bootstrap';
import {
  FaAngleDown,
  FaEllipsisH,
  FaListUl,
  FaRegArrowAltCircleUp,
  FaTh,
} from 'react-icons/fa';
import { OrderDocumentsFiltersSelector } from '../../orderDocuments/components/orderDocumentsFiltersSelector';
import { UploadAttachmentsDialog } from './upload-attachments.dialog';
import { OrderDocumentsGrid } from '../../common/components/grid/orderDocuments-grid.component';
import { OrderDocumentsFilters } from '../../orderDocuments/components/orderDocumentsFilters';
import addPhotoIcon from '../../../img/photo-add-icon.svg';
import { TakePhotoDialog } from '../../common/takePhoto/takePhoto.dialog';
import { AddAttachmentsDialog } from './add-attachments.dialog';
import { getAttachmentsLinks } from '../../../utils/helper.utils';

export type AttachmentsFilesListProps = {
  parentId: number;
  parentType: AttachmentParentType;
  isReceivingOrPacking?: boolean;
  childIds?: number[];
  setOrderAttachments?: (attachments: AttachmentDto[]) => void;
  prefix?: string;
  attachmentType?: AttachmentType;
  shouldNegateAttachmentTypeFilter?: boolean;
};

export const AttachmentsFilesList = ({
  parentId,
  parentType,
  isReceivingOrPacking = false,
  childIds,
  setOrderAttachments,
  prefix,
  attachmentType,
  shouldNegateAttachmentTypeFilter,
}: AttachmentsFilesListProps) => {
  const [offset, setOffset] = useState(0);
  const limit = isReceivingOrPacking ? 4 : 16;
  const [search, setSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const [isCardsActive, setIsCardActive] = useState(true);
  const [isTableActive, setIsTableActive] = useState(false);
  const [checkedFilters, setCheckedFilters] = useState([]);
  const { filters: filters } = useStore(attachmentsFiltersStore);
  const [
    attachments,
    setAttachments,
  ] = useState<AttachmentDtoPagedResult | null>(null);
  const [selectedAttachments, setSelectedAttachments] = useState<
    AttachmentDto[]
  >([]);
  const [isDeleteActive, setIsDeleteActive] = useState(false);

  const onPageChanged = (page) => {
    setOffset(limit * page);
  };
  const onSort = (field: string) => {
    setSort(field);
  };
  const onFilter = (query: string) => {
    setFilter(query);
  };

  useEffect(() => {
    if (parentId) {
      getAttachmentsData();
    }
  }, []);

  const getAttachmentsData = () => {
    const filterObject = parseSearchQuery(filter);
    filterObject['parentId'] = parentId;
    filterObject['parentType'] = parentType;
    if (attachmentType) {
      if (shouldNegateAttachmentTypeFilter) {
        filterObject['attachmentType'] = `NOT ${attachmentType}`;
      } else {
        filterObject['attachmentType'] = attachmentType;
      }
    }
    const newFilter = getSearchQuery(filterObject);
    getAttachments({
      offset,
      limit,
      sort,
      filter: newFilter,
      search,
    }).then((attachmentsData) => {
      getAttachmentsLinks(attachmentsData.items);
      setAttachments(attachmentsData);
    });
  };

  useEffect(() => {
    if (attachments?.items?.length > 0 && setOrderAttachments) {
      setOrderAttachments(attachments?.items);
    }
  }, [attachments]);

  const updateAttachmentsState = (newAttachments: AttachmentDto[]) => {
    getAttachmentsLinks(newAttachments);
    setAttachments((prevAttachments) => {
      const newTotalCount =
        (prevAttachments?.totalCount || 0) + newAttachments?.length;

      return {
        ...prevAttachments,
        totalCount: newTotalCount,
        items: prevAttachments
          ? [...prevAttachments?.items, ...newAttachments]
          : newAttachments,
      };
    });
  };

  const { attachmentColumns: columns } = useStore(attachmentStore);

  const onDeleteAttachment = (attachment: AttachmentDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete attachment`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteAttachment(attachment).then(() => {
          setAttachments({
            ...attachments,
            totalCount: attachments?.totalCount - 1,
            items: attachments.items.filter(
              (item) => item.attachmentId !== attachment.attachmentId,
            ),
          });
        });
      }
    });
  };

  const onEditAttachment = (attachment: AttachmentDto) => {
    if (attachment?.attachmentId) {
      showDialog({
        dialog: AttachmentDialog,
        props: {
          title: 'Update Order',
          attachmentId: attachment?.attachmentId,
          className: 'modal-edit-attachment',
        },
      }).then((result) => {
        const updatedItems = attachments?.items?.map((item) => {
          if (item?.attachmentId === result?.attachmentId) {
            return result;
          }
          return item;
        });
        setAttachments({
          ...attachments,
          items: updatedItems,
        });
      });
    }
  };

  const addAttachments = (files) => {
    if (files) {
      showDialog({
        dialog: UploadAttachmentsDialog,
        props: {
          title: 'Upload Attachments',
          className: 'modal-add-attachments',
          parentId: parentId,
          parentType: parentType,
          childIds: childIds,
          prefix: prefix,
        },
      }).then((result) => {
        if (result != null) {
          const resolvedAttachments: AttachmentDto[] = result as AttachmentDto[];
          updateAttachmentsState(resolvedAttachments);
        }
      });
    }
  };

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
  };

  useEffect(() => {
    if (selectedAttachments.length > 0) {
      setIsDeleteActive(true);
    } else {
      setIsDeleteActive(false);
    }
  }, [selectedAttachments]);

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) => (
      <span
        className="pointer"
        onClick={(e) => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        <FaEllipsisH size="15" color="#005C98" />
        &nbsp;
        <span className="align-bottom px-2 tab-items-text">Actions</span>
        &nbsp;
        <FaAngleDown size="15" color="#005C98" />
      </span>
    ),
  );

  const onFilterAttachments = (query: string) => {
    const filterObject = parseSearchQuery(filter);
    filterObject['parentId'] = parentId;
    filterObject['parentType'] = parentType;
    const newFilter = getSearchQuery(filterObject);
    const filters = newFilter + ' AND ' + query;
    getAttachments({
      offset,
      limit,
      sort,
      filter: filters,
      search,
    }).then((attachmentsData) => {
      setAttachments(attachmentsData);
    });
  };

  const actionDropdownRef = useRef<any>(null);

  const deleteSelectedAttachments = async () => {
    if (selectedAttachments?.length > 0) {
      actionDropdownRef.current.click();
      const deleteAttachmentsPromises = selectedAttachments.map((attachment) =>
        deleteAttachment(attachment),
      );

      Promise.all(deleteAttachmentsPromises)
        .then(() => {
          setAttachments({
            ...attachments,
            totalCount: attachments?.totalCount - selectedAttachments.length,
            items: attachments.items.filter(
              (item) =>
                !selectedAttachments
                  .map((item) => item.attachmentId)
                  .includes(item.attachmentId),
            ),
          });
          setSelectedAttachments([]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const takePhoto = async () => {
    const imageUrl = await showDialog({
      dialog: TakePhotoDialog,
      props: { title: 'Take a Photo', className: 'delete-modal' },
    });
    if (imageUrl) {
      const image = new Image();
      image.src = imageUrl;
      fetch(image.src)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'photo.png', {
            type: 'image/png',
          });
          saveAttachments([file]);
        });
    }
  };

  const saveAttachments = (files) => {
    if (files) {
      showDialog({
        dialog: AddAttachmentsDialog,
        props: {
          files,
          parentId: parentId,
          parentType: parentType,
          title: 'Add Attachments',
          childIds: childIds,
          attachmentType: AttachmentType.Picture,
          prefix: prefix,
        },
      }).then((result) => {
        if (result != null) {
          const resolvedAttachments: AttachmentDto[] = result as AttachmentDto[];
          updateAttachmentsState(resolvedAttachments);
        }
      });
    }
  };

  const openAttachment = (attachment: AttachmentDto) => {
    window.open(attachment?.fileUri, '_blank');
  };

  return (
    <div className={isReceivingOrPacking ? 'mb-10' : ''}>
      <div
        className={
          isReceivingOrPacking
            ? 'd-flex justify-content-between pb-4 px-2'
            : 'd-flex justify-content-between mb-4'
        }
      >
        {isReceivingOrPacking ? (
          <div>
            <h3>Pictures</h3>
          </div>
        ) : (
          <div className="d-flex" style={{ marginLeft: '-16px' }}>
            <div
              className={
                'd-flex align-items-center justify-content-start pl-0 pr-0'
              }
            >
              <FiSearch
                className={'position-relative'}
                style={{ left: '30px' }}
              />
              <input
                type="search"
                style={{ minHeight: '40px', minWidth: '500px' }}
                className={
                  'form-control flex-grow-1 search-with-icon w-auto shadow-none'
                }
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                placeholder={'Search'}
              />
            </div>
            <div className="col-2">
              <OrderDocumentsFiltersSelector
                filters={filters}
                checkedFilters={checkedFilters}
                setCheckedFilters={setCheckedFilters}
              />
            </div>
          </div>
        )}
        <div className="col-5 d-flex align-items-center justify-content-end">
          <div
            className="d-flex align-items-center actions-dropdown"
            ref={actionDropdownRef}
          >
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                <div className="px-5">
                  <div className="filters-form-block">
                    <a
                      className={
                        isDeleteActive
                          ? 'dropdown-item cursor-pointer'
                          : 'dropdown-item disabled cursor-pointer'
                      }
                      onClick={deleteSelectedAttachments}
                    >
                      Delete selected attachments
                    </a>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className="px-5 d-flex align-items-center text-end pointer"
            onClick={addAttachments}
          >
            <FaRegArrowAltCircleUp color="#005C98" />
            <span className="align-bottom pl-2 tab-items-text">Upload</span>
          </div>
          {isReceivingOrPacking && (
            <div
              className="pr-5 d-flex align-items-center text-end pointer"
              onClick={takePhoto}
            >
              <img src={addPhotoIcon} />
              <span className="align-bottom pl-2 tab-items-text">
                Take photo
              </span>
            </div>
          )}
          <div className="pr-5">
            <FaTh
              className="pointer"
              color={isCardsActive ? '#005C98' : '#98A2B3'}
              size="20"
              onClick={() => {
                setIsCardActive(true);
                setIsTableActive(false);
              }}
            />
          </div>
          <div>
            <FaListUl
              className="pointer"
              color={isTableActive ? '#005C98' : '#98A2B3'}
              size="20"
              onClick={() => {
                setIsCardActive(false);
                setIsTableActive(true);
              }}
            />
          </div>
        </div>
      </div>
      {checkedFilters?.length > 0 && (
        <div className="order-document-tabs mb-4 py-3 pl-4">
          <OrderDocumentsFilters
            filters={checkedFilters}
            onFilter={onFilterAttachments}
          ></OrderDocumentsFilters>
        </div>
      )}
      {attachments?.items?.length > 0 ? (
        <div>
          {isCardsActive && (
            <AttachmentsGrid
              data={attachments?.items}
              columns={columns}
              offset={offset}
              limit={limit}
              total={attachments?.totalCount}
              sort={sort}
              onDelete={onDeleteAttachment}
              onSort={(field) => {
                onSort(field);
              }}
              search={search}
              onEdit={onEditAttachment}
              onPageChanged={onPageChanged}
              filter={filter}
              onFilter={onFilter}
              setSelected={setSelectedAttachments}
              selectedAttachments={selectedAttachments}
              isReceivingOrPacking={true}
            />
          )}
          {isTableActive && (
            <OrderDocumentsGrid
              search={search}
              showEmptyTable={false}
              showAllStore={true}
              rowKeys={['orderDocumentId']}
              data={attachments?.items}
              columns={columns}
              offset={offset}
              limit={limit}
              total={attachments?.totalCount}
              sort={sort}
              onSort={setSort}
              onEdit={onEditAttachment}
              onPageChanged={onPageChanged}
              onColumnsChanged={null}
              onSelect={null}
              hideColumnsSelect={true}
              isDropDownList={true}
              showToolbar={false}
              onDelete={onDeleteAttachment}
              showPagination={true}
              onOpen={openAttachment}
            />
          )}
        </div>
      ) : (
        <div className="no-found-card order-document-tabs mb-4">
          <h3>No attachments found</h3>
          <img className="search-icon"></img>
          <h4 onClick={addAttachments}>
            You can add an attachment by{' '}
            <span className="no-found-card-span cursor-pointer">
              Upload Attachment
            </span>
          </h4>
        </div>
      )}
    </div>
  );
};
