import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';

export const VesselForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

VesselForm.Carrier = ({
  selectedFilter = ``,
  placeholder = 'Select Carrier',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'carrierId',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};
VesselForm.CountryCode = ({
  selectedFilter = ``,
  placeholder = 'Select Country',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'countryCode',
  onChange = () => {},
  readonly,
  nameId = 'countryFlag',
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
      isDisplayCode={true}
    />
  );
};
VesselForm.Name = ({
  id = 'vesselName',
  className,
  style,
  size,
  onChange = () => {},
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
VesselForm.VesselCode = ({
  id = 'vesselCode',
  className,
  style,
  size,
  onChange = () => {},
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={'vesselCode'}
      label={'Vessel Code'}
      placeholder={'Vessel Code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
