import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { DiscountType } from '../../../models/data.models';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';
import { ContactSelectInputProps } from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectModeOfTransportationPaginate } from '../../modeOfTransportations/components/modeOfTransportation-react-select.component';

export const DiscountForm = ({
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

DiscountForm.ActivationCount = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'activationCount'}
      label={'Activation Count'}
      placeholder={'Activation Count'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.ActivationLimit = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'activationLimit'}
      label={'Activation Limit'}
      placeholder={'Activation Limit'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.EndDate = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={'endDate'}
      label={'End Date'}
      placeholder={'End Date'}
      required={true}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
DiscountForm.IsActive = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isActive'}
      label={'Is Active'}
      placeholder={'Is Active'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
DiscountForm.MinimumChargeAmount = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'minimumChargeAmount'}
      label={'Minimum Charge Amount'}
      placeholder={'Minimum Charge Amount'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.Name = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.PromoCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'promoCode'}
      label={'Promo Code'}
      placeholder={'Promo Code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.StartDate = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={'startDate'}
      label={'Start Date'}
      placeholder={'Start Date'}
      required={true}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
DiscountForm.TargetContactTags = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'targetContactTags'}
      label={'Target Contact Tags'}
      placeholder={'Target Contact Tags'}
      required={false}
      type={'tags'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.Type = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'type'}
      label={'Discount Type'}
      placeholder={''}
      required={true}
      type={'enum-select'}
      className={className}
      enumName={DiscountType}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.UsageLimit = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'usageLimit'}
      label={'Usage Limit'}
      placeholder={'Usage Limit'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.UsedCount = ({
  className,
  style,
  size,
  disabled = true,
}: InputPublicProps) => {
  return (
    <Input
      name={'usedCount'}
      label={'Used Count'}
      placeholder={'Used Count'}
      required={true}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.Value = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'value'}
      label={'Discount'}
      placeholder={'Value'}
      required={true}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DiscountForm.CarrierContactSelect = ({
  selectedFilter = '',
  placeholder = 'Select Carriers',
  sort = 'name',
  searchKey = 'name',
  defaultValue,
  id = 'carrierContactId',
  nameId = 'carrierContactName',
  header = 'Carriers',
  required = false,
  onChange = () => {},
  readonly,
  contactTypes,
  multiple = true,
  closeMenuOnSelect = false,
  showContactType = true,
  disabled,
  canUpdateEntity = true,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      showContactType={showContactType}
      useContext={true}
      isMulti={multiple}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      selectedSearchKey={searchKey}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      closeMenuOnSelect={closeMenuOnSelect}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};

DiscountForm.ModeOfTransportations = ({
  selectedFilter = ``,
  placeholder = 'Select Mode of Transportation',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'modeOfTransportationId',
  header = 'Mode of Transportation',
  onChange = () => {},
  nameId = 'modeOfTransportationDescription',
  disabled,
  canUpdateEntity = true,
}: ContactSelectInputProps) => {
  return (
    <SelectModeOfTransportationPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeModeOfTransportation={onChange}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};
