import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  JobStatusDto,
  JobStatusListItemDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  jobStatusStore,
  getJobStatusesColumnsFx,
  getJobStatusesFx,
  deleteJobStatusFx,
  exportJobStatusesFx,
  importJobStatusesFx,
} from '../jobStatuses.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_JOBSTATUS_LINK_KEY,
  DELETE_JOBSTATUS_LINK_KEY,
  IMPORT_JOBSTATUSES_LINK_KEY,
  EXPORT_JOBSTATUSES_LINK_KEY,
  GetJobStatusParams,
} from '../jobStatuses.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { JobStatusDialog } from './jobStatus.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type JobStatusesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (jobStatusParams: GetJobStatusParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (viewName: string) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    jobStatus: JobStatusDto,
    jobStatusParams: GetJobStatusParams,
  ) => void;
};

export const JobStatusesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: JobStatusesListProps) => {
  const [
    jobStatuses,
    setJobStatuses,
  ] = useState<JobStatusListItemDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    jobStatusColumns: columns,
    defaultJobStatusColumns: defaultColumns,
  } = useStore(jobStatusStore);

  useEffect(() => {
    getJobStatusesColumnsFx();
  }, []);

  const getJobStatuses = useCallback(async () => {
    const jobStatusesData = await getJobStatusesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(jobStatusesData));
    setJobStatuses(jobStatusesData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: JobStatusListItemDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_JOBSTATUSES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importJobStatusesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getJobStatuses();
          },
          result?.links,
        ),
        getAction(
          EXPORT_JOBSTATUSES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportJobStatusesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getJobStatuses],
  );

  useEffect(() => {
    getJobStatuses();
  }, [getJobStatuses]);

  const onDeleteJobStatus = useCallback(
    async (jobStatus: JobStatusDto) => {
      if (userHas(DELETE_JOBSTATUS_LINK_KEY, jobStatuses?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Job status`,
            message: 'Are you sure you want to delete job status?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteJobStatusFx(jobStatus);
            getJobStatuses();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getJobStatuses, onDelete, jobStatuses],
  );

  const onEditJobStatus = (jobStatus: JobStatusDto) => {
    if (jobStatus) goToDetails({ jobStatusId: jobStatus.jobStatusId });
  };

  const onJobStatusSelect = useCallback(
    async (jobStatus) => {
      const selectedJobStatus = await showDialog({
        dialog: JobStatusDialog,
        props: {
          jobStatusId: jobStatus.jobStatusId,
          title: 'Update Job status',
        },
      });
      if (selectedJobStatus) {
        getJobStatuses();
      }
    },
    [getJobStatuses],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          rowKeys={['jobStatusId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={jobStatuses.items}
          onLimitChanged={onLimitChanged}
          total={jobStatuses.totalCount}
          onDelete={onDeleteJobStatus}
          onSort={onSort}
          onEdit={onEditJobStatus}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onJobStatusSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {jobStatuses?.totalCount === 0 && !search && (
          <ListElementsNotFound
            entityName="Job status"
            entityLinkKey={CREATE_JOBSTATUS_LINK_KEY}
            onClick={goToCreate}
          />
        )}
      </>
    );
  }
};
