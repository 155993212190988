import {
  ContactDto,
  LinkDto,
  LinkResourceBaseDto,
  ContactDtoPagedResult,
  CreateContactCommandValues,
  UpdateContactCommandValues,
  FileType,
} from '../../models/data.models';
import { downloadLinkFile, execLink, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import {
  CreateContactAndContactAddressesCommand,
  UpdateContactAndContactAddressesCommand,
} from '../../models/custom.models';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';
export const GET_CONTACTS_LINK_KEY = 'get-contacts';
export const CREATE_CONTACT_LINK_KEY = 'create-contact';
export const GET_CONTACT_LINK_KEY = 'get-contact';
export const UPDATE_CONTACT_LINK_KEY = 'update-contact';
export const DELETE_CONTACT_LINK_KEY = 'delete-contact';
export const IMPORT_CONTACTS_LINK_KEY = 'import-contacts';
export const EXPORT_CONTACTS_LINK_KEY = 'export-contacts';

export const CONTACT_ENTITY_NAME = 'Contact';

export interface ImportContactsParams {
  organizationId: number;
}
export interface ExportContactsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}
export interface GetContactParams {
  contactId: number;
}

export const getContactRequest = async (
  resource: LinkResourceBaseDto | null,
  { contactId }: GetContactParams,
): Promise<ContactDto> => {
  if (resource && contactId) {
    const getContactLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACT_LINK_KEY,
    );
    if (getContactLink) {
      const result = await execLink(getContactLink, { contactId });
      return result.data;
    }
  } else {
    addMessage({
      message: `Action ${GET_CONTACT_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};

export const getContactsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContactDtoPagedResult> => {
  if (resource) {
    const getContactsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTS_LINK_KEY,
    );
    if (getContactsListLink) {
      const result = await execLink(getContactsListLink, params);
      return result.data;
    }
  } else {
    addMessage({
      message: `Action ${GET_CONTACTS_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};

export const createContactRequest = async (
  resource: LinkResourceBaseDto,
  contact: CreateContactCommandValues | any,
) => {
  const createContactLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTACT_LINK_KEY,
  );
  if (createContactLink) {
    const result = await execLink(createContactLink, contact);
    return result.data;
  } else {
    addMessage({
      message: `Action ${CREATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};

export const createContactAndContactAddressesRequest = async (
  resource: LinkResourceBaseDto,
  contact: CreateContactAndContactAddressesCommand | any,
) => {
  const createContactLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTACT_LINK_KEY,
  );
  if (createContactLink) {
    const result = await execLink(createContactLink, contact);
    return result.data;
  } else {
    addMessage({
      message: `Action ${CREATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};
export const updateContactAndContactAddressesRequest = async (
  resource: LinkResourceBaseDto,
  contact: UpdateContactAndContactAddressesCommand,
) => {
  const updateContactLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTACT_LINK_KEY,
  );
  if (updateContactLink) {
    const result = await execLink(updateContactLink, contact);
    return result.data;
  } else {
    addMessage({
      message: `Action ${UPDATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};
export const updateContactRequest = async (
  resource: LinkResourceBaseDto,
  contact: UpdateContactCommandValues,
) => {
  const updateContactLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTACT_LINK_KEY,
  );
  if (updateContactLink) {
    const result = await execLink(updateContactLink, contact);
    return result.data;
  } else {
    addMessage({
      message: `Action ${UPDATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};

export const deleteContactRequest = async (contact: ContactDto) => {
  const deleteContactLink = contact.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTACT_LINK_KEY,
  );
  if (deleteContactLink) {
    const result = await execLink(deleteContactLink, contact);
    return result.data;
  } else {
    addMessage({
      message: `Action ${DELETE_CONTACT_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};

export const importContactsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportContactsParams,
  file: File,
) => {
  const importContactsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CONTACTS_LINK_KEY,
  );
  if (importContactsLink) {
    const result = await postFormData(importContactsLink, params, [file]);
    return result.data;
  } else {
    addMessage({
      message: `Action ${IMPORT_CONTACTS_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};

export const exportContactsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportContactsParams,
) => {
  const exportContactsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CONTACTS_LINK_KEY,
  );
  if (exportContactsLink) {
    const result = await downloadLinkFile(exportContactsLink, params);
    return result;
  } else {
    addMessage({
      message: `Action ${EXPORT_CONTACTS_LINK_KEY} is not allowed or missing link action`,
      type: 'danger',
      id: v4(),
    });
    return Promise.reject();
  }
};
