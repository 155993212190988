import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DriverScreen } from './screens/driver.screen';
import { DriverListScreen } from './screens/driver-list.screen';

export const DRIVERS_LIST_PATH = '/drivers';
export const DRIVERS_EDIT_PATH = '/drivers/:contactId';
export const DRIVERS_CREATE_PATH = '/drivers/0';

export const DriversRoute = (props: RouteProps) => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <DriverListScreen pageTitle="Drivers" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <DriverScreen pageTitle="Add New Driver" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <DriverScreen pageTitle="Update Driver" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
