import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import {
  Column,
  ContactPaymentMethodDtoPagedResult,
  ContactPaymentMethodViewDto,
  CustomFieldEntityType,
} from '../../../models/data.models';
import { ContactPaymentMethodsGrid } from '../../common/components/grid/contactPaymentMethod-grid';

import { getCustomFieldsFx } from '../../customFields/customFields.store';
import {
  contactPaymentMethodStore,
  getContactPaymentMethods,
  updateContactPaymentMethodsColumns,
} from '../contactPaymentMethod.store';

export type ContactPaymentMethodsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onFilter?: (query: string) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
  parentId?: number;
  disableDots?: boolean;
  showPagination?: boolean;
  isTab?: boolean;
  addButtonRenderCondition?: boolean;
  showSortColumns?: boolean;
};

export const ContactPaymentMethodsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
  showAllStore = true,
  rowKeys = ['contactId'],
  onSearch,
  isDropDownList = false,
  parentId,
  disableDots = false,
  showPagination = true,
  isTab,
  addButtonRenderCondition,
  showSortColumns = true,
}: ContactPaymentMethodsListProps) => {
  const [
    paymentMethods,
    setPaymentMethods,
  ] = useState<ContactPaymentMethodDtoPagedResult | null>(null);
  const [customFieldsNames, setCustomFieldsNames] = useState<string[]>([]);

  useEffect(() => {
    getContactsData();
  }, [paymentMethods?.items?.length]);

  const getContactsData = () => {
    getContactPaymentMethods({ offset, limit, sort, search }).then(
      (contactPaymentMethods) => {
        const paymentMethodsPagedResult: ContactPaymentMethodDtoPagedResult = {
          items: contactPaymentMethods.items.map((item) => {
            const paymentMethodsViewDto: ContactPaymentMethodViewDto = {
              contactPaymentMethodId: item.contactPaymentMethodId,
              contactId: item.contactId,
              customerId: item.customerId,
              paymentType: item.paymentType,
              description: item.description,
              expDate: item.expDate,
            };
            if (item?.customValues) {
              Object.keys(item.customValues).forEach(
                (customFieldInternalName) => {
                  if (
                    !paymentMethodsViewDto.hasOwnProperty(
                      customFieldInternalName,
                    )
                  )
                    paymentMethodsViewDto[customFieldInternalName] =
                      item.customValues[customFieldInternalName];
                },
              );
            }
            return paymentMethodsViewDto;
          }),
          limit: contactPaymentMethods.limit,
          links: contactPaymentMethods.links,
          offset: contactPaymentMethods.offset,
          totalCount: contactPaymentMethods.totalCount,
        };
        setPaymentMethods(paymentMethodsPagedResult);
      },
      () => {},
    );
  };

  const getCustomFieldsColumns = () => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.ContactPaymentMethod} AND isInactive: false`,
    }).then((customFieldsData) => {
      const customFieldsColumns: Column[] = [];
      const customFieldsColumnsNames: string[] = [];
      customFieldsData.items.forEach((customFieldDto) => {
        customFieldsColumns.push({
          name: customFieldDto.internalName,
          visible: true,
          title: customFieldDto.displayName,
          showFilter: true,
          type: 'customField',
          customFieldType: customFieldDto.customFieldType,
        });
        customFieldsColumnsNames.push(customFieldDto.internalName);
      });
      setCustomFieldsNames(customFieldsColumnsNames);
      const existingColumns = contactPaymentMethodStore.getState()
        .contactColumns;
      const existingColumnsNames: string[] = contactPaymentMethodStore
        .getState()
        .contactColumns.map((column) => {
          return column.name;
        });
      const customFieldsColumnsToAdd: Column[] = [];
      customFieldsColumns.forEach((customColumn) => {
        if (existingColumnsNames.indexOf(customColumn.name) === -1) {
          customFieldsColumnsToAdd.push(customColumn);
        }
      });
      for (
        let i = contactPaymentMethodStore.defaultState.contactColumns.length;
        i < existingColumns.length;
        i++
      ) {
        if (customFieldsColumnsNames.indexOf(existingColumns[i].name) === -1) {
          existingColumns.splice(i, 1);
        }
      }
      if (customFieldsColumnsToAdd.length > 0) {
        updateContactPaymentMethodsColumns([
          ...existingColumns,
          ...customFieldsColumnsToAdd,
        ]);
      }
    });
  };

  const { contactColumns: columns } = useStore(contactPaymentMethodStore);

  return (
    <>
      <ContactPaymentMethodsGrid
        contactType={'Contact'}
        isDropDownList={isDropDownList}
        showEmptyTable={true}
        search={search}
        onSearch={onSearch}
        showAllStore={showAllStore}
        showAllFilters={false}
        rowIncludeFilterKeys={null}
        rowKeys={rowKeys}
        data={
          parentId
            ? paymentMethods?.items.filter(
                (methods) => methods.contactId == parentId,
              )
            : paymentMethods?.items
        }
        columns={columns}
        offset={offset}
        limit={limit}
        total={0}
        sort={sort}
        onDelete={null}
        onSort={onSort}
        onEdit={null}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateContactPaymentMethodsColumns}
        onSelect={null}
        filter={filter}
        onFilter={onFilter}
        className={'payment-methods-list'}
        disableDots={disableDots}
        showPagination={showPagination}
        isTab={isTab}
        getContactsData={getContactsData}
        parentId={parentId}
        addButtonRenderCondition={addButtonRenderCondition}
        showSortColumns={showSortColumns}
      />
    </>
  );
};
