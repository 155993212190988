import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PackageCategoryScreen } from './screen/packageCategory.screen';
import { PackageCategoriesListScreen } from './screen/packageCategories-list.screen';

export const PACKAGE_CATEGORIES_LIST_PATH = '/package-categories';
export const PACKAGE_CATEGORY_EDIT_PATH =
  '/package-categories/:packageCategoryCode';
export const PACKAGE_CATEGORY_CREATE_PATH = '/package-categories/0';

export const PackageCategoryRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <PackageCategoriesListScreen
              pageTitle="Package Categories"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <PackageCategoryScreen
              pageTitle="Add New Package Category"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':packageCategoryCode'}
        element={
          <PrivateRoute>
            <PackageCategoryScreen
              pageTitle="Update Package Category"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
