import React, { useState } from 'react';
import { FiltersForm } from './filters.form';
import {
  AttachmentType,
  CargoMovementStatus,
  CargoMovementType,
  ContactDto,
  ContactType,
  CustomFieldType,
  DivisionDto,
  DocumentTemplateDto,
  IDNumberType,
  ModeOfTransportationDto,
  OrderStatuses,
  OrderTypes,
  PortDto,
  RateType,
  StatusStage,
  UserDto,
  WarehouseLocationDto,
} from '../../../../models/data.models';
import { FormikProps, FormikValues } from 'formik';
import { escapeString, getEnumValues } from '../../../../utils/helper.utils';
import lodash from 'lodash';
import { FilteredField, GridContextValue } from './filtersTab.component';

type FilterFieldsProperties = {
  filter: FilteredField;
  context: FormikProps<FormikValues>;
  index?: number;
  addData?: any;
  useGridContext?: () => GridContextValue;
};

export const FilterField = ({
  filter,
  context,
  index,
  addData,
  useGridContext,
}: FilterFieldsProperties) => {
  const [
    documentTemplateState,
    setDocumentTemplateState,
  ] = useState<DocumentTemplateDto>();

  const { entityType } = useGridContext();

  const reactSelectCustomStyles = {
    menu: (provided, state) => {
      return {
        ...provided,
        height: 150,
      };
    },

    menuList: (provided, state) => {
      return {
        ...provided,
        maxHeight: 150,
      };
    },
  };

  const filterValue = lodash.get(context.values, filter.name);
  switch (filter.type) {
    case 'contact':
      switch (filter.contactType) {
        case ContactType.Customer:
          const name = lodash.get(
            context.values,
            `${filter.name}__BillToContactName`,
          );
          const contactType = lodash.get(
            context.values,
            `${filter.name}__BillToContactType`,
          );
          return (
            <div className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}>
              <FiltersForm.CustomerSelect
                header={filter.title}
                selectedFilter={filter.contactTypes
                  .map((type) => `contactType: ${type}`)
                  .join(' OR ')}
                customStyles={reactSelectCustomStyles}
                id={filter.name}
                defaultValue={
                  filterValue != null
                    ? (name != null && contactType != null) ||
                      (addData?.__BillToContactName != null &&
                        addData?.__BillToContactType != null)
                      ? {
                          contactId: filterValue,
                          name: name ?? addData.__BillToContactName,
                          contactType:
                            contactType ?? addData.__BillToContactType,
                        }
                      : filterValue
                    : ''
                }
                onChange={(
                  data?: ContactDto,
                  context?: FormikProps<FormikValues>,
                ) => {
                  context.setFieldValue(
                    `${filter.name}__BillToContactType`,
                    data?.contactType,
                  );
                  context.setFieldValue(
                    `${filter.name}__BillToContactName`,
                    data?.name,
                  );
                  context.setFieldValue(filter.name, data?.contactId);
                }}
              />
            </div>
          );
        default:
          return (
            <div className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}>
              <FiltersForm.CarrierContactSelect
                header={filter.title}
                selectedFilter={filter.contactTypes
                  .map((type) => `contactType: ${type}`)
                  .join(' OR ')}
                customStyles={reactSelectCustomStyles}
                id={filter.name}
              />
            </div>
          );
      }
    case CustomFieldType.OrderStatus:
    case 'orderStatus':
      const filterLabel = lodash.get(
        context.values,
        `${filter.name}__orderStatus`,
      );
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.OrderStatus
            id={filter.name}
            name={`${filter.name}Name`}
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            selectedFilter={entityType ? `orderType:${entityType}` : null}
            defaultValue={
              filterValue != null
                ? filterLabel != null || addData?.__Name != null
                  ? {
                      orderStatusId: filterValue,
                      name: filterLabel ?? addData?.__Name,
                    }
                  : filterValue
                : ''
            }
          />
        </div>
      );
    case 'idNumberType':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.IdNumberType
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            options={getEnumValues(IDNumberType)}
            defaultValue={
              context.values &&
              context.values.__idNumberType != null &&
              context.values.idNumberType != null
                ? {
                    label: context.values.__idNumberType,
                    value: context.values.idNumberType,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('idNumberType', data?.value ?? null);
              context.setFieldValue('__idNumberType', data?.label ?? null);
            }}
          />
        </div>
      );
    case 'Date':
    case 'date':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.Date
            name={filter.name}
            id={filter.name}
            header={filter.title}
            placeholder={`Select ${filter.title}`}
            defaultValue={lodash.get(context.values, filter.name) || null}
          />
        </div>
      );
    case 'customField':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.Textbox
            id={filter.name}
            placeholder={filter.title}
            header={filter.title}
            defaultValue={context.values[`__${filter.name}`]}
            onChange={async (data?: any) => {
              let luceneFilter: string = escapeString(
                data.target.value,
                filter.type,
              );
              await context.setFieldValue(
                `__${filter.name}`,
                data.target.value,
              );
              await context.setFieldValue(filter.name, luceneFilter);
            }}
          />
        </div>
      );
    case 'rateType':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.RateType
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            options={getEnumValues(RateType)}
            defaultValue={
              context.values &&
              context.values.__rateType != null &&
              context.values.rateType != null
                ? {
                    label: context.values.__rateType,
                    value: context.values.rateType,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('rateType', data?.value ?? null);
              context.setFieldValue('__rateType', data?.label ?? null);
            }}
          />
        </div>
      );
    case 'movementStatus':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.MovementStatus
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            id={filter.name}
            options={getEnumValues(CargoMovementStatus)}
            defaultValue={
              context.values &&
              context.values.customValues?.movementStatus != null
                ? {
                    label: context.values.customValues?.movementStatus,
                    value: context.values.customValues?.movementStatus,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue(`${filter.name}`, data?.value ?? null);
            }}
          />
        </div>
      );
    case 'movementType':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.MovementType
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            id={filter.name}
            options={getEnumValues(CargoMovementType)}
            defaultValue={
              context.values &&
              context.values.customValues?.movementType != null
                ? {
                    label: context.values.customValues?.movementType,
                    value: context.values.customValues?.movementType,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue(`${filter.name}`, data?.value ?? null);
            }}
          />
        </div>
      );
    case 'ModeOfTransportation':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.ModeOfTransportation
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            id={filter.name}
            defaultValue={
              context.values && lodash.get(context.values, filter.name) != null
                ? context.values?.[`__${filter.name}Description`] != null ||
                  addData?.__Description != null
                  ? {
                      modeOfTransportationId: lodash.get(
                        context.values,
                        filter.name,
                      ),
                      description:
                        context.values[`__${filter.name}Description`] ??
                        addData?.__Description,
                    }
                  : lodash.get(context.values, filter.name)
                : ''
            }
            onChange={(
              data?: ModeOfTransportationDto,
              context?: FormikProps<FormikValues>,
            ) => {
              context.setFieldValue(
                `__${filter.name}Description`,
                data?.description,
              );
            }}
          />
        </div>
      );
    case 'contactStatus':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.ContactStatus
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            defaultValue={
              context.values &&
              context.values?.contactStatusId != null &&
              context.values.__statusName != null
                ? {
                    statusId: context?.values?.statusId,
                    statusName: context?.values?.__statusName,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('contactStatusId', data?.statusId ?? null);
              context.setFieldValue('__statusName', data?.statusName ?? null);
            }}
            selectedFilter={`contactType:${ContactType.Customer}`}
            isClearable={true}
          />
        </div>
      );
    case CustomFieldType.Port:
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.Port
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            defaultValue={
              context.values && context.values?.[filter.name] != null
                ? context.values?.[`__${filter.name}Name`] != null ||
                  addData?.__Name != null
                  ? {
                      portId: context.values[filter.name],
                      name:
                        context.values[`__${filter.name}Name`] ??
                        addData?.__Name,
                    }
                  : context.values[filter.name]
                : ''
            }
            onChange={(data?: PortDto, context?: FormikProps<FormikValues>) => {
              context.setFieldValue(`__${filter.name}Name`, data?.name);
            }}
          />
        </div>
      );
    case 'parcelStatus':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.ParcelStatus
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            defaultValue={
              context.values && context.values?.[filter.name] != null
                ? context.values?.orderStatusName != null ||
                  addData?.__Name != null
                  ? {
                      orderStatusId: context.values[filter.name],
                      orderStatusName:
                        context.values?.orderStatusName ?? addData?.__Name,
                    }
                  : context.values[filter.name]
                : ''
            }
          />
        </div>
      );
    case CustomFieldType.Vessel:
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.Vessel
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            defaultValue={
              context.values && context.values?.[filter.name] != null
                ? context.values?.[`__${filter.name}Name`] != null ||
                  addData?.__Name != null
                  ? {
                      vesselId: context.values[filter.name],
                      name:
                        context.values[`__${filter.name}Name`] ??
                        addData?.__Name,
                    }
                  : context.values[filter.name]
                : ''
            }
            onChange={(data, context?: FormikProps<FormikValues>) => {
              context.setFieldValue(`__${filter.name}Name`, data?.name);
            }}
          />
        </div>
      );
    case CustomFieldType.EquipmentType:
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.EquipmentType
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            defaultValue={
              context.values && context.values?.[filter.name] != null
                ? context.values?.[`__${filter.name}Name`] != null ||
                  addData?.__Name != null
                  ? {
                      equipmentTypeId: context.values[filter.name],
                      name:
                        context.values[`__${filter.name}Name`] ??
                        addData.__Name,
                    }
                  : context.values[filter.name]
                : ''
            }
            onChange={(data, context?: FormikProps<FormikValues>) => {
              context.setFieldValue(`__${filter.name}Name`, data?.name);
            }}
          />
        </div>
      );
    case CustomFieldType.CustomCode:
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.CustomCode
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            defaultValue={
              context.values && context.values?.[filter.name] != null
                ? {
                    code: context.values[filter.name],
                  }
                : ''
            }
          />
        </div>
      );
    case CustomFieldType.DestinationCountries:
      return (
        <div
          className={'col-sm-6 col-md-3 col-xs-6 col-lg-3 col-xl-3'}
          key={index}
        >
          <FiltersForm.DestinationCountries
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={`__${filter.name}`}
            defaultValue={
              context.values && lodash.get(context.values, filter.name) != null
                ? lodash.get(context.values, filter.name)
                : []
            }
            onChange={(data, context?: FormikProps<FormikValues>) => {
              const sortedCountries = data.sort((a, b) =>
                a.name.localeCompare(b.name),
              );
              context.setFieldValue(filter.name, sortedCountries);
            }}
          />
        </div>
      );
    case CustomFieldType.ContactPaymentMethod:
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.ContactPaymentMethod
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            defaultValue={
              context.values && context.values?.[filter.name] != null
                ? context.values?.[`__${filter.name}Description`] != null ||
                  addData?.__Name != null
                  ? {
                      contactPaymentMethodId: context.values[filter.name],
                      description:
                        context.values[`__${filter.name}Description`] ??
                        addData.__Name,
                    }
                  : context.values[filter.name]
                : ''
            }
            onChange={(data?: PortDto, context?: FormikProps<FormikValues>) => {
              context.setFieldValue(`__${filter.name}Description`, data?.name);
            }}
          />
        </div>
      );
    case 'currency':
      return (
        <div
          className={'col-sm-6 col-md-5 col-xs-6 col-lg-5 col-xl-5'}
          key={index}
        >
          <FiltersForm.CurrencySelect
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            nameId={`__${filter.name}Code`}
            defaultValue={
              context.values && context.values?.[filter.name] != null
                ? context.values?.[`__${filter.name}Code`] != null ||
                  addData?.__Name != null
                  ? {
                      currencyId: context.values[filter.name],
                      currencyCode:
                        context.values[`__${filter.name}Code`] ??
                        addData.__Name,
                    }
                  : context.values[filter.name]
                : ''
            }
          />
        </div>
      );
    case 'division':
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.Division
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            nameId={`__${filter.name}Name`}
            defaultValue={
              filterValue != null
                ? context.values?.[`__${filter.name}Name`] != null
                  ? {
                      divisionId: filterValue,
                      divisionName: context.values[`__${filter.name}Name`],
                    }
                  : filterValue
                : ''
            }
            onChange={(
              data?: DivisionDto,
              context?: FormikProps<FormikValues>,
            ) => {
              context.setFieldValue(`__${filter.name}Name`, data?.divisionName);
            }}
          />
        </div>
      );
    case 'documentTemplateType':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-xl-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.DocumentTemplateType
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            nameId={`${filter.name}Name`}
            defaultValue={
              filterValue != null
                ? context.values?.[`${filter.name}Name`] != null
                  ? documentTemplateState
                  : filterValue
                : ''
            }
            onChange={(
              data?: DocumentTemplateDto,
              context?: FormikProps<FormikValues>,
            ) => {
              setDocumentTemplateState(data);
              context.setFieldValue(
                `${filter.name}Id`,
                data?.documentTemplateId,
              );
            }}
          />
        </div>
      );
    case 'warehouseLocation':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-xl-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.WarehouseLocation
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            nameId={`${filter.name}Name`}
            defaultValue={
              filterValue != null
                ? context.values?.[`__${filter.name}Name`] != null
                  ? {
                      warehouseLocationId: filterValue,
                      description: context.values[`__${filter.name}Name`],
                    }
                  : filterValue
                : ''
            }
            onChange={(
              data?: WarehouseLocationDto,
              context?: FormikProps<FormikValues>,
            ) => {
              context.setFieldValue(`__${filter.name}Name`, data?.description);
              context.setFieldValue(filter.name, data?.warehouseLocationId);
            }}
          />
        </div>
      );
    case 'user':
      return (
        <div
          className={'col-sm-4 col-md-6 col-xs-6 col-xl-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.User
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={filter.name}
            nameId={`${filter.name}`}
            defaultValue={
              filterValue != null
                ? {
                    userName: filterValue,
                  }
                : ''
            }
            onChange={(data?: UserDto, context?: FormikProps<FormikValues>) => {
              context.setFieldValue(`${filter.name}`, data?.userName);
            }}
          />
        </div>
      );
    case 'finalMileCarrier':
      return (
        <div className={'col-sm-6 col-md-3 col-xs-6 col-lg-3 col-xl-3'}>
          <FiltersForm.CarrierContactSelect
            selectedFilter={filter.contactTypes
              .map((type) => `contactType: ${type}`)
              .join(' OR ')}
            customStyles={reactSelectCustomStyles}
            header={filter.title}
            id={`__${filter.name}`}
            defaultValue={
              context.values && lodash.get(context.values, filter.name) != null
                ? lodash.get(context.values, filter.name)
                : null
            }
            onChange={(data, context?: FormikProps<FormikValues>) => {
              context.setFieldValue(filter.name, data?.contactId);
            }}
            closeMenuOnSelect={true}
          />
        </div>
      );
    case 'attachmentType':
      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-xl-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.AttachmentType
            customStyles={reactSelectCustomStyles}
            options={getEnumValues(AttachmentType)}
            header={filter.title}
            defaultValue={
              context.values &&
              context.values.__attachmentType != null &&
              context.values.attachmentType != null
                ? {
                    label: context.values.__attachmentType,
                    value: context.values.attachmentType,
                  }
                : null
            }
            onChange={(data?: any) => {
              context.setFieldValue('attachmentType', data?.value ?? null);
              context.setFieldValue('__attachmentType', data?.label ?? null);
            }}
          />
        </div>
      );
    case 'Boolean':
      return (
        <div
          className={'col-sm-3 col-md-3 col-xs-3 col-lg-3 col-xl-3'}
          key={index}
        >
          <FiltersForm.Boolean
            name={filter.name}
            id={filter.name}
            placeholder={filter.title}
            header={filter.title}
            customStyles={reactSelectCustomStyles}
            options={[
              { label: 'True', value: true },
              { label: 'False', value: false },
            ]}
            defaultValue={
              context.values &&
              context.values[`__${filter.name}`] != null &&
              context.values[filter.name] != null
                ? {
                    label: context.values[`__${filter.name}`],
                    value: context.values[filter.name],
                  }
                : null
            }
            onChange={(selectedOption: any) => {
              const newValue = selectedOption?.value;
              const newLabel = selectedOption?.label;
              context.setFieldValue(`${filter.name}`, newValue);
              context.setFieldValue(`__${filter.name}`, newLabel);
            }}
          />
        </div>
      );
    case 'List':
      return (
        <div
          className={'col-sm-3 col-md-3 col-xs-3 col-lg-3 col-xl-3'}
          key={index}
        >
          <FiltersForm.List
            name={filter.name}
            id={filter.name}
            placeholder={filter.title}
            header={filter.title}
            customStyles={reactSelectCustomStyles}
          />
        </div>
      );
    case 'Options':
      let componentToRender: any;

      switch (filter.name) {
        case 'orderType':
          componentToRender = (
            <FiltersForm.OrderType
              customStyles={reactSelectCustomStyles}
              options={getEnumValues(OrderTypes)}
              header={filter.title}
              defaultValue={
                context.values &&
                context.values.__orderType != null &&
                context.values.orderType != null
                  ? {
                      label: context.values.__orderType,
                      value: context.values.orderType,
                    }
                  : null
              }
              onChange={(data?: any) => {
                context.setFieldValue('orderType', data?.value ?? null);
                context.setFieldValue('__orderType', data?.label ?? null);
              }}
            />
          );
          break;
        case 'statusStage':
          componentToRender = (
            <FiltersForm.StatusStage
              customStyles={reactSelectCustomStyles}
              options={getEnumValues(StatusStage)}
              header={filter.title}
              defaultValue={
                context.values &&
                context.values.__statusStage != null &&
                context.values.statusStage != null
                  ? {
                      label: context.values.__statusStage,
                      value: context.values.statusStage,
                    }
                  : null
              }
              onChange={(data?: any) => {
                context.setFieldValue('statusStage', data?.value ?? null);
                context.setFieldValue('__statusStage', data?.label ?? null);
              }}
            />
          );
          break;

        // Add more cases here as needed

        default:
          componentToRender = (
            <FiltersForm.Textbox
              id={filter.name}
              placeholder={filter.title}
              header={filter.title}
              defaultValue={lodash.get(context.values, `__${filter.name}`)}
              onChange={async (data?: any) => {
                let luceneFilter: string = escapeString(
                  data.target.value,
                  filter.type,
                );
                context.setFieldValue(`__${filter.name}`, data.target.value);
                context.setFieldValue(`${filter.name}`, luceneFilter);
              }}
            />
          );
          break;
      }

      return (
        <div
          className={'col-sm-6 col-md-6 col-xs-6 col-xl-2 col-xl-2'}
          key={index}
        >
          {componentToRender}
        </div>
      );
    default:
      return (
        <div
          className={'col-sm-6 col-xs-6 col-md-2 col-lg-2 col-xl-2'}
          key={index}
        >
          <FiltersForm.Textbox
            id={filter.name}
            placeholder={filter.title}
            header={filter.title}
            defaultValue={lodash.get(context.values, `__${filter.name}`)}
            onChange={async (data?: any) => {
              let luceneFilter: string = escapeString(
                data.target.value,
                filter.type,
              );
              context.setFieldValue(`__${filter.name}`, data.target.value);
              context.setFieldValue(`${filter.name}`, luceneFilter);
            }}
          />
        </div>
      );
  }
};
