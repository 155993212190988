import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { OrderDocumentForm } from './orderDocumentForm';
import {
  LinkResourceBaseDto,
  OrderDocumentDto,
} from '../../../models/data.models';
import {
  getOrderDocumentFx,
  updateOrderDocumentFx,
} from '../orderDocuments.store';

export type OrderDocumentEditProps = {
  orderDocumentId?: number | null;
  order?: LinkResourceBaseDto;
  onOrderDocumentCreated?: (document: OrderDocumentDto) => void;
  onOrderDocumentUpdated?: (attachment: OrderDocumentDto) => void;
  onCancel?: () => void;
};

const initialState: OrderDocumentDto = {
  orderDocumentId: 0,
  orderDocumentName: null,
  documentTemplateId: 0,
  lastGeneratedFile: null,
  lastGeneratedTime: null,
  orderId: 0,
  regenerateOnOrderChange: false,
  created: null,
  createdByUserName: null,
  lastModified: null,
  lastModifiedByUserName: null,
  links: [],
  documentTemplate: null,
};

export const OrderDocumentEdit = ({
  orderDocumentId,
  order,
  onOrderDocumentCreated = () => {},
  onOrderDocumentUpdated = () => {},
  onCancel = () => {},
}: OrderDocumentEditProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [initialValues, setInitialValues] = useState<OrderDocumentDto | null>(
    initialState,
  );

  useEffect(() => {
    if (orderDocumentId) {
      getOrderDocumentFx({ orderDocumentId, order }).then(
        (documentDto: OrderDocumentDto) => {
          setInitialValues(documentDto);
          setIsLoading(false);
        },
      );
    } else {
      throw new Error('Order document keys were not provided');
    }
  }, [orderDocumentId]);

  const onSubmit = (data: OrderDocumentDto) => {
    setIsSending(true);
    updateOrderDocumentFx(data)
      .then(() => {
        onOrderDocumentUpdated(data);
      })

      .finally(() => setIsSending(false));
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <div className="m-1">
      <div className="col-12">
        <OrderDocumentForm initialValues={initialValues} onSubmit={onSubmit}>
          <div className="row">
            <div className="mr-4">
              <img className="edit-pen-icon"></img>
            </div>
            <div>
              <h2 className="mb-2">Edit order document</h2>
            </div>
          </div>
          <div className="mb-4 mt-4">
            <OrderDocumentForm.RegenerateOnOrderChange
              defaultValue={initialValues.regenerateOnOrderChange}
            />
          </div>
          <div className="justify-space-between row">
            <div className="col-6">
              <Button
                color="primary"
                name="save-order-document"
                className="btn-block"
                type="submit"
              >
                Save
              </Button>
            </div>
            <div className="col-6">
              <Button
                className="btn-block btn-outline-secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </OrderDocumentForm>
      </div>
    </div>
  );
};
