import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { VesselScreen } from './screens/vessel.screen';
import { VesselsListScreen } from './screens/vessels-list.screen';

export const VESSELS_LIST_PATH = '/vessels';
export const VESSEL_EDIT_PATH = '/vessels/:vesselId';
export const VESSEL_CREATE_PATH = '/vessels/0';

export const VesselRoute = (props: RouteProps) => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <VesselsListScreen pageTitle="Vessels" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <VesselScreen pageTitle="Add New Vessel" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':vesselId'}
        element={
          <PrivateRoute>
            <VesselScreen pageTitle="Update Vessel" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
