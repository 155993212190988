import { Dialog } from '../../../common/components/dialog/dialog.component';
import { OrderDto, TrackingEventDto } from '../../../../models/data.models';
import { RateManagerComponent, RateType } from './rate-manager.component';

export type RateManagerDialogProperties = {
  title: string;
  className?: string;
  orderDto?: OrderDto;
  setTrackingEvents?: (value: React.SetStateAction<TrackingEventDto[]>) => void;
};

export class RateManagerDialog extends Dialog<RateManagerDialogProperties> {
  constructor(props: RateManagerDialogProperties) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm = (rateData: RateType) => {
    this.close(rateData);
  };

  renderContent(): any {
    const { className, orderDto, setTrackingEvents } = this
      .props as RateManagerDialogProperties;
    return (
      <RateManagerComponent
        className={className}
        orderDto={orderDto}
        onConfirm={this.onConfirm.bind(this)}
        onCancel={this.close.bind(this)}
        setTrackingEvents={setTrackingEvents}
      />
    );
  }
}
