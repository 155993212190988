import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PortForm } from './port.form';
import {
  PortDto,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  CustomFieldType,
} from '../../../models/data.models';
import {
  createPortFx,
  deletePortFx,
  getPortFx,
  updatePortFx,
} from '../ports.store';
import { Panel } from '../../common/components/panel/panel.component';
import { PortDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { DELETE_PORT_LINK_KEY, UPDATE_PORT_LINK_KEY } from '../ports.service';
import { userHas } from '../../auth/auth.store';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { Dropdown } from 'react-bootstrap';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { FiDelete, FiUpload } from 'react-icons/fi';
import { IAction, getAction } from '../../common/components/actions/actions';
import { AiFillDelete } from 'react-icons/ai';

export type PortEditProps = {
  portId?: string | null | undefined;
  countryCode?: string | null | undefined;
  onPortCreated?: (port: PortDto) => void;
  onPortUpdated?: (port: PortDto) => void;
  onPortLoaded?: (port: PortDto) => void;
  onCancel?: () => void;
  onDelete?: (port: PortDto) => void;
};

const initialState: PortDto = {
  portId: '',
  countryCode: '',
  isAir: false,
  isBorderCrossingPoint: false,
  isMail: false,
  isMariTime: false,
  isMyCompany: false,
  isRail: false,
  isRoad: false,
  name: '',
  portRemarks: null,
  stateCode: null,
  links: [],
};

export const PortEdit = ({
  portId,
  countryCode,
  onPortLoaded,
  onPortCreated,
  onPortUpdated,
  onCancel,
  onDelete,
}: PortEditProps) => {
  const [isCreateMode, setIsCreateMode] = useState(
    portId == null && countryCode == null,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [port, setPort] = useState<PortDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setIsCreateMode(portId == null && countryCode == null);
  }, [portId, , countryCode]);

  const portSchema = useMemo(() => {
    let schema = Yup.object().shape({
      isAir: Yup.boolean().required('Is Air is required'),
      isBorderCrossingPoint: Yup.boolean().required(
        'Is Border Crossing Point is required',
      ),
      isMail: Yup.boolean().required('Is Mail is required'),
      isMariTime: Yup.boolean().required('Is Mari Time is required'),
      isMyCompany: Yup.boolean().required('Is My Company is required'),
      isRail: Yup.boolean().required('Is Rail is required'),
      isRoad: Yup.boolean().required('Is Road is required'),
      name: Yup.string().max(50).required('Name is required'),
      portId: Yup.string().max(3).required('Port ID is required'),
      portRemarks: Yup.string().nullable(true),
      stateCode: Yup.string()
        .required('State Code is required')
        .max(3)
        .nullable(true),
      countryCode: Yup.string()
        .required('Country code is required')
        .max(3)
        .nullable(true),
    });

    if (customFields.length > 0) {
      schema = generateValidationSchemaWithCustomFields(customFields, schema);
    }

    return schema;
  }, [customFields]);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        DELETE_PORT_LINK_KEY,
        'Delete',
        <AiFillDelete />,
        async () => {
          const dialogResult = await showDialog({
            dialog: Confirm,
            props: {
              title: `Delete Port`,
              message: `Are you sure you want to delete ${port.name}?`,
              className: 'delete-modal',
            },
          });
          if (dialogResult) {
            try {
              await deletePortFx(port);
              if (onCancel) onCancel();
            } catch (error) {
              // Handle error here
            }
          }
        },
        port?.links,
      ),
    ];
    return activeActions.filter(Boolean);
  }, [port]);

  const fetchCustomFields = useCallback(async () => {
    // const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
    //   filter: `customFieldEntityType: ${CustomFieldEntityType.Port} AND isInactive: false`,
    // });
    // setCustomFields(fields.items);
  }, []);

  const fetchPortData = useCallback(async () => {
    const portDto = await getPortFx({ portId, countryCode });
    setPort(portDto);
    if (onPortLoaded) onPortLoaded(portDto);
    setIsLoading(false);
  }, [portId, countryCode, onPortLoaded]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    if (!isCreateMode) {
      fetchPortData();
    } else {
      setPort({ ...initialState });
      setIsLoading(false);
    }
  }, [isCreateMode, fetchPortData]);

  const onSubmit = useCallback(
    async (data: PortDto) => {
      setIsSubmitting(true);
      try {
        const result = isCreateMode
          ? await createPortFx(data)
          : await updatePortFx(data);
        isCreateMode ? onPortCreated?.(result) : onPortUpdated?.(result);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isCreateMode, onPortCreated, onPortUpdated],
  );

  const onCustomFieldChange = (result) => {
    setPort((prevState) => ({
      ...prevState,
      customValues: result,
    }));
  };

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <PortForm
      id={'port-form'}
      initialValues={port || initialState}
      onSubmit={onSubmit}
      validationSchema={portSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Additional</Tab>
                <li className="react-tabs__tab react-tabs__tab--button">
                  <ActionsDropdown size="sm" actions={actions} />
                </li>
              </TabList>

              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New Port</h3>
                  ) : (
                    <h3 className="header-form">Update Port</h3>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <PortForm.PortId defaultValue={port.portId} />
                      <PortForm.Name defaultValue={port.name} />
                      <PortForm.CountryCode defaultValue={port.countryCode} />
                      <PortForm.StateCode defaultValue={port.stateCode} />

                      <PortForm.IsAir defaultValue={port.isAir} />
                      <PortForm.IsBorderCrossingPoint
                        defaultValue={port.isBorderCrossingPoint}
                      />
                      <PortForm.IsMail defaultValue={port.isMail} />
                      <PortForm.IsMariTime defaultValue={port.isMariTime} />
                      <PortForm.IsMyCompany defaultValue={port.isMyCompany} />
                      <PortForm.IsRail defaultValue={port.isRail} />
                      <PortForm.IsRoad defaultValue={port.isRoad} />

                      <PortForm.PortRemarks defaultValue={port.portRemarks} />
                    </div>
                  </div>
                </Panel>
              </TabPanel>
            </Tabs>
            <div className="row m-3">
              <div className="col-auto text-right">
                {(userHas(UPDATE_PORT_LINK_KEY, port?.links) ||
                  isCreateMode) && (
                  <Button
                    name="save-port"
                    type="submit"
                    color="primary"
                    form={'port-form'}
                    disabled={isSubmitting}
                  >
                    Save Port
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="ml-2"
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </PortForm>
  );
};
