import React, { useEffect, useState } from 'react';
import { EntityTypes, OrderDto } from '../../../../models/data.models';
import { getContactAddressFx } from '../../../contactAddresses/contactAddresses.store';
import { Spinner } from '../../../common/components/spinner/spinner';

type AddressInfoProps = {
  parcelShipment: OrderDto;
};

export const AddressInfo = ({ parcelShipment }: AddressInfoProps) => {
  const [destination, setDestination] = useState<string | null>(null);
  const [address, setAddress] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const consignee = parcelShipment.orderEntities.find(
      (x) => x.entityType === EntityTypes.Consignee,
    );

    if (consignee?.contactCityName && consignee?.contactCountryName) {
      const destinationValue = `${consignee.contactCityName}, ${consignee.contactCountryName}`;
      setDestination(destinationValue);
    } else {
      setDestination(null);
    }

    const getContactAddressParams = {
      contactAddressId: consignee.contactAddressId,
    };
    setIsLoading(true);
    getContactAddressFx(getContactAddressParams)
      .then((address) => {
        setAddress(address);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="mt-3">
      <div className="pt-2 pb-3 text-label">Destination Address</div>
      <div>
        <h3>
          {address?.addressLine && address?.addressLine2
            ? `${address.addressLine}, ${
                address.customValues?.toAddressHouseNumber ?? ''
              } ${
                address.customValues?.['is_pickup_location'] === 'true'
                  ? ''
                  : 'kv'
              } ${address.addressLine2}`
            : '-'}
        </h3>
      </div>
      <div>
        <h3>{destination ?? '-'}</h3>
      </div>
      <div>
        <h3>
          {address?.postalCode && address?.stateCode
            ? `${address.postalCode}, ${address.stateCode}`
            : '-'}
        </h3>
      </div>
    </div>
  );
};
