import { ComponentProps } from '../layout-interfaces';
import { useEffect, useState } from 'react';
import { parseTemplate } from '../component-hooks';

export const ImageComponent = (props: ComponentProps) => {
  const className = props.props?.className;

  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    const url = parseTemplate(props.props.url, { ...props?.variables });
    setImageUrl(url);
  }, []);

  return (
    <div {...props.props} className={className}>
      <img
        className={'w-auto'}
        alt="image-preview"
        src={imageUrl}
        style={{ maxWidth: '100%' }}
      />
    </div>
  );
};
