import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectSalesTaxPaginate } from './salesTax-react-select.component';

export const SalesTaxForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

SalesTaxForm.AuthorityContactId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'authorityContactId'}
      label={'Authority Contact Id'}
      placeholder={'Authority Contact Id'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
SalesTaxForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
SalesTaxForm.IsGroupTax = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'isGroupTax'}
      label={'Is Group Tax'}
      placeholder={'Is Group Tax'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
SalesTaxForm.IsInactive = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      id={'isInactiveSalesTaxCheckbox'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
SalesTaxForm.ParentSalesTaxId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'parentSalesTaxId'}
      label={'Parent Sales Tax Id'}
      placeholder={'Parent Sales Tax Id'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
SalesTaxForm.Rate = ({
  className,
  style,
  size,
  disabled,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'rate'}
      label={'Rate'}
      placeholder={'Rate'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};
SalesTaxForm.TaxCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'taxCode'}
      label={'Tax Code'}
      placeholder={'Tax Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
SalesTaxForm.SalesTaxSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Parent Sales Tax',
  required = false,
  defaultValue,
  sort = 'taxCode',
  id = 'parentSalesTaxId',
  header = 'Parent Sales Tax',
  onChange = () => {},
  nameId = 'parentSalesTaxName',
}: InputPublicProps) => {
  return (
    <SelectSalesTaxPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeSalesTax={onChange}
    />
  );
};

SalesTaxForm.AuthorityContactSelect = ({
  selectedFilter = ``,
  nameId = 'parentAuthorityContactName',
  placeholder = 'Select Tax Authority',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'parentAuthorityContactId',
  header = 'Tax Authority',
  onChange = () => {},
  readonly,
  contactTypes = [],
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};
