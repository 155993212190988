import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CommodityStatusForm } from './commodityStatus.form';
import {
  CommodityStatusDto,
  CommodityStatusStage,
} from '../../../models/data.models';
import {
  createCommodityStatusFx,
  getCommodityStatusFx,
  updateCommodityStatusFx,
} from '../commodityStatuses.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';

export type CommodityStatusEditProps = {
  statusId?: number | null;
  onCommodityStatusCreated?: (commodityStatus: CommodityStatusDto) => void;
  onCommodityStatusUpdated?: (commodityStatus: CommodityStatusDto) => void;
  onCommodityStatusLoaded?: (commodityStatus: CommodityStatusDto) => void;
  onCancel?: () => void;
};

const initialState: CommodityStatusDto = {
  statusId: 0,
  created: null,
  createdBy: '',
  description: '',
  lastModified: null,
  lastModifiedBy: '',
  organizationId: 0,
  priority: 0,
  statusName: '',
  statusStage: CommodityStatusStage.Pending,
  links: [],
};

const commodityStatusSchema = Yup.object().shape({
  statusName: Yup.string()
    .required("Can't be blank")
    .max(50, 'Max length is 100')
    .nullable(true),
  description: Yup.string().max(255, 'Max length is 255').nullable(true),
  priority: Yup.number().typeError('Priority must be a number').nullable(true),
});

export const CommodityStatusEdit = ({
  statusId,
  onCommodityStatusLoaded = () => {},
  onCommodityStatusCreated = () => {},
  onCommodityStatusUpdated = () => {},
  onCancel = () => {},
}: CommodityStatusEditProps) => {
  const isCreateMode = !statusId || statusId === 0;
  const [isLoading, setIsLoading] = useState(true);
  const [
    commodityStatus,
    setCommodityStatus,
  ] = useState<CommodityStatusDto | null>(null);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (statusId) {
      getCommodityStatusFx({ statusId }).then(
        (commodityStatusDto: CommodityStatusDto) => {
          setCommodityStatus(commodityStatusDto);
          setIsLoading(false);
          onCommodityStatusLoaded(commodityStatusDto);
        },
      );
    } else {
      throw new Error('CommodityStatus keys were not provided');
    }
  }, [statusId]);

  const onSubmit = (data: CommodityStatusDto) => {
    if (isCreateMode) {
      createCommodityStatusFx(data).then((result) => {
        onCommodityStatusCreated(result.data);
      });
    } else {
      updateCommodityStatusFx(data).then((result) => {
        onCommodityStatusUpdated(result.data);
      });
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <div className="row">
        <div className="col-4">
          <CommodityStatusForm
            initialValues={commodityStatus || initialState}
            onSubmit={onSubmit}
            validationSchema={commodityStatusSchema}
            id={'commodityStatusForm'}
          >
            <CommodityStatusForm.Description />
            <CommodityStatusForm.Priority />
            <CommodityStatusForm.StatusName />
            <CommodityStatusForm.StatusStage />
            <div className="d-flex justify-content-end row">
              <div className="col-8">
                <Button
                  name="save-orderStatus"
                  type="submit"
                  color="primary"
                  className="btn-block"
                  form="commodityStatusForm"
                >
                  Save Status
                </Button>
              </div>
              <div className="col-4">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={false}
                >
                  Close
                </Button>
              </div>
            </div>
          </CommodityStatusForm>
        </div>
      </div>
    </Panel>
  );
};
