import { Dialog } from '../../common/components/dialog/dialog.component';
import { UserGroupEdit } from './userGroup-edit.component';
import { UserGroupDto } from '../../../models/data.models';

export type UserGroupDialogProperties = {
  userGroupId?: number | null;
  title: string;
};

export class UserGroupDialog extends Dialog<UserGroupDialogProperties> {
  constructor(props: UserGroupDialogProperties) {
    super(props);
    this.onUserGroupSaved = this.onUserGroupSaved.bind(this);
  }

  onUserGroupSaved = (userGroup: UserGroupDto) => {
    this.close(userGroup);
  };

  renderContent(): any {
    const { userGroupId } = this.props as UserGroupDialogProperties;
    return (
      <UserGroupEdit
        userGroupId={userGroupId}
        onUserGroupCreated={this.onUserGroupSaved}
        onUserGroupUpdated={this.onUserGroupSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
