import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetPortsQuery } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  WAREHOUSEZONE_CREATE_PATH,
  WAREHOUSEZONE_EDIT_PATH,
  WAREHOUSEZONE_LIST_PATH,
} from '../warehouseInspector.route';
import { GetWarehouseZoneParams } from '../warehouseInspector.service';
import { WarehouseInspectorList } from '../components/warehouseInspector-list.component';

export type WarehouseInspectorListScreenProps = {
  pageTitle: string;
  group: string;
};

export const WarehouseInspectorListScreen = ({
  pageTitle,
  group,
}: WarehouseInspectorListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'name',
    filter = '',
    search = '',
  } = useQuery() as GetPortsQuery;
  const navigate = useNavigate();

  const goToDetails = (warehouseParams: GetWarehouseZoneParams) => {
    const createOrEdit =
      warehouseParams.warehouseZoneId === 0
        ? WAREHOUSEZONE_CREATE_PATH
        : WAREHOUSEZONE_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      warehouseZoneId: warehouseParams.warehouseZoneId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSEZONE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSEZONE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSEZONE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSEZONE_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout title={'Zone Inspector'} group={group}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <WarehouseInspectorList
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
