import { get } from 'lodash';
import { FieldComponent } from '../layout/components/field-component';
import { ComponentRender } from '../layout/component-render';
import { parseTemplate } from '../layout/component-hooks';
import { GiCheckMark } from 'react-icons/gi';
import { CgClose } from 'react-icons/cg';
import { isTrue } from '../../../../utils/helper.utils';

export interface IRowRender {
  index: number;
  data: any;
  getCellProps: (column: any) => any;
  getRowProps: () => any;
  render: (column: any, context: any) => any;
  getPath: () => string;
  getChildRows: (
    targetViewName: string,
    childColProps?: any,
    childRowProps?: any,
  ) => IRowRender[];
}

export interface IRowBuilder {
  data: any;
  index?: number;
  colProps?: any;
  rowProps?: any;
  parent?: IRowRender;
  viewName?: string;
  dataGridStore?: any;
  formikContext?: any;
}

export const rowBuilder = ({
  data,
  index,
  colProps,
  rowProps,
  parent,
  viewName,
  dataGridStore,
  formikContext,
}: IRowBuilder): IRowRender => {
  const getPath = (): string => {
    return parent
      ? `${parent.getPath()}.${viewName}[${index}]`
      : `${viewName}[${index}]`;
  };

  const rowBuilderInternal = {
    index,
    data,
    getCellProps: (column) => {
      return {
        key: column.accessor ?? `${index}${column}`,
        className: column.className,
        ...colProps,
      };
    },
    getRowProps: () => {
      return {
        key: index,
        ...rowProps,
        onClick: () => {
          if (rowProps?.onClick) {
            rowProps.onClick(data);
          }
        },
      };
    },
    getChildRows: (
      targetViewName: string,
      childColProps?: any,
      childRowProps?: any,
    ): IRowRender[] => {
      return get(data, targetViewName)?.map((rowData: any, i: number) => {
        return rowBuilder({
          data: rowData,
          index: i,
          colProps: childColProps,
          rowProps: childRowProps,
          parent: rowBuilderInternal,
          viewName: targetViewName,
        });
      });
    },
    getPath,
    render: (column, context, variables) => {
      const value = get(data, column.accessor);

      const showAs = column.showAs ?? column.props?.showAs;
      const val = get(data, column.accessor);

      if (showAs) {
        return (
          <ComponentRender
            {...showAs}
            variables={{
              ...variables,
              ...data,
              value: val,
              index,
              value,
            }}
            name={`${getPath()}.${column.name}`}
            context={context}
          />
        );
      }

      if (
        typeof value === 'boolean' ||
        column.type === 'checkbox' ||
        column.type === 'boolean'
      ) {
        return isTrue(value) ? (
          <GiCheckMark color={'#4a4a4a'} />
        ) : (
          <CgClose color={'#C8CBCB'} />
        );
      }

      if (column.enableEdit && column.editor) {
        const disabled = parseTemplate(column.editor.disabled, {
          ...data,
          data: dataGridStore,
          index,
        });
        const fieldProps = {
          component: 'field',
          name: column.accessor,
          value,
          props: {
            id: `${getPath()}.${column.accessor}`,
            prefix: `${getPath()}.`,
            ...column.editor,
            disabled,
            formikContext,
          },
          context,
          variables: { ...variables, index },
        };

        return <FieldComponent {...fieldProps} />;
      }

      // ifObject
      if (value && typeof value === 'object') {
        return JSON.stringify(value);
      }
      if (column.type?.toLowerCase().startsWith('date')) {
        if (value === null || value === undefined) return '';
        if (column.type?.toLowerCase().includes('time'))
          return new Date(value).toLocaleString();
        return new Date(value).toLocaleDateString();
      }

      return value;
    },
  };

  return rowBuilderInternal;
};
