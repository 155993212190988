import { Dialog } from '../../common/components/dialog/dialog.component';
import { RateEdit } from './rate-edit.component';
import { RateDto, RateType } from '../../../models/data.models';

export type RateDialogProperties = {
  rateId?: number | null;
  title: string;
  rateType: RateType;
};

export class RateDialog extends Dialog<RateDialogProperties> {
  constructor(props: RateDialogProperties) {
    super(props);
    this.onRateSaved = this.onRateSaved.bind(this);
  }

  onRateSaved = (rate: RateDto) => {
    this.close(rate);
  };

  renderContent(): any {
    const { rateId, rateType } = this.props as RateDialogProperties;
    return (
      <RateEdit
        rateId={rateId}
        rateType={rateType}
        onRateCreated={this.onRateSaved}
        onRateUpdated={this.onRateSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
