import { v4 } from 'uuid';
import {
  OrderCommodityDto,
  LinkDto,
  LinkResourceBaseDto,
  OrderCommodityDtoPagedResult,
  CreateOrderCommodityCommandValues,
  UpdateOrderCommodityCommandValues,
  OrderTypes,
  OrderDto,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { addMessage } from '../common/messages.store';
import { ListParams } from '../common/models/ListParams';

export const GET_ORDERCOMMODITIES_LINK_KEY = 'get-ordercommodities';
export const CREATE_ORDERCOMMODITY_LINK_KEY = 'create-ordercommodity';
export const GET_ORDERCOMMODITY_LINK_KEY = 'get-ordercommodity';
export const UPDATE_ORDERCOMMODITY_LINK_KEY = 'update-ordercommodity';
export const DELETE_ORDERCOMMODITY_LINK_KEY = 'delete-ordercommodity';
export const GET_COMMODITY_ORDER_LINK_KEY = 'get-commodity-order';

export interface GetOrderCommodityParams {
  commodityId: number;
}

export const getOrderCommodityRequest = async (
  resource: LinkResourceBaseDto | null,
  { commodityId }: GetOrderCommodityParams,
): Promise<OrderCommodityDto> => {
  if (resource && commodityId) {
    const getOrderCommodityLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERCOMMODITY_LINK_KEY,
    );
    if (getOrderCommodityLink) {
      const result = await execLink(getOrderCommodityLink, { commodityId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERCOMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrderCommoditiesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderCommodityDtoPagedResult> => {
  if (resource) {
    const getOrderCommoditiesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERCOMMODITIES_LINK_KEY,
    );
    if (getOrderCommoditiesListLink) {
      const result = await execLink(getOrderCommoditiesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERCOMMODITIES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createOrderCommodityRequest = async (
  resource: LinkResourceBaseDto,
  orderCommodity: CreateOrderCommodityCommandValues,
) => {
  const createOrderCommodityLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORDERCOMMODITY_LINK_KEY,
  );
  if (createOrderCommodityLink) {
    const result = await execLink(createOrderCommodityLink, orderCommodity);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ORDERCOMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateOrderCommodityRequest = async (
  resource: LinkResourceBaseDto,
  orderCommodity: UpdateOrderCommodityCommandValues,
) => {
  const updateOrderCommodityLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORDERCOMMODITY_LINK_KEY,
  );
  if (updateOrderCommodityLink) {
    const result = await execLink(updateOrderCommodityLink, orderCommodity);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ORDERCOMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteOrderCommodityRequest = async (
  orderCommodity: OrderCommodityDto,
) => {
  const deleteOrderCommodityLink = orderCommodity.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORDERCOMMODITY_LINK_KEY,
  );
  if (deleteOrderCommodityLink) {
    const result = await execLink(deleteOrderCommodityLink, orderCommodity);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ORDERCOMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export interface GetCommodityOrderQueryValues {
  commodityId: number;
  orderType: OrderTypes;
}

export const getCommodityOrderRequest = async (
  resource: LinkResourceBaseDto,
  values: GetCommodityOrderQueryValues,
): Promise<OrderDto> => {
  const updateCommoditiesLink = resource.links?.find(
    (x: LinkDto) => x.rel === GET_COMMODITY_ORDER_LINK_KEY,
  );
  if (updateCommoditiesLink) {
    const result = await execLink(updateCommoditiesLink, values);
    return result.data;
  }
  addMessage({
    message: `Action ${GET_COMMODITY_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
