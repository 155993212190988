import {
  UserSettingDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateUserSettingCommandValues,
  UpdateUserSettingCommandValues,
  Column,
  UpdateDefaultViewCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createUserSettingRequest,
  getUserSettingsListRequest,
  getUserSettingRequest,
  deleteUserSettingRequest,
  updateUserSettingRequest,
  GetUserSettingParams,
  updateDefaultViewRequest,
} from './userSettings.service';

type UserSettingsStoreState = {
  links: LinkDto[];
  userSettingColumns: Column[];
};

export const updateUserSettingsColumns = createEvent<Column[]>();

export const getUserSettings = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUserSettingsListRequest(currentOrganization, params);
});

export const createUserSetting = createEffect(
  (userSettingData: UserSettingDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createUserSettingCommand: CreateUserSettingCommandValues = {
      ...userSettingData,
    };

    return createUserSettingRequest(
      currentOrganization!,
      createUserSettingCommand,
    );
  },
);

export const getUserSetting = createEffect(
  (userSettingParams: GetUserSettingParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getUserSettingRequest(
      currentOrganization as LinkResourceBaseDto,
      userSettingParams,
    );
  },
);

export const updateUserSetting = createEffect((userSetting: UserSettingDto) => {
  const updateUserSettingCommand: UpdateUserSettingCommandValues = {
    ...userSetting,
  };
  return updateUserSettingRequest(userSetting, updateUserSettingCommand);
});

export const updateDefaultView = createEffect(
  (updateDefaultViewData: UpdateDefaultViewCommandValues) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const updateDefaultViewCommand: UpdateDefaultViewCommandValues = {
      ...updateDefaultViewData,
    };

    return updateDefaultViewRequest(
      currentOrganization as LinkResourceBaseDto,
      updateDefaultViewCommand,
    );
  },
);

export const deleteUserSetting = createEffect((userSetting: UserSettingDto) => {
  return deleteUserSettingRequest(userSetting);
});

const defaultState: UserSettingsStoreState = {
  links: [],
  userSettingColumns: [
    { name: 'userSettingId', visible: true, title: 'User Setting Id' },
    { name: 'created', visible: true, title: 'Created' },
    { name: 'createdBy', visible: true, title: 'Created By' },
    { name: 'lastModified', visible: true, title: 'Last Modified' },
    { name: 'lastModifiedBy', visible: true, title: 'Last Modified By' },
    { name: 'name', visible: true, title: 'Name' },
    { name: 'organizationId', visible: true, title: 'Organization Id' },
    { name: 'settings', visible: true, title: 'Settings' },
    { name: 'userId', visible: true, title: 'User Id' },
  ],
};

export const userSettingStore: Store<UserSettingsStoreState> = createStore(
  defaultState,
).on(updateUserSettingsColumns, (state, payload) => {
  return { ...state, userSettingColumns: payload };
});
