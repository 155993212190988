import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  PARCEL_SHIPMENT_CREATE_PATH,
  PARCEL_SHIPMENT_LIST_PATH,
} from '../parcelShipment.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../../orders/orders.service';
import { userHas } from '../../auth/auth.store';
import { ParcelShipmentEdit } from '../components/parcelShipment-edit.component';

export type ParcelShipmentScreenProps = { pageTitle: string; group: string };

export const ParcelShipmentScreen = ({ pageTitle, group }) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, parcelShipmentId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + PARCEL_SHIPMENT_CREATE_PATH, {
    organizationId,
  });

  const onParcelShipmentCreated = () => {
    const path = generatePath(INTERNAL_PATH + PARCEL_SHIPMENT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'parcelShipment-created',
      type: 'success',
      autoHide: true,
      message: 'Parcel Shipment successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onParcelShipmentUpdated = () => {
    const path = generatePath(INTERNAL_PATH + PARCEL_SHIPMENT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'parcelShipment-updated',
      type: 'success',
      autoHide: true,
      message: 'Parcel Shipment successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + PARCEL_SHIPMENT_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Parcel Shipments'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ParcelShipmentEdit
        orderId={parcelShipmentId}
        onParcelShipmentUpdated={onParcelShipmentUpdated}
        onParcelShipmentCreated={onParcelShipmentCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
