import React, { useMemo, useCallback } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetJobsQuery } from '../../../models/data.models';
import { JOB_CREATE_PATH, JOB_EDIT_PATH, JOBS_LIST_PATH } from '../jobs.route';
import { JobsList } from '../components/jobs-list.component';
import { CREATE_JOB_LINK_KEY, GetJobParams } from '../jobs.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type JobsListScreenProps = { pageTitle: string; group: string };

const generateHistoryPath = (
  organizationId: string,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    viewName?: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + JOBS_LIST_PATH, {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

export const JobsListScreen = ({ pageTitle, group }: JobsListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    filter = '',
    sort = '',
    search = '',
    view = '',
  } = useQuery();

  const navigate = useNavigate();

  const createPath = useMemo(() => {
    if (userHas(CREATE_JOB_LINK_KEY)) {
      return generatePath(INTERNAL_PATH + JOB_CREATE_PATH, {
        organizationId,
      });
    } else {
      return null;
    }
  }, [organizationId]);

  const goToDetails = useCallback(
    (jobParams: GetJobParams) => {
      const path = generatePath(INTERNAL_PATH + JOB_EDIT_PATH, {
        organizationId,
        ...jobParams,
      });
      navigate(path);
    },
    [organizationId, navigate],
  );

  const goToCreate = useCallback(() => {
    if (createPath) navigate(createPath);
  }, [createPath]);

  const onDelete = useCallback(() => {
    navigate(navigate.length - 1);
  }, [navigate]);

  const onQueryChange = useCallback(
    (
      newParams: Partial<{
        offset: number;
        limit: number;
        sort: string;
        filter: string;
        search: string;
        view: string;
      }>,
    ) => {
      const params = { offset, limit, sort, filter, search, view };
      const mergedParams = { ...params, ...newParams };
      const newPath = generateHistoryPath(organizationId, mergedParams);
      navigate(newPath);
    },
    [offset, limit, sort, filter, search, view, organizationId, navigate],
  );

  const onPageChanged = useCallback(
    (page: number) => onQueryChange({ offset: page * limit }),
    [onQueryChange, limit],
  );

  const onLimitChanged = useCallback(
    (l: number) => onQueryChange({ limit: l }),
    [onQueryChange],
  );

  const onSortChanged = useCallback((s: string) => onQueryChange({ sort: s }), [
    onQueryChange,
  ]);

  const onFilterChanged = useCallback(
    (f: string) => onQueryChange({ filter: f, offset: 0 }),
    [onQueryChange],
  );

  const onSearchChanged = useCallback(
    (s: string) => onQueryChange({ search: s, offset: 0 }),
    [onQueryChange],
  );

  const onViewChanged = useCallback(
    (viewName: string) => onQueryChange({ view: viewName }),
    [onQueryChange],
  );

  return (
    <InternalLayout group={group} title={'Jobs'} createPath={createPath}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <JobsList
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        search={search}
        goToDetails={goToDetails}
        goToCreate={goToCreate}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSortChanged}
        onFilter={onFilterChanged}
        onLimitChanged={onLimitChanged}
        onSearch={onSearchChanged}
        onViewChanged={onViewChanged}
      />
    </InternalLayout>
  );
};
