import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectAccountingAccountPaginate } from '../../accountingAccounts/components/accountingAccount-react-select.component';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';

export const GenerateInvoiceForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

GenerateInvoiceForm.InvoiceDate = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
  id = 'invoiceDate',
  label = 'Invoice Date',
  placeholder = 'Invoice Date',
  required = true,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={id}
      label={label}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

GenerateInvoiceForm.TransactionNumber = ({
  className,
  style,
  size,
  disabled,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'transactionNumber'}
      label={'Transaction Number / Invoice Number'}
      placeholder={'Transaction Number'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};

GenerateInvoiceForm.AccountingAccountSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  id = 'accountId',
  nameId = 'accountName',
  header = 'Account',
  required = true,
  placeholder,
  readonly,
  disabled,
  sort = 'accountName',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectAccountingAccountPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      showAccountType={true}
      onChangeAccountingAccount={onChange}
    />
  );
};

GenerateInvoiceForm.DivisionId = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isClearable={false}
      isDisabled={disabled}
    />
  );
};
