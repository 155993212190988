import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
  TransportationMethod,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { deleteOrder, getOrders } from '../../orders/orders.store';
import {
  getParcelShipmentsColumns,
  parcelShipmentsStore,
} from '../parcelShipments.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ORDER_LINK_KEY,
  DELETE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../../orders/orders.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PARCEL_SHIPMENT_CREATE_PATH } from '../parcelShipment.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { ParcelShipmentDialog } from './parcelShipment.dialog';
import { userHas } from '../../auth/auth.store';
import { PurchaseGrid } from '../../common/components/grid/purchase-grid.component';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import {
  hidenFilter,
  parseParcelShipmentsQuery,
} from '../../../utils/query.utils';
import { getModeOfTransportationsFx } from '../../modeOfTransportations/modeOfTransportations.store';
import { HiPlus } from 'react-icons/hi';
import { ConsolidatedShipmentCreateDialog } from '../../consolidatedShipments/components/consolidatedShipmentCreate.dialog';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import lodash from 'lodash';
import {
  CONSOLIDATED_SHIPMENTS_PATH,
  CONSOLIDATED_SHIPMENT_EDIT_PATH,
} from '../../consolidatedShipments/consolidatedShipments.route';

export type ParcelShipmentsListProps = {
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  summary?: boolean;
  goToDetails?: (parcelShipmentParams: GetOrderParams) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (parcelShipment: OrderDto, parcelShipmentId: string) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  enableScanner?: boolean;
};

export const ParcelShipmentsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  summary = true,
  onDelete = () => {},
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch = () => {},
  enableScanner = false,
}: ParcelShipmentsListProps) => {
  // @ts-ignore
  const { organizationId } = useParams();

  const [
    parcelShipments,
    setParcelShipments,
  ] = useState<OrderDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  useEffect(() => {
    getParcelShipmentsColumns().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = urlSearchParams.get('filter');
  const queryValues = parseParcelShipmentsQuery(searchParams ?? '');

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const fetchOrders = async (additionalFilter: string) => {
    const parcelShipmentsData = await getOrders({
      offset,
      limit,
      sort,
      filter: additionalFilter,
      search,
      summary,
    });

    const modeOfTransportations = await getModeOfTransportationsFx({
      limit: 1000,
    });

    const parcelShipmentDtoResult: OrderDtoForListPagedResult = {
      limit: parcelShipmentsData?.limit,
      offset: parcelShipmentsData?.offset,
      links: parcelShipmentsData?.links,
      totalCount: parcelShipmentsData?.totalCount,
      items: parcelShipmentsData?.items?.map<OrderForListDto>((item) => {
        const resultParcelShipment: OrderForListDto = item;
        if (item?.customValues) {
          Object.keys(item.customValues).forEach((customFieldInternalName) => {
            if (!resultParcelShipment.hasOwnProperty(customFieldInternalName))
              resultParcelShipment[customFieldInternalName] =
                item.customValues[customFieldInternalName];
          });
        }
        const modeOfTransportation = modeOfTransportations.items?.find(
          (el) =>
            el.modeOfTransportationId ===
            parseInt(item?.modeOfTransportationId as any),
        );
        return {
          ...item,
          transportationMethod: modeOfTransportation?.transportationMethod,
        };
      }),
      summary: parcelShipmentsData.summary,
    };
    setParcelShipments(parcelShipmentDtoResult);
    setGetDataSuccess(true);
  };

  useEffect(() => {
    getParcelShipmentsData();
  }, [offset, limit, sort, filter, search, summary]);

  const getParcelShipmentsData = () => {
    const additionalFilter = hidenFilter(
      `orderType:${OrderTypes.ParcelShipment}`,
      filter,
    );

    fetchOrders(additionalFilter);
  };

  const {
    parcelShipmentColumns: columns,
    defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(parcelShipmentsStore);

  const onDeleteParcelShipment = async (parcelShipment: OrderDto) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${parcelShipment.orderNumber} ParcelShipment`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteOrder(parcelShipment);
        onDelete();
        getParcelShipmentsData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditParcelShipment = (parcelShipment: OrderDto) => {
    goToDetails({ orderId: parcelShipment.orderId });
  };

  const navigate = useNavigate();

  const onCreateNewParcelShipment = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(
      INTERNAL_PATH + PARCEL_SHIPMENT_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    navigate(createPath);
  };

  const onChangeItem = (data?: any[]) => {
    setParcelShipments((parcelShipmentsDto) => {
      parcelShipmentsDto.items = data;
      return { ...parcelShipmentsDto };
    });
  };

  const onParcelShipmentSelect = async (parcelShipment, orderId) => {
    onSelect?.(parcelShipment, orderId);
    if (parcelShipment?.orderId) {
      const selectedParcelShipment = await showDialog({
        dialog: ParcelShipmentDialog,
        props: {
          className: 'order-modal',
          title: 'Update Parcel Shipment',
          orderId: parcelShipment?.orderId,
        },
      });
      if (selectedParcelShipment !== null) {
        getParcelShipmentsData();
      }
    }
  };

  const onConsolidatedShipmentCreated = (
    orderId: number,
    orderType: OrderTypes,
  ) => {
    const path = generatePath(
      CONSOLIDATED_SHIPMENTS_PATH + CONSOLIDATED_SHIPMENT_EDIT_PATH,
      {
        organizationId,
        orderType,
        orderId,
      },
    );
    navigate(path);
  };

  const openConsolidatedShipmentDialog = async (values: any) => {
    let defaultMode;
    try {
      const result = await getModeOfTransportationsFx({
        limit: 100,
        filter: `TransportationMethod: ${
          values.orderType === OrderTypes.AirShipmentOrder
            ? TransportationMethod.Air
            : TransportationMethod.Ocean
        }`,
      });
      if (result && result?.items?.length > 0) {
        const stateMode = lodash.get(
          values,
          'customValues.modeOfTransportationId',
        );
        if (
          stateMode &&
          result.items.some((x) => x.modeOfTransportationId === stateMode)
        ) {
          defaultMode = stateMode;
        } else {
          defaultMode = result?.items?.[0]?.modeOfTransportationId;
        }
      }
    } catch (error) {
      addMessage({
        message: error?.Message || error,
        id: v4(),
        type: 'danger',
      });
    }

    showDialog({
      dialog: ConsolidatedShipmentCreateDialog,
      props: {
        title: 'Consolidated Shipment',
        orderId: 0,
        onConsolidatedShipmentCreated: onConsolidatedShipmentCreated,
        defaultState: {
          countries: lodash.get(
            values,
            'OrderEntities.ContactAddress.CountryCode',
          ),
          divisionId: values?.divisionId,
          modeOfTransportationId: defaultMode,
          orderType: values?.orderType,
          finalMileCarrierId: lodash.get(values, 'OrderEntities.ContactId'),
        },
      },
    });
  };

  useEffect(() => {
    setActionButtons([
      <div
        key={'parcel-create-consolidated'}
        className="dropdown dropdown-columns mr-3"
      >
        <div
          color="primary"
          className="btn btn-md btn-primary btn-button btn-block"
          data-toggle="dropdown"
        >
          <div className={'d-flex align-items-center'}>
            <HiPlus className={'mr-2'} />
            Create Consolidated Shipment
          </div>
          <span className="caret" />
        </div>
        <div
          className="dropdown-menu w-100"
          aria-labelledby="dropdownFilterButton"
        >
          <Button
            onClick={() =>
              openConsolidatedShipmentDialog({
                ...queryValues,
                orderType: OrderTypes.AirShipmentOrder,
              })
            }
            className="nav-link nav-icon-size-medium dropdown-item no-select inactive-link"
          >
            Air Shipment
          </Button>
          <Button
            onClick={() =>
              openConsolidatedShipmentDialog({
                ...queryValues,
                orderType: OrderTypes.OceanShipmentOrder,
              })
            }
            className="nav-link nav-icon-size-medium dropdown-item no-select inactive-link"
          >
            Ocean Shipment
          </Button>
        </div>
      </div>,
    ]);

    return () => clearActionButtons();
  }, [queryValues]);

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <PurchaseGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={['orderId']}
          data={parcelShipments?.items}
          offset={offset}
          limit={limit}
          total={parcelShipments?.totalCount}
          columns={columns}
          summary={parcelShipments?.summary}
          sort={sort}
          onDelete={
            userHas(
              DELETE_ORDER_LINK_KEY,
              !!parcelShipments?.items?.length &&
                parcelShipments?.items[0]?.links,
            )
              ? onDeleteParcelShipment
              : null
          }
          onSort={(field) => {
            onSort(field);
          }}
          search={search}
          onEdit={onEditParcelShipment}
          onPageChanged={onPageChanged}
          onLimitChanged={onLimitChanged}
          onViewChanged={onViewChanged}
          onSelect={onParcelShipmentSelect}
          onChangeItem={onChangeItem}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
          enableScanner={enableScanner}
          filterContextValues={{ orderType: OrderTypes.ParcelShipment }}
          entityType={OrderTypes.ParcelShipment}
        />
        {!search && !parcelShipments?.items?.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Parcel Shipments Found</h3>
            {userHas(CREATE_ORDER_LINK_KEY) && (
              <Button
                size={'lg'}
                color="primary"
                onClick={onCreateNewParcelShipment}
              >
                Create Parcel Shipment
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
