import React, { useEffect, useRef, useState } from 'react';
import {
  ContactDto,
  ContactType,
  EntityTypes,
  OrderEntityDto,
  OrderDto,
  TrackingEventDto,
} from '../../../../models/data.models';
import { Button } from '../../../common/components/button/button.component';
import { RateManagerForm } from './rate-manager.form';
import * as Yup from 'yup';
import { FormContext } from '../../../common/components/form/form.component';
import { ReactSelectItem } from '../../../../models/custom.models';
import { getOrder, updateOrder } from '../../orders.store';

export type RateManagerProps = {
  className?: string;
  orderDto?: OrderDto;
  onConfirm?: (rateData: RateType) => void;
  onCancel?: () => void;
  setTrackingEvents?: (value: React.SetStateAction<TrackingEventDto[]>) => void;
};

export type RateType = {
  shipperId?: string | number;
  consigneeId?: string | number;
  driverId?: string | number;
  carrierId?: string | number;
  orderPickupId?: number;
  orderDeliveryId?: number;
};

const getInitialState = () => {
  const initialState: RateType = {
    shipperId: null,
    consigneeId: null,
    driverId: null,
    carrierId: null,
  };
  return initialState;
};

const rateManagerSchema = Yup.object().shape({
  shipperId: Yup.string().required("Can't be blank").nullable(true),
  consigneeId: Yup.string().required("Can't be blank").nullable(true),
  carrierId: Yup.string().required("Can't be blank").nullable(true),
});

export const RateManagerComponent = ({
  orderDto,
  onConfirm,
  onCancel,
  setTrackingEvents,
}: RateManagerProps) => {
  const rateManagerFormRef = useRef<HTMLFormElement>();
  const [initialValues, setInitialValues] = useState<RateType>(
    getInitialState(),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shippers, setShippers] = useState<OrderEntityDto[]>(null);
  const [consignees, setConsignees] = useState<OrderEntityDto[]>(null);
  const [carriers, setCarriers] = useState<ContactDto[]>(null);
  const [drivers, setDrivers] = useState<ContactDto[]>(null);

  useEffect(() => {
    setIsLoading(true);
    updateOrder(orderDto).then(
      () => {
        getOrder({ orderId: orderDto.orderId }).then((order) => {
          const orderPickups = order?.orderEntities?.filter(
            (orderEntity) => orderEntity.entityType === EntityTypes.Shipper,
          );
          const orderDeliveries = order?.orderEntities?.filter(
            (orderEntity) => orderEntity.entityType === EntityTypes.Consignee,
          );
          setShippers(orderPickups);
          setConsignees(orderDeliveries);
          setCarriers(
            order.carriers.filter(
              (carrier) => carrier.contactType === ContactType.Carrier,
            ),
          );
          setDrivers(
            order.carriers.filter(
              (carrier) => carrier.contactType === ContactType.Driver,
            ),
          );
          setInitialValues((initialValues) => {
            initialValues.shipperId = orderPickups[0].contactId;
            initialValues.consigneeId = orderDeliveries[0].contactId;
            initialValues.carrierId = order.carriers.filter(
              (carrier) => carrier.contactType === ContactType.Carrier,
            )[0].contactId;
            return { ...initialValues };
          });
          setTrackingEvents(order.trackingEvents);
          setIsLoading(false);
        });
      },
      () => {},
    );
  }, []);

  const onSubmit = (data: RateType) => {
    data.orderPickupId = shippers.find(
      (shipper) => shipper.contactId == data.shipperId,
    ).orderEntityId;
    data.orderDeliveryId = consignees.find(
      (consignee) => consignee.contactId == data.consigneeId,
    ).orderEntityId;
    onConfirm(data);
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <div className={'rate-manager-form'}>
      <RateManagerForm
        id={'RateManager'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={rateManagerFormRef}
        validationSchema={rateManagerSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <div>
              <div className="row mb-3">
                <div className="col-6">
                  <RateManagerForm.ShipperSelect
                    options={shippers
                      ?.map(
                        (shipper): ReactSelectItem => {
                          return {
                            value: shipper.contactId?.toString(),
                            label: shipper.contactName,
                          };
                        },
                      )
                      .filter(
                        (option) =>
                          option.value !== null &&
                          option.value !== undefined &&
                          !isNaN(Number(option.value)),
                      )}
                    defaultValue={
                      shippers
                        ? {
                            value: shippers[0].contactId,
                            label: shippers[0].contactName,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-6">
                  <RateManagerForm.ConsigneeSelect
                    options={consignees
                      ?.map(
                        (consignee): ReactSelectItem => {
                          return {
                            value: consignee.contactId?.toString(),
                            label: consignee.contactName,
                          };
                        },
                      )
                      .filter(
                        (option) =>
                          option.value !== null &&
                          option.value !== undefined &&
                          !isNaN(Number(option.value)),
                      )}
                    defaultValue={
                      consignees
                        ? {
                            value: consignees[0].contactId,
                            label: consignees[0].contactName,
                          }
                        : null
                    }
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <RateManagerForm.CarrierSelect
                    options={carriers
                      ?.map(
                        (carrier): ReactSelectItem => {
                          return {
                            value: carrier.contactId?.toString(),
                            label: carrier.name,
                          };
                        },
                      )
                      .filter(
                        (option) =>
                          option.value !== null &&
                          option.value !== undefined &&
                          !isNaN(Number(option.value)),
                      )}
                    defaultValue={
                      carriers
                        ? {
                            value: carriers[0].contactId,
                            label: carriers[0].name,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-6">
                  <RateManagerForm.DriverSelect
                    options={drivers
                      ?.map(
                        (driver): ReactSelectItem => {
                          return {
                            value: driver.contactId?.toString(),
                            label: driver.name,
                          };
                        },
                      )
                      .filter(
                        (option) =>
                          option.value !== null &&
                          option.value !== undefined &&
                          !isNaN(Number(option.value)),
                      )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-block ml-auto"
                    name={'select'}
                  >
                    Confirm
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    onClick={onCancel}
                    type="button"
                    color="secondary"
                    className="btn-block ml-auto"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </FormContext.Consumer>
      </RateManagerForm>
    </div>
  );
};
