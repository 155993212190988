import React, { useEffect, useState } from 'react';
import {
  CommodityDto,
  PutAwayActivityStatuses,
  ScanningResultFrom,
  WarehouseLocationDto,
} from '../../../models/data.models';
import { CargoMovementForm } from '../components/cargoMovement.form';
import { Button } from '../../common/components/button/button.component';
import { updateCommodity } from '../../commodities/commodities.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { getWarehouseLocationsFx } from '../../warehouseLocations/warehouseLocations.store';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { dialogStore } from '../../common/dialog.store';
import { useStore } from 'effector-react';
import { getWarehouseLocationOptionValue } from '../../warehouseLocations/components/warehouseLocation-react-select.component';
import { ActivityCommodityDto } from '../../../models/custom.models';

export type PutAwayActivityProps = {
  setCommodity: any;
  setLastCommodity: any;
  setPutAwayStatus: (status: PutAwayActivityStatuses) => void;
  activityCommodities: CommodityDto[];
  setAtivityCommodities: any;
};

const LOCATION_NUMBER_PREFIX = 'L-';

export const PutAwayActivity = ({
  setCommodity,
  setLastCommodity,
  setPutAwayStatus,
  activityCommodities,
  setAtivityCommodities,
}: PutAwayActivityProps) => {
  const [enteredNumber, setEnteredNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    selectedLocation,
    setSelectedLocation,
  ] = useState<WarehouseLocationDto | null>(null);
  const [foundCommodity, setFoundCommodity] = useState<CommodityDto | null>(
    null,
  );
  const [warning, setWarning] = useState<string>('');

  const dialogStoreState = useStore(dialogStore);

  useScanner((scannerResult: ScanningResult) => {
    if (dialogStoreState.dialogs.length == 0) {
      catchScanResult(scannerResult);
    }
  });

  const catchScanResult = async (scannedResult: ScanningResult) => {
    if (scannedResult.from === ScanningResultFrom.Scanner) {
      if (!scannedResult.data) return;

      const scannedString = scannedResult.data.toUpperCase();
      if (scannedString.startsWith(LOCATION_NUMBER_PREFIX)) {
        const locationCode = scannedString.substring(
          LOCATION_NUMBER_PREFIX.length,
        );
        const result = await getWarehouseLocationsFx({
          offset: 0,
          limit: 1,
          filter: `Code:"${locationCode}"`,
        });

        if (result.items.length > 0) {
          setSelectedLocation(result.items[0]);
          setWarning('');
        } else {
          setSelectedLocation(null);
          setWarning('No location with specified code found');
        }
      } else {
        setEnteredNumber(scannedString);
      }
    }
  };

  // find commodity by tracking number,
  useEffect(() => {
    {
      if (enteredNumber) {
        const chosenCommodity = activityCommodities.find((x) =>
          x.commodityTrackingNumbers?.some(
            (t) => t.trackingNumber === enteredNumber,
          ),
        );
        if (chosenCommodity) {
          setCommodity(chosenCommodity);
          setFoundCommodity(chosenCommodity);
        } else {
          setCommodity(null);
          setFoundCommodity(null);
        }
      } else {
        setCommodity(null);
        setFoundCommodity(null);
      }
    }
  }, [enteredNumber, selectedLocation]);

  //change chosen commodity location and then change its status
  const onPutAway = () => {
    if (foundCommodity && selectedLocation) {
      setIsLoading(true);
      if (
        foundCommodity.warehouseLocationId ===
        selectedLocation.warehouseLocationId
      ) {
        const locationName = getWarehouseLocationOptionValue(selectedLocation);
        addMessage({
          message: `Package has already been assigned to this shelf - ${locationName}`,
          type: 'danger',
          id: v4(),
        });
        setCommodity(null);
        setFoundCommodity(null);
        setIsLoading(false);
        return;
      }

      foundCommodity.warehouseLocationId = selectedLocation.warehouseLocationId;
      updateCommodity(foundCommodity)
        .then(() => {
          if (
            (foundCommodity as ActivityCommodityDto).activityStatus ===
            PutAwayActivityStatuses.Completed
          ) {
            const prevLocationName = getWarehouseLocationOptionValue(
              foundCommodity.warehouseLocation,
            );
            const newLocationName = getWarehouseLocationOptionValue(
              selectedLocation,
            );
            addMessage({
              message: `Package has been replaced from the shelf ${prevLocationName} to ${newLocationName}`,
              type: 'info',
              id: v4(),
            });
          } else {
            setPutAwayStatus(PutAwayActivityStatuses.Completed);
          }
          setEnteredNumber('');
          setFoundCommodity(null);
          setLastCommodity({
            ...foundCommodity,
            warehouseLocation: selectedLocation,
          });
        })
        .catch((error) =>
          addMessage({
            message: error,
            type: 'danger',
            id: v4(),
          }),
        )
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      const trackingNumberInput = document.querySelector(
        '#orderNumber',
      ) as HTMLInputElement;
      trackingNumberInput?.focus();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (foundCommodity && selectedLocation && !isLoading) {
      onPutAway();
    }
  }, [foundCommodity, selectedLocation, isLoading]);

  return (
    <div className={'h-100'}>
      <div className="box h-100">
        <CargoMovementForm>
          <div className="row mb-3">
            <div className="col-12">
              <CargoMovementForm.Location
                onChange={(value) => {
                  setSelectedLocation(value);
                  setWarning('');
                }}
                defaultValue={selectedLocation}
                label={'Location'}
                barcode={true}
                setWarning={setWarning}
              />
              {warning && <span className="invalid-feedback">{warning}</span>}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <CargoMovementForm.PackageTrackingNumber
                onChange={(value) => {
                  setEnteredNumber(value);
                }}
                barcode={true}
                defaultValue={enteredNumber}
                disabled={!selectedLocation}
              />
            </div>
          </div>
        </CargoMovementForm>
        <div className={'w-100'}>
          <Button
            className={'w-100'}
            color={'primary'}
            outline={false}
            onClick={onPutAway}
            disabled={!foundCommodity || !selectedLocation || isLoading}
          >
            Put away
          </Button>
        </div>
      </div>
    </div>
  );
};
