import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetCustomFieldsQuery } from '../../../models/data.models';
import { CustomFieldsList } from '../components/customFields-list.component';
import {
  CREATE_CUSTOMFIELD_LINK_KEY,
  GetCustomFieldParams,
} from '../customFields.service';
import {
  CUSTOM_FIELDS_CREATE_PATH,
  CUSTOM_FIELDS_EDIT_PATH,
  CUSTOM_FIELDS_LIST_PATH,
} from '../customFields.route';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';
import {
  customFieldStore,
  getCustomFieldsDefaultColumnsFx,
} from '../customFields.store';
import { useStore } from 'effector-react';

export type CustomFieldsListScreenProps = { pageTitle: string; group: string };

export const CustomFieldsListScreen = ({
  pageTitle,
  group,
}: CustomFieldsListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(customFieldStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = ``,
    search = '',
    view = '',
  } = useQuery() as GetCustomFieldsQuery;
  const navigate = useNavigate();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + CUSTOM_FIELDS_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = ({ fieldId }: GetCustomFieldParams) => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_EDIT_PATH, {
      organizationId,
      fieldId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getCustomFieldsDefaultColumnsFx();
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}&view=${view}`,
    );
  };

  return (
    <InternalLayout
      group={group}
      title={'Custom Fields'}
      createPath={userHas(CREATE_CUSTOMFIELD_LINK_KEY) ? createPath() : null}
      createLabel={'Field'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CustomFieldsList
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onLimitChanged={onLimitChanged}
        onViewChanged={onViewChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
