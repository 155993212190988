import { ITableView } from './tableView';
import Dropdown from 'react-bootstrap/Dropdown';
import { localized } from '../layout/component-hooks';
import { useEffect, useState } from 'react';
import { IColumn } from './column';
import { queryGraphQlFx } from '../../../graphql/graphql.store';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { RxDotFilled } from "react-icons/rx";

interface IColumnSelectorProps {
  view: ITableView;
  className?: string;
  variables?: any;
  onAddVisibleColumn?: (viewName: string, column: IColumn) => void;
  onRemoveVisibleColumn?: (viewName: string, column: IColumn) => void;
  icon?: React.ReactNode;
  isFilter?: boolean;
}

const simpleTypes = [
  'text',
  'string',
  'number',
  'boolean',
  'date',
  'datetime',
  'rangedatetime',
  'checkbox',
  'enum',
  'select',
];

export const ColumnsSelector = (props: IColumnSelectorProps) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [entitiesFields, setEntitiesFields] = useState({} as any);
  const [filteredAndSortedColumns, setFilteredAndSortedColumns] = useState<
    IColumn[]
  >([]);

  useEffect(() => {
    if (!props.view) return;
    if (props.isFilter) {
      setSelectedColumns(
        props.view?.config.filterColumns?.map((column) => column.name) ?? [],
      );
    } else {
      setSelectedColumns(
        props.view?.config.viewColumns?.map((column) => column.name) ?? [],
      );
    }
  }, [props.view]);

  useEffect(() => {
    if (!props.view) return;
    setEntitiesFields((state) => {
      const path = [{ name: 'root', label: 'root' }];
      return {
        ...state,
        path,
        currentPath: getPathString(path),
        ['root']:
          props.view?.config.availableColumns?.filter((col) => {
            if (props.isFilter === true) {
              // only allow filterable fields
              return col.props?.allowFilter !== false;
            }
            // only allow selectable fields
            return col.props?.allowSelect !== false;
          }) ?? [],
      };
    });
  }, [props.view?.config.availableColumns]);

  const fetchEntityFields = async (column: IColumn, path: IColumn[]) => {
    try {
      const res = await queryGraphQlFx({
        query: `
        query entityFields($organizationId: Int!, $entityName: String!) {
          entityFields(organizationId: $organizationId, entityName: $entityName, take: 1000) {
              items{
                name
                fieldDefinition
              }
          }
      }
      `,
        variables: {
          ...props.variables,
          entityName: column.type,
        },
      });

      const data =
        res.entityFields.items
          ?.filter((f: any) => {
            if (props.isFilter === true) {
              // only allow filterable fields
              return f.fieldDefinition?.props?.allowFilter !== false;
            }
            // only allow selectable fields
            return f.fieldDefinition?.props?.allowSelect !== false;
          })
          .map((f: any) => ({
            label: f.fieldDefinition.displayName ?? f.name,
            type: f.fieldDefinition.fieldType,
            ...f.fieldDefinition,
            props: {
              ...f.fieldDefinition?.props,
              allowOrderBy:
                column?.props?.allowOrderBy === undefined
                  ? f.fieldDefinition.allowOrderBy
                  : column.props.allowOrderBy,
            },
          })) ?? [];

      setEntitiesFields((state) => {
        return {
          ...state,
          path,
          currentPath: getPathString(path),
          [getPathString(path)]: data,
        };
      });

      return data;
    } catch (e) {
      setEntitiesFields((state) => {
        return {
          ...state,
          path,
          currentPath: getPathString(path),
          [getPathString(path)]: [],
        };
      });
    }

    return [];
  };

  const isEntityColumn = (column: IColumn) => {
    return !simpleTypes.includes(column.type);
  };

  useEffect(() => {
    if (entitiesFields)
      setFilteredAndSortedColumns(
        entitiesFields[entitiesFields.currentPath]
          ?.filter((column) =>
            localized(column.label)
              ?.toLowerCase()
              ?.includes(filterText?.toLowerCase()),
          )
          .filter((column) =>
            !props.isFilter ? column.type !== 'select' : true,
          )
          .sort((a, b) => {
            // const aSelected = selectedColumns.includes(a.name);
            // const bSelected = selectedColumns.includes(b.name);
            // if (aSelected && !bSelected) return -1;
            // if (!aSelected && bSelected) return 1;
            return localized(a.label).localeCompare(localized(b.label));
          }) ?? [],
      );
  }, [entitiesFields, filterText]);

  const setPath = (path: IColumn[]) => {
    setEntitiesFields((state) => {
      return {
        ...state,
        path,
        currentPath: getPathString(path),
      };
    });
  };

  const getPathString = (columns = [], includeRoot = true) => {
    return columns
      .filter((column) => includeRoot || column.name !== 'root')
      .map((column) => column.name)
      .join('.');
  };

  const setFieldPath = (fieldName: string, entityType: string) => {
    const colDef = entitiesFields[getPathString(entitiesFields.path)].find(
      (col) => col.name === fieldName,
    );

    if (!fieldName) {
      // reset to root
      setEntitiesFields((state) => {
        return {
          ...state,
          currentPath: 'root',
          path: [{ name: 'root', label: 'root' }],
        };
      });
    } else {
      const path = [...entitiesFields.path, colDef];
      if (entitiesFields[getPathString(path)]) {
        setEntitiesFields((state) => {
          return {
            ...state,
            currentPath: getPathString(path),
            path,
          };
        });
      } else {
        fetchEntityFields(colDef, path);
      }
    }
  };

  const getColumnPath = (column: IColumn) => {
    return entitiesFields.path.length === 1
      ? column.name
      : getPathString(entitiesFields.path, false) + '.' + column.name;
  };

  const getColumnPrefix = () => {
    return entitiesFields.path.length === 1
      ? ''
      : entitiesFields.path[entitiesFields.path.length - 1].label;
  };

  const handleCheckboxChange = (selectedColumn: IColumn) => {
    const pathName = getColumnPath(selectedColumn);

    if (isEntityColumn(selectedColumn)) {
      setFieldPath(selectedColumn.name, selectedColumn.type);
      return;
    }

    setSelectedColumns((prevSelected) => {
      const isSelected = prevSelected.includes(pathName);

      const updatedSelected = isSelected
        ? prevSelected.filter((column) => column !== pathName)
        : [...prevSelected, pathName];

      if (isSelected && props.onRemoveVisibleColumn) {
        props.onRemoveVisibleColumn(props.view.name, {
          name: pathName,
        });
      } else if (props.onAddVisibleColumn) {
        const columnPrefix = localized(getColumnPrefix());
        props.onAddVisibleColumn(props.view.name, {
          ...selectedColumn,
          name: pathName,
          props: {
            ...selectedColumn.props,
            filter: {
              ...selectedColumn.props?.filter,
              fieldName: pathName,
            },
          },
          label: (columnPrefix + ' ' + localized(selectedColumn.label)).trim(),
        });
      }

      return updatedSelected;
    });
  };

  const handleLabelClick = (column: IColumn) => {
    handleCheckboxChange(column);
  };

  const handleFilterTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilterText(event.target.value);
  };

  return (
    <Dropdown className={props.className}>
      <Dropdown.Toggle
        id="dropdownColumnsButton"
        className="pointer btn-toggle"
        variant="light"
      >
        {props.icon}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: 'max-content', maxWidth: 'unset' }}>
        {entitiesFields.path?.length > 1 && (
          <div className="px-1">
            <a
              className="btn-toggle-text pointer"
              onClick={() => setPath([{ name: 'root', label: 'root' }])}
            >
              <FiChevronLeft size={16} />
            </a>
            {entitiesFields.path?.slice(1).map((entity, index) => (
              <>
                {index > 0 && (
                  <span className="btn-toggle-text pointer">/</span>
                )}
                <a
                  key={entity}
                  className="btn-toggle-text mx-2 pointer"
                  onClick={() =>
                    setPath(entitiesFields.path.slice(0, index + 2))
                  }
                >
                  {localized(entity.label)}
                </a>
              </>
            ))}
          </div>
        )}
        <div className="p-2">
          <input
            type="text"
            placeholder="Filter columns"
            className="form-control"
            value={filterText}
            onChange={handleFilterTextChange}
          />
        </div>
        <div
          className="scrollbar"
          style={{
            height: 'max-content',
            maxHeight: '400px',
          }}
        >
          {filteredAndSortedColumns?.map((column) => (
            <div
              key={column.name}
              className="dropdown-item pointer hover-bg"
              onClick={() => handleLabelClick(column)}
            >
              <div className="row justify-content-between align-items-center">
                <div
                  className="pr-4 btn-toggle-text"
                  style={{ userSelect: 'none' }}
                >
                  {localized(column.label)}
                </div>
                <div className={'d-flex align-items-center'}>
                  {isEntityColumn(column) && (
                    <>
                      {selectedColumns.some((x) =>
                        x.startsWith(`${getColumnPath(column)}.`),
                      ) && <RxDotFilled size={14} color="#066ffc" />}
                      <FiChevronRight size={16} className="ml-1" />
                    </>
                  )}
                  {!isEntityColumn(column) && (
                    <input
                      type="checkbox"
                      checked={selectedColumns.includes(getColumnPath(column))}
                      onChange={(e) => e.stopPropagation()}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
