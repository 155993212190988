import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputProps,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { ConfirmPassword } from '../../common/components/confirm-password/confirm-password.component';

export const UserForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

UserForm.Password = ({
  className,
  style,
  size,
  updatingCurrentPassword = false,
}: InputProps) => {
  return (
    <ConfirmPassword
      name={'password'}
      label={updatingCurrentPassword ? 'New Password' : 'Password'}
      required={true}
      className={className}
      style={style}
      size={size}
      updatingCurrentPassword={updatingCurrentPassword}
    />
  );
};

UserForm.Inactive = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'lockoutEnabled'}
      label={'Inactive'}
      placeholder={'Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

UserForm.Id = ({ className, style, size, disabled }: InputPublicProps) => {
  return (
    <Input
      name={'id'}
      label={'Id'}
      placeholder={'Id'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.AccessFailedCount = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'accessFailedCount'}
      label={'Access Failed Count'}
      placeholder={'Access Failed Count'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.ConcurrencyStamp = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'concurrencyStamp'}
      label={'Concurrency Stamp'}
      placeholder={'Concurrency Stamp'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.Email = ({ className, style, size, disabled }: InputPublicProps) => {
  return (
    <Input
      name={'email'}
      label={'Email'}
      placeholder={'Email'}
      required={true}
      type={'email'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      readonly={'readonly'}
      onFocus={(e) => {
        e.target.removeAttribute('readonly');
      }}
    />
  );
};
UserForm.EmailConfirmed = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'emailConfirmed'}
      label={'Email Confirmed'}
      placeholder={'Email Confirmed'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
UserForm.FirstName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'firstName'}
      label={'First Name'}
      placeholder={'First Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.LastName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'lastName'}
      label={'Last Name'}
      placeholder={'Last Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.LockoutEnabled = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'lockoutEnabled'}
      label={'Lockout Enabled'}
      placeholder={'Lockout Enabled'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
UserForm.LockoutEnd = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'lockoutEnd'}
      label={'Lockout End'}
      placeholder={'Lockout End'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.NormalizedEmail = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'normalizedEmail'}
      label={'Normalized Email'}
      placeholder={'Normalized Email'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.NormalizedUserName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'normalizedUserName'}
      label={'Normalized User Name'}
      placeholder={'Normalized User Name'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.OneTimeAccessCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'oneTimeAccessCode'}
      label={'One Time Access Code'}
      placeholder={'One Time Access Code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.OneTimeAccessCodeExpires = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'oneTimeAccessCodeExpires'}
      label={'One Time Access Code Expires'}
      placeholder={'One Time Access Code Expires'}
      required={false}
      type={'date'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.PasswordHash = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'passwordHash'}
      label={'Password Hash'}
      placeholder={'Password Hash'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.PhoneNumber = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'phoneNumber'}
      label={'Phone Number'}
      placeholder={'Phone Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.PhoneNumberConfirmed = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'phoneNumberConfirmed'}
      label={'Phone Number Confirmed'}
      placeholder={'Phone Number Confirmed'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
UserForm.SecurityStamp = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'securityStamp'}
      label={'Security Stamp'}
      placeholder={'Security Stamp'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
UserForm.TwoFactorEnabled = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'twoFactorEnabled'}
      label={'Two Factor Enabled'}
      placeholder={'Two Factor Enabled'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
UserForm.UserName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'userName'}
      label={'User Name'}
      placeholder={'User Name'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      readonly={'readonly'}
      onFocus={(e) => {
        e.target.removeAttribute('readonly');
      }}
    />
  );
};
