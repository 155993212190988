import React, { CSSProperties, useEffect, useState } from 'react';
import { Field } from 'formik';

export type CheckBoxGroupProps = {
  label: string;
  name: string;
  value: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  required?: boolean;
  defaultChecked?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (value: boolean) => void;
};

export const CheckboxGroup = ({
  label,
  name,
  id = null,
  disabled = false,
  size = 'md',
  className = '',
  style = {},
  onClick = () => {},
  onChange = null,
  required,
  value,
}: CheckBoxGroupProps) => {
  return (
    <div className={`form-group ${className}`} style={style}>
      <div className="custom-control custom-checkbox mb-2" onClick={onClick}>
        <Field
          type="checkbox"
          disabled={disabled}
          id={id ? id : name}
          name={name}
          required={required}
        >
          {({ field }: any) => {
            const isChecked = (field.value as Array<string>)?.includes(value);
            return (
              <input
                {...field}
                required={required}
                id={id ? id : name}
                name={name}
                value={value}
                disabled={disabled}
                checked={isChecked}
                className="custom-control-input"
                type="checkbox"
                {...(!onChange && onChange)}
              />
            );
          }}
        </Field>
        <label
          className={`custom-control-label font-size-${size} text-muted`}
          htmlFor={id ? id : name}
        >
          {label}
        </label>
      </div>
    </div>
  );
};
