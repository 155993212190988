import { createEvent, createStore, Store } from 'effector';
import { ContactDto } from '../../../models/data.models';

type ContactReactSelectStore = {
  clearLoadOptionsCache: boolean;
  lastEditedContact: ContactDto;
};

export const clearContactsLoadOptionsCache = createEvent();
export const updateLastEditedContact = createEvent<ContactDto>();

const defaultState: ContactReactSelectStore = {
  clearLoadOptionsCache: false,
  lastEditedContact: null,
};

export const contactReactSelectStore: Store<ContactReactSelectStore> = createStore(
  defaultState,
)
  .on(clearContactsLoadOptionsCache, (state, payload) => {
    return { ...state, clearLoadOptionsCache: !state.clearLoadOptionsCache };
  })
  .on(updateLastEditedContact, (state, payload) => {
    return { ...state, lastEditedContact: payload };
  });
