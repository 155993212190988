import React, { useEffect, useState } from 'react';
import { EventDefinitionDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import {
  getEventDefinitionsFx,
  getEventDefinitionFx,
} from '../eventDefinitions.store';
import { v4 as uuidv4 } from 'uuid';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import {
  CREATE_EVENTDEFINITION_LINK_KEY,
  EVENTDEFINITION_ENTITY_NAME,
  GET_EVENTDEFINITION_LINK_KEY,
} from '../eventDefinitions.service';
import { showDialog } from '../../common/dialog.store';
import { EventDefinitionDialog } from './eventDefinition.dialog';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export const SelectEventDefinitionPaginate = ({
  id = 'eventDefinitionId' + uuidv4(),
  nameId = 'eventName' + uuidv4(),
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeEventDefinition = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Event Definition',
  getOptionValue = (option) => option?.eventDefinitionId,
  getOptionLabel = (option) => `${option?.eventName}`,
}): JSX.Element => {
  const formikContext = useFormikContext();
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const [context, setContext] = useState(null);
  useEffect(() => {
    setContext(useContext);
  }, [useContext]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseEventDefinitionDtoPagedResult: EventDefinitionDtoPagedResult = await getEventDefinitionsFx(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseEventDefinitionDtoPagedResult.items,
      hasMore: responseEventDefinitionDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeEventDefinition === 'function') {
      onChangeEventDefinition(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const onChangeWithContext = (eventDefinition, currentContext) => {
    onChange(eventDefinition, currentContext);
    if (currentContext) {
      if (isMulti === true) {
        if (eventDefinition?.length > 0) {
          currentContext.setFieldValue(id, eventDefinition?.length);
          currentContext.setFieldError(id, undefined);
        } else {
          if (required) {
            currentContext.setFieldValue(id, '');
          }
        }
      } else {
        currentContext.setFieldValue(id, getOptionValue(eventDefinition));
        currentContext.setFieldValue(nameId, getOptionLabel(eventDefinition));
        currentContext.setFieldError(id, undefined);
        if (required && !eventDefinition) {
          currentContext.setFieldValue(id, '');
        }
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onEventDefinitionEditIconClick = () => {
    if (value?.eventDefinitionId) {
      showDialog({
        dialog: EventDefinitionDialog,
        props: {
          eventDefinitionId: value.eventDefinitionId,
          title: 'Update Event Definition',
        },
      }).then(
        (eventDefinition) => {
          if (eventDefinition !== null) {
            getEventDefinitionFx({
              eventDefinitionId: Number(value.eventDefinitionId) || null,
            }).then(
              (eventDefinitionDto) => {
                onChangeWithContext(eventDefinitionDto, formikContext);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  if (context) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti &&
                value &&
                userHas(GET_EVENTDEFINITION_LINK_KEY) &&
                !isDisabled ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onEventDefinitionEditIconClick}
                    data-cy={`eventDefinition-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={EVENTDEFINITION_ENTITY_NAME}
              canCreateNewEntity={userHas(CREATE_EVENTDEFINITION_LINK_KEY)}
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: EventDefinitionDialog,
                  props: {
                    title: showCreateDialogTitle,
                    eventDefinitionId: 0,
                  },
                }).then(
                  (eventDefinition) => {
                    setClearLoadOptionsCache(!clearLoadOptionsCache);
                    return eventDefinition;
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              onChange={(eventDefinition?: any) => {
                onChangeWithContext(eventDefinition, context);
              }}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true, true)}
              isDisabled={isDisabled}
              isClearable={isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={isSearchable}
              placeholder={placeholder}
              components={{
                IndicatorSeparator: () => null,
              }}
              classNamePrefix={classNamePrefix}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={EVENTDEFINITION_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_EVENTDEFINITION_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: EventDefinitionDialog,
            props: {
              title: showCreateDialogTitle,
              eventDefinitionId: 0,
            },
          }).then(
            (eventDefinition) => {
              setClearLoadOptionsCache(!clearLoadOptionsCache);
              return eventDefinition;
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        onChange={(eventDefinition?: any) => {
          onChange(eventDefinition, context);
        }}
        onFocus={clearMessages}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
