import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  AppModuleDto,
  AppModuleListItemDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  appModuleStore,
  getAppModulesColumnsFx,
  getAppModulesFx,
  deleteAppModuleFx,
  exportAppModulesFx,
  importAppModulesFx,
} from '../appModules.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_APPMODULE_LINK_KEY,
  DELETE_APPMODULE_LINK_KEY,
  IMPORT_APPMODULES_LINK_KEY,
  EXPORT_APPMODULES_LINK_KEY,
  GetAppModuleParams,
} from '../appModules.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { AppModuleDialog } from './appModule.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type AppModulesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (appModuleParams: GetAppModuleParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (viewName: string) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    appModule: AppModuleDto,
    appModuleParams: GetAppModuleParams,
  ) => void;
};

export const AppModulesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: AppModulesListProps) => {
  const [
    appModules,
    setAppModules,
  ] = useState<AppModuleListItemDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    appModuleColumns: columns,
    defaultAppModuleColumns: defaultColumns,
  } = useStore(appModuleStore);

  useEffect(() => {
    getAppModulesColumnsFx();
  }, []);

  const getAppModules = useCallback(async () => {
    const appModulesData = await getAppModulesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(appModulesData));
    setAppModules(appModulesData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: AppModuleListItemDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_APPMODULES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importAppModulesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getAppModules();
          },
          result?.links,
        ),
        getAction(
          EXPORT_APPMODULES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportAppModulesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getAppModules],
  );

  useEffect(() => {
    getAppModules();
  }, [getAppModules]);

  const onDeleteAppModule = useCallback(
    async (appModule: AppModuleDto) => {
      if (userHas(DELETE_APPMODULE_LINK_KEY, appModules?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete App module`,
            message: 'Are you sure you want to delete app module?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteAppModuleFx(appModule);
            getAppModules();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getAppModules, onDelete, appModules],
  );

  const onEditAppModule = (appModule: AppModuleDto) => {
    if (appModule) goToDetails({ appModuleId: appModule.appModuleId });
  };

  const onAppModuleSelect = useCallback(
    async (appModule) => {
      onEditAppModule(appModule);
      // const selectedAppModule = await showDialog({
      //   dialog: AppModuleDialog,
      //   props: {
      //     appModuleId: appModule.appModuleId,
      //     title: 'Update App module',
      //   },
      // });
      // if (selectedAppModule) {
      //   getAppModules();
      // }
    },
    [getAppModules],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          rowKeys={['appModuleId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={appModules.items}
          onLimitChanged={onLimitChanged}
          total={appModules.totalCount}
          onDelete={onDeleteAppModule}
          onSort={onSort}
          onEdit={onEditAppModule}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onAppModuleSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {appModules?.totalCount === 0 && !search && (
          <ListElementsNotFound
            entityName="App module"
            entityLinkKey={CREATE_APPMODULE_LINK_KEY}
            onClick={goToCreate}
          />
        )}
      </>
    );
  }
};
