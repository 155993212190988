import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';

import { AccountingItemEdit } from '../components/accountingItem-edit.component';
import {
  ACCOUNTINGITEM_CREATE_PATH,
  ACCOUNTINGITEMS_LIST_PATH,
} from '../accountingItems.route';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_ACCOUNTINGITEM_LINK_KEY } from '../accountingItems.service';
import { userHas } from '../../auth/auth.store';

export type AccountingItemScreenProps = { pageTitle: string; group: string };

export const AccountingItemScreen = ({
  pageTitle,
  group,
}: AccountingItemScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, accountingItemId } = useParams();

  function createPath() {
    return generatePath(INTERNAL_PATH + ACCOUNTINGITEM_CREATE_PATH, {
      organizationId,
    });
  }
  const onAccountingItemCreated = () => {
    const path = generatePath(INTERNAL_PATH + ACCOUNTINGITEMS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'accounting-item-created',
      type: 'success',
      autoHide: true,
      message: 'Accounting Item successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onAccountingItemUpdated = () => {
    const path = generatePath(INTERNAL_PATH + ACCOUNTINGITEMS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'accounting-item-updated',
      type: 'success',
      autoHide: true,
      message: 'Accounting Item successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + ACCOUNTINGITEMS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Accounting Items'}
      createPath={userHas(CREATE_ACCOUNTINGITEM_LINK_KEY) ? createPath() : null}
      createLabel={'Item'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AccountingItemEdit
        accountingItemId={accountingItemId}
        onAccountingItemUpdated={onAccountingItemUpdated}
        onAccountingItemCreated={onAccountingItemCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
