import {
  WorkflowDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  WorkflowDtoPagedResult,
  CreateWorkflowCommandValues,
  UpdateWorkflowCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createWorkflowRequest,
  getWorkflowsListRequest,
  getWorkflowRequest,
  deleteWorkflowRequest,
  updateWorkflowRequest,
  importWorkflowsRequest,
  exportWorkflowsRequest,
  GetWorkflowParams,
} from './workflows.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type WorkflowsStoreState = {
  links: LinkDto[];
  workflowColumns: EntityFieldDto[];
  defaultWorkflowColumns: EntityFieldDto[];
};

export const getWorkflowsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Workflow',
  });
});

addEffectStatusHandling(getWorkflowsColumnsFx);

export const getWorkflowsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Workflow',
  });
});

addEffectStatusHandling(getWorkflowsDefaultColumnsFx);

export const updateWorkflowsColumns = createEvent<EntityFieldDto[]>();

export const getWorkflowsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getWorkflowsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getWorkflowsFx);

export const createWorkflowFx = createEffect((workflowData: WorkflowDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createWorkflowCommand: CreateWorkflowCommandValues = {
    ...workflowData,
  };

  return createWorkflowRequest(currentOrganization, createWorkflowCommand);
});

addEffectStatusHandling(createWorkflowFx, {
  completeMessage: 'Workflow was created successfully',
  errorMessage: 'Workflow was not created',
});

export const getWorkflowFx = createEffect(
  (workflowParams: GetWorkflowParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getWorkflowRequest(
      currentOrganization as LinkResourceBaseDto,
      workflowParams,
    );
  },
);

addEffectStatusHandling(getWorkflowFx);

export const updateWorkflowFx = createEffect((workflow: WorkflowDto) => {
  const updateWorkflowCommand: UpdateWorkflowCommandValues = { ...workflow };
  return updateWorkflowRequest(workflow, updateWorkflowCommand);
});

addEffectStatusHandling(updateWorkflowFx, {
  completeMessage: 'Workflow was updated successfully',
  errorMessage: 'Workflow was not updated',
});

export const deleteWorkflowFx = createEffect((workflow: WorkflowDto) => {
  return deleteWorkflowRequest(workflow);
});

addEffectStatusHandling(deleteWorkflowFx, {
  completeMessage: 'Workflow was deleted successfully',
  errorMessage: 'Workflow was not deleted',
});

export const exportWorkflowsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportWorkflowsRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportWorkflowsFx, {
  completeMessage: 'Workflows were exported successfully',
  errorMessage: 'Workflows were not exported',
});

export const importWorkflowsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importWorkflowsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importWorkflowsFx, {
  completeMessage: 'Workflows were imported successfully',
  errorMessage: 'Workflows were not imported',
  inFlightMessage: 'Importing workflows...',
});

const defaultState: WorkflowsStoreState = {
  links: [],
  workflowColumns: [],
  defaultWorkflowColumns: [],
};

export const workflowStore: Store<WorkflowsStoreState> = createStore(
  defaultState,
)
  .on(getWorkflowsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      workflowColumns: payload.result.items,
      defaultWorkflowColumns: payload.result.items,
    };
  })
  .on(getWorkflowsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultWorkflowColumns: payload.result.items,
    };
  })
  .on(updateWorkflowsColumns, (state, payload) => {
    return { ...state, workflowColumns: payload };
  });
