import React from 'react';
import { HiCheck } from 'react-icons/hi';
import { Spinner } from '../spinner/spinner';

type RoundCheckboxProps = {
  onClick?: () => void;
  label?: string;
  chkBoxClassName?: string;
  spinnerClassName?: string;
  labelClassName?: string;
  size: number;
  isLoading?: boolean;
  spinnerStyle?: {};
  isInvalid?: boolean;
};

export const RoundCheckbox = ({
  onClick,
  label,
  chkBoxClassName,
  spinnerClassName,
  labelClassName,
  size,
  isLoading,
  spinnerStyle,
  isInvalid,
}: RoundCheckboxProps) => {
  return (
    <div
      className="d-flex align-items-center"
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
    >
      {isLoading ? (
        <Spinner size="sm" className={spinnerClassName} style={spinnerStyle} />
      ) : (
        <div className={chkBoxClassName}>
          <HiCheck size={size} />
        </div>
      )}
      {label && (
        <div
          className={`${labelClassName} round-checkbox-label-text ${
            isInvalid ? 'text-danger' : ''
          }`}
        >
          {label}
        </div>
      )}
    </div>
  );
};
