import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { TrackingEventForm } from './trackingEvent.form';
import {
  EventDefinitionDto,
  TrackingEventDto,
} from '../../../models/data.models';
import {
  createTrackingEvent,
  getTrackingEvent,
  updateTrackingEvent,
} from '../trackingEvents.store';
import { Panel } from '../../common/components/panel/panel.component';
import { FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { getEventDefinitionFx } from '../../eventDefinitions/eventDefinitions.store';
import { FormContext } from '../../common/components/form/form.component';
import { TrackingEventDefaultValues } from '../../common/DefaultValues';

export type TrackingEventEditProps = {
  trackingEventId?: number | null;
  defaultTrackingEvent?: TrackingEventDto | null;
  onTrackingEventCreated?: (trackingEvent: TrackingEventDto) => void;
  onTrackingEventUpdated?: (trackingEvent: TrackingEventDto) => void;
  onTrackingEventLoaded?: (trackingEvent: TrackingEventDto) => void;
  isEditMode?: boolean | null;
  saveButtonRenderCondition?: boolean;
  onCancel?: () => void;
};

const initialState: TrackingEventDto = {
  trackingEventId: TrackingEventDefaultValues.trackingEventId,
  description: TrackingEventDefaultValues.description,
  location: TrackingEventDefaultValues.location,
  eventDate: TrackingEventDefaultValues.eventDate,
  eventDefinitionId: TrackingEventDefaultValues.eventDefinitionId,
  eventName: TrackingEventDefaultValues.eventName,
  isInactive: TrackingEventDefaultValues.isInactive,
  includeInTracking: TrackingEventDefaultValues.includeInTracking,
  sendEmail: TrackingEventDefaultValues.sendEmail,
  links: [],
};

const trackingEventSchema = Yup.object().shape({
  eventDefinitionId: Yup.number().required("Can't be blank").nullable(true),
});

export const TrackingEventEdit = ({
  trackingEventId,
  defaultTrackingEvent = null,
  onTrackingEventLoaded = () => {},
  onTrackingEventCreated = () => {},
  onTrackingEventUpdated = () => {},
  isEditMode = false,
  saveButtonRenderCondition = false,
  onCancel = () => {},
}: TrackingEventEditProps) => {
  const isCreateModeForEnity = !trackingEventId || trackingEventId == 0;
  const isCreateMode = isCreateModeForEnity && !isEditMode;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<TrackingEventDto | null>(
    defaultTrackingEvent || initialState,
  );
  const [trackingEvent, setTrackingEvent] = useState<TrackingEventDto | null>(
    initialValues,
  );

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (trackingEventId || isEditMode) {
      if (defaultTrackingEvent) {
        setTrackingEvent(defaultTrackingEvent);
        setInitialValues(defaultTrackingEvent);
        setIsLoading(false);
        onTrackingEventLoaded(defaultTrackingEvent);
      } else {
        getTrackingEvent({ trackingEventId }).then(
          (trackingEventDto: TrackingEventDto) => {
            setTrackingEvent(trackingEventDto);
            setInitialValues(trackingEventDto);
            setIsLoading(false);
            onTrackingEventLoaded(trackingEventDto);
          },
        );
      }
    } else {
      throw new Error('TrackingEvent keys were not provided');
    }
  }, [trackingEventId]);

  const onSubmit = (data: TrackingEventDto) => {
    if (defaultTrackingEvent === null) {
      setIsSending(true);
      if (isCreateMode) {
        createTrackingEvent(data)
          .then((result) => {
            onTrackingEventCreated(result);
          })
          .finally(() => setIsSending(false));
      } else {
        updateTrackingEvent(data)
          .then((result) => {
            onTrackingEventUpdated(result);
          })
          .finally(() => setIsSending(false));
      }
    } else {
      if (isCreateMode) {
        onTrackingEventCreated(data);
      } else {
        onTrackingEventUpdated(data);
      }
    }
  };

  const onEventDateChange = (date: any, context: FormikProps<FormikValues>) => {
    setTrackingEvent((trackingEventDto) => {
      if (!trackingEventDto) {
        trackingEventDto = initialState;
      }
      trackingEventDto.eventDate = new Date(date);
      context.setFieldValue('eventDate', trackingEventDto.eventDate);
      return { ...trackingEventDto };
    });
  };

  const onSendEmailChange = (newValue: boolean) => {
    setTrackingEvent((oldTrackingEvent) => {
      if (!oldTrackingEvent) {
        oldTrackingEvent = initialState;
      }

      oldTrackingEvent.sendEmail = newValue;
      return { ...oldTrackingEvent };
    });
  };

  const onEventNameChange = (
    data: EventDefinitionDto,
    context: FormikProps<FormikValues>,
  ) => {
    if (data) {
      getEventDefinitionFx({ eventDefinitionId: data.eventDefinitionId }).then(
        (eventDefinition) => {
          setTrackingEvent((trackingEvent) => {
            if (!trackingEvent) {
              trackingEvent = initialState;
            }
            trackingEvent.eventDefinitionId = data?.eventDefinitionId;
            trackingEvent.eventName = data?.eventName;
            trackingEvent.description = eventDefinition?.description;
            trackingEvent.location = eventDefinition?.location;
            trackingEvent.isInactive = eventDefinition?.isInactive;
            trackingEvent.includeInTracking =
              eventDefinition?.includeInTracking;
            trackingEvent.sendEmail = eventDefinition?.sendEmail;

            context.setFieldValue(
              'description',
              trackingEvent.description ?? '',
            );
            context.setFieldValue('location', trackingEvent.location ?? '');
            context.setFieldValue('isInactive', trackingEvent.isInactive);
            context.setFieldValue(
              'includeInTracking',
              trackingEvent.includeInTracking,
            );
            context.setFieldValue('sendEmail', trackingEvent.sendEmail);
            return { ...trackingEvent };
          });
        },
      );
    } else {
      setTrackingEvent((trackingEvent) => {
        trackingEvent = initialState;
        context.setFieldValue('description', '');
        context.setFieldValue('location', '');
        context.setFieldValue('isInactive', trackingEvent.isInactive);
        context.setFieldValue(
          'includeInTracking',
          trackingEvent.includeInTracking,
        );
        context.setFieldValue('sendEmail', trackingEvent.sendEmail);
        return { ...trackingEvent };
      });
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <TrackingEventForm
      id={'TrackingEventForm'}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={trackingEventSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <Tab>Event</Tab>
            </TabList>
            <TabPanel>
              <Panel className="mt-3 mx-3">
                <div className="row p-0 row-trackingEvent">
                  <div className={'col-12 d-flex p-0'}>
                    <div className={'col-4 mr-3 p-0'}>
                      <TrackingEventForm.EventDate
                        defaultValue={trackingEvent?.eventDate}
                        onChange={(date) => onEventDateChange(date, context)}
                      />
                    </div>
                  </div>
                  <div className={'col-12 p-0 d-flex'}>
                    <div className={'col p-0'}>
                      <TrackingEventForm.EventName
                        defaultValue={
                          trackingEvent &&
                          trackingEvent.eventDefinitionId != null &&
                          trackingEvent.eventName != null
                            ? {
                                eventDefinitionId:
                                  trackingEvent.eventDefinitionId,
                                eventName: trackingEvent.eventName,
                              }
                            : ''
                        }
                        onChange={(data) => onEventNameChange(data, context)}
                        disabled={!isCreateMode}
                      />
                    </div>
                  </div>
                  <div className={'col-12 p-0 d-flex'}>
                    <div className={'col p-0'}>
                      <TrackingEventForm.Description />
                    </div>
                  </div>
                  <div className={'col-12 p-0'}>
                    <div className={'col p-0'}>
                      <TrackingEventForm.Location />
                    </div>
                  </div>
                  <div className={'col-12 p-0'}>
                    <div className={'col p-0'}>
                      <TrackingEventForm.IsInactive
                        defaultValue={trackingEvent?.isInactive ?? false}
                      />
                    </div>
                  </div>
                  <div className={'col-12 p-0'}>
                    <div className={'col p-0'}>
                      <TrackingEventForm.IncludeInTracking
                        defaultValue={trackingEvent?.includeInTracking ?? false}
                      />
                    </div>
                  </div>
                  <div className={'col-12 p-0'}>
                    <div className={'col p-0'}>
                      <TrackingEventForm.SendEmail
                        defaultValue={trackingEvent?.sendEmail ?? false}
                        onChange={onSendEmailChange}
                      />
                    </div>
                  </div>
                  <div className="justify-content-end d-flex col-12 p-0">
                    {(saveButtonRenderCondition || isCreateModeForEnity) && (
                      <div className="col-6 pl-0">
                        <Button
                          name="save-trackingEvent"
                          type="submit"
                          color="primary"
                          className="btn-block"
                          disabled={isSending}
                          isSending={isSending}
                        >
                          Save Event
                        </Button>
                      </div>
                    )}

                    <div className="col-6 pr-0">
                      <Button
                        type="button"
                        color="secondary"
                        onClick={onCancel}
                        className="col-12"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </div>
              </Panel>
            </TabPanel>
          </Tabs>
        )}
      </FormContext.Consumer>
    </TrackingEventForm>
  );
};
