import { v4 } from 'uuid';
import { queryGraphQl } from '../../../graphql/graphql.service';
import { parseTemplate } from '../../components/layout/component-hooks';
import { addMessage } from '../../messages.store';

export const queryGraphQlAction = async ({
  actionProps,
  data,
  latestStoreValues,
  source,
  onAction,
}) => {
  const commandVariables = parseTemplate(actionProps.query.variables, {
    ...latestStoreValues,
    ...data,
  });
  try {
    const result = await queryGraphQl({
      query: actionProps.query.command,
      variables: { ...commandVariables, organizationId: data.organizationId },
    });
    if (actionProps.query.onSuccess) {
      await onAction(
        actionProps.query.onSuccess,
        { ...data, ...result, result },
        latestStoreValues,
        source,
      );
    }
    return result;
  } catch (e) {
    console.error('query went wrong', e);
    if (actionProps.query.onError) {
      await onAction(
        actionProps.query.onError,
        {
          error: e,
          ...data,
        },
        latestStoreValues,
        source,
      );
    } else {
      if (e?.networkError?.result?.errors?.length > 0) {
        e.message = e.networkError?.result?.errors
          ?.map((x) => x.message)
          .join(' ');
      }
      addMessage({
        message: e?.message || e,
        type: 'danger',
        autoHide: true,
        id: v4(),
      });
    }

    return false; // do not continue actions
  }
};
