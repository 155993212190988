import React, { useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetOrdersQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import {
  CARGO_MOVEMENT_CREATE_PATH,
  CARGO_MOVEMENT_EDIT_PATH,
  CARGO_MOVEMENT_LIST_PATH,
  CARGO_MOVEMENT_EDIT_PICKING_PATH,
  CARGO_MOVEMENT_EDIT_PUTAWAY_PATH,
  ALL_PUTAWAY_ACTIVITY_FLOW_PATH,
} from '../cargoMovement.route';
import { CargoMovementsList } from '../components/cargoMovements-list.component';
import {
  CREATE_CARGO_MOVEMENT_LINK_KEY,
  GetOrderParams,
} from '../cargoMovements.service';
import { userHas } from '../../auth/auth.store';
import { getOrdersDefaultColumns, ordersStore } from '../cargoMovements.store';
import { useStore } from 'effector-react';

export type OrdersListScreenProps = { pageTitle: string; group: string };

const generateHistoryPath = (
  organizationId: string,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    view: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

export const CargoMovementsListScreen = ({
  pageTitle,
  group,
}: OrdersListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(ordersStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `orderType:${OrderTypes.CargoMovement}`,
    search = '',
    view = '',
  } = useQuery() as GetOrdersQuery;
  const navigate = useNavigate();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + CARGO_MOVEMENT_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const goToDetails = (orderParams: GetOrderParams) => {
    const orderId = orderParams.orderId;
    const createOrEdit =
      orderId === 0 ? CARGO_MOVEMENT_CREATE_PATH : CARGO_MOVEMENT_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      orderId,
    });
    navigate(path);
  };

  const goToPickingActivity = (orderParams: GetOrderParams) => {
    const orderId = orderParams.orderId;
    const edit = CARGO_MOVEMENT_EDIT_PICKING_PATH;
    const path = generatePath(INTERNAL_PATH + edit, {
      organizationId,
      orderId,
    });
    navigate(path);
  };

  const goToPutAwayActivity = (orderParams: GetOrderParams) => {
    const orderId = orderParams.orderId;
    const edit = CARGO_MOVEMENT_EDIT_PUTAWAY_PATH;
    const path = generatePath(INTERNAL_PATH + edit, {
      organizationId,
      orderId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const params = { offset, limit, sort, filter, search, view };
  const onQueryChange = (newParams: Partial<typeof params>) => {
    const mergedParams = { ...params, ...newParams };
    const newPath = generateHistoryPath(organizationId, mergedParams);
    navigate(newPath);
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getOrdersDefaultColumns();
  };

  const addPath = generatePath(INTERNAL_PATH + ALL_PUTAWAY_ACTIVITY_FLOW_PATH, {
    organizationId,
  });

  return (
    <InternalLayout
      group={group}
      title={pageTitle}
      createPath={userHas(CREATE_CARGO_MOVEMENT_LINK_KEY) ? createPath : null}
      createLabel={'Cargo Movement'}
      addPathLabel={'Put Away'}
      addPath={addPath}
    >
      <CargoMovementsList
        filter={filter}
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        goToPickingActivity={goToPickingActivity}
        goToPutAwayActivity={goToPutAwayActivity}
        onDelete={onDelete}
        onViewChanged={onViewChanged}
        onPageChanged={(page) => onQueryChange({ offset: page * limit })}
        onLimitChanged={(limit) => onQueryChange({ limit })}
        onSearch={(search) => onQueryChange({ search, offset: 0 })}
        onSort={(sort) => onQueryChange({ sort })}
        onFilter={(filter) => onQueryChange({ filter, offset: 0 })}
      />
    </InternalLayout>
  );
};
