import {
  OrderDocumentDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateOrderDocumentCommandValues,
  UpdateOrderDocumentCommandValues,
  Column,
  OrderDto,
  RegenerateOrderDocumentCommandValues,
  CreateOrderDocumentsCommandValues,
  FileType,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrderDocumentRequest,
  createOrderDocumentsRequest,
  getOrderDocumentsListRequest,
  getOrderDocumentRequest,
  deleteOrderDocumentRequest,
  updateOrderDocumentRequest,
  GetOrderDocumentParams,
  regenerateOrderDocumentRequest,
  deleteOrderDocumentsRequest,
  getOrderDocumentContentUrlRequest,
} from './orderDocuments.service';
import { OrderEditFormDto } from '../../models/custom.models';
import { FilteredField } from '../common/components/filters/filtersTab.component';
import { addEffectStatusHandling } from '../common/messages.store';
import { boolean } from 'yup';

type OrderDocumentsStoreState = {
  links: LinkDto[];
  orderDocumentColumns: Column[];
  orderDocumentsFilters: FilteredField[];
  defaultDocumentTemplatesForOrderDocumentColumns: Column[];
};

export const updateOrderDocumentsColumns = createEvent<Column[]>();
export const updateOrderDocumentsColumnsForOrderTab = createEvent<Column[]>();
export const updateOrderDocumentsFiltersForOrder = createEvent<
  FilteredField[]
>();

interface GetOrderDocumentsParams {
  order: OrderEditFormDto;
  params: ListParams;
}

interface DeleteOrderDocumentsParams {
  order: OrderEditFormDto;
  documents: OrderDocumentDto[];
}

export const getOrderDocumentsFx = createEffect(
  (data: GetOrderDocumentsParams) => {
    return getOrderDocumentsListRequest(data.order, data.params);
  },
);

export const createOrderDocumentFx = createEffect(
  (orderDocumentData: OrderDocumentDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createOrderDocumentCommand: CreateOrderDocumentCommandValues = {
      ...orderDocumentData,
    };

    return createOrderDocumentRequest(
      currentOrganization!,
      createOrderDocumentCommand,
    );
  },
);

interface CreateOrderDocumentsParams {
  order: OrderDto;
  values: CreateOrderDocumentsCommandValues[];
}

export const createOrderDocumentsFx = createEffect(
  (params: CreateOrderDocumentsParams) => {
    return createOrderDocumentsRequest(params.order!, params.values);
  },
);
addEffectStatusHandling(createOrderDocumentsFx);

export const getOrderDocumentFx = createEffect(
  (orderDocumentParams: GetOrderDocumentParams) => {
    if (orderDocumentParams.order === null)
      throw new Error('Order was not set in the current context.');

    return getOrderDocumentRequest(
      orderDocumentParams.order as LinkResourceBaseDto,
      orderDocumentParams,
    );
  },
);
addEffectStatusHandling(getOrderDocumentFx);

export const getOrderDocumentContentUrlFx = createEffect(
  ({
    orderDocument,
    fileType,
  }: {
    orderDocument: OrderDocumentDto;
    fileType: FileType;
  }) => {
    return getOrderDocumentContentUrlRequest(orderDocument, fileType);
  },
);
addEffectStatusHandling(getOrderDocumentContentUrlFx);

export const updateOrderDocumentFx = createEffect(
  (orderDocument: OrderDocumentDto) => {
    const updateOrderDocumentCommand: UpdateOrderDocumentCommandValues = {
      ...orderDocument,
    };
    return updateOrderDocumentRequest(
      orderDocument,
      updateOrderDocumentCommand,
    );
  },
);
addEffectStatusHandling(updateOrderDocumentFx);

export interface RegenerateOrderDocumentsParams {
  orderDocument: OrderDocumentDto;
  values: RegenerateOrderDocumentCommandValues;
}

export const regenerateOrderDocumentFx = createEffect(
  (params: RegenerateOrderDocumentsParams) => {
    const regenerateOrderDocumentCommand: RegenerateOrderDocumentCommandValues = {
      documentTemplateId: params?.orderDocument?.documentTemplateId,
      metadata: params?.values?.metadata,
    };
    return regenerateOrderDocumentRequest(
      params.orderDocument,
      regenerateOrderDocumentCommand,
    );
  },
);
addEffectStatusHandling(regenerateOrderDocumentFx);

export const deleteOrderDocument = createEffect(
  (orderDocument: OrderDocumentDto) => {
    return deleteOrderDocumentRequest(orderDocument);
  },
);

export const deleteOrderDocumentsFx = createEffect(
  (data: DeleteOrderDocumentsParams) => {
    return deleteOrderDocumentsRequest(data.order, data.documents);
  },
);
addEffectStatusHandling(deleteOrderDocumentsFx);

const defaultState: OrderDocumentsStoreState = {
  links: [],
  orderDocumentColumns: [
    { name: 'orderDocumentId', visible: true, title: 'Order Document Id' },
    { name: 'orderDocumentName', visible: true, title: 'Last Generated P D F' },
    { name: 'created', visible: true, title: 'Created' },
    { name: 'createdBy', visible: true, title: 'Created By' },
    { name: 'lastGeneratedTime', visible: true, title: 'Last Generated Time' },
    { name: 'lastModified', visible: true, title: 'Last Modified' },
    { name: 'lastModifiedBy', visible: true, title: 'Last Modified By' },
    { name: 'orderId', visible: true, title: 'Order Id' },
    {
      name: 'regenerateOnOrderChange',
      visible: true,
      title: 'Regenerate On Order Change',
    },
  ],
  orderDocumentsFilters: [],
  defaultDocumentTemplatesForOrderDocumentColumns: [],
};

const defaultStateOrderDocumentsTable: OrderDocumentsStoreState = {
  ...defaultState,
  orderDocumentColumns: [
    {
      name: 'documentTemplate.documentTemplateType',
      visible: true,
      title: 'Type',
      sortName: 'documentTemplate.documentTemplateType',
      width: '10%',
    },
    {
      name: 'orderDocumentName',
      visible: true,
      title: 'Name',
      sortName: 'orderDocumentName',
      width: '30%',
    },
    {
      name: 'regenerateOnOrderChange',
      visible: true,
      title: 'Regenerate On Change',
      sortName: 'regenerateOnOrderChange',
      width: '10%',
      type: 'bool',
    },
    {
      name: 'createdByUserName',
      visible: true,
      title: 'Created By',
      sortName: 'createdByUserName',
      width: '20%',
    },
    {
      name: 'created',
      visible: true,
      title: 'Create Date',
      sortName: 'created',
      width: '30%',
    },
  ],
};

export const orderDocumentStore: Store<OrderDocumentsStoreState> = createStore(
  defaultState,
).on(updateOrderDocumentsColumns, (state, payload) => {
  return { ...state, orderDocumentColumns: payload };
});

export const orderDocumentsTableStore: Store<OrderDocumentsStoreState> = createStore(
  defaultStateOrderDocumentsTable,
).on(updateOrderDocumentsColumnsForOrderTab, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

const defaultStateOrderDocumentsFilters: OrderDocumentsStoreState = {
  ...defaultState,
  orderDocumentsFilters: [
    {
      type: 'documentTemplateType',
      title: 'Template type',
      name: 'documentTemplateId',
    },
    {
      name: 'createdUser.UserName',
      title: 'Created by',
      type: 'user',
    },
    {
      type: 'date',
      title: 'Create date',
      name: 'created',
    },
  ],
};

const defaultDocumentTemplatesForOrderDocumentColumns: OrderDocumentsStoreState = {
  ...defaultState,
  defaultDocumentTemplatesForOrderDocumentColumns: [
    {
      name: 'documentTemplateId',
      visible: true,
      title: 'Select',
      type: 'checkBox',
      sortName: 'documentTemplateId',
      showFilter: true,
    },
    {
      name: 'regenerateOnOrderChange',
      visible: true,
      title: 'Regenerate On Change',
      type: 'checkBox',
      sortName: 'regenerateOnOrderChange',
      showFilter: true,
    },
    {
      name: 'description',
      visible: true,
      title: 'Name',
      sortName: 'description',
      showFilter: true,
    },
    {
      name: 'documentTemplateType',
      visible: true,
      title: 'Template type',
      sortName: 'documentTemplateType',
      showFilter: true,
    },
  ],
};

export const orderDocumentsFiltersStore: Store<OrderDocumentsStoreState> = createStore(
  defaultStateOrderDocumentsFilters,
).on(updateOrderDocumentsFiltersForOrder, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const updateDocumentTemplatesColumnsForOrderDocuments = createEvent<
  Column[]
>();

export const documentTemplateStoreForOrderDocuments: Store<OrderDocumentsStoreState> = createStore(
  defaultDocumentTemplatesForOrderDocumentColumns,
).on(updateDocumentTemplatesColumnsForOrderDocuments, (state, payload) => {
  return { ...state, documentTemplateColumns: payload };
});
