import React, { ChangeEvent } from 'react';
import { LocationType } from '../../../models/data.models';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectWarehouseZonePaginate } from '../../warehouseZones/components/warehouseZone-react-select.component';

export const WarehouseLocationForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

WarehouseLocationForm.Code = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'code'}
      label={'Code'}
      placeholder={'Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
WarehouseLocationForm.CustomerSelect = ({
  selectedFilter = ``,
  nameId = 'customerIdName',
  placeholder = 'Select Customer',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'customerId',
  header = 'Customer',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};
WarehouseLocationForm.Description = ({
  className,
  style,
  size,
  type = 'text',
  name = 'description',
  label = 'Description',
  placeholder = 'Description',
  required = false,
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      type={type}
      required={required}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
    />
  );
};
WarehouseLocationForm.Height = ({
  className,
  style,
  size,
  onChange,
  type = 'input-OnChange-number',
  selectedFieldName,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'height'}
      label={'Height'}
      minNumber={0}
      placeholder={'Height'}
      required={false}
      checkIsCountType={true}
      type={type}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      valueInput={selectedFieldName}
    />
  );
};
WarehouseLocationForm.IsInactive = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
WarehouseLocationForm.Length = ({
  className,
  style,
  size,
  onChange,
  type = 'input-OnChange-number',
  selectedFieldName,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'length'}
      label={'Length'}
      minNumber={0}
      placeholder={'Length'}
      required={false}
      checkIsCountType={true}
      type={type}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      valueInput={selectedFieldName}
    />
  );
};
WarehouseLocationForm.LocationType = ({
  onChange,
  options,
  name = 'locationType',
  id = 'locationType',
  placeholder = 'Location Type',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = null,
  label = 'Location Type',
}: InputPublicProps) => {
  return (
    <Input
      onChange={onChange}
      id={id}
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      multiple={multiple}
      required={required}
      type={'react-select'}
      disabled={disabled}
      defaultValue={defaultValue}
      enumName={LocationType}
    />
  );
};
WarehouseLocationForm.MaximumWeight = ({
  className,
  style,
  size,
  onChange,
  type = 'input-OnChange-number',
  selectedFieldName,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'maximumWeight'}
      label={'Maximum Weight'}
      minNumber={0}
      placeholder={'Maximum Weight'}
      required={false}
      checkIsCountType={true}
      type={type}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      valueInput={selectedFieldName}
    />
  );
};
WarehouseLocationForm.Width = ({
  className,
  style,
  size,
  onChange,
  type = 'input-OnChange-number',
  selectedFieldName,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'width'}
      label={'Width'}
      minNumber={0}
      placeholder={'Width'}
      required={false}
      checkIsCountType={true}
      type={type}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      valueInput={selectedFieldName}
    />
  );
};
WarehouseLocationForm.ParentZoneId = ({
  selectedFilter = ``,
  placeholder = 'Select Parent Zone',
  required = false,
  defaultValue,
  sort = '',
  id = 'parentZoneId',
  options = [],
  header = 'Parent Zone',
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <SelectWarehouseZonePaginate
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      options={options}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeWarehouseZone={onChange}
    />
  );
};
