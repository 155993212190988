import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { ParcelShipmentEdit } from './parcelShipment-edit.component';

export type ParcelShipmentDialogProperties = {
  orderId?: number | null;
  title: string;
  className?: string;
  onParcelShipmentSaved?: (parcelShipment: OrderDto) => void;
  onInvoiceGenerated?: () => void;
};

export class ParcelShipmentDialog extends Dialog<ParcelShipmentDialogProperties> {
  onInvoiceGenerated?: () => void;

  constructor(props: ParcelShipmentDialogProperties) {
    super(props);
    if (props.onInvoiceGenerated) {
      this.onInvoiceGenerated = props.onInvoiceGenerated?.bind(this);
    }
    this.onParcelShipmentSaved = this.onParcelShipmentSaved.bind(this);
  }

  onParcelShipmentSaved = (parcelShipment: OrderDto) => {
    this.close(parcelShipment);
  };

  renderContent(): any {
    const { orderId } = this.props as ParcelShipmentDialogProperties;
    const onInvoiceGenerated = this.onInvoiceGenerated;
    return (
      <ParcelShipmentEdit
        orderId={orderId}
        onParcelShipmentCreated={this.onParcelShipmentSaved}
        onParcelShipmentUpdated={this.onParcelShipmentSaved}
        onCancel={this.close.bind(this)}
        onInvoiceGenerated={onInvoiceGenerated && this.onInvoiceGenerated}
      />
    );
  }
}
