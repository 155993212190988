import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  UserGroupDto,
  UserGroupDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  userGroupStore,
  getUserGroups,
  deleteUserGroup,
  updateUserGroupsColumns,
} from '../userGroups.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_USERGROUP_LINK_KEY,
  DELETE_USERGROUP_LINK_KEY,
  GetUserGroupParams,
} from '../userGroups.service';
import { UserGroupDialog } from './userGroup.dialog';
import { userHas } from '../../auth/auth.store';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';

export type UserGroupsListProps = {
  goToDetails?: (userGroupParams: GetUserGroupParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    userGroup: UserGroupDto,
    userGroupParams: GetUserGroupParams,
  ) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const UserGroupsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = '',
  onFilter = () => {},
  onSearch,
}: UserGroupsListProps) => {
  const [userGroups, setUserGroups] = useState<UserGroupDtoPagedResult | null>(
    null,
  );

  const getData = ({ offset, limit, sort, filter, search }) => {
    getUserGroups({ offset, limit, sort, filter, search }).then(
      (userGroupsData) => {
        setUserGroups(userGroupsData);
      },
    );
  };

  useEffect(() => {
    getData({ offset, limit, sort, filter, search });
  }, [offset, limit, sort, filter, search]);

  const { userGroupColumns: columns } = useStore(userGroupStore);

  const onDeleteUserGroup = (userGroup: UserGroupDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${userGroup.name}" User Group`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteUserGroup(userGroup).then(() => {
          onDelete();
          getData({ offset, limit, sort, filter, search });
        });
      }
    });
  };

  const onEditUserGroup = (userGroup: UserGroupDto) => {
    goToDetails({ userGroupId: userGroup.userGroupId });
  };

  const onCreateNewUserGroup = () => {
    goToDetails({ userGroupId: 0 });
  };
  const onUserGroupSelect = (userGroup, userGroupId) => {
    if (onSelect) onSelect(userGroup, userGroupId);
    showDialog({
      dialog: UserGroupDialog,
      props: {
        userGroupId: userGroup.userGroupId,
        title: 'Update User Group',
      },
    }).then((customField) => {
      if (customField !== null) {
        getData({ offset, limit, sort, filter, search });
      }
    });
  };

  return (
    <>
      <Grid
        rowKeys={['userGroupId']}
        data={userGroups?.items}
        showAllStore={true}
        columns={columns}
        offset={offset}
        limit={limit}
        total={userGroups?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_USERGROUP_LINK_KEY, userGroups?.items[0]?.links)
            ? onDeleteUserGroup
            : null
        }
        onSort={onSort}
        onEdit={onEditUserGroup}
        onPageChanged={onPageChanged}
        onSelect={onUserGroupSelect}
        search={search}
        onSearch={onSearch}
      />
      {!(userGroups && userGroups.items && userGroups.items.length) && (
        <ListElementsNotFound
          entityName="User Group"
          entityLinkKey={CREATE_USERGROUP_LINK_KEY}
          onClick={onCreateNewUserGroup}
        />
      )}
    </>
  );
};
