import {
  EventDefinitionDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  EventDefinitionDtoPagedResult,
  CreateEventDefinitionCommandValues,
  UpdateEventDefinitionCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createEventDefinitionRequest,
  getEventDefinitionsListRequest,
  getEventDefinitionRequest,
  deleteEventDefinitionRequest,
  updateEventDefinitionRequest,
  importEventDefinitionsRequest,
  exportEventDefinitionsRequest,
  GetEventDefinitionParams,
} from './eventDefinitions.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type EventDefinitionsStoreState = {
  links: LinkDto[];
  eventDefinitionColumns: EntityFieldDto[];
  defaultEventDefinitionColumns: EntityFieldDto[];
};

export const getEventDefinitionsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'EventDefinition',
  });
});

addEffectStatusHandling(getEventDefinitionsColumnsFx);

export const getEventDefinitionsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'EventDefinition',
  });
});

addEffectStatusHandling(getEventDefinitionsDefaultColumnsFx);

export const updateEventDefinitionsColumns = createEvent<EntityFieldDto[]>();

export const getEventDefinitionsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getEventDefinitionsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getEventDefinitionsFx);

export const createEventDefinitionFx = createEffect(
  (eventDefinitionData: EventDefinitionDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createEventDefinitionCommand: CreateEventDefinitionCommandValues = {
      ...eventDefinitionData,
    };

    return createEventDefinitionRequest(
      currentOrganization!,
      createEventDefinitionCommand,
    );
  },
);

addEffectStatusHandling(createEventDefinitionFx, {
  completeMessage: 'Event definition was created successfully',
  errorMessage: 'Event definition was not created',
});

export const getEventDefinitionFx = createEffect(
  (eventDefinitionParams: GetEventDefinitionParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getEventDefinitionRequest(
      currentOrganization as LinkResourceBaseDto,
      eventDefinitionParams,
    );
  },
);

addEffectStatusHandling(getEventDefinitionFx);

export const updateEventDefinitionFx = createEffect(
  (eventDefinition: EventDefinitionDto) => {
    const updateEventDefinitionCommand: UpdateEventDefinitionCommandValues = {
      ...eventDefinition,
    };
    return updateEventDefinitionRequest(
      eventDefinition,
      updateEventDefinitionCommand,
    );
  },
);

addEffectStatusHandling(updateEventDefinitionFx, {
  completeMessage: 'Event definition was updated successfully',
  errorMessage: 'Event definition was not updated',
});

export const deleteEventDefinitionFx = createEffect(
  (eventDefinition: EventDefinitionDto) => {
    return deleteEventDefinitionRequest(eventDefinition);
  },
);

addEffectStatusHandling(deleteEventDefinitionFx, {
  completeMessage: 'Event definition was deleted successfully',
  errorMessage: 'Event definition was not deleted',
});

export const exportEventDefinitionsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportEventDefinitionsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportEventDefinitionsFx, {
  completeMessage: 'Event definitions were exported successfully',
  errorMessage: 'Event definitions were not exported',
});

export const importEventDefinitionsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importEventDefinitionsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importEventDefinitionsFx, {
  completeMessage: 'Event definitions were imported successfully',
  errorMessage: 'Event definitions were not imported',
  inFlightMessage: 'Importing event definitions...',
});

const defaultState: EventDefinitionsStoreState = {
  links: [],
  eventDefinitionColumns: [],
  defaultEventDefinitionColumns: [],
};

export const eventDefinitionStore: Store<EventDefinitionsStoreState> = createStore(
  defaultState,
)
  .on(getEventDefinitionsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      eventDefinitionColumns: payload.result.items,
      defaultEventDefinitionColumns: payload.result.items,
    };
  })
  .on(getEventDefinitionsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultEventDefinitionColumns: payload.result.items,
    };
  })
  .on(updateEventDefinitionsColumns, (state, payload) => {
    return { ...state, eventDefinitionColumns: payload };
  });
