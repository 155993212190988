import { Dialog } from '../dialog/dialog.component';
import { Button } from '../button/button.component';
import PropTypes from 'prop-types';
import { clearMessages } from '../../messages.store';
import { v4 } from 'uuid';

export type PromptProperties = {
  title: string;
  message: string;
  className: string;
  orderNumber: string;
};

type StateType = {
  orderNumber: string;
};

export class Prompt extends Dialog<PromptProperties> {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    className: PropTypes.string,
    orderNumber: PropTypes.string,
  };
  constructor(props: PromptProperties) {
    super(props);
    this.state = { orderNumber: '' };
  }

  handleChange = (e) => {
    this.setState({ orderNumber: e.target.value });
  };

  handleClick = () => {
    const enteredValue = (this.state as StateType).orderNumber;
    const orderValue = (this.props as PromptProperties).orderNumber;
    this.close(enteredValue === orderValue);
  };

  renderContent(): any {
    const { message, orderNumber } = this.props as PromptProperties;
    return (
      <div>
        <p
          className={'prompt-message mb-2 w-100 d-flex justify-content-center'}
        >
          {message}
        </p>
        <div className={'col-12 p-2 d-flex w-100'}>
          <input
            type={'text'}
            className={`form-control mb-2 prompt-message-input`}
            name={'cancel-order'}
            onChange={this.handleChange}
            id={'cancel-order'}
            onFocus={() => clearMessages}
            autoFocus
            key={v4()}
            value={(this.state as StateType).orderNumber}
          />
        </div>
        <div className={'col-12 p-0 d-flex w-100'}>
          <div className={'col-6'}>
            <Button
              className={'w-100'}
              color={'primary'}
              onClick={this.handleClick}
              key={v4()}
              disabled={(this.state as StateType).orderNumber !== orderNumber}
            >
              Ok
            </Button>
          </div>
          <div className={'col-6'}>
            <Button
              className={'w-100'}
              color={'secondary'}
              onClick={() => {
                this.close('');
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
