import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { PortEdit } from '../components/port-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PORTS_LIST_PATH, PORT_CREATE_PATH } from '../ports.route';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_PORT_LINK_KEY } from '../ports.service';
import { userHas } from '../../auth/auth.store';

export type PortScreenProps = { pageTitle: string; group: string };
export type PortRouteParams = {
  organizationId: string;
  portId: string | string;
  countryCode: string | string;
};

export const PortScreen = ({ pageTitle, group }: PortScreenProps) => {
  const navigate = useNavigate();

  const { organizationId, portId, countryCode }: PortRouteParams = useParams();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + PORT_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const closeScreen = useMemo(
    () => () => {
      // check if we can go back
      if (navigate.length > 2) {
        navigate(navigate.length - 1);
      } else {
        const { currentOrganization } = organizationsStore.getState();
        const path = generatePath(INTERNAL_PATH + PORTS_LIST_PATH, {
          organizationId: currentOrganization.organizationId,
        });
        navigate(path);
      }
    },
    [navigate],
  );

  const portIdMemo = useMemo(() => (portId !== 'create' ? portId : null), [
    portId,
  ]);
  const countryCodeMemo = useMemo(
    () => (countryCode !== 'create' ? countryCode : null),
    [countryCode],
  );

  return (
    <InternalLayout
      group={group}
      title={'Port'}
      createPath={userHas(CREATE_PORT_LINK_KEY) ? createPath : null}
      createLabel={'Port'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PortEdit
        portId={portIdMemo}
        countryCode={countryCodeMemo}
        onCancel={closeScreen}
        onPortCreated={closeScreen}
        onPortUpdated={closeScreen}
        onDelete={closeScreen}
      />
    </InternalLayout>
  );
};
