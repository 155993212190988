import {
  JobStatusDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  JobStatusListItemDtoPagedResult,
  CreateJobStatusCommandValues,
  UpdateJobStatusCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_JOBSTATUSES_LINK_KEY = 'get-jobstatuses';
export const CREATE_JOBSTATUS_LINK_KEY = 'create-jobstatus';
export const GET_JOBSTATUS_LINK_KEY = 'get-jobstatus';
export const UPDATE_JOBSTATUS_LINK_KEY = 'update-jobstatus';
export const DELETE_JOBSTATUS_LINK_KEY = 'delete-jobstatus';
export const IMPORT_JOBSTATUSES_LINK_KEY = 'import-jobstatuses';
export const EXPORT_JOBSTATUSES_LINK_KEY = 'export-jobstatuses';
export const JOBSTATUS_ENTITY_NAME = 'JobStatus';

export interface GetJobStatusParams {
  jobStatusId: number;
}

export interface ImportJobStatusesParams {
  organizationId: number;
}

export interface ExportJobStatusesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getJobStatusRequest = async (
  resource: LinkResourceBaseDto | null,
  { jobStatusId }: GetJobStatusParams,
): Promise<JobStatusDto> => {
  if (resource && jobStatusId) {
    const getJobStatusLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_JOBSTATUS_LINK_KEY,
    );
    if (getJobStatusLink) {
      const result = await execLink(getJobStatusLink, { jobStatusId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_JOBSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getJobStatusesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<JobStatusListItemDtoPagedResult> => {
  if (resource) {
    const getJobStatusesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_JOBSTATUSES_LINK_KEY,
    );
    if (getJobStatusesListLink) {
      const result = await execLink(getJobStatusesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_JOBSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createJobStatusRequest = async (
  resource: LinkResourceBaseDto,
  jobStatus: CreateJobStatusCommandValues,
) => {
  const createJobStatusLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_JOBSTATUS_LINK_KEY,
  );
  if (createJobStatusLink) {
    const result = await execLink(createJobStatusLink, jobStatus);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_JOBSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateJobStatusRequest = async (
  resource: LinkResourceBaseDto,
  jobStatus: UpdateJobStatusCommandValues,
) => {
  const updateJobStatusLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_JOBSTATUS_LINK_KEY,
  );
  if (updateJobStatusLink) {
    const result = await execLink(updateJobStatusLink, jobStatus);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_JOBSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteJobStatusRequest = async (jobStatus: JobStatusDto) => {
  const deleteJobStatusLink = jobStatus.links?.find(
    (x: LinkDto) => x.rel === DELETE_JOBSTATUS_LINK_KEY,
  );
  if (deleteJobStatusLink) {
    const result = await execLink(deleteJobStatusLink, jobStatus);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_JOBSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importJobStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportJobStatusesParams,
  file: File,
) => {
  const importJobStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_JOBSTATUSES_LINK_KEY,
  );
  if (importJobStatusesLink) {
    const result = await postFormData(importJobStatusesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_JOBSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportJobStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportJobStatusesParams,
) => {
  const exportJobStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_JOBSTATUSES_LINK_KEY,
  );
  if (exportJobStatusesLink) {
    const result = await downloadLinkFile(exportJobStatusesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_JOBSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};
