import React, { useEffect, useState } from 'react';
import {
  CustomFieldDto,
  CustomFieldType,
  PortDto,
} from '../../../../models/data.models';
import { SelectModeOfTransportationPaginate } from '../../../modeOfTransportations/components/modeOfTransportation-react-select.component';
import { Input } from '../input/input.component';
import { SelectEquipmentTypePaginate } from '../../../equipmentTypes/components/equipmentType-react-select.component';
import { RadioGroupComponent } from '../radio-group/radio-group.component';
import { Checkbox } from '../checkbox/checkbox.component';
import { SelectContactPaymentMethodPaginate } from '../../../contactPaymentMethods/components/contactPaymentMethods-react-select.component';
import { SelectPortPaginate } from '../../../ports/components/port-react-select.component';

export type CustomFieldsFormProps = {
  defaultValue?: {
    [key: string]: any;
  };
  customFields?: CustomFieldDto[];
  inputNamePrefix?: string;
  onChange?: (result: object) => void;
  isFieldsDisabled?: boolean;
};

export const CustomFieldsForm = ({
  defaultValue = {},
  customFields = [],
  inputNamePrefix = null,
  onChange = (result) => {},
  isFieldsDisabled,
}: CustomFieldsFormProps) => {
  const [stateValue, setStateValue] = useState(defaultValue);

  let gridColumns = 12;
  if (customFields.length > 0) {
    gridColumns = Math.round(12 / customFields.length);
  }

  const handleCheckboxChange = (key: string, value: boolean) => {
    const { DangerousItems } = stateValue;
    if (value) {
      setStateValue({
        ...stateValue,
        DangerousItems: {
          ...DangerousItems,
          value: DangerousItems?.value?.length
            ? [...DangerousItems?.value, key]
            : [key],
        },
      });
    } else {
      setStateValue({
        ...stateValue,
        DangerousItems: {
          ...DangerousItems,
          value: DangerousItems?.value.filter((item) => item !== key),
        },
      });
    }
  };

  useEffect(() => {
    onChange(stateValue);
  }, [stateValue]);

  const namePrefix = !!inputNamePrefix ? `${inputNamePrefix}.` : '';

  return (
    <div className={'row'}>
      {customFields.map((field) => {
        switch (field.customFieldType) {
          case CustomFieldType.ModeOfTransportation:
            return (
              <div className={`col-${gridColumns}`} key={field.internalName}>
                <SelectModeOfTransportationPaginate
                  id={`${namePrefix}customValues.${field.internalName}`}
                  nameId={`${namePrefix}customValues.${field.internalName}`}
                  required={false}
                  isMulti={false}
                  useContext={true}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  header={field.displayName}
                  selectedSort={'description'}
                  placeholder={field.displayName}
                  selectedValue={
                    stateValue[field.internalName] && stateValue['description']
                      ? {
                          modeOfTransportationId:
                            stateValue[field.internalName],
                          description: stateValue['description'],
                        }
                      : ''
                  }
                  selectedFilter={''}
                  isClearable={true}
                  onChangeModeOfTransportation={(value) =>
                    setStateValue((stateValue) => {
                      stateValue[field.internalName] =
                        value?.modeOfTransportationId;
                      stateValue['description'] = value?.description;
                      return { ...stateValue };
                    })
                  }
                  isDisabled={isFieldsDisabled || false}
                />
              </div>
            );
          case CustomFieldType.Port:
            return (
              <div className={`col-${gridColumns}`} key={field.internalName}>
                <SelectPortPaginate
                  id={`${namePrefix}customValues.${field.internalName}`}
                  nameId={`${namePrefix}customValues.${field.internalName}Name`}
                  header={field.displayName}
                  placeholder={`Select ${field.displayName}`}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={true}
                  isDisabled={isFieldsDisabled || false}
                  selectedValue={
                    stateValue[field.internalName] &&
                    stateValue[`${field.internalName}Name`]
                      ? {
                          portId: stateValue[field.internalName],
                          name: stateValue[`${field.internalName}Name`],
                        }
                      : ''
                  }
                  onChangePort={(value: PortDto) =>
                    setStateValue((stateValue) => {
                      stateValue[field.internalName] = value?.portId;
                      stateValue[`${field.internalName}Name`] = value?.name;
                      return { ...stateValue };
                    })
                  }
                />
              </div>
            );
          case CustomFieldType.EquipmentType:
            return (
              <div className={`col-${gridColumns}`} key={field.internalName}>
                <SelectEquipmentTypePaginate
                  id={`${namePrefix}customValues.${field.internalName}`}
                  nameId={`${namePrefix}customValues.${field.internalName}`}
                  readonly={false}
                  selectedSort={'name'}
                  required={false}
                  isMulti={false}
                  useContext={true}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  header={field.displayName}
                  placeholder={field.displayName}
                  isDisabled={isFieldsDisabled || false}
                  selectedValue={
                    stateValue[field.internalName] && stateValue['name']
                      ? {
                          equipmentTypeId: stateValue[field.internalName],
                          name: stateValue['name'],
                        }
                      : ''
                  }
                  selectedFilter={''}
                  onChangeEquipmentType={(value) =>
                    setStateValue((stateValue) => {
                      stateValue[field.internalName] = value?.equipmentTypeId;
                      stateValue['name'] = value?.name;
                      return { ...stateValue };
                    })
                  }
                />
              </div>
            );

          case CustomFieldType.Number:
          case CustomFieldType.Text:
            return (
              <div className={`col-${gridColumns}`} key={field.internalName}>
                <Input
                  name={`${namePrefix}customValues.${field.internalName}`}
                  label={field.displayName}
                  placeholder={field.displayName}
                  required={false}
                  type={'text'}
                  disabled={isFieldsDisabled || false}
                  onChange={(ev) => {
                    setStateValue((stateValue) => {
                      stateValue[field.internalName] = ev?.target?.value;
                      return { ...stateValue };
                    });
                  }}
                />
              </div>
            );

          case CustomFieldType.Date:
            return (
              <div className={`col-${gridColumns}`} key={field.internalName}>
                <Input
                  name={`${namePrefix}customValues.${field.internalName}`}
                  id={`${namePrefix}customValues.${field.internalName}`}
                  label={field.displayName}
                  placeholder={field.displayName}
                  required={false}
                  type={'date'}
                  defaultValue={
                    stateValue[field.internalName]
                      ? stateValue[field.internalName]
                      : ''
                  }
                  onChange={(data) => {
                    setStateValue((stateValue) => {
                      return {
                        ...stateValue,
                        [field.internalName]: data,
                      };
                    });
                  }}
                  disabled={isFieldsDisabled || false}
                />
              </div>
            );

          case CustomFieldType.Boolean:
            return (
              <Input
                key={field.internalName}
                defaultValue={stateValue[field.internalName]}
                onChange={(value) => {
                  setStateValue((stateValue) => {
                    stateValue[field.internalName] = value;
                    return { ...stateValue };
                  });
                }}
                type={'checkbox'}
                name={`${namePrefix}customValues.${field.internalName}`}
                label={field.displayName}
              />
            );

          case CustomFieldType.TextArea:
            return (
              <div
                key={field.internalName}
                className={`col-${field?.fieldConfig?.['col']}`}
              >
                <Input
                  name={`${namePrefix}customValues[${field.internalName}]`}
                  id={`${namePrefix}customValues.${field.internalName}`}
                  label={field.displayName}
                  placeholder={field.displayName}
                  required={false}
                  type={'textarea'}
                  disabled={isFieldsDisabled || false}
                  onChange={(ev) => {
                    setStateValue((stateValue) => {
                      stateValue[field.internalName] = ev?.target?.value;
                      return { ...stateValue };
                    });
                  }}
                />
              </div>
            );
          case CustomFieldType.PurposeOfCommodity:
            const purposeObject = stateValue?.PurposeOfCommodity;
            return (
              <div className={`col-12`} key={field.internalName}>
                <RadioGroupComponent
                  label={field?.displayName}
                  purposeObject={purposeObject}
                  onChange={(selectedValue) => {
                    setStateValue((stateValue) => {
                      stateValue.PurposeOfCommodity.value = selectedValue;
                      return { ...stateValue };
                    });
                  }}
                />
              </div>
            );

          case CustomFieldType.DangerousItems:
            const arrayOfDefaultValues = defaultValue?.DangerousItems;
            const selectedValues = defaultValue?.DangerousItems?.value;
            return (
              <div className={`col-12`} key={field.internalName}>
                <div className="input-label"> {field.displayName}: </div>
                {arrayOfDefaultValues &&
                  Object.keys(arrayOfDefaultValues)?.map((key) => {
                    const stringKey = key[0].toLowerCase() + key.slice(1);
                    if (stringKey != 'value')
                      return (
                        <Checkbox
                          key={stringKey}
                          label={arrayOfDefaultValues[key]}
                          onChange={(value) =>
                            handleCheckboxChange(stringKey, value)
                          }
                          name={stringKey}
                          defaultChecked={selectedValues?.includes(stringKey)}
                        />
                      );
                  })}
              </div>
            );
          case CustomFieldType.ContactPaymentMethod:
            return (
              <div className={`col-${gridColumns}`} key={field.internalName}>
                <SelectContactPaymentMethodPaginate
                  id={`${namePrefix}customValues.${field.internalName}`}
                  nameId={`${namePrefix}customValues.${field.internalName}`}
                  required={false}
                  isMulti={false}
                  useContext={true}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  header={'Payment Method'}
                  selectedSort={'description'}
                  placeholder={'Select Payment Method'}
                  selectedValue={
                    stateValue[field.internalName] && stateValue['description']
                      ? {
                          contactPaymentMethodId:
                            stateValue[field.internalName],
                          description: stateValue['description'],
                        }
                      : ''
                  }
                  selectedFilter={''}
                  isClearable={true}
                  onChangeContactPaymentMethod={(value) =>
                    setStateValue((stateValue) => {
                      return {
                        ...stateValue,
                        [field.internalName]: value?.contactPaymentMethodId,
                        description: value?.description,
                      };
                    })
                  }
                  isDisabled={isFieldsDisabled || false}
                />
              </div>
            );
        }
      })}
    </div>
  );
};
