import React, { useEffect, useState } from 'react';
import {
  CommodityDto,
  CommodityDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  commodityStore,
  getCommodities,
  deleteCommodity,
  getCommoditiesColumns,
} from '../commodities.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_COMMODITY_LINK_KEY,
  DELETE_COMMODITY_LINK_KEY,
  GetCommodityParams,
} from '../commodities.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { COMMODITY_CREATE_PATH } from '../commodities.route';
import { CommodityDialog } from './commodity.dialog';
import { authStore, userHas } from '../../auth/auth.store';
import { CommodityGrid } from '../../common/components/grid/commodity-grid.component';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';

export type CommoditiesListProps = {
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  goToDetails?: (commodityParams: GetCommodityParams) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    commodity: CommodityDto,
    commodityParams: GetCommodityParams,
  ) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const CommoditiesList = ({
  filter,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  goToDetails = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch,
}: CommoditiesListProps) => {
  const { user: currentUser } = authStore.getState();
  const [
    commodities,
    setCommodities,
  ] = useState<CommodityDtoPagedResult | null>(null);
  const [loading, setLoading] = useState(true);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  useEffect(() => {
    getCommoditiesColumns().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    getCommoditiesData();
  }, [offset, limit, sort, filter, search]);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const navigate = useNavigate();

  const getCommoditiesData = async () => {
    const commoditiesData = await getCommodities({
      offset,
      limit,
      sort,
      filter,
      search,
    });

    if (commoditiesData) {
      setCommodities(commoditiesData);
      setGetDataSuccess(true);
    }
  };

  const {
    commodityColumns: columns,
    defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(commodityStore);

  const onDeleteCommodity = async (commodity: CommodityDto) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Commodity`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteCommodity(commodity);
        onDelete();
        getCommoditiesData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditCommodity = (commodity: CommodityDto) => {
    goToDetails({ commodityId: commodity?.commodityId });
  };

  const onCreateNewCommodity = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + COMMODITY_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(createPath);
  };

  const onCommoditySelect = async (commodity, commodityId) => {
    onSelect?.(commodity, commodityId);
    const selectedCommodity = await showDialog({
      dialog: CommodityDialog,
      props: {
        commodity: commodity,
        commodityId: commodity.commodityId,
        title: 'Update Commodity',
        saveButtonRenderCondition:
          currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
        isEditModeInCommodities: true,
      },
    });
    if (selectedCommodity !== null) {
      getCommoditiesData();
    }
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <CommodityGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={['commodityId']}
          data={commodities?.items}
          columns={columns}
          search={search}
          offset={offset}
          limit={limit}
          filter={filter}
          sort={sort}
          total={commodities?.totalCount}
          onDelete={
            userHas(DELETE_COMMODITY_LINK_KEY, commodities?.items[0]?.links)
              ? onDeleteCommodity
              : null
          }
          onSearch={onSearch}
          onFilter={onFilter}
          onSort={onSort}
          onEdit={onEditCommodity}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onLimitChanged={onLimitChanged}
          onSelect={onCommoditySelect}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          showToolbar={false}
          showGridPanel={true}
        />
        {!(commodities?.items?.length || filter || search) && (
          <ListElementsNotFound
            entityName="Commodity"
            entityLinkKey={CREATE_COMMODITY_LINK_KEY}
            onClick={onCreateNewCommodity}
          />
        )}
      </div>
    );
  }
};
