import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { userHas } from '../../auth/auth.store';
import { CLAUSE_CREATE_PATH, CLAUSES_LIST_PATH } from '../clauses.route';
import { ClauseEdit } from '../components/clause-edit.component';
import { CREATE_CLAUSE_LINK_KEY } from '../clauses.service';

export type ClauseScreenProps = { pageTitle: string; group: string };

export const ClauseScreen = ({ pageTitle, group }: ClauseScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, clauseId } = useParams();

  function createPath() {
    return generatePath(INTERNAL_PATH + CLAUSE_CREATE_PATH, {
      organizationId,
    });
  }
  const onCommodityCreated = () => {
    const path = generatePath(INTERNAL_PATH + CLAUSES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'clause-created',
      type: 'success',
      autoHide: true,
      message: 'Clause successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCommodityUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CLAUSES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'clause-updated',
      type: 'success',
      autoHide: true,
      message: 'Clause successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CLAUSES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Clause'}
      createPath={userHas(CREATE_CLAUSE_LINK_KEY) ? createPath() : null}
      createLabel={'Clause'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ClauseEdit
        clauseId={clauseId}
        onClauseUpdated={onCommodityUpdated}
        onClauseCreated={onCommodityCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
