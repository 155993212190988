import { useState, useEffect } from 'react';
import { AttachmentDto } from '../../../models/data.models';

export type PicturePreviewProps = {
  attachment?: any;
  isAttachment: boolean;
  height?: string;
  documentName?: string;
};

export const PicturePreview = ({
  attachment,
  isAttachment = false,
  height,
  documentName,
}: PicturePreviewProps) => {
  const [attachmentData, setAttachmentData] = useState<AttachmentDto>(null);

  useEffect(() => {
    if (attachment) {
      setAttachmentData(attachment);
    }
  }, [attachment]);

  const getLabel = (documentType: string) => {
    return documentType?.split('.').pop() || 'Creating';
  };

  const isPictureUrl = (url: string) => {
    if (!url) return false;
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  return (
    <div>
      {isAttachment &&
      attachmentData &&
      isPictureUrl(attachmentData?.fileName) ? (
        <div>
          {attachmentData.previewUri && (
            <div className="w-100 d-flex justify-content-center">
              <img
                height={height ?? 'auto'}
                className={'picture-card-img'}
                alt={attachmentData?.fileName}
                src={attachmentData.previewUri}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center pt-3"
          style={{ minHeight: height ?? '100px' }}
        >
          <div
            className="documents-card-pictures"
            style={{
              minHeight: height ?? '100px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span className="text-center text-uppercase">
              {getLabel(documentName)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
