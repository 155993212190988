import { createEvent, createStore, Store } from 'effector';
import { PortDto } from '../../../models/data.models';

type PortReactSelectStore = {
  clearLoadOptionsCache: boolean;
  lastEditedPort: PortDto;
};

export const clearPortsLoadOptionsCache = createEvent();
export const updateLastEditedPort = createEvent<PortDto>();

const defaultState: PortReactSelectStore = {
  clearLoadOptionsCache: false,
  lastEditedPort: null,
};

export const portReactSelectStore: Store<PortReactSelectStore> = createStore(
  defaultState,
)
  .on(clearPortsLoadOptionsCache, (state, payload) => {
    return { ...state, clearLoadOptionsCache: !state.clearLoadOptionsCache };
  })
  .on(updateLastEditedPort, (state, payload) => {
    return { ...state, lastEditedPort: payload };
  });
