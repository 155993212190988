import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { Panel } from '../../common/components/panel/panel.component';
import { ChargeForm } from '../../charges/components/charge.form';
import { ChargeForOrderList } from '../../charges/components/charge-forOrder-list.component';
import { GetChargeParams } from '../../charges/charges.service';
import { showDialog } from '../../common/dialog.store';
import { ChargeDialog } from '../../charges/components/charge.dialog';
import { ChargeStatisticValues } from '../../../models/custom.models';
import { chargeStoreForAccountingTransaction } from '../../charges/charges.store';
import {
  AccountingAccountDto,
  AccountingTransactionDto,
  AccountingTransactionStatus,
  AccountingTransactionType,
  AddressType,
  ChargeDto,
  ChargeStatuses,
  ContactAddressDto,
  ContactDto,
  ContactType,
  DivisionDto,
  DocumentTemplateType,
  PaidAs,
  PaymentDto,
  PaymentTermDto,
  VisibleTransactions,
  WorkflowTriggerDtoListResult,
} from '../../../models/data.models';
import {
  createAccountingTransactionFx,
  getAccountingTransactionFx,
  updateAccountingTransactionFx,
} from '../../accountingTransactions/accountingTransactions.store';
import { AccountingTransactionForm } from '../../accountingTransactions/components/accountingTransaction.form';
import {
  getDocumentTemplatesFx,
  getRenderedDocumentLink,
} from '../../documentTemplates/documentTemplates.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import {
  getEnumKeyByValue,
  getEnumValues,
  getPaidAs,
} from '../../../utils/helper.utils';
import { SendEmailDialog } from '../../sendEmail/components/sendEmail.dialog';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { AccountingTransactionDefaultValues } from '../../common/DefaultValues';
import { PaymentDialog } from '../../payments/components/payment.dialog';
import { paymentInitialState } from '../../payments/components/payment-edit.component';
import * as Yup from 'yup';
import { AccountingTransactionStatus as TransactionStatus } from '../../accountingTransactions/components/accounting-transaction-status.component';
import { authStore, userHas } from '../../auth/auth.store';
import {
  CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY,
} from '../../accountingTransactions/accountingTransactions.service';
import { useCallback } from 'react';
import { WorkflowTriggerDialog } from '../../workflowTriggers/components/workflowTrigger.dialog';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { IAction, getAction } from '../../common/components/actions/actions';
import { EXECUTE_WORKFLOWTRIGGER_LINK_KEY } from '../../workflowTriggers/workflowTriggers.service';
import { AiFillCode } from 'react-icons/ai';
import { getWorkflowTriggersFx } from '../../workflowTriggers/workflowTriggers.store';

export type InvoiceEditProps = {
  accountingTransactionId?: number | null;
  onAccountingTransactionCreated?: (
    accountingTransaction: AccountingTransactionDto,
  ) => void;
  onAccountingTransactionUpdated?: (
    accountingTransaction: AccountingTransactionDto,
  ) => void;
  onAccountingTransactionLoaded?: (
    accountingTransaction: AccountingTransactionDto,
  ) => void;
  onCancel?: () => void;
};

const initialState: AccountingTransactionDto = {
  divisionId: AccountingTransactionDefaultValues.divisionId,
  divisionName: AccountingTransactionDefaultValues.divisionName,
  paidAs: AccountingTransactionDefaultValues.paidAs,
  amountDue: AccountingTransactionDefaultValues.amountDue,
  amountPaid: AccountingTransactionDefaultValues.amountPaid,
  applyToContactName: AccountingTransactionDefaultValues.applyToContactName,
  billToContactAddressName:
    AccountingTransactionDefaultValues.billToContactAddressName,
  paymentTermsDescription:
    AccountingTransactionDefaultValues.paymentTermsDescription,
  accountName: AccountingTransactionDefaultValues.accountName,
  created: undefined,
  createdBy: '',
  createdByUserName: '',
  lastModified: null,
  lastModifiedBy: '',
  lastModifiedByUserName: '',
  accountingTransactionId: 0,
  accountId: AccountingTransactionDefaultValues.accountId,
  accountingTransactionStatus:
    AccountingTransactionDefaultValues.accountingTransactionStatus,
  accountingTransactionType:
    AccountingTransactionDefaultValues.accountingTransactionType,
  applyToContactID: AccountingTransactionDefaultValues.applyToContactID,
  billToContactAddressId:
    AccountingTransactionDefaultValues.billToContactAddressId,
  dueDate: AccountingTransactionDefaultValues.dueDate,
  note: AccountingTransactionDefaultValues.note,
  organizationId: null,
  paymentTermsId: AccountingTransactionDefaultValues.paymentTermsId,
  transactionDate: AccountingTransactionDefaultValues.transactionDate,
  transactionNumber: AccountingTransactionDefaultValues.transactionNumber,
  applyToContactType: AccountingTransactionDefaultValues.applyToContactType,
  totalAmount: AccountingTransactionDefaultValues.totalAmount,
  links: [],
};

const invoiceSchema = Yup.object().shape({
  transactionDate: Yup.date().required("Can't be blank").nullable(true),
  applyToContactID: Yup.string().required("Can't be blank").nullable(true),
  dueDate: Yup.date().required("Can't be blank").nullable(true),
  accountId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
  paidAs: Yup.string().required("Can't be blank").nullable(true),
});

export const InvoiceEdit = ({
  accountingTransactionId,
  onAccountingTransactionLoaded = () => {},
  onAccountingTransactionCreated = () => {},
  onAccountingTransactionUpdated = () => {},
  onCancel = () => {},
}: InvoiceEditProps) => {
  const { user: currentUser } = authStore.getState();

  const limit = 20;
  const isCreateMode = !accountingTransactionId || accountingTransactionId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [charges, setCharges] = useState<ChargeDto[] | null>([]);
  const [
    accountingTransaction,
    setAccountingTransaction,
  ] = useState<AccountingTransactionDto | null>(null);
  const [
    initialValues,
    setInitialValues,
  ] = useState<AccountingTransactionDto | null>(initialState);
  const forceUpdate: () => void = useState()[1].bind(null, {});

  const [
    invoiceDocumentTemplatesCount,
    setInvoiceDocumentTemplatesCount,
  ] = useState<number>(0);

  const [filterApplyToContact, setFilterApplyToContact] = useState<string>(` `);
  const initialChargeStatisticValues: ChargeStatisticValues = {
    amount: 0,
    amountDue: 0,
    paidAs: null,
    tax: 0,
    totalAmount: 0,
  };
  const [
    chargeStatisticValues,
    setChargeStatisticValues,
  ] = useState<ChargeStatisticValues | null>(initialChargeStatisticValues);
  const [
    applyToContactPaidAs,
    setApplyToContactPaidAs,
  ] = useState<PaidAs | null>(null);
  const applyToContactContactTypes = [
    ContactType.Customer,
    ContactType.Vendor,
    ContactType.ForwardingAgent,
    ContactType.Contact,
  ];

  const [actions, setActions] = useState([]);

  useEffect(() => {
    setChargeStatisticValues(initialChargeStatisticValues);
    setAccountingTransaction((accountingTransactionDto) => {
      accountingTransactionDto = initialState;
      return { ...accountingTransactionDto };
    });
  }, []);

  useEffect(() => {
    getDocumentTemplatesFx({
      filter: `documentTemplateType:InvoiceDocument AND isInactive: false`,
    }).then((documentTemplates) => {
      setInvoiceDocumentTemplatesCount(documentTemplates.items?.length);
    });
  }, []);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (accountingTransactionId) {
      getAccountingTransactionFx({ accountingTransactionId }).then(
        (accountingTransactionDto: AccountingTransactionDto) => {
          setAccountingTransaction(accountingTransactionDto);
          setInitialValues(accountingTransactionDto);
          setOffset(0);
          setFilterApplyToContact(
            accountingTransactionDto.applyToContactID
              ? `contactId: ${accountingTransactionDto.applyToContactID}`
              : ` `,
          );
          accountingTransactionDto.applyToContactID
            ? getPaidAs(accountingTransactionDto.applyToContactID).then(
                (paidAs) => {
                  setApplyToContactPaidAs(paidAs);
                },
              )
            : setApplyToContactPaidAs(
                AccountingTransactionDefaultValues.paidAs,
              );
          onAccountingTransactionLoaded(accountingTransactionDto);
          setCharges(accountingTransactionDto.charges);
          countChargeStatisticValues(accountingTransactionDto);
          setIsLoading(false);
        },
      );
    } else {
      throw new Error('Invoices keys were not provided');
    }
  }, [accountingTransactionId]);

  const countChargeStatisticValues = (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    setChargeStatisticValues((statisticValue) => {
      statisticValue.amountDue = 0;
      statisticValue.tax = 0;
      statisticValue.totalAmount = 0;
      if (accountingTransaction) {
        statisticValue.amountDue = accountingTransaction.amountDue;
      } else {
        statisticValue.amountDue = 0;
      }
      accountingTransaction?.charges.forEach((item) => {
        const amount = item?.amount;
        const salesTaxAmount = item.salesTaxRate * amount;
        statisticValue.tax += salesTaxAmount;
        statisticValue.totalAmount += amount + salesTaxAmount;
      });
      statisticValue.totalAmount = Number(
        statisticValue?.totalAmount.toFixed(2),
      );
      return { ...statisticValue };
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      setAccountingTransaction((accountingTransactionDto) => {
        if (!accountingTransactionDto) {
          accountingTransactionDto = initialState;
        }
        accountingTransactionDto.charges = charges;
        countChargeStatisticValues(accountingTransactionDto);
        return { ...accountingTransactionDto };
      });
    }
  }, [charges]);

  const getWorkflowActions = useCallback(
    (triggers: WorkflowTriggerDtoListResult = {}) => {
      const workflowActions: IAction[] = [];
      for (const trigger of triggers.items) {
        const actionItem = getAction(
          EXECUTE_WORKFLOWTRIGGER_LINK_KEY,
          trigger.workflowName,
          <AiFillCode />,
          async () => {
            await showDialog({
              dialog: WorkflowTriggerDialog,
              props: {
                workflowId: trigger?.workflowId,
                title: trigger?.workflowName || 'Execute Workflow',
                defaultVariables: {
                  invoice: initialValues,
                },
              },
            });
          },
          trigger?.links,
        );
        if (actionItem) workflowActions.push(actionItem);
      }
      return workflowActions;
    },
    [initialValues],
  );

  const loadWorkflowTriggers = useCallback(async () => {
    const triggers = await getWorkflowTriggersFx({
      triggerType: 'Manual',
      organizationId: initialValues?.organizationId,
      entityName: 'AccountingTransaction',
    });

    setActions(getWorkflowActions(triggers));
  }, [initialValues]);

  useEffect(() => {
    loadWorkflowTriggers();
  }, [initialValues]);

  const onSubmit = (data: AccountingTransactionDto) => {
    if (data) {
      data.accountingTransactionType = AccountingTransactionType.Invoice;
      charges.map((charge) => {
        if (charge?.chargeStatus === ChargeStatuses.Open) {
          charge.chargeStatus = ChargeStatuses.Posted;
        }
      });
      data.charges = charges;
    }
    setIsSending(true);
    if (isCreateMode) {
      data.accountingTransactionStatus = AccountingTransactionStatus.Open;
      createAccountingTransactionFx(data)
        .then((result) => {
          onAccountingTransactionCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updateAccountingTransactionFx(data)
        .then((result) => {
          onAccountingTransactionUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  const onDueDateChange = (date?: any) => {
    setAccountingTransaction((accountingTransactionDto) => {
      if (!accountingTransactionDto) {
        accountingTransactionDto = initialState;
      }
      accountingTransactionDto.dueDate = new Date(date);
      return { ...accountingTransactionDto };
    });
  };
  const onTransactionDateChange = (date?: any) => {
    setAccountingTransaction((accountingTransactionDto) => {
      if (!accountingTransactionDto) {
        accountingTransactionDto = initialState;
      }
      accountingTransactionDto.transactionDate = new Date(date);
      return { ...accountingTransactionDto };
    });
  };

  const onPaidDateChange = (date?: any) => {
    setAccountingTransaction((accountingTransactionDto) => {
      if (!accountingTransactionDto) {
        accountingTransactionDto = initialState;
      }
      accountingTransactionDto.paidDate = new Date(date);
      return { ...accountingTransactionDto };
    });
  };

  const onChargeCreated = (charge: ChargeDto) => {
    if (!charges) {
      const initialCharge: ChargeDto[] = [];
      setCharges(initialCharge);
    }
    setCharges((chargesDto) => [...chargesDto, charge]);
  };
  const isDisabledContact = (): boolean => {
    if (
      filterApplyToContact == null ||
      filterApplyToContact === ' ' ||
      accountingTransaction?.applyToContactID == null ||
      accountingTransaction?.applyToContactID == undefined
    ) {
      return true;
    }
    return false;
  };
  const getCharges = (): ChargeDto[] => {
    return charges;
  };
  const goToDetailsCharge = (chargeParams: GetChargeParams) => {
    const cloneCharge = Object.assign({}, chargeParams.charge);
    if (chargeParams.charge) {
      showDialog({
        dialog: ChargeDialog,
        props: {
          title: 'Update Charge',
          charge: chargeParams?.charge,
          chargeId: chargeParams?.charge?.chargeId,
          chargeType: chargeParams?.charge?.chargeType,
          className: 'charge-modal',
          isEditMode: true,
          saveButtonRenderCondition:
            userHas(
              UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY,
              accountingTransaction.links,
            ) && !currentUser?.isInOperationRole,
          charges: charges,
        },
      }).then(
        (result) => {
          setOffset(0);
          if (result !== null) {
            const itemsResult: ChargeDto[] = charges.map((obj) => {
              const compareChargeResult =
                obj.chargeId === chargeParams?.charge.chargeId;
              if (compareChargeResult) obj = result;
              return obj;
            });
            setCharges(itemsResult.sort((a, b) => a.chargeId - b.chargeId));
          } else {
            setCharges((oldCharges) => {
              const index = oldCharges.findIndex(
                (charge) => charge.chargeId === cloneCharge.chargeId,
              );
              oldCharges[index] = cloneCharge;
              return oldCharges;
            });
            forceUpdate();
          }
        },
        () => {},
      );
    }
  };

  const sendEmail = () => {
    updateAccountingTransactionFx(accountingTransaction).then(
      () => {
        getAccountingTransactionFx({
          accountingTransactionId:
            accountingTransaction.accountingTransactionId,
        }).then(
          (accountingTransactionDto) => {
            showDialog({
              dialog: SendEmailDialog,
              props: {
                title: 'Send Invoice',
                className: 'send-email-modal',
                selectedRecipientId: accountingTransactionDto?.applyToContactID,
                recipientIds: [accountingTransactionDto?.applyToContactID],
                metadata: {
                  accountingTransactionId:
                    accountingTransactionDto?.accountingTransactionId,
                  applyToContactId: accountingTransactionDto?.applyToContactID,
                },
                emailTemplate: getEnumKeyByValue(
                  DocumentTemplateType.InvoiceEmail,
                  DocumentTemplateType,
                ),
                documentTypes: getEnumKeyByValue(
                  [DocumentTemplateType.InvoiceDocument],
                  DocumentTemplateType,
                ),
                onEmailSent: () => {},
                onEmailTemplateLoaded: () => {},
              },
            }).then(() => {});
          },
          () => {},
        );
      },
      () => {},
    );
  };

  const voidTransaction = () => {
    showDialog({
      dialog: Confirm,
      props: {
        title: 'Void Invoice',
        message: 'Are you sure?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        const voidedTransaction = {
          ...initialValues,
          accountingTransactionStatus: AccountingTransactionStatus.Void,
        };
        onSubmit(voidedTransaction);
      }
    });
  };

  const addPayment = async () => {
    const payment: PaymentDto = {
      ...paymentInitialState,
      accountingAccountId: accountingTransaction.accountId,
      applyToContactId: accountingTransaction.applyToContactID,
      applyToContactName: accountingTransaction.applyToContactName,
      accountingAccountName: accountingTransaction.accountName,
      accountingAccountType: accountingTransaction.accountType,
      amountReceived: chargeStatisticValues.amountDue,
      amountPaid: chargeStatisticValues.amountDue,
      accountingTransactionPayments: [
        {
          ...accountingTransaction,
          amountApplied: chargeStatisticValues.amountDue,
        },
      ],
      paymentDate: new Date(),
    };
    showDialog({
      dialog: PaymentDialog,
      props: {
        paymentId: 0,
        title: 'Add Payment',
        isModal: true,
        payment,
        selectedFilter: `accountingTransactionId:${accountingTransaction?.accountingTransactionId}`,
      },
    }).then(
      (paymentDto) => {
        if (paymentDto !== null) {
          getAccountingTransactionFx({ accountingTransactionId }).then(
            (accountingTransactionDto: AccountingTransactionDto) => {
              setCharges((currentCharges) => {
                return currentCharges.map((charge) => {
                  const chargeIndex = accountingTransactionDto.charges.findIndex(
                    (updatedCharge) =>
                      charge.chargeId === updatedCharge.chargeId,
                  );
                  if (chargeIndex > -1) {
                    return accountingTransactionDto.charges[chargeIndex];
                  } else {
                    return charge;
                  }
                });
              });
              setAccountingTransaction((currentAccountingTransaction) => {
                currentAccountingTransaction.amountDue =
                  accountingTransactionDto.amountDue;
                currentAccountingTransaction.accountingTransactionStatus =
                  accountingTransactionDto.accountingTransactionStatus;
                currentAccountingTransaction.amountPaid =
                  accountingTransactionDto.amountPaid;
                currentAccountingTransaction.accountingTransactionStatus =
                  accountingTransactionDto.accountingTransactionStatus;
                currentAccountingTransaction.paidDate =
                  accountingTransactionDto?.paidDate;
                return currentAccountingTransaction;
              });
              setInitialValues(accountingTransactionDto);
            },
          );
        }
      },
      () => {},
    );
  };

  const getInvoiceDocument = () => {
    updateAccountingTransactionFx(accountingTransaction).then(
      () => {
        getAccountingTransactionFx({
          accountingTransactionId:
            accountingTransaction.accountingTransactionId,
        }).then(
          (accountingTransactionDto) => {
            window.open(
              getRenderedDocumentLink(DocumentTemplateType.InvoiceDocument, {
                accountingTransactionId:
                  accountingTransactionDto.accountingTransactionId,
              }),
              '_blank',
            );
          },
          () => {},
        );
      },
      () => {},
    );
  };

  const isNoInvoiceTemplate = (): boolean => {
    return invoiceDocumentTemplatesCount === 0;
  };

  const isAddPaymentDisabled = (): boolean => {
    return (
      accountingTransaction?.accountingTransactionStatus ===
      AccountingTransactionStatus.Paid
    );
  };

  const isVoidDisabled = (): boolean => {
    return (
      accountingTransaction?.accountingTransactionStatus ===
      AccountingTransactionStatus.Void
    );
  };

  const isRestrictedVoidAndPay = (): boolean => {
    return currentUser?.isInOperationRole;
  };

  return (
    <Tabs>
      <TabList>
        <div className="d-flex justify-content-between">
          <div>
            <Tab>Invoice</Tab>
          </div>
          {!isCreateMode && (
            <div className="d-flex justify-content-between">
              <ActionsDropdown size="sm" actions={actions} className={'mr-2'} />
              <ActionBarComponent
                buttonText={<>&middot;&middot;&middot;</>}
                style={{
                  minWidth: 'fit-content',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <button
                  type={'button'}
                  onClick={getInvoiceDocument}
                  disabled={isNoInvoiceTemplate()}
                  title={
                    isNoInvoiceTemplate()
                      ? 'There is no active invoice document template'
                      : null
                  }
                >
                  Get Invoice
                </button>
                <button type={'button'} onClick={sendEmail}>
                  Send Invoice
                </button>
                <button
                  type={'button'}
                  onClick={voidTransaction}
                  disabled={
                    isVoidDisabled() ||
                    isAddPaymentDisabled() ||
                    isRestrictedVoidAndPay()
                  }
                  title={
                    isVoidDisabled() ||
                    isAddPaymentDisabled() ||
                    isRestrictedVoidAndPay()
                      ? isRestrictedVoidAndPay()
                        ? 'Restricted'
                        : isAddPaymentDisabled()
                        ? 'This transaction is already fully paid'
                        : 'This transaction is already voided'
                      : null
                  }
                >
                  Void Invoice
                </button>
                <button
                  type={'button'}
                  onClick={addPayment}
                  data-cy="invoice-add-payment"
                  disabled={
                    isAddPaymentDisabled() ||
                    isVoidDisabled() ||
                    isRestrictedVoidAndPay()
                  }
                  title={
                    isAddPaymentDisabled() ||
                    isVoidDisabled() ||
                    isRestrictedVoidAndPay()
                      ? isRestrictedVoidAndPay()
                        ? 'Restricted'
                        : isAddPaymentDisabled()
                        ? 'This transaction is already fully paid'
                        : 'This transaction is already voided'
                      : null
                  }
                >
                  Add Payment
                </button>
              </ActionBarComponent>
            </div>
          )}
        </div>
      </TabList>
      <AccountingTransactionForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        id={'AccountingTransaction'}
        validationSchema={invoiceSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {!isCreateMode && (
              <div className="row">
                <div className="col-2">
                  <AccountingTransactionForm.PaidDate
                    defaultValue={accountingTransaction?.paidDate}
                    onChange={onPaidDateChange}
                  />
                </div>
                <div className="col-10">
                  <TransactionStatus
                    accountingTransactionStatus={
                      accountingTransaction?.accountingTransactionStatus
                    }
                  />
                </div>
              </div>
            )}
            <div className="row justify-content-between">
              <div className="row col-5">
                <div className="col-5">
                  <AccountingTransactionForm.TransactionNumber
                    disabled={isCreateMode ? false : true}
                  />
                </div>
                <div className="col-5">
                  <AccountingTransactionForm.TransactionDate
                    defaultValue={accountingTransaction?.transactionDate}
                    onChange={onTransactionDateChange}
                  />
                </div>
              </div>
              <div className="col-5 applyToContact-input">
                <AccountingTransactionForm.ApplyToContactSelect
                  defaultValue={
                    accountingTransaction &&
                    accountingTransaction.applyToContactID != null &&
                    accountingTransaction.applyToContactName != null
                      ? {
                          contactId: accountingTransaction.applyToContactID,
                          name: accountingTransaction.applyToContactName,
                          contactType: accountingTransaction.applyToContactType,
                        }
                      : ''
                  }
                  onChange={(contactDto?: ContactDto, context?: any) => {
                    setAccountingTransaction((accountingTransactionDto) => {
                      if (!accountingTransactionDto) {
                        accountingTransactionDto = initialState;
                      }
                      accountingTransactionDto.applyToContactID =
                        contactDto?.contactId;
                      accountingTransactionDto.applyToContactName =
                        contactDto?.name;
                      accountingTransactionDto.applyToContactType =
                        contactDto?.contactType;
                      accountingTransactionDto.billToContactAddressId = null;
                      accountingTransactionDto.billToContactAddressName = null;
                      return { ...accountingTransactionDto };
                    });
                    setFilterApplyToContact(
                      contactDto ? `contactId: ${contactDto.contactId}` : ' ',
                    );
                    if (!contactDto) {
                      context.setFieldValue('applyToContactID', null);
                    }
                    contactDto?.contactId
                      ? getPaidAs(contactDto?.contactId).then((paidAs) => {
                          setApplyToContactPaidAs(paidAs);
                        })
                      : setApplyToContactPaidAs(
                          AccountingTransactionDefaultValues.paidAs,
                        );
                  }}
                  id="applyToContactID"
                  nameId="applyToContactName"
                  header="Apply To Contact"
                  required={true}
                  contactTypes={applyToContactContactTypes}
                  selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="row col-5">
                <div className="col-5">
                  <AccountingTransactionForm.PaymentTermsSelect
                    selectedFilter={` `}
                    id="paymentTermsId"
                    nameId="paymentTermsDescription"
                    header="Payment Term"
                    required={false}
                    defaultValue={
                      accountingTransaction &&
                      accountingTransaction.paymentTermsId != null &&
                      accountingTransaction.paymentTermsDescription != null
                        ? {
                            paymentTermId: accountingTransaction.paymentTermsId,
                            description:
                              accountingTransaction.paymentTermsDescription,
                          }
                        : ''
                    }
                    onChange={(paymentTermDto?: PaymentTermDto, context?) => {
                      setAccountingTransaction((accountingTransactionDto) => {
                        if (!accountingTransactionDto) {
                          accountingTransactionDto = initialState;
                        }
                        accountingTransactionDto.paymentTermsId =
                          paymentTermDto?.paymentTermId;
                        accountingTransactionDto.paymentTermsDescription =
                          paymentTermDto?.description;
                        accountingTransactionDto.dueDate = new Date();
                        accountingTransactionDto.dueDate.setDate(
                          new Date(
                            accountingTransactionDto.transactionDate,
                          ).getDate() + paymentTermDto.netDueDays,
                        );
                        context?.setFieldValue(
                          'dueDate',
                          accountingTransactionDto.dueDate,
                        );
                        return { ...accountingTransactionDto };
                      });
                    }}
                  />
                </div>
                <div className="col-5">
                  <AccountingTransactionForm.DueDate
                    defaultValue={accountingTransaction?.dueDate}
                    onChange={onDueDateChange}
                  />
                </div>
              </div>
              <div className="col-5">
                <AccountingTransactionForm.BillToContactAddressSelect
                  selectedFilter={filterApplyToContact}
                  disabled={isDisabledContact()}
                  defaultValue={
                    accountingTransaction &&
                    accountingTransaction.billToContactAddressId != null &&
                    accountingTransaction.billToContactAddressName != null
                      ? {
                          contactAddressId:
                            accountingTransaction.billToContactAddressId,
                          addressLine:
                            accountingTransaction.billToContactAddressName,
                        }
                      : ''
                  }
                  onChange={(contactAddressDto?: ContactAddressDto) => {
                    setAccountingTransaction((accountingTransactionDto) => {
                      if (!accountingTransactionDto) {
                        accountingTransactionDto = initialState;
                      }
                      accountingTransactionDto.billToContactAddressId =
                        contactAddressDto?.contactAddressId;
                      accountingTransactionDto.billToContactAddressName =
                        contactAddressDto?.addressLine;
                      return { ...accountingTransactionDto };
                    });
                  }}
                  sort={'addressLine'}
                  id="billToContactAddressId"
                  nameId="billToContactAddressName"
                  header="Bill To Contact Address"
                  required={false}
                  className={'h-100'}
                  contactId={accountingTransaction?.applyToContactID}
                  contactType={accountingTransaction?.applyToContactType}
                  addressType={AddressType.Other}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="row col-5">
                <div className="col-10">
                  <AccountingTransactionForm.AccountSelect
                    selectedFilter={``}
                    selectedName={accountingTransaction?.accountName}
                    id="accountId"
                    nameId="accountName"
                    header="Account"
                    required={true}
                    listHeader={'List Account'}
                    placeholder={'Select Account'}
                    defaultValue={
                      accountingTransaction &&
                      accountingTransaction.accountId != null &&
                      accountingTransaction.accountName != null &&
                      accountingTransaction.accountType != null
                        ? {
                            accountId: accountingTransaction.accountId,
                            accountName: accountingTransaction.accountName,
                            accountType: accountingTransaction.accountType,
                          }
                        : ''
                    }
                    onChange={(accountingAccountDto?: AccountingAccountDto) => {
                      setAccountingTransaction((accountingTransactionDto) => {
                        if (!accountingTransactionDto) {
                          accountingTransactionDto = initialState;
                        }
                        accountingTransactionDto.accountId =
                          accountingAccountDto?.accountId;
                        accountingTransactionDto.accountName =
                          accountingAccountDto?.accountName;
                        accountingTransactionDto.accountType =
                          accountingAccountDto?.accountType;
                        return { ...accountingTransactionDto };
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-5">
                <AccountingTransactionForm.Division
                  id={'divisionId'}
                  header={'Division'}
                  required={true}
                  defaultValue={
                    accountingTransaction &&
                    accountingTransaction.divisionId != null &&
                    accountingTransaction.divisionName != null
                      ? {
                          divisionId: accountingTransaction.divisionId,
                          divisionName: accountingTransaction.divisionName,
                        }
                      : ''
                  }
                  onChange={(data?: DivisionDto) => {
                    setAccountingTransaction((accountingTransactionDto) => {
                      if (!accountingTransactionDto) {
                        accountingTransactionDto = initialState;
                      }
                      accountingTransactionDto.divisionId = data?.divisionId;
                      accountingTransactionDto.divisionName =
                        data?.divisionName;
                      return { ...accountingTransactionDto };
                    });
                  }}
                  nameId={'divisionName'}
                  defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                  disabled={
                    !(
                      (currentUser?.isInOrgAdminRole ||
                        currentUser?.isInAccountingRole) &&
                      VisibleTransactions[currentUser?.visibleTransactions] ===
                        VisibleTransactions.AllTransactions
                    )
                  }
                />
              </div>
            </div>
            <div className={''}>
              <hr />
              <ChargeForOrderList
                chargeStoreForList={chargeStoreForAccountingTransaction}
                limit={limit}
                showIncomeCharge={
                  accountingTransaction?.accountingTransactionStatus !==
                    AccountingTransactionStatus.Paid &&
                  userHas(CREATE_ACCOUNTINGTRANSACTION_LINK_KEY)
                    ? true
                    : false
                }
                showExpenseCharge={false}
                showPagination={false}
                onPageChanged={(page) => setOffset(page * limit)}
                offset={offset}
                onChargeCreated={onChargeCreated}
                items={getCharges()}
                goToDetails={goToDetailsCharge}
                changeItems={setCharges}
                selectedApplyToContact={{
                  contactId: accountingTransaction.applyToContactID,
                  name: accountingTransaction.applyToContactName,
                  contactType: accountingTransaction.applyToContactType,
                  paidAs: accountingTransaction.paidAs,
                }}
                isCreateModeForTransaction={true}
                addIncomeButtonName="Add Item"
                isCreateMode={isCreateMode}
                isLoading={isLoading}
                userCanDelete={
                  (userHas(
                    UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY,
                    accountingTransaction?.links,
                  ) &&
                    !currentUser?.isInOperationRole) ||
                  isCreateMode
                }
              />
            </div>
            <div className="row justify-content-between mt-5">
              <div className={'col-2'}>
                <AccountingTransactionForm.PaidAs
                  defaultValue={
                    accountingTransaction?.paidAs
                      ? {
                          label: PaidAs[accountingTransaction?.paidAs],
                          value: accountingTransaction?.paidAs,
                        }
                      : ''
                  }
                  options={getEnumValues(PaidAs)}
                  onChange={(paidAs?: any) => {
                    setAccountingTransaction((accountingTransactionDto) => {
                      if (!accountingTransactionDto) {
                        accountingTransactionDto = initialState;
                      }
                      accountingTransactionDto.paidAs = PaidAs[paidAs?.value];
                      return { ...accountingTransactionDto };
                    });
                  }}
                  required={true}
                />
              </div>
              <div className="col-6">
                <ChargeForm
                  className={''}
                  initialValues={chargeStatisticValues}
                  id="chargeForm"
                >
                  <div className={'row'}>
                    <div className={'offset-3 col-3'}>
                      <ChargeForm.Tax readonly={'readonly'} />
                    </div>
                    <div className={'col-3'}>
                      <ChargeForm.TotalAmount readonly={'readonly'} />
                    </div>
                    <div className={'col-3'}>
                      <ChargeForm.AmountDue readonly={'readonly'} />
                    </div>
                  </div>
                </ChargeForm>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="row col-5">
                <div className="col-10">
                  <AccountingTransactionForm.Note />
                </div>
              </div>
              <div className="justify-content-end row col-6 pt-5 mt-1">
                {((userHas(
                  UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY,
                  accountingTransaction?.links,
                ) &&
                  !currentUser?.isInOperationRole) ||
                  isCreateMode) && (
                  <div className="col-6">
                    <Button
                      name="save-accountingTransaction"
                      type="submit"
                      form={'AccountingTransaction'}
                      color="primary"
                      className="btn-block"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Invoice
                    </Button>
                  </div>
                )}
                <div className="col-6">
                  <Button
                    type="button"
                    color="primary"
                    onClick={onCancel}
                    className="btn-secondary btn-block"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </AccountingTransactionForm>
    </Tabs>
  );
};
