import { OrderEditFormDto } from '../../../models/custom.models';
import { OrderDocumentDto, OrderDto } from '../../../models/data.models';
import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDocumentsEdit } from './orderDocuments.edit';

export type OrderDocumentsDialogProperties = {
  title: string;
  documentTemplateFilter?: string;
  saveButtonRenderCondition?: boolean;
  isBolCreationDisabled?: () => boolean;
  orderContext: OrderEditFormDto;
  className?: string;
  onSaveOrder: (data: OrderDto) => OrderDto;
};

export class OrderDocumentsDialog extends Dialog<OrderDocumentsDialogProperties> {
  constructor(props: OrderDocumentsDialogProperties) {
    super(props);
    this.onOrderDocumentsSaved = this.onOrderDocumentsSaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onOrderDocumentsSaved = () => {
    this.close();
  };

  onCancel = () => {
    this.close(null);
  };

  isEditMode: boolean | undefined = false;

  renderContent(): any {
    const {
      documentTemplateFilter,
      saveButtonRenderCondition,
      isBolCreationDisabled,
      title,
      orderContext,
      onSaveOrder,
    } = this.props as OrderDocumentsDialogProperties;
    return (
      <div className="modal-order-documents">
        <OrderDocumentsEdit
          title={title}
          isBolCreationDisabled={isBolCreationDisabled}
          documentTemplateFilter={documentTemplateFilter}
          onOrderDocumentsCreated={this.onOrderDocumentsSaved}
          saveButtonRenderCondition={saveButtonRenderCondition}
          onCancel={this.onCancel}
          orderContext={orderContext}
          onSaveOrder={onSaveOrder}
        />
      </div>
    );
  }
}
