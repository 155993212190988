import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { StateDto, StateDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  stateStore,
  getStatesColumnsFx,
  getStatesFx,
  deleteStateFx,
  exportStatesFx,
  importStatesFx,
} from '../states.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_STATE_LINK_KEY,
  DELETE_STATE_LINK_KEY,
  IMPORT_STATES_LINK_KEY,
  EXPORT_STATES_LINK_KEY,
  GetStateParams,
} from '../states.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { StateDialog } from './state.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type StatesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (stateParams: GetStateParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (state: StateDto, stateParams: GetStateParams) => void;
};

export const StatesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: StatesListProps) => {
  const [states, setStates] = useState<StateDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    stateColumns: columns,
    defaultStateColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(stateStore);

  useEffect(() => {
    getStatesColumnsFx();
  }, []);

  const getStates = useCallback(async () => {
    const statesData = await getStatesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(statesData));
    setStates(statesData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: StateDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_STATES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importStatesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getStates();
          },
          result?.links,
        ),
        getAction(
          EXPORT_STATES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportStatesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getStates],
  );

  useEffect(() => {
    getStates();
  }, [getStates]);

  const onDeleteState = useCallback(
    async (state: StateDto) => {
      if (userHas(DELETE_STATE_LINK_KEY, states?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete State`,
            message: 'Are you sure you want to delete state?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteStateFx(state);
            getStates();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, states],
  );

  const onEditState = (state: StateDto) => {
    if (state)
      goToDetails({
        countryCode: state.countryCode,
        stateCode: state.stateCode,
      });
  };

  const onStateSelect = useCallback(
    async (state) => {
      const selectedState = await showDialog({
        dialog: StateDialog,
        props: {
          countryCode: state.countryCode,
          stateCode: state.stateCode,
          title: 'Update State',
        },
      });
      if (selectedState) {
        getStates();
      }
    },
    [getStates],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['countryCode', 'stateCode']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={states.items}
          onLimitChanged={onLimitChanged}
          total={states.totalCount}
          onDelete={onDeleteState}
          onSort={onSort}
          onEdit={onEditState}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onStateSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {states?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="State"
            entityLinkKey={CREATE_STATE_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
