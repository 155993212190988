import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { CustomCodesList } from '../components/customCodes-list.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import {
  CUSTOMCODES_LIST_PATH,
  CUSTOMCODE_CREATE_PATH,
  CUSTOMCODE_EDIT_PATH,
} from '../customCodes.route';
import { GetCustomCodesQuery } from '../../../models/data.models';
import { CREATE_CUSTOMCODE_LINK_KEY } from '../customCodes.service';
import { userHas } from '../../auth/auth.store';
import { Helmet } from 'react-helmet';

export type CustomCodesListScreenProps = { pageTitle: string; group: string };

export const CustomCodesListScreen = ({
  pageTitle,
  group,
}: CustomCodesListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'description',
    filter = '',
    search = '',
  } = useQuery() as GetCustomCodesQuery;
  const navigate = useNavigate();

  const createPath = generatePath(INTERNAL_PATH + CUSTOMCODE_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (code: string | number = 'create') => {
    const path = generatePath(INTERNAL_PATH + CUSTOMCODE_EDIT_PATH, {
      organizationId,
      code,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMCODES_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMCODES_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMCODES_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMCODES_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout
      group={group}
      title={'Customs Codes'}
      createPath={userHas(CREATE_CUSTOMCODE_LINK_KEY) ? createPath : null}
      createLabel={'Code'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CustomCodesList
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        search={search}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
