import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { JobStatusEdit } from '../components/jobStatus-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  JOBSTATUSES_LIST_PATH,
  JOBSTATUS_CREATE_PATH,
} from '../jobStatuses.route';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_JOBSTATUS_LINK_KEY } from '../jobStatuses.service';
import { userHas } from '../../auth/auth.store';

export type JobStatusScreenProps = { pageTitle: string; group: string };
export type JobStatusRouteParams = {
  organizationId?: string;
  jobStatusId?: number | string;
};

export const JobStatusScreen = ({ pageTitle, group }: JobStatusScreenProps) => {
  const navigate = useNavigate();

  const { organizationId, jobStatusId }: JobStatusRouteParams = useParams();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + JOBSTATUS_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const closeScreen = useMemo(
    () => () => {
      // check if we can go back
      if (navigate.length > 2) {
        navigate(navigate.length - 1);
      } else {
        const { currentOrganization } = organizationsStore.getState();
        const path = generatePath(INTERNAL_PATH + JOBSTATUSES_LIST_PATH, {
          organizationId: currentOrganization.organizationId,
        });
        navigate(path);
      }
    },
    [navigate],
  );

  const jobStatusIdMemo = useMemo(
    () => (jobStatusId !== 'create' ? jobStatusId : null),
    [jobStatusId],
  );

  return (
    <InternalLayout
      group={group}
      title={'JobStatus'}
      createPath={userHas(CREATE_JOBSTATUS_LINK_KEY) ? createPath : null}
      createLabel={'JobStatus'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <JobStatusEdit
        jobStatusId={jobStatusIdMemo}
        onCancel={closeScreen}
        onJobStatusCreated={closeScreen}
        onJobStatusUpdated={closeScreen}
        onDelete={closeScreen}
      />
    </InternalLayout>
  );
};
