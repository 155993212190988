import { showDialog } from '../../dialog.store';
import { DialogComponent } from '../../components/layout/components/dialog-component';
import {
  localized,
  parseTemplate,
} from '../../components/layout/component-hooks';

export const dialogAction = async (actionProps, data, latestStoreValues) => {
  const props = {
    currentUser: data.currentUser,
    organizationId: data.organizationId,
    ...actionProps.dialog.props,
    ...parseTemplate(actionProps.dialog.props, {
      ...latestStoreValues,
      ...data,
    }),
    ...actionProps.dialog.props?.$raw,
  };

  return showDialog({
    dialog: DialogComponent,
    props: {
      ...actionProps,
      title: parseTemplate(localized(actionProps.dialog?.props?.title), {
        ...latestStoreValues,
        ...data,
      }),
      dialog: {
        ...actionProps.dialog,
        props,
      },
    },
  });
};
