import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FieldArray } from 'formik';
import * as Yup from 'yup';
import { useStore } from 'effector-react';
import { commodityStoreForCargoMovement } from '../../commodities/commodities.store';

import { Button } from '../../common/components/button/button.component';
import { CargoMovementForm } from './cargoMovement.form';
import {
  CargoMovementStatus,
  CargoMovementType,
  CommodityDto,
  ContactType,
  EntityTypes,
  OrderDto,
  OrderTypes,
} from '../../../models/data.models';
import { createOrder, getOrder, updateMovement } from '../cargoMovements.store';
import { Panel } from '../../common/components/panel/panel.component';
import {
  OrderEntityEditFormDto,
  OrderEditFormDto,
} from '../../../models/custom.models';
import { GetCommodityParams } from '../../commodities/commodities.service';
import { showDialog } from '../../common/dialog.store';
import { CommodityDialog } from '../../commodities/components/commodity.dialog';

import { FormContext } from '../../common/components/form/form.component';
import {
  OrderDefaultValues,
  EntityDefaultValues,
} from '../../common/DefaultValues';
import { getContacts } from '../../contacts/contacts.store';
import { authStore } from '../../auth/auth.store';
import { getEventDefinitionFx } from '../../eventDefinitions/eventDefinitions.store';
import { CommoditiesSelectorDialog } from './commoditiesSelector.dialog';
import { CommoditiesForCargoMovementList } from './commodities-forCargoMovement-list.component';

const getInitialStateOrderPickupEditForm = () => {
  const initialStateOrderPickupEditForm: OrderEntityEditFormDto = {
    created: null,
    createdBy: null,
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderId: 0,
    orderEntityId: 0,
    orderEntitySequence: 0,
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    customValues: { pickDate: null, shippingNotes: '', shipperFilter: '' },
    entityType: EntityTypes.Shipper,
  };
  return initialStateOrderPickupEditForm;
};

const getInitialStateOrderDeliveryEditForm = () => {
  const initialStateOrderDeliveryEditForm: OrderEntityEditFormDto = {
    contactAddressId: EntityDefaultValues.contactAddressId,
    contactAddressName: EntityDefaultValues.contactAddressName,
    contactId: EntityDefaultValues.contactId,
    contactName: EntityDefaultValues.contactName,
    filter: '',
    created: null,
    createdBy: null,
    entityType: EntityTypes.Consignee,
    customValues: {
      deliveryDate: null,
      deliveryNotes: '',
      consigneeFilter: '',
    },
    lastModified: null,
    lastModifiedBy: null,
    links: [],
    orderEntityId: 0,
    orderEntitySequence: 0,
    orderId: 0,
  };
  return initialStateOrderDeliveryEditForm;
};

const getInitialState = () => {
  const initialState: OrderEditFormDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: [],
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntities: [
      getInitialStateOrderPickupEditForm(),
      getInitialStateOrderDeliveryEditForm(),
    ],
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    links: [],
    customValues: {
      endMovement: '',
      startMovement: '',
      movementStatus: CargoMovementStatus.Created,
    },
  };
  return initialState;
};

export type OrderEditProps = {
  orderId: number | null;
  onOrderCreated?: (order: OrderDto) => void;
  onOrderUpdated?: (order: OrderDto) => void;
  onOrderLoaded?: (order: OrderDto) => void;
  onCancel?: () => void;
  onInvoiceGenerated?: () => void;
};

let movmentSchema = Yup.object().shape({
  divisionId: Yup.string().required("Can't be blank").nullable(true),
  commodities: Yup.array().min(1),
  customValues: Yup.object().shape(
    {
      endMovement: Yup.date()
        .label('End Date')
        .when('startMovement', (start, schema) => {
          return start
            ? schema.min(
                new Date(start),
                ({ min }) => `must be later than ${min.toLocaleDateString()}`,
              )
            : schema.min(
                new Date(new Date().setHours(0, 0, 0, 0)),
                ({ min }) => `must be later than ${min.toLocaleDateString()}`,
              );
        }),
      startMovement: Yup.date()
        .label('Start Date')
        .when('endMovement', (end, schema) => {
          return end
            ? schema.max(
                new Date(end),
                ({ max }) => `must be later than ${max.toLocaleDateString()}`,
              )
            : schema.min(
                new Date(new Date().setHours(0, 0, 0, 0)),
                ({ min }) => `must be later than ${min.toLocaleDateString()}`,
              );
        }),
      destinationLocationId: Yup.number()
        .integer()
        .label('Destination Location')
        .required('Select the Destination Location'),
    },
    [['startMovement', 'endMovement']],
  ),
});

export const CargoMovementEdit = ({
  orderId = 0,
  onOrderLoaded = () => {},
  onOrderCreated = () => {},
  onOrderUpdated = () => {},
  onCancel = () => {},
}: OrderEditProps) => {
  const { user: currentUser } = authStore.getState();
  const { commodityColumns: columns } = useStore(
    commodityStoreForCargoMovement,
  );

  const limit = 20;
  const isCreateMode = !orderId || orderId == 0;

  const [isSending, setIsSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const forceUpdate: () => void = useState()[1].bind(null, {});

  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    getInitialState(),
  );

  useEffect(() => {
    getContacts({ filter: `contactType:${ContactType.Employee}` })
      .then(
        (result) => {
          const defaultDispatcher = result.items.find(
            (employee) => employee.userEmployee?.userId == currentUser?.userId,
          );
          setInitialValues((state) => {
            return {
              ...state,
              employeeContactId: defaultDispatcher?.contactId,
              employeeContactName: defaultDispatcher?.name,
            };
          });
        },
        () => {},
      )
      .then(() => {
        if (isCreateMode) {
          setIsLoading(false);
        } else if (orderId) {
          getOrder({ orderId })
            .then((orderDto: OrderDto) => {
              if (orderDto.trackingEvents) {
                orderDto.trackingEvents.forEach((trackingEvent) => {
                  if (trackingEvent.eventDefinitionId) {
                    getEventDefinitionFx({
                      eventDefinitionId: trackingEvent.eventDefinitionId,
                    }).then(
                      (eventDefinition) =>
                        (trackingEvent.eventName = eventDefinition.eventName),
                    );
                  }
                });
              }
              setInitialValues({ ...orderDto });
              onOrderLoaded(orderDto);
            })
            .finally(() => setIsLoading(false));
        } else {
          throw new Error('Cargo Movement keys were not provided');
        }
      })
      .finally(() => setIsLoading(false));
  }, [orderId]);

  const goToDetailsCommodity = (commodityParams: GetCommodityParams) => {
    if (commodityParams.commodity) {
      showDialog({
        dialog: CommodityDialog,
        props: {
          title: 'Update Commodity',
          commodity: commodityParams?.commodity,
          commodityId: commodityParams?.commodity?.commodityId,
          className: 'commodity-modal',
          saveButtonRenderCondition: false,
          isEditMode: false,
        },
      });
    }
  };

  const getTotalPcsCrt = (commodities) => {
    let sumPieces: number = 0;
    commodities?.forEach((commodity) => {
      sumPieces += Number(commodity.pieces);
    });
    return sumPieces;
  };
  const getWeightTotal = (commodities) => {
    let weightTotal = 0;
    commodities?.forEach((commodity) => {
      weightTotal += commodity.weightTotal;
    });
    return weightTotal;
  };
  const getVolumeTotal = (commodities) => {
    let volumeTotal = 0;
    commodities?.forEach((commodity) => {
      volumeTotal += commodity?.volumeTotal;
    });
    return volumeTotal;
  };

  const selectCommodities = (setFieldValue: any) => {
    showDialog({
      dialog: CommoditiesSelectorDialog,
      props: {
        title: 'Select Commodity',
        className: 'commodity-modal',
      },
    }).then(
      (result: CommodityDto[] | null) => {
        setOffset(0);
        if (result !== null) {
          setFieldValue(
            'commodities',
            result.sort((a, b) => a.commodityId - b.commodityId),
          );
        } else {
          forceUpdate();
        }
      },
      () => {},
    );
  };

  const onSubmit = (data: OrderDto) => {
    data.orderType = OrderTypes.CargoMovement;
    data.orderEntities = [];

    if (data.customValues?.endMovement) {
      data.commodities.forEach(
        (el) =>
          (el.warehouseLocationId = data.customValues.destinationLocationId),
      );
    }

    if (
      data?.customValues?.movementStatus === CargoMovementStatus.Finished &&
      !data.customValues?.startMovement
    ) {
      data.customValues.startMovement = new Date(Date.now());
    }

    setIsSending(true);
    if (isCreateMode) {
      createOrder(data)
        .then(
          (result) => {
            onOrderCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateMovement(data)
        .then(
          (result) => {
            onOrderUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <div className={'order-edit-form'}>
      <CargoMovementForm
        id={'CargoMovementForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={movmentSchema}
        validateOnChange={true}
      >
        <FormContext.Consumer>
          {(context) => {
            return (
              <Tabs>
                <TabList>
                  <div className="d-flex justify-content-between">
                    <span>
                      <Tab data-cy={'general'}>Movement Information</Tab>
                    </span>
                  </div>
                </TabList>
                <div>
                  <TabPanel forceRender={true}>
                    <Panel className="m-3">
                      <div className="row">
                        {isCreateMode ? (
                          <div />
                        ) : (
                          <>
                            <div className="col-2">
                              <CargoMovementForm.OrderNumber />
                            </div>
                          </>
                        )}
                        <div
                          className={`${
                            isCreateMode ? 'col-3' : 'col-2'
                          } employee-contact-input`}
                        >
                          <CargoMovementForm.EmployeeContactSelect
                            id={'employeeContactId'}
                            header={'Employee'}
                            contactTypes={[ContactType.Employee]}
                            selectedFilter={`contactType: ${ContactType.Employee}`}
                            required={false}
                            nameId={'employeeContactName'}
                            disabled={false}
                            defaultValue={
                              context &&
                              context?.values?.employeeContactId &&
                              context?.values?.employeeContactName
                                ? {
                                    contactId: context.values.employeeContactId,
                                    name: context.values.employeeContactName,
                                    contactType: ContactType.Employee,
                                  }
                                : ''
                            }
                          />
                        </div>
                        <div className="col-3">
                          <CargoMovementForm.Status
                            enumName={CargoMovementType}
                            header="Movement Type"
                            id={'customValues.movementType'}
                            name={'customValues.movementType'}
                            disabled={false}
                          />
                        </div>
                        <div className="col-3 division-input">
                          <CargoMovementForm.Division
                            id={'divisionId'}
                            nameId={'divisionName'}
                            header={'Division'}
                            required={true}
                            defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                            disabled={false}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className={'col-2 equipmentType-input'}>
                          <CargoMovementForm.Status
                            header="Movement Status"
                            id={'customValues.movementStatus'}
                            enumName={CargoMovementStatus}
                            disabled={false}
                          />
                        </div>
                        <div className={'col-2 equipmentType-input'}>
                          <CargoMovementForm.Date
                            nameId="created"
                            header="Created Date"
                            placeholder="Created Date"
                            disabled={false}
                          />
                        </div>
                        <div className={'col-2 equipmentType-input'}>
                          <CargoMovementForm.Date
                            nameId="customValues.startMovement"
                            header="Start Date"
                            placeholder="Select Start Date"
                            disabled={false}
                          />
                        </div>
                        <div className={'col-2 equipmentType-input'}>
                          <CargoMovementForm.Date
                            nameId="customValues.endMovement"
                            header="End Date"
                            placeholder="Select End Date"
                            disabled={false}
                          />
                        </div>
                      </div>
                      <hr className="my-4" />
                      <div className="row">
                        <div className="col-4">
                          <CargoMovementForm.WarehouseLocation
                            header="Destination Location"
                            id="customValues.destinationLocationId"
                            nameId="customValues.destinationLocationDescription"
                            disabled={false}
                          />
                        </div>
                        <div className="col-4">
                          <CargoMovementForm.PalletNumber />
                        </div>
                      </div>
                      <hr className="my-4" />

                      <h3 className={'font-weight-normal'}>Commodities</h3>
                      <FieldArray name="commodities">
                        {({ insert, remove, push }) => (
                          <div>
                            <div className="row justify-content-end">
                              {(currentUser?.isInOrgAdminRole ||
                                currentUser?.isInOperationRole) &&
                                !(
                                  context?.values?.customValues
                                    ?.movementStatus ===
                                    CargoMovementStatus.Finished ||
                                  context?.values?.customValues
                                    ?.movementStatus ===
                                    CargoMovementStatus.InProgress
                                ) && (
                                  <div className="col-1 pt-4">
                                    <Button
                                      form={'commodityForm'}
                                      name={'create-commodity'}
                                      type="button"
                                      onClick={() =>
                                        selectCommodities(context.setFieldValue)
                                      }
                                      color="secondary"
                                      className="btn-block text-break"
                                    >
                                      +
                                    </Button>
                                  </div>
                                )}
                            </div>
                            <CommoditiesForCargoMovementList
                              className={'mb-4 commodities-for-order-list'}
                              limit={limit}
                              showPagination={false}
                              onPageChanged={(page) => setOffset(page * limit)}
                              offset={offset}
                              items={context.values.commodities}
                              goToDetails={goToDetailsCommodity}
                              changeItems={remove}
                              isDeleteable={isCreateMode}
                              columns={columns}
                            />
                            {context.values.commodities?.length === 0 && (
                              <h4 className="text-center m-5 text-muted">
                                No commodities
                              </h4>
                            )}
                            <div className="row">
                              <div className="col-2">
                                <CargoMovementForm.TotalPcsCrt
                                  selectedName={getTotalPcsCrt(
                                    context.values.commodities,
                                  )}
                                />
                              </div>
                              <div className="col-2">
                                <CargoMovementForm.WeightTotal
                                  selectedName={getWeightTotal(
                                    context.values.commodities,
                                  )}
                                />
                              </div>
                              <div className="col-2">
                                <CargoMovementForm.VolumeTotal
                                  selectedName={getVolumeTotal(
                                    context.values.commodities,
                                  )}
                                />
                              </div>
                              <div className="justify-content-end d-flex col-6">
                                <div className="col-6 pt-4">
                                  <Button
                                    type="submit"
                                    form={'CargoMovementForm'}
                                    color="primary"
                                    className="btn-block"
                                    disabled={isSending}
                                    isSending={isSending}
                                  >
                                    Save Cargo Movement
                                  </Button>
                                </div>
                                <div className="col-6 pt-4">
                                  <Button
                                    type="button"
                                    color="secondary"
                                    onClick={onCancel}
                                    className="col-12"
                                    disabled={isSending}
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </Panel>
                  </TabPanel>
                </div>
              </Tabs>
            );
          }}
        </FormContext.Consumer>
      </CargoMovementForm>
    </div>
  );
};
