import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { STATE_CREATE_PATH, STATES_LIST_PATH } from '../states.route';
import { StateEdit } from '../components/state-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_STATE_LINK_KEY } from '../states.service';
import { userHas } from '../../auth/auth.store';

export type StateScreenProps = { pageTitle: string; group: string };

export const StateScreen = ({ pageTitle, group }: StateScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, stateCode, countryCode } = useParams();

  const createPath = generatePath(INTERNAL_PATH + STATE_CREATE_PATH, {
    organizationId,
  });

  const onStateUpdated = () => {
    const path = generatePath(INTERNAL_PATH + STATES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'state-created',
      type: 'success',
      autoHide: true,
      message: 'State successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onStateCreated = () => {
    const path = generatePath(INTERNAL_PATH + STATES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'state-updated',
      type: 'success',
      autoHide: true,
      message: 'State successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + STATES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'State'}
      createPath={userHas(CREATE_STATE_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <StateEdit
        stateCode={stateCode}
        countryCode={countryCode}
        onStateCreated={onStateCreated}
        onStateUpdated={onStateUpdated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
