import React from 'react';
import { Button } from '../../../common/components/button/button.component';
import * as Yup from 'yup';
import { PackingForm } from '../../../parcelShipments/packingWizard/packing.form';
import {
  CommodityDto,
  CreateOrderDocumentsCommandValues,
  EntityTypes,
  LocationType,
  OrderDto,
  UserDto,
} from '../../../../models/data.models';
import { useFormikContext } from 'formik';
import { setSubmitAction } from '../../../parcelShipments/packingWizard/packingWizard.component';
import { addMessage } from '../../../common/messages.store';
import { v4 } from 'uuid';
import { createOrderDocumentsFx } from '../../../orderDocuments/orderDocuments.store';
import { ACTION_SHIPPING_PALLET } from '../../../constants/api';
import { triggerWorkflowByConfig } from '../../../workflowTriggers/workflowTriggerHook';

export const createOrderDocumentsForOrder = (
  order: OrderDto,
  documentTemplateId: number,
) => {
  if (documentTemplateId > 0) {
    const documentIdArray = [documentTemplateId];
    const values = documentIdArray.map((id) => {
      const createValues: CreateOrderDocumentsCommandValues = {
        documentTemplateId: id,
        regenerateOnOrderChange: false,
        metadata: {
          orderId: order?.orderId.toString(),
          orderPickupId: order?.orderEntities
            .find((entity) => entity.entityType === EntityTypes.Shipper)
            ?.orderEntityId.toString(),
          orderDeliveryId: order?.orderEntities
            .find((entity) => entity.entityType === EntityTypes.Consignee)
            ?.orderEntityId.toString(),
          customerId: order?.billToContactId.toString(),
        },
      };
      return createValues;
    });
    return createOrderDocumentsFx({ order, values });
  }
  return Promise.resolve();
};

export const onMoveToShipping = (
  onComplete: () => void,
  currentUser?: UserDto,
) => async (data: any, { setFieldValue }) => {
  try {
    if (data?.parcelShipment && data?.purchase) {
      const workflowExecutionVariables = {
        orderId: data.parcelShipment.orderId,
        weight: data.purchase.commodities[0].weight || null,
        weightUnit: data.purchase.commodities[0].weightUnit,
        length: data.purchase.commodities[0].length,
        width: data.purchase.commodities[0].width,
        height: data.purchase.commodities[0].height,
        dimensionUnit: data.purchase.commodities[0].dimensionsUnit,
        palletNumber: data.shippingPallet,
        customValues: data.parcelShipment.customValues,
      };

      const result = await triggerWorkflowByConfig({
        configName: 'tms.modules.packing',
        workflowIdConfigKey: 'shippingWorkflowId',
        workflowVariables: workflowExecutionVariables,
      });

      if (result?.outputs) {
        setFieldValue('lastGeneratedDocument', result.outputs.labelDocumentId);
        setFieldValue(
          'lastGeneratedDocumentLink',
          result.outputs.labelDocumentLink,
        );
        onComplete();
      }
    }
  } catch (error) {
    addMessage({
      message: error?.message || error,
      type: 'danger',
      autoHide: true,
      id: v4(),
    });
  }
};

export type ShippingPalletProps = {
  handleNext: () => void;
  commodities: CommodityDto[];
  createOrderDocumentsForOrder?: (order: OrderDto) => Promise<void>;
  parcelShipment?: OrderDto;
  setLastGeneratedDocument?: any;
  isGenerateInvoiceEnabled?: any;
  dispatcherId: number;
  divisionId: number;
};

export type ShippingPalletInitialState = {
  shippingPallet: string;
};

export const shippingPalletSchema = {
  shippingPallet: Yup.string()
    .typeError("Can't be blank")
    .when('_submitAction', (val, schema) => {
      return val === ACTION_SHIPPING_PALLET
        ? schema.required("Can't be blank")
        : schema.nullable();
    }),
};

export const shippingPalletInitialState = () => {
  const initialState: ShippingPalletInitialState = {
    shippingPallet: sessionStorage.getItem(
      `${LocationType.Shipping}PalletNumber`,
    ),
  };

  return initialState;
};

export const ShippingPallet = ({
  isCompleteAllServices,
  isVerifiedAll,
  isCustomerNotesVerified,
}) => {
  const formikContext = useFormikContext();

  return (
    <div className="row justify-content-start">
      <div className="col-12">
        <div>
          <PackingForm.PalletNumber
            label={'Shipping Pallet #'}
            name={'shippingPallet'}
            onChange={(event) =>
              sessionStorage.setItem(
                `${LocationType.Shipping}PalletNumber`,
                event?.target?.value,
              )
            }
            barcode={true}
          />
        </div>
      </div>
      <div className="w-100">
        <Button
          className="ml-2 mt-3 w-100"
          color="primary"
          name="_submitAction"
          style={{ minWidth: '9.5rem' }}
          disabled={formikContext.isSubmitting}
          isSending={formikContext.isSubmitting}
          onClick={() => {
            !isCompleteAllServices && !isVerifiedAll
              ? addMessage({
                  message: `Packages Verification & Services Completion are Required`,
                  type: 'warning',
                  id: v4(),
                })
              : !isCompleteAllServices && isVerifiedAll
              ? addMessage({
                  message: `Completion Required: Services are Incomplete`,
                  type: 'warning',
                  id: v4(),
                })
              : isCompleteAllServices && !isVerifiedAll
              ? addMessage({
                  message: `Packages Verification Required: Packages are Not Verified`,
                  type: 'warning',
                  id: v4(),
                })
              : setSubmitAction(formikContext, ACTION_SHIPPING_PALLET);
          }}
        >
          Print Label & Move To Shipping
        </Button>
      </div>
    </div>
  );
};
