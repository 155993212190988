import {
  SalesTaxDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  SalesTaxDtoPagedResult,
  CreateSalesTaxCommandValues,
  UpdateSalesTaxCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_SALESTAXES_LINK_KEY = 'get-salestaxes';
export const CREATE_SALESTAX_LINK_KEY = 'create-salestax';
export const GET_SALESTAX_LINK_KEY = 'get-salestax';
export const UPDATE_SALESTAX_LINK_KEY = 'update-salestax';
export const DELETE_SALESTAX_LINK_KEY = 'delete-salestax';
export const IMPORT_SALESTAXES_LINK_KEY = 'import-salestaxes';
export const EXPORT_SALESTAXES_LINK_KEY = 'export-salestaxes';
export const SALESTAX_ENTITY_NAME = 'Sales Tax';

export interface GetSalesTaxParams {
  salesTaxId: number;
}

export interface ImportSalesTaxesParams {
  organizationId: number;
}

export interface ExportSalesTaxesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getSalesTaxRequest = async (
  resource: LinkResourceBaseDto | null,
  { salesTaxId }: GetSalesTaxParams,
): Promise<SalesTaxDto> => {
  if (resource && salesTaxId) {
    const getSalesTaxLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_SALESTAX_LINK_KEY,
    );
    if (getSalesTaxLink) {
      const result = await execLink(getSalesTaxLink, { salesTaxId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_SALESTAX_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getSalesTaxesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<SalesTaxDtoPagedResult> => {
  if (resource) {
    const getSalesTaxesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_SALESTAXES_LINK_KEY,
    );
    if (getSalesTaxesListLink) {
      const result = await execLink(getSalesTaxesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_SALESTAXES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createSalesTaxRequest = async (
  resource: LinkResourceBaseDto,
  salesTax: CreateSalesTaxCommandValues,
) => {
  const createSalesTaxLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_SALESTAX_LINK_KEY,
  );
  if (createSalesTaxLink) {
    const result = await execLink(createSalesTaxLink, salesTax);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_SALESTAX_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateSalesTaxRequest = async (
  resource: LinkResourceBaseDto,
  salesTax: UpdateSalesTaxCommandValues,
) => {
  const updateSalesTaxLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_SALESTAX_LINK_KEY,
  );
  if (updateSalesTaxLink) {
    const result = await execLink(updateSalesTaxLink, salesTax);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_SALESTAX_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteSalesTaxRequest = async (salesTax: SalesTaxDto) => {
  const deleteSalesTaxLink = salesTax.links?.find(
    (x: LinkDto) => x.rel === DELETE_SALESTAX_LINK_KEY,
  );
  if (deleteSalesTaxLink) {
    const result = await execLink(deleteSalesTaxLink, salesTax);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_SALESTAX_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importSalesTaxesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportSalesTaxesParams,
  file: File,
) => {
  const importSalesTaxesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_SALESTAXES_LINK_KEY,
  );
  if (importSalesTaxesLink) {
    const result = await postFormData(importSalesTaxesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_SALESTAXES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportSalesTaxesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportSalesTaxesParams,
) => {
  const exportSalesTaxesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_SALESTAXES_LINK_KEY,
  );
  if (exportSalesTaxesLink) {
    const result = await downloadLinkFile(exportSalesTaxesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_SALESTAXES_LINK_KEY} is not allowed or missing link action`,
  );
};
