import { Dialog } from '../../common/components/dialog/dialog.component';
import { AttachmentEdit } from './attachment-edit.component';
import { AttachmentDto } from '../../../models/data.models';

export type AttachmentDialogProperties = {
  attachmentId?: number | null;
  title: string;
};

export class AttachmentDialog extends Dialog<AttachmentDialogProperties> {
  constructor(props: AttachmentDialogProperties) {
    super(props);
    this.onAttachmentSaved = this.onAttachmentSaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onAttachmentSaved = (attachment: AttachmentDto) => {
    this.close(attachment);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { attachmentId } = this.props as AttachmentDialogProperties;
    return (
      <AttachmentEdit
        attachmentId={attachmentId}
        onAttachmentCreated={this.onAttachmentSaved}
        onAttachmentUpdated={this.onAttachmentSaved}
        onCancel={this.onCancel}
      />
    );
  }
}
