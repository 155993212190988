import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { WarehouseZoneScreen } from './screens/warehouseZone.screen';
import { WarehouseZonesListScreen } from './screens/warehouseZones-list.screen';

export const WAREHOUSEZONE_LIST_PATH = '/warehouseZones';
export const WAREHOUSEZONE_EDIT_PATH = '/warehouseZones/:warehouseZoneId';
export const WAREHOUSEZONE_CREATE_PATH = '/warehouseZones/0';

export const WarehouseZoneRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <WarehouseZonesListScreen
              pageTitle="Warehouse Zones"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <WarehouseZoneScreen
              pageTitle="Add New Warehouse Zone"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':warehouseZoneId'}
        element={
          <PrivateRoute>
            <WarehouseZoneScreen
              pageTitle="Update Warehouse Zone"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
