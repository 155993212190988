import { useCallback, useMemo } from 'react';
import Select from 'react-select';

export interface Option {
  label: string;
  value: string;
}

export const ReactSelect = ({
  field,
  form,
  options,
  componentProps,
  ...props
}) => {
  const { items } = componentProps.props;
  const handleChange = useCallback(
    (selectedOption: Option | null) => {
      const value = selectedOption ? selectedOption.value : '';
      form.setFieldValue(field.name, value);
    },
    [form, field.name, props],
  );

  const selectedValue = useMemo(
    () => items?.find((option) => option.value === field.value) || null,
    [field.value, items],
  );

  return (
    <Select
      {...field}
      {...options}
      options={items}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};
