import {
  PaymentTermDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreatePaymentTermCommandValues,
  UpdatePaymentTermCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPaymentTermRequest,
  getPaymentTermsListRequest,
  getPaymentTermRequest,
  deletePaymentTermRequest,
  updatePaymentTermRequest,
  importPaymentTermsRequest,
  exportPaymentTermsRequest,
  GetPaymentTermParams,
} from './paymentTerms.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type PaymentTermsStoreState = {
  links: LinkDto[];
  paymentTermColumns: EntityFieldDto[];
  defaultPaymentTermColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getPaymentTermsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'PaymentTerm',
  });
});

addEffectStatusHandling(getPaymentTermsColumnsFx);

export const getPaymentTermsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'PaymentTerm',
  });
});

addEffectStatusHandling(getPaymentTermsDefaultColumnsFx);

export const updatePaymentTermsColumns = createEvent<EntityFieldDto[]>();

export const getPaymentTermsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPaymentTermsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getPaymentTermsFx);

export const createPaymentTermFx = createEffect(
  (paymentTermData: PaymentTermDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createPaymentTermCommand: CreatePaymentTermCommandValues = {
      ...paymentTermData,
    };

    return createPaymentTermRequest(
      currentOrganization!,
      createPaymentTermCommand,
    );
  },
);

addEffectStatusHandling(createPaymentTermFx, {
  completeMessage: 'Payment term was created successfully',
  errorMessage: 'Payment term was not created',
});

export const getPaymentTermFx = createEffect(
  (paymentTermParams: GetPaymentTermParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getPaymentTermRequest(
      currentOrganization as LinkResourceBaseDto,
      paymentTermParams,
    );
  },
);

addEffectStatusHandling(getPaymentTermFx);

export const updatePaymentTermFx = createEffect(
  (paymentTerm: PaymentTermDto) => {
    const updatePaymentTermCommand: UpdatePaymentTermCommandValues = {
      ...paymentTerm,
    };
    return updatePaymentTermRequest(paymentTerm, updatePaymentTermCommand);
  },
);

addEffectStatusHandling(updatePaymentTermFx, {
  completeMessage: 'Payment term was updated successfully',
  errorMessage: 'Payment term was not updated',
});

export const deletePaymentTermFx = createEffect(
  (paymentTerm: PaymentTermDto) => {
    return deletePaymentTermRequest(paymentTerm);
  },
);

addEffectStatusHandling(deletePaymentTermFx, {
  completeMessage: 'Payment term was deleted successfully',
  errorMessage: 'Payment term was not deleted',
});

export const exportPaymentTermsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportPaymentTermsRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportPaymentTermsFx, {
  completeMessage: 'Payment terms were exported successfully',
  errorMessage: 'Payment terms were not exported',
});

export const importPaymentTermsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importPaymentTermsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importPaymentTermsFx, {
  completeMessage: 'Payment terms were imported successfully',
  errorMessage: 'Payment terms were not imported',
  inFlightMessage: 'Importing payment terms...',
});

const defaultState: PaymentTermsStoreState = {
  links: [],
  paymentTermColumns: [],
  defaultPaymentTermColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const paymentTermStore: Store<PaymentTermsStoreState> = createStore(
  defaultState,
)
  .on(getPaymentTermsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      paymentTermColumns: payload.result.items,
      defaultPaymentTermColumns: payload.result.items,
    };
  })
  .on(getPaymentTermsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultPaymentTermColumns: payload.result.items,
    };
  })
  .on(updatePaymentTermsColumns, (state, payload) => {
    return { ...state, paymentTermColumns: payload };
  });
