import {
  CityDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateCityCommandValues,
  UpdateCityCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCityRequest,
  getCitiesListRequest,
  getCityRequest,
  deleteCityRequest,
  updateCityRequest,
  importCitiesRequest,
  exportCitiesRequest,
  GetCityParams,
} from './cities.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type CitiesStoreState = {
  links: LinkDto[];
  cityColumns: EntityFieldDto[];
  defaultCityColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getCitiesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'City',
  });
});

addEffectStatusHandling(getCitiesColumnsFx);

export const getCitiesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'City',
  });
});

addEffectStatusHandling(getCitiesDefaultColumnsFx);

export const updateCitiesColumns = createEvent<EntityFieldDto[]>();

export const getCitiesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCitiesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getCitiesFx);

export const createCityFx = createEffect((cityData: CityDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createCityCommand: CreateCityCommandValues = { ...cityData };

  return createCityRequest(currentOrganization!, createCityCommand);
});

addEffectStatusHandling(createCityFx, {
  completeMessage: 'City was created successfully',
  errorMessage: 'City was not created',
});

export const getCityFx = createEffect((cityParams: GetCityParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCityRequest(currentOrganization as LinkResourceBaseDto, cityParams);
});

addEffectStatusHandling(getCityFx);

export const updateCityFx = createEffect((city: CityDto) => {
  const updateCityCommand: UpdateCityCommandValues = { ...city };
  return updateCityRequest(city, updateCityCommand);
});

addEffectStatusHandling(updateCityFx, {
  completeMessage: 'City was updated successfully',
  errorMessage: 'City was not updated',
});

export const deleteCityFx = createEffect((city: CityDto) => {
  return deleteCityRequest(city);
});

addEffectStatusHandling(deleteCityFx, {
  completeMessage: 'City was deleted successfully',
  errorMessage: 'City was not deleted',
});

export const exportCitiesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportCitiesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportCitiesFx, {
  completeMessage: 'Cities were exported successfully',
  errorMessage: 'Cities were not exported',
});

export const importCitiesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importCitiesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importCitiesFx, {
  completeMessage: 'Cities were imported successfully',
  errorMessage: 'Cities were not imported',
  inFlightMessage: 'Importing cities...',
});

const defaultState: CitiesStoreState = {
  links: [],
  cityColumns: [],
  defaultCityColumns: [],
  defaultSort: 'cityName',
  defaultLimit: 20,
};

export const cityStore: Store<CitiesStoreState> = createStore(defaultState)
  .on(getCitiesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      cityColumns: payload.result.items,
      defaultCityColumns: payload.result.items,
    };
  })
  .on(getCitiesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultCityColumns: payload.result.items,
    };
  })
  .on(updateCitiesColumns, (state, payload) => {
    return { ...state, cityColumns: payload };
  });
