import { Dialog } from '../../common/components/dialog/dialog.component';
import { PaymentEdit } from './payment-edit.component';
import { PaymentDto } from '../../../models/data.models';

export type PaymentDialogProperties = {
  paymentId?: number | null;
  title: string;
  payment?: PaymentDto;
  selectedFilter?: string;
  isModal?: boolean;
};

export class PaymentDialog extends Dialog<PaymentDialogProperties> {
  constructor(props: PaymentDialogProperties) {
    super(props);
    this.onPaymentSaved = this.onPaymentSaved.bind(this);
  }

  onPaymentSaved = (payment: PaymentDto) => {
    this.close(payment);
  };

  renderContent(): any {
    const { paymentId, payment, selectedFilter, isModal } = this
      .props as PaymentDialogProperties;
    return (
      <PaymentEdit
        paymentId={paymentId}
        onPaymentCreated={this.onPaymentSaved}
        onPaymentUpdated={this.onPaymentSaved}
        onCancel={this.close.bind(this)}
        defaultPayment={payment}
        selectedFilter={selectedFilter}
        isModal={isModal}
      />
    );
  }
}
