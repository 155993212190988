// create hook to get workflowTrigger instance and open dialog to invoke it

// Path: src/modules/workflowTriggers/workflowTriggerHook.tsx

import { WorkflowTriggerDto } from '../../models/data.models';
import { WorkflowTriggerDialog } from './components/workflowTrigger.dialog';
import { showDialog } from '../common/dialog.store';
import {
  executeWorkflowTriggerFx,
  getWorkflowTriggerFx,
} from './workflowTriggers.store';
import { getOrganizationConfigsFx } from '../organizationConfigs/organizationConfigs.store';
import { get } from 'lodash';

const openWorkflowTriggerDialog = async (
  workflow: WorkflowTriggerDto,
  defaultVariables?: any,
  setResult?: any,
) => {
  return showDialog({
    dialog: WorkflowTriggerDialog,
    props: {
      workflowId: workflow?.workflowId,
      workflowTrigger: workflow,
      defaultVariables,
      title: workflow?.workflowName || 'Execute Workflow',
      setResult,
      autoClose: workflow.additionalProperties?.autoClose === true,
    },
  });
};

const isTrue = (value: string | boolean | undefined) => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    return value === 'true';
  }

  return false;
};

export const triggerWorkflowTrigger = async (
  workflowTrigger: WorkflowTriggerDto,
  defaultVariables?: any,
  options?: { silent: boolean | null; notification?: boolean },
) => {
  if (isTrue(workflowTrigger.additionalProperties?.silent || options?.silent)) {
    const vars: any = {};
    for (const input of workflowTrigger?.inputs || []) {
      vars[input.name] = defaultVariables[input.name];
    }
    const wfResult = await executeWorkflowTriggerFx({
      workflowTrigger,
      variables: vars,
      options,
    });
    return wfResult;
  } else {
    let wfResult;
    await openWorkflowTriggerDialog(
      workflowTrigger,
      defaultVariables,
      (result) => {
        wfResult = result;
      },
    );
    return wfResult;
  }
};

export const triggerWorkflowById = async (
  workflowId: string,
  defaultVariables?: any,
  options?: { silent: boolean | null; notification?: boolean },
) => {
  const workflowTrigger = await getWorkflowTriggerFx({ workflowId });
  return triggerWorkflowTrigger(workflowTrigger, defaultVariables, options);
};

export const triggerWorkflowByConfig = async ({
  configName,
  workflowIdConfigKey: configKey,
  workflowVariables,
}: {
  configName: string;
  workflowIdConfigKey: string;
  workflowVariables?: any;
}) => {
  const organizationConfig = await getOrganizationConfigsFx({
    filter: `configName:${configName}`,
    limit: 1,
  });

  // throw error if config not found
  if (!organizationConfig?.items || organizationConfig.items.length === 0) {
    throw new Error(`Organization config ${configName} not found`);
  }

  const workflowId = get(organizationConfig.items[0].customValues, configKey);

  // throw error if config key not found
  if (!workflowId) {
    throw new Error(`Config key ${configKey} not found in ${configName}`);
  }

  return triggerWorkflowById(workflowId, workflowVariables);
};
