import { createEvent, createStore, Store } from 'effector';
import { ContactAddressDto } from '../../../models/data.models';

type ContactAddressesReactSelectStore = {
  clearLoadOptionsCache: boolean;
  lastEditedContactAddress: ContactAddressDto;
};

export const clearContactAddressesLoadOptionsCache = createEvent();
export const updateLastEditedContactAddress = createEvent<ContactAddressDto>();

const defaultState: ContactAddressesReactSelectStore = {
  clearLoadOptionsCache: false,
  lastEditedContactAddress: null,
};

export const contactAddressesReactSelectStore: Store<ContactAddressesReactSelectStore> = createStore(
  defaultState,
)
  .on(clearContactAddressesLoadOptionsCache, (state, payload) => {
    return { ...state, clearLoadOptionsCache: !state.clearLoadOptionsCache };
  })
  .on(updateLastEditedContactAddress, (state, payload) => {
    return { ...state, lastEditedContactAddress: payload };
  });
