import {
  AccountingItemDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  AccountingItemDtoPagedResult,
  CreateAccountingItemCommandValues,
  UpdateAccountingItemCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_ACCOUNTINGITEMS_LINK_KEY = 'get-accountingitems';
export const CREATE_ACCOUNTINGITEM_LINK_KEY = 'create-accountingitem';
export const GET_ACCOUNTINGITEM_LINK_KEY = 'get-accountingitem';
export const UPDATE_ACCOUNTINGITEM_LINK_KEY = 'update-accountingitem';
export const DELETE_ACCOUNTINGITEM_LINK_KEY = 'delete-accountingitem';
export const IMPORT_ACCOUNTINGITEMS_LINK_KEY = 'import-accountingitems';
export const EXPORT_ACCOUNTINGITEMS_LINK_KEY = 'export-accountingitems';
export const ACCOUNTINGITEM_ENTITY_NAME = 'Accounting Item';

export interface GetAccountingItemParams {
  accountingItemId: number;
}

export interface ImportAccountingItemsParams {
  organizationId: number;
}

export interface ExportAccountingItemsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getAccountingItemRequest = async (
  resource: LinkResourceBaseDto | null,
  { accountingItemId }: GetAccountingItemParams,
): Promise<AccountingItemDto> => {
  if (resource && accountingItemId) {
    const getAccountingItemLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGITEM_LINK_KEY,
    );
    if (getAccountingItemLink) {
      const result = await execLink(getAccountingItemLink, {
        accountingItemId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACCOUNTINGITEM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getAccountingItemsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<AccountingItemDtoPagedResult> => {
  if (resource) {
    const getAccountingItemsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGITEMS_LINK_KEY,
    );
    if (getAccountingItemsListLink) {
      const result = await execLink(getAccountingItemsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ACCOUNTINGITEMS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createAccountingItemRequest = async (
  resource: LinkResourceBaseDto,
  accountingItem: CreateAccountingItemCommandValues,
) => {
  const createAccountingItemLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ACCOUNTINGITEM_LINK_KEY,
  );
  if (createAccountingItemLink) {
    const result = await execLink(createAccountingItemLink, accountingItem);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ACCOUNTINGITEM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateAccountingItemRequest = async (
  resource: LinkResourceBaseDto,
  accountingItem: UpdateAccountingItemCommandValues,
) => {
  const updateAccountingItemLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ACCOUNTINGITEM_LINK_KEY,
  );
  if (updateAccountingItemLink) {
    const result = await execLink(updateAccountingItemLink, accountingItem);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_ACCOUNTINGITEM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteAccountingItemRequest = async (
  accountingItem: AccountingItemDto,
) => {
  const deleteAccountingItemLink = accountingItem.links?.find(
    (x: LinkDto) => x.rel === DELETE_ACCOUNTINGITEM_LINK_KEY,
  );
  if (deleteAccountingItemLink) {
    const result = await execLink(deleteAccountingItemLink, accountingItem);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ACCOUNTINGITEM_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importAccountingItemsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportAccountingItemsParams,
  file: File,
) => {
  const importAccountingItemsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_ACCOUNTINGITEMS_LINK_KEY,
  );
  if (importAccountingItemsLink) {
    const result = await postFormData(importAccountingItemsLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_ACCOUNTINGITEMS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportAccountingItemsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportAccountingItemsParams,
) => {
  const exportAccountingItemsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_ACCOUNTINGITEMS_LINK_KEY,
  );
  if (exportAccountingItemsLink) {
    const result = await downloadLinkFile(exportAccountingItemsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_ACCOUNTINGITEMS_LINK_KEY} is not allowed or missing link action`,
  );
};
