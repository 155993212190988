import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  ACCOUNTINGACCOUNT_CREATE_PATH,
  ACCOUNTINGACCOUNTS_LIST_PATH,
} from '../accountingAccounts.route';
import { AccountingAccountEdit } from '../components/accountingAccount-edit.component';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_ACCOUNTINGACCOUNT_LINK_KEY } from '../accountingAccounts.service';
import { userHas } from '../../auth/auth.store';

export type AccountingAccountScreenProps = { pageTitle: string; group: string };

export const AccountingAccountScreen = ({
  pageTitle,
  group,
}: AccountingAccountScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, accountId } = useParams();

  function createPath() {
    return generatePath(INTERNAL_PATH + ACCOUNTINGACCOUNT_CREATE_PATH, {
      organizationId,
    });
  }

  const onAccountingAccountCreated = () => {
    const path = generatePath(INTERNAL_PATH + ACCOUNTINGACCOUNTS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'accounting-account-created',
      type: 'success',
      autoHide: true,
      message: 'Accounting Account successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onAccountingAccountUpdated = () => {
    const path = generatePath(INTERNAL_PATH + ACCOUNTINGACCOUNTS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'accounting-account-updated',
      type: 'success',
      autoHide: true,
      message: 'Accounting Account successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + ACCOUNTINGACCOUNTS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      createLabel={'Item'}
      group={group}
      title={pageTitle}
      createPath={
        userHas(CREATE_ACCOUNTINGACCOUNT_LINK_KEY) ? createPath() : null
      }
    >
      <AccountingAccountEdit
        accountId={accountId}
        onAccountingAccountUpdated={onAccountingAccountUpdated}
        onAccountingAccountCreated={onAccountingAccountCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
