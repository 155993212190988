import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { OrganizationConfigForm } from './organizationConfig.form';
import { OrganizationConfigDto } from '../../../models/data.models';
import {
  createOrganizationConfigFx,
  getOrganizationConfigFx,
  updateOrganizationConfigFx,
} from '../organizationConfigs.store';
import { Panel } from '../../common/components/panel/panel.component';
import { OrganizationConfigDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_ORGANIZATIONCONFIG_LINK_KEY } from '../organizationConfigs.service';
import { v4 } from 'uuid';
import { addMessage } from '../../common/messages.store';

export type OrganizationConfigEditProps = {
  organizationConfigId?: number | null;
  onOrganizationConfigCreated?: (
    organizationConfig: OrganizationConfigDto,
  ) => void;
  onOrganizationConfigUpdated?: (
    organizationConfig: OrganizationConfigDto,
  ) => void;
  onOrganizationConfigLoaded?: (
    organizationConfig: OrganizationConfigDto,
  ) => void;
  onCancel?: () => void;
};

const initialState: OrganizationConfigDto = {
  organizationId: 0,
  organizationConfigId: 0,
  configName: OrganizationConfigDefaultValues.configName,
  customValues: JSON.stringify(OrganizationConfigDefaultValues.customValues),
  links: [],
};

const organizationConfigSchema = Yup.object().shape({
  configName: Yup.string().required("Can't be blank").nullable(true),
});

export const OrganizationConfigEdit = ({
  organizationConfigId,
  onOrganizationConfigLoaded = () => {},
  onOrganizationConfigCreated = () => {},
  onOrganizationConfigUpdated = () => {},
  onCancel = () => {},
}: OrganizationConfigEditProps) => {
  const isCreateMode = !organizationConfigId || organizationConfigId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    initialValues,
    setInitialValues,
  ] = useState<OrganizationConfigDto | null>(initialState);
  const [
    organizationConfig,
    setOrganizationConfig,
  ] = useState<OrganizationConfigDto | null>(null);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (organizationConfigId) {
      getOrganizationConfigFx({ organizationConfigId }).then(
        (organizationConfigDto: OrganizationConfigDto) => {
          organizationConfigDto.customValues = JSON.stringify(
            organizationConfigDto.customValues,
            undefined,
            8,
          );
          setOrganizationConfig(organizationConfigDto);
          setInitialValues(organizationConfigDto);
          setIsLoading(false);
          onOrganizationConfigLoaded(organizationConfigDto);
        },
      );
    } else {
      throw new Error('OrganizationConfig keys were not provided');
    }
  }, [organizationConfigId]);

  const onSubmit = (data: OrganizationConfigDto) => {
    const organizationConfigDto = { ...data };

    if (organizationConfigDto) {
      try {
        organizationConfigDto.customValues = JSON.parse(
          organizationConfigDto.customValues as string,
        );
      } catch {
        addMessage({
          message: 'Please, check your code: wrong JSON format',
          type: 'danger',
          id: v4(),
        });
        return;
      }
    }

    setIsSending(true);
    if (isCreateMode) {
      createOrganizationConfigFx(organizationConfigDto)
        .then((result) => {
          onOrganizationConfigCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updateOrganizationConfigFx(organizationConfigDto)
        .then((result) => {
          onOrganizationConfigUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };

  const onSettingsKeyDown = (e: any) => {
    if (e.keyCode === 9 || e.which === 9) {
      e.preventDefault();
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      input.value =
        input.value.substring(0, start) + '\t' + input.value.substring(end);
      input.selectionEnd = start + 1;
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <Panel className="m-3">
      <div className="row">
        <div className="col-12">
          <OrganizationConfigForm
            id={'organizationConfig-form'}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={organizationConfigSchema}
          >
            <div className="row">
              <div className="col-4">
                <OrganizationConfigForm.ConfigName />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <OrganizationConfigForm.Settings />
              </div>
            </div>
          </OrganizationConfigForm>
        </div>
      </div>
      <div className="justify-content-end row">
        {(userHas(
          UPDATE_ORGANIZATIONCONFIG_LINK_KEY,
          organizationConfig?.links,
        ) ||
          isCreateMode) && (
          <div className="col-4">
            <Button
              form={'organizationConfig-form'}
              type="submit"
              color="primary"
              className="btn-block"
              disabled={isSending}
              isSending={isSending}
            >
              Save Organization Config
            </Button>
          </div>
        )}
        <div className="col-3">
          <Button
            type="button"
            color="primary"
            onClick={onCancel}
            className="w-100 btn-secondary"
            disabled={isSending}
          >
            Close
          </Button>
        </div>
      </div>
    </Panel>
  );
};
