import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderStatusEdit } from './orderStatus-edit.component';
import { OrderStatusDto } from '../../../models/data.models';

export type OrderStatusDialogProperties = {
  orderStatusId?: number | null;
  title: string;
};

export class OrderStatusDialog extends Dialog<OrderStatusDialogProperties> {
  constructor(props: OrderStatusDialogProperties) {
    super(props);
    this.onOrderStatusSaved = this.onOrderStatusSaved.bind(this);
  }

  onOrderStatusSaved = (orderStatus: OrderStatusDto) => {
    this.close(orderStatus);
  };

  renderContent(): any {
    const { orderStatusId } = this.props as OrderStatusDialogProperties;
    return (
      <OrderStatusEdit
        orderStatusId={orderStatusId}
        onOrderStatusCreated={this.onOrderStatusSaved}
        onOrderStatusUpdated={this.onOrderStatusSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
