import { createStore, createEffect, Store, createEvent, Event } from 'effector';
import {
  LinkDto,
  LinkResourceBaseDto,
  OrganizationDto,
  UpdateOrganizationCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { addRoute } from '../api/api.store';
import {
  GetOrganizationParams,
  getOrganizationRequest,
  updateOrganizationRequest,
} from './organization.service';

type OrganizationStoreState = {
  links: any;
  organizations: OrganizationDto[];
  currentOrganization?: OrganizationDto | null;
};

export const getOrganization = createEffect(
  (organizationParams: GetOrganizationParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrganizationRequest(
      currentOrganization as LinkResourceBaseDto,
      organizationParams,
    );
  },
);

export const getOrganizationsFx = createEffect((params = {}) => {
  const state: OrganizationStoreState = organizationsStore.getState();
  const link: LinkDto = state.links.getOrganizations;

  if (!link) {
    throw new Error('Link not found for getOrganizations in /api');
  }

  return execLink(link, params).then(
    (result) => {
      return result.data;
    },
    () => {},
  );
});

export const createOrganization = createEffect(
  (organizationData: OrganizationDto) => {
    const state: OrganizationStoreState = organizationsStore.getState();
    const link: LinkDto = state.links.createOrganization;
    return execLink(link, organizationData).then(
      (result) => {
        return result.data;
      },
      () => {},
    );
  },
);

export const updateOrganization = createEffect(
  (organizationData: OrganizationDto) => {
    const updateCommand: UpdateOrganizationCommandValues = {
      ...organizationData,
    };
    return updateOrganizationRequest(organizationData, updateCommand);
  },
);

export const clearOrganizations = createEvent();

export const setCurrentOrganization: Event<OrganizationDto> = createEvent();
export const setOrganization: Event<OrganizationDto> = createEvent();

const defaultState: OrganizationStoreState = {
  links: {},
  organizations: [],
  currentOrganization: null,
};

export const organizationsStore: Store<OrganizationStoreState> = createStore(
  defaultState,
)
  .on(addRoute, (state: any, payload: LinkDto) => {
    switch (payload.rel) {
      case 'get-organizations':
        state.links.getOrganizations = payload;
        break;
      case 'create-organization':
        state.links.createOrganization = payload;
        break;
    }
    return state;
  })
  .on(setOrganization, (state, payload) => {
    const index = state.organizations.findIndex(
      (org) => org.organizationId === payload.organizationId,
    );
    if (index > -1) {
      return {
        ...state,
        organizations: [...state.organizations.splice(index, 1, payload)],
      };
    }
    return { ...state, organizations: [...state.organizations, payload] };
  })
  .on(getOrganizationsFx.done, (state, payload) => {
    const organizations = payload.result;
    organizations.items.forEach((organization: OrganizationDto) =>
      setOrganization(organization),
    );
    organizations.links.forEach((link: LinkDto) => addRoute(link));

    const organizationId = Number(
      location.pathname.split('/org/')[1]?.split('/')[0],
    );
    if (state.currentOrganization === null && organizations.items.length > 0)
      if (isNaN(organizationId)) {
        setCurrentOrganization(organizations.items[0]);
      } else {
        setCurrentOrganization(
          organizations.items.find((organization: OrganizationDto) => {
            return organization.organizationId === organizationId;
          }),
        );
      }
  })
  .on(getOrganizationsFx.fail, (state, payload) => {
    return { ...state, organizations: [], currentOrganization: null };
  })
  .on(createOrganization.done, (state, payload) => {
    setOrganization(payload.result);
    getOrganizationsFx({ sort: 'organizationId' });
    setCurrentOrganization(payload.result);
  })
  .on(setCurrentOrganization, (state, payload) => {
    return { ...state, currentOrganization: payload };
  })
  .on(clearOrganizations, (state) => {
    return defaultState;
  });
