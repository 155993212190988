import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { createOrder, deleteOrder, getOrders } from '../../orders/orders.store';
import {
  getWarehouseReceiptsColumns,
  warehouseReceiptsStore,
} from '../warehouseReceipts.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ORDER_LINK_KEY,
  DELETE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../../orders/orders.service';
import { organizationsStore } from '../../organization/organization.store';
import { INTERNAL_PATH } from '../../../app.router';
import {
  WAREHOUSE_RECEIPT_CREATE_PATH,
  WAREHOUSE_RECEIPT_LIST_PATH,
} from '../warehouseReceipt.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { WarehouseReceiptDialog } from './warehouseReceipt.dialog';
import { userHas } from '../../auth/auth.store';
import { WarehouseReceiptGrid } from '../../common/components/grid/warehouseReceipt-grid.component';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { CreateByPurchaseWizardDialog } from '../createByPurchaseWizard/components/createByPurchaseWizard.dialog';
import { addMessage, Message } from '../../common/messages.store';
import { PackageReceiveWizardDialog } from '../packageReceiveWizard/packageReceiveWizard.dialog';
import { hidenFilter } from '../../../utils/query.utils';

export type WarehouseReceiptsListProps = {
  goToDetails?: (warehouseReceiptParams: GetOrderParams) => void;
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (warehouseReceipt: OrderDto, warehouseReceiptId: string) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const WarehouseReceiptsList = ({
  goToDetails = () => {},
  filter = null,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch = () => {},
}: WarehouseReceiptsListProps) => {
  const [
    warehouseReceipts,
    setWarehouseReceipts,
  ] = useState<OrderDtoPagedResult | null>(null);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  useEffect(() => {
    getWarehouseReceiptsColumns().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  useEffect(() => {
    getWarehouseReceiptsData();
  }, [offset, limit, sort, filter, search]);

  const getWarehouseReceiptsData = () => {
    const additionalFilter = hidenFilter(
      `orderType:${OrderTypes.WarehouseReceipt}`,
      filter,
    );
    getOrders({ offset, limit, sort, filter: additionalFilter, search }).then(
      (warehouseReceiptsData) => {
        const warehouseReceiptDtoResult: OrderDtoForListPagedResult = {
          limit: warehouseReceiptsData.limit,
          offset: warehouseReceiptsData.offset,
          links: warehouseReceiptsData.links,
          totalCount: warehouseReceiptsData.totalCount,
          items: warehouseReceiptsData.items.map<OrderForListDto>((item) => {
            const resultWarehouseReceipt: OrderForListDto = item;
            if (item?.customValues) {
              Object.keys(item.customValues).forEach(
                (customFieldInternalName) => {
                  if (
                    !resultWarehouseReceipt.hasOwnProperty(
                      customFieldInternalName,
                    )
                  )
                    resultWarehouseReceipt[customFieldInternalName] =
                      item.customValues[customFieldInternalName];
                },
              );
            }
            return item;
          }),
        };
        setWarehouseReceipts(warehouseReceiptDtoResult);
        setGetDataSuccess(true);
      },
      () => {},
    );
  };

  const {
    warehouseReceiptColumns: columns,
    defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(warehouseReceiptsStore);

  const onDeleteWarehouseReceipt = async (warehouseReceipt: OrderDto) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${warehouseReceipt.orderNumber} WarehouseReceipt`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteOrder(warehouseReceipt);
        onDelete();
        getWarehouseReceiptsData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditWarehouseReceipt = (warehouseReceipt: OrderDto) => {
    goToDetails({ orderId: warehouseReceipt.orderId });
  };

  const onCopyWarehouseReceipt = async (warehouseReceipt: OrderDto) => {
    const warehouseReceiptCopy = { ...warehouseReceipt };
    delete warehouseReceiptCopy.charges;

    await createOrder(warehouseReceiptCopy);
    getWarehouseReceiptsData();
  };

  const navigate = useNavigate();

  const onCreateNewWarehouseReceipt = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(
      INTERNAL_PATH + WAREHOUSE_RECEIPT_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    navigate(createPath);
  };

  const onChangeItem = (data?: any[]) => {
    setWarehouseReceipts((warehouseReceiptsDto) => {
      warehouseReceiptsDto.items = data;
      return { ...warehouseReceiptsDto };
    });
  };

  const onWarehouseReceiptSelect = async (warehouseReceipt, orderId) => {
    onSelect?.(warehouseReceipt, orderId);
    if (warehouseReceipt?.orderId) {
      const selectedWarehouseReceipt = await showDialog({
        dialog: WarehouseReceiptDialog,
        props: {
          className: 'order-modal',
          title: 'Update Warehouse Receipt',
          orderId: warehouseReceipt?.orderId,
        },
      });
      if (selectedWarehouseReceipt !== null) {
        getWarehouseReceiptsData();
      }
    }
  };

  // @ts-ignore
  const { organizationId } = useParams();

  const onReceiptCreated = () => {
    const path = generatePath(INTERNAL_PATH + WAREHOUSE_RECEIPT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'warehouse-receipt-created',
      type: 'success',
      autoHide: true,
      message: 'Warehouse receipt successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onFindCommodities = async () => {
    await showDialog({
      dialog: CreateByPurchaseWizardDialog,
      props: {
        className: 'order-modal',
        title: 'Find Commodities',
        onReceiptSaved: onReceiptCreated,
      },
    });
    getWarehouseReceiptsData();
  };

  const onPackageReceive = async () => {
    await showDialog({
      dialog: PackageReceiveWizardDialog,
      props: {
        title: 'Receive Package',
        bodyClassName: 'modal-filled-body',
      },
    });
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <WarehouseReceiptGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={['orderId']}
          data={warehouseReceipts?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={warehouseReceipts?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_ORDER_LINK_KEY, warehouseReceipts?.items[0]?.links)
              ? onDeleteWarehouseReceipt
              : null
          }
          onSort={(field) => {
            onSort(field);
          }}
          search={search}
          onEdit={onEditWarehouseReceipt}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onLimitChanged={onLimitChanged}
          onSelect={onWarehouseReceiptSelect}
          onChangeItem={onChangeItem}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
          onFindCommodities={onFindCommodities}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          showToolbar={false}
          entityType={OrderTypes.WarehouseReceipt}
        />
        {!search && !warehouseReceipts?.items?.length && (
          <ListElementsNotFound
            entityName="Warehouse Receipt"
            entityLinkKey={CREATE_ORDER_LINK_KEY}
            onClick={onCreateNewWarehouseReceipt}
          />
        )}
      </div>
    );
  }
};
