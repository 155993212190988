import { createStore, Store, createEffect } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import { getUserRolesListRequest } from './userRoles.service';

type UserRolesStoreState = {};

export const getUserRoles = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUserRolesListRequest(currentOrganization, params);
});

const defaultState: UserRolesStoreState = {};

export const roleStore: Store<UserRolesStoreState> = createStore(defaultState);
