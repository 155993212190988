import { Dialog } from '../../common/components/dialog/dialog.component';
import { WarehouseLocationEdit } from './warehouseLocation-edit.component';
import { WarehouseLocationDto } from '../../../models/data.models';

export type WarehouseLocationDialogProperties = {
  warehouseLocationId?: number | null;
  title: string;
};

export class WarehouseLocationDialog extends Dialog<WarehouseLocationDialogProperties> {
  constructor(props: WarehouseLocationDialogProperties) {
    super(props);
    this.onWarehouseLocationSaved = this.onWarehouseLocationSaved.bind(this);
  }

  onWarehouseLocationSaved = (warehouseLocation: WarehouseLocationDto) => {
    this.close(warehouseLocation);
  };

  renderContent(): any {
    const { warehouseLocationId } = this
      .props as WarehouseLocationDialogProperties;
    return (
      <WarehouseLocationEdit
        warehouseLocationId={warehouseLocationId}
        onWarehouseLocationCreated={this.onWarehouseLocationSaved}
        onWarehouseLocationUpdated={this.onWarehouseLocationSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
