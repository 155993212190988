import {
  CommodityTypeDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  CommodityTypeDtoPagedResult,
  CreateCommodityTypeCommandValues,
  UpdateCommodityTypeCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_COMMODITYTYPES_LINK_KEY = 'get-commoditytypes';
export const CREATE_COMMODITYTYPE_LINK_KEY = 'create-commoditytype';
export const GET_COMMODITYTYPE_LINK_KEY = 'get-commoditytype';
export const UPDATE_COMMODITYTYPE_LINK_KEY = 'update-commoditytype';
export const DELETE_COMMODITYTYPE_LINK_KEY = 'delete-commoditytype';
export const IMPORT_COMMODITYTYPES_LINK_KEY = 'import-commoditytypes';
export const EXPORT_COMMODITYTYPES_LINK_KEY = 'export-commoditytypes';
export const COMMODITYTYPE_ENTITY_NAME = 'Commodity Type';

export interface GetCommodityTypeParams {
  commodityTypeId: number;
}

export interface ImportCommodityTypesParams {
  organizationId: number;
}

export interface ExportCommodityTypesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getCommodityTypeRequest = async (
  resource: LinkResourceBaseDto | null,
  { commodityTypeId }: GetCommodityTypeParams,
): Promise<CommodityTypeDto> => {
  if (resource && commodityTypeId) {
    const getCommodityTypeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITYTYPE_LINK_KEY,
    );
    if (getCommodityTypeLink) {
      const result = await execLink(getCommodityTypeLink, { commodityTypeId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COMMODITYTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCommodityTypesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CommodityTypeDtoPagedResult> => {
  if (resource) {
    const getCommodityTypesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITYTYPES_LINK_KEY,
    );
    if (getCommodityTypesListLink) {
      const result = await execLink(getCommodityTypesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COMMODITYTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCommodityTypeRequest = async (
  resource: LinkResourceBaseDto,
  commodityType: CreateCommodityTypeCommandValues,
) => {
  const createCommodityTypeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_COMMODITYTYPE_LINK_KEY,
  );
  if (createCommodityTypeLink) {
    const result = await execLink(createCommodityTypeLink, commodityType);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_COMMODITYTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateCommodityTypeRequest = async (
  resource: LinkResourceBaseDto,
  commodityType: UpdateCommodityTypeCommandValues,
) => {
  const updateCommodityTypeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_COMMODITYTYPE_LINK_KEY,
  );
  if (updateCommodityTypeLink) {
    const result = await execLink(updateCommodityTypeLink, commodityType);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_COMMODITYTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteCommodityTypeRequest = async (
  commodityType: CommodityTypeDto,
) => {
  const deleteCommodityTypeLink = commodityType.links?.find(
    (x: LinkDto) => x.rel === DELETE_COMMODITYTYPE_LINK_KEY,
  );
  if (deleteCommodityTypeLink) {
    const result = await execLink(deleteCommodityTypeLink, commodityType);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_COMMODITYTYPE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importCommodityTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportCommodityTypesParams,
  file: File,
) => {
  const importCommodityTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_COMMODITYTYPES_LINK_KEY,
  );
  if (importCommodityTypesLink) {
    const result = await postFormData(importCommodityTypesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_COMMODITYTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportCommodityTypesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportCommodityTypesParams,
) => {
  const exportCommodityTypesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_COMMODITYTYPES_LINK_KEY,
  );
  if (exportCommodityTypesLink) {
    const result = await downloadLinkFile(exportCommodityTypesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_COMMODITYTYPES_LINK_KEY} is not allowed or missing link action`,
  );
};
