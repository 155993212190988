import React from 'react';

import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { Form, FormProps } from '../../common/components/form/form.component';
import { ConfirmPassword } from '../../common/components/confirm-password/confirm-password.component';

export const SignUpForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

SignUpForm.FirstName = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'firstName'}
      label={'First Name'}
      placeholder={'Enter your first name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

SignUpForm.LastName = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'lastName'}
      label={'Last Name'}
      placeholder={'Enter your last name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

SignUpForm.Email = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'email'}
      label={'Email'}
      placeholder={'Enter email'}
      required={true}
      type={'email'}
      className={className}
      style={style}
      size={size}
    />
  );
};

SignUpForm.Username = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'userName'}
      label={'Username'}
      placeholder={'Enter username'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

SignUpForm.Password = ({
  className,
  style,
  size,
  label = 'Password',
}: InputPublicProps) => {
  return (
    <ConfirmPassword
      name={'password'}
      label={label}
      required={true}
      className={className}
      style={style}
      size={size}
    />
  );
};
