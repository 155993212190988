import * as yup from 'yup';

const itemSchema = (validation: any, key, schema?: any) => {
  let yupType: any;
  schema = schema || yup;

  switch (validation.type) {
    case 'string':
      yupType = schema.string().nullable();
      break;
    case 'number':
      yupType = schema.number();
      break;
    case 'boolean':
      yupType = schema.boolean();
      break;
    case 'date':
      yupType = schema.date();
      break;
    case 'array':
      yupType = schema.array();
      if (validation.itemSchema) {
        yupType = yupType.of(itemSchema(validation.itemSchema, `${key} item`));
      }
      break;
    case 'object':
      yupType = schema.object();
      if (validation.schema) {
        yupType = yupType.shape(
          Object.keys(validation.schema).reduce((acc, k) => {
            return {
              ...acc,
              [k]: itemSchema(validation.schema[k], k),
            };
          }, {}),
        );
      }
      break;
    default:
      yupType = schema.string();
  }

  // transform
  if (validation.transform) {
    const { condition, transformTo } = validation.transform;
    yupType = yupType.transform((value) =>
      new Function('value', `return ${condition}`)(value) ? transformTo : value,
    );
  }

  if (validation.required) {
    yupType = yupType.required(
      validation.required.message || `${key} field is required`,
    );
  }

  if (validation.nullable !== undefined) {
    yupType = yupType.nullable(validation.nullable || true);
  }

  if (validation.url !== undefined) {
    yupType = yupType.url(
      validation.url?.message || `${key} field is invalid. Must be a url`,
    );
  }

  if (validation.email !== undefined) {
    yupType = yupType.email(
      validation.email?.message || `${key} field is invalid. Must be an email`,
    );
  }

  if (validation.positive !== undefined) {
    yupType = yupType.positive(
      validation.positive?.message ||
        `${key} field is invalid. Must be positive`,
    );
  }

  if (validation.negative !== undefined) {
    yupType = yupType.negative(
      validation.negative?.message ||
        `${key} field is invalid. Must be negative`,
    );
  }

  if (validation.integer !== undefined) {
    yupType = yupType.integer(
      validation.integer?.message ||
        `${key} field is invalid. Must be an integer`,
    );
  }

  // min and max
  if (validation.min) {
    yupType = yupType.min(
      validation.min.length || validation.min,
      validation.min.message ||
        `${key} field should be greater than ${validation.min}`,
    );
  }

  if (validation.max) {
    yupType = yupType.max(
      validation.max.length || validation.max,
      validation.max.message ||
        `${key} field should be less than ${validation.max}`,
    );
  }

  // moreThan and lessThan
  if (validation.moreThan) {
    yupType = yupType.moreThan(
      validation.moreThan.value,
      validation.moreThan.message ||
        `${key} field should be more than ${validation.moreThan.value}`,
    );
  }

  if (validation.lessThan) {
    yupType = yupType.lessThan(
      validation.lessThan.value,
      validation.lessThan.message ||
        `${key} field should be less than ${validation.lessThan.value}`,
    );
  }

  // matches
  if (validation.matches) {
    yupType = yupType.matches(
      validation.matches.regex,
      validation.matches.message || `${key} field is invalid`,
    );
  }

  // when
  if (validation.when) {
    // for each key in when, create a when statement
    Object.keys(validation.when).forEach((property) => {
      const { is, negated, then, otherwise } = validation.when[property];
      const isMatch = (value) => {
        let val = value;
        if (typeof value === 'string') {
          val = value.trim();
        }
        if (val === undefined || val === null) {
          val = '';
        }
        return negated ? val !== is : val === is;
      };

      yupType = yupType.when(property, {
        is: isMatch,
        then: then !== undefined ? itemSchema(then, property) : undefined,
        otherwise:
          otherwise !== undefined
            ? (s) => itemSchema(otherwise, property, s)
            : undefined,
      });
    });
  }

  return yupType;
};
// validationSchemaToYup2 is key based
export const validationSchemaToYup = (validationSchemaDef: any) => {
  const yupSchema = Object.keys(validationSchemaDef).reduce((acc, key) => {
    const validation = validationSchemaDef[key];
    return {
      ...acc,
      [key]: itemSchema(validation, key),
    };
  }, {});

  return yup.object().shape(yupSchema);
};
