import {
  CreateChargeCommand,
  CreateOrderCommandValues,
  CreateOrderDocumentCommandValues,
  CreateOrderEntityCommandValues,
  CreateTrackingEventCommand,
  EntityFieldDto,
  LinkDto,
  LinkResourceBaseDto,
  OrderDto,
  OrderTypes,
  UpdateChargeCommand,
  UpdateCommodityCommand,
  UpdateOrderCommandValues,
  UpdateOrderEntityCommand,
  UpdateTrackingEventCommand,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrderRequest,
  deleteOrderRequest,
  GetOrderParams,
  getOrderRequest,
  getOrdersContactCommoditiesListRequest,
  getOrdersListRequest,
  PatchOrderParams,
  patchOrderRequest,
  updateMovementRequest,
} from './cargoMovements.service';
import { getEntityFieldListRequest } from '../entityFields/entityFields.service';

type OrdersStoreState = {
  links: LinkDto[];
  orderColumns: EntityFieldDto[];
  defaultColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getOrdersColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CargoMovement',
  });
});

export const getOrdersDefaultColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'CargoMovement',
  });
});

export const updateOrdersColumns = createEvent<EntityFieldDto[]>();

export const getOrders = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getOrdersListRequest(currentOrganization, params);
});

const carriersAndCustomValuesCreation = (order: OrderDto) => {
  let carriersId: number[] = [];
  if (
    order.orderType === OrderTypes.Quote ||
    order.orderType === OrderTypes.WarehouseReceipt
  ) {
    if (order.carrierContactId) {
      carriersId.push(order.carrierContactId);
    }
  } else {
    carriersId = order?.carriers?.map<number>((item) => {
      return item.contactId;
    });
  }

  let customValues = order.customValues ?? {};

  const specialQuoteFields: string[] = [
    'modeOfTransportationId',
    'modeOfTransportationDescription',
    'paymentTermId',
    'paymentTermDescription',
    'expDate',
    'quoteStatus',
  ];

  const specialWarehouseReceiptFields: string[] = [
    'modeOfTransportationId',
    'modeOfTransportationDescription',
    'destinationAgentContactId',
    'destinationAgentContactName',
    'issuedByContactId',
    'issuedByContactName',
    'date',
    'time',
    'entryDate',
    'entryNumber',
  ];

  let fields: string[] = [];
  switch (order.orderType) {
    case OrderTypes.Quote:
      fields = specialQuoteFields;
      break;
    case OrderTypes.WarehouseReceipt:
      fields = specialWarehouseReceiptFields;
      break;
  }

  fields.forEach((field) => {
    if (order[field]) {
      customValues[field] = order[field];
    } else {
      delete customValues[field];
    }
  });

  return { carriersId, customValues };
};

export const createOrder = createEffect((order: OrderDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const charges: CreateChargeCommand[] = order?.charges?.map<CreateChargeCommand>(
    (item) => {
      const createChargeCommand: CreateChargeCommand = {
        values: {
          amount: item.amount,
          currencyId: item.currencyId,
          accountingItemId: item.accountingItemId,
          applyBy: item.applyBy,
          applyToContactId: item.applyToContactId,
          chargeType: item.chargeType,
          description: item.description,
          freightServiceClassId: item.freightServiceClassId,
          grossVolume: item.grossVolume,
          grossWeight: item.grossWeight,
          isConsolidated: item.isConsolidated,
          note: item.note,
          paidAs: item.paidAs,
          pieces: item.pieces,
          price: item.price,
          quantity: item.quantity,
          showInDocuments: item.showInDocuments,
          unit: item.unit,
          salesTaxRate: item.salesTaxRate ?? 0,
          totalAmount: item.totalAmount,
          salesTaxId: item.salesTaxId,
          salesTaxAmount: item.salesTaxAmount,
          rateId: item.rateId,
          allowAutomaticUpdate: item.allowAutomaticUpdate,
        },
      };
      return createChargeCommand;
    },
  );

  const trackingEvents: CreateTrackingEventCommand[] = order?.trackingEvents?.map<CreateTrackingEventCommand>(
    (item) => {
      const createTrackingEventCommand: CreateTrackingEventCommand = {
        values: {
          description: item.description,
          location: item.location,
          eventDate: item.eventDate,
          eventDefinitionId: item.eventDefinitionId,
          isInactive: item.isInactive,
          includeInTracking: item.includeInTracking,
          sendEmail: item.sendEmail,
        },
      };
      return createTrackingEventCommand;
    },
  );

  const orderEntities: CreateOrderEntityCommandValues[] = order?.orderEntities?.map<CreateOrderEntityCommandValues>(
    (item) => {
      const createOrderEntityCommandValues: CreateOrderEntityCommandValues = {
        contactId: item.contactId,
        contactAddressId: item.contactAddressId,
        customValues: item.customValues ?? {},
        nonContactName: '',
        orderEntitySequence: item.orderEntitySequence,
        entityType: item.entityType,
      };

      return createOrderEntityCommandValues;
    },
  );

  const orderDocuments: CreateOrderDocumentCommandValues[] = order?.orderDocuments?.map<CreateOrderDocumentCommandValues>(
    (item) => {
      const createOrderDocumentCommandValues: CreateOrderDocumentCommandValues = {
        documentTemplateId: item.documentTemplateId,
        regenerateOnOrderChange: item.regenerateOnOrderChange,
      };
      return createOrderDocumentCommandValues;
    },
  );

  const { carriersId, customValues } = carriersAndCustomValuesCreation(order);

  const createOrderCommand: CreateOrderCommandValues = {
    orderStatusId: order.orderStatusId,
    commodities: order.commodities,
    billToContactId: order.billToContactId,
    carrierContactId: order.carrierContactId,
    divisionId: order.divisionId,
    equipmentTypeId: order.equipmentTypeId,
    employeeContactId: order.employeeContactId,
    salespersonContactId: order.salespersonContactId,
    orderNumber: order.orderNumber,
    charges,
    trackingEvents,
    carriersId,
    customValues,
    orderEntities,
    orderDocuments,
    trackingNumber: order.trackingNumber,
    orderType: order.orderType,
  };
  return createOrderRequest(currentOrganization!, createOrderCommand);
});

export const getOrder = createEffect((orderParams: GetOrderParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getOrderRequest(
    currentOrganization as LinkResourceBaseDto,
    orderParams,
  );
});

export const updateMovement = createEffect((order: OrderDto) => {
  const charges: UpdateChargeCommand[] = order?.charges?.map<UpdateChargeCommand>(
    (item) => {
      const updateChargeCommand: UpdateChargeCommand = {
        chargeId: item.chargeId,
        values: {
          amount: item.amount,
          currencyId: item.currencyId,
          accountingItemId: item.accountingItemId,
          applyBy: item.applyBy,
          applyToContactId: item.applyToContactId,
          chargeType: item.chargeType,
          description: item.description,
          freightServiceClassId: item.freightServiceClassId,
          grossVolume: item.grossVolume,
          grossWeight: item.grossWeight,
          isConsolidated: item.isConsolidated,
          isDeleted: item.isDeleted,
          note: item.note,
          paidAs: item.paidAs,
          pieces: item.pieces,
          price: item.price,
          quantity: item.quantity,
          showInDocuments: item.showInDocuments,
          unit: item.unit,
          salesTaxRate: item.salesTaxRate ?? 0,
          totalAmount: item.totalAmount,
          salesTaxId: item.salesTaxId,
          salesTaxAmount: item.salesTaxAmount,
          rateId: item.rateId,
          allowAutomaticUpdate: item.allowAutomaticUpdate,
        },
      };
      return updateChargeCommand;
    },
  );

  const commodities: UpdateCommodityCommand[] = order?.commodities?.map<UpdateCommodityCommand>(
    (item) => {
      const updateCommodityCommand: UpdateCommodityCommand = {
        commodityId: item.commodityId,
        values: {
          ...item,
        },
      };
      return updateCommodityCommand;
    },
  );

  const trackingEvents: UpdateTrackingEventCommand[] = order?.trackingEvents?.map<UpdateTrackingEventCommand>(
    (item) => {
      const updateTrackingEventCommand: UpdateTrackingEventCommand = {
        trackingEventId: item.trackingEventId,
        values: {
          description: item.description,
          location: item.location,
          eventDate: item.eventDate,
          eventDefinitionId: item.eventDefinitionId,
          isInactive: item.isInactive,
          includeInTracking: item.includeInTracking,
          sendEmail: item.sendEmail,
        },
      };
      return updateTrackingEventCommand;
    },
  );

  const orderEntities: UpdateOrderEntityCommand[] = order?.orderEntities?.map<UpdateOrderEntityCommand>(
    (item) => {
      const updateOrderEntityCommand: UpdateOrderEntityCommand = {
        orderEntityId: item.orderEntityId,
        values: {
          contactAddressId: item.contactAddressId,
          contactId: item.contactId,
          nonContactName: item.nonContactName,
          orderEntitySequence: item.orderEntitySequence,
          entityType: item.entityType,
          customValues: item.customValues ?? {},
        },
      };
      return updateOrderEntityCommand;
    },
  );

  const { carriersId, customValues } = carriersAndCustomValuesCreation(order);

  const updateOrderCommand: UpdateOrderCommandValues = {
    orderStatusId: order.orderStatusId,
    commodities,
    billToContactId: order.billToContactId,
    divisionId: order.divisionId,
    equipmentTypeId: order.equipmentTypeId,
    employeeContactId: order.employeeContactId,
    salespersonContactId: order.salespersonContactId,
    orderNumber: order.orderNumber,
    charges,
    trackingEvents,
    carriersId,
    customValues,
    orderEntities,
    trackingNumber: order.trackingNumber,
    orderType: order.orderType,
  };
  return updateMovementRequest(order, updateOrderCommand);
});

export const patchOrder = createEffect((patchOrderParams: PatchOrderParams) => {
  return patchOrderRequest(
    patchOrderParams.resource,
    patchOrderParams.patchOrderCommand,
  );
});

export const deleteOrder = createEffect((order: OrderDto) => {
  return deleteOrderRequest(order);
});

const defaultState: OrdersStoreState = {
  links: [],
  orderColumns: [],
  defaultColumns: [],
  defaultSort: '-orderNumber~ToInt32',
  defaultLimit: 20,
};

export const ordersStore: Store<OrdersStoreState> = createStore(defaultState)
  .on(getOrdersColumns.done, (state, payload) => {
    return {
      ...state,
      orderColumns: payload.result.items,
      defaultColumns: payload.result.items,
    };
  })
  .on(getOrdersDefaultColumns.done, (state, payload) => {
    return {
      ...state,
      defaultColumns: payload.result.items,
    };
  })
  .on(updateOrdersColumns, (state, payload) => {
    return { ...state, orderColumns: payload };
  });

export const getOrdersContactCommodities = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrdersContactCommoditiesListRequest(currentOrganization, params);
  },
);
