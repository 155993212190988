import { Dialog } from '../../common/components/dialog/dialog.component';
import { CommodityTypeEdit } from './commodityType-edit.component';
import { CommodityTypeDto } from '../../../models/data.models';

export type CommodityTypeDialogProperties = {
  commodityTypeId?: number | null;
  title: string;
};

export class CommodityTypeDialog extends Dialog<CommodityTypeDialogProperties> {
  constructor(props: CommodityTypeDialogProperties) {
    super(props);
    this.onCommodityTypeSaved = this.onCommodityTypeSaved.bind(this);
  }

  onCommodityTypeSaved = (commodityType: CommodityTypeDto) => {
    this.close(commodityType);
  };

  renderContent(): any {
    const { commodityTypeId } = this.props as CommodityTypeDialogProperties;
    return (
      <CommodityTypeEdit
        commodityTypeId={commodityTypeId}
        onCommodityTypeCreated={this.onCommodityTypeSaved}
        onCommodityTypeUpdated={this.onCommodityTypeSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
