import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PackageReceiveContext } from './packageReceiveWizard.component';
import { CommoditiesTable } from '../components/common/commoditiesTable';
import { TrackingNumbers } from '../components/common/trackingNumbers.component';
import { getCommodityTrackingNumbers } from '../../commodityTrackingNumbers/commodityTrackingNumbers.store';
import { PalletComponent } from '../components/common/pallet.component';
import {
  AttachmentDto,
  AttachmentParentType,
  CargoMovementType,
  CommodityTrackingNumberDto,
  ContactDto,
  ContactType,
  LocationType,
  OrderContextValues,
  OrderDto,
  OrderTypes,
  ScanningResultFrom,
} from '../../../models/data.models';
import { getContacts } from '../../contacts/contacts.store';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { HiX } from 'react-icons/hi';
import { authStore } from '../../auth/auth.store';
import { CustomerInformation } from '../../customers/components/common/customerInformation.component';
import { ParcelShipmentInformation } from '../../parcelShipments/components/common/parcelShipmentInformation.component';
import { useStore } from 'effector-react/effector-react.cjs';
import { receivingCommodityTableStore } from '../../commodities/commodities.store';
import { OrderForm } from '../../orders/components/order.form';
import { FormContext } from '../../common/components/form/form.component';
import { MAX_FETCH_LIMIT } from '../../constants/api';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { updateAttachment } from '../../attachments/attachments.store';
import { GetOrderByTrackingNumbersParams } from '../../orders/orders.service';
import { getOrdersByTrackingNumbers } from '../../orders/orders.store';
import { createActionEventFx } from '../../actionEvents/actionEvents.store';
import { Spinner } from '../../common/components/spinner/spinner';
import { showDialog } from '../../common/dialog.store';
import { ReportProblemReasonDialog } from '../../cargoMovement/picking/reportProblemReason.dialog';

export type TrackingNumberScanFragmentProps = {
  handleBack: () => void;
  setTrackingNumbers: any;
  setNotification?: any;
  setPurchase: any;
  setParcelShipment: any;
};

export const PackageInfoFragment = ({
  handleBack,
  setTrackingNumbers,
  setNotification,
  setPurchase,
  setParcelShipment,
}: TrackingNumberScanFragmentProps) => {
  const { purchase, parcelShipment, trackingNumbers } = useContext(
    PackageReceiveContext,
  );
  const { user: currentUser } = authStore.getState();
  const { commodityColumns: columns } = useStore(receivingCommodityTableStore);
  const [defaultDispatcher, setDefaultDispatcher] = useState<ContactDto>(null);
  const [
    orderContextValues,
    setOrderContextValues,
  ] = useState<OrderContextValues | null>({
    billToContactId: purchase?.billToContactId ?? null,
    packageDescription: null,
    employeeContactId: purchase?.employeeContactId ?? null,
  });
  const [orderAttachments, setOrderAttachments] = useState<AttachmentDto[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [isConsolidated, setIsConsolidated] = useState<boolean>(
    parcelShipment?.customValues?.['isConsolidated'] === 'true' || false,
  );

  useEffect(() => {
    if (trackingNumbers.length === 0) {
      const trackingNumber: CommodityTrackingNumberDto = {
        commodityId: 0,
        commodityTrackingNumberId: 0,
        trackingNumber: purchase.trackingNumber,
        isPrimary: true,
      };
      trackingNumbers.push(trackingNumber);
    }
  }, []);

  useEffect(() => {
    getCommodityTrackingNumbers({
      limit: MAX_FETCH_LIMIT,
      filter: 'Commodity.CommodityStatus.StatusStage: Pending',
    }).then(
      (commodityTrackingNumbers) => {
        commodityTrackingNumbers?.items?.forEach((commodityTrackingNumber) => {
          const isDuplicate = trackingNumbers.some(
            (item) =>
              item.trackingNumber === commodityTrackingNumber.trackingNumber,
          );
          if (
            commodityTrackingNumber.commodityId !==
              purchase?.commodities[0]?.commodityId ||
            isDuplicate
          ) {
            return;
          } else {
            setTrackingNumbers((prevNumbers) => {
              return [...prevNumbers, commodityTrackingNumber];
            });
          }
        });
      },
      () => {},
    );
  }, []);

  useEffect(() => {
    if (!defaultDispatcher) {
      getContacts({ filter: `contactType:${ContactType.Employee}` }).then(
        (result) => {
          const dispatcher = result.items.find(
            (employee) => employee.userEmployee?.userId == currentUser?.userId,
          );
          setDefaultDispatcher(dispatcher);
        },
        () => {},
      );
    }
  }, []);

  useEffect(() => {
    if (handleBack)
      setActionButtons([
        <Button
          key={'package-receive-info-fragment-cancel-btn'}
          color="secondary"
          className={'d-flex align-items-center justify-content-center'}
          outline={true}
          onClick={handleBack}
          style={{ minWidth: '8.5rem' }}
        >
          <HiX size={16} className={'mr-2'} />
          Cancel
        </Button>,
      ]);
  }, []);

  useEffect(() => () => clearActionButtons(), []);

  useScanner((scannerResult: ScanningResult) => {
    catchScanResult(scannerResult);
  });

  const catchScanResult = (scanningResult: ScanningResult) => {
    if (scanningResult.from === ScanningResultFrom.Scanner) {
      if (scanningResult.data) {
        // check not exists in trackingNumbers
        if (
          trackingNumbers.find(
            (tn) => tn.trackingNumber === scanningResult.data,
          )
        ) {
          return;
        }
        setTrackingNumbers([
          ...trackingNumbers,
          {
            trackingNumber: scanningResult.data,
            commodityId: purchase?.commodities[0]?.commodityId,
          },
        ]);
      }
    }
  };

  const onUpdateAttachments = (order: OrderDto) => {
    if (orderAttachments?.length > 0) {
      orderAttachments.forEach((attachment) => {
        if (!attachment?.parentId && order?.orderId) {
          const updatedAttachment = {
            ...attachment,
            parentId: order.orderId,
          };
          updateAttachment(updatedAttachment);
        }
      });
    }
  };

  useEffect(() => {
    if (trackingNumbers && trackingNumbers.length > 1) {
      const params: GetOrderByTrackingNumbersParams = {
        trackingNumbers: [],
      };
      const newTrackingNumber = trackingNumbers[trackingNumbers.length - 1];
      params.trackingNumbers.push(newTrackingNumber.trackingNumber);

      setIsSending(true);
      getOrdersByTrackingNumbers(params)
        .then(
          async (ordersDto: OrderDto[]) => {
            await createActionEventFx({
              eventName: 'purchase.receive',
              eventData: {
                trackingNumber: newTrackingNumber?.trackingNumber,
                location: 'RCV',
              },
            });

            const initialTrackingNumber = trackingNumbers?.find(
              (x) => x.commodityId === 0,
            )?.trackingNumber;

            const purchase = initialTrackingNumber
              ? ordersDto.find(
                  (order: OrderDto) =>
                    order.orderType === OrderTypes.Purchase &&
                    order.trackingNumber == initialTrackingNumber,
                )
              : ordersDto.find(
                  (order: OrderDto) => order.orderType === OrderTypes.Purchase,
                );

            const parcelShipment = ordersDto.find(
              (order: OrderDto) =>
                order.orderType === OrderTypes.ParcelShipment,
            );
            setPurchase(purchase ?? parcelShipment); // [?? parcelShipment] is temp fix for parcels receiving
            setParcelShipment(parcelShipment);
            setIsConsolidated(
              parcelShipment?.customValues?.['isConsolidated'] === 'true' ||
                false,
            );
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  }, [trackingNumbers]);

  useEffect(() => {
    const putAwayPalletInput = document.querySelector(
      `[name="${LocationType.PutAway}PalletNumber"]`,
    ) as HTMLInputElement;
    const packingPalletInput = document.querySelector(
      `[name="${LocationType.Packing}PalletNumber"]`,
    ) as HTMLInputElement;

    if (purchase?.billToContactId && parcelShipment && !isConsolidated) {
      packingPalletInput?.focus();
    } else if (
      purchase?.billToContactId &&
      (!parcelShipment || isConsolidated)
    ) {
      putAwayPalletInput?.focus();
    }
  }, [purchase, parcelShipment]);

  return (
    <div className="px-3 pb-3">
      <h3 className="pb-3">Purchase Information</h3>
      <div className="row d-flex justify-content-between mb-5">
        <div className="col-12 mb-5">
          <div className="box">
            <TrackingNumbers
              commodityTrackingNumbers={trackingNumbers}
              setCommodityTrackingNumbers={setTrackingNumbers}
              allowEdit={true}
              commodityId={purchase?.commodities[0]?.commodityId}
            />
          </div>
        </div>
        <div className="col">
          <OrderForm id={'ParcelShipmentForm'} onSubmit={() => {}}>
            <FormContext.Consumer>
              {(context) =>
                isSending ? (
                  <Spinner />
                ) : (
                  <div className="row d-flex justify-content-between">
                    <div className="col">
                      <div className="box mr-1 h-100">
                        <CustomerInformation
                          context={context}
                          contactId={purchase?.billToContactId}
                          setOrderContextValues={setOrderContextValues}
                          isCustomerAccountNotAssigned={
                            orderContextValues?.billToContactId === undefined
                          }
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="box ml-1 h-100">
                        <ParcelShipmentInformation
                          order={parcelShipment}
                          purchase={purchase}
                          setOrderContextValues={setOrderContextValues}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
            </FormContext.Consumer>
          </OrderForm>
        </div>
      </div>

      <div className={'row mb-5'}>
        <div className={parcelShipment && !isConsolidated ? 'col-4' : 'col-6'}>
          <div className={'box h-100 mr-1'}>
            <PalletComponent
              locationType={LocationType.Service}
              movementType={CargoMovementType.Service}
              currentLocation={LocationType.Receiving}
              commodities={purchase?.commodities}
              label={'Service Pallet #'}
              buttonName={'Move To Service'}
              buttonColor={'action-secondary'}
              dispatcherId={defaultDispatcher?.contactId}
              divisionId={currentUser?.divisionId}
              onComplete={(data) => {
                handleBack();
              }}
              orderContextValues={orderContextValues}
              trackingNumbers={trackingNumbers}
              onUpdateAttachments={onUpdateAttachments}
              setNotification={setNotification}
              purchase={purchase}
            />
          </div>
        </div>
        <div className={parcelShipment && !isConsolidated ? 'col-4' : 'col-6'}>
          <div className={'box h-100 mx-1'}>
            <PalletComponent
              locationType={LocationType.PutAway}
              movementType={CargoMovementType.PutAway}
              currentLocation={LocationType.Receiving}
              commodities={purchase?.commodities}
              label={'Put Away Pallet #'}
              buttonName={'Put Away'}
              buttonColor={'action-waiting'}
              dispatcherId={defaultDispatcher?.contactId}
              divisionId={currentUser?.divisionId}
              onComplete={handleBack}
              orderContextValues={orderContextValues}
              trackingNumbers={trackingNumbers}
              onUpdateAttachments={onUpdateAttachments}
              setNotification={setNotification}
              purchase={purchase}
              setOrderContextValues={setOrderContextValues}
              attachments={orderAttachments}
            />
          </div>
        </div>{' '}
        {parcelShipment && !isConsolidated && (
          <div className="col-4">
            <div className={'box h-100 ml-1'}>
              <PalletComponent
                locationType={LocationType.Packing}
                movementType={CargoMovementType.Local}
                currentLocation={LocationType.Receiving}
                commodities={purchase?.commodities}
                label={'Packing Pallet #'}
                buttonName={'Move To Packing'}
                buttonColor={'primary'}
                dispatcherId={defaultDispatcher?.contactId}
                divisionId={currentUser?.divisionId}
                onComplete={handleBack}
                orderContextValues={orderContextValues}
                trackingNumbers={trackingNumbers}
                purchase={purchase}
                setNotification={setNotification}
                isVerifiedAll={true}
              />
            </div>
          </div>
        )}
      </div>
      {purchase?.commodities && (
        <div className="mb-6">
          <CommoditiesTable
            setCommodities={(commodities) =>
              setPurchase((prev) => {
                prev.commodities = commodities;
                return { ...prev };
              })
            }
            commodities={purchase?.commodities}
            columns={columns}
          />
        </div>
      )}
      <AttachmentsFilesList
        parentId={purchase?.orderId}
        parentType={AttachmentParentType.Order}
        isReceivingOrPacking={true}
        setOrderAttachments={setOrderAttachments}
        prefix={purchase?.trackingNumber}
      />
    </div>
  );
};
