import React, { CSSProperties } from 'react';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import {
  AccountingTransactionStatus,
  AccountingTransactionType,
  AccountType,
  AddressType,
  ApplyBy,
  AttachmentParentType,
  CargoMovementStatus,
  CargoMovementType,
  ChargeStatuses,
  CommodityStatuses,
  CommodityStatusStage,
  ConsolidatedShipmentTypes,
  ContactStatusStage,
  ContactType,
  CustomCodeType,
  CustomFieldDto,
  CustomFieldEntityType,
  DangerousItemsValues,
  DecimalPlace,
  DimensionsUnit,
  DiscountType,
  IDNumberType,
  ItemType,
  LocationType,
  OrderEvents,
  OrderStatuses,
  OrderTypes,
  PaidAs,
  PurposeOfCommodityValues,
  StatusStage,
  TransportationMethod,
  TransportationMethodDescription,
  Trigger,
  TriggerEvents,
  VisibleTransactions,
  VolumeUnit,
  WeightUnit,
} from '../../../../models/data.models';
import { Checkbox } from '../checkbox/checkbox.component';
import { $enum } from 'ts-enum-util';
import moment from 'moment/moment';
import {
  Air,
  Ground,
  Mail,
  Ocean,
  Pipe,
  Rail,
  ReactSelectItem,
  Unknown,
} from '../../../../models/custom.models';
import { ReactSelect } from './input.select';
import DatePicker from 'react-datepicker';
import { countDecimals } from '../../../../utils/helper.utils';
import { FormContext } from '../form/form.component';
import { BsCalendar3 } from 'react-icons/bs';
import { clearMessages } from '../../messages.store';
import { CheckboxGroup } from '../checkbox/checkbox-group.component';
import { ComputedInput } from './input.computed';
import { ScanButton } from '../../../barcodeScanner/components/scan-button.component';
import { AttachmentInput } from '../../../attachments/components/attachment-input.component';
import CreatableSelect from 'react-select/creatable';

function parseCamelCase(text: string): string {
  // Insert space before capital letters and trim the resulting string.
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
}

class InstanceLoader {
  constructor(private context: Object) {}

  getInstance(name: string, ...args: any[]) {
    const instance = Object.create(this.context[name].prototype);
    instance.constructor.apply(instance, args);
    return instance;
  }
}
export type InputProps = {
  size?: 'sm' | 'md' | 'lg';
  type?:
    | 'text'
    | 'unitName'
    | 'input-value'
    | 'number'
    | 'password'
    | 'email'
    | 'date'
    | 'checkbox'
    | 'checkbox-group'
    | 'enum-select'
    | 'radio-button'
    | 'input-OnChange-text-with-value'
    | 'input-OnChange-number-index-with-value'
    | 'input-OnChange-number-with-value'
    | 'input-OnChange-number'
    | 'input-OnChange-text'
    | 'react-select'
    | 'enum-select-onChange'
    | 'textarea'
    | 'json'
    | 'filterText'
    | 'computed-input'
    | 'attachment'
    | 'tags';
  enumName?:
    | typeof OrderStatuses
    | typeof CommodityStatuses
    | typeof WeightUnit
    | typeof VolumeUnit
    | typeof DimensionsUnit
    | typeof IDNumberType
    | typeof AddressType
    | typeof ItemType
    | typeof ApplyBy
    | typeof PaidAs
    | typeof DecimalPlace
    | typeof ChargeStatuses
    | typeof AccountingTransactionStatus
    | typeof AccountingTransactionType
    | typeof AccountType
    | typeof TransportationMethod
    | typeof Ocean
    | typeof Rail
    | typeof Ground
    | typeof Air
    | typeof Pipe
    | typeof Unknown
    | typeof Mail
    | typeof TransportationMethodDescription
    | typeof VisibleTransactions
    | typeof LocationType
    | typeof OrderEvents
    | typeof LocationType
    | typeof CustomCodeType
    | typeof PurposeOfCommodityValues
    | typeof DangerousItemsValues
    | typeof Trigger
    | typeof OrderTypes
    | typeof StatusStage
    | typeof CargoMovementStatus
    | typeof CargoMovementType
    | typeof TriggerEvents
    | typeof ContactType
    | typeof ContactStatusStage
    | typeof ConsolidatedShipmentTypes
    | typeof CommodityStatusStage
    | typeof DiscountType;
  id?: string;
  name?: string;
  ref?: string;
  header?: string;
  label?: string;
  labelStyles?: CSSProperties;
  placeholder?: string;
  required?: boolean;
  readonly?: 'readonly' | '';
  style?: CSSProperties;
  className?: string;
  validate?: (value?: string) => Promise<string>;
  secondLabel?: () => any;
  unitName?: string;
  valueInput?: string | number | object;
  disabled?: boolean;
  minNumber?: number;
  maxNumber?: number;
  defaultValue?: any;
  checkIsCountType?: boolean;
  onChange?: (data: any, context?: any) => void;
  multiple?: boolean;
  options?: ReactSelectItem[];
  isSearchable?: boolean;
  isClearable?: boolean;
  tabIndex?: number;
  useContext?: boolean;
  onBlur?: (data?: any, context?: any) => void;
  maxDecimals?: number;
  parseNumberInput?: boolean;
  updatingCurrentPassword?: boolean;
  rows?: number;
  htmlCode?: boolean;
  customStyles?: any;
  maxLength?: number;
  onClick?: (data?: any, context?: any) => void;
  onKeyDown?: (e?: any) => void;
  onFocus?: (e?: any) => void;
  dependencies?: Array<string>;
  callback?: Function;
  appendElement?: JSX.Element;
  barcode?: boolean;
  autoSubmit?: (data: any) => void;
  barcodeButtonClassName?: string;
  parentId?: number;
  parentType?: AttachmentParentType;
  onAttachmentUploaded?: (data: any) => void;
  setParentValues?: any;
  index?: number;
};

export type InputPublicProps = {
  options?: any[];
  multiple?: boolean;
  sort?: string;
  searchKey?: string;
  style?: CSSProperties;
  readonly?: 'readonly' | '';
  name?: string;
  id?: string;
  label?: string;
  labelStyles?: CSSProperties;
  min?: number;
  className?: string;
  placeholder?: string;
  required?: boolean;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  selectedName?: string | number;
  selectedFieldName?: string;
  selectedFilter?: string;
  defaultValueFilter?: string;
  header?: string;
  listHeader?: string;
  nameId?: string;
  showCreateDialogTitle?: string;
  showUpdateDialogTitle?: string;
  valueInput?: any;
  defaultValue?: any;
  ref?: string;
  tabIndex?: number;
  isClearable?: boolean;
  useContext?: boolean;
  height?: number;
  type?:
    | 'text'
    | 'input-value'
    | 'input-OnChange-text-with-value'
    | 'input-OnChange-number-index-with-value'
    | 'input-OnChange-number-with-value'
    | 'number'
    | 'password'
    | 'email'
    | 'checkbox'
    | 'checkbox-group'
    | 'enum-select'
    | 'radio-button'
    | 'input-OnChange-number'
    | 'input-OnChange-text'
    | 'react-select'
    | 'enum-select-onChange'
    | 'textarea'
    | 'json'
    | 'filterText'
    | 'computed-input'
    | 'attachment'
    | 'tags';
  enumName?:
    | typeof OrderStatuses
    | typeof CommodityStatuses
    | typeof WeightUnit
    | typeof VolumeUnit
    | typeof DimensionsUnit
    | typeof IDNumberType
    | typeof AddressType
    | typeof ItemType
    | typeof ApplyBy
    | typeof PaidAs
    | typeof DecimalPlace
    | typeof ChargeStatuses
    | typeof AccountingTransactionStatus
    | typeof AccountingTransactionType
    | typeof AccountType
    | typeof TransportationMethod
    | typeof Ocean
    | typeof Rail
    | typeof Ground
    | typeof Air
    | typeof Pipe
    | typeof Unknown
    | typeof Mail
    | typeof TransportationMethodDescription
    | typeof VisibleTransactions
    | typeof Trigger
    | typeof OrderTypes
    | typeof StatusStage
    | typeof CargoMovementStatus
    | typeof CargoMovementType
    | typeof TriggerEvents
    | typeof ContactType
    | typeof ContactStatusStage
    | typeof ConsolidatedShipmentTypes
    | typeof CommodityStatusStage
    | typeof DiscountType;
  checkIsCountType?: boolean;
  onChange?: (data?: any, context?: any) => void;
  onBlur?: (data?: any, context?: any) => void;
  maxDecimals?: number;
  parseNumberInput?: boolean;
  onClick?: (data?: any, context?: any) => void;
  onKeyDown?: (e?: any) => void;
  onSelect?: (data: any, context?: any) => string;
  customStyles?: any;
  dependencies?: Array<string>;
  callback?: Function;
  appendElement?: JSX.Element;
  canUpdateEntity?: boolean;
  barcode?: boolean;
  selectsRange?: boolean;
  setWarning?: Function;
  autoSubmit?: (data: any) => void;
  barcodeButtonClassName?: string;
  index?: number;
  output?: string;
};

export interface CustomValuesInputProps extends InputPublicProps {
  entityType: CustomFieldEntityType;
  customFields: CustomFieldDto[];
  inputNamePrefix?: string;
  saveButtonRenderCondition?: boolean;
  isSending: boolean;
  formName: string;
  entityName: string;
  onCancel?: (result: object) => void;
  context?: FormikProps<FormikValues>;
}

export interface ReactSelectInputProps extends InputPublicProps {
  getOptionValue?: (any) => any;
  getOptionLabel?: (any) => string;
  isSearchable?: boolean;
  isClearable?: boolean;
  customStyles?: any;
}

export interface ContactSelectInputProps extends ReactSelectInputProps {
  contactTypes?: ContactType[];
  labelStyles?: CSSProperties;
  closeMenuOnSelect?: boolean;
  showContactType?: boolean;
  showContactName?: boolean;
  selectedOptionLabel?: string;
  canCreateNewEntity?: any;
  canUpdateEntity?: boolean;
}

export interface AttachmentInputProps extends InputPublicProps {
  parentId?: number;
  parentType?: AttachmentParentType;
  context?: FormikProps<FormikValues>;
  setParentValues?: any;
}

// New JsonInput Component
const JsonInput = ({ field, form, ...props }) => {
  const { setFieldValue, setFieldError } = useFormikContext();

  const onChangeJson = (event) => {
    const value = event.target.value;

    try {
      const parsedValue = JSON.parse(value);
      setFieldValue(props.name, parsedValue);
    } catch (error) {
      setFieldError(props.name, 'Invalid JSON');
    }
  };

  return (
    <textarea
      {...field}
      {...props}
      onChange={onChangeJson}
      value={JSON.stringify(props.value)}
    />
  );
};

export const Input = ({
  type = 'text',
  name,
  minNumber,
  maxNumber,
  checkIsCountType,
  valueInput = '',
  enumName = null,
  label,
  labelStyles,
  readonly,
  disabled,
  secondLabel,
  unitName = '',
  size = 'md',
  placeholder,
  defaultValue,
  required = false,
  style = {},
  className = '',
  validate = () => Promise.resolve(''),
  onChange = (data?: any) => {},
  onBlur = () => {},
  multiple = false,
  id = '',
  options = null,
  header,
  isSearchable = true,
  isClearable = true,
  tabIndex = null,
  useContext = true,
  maxDecimals = null,
  parseNumberInput = true,
  rows = 5,
  htmlCode = false,
  customStyles = {},
  maxLength = null,
  onClick = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  dependencies,
  callback,
  appendElement,
  barcode,
  autoSubmit = () => {},
  barcodeButtonClassName = '',
  parentId,
  parentType,
  setParentValues,
  index,
}: InputProps) => {
  const getDateFormat = (date?: Date | null | undefined): string => {
    return moment(date).format('YYYY-MM-DD');
  };

  const formikContext = useFormikContext();
  return (
    <div className={`form-group ${className}`} style={style}>
      {label && type !== 'checkbox' ? (
        <label className="input-label" htmlFor={name} style={labelStyles}>
          <span className="d-flex justify-content-between align-items-center">
            <span className="input-label-primary">{label}</span>
            {secondLabel ? (
              <span className="input-label-secondary">{secondLabel()}</span>
            ) : null}
            {htmlCode ? (
              <div className="menu-icon-wrapper d-flex icon-edit-entity">
                <button
                  className="btn btn-secondary p-0"
                  type="button"
                  onClick={onClick}
                >
                  Prettier
                </button>
              </div>
            ) : null}
          </span>
        </label>
      ) : null}
      <div className="d-flex flex-column prevent-drag">
        {(() => {
          switch (type) {
            case 'react-select':
              return (
                <ReactSelect
                  name={name}
                  id={id}
                  error={false}
                  isSearchable={isSearchable}
                  isClearable={isClearable}
                  readonly={readonly}
                  header={header}
                  placeholder={placeholder}
                  required={required}
                  value={valueInput}
                  defaultValue={defaultValue}
                  multiple={multiple}
                  disabled={disabled}
                  onChange={onChange}
                  options={options}
                  tabIndex={tabIndex}
                  useContext={useContext}
                  customStyles={customStyles}
                />
              );
            case 'enum-select':
              return (
                <div className="position-relative">
                  <Field
                    as="select"
                    placeholder={placeholder}
                    className={`form-control form-control-${size}`}
                    name={name}
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    id={name}
                    tabIndex={tabIndex}
                    onFocus={clearMessages}
                  >
                    {placeholder && <option value="">{placeholder}</option>}
                    {$enum(enumName).map((value, key, wrappedEnum, index) => (
                      <option key={key} value={key}>
                        {parseCamelCase(value)}
                      </option>
                    ))}
                  </Field>
                </div>
              );
            case 'enum-select-onChange':
              return (
                <div className="position-relative">
                  <Field
                    as="select"
                    placeholder={placeholder}
                    className={`form-control form-control-${size}`}
                    name={name}
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    //value={valueInput}
                    onChange={(e) => {
                      valueInput = e?.target?.value;
                      onChange(e?.target?.value);
                    }}
                    id={name}
                    tabIndex={tabIndex}
                    onFocus={clearMessages}
                  >
                    {placeholder && <option value="">{placeholder}</option>}
                    {$enum(enumName).map((value, key, wrappedEnum, index) => (
                      <option key={key} value={key}>
                        {parseCamelCase(value)}
                      </option>
                    ))}
                  </Field>
                </div>
              );
            case 'radio-button':
              return (
                <div>
                  {$enum(enumName).map((value, key, wrappedEnum, index) => (
                    <label
                      className={'d-flex align-items-center'}
                      htmlFor={enumName[value as any]}
                      key={value}
                      role="button"
                    >
                      <Field
                        type="radio"
                        placeholder={placeholder}
                        className={`form-control form-control-${size} mx-1 w-auto`}
                        name={name}
                        value={key}
                        readOnly={readonly}
                        disabled={disabled}
                        required={required}
                        onChange={(e) => {
                          onChange(key);
                        }}
                        id={key}
                      />
                      {value}
                    </label>
                  ))}
                </div>
              );
            case 'checkbox':
              return (
                <Checkbox
                  defaultChecked={defaultValue}
                  label={label}
                  size={size}
                  onChange={onChange}
                  disabled={disabled}
                  className={className}
                  name={name}
                  id={id}
                  required={required}
                />
              );
            case 'checkbox-group':
              return (
                <div>
                  {$enum(enumName).map((value, key, wrappedEnum, index) => (
                    <label
                      className={'d-flex align-items-center my-2'}
                      htmlFor={enumName[value as any]}
                      key={value}
                      role="button"
                    >
                      <CheckboxGroup
                        label={label}
                        size={size}
                        value={key}
                        onChange={onChange}
                        disabled={disabled}
                        className={className}
                        name={name}
                        id={`${id}.${index}`}
                        required={required}
                      />
                      {value}
                    </label>
                  ))}
                </div>
              );
            case 'input-value':
              return (
                <Field
                  label={label}
                  size={size}
                  type={'text'}
                  className={`form-control form-control-${size}`}
                  name={name}
                  value={valueInput}
                  readOnly={readonly}
                  disabled={disabled}
                  required={required}
                  id={name}
                  tabIndex={tabIndex}
                  onFocus={clearMessages}
                />
              );
            case 'input-OnChange-number':
              return (
                <div className={'d-flex'}>
                  <Field
                    placeholder={placeholder}
                    type={'number'}
                    min={minNumber}
                    className={`form-control form-control-${size} ${
                      appendElement || barcode ? 'input-prepend' : ''
                    }`}
                    name={name}
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    onChange={(e) => {
                      valueInput = e?.target?.value;
                      onChange(e?.target?.value);
                      formikContext.setFieldValue(name, valueInput);
                    }}
                    id={name}
                    tabIndex={tabIndex}
                    onFocus={clearMessages}
                    data-barcode={barcode}
                  />
                  {barcode ? (
                    <ScanButton
                      disabled={disabled}
                      name={name}
                      onChange={onChange}
                      setFieldValue={formikContext.setFieldValue}
                    />
                  ) : null}
                  {appendElement || null}
                </div>
              );
            case 'input-OnChange-text':
              return (
                <div className={'d-flex'}>
                  <Field
                    placeholder={placeholder}
                    type={'text'}
                    min={minNumber}
                    className={`form-control form-control-${size} ${
                      appendElement || barcode ? 'input-prepend' : ''
                    }`}
                    name={name}
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    value={defaultValue}
                    onChange={(e) => {
                      onChange(e?.target?.value);
                    }}
                    id={name}
                    tabIndex={tabIndex}
                    onFocus={clearMessages}
                    data-barcode={barcode}
                  />
                  {barcode ? (
                    <ScanButton
                      disabled={disabled}
                      name={name}
                      onChange={onChange}
                      setFieldValue={formikContext.setFieldValue}
                    />
                  ) : null}
                  {appendElement || null}
                </div>
              );

            case 'input-OnChange-text-with-value':
              return (
                <Field
                  placeholder={placeholder}
                  type={'text'}
                  min={minNumber}
                  className={`form-control form-control-${size}`}
                  name={name}
                  readOnly={readonly}
                  disabled={disabled}
                  required={required}
                  onChange={(e) => {
                    onChange(e?.target?.value);
                  }}
                  value={valueInput}
                  id={name}
                  tabIndex={tabIndex}
                  onFocus={clearMessages}
                  maxLength={maxLength}
                />
              );
            case 'input-OnChange-number-with-value':
              return (
                <FormContext.Consumer>
                  {(context) => (
                    <Field
                      onFocus={(e) => {
                        e.target.select();
                        clearMessages();
                      }}
                      placeholder={placeholder}
                      type={'string'}
                      min={minNumber}
                      max={maxNumber}
                      className={`form-control form-control-${size}`}
                      name={name}
                      readOnly={readonly}
                      disabled={disabled}
                      required={required}
                      onChange={(e) => {
                        if (
                          !maxDecimals ||
                          (maxDecimals &&
                            countDecimals(parseFloat(e?.target?.value)) <=
                              maxDecimals)
                        ) {
                          const value = e?.target?.value.replaceAll(',', '.');
                          onChange(
                            parseNumberInput ? parseFloat(value) : value,
                            context,
                          );
                        }
                      }}
                      onBlur={() => {
                        context.setFieldTouched(name, true);
                        onBlur();
                      }}
                      value={valueInput}
                      id={name}
                      tabIndex={tabIndex}
                    />
                  )}
                </FormContext.Consumer>
              );
            case 'input-OnChange-number-index-with-value':
              return (
                <FormContext.Consumer>
                  {(context) => (
                    <Field
                      onFocus={(e) => {
                        e.target.select();
                        clearMessages();
                      }}
                      placeholder={placeholder}
                      type={'number'}
                      min={minNumber}
                      max={maxNumber}
                      className={`form-control form-control-${size} ${className}`}
                      name={name}
                      readOnly={readonly}
                      disabled={disabled}
                      required={required}
                      onChange={(e) => {
                        if (
                          !maxDecimals ||
                          (maxDecimals &&
                            countDecimals(parseFloat(e?.target?.value)) <=
                              maxDecimals)
                        )
                          onChange(
                            {
                              data: parseNumberInput
                                ? parseFloat(e?.target?.value)
                                : e?.target?.value,
                              index: index,
                            },
                            context,
                          );
                      }}
                      onKeyDown={onKeyDown}
                      onBlur={() => {
                        context.setFieldTouched(name, true);
                        onBlur();
                      }}
                      value={valueInput}
                      id={name}
                      tabIndex={tabIndex}
                    />
                  )}
                </FormContext.Consumer>
              );
            case 'date':
              return (
                <FormContext.Consumer>
                  {(context) => (
                    <>
                      <Field
                        required={required}
                        type="hidden"
                        name={id}
                        value={defaultValue}
                      />
                      <div className="d-flex align-items-center position-relative">
                        <DatePicker
                          selected={
                            defaultValue ? new Date(defaultValue) : false
                          }
                          className={`form-control form-control-${size} ${name}`}
                          onChange={(date) => {
                            context.setFieldValue(id, date?.toISOString());
                            onChange(date);
                          }}
                          onCalendarClose={() =>
                            context.setFieldTouched(id, true, true)
                          }
                          placeholderText={placeholder}
                          readonly={readonly}
                          disabled={disabled}
                          required={required}
                          onFocus={clearMessages}
                          id={name}
                          shouldCloseOnSelect={true}
                          autoComplete={'off'}
                        />
                        <label
                          htmlFor={name}
                          className="label-datepicker calendar-icon position-absolute"
                        >
                          <BsCalendar3 />
                        </label>
                      </div>
                    </>
                  )}
                </FormContext.Consumer>
              );
            case 'textarea':
              return (
                <Field
                  as={type}
                  placeholder={placeholder}
                  min={minNumber}
                  max={maxNumber}
                  className={`form-control form-control-${size} ${className}`}
                  name={name}
                  readOnly={readonly}
                  disabled={disabled}
                  required={required}
                  tabIndex={tabIndex}
                  id={name}
                  rows={rows}
                  style={{ resize: 'none' }}
                  onFocus={clearMessages}
                  onBlur={onChange}
                  onKeyDown={onKeyDown}
                />
              );
            case 'json':
              return (
                <Field
                  as={JsonInput}
                  placeholder={placeholder}
                  min={minNumber}
                  max={maxNumber}
                  className={`form-control form-control-${size} ${className}`}
                  name={name}
                  readOnly={readonly}
                  disabled={disabled}
                  required={required}
                  tabIndex={tabIndex}
                  id={name}
                  rows={rows}
                  style={{ resize: 'none' }}
                  onFocus={clearMessages}
                  onBlur={onChange}
                  onKeyDown={onKeyDown}
                />
              );
            case 'number':
              return (
                <Field
                  onFocus={(e) => {
                    e.target.select();
                    clearMessages();
                  }}
                  placeholder={placeholder}
                  type={type}
                  min={minNumber}
                  max={maxNumber}
                  className={`form-control form-control-${size} ${className}`}
                  name={name}
                  readOnly={readonly}
                  disabled={disabled}
                  required={required}
                  tabIndex={tabIndex}
                  id={name}
                />
              );
            case 'unitName':
              return (
                <div className="d-flex align-items-center">
                  <Field
                    onFocus={(e) => {
                      e.target.select();
                      clearMessages();
                    }}
                    placeholder={placeholder}
                    type="number"
                    min={minNumber}
                    max={maxNumber}
                    className={`mr-2 form-control form-control-${size} ${className}`}
                    name={name}
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    tabIndex={tabIndex}
                    id={name}
                  />
                  {unitName}
                </div>
              );
            case 'filterText':
              return (
                <Field
                  placeholder={placeholder}
                  type={type}
                  min={minNumber}
                  max={maxNumber}
                  className={`form-control form-control-${size} ${className}`}
                  name={name}
                  readOnly={readonly}
                  disabled={disabled}
                  required={required}
                  tabIndex={tabIndex}
                  id={name}
                  onFocus={(e) => {
                    clearMessages();
                    onFocus(e);
                  }}
                  onKeyDown={onKeyDown}
                  onInput={onChange}
                  value={valueInput}
                />
              );
            case 'computed-input':
              return (
                <ComputedInput
                  dependencies={dependencies}
                  callback={callback}
                  placeholder={placeholder}
                  type={type}
                  className={`form-control form-control-${size} ${className}`}
                  name={name}
                  readOnly={true}
                  disabled={disabled}
                  required={required}
                  tabIndex={tabIndex}
                  id={name}
                  appendElement={appendElement}
                />
              );
            case 'attachment':
              return (
                <FormContext.Consumer>
                  {(context) => (
                    <AttachmentInput
                      name={name}
                      onChange={onChange}
                      parentId={parentId}
                      parentType={parentType}
                      context={context}
                      setParentValues={setParentValues}
                    />
                  )}
                </FormContext.Consumer>
              );
            case 'tags':
              return (
                <>
                  {header ? (
                    <label className="input-label" htmlFor={name}>
                      <span className="d-flex justify-content-between align-items-center">
                        <span className="input-label-primary">{header}</span>
                      </span>
                    </label>
                  ) : null}
                  <FormContext.Consumer>
                    {(context) => (
                      <CreatableSelect
                        required={required}
                        isMulti={true}
                        name={name}
                        id={id}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            minHeight: '32px',
                            boxShadow: state.isFocused ? null : null,
                            borderRadius: '8px',
                            fontSize: '0.825rem',
                          }),
                        }}
                        components={{ DropdownIndicator: null }}
                        placeholder={placeholder}
                        className={className}
                        onChange={(value: any) => {
                          const tags = value?.map((tag) => tag.value);
                          context.setFieldValue(name, tags);
                        }}
                        value={context?.values?.[name]?.map((tag) => ({
                          label: tag,
                          value: tag,
                        }))}
                      />
                    )}
                  </FormContext.Consumer>
                </>
              );
            default:
              return (
                <div className={'d-flex'}>
                  <Field
                    placeholder={placeholder}
                    type={type}
                    min={minNumber}
                    max={maxNumber}
                    className={`form-control form-control-${size}  ${
                      appendElement || barcode ? 'input-prepend' : ''
                    }`}
                    name={name}
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    tabIndex={tabIndex}
                    id={name}
                    onFocus={(e) => {
                      clearMessages();
                      onFocus(e);
                    }}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    onInput={onChange}
                    data-barcode={barcode}
                  />
                  {barcode ? (
                    <ScanButton
                      disabled={disabled}
                      name={name}
                      onChange={onChange}
                      setFieldValue={formikContext.setFieldValue}
                      autoSubmit={autoSubmit}
                      barcodeButtonClassName={barcodeButtonClassName}
                    />
                  ) : null}
                  {appendElement || null}
                </div>
              );
          }
        })()}
        {type === 'react-select' ? (
          <div />
        ) : (
          <ErrorMessage
            name={name}
            component="div"
            className="invalid-feedback"
          />
        )}
      </div>
    </div>
  );
};
