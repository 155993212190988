import {
  WarehouseLocationDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  WarehouseLocationDtoPagedResult,
  CreateWarehouseLocationCommandValues,
  UpdateWarehouseLocationCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_WAREHOUSELOCATIONS_LINK_KEY = 'get-warehouselocations';
export const CREATE_WAREHOUSELOCATION_LINK_KEY = 'create-warehouselocation';
export const GET_WAREHOUSELOCATION_LINK_KEY = 'get-warehouselocation';
export const UPDATE_WAREHOUSELOCATION_LINK_KEY = 'update-warehouselocation';
export const DELETE_WAREHOUSELOCATION_LINK_KEY = 'delete-warehouselocation';
export const IMPORT_WAREHOUSELOCATIONS_LINK_KEY = 'import-warehouselocations';
export const EXPORT_WAREHOUSELOCATIONS_LINK_KEY = 'export-warehouselocations';
export const WAREHOUSELOCATION_ENTITY_NAME = 'Warehouse Location';

export interface GetWarehouseLocationParams {
  warehouseLocationId: number;
}

export interface ImportWarehouseLocationsParams {
  organizationId: number;
}

export interface ExportWarehouseLocationsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getWarehouseLocationRequest = async (
  resource: LinkResourceBaseDto | null,
  { warehouseLocationId }: GetWarehouseLocationParams,
): Promise<WarehouseLocationDto> => {
  if (resource && warehouseLocationId) {
    const getWarehouseLocationLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_WAREHOUSELOCATION_LINK_KEY,
    );
    if (getWarehouseLocationLink) {
      const result = await execLink(getWarehouseLocationLink, {
        warehouseLocationId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WAREHOUSELOCATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getWarehouseLocationsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<WarehouseLocationDtoPagedResult> => {
  if (resource) {
    const getWarehouseLocationsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_WAREHOUSELOCATIONS_LINK_KEY,
    );
    if (getWarehouseLocationsListLink) {
      const result = await execLink(getWarehouseLocationsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WAREHOUSELOCATIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createWarehouseLocationRequest = async (
  resource: LinkResourceBaseDto,
  warehouseLocation: CreateWarehouseLocationCommandValues,
) => {
  const createWarehouseLocationLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_WAREHOUSELOCATION_LINK_KEY,
  );
  if (createWarehouseLocationLink) {
    const result = await execLink(
      createWarehouseLocationLink,
      warehouseLocation,
    );
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_WAREHOUSELOCATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateWarehouseLocationRequest = async (
  resource: LinkResourceBaseDto,
  warehouseLocation: UpdateWarehouseLocationCommandValues,
) => {
  const updateWarehouseLocationLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_WAREHOUSELOCATION_LINK_KEY,
  );
  if (updateWarehouseLocationLink) {
    const result = await execLink(
      updateWarehouseLocationLink,
      warehouseLocation,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_WAREHOUSELOCATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteWarehouseLocationRequest = async (
  warehouseLocation: WarehouseLocationDto,
) => {
  const deleteWarehouseLocationLink = warehouseLocation.links?.find(
    (x: LinkDto) => x.rel === DELETE_WAREHOUSELOCATION_LINK_KEY,
  );
  if (deleteWarehouseLocationLink) {
    const result = await execLink(
      deleteWarehouseLocationLink,
      warehouseLocation,
    );
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_WAREHOUSELOCATION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importWarehouseLocationsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportWarehouseLocationsParams,
  file: File,
) => {
  const importWarehouseLocationsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_WAREHOUSELOCATIONS_LINK_KEY,
  );
  if (importWarehouseLocationsLink) {
    const result = await postFormData(importWarehouseLocationsLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_WAREHOUSELOCATIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportWarehouseLocationsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportWarehouseLocationsParams,
) => {
  const exportWarehouseLocationsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_WAREHOUSELOCATIONS_LINK_KEY,
  );
  if (exportWarehouseLocationsLink) {
    const result = await downloadLinkFile(exportWarehouseLocationsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_WAREHOUSELOCATIONS_LINK_KEY} is not allowed or missing link action`,
  );
};
