import React from 'react';
import { userHas } from '../../../auth/auth.store';
import { Button } from '../button/button.component';

export type ListElementsNotFoundProps = {
  entityName: string;
  entityLinkKey: string;
  onClick: () => void;
  additionalConditions?: boolean;
};

export const ListElementsNotFound = ({
  entityName = '',
  entityLinkKey = '',
  onClick,
  additionalConditions = true,
}: ListElementsNotFoundProps) => {
  return (
    <div className="m-5 text-center">
      <h3 className="text-muted mb-4">You Don't Have Any {entityName} Yet</h3>
      {userHas(entityLinkKey) && additionalConditions && (
        <Button size="lg" color="primary" onClick={onClick}>
          Create {entityName}
        </Button>
      )}
    </div>
  );
};
