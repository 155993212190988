import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { ORDER_CREATE_PATH, ORDER_LIST_PATH } from '../order.route';
import { OrderEdit } from '../components/order-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../orders.service';
import { userHas } from '../../auth/auth.store';
import { organizationsStore } from '../../organization/organization.store';

export type OrderScreenProps = { pageTitle: string; group: string };

export const OrderScreen = ({ pageTitle, group }: OrderScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
    organizationId,
  });

  const onOrderCreated = () => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-created',
      type: 'success',
      autoHide: true,
      message: 'Order successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onOrderUpdated = () => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-updated',
      type: 'success',
      autoHide: true,
      message: 'Order successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Orders'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrderEdit
        orderId={orderId}
        onOrderUpdated={onOrderUpdated}
        onOrderCreated={onOrderCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
