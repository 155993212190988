import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { EventDefinitionForm } from './eventDefinition.form';
import {
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  DocumentTemplateDto,
  EventDefinitionDto,
  OrderEvents,
  OrderTypes,
} from '../../../models/data.models';
import {
  createEventDefinitionFx,
  getEventDefinitionFx,
  updateEventDefinitionFx,
} from '../eventDefinitions.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import {
  generateValidationSchemaWithCustomFields,
  getEnumKeyByValue,
  getEnumValues,
} from '../../../utils/helper.utils';
import { EventDefinitionDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { UPDATE_EVENTDEFINITION_LINK_KEY } from '../eventDefinitions.service';
import { userHas } from '../../auth/auth.store';
import { FormContext } from '../../common/components/form/form.component';
import { getDocumentTemplatesFx } from '../../documentTemplates/documentTemplates.store';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';

export type EventDefinitionEditProps = {
  eventDefinitionId?: number | null;
  onEventDefinitionCreated?: (eventDefinition: EventDefinitionDto) => void;
  onEventDefinitionUpdated?: (eventDefinition: EventDefinitionDto) => void;
  onEventDefinitionLoaded?: (eventDefinition: EventDefinitionDto) => void;
  onCancel?: () => void;
};

const initialState: EventDefinitionDto = {
  eventDefinitionId: EventDefinitionDefaultValues.eventDefinitionId,
  eventName: EventDefinitionDefaultValues.eventName,
  isAutomaticCreate: EventDefinitionDefaultValues.isAutomaticCreate,
  trigger: EventDefinitionDefaultValues.trigger,
  description: EventDefinitionDefaultValues.description,
  location: EventDefinitionDefaultValues.location,
  isInactive: EventDefinitionDefaultValues.isInactive,
  includeInTracking: EventDefinitionDefaultValues.includeInTracking,
  sendEmail: EventDefinitionDefaultValues.sendEmail,
  organizationId: EventDefinitionDefaultValues.organizationId,
  sendEmailDocumentId: EventDefinitionDefaultValues.sendEmailDocumentId,
  created: null,
  createdBy: '',
  lastModified: null,
  lastModifiedBy: '',
  links: [],
  customValues: {},
};

let eventDefinitionSchema = Yup.object().shape({
  eventName: Yup.string()
    .max(50, 'Max length is 50')
    .required("Can't be blank")
    .nullable(true),
});

export const EventDefinitionEdit = ({
  eventDefinitionId,
  onEventDefinitionLoaded = () => {},
  onEventDefinitionCreated = () => {},
  onEventDefinitionUpdated = () => {},
  onCancel = () => {},
}: EventDefinitionEditProps) => {
  const isCreateMode = !eventDefinitionId || eventDefinitionId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    eventDefinition,
    setEventDefinition,
  ] = useState<EventDefinitionDto | null>(null);
  const [initialValues, setInitialValues] = useState<EventDefinitionDto | null>(
    initialState,
  );
  const [
    selectedTemplates,
    setSelectedTemplates,
  ] = useState<DocumentTemplateDto>(null);
  const [sendEmailState, setSendEmailState] = useState<boolean>(false);
  const ref = useRef<HTMLFormElement>();

  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  useEffect(() => {
    const fetchEventDefinition = async () => {
      let loadedCustomFields: CustomFieldDto[] = [];
      try {
        const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
          filter: `customFieldEntityType: ${CustomFieldEntityType.EventDefinition} AND isInactive: false`,
        });
        loadedCustomFields = fields.items;
        eventDefinitionSchema = generateValidationSchemaWithCustomFields(
          loadedCustomFields,
          eventDefinitionSchema,
        );
        setCustomFields(loadedCustomFields);
      } catch {
        addMessage({
          message: `Can't load Custom Fields.`,
          type: 'danger',
          id: v4(),
        });
      }

      if (isCreateMode) {
        setIsLoading(false);
      } else if (eventDefinitionId) {
        getEventDefinitionFx({ eventDefinitionId }).then(
          (eventDefinitionDto: EventDefinitionDto) => {
            setEventDefinition(eventDefinitionDto);
            setInitialValues(eventDefinitionDto);
            setSendEmailState(eventDefinitionDto.sendEmail);
            if (eventDefinitionDto?.sendEmailDocumentId > 0) {
              getDocumentTemplatesFx({
                filter: `documentTemplateId:${eventDefinitionDto?.sendEmailDocumentId} AND isInactive: false`,
              }).then((documentTemplates) => {
                setSelectedTemplates(documentTemplates.items[0]);
              });
            }

            setIsLoading(false);
            onEventDefinitionLoaded(eventDefinitionDto);
          },
          () => {},
        );
      } else {
        throw new Error('EventDefinition keys were not provided');
      }
    };

    fetchEventDefinition();
  }, [eventDefinitionId]);

  const onSubmit = (data: EventDefinitionDto) => {
    data.trigger = eventDefinition?.trigger;
    data.isAutomaticCreate = eventDefinition?.isAutomaticCreate;
    data.sendEmailDocumentId = selectedTemplates?.documentTemplateId;
    setIsSending(true);
    if (isCreateMode) {
      createEventDefinitionFx(data)
        .then(
          (result) => {
            onEventDefinitionCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateEventDefinitionFx(data)
        .then(
          (result) => {
            onEventDefinitionUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };

  const defaultTriggerValue = {
    entity: 'Order',
    conditionFields: {
      Type: 'All',
    },
    eventName: null,
  };

  const onAutomaticCreateOrderTypeChange = (
    newValueOrderType: ReactSelectItem,
  ) => {
    setEventDefinition((oldEventDefinition) => {
      if (!oldEventDefinition) {
        oldEventDefinition = initialState;
      }
      if (!oldEventDefinition.trigger) {
        oldEventDefinition.trigger = defaultTriggerValue;
      }
      if (newValueOrderType?.value) {
        oldEventDefinition.trigger.conditionFields['Type'] = getEnumKeyByValue(
          newValueOrderType.value,
          OrderTypes,
        );
      } else
        oldEventDefinition.trigger.conditionFields['Type'] =
          defaultTriggerValue.conditionFields.Type;

      return { ...oldEventDefinition };
    });
  };

  const onAutomaticCreateChange = (newValue: boolean) => {
    setEventDefinition((oldEventDefinition) => {
      if (!oldEventDefinition) {
        oldEventDefinition = initialState;
      }

      oldEventDefinition.isAutomaticCreate = newValue;
      return { ...oldEventDefinition };
    });
  };

  const onAutomaticCreateOrderEventChange = (
    newValueOrderEvent: ReactSelectItem,
  ) => {
    setEventDefinition((oldEventDefinition) => {
      if (!oldEventDefinition) {
        oldEventDefinition = initialState;
      }
      if (!oldEventDefinition.trigger) {
        oldEventDefinition.trigger = defaultTriggerValue;
      }
      if (newValueOrderEvent?.value) {
        oldEventDefinition.trigger.eventName = getEnumKeyByValue(
          newValueOrderEvent.label,
          OrderEvents,
        );
      } else oldEventDefinition.trigger.eventName = null;
      return { ...oldEventDefinition };
    });
  };

  const onSendEmailChange = (newValue: boolean) => {
    setEventDefinition((oldEventDefinition) => {
      if (!oldEventDefinition) {
        oldEventDefinition = initialState;
      }

      oldEventDefinition.sendEmail = newValue;
      return { ...oldEventDefinition };
    });
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <EventDefinitionForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={ref}
      id="eventDefinitionForm"
      validationSchema={eventDefinitionSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <Tab name="eventDefinition">Event Definition</Tab>
              <Tab name="additional">Additional</Tab>
            </TabList>
            <TabPanel>
              <>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h2 className="header-form">Add Event Definition</h2>
                  ) : (
                    <h2>Update Event Definition</h2>
                  )}
                  <div className="row">
                    <div className={'col-12 p-0'}>
                      <div className={'col-4'}>
                        <EventDefinitionForm.EventName />
                      </div>
                    </div>
                    <div className={'col-12 p-0'}>
                      <div className={'col-4'}>
                        <EventDefinitionForm.Description />
                      </div>
                    </div>
                    <div className={'col-12 p-0'}>
                      <div className={'col-4'}>
                        <EventDefinitionForm.Location />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className={'col-12'}>
                      <div className={'row px-2'}>
                        <EventDefinitionForm.AutomaticCreate
                          defaultValue={
                            eventDefinition?.isAutomaticCreate ?? false
                          }
                          onChange={onAutomaticCreateChange}
                        />
                      </div>
                    </div>
                    <div className={'col-12'}>
                      <div className={'row'}>
                        <div className={'col-2'}>
                          <EventDefinitionForm.OrderType
                            defaultValue={
                              eventDefinition?.trigger?.conditionFields &&
                              eventDefinition?.trigger?.conditionFields?.Type !=
                                'All'
                                ? {
                                    label:
                                      OrderTypes[
                                        eventDefinition?.trigger
                                          ?.conditionFields['Type']
                                      ],
                                    value:
                                      eventDefinition?.trigger?.conditionFields[
                                        'Type'
                                      ],
                                  }
                                : null
                            }
                            onChange={onAutomaticCreateOrderTypeChange}
                            multiple={false}
                            options={getEnumValues(OrderTypes)}
                          />
                        </div>
                        <div className={'col-2'}>
                          <EventDefinitionForm.OrderEvent
                            defaultValue={
                              eventDefinition?.trigger?.eventName
                                ? {
                                    label:
                                      OrderEvents[
                                        eventDefinition?.trigger?.eventName
                                      ],
                                    value: eventDefinition?.trigger?.eventName,
                                  }
                                : null
                            }
                            onChange={onAutomaticCreateOrderEventChange}
                            multiple={false}
                            options={getEnumValues(OrderEvents)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className={'col-12 p-0'}>
                      <div className={'col-4'}>
                        <EventDefinitionForm.IsInactive
                          defaultValue={eventDefinition?.isInactive ?? false}
                        />
                      </div>
                    </div>
                    <div className={'col-12 p-0'}>
                      <div className={'col-4'}>
                        <EventDefinitionForm.IncludeInTracking
                          defaultValue={
                            eventDefinition?.includeInTracking ?? false
                          }
                        />
                      </div>
                    </div>
                    <div className={'col-12 p-0'}>
                      <div className={'col-4'}>
                        <EventDefinitionForm.SendEmail
                          defaultValue={eventDefinition?.sendEmail ?? false}
                          onChange={onSendEmailChange}
                        />
                      </div>
                    </div>
                    <div className={'col-12 p-0'}>
                      <div className={'col-4'}>
                        <EventDefinitionForm.DocumentTemplateSelect
                          selectedFilter={`isInactive: false`}
                          required={false}
                          name={'sendEmailDocumentId'}
                          nameId={'sendEmailDocumentName'}
                          id={'sendEmailDocumentId'}
                          header={'Document Template'}
                          defaultValue={selectedTemplates}
                          disabled={!eventDefinition?.sendEmail}
                          onChange={(data?: DocumentTemplateDto) => {
                            setSelectedTemplates(data);
                            context.setFieldValue(
                              'sendEmailDocumentId',
                              data?.documentTemplateId,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </>
            </TabPanel>
            <TabPanel>
              <Panel className="m-3">
                <EventDefinitionForm.CustomValues
                  customFields={customFields}
                  entityType={CustomFieldEntityType.EventDefinition}
                  defaultValue={
                    isCreateMode ? {} : eventDefinition?.customValues
                  }
                  onChange={(result) => {
                    context.setFieldValue('customValues', result);
                    setEventDefinition((eventDefinitionDto) => {
                      eventDefinitionDto.customValues = result;
                      return { ...eventDefinitionDto };
                    });
                  }}
                  saveButtonRenderCondition={false}
                  isSending={isSending}
                  formName={'eventDefinitionForm'}
                  entityName={'EventDefinition'}
                  onCancel={null}
                  context={context}
                />
              </Panel>
            </TabPanel>
            <div className="col-12">
              <div className="justify-content-end row">
                {(userHas(
                  UPDATE_EVENTDEFINITION_LINK_KEY,
                  eventDefinition?.links,
                ) ||
                  isCreateMode) && (
                  <div className="col-3">
                    <Button
                      name="save-eventDefinition"
                      type="submit"
                      color="primary"
                      className="w-100 btn-block"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Event Definition
                    </Button>
                  </div>
                )}
                <div className="col-3">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={onCancel}
                    className="w-100"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Tabs>
        )}
      </FormContext.Consumer>
    </EventDefinitionForm>
  );
};
