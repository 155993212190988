import {
  JobDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  JobListItemDtoPagedResult,
  CreateJobCommandValues,
  UpdateJobCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createJobRequest,
  getJobsListRequest,
  getJobRequest,
  deleteJobRequest,
  updateJobRequest,
  importJobsRequest,
  exportJobsRequest,
  GetJobParams,
} from './jobs.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type JobsStoreState = {
  links: LinkDto[];
  jobColumns: EntityFieldDto[];
  defaultJobColumns: EntityFieldDto[];
};

export const getJobsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Job',
  });
});

addEffectStatusHandling(getJobsColumnsFx);

export const getJobsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Job',
  });
});

addEffectStatusHandling(getJobsDefaultColumnsFx);

export const updateJobsColumns = createEvent<EntityFieldDto[]>();

export const getJobsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getJobsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getJobsFx);

export const createJobFx = createEffect((jobData: JobDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createJobCommand: CreateJobCommandValues = { ...jobData };

  return createJobRequest(currentOrganization!, createJobCommand);
});

addEffectStatusHandling(createJobFx, {
  completeMessage: 'Job was created successfully',
  errorMessage: 'Job was not created',
});

export const getJobFx = createEffect((jobParams: GetJobParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getJobRequest(currentOrganization as LinkResourceBaseDto, jobParams);
});

addEffectStatusHandling(getJobFx);

export const updateJobFx = createEffect((job: JobDto) => {
  const updateJobCommand: UpdateJobCommandValues = { ...job };
  return updateJobRequest(job, updateJobCommand);
});

addEffectStatusHandling(updateJobFx, {
  completeMessage: 'Job was updated successfully',
  errorMessage: 'Job was not updated',
});

export const deleteJobFx = createEffect((job: JobDto) => {
  return deleteJobRequest(job);
});

addEffectStatusHandling(deleteJobFx, {
  completeMessage: 'Job was deleted successfully',
  errorMessage: 'Job was not deleted',
});

export const exportJobsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportJobsRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportJobsFx, {
  completeMessage: 'Jobs were exported successfully',
  errorMessage: 'Jobs were not exported',
});

export const importJobsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importJobsRequest(listResource as LinkResourceBaseDto, params, file);
  },
);
addEffectStatusHandling(importJobsFx, {
  completeMessage: 'Jobs were imported successfully',
  errorMessage: 'Jobs were not imported',
  inFlightMessage: 'Importing jobs...',
});

const defaultState: JobsStoreState = {
  links: [],
  jobColumns: [],
  defaultJobColumns: [],
};

export const jobStore: Store<JobsStoreState> = createStore(defaultState)
  .on(getJobsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      jobColumns: payload.result.items,
      defaultJobColumns: payload.result.items,
    };
  })
  .on(getJobsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultJobColumns: payload.result.items,
    };
  })
  .on(updateJobsColumns, (state, payload) => {
    return { ...state, jobColumns: payload };
  });
