import React from 'react';
import { SignUpForm } from './signup.form';
import { Button } from '../../common/components/button/button.component';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

export type SignUpData = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export type SignUpProps = {
  onSubmit: (data: SignUpData) => void;
};

const initialValues: SignUpData = {
  firstName: null,
  lastName: null,
  userName: null,
  email: null,
  password: null,
  confirmPassword: null,
};

const registrationSchema = Yup.object().shape({
  firstName: Yup.string().required("Can't be blank").nullable(true),
  lastName: Yup.string().required("Can't be blank").nullable(true),
  userName: Yup.string()
    .min(5, 'Min length is 5')
    .required("Can't be blank")
    .nullable(true),
  email: Yup.string()
    .email('Invalid email')
    .required("Can't be blank")
    .nullable(true),
  password: Yup.string()
    .min(6, 'Min length is 6')
    .max(100, 'Max length is 100')
    .required("Can't be blank")
    .nullable(true),
  confirmPassword: Yup.string()
    .equals([Yup.ref('password')], 'Passwords are not equal')
    .required("Can't be blank")
    .nullable(true),
});

export const SignUp = ({ onSubmit }: SignUpProps) => {
  return (
    <SignUpForm
      onSubmit={onSubmit}
      id={'RegistrationForm'}
      validationSchema={registrationSchema}
      initialValues={initialValues}
    >
      <div className="row">
        <div className="col-12 col-sm-6">
          <SignUpForm.FirstName />
        </div>
        <div className="col-12 col-sm-6">
          <SignUpForm.LastName />
        </div>
      </div>
      <SignUpForm.Username />
      <SignUpForm.Email />
      <SignUpForm.Password />
      <Button
        type="submit"
        color="primary"
        name="sign-up"
        className="btn-block"
      >
        Sign Up
      </Button>
      <div className="mt-5">
        {/* <span className="mb-3 d-flex justify-content-center">
          Sign up with social profiles
        </span>
        <div className="row d-flex no-gutters text-center mb-5 mx-sm-n1">
          <div className="col-12 col-sm-4 mb-1 mb-sm-0 px-0 px-sm-1">
            <Button className="btn-block" outline type="submit" size="sm">
              <FaFacebookF className="i-register" />
            </Button>
          </div>
          <div className="col-12 col-sm-4 mb-1 mb-sm-0 px-0 px-sm-1">
            <Button className="btn-block" outline type="submit" size="sm">
              <AiOutlineGoogle className="i-register" />
            </Button>
          </div>
          <div className="col-12 col-sm-4 mb-1 mb-sm-0 px-0 px-sm-1">
            <Button className="btn-block" outline type="submit" size="sm">
              <AiOutlineTwitter className="i-register" />
            </Button>
          </div>
        </div> */}
        <div className="d-flex justify-content-center">
          <span>Already have an account?</span>
          <span>
            <Link to="/login">&nbsp;Login here</Link>
          </span>
        </div>
      </div>
    </SignUpForm>
  );
};
