import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { PackingScanFragment } from './packingScanFragment.component';
import { PackingInfoFragment } from './packingInfoFragment.component';
import { PackingLabelFragment } from './packingLabelFragment.component';

type HOCProps = {
  step: number;
  onCancel: () => void;
  handleNext: any;
  handleBack: any;
  setInitialState: (val: any) => void;
  notification?: string;
};

export const CatchSubmitActionHOC = ({
  step,
  onCancel,
  handleNext,
  handleBack,
  setInitialState,
  notification,
}: HOCProps) => {
  const {
    handleSubmit,
    values,
    isValid,
    isValidating,
    isSubmitting,
    setFieldValue,
  } = useFormikContext<any>();

  useEffect(() => {
    if (values?._submitAction) {
      handleSubmit();
    }
  }, [values?._submitAction]);

  useEffect(() => {
    if (values?.purchase?.orderId > 0) {
      if (
        values?.purchase?.commodities != null &&
        values?.purchase?.commodities.length > 0
      )
        values.purchase.commodities[0].weight = undefined;

      setInitialState((prev) => {
        return { ...prev, purchase: values?.purchase };
      });
    }
  }, [values?.purchase?.orderId]);

  useEffect(() => {
    if (values?.parcelShipment?.orderId > 0) {
      setInitialState((prev) => {
        return { ...prev, parcelShipment: values?.parcelShipment };
      });
    }
  }, [values?.parcelShipment?.orderId]);

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      setFieldValue('_submitAction', '');
    }
  }, [isSubmitting, isValid, isValidating]);

  switch (step) {
    case 0:
      return (
        <PackingScanFragment
          isHandleNext={true}
          onCancel={onCancel}
          notification={notification}
        />
      );
    case 1:
      return <PackingInfoFragment handleBack={handleBack} />;
    case 2:
      return (
        <PackingLabelFragment
          handleNext={handleNext}
          handleBack={handleBack}
          orderDocumentId={values?.lastGeneratedDocument}
          orderDocumentLink={values?.lastGeneratedDocumentLink}
        />
      );
  }
};
