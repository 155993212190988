import React, { useEffect, useState } from 'react';
import {
  AccountingTransactionDto,
  AccountingTransactionDtoPagedResult,
  AccountingTransactionType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
  GetAccountingTransactionParams,
} from '../../accountingTransactions/accountingTransactions.service';
import {
  accountingTransactionStore,
  deleteAccountingTransactionFx,
  getAccountingTransactionsFx,
  getAccountingTransactionsColumnsFx,
} from '../../accountingTransactions/accountingTransactions.store';
import { BILLS_CREATE_PATH } from '../bills.route';
import { BillDialog } from './bill.dialog';
import { userHas } from '../../auth/auth.store';
import { AccountingTransactionGrid } from '../../common/components/grid/accountingTransaction-grid.component';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';

export type BillsListProps = {
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  goToDetails?: (
    accountingTransactionParams: GetAccountingTransactionParams,
  ) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onFilter?: (query: string) => void;
  onSelect?: (
    accountingTransaction: AccountingTransactionDto,
    accountingTransactionParams: GetAccountingTransactionParams,
  ) => void;
  onSearch?: (query: string) => void;
};

export const BillsList = ({
  filter = `accountingTransactionType: ${AccountingTransactionType.Bill}`,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  goToDetails = () => {},
  onFilter = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onSort = () => {},
  onSelect,
  onSearch,
}: BillsListProps) => {
  const [
    accountingTransactions,
    setAccountingTransactions,
  ] = useState<AccountingTransactionDtoPagedResult | null>(null);

  useEffect(() => {
    getAccountingTransactionsColumnsFx();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    getAccountingTransactionsData();
  }, [offset, limit, sort, filter, search]);

  const getAccountingTransactionsData = () => {
    getAccountingTransactionsFx({ offset, limit, sort, filter, search }).then(
      (accountingTransactionsData) => {
        setAccountingTransactions(accountingTransactionsData);
      },
    );
  };

  const {
    accountingTransactionColumns: columns,
    defaultAccountingTransactionColumns,
  } = useStore(accountingTransactionStore);

  const onDeleteAccountingTransaction = async (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    const resullt = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${accountingTransaction.transactionNumber} Bill`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (resullt) {
      try {
        await deleteAccountingTransactionFx(accountingTransaction);
        onDelete();
        getAccountingTransactionsData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditAccountingTransaction = (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    goToDetails({
      accountingTransactionId: accountingTransaction.accountingTransactionId,
    });
  };

  const onCreateNewAccountingTransaction = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + BILLS_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(createPath);
  };

  const onBillSelect = async (bill, billId) => {
    onSelect?.(bill, billId);
    const selectedBill = await showDialog({
      dialog: BillDialog,
      props: {
        accountingTransactionId: bill.accountingTransactionId,
        title: 'Update Bill',
        className: '',
      },
    });
    if (selectedBill !== null) {
      getAccountingTransactionsData();
    }
  };

  return (
    <div className="pb-3">
      <AccountingTransactionGrid
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        showAllFilters={false}
        showToolbar={false}
        showGridPanel={true}
        rowKeys={['accountingTransactionId']}
        data={accountingTransactions?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={accountingTransactions?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
            accountingTransactions?.items[0]?.links,
          )
            ? onDeleteAccountingTransaction
            : null
        }
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
        onEdit={onEditAccountingTransaction}
        onPageChanged={onPageChanged}
        onLimitChanged={onLimitChanged}
        onViewChanged={onViewChanged}
        onSelect={onBillSelect}
        defaultColumns={defaultAccountingTransactionColumns}
      />
      {!(
        accountingTransactions?.items?.length > 0 ||
        (filter &&
          filter !==
            `accountingTransactionType: ${AccountingTransactionType.Bill}`) ||
        search
      ) && (
        <ListElementsNotFound
          entityName="Bill"
          entityLinkKey={CREATE_ACCOUNTINGTRANSACTION_LINK_KEY}
          onClick={onCreateNewAccountingTransaction}
        />
      )}
    </div>
  );
};
