import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
  ReactSelectInputProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { QuillInput } from '../../common/components/input/input-quill.component';
import { SelectDocumentTemplatePaginate } from '../../documentTemplates/components/documentTemplate-react-select.component';

export const SendEmailForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

SendEmailForm.Recipients = ({
  selectedFilter = ``,
  nameId = 'recipients',
  placeholder = 'Select Recipients',
  required = true,
  defaultValue,
  sort = 'emailAddress',
  id = 'recipients',
  header = 'Recipients',
  onChange = () => {},
  readonly,
  contactTypes = [],
  getOptionLabel,
  getOptionValue,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={true}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isMailInput={true}
      canCreateNewEntity={false}
    />
  );
};

SendEmailForm.SendMeACopy = ({
  className,
  defaultValue,
  style,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      label={'Send me a copy'}
      name={'sendMeACopy'}
      className={className}
      style={style}
      type={'checkbox'}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

SendEmailForm.Subject = ({
  className,
  style,
  size,
  readonly,
  name = 'subject',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      disabled={disabled}
      label={'Subject'}
      placeholder={'Subject'}
      required={true}
      className={className}
      style={style}
      size={size}
    />
  );
};

SendEmailForm.Message = ({
  className,
  style,
  size,
  readonly,
  name = 'message',
  type = 'textarea',
  disabled,
  defaultValue,
  required,
  placeholder,
  label = 'Message',
}: InputPublicProps) => {
  return (
    <QuillInput
      defaultValue={defaultValue}
      // onChange={onChange}
      required={required}
      name={name}
      header={label}
      placeholder={placeholder}
      readonly={disabled}
    />
  );
};

SendEmailForm.Documents = ({
  selectedFilter,
  onChange,
  id = 'documentIds',
  nameId = 'documentIds',
  header = 'Document Templates',
  required = false,
  placeholder,
  readonly,
  disabled,
  sort = 'name',
  defaultValue,
}: ReactSelectInputProps) => {
  return (
    <SelectDocumentTemplatePaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={true}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeDocumentTemplate={onChange}
      canCreateNewEntity={false}
    />
  );
};
