import {
  WorkflowTriggerDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  WorkflowTriggerListItemDtoListResult,
  ExecuteWorkflowCommandValues,
  GetWorkflowTriggersQuery,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_WORKFLOWTRIGGERS_LINK_KEY = 'get-workflowtriggers';
export const EXECUTE_WORKFLOWTRIGGER_LINK_KEY = 'execute-workflow';
export const GET_WORKFLOWTRIGGER_LINK_KEY = 'get-workflowtrigger';
export const WORKFLOWTRIGGER_ENTITY_NAME = 'Workflow Trigger';

export interface GetWorkflowTriggerParams {
  workflowId: string;
}

export interface ImportWorkflowTriggersParams {
  organizationId: number;
}

export interface ExportWorkflowTriggersParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getWorkflowTriggerRequest = async (
  resource: LinkResourceBaseDto | null,
  { workflowId }: GetWorkflowTriggerParams,
): Promise<WorkflowTriggerDto> => {
  if (resource && workflowId) {
    const getWorkflowTriggerLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_WORKFLOWTRIGGER_LINK_KEY,
    );
    if (getWorkflowTriggerLink) {
      const result = await execLink(getWorkflowTriggerLink, { workflowId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WORKFLOWTRIGGER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getWorkflowTriggersListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: GetWorkflowTriggersQuery = {},
): Promise<WorkflowTriggerListItemDtoListResult> => {
  if (resource) {
    const getWorkflowTriggersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_WORKFLOWTRIGGERS_LINK_KEY,
    );
    if (getWorkflowTriggersListLink) {
      const result = await execLink(getWorkflowTriggersListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WORKFLOWTRIGGERS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const executeWorkflowRequest = async (
  workflowTriggerDto: WorkflowTriggerDto,
  workflowTrigger: ExecuteWorkflowCommandValues,
) => {
  const createWorkflowTriggerLink = workflowTriggerDto?.links?.find(
    (x: LinkDto) => x.rel === EXECUTE_WORKFLOWTRIGGER_LINK_KEY,
  );
  if (createWorkflowTriggerLink) {
    if (workflowTriggerDto.workflowType?.toLowerCase() === 'document') {
      const result = await downloadLinkFile(
        createWorkflowTriggerLink,
        workflowTrigger,
      );
      return result.data;
    } else {
      const result = await execLink(createWorkflowTriggerLink, workflowTrigger);
      return result.data;
    }
  }
  throw new Error(
    `Action ${EXECUTE_WORKFLOWTRIGGER_LINK_KEY} is not allowed or missing link action`,
  );
};
