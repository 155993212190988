import { CommodityTrackingNumberDto } from '../../../models/data.models';
import { createCommodityTrackingNumber } from '../../commodityTrackingNumbers/commodityTrackingNumbers.store';

export type TrackingNumberSubmitProps = {
  commodityId: number;
  trackingNumber: string;
  isPrimary: boolean;
  syncOrderId?: number;
};

export const useTrackingNumberHandler = () => {
  return {
    onTrackingNumberSubmit: trackingNumberSubmitHandler,
  };
};

export const trackingNumberSubmitHandler = (
  data: TrackingNumberSubmitProps,
) => {
  if (data.commodityId != null) {
    const commodityTrackingNumberParams: CommodityTrackingNumberDto = {
      commodityId: data.commodityId,
      trackingNumber: data.trackingNumber,
      commodityTrackingNumberId: 0,
      isPrimary: data.isPrimary,
      syncOrderId: data.syncOrderId,
    };

    return createCommodityTrackingNumber(commodityTrackingNumberParams);
  } else {
    return Promise.reject();
  }
};
