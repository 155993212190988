import { FiChevronLeft, FiChevronRight, FiChevronUp } from 'react-icons/fi';
import logger from '../../logger';
import { ILocalizeString, localized } from '../layout/component-hooks';
import { ITableView } from './tableView';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

export interface IColumn {
  name: string;
  accessor?: string;
  type?: string;
  label?: ILocalizeString;
  showAs?: { component: string; props: any }; // Component to render the cell as
  props?: any;
  value?: string; // value for the cell
}

export interface ITableColumn {
  name: string;
  Header: ILocalizeString;
  accessor: string;
  getHeaderProps: () => any;
  render: (elementName: string, row: any, view: ITableView) => any;
  props?: any;
  value?: string;
}

const getAccessor = (column: IColumn): string => {
  if (column.accessor) {
    return column.accessor;
  }
  // remove all (...) from the column name
  if (column.name.includes('(')) {
    const colName = column.name.replace(/\(.*\)/, '');
    return colName;
  }

  return column.name;
};

export const columnBuilder = (
  column: IColumn & { onColumnClick?: (column: IColumn) => void },
): ITableColumn => {
  const colAccessor = getAccessor(column);
  const orderByIcon = (orderColumn: IColumn, view: ITableView) => {
    const orderBy = view?.config?.orderByColumns?.find(
      (c) => c.name === orderColumn.name,
    );
    if (orderBy) {
      if (orderBy.direction === 'ASC') {
        return <BsArrowDown />;
      }
      if (orderBy.direction === 'DESC') {
        return <BsArrowUp />;
      }
    }
    return null;
  };

  return {
    ...column,
    Header: column.label,
    accessor: colAccessor,
    props: column.props,
    value: column.value,
    getHeaderProps: () => {
      return {
        key: column.name,
        scope: 'col',
        onClick: () => {
          if (column.onColumnClick) {
            column.onColumnClick(column);
          }
        },
      };
    },
    render: (elementName: any, row: any, view: ITableView) => {
      if (elementName === 'Header') {
        return (
          <a href="javascript:" className="link">
            {localized(column.label)}
            {orderByIcon(column, view)}
          </a>
        );
      }
      if (elementName === 'Cell') {
        return row[colAccessor];
      }
      return 'Column Error';
    },
  };
};
