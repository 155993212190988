import React from 'react';
import { CommodityDto, OrderDto } from '../../../../models/data.models';
import { WarehouseReceiptForm } from './warehouseReceipt-form.component';

export type CreateWarehouseFragmentProps = {
  orders: OrderDto[];
  commodities: CommodityDto[];
  onWarehouseReceiptCreated: (order: OrderDto) => void;
  handleBack: () => void;
};
export const CreateWarehouseFragment = ({
  orders,
  commodities,
  onWarehouseReceiptCreated,
  handleBack,
}: CreateWarehouseFragmentProps) => {
  const onCancel = () => {
    handleBack();
  };

  const getAnyOrder = () => {
    if (orders && orders.length > 0) {
      orders.forEach((order) => {
        if (
          order.billToContactId &&
          order.billToContactName &&
          order.billToContactType
        ) {
          return order;
        }
      });
      return orders[0];
    }
    return null;
  };

  return (
    <>
      {commodities && (
        <WarehouseReceiptForm
          order={getAnyOrder()}
          commodities={commodities}
          onWarehouseReceiptCreated={onWarehouseReceiptCreated}
          onCancel={onCancel}
        />
      )}
    </>
  );
};
