import { Dialog } from '../../common/components/dialog/dialog.component';
import { JobStatusEdit } from './jobStatus-edit.component';
import { JobStatusDto } from '../../../models/data.models';

export type JobStatusDialogProperties = {
  jobStatusId?: number | null;
  title: string;
};

export class JobStatusDialog extends Dialog<JobStatusDialogProperties> {
  constructor(props: JobStatusDialogProperties) {
    super(props);
    this.onJobStatusSaved = this.onJobStatusSaved.bind(this);
  }

  onJobStatusSaved = (jobStatus: JobStatusDto) => {
    this.close(jobStatus);
  };

  onJobStatusDeleted = (jobStatus: JobStatusDto) => {
    this.close(jobStatus);
  };

  onCancel = () => {
    this.close();
  };

  renderContent(): any {
    const { jobStatusId } = this.props as JobStatusDialogProperties;
    return (
      <JobStatusEdit
        jobStatusId={jobStatusId}
        onJobStatusCreated={this.onJobStatusSaved}
        onJobStatusUpdated={this.onJobStatusSaved}
        onDelete={this.onJobStatusDeleted}
        onCancel={this.onCancel}
      />
    );
  }
}
