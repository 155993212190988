import {
  UserDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  UserDtoPagedResult,
  CreateUserCommandValues,
  UpdateUserCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createUserRequest,
  getUsersListRequest,
  getUserRequest,
  deleteUserRequest,
  updateUserRequest,
  importUsersRequest,
  exportUsersRequest,
  GetUserParams,
} from './users.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type UsersStoreState = {
  links: LinkDto[];
  userColumns: EntityFieldDto[];
  defaultUserColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getUsersColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'User',
  });
});

addEffectStatusHandling(getUsersColumnsFx);

export const getUsersDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'User',
  });
});

addEffectStatusHandling(getUsersDefaultColumnsFx);

export const updateUsersColumns = createEvent<EntityFieldDto[]>();

export const getUsersFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUsersListRequest(currentOrganization, params);
});

addEffectStatusHandling(getUsersFx);

export const createUserFx = createEffect((userData: UserDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createUserCommand: CreateUserCommandValues = { ...userData };

  return createUserRequest(currentOrganization!, createUserCommand);
});

addEffectStatusHandling(createUserFx, {
  completeMessage: 'Asp net user was created successfully',
  errorMessage: 'Asp net user was not created',
});

export const getUserFx = createEffect((userParams: GetUserParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUserRequest(currentOrganization as LinkResourceBaseDto, userParams);
});

addEffectStatusHandling(getUserFx);

export const updateUserFx = createEffect((user: UserDto) => {
  const updateUserCommand: UpdateUserCommandValues = { ...user };
  return updateUserRequest(user, updateUserCommand);
});

addEffectStatusHandling(updateUserFx, {
  completeMessage: 'Asp net user was updated successfully',
  errorMessage: 'Asp net user was not updated',
});

export const deleteUserFx = createEffect((user: UserDto) => {
  return deleteUserRequest(user);
});

addEffectStatusHandling(deleteUserFx, {
  completeMessage: 'Asp net user was deleted successfully',
  errorMessage: 'Asp net user was not deleted',
});

export const exportUsersFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportUsersRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportUsersFx, {
  completeMessage: 'Asp net users were exported successfully',
  errorMessage: 'Asp net users were not exported',
});

export const importUsersFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importUsersRequest(listResource as LinkResourceBaseDto, params, file);
  },
);
addEffectStatusHandling(importUsersFx, {
  completeMessage: 'Asp net users were imported successfully',
  errorMessage: 'Asp net users were not imported',
  inFlightMessage: 'Importing asp net users...',
});

const defaultState: UsersStoreState = {
  links: [],
  userColumns: [],
  defaultUserColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const userStore: Store<UsersStoreState> = createStore(defaultState)
  .on(getUsersColumnsFx.done, (state, payload) => {
    return {
      ...state,
      userColumns: payload.result.items,
      defaultUserColumns: payload.result.items,
    };
  })
  .on(getUsersDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultUserColumns: payload.result.items,
    };
  })
  .on(updateUsersColumns, (state, payload) => {
    return { ...state, userColumns: payload };
  });
