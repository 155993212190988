import React, { useState } from 'react';
import { CreateWarehouseFragment } from './createWarehouseFragment.component';
import { CommodityDto, OrderDto } from '../../../../models/data.models';
import {
  CargoFragment,
  CargoFragmentTab,
} from '../../../airShipmentOrders/wizard/components/cargoFragment.component';

export type CreateByPurchaseWizardProps = {
  onWarehouseReceiptCreated: (order: OrderDto) => void;
};
export const CreateByPurchaseWizard = ({
  onWarehouseReceiptCreated,
}: CreateByPurchaseWizardProps) => {
  const [commodityIds, setCommodityIds] = useState<number[]>([]);
  const [commodities, setCommodities] = useState<CommodityDto[]>([]);
  const [orders, setOrders] = useState<OrderDto[]>([]);
  const [step, setStep] = useState<number>(0);

  const handleNext = () => {
    if (step < 1) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };
  return (
    <>
      {step === 0 && (
        <CargoFragment
          commodityIds={commodityIds}
          setCommodityIds={setCommodityIds}
          commodities={commodities}
          setCommodities={setCommodities}
          orders={orders}
          setOrders={setOrders}
          handleNext={handleNext}
          hiddenTabs={[CargoFragmentTab.Warehouse, CargoFragmentTab.Location]}
        />
      )}
      {step === 1 && (
        <CreateWarehouseFragment
          commodities={commodities}
          onWarehouseReceiptCreated={onWarehouseReceiptCreated}
          handleBack={handleBack}
          orders={orders}
        />
      )}
    </>
  );
};
