import React, { useState, useEffect } from 'react';
import {
  Column,
  DocumentTemplateDtoPagedResult,
} from '../../../../models/data.models';
import { getDocumentTemplatesFx } from '../../../documentTemplates/documentTemplates.store';
import { WizardGrid } from '../common/wizardGrid.component';
import { ListElementsNotFound } from '../../../common/components/list-elements-not-found/list-elements-not-found.component';
import { Button } from '../../../common/components/button/button.component';

export type DocumentsFragmentProps = {
  handleNext: () => void;
  handleBack: () => void;
  documentIds: number[];
  setDocumentIds: (documentIds: any) => void;
  documentTemplateFilter?: string;
  isBolCreationDisabled?: () => boolean;
};

export const DocumentsFragment = ({
  handleNext,
  handleBack,
  documentIds,
  setDocumentIds,
  documentTemplateFilter,
  isBolCreationDisabled,
}: DocumentsFragmentProps) => {
  const [
    documentTemplates,
    setDocumentTemplates,
  ] = useState<DocumentTemplateDtoPagedResult | null>(null);

  const toggleSelectedId = (id: any) => {
    if (documentIds.includes(id)) {
      setDocumentIds(documentIds.filter((selectedId) => selectedId !== id));
    } else {
      setDocumentIds([...documentIds, id]);
    }
  };

  const isDocumentSelected = (id: any) => {
    return documentIds.includes(id);
  };

  const columns: Column[] = [
    {
      name: 'documentTemplateId',
      visible: true,
      title: 'Document template',
      sortName: 'documentTemplateId',
      type: 'checkBox',
    },
    {
      name: 'name',
      visible: true,
      title: 'Name',
      sortName: 'name',
    },
    {
      name: 'documentTemplateType',
      visible: true,
      title: 'Document Template Type',
      sortName: 'documentTemplateType',
    },
  ];

  const sort = 'documentTemplateId';
  const filter = '';
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [search, setSearch] = useState('');

  const getData = () => {
    getDocumentTemplatesFx({
      offset,
      limit,
      sort,
      filter: documentTemplateFilter,
      search,
    }).then(
      (documentTemplatesData) => {
        setDocumentTemplates(documentTemplatesData);
      },
      () => {},
    );
  };

  const changeLimit = (documnetslimit: number) => {
    setLimit(documnetslimit);
  };

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const onDocumentsSearch = (search) => {
    setSearch(search);
  };

  useEffect(() => {
    getData();
  }, [offset, limit, search]);

  return (
    <div className="p-5">
      <div className="row mb-3">
        <div className="col-7 ml-3">
          <h2>Select documents for the master shipment</h2>
        </div>
      </div>
      <WizardGrid
        onSearch={onDocumentsSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={['documentTemplateId']}
        data={documentTemplates?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={documentTemplates?.totalCount}
        sort={sort}
        onSort={() => {}}
        onEdit={null}
        onPageChanged={onPageChanged}
        onColumnsChanged={null}
        onSelect={null}
        setIds={toggleSelectedId}
        isSelected={isDocumentSelected}
        isCheckBoxDisabled={
          isBolCreationDisabled !== null ? isBolCreationDisabled : null
        }
        hideColumnsSelect={true}
        onLimitChange={changeLimit}
        isDropDownList={true}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      {!(
        documentTemplates &&
        documentTemplates.items &&
        documentTemplates.items.length
      ) && (
        <ListElementsNotFound
          entityName="Document Template"
          entityLinkKey={null}
          onClick={null}
        />
      )}
    </div>
  );
};
