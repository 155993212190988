import React from 'react';
import { Dialog } from '../components/dialog/dialog.component';
import { TakePhoto } from './takePhoto.component';

export type TakePhotoDialogProps = {
  title: string;
};

export class TakePhotoDialog extends Dialog<TakePhotoDialogProps> {
  constructor(props: TakePhotoDialogProps) {
    super({ ...props });
    this.onPhotoChanged = this.onPhotoChanged.bind(this);
  }
  onPhotoChanged(image) {
    this.close(image);
  }
  renderContent() {
    return <TakePhoto onChange={this.onPhotoChanged} />;
  }
}
