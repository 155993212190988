import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { OrderDto } from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { CarrierPayManagerForListDtoPagedResult } from '../../../models/custom.models';
import { GetOrderParams } from '../../orders/orders.service';
import { deleteOrder } from '../../orders/orders.store';
import { ORDER_CREATE_PATH } from '../../orders/order.route';
import { CarrierPayManagerGrid } from '../../common/components/grid/carrierPayManager-grid.component';
import {
  carrierPayManagerStore,
  getCarrierPays,
  updateCarrierPayColumns,
} from '../carrierPayManager.store';
import { ReceiveCarrierPayDialog } from './receiveCarrierPay.dialog';
import { OrderDialog } from '../../orders/components/order.dialog';
import { getFormattedDate } from '../../../utils/formatting.utils';

export type CarrierPayManagerListProps = {
  goToDetails?: (orderParams: GetOrderParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (order: OrderDto, orderId: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const CarrierPayManagerList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch = () => {},
}: CarrierPayManagerListProps) => {
  const [
    carrierPays,
    setCarrierPays,
  ] = useState<CarrierPayManagerForListDtoPagedResult | null>(null);

  useEffect(() => {
    getOrdersData();
  }, [offset, limit, sort, filter, search]);
  const getOrdersData = () => {
    getCarrierPays({ offset, limit, sort, filter, search }).then(
      (ordersData) => {
        ordersData.items.forEach((item) => {
          item.shippedDate = item.shippedDate
            ? getFormattedDate(item.shippedDate, false)
            : null;
          item.deliveredDate = item.deliveredDate
            ? getFormattedDate(item.deliveredDate, false)
            : null;
          item.receivedDate = item.receivedDate
            ? getFormattedDate(item.receivedDate, false)
            : null;
        });
        const orderDtoResult: CarrierPayManagerForListDtoPagedResult = {
          limit: ordersData.limit,
          offset: ordersData.offset,
          links: ordersData.links,
          totalCount: ordersData.totalCount,
          items: ordersData.items,
        };
        setCarrierPays(orderDtoResult);
      },
      () => {},
    );
  };
  const { orderCarrierPayColumns: columns } = useStore(carrierPayManagerStore);

  const onDeleteOrder = (order: OrderDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${order.orderId} Order`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteOrder(order).then(() => {
            onDelete();
            getOrdersData();
          });
        }
      },
      () => {},
    );
  };

  const onEditOrder = (order: OrderDto) => {
    goToDetails({ orderId: order.orderId });
  };
  const navigate = useNavigate();
  const onCreateNewOrder = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(createPath);
  };
  const onChangeItem = (data?: any[]) => {
    setCarrierPays((ordersDto) => {
      ordersDto.items = data;
      return { ...ordersDto };
    });
  };

  const onCarrierPayReceived = () => {
    getOrdersData();
  };

  const receiveCarrierPay = (carrierPay) => {
    showDialog({
      dialog: ReceiveCarrierPayDialog,
      props: {
        className: 'receive-carrier-pay-modal',
        title: 'Receive Carrier Pay',
        balanceOwning: carrierPay.balanceOwning,
        orderCarrierId: carrierPay.orderCarrierId,
        transactionNumber: carrierPay.orderId.toString(),
        onCarrierPayReceived: onCarrierPayReceived,
      },
    }).then((result) => {});
  };
  const onCarrierPaySelect = (carrierPay, carrierPayId) => {
    if (onSelect) onSelect(carrierPay, carrierPayId);
    showDialog({
      dialog: OrderDialog,
      props: {
        orderId: carrierPay.orderId,
        title: 'Update Order',
        className: '',
      },
    }).then(
      (carrierPay) => {
        if (carrierPay !== null) {
          getOrdersData();
        }
      },
      () => {},
    );
  };

  return (
    <>
      <CarrierPayManagerGrid
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={null}
        data={carrierPays?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={carrierPays?.totalCount}
        sort={sort}
        onDelete={onDeleteOrder}
        onSort={(field) => {
          onSort(field);
        }}
        search={search}
        onEdit={onEditOrder}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateCarrierPayColumns}
        onSelect={onCarrierPaySelect}
        onChangeItem={onChangeItem}
        filter={filter}
        onFilter={onFilter}
        onSearch={onSearch}
        receiveCarrierPay={receiveCarrierPay}
      />
    </>
  );
};
