import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { ContactType, ContactStatusStage } from '../../../models/data.models';

export const ContactStatusForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

ContactStatusForm.StatusDescription = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'statusDescription'}
      label={'Contact Status Description'}
      placeholder={'Contact Status Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ContactStatusForm.StatusName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'statusName'}
      label={'Contact Status Name'}
      placeholder={'Contact Status Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ContactStatusForm.ContactType = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'contactType'}
      label={'Contact Type'}
      placeholder={'Contact Type'}
      required={true}
      type={'enum-select'}
      enumName={ContactType}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ContactStatusForm.Priority = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'priority'}
      label={'Priority'}
      placeholder={'Priority'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ContactStatusForm.StatusStage = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'statusStage'}
      label={'Status Stage'}
      placeholder={''}
      required={true}
      type={'enum-select'}
      enumName={ContactStatusStage}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
