import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PackageTypesListScreen } from './screen/packageType-list.screen';
import { PackageTypeScreen } from './screen/packageType.screen';

export const PACKAGETYPES_LIST_PATH = '/package-type';
export const PACKAGETYPE_EDIT_PATH = '/package-type/:packageTypeId';
export const PACKAGETYPE_CREATE_PATH = '/package-type/0';

export const PackageTypeRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <PackageTypesListScreen pageTitle="Package Types" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <PackageTypeScreen pageTitle="Add New Package Type" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':packageTypeId'}
        element={
          <PrivateRoute>
            <PackageTypeScreen pageTitle="Update Package Type" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
