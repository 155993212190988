import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  parseTemplate,
  useComponentQueries,
  useComponentVariables,
} from '../component-hooks';
import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';
import { ListGroup } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormikContext } from 'formik';

const ItemType = 'DRAGGABLE_ITEM';

const DraggableItem = ({ id, index, moveItem, children, enableSorting }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { id, index },
    canDrag: () => enableSorting,
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return <div ref={(node) => ref(drop(node))}>{children}</div>;
};

export const CollectionComponent = (props: ComponentProps) => {
  const {
    items,
    itemProps,
    itemName = 'item',
    orientation,
    cols,
    childClassName,
    enableSorting,
    divider,
  } = props?.props || {};
  const [collection, setCollection] = useState([] as any);

  const { variables } = useComponentVariables(props);
  const { getPropertyValue, queries } = useComponentQueries(props, variables);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedInitialValues, setIsLoadedInitialValues] = useState(false);
  const formikContext = useContext(FormikContext);

  useEffect(() => {
    if (isLoadedInitialValues) return;

    if (items && queries) {
      if (!isLoadedInitialValues) {
        getPropertyValue(
          items,
          {},
          { ...props.variables, ...props.context?.store },
        )
          .then((data) => {
            setCollection(data);
          })
          .catch((e) => {
            console.log('initialValues error', e);
          })
          .finally(() => {
            setIsLoadedInitialValues(true);
            setIsLoaded(true);
          });
      }
    } else if (!props?.props?.queries && items) {
      setCollection(
        parseTemplate(items, {
          ...props.variables,
          ...props.context?.store,
        }),
      );
      setIsLoaded(true);
    }
  }, [queries, variables, items, props.variables, props.context?.store]);

  const moveItem = useCallback((fromIndex, toIndex) => {
    setCollection((prevCollection) => {
      const newCollection = [...prevCollection];
      const [movedItem] = newCollection.splice(fromIndex, 1);
      newCollection.splice(toIndex, 0, movedItem);
      if (formikContext) {
        formikContext.setFieldValue(props.name, newCollection);
      }
      return newCollection;
    });
  }, []);

  const rowColsClass = useMemo(() => {
    if (orientation === 'vertical') {
      return `row-cols-${cols ?? 1}`;
    } else if (cols > 0) {
      return `row-cols-${cols ?? 1}`;
    } else {
      return 'row-cols-1';
    }
  }, [orientation, cols]);

  if (!isLoaded) return null;

  let CollectionTag = props?.props?.collectionTag || 'div';
  let ContainerTag = props?.props?.containerTag || 'div';
  const ItemTag = props?.props?.itemTag || 'div';

  if (props?.props?.type === 'ListGroup') {
    CollectionTag = ListGroup;
    ContainerTag = ListGroup.Item;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <CollectionTag
        data-testid={props?.name}
        className={`${
          orientation === 'vertical'
            ? 'd-flex flex-column'
            : 'd-flex flex-row flex-wrap row-gap'
        } ${rowColsClass}`}
      >
        {collection?.map((child, colIx) => {
          if (!child) return null;

          let childItem = child;
          if (itemProps) childItem = parseTemplate(itemProps, child);

          const combinedClass = [childClassName, child.props?.className]
            .filter(Boolean)
            .join(' ')
            .trim();

          return (
            <>
              <ContainerTag key={`child${colIx}`} className={combinedClass}>
                {props?.children?.map(
                  (childComponent: ComponentProps, compIx) => {
                    if (!child) return null;
                    const renderItem = () => {
                      return (
                        <ComponentRender
                          {...childComponent}
                          props={{
                            ...childComponent?.props,
                            prefix: `${
                              childComponent?.props?.prefix || props?.name || ''
                            }[${colIx}].`,
                          }}
                          context={props.context}
                          variables={{
                            ...props.variables,
                            [itemName]: childItem,
                            [`${itemName}Index`]: colIx,
                          }}
                        />
                      );
                    };
                    if (!enableSorting) {
                      return (
                        <ItemTag index={colIx} className={combinedClass}>
                          {renderItem()}
                        </ItemTag>
                      );
                    } else {
                      return (
                        <DraggableItem
                          key={`${colIx}_${compIx}`}
                          id={child.id}
                          index={colIx}
                          moveItem={moveItem}
                          enableSorting={enableSorting}
                        >
                          <ItemTag className={combinedClass}>
                            {renderItem()}
                          </ItemTag>
                        </DraggableItem>
                      );
                    }
                  },
                )}
              </ContainerTag>
              {colIx !== collection.length - 1 && divider && (
                <span>{divider.replaceAll(' ', '\u00a0')}</span>
              )}
            </>
          );
        })}
      </CollectionTag>
    </DndProvider>
  );
};

export const FormCollectionComponent = (props: ComponentProps) => {};
