import { parseTemplate } from '../../components/layout/component-hooks';
import { addMessage } from '../../messages.store';

export const fileDownloadAction = ({
  actionProps,
  data,
  latestStoreValues,
  source,
  onAction,
}) => {
  const link = parseTemplate(actionProps.fileDownload.url, {
    ...latestStoreValues,
    ...data,
  });
  return fetch(link, {
    referrerPolicy: 'no-referrer',
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(`Failed to download document ${response.statusText}`);
      }
      let filename = response.url.split('/').pop().split('?')[0];

      const disposition = response.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      const urlObj = window.URL.createObjectURL(
        new Blob([await response.blob()]),
      );
      const ahref = document.createElement('a');
      ahref.href = urlObj;
      ahref.setAttribute('download', filename);

      document.body.appendChild(ahref);
      ahref.click();
      document.body.removeChild(ahref);
    })
    .catch((e) => {
      addMessage({
        message: `Failed to download document ${e}`,
        type: 'danger',
        autoHide: true,
      });

      return false;
    });
};
