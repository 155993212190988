import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContactStatusesListScreen } from './screens/contactStatuses-list.screen';
import { ContactStatusScreen } from './screens/contactStatus.screen';

export const CONTACTSTATUSES_LIST_PATH = '/contact-statuses';
export const CONTACTSTATUS_EDIT_PATH = '/contact-statuses/:statusId';
export const CONTACTSTATUS_CREATE_PATH = '/contact-statuses/create';

export const ContactStatusesRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ContactStatusesListScreen
              pageTitle="Contact Statuses"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <ContactStatusScreen
              pageTitle="Add New Contact Status"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':statusId'}
        element={
          <PrivateRoute>
            <ContactStatusScreen
              pageTitle="Update Contact Status"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
