import React, { useState } from 'react';
import { CommodityDto } from '../../../../models/data.models';
import { Toggle } from '../../../common/components/toggle/toggle.component';
import { BsExclamationTriangle } from 'react-icons/bs';
import { triggerWorkflowByConfig } from '../../../workflowTriggers/workflowTriggerHook';

type DangerousToggleProps = {
  row?: CommodityDto;
  index?: number;
};

export const DangerousToggle = ({ row, index }: DangerousToggleProps) => {
  const [checked, setChecked] = useState<boolean>(
    !!row.customValues['dangerousItems'] &&
      row.customValues['dangerousItems'] !== 'false',
  );

  const onToggleDangerousChange = async (checked: boolean) => {
    const workflowExecutionVariables = {
      commodityId: row['commodityId'],
      dangerousItems: checked ? 'DangerousGoods' : '',
    };

    setChecked(checked);
    await triggerWorkflowByConfig({
      configName: 'tms.modules.packing.setDangerous',
      workflowIdConfigKey: 'setDangerousWorkflowId',
      workflowVariables: workflowExecutionVariables,
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-end">
      <Toggle
        name="dangerous"
        index={index}
        onChange={onToggleDangerousChange}
        defaultChecked={checked}
        style={{ flexBasis: '60%' }}
      />
      <div className="d-flex" style={{ flexBasis: '40%' }}>
        <BsExclamationTriangle
          size={24}
          color={checked ? '#F04438' : '#616161'}
        />
      </div>
    </div>
  );
};
