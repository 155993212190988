import {
  RateDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateRateCommandValues,
  UpdateRateCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createRateRequest,
  getRatesListRequest,
  getRateRequest,
  deleteRateRequest,
  updateRateRequest,
  importRatesRequest,
  exportRatesRequest,
  GetRateParams,
} from './rates.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type RatesStoreState = {
  links: LinkDto[];
  rateColumns: EntityFieldDto[];
  defaultRateColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getRatesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Rate',
  });
});

addEffectStatusHandling(getRatesColumnsFx);

export const getRatesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Rate',
  });
});

addEffectStatusHandling(getRatesDefaultColumnsFx);

export const updateRatesColumns = createEvent<EntityFieldDto[]>();

export const getRatesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getRatesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getRatesFx);

export const createRateFx = createEffect((rateData: RateDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createRateCommand: CreateRateCommandValues = { ...rateData };

  return createRateRequest(currentOrganization!, createRateCommand);
});

addEffectStatusHandling(createRateFx, {
  completeMessage: 'Rate was created successfully',
  errorMessage: 'Rate was not created',
});

export const getRateFx = createEffect((rateParams: GetRateParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getRateRequest(currentOrganization as LinkResourceBaseDto, rateParams);
});

addEffectStatusHandling(getRateFx);

export const updateRateFx = createEffect((rate: RateDto) => {
  const updateRateCommand: UpdateRateCommandValues = { ...rate };
  return updateRateRequest(rate, updateRateCommand);
});

addEffectStatusHandling(updateRateFx, {
  completeMessage: 'Rate was updated successfully',
  errorMessage: 'Rate was not updated',
});

export const deleteRateFx = createEffect((rate: RateDto) => {
  return deleteRateRequest(rate);
});

addEffectStatusHandling(deleteRateFx, {
  completeMessage: 'Rate was deleted successfully',
  errorMessage: 'Rate was not deleted',
});

export const exportRatesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportRatesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportRatesFx, {
  completeMessage: 'Rates were exported successfully',
  errorMessage: 'Rates were not exported',
});

export const importRatesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importRatesRequest(listResource as LinkResourceBaseDto, params, file);
  },
);
addEffectStatusHandling(importRatesFx, {
  completeMessage: 'Rates were imported successfully',
  errorMessage: 'Rates were not imported',
  inFlightMessage: 'Importing rates...',
});

const defaultState: RatesStoreState = {
  links: [],
  rateColumns: [],
  defaultRateColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const rateStore: Store<RatesStoreState> = createStore(defaultState)
  .on(getRatesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      rateColumns: payload.result.items,
      defaultRateColumns: payload.result.items,
    };
  })
  .on(getRatesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultRateColumns: payload.result.items,
    };
  })
  .on(updateRatesColumns, (state, payload) => {
    return { ...state, rateColumns: payload };
  });
