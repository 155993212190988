import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { AccountingItemScreen } from './screens/accountingItem.screen';
import { AccountingItemsListScreen } from './screens/accountingItems-list.screen';

export const ACCOUNTINGITEMS_LIST_PATH = '/accounting-items';
export const ACCOUNTINGITEM_EDIT_PATH = '/accounting-items/:accountingItemId';
export const ACCOUNTINGITEM_CREATE_PATH = '/accounting-items/0';

export const AccountingItemsRoute = () => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <AccountingItemsListScreen
              pageTitle="Accounting Items"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <AccountingItemScreen
              pageTitle="Add New Accounting Item"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':accountingItemId'}
        element={
          <PrivateRoute>
            <AccountingItemScreen
              pageTitle="Update Accounting Item"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
