import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AttachmentForm } from './attachment.form';
import {
  AttachmentDto,
  AttachmentParentType,
  AttachmentType,
} from '../../../models/data.models';
import { ReactDropzoneComponent } from '../../common/components/dropzone/react-dropzone.component';
import { showDialog } from '../../common/dialog.store';
import { AddAttachmentsDialog } from './add-attachments.dialog';
import { AiFillCloseCircle } from 'react-icons/ai';
import { deleteAttachment } from '../attachments.store';
import { PicturePreview } from './picture-preview.component';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { getAttachmentsLinks } from '../../../utils/helper.utils';

export type UploadAttachmentsProps = {
  onAttachmentsCreated?: (attachments: AttachmentDto[]) => void;
  onAttachmentsUpdated?: (attachments: AttachmentDto[]) => void;
  onCancel?: () => void;
  parentId: number;
  parentType: AttachmentParentType;
  childIds: number[];
  prefix?: string;
};

const initialState: AttachmentDto = {
  attachmentId: 0,
  attachmentType: null,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: null,
  fileName: '',
  fileUri: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  parentId: 0,
  parentType: null,
  previewUri: null,
  thumbnailUri: null,
  createdByUserName: '',
  lastModifiedByUserName: '',
  presignedPreviewUri: null,
  presignedThumbnailUri: null,
  presignedFileUri: null,
  links: [],
};

export const UploadAttachments = ({
  onAttachmentsCreated,
  onAttachmentsUpdated,
  onCancel = () => {},
  parentId,
  parentType,
  childIds,
  prefix,
}: UploadAttachmentsProps) => {
  const [initialValues, setInitialValues] = useState<AttachmentDto[] | null>([
    initialState,
  ]);
  const [isSending, setIsSending] = useState(false);
  const [files, setFiles] = useState<AttachmentDto[] | null>([]);
  const [filled, setFilled] = useState(0);

  const addAttachments = (files) => {
    if (files) {
      showDialog({
        dialog: AddAttachmentsDialog,
        props: {
          files,
          parentId: parentId,
          parentType: parentType,
          title: 'Add Attachments',
          childIds: childIds,
          attachmentType: AttachmentType.Picture,
          prefix: prefix,
        },
      })
        .then((result: AttachmentDto[]) => {
          if (result != null) {
            setFiles((prevFiles) => {
              getAttachmentsLinks(result);
              return [...prevFiles, ...result];
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (filled < 100 && files?.length > 0) {
      setTimeout(() => setFilled((prev) => (prev += 2)), 50);
    }
  }, [filled, files]);

  const onSubmit = () => {
    onAttachmentsCreated(files);
  };

  const onReset = async () => {
    setIsSending(true);
    if (files?.length > 0) {
      for (const attachment of files) {
        await deleteAttachment(attachment);
      }
    }
    setIsSending(false);
    onCancel();
  };

  const onDeleteAttachment = (attachment: AttachmentDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${attachment.attachmentId} Attachment`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteAttachment(attachment).then(() => {
          setFiles(
            files.filter((x) => x.attachmentId !== attachment.attachmentId),
          );
        });
      }
    });
  };

  return (
    <AttachmentForm initialValues={initialState} onSubmit={onSubmit}>
      <div className="d-flex flex-column">
        <h2>Add attachments</h2>
        <div className="pt-2 pb-5">
          <ReactDropzoneComponent onDrop={addAttachments}>
            <div className="drag-and-drop-card">
              <img className="upload-cloud-icon mb-3" />
              <p>
                <span className="click-upload-text" color="primary">
                  Click to upload
                </span>{' '}
                or drag and drop
              </p>
              <p>Maximum size 6 MB</p>
            </div>
          </ReactDropzoneComponent>
        </div>
        {files?.length > 0 &&
          files.map((file, index) => {
            return (
              <div className="picture-card-uploaded" key={index}>
                <div className="w-25">
                  <PicturePreview
                    attachment={file}
                    isAttachment={true}
                    height={'75px'}
                    documentName={file?.fileName}
                  />
                </div>
                <div className="w-75">
                  <div className="picture-card-description">
                    <div>
                      <p className="mb-1">{file?.fileName}</p>
                      <p className="mb-3" style={{ color: 'GrayText' }}>
                        20KB
                      </p>
                    </div>
                    <div>
                      <AiFillCloseCircle
                        size="20px"
                        color="#98A2B3"
                        className="cursor-pointer"
                        onClick={() => {
                          onDeleteAttachment(file);
                        }}
                      />
                    </div>
                  </div>
                  {filled < 100 && (
                    <div className="bar-container">
                      <div className="progress w-100">
                        <div
                          className="progress-bar html"
                          role="progressbar"
                          style={{
                            width: `${filled}%`,
                            transition: 'width 0.5s',
                          }}
                        ></div>
                      </div>
                      <span>{`${filled}%`}</span>
                    </div>
                  )}
                  {filled >= 100 && (
                    <div
                      className="d-flex justify-content-between"
                      style={{ fontSize: '14px', color: '#475467' }}
                    >
                      <div>by {file?.createdByUserName}</div>
                      <div>{getFormattedDate(file?.created, false, true)}</div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        <div className="justify-space-between row">
          <div className="col-6">
            <Button
              name="save-attachment"
              type="submit"
              color="primary"
              className="btn-block"
              disabled={files?.length < 1 || isSending || filled < 100}
            >
              Save
            </Button>
          </div>
          <div className="col-6">
            <Button
              type="button"
              color="secondary"
              className="btn-block btn-outline-secondary"
              disabled={isSending}
              isSending={isSending}
              onClick={onReset}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </AttachmentForm>
  );
};
