export const CONNECTION_EVENT = {
  device: {
    bleFirmwareVersion: '050034',
    firmwareVersion: '3.17.480 A7VD',
    isMSRICAttached: false,
    isMsrEncrypt: false,
    isUHFAttached: false,
    kdcName: 'KDC480[103084]',
    serialNumber: '23f4103084',
    subType: 'BLE',
    type: 'BLUETOOTH',
    modelConfig: {
      is2D: false,
      isBarcode: true,
      isBluetooth: true,
      isCDMA: false,
      isDisp: false,
      isFingerPrint: true,
      isGPS: false,
      isKeypad: false,
      isMSR: false,
      isMSRIC: true,
      isNFC: true,
      isPOS: false,
      isPassport: true,
      isUHF: true,
      isVib: false,
      isWiFi: false,
      modelName: 'KDC470L/D',
      modelNumber: 'f',
    },
  },
  msg: 'KDC connection successful',
  progress: 'CONNECTION_STATE_CONNECTED',
  status: true,
};

export const DISCONNECT_EVENT = {
  device: null,
  msg: 'Device is disconnected',
  progress: 'CONNECTION_STATE_DISCONECTED',
  status: false,
};

export const SCAN_EVENT = {
  code: 0,
  data: 'TRTMD00016636',
  msg: '',
  size: 18,
  sort: 'GENERAL',
  status: true,
  time: '2000-01-01T00:01:59.000Z',
  type: 'CODE39',
};
