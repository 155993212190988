import React from 'react';
import {
  Form,
  FormProps,
} from '../../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../../common/components/input/input.component';
import { SelectContactPaginate } from '../../../contacts/components/contact-react-select.component';
import { ReactEnumInputSelect } from '../../../common/components/input/enum-input.select';
import { SelectModeOfTransportationPaginate } from '../../../modeOfTransportations/components/modeOfTransportation-react-select.component';
import { SelectContactStatusPaginate } from '../../../contactStatuses/components/contactStatus-react-select.component';
import { SelectPortPaginate } from '../../../ports/components/port-react-select.component';
import { SelectVesselPaginate } from '../../../vessels/components/vessel-react-select.component';
import { SelectCustomCodePaginate } from '../../../customCodes/components/customCode-react-select.component';
import { SelectCountryPaginate } from '../../../countries/components/country-react-select.component';
import { SelectEquipmentTypePaginate } from '../../../equipmentTypes/components/equipmentType-react-select.component';
import { SelectContactPaymentMethodPaginate } from '../../../contactPaymentMethods/components/contactPaymentMethods-react-select.component';
import { SelectCurrencyPaginate } from '../../../currencies/components/currencies-react-select.component';
import { SelectOrderStatusPaginate } from '../../../orderStatuses/components/orderStatus-react-select.component';
import { SelectDivisionPaginate } from '../../../divisions/components/division-react-select.component';
import { SelectDocumentTemplatePaginate } from '../../../documentTemplates/components/documentTemplate-react-select.component';
import { SelectUserPaginate } from '../../../users/components/user-react-select.component';
import { OrderTypes } from '../../../../models/data.models';
import { clearMessages } from '../../messages.store';
import { RangeDatePicker } from '../rangeDatePicker/rangeDatePicker';
import { SelectMovementLocationPaginate } from '../../../cargoMovement/components/movementLocation-react-select.component';

export const FiltersForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
  className,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      className={className}
    >
      {children}
    </Form>
  );
};

FiltersForm.Textbox = ({
  id,
  placeholder,
  header,
  defaultValue,
  onChange = () => {},
}: ContactSelectInputProps) => {
  return (
    <Input
      key={id}
      size={'sm'}
      name={id}
      type={'filterText'}
      label={header}
      placeholder={placeholder}
      valueInput={defaultValue}
      onChange={onChange}
    />
  );
};

FiltersForm.CustomerSelect = ({
  selectedFilter = ``,
  nameId = '__BillToContactName',
  placeholder = 'Select Bill To Customer',
  required = false,
  defaultValue,
  sort = 'name',
  id,
  header = 'Customer',
  onChange = () => {},
  readonly,
  customStyles,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      canCreateNewEntity={false}
      customStyles={customStyles}
    />
  );
};

FiltersForm.CarrierContactSelect = ({
  selectedFilter = '',
  placeholder = 'Select Carrier',
  sort = 'name',
  searchKey = 'name',
  defaultValue,
  id = 'carrierContactId',
  header = 'Carrier / Driver',
  required = false,
  onChange = () => {},
  readonly,
  customStyles,
  closeMenuOnSelect,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      readonly={readonly === 'readonly'}
      required={required}
      showContactType={true}
      useContext={true}
      isMulti={false}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      selectedSearchKey={searchKey}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      canCreateNewEntity={false}
      customStyles={customStyles}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};

FiltersForm.Date = ({
  size,
  defaultValue,
  onChange = () => {},
  readonly,
  id,
  name,
  disabled,
  placeholder,
  header = 'Date Picker',
  selectsRange = true,
}: InputPublicProps) => {
  return (
    <RangeDatePicker
      readonly={readonly}
      id={id}
      name={name}
      header={header}
      placeholder={placeholder}
      required={false}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      selectsRange={selectsRange}
      clearMessages={clearMessages}
    />
  );
};

FiltersForm.Boolean = ({
  onChange,
  options,
  name,
  id,
  placeholder,
  required = false,
  disabled = false,
  header,
  defaultValue,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

FiltersForm.OrderStatus = ({
  onChange,
  name = 'orderStatusName',
  id = 'orderStatus',
  placeholder = 'Select Order Status',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Order Status',
  defaultValue = '',
  selectedFilter = '',
}: InputPublicProps) => {
  return (
    <SelectOrderStatusPaginate
      onChangeStatus={onChange}
      nameId={name}
      id={id}
      selectedSort={'priority,orderStatusName'}
      placeholder={placeholder}
      required={required}
      isMulti={multiple}
      isDisabled={disabled}
      header={header}
      selectedValue={defaultValue}
      isClearable={true}
      closeMenuOnSelect={true}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValue}
    />
  );
};

FiltersForm.CommodityStatus = ({
  onChange,
  options,
  name = 'commodityStatus',
  id = 'commodityStatus',
  placeholder = 'Select Status',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Status',
  defaultValue = '',
  readonly,
  customStyles,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
      customStyles={customStyles}
    />
  );
};

FiltersForm.IdNumberType = ({
  onChange,
  options,
  name = 'idNumberType',
  id = 'idNumberType',
  placeholder = 'Select Id Number Type',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Id Number Type',
  defaultValue = '',
  readonly,
  customStyles,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
      customStyles={customStyles}
    />
  );
};

FiltersForm.RateType = ({
  onChange,
  options,
  name = 'rateType',
  id = 'rateType',
  placeholder = 'Select Rate Type',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Rate Type',
  defaultValue = '',
  readonly,
  customStyles,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
      customStyles={customStyles}
    />
  );
};

FiltersForm.MovementType = ({
  onChange,
  options,
  name = 'movementType',
  id = 'movementType',
  placeholder = 'Select Movement Type',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Movement Type',
  defaultValue = '',
  readonly,
  customStyles,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
      customStyles={customStyles}
    />
  );
};

FiltersForm.MovementStatus = ({
  onChange,
  options,
  name = 'movementStatus',
  id = 'movementStatus',
  placeholder = 'Select Movement Status',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Movement Status',
  defaultValue = '',
  readonly,
  customStyles,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
      customStyles={customStyles}
    />
  );
};

FiltersForm.ModeOfTransportation = ({
  selectedFilter = ``,
  placeholder = 'Select Mode of Transportation',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'modeOfTransportationId',
  header = 'Mode of Transportation',
  onChange = () => {},
  nameId = '__Description',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectModeOfTransportationPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeModeOfTransportation={onChange}
      isDisabled={disabled}
    />
  );
};

FiltersForm.ContactStatus = ({
  selectedFilter = ``,
  placeholder = 'Select status',
  required = false,
  defaultValue,
  header = 'Contact status',
  onChange = () => {},
  disabled = false,
  isClearable = false,
}: InputPublicProps) => {
  return (
    <SelectContactStatusPaginate
      id={'contactStatusId'}
      nameId="__contactStatusName"
      selectedFilter={selectedFilter}
      placeholder={placeholder}
      required={required}
      selectedValue={defaultValue}
      header={header}
      onChangeStatus={onChange}
      isDisabled={disabled}
      isClearable={true}
      closeMenuOnSelect={true}
    />
  );
};

FiltersForm.Port = ({
  selectedFilter = ``,
  placeholder = 'Select Port',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'portId',
  header = 'Port',
  onChange = () => {},
  readonly,
  nameId = 'portName',
}: InputPublicProps) => {
  return (
    <SelectPortPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePort={onChange}
    />
  );
};

FiltersForm.ParcelStatus = ({
  onChange,
  name = 'orderStatusName',
  id = 'orderStatusId',
  placeholder = 'Select Order Status',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Order Status',
  defaultValue = '',
  isClearable,
  selectedFilter = `OrderType:${OrderTypes.ParcelShipment}`,
  defaultValueFilter = `OrderType:${OrderTypes.ParcelShipment}`,
}: InputPublicProps) => {
  return (
    <SelectOrderStatusPaginate
      onChangeStatus={onChange}
      nameId={name}
      id={id}
      placeholder={placeholder}
      required={required}
      isMulti={multiple}
      isDisabled={disabled}
      header={header}
      selectedValue={defaultValue}
      isClearable={isClearable}
      closeMenuOnSelect={true}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
    />
  );
};

FiltersForm.Vessel = ({
  selectedFilter = ``,
  placeholder = 'Select vessel',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'vesselCode',
  header = 'Vessel',
  onChange = () => {},
  nameId = 'portName',
}: InputPublicProps) => {
  return (
    <SelectVesselPaginate
      id={id}
      nameId={nameId}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeVessel={onChange}
    />
  );
};
FiltersForm.CustomCode = ({
  selectedFilter = ``,
  placeholder = 'Custom Code',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'customCode',
  header = 'Custom Code',
  onChange = () => {},
  nameId = 'customCodeName',
}: InputPublicProps) => {
  return (
    <SelectCustomCodePaginate
      id={id}
      nameId={nameId}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCustomCode={onChange}
    />
  );
};

FiltersForm.DestinationCountries = ({
  selectedFilter = ``,
  placeholder = 'Destination Countries',
  defaultValue,
  sort = 'name',
  id = 'destinationCountries',
  header = 'Destination Countries',
  onChange = () => {},
  readonly,
  nameId = 'portName',
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      selectedSort={sort}
      required={false}
      isMulti={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
    />
  );
};

FiltersForm.EquipmentType = ({
  selectedFilter = ``,
  placeholder = 'Equipment Type',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'equipmentType',
  header = 'Equipment Type',
  onChange = () => {},
  nameId = 'equipmentType',
}: InputPublicProps) => {
  return (
    <SelectEquipmentTypePaginate
      id={id}
      nameId={nameId}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeEquipmentType={onChange}
    />
  );
};

FiltersForm.ContactPaymentMethod = ({
  selectedFilter = ``,
  placeholder = 'Select Port',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'contactPaymentMethodId',
  header = 'Contact Payment Method',
  onChange = () => {},
  readonly,
  nameId = 'contactPaymentMethodName',
}: InputPublicProps) => {
  return (
    <SelectContactPaymentMethodPaginate
      id={id}
      nameId={nameId}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContactPaymentMethod={onChange}
    />
  );
};

FiltersForm.CurrencySelect = ({
  selectedFilter = ``,
  placeholder = 'Select Currency',
  required = true,
  defaultValue,
  sort = 'currencyId',
  id = 'currencyId',
  header = 'Currency',
  onChange = () => {},
  nameId = 'currencyIdName',
  isClearable = false,
  disabled,
}: InputPublicProps) => {
  return (
    <SelectCurrencyPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCurrency={onChange}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};

FiltersForm.Division = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = false,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = '__divisionIdName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isDisabled={disabled}
    />
  );
};

FiltersForm.DocumentTemplateType = ({
  onChange,
  options,
  name = 'documentTemplateName',
  id = 'documentTemplateId',
  placeholder = 'Select template type',
  required = false,
  disabled = false,
  header = 'Template type',
  defaultValue,
  readonly,
  sort,
  selectedFilter,
}: InputPublicProps) => {
  return (
    <SelectDocumentTemplatePaginate
      id={id}
      nameId={name}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeDocumentTemplate={onChange}
      isDisabled={disabled}
    />
  );
};

FiltersForm.User = ({
  selectedFilter = ``,
  placeholder = 'Select created by',
  required = false,
  defaultValue,
  sort = 'userName',
  id = 'userId',
  header = 'Created by',
  onChange = () => {},
  readonly,
  nameId = 'userName',
  disabled = false,
}: InputPublicProps) => {
  return (
    <SelectUserPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeUser={onChange}
      isDisabled={disabled}
    />
  );
};

FiltersForm.AttachmentType = ({
  onChange,
  options,
  name = 'attachmentType',
  id = 'attachmentType',
  placeholder = 'Select file type',
  required = false,
  disabled = false,
  header = 'File type',
  defaultValue,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

FiltersForm.OrderType = ({
  onChange,
  options,
  name = 'orderType',
  id = 'orderType',
  placeholder = 'Select order type',
  required = false,
  disabled = false,
  header = 'Order type',
  defaultValue,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

FiltersForm.StatusStage = ({
  onChange,
  options,
  name = 'statusStage',
  id = 'statusStage',
  placeholder = 'Select Status Stage',
  required = false,
  disabled = false,
  header = 'Status Stage',
  defaultValue,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

FiltersForm.List = ({
  name = 'tags',
  placeholder = 'Type a tag and press enter...',
  header = 'Tags',
  disabled = false,
  required = false,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      type={'tags'}
      placeholder={placeholder}
      header={header}
      disabled={disabled}
      required={required}
      onChange={onChange}
    />
  );
};

FiltersForm.WarehouseLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Warehouse Location',
  required = false,
  defaultValue,
  sort = '',
  id = 'warehouseLocationId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  nameId = '',
}: InputPublicProps) => {
  return (
    <SelectMovementLocationPaginate
      id={id}
      required={required}
      isMulti={false}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeLocation={onChange}
      isDisabled={disabled}
      nameId={nameId}
    />
  );
};
