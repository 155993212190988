import {
  Column,
  CommodityTrackingNumberDto,
  CreateCommodityTrackingNumberCommandValues,
  LinkDto,
  LinkResourceBaseDto,
  UpdateCommodityTrackingNumberCommandValues,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCommodityTrackingNumberRequest,
  createCommodityTrackingNumbersRequest,
  deleteCommodityTrackingNumberRequest,
  GetCommodityTrackingNumberParams,
  getCommodityTrackingNumberByTrackingNumberRequest,
  getCommodityTrackingNumberRequest,
  getCommodityTrackingNumbersListRequest,
  updateCommodityTrackingNumberRequest,
} from './commodityTrackingNumbers.service';

type CommodityTrackingNumbersStoreState = {
  links: LinkDto[];
  commodityTrackingNumberColumns: Column[];
};

export type CreateTrackingNumbersForCommodityParams = {
  commodityId: number;
  values: CreateCommodityTrackingNumberCommandValues[];
};

export const updateCommodityTrackingNumbersColumns = createEvent<Column[]>();
export const updateCommodityTrackingNumbersOrderColumns = createEvent<
  Column[]
>();

export const getCommodityTrackingNumbers = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCommodityTrackingNumbersListRequest(currentOrganization, params);
  },
);

export const createCommodityTrackingNumber = createEffect(
  (commodityTrackingNumberData: CommodityTrackingNumberDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createCommodityTrackingNumberCommand: CreateCommodityTrackingNumberCommandValues = {
      ...commodityTrackingNumberData,
    };

    return createCommodityTrackingNumberRequest(
      currentOrganization!,
      createCommodityTrackingNumberCommand,
    );
  },
);

export const getCommodityTrackingNumber = createEffect(
  (commodityTrackingNumberParams: GetCommodityTrackingNumberParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCommodityTrackingNumberRequest(
      currentOrganization as LinkResourceBaseDto,
      commodityTrackingNumberParams,
    );
  },
);

export const getCommodityTrackingNumberByTrackingNumber = createEffect(
  (commodityTrackingNumberParams: GetCommodityTrackingNumberParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCommodityTrackingNumberByTrackingNumberRequest(
      currentOrganization as LinkResourceBaseDto,
      commodityTrackingNumberParams,
    );
  },
);

export const updateCommodityTrackingNumber = createEffect(
  (commodityTrackingNumber: CommodityTrackingNumberDto) => {
    const updateCommodityTrackingNumberCommand: UpdateCommodityTrackingNumberCommandValues = {
      ...commodityTrackingNumber,
    };
    return updateCommodityTrackingNumberRequest(
      commodityTrackingNumber,
      updateCommodityTrackingNumberCommand,
    );
  },
);

export const deleteCommodityTrackingNumber = createEffect(
  (commodityTrackingNumber: CommodityTrackingNumberDto) => {
    return deleteCommodityTrackingNumberRequest(commodityTrackingNumber);
  },
);

export const createTrackingNumbersForCommodity = createEffect(
  (params: CreateTrackingNumbersForCommodityParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return createCommodityTrackingNumbersRequest(
      currentOrganization as LinkResourceBaseDto,
      params,
    );
  },
);

const defaultState: CommodityTrackingNumbersStoreState = {
  links: [],
  commodityTrackingNumberColumns: [
    { name: 'trackingNumber', visible: true, title: 'Tracking Number' },
    { name: 'trackingNumberType', visible: true, title: 'Tracking Type' },
    { name: 'isPrimary', visible: true, title: 'Primary', type: 'checkBox' },
    { name: 'syncOrderId', visible: true, title: 'Sync Order' },
  ],
};

export const commodityTrackingNumberStore: Store<CommodityTrackingNumbersStoreState> = createStore(
  defaultState,
).on(updateCommodityTrackingNumbersColumns, (state, payload) => {
  return { ...state, commodityTrackingNumberColumns: payload };
});

export const commodityTrackingNumberStoreForOrder: Store<CommodityTrackingNumbersStoreState> = createStore(
  defaultState,
).on(updateCommodityTrackingNumbersColumns, (state, payload) => {
  return { ...state, commodityTrackingNumberColumns: payload };
});
