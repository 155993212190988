export const handleServerError = (error: any) => {
  let errorMessage = 'Unknown server error';
  if (error.title) {
    errorMessage = error.title;
  } else {
    handledServerErrors.forEach((handledServerError) => {
      if (error.indexOf(handledServerError) !== -1) {
        errorMessage = error.substring(
          error.indexOf(handledServerError) +
            handledServerError.length +
            specificErrorSigns.length,
          error.indexOf(specificErrorEndSigns),
        );
      }
    });
  }
  return errorMessage;
};

const specificErrorSigns = ': ';
const specificErrorEndSigns = '\n';

const handledServerErrors = ['Exceptions.ValidationException'];
