import {
  UserGroupDto,
  LinkDto,
  LinkResourceBaseDto,
  UserGroupDtoPagedResult,
  CreateUserGroupCommandValues,
  UpdateUserGroupCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_USERGROUPS_LINK_KEY = 'get-usergroups';
export const CREATE_USERGROUP_LINK_KEY = 'create-usergroup';
export const GET_USERGROUP_LINK_KEY = 'get-usergroup';
export const UPDATE_USERGROUP_LINK_KEY = 'update-usergroup';
export const DELETE_USERGROUP_LINK_KEY = 'delete-usergroup';

export const USERGROUP_ENTITY_NAME = 'User Group';

export interface GetUserGroupParams {
  userGroupId: number;
}

export const getUserGroupRequest = async (
  resource: LinkResourceBaseDto | null,
  { userGroupId }: GetUserGroupParams,
): Promise<UserGroupDto> => {
  if (resource && userGroupId) {
    const getUserGroupLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_USERGROUP_LINK_KEY,
    );
    if (getUserGroupLink) {
      const result = await execLink(getUserGroupLink, { userGroupId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_USERGROUP_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getUserGroupsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<UserGroupDtoPagedResult> => {
  if (resource) {
    const getUserGroupsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_USERGROUPS_LINK_KEY,
    );
    if (getUserGroupsListLink) {
      const result = await execLink(getUserGroupsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_USERGROUPS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createUserGroupRequest = async (
  resource: LinkResourceBaseDto,
  userGroup: CreateUserGroupCommandValues,
) => {
  const createUserGroupLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_USERGROUP_LINK_KEY,
  );
  if (createUserGroupLink) {
    const result = await execLink(createUserGroupLink, userGroup);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_USERGROUP_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateUserGroupRequest = async (
  resource: LinkResourceBaseDto,
  userGroup: UpdateUserGroupCommandValues,
) => {
  const updateUserGroupLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_USERGROUP_LINK_KEY,
  );
  if (updateUserGroupLink) {
    const result = await execLink(updateUserGroupLink, userGroup);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_USERGROUP_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteUserGroupRequest = async (userGroup: UserGroupDto) => {
  const deleteUserGroupLink = userGroup.links?.find(
    (x: LinkDto) => x.rel === DELETE_USERGROUP_LINK_KEY,
  );
  if (deleteUserGroupLink) {
    const result = await execLink(deleteUserGroupLink, userGroup);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_USERGROUP_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
