import { ReactElement } from 'react';
import { userHas } from '../../../auth/auth.store';
import { LinkDto } from '../../../../models/data.models';

export const getAction = (
  linkKey: string,
  label: string,
  icon: JSX.Element,
  onAction: () => void | Promise<void>,
  resultLinks: LinkDto[],
): IAction | null => {
  if (userHas(linkKey, resultLinks)) {
    return {
      name: linkKey,
      label,
      onAction,
      icon,
    };
  }
  return null;
};

export interface IAction {
  name: string;
  label: string;
  icon?: ReactElement;
  onAction: (data: any) => void | Promise<void>;
}
