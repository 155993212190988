import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { FactoringCompanyEdit } from './factoringCompany-edit.component';

export type FactoringCompanyDialogProperties = {
  contactId: number;
  title: string;
  disableDots?: boolean;
};

export class FactoringCompanyDialog extends Dialog<FactoringCompanyDialogProperties> {
  constructor(props: FactoringCompanyDialogProperties) {
    super(props);
    this.onFactoringCompanySaved = this.onFactoringCompanySaved.bind(this);
  }

  onFactoringCompanySaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId, disableDots } = this
      .props as FactoringCompanyDialogProperties;
    return (
      <FactoringCompanyEdit
        contactId={contactId}
        onFactoringCompanyCreated={this.onFactoringCompanySaved}
        onFactoringCompanyUpdated={this.onFactoringCompanySaved}
        onCancel={this.close.bind(this)}
        disableDots={disableDots}
      />
    );
  }
}
