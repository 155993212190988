import { NumericFormat } from 'react-number-format';

export const NumberInputComponent = ({
  field,
  options,
  disabled,
  className,
  placeholder,
  onChange,
  onClick,
}) => {
  const handleChange = (e) => {
    // Convert value to number if possible
    const numericValue = parseFloat(e.target.value);

    // Call the passed onChange handler with the numeric value
    if (onChange) {
      onChange(numericValue);
    }
  };

  return (
    <NumericFormat
      {...field}
      disabled={disabled}
      allowLeadingZeros
      fixedDecimalScale={options?.fixedDecimalScale ?? false}
      displayType={'input'}
      // valueIsNumericString={true}
      allowNegative={options?.allowNegative ?? true}
      allowedDecimalSeparators={['.', ',']}
      decimalScale={options?.decimalScale ?? 2}
      thousandSeparator={''}
      className={className}
      placeholder={placeholder}
      onChange={handleChange}
      onClick={onClick}
    />
  );
};
