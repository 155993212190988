import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button } from '../../common/components/button/button.component';
import { WarehouseZoneForm } from './warehouseZone.form';
import {
  ContactDto,
  DivisionDto,
  WarehouseZoneDto,
} from '../../../models/data.models';
import {
  createWarehouseZoneFx,
  getWarehouseZoneFx,
  updateWarehouseZoneFx,
} from '../warehouseZones.store';
import { Panel } from '../../common/components/panel/panel.component';
import { UPDATE_WAREHOUSEZONE_LINK_KEY } from '../warehouseZones.service';
import { userHas } from '../../auth/auth.store';

export type WarehouseZoneEditProps = {
  warehouseZoneId?: number | null;
  onWarehouseZoneCreated?: (warehouseZone: WarehouseZoneDto) => void;
  onWarehouseZoneUpdated?: (warehouseZone: WarehouseZoneDto) => void;
  onWarehouseZoneLoaded?: (warehouseZone: WarehouseZoneDto) => void;
  onCancel?: () => void;
};

const initialState: WarehouseZoneDto = {
  warehouseZoneId: 0,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  customerId: null,
  description: null,
  divisionId: null,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: '',
  organizationId: 0,
  parentZoneId: null,
  customerName: '',
  divisionName: '',
  links: [],
};

const warehouseZoneSchema = Yup.object().shape({
  name: Yup.string().required("Can't be blank").nullable(true),
});

export const WarehouseZoneEdit = ({
  warehouseZoneId,
  onWarehouseZoneLoaded = () => {},
  onWarehouseZoneCreated = () => {},
  onWarehouseZoneUpdated = () => {},
  onCancel = () => {},
}: WarehouseZoneEditProps) => {
  const isCreateMode = !warehouseZoneId || warehouseZoneId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [warehouseZone, setWarehouseZone] = useState<WarehouseZoneDto | null>(
    null,
  );

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (warehouseZoneId) {
      getWarehouseZoneFx({ warehouseZoneId }).then(
        (warehouseZoneDto: WarehouseZoneDto) => {
          setWarehouseZone(warehouseZoneDto);
          setIsLoading(false);
          onWarehouseZoneLoaded(warehouseZoneDto);
        },
        () => {},
      );
    } else {
      throw new Error('WarehouseZone keys were not provided');
    }
  }, [warehouseZoneId]);

  const onSubmit = (data: WarehouseZoneDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createWarehouseZoneFx(data)
        .then((result) => {
          onWarehouseZoneCreated(result.data);
        })
        .finally(() => setIsSending(false));
    } else {
      updateWarehouseZoneFx(data)
        .then((result) => {
          onWarehouseZoneUpdated(result.data);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <WarehouseZoneForm
        id={'warehouseZone-form'}
        initialValues={warehouseZone || initialState}
        onSubmit={onSubmit}
        validationSchema={warehouseZoneSchema}
      >
        {`${isCreateMode ? 'Add New' : 'Update'} Warehouse Zone`}
        <div className="row">
          <div className="col-4">
            <WarehouseZoneForm.CustomerId
              defaultValue={
                warehouseZone?.customerId && warehouseZone?.customerName
                  ? {
                      contactId: warehouseZone.customerId,
                      name: warehouseZone.customerName,
                    }
                  : null
              }
            />
            <WarehouseZoneForm.Description />
            <WarehouseZoneForm.DivisionId
              defaultValue={
                warehouseZone?.divisionId && warehouseZone?.divisionName
                  ? {
                      divisionId: warehouseZone.divisionId,
                      divisionName: warehouseZone.divisionName,
                    }
                  : null
              }
            />
            <WarehouseZoneForm.Name />
            <WarehouseZoneForm.ParentZoneId
              options={[warehouseZone?.warehouseZoneId]}
              defaultValue={
                warehouseZone?.parentZoneId &&
                warehouseZone?.parentWarehouseZoneName
                  ? {
                      warehouseZoneId: warehouseZone.parentZoneId,
                      name: warehouseZone.parentWarehouseZoneName,
                    }
                  : null
              }
            />
          </div>
        </div>
        <div className="d-flex justify-content-end row">
          {(userHas(UPDATE_WAREHOUSEZONE_LINK_KEY, warehouseZone?.links) ||
            isCreateMode) && (
            <div className="col-3">
              <Button
                name="save-warehouseZone"
                type="submit"
                form={'warehouseZone-form'}
                color="primary"
                className="btn-block"
                disabled={isSending}
                isSending={isSending}
              >
                {`${isCreateMode ? 'Save' : 'Update'} Warehouse Zone`}
              </Button>
            </div>
          )}
          <div className="col-3">
            <Button
              type="button"
              color="primary"
              onClick={onCancel}
              className="w-100 btn-secondary"
              disabled={isSending}
            >
              Close
            </Button>
          </div>
        </div>
      </WarehouseZoneForm>
    </Panel>
  );
};
