import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { YamlField } from '../../common/components/input/ace-input.component';

export const AppModuleForm = ({
  id = 'appModuleForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

AppModuleForm.AppModuleId = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'appModuleId'}
      id={'appModuleId'}
      label={'App Module Id'}
      placeholder={'App Module Id'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
AppModuleForm.AppManifestId = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'appManifestId'}
      id={'appManifestId'}
      label={'App Manifest Id'}
      placeholder={'App Manifest Id'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
AppModuleForm.AppModuleDocument = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'appModuleDocument'}
      id={'appModuleDocument'}
      label={'App Module Document'}
      placeholder={'App Module Document'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
AppModuleForm.AppModuleYamlDocument = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <YamlField
      name={'appModuleYamlDocument'}
      id={'appModuleYamlDocument'}
      label={'App Module Yaml'}
      placeholder={'App Module Yaml'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={onChange}
      size={size}
    />
  );
};
AppModuleForm.Name = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      id={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
