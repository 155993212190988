import { get } from 'lodash';
import { queryGraphQlFx } from '../../../../graphql/graphql.store';

// init store for configs

export const fromConfig = async (
  name: string,
  path?: string,
  variables?: any,
) => {
  const command = `query ($organizationId: Int!) {
    organizationConfigs(organizationId: $organizationId, filter: "configName: \\"${name}\\"") {
       items{
          configName
          customValues
       }
    }
  }`;

  const vars = {
    organizationId: variables?.organizationId || -1,
  };

  const res = await queryGraphQlFx({
    query: command,
    variables: vars,
  });
  if (!path)
    return {
      configName: res?.organizationConfigs?.items?.[0].configName,
      ...res?.organizationConfigs?.items?.[0].customValues,
    };

  return {
    configName: res?.organizationConfigs?.items?.[0].configName,
    [path]: get(res?.organizationConfigs?.items?.[0].customValues, path),
  };
};
