import { createEffect, createEvent, createStore, Store } from 'effector';
import { Column, LinkDto, LinkResourceBaseDto } from '../../models/data.models';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  GetContactPaymentMethodParams,
  getContactPaymentMethodRequest,
  getContactPaymentMethodsListRequest,
} from './contactPaymentMethod.service';

export const updateContactPaymentMethodsColumns = createEvent<Column[]>();

type ContactPaymentMethodStoreState = {
  links: LinkDto[];
  contactColumns: Column[];
};

export const getContactPaymentMethods = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContactPaymentMethodsListRequest(currentOrganization, params);
  },
);

export const getContactPaymentMethod = createEffect(
  (contactPaymentMethodParams: GetContactPaymentMethodParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContactPaymentMethodRequest(
      currentOrganization as LinkResourceBaseDto,
      contactPaymentMethodParams,
    );
  },
);

const defaultState: ContactPaymentMethodStoreState = {
  links: [],
  contactColumns: [
    {
      name: 'contactPaymentMethodId',
      visible: true,
      title: 'Payment method Id',
      showFilter: false,
      sortName: 'contactPaymentMethodId',
    },
    {
      name: 'customerId',
      visible: true,
      title: 'Customer Id',
      showFilter: false,
      sortName: 'customerId',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      showFilter: false,
      sortName: 'description',
    },
    {
      name: 'expDate',
      visible: true,
      title: 'Exp Date',
      showFilter: false,
      sortName: 'expDate',
    },
    {
      name: 'paymentType',
      visible: true,
      title: 'Payment Type',
      showFilter: false,
    },
  ],
};

export const contactPaymentMethodStore: Store<ContactPaymentMethodStoreState> = createStore(
  defaultState,
).on(updateContactPaymentMethodsColumns, (state, payload) => {
  return { ...state, contactColumns: payload };
});
