import React from 'react';

import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { Form, FormProps } from '../../common/components/form/form.component';
import { Checkbox } from '../../common/components/checkbox/checkbox.component';
import { Link } from 'react-router-dom';

export const LoginForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

LoginForm.Username = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'username'}
      label={'Username or Email'}
      placeholder={'Username or Email'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
      tabIndex={1}
    />
  );
};

LoginForm.Password = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'password'}
      placeholder="Password"
      label={'Password'}
      type={'password'}
      required={true}
      className={className}
      style={style}
      size={size}
      secondLabel={() => (
        <Link to="/forgot-password" data-cy="link-forgot-password">
          Forgot Password?
        </Link>
      )}
      tabIndex={2}
    />
  );
};

LoginForm.RememberMe = ({ className, style }: InputPublicProps) => {
  return (
    <Checkbox
      label={'Remember Me'}
      name={'rememberMe'}
      className={className}
      style={style}
    />
  );
};
