import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContactAddressScreen } from './screens/contactAddress.screen';
import { ContactAddressesListScreen } from './screens/contactAddresses-list.screen';

export const CONTACTS_ADDRESS_LIST_PATH = '/contact-address';
export const CONTACTS_ADDRESS_EDIT_PATH = '/contact-address/:contactAddressId';
export const CONTACTS_ADDRESS_CREATE_PATH = '/contact-address/0';

export const ContactAddressRoute = (props: RouteProps) => {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ContactAddressesListScreen
              pageTitle="Contact Addresses"
              group=""
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ContactAddressScreen pageTitle="Add New Contact Address" />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactAddressId'}
        element={
          <PrivateRoute>
            <ContactAddressScreen pageTitle="Update Contact Address" />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
