import React, { useEffect, useRef, useState } from 'react';
import {
  ContactDto,
  ContactType,
  EntityTypes,
  OrderDto,
  OrderDtoPagedResult,
  OrderEntityDto,
  OrderTypes,
} from '../../../models/data.models';
import { ConsolidatedShipmentStateProperties } from './consolidatedShipment.dialog';
import { OrderDefaultValues } from '../../common/DefaultValues';
import {
  OrderDtoForListPagedResult,
  OrderEditFormDto,
} from '../../../models/custom.models';
import * as Yup from 'yup';
import { authStore } from '../../auth/auth.store';
import { useStore } from 'effector-react';
import { createOrder, getOrders } from '../../orders/orders.store';
import { OrderForm } from '../../orders/components/order.form';
import { FormContext } from '../../common/components/form/form.component';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { TotalItems } from '../../common/components/totalItems/totalItems';
import { Button } from '../../common/components/button/button.component';
import { HiX } from 'react-icons/hi';
import { AiOutlineCheck } from 'react-icons/ai';
import { addMessage } from '../../common/messages.store';
import { getContact } from '../../contacts/contacts.store';
import { v4 } from 'uuid';
import { getOrganizationConfigsFx } from '../../organizationConfigs/organizationConfigs.store';

const destinationCountries = (codes, names) => {
  const countryCodes = codes.split(';');
  const countryNames = names.split(';');

  return countryCodes.map((countryCode, idx) => {
    return {
      countryCode,
      name: countryNames[idx],
    };
  });
};

export type ConsolidatedShipmentCreateProps = {
  isDialog?: boolean | null;
  onConsolidatedShipmentCreated?: (
    orderId: number,
    orderType: OrderTypes,
  ) => void;
  onCancel?: () => void;
  defaultState?: ConsolidatedShipmentStateProperties | null;
  orderType: OrderTypes;
};

const getInitialStateOrderEntities = (
  contactType: ContactType,
  entityType: EntityTypes,
) => {
  const initialStateOrderCarrier: OrderEntityDto = {
    orderEntityId: 0,
    contactAddressId: null,
    contactAddressName: null,
    contactId: null,
    contactName: null,
    contactType: contactType,
    created: null,
    createdBy: null,
    date: null,
    notes: '',
    lastModified: null,
    lastModifiedBy: null,
    orderEntitySequence: 0,
    orderId: 0,
    contactCityName: null,
    contactStateCode: null,
    entityType: entityType,
    customValues: {},
    links: [],
  };
  return initialStateOrderCarrier;
};

const getInitialState = () => {
  const initialState: OrderEditFormDto & OrderDto = {
    createdByUserName: '',
    lastModifiedByUserName: '',
    carriers: null,
    totalPcsCrt: 0,
    weighTotal: 0,
    volumeTotal: 0,
    orderEntityCarriers: [
      getInitialStateOrderEntities(ContactType.Carrier, EntityTypes.Carrier),
    ],
    orderType: OrderTypes.AirShipmentOrder,
    orderId: null,
    billToContactId: OrderDefaultValues.billToContactId,
    carrierContactId: OrderDefaultValues.carrierContactId,
    orderStatusId: OrderDefaultValues.orderStatusId,
    created: new Date(),
    createdBy: '',
    employeeContactId: OrderDefaultValues.employeeContactId,
    lastModified: new Date(),
    lastModifiedBy: '',
    orderNumber: '',
    organizationId: null,
    salespersonContactId: OrderDefaultValues.salespersonContactId,
    commodities: [],
    charges: [],
    trackingEvents: [],
    divisionId: OrderDefaultValues.divisionId,
    orderStatus: OrderDefaultValues.orderStatus,
    orderEntities: [],
    links: [],
    customValues: {},
    shipper: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Shipper,
    ),
    ultimateConsignee: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.UltimateConsignee,
    ),
    consignee: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Consignee,
    ),
    notifyParty: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.NotifyParty,
    ),
    intermediate: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.Intermediate,
    ),
    forwardingAgent: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.ForwardingAgent,
    ),
    destinationAgent: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.DestinationAgent,
    ),
    deliveringCarrier: getInitialStateOrderEntities(
      ContactType.Carrier,
      EntityTypes.DeliveryCarrier,
    ),
    pickupFrom: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.PickupFrom,
    ),
    deliverTo: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.DeliverTo,
    ),
    receivedBy: getInitialStateOrderEntities(
      ContactType.Customer,
      EntityTypes.ReceivedBy,
    ),
  };
  return initialState;
};

//validation
let orderSchema = Yup.object().shape({
  billToContactId: Yup.string().notRequired().nullable(true),
});

type CarriersId = {
  carriersId: number[];
};

const transformOrder = (data: OrderDto & CarriersId): OrderDto => {
  const order = { ...data };
  order.carriers =
    data?.carriersId?.map((id) => {
      return { contactId: id };
    }) ?? order.carriers;

  if (data.orderEntities?.length > 0) {
    order.orderEntities[0] = {
      ...(data as any).finalMileCarrier,
      ...{ entityType: EntityTypes.DeliveryCarrier },
    };
  } else {
    order.orderEntities = [
      {
        ...(data as any).finalMileCarrier,
        ...{ entityType: EntityTypes.DeliveryCarrier },
      },
    ];
  }

  if (order.orderType === OrderTypes.AirShipmentOrder) {
    const customValues: any = {
      ...data.customValues,
      arrivePortId: data?.customValues?.arrivePortId,
      arrivePortIdName: data?.customValues?.arrivePortIdName,
      departureDate: data?.customValues?.departureDate,
    };

    delete customValues.portOfUnloading;
    delete customValues.portOfUnloadingName;
    delete customValues.arrivalDate;
    order.customValues = customValues;
  } else if (order.orderType === OrderTypes.OceanShipmentOrder) {
    const customValues: any = {
      ...data.customValues,
      portOfUnloading: data?.customValues?.arrivePortId,
      portOfUnloadingName: data?.customValues?.arrivePortIdName,
      arrivalDate: data?.customValues?.departureDate,
    };

    delete customValues.arrivePortId;
    delete customValues.arrivePortIdName;
    delete customValues.departureDate;
    order.customValues = customValues;
  }

  return order;
};

const applyLocationState = (
  data: OrderDto,
  state: ConsolidatedShipmentStateProperties,
  userDivisionId: number,
): OrderDto => {
  const order = {
    ...data,
    orderType: state?.orderType ?? data.orderType,
    divisionId: state?.divisionId ?? data.divisionId ?? userDivisionId,
    currentPallet: state?.currentPallet,
    finalMileCarrier: {},
    customValues: {
      ...data.customValues,
      destinationCountries:
        state?.countries ?? data.customValues?.destinationCountries,
      modeOfTransportationId:
        state?.modeOfTransportationId ??
        data.customValues?.modeOfTransportationId,
    },
  };

  if (state?.finalMileCarrierId) {
    getContact({ contactId: state.finalMileCarrierId })
      .then((result: ContactDto) => {
        if (result) {
          order.finalMileCarrier = {
            contactId: result?.contactId,
            contactName: result?.name,
            contactType: result?.contactType,
          };
        }
      })
      .catch((error) => {
        addMessage({
          message: error?.Message || error,
          id: v4(),
          type: 'danger',
        });
      });
  }

  return order;
};

export const ConsolidatedShipmentCreate = ({
  defaultState,
  isDialog,
  onConsolidatedShipmentCreated,
  onCancel = () => {},
  orderType,
}: ConsolidatedShipmentCreateProps) => {
  const { user: currentUser } = authStore.getState();
  const orderFormRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    parcelShipments,
    setParcelShipments,
  ] = useState<OrderDtoPagedResult | null>(null);

  const { user } = useStore(authStore);

  const [initialValues, setInitialValues] = useState<OrderEditFormDto | null>(
    applyLocationState(getInitialState(), defaultState, user.divisionId),
  );

  const [filter, setFilter] = useState(
    `OrderType:${OrderTypes.ParcelShipment} AND OrderStatus.OrderStatusName: "Ready To Ship"`,
  );
  const [portPart, setPortPart] = useState('');
  const [modePart, setModePart] = useState('');
  const [carrierPart, setCarrierPart] = useState('');
  const [countriesPart, setCountriesPart] = useState('');
  const [divisionPart, setDivisionPart] = useState('');
  const [
    shipmentOrganizationDefaults,
    setShipmentOrganizationDefaults,
  ] = useState(null);

  const getOrganizationDefaults = (orderType: OrderTypes) => {
    getOrganizationConfigsFx({
      filter: `configName:${orderType}.consolidated.defaults`,
      limit: 1,
    }).then((data) => {
      if (data && data.items && data.items.length > 0)
        setShipmentOrganizationDefaults(data.items[0].customValues);
    });
  };

  const changeCountriesFilter = (sortedCountries) => {
    if (sortedCountries) {
      const filterPart = sortedCountries
        .split(';')
        .join(' OR OrderEntities.ContactAddress.CountryCode:');
      const part = `  AND (OrderEntities.EntityType: ${EntityTypes.Consignee} AND OrderEntities.ContactAddress.CountryCode:${filterPart})`;
      setFilter((filter) => {
        filter = filter.replace(countriesPart, '');
        filter += part;
        return filter;
      });
      setCountriesPart(part);
    } else {
      setFilter((filter) => {
        filter = filter.replace(countriesPart, '');
        return filter;
      });
      setCountriesPart('');
    }
  };

  const changePortFilter = (value) => {
    if (value) {
      setFilter((filter) => {
        filter = filter.replace(portPart, '');
        filter += ` AND (OrderEntities.EntityType: ${EntityTypes.Carrier} AND OrderEntities.CustomValues.portId:${value.portId})`;
        return filter;
      });
      setPortPart(
        ` AND (OrderEntities.EntityType: ${EntityTypes.Carrier} AND OrderEntities.CustomValues.portId:${value.portId})`,
      );
    } else {
      setFilter((filter) => {
        filter = filter.replace(portPart, '');
        return filter;
      });
      setPortPart('');
    }
  };

  const changeCarrierFilter = (value) => {
    if (value) {
      const part = ` AND (OrderEntities.ContactId:${value.contactId} AND OrderEntities.EntityType:${EntityTypes.Carrier})`;
      setFilter((filter) => {
        filter = filter.replace(carrierPart, '');
        filter = filter += part;
        return filter;
      });
      setCarrierPart(part);
    } else {
      setFilter((filter) => {
        filter = filter.replace(carrierPart, '');
        return filter;
      });
      setCarrierPart('');
    }
  };

  const changeModeFilter = (value) => {
    if (value) {
      const filterPart = ` AND CustomValues.modeOfTransportationId:${value.modeOfTransportationId}`;
      setFilter((filter) => {
        filter = filter.replace(modePart, '');
        filter += filterPart;
        return filter;
      });
      setModePart(filterPart);
    } else {
      setFilter((filter) => {
        filter = filter.replace(modePart, '');
        return filter;
      });
      setModePart('');
    }
  };

  const changeDivisionFilter = (value) => {
    if (value) {
      const filterPart = ` AND DivisionId:${value.divisionId}`;
      setFilter((filter) => {
        filter = filter.replace(divisionPart, '');
        filter += filterPart;
        return filter;
      });
      setDivisionPart(filterPart);
    } else {
      setFilter((filter) => {
        filter = filter.replace(divisionPart, '');
        return filter;
      });
      setDivisionPart('');
    }
  };

  useEffect(() => {
    if (initialValues) {
      const countries = initialValues?.customValues?.destinationCountries;
      if (countries) changeCountriesFilter(countries);
      const modeOfTransportationId =
        initialValues?.customValues?.modeOfTransportationId;
      if (modeOfTransportationId) changeModeFilter({ modeOfTransportationId });
      const divisionId = initialValues?.divisionId;
      if (divisionId) changeDivisionFilter({ divisionId });
    }
  }, [initialValues]);

  useEffect(() => {
    const contactId = defaultState?.finalMileCarrierId;
    if (contactId) changeCarrierFilter({ contactId });
  }, [defaultState]);

  useEffect(() => {
    if (shipmentOrganizationDefaults) {
      setInitialValues({
        ...initialValues,
        customValues: {
          ...initialValues.customValues,
          ...shipmentOrganizationDefaults,
        },
      });
    } else {
      getOrganizationDefaults(orderType);
    }
  }, [shipmentOrganizationDefaults]);

  const prevFilterRef = useRef(filter);
  const isInitialRender = useRef(true);

  const getParcelShipments = () => {
    getOrders({
      offset: 0,
      limit: 1,
      summary: true,
      filter: filter,
    })
      .then(
        (parcelShipmentsData) => {
          const parcelShipmentDtoResult: OrderDtoForListPagedResult = {
            summary: parcelShipmentsData.summary,
          };
          setParcelShipments(parcelShipmentDtoResult);
        },
        () => {},
      )
      .catch((error) => {
        addMessage({
          message: error.message || error,
          type: 'danger',
          id: v4(),
        });
      });
  };

  // Update the previous filter reference whenever the filter changes
  useEffect(() => {
    prevFilterRef.current = filter;
  }, [filter]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    setIsLoading(false);
    getParcelShipments();
  }, [filter, defaultState, user, initialValues]);

  useEffect(() => {
    // Check if the current filter is different from the previous one
    if (filter !== prevFilterRef.current) {
      setIsLoading(false);
      getParcelShipments();
    }
  }, [filter, defaultState, user, initialValues]);

  //submit form
  const onSubmit = async (data: OrderDto & CarriersId) => {
    setIsSending(true);
    const order = transformOrder(data);

    try {
      const result = await createOrder(order);
      if (result) {
        onConsolidatedShipmentCreated(result.orderId, orderType);
        onCancel();
      }
    } catch (error) {
      addMessage({
        message: error.message || error,
        type: 'danger',
        id: v4(),
      });
    } finally {
      setIsSending(false);
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const actionButtons = [
    <Button
      color={'secondary'}
      className="mr-4"
      outline
      onClick={onCancel}
      disabled={isSending}
    >
      <div className={'d-flex align-items-center'}>
        <HiX size={16} className={'mr-2'} />
        Cancel
      </div>
    </Button>,
    <Button
      color={'primary'}
      onClick={() => {}}
      type="submit"
      form="order-Form"
      disabled={isSending}
    >
      <div className={'d-flex align-items-center'}>
        <AiOutlineCheck className={'mr-2'} size={16} />
        Create Shipment
      </div>
    </Button>,
  ];

  return (
    <div className={'order-edit-form mx-3'}>
      {isDialog ? (
        <div className="row">
          <div className="col-4">
            <div className="row p-3 mt-2">
              <h3>{`New ${
                orderType === OrderTypes.AirShipmentOrder ? 'Air' : 'Ocean'
              } Consolidated Shipment`}</h3>
            </div>
          </div>
          <div className="col-8">
            <div className="row d-flex justify-content-end p-3">
              {actionButtons.map((x) => {
                return x;
              })}
            </div>
          </div>
        </div>
      ) : null}
      <OrderForm
        id={'order-Form'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={orderFormRef}
        validationSchema={orderSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <>
              <div className="d-flex flex-wrap box mb-5 px-5 pb-5">
                <div className={'row w-100 m-auto'}>
                  <div className="col-8">
                    <SelectCountryPaginate
                      readonly={false}
                      selectedSort={'name'}
                      required={false}
                      isMulti={true}
                      useContext={true}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      header={'Destination Countries'}
                      placeholder={'Destination Countries'}
                      selectedFilter={''}
                      selectedValue={
                        context.values?.customValues?.destinationCountries
                          ? context.values?.customValues
                              ?.destinationCountriesName
                            ? destinationCountries(
                                context.values.customValues
                                  .destinationCountries,
                                context.values.customValues
                                  .destinationCountriesName,
                              )
                            : context.values?.customValues?.destinationCountries
                          : []
                      }
                      onChangeCountry={(value) => {
                        const sortedCountries = value?.sort((a, b) =>
                          a.name.localeCompare(b.name),
                        );
                        context.setFieldValue(
                          'customValues.destinationCountries',
                          sortedCountries
                            ?.map((el) => el.countryCode)
                            .join(';'),
                        );
                        context.setFieldValue(
                          'customValues.destinationCountriesName',
                          sortedCountries?.map((el) => el.name).join(';'),
                        );
                        changeCountriesFilter(
                          sortedCountries
                            ?.map((el) => el.countryCode)
                            .join(';'),
                        );
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <OrderForm.Port
                      header={'Destination Port'}
                      id={'customValues.arrivePortId'}
                      nameId={'customValues.arrivePortIdName'}
                      defaultValue={
                        context.values?.customValues?.arrivePortId
                          ? {
                              portId:
                                context.values?.customValues?.arrivePortId,
                              name:
                                context.values?.customValues?.arrivePortIdName,
                            }
                          : null
                      }
                      onChange={changePortFilter}
                    />
                  </div>
                  <div className="col-4 carrier-contact-input">
                    <OrderForm.CarrierContactSelect
                      id={'finalMileCarrier.contactId'}
                      nameId={'finalMileCarrier.contactName'}
                      header={'Final Mile Carrier'}
                      contactTypes={[ContactType.Carrier]}
                      selectedFilter={`contactType: ${ContactType.Carrier}`}
                      multiple={false}
                      closeMenuOnSelect={true}
                      showContactType={false}
                      defaultValue={
                        context.values?.finalMileCarrier?.contactId
                          ? context.values?.finalMileCarrier?.contactName
                            ? {
                                contactId:
                                  context.values?.finalMileCarrier?.contactId,
                                name:
                                  context.values?.finalMileCarrier?.contactName,
                              }
                            : context.values?.finalMileCarrier?.contactId
                          : null
                      }
                      onChange={changeCarrierFilter}
                    />
                  </div>
                  <div className="col-4">
                    <OrderForm.Date
                      header={'Planned Shipping Date'}
                      nameId={'customValues.departureDate'}
                      defaultValue={context.values?.customValues?.departureDate}
                    />
                  </div>
                  <div className="col-4">
                    <OrderForm.Division
                      id={'divisionId'}
                      header={'Division'}
                      required={true}
                      defaultValue={
                        context.values?.divisionId != null
                          ? context.values?.divisionName != null
                            ? {
                                divisionId: context.values?.divisionId,
                                divisionName: context.values?.divisionName,
                              }
                            : context.values?.divisionId ||
                              currentUser.divisionId
                          : ''
                      }
                      onChange={changeDivisionFilter}
                    />
                  </div>
                </div>
              </div>
              <div className={'box px-6 py-4 mb-5'}>
                <TotalItems
                  title="Total Available to Load"
                  summary={parcelShipments?.summary}
                />
              </div>
            </>
          )}
        </FormContext.Consumer>
      </OrderForm>
    </div>
  );
};
