import React from 'react';
import { Button } from '../button/button.component';

export type ConfirmationProps = {
  className?: string;
  actionText?: string;
  onInvoiceConfirmed?: (isConfirmed: boolean) => void;
  onCancel?: () => void;
};

export const ConfirmationComponent = ({
  className,
  actionText,
  onInvoiceConfirmed,
  onCancel,
}: ConfirmationProps) => {
  return (
    <>
      <div className="mb-3">
        You have unsaved changes.
        <br />
        Do you want to save them before {actionText}?
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <Button
              onClick={() => {
                onInvoiceConfirmed(true);
              }}
              type="button"
              color="primary"
              className="btn-block ml-auto"
              name={'select'}
            >
              Yes
            </Button>
          </div>
          <div className="col">
            <Button
              onClick={onCancel}
              type="button"
              color="secondary"
              className="btn-block ml-auto"
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
