import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { VesselDto, VesselDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  vesselStore,
  getVesselsColumnsFx,
  getVesselsFx,
  deleteVesselFx,
  exportVesselsFx,
  importVesselsFx,
} from '../vessels.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_VESSEL_LINK_KEY,
  DELETE_VESSEL_LINK_KEY,
  IMPORT_VESSELS_LINK_KEY,
  EXPORT_VESSELS_LINK_KEY,
  GetVesselParams,
} from '../vessels.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { VesselDialog } from './vessel.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type VesselsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (vesselParams: GetVesselParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (vessel: VesselDto, vesselParams: GetVesselParams) => void;
};

export const VesselsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: VesselsListProps) => {
  const [vessels, setVessels] = useState<VesselDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    vesselColumns: columns,
    defaultVesselColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(vesselStore);

  useEffect(() => {
    getVesselsColumnsFx();
  }, []);

  const getVessels = useCallback(async () => {
    const vesselsData = await getVesselsFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(vesselsData));
    setVessels(vesselsData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: VesselDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_VESSELS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importVesselsFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getVessels();
          },
          result?.links,
        ),
        getAction(
          EXPORT_VESSELS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportVesselsFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getVessels],
  );

  useEffect(() => {
    getVessels();
  }, [getVessels]);

  const onDeleteVessel = useCallback(
    async (vessel: VesselDto) => {
      if (userHas(DELETE_VESSEL_LINK_KEY, vessels?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Vessel`,
            message: 'Are you sure you want to delete vessel?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteVesselFx(vessel);
            getVessels();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, vessels],
  );

  const onEditVessel = (vessel: VesselDto) => {
    if (vessel) goToDetails({ vesselId: vessel.vesselId });
  };

  const onVesselSelect = useCallback(
    async (vessel) => {
      const selectedVessel = await showDialog({
        dialog: VesselDialog,
        props: {
          vesselId: vessel.vesselId,
          title: 'Update Vessel',
        },
      });
      if (selectedVessel) {
        getVessels();
      }
    },
    [getVessels],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['vesselId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={vessels.items}
          onLimitChanged={onLimitChanged}
          total={vessels.totalCount}
          onDelete={onDeleteVessel}
          onSort={onSort}
          onEdit={onEditVessel}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onVesselSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {vessels?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Vessel"
            entityLinkKey={CREATE_VESSEL_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
