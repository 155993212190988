import { Dialog } from '../../common/components/dialog/dialog.component';
import { PaymentTermEdit } from './paymentTerm-edit.component';
import { PaymentTermDto } from '../../../models/data.models';

export type PaymentTermDialogProperties = {
  paymentTermId?: number | null;
  title: string;
};

export class PaymentTermDialog extends Dialog<PaymentTermDialogProperties> {
  constructor(props: PaymentTermDialogProperties) {
    super(props);
    this.onPaymentTermSaved = this.onPaymentTermSaved.bind(this);
  }

  onPaymentTermSaved = (paymentTerm: PaymentTermDto) => {
    this.close(paymentTerm);
  };

  renderContent(): any {
    const { paymentTermId } = this.props as PaymentTermDialogProperties;
    return (
      <PaymentTermEdit
        paymentTermId={paymentTermId}
        onPaymentTermCreated={this.onPaymentTermSaved}
        onPaymentTermUpdated={this.onPaymentTermSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
