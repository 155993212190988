import React, { CSSProperties, useRef } from 'react';

import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../../../common/components/filters/filters.component';
import { Pagination } from '../../../common/components/pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import { Column } from '../../../../models/data.models';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import { DocumentTemplateTypeBadge } from '../status-badge/documentTemplate-type-badge';
import { addMessage } from '../../messages.store';
import { v4 } from 'uuid';

export type GridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: any[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  disableDots?: boolean;
  isTab?: boolean;
  parentId?: number;
  addButtonRenderCondition?: boolean;
  setIds?: (selectedItem: any, toggleState: boolean, orderOfItem?: any) => void;
  isSelected?: (selectedValue: any) => boolean;
  isCheckBoxDisabled?: (value: any) => boolean | null;
  onLimitChange?: (limit: number) => void;
  handleNext?: () => void;
  handleBack?: () => void;
  showToolbar: boolean;
  onOpen?: (row: any) => void;
  setRegenerateIds?: (selectedItem: any) => void;
  isInRegenerateIds?: (selectedItem: any) => boolean;
};

export const OrderDocumentsGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onSort = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
  loading,
  showToolbar = true,
  setIds,
  isSelected,
  isCheckBoxDisabled,
  onLimitChange,
  onOpen = null,
  setRegenerateIds,
  isInRegenerateIds,
}: GridProps) => {
  const colByName: { [key: string]: any } = {};

  const rowsCheckboxRef = useRef<HTMLInputElement[]>([]);

  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col?.name === colName)?.sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };
  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  return (
    <div className={`grid ${className} expandable`} style={style}>
      {showToolbar ? (
        <div
          className={
            isDropDownList === true
              ? 'grid-toolbar d-flex flex-wrap px-3 justify-content-center'
              : 'grid-toolbar d-flex pl-3'
          }
        >
          {onSearch ? (
            <input
              type="search"
              className={
                (isDropDownList === true ? 'w-100 col-12 ' : ' ') +
                'form-control my-2'
              }
              placeholder="Search"
              value={decodeURIComponent(search)}
            />
          ) : null}
          {onFilter ? (
            <div
              className={
                isDropDownList === true
                  ? 'dropdown dropdown-columns my-3 px-auto mx-auto w-auto col-auto'
                  : 'dropdown dropdown-columns my-3 px-3 w-100'
              }
            >
              {columns.some((column) => column.showFilter) && (
                <span
                  className="dropdown-toggle pointer"
                  id="dropdownFilterButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BsFilter />
                  &nbsp;
                  <small className="align-middle pl-1 text-uppercase">
                    Filters
                  </small>
                  &nbsp;
                  <small className="align-middle text-primary">
                    {getReadableQueryString(filter, getFilteredRowsFilter())}
                  </small>
                </span>
              )}
              {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
                <div
                  className="dropdown-menu px-5"
                  aria-labelledby="dropdownFilterButton"
                >
                  <div>
                    <Filters
                      query={filter}
                      columns={getFilteredRowsFilter()}
                      onFilter={updateFilter}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {hideColumnsSelect ? null : (
            <div
              className={
                isDropDownList === true
                  ? 'dropdown dropdown-columns my-3 px-3 mx-auto pointer col-auto'
                  : 'dropdown dropdown-columns my-3 px-3 ml-auto pointer'
              }
            >
              <div
                className="dropdown-toggle"
                id="dropdownColumnsButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <BsListTask className="" />
                &nbsp;
                <small className="align-middle pl-1 text-uppercase">
                  Columns
                </small>
              </div>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownColumnsButton"
                style={{ width: 'max-content', maxWidth: 'unset' }}
              >
                {columns
                  .filter((item) => {
                    if (showAllStore) {
                      return true;
                    }
                    return rowKeys?.includes(item.name);
                  })
                  .map((col) => {
                    return (
                      <label key={col.name} className="dropdown-item pointer">
                        <input
                          type="checkbox"
                          //onChange={() => updateCols(col.name)}
                          defaultChecked={col.visible}
                        />{' '}
                        {col.title}
                      </label>
                    );
                  })}
              </div>
            </div>
          )}
          {actionBar && <div className={'mr-3 py-2'}>{actionBar}</div>}
        </div>
      ) : null}
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white">
          <table className="table">
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th
                        scope="col"
                        key={col.name}
                        style={{ textAlign: 'left' }}
                      >
                        <a
                          className={
                            col.sortName
                              ? 'link justify-content-start ml-3 text-table-footer'
                              : 'inactive-link justify-content-start ml-3 text-table-footer'
                          }
                          style={{ fontSize: '12px' }}
                          onClick={() =>
                            col.sortName ? updateSort(col.name) : null
                          }
                        >
                          {col.title}
                          {sort === col.name || sort === col.sortName ? (
                            <FaArrowDown className={'ml-2'} size={12} />
                          ) : null}
                          {sort === '-' + col.name ||
                          sort === '-' + col.sortName ? (
                            <FaArrowUp className={'ml-2'} size={12} />
                          ) : null}
                        </a>
                      </th>
                    );
                  })}
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="m-5 text-center">
                  <h3 className="text-muted mb-4">Loading...</h3>
                </div>
              ) : (
                data.map((row, rowIndex) => {
                  return (
                    <React.Fragment key={rowIndex}>
                      <tr
                        key={
                          rowKeys
                            ? rowKeys?.map((x) => row[x]).join('_')
                            : rowIndex
                        }
                      >
                        {Object.values(columns)
                          .filter((item) => {
                            if (showAllStore && item.visible) {
                              return true;
                            }
                            return item.visible && rowKeys?.includes(item.name);
                          })
                          .map((item) => {
                            return (
                              <td
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                className={`cursor-pointer ${
                                  item.name === 'checkBox'
                                    ? 'justify-content-start'
                                    : ''
                                }`}
                                style={
                                  item.name === 'currencySymbol'
                                    ? { textAlign: 'center' }
                                    : {
                                        width: `calc(100% / ${
                                          columns.filter(
                                            (column) => column.visible === true,
                                          ).length
                                        })`,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'start',
                                      }
                                }
                                onClick={
                                  onOpen
                                    ? () => onOpen(row)
                                    : () => {
                                        rowsCheckboxRef.current[
                                          rowIndex
                                        ]?.click();
                                      }
                                }
                              >
                                {typeof row[item.name] === 'boolean' ? (
                                  row[item.name] ? (
                                    <span className="ml-5 pl-5">&#x2713;</span>
                                  ) : (
                                    <></>
                                  )
                                ) : item.name == 'created' ||
                                  item.name == 'lastModified' ||
                                  item.name == 'updated' ? (
                                  <div className={'text-start pl-3'}>
                                    {getFormattedDate(
                                      (row[item.name] as string).split(',')[0],
                                      false,
                                    )}
                                  </div>
                                ) : item.type === 'checkBox' ? (
                                  item.name == 'documentTemplateId' ? (
                                    <span
                                      title={
                                        isCheckBoxDisabled(row[item.name]) ===
                                          true &&
                                        item.name === 'documentTemplateId'
                                          ? 'Please, select shipper and consignee to generate this document'
                                          : ''
                                      }
                                    >
                                      <label
                                        onClick={(e) => e.stopPropagation()}
                                        className="custom-control custom-checkbox d-inline-block mb-1 ml-4"
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input m-0"
                                          onChange={(event) => {
                                            if (
                                              !(
                                                row.documentTemplateType ===
                                                'ExportManifestExcelDocument'
                                              ) &&
                                              !(
                                                row.documentTemplateType ===
                                                'ExportManifestDocument'
                                              ) &&
                                              isCheckBoxDisabled &&
                                              isCheckBoxDisabled(row[item.name])
                                            ) {
                                              addMessage({
                                                message: `Please, select shipper and consignee to generate this document`,
                                                type: 'warning',
                                                id: v4(),
                                              });
                                            } else {
                                              setIds(
                                                row[item.name],
                                                event.target.checked,
                                                row,
                                              );
                                            }
                                          }}
                                          checked={isSelected(row[item.name])}
                                          ref={(el) =>
                                            (rowsCheckboxRef.current = [
                                              ...rowsCheckboxRef.current,
                                              el,
                                            ])
                                          }
                                        />
                                        <span className="custom-control-label"></span>
                                      </label>
                                      {onSelect ? (
                                        <u
                                          className="font-weight-bold cursor-pointer"
                                          onClick={() => {
                                            onSelect(
                                              row,
                                              rowKeys?.reduce(
                                                (keyObj, field) => {
                                                  return row[field];
                                                },
                                              ),
                                            );
                                          }}
                                        >
                                          {row[item?.labelToShow]}
                                        </u>
                                      ) : (
                                        <>{row[item?.labelToShow]}</>
                                      )}
                                    </span>
                                  ) : item.name === 'regenerateOnOrderChange' &&
                                    setRegenerateIds &&
                                    isInRegenerateIds ? (
                                    <span>
                                      <label
                                        onClick={(e) => e.stopPropagation()}
                                        className="custom-control custom-checkbox d-inline-block mb-1 ml-4"
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input m-0"
                                          onChange={() => {
                                            setRegenerateIds(
                                              row['documentTemplateId'],
                                            );
                                          }}
                                          disabled={
                                            !isSelected(
                                              row['documentTemplateId'],
                                            )
                                          }
                                          checked={isInRegenerateIds(
                                            row['documentTemplateId'],
                                          )}
                                        />
                                        <span className="custom-control-label"></span>
                                      </label>
                                    </span>
                                  ) : (
                                    <></>
                                  )
                                ) : item.name ==
                                  'documentTemplate.documentTemplateType' ? (
                                  <div className="d-flex">
                                    <div className="align-self-start pl-3">
                                      <DocumentTemplateTypeBadge
                                        documentType={
                                          row?.orderDocumentName ?? row?.fileUri
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      'text-start ml-3 table-text-documents'
                                    }
                                  >
                                    {row[item.name]}
                                  </div>
                                )}
                              </td>
                            );
                          })}
                        <td style={{ textAlign: 'center' }}>
                          <div className="dropdown">
                            <BsThreeDotsVertical
                              className="dropdown-toggle dropdown-dots-vertical pointer"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            />
                            <div
                              className="dropdown-menu dropdown-menu-grid"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {onDelete && (
                                <span
                                  title={
                                    row.charges?.length > 0
                                      ? 'Quote contains charges'
                                      : null
                                  }
                                >
                                  <a
                                    role="button"
                                    className="dropdown-item"
                                    onClick={() => {
                                      onDelete(row);
                                    }}
                                    style={
                                      row.charges?.length > 0
                                        ? {
                                            pointerEvents: 'none',
                                            color: 'gray',
                                          }
                                        : null
                                    }
                                  >
                                    Delete
                                  </a>
                                </span>
                              )}
                              {onEdit && (
                                <a
                                  role="button"
                                  className="dropdown-item"
                                  onClick={() => {
                                    onEdit(row);
                                  }}
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }} />
                      </tr>
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > limit && showPagination === true ? (
        <div className="d-flex flex-row justify-content-between grid-footer">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
