import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { LeadEdit } from './lead-edit.component';

export type LeadDialogProperties = {
  contactId: number;
  title: string;
  disableDots?: boolean;
};

export class LeadDialog extends Dialog<LeadDialogProperties> {
  constructor(props: LeadDialogProperties) {
    super(props);
    this.onLeadSaved = this.onLeadSaved.bind(this);
  }

  onLeadSaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId, disableDots } = this.props as LeadDialogProperties;
    return (
      <LeadEdit
        contactId={contactId}
        onLeadCreated={this.onLeadSaved}
        onLeadUpdated={this.onLeadSaved}
        onCancel={this.close.bind(this)}
        disableDots={disableDots}
      />
    );
  }
}
