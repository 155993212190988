import {
  CountryDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateCountryCommandValues,
  UpdateCountryCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCountryRequest,
  getCountriesListRequest,
  getCountryRequest,
  deleteCountryRequest,
  updateCountryRequest,
  importCountriesRequest,
  exportCountriesRequest,
  GetCountryParams,
} from './countries.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type CountriesStoreState = {
  links: LinkDto[];
  countryColumns: EntityFieldDto[];
  defaultCountryColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getCountriesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Country',
  });
});

addEffectStatusHandling(getCountriesColumnsFx);

export const getCountriesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Country',
  });
});

addEffectStatusHandling(getCountriesDefaultColumnsFx);

export const updateCountriesColumns = createEvent<EntityFieldDto[]>();

export const getCountriesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCountriesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getCountriesFx);

export const createCountryFx = createEffect((countryData: CountryDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createCountryCommand: CreateCountryCommandValues = { ...countryData };

  return createCountryRequest(currentOrganization!, createCountryCommand);
});

addEffectStatusHandling(createCountryFx, {
  completeMessage: 'Country was created successfully',
  errorMessage: 'Country was not created',
});

export const getCountryFx = createEffect((countryParams: GetCountryParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCountryRequest(
    currentOrganization as LinkResourceBaseDto,
    countryParams,
  );
});

addEffectStatusHandling(getCountryFx);

export const updateCountryFx = createEffect((country: CountryDto) => {
  const updateCountryCommand: UpdateCountryCommandValues = { ...country };
  return updateCountryRequest(country, updateCountryCommand);
});

addEffectStatusHandling(updateCountryFx, {
  completeMessage: 'Country was updated successfully',
  errorMessage: 'Country was not updated',
});

export const deleteCountryFx = createEffect((country: CountryDto) => {
  return deleteCountryRequest(country);
});

addEffectStatusHandling(deleteCountryFx, {
  completeMessage: 'Country was deleted successfully',
  errorMessage: 'Country was not deleted',
});

export const exportCountriesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportCountriesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportCountriesFx, {
  completeMessage: 'Countries were exported successfully',
  errorMessage: 'Countries were not exported',
});

export const importCountriesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importCountriesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importCountriesFx, {
  completeMessage: 'Countries were imported successfully',
  errorMessage: 'Countries were not imported',
  inFlightMessage: 'Importing countries...',
});

const defaultState: CountriesStoreState = {
  links: [],
  countryColumns: [],
  defaultCountryColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const countryStore: Store<CountriesStoreState> = createStore(
  defaultState,
)
  .on(getCountriesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      countryColumns: payload.result.items,
      defaultCountryColumns: payload.result.items,
    };
  })
  .on(getCountriesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultCountryColumns: payload.result.items,
    };
  })
  .on(updateCountriesColumns, (state, payload) => {
    return { ...state, countryColumns: payload };
  });
