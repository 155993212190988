import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrganizationConfigEdit } from './organizationConfig-edit.component';
import { OrganizationConfigDto } from '../../../models/data.models';

export type OrganizationConfigDialogProperties = {
  organizationConfigId?: number | null;
  title: string;
};

export class OrganizationConfigDialog extends Dialog<OrganizationConfigDialogProperties> {
  constructor(props: OrganizationConfigDialogProperties) {
    super(props);
    this.onOrganizationConfigSaved = this.onOrganizationConfigSaved.bind(this);
  }

  onOrganizationConfigSaved = (organizationConfig: OrganizationConfigDto) => {
    this.close(organizationConfig);
  };

  renderContent(): any {
    const { organizationConfigId } = this
      .props as OrganizationConfigDialogProperties;
    return (
      <OrganizationConfigEdit
        organizationConfigId={organizationConfigId}
        onOrganizationConfigCreated={this.onOrganizationConfigSaved}
        onOrganizationConfigUpdated={this.onOrganizationConfigSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
