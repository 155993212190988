import React from 'react';
import { MdFilterList } from 'react-icons/md';
import Dropdown from 'react-bootstrap/Dropdown';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { GridContextValue } from '../../filters/filtersTab.component';

type GridFiltersSetupProperties = {
  className?: string;
  useGridContext?: () => GridContextValue;
};

export const GridFiltersSetup = ({
  className = '',
  useGridContext,
}: GridFiltersSetupProperties) => {
  const { filters, onFiltersChanged } = useGridContext();

  const updateFilters = (filterName: string) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.name === filterName) {
        return {
          ...filter,
          visible: !filter.visible,
        };
      }
      return filter;
    });

    onFiltersChanged(updatedFilters);
  };

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        id="dropdownColumnsButton"
        className="pointer btn-toggle"
        variant="light"
      >
        <MdFilterList size="20px" />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: 'max-content', maxWidth: 'unset' }}>
        <div
          className="scrollbar"
          style={{
            height: 'max-content',
            maxHeight: '400px',
          }}
        >
          {filters.map((filter) => (
            <div
              key={filter.name}
              className="dropdown-item pointer hover-bg"
              onClick={() => updateFilters(filter.name)}
            >
              <div className="row justify-content-between">
                <div
                  className="pr-4 align-self-center btn-toggle-text"
                  style={{ userSelect: 'none' }}
                >
                  {filter.title}
                </div>
                <div>
                  {filter.visible ? (
                    <ImCheckboxChecked size={'20px'} color="#066FFC" />
                  ) : (
                    <ImCheckboxUnchecked size={'20px'} color="#D0D5DD" />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
