import { OrderDocumentDto } from '../../../models/data.models';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiFileText } from 'react-icons/fi';
import { PicturePreview } from '../../attachments/components/picture-preview.component';
import { useEffect, useState } from 'react';

export type OrderDocumentCardProps = {
  document: any;
  openDocument: (document: OrderDocumentDto) => void;
  isRegeneratingEnabled: boolean;
  regenerateDocument: (document: OrderDocumentDto) => void;
  setSelectedDocuments?: (selectedItem: any) => void;
  onDeleteDocument: (document: OrderDocumentDto) => void;
  documentName: string;
  isRegenerable: boolean;
  isAttachment: boolean;
  isSelected?: boolean;
  onEditDocument?: (document: OrderDocumentDto) => void;
};

export const OrderDocumentCard = ({
  document,
  openDocument = () => {},
  isRegeneratingEnabled = false,
  regenerateDocument = () => {},
  setSelectedDocuments,
  onDeleteDocument,
  documentName,
  isRegenerable = false,
  isAttachment = false,
  isSelected,
  onEditDocument,
}: OrderDocumentCardProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(isSelected);

  useEffect(() => {
    setIsChecked(!!isSelected);
  }, [isSelected]);

  const handleCheckboxChange = (
    document: OrderDocumentDto,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      setSelectedDocuments((prevSelectedDocuments) => [
        ...prevSelectedDocuments,
        document,
      ]);
      setIsChecked(true);
    } else {
      setSelectedDocuments((prevSelectedDocuments) =>
        prevSelectedDocuments.filter(
          (selectedDocument) => selectedDocument !== document,
        ),
      );
      setIsChecked(false);
    }
  };

  return (
    <div
      className="custom-card col-12"
      style={{ border: isChecked ? '1px solid #066FFC' : '1px solid #EAECF0' }}
    >
      <div className="custom-card-body">
        <div className="row mb-3">
          <div className="col-1 align-items-center d-flex">
            <input
              type="checkbox"
              className="custom-checkbox-documents"
              checked={isChecked}
              onChange={(event) => {
                handleCheckboxChange(document, event.target.checked);
              }}
            />
          </div>
          <div className="col-10 mr-0">
            <a
              onClick={() => {
                openDocument(document);
              }}
              style={{
                display: 'block',
                cursor: 'pointer',
              }}
            >
              <div className={'row pl-2'}>
                <div className="col-1 p-0 align-items-center d-flex">
                  <FiFileText color="#98A2B3" />
                </div>
                <div
                  className="col-10 pr-0 mr-0"
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    lineHeight: '20px',
                  }}
                  title={documentName}
                >
                  {documentName}
                </div>
              </div>
            </a>
          </div>
          <div className="col-1 align-items-center d-flex">
            <div className="dropdown">
              <BsThreeDotsVertical
                className="dropdown-toggle dropdown-dots-vertical pointer"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                color="#98A2B3"
              />
              <div
                className="dropdown-menu dropdown-menu-grid"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    regenerateDocument(document);
                  }}
                >
                  {isRegenerable ? 'Regenerate' : 'Edit'}
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    onDeleteDocument(document);
                  }}
                >
                  Delete
                </button>
                {onEditDocument && (
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      onEditDocument(document);
                    }}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <PicturePreview
          attachment={document}
          isAttachment={isAttachment}
          documentName={documentName}
          height={'155px'}
        />
      </div>
    </div>
  );
};
