import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { JobStatusesListScreen } from './screens/jobStatuses-list.screen';
import { JobStatusScreen } from './screens/jobStatus.screen';

export const JOBSTATUSES_LIST_PATH = '/jobstatuses';
export const JOBSTATUS_EDIT_PATH = '/jobstatuses/:jobStatusId';
export const JOBSTATUS_CREATE_PATH = '/jobstatuses/create';

export const JobStatusesRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <JobStatusesListScreen pageTitle="Job Statuses" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <JobStatusScreen pageTitle="Add New Job Status" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':jobStatusId'}
        element={
          <PrivateRoute>
            <JobStatusScreen pageTitle="Update Job Status" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
