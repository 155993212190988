import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../button/button.component';
import { FormContext } from '../form/form.component';
import { parseParcelShipmentsQuery } from '../../../../utils/query.utils';
import { FiltersForm } from './filters.form';
import {
  Column,
  ContactType,
  CustomFieldType,
  EntityTypes,
  LinkDto,
  OrderTypes,
} from '../../../../models/data.models';
import { useFormikContext } from 'formik';
import lodash from 'lodash';
import { FilterField } from './filterField.component';
import { GridPanel } from '../grid/grid-panel/grid-panel.component';
import { IAction } from '../actions/actions';

export type FilteredField = {
  type:
    | Column['type']
    | CustomFieldType
    | 'Options'
    | 'currency'
    | 'parcelStatus'
    | 'division'
    | 'documentTemplateType'
    | 'user'
    | 'finalMileCarrier'
    | 'attachmentType'
    | 'warehouseLocation';
  name: string;
  sortName?: string;
  filterFieldName?: string;
  title: string;
  visible?: boolean;
  contactType?: ContactType;
  contactTypes?: ContactType[];
};

export interface GridContextValue {
  columns: Column[];
  filters: FilteredField[];
  filterContextValues?: any;
  selectedView?: string;
  search: string;
  defaultColumns?: Column[];
  defaultFilters?: FilteredField[];
  defaultSort?: string;
  defaultLimit?: number;
  onSearch?: (query: string) => void;
  onFilter?: (query: string) => void;
  onColumnsChanged: (columns: Column[]) => void;
  onFiltersChanged: (filters: FilteredField[]) => void;
  onViewChanged: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  actions?: IAction[];
  enableScanner?: boolean;
  entityType?: OrderTypes | any;
}

type FiltersTabProps = {
  useGridContext?: () => GridContextValue;
};

const AutoSaveFilter = () => {
  const formikContext = useFormikContext();
  useEffect(() => {
    if (formikContext.values !== null) {
      formikContext.submitForm();
    }
  }, [formikContext.values]);
  return null;
};

export const FiltersTab = ({ useGridContext }: FiltersTabProps) => {
  const { filters, onFilter, filterContextValues } = useGridContext();

  const [visibleFilters, setVisibleFilters] = useState<FilteredField[]>([]);

  useEffect(() => {
    const filteredFilters = filters.filter((filter) => filter.visible === true);
    setVisibleFilters(filteredFilters);
  }, [filters]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = urlSearchParams.get('filter');
  const queryValues = parseParcelShipmentsQuery(searchParams ?? '');

  const [initialValues, setInitialValues] = useState({
    ...filterContextValues,
    ...queryValues,
  });

  const formikRef = useRef<HTMLFormElement>();

  const escapeLucene = (input) => {
    if (!input) {
      return input;
    }
    return input.replace(/([\\+\-&|!(){}\[\]\^"~*?:])/g, '\\$1');
  };

  const formatDateToLucene = (date: Date) => {
    return (
      date.getUTCFullYear() +
      String(date.getUTCMonth() + 1).padStart(2, '0') +
      String(date.getUTCDate()).padStart(2, '0')
    );
  };

  const wrapWithQuotes = (value: string) => {
    if (value && value.length > 0) {
      return `"${value}"`;
    }
    return value;
  };

  const wrapWithWildCards = (value: string) => {
    if (value && value.length > 0) {
      return `*${value}*`;
    }
    return value;
  };

  const getFilterValue = (data: any, filter: FilteredField) => {
    const value = lodash.get(data, filter.name);
    const filterType = filter.type?.toLowerCase();

    if (value?.length > 0) {
      return wrapWithQuotes(wrapWithWildCards(escapeLucene(value)));
    }

    return value;
  };

  const getFilterFieldName = (filter: FilteredField) => {
    return filter.filterFieldName || filter.sortName || filter.name;
  };

  const onSubmit = async (data) => {
    const queryFilter: string[] = await Promise.all(
      filters
        .filter((filter) => {
          if (Array.isArray(lodash.get(data, filter.name))) {
            return lodash.get(data, filter.name).length > 0;
          } else {
            return lodash.get(data, filter.name);
          }
        })
        .map(async (filter) => {
          if (filter.type === CustomFieldType.DestinationCountries) {
            const countries = Array.isArray(lodash.get(data, filter.name))
              ? lodash
                  .get(data, filter.name)
                  .map((el) => el.countryCode)
                  .join(' OR OrderEntities.ContactAddress.CountryCode:')
              : lodash.get(data, filter.name);
            return `(OrderEntities.EntityType:${EntityTypes.Consignee} AND OrderEntities.ContactAddress.CountryCode:${countries})`;
          }
          if (filter.type === 'finalMileCarrier') {
            const carrierId = lodash.get(data, filter.name);
            return `(OrderEntities.EntityType:${EntityTypes.Carrier} AND OrderEntities.ContactId:${carrierId})`;
          }

          if (filter.type === 'Date') {
            const time = lodash.get(data, filter.name);
            const isRange = Array.isArray(time);

            const minDate = isRange ? time?.[0] : time;
            const min = formatDateToLucene(new Date(minDate));

            const maxDate = isRange ? time?.[1] : time;
            const max = formatDateToLucene(
              new Date(new Date(maxDate ?? minDate).setHours(24)),
            );
            return `${getFilterFieldName(filter)}:["${escapeLucene(
              min,
            )}" TO "${escapeLucene(max)}"]`;
          }
          if (filter.type !== 'Text') {
            const val = lodash.get(data, filter.name);
            return `${getFilterFieldName(filter)}:"${wrapWithQuotes(val)}"`;
          }

          return `${getFilterFieldName(filter)}:${getFilterValue(
            data,
            filter,
          )}`;
        }),
    );
    onFilter(queryFilter.join(' AND '));
  };

  return (
    <FiltersForm
      initialValues={initialValues}
      className={'d-flex flex-wrap row filters-form w-100'}
      onSubmit={onSubmit}
      id={'filters-form'}
      innerRef={formikRef}
    >
      <FormContext.Consumer>
        {(context) => {
          return (
            <div className="column w-100">
              <GridPanel
                context={context}
                useGridContext={useGridContext}
                rowKeys={['orderId']}
              />
              {visibleFilters.length > 0 && (
                <div className="box row ml-2 mt-4">
                  <AutoSaveFilter />
                  {visibleFilters.map((filter, index) => {
                    return (
                      <FilterField
                        key={filter.name}
                        filter={filter}
                        context={context}
                        index={index}
                        useGridContext={useGridContext}
                      />
                    );
                  })}
                  <Button
                    className={'mt-4 ml-2'}
                    onClick={() => {
                      context.resetForm({ values: {} });
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>
          );
        }}
      </FormContext.Consumer>
    </FiltersForm>
  );
};
