import { createEvent, createStore, Event, Store } from 'effector';
import {
  actionEventStore,
  createActionEventFx,
} from '../actionEvents/actionEvents.store';

export type KomcatDeviceConfig = {
  is2D: boolean;
  isBarcode: boolean;
  isBluetooth: boolean;
  isCDMA: boolean;
  isDisp: boolean;
  isFingerPrint: boolean;
  isGPS: boolean;
  isKeypad: boolean;
  isMSR: boolean;
  isMSRIC: boolean;
  isNFC: boolean;
  isPOS: boolean;
  isPassport: boolean;
  isUHF: boolean;
  isVib: boolean;
  isWiFi: boolean;
  modelName: string;
  modelNumber: string;
};

export type KomcatDevice = {
  bleFirmwareVersion: string;
  firmwareVersion: string;
  isMSRICAttached: boolean;
  isMsrEncrypt: boolean;
  isUHFAttached: boolean;
  kdcName: string;
  modelConfig: KomcatDeviceConfig;
  serialNumber: string;
  subType: string;
  type: string;
};

export type ScanningResult = {
  byte?: Uint8Array;
  code?: number;
  data: string;
  dataInt?: number;
  msg?: string;
  size?: number;
  sort?: string;
  status?: boolean;
  time?: string;
  type?: string;
  from?: string;
};

declare type ScannerStoreState = {
  status: boolean;
  progress: string;
  device: KomcatDevice;
  scanningResult: ScanningResult;
};
const defaultState: ScannerStoreState = {
  status: false,
  progress: 'CONNECTION_STATE_DISCONECTED',
  device: null,
  scanningResult: null,
};

export const onDeviceConnectionStatus: Event<{
  status: boolean;
  progress: string;
  device: KomcatDevice;
}> = createEvent();

export const onScannerResult: Event<ScanningResult> = createEvent();

onScannerResult.watch((payload) => {
  console.log('onScannerResult', payload);

  createActionEventFx({
    eventName: 'commodity.scan',
    eventData: {
      trackingNumber: payload.data,
      location: '', // TODO: Add location
    },
  });
});

const onScannerStoreEvent = onScannerResult.map((e) => e);

export const scannerStore: Store<ScannerStoreState> = createStore(defaultState)
  .on(onDeviceConnectionStatus, (state, payload) => {
    return { ...state, ...payload };
  })
  .on(onScannerStoreEvent, (state, payload) => {
    return { ...state, scanningResult: payload };
  });
