import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CountriesListScreen } from './screens/countries-list.screen';
import { CountryScreen } from './screens/country.screen';

export const COUNTRIES_LIST_PATH = '/countries';
export const COUNTRY_EDIT_PATH = '/countries/:countryCode';
export const COUNTRY_CREATE_PATH = '/countries/create';

export const CountriesRoute = () => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CountriesListScreen pageTitle="Countries" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <CountryScreen pageTitle="Add New Country" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':countryCode'}
        element={
          <PrivateRoute>
            <CountryScreen pageTitle="Update Country" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
