import { localized } from '../component-hooks';
import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';

export const ToolbarComponent = (props: ComponentProps) => {
  const { buttons } = props?.props || {};
  return (
    <nav className="navbar navbar-light navbar-expand-lg bg-light">
      <div className="container-fluid">
        <div className="navbar-brand">{localized(props?.props?.title)}</div>

        <div className="d-flex ms-auto" role="search">
          {buttons?.map((child, index) => {
            return (
              <ComponentRender
                key={child.name}
                {...child}
                context={props.context}
                variables={props.variables}
              />
            );
          })}
        </div>
      </div>
    </nav>
  );
};
