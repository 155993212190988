import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import {
  CustomFieldDto,
  DimensionsUnit,
  WeightUnit,
} from '../../../models/data.models';

const labelStyles = {
  fontWeight: 500,
  lineHeight: '19px',
  fontSize: '16px',
};

export interface TrackingNumberFormProps extends FormProps {
  customFields?: CustomFieldDto[];
}

export const PackingForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  onKeyPress,
  validationSchema,
}: TrackingNumberFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      initialValues={initialValues}
      onKeyPress={onKeyPress}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

PackingForm.TrackingNumber = ({
  className,
  style,
  size,
  type = 'text',
  name = 'trackingNumber',
  placeholder = 'Tracking Number',
  required = true,
  selectedFieldName,
  onChange,
  barcode,
  autoSubmit,
}: InputPublicProps) => {
  return (
    <Input
      id={name}
      name={name}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      placeholder={placeholder}
      barcode={barcode}
      autoSubmit={autoSubmit}
    />
  );
};

PackingForm.ServicePallet = ({
  className,
  style,
  size,
  type = 'text',
  name = 'servicePallet',
  label = 'Service Pallet #',
  required = true,
  selectedFieldName,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
    />
  );
};

PackingForm.PackageTotalWeight = ({
  className,
  style,
  size,
  placeholder = 'Weight',
  type = 'text',
  name = 'packageTotalWeight',
  label = 'Total Weight',
  required = true,
  selectedFieldName,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

PackingForm.PalletNumber = ({
  id,
  className,
  style,
  size,
  type = 'text',
  name = 'putAwayPallet',
  label = 'Put Away Pallet #',
  required = true,
  selectedFieldName,
  onChange,
  barcode,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={name}
      label={label}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      barcode={barcode}
    />
  );
};

PackingForm.Weight = ({
  id = 'weight',
  className,
  style,
  size,
  label = 'Weight',
  disabled,
  onChange,
  onKeyDown,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      disabled={disabled}
      name={id}
      label={label}
      placeholder={'Weight'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};

PackingForm.WeightUnit = ({
  onChange,
  options,
  name = 'weightUnit',
  id = 'weightUnit',
  placeholder = 'Select Weight Unit',
  required = true,
  multiple = false,
  disabled = false,
  header,
  valueInput,
  defaultValue = '',
  label = 'Weight Unit',
  isClearable,
  className,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      className={className}
      onChange={onChange}
      options={options}
      multiple={multiple}
      disabled={disabled}
      required={required}
      valueInput={valueInput}
      defaultValue={defaultValue}
      name={id}
      label={label}
      placeholder={placeholder}
      type={'react-select'}
      id={id}
      enumName={WeightUnit}
      isClearable={isClearable}
      onBlur={onBlur}
    />
  );
};

PackingForm.Length = ({
  id = 'length',
  className,
  style,
  size,
  label = 'Length',
  onChange,
  onKeyDown,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={id}
      label={label}
      placeholder={'Length'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};
PackingForm.Width = ({
  id = 'width',
  className,
  style,
  size,
  label = 'Width',
  onChange,
  onKeyDown,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={id}
      label={label}
      placeholder={'Width'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};
PackingForm.Height = ({
  id = 'height',
  className,
  style,
  size,
  label = 'Height',
  onChange,
  onKeyDown,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={id}
      label={label}
      placeholder={'Height'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};
PackingForm.DimensionsUnit = ({
  onChange,
  options,
  name = 'dimensionsUnit',
  id = 'dimensionsUnit',
  placeholder = 'Select Dimension Unit',
  required = true,
  multiple = false,
  disabled = false,
  header,
  valueInput,
  defaultValue = '',
  label = 'Dimension Unit',
  isClearable,
  className,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      className={className}
      onChange={onChange}
      options={options}
      multiple={multiple}
      disabled={disabled}
      required={required}
      valueInput={valueInput}
      defaultValue={defaultValue}
      name={id}
      label={label}
      placeholder={placeholder}
      type={'react-select'}
      id={id}
      enumName={DimensionsUnit}
      isClearable={isClearable}
      onBlur={onBlur}
    />
  );
};
