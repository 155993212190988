import {
  Suspense,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { ComponentProps } from '../layout/layout-interfaces';
import { useTable } from './table';
import { queryGraphQlFx } from '../../../graphql/graphql.store';
import { generateGraphQLQuery, toLuceneTerm } from './graphql';
import { ViewSelector } from './views.component';
import { DataGridPaginationComponent } from './pagination.component';
import { localized, parseTemplate } from '../layout/component-hooks';
import { ComponentRender } from '../layout/component-render';
import { Formik, FormikContext } from 'formik';
import logger from '../../logger';
import { IOrderByColumn, ITableView, ITableViewProps } from './tableView';
import { IRowRender } from './row';
import { ColumnsSelector } from './columnSelector.components';
import { IColumn } from './column';
import { MdFilterList } from 'react-icons/md';
import { FiColumns } from 'react-icons/fi';
import { debounce, isArray } from 'lodash';
import { CustomMenu, CustomToggle } from './customToggle';
import { useViews } from './tableViews';
import { Dropdown } from 'react-bootstrap';
import { getDateRange } from '../../../../utils/helper.utils';

export const orderByParser = (orderByColumns: IOrderByColumn[]) => {
  if (!orderByColumns) return undefined;

  return orderByColumns
    ?.map((column) => {
      if (column.direction === 'DESC') {
        return `-${column.name}`;
      }
      return `${column.name}`;
    })
    .join(',');
};

export const DataGridComponent = (props: ComponentProps) => {
  const formikContext = useContext(FormikContext);
  const { options, toolbar, dotsMenu } = props.props;
  const [search, setSearch] = useState('');
  const [filterValues, setFilterValues] = useState({} as any);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const viewsHooks = useViews({
    name: props.name,
    views: props.props.views,
    context: props.context,
    variables: props.variables,
    filterValues,
  });

  // entity definition
  const [entitiesFields, setEntitiesFields] = useState({} as any);
  const [refreshData, setRefreshData] = useState(0);
  const [refreshFilterData, setRefreshFilterData] = useState(0);
  const [gqlCommand, setGqlCommand] = useState(null);

  const [loading, setLoading] = useState(true);
  const requestId = useRef(Symbol());
  const [paginationOptions, setPaginationOptions] = useState({
    offset: props.variables?.offset ?? 0,
    limit: props.variables?.limit ?? 20,
  } as any);
  const [totalCount, setTotalCount] = useState(0);

  const onRowClick = (rowClickOptions: any, rowData: any) => {
    setTimeout(() => {
      // check if text was selected in the row
      if (window.getSelection().toString()) {
        return;
      }

      props.context?.action(
        rowClickOptions,
        {
          ...props.variables,
          ...rowData,
        },
        { formikContext },
      );
    }, 150);
  };

  const {
    setCurrentView: setCurrentView,
    view,
    rows,
    data: dataGridData,
    setData,
    tableInstance,
  } = useTable({
    options,
    onRowClick,
    dataGridStore: props?.context?.store?.[props.name ?? 'dataGrid'],
    formikContext,
  });

  useEffect(() => {
    if (
      props.variables?.limit !== paginationOptions.limit ||
      props.variables?.offset !== paginationOptions.offset
    ) {
      setPaginationOptions({
        ...paginationOptions,
        limit: props.variables?.limit ?? 20,
        offset: props.variables?.offset ?? 0,
      });
    }
  }, [props.variables]);

  useEffect(() => {
    // check if view is was changed
    if (props.variables?.view) {
      const selectedView = viewsHooks.views[props.variables?.view];
      if (
        selectedView &&
        (selectedView.name !== view?.name ||
          (view &&
            !CheckColumnsSame(view?.config.viewColumns, selectedView?.columns)))
      ) {
        setCurrentView(selectedView);
      }
    }
  }, [viewsHooks.views]);

  function CheckColumnsSame(currentColumns, newColumns) {
    if (currentColumns.length !== newColumns.length) {
      return false;
    }

    for (let i = 0; i < currentColumns.length; i++) {
      const currentColumn = currentColumns[i];
      const newColumn = newColumns[i];

      const currentKeys = Object.keys(currentColumn);
      const newKeys = Object.keys(newColumn);

      if (currentKeys.length !== newKeys.length) {
        return false;
      }

      for (const key of currentKeys) {
        if (currentColumn[key] !== newColumn[key]) {
          return false;
        }
      }
    }

    return true;
  }

  // Set pagination options to context
  useEffect(() => {
    if (props.context?.setParams && paginationOptions && view?.name) {
      const newParams = {
        limit: paginationOptions.limit,
        offset: paginationOptions.offset,
        view: view?.name ?? '',
      };
      if (
        props?.variables?.limit !== newParams.limit ||
        props?.variables?.offset !== newParams.offset ||
        props?.variables?.view !== newParams.view
      ) {
        if (options.navigationType === 'store') {
          Object.entries(newParams).forEach((entry) =>
            props.context?.setStore(entry[0], entry[1]),
          );
        } else {
          props.context?.setParams(newParams);
        }
      }
    }
  }, [paginationOptions, view]);

  const fetchEntityFields = async (entityName: string) => {
    try {
      const res = await queryGraphQlFx({
        query: `
        query entityFields($organizationId: Int!, $entityName: String!) {
          entityFields(organizationId: $organizationId, entityName: $entityName, take: 1000) {
              items{
                name
                fieldDefinition
              }
          }
      }
      `,
        variables: {
          ...props.variables,
          entityName,
        },
      });

      setEntitiesFields((state) => {
        return {
          ...state,
          [entityName]:
            res.entityFields.items?.map((f: any) => ({
              label: f.displayName,
              ...f.fieldDefinition,
            })) ?? [],
        };
      });
    } catch (e) {
      logger.error(e);
      setEntitiesFields((state) => {
        return {
          ...state,
          [entityName]: [],
        };
      });
    }
  };

  const addColumnsFromChildViews = (
    v: ITableView,
    columns?: string[],
  ): string[] => {
    const cols = columns ?? [];
    cols.push(
      ...view.config.viewColumns
        .filter((column: any) => !column.excludeFromQuery && !column.value)
        .map((column: any) => {
          return column.path ?? column.name;
        }),
    );

    v.config.childViews?.forEach((childView) => {
      childView.config.viewColumns
        .filter((column: any) => !column.excludeFromQuery)
        .forEach((column: any) => {
          if (column.path) {
            cols.push(`${childView.name}.${column.path}.${column.name}`);
          } else {
            cols.push(`${childView.name}.${column.name}`);
          }
        });
      addColumnsFromChildViews(childView, cols);
    });

    return cols;
  };

  const combineFilters = (baseFilter?: string, selectedFilterValues?: any) => {
    let filter = baseFilter ?? '';
    const terms =
      Object.entries(selectedFilterValues)
        .map(([key, value]) => {
          if (
            !value ||
            value?.length === 0 ||
            (isArray(value) && value.every((x) => !x))
          ) {
            return null;
          }
          // if value is array, convert to lucene OR
          if (Array.isArray(value)) {
            // if value is a date range
            if (
              value.length === 2 &&
              value.every(
                (x) =>
                  (x instanceof Date || typeof x === 'string') &&
                  !isNaN(new Date(x).getTime()),
              )
            ) {
              const dateRange = getDateRange(value.map((x) => new Date(x)));
              return `${key}:["${dateRange[0]}" TO "${dateRange[1]}"]`;
            }
            return `(${value
              .map((v) => {
                return `${key}:${toLuceneTerm(v.value ?? v)}`;
              })
              .join(' OR ')})`;
          }

          return `${key}:${toLuceneTerm(value)}`;
        })
        .filter((x) => x) ?? [];

    if (filter && terms.length > 0) {
      filter = `${filter} AND ${terms.join(' AND ')}`;
    } else if (terms.length > 0) {
      filter = terms.join(' AND ');
    }

    return filter;
  };

  const fetchData = useCallback(
    async ({ filterValuesLocal, searchLocal }) => {
      if (!props.variables?.currentUser) return;

      const graphqlColumns = addColumnsFromChildViews(view);

      const command = generateGraphQLQuery({
        tableName: options.query,
        columns: graphqlColumns,
        entityKeys: options.entityKeys,
        parameters: {
          organizationId: 'Int!',
          search: 'String!',
          take: 'Int!',
          skip: 'Int!',
          filter: 'String!',
          orderBy:
            view.config.orderByColumns?.length > 0 ? 'String!' : undefined,
        },
      });

      const baseFilter = parseTemplate(view?.filter ?? '', {
        ...props.variables,
        ...props?.props,
        ...props?.context?.store,
      });
      const filter = combineFilters(baseFilter, filterValuesLocal);

      const orderBy = orderByParser(view.config.orderByColumns);

      const variables = {
        ...props.variables,
        search: searchLocal,
        take: paginationOptions.limit,
        skip: paginationOptions.offset,
        filter,
        orderBy,
      };
      const currentRequestId = Symbol();
      requestId.current = currentRequestId;
      setLoading(true);
      try {
        setGqlCommand({
          query: command,
          variables,
        });

        const res = await queryGraphQlFx({
          query: command,
          variables,
        });

        const valueColumns = view.config.viewColumns.map(
          (column: any) => column,
        );

        if (requestId.current !== currentRequestId) return;
        const rowData =
          res[options.query]?.items?.map((item: any) => {
            valueColumns.forEach((column: any) => {
              if (column.value) {
                item[column.name] = parseTemplate(column.value, {
                  ...props.variables,
                  ...props.context.store,
                  ...item,
                  item,
                });
              }
            });

            // child views
            if (view.config.childViews) {
              view.config.childViews.forEach((childView) => {
                const viewData = item[childView.name];
                if (isArray(viewData) && viewData?.length > 0) {
                  // for each childView column
                  childView.config.viewColumns.forEach((column: any) => {
                    if (column.value) {
                      viewData.forEach((row: any) => {
                        row[column.name] = parseTemplate(column.value, {
                          ...props.variables,
                          ...props.context.store,
                          ...row,
                          item,
                        });
                      });
                    }
                  });
                }
              });
            }

            return item;
          }) ?? [];

        if (isArray(rowData) && rowData.length > 0) {
          rowData.forEach((row) => {
            if (isArray(row.customValues)) {
              row.customValues = row.customValues.reduce((acc, val) => {
                acc[val.key] = val.value;
                return acc;
              }, {});
            }
          });
        }

        setData(rowData);
        setTotalCount(res[options.query]?.totalCount);

        // dataGrid onLoad
        if (props.props.options?.onDataLoad) {
          props.context?.action(
            props.props.options?.onDataLoad,
            {
              ...props.variables,
              ...props.context.store,
              data: res[options.query]?.items,
            },
            {
              sender: props.name,
              formikContext,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [view, props.variables, paginationOptions],
  );

  const debouncedFetchData = useCallback(debounce(fetchData, 200), [fetchData]);

  useEffect(() => {
    if (
      options?.rootEntityName &&
      view?.config.viewColumns &&
      entitiesFields[options?.rootEntityName]
    ) {
      debouncedFetchData({
        filterValuesLocal: filterValues,
        searchLocal: search,
      });
    } else {
      if (options?.items) {
        setData(
          parseTemplate(options.items, {
            ...props.variables,
            ...props.context.store,
          }),
        );
        setLoading(false);
      }
    }
  }, [
    view,
    refreshData,
    options,
    search,
    filterValues,
    paginationOptions,
    entitiesFields,
    debouncedFetchData,
    options?.items ? props.context.store : undefined,
    props.context?.refreshHandlers?.[props.props.refreshHandler],
  ]);

  useEffect(() => {
    if (entitiesFields && entitiesFields[options?.rootEntityName]) {
      const columns = entitiesFields[options.rootEntityName]
        ?.filter((field: any) => field.fieldType !== 'Entity')
        ?.map((field: any) => {
          return {
            name: field.name,
            label: field.description,
            type: field.fieldType,
            props: {
              ...field.props,
            },
          };
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

      view?.setAvailableColumns(columns);
    }
  }, [entitiesFields, view]);

  useEffect(() => {
    if (options?.rootEntityName) {
      const { rootEntityName } = options;
      if (rootEntityName) {
        fetchEntityFields(rootEntityName);
      }
    } else if (options?.columns) {
      setCurrentView({
        name: 'default',
        columns: options.columns ?? [],
      });
    }
  }, [options]);

  // set default view
  useEffect(() => {
    if (options?.defaultView && !props?.variables?.view) {
      const selectedView = viewsHooks.views?.[options.defaultView];
      setCurrentView(selectedView);
    }
  }, [options]);

  const onViewChanged = (selectedView: ITableViewProps) => {
    let filters = {};
    if (selectedView?.filters) {
      selectedView.filters.forEach(
        (filter) => (filters[filter.name] = filter.values),
      );
    }
    setFilterValues(filters);
    setCurrentView(selectedView);
  };

  const onSaveView = async () => {
    const newView = await viewsHooks.saveView(view);
    if (newView && newView.id !== view.id) {
      setCurrentView(newView);
    }
  };

  const updateSearch = (e: any) => {
    setSearch(e.target.value);
    onPageChange(0);
  };

  const onPageSizeChange = (limit: number) => {
    setPaginationOptions((state) => {
      return {
        ...state,
        limit,
      };
    });
  };

  const onPageChange = (page: number) => {
    setPaginationOptions((state) => {
      return {
        ...state,
        offset: page * state.limit,
      };
    });
  };

  const handleDotsMenuItemClick = useCallback(
    (item: any, data: any) => () => {
      if (item?.onClick)
        props.context?.action(
          item.onClick,
          {
            ...props.variables,
            ...props.context.store,
            ...data,
          },
          { formikContext },
        );
    },
    [props.context.store, dotsMenu?.items, props.variables],
  );

  const onFormChange = useCallback(
    (e, formikProps) => {
      logger.log('Form changed', formikProps.values);
      setData(formikProps.values.data);
    },
    [props],
  );

  useEffect(() => {
    if (props.props?.enableStore) {
      logger.log('Data changed', dataGridData);
      props.context.setStore(props.name ?? 'dataGrid', dataGridData);
    }
  }, [dataGridData]);

  const FormWrapper = useCallback(
    ({ children }) => {
      if (view?.enableEdit) {
        return (
          <Formik
            initialValues={{ data: dataGridData }}
            enableReinitialize={true}
            onSubmit={() => {}}
          >
            {(formikProps) => {
              useEffect(() => {
                onFormChange(null, formikProps);
              }, [formikProps.values]);
              return <>{children}</>;
            }}
          </Formik>
        );
      }
      return <>{children}</>;
    },
    [view, dataGridData],
  );

  const onAddVisibleColumn = (viewName: string, columnDef: IColumn) => {
    view?.addVisibleColumn(columnDef);
    setRefreshData((state) => state + 1);
  };

  const onRemoveVisibleColumn = (viewName: string, columnDef: IColumn) => {
    view?.setColumns(
      view?.config.viewColumns.filter((col) => col.name !== columnDef.name),
    );
    setRefreshData((state) => state + 1);
  };

  const onOrderByChange = (orderByColumns: IOrderByColumn[]) => {
    setRefreshData((state) => state + 1);
  };

  // refresh filter data
  useEffect(() => {}, [refreshFilterData]);

  const onAddFilterColumn = (viewName: string, columnDef: IColumn) => {
    view?.addFilterColumn(columnDef);
    setRefreshFilterData((state) => state + 1);
  };

  const onRemoveFilterColumn = (viewName: string, columnDef: IColumn) => {
    view?.removeFilterColumn(columnDef);
    setFilterValues((state) => {
      const newState = { ...state };
      delete newState[columnDef.name];
      return newState;
    });
  };

  const onFilterChange = (e: any, formikProps: any) => {
    const values = Object.fromEntries(
      Object.entries(formikProps.values).map((entry) => {
        if (
          isArray(entry[1]) &&
          entry[1].length === 2 &&
          entry[1].every(
            (value) =>
              typeof value === 'string' && !isNaN(new Date(value).getTime()),
          )
        ) {
          const timezoneOffset =
            new Date(entry[1][0]).getTimezoneOffset() * 60000;
          entry[1] = entry[1].map(
            (value) => new Date(new Date(value).getTime() + timezoneOffset),
          );
          formikProps.setFieldValue(`[${entry[0]}]`, entry[1]);
        }
        return entry;
      }),
    );

    setFilterValues(values);
    onPageChange(0);
  };

  const closeDropdowns = () => {
    const activeDropdowns = document.querySelectorAll('.show.dropdown');
    activeDropdowns?.forEach((x) => x.classList.remove('show'));
  };

  const setDropdownPosition = (e) => {
    const scrollableElement = e.currentTarget.closest('.modal-dialog');
    if (scrollableElement) {
      const rect = scrollableElement.getBoundingClientRect();
      const x =
        e.pageX - rect.left + scrollableElement.scrollLeft - window.scrollX;
      const y =
        e.pageY - rect.top + scrollableElement.scrollTop - window.scrollY;
      setMenuPosition({ x, y });
    } else {
      setMenuPosition({
        x: e.clientX,
        y: e.clientY,
      });
    }
  };

  const renderTable = (v: ITableView, viewRows: IRowRender[]) => {
    return (
      <div className="bg-white horizontal-scrollbar">
        <table {...tableInstance.getTableProps()}>
          {v.showHeader !== false && (
            <thead>
              <tr>
                {v.config.viewColumns
                  ?.filter((column: any) => !column.isHidden)
                  .map((col) => (
                    // tslint:disable-next-line:jsx-key
                    <th key={col.name} {...col.getHeaderProps()}>
                      {col.render('Header', {}, view)}
                    </th>
                  ))}
                {dotsMenu?.items && <th>&nbsp;</th>}
              </tr>
            </thead>
          )}
          {viewRows && (
            <tbody>
              {viewRows?.map((row: any, index: number) => {
                return (
                  // tslint:disable-next-line:jsx-key
                  <>
                    <tr {...row.getRowProps()}>
                      {v.config.viewColumns
                        .filter((column: any) => !column.isHidden)
                        .map((col: any) => {
                          return (
                            // tslint:disable-next-line:jsx-key
                            <td
                              key={col.name}
                              style={{ maxWidth: `${col.maxWidth ?? 200}px` }}
                              {...row.getCellProps(col)}
                            >
                              {row.render(col, props.context, props.variables)}
                            </td>
                          );
                        })}
                      {dotsMenu?.items && (
                        <td
                          style={{
                            textAlign: 'center',
                            right: '-20px',
                            zIndex: viewRows.length - index,
                          }}
                        >
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              closeDropdowns();
                            }}
                          >
                            <Dropdown
                              autoClose={true}
                              onClick={setDropdownPosition}
                            >
                              <Dropdown.Toggle
                                as={CustomToggle}
                                id={`dropdown-custom-components-${index}`}
                              />
                              <Dropdown.Menu as={CustomMenu}>
                                <div
                                  className="dropdown-main-menu dropdown-menu-grid-fixed"
                                  style={{
                                    top: menuPosition.y,
                                    left: menuPosition.x,
                                  }}
                                >
                                  {typeof dotsMenu?.items?.map === 'function' &&
                                    dotsMenu.items.map(
                                      (item, index) =>
                                        item && (
                                          <Dropdown.Item
                                            key={index}
                                            data-testid={
                                              localized(item.label) + index
                                            }
                                            role="button"
                                            onClick={handleDotsMenuItemClick(
                                              item,
                                              row.data,
                                            )}
                                          >
                                            {localized(item.label)}
                                          </Dropdown.Item>
                                        ),
                                    )}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      )}
                    </tr>
                    {v.config.childViews?.map((childView) => {
                      return (
                        // tslint:disable-next-line:jsx-key
                        <tr>
                          <td colSpan={v.config.viewColumns.length + 1}>
                            {renderTable(
                              childView,
                              row.getChildRows(
                                childView.name,
                                {},
                                {
                                  style: { cursor: 'pointer' },
                                  onClick: (childRowData) => {
                                    if (childView.onRowClick) {
                                      onRowClick(childView.onRowClick, {
                                        ...props.variables,
                                        ...props.context.store,
                                        ...childRowData,
                                      });
                                    }
                                  },
                                },
                              ),
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    );
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className={`pb-3 ${props?.props?.childClassName}`}>
        <div className="mx-3">
          {props.props?.enableToolbar !== false && (
            <div className="row align-items-center justify-content-start">
              {options?.enableViews !== false && (
                <div className="col-auto">
                  <ViewSelector
                    views={viewsHooks.views}
                    context={props.context}
                    dataGridName={props.name}
                    variables={props.variables}
                    filterValues={filterValues}
                    defaultViewName={view?.name}
                    currentView={view}
                    onViewChanged={onViewChanged}
                    onSaveView={onSaveView}
                    className="dropdown-toggle pointer"
                  />
                </div>
              )}
              {options?.enableSearch !== false && (
                <div className="col">
                  <input
                    type="search"
                    className={'form-control input-main'}
                    placeholder="Search"
                    value={decodeURIComponent(search)}
                    onChange={updateSearch}
                  />
                </div>
              )}
              {options?.enableFilter !== false && (
                <div className="col-auto ms-auto">
                  <ColumnsSelector
                    view={view}
                    isFilter={true}
                    onAddVisibleColumn={onAddFilterColumn}
                    onRemoveVisibleColumn={onRemoveFilterColumn}
                    icon={<MdFilterList />}
                  />
                </div>
              )}
              {options?.enableColumns !== false && (
                <div className="col-auto ms-auto">
                  <ColumnsSelector
                    view={view}
                    onAddVisibleColumn={onAddVisibleColumn}
                    onRemoveVisibleColumn={onRemoveVisibleColumn}
                    icon={<FiColumns />}
                  />
                </div>
              )}
              <div className="col-auto ms-auto">
                {toolbar?.map((child, index) => {
                  return (
                    <ComponentRender
                      key={'tool' + index}
                      {...child}
                      context={props.context}
                      variables={{
                        ...props.variables,
                        ...props.props,
                        gqlCommand,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {view?.config?.filterColumns?.length > 0 && (
          <div className="box row ml-2 m-3 p-4">
            <Formik
              initialValues={view?.config?.filterValues ?? {}}
              enableReinitialize={true}
              onSubmit={() => {}}
            >
              {(formikProps) => {
                useEffect(() => {
                  onFilterChange(null, formikProps);
                }, [formikProps.values]);

                return (
                  <>
                    {view?.config?.filterColumns?.map((column) => {
                      return (
                        <div key={column.name} className="col-3">
                          <ComponentRender
                            name={"['" + column.name + "']"}
                            component={column.component}
                            context={props.context}
                            props={column.componentProps}
                            variables={{
                              ...props.variables,
                              ...props.context.store,
                            }}
                          />
                        </div>
                      );
                    })}
                  </>
                );
              }}
            </Formik>
          </div>
        )}

        <div className="grid mx-3 my-4">
          <FormWrapper>{view && renderTable(view, rows)}</FormWrapper>
          {loading && (
            <div className="text-center p-3">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {!loading && rows?.length === 0 && (
            <div className="text-center p-3"> No data found </div>
          )}
          {options?.enablePagination !== false && (
            <DataGridPaginationComponent
              totalCount={totalCount}
              pagination={{
                limit: paginationOptions.limit,
                offset: paginationOptions.offset,
                onPageSizeChange,
                onPageChange,
              }}
            />
          )}
        </div>
      </div>
    </Suspense>
  );
};
