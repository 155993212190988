import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { UserDto, UserDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  userStore,
  getUsersColumnsFx,
  getUsersFx,
  deleteUserFx,
  exportUsersFx,
  importUsersFx,
} from '../users.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_USER_LINK_KEY,
  DELETE_USER_LINK_KEY,
  IMPORT_USERS_LINK_KEY,
  EXPORT_USERS_LINK_KEY,
  GetUserParams,
} from '../users.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { UserDialog } from './user.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type UsersListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (userParams: GetUserParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (user: UserDto, userParams: GetUserParams) => void;
};

export const UsersList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: UsersListProps) => {
  const [users, setUsers] = useState<UserDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const {
    userColumns: columns,
    defaultUserColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(userStore);

  useEffect(() => {
    getUsersColumnsFx();
  }, []);

  const getUsers = useCallback(async () => {
    const usersData = await getUsersFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });
    setActions(getActions(usersData));
    setUsers(usersData);
    setLoading(false);
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: UserDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_USERS_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importUsersFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getUsers();
          },
          result?.links,
        ),
        getAction(
          EXPORT_USERS_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportUsersFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getUsers],
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onDeleteUser = useCallback(
    async (user: UserDto) => {
      if (userHas(DELETE_USER_LINK_KEY, users?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Asp net user`,
            message: 'Are you sure you want to delete asp net user?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deleteUserFx(user);
            getUsers();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [onDelete, users],
  );

  const onEditUser = (user: UserDto) => {
    if (user) goToDetails({ userId: user.userId });
  };

  const onUserSelect = useCallback(
    async (user) => {
      const selectedUser = await showDialog({
        dialog: UserDialog,
        props: {
          userId: user.userId,
          title: 'Update Asp net user',
        },
      });
      if (selectedUser) {
        getUsers();
      }
    },
    [getUsers],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['userId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={users.items}
          onLimitChanged={onLimitChanged}
          total={users.totalCount}
          onDelete={onDeleteUser}
          onSort={onSort}
          onEdit={onEditUser}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onUserSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {users?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Asp net user"
            entityLinkKey={CREATE_USER_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
