import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { CustomerEdit } from './customer-edit.component';

export type CustomerDialogProperties = {
  contactId: number;
  title: string;
  disableDots?: boolean;
};

export class CustomerDialog extends Dialog<CustomerDialogProperties> {
  constructor(props: CustomerDialogProperties) {
    super(props);
    this.onCustomerSaved = this.onCustomerSaved.bind(this);
  }

  onCustomerSaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId, disableDots } = this.props as CustomerDialogProperties;
    return (
      <CustomerEdit
        contactId={contactId}
        onCustomerCreated={this.onCustomerSaved}
        onCustomerUpdated={this.onCustomerSaved}
        onCancel={this.close.bind(this)}
        disableDots={disableDots}
      />
    );
  }
}
