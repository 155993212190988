import { Dialog } from '../../common/components/dialog/dialog.component';
import { PackageCategoryEdit } from './packageCategory-edit.component';
import { PackageCategoryDto } from '../../../models/data.models';

export type PackageCategoryDialogProperties = {
  packageCategoryCode?: string | null;
  title: string;
};

export class PackageCategoryDialog extends Dialog<PackageCategoryDialogProperties> {
  constructor(props: PackageCategoryDialogProperties) {
    super(props);
    this.onPackageCategorySaved = this.onPackageCategorySaved.bind(this);
  }

  onPackageCategorySaved = (packageCategory: PackageCategoryDto) => {
    this.close(packageCategory);
  };

  renderContent(): any {
    const { packageCategoryCode } = this
      .props as PackageCategoryDialogProperties;
    return (
      <PackageCategoryEdit
        packageCategoryCode={packageCategoryCode}
        onPackageCategoryCreated={this.onPackageCategorySaved}
        onPackageCategoryUpdated={this.onPackageCategorySaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
