import React from 'react';
import { Dashboard } from '../components/dashboard.coomponent';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { Helmet } from 'react-helmet';

export type DashboardScreenProps = { pageTitle: string };

export const DashboardScreen = ({ pageTitle }: DashboardScreenProps) => {
  return (
    <InternalLayout title={'Dashboard'} createLabel={'Item'}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Dashboard />
    </InternalLayout>
  );
};
