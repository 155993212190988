import {
  WarehouseZoneDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  WarehouseZoneDtoPagedResult,
  CreateWarehouseZoneCommandValues,
  UpdateWarehouseZoneCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_WAREHOUSEZONES_LINK_KEY = 'get-warehousezones';
export const CREATE_WAREHOUSEZONE_LINK_KEY = 'create-warehousezone';
export const GET_WAREHOUSEZONE_LINK_KEY = 'get-warehousezone';
export const UPDATE_WAREHOUSEZONE_LINK_KEY = 'update-warehousezone';
export const DELETE_WAREHOUSEZONE_LINK_KEY = 'delete-warehousezone';
export const IMPORT_WAREHOUSEZONES_LINK_KEY = 'import-warehousezones';
export const EXPORT_WAREHOUSEZONES_LINK_KEY = 'export-warehousezones';
export const WAREHOUSEZONE_ENTITY_NAME = 'Warehouse Zone';

export interface GetWarehouseZoneParams {
  warehouseZoneId: number;
}

export interface ImportWarehouseZonesParams {
  organizationId: number;
}

export interface ExportWarehouseZonesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getWarehouseZoneRequest = async (
  resource: LinkResourceBaseDto | null,
  { warehouseZoneId }: GetWarehouseZoneParams,
): Promise<WarehouseZoneDto> => {
  if (resource && warehouseZoneId) {
    const getWarehouseZoneLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_WAREHOUSEZONE_LINK_KEY,
    );
    if (getWarehouseZoneLink) {
      const result = await execLink(getWarehouseZoneLink, { warehouseZoneId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getWarehouseZonesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<WarehouseZoneDtoPagedResult> => {
  if (resource) {
    const getWarehouseZonesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_WAREHOUSEZONES_LINK_KEY,
    );
    if (getWarehouseZonesListLink) {
      const result = await execLink(getWarehouseZonesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_WAREHOUSEZONES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createWarehouseZoneRequest = async (
  resource: LinkResourceBaseDto,
  warehouseZone: CreateWarehouseZoneCommandValues,
) => {
  const createWarehouseZoneLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_WAREHOUSEZONE_LINK_KEY,
  );
  if (createWarehouseZoneLink) {
    const result = await execLink(createWarehouseZoneLink, warehouseZone);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateWarehouseZoneRequest = async (
  resource: LinkResourceBaseDto,
  warehouseZone: UpdateWarehouseZoneCommandValues,
) => {
  const updateWarehouseZoneLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_WAREHOUSEZONE_LINK_KEY,
  );
  if (updateWarehouseZoneLink) {
    const result = await execLink(updateWarehouseZoneLink, warehouseZone);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteWarehouseZoneRequest = async (
  warehouseZone: WarehouseZoneDto,
) => {
  const deleteWarehouseZoneLink = warehouseZone.links?.find(
    (x: LinkDto) => x.rel === DELETE_WAREHOUSEZONE_LINK_KEY,
  );
  if (deleteWarehouseZoneLink) {
    const result = await execLink(deleteWarehouseZoneLink, warehouseZone);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importWarehouseZonesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportWarehouseZonesParams,
  file: File,
) => {
  const importWarehouseZonesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_WAREHOUSEZONES_LINK_KEY,
  );
  if (importWarehouseZonesLink) {
    const result = await postFormData(importWarehouseZonesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_WAREHOUSEZONES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportWarehouseZonesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportWarehouseZonesParams,
) => {
  const exportWarehouseZonesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_WAREHOUSEZONES_LINK_KEY,
  );
  if (exportWarehouseZonesLink) {
    const result = await downloadLinkFile(exportWarehouseZonesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_WAREHOUSEZONES_LINK_KEY} is not allowed or missing link action`,
  );
};
