import {
  LinkDto,
  LinkResourceBaseDto,
  Column,
  CustomCodeDto,
  UpdateCustomCodeCommandValues,
  CreateCustomCodeCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCustomCodeRequest,
  getCustomCodesListRequest,
  getCustomCodeRequest,
  deleteCustomCodeRequest,
  updateCustomCodeRequest,
  GetCustomCodeParams,
} from './customCodes.service';

type CustomCodesStoreState = {
  links: LinkDto[];
  customCodesColumns: Column[];
};

export const updateColumns = createEvent<Column[]>();

export const getCustomCodes = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');
  return getCustomCodesListRequest(currentOrganization, params);
});

export const createCustomCode = createEffect(
  (customCodeData: CreateCustomCodeCommandValues) => {
    const { currentOrganization } = organizationsStore.getState();
    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return createCustomCodeRequest(currentOrganization, customCodeData);
  },
);

export const getCustomCode = createEffect(({ code }: GetCustomCodeParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCustomCodeRequest(currentOrganization as LinkResourceBaseDto, {
    code,
  });
});

export const updateCustomCode = createEffect((customCode: CustomCodeDto) => {
  const updateCommand: UpdateCustomCodeCommandValues = { ...customCode };
  return updateCustomCodeRequest(customCode, updateCommand);
});

export const deleteCustomCode = createEffect((customCode: CustomCodeDto) => {
  return deleteCustomCodeRequest(customCode);
});

const defaultState: CustomCodesStoreState = {
  links: [],
  customCodesColumns: [
    {
      name: 'code',
      visible: true,
      title: 'Customs Code',
      sortName: 'code',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'codeType',
      visible: true,
      title: 'Code Type',
      sortName: 'codeType',
    },
    {
      name: 'isCommonlyUsed',
      visible: true,
      title: 'Is Commonly Used',
      sortName: 'isCommonlyUsed',
    },
  ],
};

export const customCodesStore: Store<CustomCodesStoreState> = createStore(
  defaultState,
)
  .on(getCustomCodes.done, (state, payload) => {
    const { result: customCodes } = payload;
    return { ...state, links: customCodes.links || state.links };
  })
  .on(updateColumns, (state, payload) => {
    return { ...state, customCodesColumns: payload };
  });
