import {
  WarehouseZoneDto,
  LinkDto,
  LinkResourceBaseDto,
  WarehouseZoneDtoPagedResult,
  CreateWarehouseZoneCommandValues,
  UpdateWarehouseZoneCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_WAREHOUSEZONES_LINK_KEY = 'get-warehousezones';
export const CREATE_WAREHOUSEZONE_LINK_KEY = 'create-warehousezone';
export const GET_WAREHOUSEZONE_LINK_KEY = 'get-warehousezone';
export const UPDATE_WAREHOUSEZONE_LINK_KEY = 'update-warehousezone';
export const DELETE_WAREHOUSEZONE_LINK_KEY = 'delete-warehousezone';

export const WAREHOUSEZONE_TYPE_ENTITY_NAME = 'Warehouse Zone';

export interface GetWarehouseZoneParams {
  warehouseZoneId: number;
}

export const getWarehouseZoneRequest = async (
  resource: LinkResourceBaseDto | null,
  { warehouseZoneId }: GetWarehouseZoneParams,
): Promise<WarehouseZoneDto> => {
  if (resource && warehouseZoneId) {
    const getWarehouseZoneLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_WAREHOUSEZONE_LINK_KEY,
    );
    if (getWarehouseZoneLink) {
      const result = await execLink(getWarehouseZoneLink, { warehouseZoneId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getWarehouseZonesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<WarehouseZoneDtoPagedResult> => {
  if (resource) {
    const getWarehouseZonesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_WAREHOUSEZONES_LINK_KEY,
    );
    if (getWarehouseZonesListLink) {
      const result = await execLink(getWarehouseZonesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_WAREHOUSEZONES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createWarehouseZoneRequest = async (
  resource: LinkResourceBaseDto,
  warehouseZone: CreateWarehouseZoneCommandValues,
) => {
  const createWarehouseZoneLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_WAREHOUSEZONE_LINK_KEY,
  );
  if (createWarehouseZoneLink) {
    const result = await execLink(createWarehouseZoneLink, warehouseZone);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateWarehouseZoneRequest = async (
  resource: LinkResourceBaseDto,
  warehouseZone: UpdateWarehouseZoneCommandValues,
) => {
  const updateWarehouseZoneLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_WAREHOUSEZONE_LINK_KEY,
  );
  if (updateWarehouseZoneLink) {
    const result = await execLink(updateWarehouseZoneLink, warehouseZone);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteWarehouseZoneRequest = async (
  warehouseZone: WarehouseZoneDto,
) => {
  const deleteWarehouseZoneLink = warehouseZone.links?.find(
    (x: LinkDto) => x.rel === DELETE_WAREHOUSEZONE_LINK_KEY,
  );
  if (deleteWarehouseZoneLink) {
    const result = await execLink(deleteWarehouseZoneLink, warehouseZone);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_WAREHOUSEZONE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
