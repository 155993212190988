import { Dialog } from '../../common/components/dialog/dialog.component';
import { BusinessRuleEdit } from './businessRule-edit.component';
import { BusinessRuleDto } from '../../../models/data.models';

export type BusinessRuleDialogProperties = {
  businessRuleId?: number | null;
  title: string;
};

export class BusinessRuleDialog extends Dialog<BusinessRuleDialogProperties> {
  constructor(props: BusinessRuleDialogProperties) {
    super(props);
    this.onBusinessRuleSaved = this.onBusinessRuleSaved.bind(this);
  }

  onBusinessRuleSaved = (businessRule: BusinessRuleDto) => {
    this.close(businessRule);
  };

  renderContent(): any {
    const { businessRuleId } = this.props as BusinessRuleDialogProperties;
    return (
      <BusinessRuleEdit
        businessRuleId={businessRuleId}
        onBusinessRuleCreated={this.onBusinessRuleSaved}
        onBusinessRuleUpdated={this.onBusinessRuleSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
