import React from 'react';
import {
  ContactType,
  CustomFieldDto,
  EntityTypes,
  OrderEntityDto,
} from '../../../../models/data.models';
import { CustomFieldsLayout } from '../../../common/components/form/customFields-layout.component';
import { Button } from '../../../common/components/button/button.component';
import { AiFillPlusCircle } from 'react-icons/ai';
import { CarrierForm } from '../../components/form-items/carrier-form.component';
import { OrderEditFormDto } from '../../../../models/custom.models';

export type RoutingFragmentProps = {
  handleNext: () => void;
  handleBack: () => void;
  airOrderContextFormValue: OrderEditFormDto;
  setAirOrderContextFormValue: (airOrder: any) => void;
  customFields: CustomFieldDto[];
};

export const RoutingFragment = ({
  handleNext,
  handleBack,
  airOrderContextFormValue,
  setAirOrderContextFormValue,
  customFields,
}: RoutingFragmentProps) => {
  const getInitialStateOrderEntities = (
    contactType: ContactType,
    entityType: EntityTypes,
  ) => {
    const initialStateOrderCarrier: OrderEntityDto = {
      orderEntityId: 0,
      contactAddressId: null,
      contactAddressName: null,
      contactId: null,
      contactName: null,
      contactType: contactType,
      created: null,
      createdBy: null,
      date: null,
      notes: '',
      lastModified: null,
      lastModifiedBy: null,
      orderEntitySequence: 0,
      orderId: 0,
      contactCityName: null,
      contactStateCode: null,
      entityType: entityType,
      customValues: {},
      links: [],
    };
    return initialStateOrderCarrier;
  };
  const createNewItemOrderEntityCarrier = () => {
    setAirOrderContextFormValue((airOrderContextFormValueDto) => {
      if (!airOrderContextFormValueDto.orderEntityCarriers) {
        airOrderContextFormValueDto.orderEntityCarriers = [];
      }
      airOrderContextFormValueDto.orderEntityCarriers.push({
        ...getInitialStateOrderEntities(
          ContactType.Carrier,
          EntityTypes.Carrier,
        ),
      });
      return { ...airOrderContextFormValueDto };
    });
  };

  const getCarrierContact = (index: number): any => {
    return {
      contactId:
        airOrderContextFormValue?.orderEntityCarriers[index]?.contactId,
      name: airOrderContextFormValue?.orderEntityCarriers[index]?.contactName,
      contactType:
        airOrderContextFormValue?.orderEntityCarriers[index]?.contactType,
    };
  };

  const checkInitialState = (
    orderDto: OrderEditFormDto,
    carrierContactIndex?: number,
  ): void => {
    if (!orderDto.orderEntityCarriers[carrierContactIndex]) {
      orderDto.orderEntityCarriers[
        carrierContactIndex
      ] = getInitialStateOrderEntities(
        ContactType.Carrier,
        EntityTypes.Carrier,
      );
    }
  };

  const setCarrierContact = (
    contactId: number,
    name: string,
    contactType: ContactType,
    carrierContactIndex?: number,
  ) => {
    setAirOrderContextFormValue((orderDto) => {
      checkInitialState(orderDto, carrierContactIndex);
      orderDto.orderEntityCarriers[carrierContactIndex].contactId = Number(
        contactId,
      );
      orderDto.orderEntityCarriers[carrierContactIndex].contactName = name;
      orderDto.orderEntityCarriers[
        carrierContactIndex
      ].contactType = contactType;
      return { ...orderDto };
    });
  };

  const deleteItemOrderCarrierItem = (indexItemToDelete?: number) => {
    setAirOrderContextFormValue((airOrderContextFormValueDto) => {
      airOrderContextFormValueDto.orderEntityCarriers?.splice(
        indexItemToDelete,
        1,
      );
      return { ...airOrderContextFormValueDto };
    });
  };

  const getCarriers = () => {
    if (
      airOrderContextFormValue?.orderEntityCarriers != null &&
      airOrderContextFormValue?.orderEntityCarriers !== undefined
    ) {
      return airOrderContextFormValue?.orderEntityCarriers?.map(
        (item, index) => (
          <CarrierForm
            index={index}
            allowDelete={
              airOrderContextFormValue?.orderEntityCarriers?.length > 1
            }
            setCarrierFilter={(filter: string): void => {}}
            carrierFilter={''}
            carrierContact={getCarrierContact(index)}
            setCarrierContact={(contactId, name, contactType) => {
              setCarrierContact(contactId, name, contactType, index);
            }}
            onCarrierDeleted={() => deleteItemOrderCarrierItem(index)}
            customValues={item.customValues}
            onChange={(result) => {
              setAirOrderContextFormValue((purchaseContextFormValue) => {
                purchaseContextFormValue.orderEntityCarriers[
                  index
                ].customValues = result;
                return { ...purchaseContextFormValue };
              });
            }}
            customFields={customFields}
          />
        ),
      );
    }
  };
  return (
    <div>
      <div className="row mb-3">
        <div className="col-3">
          <h2>Routing infromation</h2>
        </div>
      </div>
      <CustomFieldsLayout
        inputNamePrefix=""
        rows={[
          { rowNumber: 1 },
          { rowNumber: 2, header: 'Departure' },
          { rowNumber: 3, header: 'Arrival' },
        ]}
        filter={'routing'}
        customFields={customFields}
        defaultValue={airOrderContextFormValue.customValues}
        onChange={(result) => {
          setAirOrderContextFormValue((orderContextFormValue) => {
            orderContextFormValue.customValues = result;
            return orderContextFormValue;
          });
        }}
      />

      <hr className="my-4 mb-5" />

      <div className="d-flex mb-3">
        <h2>Carrier</h2>
        <AiFillPlusCircle
          onClick={createNewItemOrderEntityCarrier}
          className="i-top-bar ml-2 h-100 align-middle font-size-sm i-top-bar-blue"
          data-testid="btn-create-new-carrier"
        />
      </div>
      {getCarriers()}
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button
          className="mr-2"
          color="primary"
          onClick={handleNext}
          style={{ minWidth: '8.5rem' }}
        >
          Next
        </Button>
        <Button
          className="mr-3"
          onClick={handleBack}
          style={{ minWidth: '8.5rem' }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};
