import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useCallback, useEffect, useState } from 'react';
import { parseObjectTemplate, parseTemplate } from '../component-hooks';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { debounce } from 'lodash';
import logger from '../../../logger';
import { parseGeocodeResults } from '../../../../../utils/helper.utils';

export const AutocompleteComponentGooglePlaces = ({
  field,
  form,
  componentProps,
  variables,
  options,
  disabled,
  ...props
}) => {
  const [selected, setSelected] = useState(!!field.value ? [field.value] : []);
  const [items, setItems] = useState(componentProps.props?.items ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setSelected(!!field.value ? [field.value] : []);
  }, [field]);

  useEffect(() => {
    if (options) {
      setIsLoaded(true);
    }
  }, [options]);

  const onSearch = useCallback(
    async (search) => {
      setIsLoading(true);
      if (!search) return;

      try {
        const minLength = componentProps.props?.minLength ?? 3;
        if (search.length >= minLength) {
          // check if  google.maps.places library is loaded
          if (!google.maps.places) {
            logger.error(
              'google.maps.places library is not loaded. use script component to load library',
            );
            return;
          }

          // Call the Google Places API
          const service = new google.maps.places.AutocompleteService();

          const request = parseTemplate(
            options.searchQuery?.params ?? {
              input: '{{search}}',
              language: 'en',
            },
            {
              ...variables,
              ...componentProps?.context?.store,
              ...form.values,
              search,
            },
          );

          service.getPlacePredictions(request, (predictions, status) => {
            if (
              status !== google.maps.places.PlacesServiceStatus.OK &&
              status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              logger.error('Error fetching place predictions', status);
              setIsLoading(false);
              return;
            }
            // Transform the results to the format required by the Typeahead component
            const transformedOptions = predictions?.map((prediction) => ({
              label: parseTemplate(options.itemLabelTemplate, prediction) ?? '',
              ...prediction,
            }));

            setItems(transformedOptions ?? []);
          });
        } else {
          setItems([]);
        }
      } catch (error) {
        logger.error('Error fetching place predictions', error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      isLoaded,
      setItems,
      variables,
      componentProps?.context?.store,
      form?.values,
    ],
  );

  const searchBounced = useCallback(debounce(onSearch, 1000), [onSearch]);

  const onInputChange = useCallback(
    async (input) => {
      form.setFieldValue(field.name, input);
      setSelected([input]);
      onSearch(input);
    },
    [searchBounced, onSearch],
  );

  const onChange = useCallback(
    (selectedVals) => {
      if (componentProps.props?.multiple) {
        return;
      }

      if (selectedVals.length > 0) {
        // get postal code from google places
        const placeService = new google.maps.places.PlacesService(
          document.createElement('div'),
        );

        const request = {
          placeId: selectedVals[0].place_id,
          fields: ['address_components', 'formatted_address'],
          language: 'en',
          ...options.valueQuery?.params,
        };

        placeService.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const parsedPlace = parseGeocodeResults(place);

            const parsedValue = parseTemplate(
              options.itemLabelTemplate ?? '{{description}}',
              { ...selectedVals[0], ...parsedPlace },
            );

            setSelected([parsedValue]);
            form.setFieldValue(field.name, parsedValue);

            if (componentProps?.props?.onSelectValue) {
              const prefix =
                componentProps?.props?.prefix?.toString()?.trim() ?? '';
              const componentPrefix = parseTemplate(prefix, { ...variables });

              const formValues = {
                ...form.values,
                selectedItem: parsedPlace,
                selectedValue: parsedValue,
              };

              const onSelectValue = parseObjectTemplate(
                componentProps?.props?.onSelectValue,
                {
                  ...variables,
                  ...formValues,
                  prefix: componentPrefix,
                },
              );

              componentProps.context?.action(
                onSelectValue,
                {
                  selectedItem: parsedPlace,
                  selectedValue: parsedValue,
                  ...componentProps?.variables,
                },
                {
                  sender: field.name,
                  formikContext: form,
                },
              );
            }
          }
        });
      }
    },
    [componentProps, options, field, form, variables],
  );

  const onBlur = useCallback(() => {
    form.setFieldTouched(field.name, true);
  }, []);

  const filterBy = useCallback(() => true, []);

  return (
    <AsyncTypeahead
      id={field.name}
      labelKey={componentProps.props?.labelKey ?? 'description'}
      options={items}
      multiple={componentProps.props?.multiple}
      placeholder={componentProps.props?.placeholder}
      minLength={1}
      filterBy={filterBy} // bypass filterBy
      onBlur={onBlur}
      onChange={onChange}
      isLoading={isLoading}
      defaultSelected={selected}
      selected={selected}
      onSearch={onSearch}
      allowNew={componentProps.props?.allowNew}
      onInputChange={onInputChange}
      disabled={disabled}
      useCache={false}
      promptText={'Nothing Found'}
    />
  );
};
