import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  getOrders,
  getOrdersContactCommodities,
} from '../../../orders/orders.store';
import { OrderDtoForListPagedResult } from '../../../../models/custom.models';
import {
  Column,
  CommodityDto,
  ContactDtoPagedResult,
  ContactType,
  OrderDto,
  OrderDtoPagedResult,
  OrderTypes,
  WarehouseLocationDtoPagedResult,
  WarehouseZoneDtoPagedResult,
} from '../../../../models/data.models';
import { WizardGrid } from '../common/wizardGrid.component';
import { ListElementsNotFound } from '../../../common/components/list-elements-not-found/list-elements-not-found.component';
import { showDialog } from '../../../common/dialog.store';
import { PurchaseDialog } from '../../../purchases/components/purchase.dialog';
import { ParcelShipmentDialog } from '../../../parcelShipments/components/parcelShipment.dialog';
import { CustomerDialog } from '../../../customers/components/customer.dialog';
import { WarehouseReceiptDialog } from '../../../warehouseReceipts/components/warehouseReceipt.dialog';
import { Card } from 'react-bootstrap';
import { getWarehouseZonesFx } from '../../../warehouseInspector/warehouseInspector.store';
const INCLUDE_ZONE_COMMODITIES = 'include_commodities';

export type CargoFragmentProps = {
  commodities?: CommodityDto[];
  setCommodities?: (commodities: CommodityDto[]) => void;
  commodityIds?: number[];
  setCommodityIds?: (commodityIds: number[]) => void;
  orders?: OrderDto[];
  setOrders?: (orders: OrderDto[]) => void;
  handleNext?: () => void;
  handleBack?: () => void;
  hiddenTabs?: CargoFragmentTab[];
};

const columns: Column[] = [
  {
    name: 'commodities',
    visible: true,
    title: 'Select',
    showFilter: false,
    sortName: 'orderNumber~ToInt32',
    type: 'checkBox',
  },
  {
    name: 'billToContactName',
    visible: true,
    title: 'Customer / Lead',
    type: 'contact',
    contactType: ContactType.Customer,
    contactTypes: [
      ContactType.Customer,
      ContactType.Vendor,
      ContactType.ForwardingAgent,
      ContactType.Contact,
    ],
    dataField: 'BillToContactId',
    showFilter: true,
    sortName: 'BillToContact.Name',
  },
];

const locationColumns: Column[] = [
  {
    name: 'commodities',
    visible: true,
    title: 'Select',
    showFilter: false,
    sortName: 'warehouseLocationId',
    type: 'checkBox',
    labelToShow: 'description',
  },
  {
    name: 'customerName',
    visible: true,
    title: 'Customer',
    sortName: 'customerName',
  },
];

const customerColumns: Column[] = [
  {
    name: 'commodities',
    visible: true,
    title: 'Select',
    showFilter: false,
    sortName: 'orderId',
    type: 'checkBox',
    labelToShow: 'billToContactName',
  },
];

const warehouseColumns: Column[] = [
  {
    name: 'commodities',
    visible: true,
    title: 'Select',
    showFilter: false,
    sortName: 'orderId',
    type: 'checkBox',
    labelToShow: 'orderNumber',
  },
  {
    name: 'billToContactName',
    visible: true,
    title: 'Customer',
    sortName: 'billToContactName',
  },
  {
    name: 'expDate',
    visible: true,
    title: 'Date',
    sortName: 'expDate',
  },
];

const purchaseColumns: Column[] = [
  {
    name: 'commodities',
    visible: true,
    title: 'Select',
    showFilter: false,
    sortName: 'orderId',
    type: 'checkBox',
    labelToShow: 'trackingNumber',
  },
  {
    name: 'billToContactName',
    visible: true,
    title: 'Customer',
    sortName: 'billToContactName',
  },
  {
    name: 'orderStatus',
    visible: true,
    title: 'Status',
    sortName: 'orderStatus',
  },
];

const parcelShipmentColumns: Column[] = [
  {
    name: 'commodities',
    visible: true,
    title: 'Select',
    showFilter: false,
    sortName: 'orderId',
    type: 'checkBox',
    labelToShow: 'trackingNumber',
  },
  {
    name: 'billToContactName',
    visible: true,
    title: 'Customer',
    sortName: 'billToContactName',
  },
  {
    name: 'contactCityName',
    visible: true,
    title: 'Destination',
    sortName: 'contactCityName',
  },
  {
    name: 'orderStatus',
    visible: true,
    title: 'Status',
    sortName: 'orderStatus',
  },
  {
    name: 'created',
    visible: true,
    title: 'Created Date',
    sortName: 'created',
  },
];

export enum CargoFragmentTab {
  Warehouse = 'Warehouse',
  Location = 'Location',
  Purchase = 'Purchase',
  Customer = 'Customer',
  ParcelShipment = 'ParcelShipment',
}

export const CargoFragment = ({
  commodities = [],
  setCommodities = () => {},
  commodityIds = [],
  setCommodityIds = () => {},
  orders = [],
  setOrders = () => {},
  handleBack = null,
  handleNext = null,
  hiddenTabs = [],
}: CargoFragmentProps) => {
  const sort = 'orderId';

  const [
    warehouseReceipts,
    setWarehouseReceipts,
  ] = useState<OrderDtoForListPagedResult>();
  const [purchases, setPurchases] = useState<OrderDtoForListPagedResult>();
  const [
    parcelShipments,
    setParcelShipments,
  ] = useState<OrderDtoForListPagedResult>();
  const [
    warehouseLocations,
    setWarehouseLocations,
  ] = useState<WarehouseLocationDtoPagedResult>();

  const [
    warehouseZones,
    setWarehouseZones,
  ] = useState<WarehouseZoneDtoPagedResult>();

  const [
    customersOrders,
    setCustomersOrders,
  ] = useState<ContactDtoPagedResult>();
  const [warehouseLimit, setWarehouseLimit] = useState<number>(10);
  const [purchaseLimit, setPurchaseLimit] = useState<number>(10);
  const [parcelLimit, setParcelLimit] = useState<number>(10);
  const [locationLimit, setLocationLimit] = useState<number>(10);
  const [customersOrdersLimit, setCustomersOrdersLimit] = useState<number>(10);
  const [warehouseSearch, setWarehouseSearch] = useState('');
  const [purchaseSearch, setPurchaseSearch] = useState('');
  const [parcelSearch, setParcelSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [customersOrdersSearch, setCustomerOrdersSearch] = useState('');

  const getWarehouseReceiptsData = (offset) => {
    getOrders({
      offset: offset * warehouseLimit,
      limit: warehouseLimit,
      sort,
      filter: `OrderType:${OrderTypes.WarehouseReceipt} AND OrderCommodities.count: [1 TO *]`,
      search: warehouseSearch,
    }).then(
      (ordersData: OrderDtoPagedResult) => {
        setWarehouseReceipts(ordersData);
      },
      () => {},
    );
  };

  const onWarehouseReceiptSelect = (warehouseReceipt, orderId) => {
    if (warehouseReceipt?.orderId) {
      showDialog({
        dialog: WarehouseReceiptDialog,
        props: {
          className: 'order-modal',
          title: 'Update Warehouse Receipt',
          orderId: warehouseReceipt?.orderId,
        },
      }).then(
        (result) => {
          if (result !== null) {
            getWarehouseReceiptsData(0);
          }
        },
        () => {},
      );
    }
  };

  const getPurchasesData = (offset) => {
    getOrders({
      offset: offset * purchaseLimit,
      limit: purchaseLimit,
      sort,
      filter: `OrderType:${OrderTypes.Purchase} AND OrderCommodities.count: [1 TO *]`,
      search: purchaseSearch,
    }).then(
      (ordersData: OrderDtoPagedResult) => {
        setPurchases(ordersData);
      },
      () => {},
    );
  };

  const onPurchaseSelect = (purchase, orderId) => {
    if (purchase?.orderId) {
      showDialog({
        dialog: PurchaseDialog,
        props: {
          className: 'order-modal',
          title: 'Update Purchase',
          orderId: purchase?.orderId,
        },
      }).then(
        (result) => {
          if (result !== null) {
            getPurchasesData(0);
          }
        },
        () => {},
      );
    }
  };

  const getParcelShipmentsData = (offset) => {
    getOrders({
      offset: offset * parcelLimit,
      limit: parcelLimit,
      sort,
      filter: `OrderType:${OrderTypes.ParcelShipment} AND OrderCommodities.count: [1 TO *]`,
      search: parcelSearch,
    }).then(
      (ordersData: OrderDtoPagedResult) => {
        const newModelItems = ordersData.items?.map((order) => {
          return {
            orderId: order.orderId,
            billToContactId: order?.billToContactId,
            billToContactName: order?.billToContactName,
            billToContactType: order?.billToContactType,
            trackingNumber: order?.trackingNumber,
            contactCityName: order?.orderEntities[0]?.contactCityName,
            orderStatus: order.orderStatus,
            commodities: order.commodities,
            totalPcsCrt: order?.totalPcsCrt,
            weighTotal: order.weighTotal,
            volumeTotal: order.volumeTotal,
            carriers: order.carriers,
            createdByUserName: order.createdByUserName,
            lastModifiedByUserName: order.lastModifiedByUserName,
            created: order.created,
          };
        });
        setParcelShipments({
          totalCount: ordersData.totalCount,
          items: newModelItems,
        });
      },
      () => {},
    );
  };

  const onParcelShipmentSelect = (parcelShipment, orderId) => {
    if (parcelShipment?.orderId) {
      showDialog({
        dialog: ParcelShipmentDialog,
        props: {
          className: 'order-modal',
          title: 'Update Parcel Shipment',
          orderId: parcelShipment?.orderId,
        },
      }).then(
        (result) => {
          if (result !== null) {
            getParcelShipmentsData(0);
          }
        },
        () => {},
      );
    }
  };

  const getWarehouseZonesData = (offset) => {
    getWarehouseZonesFx({
      offset: offset * locationLimit,
      limit: locationLimit,
      sort: 'warehouseZoneId',
      search: INCLUDE_ZONE_COMMODITIES,
    }).then(
      (warehouseZonesData: WarehouseZoneDtoPagedResult) => {
        setWarehouseZones(warehouseZonesData);
      },
      () => {},
    );
  };

  const getContactsOrdersData = (offset) => {
    getOrdersContactCommodities({
      offset: offset * customersOrdersLimit,
      limit: customersOrdersLimit,
      sort: 'billToContactId',
      filter: 'OrderCommodities.count: [1 TO *]',
      search: 'customersOrdersSearch',
    }).then(
      (customersData: OrderDtoPagedResult) => {
        customersData.items.forEach((order) => {
          order.commodities = order.commodities.filter(
            (item, index, self) =>
              index ===
              self.findIndex((i) => i.commodityId === item.commodityId),
          );
        });
        setCustomersOrders(customersData);
      },
      () => {},
    );
  };

  const onCustomerSelect = (customerOrder, orderId) => {
    if (customerOrder?.billToContactId) {
      showDialog({
        dialog: CustomerDialog,
        props: {
          title: 'Update Customer',
          contactId: customerOrder?.billToContactId,
          disableDots: true,
        },
      }).then((result) => {
        getContactsOrdersData(0);
      });
    }
  };

  const toggleSelectedId = (
    orderCommodities: any[],
    toggleState: boolean,
    selectedOrder: any,
  ) => {
    if (!toggleState) {
      setCommodityIds(
        commodityIds.filter(
          (id) =>
            !orderCommodities.some(
              (orderCommodity) => orderCommodity.commodityId === id,
            ),
        ),
      );
      setCommodities(
        commodities.filter(
          (c) =>
            !orderCommodities.some(
              (orderCommodity) => orderCommodity.commodityId === c.commodityId,
            ),
        ),
      );
      setOrders(
        orders.filter((order) => order.orderId != selectedOrder?.orderId),
      );
    } else {
      setCommodityIds([
        ...commodityIds,
        ...orderCommodities.map((x) => {
          return x.commodityId;
        }),
      ]);
      setCommodities([...commodities, ...orderCommodities]);
      setOrders([...orders, selectedOrder]);
    }
  };

  const isCommoditiesSelected = (value: any[]) => {
    if (value?.length > 0) {
      const ids = value.map((x) => x.commodityId);
      return ids.every((id) => commodityIds.includes(id));
    }
    return false;
  };

  const isCheckBoxDisabled = (commodities: CommodityDto[]) => {
    return commodities?.length === 0;
  };

  const isTabHidden = (tabKey: CargoFragmentTab) => {
    return hiddenTabs.includes(tabKey);
  };

  const defaultTabIndex = () => {
    for (const tab in CargoFragmentTab) {
      if (!hiddenTabs.includes(CargoFragmentTab[tab])) {
        return Object.values(CargoFragmentTab).indexOf(CargoFragmentTab[tab]);
      }
    }
  };

  useEffect(() => {
    getWarehouseReceiptsData(0);
  }, [warehouseLimit, warehouseSearch]);

  useEffect(() => {
    getPurchasesData(0);
  }, [purchaseLimit, purchaseSearch]);

  useEffect(() => {
    getParcelShipmentsData(0);
  }, [parcelLimit, parcelSearch]);

  useEffect(() => {
    getWarehouseZonesData(0);
  }, [locationLimit, locationSearch]);

  useEffect(() => {
    getContactsOrdersData(0);
  }, [customersOrdersLimit, customersOrdersSearch]);

  return (
    <div className="bg-tetriaty">
      <Tabs defaultIndex={defaultTabIndex()}>
        <div>
          <TabList>
            <div className="d-flex justify-content-between pt-4">
              <span>
                <Tab
                  hidden={isTabHidden(CargoFragmentTab.Warehouse)}
                  data-cy={'warehouse'}
                >
                  Warehouse receipt
                </Tab>
                <Tab
                  hidden={isTabHidden(CargoFragmentTab.Location)}
                  data-cy={'location'}
                >
                  Location
                </Tab>
                <Tab
                  hidden={isTabHidden(CargoFragmentTab.Purchase)}
                  data-cy={'purchase'}
                >
                  Purchase
                </Tab>
                <Tab
                  hidden={isTabHidden(CargoFragmentTab.Customer)}
                  data-cy={'customer'}
                >
                  Customer
                </Tab>
                <Tab
                  hidden={isTabHidden(CargoFragmentTab.ParcelShipment)}
                  data-cy={'parcel-shipment'}
                >
                  Parcel Shipment
                </Tab>
              </span>
            </div>
          </TabList>
        </div>
        <div>
          <TabPanel>
            <Card className="m-3 p-3">
              <WizardGrid
                onSearch={(search) => setWarehouseSearch(search)}
                search={warehouseSearch}
                showEmptyTable={true}
                showAllStore={true}
                rowKeys={['orderId']}
                data={warehouseReceipts?.items}
                columns={warehouseColumns}
                offset={warehouseReceipts?.offset}
                limit={warehouseLimit}
                total={warehouseReceipts?.totalCount}
                sort={sort}
                onSort={null}
                onEdit={null}
                onPageChanged={getWarehouseReceiptsData}
                onColumnsChanged={null}
                showPagination={true}
                onSelect={onWarehouseReceiptSelect}
                setIds={toggleSelectedId}
                hideColumnsSelect={true}
                isSelected={isCommoditiesSelected}
                isCheckBoxDisabled={isCheckBoxDisabled}
                onLimitChange={setWarehouseLimit}
                isDropDownList={true}
                handleNext={handleNext}
                handleBack={handleBack}
              />
              {!(
                warehouseReceipts &&
                warehouseReceipts.items &&
                warehouseReceipts.items.length
              ) && (
                <ListElementsNotFound
                  entityName="Warehouse Receits"
                  entityLinkKey={null}
                  onClick={null}
                />
              )}
            </Card>
          </TabPanel>
          <TabPanel>
            <Card className="m-3 p-3">
              <WizardGrid
                onSearch={(search) => setLocationSearch(search)}
                search={locationSearch}
                showEmptyTable={true}
                showAllStore={true}
                rowKeys={['warehouseLocationId']}
                data={warehouseZones?.items}
                columns={locationColumns}
                offset={warehouseZones?.offset}
                limit={locationLimit}
                total={warehouseZones?.totalCount}
                sort={sort}
                onSort={null}
                onEdit={null}
                onPageChanged={getWarehouseZonesData}
                onColumnsChanged={null}
                onSelect={null}
                setIds={toggleSelectedId}
                hideColumnsSelect={true}
                isSelected={isCommoditiesSelected}
                isCheckBoxDisabled={isCheckBoxDisabled}
                onLimitChange={setLocationLimit}
                isDropDownList={true}
                handleNext={handleNext}
                handleBack={handleBack}
              />
              {!(
                warehouseZones &&
                warehouseZones.items &&
                warehouseZones.items.length
              ) && (
                <ListElementsNotFound
                  entityName="Warehouse Location"
                  entityLinkKey={null}
                  onClick={null}
                />
              )}
            </Card>
          </TabPanel>
          <TabPanel>
            <Card className="m-3 p-3">
              <WizardGrid
                onSearch={(search) => setPurchaseSearch(search)}
                search={purchaseSearch}
                showEmptyTable={true}
                showAllStore={true}
                rowKeys={['orderId']}
                data={purchases?.items}
                columns={purchaseColumns}
                offset={purchases?.offset}
                limit={purchaseLimit}
                total={purchases?.totalCount}
                sort={sort}
                onSort={null}
                onEdit={null}
                onPageChanged={getPurchasesData}
                onColumnsChanged={null}
                onSelect={onPurchaseSelect}
                setIds={toggleSelectedId}
                isSelected={isCommoditiesSelected}
                hideColumnsSelect={true}
                isCheckBoxDisabled={isCheckBoxDisabled}
                onLimitChange={setPurchaseLimit}
                isDropDownList={true}
                handleNext={handleNext}
                handleBack={handleBack}
              />
              {!(purchases && purchases.items && purchases.items.length) && (
                <ListElementsNotFound
                  entityName="Purchase"
                  entityLinkKey={null}
                  onClick={null}
                />
              )}
            </Card>
          </TabPanel>
          <TabPanel>
            <Card className="m-3 p-3">
              <WizardGrid
                onSearch={(search) => setCustomerOrdersSearch(search)}
                search={customersOrdersSearch}
                showEmptyTable={true}
                showAllStore={true}
                rowKeys={['orderId']}
                data={customersOrders?.items}
                columns={customerColumns}
                offset={customersOrders?.offset}
                limit={customersOrdersLimit}
                total={customersOrders?.totalCount}
                sort={sort}
                onSort={null}
                onEdit={null}
                onPageChanged={getContactsOrdersData}
                onColumnsChanged={null}
                onSelect={onCustomerSelect}
                setIds={toggleSelectedId}
                hideColumnsSelect={true}
                isSelected={isCommoditiesSelected}
                isCheckBoxDisabled={isCheckBoxDisabled}
                onLimitChange={setCustomersOrdersLimit}
                isDropDownList={true}
                handleNext={handleNext}
                handleBack={handleBack}
              />
              {!(
                customersOrders &&
                customersOrders.items &&
                customersOrders.items.length
              ) && (
                <ListElementsNotFound
                  entityName="Customer"
                  entityLinkKey={null}
                  onClick={null}
                />
              )}
            </Card>
          </TabPanel>
          <TabPanel>
            <Card className="m-3 p-3">
              <WizardGrid
                onSearch={(search) => setParcelSearch(search)}
                search={parcelSearch}
                showEmptyTable={true}
                showAllStore={true}
                rowKeys={['orderId']}
                data={parcelShipments?.items}
                columns={parcelShipmentColumns}
                offset={parcelShipments?.offset}
                limit={parcelLimit}
                total={parcelShipments?.totalCount}
                sort={sort}
                onSort={null}
                onEdit={null}
                onPageChanged={getParcelShipmentsData}
                onColumnsChanged={null}
                onSelect={onParcelShipmentSelect}
                setIds={toggleSelectedId}
                hideColumnsSelect={true}
                isSelected={isCommoditiesSelected}
                isCheckBoxDisabled={isCheckBoxDisabled}
                onLimitChange={setParcelLimit}
                isDropDownList={true}
                handleNext={handleNext}
                handleBack={handleBack}
              />
              {!(
                parcelShipments &&
                parcelShipments.items &&
                parcelShipments.items.length
              ) && (
                <ListElementsNotFound
                  entityName="Parcel Shipment"
                  entityLinkKey={null}
                  onClick={null}
                />
              )}
            </Card>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};
