import { Dropdown } from 'react-bootstrap';
import { FiActivity } from 'react-icons/fi';
import { IAction } from './actions';

type ActionsDropdownProperties = {
  actions: IAction[];
  className?: string;
  size?: 'sm' | 'lg';
};

export const ActionsDropdown = ({
  actions,
  className = '',
  size = 'lg',
}: ActionsDropdownProperties) => {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        id="dropdownColumnsButton"
        className="pointer btn-toggle"
        variant="light"
        size={size}
      >
        <FiActivity /> 
        &nbsp;
        <span className="px-1">Actions</span>&nbsp;
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {actions?.length ? (
          actions?.map((action) => (
            <Dropdown.Item key={action.name} onClick={action.onAction}>
              {action.icon} {action.label}
            </Dropdown.Item>
          ))
        ) : (
          <span className="px-6 btn-toggle-text" color="">
            No Actions
          </span>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
