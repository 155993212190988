import {
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDtoPagedResult,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_ENTITYFIELDS_LINK_KEY = 'get-entityfields';

export interface GetEntityFieldsParams extends ListParams {
  entityName: string;
}

export const getEntityFieldListRequest = (
  resource?: LinkResourceBaseDto | null,
  params: GetEntityFieldsParams = {
    entityName: '',
  },
): Promise<EntityFieldDtoPagedResult> => {
  if (resource) {
    const getEntityFieldsLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ENTITYFIELDS_LINK_KEY,
    );
    if (getEntityFieldsLink) {
      return execLink(getEntityFieldsLink, params).then((result: any) => {
        return result.data;
      });
    }
  }
  addMessage({
    message: `Action ${GET_ENTITYFIELDS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
