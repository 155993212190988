import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto, PackageCategoryDto } from '../../../models/data.models';
import { ReceiveDriverPay } from './receiveDriverPay.component';

export type ReceiveDriverPayDialogProperties = {
  title: string;
  className?: string;
  driverContactId: number;
  orderIds: number[];
  onDriverPayReceived: () => void;
};

export class ReceiveDriverPayDialog extends Dialog<ReceiveDriverPayDialogProperties> {
  onDriverPayReceived: () => void;

  constructor(props: ReceiveDriverPayDialogProperties) {
    super(props);
    this.onDriverPayReceived = props.onDriverPayReceived.bind(this);
  }

  // onDriverPayReceived = (order: any) => {
  //   this.close(order);
  // };

  renderContent(): any {
    const { driverContactId, orderIds } = this
      .props as ReceiveDriverPayDialogProperties;
    return (
      <ReceiveDriverPay
        driverContactId={driverContactId}
        orderIds={orderIds}
        onDriverPayReceived={() => {
          this.onDriverPayReceived();
          this.close();
        }}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
