import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ActionEventForm } from './actionEvent.form';
import {
  ActionEventDto,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  CustomFieldType,
  EventStatus,
} from '../../../models/data.models';
import {
  createActionEventFx,
  deleteActionEventFx,
  getActionEventFx,
  updateActionEventFx,
} from '../actionEvents.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import {
  DELETE_ACTIONEVENT_LINK_KEY,
  UPDATE_ACTIONEVENT_LINK_KEY,
} from '../actionEvents.service';
import { userHas } from '../../auth/auth.store';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { Dropdown } from 'react-bootstrap';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { FiDelete, FiUpload } from 'react-icons/fi';
import { IAction, getAction } from '../../common/components/actions/actions';
import { AiFillDelete } from 'react-icons/ai';

export type ActionEventEditProps = {
  eventId?: string | null | undefined;
  onActionEventCreated?: (actionEvent: ActionEventDto) => void;
  onActionEventUpdated?: (actionEvent: ActionEventDto) => void;
  onActionEventLoaded?: (actionEvent: ActionEventDto) => void;
  onCancel?: () => void;
  onDelete?: (actionEvent: ActionEventDto) => void;
};

const initialState: ActionEventDto = {
  errorMessage: null,
  eventData: {},
  eventName: '',
  status: EventStatus.Completed,
  links: [],
};

export const ActionEventEdit = ({
  eventId,
  onActionEventLoaded,
  onActionEventCreated,
  onActionEventUpdated,
  onCancel,
  onDelete,
}: ActionEventEditProps) => {
  const [isCreateMode, setIsCreateMode] = useState(eventId == null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [actionEvent, setActionEvent] = useState<ActionEventDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setIsCreateMode(eventId == null);
  }, [eventId]);

  const actionEventSchema = useMemo(() => {
    let schema = Yup.object().shape({
      errorMessage: Yup.string().max(500).nullable(true),
      eventName: Yup.string().max(50).required('Event Name is required'),
    });

    if (customFields.length > 0) {
      schema = generateValidationSchemaWithCustomFields(customFields, schema);
    }

    return schema;
  }, [customFields]);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        DELETE_ACTIONEVENT_LINK_KEY,
        'Delete',
        <AiFillDelete />,
        async () => {
          const dialogResult = await showDialog({
            dialog: Confirm,
            props: {
              title: `Delete ActionEvent`,
              message: `Are you sure you want to delete ${actionEvent.eventName}?`,
              className: 'delete-modal',
            },
          });
          if (dialogResult) {
            try {
              await deleteActionEventFx(actionEvent);
              if (onCancel) onCancel();
            } catch (error) {
              // Handle error here
            }
          }
        },
        actionEvent?.links,
      ),
    ];
    return activeActions.filter(Boolean);
  }, [actionEvent]);

  const fetchActionEventData = useCallback(async () => {
    const actionEventDto = await getActionEventFx({ eventId });
    setActionEvent(actionEventDto);
    if (onActionEventLoaded) onActionEventLoaded(actionEventDto);
    setIsLoading(false);
  }, [eventId, onActionEventLoaded]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    if (!isCreateMode) {
      fetchActionEventData();
    } else {
      setActionEvent({ ...initialState });
      setIsLoading(false);
    }
  }, [isCreateMode, fetchActionEventData]);

  const onSubmit = useCallback(
    async (data: ActionEventDto) => {
      setIsSubmitting(true);
      try {
        const result = isCreateMode
          ? await createActionEventFx(data)
          : await updateActionEventFx(data);
        isCreateMode
          ? onActionEventCreated?.(result)
          : onActionEventUpdated?.(result);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isCreateMode, onActionEventCreated, onActionEventUpdated],
  );

  const onCustomFieldChange = (result) => {
    setActionEvent((prevState) => ({
      ...prevState,
      customValues: result,
    }));
  };

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <ActionEventForm
      id={'actionEvent-form'}
      initialValues={actionEvent || initialState}
      onSubmit={onSubmit}
      validationSchema={actionEventSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <li className="react-tabs__tab react-tabs__tab--button">
                  <ActionsDropdown size="sm" actions={actions} />
                </li>
              </TabList>

              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New Action Event</h3>
                  ) : (
                    <h3 className="header-form">Update Action Event</h3>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <ActionEventForm.EventName
                        defaultValue={actionEvent.eventName}
                      />
                      <ActionEventForm.EventData
                        defaultValue={actionEvent.eventData}
                      />
                      <ActionEventForm.ErrorMessage
                        defaultValue={actionEvent.errorMessage}
                      />
                      <ActionEventForm.Status
                        defaultValue={actionEvent.status}
                      />
                    </div>
                  </div>
                </Panel>
              </TabPanel>
            </Tabs>
            <div className="row m-3">
              <div className="col-auto text-right">
                {(userHas(UPDATE_ACTIONEVENT_LINK_KEY, actionEvent?.links) ||
                  isCreateMode) && (
                  <Button
                    name="save-actionEvent"
                    type="submit"
                    color="primary"
                    form={'actionEvent-form'}
                    disabled={isSubmitting}
                  >
                    Save ActionEvent
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="ml-2"
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </ActionEventForm>
  );
};
