import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { AccessDeniedScreen } from './screens/access-denied.screen';

export const ACCESS_DENIED_PATH = '/access-denied';

export const AccessDeniedRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AccessDeniedScreen pageTitle="Access Denied" />}
      />
    </Routes>
  );
};
