import React, { CSSProperties, useState } from 'react';

export interface DocumentTemplateTypeBadgeProps {
  documentType: string;
  style?: CSSProperties;
}

export const DocumentTemplateTypeBadge = ({
  documentType,
  style = {},
}: DocumentTemplateTypeBadgeProps) => {
  const getDocumentTemplateTypeByDocumentName = (
    documentType: string,
  ): string => {
    const prefix = 'status-badge-';

    if (documentType?.includes('pdf')) {
      return prefix + 'pdf';
    } else if (documentType?.includes('xlsx')) {
      return prefix + 'xlsx';
    } else {
      return prefix + 'jpg';
    }
  };

  const getLabel = (documentType: string) => {
    return documentType?.split('.')?.pop() || 'Creating';
  };

  return (
    <div
      className={`status-badge ${getDocumentTemplateTypeByDocumentName(
        documentType,
      )}`}
      style={style}
    >
      <div className={'status-badge-dot'} />
      {getLabel(documentType)}
    </div>
  );
};
