import {
  PaymentDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  PaymentDtoPagedResult,
  CreatePaymentCommandValues,
  UpdatePaymentCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_PAYMENTS_LINK_KEY = 'get-payments';
export const CREATE_PAYMENT_LINK_KEY = 'create-payment';
export const GET_PAYMENT_LINK_KEY = 'get-payment';
export const UPDATE_PAYMENT_LINK_KEY = 'update-payment';
export const DELETE_PAYMENT_LINK_KEY = 'delete-payment';
export const IMPORT_PAYMENTS_LINK_KEY = 'import-payments';
export const EXPORT_PAYMENTS_LINK_KEY = 'export-payments';
export const PAYMENT_ENTITY_NAME = 'Payment';

export interface GetPaymentParams {
  paymentId: number;
}

export interface ImportPaymentsParams {
  organizationId: number;
}

export interface ExportPaymentsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getPaymentRequest = async (
  resource: LinkResourceBaseDto | null,
  { paymentId }: GetPaymentParams,
): Promise<PaymentDto> => {
  if (resource && paymentId) {
    const getPaymentLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENT_LINK_KEY,
    );
    if (getPaymentLink) {
      const result = await execLink(getPaymentLink, { paymentId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PAYMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getPaymentsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PaymentDtoPagedResult> => {
  if (resource) {
    const getPaymentsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENTS_LINK_KEY,
    );
    if (getPaymentsListLink) {
      const result = await execLink(getPaymentsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PAYMENTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createPaymentRequest = async (
  resource: LinkResourceBaseDto,
  payment: CreatePaymentCommandValues,
) => {
  const createPaymentLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PAYMENT_LINK_KEY,
  );
  if (createPaymentLink) {
    const result = await execLink(createPaymentLink, payment);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_PAYMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updatePaymentRequest = async (
  resource: LinkResourceBaseDto,
  payment: UpdatePaymentCommandValues,
) => {
  const updatePaymentLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PAYMENT_LINK_KEY,
  );
  if (updatePaymentLink) {
    const result = await execLink(updatePaymentLink, payment);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_PAYMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deletePaymentRequest = async (payment: PaymentDto) => {
  const deletePaymentLink = payment.links?.find(
    (x: LinkDto) => x.rel === DELETE_PAYMENT_LINK_KEY,
  );
  if (deletePaymentLink) {
    const result = await execLink(deletePaymentLink, payment);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_PAYMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importPaymentsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportPaymentsParams,
  file: File,
) => {
  const importPaymentsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_PAYMENTS_LINK_KEY,
  );
  if (importPaymentsLink) {
    const result = await postFormData(importPaymentsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_PAYMENTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportPaymentsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportPaymentsParams,
) => {
  const exportPaymentsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_PAYMENTS_LINK_KEY,
  );
  if (exportPaymentsLink) {
    const result = await downloadLinkFile(exportPaymentsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_PAYMENTS_LINK_KEY} is not allowed or missing link action`,
  );
};
