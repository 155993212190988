import React, { useState } from 'react';
import { TrackingEventDto } from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  DELETE_TRACKINGEVENT_LINK_KEY,
  GetTrackingEventParams,
} from '../trackingEvents.service';
import {
  trackingEventsStore,
  updateTrackingEventsColumns,
} from '../trackingEvents.store';
import { Button } from '../../common/components/button/button.component';
import { TrackingEventDialog } from './trackingEvent.dialog';
import { Store } from 'effector';
import { EventGrid } from '../../common/components/grid/eventDefinitions-grid.component';
import { userHas } from '../../auth/auth.store';

export type TrackingEventForOrderListProps = {
  goToDetails?: (trackingEventParams: GetTrackingEventParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  items?: TrackingEventDto[];
  showPagination?: boolean;
  showAddButton?: boolean;
  changeItems?: React.Dispatch<React.SetStateAction<TrackingEventDto[]>>;
  onTrackingEventCreated?: (trackingEvent: TrackingEventDto) => void;
  onTrackingEventUpdated?: (trackingEvent: TrackingEventDto) => void;
  trackingEventStoreForList?: Store<any>;
  onSelect?: (
    trackingEvent: TrackingEventDto,
    trackingEventParams: GetTrackingEventParams,
  ) => void;
  isCreateMode?: boolean;
  isLoading?: boolean;
  userCanDelete?: boolean;
};

export const TrackingEventForOrderList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = '',
  showPagination = true,
  showAddButton = true,
  onDelete = () => {},
  onPageChanged = () => {},
  onSelect,
  items,
  changeItems,
  onTrackingEventCreated = () => {},
  onTrackingEventUpdated = () => {},
  trackingEventStoreForList = trackingEventsStore,
  isCreateMode = true,
  isLoading = true,
  userCanDelete = false,
}: TrackingEventForOrderListProps) => {
  const [fakeEventIndex, setFakeEventIndex] = useState<number>(
    !isLoading &&
      (isCreateMode ? 0 : (items[items.length - 1]?.trackingEventId + 1) | 0),
  );
  const { trackingEventColumns: columns } = useStore(trackingEventStoreForList);
  const onCreateNewTrackingEvent = () => {
    const trackingEvent: TrackingEventDto = {
      trackingEventId: fakeEventIndex,
      description: null,
      location: null,
      eventDate: new Date(),
      eventDefinitionId: null,
      isInactive: false,
      includeInTracking: false,
      sendEmail: false,
    };
    showDialog({
      dialog: TrackingEventDialog,
      props: {
        title: `Add Event`,
        trackingEvent,
        trackingEventId: 0,
        className: 'trackingEvent-modal',
      },
    }).then((result) => {
      if (result) {
        onTrackingEventCreated(result);
        setFakeEventIndex(fakeEventIndex + 1);
      }
    });
  };
  const onDeleteTrackingEvent = (trackingEvent: TrackingEventDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${trackingEvent.eventName}" Event`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        let isDeleted = false;
        items = items.filter((obj) => {
          const compareTrackingEventResult =
            obj.trackingEventId !== trackingEvent.trackingEventId;
          if (!compareTrackingEventResult && isDeleted === false) {
            isDeleted = true;
            return false;
          }
          return true;
        });
        changeItems(items);
      }
    });
  };

  const onEditTrackingEvent = (trackingEvent: TrackingEventDto) => {
    goToDetails({
      trackingEvent,
      trackingEventId: trackingEvent.trackingEventId,
    });
  };

  return (
    <div className="w-100">
      <div>
        <div className="row mb-3">
          <div className="col-2">
            <h2>Events</h2>
          </div>
          {showAddButton && (
            <div className={'offset-8 col-2'}>
              <Button
                size={'sm'}
                color="secondary"
                className="w-100 h-100"
                name="create-trackingEvent"
                onClick={(event) => onCreateNewTrackingEvent()}
              >
                Add Event
              </Button>
            </div>
          )}
        </div>
      </div>
      {items && items.length ? (
        <EventGrid
          className="tracking-event-for-order-list"
          showAllStore={true}
          rowKeys={['trackingEventId']}
          showEmptyTable={true}
          data={items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={items?.length}
          sort={sort}
          showPagination={showPagination}
          onDelete={
            userHas(DELETE_TRACKINGEVENT_LINK_KEY, items[0]?.links) ||
            userCanDelete
              ? onDeleteTrackingEvent
              : null
          }
          onEdit={onEditTrackingEvent}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateTrackingEventsColumns}
          onSelect={onEditTrackingEvent}
          hideColumnsSelect={true}
        />
      ) : (
        <>
          <h3 className="text-center m-5 text-muted">No Events</h3>
        </>
      )}
    </div>
  );
};
