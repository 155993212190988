import React, { useEffect, useState } from 'react';
import {
  AccountingTransactionDto,
  AccountingTransactionDtoPagedResult,
  AccountingTransactionType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
  GetAccountingTransactionParams,
} from '../../accountingTransactions/accountingTransactions.service';
import {
  accountingTransactionStore,
  deleteAccountingTransactionFx,
  getAccountingTransactionsFx,
  getAccountingTransactionsColumnsFx,
} from '../../accountingTransactions/accountingTransactions.store';
import { INVOICES_CREATE_PATH } from '../invoices.route';
import { InvoiceDialog } from './invoice.dialog';
import { userHas } from '../../auth/auth.store';
import { AccountingTransactionGrid } from '../../common/components/grid/accountingTransaction-grid.component';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';

export type InvoicesListProps = {
  filter?: string;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  goToDetails?: (
    accountingTransactionParams: GetAccountingTransactionParams,
  ) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onFilter?: (query: string) => void;
  onSelect?: (
    accountingTransaction: AccountingTransactionDto,
    accountingTransactionParams: GetAccountingTransactionParams,
  ) => void;
  onSearch?: (query: string) => void;
};

export const InvoicesList = ({
  filter = `accountingTransactionType: ${AccountingTransactionType.Invoice}`,
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  goToDetails = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
  onSelect,
  onSearch,
}: InvoicesListProps) => {
  const [
    accountingTransactions,
    setAccountingTransactions,
  ] = useState<AccountingTransactionDtoPagedResult | null>(null);
  const [loading, setLoading] = useState(true);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  useEffect(() => {
    getAccountingTransactionsColumnsFx().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const navigate = useNavigate();

  useEffect(() => {
    getAccountingTransactionsData();
  }, [offset, limit, sort, filter, search]);

  const getAccountingTransactionsData = async () => {
    try {
      const accountingTransactionsData = await getAccountingTransactionsFx({
        offset,
        limit,
        sort,
        filter,
        search,
      });

      if (accountingTransactionsData) {
        setAccountingTransactions(accountingTransactionsData);
        setGetDataSuccess(true);
      }
    } catch (error) {
      // Handle error here
    }
  };

  const {
    accountingTransactionColumns: columns,
    defaultAccountingTransactionColumns,
  } = useStore(accountingTransactionStore);

  const onDeleteAccountingTransaction = async (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    const result = await showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${accountingTransaction.transactionNumber} Invoice`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    });
    if (result) {
      try {
        await deleteAccountingTransactionFx(accountingTransaction);
        onDelete();
        getAccountingTransactionsData();
      } catch (error) {
        // Handle error here
      }
    }
  };

  const onEditAccountingTransaction = (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    goToDetails({
      accountingTransactionId: accountingTransaction.accountingTransactionId,
    });
  };

  const onCreateNewAccountingTransaction = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + INVOICES_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(createPath);
  };

  const onInvoiceSelect = async (invoice, invoiceId) => {
    onSelect?.(invoice, invoiceId);
    const selectedInvoice = await showDialog({
      dialog: InvoiceDialog,
      props: {
        accountingTransactionId: invoice.accountingTransactionId,
        title: 'Update Invoice',
        className: '',
      },
    });
    if (selectedInvoice !== null) {
      getAccountingTransactionsData();
    }
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <AccountingTransactionGrid
          onSearch={onSearch}
          search={search}
          showEmptyTable={true}
          showAllStore={true}
          showAllFilters={false}
          showToolbar={false}
          showGridPanel={true}
          rowKeys={['accountingTransactionId']}
          data={accountingTransactions?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={accountingTransactions?.totalCount}
          sort={sort}
          onDelete={
            userHas(
              DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
              accountingTransactions?.items[0]?.links,
            )
              ? onDeleteAccountingTransaction
              : null
          }
          onSort={onSort}
          filter={filter}
          onFilter={onFilter}
          onEdit={onEditAccountingTransaction}
          onPageChanged={onPageChanged}
          onLimitChanged={onLimitChanged}
          onViewChanged={onViewChanged}
          onSelect={onInvoiceSelect}
          defaultColumns={defaultAccountingTransactionColumns}
        />
        {!(
          accountingTransactions?.items?.length > 0 ||
          (filter &&
            filter !==
              `accountingTransactionType: ${AccountingTransactionType.Invoice}`) ||
          search
        ) && (
          <ListElementsNotFound
            entityName="Invoice"
            entityLinkKey={CREATE_ACCOUNTINGTRANSACTION_LINK_KEY}
            onClick={onCreateNewAccountingTransaction}
          />
        )}
      </div>
    );
  }
};
