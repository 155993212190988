import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetOrdersQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import {
  OCEAN_ORDER_CREATE_PATH,
  OCEAN_ORDER_LIST_PATH,
} from '../oceanShipmentOrders.route';
import { Helmet } from 'react-helmet';
import { OceanShipmentOrdersList } from '../components/oceanShipmentOrder-list.component';
import {
  CONSOLIDATED_SHIPMENTS_PATH,
  CONSOLIDATED_SHIPMENT_EDIT_PATH,
} from '../../consolidatedShipments/consolidatedShipments.route';
import {
  getOrdersDefaultColumns,
  oceanShipmentOrdersStore,
} from '../oceanShipmentOrders.store';
import { useStore } from 'effector-react';

export const OceanShipmentOrderListScreen = ({ pageTitle, group }) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(oceanShipmentOrdersStore);
  const {
    offset = 0,
    limit = defaultLimit,
    sort = defaultSort,
    filter = `orderType:${OrderTypes.OceanShipmentOrder}`,
    search = '',
    view = '',
  } = useQuery() as GetOrdersQuery;
  const navigate = useNavigate();

  const createPath = generatePath(INTERNAL_PATH + OCEAN_ORDER_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (orderId: number, orderType?: OrderTypes) => {
    const path = generatePath(
      CONSOLIDATED_SHIPMENTS_PATH + CONSOLIDATED_SHIPMENT_EDIT_PATH,
      {
        organizationId,
        orderType,
        orderId,
      },
    );
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onViewChanged = (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limitNumber}&sort=${sortField}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${viewName}`,
    );

    getOrdersDefaultColumns();
  };

  const onLimitChanged = (limit: number) => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}&view=${view}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + OCEAN_ORDER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}&view=${view}`,
    );
  };

  return (
    <InternalLayout
      group={group}
      title={'Ocean Shipments'}
      createPath={null}
      createAction={null}
      createLabel={'Shipment'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OceanShipmentOrdersList
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onViewChanged={onViewChanged}
        onLimitChanged={onLimitChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
        filter={filter}
      />
    </InternalLayout>
  );
};
