import { Dialog } from '../../common/components/dialog/dialog.component';
import { UserEdit } from './user-edit.component';
import { UserDto } from '../../../models/data.models';

export type UserDialogProperties = {
  userId?: string | null;
  title: string;
};

export class UserDialog extends Dialog<UserDialogProperties> {
  constructor(props: UserDialogProperties) {
    super(props);
    this.onUserSaved = this.onUserSaved.bind(this);
  }

  onUserSaved = (user: UserDto) => {
    this.close(user);
  };

  renderContent(): any {
    const { userId } = this.props as UserDialogProperties;
    return (
      <UserEdit
        userId={userId}
        onUserCreated={this.onUserSaved}
        onUserUpdated={this.onUserSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
