import React from 'react';
import { Pagination } from '../../common/components/pagination/pagination.component';
import { AttachmentDto, Column } from '../../../models/data.models';
import { OrderDocumentCard } from '../../orderDocuments/components/orderDocumentCard.component';

export type AttachmentsGridProps = {
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: AttachmentDto[];
  showPagination?: boolean;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  setSelected?: (selectedItem: any) => void;
  isReceivingOrPacking?: boolean;
  selectedAttachments?: AttachmentDto[];
};

export const AttachmentsGrid = ({
  data = [],
  columns = [],
  limit = 20,
  offset = 0,
  total = 0,
  search = '',
  showPagination = true,
  onDelete = () => {},
  onEdit = () => {},
  onSearch,
  onPageChanged = () => {},
  setSelected,
  isReceivingOrPacking = false,
  selectedAttachments,
}: AttachmentsGridProps) => {
  const colByName: { [key: string]: any } = {};

  const updateSearch = (event) => {
    return onSearch(event.target.value);
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const openPicture = (attachment: AttachmentDto) => {
    window.open(attachment.fileUri, '_blank');
  };

  return (
    <div className="order-document-tabs mb-4">
      <div
        className={
          isReceivingOrPacking ? 'container px-5' : 'container pl-0 pr-0'
        }
      >
        <div className="custom-cards-columns pt-5">
          {data?.map((attachment) => {
            return (
              <OrderDocumentCard
                document={attachment}
                openDocument={openPicture}
                isRegeneratingEnabled={true}
                regenerateDocument={onEdit}
                setSelectedDocuments={setSelected}
                onDeleteDocument={onDelete}
                documentName={attachment?.fileName}
                isRegenerable={false}
                isAttachment={true}
                isSelected={selectedAttachments
                  .map((item) => item.attachmentId)
                  .includes(attachment.attachmentId)}
              />
            );
          })}
        </div>
      </div>
      <div className="px-6 py-4" style={{ borderTop: '1px solid #eaecf0' }}>
        {showPagination === true ? (
          <div className="d-flex justify-content-between grid-footer">
            <Pagination
              goToPage={onPageChanged}
              offset={offset}
              limit={limit}
              total={total}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
