import { on } from 'events';
import { useEffect, useState } from 'react';

const PaginationSelect = ({ defaultPageSize, onPageSizeChange }) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    onPageSizeChange(newPageSize);
  };

  return (
    <div className="pagination-select">
      <select
        value={pageSize}
        className="form-control form-control-sm"
        onChange={handlePageSizeChange}
      >
        {/* Define the available page sizes */}
        {[10, 20, 30, 50].map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
  );
};

export const DataGridPaginationComponent = ({ totalCount, pagination }) => {
  const { offset, limit } = pagination;

  const [paginationOptions, setPaginationOptions] = useState({
    totalCount: totalCount ?? 0,
    offset: offset ?? 0,
    limit: limit ?? 10,
    currentPage: 0,
    leftPages: 0,
    rightPages: 0,
    leftPagesSkipped: false,
    rightPagesSkipped: false,
    showLeftDots: false,
    showRightDots: false,
    lastPage: 0,
  });

  useEffect(() => {
    // calculate pages
    const totalPages = Math.ceil(totalCount / limit);
    const currentPage = Math.ceil(offset / limit);
    const leftPages = currentPage - 1; // Previous page
    const rightPages = totalPages - currentPage; // Next page

    const leftPagesSkipped = leftPages > 2; // 2 because we have 2 pages in the middle
    const rightPagesSkipped = rightPages > 2; // 2 because we have 2 pages in the middle
    const showLeftDots = leftPages > 1; // 1 because we have 1 page in the middle
    const showRightDots = rightPages > 1; // 1 because we have 1 page in the middle
    const lastPage = totalPages - 1; // Last page

    setPaginationOptions({
      totalCount,
      offset,
      limit,
      currentPage,
      leftPages,
      rightPages,
      leftPagesSkipped,
      rightPagesSkipped,
      showLeftDots,
      showRightDots,
      lastPage,
    });
  }, [pagination]);

  const changePage = (page) => {
    if (pagination.onPageChange) pagination.onPageChange(page);
  };

  const handlePreviousPageClick = () => {
    if (paginationOptions.currentPage > 0) {
      changePage(paginationOptions.currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (paginationOptions.currentPage < paginationOptions.lastPage) {
      changePage(paginationOptions.currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    changePage(page);
  };

  const onPageSizeChange = (size) => {
    if (pagination.onPageSizeChange) pagination.onPageSizeChange(size);
  };

  // Render function for individual page numbers
  const renderPageNumbers = () => {
    const pages = [];
    if (totalCount === 0) return pages;

    const { currentPage, lastPage } = paginationOptions;
    const rightPages = lastPage - currentPage - 1;
    const totalPagesToShow = 7;

    // Always show the first page
    if (currentPage > 2) {
      pages.push(
        <li
          className="page-item"
          key="first"
          onClick={() => handlePageClick(0)}
        >
          <a className="page-link">1</a>
        </li>,
      );
    }

    // Show left ellipsis if there are more than two pages between the current and the first
    if (currentPage - 1 > 1) {
      pages.push(
        <li className="page-item disabled" key="leftEllipsis">
          <span className="page-link">...</span>
        </li>,
      );
    }

    // Show one page to the left of the current page if it exists
    if (currentPage > 0) {
      pages.push(
        <li
          className="page-item"
          key={`page-${currentPage}`}
          onClick={() => handlePageClick(currentPage - 1)}
        >
          <a className="page-link">{currentPage}</a>
        </li>,
      );
    }

    // Current page button
    pages.push(
      <li className="page-item active" key={`current-${currentPage}`}>
        <span className="page-link">{currentPage + 1}</span>
      </li>,
    );

    // Show pages to the right of the current page
    for (let i = 1; i <= rightPages && pages.length < totalPagesToShow; i++) {
      pages.push(
        <li
          className="page-item"
          key={`page-${currentPage + i}`}
          onClick={() => handlePageClick(currentPage + i)}
        >
          <a className="page-link">{currentPage + i + 1}</a>
        </li>,
      );
    }

    // Show right ellipsis if there are more than two pages between the current and the last
    if (lastPage - currentPage > 1) {
      pages.push(
        <li className="page-item disabled" key="rightEllipsis">
          <span className="page-link">...</span>
        </li>,
      );
    }

    // Always show the last page
    if (currentPage < lastPage) {
      pages.push(
        <li
          className="page-item"
          key={`last-${lastPage}`}
          onClick={() => handlePageClick(lastPage)}
        >
          <a className="page-link">{lastPage + 1}</a>
        </li>,
      );
    }

    return pages;
  };

  return (
    <div className="d-flex justify-content-center grid-footer">
      <nav
        className="d-flex justify-content-between align-items-center w-100"
        aria-label="Page navigation example"
      >
        <div className="d-flex flex-column">
          <div className="pagination-span text-nowrap">Total: {totalCount}</div>
        </div>
        <ul className="pagination">
          {paginationOptions.leftPages > -1 && (
            <li className="page-item">
              <a
                className="page-link"
                aria-label="Previous"
                onClick={handlePreviousPageClick}
              >
                <span aria-hidden="true">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
          )}

          {renderPageNumbers()}

          {paginationOptions.rightPages > 0 && (
            <li className="page-item">
              <a
                className="page-link"
                aria-label="Next"
                onClick={handleNextPageClick}
              >
                <span aria-hidden="true">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 16.707a1 1 0 001.414 0l6-6a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-4.293 4.293a1 1 0 000 1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          )}
        </ul>
        <div className="d-flex align-items-center">
          <div className="pagination-span text-nowrap pr-3">Rows</div>
          <PaginationSelect
            defaultPageSize={limit}
            onPageSizeChange={(size) => onPageSizeChange(size)}
          />
        </div>
      </nav>
    </div>
  );
};
