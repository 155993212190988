import {
  DivisionDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  DivisionDtoPagedResult,
  CreateDivisionCommandValues,
  UpdateDivisionCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_DIVISIONS_LINK_KEY = 'get-divisions';
export const CREATE_DIVISION_LINK_KEY = 'create-division';
export const GET_DIVISION_LINK_KEY = 'get-division';
export const UPDATE_DIVISION_LINK_KEY = 'update-division';
export const DELETE_DIVISION_LINK_KEY = 'delete-division';
export const IMPORT_DIVISIONS_LINK_KEY = 'import-divisions';
export const EXPORT_DIVISIONS_LINK_KEY = 'export-divisions';
export const DIVISION_ENTITY_NAME = 'Division';

export interface GetDivisionParams {
  divisionId: number;
}

export interface ImportDivisionsParams {
  organizationId: number;
}

export interface ExportDivisionsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getDivisionRequest = async (
  resource: LinkResourceBaseDto | null,
  { divisionId }: GetDivisionParams,
): Promise<DivisionDto> => {
  if (resource && divisionId) {
    const getDivisionLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_DIVISION_LINK_KEY,
    );
    if (getDivisionLink) {
      const result = await execLink(getDivisionLink, { divisionId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_DIVISION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getDivisionsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<DivisionDtoPagedResult> => {
  if (resource) {
    const getDivisionsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_DIVISIONS_LINK_KEY,
    );
    if (getDivisionsListLink) {
      const result = await execLink(getDivisionsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_DIVISIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createDivisionRequest = async (
  resource: LinkResourceBaseDto,
  division: CreateDivisionCommandValues,
) => {
  const createDivisionLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_DIVISION_LINK_KEY,
  );
  if (createDivisionLink) {
    const result = await execLink(createDivisionLink, division);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_DIVISION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateDivisionRequest = async (
  resource: LinkResourceBaseDto,
  division: UpdateDivisionCommandValues,
) => {
  const updateDivisionLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_DIVISION_LINK_KEY,
  );
  if (updateDivisionLink) {
    const result = await execLink(updateDivisionLink, division);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_DIVISION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteDivisionRequest = async (division: DivisionDto) => {
  const deleteDivisionLink = division.links?.find(
    (x: LinkDto) => x.rel === DELETE_DIVISION_LINK_KEY,
  );
  if (deleteDivisionLink) {
    const result = await execLink(deleteDivisionLink, division);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_DIVISION_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importDivisionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportDivisionsParams,
  file: File,
) => {
  const importDivisionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_DIVISIONS_LINK_KEY,
  );
  if (importDivisionsLink) {
    const result = await postFormData(importDivisionsLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_DIVISIONS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportDivisionsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportDivisionsParams,
) => {
  const exportDivisionsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_DIVISIONS_LINK_KEY,
  );
  if (exportDivisionsLink) {
    const result = await downloadLinkFile(exportDivisionsLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_DIVISIONS_LINK_KEY} is not allowed or missing link action`,
  );
};
