import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const OrderDocumentForm = ({
  children,
  onSubmit,
  initialValues,
}: FormProps) => {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {children}
    </Form>
  );
};

OrderDocumentForm.RegenerateOnOrderChange = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'regenerateOnOrderChange'}
      label={'Regenerate On Order Change'}
      placeholder={''}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
