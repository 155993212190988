import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { userHas } from '../../auth/auth.store';
import { VESSEL_CREATE_PATH, VESSELS_LIST_PATH } from '../vessel.route';
import { CREATE_VESSEL_LINK_KEY } from '../vessels.service';
import { VesselEdit } from '../components/vessel-edit.component';

export type VesselScreenProps = { pageTitle: string; group: string };

export const VesselScreen = ({ pageTitle, group }: VesselScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, vesselId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + VESSEL_CREATE_PATH, {
    organizationId,
  });

  const onVesselCreated = () => {
    const path = generatePath(INTERNAL_PATH + VESSELS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'vessel-created',
      type: 'success',
      autoHide: true,
      message: 'Vessel successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onVesselUpdated = () => {
    const path = generatePath(INTERNAL_PATH + VESSELS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'vessel-updated',
      type: 'success',
      autoHide: true,
      message: 'Vessel successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + VESSELS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Vessel'}
      createPath={userHas(CREATE_VESSEL_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <VesselEdit
        vesselId={vesselId}
        onVesselUpdated={onVesselUpdated}
        onVesselCreated={onVesselCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
