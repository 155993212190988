import {
  DocumentTemplateDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateDocumentTemplateCommandValues,
  UpdateDocumentTemplateCommandValues,
  DocumentTemplateType,
  FileType,
  EntityFieldDto,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createDocumentTemplateRequest,
  getDocumentTemplatesListRequest,
  getDocumentTemplateRequest,
  deleteDocumentTemplateRequest,
  updateDocumentTemplateRequest,
  GetDocumentTemplateParams,
  downloadDefaultDocumentTemplatesRequest,
  GET_RENDEREDDOCUMENT_LINK_KEY,
  importDocumentTemplatesRequest,
  exportDocumentTemplatesRequest,
} from './documentTemplates.service';
import { getBearerToken } from '../api/api.service';
import { getEnumKeyByValue } from '../../utils/helper.utils';
import URITemplate from 'urijs/src/URITemplate';
import { addEffectStatusHandling } from '../common/messages.store';
import { getEntityFieldListRequest } from '../entityFields/entityFields.service';

type DocumentTemplatesStoreState = {
  links: LinkDto[];
  documentTemplateColumns: EntityFieldDto[];
  defaultDocumentTemplateColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getDocumentTemplatesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'DocumentTemplate',
  });
});

addEffectStatusHandling(getDocumentTemplatesColumnsFx);

export const getDocumentTemplatesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'DocumentTemplate',
  });
});

addEffectStatusHandling(getDocumentTemplatesDefaultColumnsFx);

export const updateDocumentTemplatesColumns = createEvent<EntityFieldDto[]>();

export const getDocumentTemplatesFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getDocumentTemplatesListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getDocumentTemplatesFx);

export const createDocumentTemplateFx = createEffect(
  (documentTemplateData: DocumentTemplateDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createDocumentTemplateCommand: CreateDocumentTemplateCommandValues = {
      ...documentTemplateData,
    };

    return createDocumentTemplateRequest(
      currentOrganization!,
      createDocumentTemplateCommand,
    );
  },
);

addEffectStatusHandling(createDocumentTemplateFx, {
  completeMessage: 'Document template was created successfully',
  errorMessage: 'Document template was not created',
});

export const getDocumentTemplateFx = createEffect(
  (documentTemplateParams: GetDocumentTemplateParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getDocumentTemplateRequest(
      currentOrganization as LinkResourceBaseDto,
      documentTemplateParams,
    );
  },
);

addEffectStatusHandling(getDocumentTemplateFx);

export const updateDocumentTemplateFx = createEffect(
  (documentTemplate: DocumentTemplateDto) => {
    const updateDocumentTemplateCommand: UpdateDocumentTemplateCommandValues = {
      ...documentTemplate,
    };
    return updateDocumentTemplateRequest(
      documentTemplate,
      updateDocumentTemplateCommand,
    );
  },
);

addEffectStatusHandling(updateDocumentTemplateFx, {
  completeMessage: 'Document template was updated successfully',
  errorMessage: 'Document template was not updated',
});

export const deleteDocumentTemplateFx = createEffect(
  (documentTemplate: DocumentTemplateDto) => {
    return deleteDocumentTemplateRequest(documentTemplate);
  },
);

addEffectStatusHandling(deleteDocumentTemplateFx, {
  completeMessage: 'Document template was deleted successfully',
  errorMessage: 'Document template was not deleted',
});

export const downloadDefaultDocumentTemplatesFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return downloadDefaultDocumentTemplatesRequest(currentOrganization);
});

export const getRenderedDocumentLink = (documentTemplateType, metadata) => {
  const { currentOrganization } = organizationsStore.getState();
  const renderedDocumentLink = currentOrganization.links?.find(
    (x: LinkDto) => x.rel === GET_RENDEREDDOCUMENT_LINK_KEY,
  );
  if (renderedDocumentLink) {
    if (renderedDocumentLink.href) {
      const bearerToken = `Bearer ${getBearerToken()}`;
      const documentTemplateTypeValue = getEnumKeyByValue(
        documentTemplateType,
        DocumentTemplateType,
      );
      const metadataString = JSON.stringify(metadata);
      return URITemplate(renderedDocumentLink.href)
        .expand({
          documentTemplateType: documentTemplateTypeValue,
          metadata: metadataString,
          authorization: bearerToken,
        } as any)
        .toString();
    }
  }
};

export const exportDocumentTemplatesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportDocumentTemplatesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportDocumentTemplatesFx, {
  completeMessage: 'Document templates were exported successfully',
  errorMessage: 'Document templates were not exported',
});

export const importDocumentTemplatesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importDocumentTemplatesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importDocumentTemplatesFx, {
  completeMessage: 'Document templates were imported successfully',
  errorMessage: 'Document templates were not imported',
  inFlightMessage: 'Importing document templates...',
});

const defaultState: DocumentTemplatesStoreState = {
  links: [],
  documentTemplateColumns: [],
  defaultDocumentTemplateColumns: [],
  defaultSort: 'documentTemplateId',
  defaultLimit: 20,
};

export const documentTemplateStore: Store<DocumentTemplatesStoreState> = createStore(
  defaultState,
)
  .on(getDocumentTemplatesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      documentTemplateColumns: payload.result.items,
      defaultDocumentTemplateColumns: payload.result.items,
    };
  })
  .on(getDocumentTemplatesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultDocumentTemplateColumns: payload.result.items,
    };
  })
  .on(updateDocumentTemplatesColumns, (state, payload) => {
    return { ...state, documentTemplateColumns: payload };
  });
