import { Dialog } from '../../common/components/dialog/dialog.component';
import { ContactDto } from '../../../models/data.models';
import { SelectContactComponent } from './select-contact.component';

export type SelectContactDialogProperties = {
  title: string;
  className?: string;
  contacts?: ContactDto[];
  header?: string;
  placeholder?: string;
  multiple?: boolean;
  required?: boolean;
  onContactsSelected?: (contacts: ContactDto[]) => void;
};

export class SelectContactDialog extends Dialog<SelectContactDialogProperties> {
  constructor(props: SelectContactDialogProperties) {
    super(props);
    this.onContactsSelected = this.onContactsSelected.bind(this);
  }

  onContactsSelected = (contacts: ContactDto[]) => {
    this.close(contacts);
  };

  renderContent(): any {
    const {
      className,
      contacts,
      header,
      placeholder,
      multiple,
      required,
    } = this.props as SelectContactDialogProperties;
    return (
      <SelectContactComponent
        className={className}
        contacts={contacts}
        header={header}
        placeholder={placeholder}
        multiple={multiple}
        required={required}
        onContactsSelected={this.onContactsSelected.bind(this)}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
