import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import {
  FORGOT_PASSWORD_PATH,
  ForgotPasswordRoute,
  LOGIN_PATH,
  LoginRoute,
  REGISTER_PATH,
  RegisterRoute,
  RESET_PASSWORD_PATH,
  ResetPasswordRoute,
} from './modules/auth/auth.router';
import React, { useEffect, useMemo } from 'react';
import { clearMessages } from './modules/common/messages.store';
import {
  NOT_FOUND_PATH,
  NotFoundRoute,
} from './modules/notFound/not-found.route';
import {
  OrganizationRoute,
  OUTER_ORGANIZATION_CREATE_PATH,
} from './modules/organization/organization.route';
import {
  COUNTRIES_LIST_PATH,
  CountriesRoute,
} from './modules/countries/countries.route';
import { STATES_LIST_PATH, StatesRoute } from './modules/states/states.route';
import { PortsRoute, PORTS_LIST_PATH } from './modules/ports/ports.route';
import {
  CONTACTS_LIST_PATH,
  ContactsRoute,
} from './modules/contacts/contact.route';
import {
  DivisionRoute,
  DIVISIONS_LIST_PATH,
} from './modules/divisions/division.route';
import { ORDER_LIST_PATH, OrderRoute } from './modules/orders/order.route';
import {
  ContactAddressRoute,
  CONTACTS_ADDRESS_LIST_PATH,
} from './modules/contactAddresses/contactAddress.route';
import {
  CARRIERS_LIST_PATH,
  CarriersRoute,
} from './modules/carriers/carrier.route';
import { VendorRoute, VENDORS_LIST_PATH } from './modules/vendor/vendor.route';
import {
  DRIVERS_LIST_PATH,
  DriversRoute,
} from './modules/drivers/driver.route';
import {
  SALES_PERSONS_LIST_PATH,
  SalesPersonsRoute,
} from './modules/salesPersons/salesPerson.route';
import {
  EMPLOYEES_LIST_PATH,
  EmployeesRoute,
} from './modules/employees/employee.route';
import {
  FORWARDING_AGENTS_LIST_PATH,
  ForwardingAgentsRoute,
} from './modules/forwardingAgent/forwardingAgent.route';
import {
  AccountingAccountsRoute,
  ACCOUNTINGACCOUNTS_LIST_PATH,
} from './modules/accountingAccounts/accountingAccounts.route';
import {
  SALESTAXES_LIST_PATH,
  SalesTaxRoute,
} from './modules/salesTaxes/salesTax.route';
import {
  ACCOUNTINGITEMS_LIST_PATH,
  AccountingItemsRoute,
} from './modules/accountingItems/accountingItems.route';
import {
  CURRENCIES_LIST_PATH,
  CurrencyRoute,
} from './modules/currencies/currencies.route';
import {
  PaymentTermRoute,
  PAYMENTTERMS_LIST_PATH,
} from './modules/paymentTerms/paymentTerms.route';
import {
  INVOICES_LIST_PATH,
  InvoicesRoute,
} from './modules/invoice/invoices.route';
import { BILLS_LIST_PATH, BillsRoute } from './modules/bill/bills.route';
import {
  COMMODITIES_LIST_PATH,
  CommoditiesRoute,
} from './modules/commodities/commodities.route';
import {
  CONTAINER_TYPES_LIST_PATH,
  ContainerTypesRoute,
} from './modules/containerTypes/containerTypes.route';
import {
  CONTAINER_DESCRIPTIONS_LIST_PATH,
  ContainerDescriptionsRoute,
} from './modules/containerDescriptions/containerDescriptions.route';
import {
  MODE_OF_TRANSPORTATIONS_LIST_PATH,
  ModeOfTransportationRoute,
} from './modules/modeOfTransportations/modeOfTransportation.route';
import {
  CARRIER_PAY_MANAGER_LIST_PATH,
  CarrierPayManagerRoute,
} from './modules/carrierPayManager/carrierPayManager.route';
import {
  INVOICE_MANAGER_LIST_PATH,
  InvoiceManagerRoute,
} from './modules/invoiceManager/invoiceManager.route';
import {
  DRIVER_PAY_MANAGER_LIST_PATH,
  DriverPayManagerRoute,
} from './modules/driverPayManager/driverPayManager.route';
import {
  EquipmentTypeRoute,
  EQUIPMENTTYPES_LIST_PATH,
} from './modules/equipmentTypes/equipmentType.route';
import {
  FACTORING_COMPANIES_LIST_PATH,
  FactoringCompanysRoute,
} from './modules/factoringCompanies/factoringCompany.route';
import {
  InnerOrganizationRoute,
  ORGANIZATION_PATH,
} from './modules/organization/inner-organization.route';
import {
  DOCUMENT_TEMPLATES_LIST_PATH,
  DocumentTemplateRoute,
} from './modules/documentTemplates/documentTemplate.route';
import {
  CUSTOM_FIELDS_LIST_PATH,
  CustomFieldRoute,
} from './modules/customFields/customFields.route';
import { USERS_LIST_PATH, UsersRoute } from './modules/users/users.route';
import {
  UserGroupRoute,
  USERGROUPS_LIST_PATH,
} from './modules/userGroups/userGroup.route';
import {
  PaymentRoute,
  PAYMENTS_LIST_PATH,
} from './modules/payments/payment.route';
import { DashboardRoute } from './modules/dashboard/dashboard.route';
import { RootRoute } from './modules/common/root.route';
import {
  PackageTypeRoute,
  PACKAGETYPES_LIST_PATH,
} from './modules/packageTypes/packageType.route';
import {
  PACKAGE_CATEGORIES_LIST_PATH,
  PackageCategoryRoute,
} from './modules/packageCategories/packageCategories.route';
import {
  CustomerRoute,
  CUSTOMERS_LIST_PATH,
} from './modules/customers/customer.route';
import {
  EventDefinitionRoute,
  EVENTDEFINITIONS_LIST_PATH,
} from './modules/eventDefinitions/eventDefinitions.route';
import {
  TrackingRoute,
  TRACKING_PATH,
} from './modules/tracking/tracking.route';
import { LeadRoute, LEADS_LIST_PATH } from './modules/leads/lead.route';
import { QUOTE_LIST_PATH, QuoteRoute } from './modules/quotes/quote.route';
import {
  WarehouseLocationRoute,
  WAREHOUSE_LOCATIONS_LIST_PATH,
} from './modules/warehouseLocations/warehouseLocation.route';
import {
  WarehouseZoneRoute,
  WAREHOUSEZONE_LIST_PATH,
} from './modules/warehouseZones/warehouseZone.route';
import {
  WarehouseInspectorRoute,
  ZONES_INSPECTOR_LIST_PATH,
} from './modules/warehouseInspector/warehouseInspector.route';
import {
  PURCHASE_LIST_PATH,
  PurchaseRoute,
} from './modules/purchases/purchase.route';
import {
  CommodityTypeRoute,
  COMMODITY_TYPES_LIST_PATH,
} from './modules/commodityTypes/commodityTypes.route';
import {
  ClausesRoute,
  CLAUSES_LIST_PATH,
} from './modules/clauses/clauses.route';
import {
  WarehouseReceiptRoute,
  WAREHOUSE_RECEIPT_LIST_PATH,
} from './modules/warehouseReceipts/warehouseReceipt.route';
import {
  AirShipmentOrderRoute,
  AIR_ORDER_LIST_PATH,
} from './modules/airShipmentOrders/airShipmentOrders.route';
import {
  ParcelShipmentRoute,
  PARCEL_SHIPMENT_LIST_PATH,
} from './modules/parcelShipments/parcelShipment.route';
import {
  CustomCodesRoute,
  CUSTOMCODES_LIST_PATH,
} from './modules/customCodes/customCodes.route';
import { VesselRoute, VESSELS_LIST_PATH } from './modules/vessels/vessel.route';
import {
  OceanShipmentOrderRoute,
  OCEAN_ORDER_LIST_PATH,
} from './modules/oceanShipment/oceanShipmentOrders.route';
import {
  BusinessRulesRoute,
  BUSINESSRULES_LIST_PATH,
} from './modules/businessRules/businessRules.route';
import {
  OrderStatusesRoute,
  ORDERSTATUSES_LIST_PATH,
} from './modules/orderStatuses/orderStatuses.route';
import {
  CARGO_MOVEMENT_LIST_PATH,
  CargoMovementRoute,
} from './modules/cargoMovement/cargoMovement.route';
import {
  ACCESS_DENIED_PATH,
  AccessDeniedRoute,
} from './modules/accessDenied/access-denied.route';
import {
  ContactStatusesRoute,
  CONTACTSTATUSES_LIST_PATH,
} from './modules/contactStatuses/contactStatuses.route';
import {
  CommodityStatusesRoute,
  COMMODITYSTATUSES_LIST_PATH,
} from './modules/commodityStatuses/commodityStatuses.route';
import { CITIES_LIST_PATH, CitiesRoute } from './modules/cities/cities.route';
import {
  DiscountsRoute,
  DISCOUNT_LIST_PATH,
} from './modules/discounts/discounts.route';
import {
  ORGANIZATION_CONFIGS_LIST_PATH,
  OrganizationConfigsRoute,
} from './modules/organizationConfigs/organizationConfigs.route';
import {
  WORKFLOWS_LIST_PATH,
  WorkflowsRoute,
} from './modules/workflows/workflows.route';
import {
  ACTIONEVENTS_LIST_PATH,
  ActionEventsRoute,
} from './modules/actionEvents/actionEvents.route';
import {
  EXECUTE_WORKFLOWTRIGGER_LINK_KEY,
  WorkflowTriggersRoute,
} from './modules/workflowTriggers/workflowTriggers.route';
import {
  JOBSTATUSES_LIST_PATH,
  JobStatusesRoute,
} from './modules/jobStatuses/jobStatuses.route';
import { JOBS_LIST_PATH, JobsRoute } from './modules/jobs/jobs.route';
import {
  APPMODULES_LIST_PATH,
  AppModulesRoute,
} from './modules/appModules/appModules.route';
import { useStore } from 'effector-react';
import {
  appRoutesStore,
  getAppRoutesFx,
} from './modules/appModules/appRoutes.store';
import { AppComponentScreen } from './modules/common/screens/appComponentScreen.screen';
import {
  STANDARD_CLIENT_RATES_LIST_PATH,
  StandardClientRatesRoute,
} from './modules/rates/standard-client-rates.route';
import {
  CLIENT_RATES_LIST_PATH,
  ClientRatesRoute,
} from './modules/rates/client-rates.route';
import {
  CARRIER_RATES_LIST_PATH,
  CarrierRatesRoute,
} from './modules/rates/carrier-rates.route';
import {
  CONSOLIDATED_SHIPMENTS_LIST_PATH,
  ConsolidatedShipmentRoute,
} from './modules/consolidatedShipments/consolidatedShipments.route';
import OAuth2CallBackPage from './modules/common/screens/oauth2.screen';
import { LOGOUT_PATH, LogoutRoute } from './modules/logout/logout.route';

export const INTERNAL_PATH = '/org/:organizationId';
export const INTERNAL_PATH_V2 = '/org/:organizationId/v2';
export const INTERNAL_PATH_TEMPLATE = '/org/{{organizationId}}/v2/';

// navigate.listen((update) => {
//   clearMessages();
// });

export const useQuery = (): any => {
  const location = useLocation();

  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    return Object.fromEntries(urlSearchParams.entries());
  }, [location.search]);
};

export const useAppRoutes = () => {
  const appRoutes = useStore(appRoutesStore);
  return appRoutes;
};

let navigateTo: (path: string) => void;

export const navigate = (path: string) => {
  navigateTo(path);
};

const AppRoutes = ({ appRoutes }: { appRoutes: any }) => {
  return (
    <Routes>
      {appRoutes.map((appRoute: any) => {
        const { path, component, appRouteId } = appRoute;
        return (
          <Route
            key={appRouteId}
            path={path + '/*'}
            element={
              <Routes>
                {component && (
                  <Route
                    key={appRoute.appRouteId}
                    path={'/'}
                    element={
                      <AppComponentScreen
                        key={appRoute.appRouteId}
                        appRoute={appRoute}
                      />
                    }
                  />
                )}
                {appRoute.children?.map((childRoute: any) => {
                  const { path: childrenPath } = childRoute;
                  return (
                    <Route
                      key={childRoute.appRouteId}
                      path={childrenPath}
                      element={
                        <AppComponentScreen
                          key={childRoute.appRouteId}
                          appRoute={childRoute}
                        />
                      }
                    />
                  );
                })}
              </Routes>
            }
          />
        );
      })}
    </Routes>
  );
};

export const AppRouter = () => {
  const appRoutes = useAppRoutes();

  useEffect(() => {
    getAppRoutesFx();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOGIN_PATH} element={<LoginRoute />} />
        <Route path={REGISTER_PATH} element={<RegisterRoute />} />
        <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPasswordRoute />} />
        <Route path={RESET_PASSWORD_PATH} element={<ResetPasswordRoute />} />
        <Route
          path={OUTER_ORGANIZATION_CREATE_PATH}
          element={<OrganizationRoute />}
        />
        <Route path={ACCESS_DENIED_PATH} element={<AccessDeniedRoute />} />
        <Route path={USERS_LIST_PATH + '/*'} element={<UsersRoute />} />
        <Route path={NOT_FOUND_PATH} element={<NotFoundRoute />} />
        <Route
          path={INTERNAL_PATH_V2 + '/*'}
          element={<AppRoutes appRoutes={appRoutes} />}
        />
        <Route path="/oauth2/callback" element={<OAuth2CallBackPage />} />
        <Route
          path={INTERNAL_PATH + '/*'}
          element={
            <Routes>
              {DashboardRoute()}
              <Route
                path={COUNTRIES_LIST_PATH + '/*'}
                element={<CountriesRoute />}
              />
              <Route path={STATES_LIST_PATH + '/*'} element={<StatesRoute />} />
              <Route path={CITIES_LIST_PATH + '/*'} element={<CitiesRoute />} />
              <Route path={PORTS_LIST_PATH + '/*'} element={<PortsRoute />} />
              <Route
                path={DIVISIONS_LIST_PATH + '/*'}
                element={<DivisionRoute />}
              />
              <Route path={ORDER_LIST_PATH + '/*'} element={<OrderRoute />} />
              <Route
                path={CARGO_MOVEMENT_LIST_PATH + '/*'}
                element={<CargoMovementRoute />}
              />
              <Route path={QUOTE_LIST_PATH + '/*'} element={<QuoteRoute />} />
              <Route
                path={PURCHASE_LIST_PATH + '/*'}
                element={<PurchaseRoute />}
              />
              <Route
                path={WAREHOUSE_RECEIPT_LIST_PATH + '/*'}
                element={<WarehouseReceiptRoute />}
              />
              <Route
                path={AIR_ORDER_LIST_PATH + '/*'}
                element={<AirShipmentOrderRoute />}
              />
              <Route
                path={OCEAN_ORDER_LIST_PATH + '/*'}
                element={<OceanShipmentOrderRoute />}
              />
              <Route
                path={PARCEL_SHIPMENT_LIST_PATH + '/*'}
                element={<ParcelShipmentRoute />}
              />
              <Route
                path={CONTACTS_ADDRESS_LIST_PATH + '/*'}
                element={<ContactAddressRoute />}
              />
              <Route
                path={PACKAGETYPES_LIST_PATH + '/*'}
                element={<PackageTypeRoute />}
              />
              <Route
                path={PACKAGE_CATEGORIES_LIST_PATH + '/*'}
                element={<PackageCategoryRoute />}
              />
              <Route
                path={CUSTOMERS_LIST_PATH + '/*'}
                element={<CustomerRoute />}
              />
              <Route path={LEADS_LIST_PATH + '/*'} element={<LeadRoute />} />
              <Route
                path={CARRIERS_LIST_PATH + '/*'}
                element={<CarriersRoute />}
              />
              <Route
                path={VENDORS_LIST_PATH + '/*'}
                element={<VendorRoute />}
              />
              <Route
                path={CONTACTS_LIST_PATH + '/*'}
                element={<ContactsRoute />}
              />
              <Route
                path={DRIVERS_LIST_PATH + '/*'}
                element={<DriversRoute />}
              />
              <Route
                path={SALES_PERSONS_LIST_PATH + '/*'}
                element={<SalesPersonsRoute />}
              />
              <Route
                path={EMPLOYEES_LIST_PATH + '/*'}
                element={<EmployeesRoute />}
              />
              <Route
                path={FORWARDING_AGENTS_LIST_PATH + '/*'}
                element={<ForwardingAgentsRoute />}
              />
              <Route
                path={ACCOUNTINGACCOUNTS_LIST_PATH + '/*'}
                element={<AccountingAccountsRoute />}
              />
              <Route
                path={SALESTAXES_LIST_PATH + '/*'}
                element={<SalesTaxRoute />}
              />
              <Route
                path={ACCOUNTINGITEMS_LIST_PATH + '/*'}
                element={<AccountingItemsRoute />}
              />
              <Route
                path={CURRENCIES_LIST_PATH + '/*'}
                element={<CurrencyRoute />}
              />
              <Route
                path={PAYMENTTERMS_LIST_PATH + '/*'}
                element={<PaymentTermRoute />}
              />
              <Route
                path={INVOICES_LIST_PATH + '/*'}
                element={<InvoicesRoute />}
              />
              <Route path={BILLS_LIST_PATH + '/*'} element={<BillsRoute />} />
              <Route
                path={COMMODITIES_LIST_PATH + '/*'}
                element={<CommoditiesRoute />}
              />
              <Route
                path={COMMODITY_TYPES_LIST_PATH + '/*'}
                element={<CommodityTypeRoute />}
              />
              <Route
                path={WAREHOUSEZONE_LIST_PATH + '/*'}
                element={<WarehouseZoneRoute />}
              />
              <Route
                path={ZONES_INSPECTOR_LIST_PATH + '/*'}
                element={<WarehouseInspectorRoute />}
              />
              <Route
                path={CONTAINER_TYPES_LIST_PATH + '/*'}
                element={<ContainerTypesRoute />}
              />
              <Route
                path={CONTAINER_DESCRIPTIONS_LIST_PATH + '/*'}
                element={<ContainerDescriptionsRoute />}
              />
              <Route
                path={MODE_OF_TRANSPORTATIONS_LIST_PATH + '/*'}
                element={<ModeOfTransportationRoute />}
              />
              <Route
                path={CARRIER_PAY_MANAGER_LIST_PATH + '/*'}
                element={<CarrierPayManagerRoute />}
              />
              <Route
                path={INVOICE_MANAGER_LIST_PATH + '/*'}
                element={<InvoiceManagerRoute />}
              />
              <Route
                path={DRIVER_PAY_MANAGER_LIST_PATH + '/*'}
                element={<DriverPayManagerRoute />}
              />
              <Route
                path={EQUIPMENTTYPES_LIST_PATH + '/*'}
                element={<EquipmentTypeRoute />}
              />
              <Route
                path={FACTORING_COMPANIES_LIST_PATH + '/*'}
                element={<FactoringCompanysRoute />}
              />
              <Route
                path={ORGANIZATION_PATH}
                element={<InnerOrganizationRoute />}
              />
              <Route
                path={DOCUMENT_TEMPLATES_LIST_PATH + '/*'}
                element={<DocumentTemplateRoute />}
              />
              <Route
                path={CUSTOM_FIELDS_LIST_PATH + '/*'}
                element={<CustomFieldRoute />}
              />
              <Route
                path={USERGROUPS_LIST_PATH + '/*'}
                element={<UserGroupRoute />}
              />
              <Route
                path={EVENTDEFINITIONS_LIST_PATH + '/*'}
                element={<EventDefinitionRoute />}
              />
              <Route
                path={PAYMENTS_LIST_PATH + '/*'}
                element={<PaymentRoute />}
              />
              <Route path={TRACKING_PATH + '/*'} element={<TrackingRoute />} />
              <Route
                path={WAREHOUSE_LOCATIONS_LIST_PATH + '/*'}
                element={<WarehouseLocationRoute />}
              />
              <Route
                path={CLAUSES_LIST_PATH + '/*'}
                element={<ClausesRoute />}
              />
              <Route
                path={CUSTOMCODES_LIST_PATH + '/*'}
                element={<CustomCodesRoute />}
              />
              <Route
                path={VESSELS_LIST_PATH + '/*'}
                element={<VesselRoute />}
              />
              <Route
                path={STANDARD_CLIENT_RATES_LIST_PATH + '/*'}
                element={<StandardClientRatesRoute />}
              />
              <Route
                path={CLIENT_RATES_LIST_PATH + '/*'}
                element={<ClientRatesRoute />}
              />
              <Route
                path={CARRIER_RATES_LIST_PATH + '/*'}
                element={<CarrierRatesRoute />}
              />
              <Route
                path={BUSINESSRULES_LIST_PATH + '/*'}
                element={<BusinessRulesRoute />}
              />
              <Route
                path={ORDERSTATUSES_LIST_PATH + '/*'}
                element={<OrderStatusesRoute />}
              />
              <Route
                path={CONTACTSTATUSES_LIST_PATH + '/*'}
                element={<ContactStatusesRoute />}
              />
              <Route
                path={COMMODITYSTATUSES_LIST_PATH + '/*'}
                element={<CommodityStatusesRoute />}
              />
              <Route
                path={DISCOUNT_LIST_PATH + '/*'}
                element={<DiscountsRoute />}
              />
              <Route
                path={ORGANIZATION_CONFIGS_LIST_PATH + '/*'}
                element={<OrganizationConfigsRoute />}
              />
              <Route
                path={WORKFLOWS_LIST_PATH + '/*'}
                element={<WorkflowsRoute />}
              />
              <Route
                path={ACTIONEVENTS_LIST_PATH + '/*'}
                element={<ActionEventsRoute />}
              />
              <Route
                path={EXECUTE_WORKFLOWTRIGGER_LINK_KEY}
                element={<WorkflowTriggersRoute />}
              />
              <Route
                path={JOBSTATUSES_LIST_PATH + '/*'}
                element={<JobStatusesRoute />}
              />
              <Route path={JOBS_LIST_PATH + '/*'} element={<JobsRoute />} />
              <Route
                path={APPMODULES_LIST_PATH + '/*'}
                element={<AppModulesRoute />}
              />
              <Route
                path={CONSOLIDATED_SHIPMENTS_LIST_PATH + '/*'}
                element={<ConsolidatedShipmentRoute />}
              />
              {/* Fallback route for nested paths */}
              <Route path="" element={<RootRoute path="/" />} />
            </Routes>
          }
        />
        <Route path={LOGOUT_PATH} element={<LogoutRoute />} />
        <Route path={TRACKING_PATH + '/*'} element={<TrackingRoute />} />
        <Route path="" element={<RootRoute path="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
