import {
  GetEmailCommandValues,
  SendDocumentCommandValues,
} from '../../models/data.models';
import { createEffect } from 'effector';
import { organizationsStore } from '../organization/organization.store';
import { getEmailRequest, sendEmailRequest } from './sendEmail.service';

export const getEmail = createEffect((emailParams: GetEmailCommandValues) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getEmailRequest(currentOrganization, emailParams);
});

export const sendEmail = createEffect(
  (emailParams: SendDocumentCommandValues) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return sendEmailRequest(currentOrganization, emailParams);
  },
);
