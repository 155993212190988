import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { GrClose } from 'react-icons/gr';
import tool from '../../../img/tool-wrench.png';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { createActionEventFx } from '../../actionEvents/actionEvents.store';
import { Spinner } from '../../common/components/spinner/spinner';
import { CommodityDto, ScanningResultFrom } from '../../../models/data.models';
import { addMessage, Message } from '../../common/messages.store';
import { CargoMovementForm } from '../components/cargoMovement.form';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { getOrdersByTrackingNumbers } from '../../orders/orders.store';

export type ReportPackageProps = {
  commodities: CommodityDto[];
  onCancel?: () => void;
  onSelect?: (reason: string) => void;
  eventName?: string;
};

export const ReportProblemReason = ({
  commodities,
  onCancel,
  onSelect,
  eventName = 'pallet.reportProblemReason',
}: ReportPackageProps) => {
  const [reasons, setReasons] = React.useState<any[]>([]);
  const [reasonsIsLoading, setReasonsIsLoading] = React.useState<boolean>(true);
  const [enteredNumber, setEnteredNumber] = useState('');
  const [foundedCommodities, setFoundedCommodities] = useState<CommodityDto[]>(
    [],
  );

  useScanner((scannerResult: ScanningResult) => {
    catchScanResult(scannerResult);
  });

  const catchScanResult = async (scannedResult: ScanningResult) => {
    if (scannedResult.from === ScanningResultFrom.Scanner) {
      if (!scannedResult.data) return;

      const scannedString = scannedResult.data;
      setEnteredNumber(scannedString);

      await handleEnteredNumberChange(scannedString);
    }
  };

  const handleEnteredNumberChange = async (enteredTrackingNumber: string) => {
    if (enteredTrackingNumber) {
      const orders = await getOrdersByTrackingNumbers({
        trackingNumbers: [enteredTrackingNumber],
      });

      const commodities: CommodityDto[] = [];

      orders.map((o) => commodities.push(...o.commodities));

      const uniqueCommoditiesIds = commodities
        .map((item) => item.commodityId)
        .filter((value, index, self) => self.indexOf(value) === index);

      const uniqueCommodities = uniqueCommoditiesIds.map((id) =>
        commodities.find((c) => c.commodityId === id),
      );

      setFoundedCommodities(uniqueCommodities);
    }
  };

  const handleReason = async (reason: string) => {
    if (foundedCommodities.length > 0) {
      commodities = foundedCommodities;
    }
    if (commodities && commodities.length > 0) {
      setReasonsIsLoading(true);
      await createActionEventFx({
        eventName,
        eventData: {
          commodities,
          problemReason: reason,
        },
      });

      const message: Message = {
        id: 'status-set-problem',
        type: 'success',
        autoHide: true,
        message: 'Status set to Problem with reason ' + reason,
      };
      addMessage(message);

      if (onSelect) onSelect(reason);
      if (onCancel) onCancel();
      setReasonsIsLoading(false);
    } else {
      const message: Message = {
        id: 'status-set-problem-commodities-not-found',
        type: 'warning',
        autoHide: true,
        message:
          'Commodities with tracking number ' + enteredNumber + ' not found',
      };
      addMessage(message);
    }
  };

  useEffect(() => {
    setReasonsIsLoading(true);
    getCustomFieldsFx({
      filter: `internalName: problemReason
      AND isInactive: false`,
    })
      .then((res: any) => {
        if (res && res.items?.length > 0) {
          setReasons(
            res.items[0].fieldConfig.options.split(';')?.map((value) => {
              return value;
            }),
          );
        }
      })
      .finally(() => {
        setReasonsIsLoading(false);
      });
  }, []);

  useEffect(() => {
    handleEnteredNumberChange(enteredNumber);
  }, [enteredNumber]);

  return (
    <div>
      <a
        style={{ position: 'absolute', right: 24, top: 20, cursor: 'pointer' }}
        onClick={onCancel}
      >
        <GrClose size={'18px'} />
      </a>
      <div>
        <>
          <div className={'d-flex align-items-center'}>
            <div className={'icon-badge mr-4'}>
              <img src={tool} />
            </div>

            <h3 className={'mr-4'}>
              Please{' '}
              {commodities && commodities.length > 0
                ? ''
                : 'scan a package and '}
              specify a reason moving package to service
            </h3>
          </div>
          {commodities && commodities.length > 0 ? null : (
            <div className="mt-5">
              <CargoMovementForm>
                <CargoMovementForm.EnteredNumber
                  label="Tracking number"
                  barcode={true}
                  onChange={(value) => {
                    setEnteredNumber(value);
                  }}
                  defaultValue={enteredNumber}
                />
              </CargoMovementForm>
            </div>
          )}
          <div className="row mt-5">
            {reasonsIsLoading ? (
              <Spinner className={'mx-auto'} />
            ) : (
              reasons.length > 0 &&
              reasons?.map((reason: any, index) => (
                <div key={reason + index} className={'col-4 mt-3 mx-auto'}>
                  <Button
                    className={'w-100'}
                    color={'secondary'}
                    outline={true}
                    onClick={() => {
                      handleReason(reason);
                    }}
                  >
                    {reason}
                  </Button>
                </div>
              ))
            )}
          </div>
        </>
      </div>
    </div>
  );
};
