import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { EventDefinitionsListScreen } from './screens/eventDefinitions-list.screen';
import { EventDefinitionScreen } from './screens/eventDefinition.screen';

export const EVENTDEFINITIONS_LIST_PATH = '/eventDefinitions';
export const EVENTDEFINITION_EDIT_PATH = '/eventDefinitions/:eventDefinitionId';
export const EVENTDEFINITION_CREATE_PATH = '/eventDefinitions/0';

export const EventDefinitionRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <EventDefinitionsListScreen
              pageTitle="Event Definitions"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <EventDefinitionScreen
              pageTitle="Add New Event Definition"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':eventDefinitionId'}
        element={
          <PrivateRoute>
            <EventDefinitionScreen
              pageTitle="Update Event Definition"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
