import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { Helmet } from 'react-helmet';
import { PackageReceiveWizard } from '../packageReceiveWizard/packageReceiveWizard.component';
import { setCurrentPagePath } from '../../common/nav.store';
import { useNavigate } from 'react-router-dom';

export type PackageReceiveWizardScreenProps = {
  pageTitle: string;
  group: string;
};

export const PackageReceiveWizardScreen = ({
  pageTitle,
  group,
}: PackageReceiveWizardScreenProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentPagePath(['Operations', 'Warehouse Receipts', 'Receive']);
  });

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <InternalLayout group={group} title={pageTitle}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PackageReceiveWizard onCancel={onCancel} />
    </InternalLayout>
  );
};
