import { Dialog } from '../../common/components/dialog/dialog.component';
import { ReportProblemReason } from './reportProblemReason.component';
import { CommodityDto } from '../../../models/data.models';

export type ReportProblemReasonDialogProperties = {
  title: string;
  className?: string;
  onSelect?: (reason: string) => void;
  commodities: CommodityDto[];
};

export class ReportProblemReasonDialog extends Dialog<ReportProblemReasonDialogProperties> {
  constructor(props: ReportProblemReasonDialogProperties) {
    super(props);
  }

  renderContent(): any {
    const { onSelect, commodities } = this
      .props as ReportProblemReasonDialogProperties;
    return (
      <ReportProblemReason
        commodities={commodities}
        onSelect={onSelect}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
