import {
  ContainerTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateContainerTypeCommandValues,
  UpdateContainerTypeCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContainerTypeRequest,
  getContainerTypesListRequest,
  getContainerTypeRequest,
  deleteContainerTypeRequest,
  updateContainerTypeRequest,
  importContainerTypesRequest,
  exportContainerTypesRequest,
  GetContainerTypeParams,
} from './containerTypes.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type ContainerTypesStoreState = {
  links: LinkDto[];
  containerTypeColumns: EntityFieldDto[];
  defaultContainerTypeColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getContainerTypesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContainerType',
  });
});

addEffectStatusHandling(getContainerTypesColumnsFx);

export const getContainerTypesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContainerType',
  });
});

addEffectStatusHandling(getContainerTypesDefaultColumnsFx);

export const updateContainerTypesColumns = createEvent<EntityFieldDto[]>();

export const getContainerTypesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContainerTypesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getContainerTypesFx);

export const createContainerTypeFx = createEffect(
  (containerTypeData: ContainerTypeDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createContainerTypeCommand: CreateContainerTypeCommandValues = {
      ...containerTypeData,
    };

    return createContainerTypeRequest(
      currentOrganization!,
      createContainerTypeCommand,
    );
  },
);

addEffectStatusHandling(createContainerTypeFx, {
  completeMessage: 'Container type was created successfully',
  errorMessage: 'Container type was not created',
});

export const getContainerTypeFx = createEffect(
  (containerTypeParams: GetContainerTypeParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContainerTypeRequest(
      currentOrganization as LinkResourceBaseDto,
      containerTypeParams,
    );
  },
);

addEffectStatusHandling(getContainerTypeFx);

export const updateContainerTypeFx = createEffect(
  (containerType: ContainerTypeDto) => {
    const updateContainerTypeCommand: UpdateContainerTypeCommandValues = {
      ...containerType,
    };
    return updateContainerTypeRequest(
      containerType,
      updateContainerTypeCommand,
    );
  },
);

addEffectStatusHandling(updateContainerTypeFx, {
  completeMessage: 'Container type was updated successfully',
  errorMessage: 'Container type was not updated',
});

export const deleteContainerTypeFx = createEffect(
  (containerType: ContainerTypeDto) => {
    return deleteContainerTypeRequest(containerType);
  },
);

addEffectStatusHandling(deleteContainerTypeFx, {
  completeMessage: 'Container type was deleted successfully',
  errorMessage: 'Container type was not deleted',
});

export const exportContainerTypesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportContainerTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportContainerTypesFx, {
  completeMessage: 'Container types were exported successfully',
  errorMessage: 'Container types were not exported',
});

export const importContainerTypesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importContainerTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importContainerTypesFx, {
  completeMessage: 'Container types were imported successfully',
  errorMessage: 'Container types were not imported',
  inFlightMessage: 'Importing container types...',
});

const defaultState: ContainerTypesStoreState = {
  links: [],
  containerTypeColumns: [],
  defaultContainerTypeColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const containerTypeStore: Store<ContainerTypesStoreState> = createStore(
  defaultState,
)
  .on(getContainerTypesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      containerTypeColumns: payload.result.items,
      defaultContainerTypeColumns: payload.result.items,
    };
  })
  .on(getContainerTypesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultContainerTypeColumns: payload.result.items,
    };
  })
  .on(updateContainerTypesColumns, (state, payload) => {
    return { ...state, containerTypeColumns: payload };
  });
