import { createStore, Store, Event, createEvent } from 'effector';
import { requestError } from '../api/api.store';
import { v4 } from 'uuid';
import { handleServerError } from '../../utils/serverErrorHandler.utils';

export type Message = {
  id?: string;
  type: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger';
  message: string;
  autoHide?: boolean;
};

type MessagesStoreState = {
  messages: Message[];
};

export const addMessage: Event<Message> = createEvent();
export const removeMessage: Event<Message> = createEvent();
export const clearMessages = createEvent();

const defaultState: MessagesStoreState = {
  messages: [],
};

export const messagesStore: Store<MessagesStoreState> = createStore(
  defaultState,
)
  .on(requestError, (state, payload) => {
    if (payload.data) {
      const errors = [];

      if (payload.data.errors && payload.data.errors.length > 0) {
        if (Array.isArray(payload.data.errors)) {
          errors.push(payload.data.errors);
        } else {
          const keys = Object.keys(payload.data.errors);
          keys.forEach((key) => {
            errors.push(payload.data.errors[key]);
          });
        }
      } else if (payload.data.title) {
        errors.push(payload.data.title);
      } else if (payload.data.details) {
        errors.push(payload.data.details);
      } else if (payload.data.error_description) {
        const message =
          (payload.data &&
            payload.data.error_description &&
            payload.data.error_description.split('_').join(' ')) ||
          payload.statusText;
        errors.push(message);
      } else if (payload.data.status === 500 || payload.status === 500) {
        errors.push(handleServerError(payload.data));
      }
      errors.forEach((error) => {
        if (Array.isArray(error)) {
          error.forEach((subError) =>
            addMessage({ id: v4(), message: subError, type: 'danger' }),
          );
        } else {
          addMessage({ id: v4(), message: error, type: 'danger' });
        }
      });
    } else {
      const message =
        (payload.data &&
          payload.data.error_description &&
          payload.data.error_description.split('_').join(' ')) ||
        payload.statusText;
      addMessage({ id: v4(), message, type: 'danger' });
    }
  })
  .on(addMessage, (state: MessagesStoreState, payload: Message) => {
    if (!payload || !payload.message) {
      return state;
    }
    if (!payload.id) payload.id = v4();
    if (payload.autoHide !== false) {
      setTimeout(() => {
        removeMessage(payload);
      }, 14000);
    }
    return { messages: [...state.messages, payload] };
  })
  .on(removeMessage, (state: MessagesStoreState, payload: Message) => {
    return {
      messages: state.messages.filter((x) => x.id !== payload.id),
    };
  })
  .on(clearMessages, () => ({ messages: [] }));

export const addEffectStatusHandling = (
  effect: any,
  options?: {
    errorMessage?:
      | string
      | ((error: any, params?: any, result?: any) => string);
    completeMessage?: string | ((params?: any, result?: any) => string);
    inFlightMessage?: string | ((params?: any) => string);
  },
) => {
  effect.inFlight.watch((inFlight) => {
    if (inFlight && options?.inFlightMessage) {
      const message =
        typeof options.inFlightMessage === 'function'
          ? options.inFlightMessage(effect.request)
          : options.inFlightMessage;

      addMessage({
        message,
        type: 'primary',
      });
    }
  });

  effect.done.watch(({ params, result }) => {
    if (options?.completeMessage) {
      const message =
        typeof options.completeMessage === 'function'
          ? options.completeMessage(params, result)
          : options.completeMessage;

      addMessage({
        message,
        type: 'success',
      });
    }
  });

  effect.fail.watch(({ params, error, result }) => {
    if (typeof options?.errorMessage === 'function') {
      addMessage({
        message: options?.errorMessage(error, params, result),
        type: 'danger',
      });
    } else if (options?.errorMessage) {
      // read apollo error
      const apolloError = error?.networkError?.result?.errors?.[0];
      addMessage({
        message: `${options?.errorMessage}\n${error.message}\n${apolloError?.message}`.trim(),
        type: 'danger',
      });
    }
  });
};
