import {
  StateDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  StateDtoPagedResult,
  CreateStateCommandValues,
  UpdateStateCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createStateRequest,
  getStatesListRequest,
  getStateRequest,
  deleteStateRequest,
  updateStateRequest,
  importStatesRequest,
  exportStatesRequest,
  GetStateParams,
} from './states.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type StatesStoreState = {
  links: LinkDto[];
  stateColumns: EntityFieldDto[];
  defaultStateColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getStatesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'State',
  });
});

addEffectStatusHandling(getStatesColumnsFx);

export const getStatesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'State',
  });
});

addEffectStatusHandling(getStatesDefaultColumnsFx);

export const updateStatesColumns = createEvent<EntityFieldDto[]>();

export const getStatesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getStatesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getStatesFx);

export const createStateFx = createEffect((stateData: StateDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createStateCommand: CreateStateCommandValues = { ...stateData };

  return createStateRequest(currentOrganization!, createStateCommand);
});

addEffectStatusHandling(createStateFx, {
  completeMessage: 'State was created successfully',
  errorMessage: 'State was not created',
});

export const getStateFx = createEffect((stateParams: GetStateParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getStateRequest(
    currentOrganization as LinkResourceBaseDto,
    stateParams,
  );
});

addEffectStatusHandling(getStateFx);

export const updateStateFx = createEffect((state: StateDto) => {
  const updateStateCommand: UpdateStateCommandValues = { ...state };
  return updateStateRequest(state, updateStateCommand);
});

addEffectStatusHandling(updateStateFx, {
  completeMessage: 'State was updated successfully',
  errorMessage: 'State was not updated',
});

export const deleteStateFx = createEffect((state: StateDto) => {
  return deleteStateRequest(state);
});

addEffectStatusHandling(deleteStateFx, {
  completeMessage: 'State was deleted successfully',
  errorMessage: 'State was not deleted',
});

export const exportStatesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportStatesRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportStatesFx, {
  completeMessage: 'States were exported successfully',
  errorMessage: 'States were not exported',
});

export const importStatesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importStatesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importStatesFx, {
  completeMessage: 'States were imported successfully',
  errorMessage: 'States were not imported',
  inFlightMessage: 'Importing states...',
});

const defaultState: StatesStoreState = {
  links: [],
  stateColumns: [],
  defaultStateColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const stateStore: Store<StatesStoreState> = createStore(defaultState)
  .on(getStatesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      stateColumns: payload.result.items,
      defaultStateColumns: payload.result.items,
    };
  })
  .on(getStatesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultStateColumns: payload.result.items,
    };
  })
  .on(updateStatesColumns, (state, payload) => {
    return { ...state, stateColumns: payload };
  });
