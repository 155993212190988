import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CarrierListScreen } from './screens/carrier-list.screen';
import { CarrierScreen } from './screens/carrier.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';

export const CARRIERS_LIST_PATH = '/carriers';
export const CARRIERS_EDIT_PATH = '/carriers/:contactId';
export const CARRIERS_CREATE_PATH = '/carriers/0';

export const CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS =
  '/carriers/:contactId/contacts';

export const CarriersRoute = () => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CarrierListScreen pageTitle="Carriers" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CarrierScreen pageTitle="Add New Carrier" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <CarrierScreen pageTitle="Update Carrier" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId/contacts'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Contacts" isTab={true} group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
