import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { DriverEdit } from './driver-edit.component';

export type DriverDialogProperties = {
  contactId: number;
  title: string;
};

export class DriverDialog extends Dialog<DriverDialogProperties> {
  constructor(props: DriverDialogProperties) {
    super(props);
    this.onDriverSaved = this.onDriverSaved.bind(this);
  }

  onDriverSaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId } = this.props as DriverDialogProperties;
    return (
      <DriverEdit
        contactId={contactId}
        onDriverCreated={this.onDriverSaved}
        onDriverUpdated={this.onDriverSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
