// formik field component for object fields
import { Field } from 'formik';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-graphqlschema';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/ext-searchbox';

import { useCallback, useEffect, useState } from 'react';

const getValue = (value, output) => {
  if (value === null || value === undefined) return '';

  if (output === 'object') {
    return JSON.stringify(value, null, 2);
  }

  return value;
};

const FormikAceEditor = ({
  field,
  form,
  componentProps,
  variables,
  ...props
}) => {
  const [content, setContent] = useState(
    getValue(field.value, componentProps.output),
  );

  const handleChange = useCallback(
    (value) => {
      let objValue = value;
      if (componentProps.mode === 'json') {
        try {
          const obj = JSON.parse(value);
          // remove errors if we are able to parse the value
          form.setFieldError(field.name, undefined);

          if (componentProps.output === 'object') {
            objValue = obj;
          }
        } catch (e) {
          form.setFieldError(field.name, 'Invalid JSON:' + e.message);
          return;
        } finally {
          setContent(value);
        }
      } else {
        setContent(objValue);
      }

      if (props.onChange) props.onChange(objValue);
    },
    [field, form, props, componentProps],
  );

  return (
    <>
      <AceEditor
        theme="github" // set the theme
        width="100%"
        height={componentProps.height}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        {...props}
        value={content}
        name={field.name + '-ace-editor'}
        onChange={handleChange}
      />
      {form.errors[field.name] && (
        <div className="invalid-feedback d-block">
          {form.errors[field.name]}
        </div>
      )}
    </>
  );
};

export const YamlField = (props) => {
  return <Field {...props} component={FormikAceEditor} mode="yaml" />;
};

export const JsonField = (props) => {
  return <Field {...props} component={FormikAceEditor} mode="json" />;
};

export const GraphqlField = (props) => {
  return <Field {...props} component={FormikAceEditor} mode="graphqlschema" />;
};

export const HtmlField = (props) => {
  return <Field {...props} component={FormikAceEditor} mode="html" />;
};
