import React, { useEffect, useMemo, useState } from 'react';
import {
  LocationType,
  WarehouseLocationDto,
  WarehouseLocationDtoPagedResult,
} from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { v4 as uuidv4 } from 'uuid';
import { showDialog } from '../../common/dialog.store';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  getWarehouseLocationFx,
  getWarehouseLocationsFx,
} from '../../warehouseLocations/warehouseLocations.store';
import { WarehouseLocationDialog } from '../../warehouseLocations/components/warehouseLocation.dialog';
import {
  CREATE_WAREHOUSELOCATION_LINK_KEY,
  GET_WAREHOUSELOCATION_LINK_KEY,
  WAREHOUSELOCATION_ENTITY_NAME,
} from '../../warehouseLocations/warehouseLocations.service';

export const getWarehouseLocationOptionValue = (
  location: WarehouseLocationDto,
): string => {
  if (!location) return '';
  return `${
    location?.description ?? LocationType[location?.locationType] ?? 'Location'
  } (${location?.code})`;
};

export const SelectMovementLocationPaginate = ({
  id = 'warehouseLocationId' + uuidv4(),
  nameId = 'warehouseLocation',
  required = false,
  limit = 10,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeLocation = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Warehouse Location',
}): JSX.Element => {
  const formikContext = useFormikContext<any>();
  const [value, setValue] = useState(selectedValue);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const locationId = parseInt(_.get(formikContext.values, id));

  useEffect(() => {
    if (locationId > 0 && locationId !== value?.warehouseLocationId) {
      getWarehouseLocationFx({
        warehouseLocationId: locationId,
      }).then(
        (warehouseLocationDto: WarehouseLocationDto) => {
          formikContext.setFieldValue(
            nameId,
            getWarehouseLocationOptionValue(warehouseLocationDto),
          );
          setValue(warehouseLocationDto);
        },
        () => {},
      );
    }
  }, [locationId]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseWarehouseLocationDtoPagedResult: WarehouseLocationDtoPagedResult = await getWarehouseLocationsFx(
      {
        limit: limit,
        offset: (page - 1) * limit,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseWarehouseLocationDtoPagedResult.items,
      hasMore: responseWarehouseLocationDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeLocation === 'function') {
      onChangeLocation(option, contextDto);
    }
  };

  const onChangeWithContext = (location, currentContext) => {
    onChange(location, currentContext);
    currentContext.setFieldValue(id, location?.warehouseLocationId);
    currentContext.setFieldValue(
      nameId,
      getWarehouseLocationOptionValue(location),
    );
    setValue(location);
    currentContext.setFieldError(id, undefined);
    if (required && !location) {
      currentContext.setFieldValue(id, '');
      currentContext.setFieldValue(nameId, null);
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onLocationEditIconClick = () => {
    if (value?.warehouseLocationId) {
      showDialog({
        dialog: WarehouseLocationDialog,
        props: {
          warehouseLocationId: value.warehouseLocationId,
          title: 'Warehouse Location',
        },
      }).then(
        (location) => {
          if (location !== null) {
            getWarehouseLocationFx({
              warehouseLocationId: Number(value.warehouseLocationId) || null,
            }).then(
              (warehouseLocationDto) => {
                onChangeWithContext(warehouseLocationDto, formikContext);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  return (
    <FormContext.Consumer>
      {(context) => (
        <div className="form-group">
          <label className="input-label" htmlFor={id}>
            <span className="d-flex justify-content-between align-items-center">
              <span className="input-label-primary">{header}</span>
              {!isMulti && value && userHas(GET_WAREHOUSELOCATION_LINK_KEY) ? (
                <div
                  className="menu-icon-wrapper d-flex icon-edit-entity"
                  onClick={onLocationEditIconClick}
                  data-cy={`warehouse-location-edit-icon`}
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </div>
              ) : null}
            </span>
          </label>
          <Field required={required} type="hidden" name={id} />
          <Field required={required} type="hidden" name={nameId} />
          <SelectEntityAsyncPaginate
            className={id}
            entityName={WAREHOUSELOCATION_ENTITY_NAME}
            canCreateNewEntity={userHas(CREATE_WAREHOUSELOCATION_LINK_KEY)}
            onCreateNewEntity={() => {
              return showDialog({
                dialog: WarehouseLocationDialog,
                props: {
                  title: showCreateDialogTitle,
                  warehouseLocationId: 0,
                },
              }).then(
                (location) => {
                  setClearLoadOptionsCache(!clearLoadOptionsCache);
                  return location;
                },
                () => {},
              );
            }}
            key={filter}
            value={value}
            loadOptions={loadOptions}
            getOptionValue={(option) => option?.warehouseLocationId}
            getOptionLabel={(option) => getWarehouseLocationOptionValue(option)}
            onChange={(location?: any) => {
              onChangeWithContext(location, context);
            }}
            onFocus={clearMessages}
            onBlur={() => context.setFieldTouched(id, true)}
            isDisabled={isDisabled}
            isClearable={isClearable}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            isSearchable={isSearchable}
            placeholder={placeholder}
            classNamePrefix={classNamePrefix}
            components={{
              IndicatorSeparator: () => null,
            }}
            additional={{
              page: 1,
            }}
            cacheUniqs={[clearLoadOptionsCache]}
          />
          <ErrorMessage
            name={id}
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
    </FormContext.Consumer>
  );
};
