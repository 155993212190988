import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CustomerListScreen } from './screens/customer-list.screen';
import { CustomerScreen } from './screens/customer.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';

export const CUSTOMERS_LIST_PATH = '/customers';
export const CUSTOMERS_EDIT_PATH = '/customers/:contactId';
export const CUSTOMERS_CREATE_PATH = '/customers/0';

export const CustomerRoute = (props: RouteProps) => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CustomerListScreen pageTitle="Customers" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <CustomerScreen pageTitle="Add New Customer" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <CustomerScreen pageTitle="Update Customer" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':customerId/contacts/:contactId'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Contacts" isTab={true} group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
