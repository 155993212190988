import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { CustomFieldsForm } from '../../common/components/form/customFields-form.component';
import { SelectMovementLocationPaginate } from './movementLocation-react-select.component';
import { FormikDatepickerComponent } from '../../common/components/react-datepicker/formik-datepicker.component';
import { SelectDivisionForMovementPaginate } from './division-forMovement-react-select.component';
import { SelectContactPaginateForMovement } from './contact-forMovement-react-select.component';
import { SelectWarehouseLocationPaginate } from '../../warehouseLocations/components/warehouseLocation-react-select.component';

export const CargoMovementForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
  validateOnChange,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
    >
      {children}
    </Form>
  );
};

CargoMovementForm.Date = ({
  required = false,
  defaultValue,
  header = 'Date',
  nameId = 'date',
  placeholder = 'Select Date',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <FormikDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

CargoMovementForm.Division = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectDivisionForMovementPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isDisabled={disabled}
    />
  );
};

CargoMovementForm.EmployeeContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Dispatcher',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'employeeContactId',
  nameId = 'employeeContactName',
  header = 'Dispatchers',
  onChange = () => {},
  readonly,
  contactTypes,
  disabled,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginateForMovement
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      isDisabled={disabled}
    />
  );
};

CargoMovementForm.OrderNumber = ({
  className,
  style,
  size,
  placeholder = 'Movement Number',
  label = 'Movement Number',
}: InputPublicProps) => {
  return (
    <Input
      name={'orderNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={true}
    />
  );
};

CargoMovementForm.Status = ({
  disabled,
  id = 'movementType',
  header = 'Movement Type',
  placeholder = '',
  onChange,
  options,
  required = true,
  multiple = false,
  defaultValue = '',
  readonly,
  enumName,
}: InputPublicProps) => {
  return (
    <>
      <label className="input-label" htmlFor={id}>
        <span className="d-flex justify-content-between align-items-center">
          <span className="input-label-primary">{header}</span>
        </span>
      </label>
      <Input
        enumName={enumName}
        readonly={readonly}
        type={'enum-select'}
        name={id}
        id={id}
        header={header}
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        multiple={multiple}
        disabled={disabled}
        onChange={onChange}
        options={options}
        isClearable={false}
      />
    </>
  );
};
CargoMovementForm.TotalPcsCrt = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'totalPcsCrt'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'totalPcsCrt'}
      label={'Total Pcs/Crt'}
      placeholder={'Total Pcs/Crt'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CargoMovementForm.WeightTotal = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'weightTotal'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'weightTotal'}
      label={'Weight Total'}
      placeholder={'Weight Total'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CargoMovementForm.VolumeTotal = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'volumeTotal'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'volumeTotal'}
      label={'Volume Total'}
      placeholder={'Volume Total'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CargoMovementForm.CustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      saveButtonRenderCondition={saveButtonRenderCondition}
      isSending={isSending}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};

CargoMovementForm.CustomFields = ({
  customFields,
  customValues,
  onChange,
  isFieldDisable,
}) => {
  return (
    <CustomFieldsForm
      customFields={customFields}
      defaultValue={customValues}
      inputNamePrefix={`orderEntity`}
      onChange={onChange}
      isFieldsDisabled={isFieldDisable}
    />
  );
};

CargoMovementForm.WarehouseLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Warehouse Location',
  required = false,
  defaultValue,
  sort = '',
  id = 'warehouseLocationId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  nameId = '',
}: InputPublicProps) => {
  return (
    <SelectMovementLocationPaginate
      id={id}
      required={required}
      isMulti={false}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeLocation={onChange}
      isDisabled={disabled}
      nameId={nameId}
    />
  );
};

CargoMovementForm.PalletNumber = ({
  className,
  style,
  size,
  placeholder = 'Pallet/Cart Number',
  label = 'Pallet/Cart Number',
  barcode,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'trackingNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
      barcode={barcode}
    />
  );
};

CargoMovementForm.EnteredNumber = ({
  className,
  style,
  size,
  placeholder = '',
  label = '',
  defaultValue,
  onChange,
  disabled,
  barcode,
}: InputPublicProps) => {
  return (
    <Input
      name={'orderNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'input-OnChange-text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={onChange}
      barcode={barcode}
    />
  );
};

CargoMovementForm.Location = ({
  placeholder = '',
  label = 'Location',
  defaultValue,
  onChange,
  disabled = false,
  appendElement,
  id = 'warehouseLocationId',
  barcode,
  setWarning,
}: InputPublicProps) => {
  return (
    <SelectWarehouseLocationPaginate
      onChangeLocation={onChange}
      selectedValue={defaultValue}
      isMulti={false}
      isClearable={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={label}
      useContext={true}
      isDisabled={disabled}
      placeholder={placeholder}
      id={id}
      barcode={barcode}
      setWarning={setWarning}
    />
  );
};

CargoMovementForm.PackageTrackingNumber = ({
  className,
  style,
  size,
  placeholder = '',
  label = 'Tracking number',
  defaultValue,
  onChange,
  disabled,
  barcode,
}: InputPublicProps) => {
  return (
    <Input
      name={'orderNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'input-OnChange-text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={onChange}
      barcode={barcode}
    />
  );
};
