import React, {
  useContext,
  createContext,
  CSSProperties,
  useEffect,
  useState,
} from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsThreeDotsVertical } from 'react-icons/bs';
import lodash from 'lodash';
import { Pagination } from '../pagination/pagination.component';
import { OrderForListDto } from '../../../../models/custom.models';
import {
  Column,
  ContactStatusStage,
  CustomFieldType,
  OrderStatuses,
  SummaryItems,
} from '../../../../models/data.models';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import { StatusBadge } from '../status-badge/status-badge';
import {
  FilteredField,
  FiltersTab,
  GridContextValue,
} from '../filters/filtersTab.component';
import { TotalItems } from '../totalItems/totalItems';

export type PurchaseGridProps = {
  rowKeys?: string[] | null;
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  filterContextValues?: any;
  total?: number;
  data?: OrderForListDto[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  defaultSort?: string;
  defaultLimit?: number;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onLimitChanged?: (limit: number) => void;
  onSelect?: (item: any, keys: any) => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  onChangeItem?: (items: any[]) => void;
  onPackagePacking?: () => void;
  summary?: SummaryItems | undefined;
  columns?: Column[];
  defaultColumns?: Column[];
  enableScanner?: boolean;
  showGridPanel?: boolean;
  entityType?: string;
};

const GridContext = createContext<GridContextValue | null>(null);

export const useGridContext = (): GridContextValue => {
  const contextValue = useContext(GridContext);

  if (!contextValue) {
    throw new Error(
      'useGridContext must be used within a GridContext.Provider',
    );
  }

  return contextValue;
};

export const PurchaseGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  filterContextValues = {},
  style = {},
  showPagination = true,
  defaultSort,
  defaultLimit,
  onSort = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onViewChanged = () => {},
  onLimitChanged = () => {},
  rowKeys = ['id'],
  onSelect,
  showEmptyTable = false,
  showAllStore = false,
  summary,
  defaultColumns,
  enableScanner = false,
  showGridPanel = true,
  entityType = null,
}: PurchaseGridProps) => {
  const colByName: { [key: string]: any } = {};

  const [gridColumns, setGridColumns] = useState<Column[]>([...columns]);
  const [filters, setFilters] = useState<FilteredField[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<FilteredField[]>([]);

  const onColumnsChangedHandler = (columnsChanged: Column[]): void => {
    setGridColumns([...columnsChanged]);
  };

  useEffect(() => {
    if (columns.length === 0) return;
    setGridColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    setFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: filters.find((y) => y.name === x.name)?.visible ?? false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
    setDefaultFilters(
      gridColumns.map((x) => ({
        name: x.name,
        title: x.title,
        type: x.type,
        visible: false,
        sortName: x.sortName,
        filterFieldName: x.filterFieldName,
      })),
    );
  }, [gridColumns]);

  const updateSort = (colName: string) => {
    const column = gridColumns.find((col) => col.name === colName);
    const sortName = column?.['sortName'];
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.startsWith('-') ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const getTdClass = (itemName?: any) => {
    let resultClasses = ['cursor-pointer'];
    if (itemName === 'firstOrderPickupsShipperAddressName') {
      resultClasses.push('three-dots order-grid-origin');
    } else if (itemName === 'firstOrderDeliveriesConsigneeAddressName') {
      resultClasses.push('three-dots order-grid-destination');
    } else if (itemName === 'orderStatus') {
      resultClasses.push('order-grid-order-status');
    }
    return resultClasses.join(' ');
  };

  gridColumns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const onFiltersChangedHandler = (filtersChanged: FilteredField[]): void => {
    if (filtersChanged) setFilters([...filtersChanged]);
  };

  const [gridContext, setGridContext] = useState<GridContextValue>({
    columns: gridColumns,
    filters,
    search,
    defaultColumns,
    filterContextValues,
    defaultFilters,
    defaultSort,
    defaultLimit,
    onSearch,
    onFilter,
    onColumnsChanged: onColumnsChangedHandler,
    onFiltersChanged: onFiltersChangedHandler,
    onViewChanged,
    enableScanner,
    entityType: entityType,
  });

  useEffect(() => {
    setGridContext({
      columns: gridColumns,
      filters,
      search,
      defaultColumns,
      filterContextValues,
      defaultFilters,
      defaultSort,
      defaultLimit,
      onSearch,
      onFilter,
      onColumnsChanged: onColumnsChangedHandler,
      onFiltersChanged: onFiltersChangedHandler,
      onViewChanged,
      enableScanner,
      entityType: entityType,
    });
  }, [gridColumns, filters, search, defaultColumns, defaultFilters]);

  return (
    <>
      <div className="mx-3">
        {showGridPanel && (
          <>
            {(filters.length > 0 || gridColumns.length > 0) && (
              <GridContext.Provider value={gridContext}>
                <FiltersTab useGridContext={useGridContext} />
              </GridContext.Provider>
            )}
          </>
        )}

        {summary && (
          <div className={'box px-6 py-4 my-4'}>
            <TotalItems summary={summary} />
          </div>
        )}
      </div>
      <div className={`grid mx-3 my-4 ${className}`} style={style}>
        {total > 0 || showEmptyTable === true ? (
          <div className="horizontal-scrollbar">
            <table className="table">
              <thead>
                <tr>
                  {gridColumns
                    .filter((col) => {
                      if (showAllStore && col.visible) {
                        return true;
                      }
                      return col.visible && rowKeys?.includes(col.name);
                    })
                    .map((col) => {
                      return (
                        <th scope="col" key={col.name}>
                          <a
                            className={col.sortName ? 'link' : 'inactive-link'}
                            onClick={() =>
                              col.sortName ? updateSort(col.name) : null
                            }
                          >
                            {col.title}
                            {sort === col.name ||
                            sort === col.sortName ||
                            sort === col.sortName + '~ToInt32' ? (
                              <FaArrowDown />
                            ) : null}
                            {sort === '-' + col.name ||
                            sort === '-' + col.sortName ||
                            sort === '-' + col.sortName + '~ToInt32' ? (
                              <FaArrowUp />
                            ) : null}
                          </a>
                        </th>
                      );
                    })}
                  <th colSpan={2}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => {
                  return (
                    <tr
                      data-cy-tracking-number={row.trackingNumber}
                      key={rowKeys?.map((x) => row[x]).join('_')}
                    >
                      {Object.values(gridColumns)
                        .filter((item) => {
                          if (showAllStore && item.visible) {
                            return true;
                          }
                          return item.visible && rowKeys?.includes(item.name);
                        })
                        .map((item, index) => {
                          switch (item.name) {
                            case 'orderStatusName':
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  style={{
                                    width: `calc(100% / ${
                                      gridColumns.filter(
                                        (column) => column.visible === true,
                                      ).length
                                    })`,
                                    whiteSpace: 'nowrap',
                                  }}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  onClick={(event) => {
                                    if (onSelect) {
                                      onSelect(
                                        row,
                                        rowKeys?.reduce((keyObj, field) => {
                                          return row[field];
                                        }),
                                      );
                                    }
                                  }}
                                >
                                  <StatusBadge
                                    label={row?.orderStatus?.orderStatusName}
                                    stage={row?.orderStatus?.orderStatusName}
                                    enumType={OrderStatuses}
                                  />
                                </td>
                              );
                            case 'billToContactStatus':
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  style={{
                                    width: `calc(100% / ${
                                      gridColumns.filter(
                                        (column) => column.visible === true,
                                      ).length
                                    })`,
                                    whiteSpace: 'nowrap',
                                  }}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  onClick={(event) => {
                                    if (onSelect) {
                                      onSelect(
                                        row,
                                        rowKeys?.reduce((keyObj, field) => {
                                          return row[field];
                                        }),
                                      );
                                    }
                                  }}
                                >
                                  <StatusBadge
                                    label={row?.billToContactStatus}
                                    stage={row?.billToContactStatus}
                                    enumType={ContactStatusStage}
                                  />
                                </td>
                              );
                            default:
                              return (
                                <td
                                  className={getTdClass(item.name)}
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  onClick={(event) => {
                                    if (onSelect) {
                                      onSelect(
                                        row,
                                        rowKeys?.reduce((keyObj, field) => {
                                          return row[field];
                                        }),
                                      );
                                    }
                                  }}
                                  style={{
                                    width: `calc(100% / ${
                                      gridColumns.filter(
                                        (column) => column.visible === true,
                                      ).length
                                    })`,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {gridColumns?.find(
                                    (column) =>
                                      column.name === item.name &&
                                      column.type === 'customField' &&
                                      column.customFieldType ===
                                        CustomFieldType.Boolean,
                                  ) ? (
                                    lodash.get(row, item.name) === true ||
                                    lodash.get(row, item.name) === 'true' ? (
                                      <>&#x2713;</>
                                    ) : (
                                      <></>
                                    )
                                  ) : gridColumns?.find(
                                      (column) =>
                                        (column.name === item.name &&
                                          column.type === 'customField' &&
                                          column.customFieldType ===
                                            CustomFieldType.Date) ||
                                        item.type === CustomFieldType.Date,
                                    ) ? (
                                    getFormattedDate(
                                      lodash.get(row, item.name),
                                      false,
                                    )
                                  ) : (
                                    <>{lodash.get(row, item.name)}</>
                                  )}
                                </td>
                              );
                          }
                        })}
                      {(onEdit || onDelete) && (
                        <td style={{ textAlign: 'center' }}>
                          <div className="dropdown">
                            <BsThreeDotsVertical
                              className="dropdown-toggle dropdown-dots-vertical pointer"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            />
                            <div
                              className="dropdown-menu dropdown-menu-grid"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {onDelete && (
                                <span
                                  title={
                                    row.charges?.length > 0
                                      ? 'Quote contains charges'
                                      : null
                                  }
                                >
                                  <a
                                    role="button"
                                    className="dropdown-item"
                                    onClick={() => {
                                      onDelete(row);
                                    }}
                                    style={
                                      row.charges?.length > 0
                                        ? {
                                            pointerEvents: 'none',
                                            color: 'gray',
                                          }
                                        : null
                                    }
                                  >
                                    Delete
                                  </a>
                                </span>
                              )}
                              {onEdit && (
                                <a
                                  role="button"
                                  className="dropdown-item"
                                  onClick={() => {
                                    onEdit(row);
                                  }}
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center mt-4">Nothing Found</p>
        )}
        <div className="grid-footer">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
            onLimitChanged={onLimitChanged}
          />
        </div>
      </div>
    </>
  );
};
