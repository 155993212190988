import {
  Column,
  CommodityDto,
  CreateCommodityCommandValues,
  EntityFieldDto,
  LinkDto,
  LinkResourceBaseDto,
  OrderTypes,
  UpdateCommodityCommandValues,
  UpdateOneOfManyCommodityValues,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCommodityRequest,
  deleteCommodityRequest,
  getCommoditiesListRequest,
  GetCommodityParams,
  getCommodityRequest,
  updateCommoditiesRequest,
  updateCommodityRequest,
} from './commodities.service';
import { getEntityFieldListRequest } from '../entityFields/entityFields.service';

type CommoditiesStoreState = {
  links: LinkDto[];
  commodityColumns: EntityFieldDto[];
  defaultColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getCommoditiesColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Commodity',
  });
});

export const getCommoditiesDefaultColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Commodity',
  });
});

export const updateCommoditiesColumns = createEvent<EntityFieldDto[]>();
export const updateCommoditiesForOrderColumns = createEvent<Column[]>();
export const updateCommoditiesForConsolidatedShipment = createEvent<Column[]>();
export const updateCommoditiesTableColumns = createEvent<Column[]>();
export const updateReceivingCommoditiesTableColumns = createEvent<Column[]>();
export const updatePackingCommoditiesTableColumns = createEvent<Column[]>();
export const updatePickingCommoditiesTableColumns = createEvent<Column[]>();
export const getCommodities = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCommoditiesListRequest(currentOrganization, params);
});

export const getCommoditiesForOrder = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return;
  },
);

export const createCommodity = createEffect((commodityData: CommodityDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  commodityData.organizationId = currentOrganization?.organizationId;
  const createCommodityCommand: CreateCommodityCommandValues = {
    ...commodityData,
  };

  return createCommodityRequest(currentOrganization!, createCommodityCommand);
});

export const getCommodity = createEffect(
  (commodityParams: GetCommodityParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');
    return getCommodityRequest(
      currentOrganization as LinkResourceBaseDto,
      commodityParams,
    );
  },
);

export const updateCommodity = createEffect((commodity: CommodityDto) => {
  const updateCommodityCommand: UpdateCommodityCommandValues = { ...commodity };
  return updateCommodityRequest(commodity, updateCommodityCommand);
});

export const updateCommodities = createEffect((commodities: CommodityDto[]) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const updateCommoditiesCommand: UpdateOneOfManyCommodityValues[] = [
    ...commodities,
  ];
  return updateCommoditiesRequest(
    currentOrganization,
    updateCommoditiesCommand,
  );
});

export const deleteCommodity = createEffect((commodity: CommodityDto) => {
  return deleteCommodityRequest(commodity);
});

const defaultState: CommoditiesStoreState = {
  links: [],
  commodityColumns: [],
  defaultColumns: [],
  defaultSort: 'commodityId',
  defaultLimit: 20,
};

const defaultStateForOrder: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'commodityStatus',
      visible: true,
      title: 'Status',
      sortName: 'commodityStatusId',
      dataField: 'statusName',
    },
    {
      name: 'commodityTypeName',
      visible: true,
      title: 'Commodity Type',
      sortName: 'CommodityType.Description',
    },
    {
      name: 'packageTypeName',
      visible: true,
      title: 'Package Type',
      sortName: 'PackageType.Name',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      dataField: 'code',
      sortName: 'WarehouseLocation.Code',
    },
    { name: 'pieces', visible: false, title: 'pieces', sortName: 'pieces' },
    { name: 'length', visible: false, title: 'Length', sortName: 'length' },
    { name: 'width', visible: false, title: 'Width', sortName: 'width' },
    { name: 'height', visible: false, title: 'Height', sortName: 'height' },
    { name: 'weight', visible: false, title: 'Weight', sortName: 'weight' },
    {
      name: 'volumePiece',
      visible: false,
      title: 'Volume',
      sortName: 'volumePiece',
    },
    {
      name: 'valueTotal',
      visible: true,
      title: 'Value',
      sortName: 'valueTotal',
    },
    {
      name: 'commodityTypeCode',
      visible: false,
      title: 'Commodity Type Code',
      sortName: 'CommodityType.Code',
    },
    {
      name: 'shipments',
      visible: true,
      title: 'Shipments',
      sortName: 'Shipments',
      visibleForOrderTypes: [
        OrderTypes.Order,
        OrderTypes.AirShipmentOrder,
        OrderTypes.OceanShipmentOrder,
        OrderTypes.ParcelShipment,
        OrderTypes.CargoMovement,
        OrderTypes.Commodity,
        OrderTypes.Quote,
        OrderTypes.WarehouseReceipt,
      ],
      orderTypes: [
        OrderTypes.AirShipmentOrder,
        OrderTypes.CargoMovement,
        OrderTypes.Commodity,
        OrderTypes.OceanShipmentOrder,
        OrderTypes.Order,
        OrderTypes.ParcelShipment,
        OrderTypes.Purchase,
        OrderTypes.Quote,
        OrderTypes.WarehouseReceipt,
      ],
      selectable: false,
    },
    {
      name: 'shipments',
      visible: true,
      title: 'Parcel Shipment',
      sortName: 'Shipments',
      visibleForOrderTypes: [OrderTypes.Purchase],
      orderTypes: [OrderTypes.ParcelShipment],
      selectable: false,
    },
  ],
};

const defaultStateForConsolidatedShipment: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'commodityStatus',
      visible: true,
      title: 'Status',
      sortName: 'commodityStatusId',
      dataField: 'statusName',
    },
    {
      name: 'description',
      visible: false,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'commodityTrackingNumber',
      visible: true,
      title: 'Pallet Number',
      sortName: 'CustomValues.pallet',
    },
    {
      name: 'parcelTrackingNumber',
      visible: true,
      title: 'Parcel Tracking Number',
      sortName: 'parcelTrackingNumber',
      selectable: false,
    },
    {
      name: 'warehouseLocation',
      visible: false,
      title: 'Location',
      dataField: 'code',
      sortName: 'WarehouseLocation.Code',
    },
    { name: 'pieces', visible: false, title: 'pieces', sortName: 'pieces' },
    { name: 'length', visible: false, title: 'Length', sortName: 'length' },
    { name: 'width', visible: false, title: 'Width', sortName: 'width' },
    { name: 'height', visible: false, title: 'Height', sortName: 'height' },
    { name: 'weightTotal', visible: true, title: 'Weight', sortName: 'weight' },
    {
      name: 'volumePiece',
      visible: false,
      title: 'Volume',
      sortName: 'volumePiece',
    },
    {
      name: 'commodityTypeCode',
      visible: false,
      title: 'Commodity Type Code',
      sortName: 'CommodityType.Code',
    },
    {
      name: 'shipments',
      visible: false,
      title: 'Shipments',
      sortName: 'Shipments',
      visibleForOrderTypes: [
        OrderTypes.Order,
        OrderTypes.AirShipmentOrder,
        OrderTypes.OceanShipmentOrder,
        OrderTypes.ParcelShipment,
        OrderTypes.CargoMovement,
        OrderTypes.Commodity,
        OrderTypes.Quote,
        OrderTypes.WarehouseReceipt,
      ],
      orderTypes: [
        OrderTypes.AirShipmentOrder,
        OrderTypes.CargoMovement,
        OrderTypes.Commodity,
        OrderTypes.OceanShipmentOrder,
        OrderTypes.Order,
        OrderTypes.ParcelShipment,
        OrderTypes.Purchase,
        OrderTypes.Quote,
        OrderTypes.WarehouseReceipt,
      ],
      selectable: false,
    },
    {
      name: 'shipments',
      visible: false,
      title: 'Parcel Shipment',
      sortName: 'Shipments',
      visibleForOrderTypes: [OrderTypes.Purchase],
      orderTypes: [OrderTypes.ParcelShipment],
      selectable: false,
    },
  ],
};

const defaultStateForMovementEdit: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'commodityStatus',
      visible: true,
      title: 'Status',
      sortName: 'commodityStatusId',
      dataField: 'statusName',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'commodityTrackingNumber',
      visible: true,
      title: 'Pallet Number',
      sortName: 'CustomValues.pallet',
    },
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      dataField: 'code',
      sortName: 'WarehouseLocation.Code',
    },
    { name: 'pieces', visible: false, title: 'pieces', sortName: 'pieces' },
    { name: 'length', visible: false, title: 'Length', sortName: 'length' },
    { name: 'width', visible: false, title: 'Width', sortName: 'width' },
    { name: 'height', visible: false, title: 'Height', sortName: 'height' },
    { name: 'weightTotal', visible: true, title: 'Weight', sortName: 'weight' },
    {
      name: 'volumePiece',
      visible: false,
      title: 'Volume',
      sortName: 'volumePiece',
    },
    {
      name: 'commodityTypeCode',
      visible: false,
      title: 'Commodity Type Code',
      sortName: 'CommodityType.Code',
    },
    {
      name: 'shipments',
      visible: true,
      title: 'Shipments',
      sortName: 'Shipments',
      visibleForOrderTypes: [
        OrderTypes.Order,
        OrderTypes.AirShipmentOrder,
        OrderTypes.OceanShipmentOrder,
        OrderTypes.ParcelShipment,
        OrderTypes.CargoMovement,
        OrderTypes.Commodity,
        OrderTypes.Quote,
        OrderTypes.WarehouseReceipt,
      ],
      orderTypes: [
        OrderTypes.AirShipmentOrder,
        OrderTypes.CargoMovement,
        OrderTypes.Commodity,
        OrderTypes.OceanShipmentOrder,
        OrderTypes.Order,
        OrderTypes.ParcelShipment,
        OrderTypes.Purchase,
        OrderTypes.Quote,
        OrderTypes.WarehouseReceipt,
      ],
      selectable: false,
    },
    {
      name: 'shipments',
      visible: true,
      title: 'Parcel Shipment',
      sortName: 'Shipments',
      visibleForOrderTypes: [OrderTypes.Purchase],
      orderTypes: [OrderTypes.ParcelShipment],
      selectable: false,
    },
  ],
};

const defaultStateCommodityTable: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'expand',
      visible: true,
      title: '',
      sortName: null,
      selectable: false,
      width: '5%',
    },
    {
      name: 'commodityStatus',
      visible: true,
      title: 'Status',
      sortName: 'commodityStatusId',
      width: '10%',
      showFilter: true,
      type: 'commodityStatus',
      dataField: 'statusName',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
      width: '30%',
    },
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      dataField: 'code',
      sortName: 'warehouseLocation.code',
      selectable: false,
      width: '30%',
    },
    {
      name: 'shipments',
      visible: true,
      title: 'Parcel Shipment',
      sortName: 'shipments.0.trackingNumber',
      orderTypes: [OrderTypes.ParcelShipment],
      selectable: false,
      width: '15%',
    },
    {
      name: 'dangerous',
      visible: true,
      title: 'Dangerous',
      orderTypes: [OrderTypes.ParcelShipment],
      dataField: 'dangerous',
      selectable: false,
      width: '10%',
    },
  ],
};

const defaultStateReceivingCommodityTable: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'expand',
      visible: true,
      title: '',
      sortName: null,
      selectable: false,
      width: '5%',
    },
    {
      name: 'commodityStatus',
      visible: true,
      title: 'Status',
      sortName: 'commodityStatusId',
      width: '10%',
      type: 'commodityStatus',
      dataField: 'statusName',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
      width: '30%',
    },
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      dataField: 'code',
      sortName: 'warehouseLocation.code',
      selectable: false,
      width: '15%',
    },
    {
      name: 'shipments',
      visible: true,
      title: 'Parcel Shipment',
      sortName: 'shipments.0.trackingNumber',
      orderTypes: [OrderTypes.ParcelShipment],
      selectable: false,
      width: '15%',
    },
    {
      name: 'received',
      visible: true,
      title: 'Received Timestamp',
      sortName: 'shipments[orderType=CargoMovement].0.createdDate',
      orderTypes: [OrderTypes.CargoMovement],
      dataField: 'shipments',
      selectable: false,
      width: '15%',
    },
  ],
};

const defaultStatePackingCommodityTable: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'expand',
      visible: true,
      title: '',
      sortName: null,
      selectable: false,
      width: '5%',
    },
    {
      name: 'packingStatus',
      visible: true,
      title: 'Status',
      sortName: null,
      width: '10%',
      type: 'commodityStatus',
      dataField: 'statusName',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
      width: '20%',
    },
    {
      name: 'quantity',
      visible: true,
      title: 'Quantity',
      sortName: 'quantity',
      width: '10%',
    },
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      dataField: 'code',
      sortName: 'warehouseLocation.code',
      selectable: false,
      width: '15%',
    },
    {
      name: 'shipments',
      visible: true,
      title: 'Content Verification',
      sortName: 'shipments.0.trackingNumber',
      orderTypes: [OrderTypes.ParcelShipment],
      selectable: false,
      width: '15%',
    },
    {
      name: 'received',
      visible: true,
      title: 'Received Timestamp',
      sortName: 'shipments[orderType=CargoMovement].0.createdDate',
      orderTypes: [OrderTypes.CargoMovement],
      dataField: 'shipments',
      selectable: false,
      width: '15%',
    },
    {
      name: 'dangerous',
      visible: true,
      title: 'Dangerous',
      orderTypes: [OrderTypes.ParcelShipment],
      dataField: 'dangerous',
      selectable: false,
      width: '10%',
    },
  ],
};

const defaultStateActivityCommoditiesTable: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      sortName: 'warehouseLocation.code',
      selectable: false,
      width: '20%',
      type: 'activityLocation',
    },
    {
      name: 'commodityTrackingNumber',
      visible: true,
      title: 'Tracking number',
      sortName: 'commodityTrackingNumber',
      width: '15%',
    },
    {
      name: 'parcelTrackingNumber',
      visible: true,
      title: 'Parcel shipment number',
      sortName: 'parcelTrackingNumber',
      width: '15%',
      selectable: false,
    },
    {
      name: 'activityStatus',
      visible: true,
      title: 'Status',
      sortName: 'activityStatus',
      width: '15%',
    },
    {
      name: 'commodityAction',
      visible: true,
      title: 'Action',
      width: '10%',
      selectable: false,
    },
  ],
};

const defaultStatePutAwayActivityCommoditiesTable: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'activityStatus',
      visible: true,
      title: 'Status',
      sortName: 'activityStatus',
      width: '20%',
    },
    {
      name: 'commodityTrackingNumber',
      visible: true,
      title: 'Tracking number',
      sortName: 'commodityTrackingNumber',
      width: '20%',
    },
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      sortName: 'warehouseLocation.code',
      selectable: false,
      width: '20%',
      type: 'activityLocation',
    },
    {
      name: 'commodityAction',
      visible: true,
      title: 'Action',
      width: '20%',
    },
  ],
};

const defaultStateAllPutAwayActivityCommoditiesTable: CommoditiesStoreState = {
  ...defaultState,
  commodityColumns: [
    {
      name: 'activityStatus',
      visible: true,
      title: 'Status',
      sortName: 'activityStatus',
      width: '20%',
    },
    {
      name: 'commodityTrackingNumber',
      visible: true,
      title: 'Tracking number',
      sortName: 'commodityTrackingNumber',
      width: '20%',
    },
    {
      name: 'palletNumber',
      visible: true,
      title: 'Pallet number',
      sortName: 'palletNumber',
      width: '20%',
    },
    {
      name: 'warehouseLocation',
      visible: true,
      title: 'Location',
      sortName: 'warehouseLocation.code',
      selectable: false,
      width: '20%',
      type: 'activityLocation',
    },
    {
      name: 'commodityAction',
      visible: true,
      title: 'Action',
      width: '20%',
    },
  ],
};

export const commodityStore: Store<CommoditiesStoreState> = createStore(
  defaultState,
)
  .on(getCommoditiesColumns.done, (state, payload) => {
    return {
      ...state,
      commodityColumns: payload.result.items,
      defaultColumns: payload.result.items,
    };
  })
  .on(getCommoditiesDefaultColumns.done, (state, payload) => {
    return {
      ...state,
      defaultColumns: payload.result.items,
    };
  })
  .on(updateCommoditiesColumns, (state, payload) => {
    return { ...state, commodityColumns: payload };
  });

export const commodityStoreForOrder: Store<CommoditiesStoreState> = createStore(
  defaultStateForOrder,
).on(updateCommoditiesForOrderColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const commodityStoreForConsolidatedShipment: Store<CommoditiesStoreState> = createStore(
  defaultStateForConsolidatedShipment,
).on(updateCommoditiesForConsolidatedShipment, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const commodityStoreForCargoMovement: Store<CommoditiesStoreState> = createStore(
  defaultStateForMovementEdit,
).on(updateCommoditiesForConsolidatedShipment, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const commodityTableStore: Store<CommoditiesStoreState> = createStore(
  defaultStateCommodityTable,
).on(updateCommoditiesTableColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const receivingCommodityTableStore: Store<CommoditiesStoreState> = createStore(
  defaultStateReceivingCommodityTable,
).on(updateReceivingCommoditiesTableColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const packingCommodityTableStore: Store<CommoditiesStoreState> = createStore(
  defaultStatePackingCommodityTable,
).on(updatePackingCommoditiesTableColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const pickingCommoditiyTableStore: Store<CommoditiesStoreState> = createStore(
  defaultStateActivityCommoditiesTable,
).on(updatePickingCommoditiesTableColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const putAwayCommoditiyTableStore: Store<CommoditiesStoreState> = createStore(
  defaultStatePutAwayActivityCommoditiesTable,
).on(updatePickingCommoditiesTableColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});

export const allPutAwayCommoditiyTableStore: Store<CommoditiesStoreState> = createStore(
  defaultStateAllPutAwayActivityCommoditiesTable,
).on(updatePickingCommoditiesTableColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});
