import { LinkDto, EntityFieldDto } from '../../models/data.models';
import { createStore, Store, createEvent, createEffect } from 'effector';
import { organizationsStore } from '../organization/organization.store';
import { getEntityFieldListRequest } from '../entityFields/entityFields.service';

export type ParcelShipmentsStoreState = {
  links: LinkDto[];
  parcelShipmentColumns: EntityFieldDto[];
  defaultColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const consolidatedShipmentParcelColumns: EntityFieldDto[] = [
  {
    name: 'orderNumber',
    type: 'string',
    description: 'Order Number',
    entity: 'OrderDto',
    title: 'Load',
    visible: true,
    sortName: 'orderNumber',
    showFilter: false,
  },
  {
    name: 'trackingNumber',
    type: 'string',
    description: 'Tracking Number',
    entity: 'OrderDto',
    title: 'Tracking Number',
    visible: true,
    sortName: 'trackingNumber',
    showFilter: false,
  },
  {
    name: 'destination',
    type: 'string',
    description: 'Destination',
    entity: 'OrderDto',
    title: 'Destination',
    visible: true,
    sortName: '',
    showFilter: false,
  },
  {
    name: 'customValues.modeOfTransportationIdDescription',
    type: 'string',
    description: 'Mode of transportation',
    entity: 'OrderDto',
    title: 'Transportation mode',
    visible: true,
    sortName: 'CustomValues.modeOfTransportationIdDescription',
    showFilter: true,
  },
  {
    name: 'orderStatusName',
    type: 'string',
    description: 'Order Status Name',
    entity: 'OrderDto',
    title: 'Status',
    visible: true,
    sortName: 'orderStatus.OrderStatusName',
    showFilter: true,
  },
  {
    name: 'commodities[0].warehouseLocationName',
    type: 'string',
    description: 'Warehouse Location',
    entity: 'OrderDto',
    title: 'Location',
    visible: true,
    sortName: '',
    showFilter: true,
  },
];

export const getParcelShipmentsColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ParcelShipment',
  });
});

export const getParcelShipmentsDefaultColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ParcelShipment',
  });
});

export const updateParcelShipmentsColumns = createEvent<EntityFieldDto[]>();

export const defaultState: ParcelShipmentsStoreState = {
  links: [],
  parcelShipmentColumns: [],
  defaultColumns: [],
  defaultSort: '-orderNumber~ToInt32',
  defaultLimit: 20,
};

export const parcelShipmentsStore: Store<ParcelShipmentsStoreState> = createStore(
  defaultState,
)
  .on(getParcelShipmentsColumns.done, (state, payload) => {
    return {
      ...state,
      parcelShipmentColumns: payload.result.items,
      defaultColumns: payload.result.items,
    };
  })
  .on(getParcelShipmentsDefaultColumns.done, (state, payload) => {
    return {
      ...state,
      defaultColumns: payload.result.items,
    };
  })
  .on(updateParcelShipmentsColumns, (state, payload) => {
    return { ...state, parcelShipmentColumns: payload };
  });
