import { Field } from 'formik';
import { Input, InputPublicProps } from './input.component';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-graphqlschema';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/ext-searchbox';

import { useEffect, useState } from 'react';

const getValue = (value, output) => {
  if (output === 'object') {
    return JSON.stringify(value, null, 2);
  }

  return value;
};

const CodeAceEditor = (props: any) => {
  const [error, setError] = useState(null);
  const [content, setContent] = useState(
    getValue(props.valueInput, props.output),
  );

  useEffect(() => {
    setContent(getValue(props.valueInput, props.output));
  }, [props.valueInput, props.output]);

  const handleChange = (value) => {
    let objValue = value;
    if (props.mode === 'json') {
      try {
        const obj = JSON.parse(value);
        // remove errors if we are able to parse the value
        setError(undefined);

        if (props.output === 'object') {
          objValue = obj;
        }
      } catch (e) {
        setError('Invalid JSON:' + e.message);
      }
    }

    if (props.onChange) props.onChange(objValue);
  };

  return (
    <div className={`form-group ${props.className}`} style={props.style}>
      <label htmlFor={props.name} className="input-label">
        <span className="input-label-title">
          {props.required && <span className="text-danger">*</span>}
        </span>

        {props.label}
      </label>
      <AceEditor
        theme="github" // set the theme
        name={props.name + '-ace-editor'}
        value={content}
        width="100%"
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        {...props}
        onChange={handleChange}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};

const FormikAceEditor = ({ field, form, ...props }) => {
  const [content, setContent] = useState(getValue(field.value, props.output));

  const handleChange = (value) => {
    let objValue = value;
    if (props.mode === 'json') {
      try {
        const obj = JSON.parse(value);
        // remove errors if we are able to parse the value
        form.setFieldError(field.name, undefined);

        if (props.output === 'object') {
          objValue = obj;
        }
      } catch (e) {
        form.setFieldError(field.name, 'Invalid JSON:' + e.message);
      }
    }
    setContent(value);
    form.setFieldValue(field.name, objValue);
    if (props.onChange) props.onChange(objValue);
  };

  return (
    <div className={`form-group ${props.className}`} style={props.style}>
      <label htmlFor={field.name} className="input-label">
        <span className="input-label-title">
          {props.required && <span className="text-danger">*</span>}
        </span>

        {props.label}
      </label>
      <AceEditor
        theme="github" // set the theme
        name={field.name + '-ace-editor'}
        value={content}
        width="100%"
        height="800px"
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        {...props}
        onChange={handleChange}
      />
      {form.errors[field.name] && (
        <div className="invalid-feedback d-block">
          {form.errors[field.name]}
        </div>
      )}
    </div>
  );
};

export const YamlField = (props: InputPublicProps) => {
  return <Field {...props} component={FormikAceEditor} mode="yaml" />;
};

export const JsonField = (props: InputPublicProps) => {
  return <Field {...props} component={FormikAceEditor} mode="json" />;
};

export const JsonInput = (props: InputPublicProps) => {
  return <CodeAceEditor {...props} mode="json" />;
};

export const GraphqlField = (props: InputPublicProps) => {
  return <Field {...props} component={FormikAceEditor} mode="graphqlschema" />;
};

export const HtmlField = (props: InputPublicProps) => {
  return <Field {...props} component={FormikAceEditor} mode="html" />;
};
