import { createEffect, createStore } from 'effector';
import { organizationsStore } from '../organization/organization.store';
import { queryGraphQl } from '../graphql/graphql.service';

export const getCurrentUserFx = createEffect(async () => {
  const { currentOrganization } = organizationsStore.getState();

  const data = await queryGraphQl({
    query: `query($organizationId: Int!) {
      currentUser{
        id
        email
        userName
        firstName
        lastName
        permissions(organizationId: $organizationId) {
          name
        }
        organizationContact:  contact(organizationId: $organizationId) {
          contactId
          divisionId
        }
      }
    }`,
    variables: {
      organizationId: currentOrganization?.organizationId,
    },
    fetchPolicy: 'cache-first',
  });

  return data.currentUser;
});

const currentUserStore = createStore(null);

currentUserStore.on(getCurrentUserFx.done, (state, payload) => {
  return {
    ...state,
    ...payload.result,
    fullName: (
      `${payload.result.firstName} ${payload.result.lastName}` ??
      payload.result.userName
    )?.trim(),
    permissionSet: new Set(payload.result.permissions.map((p) => p.name)), // for faster permission check
    permissions: payload.result.permissions.map((p) => p.name),
  };
});

export { currentUserStore };
