import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PackageReceiveForm } from './packageReceive.form';
import * as Yup from 'yup';
import { GetOrderByTrackingNumbersParams } from '../../orders/orders.service';
import { getOrdersByTrackingNumbers } from '../../orders/orders.store';
import {
  LocationType,
  OrderDto,
  OrderTypes,
  ScanningResultFrom,
} from '../../../models/data.models';
import { useTrackingNumberHandler } from '../hooks/useTrackingNumberHandler';
import { Spinner } from '../../common/components/spinner/spinner';
import { clearActionButtons, setActionButtons } from '../../common/nav.store';
import { HiOutlineArrowSmRight, HiX } from 'react-icons/hi';
import { useScanner } from '../../barcodeScanner/components/scan.hook';
import { ScanningResult } from '../../barcodeScanner/scanner.store';
import { FormContext } from '../../common/components/form/form.component';
import { FormikProps, FormikValues } from 'formik';
import { handleAutoSubmitBarcodeScanning } from '../../barcodeScanner/components/scan-button.component';
import { createActionEventFx } from '../../actionEvents/actionEvents.store';
import { extractTrackingCodeFromFedexLabel } from '../../../apps/fedex/hooks/fedexTrackingCode';
import { extractTrackingCodeFromUSPSBarcode } from '../../../apps/usps/hooks/uspsBarcodeReader';

export type PackageScanFragmentProps = {
  handleNext: () => void;
  onCancel: () => void;
  setPurchase: any;
  setParcelShipment: any;
  setTrackingNumbers: any;
  notification?: string;
};

const packageSchema = Yup.object().shape({
  trackingNumber: Yup.string().required("Can't be blank"),
});

export const getInitialState = () => {
  const initialState: GetOrderByTrackingNumbersParams = {
    trackingNumbers: [],
  };

  return initialState;
};

export const PackageScanFragment = ({
  handleNext,
  onCancel,
  setPurchase,
  setParcelShipment,
  setTrackingNumbers,
  notification,
}: PackageScanFragmentProps) => {
  const [isSending, setIsSending] = useState(false);

  const { onTrackingNumberSubmit } = useTrackingNumberHandler();
  const [parseBarcodes, setParseBarcodes] = useState(true);

  useEffect(() => {
    if (onCancel)
      setActionButtons([
        <Button
          key={'package-receive-scan-fragment-cancel-btn'}
          color="secondary"
          className={'d-flex align-items-center justify-content-center'}
          outline={true}
          onClick={onCancel}
          style={{ minWidth: '8.5rem' }}
        >
          <HiX size={16} className={'mr-2'} />
          Cancel
        </Button>,
      ]);
  }, []);

  useEffect(() => () => clearActionButtons(), []);

  const [formikContext, setFormikContext] = useState<FormikProps<FormikValues>>(
    null,
  );
  const [trackingNumber, setTrackingNumber] = useState('');

  useScanner((scannerResult: ScanningResult) => {
    catchScanResult(scannerResult, formikContext);
  });

  const catchScanResult = (
    scanningResult: ScanningResult,
    context: FormikProps<FormikValues>,
  ) => {
    if (scanningResult.from === ScanningResultFrom.Scanner) {
      if (scanningResult.data) {
        if (!trackingNumber) {
          context.setFieldValue('trackingNumber', scanningResult.data);
          setTrackingNumber(scanningResult.data);

          handleAutoSubmitBarcodeScanning(
            context,
            scanningResult.data,
            onSubmit,
          );
        }
      }
    }
  };

  const onSubmit = (data) => {
    const params: GetOrderByTrackingNumbersParams = {
      trackingNumbers: [],
    };

    data.trackingNumber = data.trackingNumber?.trim();

    if (parseBarcodes) {
      const payload = {
        data: data.trackingNumber,
      };

      extractTrackingCodeFromUSPSBarcode(payload);
      extractTrackingCodeFromFedexLabel(payload);

      if (payload?.data) {
        data.trackingNumber = payload.data;
      }
    }

    params.trackingNumbers.push(data.trackingNumber);

    setIsSending(true);
    getOrdersByTrackingNumbers(params)
      .then(async (ordersDto: OrderDto[]) => {
        await createActionEventFx({
          eventName: 'purchase.receive',
          eventData: {
            trackingNumber: data.trackingNumber,
            location: 'RCV',
          },
        });

        let purchase = ordersDto.find(
          (order: OrderDto) => order.orderType === OrderTypes.Purchase,
        );

        // Hack for parcel shipment
        if (!purchase && ordersDto.length > 0) {
          purchase = ordersDto.find(
            (order: OrderDto) => order.orderType === OrderTypes.ParcelShipment,
          );
        }

        if (purchase) {
          if (params.trackingNumbers.length > 1) {
            const newTrackingNumber = params.trackingNumbers.find(
              (t) => t !== purchase.trackingNumber.toString(),
            );

            if (newTrackingNumber) {
              onTrackingNumberSubmit({
                commodityId: purchase?.commodities[0]?.commodityId,
                trackingNumber: newTrackingNumber,
                isPrimary: false,
              }).then(
                () => {
                  const parcelShipment = ordersDto.find(
                    (order: OrderDto) =>
                      order.orderType === OrderTypes.ParcelShipment,
                  );

                  setPurchase(purchase);
                  if (parcelShipment) {
                    setParcelShipment(parcelShipment);
                  }

                  handleNext();
                },
                () => {},
              );
            }
          } else {
            const parcelShipment = ordersDto.find(
              (order: OrderDto) =>
                order.orderType === OrderTypes.ParcelShipment,
            );

            setPurchase(purchase);
            if (parcelShipment) {
              setParcelShipment(parcelShipment);
            }

            handleNext();
          }
        } else {
          setTrackingNumbers((prevNumbers) => [
            ...prevNumbers,
            {
              commodityId: 0,
              commodityTrackingNumberId: 0,
              trackingNumber: data.trackingNumber,
              isPrimary: true,
            },
          ]);

          handleNext();
        }
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <>
      {notification && (
        <div
          className="d-flex align-items-center justify-content-center mt-5"
          style={{ minHeight: '6vh' }}
        >
          <div
            className="box col-6 p-3 shadow-none text-center text-white"
            style={{ backgroundColor: '#00C714' }}
          >
            {notification}
          </div>
        </div>
      )}
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: notification ? '35vh' : '50vh' }}
      >
        <div className={'box col-6'}>
          <h3 className="pb-4 text-left">
            Scan Tracking Number Barcode or Enter Manually
          </h3>
          <PackageReceiveForm
            id={'PackageScanForm'}
            initialValues={getInitialState()}
            validationSchema={packageSchema}
            onSubmit={onSubmit}
          >
            <FormContext.Consumer>
              {(context) => {
                if (formikContext === null) {
                  setFormikContext(context);
                }
                return (
                  <>
                    <div className="py-3">
                      <h3 className={'text-label pb-2'}>Tracking Number</h3>
                      <PackageReceiveForm.TrackingNumber
                        barcode={false}
                        onChange={(event) => {
                          setTrackingNumber(event?.target?.value);
                        }}
                        autoSubmit={onSubmit}
                      />
                    </div>
                  </>
                );
              }}
            </FormContext.Consumer>
          </PackageReceiveForm>
          <div className="py-3">
            <div className="d-flex">
              <label className="text-label">Parse Barcodes</label>
              <input
                type="checkbox"
                className="ml-2"
                checked={parseBarcodes}
                onChange={(e) => setParseBarcodes(e.target.checked)}
              />
            </div>
          </div>

          {isSending ? (
            <Spinner />
          ) : (
            <div className="row justify-content-center">
              {handleNext && (
                <Button
                  form={'PackageScanForm'}
                  className="w-100 mx-2 d-flex align-items-center justify-content-center"
                  color="primary"
                  type="submit"
                >
                  <HiOutlineArrowSmRight size={20} className={'mr-2'} />
                  Next
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
