import {
  AppModuleDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  AppModuleListItemDtoPagedResult,
  CreateAppModuleCommandValues,
  UpdateAppModuleCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_APPMODULES_LINK_KEY = 'get-appmodules';
export const CREATE_APPMODULE_LINK_KEY = 'create-appmodule';
export const GET_APPMODULE_LINK_KEY = 'get-appmodule';
export const UPDATE_APPMODULE_LINK_KEY = 'update-appmodule';
export const DELETE_APPMODULE_LINK_KEY = 'delete-appmodule';
export const IMPORT_APPMODULES_LINK_KEY = 'import-appmodules';
export const EXPORT_APPMODULES_LINK_KEY = 'export-appmodules';
export const APPMODULE_ENTITY_NAME = 'AppModule';

export interface GetAppModuleParams {
  appModuleId: string;
}

export interface ImportAppModulesParams {
  organizationId: number;
}

export interface ExportAppModulesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getAppModuleRequest = async (
  resource: LinkResourceBaseDto | null,
  { appModuleId }: GetAppModuleParams,
): Promise<AppModuleDto> => {
  if (resource && appModuleId) {
    const getAppModuleLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_APPMODULE_LINK_KEY,
    );
    if (getAppModuleLink) {
      const result = await execLink(getAppModuleLink, { appModuleId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_APPMODULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getAppModulesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<AppModuleListItemDtoPagedResult> => {
  if (resource) {
    const getAppModulesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_APPMODULES_LINK_KEY,
    );
    if (getAppModulesListLink) {
      const result = await execLink(getAppModulesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_APPMODULES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createAppModuleRequest = async (
  resource: LinkResourceBaseDto,
  appModule: CreateAppModuleCommandValues,
) => {
  const createAppModuleLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_APPMODULE_LINK_KEY,
  );
  if (createAppModuleLink) {
    const result = await execLink(createAppModuleLink, appModule);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_APPMODULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateAppModuleRequest = async (
  resource: LinkResourceBaseDto,
  appModule: UpdateAppModuleCommandValues,
) => {
  const updateAppModuleLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_APPMODULE_LINK_KEY,
  );
  if (updateAppModuleLink) {
    const result = await execLink(updateAppModuleLink, appModule);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_APPMODULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteAppModuleRequest = async (appModule: AppModuleDto) => {
  const deleteAppModuleLink = appModule.links?.find(
    (x: LinkDto) => x.rel === DELETE_APPMODULE_LINK_KEY,
  );
  if (deleteAppModuleLink) {
    const result = await execLink(deleteAppModuleLink, appModule);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_APPMODULE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importAppModulesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportAppModulesParams,
  file: File,
) => {
  const importAppModulesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_APPMODULES_LINK_KEY,
  );
  if (importAppModulesLink) {
    const result = await postFormData(importAppModulesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_APPMODULES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportAppModulesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportAppModulesParams,
) => {
  const exportAppModulesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_APPMODULES_LINK_KEY,
  );
  if (exportAppModulesLink) {
    const result = await downloadLinkFile(exportAppModulesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_APPMODULES_LINK_KEY} is not allowed or missing link action`,
  );
};
