import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { JsonField } from '../../common/components/input/ace-input.component';

export const OrganizationConfigForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

OrganizationConfigForm.ConfigName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'configName'}
      label={'Organization Config Name'}
      placeholder={'Organization Config Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};
OrganizationConfigForm.Settings = ({
  className,
  style,
  size,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <JsonField
      name={'customValues'}
      label={'Organization Config Settings (JSON)'}
      placeholder={'Enter Settings'}
      type={'textarea'}
      className={className}
      style={style}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};
