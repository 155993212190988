import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { DriverPayForm } from './driverPay.form';
import {
  AccountingAccountDto,
  DivisionDto,
  ReceiveDriverPayCommandValues,
  VisibleTransactions,
} from '../../../models/data.models';
import { Panel } from '../../common/components/panel/panel.component';
import { ReceiveDriverPayFormDto } from '../../../models/custom.models';
import { FormContext } from '../../common/components/form/form.component';
import { receiveDriverPay } from '../driverPayManager.store';
import * as Yup from 'yup';
import { authStore } from '../../auth/auth.store';

export type ReceiveDriverPayProps = {
  driverContactId: number;
  orderIds: number[];
  onDriverPayReceived?: () => void;
  onCancel?: () => void;
};

const receiveDriverSchema = Yup.object().shape({
  receivedDate: Yup.date().required("Can't be blank").nullable(true),
  accountId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

export const ReceiveDriverPay = ({
  driverContactId,
  onDriverPayReceived = () => {},
  orderIds,
  onCancel = () => {},
}: ReceiveDriverPayProps) => {
  const { user: currentUser } = authStore.getState();

  const isCreateMode = true; //!orderId || orderId == 0;

  const [isLoading, setIsLoading] = useState(true);
  const [accountingAccount, setAccountingAccount] = useState(null);

  const [
    driverPayContextFormValue,
    setDriverPayContextFormValue,
  ] = useState<ReceiveDriverPayFormDto | null>({
    receivedDate: new Date(),
    accountId: null,
    accountName: null,
    accountType: null,
    divisionId: null,
    divisionName: null,
  });

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    }
  }, []);

  const onSubmit = (data: ReceiveDriverPayFormDto) => {
    const receiveDriverPayCommandValues: ReceiveDriverPayCommandValues = {
      ...data,
      driverContactId,
      orderIds,
    };
    return receiveDriverPay(receiveDriverPayCommandValues).then(
      () => {
        onDriverPayReceived();
      },
      () => {},
    );
  };

  const nextStep = () => {
    setStep(1);
  };

  const showMessage = () => {
    window.alert(
      'Please either adjust the payable rate on the load confirmation to match the driver invoice, or contact the driver to discuss.',
    );
  };

  const onReceivedDateChange = (date: Date) => {
    driverPayContextFormValue.receivedDate = date;
    setDriverPayContextFormValue({ ...driverPayContextFormValue });
  };

  const onAccountChange = (account: AccountingAccountDto) => {
    driverPayContextFormValue.accountId = account?.accountId;
    driverPayContextFormValue.accountName = account?.accountName;
    driverPayContextFormValue.accountType = account?.accountType;
    setDriverPayContextFormValue({ ...driverPayContextFormValue });
  };

  const onDivisionChange = (division: DivisionDto) => {
    driverPayContextFormValue.divisionId = division?.divisionId;
    driverPayContextFormValue.divisionName = division?.divisionName;
    setDriverPayContextFormValue({ ...driverPayContextFormValue });
  };

  return (
    <div className={'order-edit-form'}>
      <Panel className="m-3">
        <div className="row">
          <div className="col-12">
            <DriverPayForm
              id={'driver-pay-form'}
              initialValues={driverPayContextFormValue}
              onSubmit={onSubmit}
              validationSchema={receiveDriverSchema}
              // innerRef={orderFormRef}
            >
              <FormContext.Consumer>
                {(context) => (
                  <>
                    <>
                      <DriverPayForm.ReceivedDate
                        defaultValue={driverPayContextFormValue?.receivedDate}
                        onChange={onReceivedDateChange}
                      />
                      <DriverPayForm.AccountingAccountSelect
                        id="accountId"
                        nameId="accountName"
                        header="Account"
                        required={true}
                        listHeader={'List Account'}
                        placeholder={'Select Account'}
                        defaultValue={
                          driverPayContextFormValue &&
                          driverPayContextFormValue.accountId &&
                          driverPayContextFormValue.accountName &&
                          driverPayContextFormValue.accountType
                            ? {
                                accountId: driverPayContextFormValue?.accountId,
                                accountName:
                                  driverPayContextFormValue?.accountName,
                                accountType:
                                  driverPayContextFormValue?.accountType,
                              }
                            : ''
                        }
                        onChange={onAccountChange}
                      />
                      <DriverPayForm.DivisionId
                        id={'divisionId'}
                        header={'Division'}
                        required={true}
                        onChange={onDivisionChange}
                        nameId={'divisionName'}
                        defaultValue={
                          driverPayContextFormValue &&
                          driverPayContextFormValue.divisionId &&
                          driverPayContextFormValue.divisionName
                            ? {
                                divisionId:
                                  driverPayContextFormValue?.divisionId,
                                divisionName:
                                  driverPayContextFormValue?.divisionName,
                              }
                            : ''
                        }
                        defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                        disabled={
                          VisibleTransactions[
                            currentUser?.visibleTransactions
                          ] !== VisibleTransactions.AllTransactions
                        }
                      />
                      <div className="row">
                        <div className="col-4 offset-4">
                          <Button
                            type="button"
                            color="primary"
                            className="btn-block"
                            onClick={onCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="ml-auto col-4">
                          <Button
                            form={'driver-pay-form'}
                            type="submit"
                            color="primary"
                            className="btn-block"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </>
                  </>
                )}
              </FormContext.Consumer>
            </DriverPayForm>
          </div>
        </div>
      </Panel>
    </div>
  );
};
