import { OrderEditFormDto } from '../../models/custom.models';
import {
  CreateOrderDocumentCommandValues,
  CreateOrderDocumentsCommandValues,
  LinkDto,
  LinkResourceBaseDto,
  OrderDocumentDto,
  OrderDocumentDtoPagedResult,
  RegenerateOrderDocumentCommandValues,
  UpdateOrderDocumentCommandValues,
} from '../../models/data.models';
import { execLink, getActualLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { queryGraphQlFx } from '../graphql/graphql.store';
import { organizationsStore } from '../organization/organization.store';

export const GET_ORDERDOCUMENTS_LINK_KEY = 'get-orderdocuments';
export const CREATE_ORDERDOCUMENT_LINK_KEY = 'create-orderdocument';
export const CREATE_ORDERDOCUMENTS_LINK_KEY = 'create-orderdocuments';
export const GET_ORDERDOCUMENT_LINK_KEY = 'get-orderdocument';
export const UPDATE_ORDERDOCUMENT_LINK_KEY = 'update-orderdocument';
export const REGENERATE_ORDERDOCUMENT_LINK_KEY = 'regenerate-orderdocument';
export const DELETE_ORDERDOCUMENT_LINK_KEY = 'delete-orderdocument';
export const DELETE_ORDERDOCUMENTS_LINK_KEY = 'delete-orderdocuments';
export const GET_ORDERDOCUMENTCONTENT_LINK_KEY = 'get-orderdocumentcontent';

export interface GetOrderDocumentParams {
  orderDocumentId: number;
  order?: LinkResourceBaseDto | null;
}

export const getOrderDocumentRequest = async (
  resource: LinkResourceBaseDto | null,
  { orderDocumentId }: GetOrderDocumentParams,
): Promise<OrderDocumentDto> => {
  if (resource && orderDocumentId) {
    const getOrderDocumentLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERDOCUMENT_LINK_KEY,
    );
    if (getOrderDocumentLink) {
      const result = await execLink(getOrderDocumentLink, { orderDocumentId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ORDERDOCUMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getOrderDocumentContentUrlRequest = (orderDocument, fileType) => {
  const getOrderDocumentContentUrlLink = orderDocument.links?.find(
    (x: LinkDto) => x.rel === GET_ORDERDOCUMENTCONTENT_LINK_KEY,
  );
  if (getOrderDocumentContentUrlLink) {
    return getActualLink(getOrderDocumentContentUrlLink, { fileType }, true);
  }
};

export const getOrderDocumentsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderDocumentDtoPagedResult> => {
  if (resource) {
    const getOrderDocumentsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERDOCUMENTS_LINK_KEY,
    );
    if (getOrderDocumentsListLink) {
      const result = await execLink(getOrderDocumentsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ORDERDOCUMENTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createOrderDocumentRequest = async (
  resource: LinkResourceBaseDto,
  orderDocument: CreateOrderDocumentCommandValues,
) => {
  const createOrderDocumentLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORDERDOCUMENT_LINK_KEY,
  );
  if (createOrderDocumentLink) {
    const result = await execLink(createOrderDocumentLink, orderDocument);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ORDERDOCUMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createOrderDocumentsRequest = async (
  resource: LinkResourceBaseDto,
  orderDocuments: CreateOrderDocumentsCommandValues[],
) => {
  const createOrderDocumentsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORDERDOCUMENTS_LINK_KEY,
  );
  if (createOrderDocumentsLink) {
    const result = await execLink(createOrderDocumentsLink, orderDocuments);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ORDERDOCUMENTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateOrderDocumentRequest = async (
  resource: LinkResourceBaseDto,
  orderDocument: UpdateOrderDocumentCommandValues,
) => {
  const updateOrderDocumentLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORDERDOCUMENT_LINK_KEY,
  );
  if (updateOrderDocumentLink) {
    const result = await execLink(updateOrderDocumentLink, orderDocument);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_ORDERDOCUMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const regenerateOrderDocumentRequest = async (
  resource: LinkResourceBaseDto,
  values: RegenerateOrderDocumentCommandValues,
) => {
  const regenerateOrderDocumentLink = resource.links?.find(
    (x: LinkDto) => x.rel === REGENERATE_ORDERDOCUMENT_LINK_KEY,
  );
  if (regenerateOrderDocumentLink) {
    const result = await execLink(regenerateOrderDocumentLink, values);
    return result.data;
  }
  throw new Error(
    `Action ${REGENERATE_ORDERDOCUMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteOrderDocumentRequest = async (
  orderDocument: OrderDocumentDto,
) => {
  const deleteOrderDocumentLink = orderDocument.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORDERDOCUMENT_LINK_KEY,
  );
  if (deleteOrderDocumentLink) {
    const result = await execLink(deleteOrderDocumentLink, orderDocument);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ORDERDOCUMENT_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteOrderDocumentsRequest = async (
  resource: OrderEditFormDto,
  orderDocuments: OrderDocumentDto[],
) => {
  const deleteOrderDocumentsLink = resource.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORDERDOCUMENTS_LINK_KEY,
  );
  if (deleteOrderDocumentsLink) {
    const result = await execLink(deleteOrderDocumentsLink, orderDocuments);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ORDERDOCUMENTS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getOrderDocumentLink = async (document: OrderDocumentDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (
    document?.workflowId &&
    document?.lastGeneratedFile &&
    currentOrganization?.organizationId
  ) {
    const query = `query ($organizationId: Int!, $orderDocumentId: Int!) {
        orderDocument(
          organizationId: $organizationId
          orderDocumentId: $orderDocumentId
      ) {
          lastGeneratedFile
          linkToDocument: getLinkToDocument(expiresInDays: 1)
        }
      }`;
    const variables = {
      organizationId: currentOrganization?.organizationId,
      orderDocumentId: document?.orderDocumentId,
    };
    const queryResult = await queryGraphQlFx({
      query,
      variables,
    });
    return queryResult.orderDocument?.linkToDocument;
  }
  return null;
};
