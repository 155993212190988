import {
  GenerateInvoiceCommandValues,
  LinkDto,
  LinkResourceBaseDto,
  ReceiveCarrierPayCommandValues,
  UninvoicedOrderDtoPagedResult,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import {
  CarrierPayManagerForListDtoPagedResult,
  InvoiceManagerForListDtoPagedResult,
} from '../../models/custom.models';
import { CREATE_ORDER_LINK_KEY } from '../orders/orders.service';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_UNINVOICED_ORDERS_LINK_KEY = 'get-uninvoiced-orders';
export const GENERATE_INVOICE_LINK_KEY = 'generate-invoice';

export const getUninvoicedOrdersListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<UninvoicedOrderDtoPagedResult> => {
  if (resource) {
    const getUninvoicedOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_UNINVOICED_ORDERS_LINK_KEY,
    );
    if (getUninvoicedOrdersListLink) {
      const result = await execLink(getUninvoicedOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_UNINVOICED_ORDERS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const generateInvoiceRequest = async (
  resource: LinkResourceBaseDto,
  invoice: GenerateInvoiceCommandValues,
) => {
  const generateInvoiceLink = resource?.links?.find(
    (x: LinkDto) => x.rel === GENERATE_INVOICE_LINK_KEY,
  );
  if (generateInvoiceLink) {
    const result = await execLink(generateInvoiceLink, invoice);
    return result.data;
  }
  addMessage({
    message: `Action ${GENERATE_INVOICE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
