import { Dialog } from '../../../common/components/dialog/dialog.component';
import { OrderDto, TrackingEventDto } from '../../../../models/data.models';
import { BOLManagerComponent, BOLType } from './bol-manager.component';

export type BOLManagerDialogProperties = {
  title: string;
  className?: string;
  orderDto?: OrderDto;
  setTrackingEvents?: (value: React.SetStateAction<TrackingEventDto[]>) => void;
};

export class BOLManagerDialog extends Dialog<BOLManagerDialogProperties> {
  constructor(props: BOLManagerDialogProperties) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm = (bolData: BOLType) => {
    this.close(bolData);
  };

  renderContent(): any {
    const { className, orderDto, setTrackingEvents } = this
      .props as BOLManagerDialogProperties;
    return (
      <BOLManagerComponent
        className={className}
        orderDto={orderDto}
        onConfirm={this.onConfirm.bind(this)}
        onCancel={this.close.bind(this)}
        setTrackingEvents={setTrackingEvents}
      />
    );
  }
}
