import {
  DocumentTemplateDto,
  LinkDto,
  LinkResourceBaseDto,
  DocumentTemplateDtoPagedResult,
  CreateDocumentTemplateCommandValues,
  UpdateDocumentTemplateCommandValues,
  FileType,
} from '../../models/data.models';
import { downloadLinkFile, execLink, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_DOCUMENTTEMPLATES_LINK_KEY = 'get-documenttemplates';
export const CREATE_DOCUMENTTEMPLATE_LINK_KEY = 'create-documenttemplate';
export const GET_DOCUMENTTEMPLATE_LINK_KEY = 'get-documenttemplate';
export const UPDATE_DOCUMENTTEMPLATE_LINK_KEY = 'update-documenttemplate';
export const DELETE_DOCUMENTTEMPLATE_LINK_KEY = 'delete-documenttemplate';
export const DOWNLOAD_DEFAULT_DOCUMENTTEMPLATES_LINK_KEY =
  'download-documenttemplates';
export const GET_RENDEREDDOCUMENT_LINK_KEY = 'get-rendereddocument';

export const DOCUMENT_TEMPLATE_ENTITY_NAME = 'Document Template';

export const IMPORT_DOCUMENTTEMPLATES_LINK_KEY = 'import-documenttemplates';
export const EXPORT_DOCUMENTTEMPLATES_LINK_KEY = 'export-documenttemplates';

export interface GetDocumentTemplateParams {
  documentTemplateId: number;
}

export interface ImportDocumentTemplatesParams {
  organizationId: number;
}

export interface ExportDocumentTemplatesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getDocumentTemplateRequest = async (
  resource: LinkResourceBaseDto | null,
  { documentTemplateId }: GetDocumentTemplateParams,
): Promise<DocumentTemplateDto> => {
  if (resource && documentTemplateId) {
    const getDocumentTemplateLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_DOCUMENTTEMPLATE_LINK_KEY,
    );
    if (getDocumentTemplateLink) {
      const result = await execLink(getDocumentTemplateLink, {
        documentTemplateId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getDocumentTemplatesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<DocumentTemplateDtoPagedResult> => {
  if (resource) {
    const getDocumentTemplatesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_DOCUMENTTEMPLATES_LINK_KEY,
    );
    if (getDocumentTemplatesListLink) {
      const result = await execLink(getDocumentTemplatesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_DOCUMENTTEMPLATES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createDocumentTemplateRequest = async (
  resource: LinkResourceBaseDto,
  documentTemplate: CreateDocumentTemplateCommandValues,
) => {
  const createDocumentTemplateLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_DOCUMENTTEMPLATE_LINK_KEY,
  );
  if (createDocumentTemplateLink) {
    const result = await execLink(createDocumentTemplateLink, documentTemplate);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateDocumentTemplateRequest = async (
  resource: LinkResourceBaseDto,
  documentTemplate: UpdateDocumentTemplateCommandValues,
) => {
  const updateDocumentTemplateLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_DOCUMENTTEMPLATE_LINK_KEY,
  );
  if (updateDocumentTemplateLink) {
    const result = await execLink(updateDocumentTemplateLink, documentTemplate);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteDocumentTemplateRequest = async (
  documentTemplate: DocumentTemplateDto,
) => {
  const deleteDocumentTemplateLink = documentTemplate.links?.find(
    (x: LinkDto) => x.rel === DELETE_DOCUMENTTEMPLATE_LINK_KEY,
  );
  if (deleteDocumentTemplateLink) {
    const result = await execLink(deleteDocumentTemplateLink, documentTemplate);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importDocumentTemplatesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportDocumentTemplatesParams,
  file: File,
) => {
  const importDocumentTemplatesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_DOCUMENTTEMPLATES_LINK_KEY,
  );
  if (importDocumentTemplatesLink) {
    const result = await postFormData(importDocumentTemplatesLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_DOCUMENTTEMPLATES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportDocumentTemplatesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportDocumentTemplatesParams,
) => {
  const exportDocumentTemplatesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_DOCUMENTTEMPLATES_LINK_KEY,
  );
  if (exportDocumentTemplatesLink) {
    const result = await downloadLinkFile(exportDocumentTemplatesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_DOCUMENTTEMPLATES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const downloadDefaultDocumentTemplatesRequest = async (
  resource?: LinkResourceBaseDto | null,
): Promise<DocumentTemplateDtoPagedResult> => {
  if (resource) {
    const getDocumentTemplatesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === DOWNLOAD_DEFAULT_DOCUMENTTEMPLATES_LINK_KEY,
    );
    if (getDocumentTemplatesListLink) {
      const result = await execLink(getDocumentTemplatesListLink);
      return result.data;
    }
  }
  throw new Error(
    `Action ${DOWNLOAD_DEFAULT_DOCUMENTTEMPLATES_LINK_KEY} is not allowed or missing link action`,
  );
};
