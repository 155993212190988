import React from 'react';
import { LocationType } from '../../../../models/data.models';
import { CargoMovementForm } from '../../../cargoMovement/components/cargoMovement.form';
import { RiFileList2Line } from 'react-icons/ri';
import { FiShoppingCart, FiUser } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { Button } from '../button/button.component';
import { FaBarcode } from 'react-icons/fa';

export type ActivityHeaderProps = {
  type?: string;
  orderNumber?: string;
  assignedTo?: string;
  destination?: LocationType;
  showCartNumber?: boolean;
};
export const ActivityHeader = ({
  type,
  orderNumber,
  assignedTo,
  destination,
  showCartNumber,
}: ActivityHeaderProps) => {
  return (
    <div className="box py-sm-2 py-4">
      <div className="">
        <div className="d-flex flex-wrap row">
          <div className={'col-lg-7 col-12 d-flex flex-wrap'}>
            {type && orderNumber && (
              <div
                className={`text-placeholder font-weight-normal text-nowrap d-flex align-items-center mr-4`}
              >
                <RiFileList2Line
                  size={22}
                  className={'mr-2 color-text-disable'}
                />
                {`${type} order:`}&nbsp;
                <span className={'font-weight-bold'}>{orderNumber}</span>
              </div>
            )}
            {assignedTo && (
              <div
                className={`text-placeholder font-weight-normal text-nowrap d-flex align-items-center mr-4`}
              >
                <FiUser size={22} className={'mr-2 color-text-disable'} />
                Assigned to:&nbsp;
                <span className={'font-weight-bold'}>{assignedTo}</span>
              </div>
            )}
            {destination && (
              <div
                className={`text-placeholder font-weight-normal text-nowrap d-flex align-items-center mr-4`}
              >
                <HiOutlineLocationMarker
                  size={22}
                  className={'mr-2 color-text-disable'}
                />
                Destination:&nbsp;
                <span className={'font-weight-bold'}>{destination}</span>
              </div>
            )}
          </div>
          <div className={'col-lg-5 col-12 d-flex justify-content-end'}>
            {showCartNumber && (
              <div className={`d-flex w-auto align-items-center`}>
                <div className="d-flex flex-sm-nowrap flex-wrap">
                  <div className="text-placeholder d-flex align-items-center text-nowrap mr-4">
                    <FiShoppingCart
                      size={22}
                      className={'mr-2 color-text-disable'}
                    />
                    Cart number:&nbsp;
                  </div>
                  <div className="mt-3">
                    <CargoMovementForm.PalletNumber
                      className={'w-100'}
                      placeholder={'Cart Number'}
                      label={''}
                      name={'trackingNumber'}
                      disabled={true}
                      barcode={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
