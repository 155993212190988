import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CommodityTypeForm } from './commodityType.form';
import {
  CommodityTypeDto,
  CustomFieldDto,
  CustomFieldEntityType,
} from '../../../models/data.models';
import {
  createCommodityTypeFx,
  getCommodityTypeFx,
  updateCommodityTypeFx,
} from '../commodityTypes.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CommodityTypeDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { authStore, userHas } from '../../auth/auth.store';
import { UPDATE_COMMODITYTYPE_LINK_KEY } from '../commodityTypes.service';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';

export type CommodityTypeEditProps = {
  commodityTypeId?: number | null;
  onCommodityTypeCreated?: (commodityType: CommodityTypeDto) => void;
  onCommodityTypeUpdated?: (commodityType: CommodityTypeDto) => void;
  onCommodityTypeLoaded?: (commodityType: CommodityTypeDto) => void;
  onCancel?: () => void;
};

const initialState: CommodityTypeDto = {
  commodityTypeId: 0,
  code: CommodityTypeDefaultValues.code,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: CommodityTypeDefaultValues.description,
  isActive: CommodityTypeDefaultValues.isActive,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  links: [],
  customValues: {},
};

let commodityTypeSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code can't be blank")
    .max(50, 'Max length is 50')
    .nullable(true),
  description: Yup.string().nullable(true),
  isActive: Yup.boolean().nullable(true),
});

export const CommodityTypeEdit = ({
  commodityTypeId = null,
  onCommodityTypeLoaded = () => {},
  onCommodityTypeCreated = () => {},
  onCommodityTypeUpdated = () => {},
  onCancel = () => {},
}: CommodityTypeEditProps) => {
  const isCreateMode = !commodityTypeId || commodityTypeId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [commodityType, setCommodityType] = useState<CommodityTypeDto | null>(
    null,
  );
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const { user: currentUser } = authStore.getState();

  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.CommodityType} AND isInactive: false`,
    }).then((fields) => {
      const customFields: CustomFieldDto[] = fields.items;
      commodityTypeSchema = generateValidationSchemaWithCustomFields(
        customFields,
        commodityTypeSchema,
      );
      setCustomFields(customFields);
    });
    if (isCreateMode) {
      setIsLoading(false);
    } else if (commodityTypeId) {
      getCommodityTypeFx({ commodityTypeId }).then(
        (commodityTypeDto: CommodityTypeDto) => {
          setCommodityType(commodityTypeDto);
          setIsLoading(false);
          onCommodityTypeLoaded(commodityTypeDto);
        },
        () => {},
      );
    } else {
      throw new Error('CommodityType keys were not provided');
    }
  }, [commodityTypeId]);

  const onSubmit = (data: CommodityTypeDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createCommodityTypeFx(data)
        .then(
          (result) => {
            onCommodityTypeCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateCommodityTypeFx(data)
        .then(
          (result) => {
            onCommodityTypeUpdated(result.data);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <CommodityTypeForm
      id={'commodityType-form'}
      innerRef={ref}
      initialValues={commodityType || initialState}
      onSubmit={onSubmit}
      validationSchema={commodityTypeSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <span>
                <Tab name="general">General</Tab>
                <Tab name="additional">Additional</Tab>
              </span>
            </TabList>
            <TabPanel forceRender={true}>
              <Panel className="m-3">
                {isCreateMode ? (
                  <h2 className="header-form">Add Commodity Type</h2>
                ) : (
                  <h2>Update Commodity Type</h2>
                )}
                <div className="row">
                  <div className="col-4">
                    <CommodityTypeForm.Code disabled={!isCreateMode} />
                    <CommodityTypeForm.Description />
                    <CommodityTypeForm.IsActive
                      defaultValue={commodityType?.isActive ?? false}
                    />
                  </div>
                </div>
                <div className="justify-content-end row">
                  {(userHas(
                    UPDATE_COMMODITYTYPE_LINK_KEY,
                    commodityType?.links,
                  ) ||
                    isCreateMode) && (
                    <div className="col-3">
                      <Button
                        form={'commodityType-form'}
                        name="save-commodityType"
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Commodity Type
                      </Button>
                    </div>
                  )}
                  <div className="col-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel forceRender={isCreateMode ? false : true}>
              <CommodityTypeForm.CustomValues
                customFields={customFields}
                entityType={CustomFieldEntityType.CommodityType}
                defaultValue={{ ...commodityType?.customValues }}
                onChange={(result) => {
                  setCommodityType((type) => {
                    if (type) {
                      type.customValues = result;
                      return { ...type };
                    }
                    return type;
                  });
                }}
                saveButtonRenderCondition={
                  (userHas(
                    UPDATE_COMMODITYTYPE_LINK_KEY,
                    commodityType?.links,
                  ) ||
                    isCreateMode) &&
                  currentUser?.isInOrgAdminRole
                }
                isSending={isSending}
                formName={'commodityType-form'}
                entityName={'Commodity Type'}
                onCancel={onCancel}
                context={context}
              />
            </TabPanel>
          </Tabs>
        )}
      </FormContext.Consumer>
    </CommodityTypeForm>
  );
};
