import React, { CSSProperties } from 'react';
import {
  ChargeStatuses,
  CommodityStatusStage,
  CommodityStatuses,
  ContactStatusStage,
  OrderStatuses,
  PickingActivityStatuses,
  StatusStage,
} from '../../../../models/data.models';
import { getEnumKeyByValue } from '../../../../utils/helper.utils';

export interface StatusBadgeProps {
  label: string;
  stage?:
    | OrderStatuses
    | StatusStage
    | ContactStatusStage
    | ChargeStatuses
    | CommodityStatuses
    | PickingActivityStatuses
    | string
    | CommodityStatusStage;
  enumType: object;
  style?: CSSProperties;
}

export const StatusBadge = ({
  label,
  stage,
  enumType,
  style = {},
}: StatusBadgeProps) => {
  const getStatusBadgeClassByStage = (stage, enumType) => {
    const prefix = 'status-badge-';

    const value = getEnumKeyByValue(stage, enumType);
    if (stage && value && typeof value == 'string')
      return prefix + value?.toLowerCase().replace(' ', '-');
    return prefix + 'pending';
  };

  return (
    <div
      className={`status-badge ${getStatusBadgeClassByStage(stage, enumType)}`}
      style={style}
    >
      <div className={'status-badge-dot'} />
      {label}
    </div>
  );
};
