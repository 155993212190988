import {
  DivisionDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateDivisionCommandValues,
  UpdateDivisionCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createDivisionRequest,
  getDivisionsListRequest,
  getDivisionRequest,
  deleteDivisionRequest,
  updateDivisionRequest,
  importDivisionsRequest,
  exportDivisionsRequest,
  GetDivisionParams,
} from './divisions.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type DivisionsStoreState = {
  links: LinkDto[];
  divisionColumns: EntityFieldDto[];
  defaultDivisionColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getDivisionsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Division',
  });
});

addEffectStatusHandling(getDivisionsColumnsFx);

export const getDivisionsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Division',
  });
});

addEffectStatusHandling(getDivisionsDefaultColumnsFx);

export const updateDivisionsColumns = createEvent<EntityFieldDto[]>();

export const getDivisionsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getDivisionsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getDivisionsFx);

export const createDivisionFx = createEffect((divisionData: DivisionDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createDivisionCommand: CreateDivisionCommandValues = {
    ...divisionData,
  };

  return createDivisionRequest(currentOrganization!, createDivisionCommand);
});

addEffectStatusHandling(createDivisionFx, {
  completeMessage: 'Division was created successfully',
  errorMessage: 'Division was not created',
});

export const getDivisionFx = createEffect(
  (divisionParams: GetDivisionParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getDivisionRequest(
      currentOrganization as LinkResourceBaseDto,
      divisionParams,
    );
  },
);

addEffectStatusHandling(getDivisionFx);

export const updateDivisionFx = createEffect((division: DivisionDto) => {
  const updateDivisionCommand: UpdateDivisionCommandValues = { ...division };
  return updateDivisionRequest(division, updateDivisionCommand);
});

addEffectStatusHandling(updateDivisionFx, {
  completeMessage: 'Division was updated successfully',
  errorMessage: 'Division was not updated',
});

export const deleteDivisionFx = createEffect((division: DivisionDto) => {
  return deleteDivisionRequest(division);
});

addEffectStatusHandling(deleteDivisionFx, {
  completeMessage: 'Division was deleted successfully',
  errorMessage: 'Division was not deleted',
});

export const exportDivisionsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportDivisionsRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportDivisionsFx, {
  completeMessage: 'Divisions were exported successfully',
  errorMessage: 'Divisions were not exported',
});

export const importDivisionsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importDivisionsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importDivisionsFx, {
  completeMessage: 'Divisions were imported successfully',
  errorMessage: 'Divisions were not imported',
  inFlightMessage: 'Importing divisions...',
});

const defaultState: DivisionsStoreState = {
  links: [],
  divisionColumns: [],
  defaultDivisionColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const divisionStore: Store<DivisionsStoreState> = createStore(
  defaultState,
)
  .on(getDivisionsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      divisionColumns: payload.result.items,
      defaultDivisionColumns: payload.result.items,
    };
  })
  .on(getDivisionsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultDivisionColumns: payload.result.items,
    };
  })
  .on(updateDivisionsColumns, (state, payload) => {
    return { ...state, divisionColumns: payload };
  });
