import { Dialog } from '../../common/components/dialog/dialog.component';
import { WarehouseZoneEdit } from './warehouseZone-edit.component';
import { WarehouseZoneDto } from '../../../models/data.models';

export type WarehouseZoneDialogProperties = {
  warehouseZoneId?: number | null;
  title: string;
};

export class WarehouseZoneDialog extends Dialog<WarehouseZoneDialogProperties> {
  constructor(props: WarehouseZoneDialogProperties) {
    super(props);
    this.onWarehouseZoneSaved = this.onWarehouseZoneSaved.bind(this);
  }

  onWarehouseZoneSaved = (warehouseZone: WarehouseZoneDto) => {
    this.close(warehouseZone);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { warehouseZoneId } = this.props as WarehouseZoneDialogProperties;
    return (
      <WarehouseZoneEdit
        warehouseZoneId={warehouseZoneId}
        onWarehouseZoneCreated={this.onWarehouseZoneSaved}
        onWarehouseZoneUpdated={this.onWarehouseZoneSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
