import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { PaymentTermEdit } from '../components/paymentTerm-edit.component';
import {
  PAYMENTTERM_CREATE_PATH,
  PAYMENTTERMS_LIST_PATH,
} from '../paymentTerms.route';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_PAYMENTTERM_LINK_KEY } from '../paymentTerms.service';
import { userHas } from '../../auth/auth.store';

export type PaymentTermScreenProps = { pageTitle: string; group: string };

export const PaymentTermScreen = ({
  pageTitle,
  group,
}: PaymentTermScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, paymentTermId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + PAYMENTTERM_CREATE_PATH, {
      organizationId,
    });
  }
  const onPaymentTermCreated = () => {
    const path = generatePath(INTERNAL_PATH + PAYMENTTERMS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'payment-term-created',
      type: 'success',
      autoHide: true,
      message: 'Payment Term successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onPaymentTermUpdated = () => {
    const path = generatePath(INTERNAL_PATH + PAYMENTTERMS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'payment-term-updated',
      type: 'success',
      autoHide: true,
      message: 'Payment Term successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + PAYMENTTERMS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Payment Terms'}
      createPath={userHas(CREATE_PAYMENTTERM_LINK_KEY) ? createPath() : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PaymentTermEdit
        paymentTermId={paymentTermId}
        onPaymentTermUpdated={onPaymentTermUpdated}
        onPaymentTermCreated={onPaymentTermCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
