import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CONTACTS_ADDRESS_CREATE_PATH,
  CONTACTS_ADDRESS_LIST_PATH,
} from '../contactAddress.route';
import { ContactAddressEdit } from '../components/contactAddress-edit.component';
import { Helmet } from 'react-helmet';

export type ContactAddressScreenProps = { pageTitle: string };

export const ContactAddressScreen = ({
  pageTitle,
}: ContactAddressScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, contactAddressId } = useParams();

  const createPath = generatePath(
    INTERNAL_PATH + CONTACTS_ADDRESS_CREATE_PATH,
    { organizationId },
  );

  const onContactAddressCreated = () => {
    const path = generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_CREATE_PATH, {
      organizationId,
    });
    navigate(path);
  };

  const onContactAddressUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      title={'Contact Addresses'}
      createPath={createPath}
      createLabel={'Address'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ContactAddressEdit
        contactAddressId={contactAddressId}
        onContactAddressUpdated={onContactAddressUpdated}
        onContactAddressCreated={onContactAddressCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
