import { LinkDto, EntityFieldDto } from '../../models/data.models';
import { createStore, Store, createEvent, createEffect } from 'effector';
import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { organizationsStore } from '../organization/organization.store';

type WarehouseReceiptsStoreState = {
  links: LinkDto[];
  warehouseReceiptColumns: EntityFieldDto[];
  defaultColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getWarehouseReceiptsColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'WarehouseReceipt',
  });
});

export const getWarehouseReceiptsDefaultColumns = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'WarehouseReceipt',
  });
});

export const updateWarehouseReceiptsColumns = createEvent<EntityFieldDto[]>();

const defaultState: WarehouseReceiptsStoreState = {
  links: [],
  warehouseReceiptColumns: [],
  defaultColumns: [],
  defaultSort: '-orderNumber~ToInt32',
  defaultLimit: 20,
};

export const warehouseReceiptsStore: Store<WarehouseReceiptsStoreState> = createStore(
  defaultState,
)
  .on(getWarehouseReceiptsColumns.done, (state, payload) => {
    return {
      ...state,
      warehouseReceiptColumns: payload.result.items,
      defaultColumns: payload.result.items,
    };
  })
  .on(getWarehouseReceiptsDefaultColumns.done, (state, payload) => {
    return {
      ...state,
      defaultColumns: payload.result.items,
    };
  })
  .on(updateWarehouseReceiptsColumns, (state, payload) => {
    return { ...state, warehouseReceiptColumns: payload };
  });
