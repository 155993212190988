import {
  OrganizationConfigDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  OrganizationConfigDtoPagedResult,
  CreateOrganizationConfigCommandValues,
  UpdateOrganizationConfigCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_ORGANIZATIONCONFIGS_LINK_KEY = 'get-organizationconfigs';
export const CREATE_ORGANIZATIONCONFIG_LINK_KEY = 'create-organizationconfig';
export const GET_ORGANIZATIONCONFIG_LINK_KEY = 'get-organizationconfig';
export const UPDATE_ORGANIZATIONCONFIG_LINK_KEY = 'update-organizationconfig';
export const DELETE_ORGANIZATIONCONFIG_LINK_KEY = 'delete-organizationconfig';
export const IMPORT_ORGANIZATIONCONFIGS_LINK_KEY = 'import-organizationconfigs';
export const EXPORT_ORGANIZATIONCONFIGS_LINK_KEY = 'export-organizationconfigs';
export const ORGANIZATIONCONFIG_ENTITY_NAME = 'Organization Config';

export interface GetOrganizationConfigParams {
  organizationConfigId: number;
}

export interface ImportOrganizationConfigsParams {
  organizationId: number;
}

export interface ExportOrganizationConfigsParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getOrganizationConfigRequest = async (
  resource: LinkResourceBaseDto | null,
  { organizationConfigId }: GetOrganizationConfigParams,
): Promise<OrganizationConfigDto> => {
  if (resource && organizationConfigId) {
    const getOrganizationConfigLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORGANIZATIONCONFIG_LINK_KEY,
    );
    if (getOrganizationConfigLink) {
      const result = await execLink(getOrganizationConfigLink, {
        organizationConfigId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getOrganizationConfigsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrganizationConfigDtoPagedResult> => {
  if (resource) {
    const getOrganizationConfigsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORGANIZATIONCONFIGS_LINK_KEY,
    );
    if (getOrganizationConfigsListLink) {
      const result = await execLink(getOrganizationConfigsListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ORGANIZATIONCONFIGS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createOrganizationConfigRequest = async (
  resource: LinkResourceBaseDto,
  organizationConfig: CreateOrganizationConfigCommandValues,
) => {
  const createOrganizationConfigLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORGANIZATIONCONFIG_LINK_KEY,
  );
  if (createOrganizationConfigLink) {
    const result = await execLink(
      createOrganizationConfigLink,
      organizationConfig,
    );
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateOrganizationConfigRequest = async (
  resource: LinkResourceBaseDto,
  organizationConfig: UpdateOrganizationConfigCommandValues,
) => {
  const updateOrganizationConfigLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORGANIZATIONCONFIG_LINK_KEY,
  );
  if (updateOrganizationConfigLink) {
    const result = await execLink(
      updateOrganizationConfigLink,
      organizationConfig,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteOrganizationConfigRequest = async (
  organizationConfig: OrganizationConfigDto,
) => {
  const deleteOrganizationConfigLink = organizationConfig.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORGANIZATIONCONFIG_LINK_KEY,
  );
  if (deleteOrganizationConfigLink) {
    const result = await execLink(
      deleteOrganizationConfigLink,
      organizationConfig,
    );
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importOrganizationConfigsRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportOrganizationConfigsParams,
  file: File,
) => {
  const importOrganizationConfigsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_ORGANIZATIONCONFIGS_LINK_KEY,
  );
  if (importOrganizationConfigsLink) {
    const result = await postFormData(importOrganizationConfigsLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_ORGANIZATIONCONFIGS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportOrganizationConfigsRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportOrganizationConfigsParams,
) => {
  const exportOrganizationConfigsLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_ORGANIZATIONCONFIGS_LINK_KEY,
  );
  if (exportOrganizationConfigsLink) {
    const result = await downloadLinkFile(
      exportOrganizationConfigsLink,
      params,
    );
    return result;
  }
  throw new Error(
    `Action ${EXPORT_ORGANIZATIONCONFIGS_LINK_KEY} is not allowed or missing link action`,
  );
};
