import { Dialog } from '../../common/components/dialog/dialog.component';
import { WorkflowTriggerExecute } from './workflowTrigger-execute.component';
import { WorkflowTriggerDto } from '../../../models/data.models';

export type WorkflowTriggerDialogProperties = {
  workflowId?: string | null;
  workflowTrigger?: WorkflowTriggerDto | null;
  title: string;
  defaultVariables?: any;
  setResult?: any;
  autoClose?: boolean;
};

export class WorkflowTriggerDialog extends Dialog<WorkflowTriggerDialogProperties> {
  constructor(props: WorkflowTriggerDialogProperties) {
    super(props);
    this.onWorkflowTriggerSaved = this.onWorkflowTriggerSaved.bind(this);
  }

  onWorkflowTriggerSaved = (workflowTrigger: WorkflowTriggerDto) => {
    this.close(workflowTrigger);
  };

  onWorkflowTriggerDeleted = (workflowTrigger: WorkflowTriggerDto) => {
    this.close(workflowTrigger);
  };

  onCancel = () => {
    this.close();
  };

  renderContent(): any {
    const { workflowId, defaultVariables, setResult, autoClose } = this
      .props as WorkflowTriggerDialogProperties;
    return (
      <WorkflowTriggerExecute
        workflowId={workflowId}
        defaultVariables={defaultVariables}
        setResult={setResult}
        autoClose={autoClose}
        onWorkflowTriggerCreated={this.onWorkflowTriggerSaved}
        onWorkflowTriggerUpdated={this.onWorkflowTriggerSaved}
        onDelete={this.onWorkflowTriggerDeleted}
        onCancel={this.onCancel}
      />
    );
  }
}
