import { Dialog } from '../../common/components/dialog/dialog.component';
import { CountryDto } from '../../../models/data.models';
import { ForwardingAgentEdit } from './forwardingAgent-edit.component';

export type ForwardingAgentDialogProperties = {
  contactId: number;
  title: string;
  disableDots?: boolean;
};

export class ForwardingAgentDialog extends Dialog<ForwardingAgentDialogProperties> {
  constructor(props: ForwardingAgentDialogProperties) {
    super(props);
    this.onForwardingAgentSaved = this.onForwardingAgentSaved.bind(this);
  }

  onForwardingAgentSaved = (country: CountryDto) => {
    this.close(country);
  };

  renderContent(): any {
    const { contactId, disableDots } = this
      .props as ForwardingAgentDialogProperties;
    return (
      <ForwardingAgentEdit
        contactId={contactId}
        onForwardingAgentCreated={this.onForwardingAgentSaved}
        onForwardingAgentUpdated={this.onForwardingAgentSaved}
        onCancel={this.close.bind(this)}
        disableDots={disableDots}
      />
    );
  }
}
