import { createEvent, createStore, Store } from 'effector';
import { CountryDto } from '../../../models/data.models';

type CountryReactSelectStore = {
  clearLoadOptionsCache: boolean;
  lastEditedCountry: CountryDto;
};

export const clearCountriesLoadOptionsCache = createEvent();
export const updateLastEditedCountry = createEvent<CountryDto>();

const defaultState: CountryReactSelectStore = {
  clearLoadOptionsCache: false,
  lastEditedCountry: null,
};

export const countryReactSelectStore: Store<CountryReactSelectStore> = createStore(
  defaultState,
)
  .on(clearCountriesLoadOptionsCache, (state, payload) => {
    return { ...state, clearLoadOptionsCache: !state.clearLoadOptionsCache };
  })
  .on(updateLastEditedCountry, (state, payload) => {
    return { ...state, lastEditedCountry: payload };
  });
