import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { ContactAddressForm } from './contactAddress.form';
import {
  AddressType,
  ContactAddressDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
} from '../../../models/data.models';
import {
  createContactAddressFx,
  getContactAddressFx,
  updateContactAddressFx,
} from '../contactAddresses.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ContactAddressDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import {
  generateValidationSchemaWithCustomFields,
  parseGeocodeResults,
} from '../../../utils/helper.utils';
import { FormContext } from '../../common/components/form/form.component';
import { FormikProps, FormikValues } from 'formik';

export type ContactAddressEditProps = {
  contactAddressId?: number;
  contactId?: number;
  addressType?: AddressType;
  contactType?: ContactType;
  UseParameterAddressTypeInSubmitForm?: boolean;
  UseCurrentContact?: boolean;
  validateOnChange?: boolean;
  onContactAddressCreated?: (contactAddress: ContactAddressDto) => void;
  onContactAddressUpdated?: (contactAddress: ContactAddressDto) => void;
  onContactAddressLoaded?: (contactAddress: ContactAddressDto) => void;
  onCancel?: () => void;
};

const initialState: ContactAddressDto = {
  addressType: ContactAddressDefaultValues.addressType,
  contactId: ContactAddressDefaultValues.contactId,
  stateName: ContactAddressDefaultValues.stateName,
  stateCode: ContactAddressDefaultValues.stateCode,
  links: null,
  countryName: ContactAddressDefaultValues.countryName,
  countryCode: ContactAddressDefaultValues.countryCode,
  createdBy: null,
  lastModified: null,
  created: null,
  lastModifiedBy: null,
  addressLine: ContactAddressDefaultValues.addressLine,
  organizationId: null,
  contactAddressId: null,
  addressLine2: ContactAddressDefaultValues.addressLine2,
  cityName: ContactAddressDefaultValues.city,
  postalCode: ContactAddressDefaultValues.postalCode,
  latitude: ContactAddressDefaultValues.latitude,
  longitude: ContactAddressDefaultValues.longitude,
  customValues: {},
};

let contactAddressSchema = Yup.object().shape({
  addressLine: Yup.string().required("Can't be blank").nullable(true),
  countryCode: Yup.string().required("Can't be blank").nullable(true),
  stateCode: Yup.string().when('countryCode', {
    is: (countryCode) => countryCode == 'US' || countryCode == 'CA',
    then: Yup.string().required("Can't be blank").nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
});

export const ContactAddressEdit = ({
  contactAddressId,
  contactId,
  addressType,
  contactType,
  UseCurrentContact,
  onContactAddressLoaded = () => {},
  onContactAddressCreated = () => {},
  onContactAddressUpdated = () => {},
  onCancel = () => {},
}: ContactAddressEditProps) => {
  const isCreateMode = !contactAddressId || contactAddressId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [
    contactAddress,
    setContactAddress,
  ] = useState<ContactAddressDto | null>(null);
  const [initialValues, setInitialValues] = useState<ContactAddressDto | null>(
    initialState,
  );
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  useEffect(() => {
    if (contactType) {
      getCustomFieldsFx({
        filter: `customFieldEntityType: ${
          CustomFieldEntityType[`${contactType}Address`]
        } AND isInactive: false`,
      }).then(
        (fields) => {
          const customFields: CustomFieldDto[] = fields.items;
          contactAddressSchema = generateValidationSchemaWithCustomFields(
            customFields,
            contactAddressSchema,
          );
          setCustomFields(customFields);
        },
        () => {},
      );
    }
    if (isCreateMode) {
      setContactAddress((contactAddress) => {
        if (!contactAddress) {
          contactAddress = initialState;
        }
        contactAddress.contactId = contactId;
        contactAddress.addressType = addressType;
        return { ...contactAddress };
      });
      setIsLoading(false);
    } else if (contactAddressId) {
      getContactAddressFx({ contactAddressId }).then(
        (contactAddressDto: ContactAddressDto) => {
          setContactAddress(contactAddressDto);
          setInitialValues(contactAddressDto);
          setIsLoading(false);
          onContactAddressLoaded(contactAddressDto);
        },
        () => {},
      );
    } else {
      throw new Error('ContactAddress keys were not provided');
    }
  }, [contactAddressId]);

  const onSubmit = (data: ContactAddressDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createContactAddressFx(data)
        .then(
          (result) => {
            onContactAddressCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateContactAddressFx(data)
        .then(
          (result) => {
            onContactAddressUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const onSelectPlaceAutocompleteContactAddress = (
    results: any,
    context: FormikProps<FormikValues>,
  ) => {
    const addressInfo = parseGeocodeResults(results);
    context.setFieldValue('addressLine', addressInfo.addressLine);
    context.setFieldValue('cityName', addressInfo.city);
    context.setFieldValue('postalCode', addressInfo.postalCode);
    context.setFieldValue('countryCode', addressInfo.countryCode);
    context.setFieldValue('stateCode', addressInfo.stateCode);
    context.setFieldValue('latitude', addressInfo.latitude);
    context.setFieldValue('longitude', addressInfo.longitude);
    setContactAddress((oldContactAddress) => {
      if (!oldContactAddress) {
        oldContactAddress = initialValues;
      }
      oldContactAddress.addressLine = addressInfo.addressLine;
      oldContactAddress.cityName = addressInfo.city;
      oldContactAddress.postalCode = addressInfo.postalCode;
      oldContactAddress.countryCode = addressInfo.countryCode;
      oldContactAddress.countryName = addressInfo.countryName;
      oldContactAddress.stateCode = addressInfo.stateCode;
      oldContactAddress.stateName = addressInfo.stateName;
      oldContactAddress.latitude = addressInfo.latitude;
      oldContactAddress.longitude = addressInfo.longitude;
      return { ...oldContactAddress };
    });
    return addressInfo.addressLine;
  };

  return (
    <ContactAddressForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      id={'ContactAddressForm'}
      validationSchema={contactAddressSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <span>
                <Tab name="general">General</Tab>
                <Tab name="additional">Additional</Tab>
              </span>
            </TabList>

            <TabPanel forceRender={true}>
              <Panel className="m-3">
                <div className="row">
                  <div className="col-4">
                    <ContactAddressForm.AddressLine
                      defaultValue={contactAddress?.addressLine}
                      onSelect={onSelectPlaceAutocompleteContactAddress}
                    />
                    <ContactAddressForm.AddressLine2 />
                    <ContactAddressForm.City />
                    <ContactAddressForm.CountryCode
                      id={'countryCode'}
                      header={'Country'}
                      required={true}
                      defaultValue={
                        contactAddress &&
                        contactAddress.countryCode &&
                        contactAddress.countryName
                          ? {
                              countryCode: contactAddress.countryCode,
                              name: contactAddress.countryName,
                            }
                          : ''
                      }
                      onChange={(country) => {
                        setContactAddress((contactAddress) => {
                          if (!contactAddress) {
                            contactAddress = initialState;
                          }
                          contactAddress.countryCode = country?.countryCode;
                          contactAddress.countryName = country?.name;
                          contactAddress.stateCode = null;
                          contactAddress.stateName = null;
                          return { ...contactAddress };
                        });
                      }}
                      nameId={`countryName`}
                    />
                    <ContactAddressForm.PostalCode />
                    <ContactAddressForm.StateCode
                      id={'stateCode'}
                      header={'State'}
                      required={true}
                      defaultValue={
                        contactAddress &&
                        contactAddress.stateCode &&
                        contactAddress.stateName
                          ? {
                              stateCode: contactAddress.stateCode,
                              name: contactAddress.stateName,
                              countryCode: contactAddress.countryCode,
                            }
                          : ''
                      }
                      onChange={(state) => {
                        setContactAddress((contactAddress) => {
                          if (!contactAddress) {
                            contactAddress = initialState;
                          }
                          contactAddress.stateCode = state?.stateCode;
                          contactAddress.stateName = state?.name;
                          return { ...contactAddress };
                        });
                      }}
                      nameId={`stateName`}
                      selectedFilter={`countryCode: ${contactAddress.countryCode}`}
                      disabled={!contactAddress.countryCode}
                    />
                    <ContactAddressForm.Latitude />
                    <ContactAddressForm.Longitude />
                    {UseCurrentContact ? (
                      <div></div>
                    ) : (
                      <div className="w-100 d-flex align-items-center justify-content-between">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block mr-3"
                          disabled={isSending}
                          isSending={isSending}
                        >
                          {`Save ${contactType} Address`}
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCancel}
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel forceRender={isCreateMode ? false : true}>
              <ContactAddressForm.ContactAddressCustomValues
                customFields={customFields}
                entityType={CustomFieldEntityType.ContactAddress}
                defaultValue={{ ...contactAddress?.customValues }}
                inputNamePrefix={''}
                onChange={(customValues) => {
                  setContactAddress((contactAddress) => {
                    if (!contactAddress) {
                      contactAddress = {
                        ...initialState,
                      };
                    }
                    contactAddress.customValues = customValues;
                    return { ...contactAddress };
                  });
                }}
                saveButtonRenderCondition={!UseCurrentContact}
                isSending={isSending}
                formName={'ContactAddressForm'}
                entityName={`${contactType} Address`}
                onCancel={onCancel}
                context={context}
              />
            </TabPanel>
          </Tabs>
        )}
      </FormContext.Consumer>
    </ContactAddressForm>
  );
};
