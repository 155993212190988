import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import {
  AccountingTransactionStatus,
  AccountingTransactionType,
} from '../../../models/data.models';
import { SelectContactAddressesPaginate } from '../../contactAddresses/components/contactAddresses-react-select.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectAccountingAccountPaginate } from '../../accountingAccounts/components/accountingAccount-react-select.component';
import { SelectPaymentTermPaginate } from '../../paymentTerms/components/paymentTerm-react-select.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';
import { ContactAddressSelectInputProps } from '../../../models/custom.models';

export const AccountingTransactionForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

AccountingTransactionForm.AccountSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  id = 'accountId',
  nameId = 'accountName',
  header = 'Account',
  required = true,
  placeholder,
  readonly,
  disabled,
  sort = 'accountName',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectAccountingAccountPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      showAccountType={true}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeAccountingAccount={onChange}
    />
  );
};
AccountingTransactionForm.AccountingTransactionStatus = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'accountingTransactionStatus'}
      label={'Accounting Transaction Status'}
      placeholder={'Accounting Transaction Status'}
      required={true}
      type={'enum-select'}
      enumName={AccountingTransactionStatus}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingTransactionForm.AccountingTransactionType = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'accountingTransactionType'}
      label={'Accounting Transaction Type'}
      placeholder={'Accounting Transaction Type'}
      required={true}
      type={'enum-select'}
      enumName={AccountingTransactionType}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingTransactionForm.ApplyToContactSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  readonly,
  id = 'applyToContactID',
  nameId = 'applyToContactName',
  header = 'Apply To Contact ID',
  placeholder = 'Select Apply To Contact',
  required = true,
  sort = 'name',
  defaultValue,
  disabled,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      showContactType={true}
    />
  );
};
AccountingTransactionForm.BillToContactAddressSelect = ({
  className,
  style,
  size,
  selectedName,
  selectedFilter,
  id = 'billToContactAddressId',
  nameId = 'billToContactAddressName',
  header = 'Bill To Contact Address',
  required = false,
  selectedFieldName,
  readonly,
  disabled,
  sort = 'addressLine',
  placeholder = 'Select Bill To Contact Address',
  defaultValue,
  onChange = () => {},
  contactId,
  contactType,
  addressType,
}: ContactAddressSelectInputProps) => {
  return (
    <SelectContactAddressesPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly' ? true : false}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContactAddress={onChange}
      contactId={contactId}
      contactType={contactType}
      contactAddressType={addressType}
    />
  );
};
AccountingTransactionForm.TransactionDate = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={'transactionDate'}
      label={'Transaction Date'}
      placeholder={'Transaction Date'}
      required={true}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
AccountingTransactionForm.DueDate = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={'dueDate'}
      label={'Due Date'}
      placeholder={'Due Date'}
      required={true}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
AccountingTransactionForm.Note = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'note'}
      label={'Note'}
      placeholder={'Note'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingTransactionForm.PaymentTermsSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  id = 'paymentTermsId',
  nameId = 'paymentTermsName',
  header = 'Payment Terms Id',
  required = false,
  readonly,
  disabled,
  sort = 'description',
  placeholder = 'Select Payment Term',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectPaymentTermPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePaymentTerm={onChange}
    />
  );
};
AccountingTransactionForm.TransactionNumber = ({
  className,
  style,
  size,
  disabled,
  readonly,
  selectedName,
}: InputPublicProps) => {
  return (
    <Input
      name={'transactionNumber'}
      label={'Transaction Number'}
      placeholder={'Transaction Number'}
      required={false}
      type={'text'}
      valueInput={selectedName}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      readonly={readonly}
    />
  );
};

AccountingTransactionForm.PaidAs = ({
  className,
  style,
  size,
  disabled,
  required = true,
  id = 'paidAs',
  name = 'paidAs',
  header = 'Paid As',
  placeholder = 'Select Paid As',
  options,
  defaultValue,
  multiple = false,
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <Input
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

AccountingTransactionForm.Division = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isClearable={false}
      isDisabled={disabled}
    />
  );
};

AccountingTransactionForm.PaidDate = ({
  className,
  style,
  size,
  onChange,
  defaultValue,
  disabled = false,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={'paidDate'}
      label={'Paid Date'}
      placeholder={'Paid Date'}
      required={false}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
