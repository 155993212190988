import { Dialog } from '../../common/components/dialog/dialog.component';
import { CustomCodeEdit } from './customCode-edit.component';
import { CustomCodeDto } from '../../../models/data.models';

export type CustomCodeDialogProperties = {
  code: string;
  title: string;
};

export class CustomCodeDialog extends Dialog<CustomCodeDialogProperties> {
  constructor(props: CustomCodeDialogProperties) {
    super(props);
    this.onCodeSaved = this.onCodeSaved.bind(this);
  }

  onCodeSaved = (customCode: CustomCodeDto) => {
    this.close(customCode);
  };

  renderContent(): any {
    const { code } = this.props as CustomCodeDialogProperties;
    return (
      <CustomCodeEdit
        code={code}
        onCustomCodeCreated={this.onCodeSaved}
        onCustomCodeUpdated={this.onCodeSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
