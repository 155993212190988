import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { PurchaseEdit } from './purchase-edit.component';

export type PurchaseDialogProperties = {
  orderId?: number | null;
  title: string;
  className?: string;
  onPurchaseSaved?: (purchase: OrderDto) => void;
  onInvoiceGenerated?: () => void;
};

export class PurchaseDialog extends Dialog<PurchaseDialogProperties> {
  onInvoiceGenerated?: () => void;

  constructor(props: PurchaseDialogProperties) {
    super(props);
    this.onInvoiceGenerated = props.onInvoiceGenerated?.bind(this);
    this.onPurchaseSaved = this.onPurchaseSaved.bind(this);
  }

  onPurchaseSaved = (purchase: OrderDto) => {
    this.close(purchase);
  };

  renderContent(): any {
    const { orderId } = this.props as PurchaseDialogProperties;
    const onInvoiceGenerated = this.onInvoiceGenerated;
    return (
      <PurchaseEdit
        orderId={orderId}
        onPurchaseCreated={this.onPurchaseSaved}
        onPurchaseUpdated={this.onPurchaseSaved}
        onCancel={this.close.bind(this)}
        onInvoiceGenerated={onInvoiceGenerated && this.onInvoiceGenerated}
      />
    );
  }
}
