import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PURCHASE_CREATE_PATH, PURCHASE_LIST_PATH } from '../purchase.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../../orders/orders.service';
import { userHas } from '../../auth/auth.store';
import { PurchaseEdit } from '../components/purchase-edit.component';

export type PurchaseScreenProps = { pageTitle: string; group: string };

export const PurchaseScreen = ({ pageTitle, group }: PurchaseScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, purchaseId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + PURCHASE_CREATE_PATH, {
    organizationId,
  });

  const onPurchaseCreated = () => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'purchase-created',
      type: 'success',
      autoHide: true,
      message: 'Purchase successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onPurchaseUpdated = () => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'purchase-updated',
      type: 'success',
      autoHide: true,
      message: 'Purchase successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + PURCHASE_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Purchases'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PurchaseEdit
        orderId={purchaseId}
        onPurchaseUpdated={onPurchaseUpdated}
        onPurchaseCreated={onPurchaseCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
