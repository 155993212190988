import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  ContactDto,
  ContactDtoPagedResult,
  ContactType,
  CustomFieldEntityType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  contactStore,
  getContactsColumns,
  getContacts,
  deleteContact,
  exportContacts,
  importContacts,
} from '../contacts.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_CONTACT_LINK_KEY,
  DELETE_CONTACT_LINK_KEY,
  IMPORT_CONTACTS_LINK_KEY,
  EXPORT_CONTACTS_LINK_KEY,
  GetContactParams,
} from '../contacts.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';
import { hidenFilter } from '../../../utils/query.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ContactDialog } from './contact.dialog';

export type ContactsListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  disableDots?: boolean;
  parentId?: number;
  showPagination?: boolean;
  showGridPanel?: boolean;
  isTab?: boolean;
  addButtonRenderCondition?: boolean;
  isDropDownList?: boolean;
  rowKeys?: string[];
  contactType?: ContactType;
  customFieldEntityType?: CustomFieldEntityType;
  selectDialogType?: any;
  goToDetails?: (contactParams: GetContactParams | any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (contact: ContactDto, contactParams: GetContactParams) => void;
};

export const ContactsList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  disableDots = false,
  parentId,
  showPagination = true,
  showGridPanel = true,
  isTab,
  addButtonRenderCondition,
  isDropDownList = false,
  rowKeys = ['contactId'],
  contactType,
  customFieldEntityType,
  selectDialogType = ContactDialog,
  goToDetails = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
}: ContactsListProps) => {
  const [contacts, setContacts] = useState<ContactDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  const {
    contactColumns: columns,
    defaultContactColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(contactStore);

  useEffect(() => {
    getContactsColumns(contactType).then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const getActions = (result: ContactDtoPagedResult): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        IMPORT_CONTACTS_LINK_KEY,
        'Import',
        <FiUpload />,
        async () => {
          await importContacts({
            file: await getFileFromUser(),
            listResource: result,
          });
          await getContacts();
        },
        result?.links,
      ),
      getAction(
        EXPORT_CONTACTS_LINK_KEY,
        'Export',
        <FiDownload />,
        () => exportContacts({ listResource: result }),
        result?.links,
      ),
    ];
    return activeActions.filter(Boolean) as IAction[];
  };

  const getContactsData = async () => {
    try {
      if (parentId) {
        filter += ` AND contactLinks.LinkToContactId:${parentId}`;
      }
      const additionalFilter = hidenFilter(
        `contactType:${contactType}`,
        filter,
      );
      const contactsData = await getContacts({
        offset,
        limit,
        sort,
        filter: additionalFilter,
        search,
      });

      if (contactsData) {
        setActions(getActions(contactsData));
        setContacts(contactsData);
        setGetDataSuccess(true);
      }
    } catch (error) {
      // Handle error here
    }
  };

  !parentId
    ? useEffect(() => {
        getContactsData();
      }, [offset, limit, sort, filter, search])
    : useEffect(() => {
        getContactsData();
      }, [contacts?.items?.length]);

  const onDeleteContact = async (contact: ContactDto) => {
    if (userHas(DELETE_CONTACT_LINK_KEY, contacts?.items[0]?.links)) {
      const result = await showDialog({
        dialog: Confirm,
        props: {
          title: `Delete ${entityName}`,
          message: 'Are you sure you want to delete?',
          className: 'delete-modal',
        },
      });
      if (result) {
        try {
          await deleteContact(contact);
          getContacts();
          onDelete();
        } catch (error) {
          // Handle error here
        }
      }
    }
  };

  const onEditContact = (contact: ContactDto) => {
    goToDetails(contact.contactId);
  };

  const onCreateNewContact = () => {
    goToDetails('create');
  };

  const onContactSelect = async (contact) => {
    const selectedContact = await showDialog({
      dialog: selectDialogType,
      props: {
        contactId: contact.contactId,
        title: `Update ${entityName}`,
      },
    });
    if (selectedContact !== null) {
      getContactsData();
    }
  };

  const entityName = contactType.replace(/([a-z])([A-Z])/g, '$1 $2');

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={rowKeys}
          isDropDownList={isDropDownList}
          data={contacts?.items}
          columns={columns}
          actions={actions}
          offset={offset}
          limit={limit}
          onLimitChanged={onLimitChanged}
          total={contacts?.totalCount}
          sort={sort}
          disableDots={disableDots}
          showPagination={showPagination}
          parentId={parentId}
          isTab={isTab}
          addButtonRenderCondition={addButtonRenderCondition}
          onDelete={onDeleteContact}
          onSort={onSort}
          onEdit={onEditContact}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onSelect ?? onContactSelect}
          onSearch={onSearch}
          showAllStore={true}
          search={search}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          filter={filter}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={showGridPanel}
          getContactsData={getContactsData}
        />
        {(!contacts || contacts?.totalCount === 0) && (
          <ListElementsNotFound
            entityName={`${entityName}`}
            entityLinkKey={CREATE_CONTACT_LINK_KEY}
            onClick={onCreateNewContact}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
