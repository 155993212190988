import React, { useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { JobEdit } from '../components/job-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { JOBS_LIST_PATH, JOB_CREATE_PATH } from '../jobs.route';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_JOB_LINK_KEY } from '../jobs.service';
import { userHas } from '../../auth/auth.store';

export type JobScreenProps = { pageTitle: string; group: string };
export type JobRouteParams = { organizationId: string; jobId: string | string };

export const JobScreen = ({ pageTitle, group }: JobScreenProps) => {
  const navigate = useNavigate();

  const { organizationId, jobId }: JobRouteParams = useParams();

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + JOB_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const closeScreen = useMemo(
    () => () => {
      // check if we can go back
      if (navigate.length > 2) {
        navigate(navigate.length - 1);
      } else {
        const { currentOrganization } = organizationsStore.getState();
        const path = generatePath(INTERNAL_PATH + JOBS_LIST_PATH, {
          organizationId: currentOrganization.organizationId,
        });
        navigate(path);
      }
    },
    [navigate],
  );

  const jobIdMemo = useMemo(() => (jobId !== 'create' ? jobId : null), [jobId]);

  return (
    <InternalLayout
      group={group}
      title={'Job'}
      createPath={userHas(CREATE_JOB_LINK_KEY) ? createPath : null}
      createLabel={'Job'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <JobEdit
        jobId={jobIdMemo}
        onCancel={closeScreen}
        onJobCreated={closeScreen}
        onJobUpdated={closeScreen}
        onDelete={closeScreen}
      />
    </InternalLayout>
  );
};
