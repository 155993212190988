import { Dialog } from '../../common/components/dialog/dialog.component';
import { DiscountEdit } from './discount-edit.component';
import { DiscountDto } from '../../../models/data.models';

export type DiscountDialogProperties = {
  discountId?: number | null;
  title: string;
};

export class DiscountDialog extends Dialog<DiscountDialogProperties> {
  constructor(props: DiscountDialogProperties) {
    super(props);
    this.onDiscountSaved = this.onDiscountSaved.bind(this);
  }

  onDiscountSaved = (discount: DiscountDto) => {
    this.close(discount);
  };

  renderContent(): any {
    const { discountId } = this.props as DiscountDialogProperties;
    return (
      <DiscountEdit
        discountId={discountId}
        onDiscountCreated={this.onDiscountSaved}
        onDiscountUpdated={this.onDiscountSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
