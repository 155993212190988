import React, { CSSProperties } from 'react';

export type PanelProps = {
  className?: string;
  style?: CSSProperties;
  children?: any;
  title?: string;
};

export const Panel = ({
  children = null,
  style = {},
  className = '',
  title = null,
}: PanelProps) => {
  return (
    <div className={`card ${className}`} style={style}>
      {title && (
        <div className="card-header">
          <h3 className="card-title">{title}</h3>
        </div>
      )}
      <div className="card-body login-register-card-body">{children}</div>
    </div>
  );
};
