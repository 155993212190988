import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { FactoringCompanyScreen } from './screens/factoringCompany.screen';
import { FactoringCompanyListScreen } from './screens/factoringCompany-list.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_FACTORING_COMPANIES } from '../contacts/contact.route';

export const FACTORING_COMPANIES_LIST_PATH = '/factoring-companies';
export const FACTORING_COMPANIES_EDIT_PATH = '/factoring-companies/:contactId';
export const FACTORING_COMPANIES_CREATE_PATH = '/factoring-companies/0';

export const FactoringCompanysRoute = (props: RouteProps) => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <FactoringCompanyListScreen
              pageTitle="Factoring Companies"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <FactoringCompanyScreen
              pageTitle="Add New Factoring Company"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <FactoringCompanyScreen
              pageTitle="Update Factoring Company"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':factoringCompanyId/contacts/:contactId'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Contacts" isTab={true} group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
