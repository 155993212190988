import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';

import { LoginScreen } from './screens/login.screen';
import { SignUpScreen } from './screens/signup.screen';
import { ForgotPasswordScreen } from './screens/forgot-password.screen';
import { ResetPasswordScreen } from './screens/reset-password.screen';
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password/:userId/:code';

export const LoginRoute = (props: RouteProps) => {
  return (
    <Routes>
      <Route
        {...props}
        path="/"
        element={<LoginScreen pageTitle="Login - TrtExpress Express" />}
      />
    </Routes>
  );
};

export const RegisterRoute = (props: RouteProps) => {
  return (
    <Routes>
      <Route
        {...props}
        path="/"
        element={<SignUpScreen pageTitle="Sign Up - TrtExpress Express" />}
      />
    </Routes>
  );
};

export const ForgotPasswordRoute = (props: RouteProps) => {
  return (
    <Routes>
      <Route
        {...props}
        path="/"
        element={
          <ForgotPasswordScreen pageTitle="Forgot Password - TrtExpress Express" />
        }
      />
    </Routes>
  );
};

export const ResetPasswordRoute = (props: RouteProps) => {
  return (
    <Routes>
      <Route
        {...props}
        path="/"
        element={
          <ResetPasswordScreen pageTitle="Reset Password - TrtExpress Express" />
        }
      />
    </Routes>
  );
};
