import { Dialog } from '../../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../../models/data.models';
import { CreateByPurchaseWizard } from './createByPurchaseWizard.component';

export type CreateByPurchaseWizardDialogProperties = {
  title: string;
  className?: string;
  onReceiptCreated?: (airOrder: OrderDto) => void;
};

export class CreateByPurchaseWizardDialog extends Dialog<CreateByPurchaseWizardDialogProperties> {
  constructor(props: CreateByPurchaseWizardDialogProperties) {
    super(props);
    this.onReceiptSaved = this.onReceiptSaved.bind(this);
  }

  onReceiptSaved = (warehouseReceipt: OrderDto) => {
    this.close(warehouseReceipt);
  };

  renderContent(): any {
    return (
      <CreateByPurchaseWizard onWarehouseReceiptCreated={this.onReceiptSaved} />
    );
  }
}
