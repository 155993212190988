import {
  OrderStatusDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  OrderStatusDtoPagedResult,
  CreateOrderStatusCommandValues,
  UpdateOrderStatusCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_ORDERSTATUSES_LINK_KEY = 'get-orderstatuses';
export const CREATE_ORDERSTATUS_LINK_KEY = 'create-orderstatus';
export const GET_ORDERSTATUS_LINK_KEY = 'get-orderstatus';
export const UPDATE_ORDERSTATUS_LINK_KEY = 'update-orderstatus';
export const DELETE_ORDERSTATUS_LINK_KEY = 'delete-orderstatus';
export const IMPORT_ORDERSTATUSES_LINK_KEY = 'import-orderstatuses';
export const EXPORT_ORDERSTATUSES_LINK_KEY = 'export-orderstatuses';
export const ORDERSTATUS_ENTITY_NAME = 'Order Status';

export interface GetOrderStatusParams {
  orderStatusId: number;
}

export interface ImportOrderStatusesParams {
  organizationId: number;
}

export interface ExportOrderStatusesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getOrderStatusRequest = async (
  resource: LinkResourceBaseDto | null,
  { orderStatusId }: GetOrderStatusParams,
): Promise<OrderStatusDto> => {
  if (resource && orderStatusId) {
    const getOrderStatusLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERSTATUS_LINK_KEY,
    );
    if (getOrderStatusLink) {
      const result = await execLink(getOrderStatusLink, { orderStatusId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ORDERSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getOrderStatusesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderStatusDtoPagedResult> => {
  if (resource) {
    const getOrderStatusesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERSTATUSES_LINK_KEY,
    );
    if (getOrderStatusesListLink) {
      const result = await execLink(getOrderStatusesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_ORDERSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createOrderStatusRequest = async (
  resource: LinkResourceBaseDto,
  orderStatus: CreateOrderStatusCommandValues,
) => {
  const createOrderStatusLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORDERSTATUS_LINK_KEY,
  );
  if (createOrderStatusLink) {
    const result = await execLink(createOrderStatusLink, orderStatus);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_ORDERSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateOrderStatusRequest = async (
  resource: LinkResourceBaseDto,
  orderStatus: UpdateOrderStatusCommandValues,
) => {
  const updateOrderStatusLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORDERSTATUS_LINK_KEY,
  );
  if (updateOrderStatusLink) {
    const result = await execLink(updateOrderStatusLink, orderStatus);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_ORDERSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteOrderStatusRequest = async (orderStatus: OrderStatusDto) => {
  const deleteOrderStatusLink = orderStatus.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORDERSTATUS_LINK_KEY,
  );
  if (deleteOrderStatusLink) {
    const result = await execLink(deleteOrderStatusLink, orderStatus);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_ORDERSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importOrderStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportOrderStatusesParams,
  file: File,
) => {
  const importOrderStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_ORDERSTATUSES_LINK_KEY,
  );
  if (importOrderStatusesLink) {
    const result = await postFormData(importOrderStatusesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_ORDERSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportOrderStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportOrderStatusesParams,
) => {
  const exportOrderStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_ORDERSTATUSES_LINK_KEY,
  );
  if (exportOrderStatusesLink) {
    const result = await downloadLinkFile(exportOrderStatusesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_ORDERSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};
