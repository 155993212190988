import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import {
  PackageTypeDto,
  PackageTypeDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  packageTypeStore,
  getPackageTypesColumnsFx,
  getPackageTypesFx,
  deletePackageTypeFx,
  exportPackageTypesFx,
  importPackageTypesFx,
} from '../packageTypes.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { userHas } from '../../auth/auth.store';
import {
  CREATE_PACKAGETYPE_LINK_KEY,
  DELETE_PACKAGETYPE_LINK_KEY,
  IMPORT_PACKAGETYPES_LINK_KEY,
  EXPORT_PACKAGETYPES_LINK_KEY,
  GetPackageTypeParams,
} from '../packageTypes.service';
import { ListElementsNotFound } from '../../common/components/list-elements-not-found/list-elements-not-found.component';
import { PackageTypeDialog } from './packageType.dialog';
import { IAction, getAction } from '../../common/components/actions/actions';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { getFileFromUser } from '../../common/utilities';

export type PackageTypesListProps = {
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  viewName?: string;
  goToDetails?: (packageTypeParams: GetPackageTypeParams | any) => void;
  goToCreate?: (discountCreateParams?: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onLimitChanged?: (limit: number) => void;
  onViewChanged?: (
    viewName: string,
    sortField: string,
    limitNumber: number,
  ) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    packageType: PackageTypeDto,
    packageTypeParams: GetPackageTypeParams,
  ) => void;
};

export const PackageTypesList = ({
  offset = 0,
  limit = 20,
  search = {},
  filter = null,
  sort = null,
  viewName = null,
  goToDetails = () => {},
  goToCreate = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onLimitChanged = () => {},
  onViewChanged = () => {},
  onSort = () => {},
  onSelect = () => {},
  onFilter = () => {},
}: PackageTypesListProps) => {
  const [
    packageTypes,
    setPackageTypes,
  ] = useState<PackageTypeDtoPagedResult | null>(null);

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [getColumnsSuccess, setGetColumnsSuccess] = useState(false);
  const [getDataSuccess, setGetDataSuccess] = useState(false);

  const {
    packageTypeColumns: columns,
    defaultPackageTypeColumns: defaultColumns,
    defaultSort,
    defaultLimit,
  } = useStore(packageTypeStore);

  useEffect(() => {
    getPackageTypesColumnsFx().then(() => {
      setGetColumnsSuccess(true);
    });
  }, []);

  useEffect(() => {
    if (getColumnsSuccess && getDataSuccess) {
      setLoading(false);
    }
  }, [getColumnsSuccess, getDataSuccess]);

  const getPackageTypes = useCallback(async () => {
    const packageTypesData = await getPackageTypesFx({
      offset,
      limit,
      sort,
      filter,
      search,
    });

    if (packageTypesData) {
      setActions(getActions(packageTypesData));
      setPackageTypes(packageTypesData);
      setGetDataSuccess(true);
    }
  }, [offset, limit, sort, filter, search]);

  const getActions = useCallback(
    (result: PackageTypeDtoPagedResult): IAction[] => {
      const activeActions: (IAction | null)[] = [
        getAction(
          IMPORT_PACKAGETYPES_LINK_KEY,
          'Import',
          <FiUpload />,
          async () => {
            await importPackageTypesFx({
              file: await getFileFromUser(),
              listResource: result,
            });
            await getPackageTypes();
          },
          result?.links,
        ),
        getAction(
          EXPORT_PACKAGETYPES_LINK_KEY,
          'Export',
          <FiDownload />,
          () => exportPackageTypesFx({ listResource: result }),
          result?.links,
        ),
      ];
      return activeActions.filter(Boolean) as IAction[];
    },
    [getPackageTypes],
  );

  useEffect(() => {
    getPackageTypes();
  }, [getPackageTypes]);

  const onDeletePackageType = useCallback(
    async (packageType: PackageTypeDto) => {
      if (userHas(DELETE_PACKAGETYPE_LINK_KEY, packageTypes?.items[0]?.links)) {
        const result = await showDialog({
          dialog: Confirm,
          props: {
            title: `Delete Package type`,
            message: 'Are you sure you want to delete package type?',
            className: 'delete-modal',
          },
        });
        if (result) {
          try {
            await deletePackageTypeFx(packageType);
            await getPackageTypes();
            onDelete();
          } catch (error) {
            // Handle error here
          }
        }
      }
    },
    [getPackageTypes, onDelete, packageTypes],
  );

  const onEditPackageType = (packageType: PackageTypeDto) => {
    if (packageType) goToDetails({ packageTypeId: packageType.packageTypeId });
  };

  const onPackageTypeSelect = useCallback(
    async (packageType) => {
      const selectedPackageType = await showDialog({
        dialog: PackageTypeDialog,
        props: {
          packageTypeId: packageType.packageTypeId,
          title: 'Update Package type',
        },
      });
      if (selectedPackageType) {
        getPackageTypes();
      }
    },
    [getPackageTypes],
  );

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div className="pb-3">
        <Grid
          rowKeys={['packageTypeId']}
          offset={offset}
          limit={limit}
          filter={filter}
          search={search}
          sort={sort}
          columns={columns}
          actions={actions}
          viewName={viewName}
          data={packageTypes.items}
          onLimitChanged={onLimitChanged}
          total={packageTypes.totalCount}
          onDelete={
            userHas(DELETE_PACKAGETYPE_LINK_KEY, packageTypes?.items[0]?.links)
              ? onDeletePackageType
              : null
          }
          onSort={onSort}
          onEdit={onEditPackageType}
          onPageChanged={onPageChanged}
          onViewChanged={onViewChanged}
          onSelect={onPackageTypeSelect}
          onSearch={onSearch}
          showAllStore={true}
          defaultColumns={defaultColumns}
          defaultSort={defaultSort}
          defaultLimit={defaultLimit}
          onFilter={onFilter}
          showToolbar={false}
          showGridPanel={true}
        />
        {packageTypes?.totalCount === 0 && (
          <ListElementsNotFound
            entityName="Package type"
            entityLinkKey={CREATE_PACKAGETYPE_LINK_KEY}
            onClick={goToCreate}
            additionalConditions={!filter && !search}
          />
        )}
      </div>
    );
  }
};
