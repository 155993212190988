import { v4 } from 'uuid';
import {
  CommodityTrackingNumberDto,
  LinkDto,
  LinkResourceBaseDto,
  CommodityTrackingNumberDtoPagedResult,
  CreateCommodityTrackingNumberCommandValues,
  UpdateCommodityTrackingNumberCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { addMessage } from '../common/messages.store';
import { ListParams } from '../common/models/ListParams';
import { CreateTrackingNumbersForCommodityParams } from './commodityTrackingNumbers.store';

export const GET_COMMODITYTRACKINGNUMBERS_LINK_KEY =
  'get-commoditytrackingnumbers';
export const CREATE_COMMODITYTRACKINGNUMBER_LINK_KEY =
  'create-commoditytrackingnumber';
export const GET_COMMODITYTRACKINGNUMBER_LINK_KEY =
  'get-commoditytrackingnumber';
export const UPDATE_COMMODITYTRACKINGNUMBER_LINK_KEY =
  'update-commoditytrackingnumber';
export const DELETE_COMMODITYTRACKINGNUMBER_LINK_KEY =
  'delete-commoditytrackingnumber';
export const CREATE_ALL_COMMODITY_TRACKING_NUMBERS_LINK_KEY =
  'create-allcommoditytrackingnumbers';
export const GET_COMMODITYTRACKINGNUMBER_BY_TRACKING_NUMBER_LINK_KEY =
  'get-commoditytrackingnumber-by-tracking-number';

export interface GetCommodityTrackingNumberParams {
  commodityTrackingNumber?: CommodityTrackingNumberDto;
  commodityTrackingNumberId?: number;
  trackingNumber?: string;
}

export const getCommodityTrackingNumberRequest = async (
  resource: LinkResourceBaseDto | null,
  { commodityTrackingNumberId }: GetCommodityTrackingNumberParams,
): Promise<CommodityTrackingNumberDto> => {
  if (resource && commodityTrackingNumberId) {
    const getCommodityTrackingNumberLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITYTRACKINGNUMBER_LINK_KEY,
    );
    if (getCommodityTrackingNumberLink) {
      const result = await execLink(getCommodityTrackingNumberLink, {
        commodityTrackingNumberId,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COMMODITYTRACKINGNUMBER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCommodityTrackingNumberByTrackingNumberRequest = async (
  resource: LinkResourceBaseDto | null,
  { trackingNumber }: GetCommodityTrackingNumberParams,
): Promise<CommodityTrackingNumberDto> => {
  if (resource && trackingNumber) {
    const getCommodityTrackingNumberLink = resource.links?.find(
      (x: LinkDto) =>
        x.rel === GET_COMMODITYTRACKINGNUMBER_BY_TRACKING_NUMBER_LINK_KEY,
    );
    if (getCommodityTrackingNumberLink) {
      const result = await execLink(getCommodityTrackingNumberLink, {
        trackingNumber,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COMMODITYTRACKINGNUMBER_BY_TRACKING_NUMBER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCommodityTrackingNumbersListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CommodityTrackingNumberDtoPagedResult> => {
  if (resource) {
    const getCommodityTrackingNumbersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITYTRACKINGNUMBERS_LINK_KEY,
    );
    if (getCommodityTrackingNumbersListLink) {
      const result = await execLink(
        getCommodityTrackingNumbersListLink,
        params,
      );
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COMMODITYTRACKINGNUMBERS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCommodityTrackingNumberRequest = async (
  resource: LinkResourceBaseDto,
  commodityTrackingNumber: CreateCommodityTrackingNumberCommandValues,
) => {
  const createCommodityTrackingNumberLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_COMMODITYTRACKINGNUMBER_LINK_KEY,
  );
  if (createCommodityTrackingNumberLink) {
    const result = await execLink(
      createCommodityTrackingNumberLink,
      commodityTrackingNumber,
    );
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_COMMODITYTRACKINGNUMBER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateCommodityTrackingNumberRequest = async (
  resource: LinkResourceBaseDto,
  commodityTrackingNumber: UpdateCommodityTrackingNumberCommandValues,
) => {
  const updateCommodityTrackingNumberLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_COMMODITYTRACKINGNUMBER_LINK_KEY,
  );
  if (updateCommodityTrackingNumberLink) {
    const result = await execLink(
      updateCommodityTrackingNumberLink,
      commodityTrackingNumber,
    );
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_COMMODITYTRACKINGNUMBER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteCommodityTrackingNumberRequest = async (
  commodityTrackingNumber: CommodityTrackingNumberDto,
) => {
  const deleteCommodityTrackingNumberLink = commodityTrackingNumber.links?.find(
    (x: LinkDto) => x.rel === DELETE_COMMODITYTRACKINGNUMBER_LINK_KEY,
  );
  if (deleteCommodityTrackingNumberLink) {
    const result = await execLink(
      deleteCommodityTrackingNumberLink,
      commodityTrackingNumber,
    );
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_COMMODITYTRACKINGNUMBER_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCommodityTrackingNumbersRequest = async (
  resource?: LinkResourceBaseDto | null,
  commodityTrackingNumbers?: CreateTrackingNumbersForCommodityParams,
): Promise<void> => {
  if (resource) {
    const createAllCommodityTrackingNumbersLink = resource?.links?.find(
      (x: LinkDto) => x.rel === CREATE_ALL_COMMODITY_TRACKING_NUMBERS_LINK_KEY,
    );
    if (createAllCommodityTrackingNumbersLink) {
      await execLink(
        createAllCommodityTrackingNumbersLink,
        commodityTrackingNumbers,
      );
      return;
    }
  }
  addMessage({
    message: `Action ${CREATE_ALL_COMMODITY_TRACKING_NUMBERS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
