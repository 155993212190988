import React from 'react';
import { Panel } from '../../common/components/panel/panel.component';
import { ForgotPasswordComponent } from '../components/forgot-password.component';
import { Helmet } from 'react-helmet';

export type ForgotPasswordScreenProps = { pageTitle: string };

export const ForgotPasswordScreen = ({
  pageTitle,
}: ForgotPasswordScreenProps) => {
  return (
    <div className="py-5">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="d-flex justify-content-center">
        <Panel style={{ maxWidth: '500px', width: '100%' }}>
          <h1 className="text-center login-register-header font-weight-light">
            Forgot password
          </h1>
          <ForgotPasswordComponent />
        </Panel>
      </div>
    </div>
  );
};
