import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ForwardingAgentScreen } from './screens/forwardingAgent.screen';
import { ForwardingAgentListScreen } from './screens/forwardingAgent-list.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_FORWARDING_AGENTS } from '../contacts/contact.route';

export const FORWARDING_AGENTS_LIST_PATH = '/forwarding-agents';
export const FORWARDING_AGENTS_EDIT_PATH = '/forwarding-agents/:contactId';
export const FORWARDING_AGENTS_CREATE_PATH = '/forwarding-agents/0';

export const ForwardingAgentsRoute = (props: RouteProps) => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ForwardingAgentListScreen
              pageTitle="Forwarding Agents"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ForwardingAgentScreen
              pageTitle="Add New Forwarding Agent"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <ForwardingAgentScreen
              pageTitle="Update Forwarding Agent"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':customerId/contacts/:contactId'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Contacts" isTab={true} group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
