import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CountryForm } from './country.form';
import {
  CountryDto,
  CustomFieldDto,
  CustomFieldDtoPagedResult,
  CustomFieldEntityType,
  CustomFieldType,
} from '../../../models/data.models';
import {
  createCountryFx,
  deleteCountryFx,
  getCountryFx,
  updateCountryFx,
} from '../countries.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CountryDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import {
  DELETE_COUNTRY_LINK_KEY,
  UPDATE_COUNTRY_LINK_KEY,
} from '../countries.service';
import { userHas } from '../../auth/auth.store';
import { getCustomFieldsFx } from '../../customFields/customFields.store';
import { generateValidationSchemaWithCustomFields } from '../../../utils/helper.utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { Dropdown } from 'react-bootstrap';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { FiDelete, FiUpload } from 'react-icons/fi';
import { IAction, getAction } from '../../common/components/actions/actions';
import { AiFillDelete } from 'react-icons/ai';

export type CountryEditProps = {
  countryCode?: string;
  onCountryCreated?: (country: CountryDto) => void;
  onCountryUpdated?: (country: CountryDto) => void;
  onCountryLoaded?: (country: CountryDto) => void;
  onCancel?: () => void;
  onDelete?: (country: CountryDto) => void;
};

const initialState: CountryDto = {
  links: [],
  organizationId: 0,
  countryCode: CountryDefaultValues.countryCode,
  customValues: {},
  name: CountryDefaultValues.countryName,
};

export const CountryEdit = ({
  countryCode,
  onCountryLoaded,
  onCountryCreated,
  onCountryUpdated,
  onCancel,
  onDelete,
}: CountryEditProps) => {
  const [isCreateMode, setIsCreateMode] = useState(
    !countryCode || countryCode === 'create',
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [country, setCountry] = useState<CountryDto | null>(null);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setIsCreateMode(!countryCode || countryCode === 'create');
  }, [countryCode]);

  const countrySchema = useMemo(() => {
    let schema = Yup.object().shape({
      countryCode: Yup.string()
        .required("Can't be blank")
        .max(3, 'Max length is 3')
        .nullable(true),
      name: Yup.string().required("Can't be blank").nullable(true),
    });

    if (customFields.length > 0) {
      schema = generateValidationSchemaWithCustomFields(customFields, schema);
    }

    return schema;
  }, [customFields]);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        DELETE_COUNTRY_LINK_KEY,
        'Delete',
        <AiFillDelete />,
        async () => {
          const dialogResult = await showDialog({
            dialog: Confirm,
            props: {
              title: `Delete Country`,
              message: `Are you sure you want to delete ${country.name}?`,
              className: 'delete-modal',
            },
          });
          if (dialogResult) {
            try {
              await deleteCountryFx(country);
              if (onCancel) onCancel();
            } catch (error) {
              // Handle error here
            }
          }
        },
        country?.links,
      ),
    ];
    return activeActions.filter(Boolean);
  }, [country]);

  const fetchCustomFields = useCallback(async () => {
    const fields: CustomFieldDtoPagedResult = await getCustomFieldsFx({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Country} AND isInactive: false`,
    });
    setCustomFields(fields.items);
  }, []);

  const fetchCountryData = useCallback(async () => {
    const countryDto = await getCountryFx({ countryCode });
    setCountry(countryDto);
    if (onCountryLoaded) onCountryLoaded(countryDto);
    setIsLoading(false);
  }, [countryCode, onCountryLoaded]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    if (!isCreateMode && countryCode) {
      fetchCountryData();
    } else {
      setCountry({ ...initialState });
      setIsLoading(false);
    }
  }, [isCreateMode, fetchCountryData]);

  const onSubmit = useCallback(
    async (data: CountryDto) => {
      setIsSubmitting(true);
      try {
        const result = isCreateMode
          ? await createCountryFx(data)
          : await updateCountryFx(data);
        isCreateMode ? onCountryCreated?.(result) : onCountryUpdated?.(result);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isCreateMode, onCountryCreated, onCountryUpdated],
  );

  const onCustomFieldChange = (result) => {
    setCountry((prevState) => ({
      ...prevState,
      customValues: result,
    }));
  };

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <CountryForm
      id={'country-form'}
      initialValues={country || initialState}
      onSubmit={onSubmit}
      validationSchema={countrySchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Additional</Tab>
                <li className="react-tabs__tab react-tabs__tab--button">
                  <ActionsDropdown size="sm" actions={actions} />
                </li>
              </TabList>

              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New Country</h3>
                  ) : (
                    <h3 className="header-form">Update Country</h3>
                  )}
                  <div className="row">
                    <div className="col-4">
                      <CountryForm.CountryCode disabled={!isCreateMode} />
                      <CountryForm.Name />
                    </div>
                  </div>
                </Panel>
              </TabPanel>

              <TabPanel forceRender={!isCreateMode}>
                <CountryForm.CustomFields
                  customFields={customFields}
                  entityType={CustomFieldEntityType.Country}
                  defaultValue={isCreateMode ? {} : country?.customValues}
                  onChange={onCustomFieldChange}
                  isSending={isSubmitting}
                  formName={'country-form'}
                  entityName={'Country'}
                  context={context}
                />
              </TabPanel>
            </Tabs>
            <div className="row m-3">
              <div className="col-auto text-right">
                {(userHas(UPDATE_COUNTRY_LINK_KEY, country?.links) ||
                  isCreateMode) && (
                  <Button
                    name="save-country"
                    type="submit"
                    color="primary"
                    form={'country-form'}
                    disabled={isSubmitting}
                  >
                    Save Country
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  className="ml-2"
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </CountryForm>
  );
};
