import {
  AddressType,
  ContactDto,
  ContactType,
  CustomFieldDto,
  CustomFieldEntityType,
} from '../../../../models/data.models';
import { OrderForm } from '../../../orders/components/order.form';
import React from 'react';
import { CustomFieldsForm } from '../../../common/components/form/customFields-form.component';

export type OrderEntityProps = {
  index: number;
  entityName: string;
  header: string;
  allowDelete: boolean;
  onOrderEntityDeleted?: () => void;
  setOrderEntityContact: (
    contactId: number,
    name: string,
    contactType: ContactType,
  ) => void;
  setOrderEntityFilter: (filter: string) => void;
  setOrderEntityContactAddress: (
    contactAddressId: number,
    fullAddress: string,
  ) => void;
  entityTypeFilter: CustomFieldEntityType;
  orderEntityContact: {
    contactId: number;
    name: string;
    contactType: ContactType;
    contactAddressId: number;
    fullAddress: string;
  };
  customValues?: object;
  onChange?: (result: object) => void;
  customFields: CustomFieldDto[];
};

export const OrderEntityForm = ({
  index,
  entityName,
  header,
  setOrderEntityContact,
  setOrderEntityFilter,
  setOrderEntityContactAddress,
  entityTypeFilter,
  orderEntityContact,
  allowDelete = false,
  onOrderEntityDeleted,
  customValues,
  customFields,
  onChange = (result) => {},
}: OrderEntityProps) => {
  const shipper =
    orderEntityContact &&
    orderEntityContact.contactId != null &&
    orderEntityContact.name != null &&
    orderEntityContact.contactType != null
      ? {
          contactId: orderEntityContact.contactId,
          name: orderEntityContact.name,
          contactType: orderEntityContact.contactType,
        }
      : '';

  const contactAddress =
    orderEntityContact &&
    orderEntityContact.contactAddressId != null &&
    orderEntityContact.fullAddress != null
      ? {
          contactAddressId: orderEntityContact.contactAddressId,
          fullAddress: orderEntityContact.fullAddress,
        }
      : '';

  const filteredCustomFields = customFields?.filter(
    (field) => field.customFieldEntityType == entityTypeFilter,
  );

  const getFullAddress = (data: any) => {
    const address = `${data?.addressLine ? data.addressLine + ', ' : ''}${
      data?.addressLine2 ? data.addressLine2 + ', ' : ''
    }${data?.stateCode ? data.stateCode + ', ' : ''}${
      data?.postalCode ? data.postalCode + ', ' : ''
    }${data?.countryCode ? data.countryCode : ''}`;

    return address;
  };

  return (
    <div className="row" data-testid={`${header}-${index + 1}`}>
      <div className="col-3">
        <OrderForm.ShipperCompany
          id={`${entityName}[${index}].contactId`}
          header={`${header} ${index + 1}`}
          contactTypes={[ContactType.Contact]}
          selectedFilter={`contactType: ${ContactType.Contact}`}
          required={false}
          defaultValue={shipper}
          onChange={(data?: ContactDto, context?: any) => {
            setOrderEntityContact(
              data?.contactId,
              data?.name,
              data?.contactType,
            );
            setOrderEntityFilter(
              data?.contactId ? `contactId:${data?.contactId}` : ' ',
            );
          }}
          nameId={`orderEntities${entityName}s[${index}].contactName`}
        />
      </div>
      <div className="col-3">
        <OrderForm.ShipperLocation
          contactId={1}
          contactType={ContactType.Contact}
          addressType={AddressType.Other}
          defaultValue={contactAddress}
          onChange={(data: any, context?: any) => {
            setOrderEntityContactAddress(
              data?.contactAddressId,
              data?.fullAddress ?? getFullAddress(data),
            );
          }}
        />
      </div>
      <div className={allowDelete ? 'col-3' : 'col-6'}>
        <div className={'w-100'}>
          <CustomFieldsForm
            customFields={filteredCustomFields}
            defaultValue={customValues}
            inputNamePrefix={`${entityName}[${index}]`}
            onChange={onChange}
          />
        </div>
      </div>
      {allowDelete && (
        <div className="col-3 d-flex">
          <div className={'d-flex align-items-center pt-3'}>
            <div
              data-testid={`delete-${entityName}[${index}]`}
              onClick={(event) => {
                onOrderEntityDeleted();
              }}
              color="primary"
              className="btn-close icon-close"
            />
          </div>
        </div>
      )}
    </div>
  );
};
