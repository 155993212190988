import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CurrencyForm } from './currency.form';
import {
  AccountingItemDto,
  CurrencyDto,
  DecimalPlace,
} from '../../../models/data.models';
import {
  createCurrencyFx,
  getCurrenciesFx,
  getCurrencyFx,
  updateCurrencyFx,
} from '../currencies.store';
import { Panel } from '../../common/components/panel/panel.component';
import { useLocation } from 'react-router-dom';
import { ReactSelectItem } from '../../../models/custom.models';
import { getEnumValues } from '../../../utils/helper.utils';
import { CurrencyDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { userHas } from '../../auth/auth.store';
import { UPDATE_CURRENCY_LINK_KEY } from '../currencies.service';

export type CurrencyEditProps = {
  currencyId?: number | null;
  onCurrencyCreated?: (currency: CurrencyDto) => void;
  onCurrencyUpdated?: (currency: CurrencyDto) => void;
  onCurrencyLoaded?: (currency: CurrencyDto) => void;
  onCancel?: () => void;
};

const initialState: CurrencyDto = {
  currencyCode: CurrencyDefaultValues.currencyCode,
  currencyName: CurrencyDefaultValues.currencyName,
  decimalPlaces: CurrencyDefaultValues.decimalPlaces,
  exchangeRateLastUpdate: CurrencyDefaultValues.exchangeRateLastUpdate,
  exchangeRateToPrimary: CurrencyDefaultValues.exchangeRateToPrimary,
  gainLossAccountDescription: CurrencyDefaultValues.gainLossAccountDescription,
  gainLossAccountId: CurrencyDefaultValues.gainLossAccountId,
  isInactive: CurrencyDefaultValues.isInactive,
  organizationId: null,
  symbol: CurrencyDefaultValues.symbol,
  created: null,
  createdBy: '',
  createdByUserName: '',
  lastModified: null,
  lastModifiedBy: '',
  lastModifiedByUserName: '',
  currencyId: 0,
  links: [],
};

const currencySchema = Yup.object().shape({
  currencyName: Yup.string().required("Can't be blank").nullable(true),
  currencyCode: Yup.string()
    .required("Can't be blank")
    .max(3, 'Max length is 3')
    .nullable(true),
  symbol: Yup.string()
    .required("Can't be blank")
    .length(1, 'The exact length is 1')
    .nullable(true),
  decimalPlaces: Yup.string().required("Can't be blank").nullable(true),
});

export const CurrencyEdit = ({
  currencyId,
  onCurrencyLoaded = () => {},
  onCurrencyCreated = () => {},
  onCurrencyUpdated = () => {},
  onCancel = () => {},
}: CurrencyEditProps) => {
  const isCreateMode = !currencyId || currencyId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currency, setCurrency] = useState<CurrencyDto | null>(null);
  const [initialValues, setInitialValues] = useState<CurrencyDto | null>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();
  const getEnumKeyByEnumValue = (
    myEnum: any,
    enumValue: number | string,
  ): string => {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
  };
  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (currencyId) {
      getCurrencyFx({ currencyId }).then(
        (currencyDto: CurrencyDto) => {
          setCurrency(currencyDto);
          setInitialValues(currencyDto);
          setIsLoading(false);
          onCurrencyLoaded(currencyDto);
        },
        () => {},
      );
    } else {
      throw new Error('Currency keys were not provided');
    }
  }, [currencyId]);

  const onSubmit = (data: CurrencyDto) => {
    data.decimalPlaces = currency?.decimalPlaces;
    data.exchangeRateLastUpdate = currency?.exchangeRateLastUpdate;
    setIsSending(true);
    if (isCreateMode) {
      createCurrencyFx(data)
        .then(
          async (result) => {
            onCurrencyCreated(result);
            await getCurrenciesFx();
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateCurrencyFx(data)
        .then(
          async (result) => {
            onCurrencyUpdated(result);
            await getCurrenciesFx();
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  const onExchangeRateLastUpdateChange = (date?: any) => {
    setCurrency((currencyDto) => {
      if (!currencyDto) {
        currencyDto = initialState;
      }
      currencyDto.exchangeRateLastUpdate = new Date(date);
      return { ...currencyDto };
    });
  };
  const onDecimalPlacesChange = (decimalPlaces: ReactSelectItem) => {
    setCurrency((currency) => {
      if (!currency) {
        currency = initialState;
      }
      currency.decimalPlaces = Number(decimalPlaces?.label);
      return { ...currency };
    });
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Tabs>
      <TabList>
        <span>
          <Tab name="currency">Currency</Tab>
        </span>
      </TabList>
      <CurrencyForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={ref}
        id="currencyForm"
        validationSchema={currencySchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Currency</h2>
            ) : (
              <h2>Update Currency</h2>
            )}
            <div className="row">
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.CurrencyName />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.CurrencyCode />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.Symbol />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.ExchangeRateToPrimary />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.ExchangeRateLastUpdate
                    defaultValue={currency?.exchangeRateLastUpdate}
                    onChange={onExchangeRateLastUpdateChange}
                  />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.AccountingItemSelect
                    required={false}
                    nameId={'gainLossAccountDescription'}
                    id={'gainLossAccountId'}
                    header={'Gain Loss Account'}
                    placeholder={'Select Gain Loss Account'}
                    defaultValue={
                      currency &&
                      currency.gainLossAccountId != null &&
                      currency.gainLossAccountDescription != null
                        ? {
                            accountingItemId: currency.gainLossAccountId,
                            description: currency.gainLossAccountDescription,
                          }
                        : ''
                    }
                    onChange={(data?: AccountingItemDto) => {
                      setCurrency((currency) => {
                        if (!currency) {
                          currency = initialState;
                        }
                        currency.gainLossAccountId = data?.accountingItemId;
                        currency.gainLossAccountDescription = data?.description;
                        return { ...currency };
                      });
                    }}
                  />
                </div>
              </div>

              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.DecimalPlaces
                    placeholder={'Select Decimal Places'}
                    id={'decimalPlaces'}
                    header={'Decimal Places'}
                    name={'decimalPlaces'}
                    disabled={false}
                    required={true}
                    defaultValue={
                      currency?.decimalPlaces
                        ? {
                            label: DecimalPlace[currency?.decimalPlaces],
                            value: currency?.decimalPlaces,
                          }
                        : null
                    }
                    onChange={onDecimalPlacesChange}
                    multiple={false}
                    options={getEnumValues(DecimalPlace)}
                  />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <CurrencyForm.IsInactive
                    defaultValue={currency?.isInactive ?? false}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="justify-content-end row">
                  {(userHas(UPDATE_CURRENCY_LINK_KEY, currency?.links) ||
                    isCreateMode) && (
                    <div className="col-3">
                      <Button
                        name="save-currency"
                        type="submit"
                        color="primary"
                        className="w-100 btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Currency
                      </Button>
                    </div>
                  )}
                  <div className="col-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </CurrencyForm>
    </Tabs>
  );
};
