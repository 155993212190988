import React, { useState, useEffect } from 'react';
import { closeMenuItem, navigationStore, openMenuItem } from '../../nav.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';
import { LinkDto } from '../../../../models/data.models';
import { organizationsStore } from '../../../organization/organization.store';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type ActionsGroupComponentProps = {
  groupName: string;
  displayGroupName: string;
  icon: IconProp;
  actions: string[];
  children: JSX.Element;
};

export const ActionsGroupComponent = ({
  groupName,
  icon,
  actions,
  children,
  displayGroupName,
}: ActionsGroupComponentProps) => {
  const navStore = useStore(navigationStore);
  const { currentOrganization } = organizationsStore.getState();
  const hasAllowedActions = actions.some((action) =>
    currentOrganization?.links?.some((x: LinkDto) => x.rel === action),
  );

  const [isGroupMenuItemHovered, setIsGroupMenuItemHovered] = useState<boolean>(
    false,
  );
  const [isGroupMenuDataHovered, setIsGroupMenuDataHovered] = useState<boolean>(
    false,
  );

  useEffect(() => {
    if (isGroupMenuItemHovered || isGroupMenuDataHovered) {
      openMenuItem(groupName);
    } else {
      closeMenuItem(groupName);
    }
  }, [isGroupMenuItemHovered, isGroupMenuDataHovered]);

  const handleGroupMenuItemHover = () => {
    setIsGroupMenuItemHovered((prev) => !prev);
  };

  const handleGroupMenuDataHover = () => {
    setIsGroupMenuDataHovered((prev) => !prev);
  };

  if (hasAllowedActions)
    return (
      <li
        className={`nav-item nav-ripple ${
          navStore[groupName] ? 'dropup' : ''
        } `}
      >
        <a
          onPointerEnter={handleGroupMenuItemHover}
          onMouseLeave={() => {
            setIsGroupMenuItemHovered(false);
          }}
          className={`nav-link ${
            navStore.path[0] === displayGroupName ? 'active' : ''
          } nav-icon-size-medium dropdown-toggle`}
          data-cy={`link-${groupName}`}
        >
          <div className="menu-icon-wrapper d-flex justify-content-center align-items-center d-lg-none">
            <FontAwesomeIcon icon={icon} />
          </div>

          <div className="nav-link-text">{displayGroupName}</div>
        </a>
        <ul
          className={`collapse p-4 position-absolute nav-group ${
            navStore[groupName] ? 'show' : ''
          }`}
          onClick={() => {
            window.scrollTo(0, 0);
            closeMenuItem(groupName);
          }}
          onMouseEnter={handleGroupMenuDataHover}
          onMouseLeave={() => {
            setIsGroupMenuDataHovered(false);
          }}
        >
          {children}
        </ul>
      </li>
    );
  return null;
};
