import {
  PackageTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreatePackageTypeCommandValues,
  UpdatePackageTypeCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPackageTypeRequest,
  getPackageTypesListRequest,
  getPackageTypeRequest,
  deletePackageTypeRequest,
  updatePackageTypeRequest,
  importPackageTypesRequest,
  exportPackageTypesRequest,
  GetPackageTypeParams,
} from './packageTypes.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type PackageTypesStoreState = {
  links: LinkDto[];
  packageTypeColumns: EntityFieldDto[];
  defaultPackageTypeColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getPackageTypesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'PackageType',
  });
});

addEffectStatusHandling(getPackageTypesColumnsFx);

export const getPackageTypesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'PackageType',
  });
});

addEffectStatusHandling(getPackageTypesDefaultColumnsFx);

export const updatePackageTypesColumns = createEvent<EntityFieldDto[]>();

export const getPackageTypesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPackageTypesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getPackageTypesFx);

export const createPackageTypeFx = createEffect(
  (packageTypeData: PackageTypeDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createPackageTypeCommand: CreatePackageTypeCommandValues = {
      ...packageTypeData,
    };

    return createPackageTypeRequest(
      currentOrganization!,
      createPackageTypeCommand,
    );
  },
);

addEffectStatusHandling(createPackageTypeFx, {
  completeMessage: 'Package type was created successfully',
  errorMessage: 'Package type was not created',
});

export const getPackageTypeFx = createEffect(
  (packageTypeParams: GetPackageTypeParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getPackageTypeRequest(
      currentOrganization as LinkResourceBaseDto,
      packageTypeParams,
    );
  },
);

addEffectStatusHandling(getPackageTypeFx);

export const updatePackageTypeFx = createEffect(
  (packageType: PackageTypeDto) => {
    const updatePackageTypeCommand: UpdatePackageTypeCommandValues = {
      ...packageType,
    };
    return updatePackageTypeRequest(packageType, updatePackageTypeCommand);
  },
);

addEffectStatusHandling(updatePackageTypeFx, {
  completeMessage: 'Package type was updated successfully',
  errorMessage: 'Package type was not updated',
});

export const deletePackageTypeFx = createEffect(
  (packageType: PackageTypeDto) => {
    return deletePackageTypeRequest(packageType);
  },
);

addEffectStatusHandling(deletePackageTypeFx, {
  completeMessage: 'Package type was deleted successfully',
  errorMessage: 'Package type was not deleted',
});

export const exportPackageTypesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportPackageTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportPackageTypesFx, {
  completeMessage: 'Package types were exported successfully',
  errorMessage: 'Package types were not exported',
});

export const importPackageTypesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importPackageTypesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importPackageTypesFx, {
  completeMessage: 'Package types were imported successfully',
  errorMessage: 'Package types were not imported',
  inFlightMessage: 'Importing package types...',
});

const defaultState: PackageTypesStoreState = {
  links: [],
  packageTypeColumns: [],
  defaultPackageTypeColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const packageTypeStore: Store<PackageTypesStoreState> = createStore(
  defaultState,
)
  .on(getPackageTypesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      packageTypeColumns: payload.result.items,
      defaultPackageTypeColumns: payload.result.items,
    };
  })
  .on(getPackageTypesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultPackageTypeColumns: payload.result.items,
    };
  })
  .on(updatePackageTypesColumns, (state, payload) => {
    return { ...state, packageTypeColumns: payload };
  });
