import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  PACKAGETYPE_CREATE_PATH,
  PACKAGETYPES_LIST_PATH,
} from '../packageType.route';
import { PackageTypeEdit } from '../components/packageType-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_PACKAGETYPE_LINK_KEY } from '../packageTypes.service';
import { userHas } from '../../auth/auth.store';

export type PackageTypeScreenProps = { pageTitle: string; group: string };

export const PackageTypeScreen = ({
  pageTitle,
  group,
}: PackageTypeScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, packageTypeId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + PACKAGETYPE_CREATE_PATH, {
    organizationId,
  });

  const onPackageTypeCreated = () => {
    const path = generatePath(INTERNAL_PATH + PACKAGETYPES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'package-type-created',
      type: 'success',
      autoHide: true,
      message: 'Package Type successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onPackageTypeUpdated = () => {
    const path = generatePath(INTERNAL_PATH + PACKAGETYPES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'package-type-updated',
      type: 'success',
      autoHide: true,
      message: 'Package Type successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + PACKAGETYPES_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Package Types'}
      createPath={userHas(CREATE_PACKAGETYPE_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PackageTypeEdit
        packageTypeId={packageTypeId}
        onPackageTypeUpdated={onPackageTypeUpdated}
        onPackageTypeCreated={onPackageTypeCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
