import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DocumentTemplateScreen } from './screens/documentTemplates.screen';
import { DocumentTemplatesListScreen } from './screens/documentTemplates-list.screen';

export const DOCUMENT_TEMPLATES_LIST_PATH = '/documentTemplates';
export const DOCUMENT_TEMPLATES_EDIT_PATH =
  '/documentTemplates/:documentTemplateId';
export const DOCUMENT_TEMPLATES_CREATE_PATH = '/documentTemplates/0';

export const DocumentTemplateRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <DocumentTemplatesListScreen
              pageTitle="Document Templates"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <DocumentTemplateScreen
              pageTitle="Add New Document Template"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':documentTemplateId'}
        element={
          <PrivateRoute>
            <DocumentTemplateScreen
              pageTitle="Update Document Template"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
