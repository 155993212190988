import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { WorkflowForm } from './workflow.form';
import { WorkflowDto } from '../../../models/data.models';
import {
  createWorkflowFx,
  deleteWorkflowFx,
  getWorkflowFx,
  updateWorkflowFx,
} from '../workflows.store';
import { Panel } from '../../common/components/panel/panel.component';
import * as Yup from 'yup';
import {
  DELETE_WORKFLOW_LINK_KEY,
  UPDATE_WORKFLOW_LINK_KEY,
} from '../workflows.service';
import { userHas } from '../../auth/auth.store';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { LoadingIndicator } from '../../common/components/loading-indicator/loading-indicator.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { ActionsDropdown } from '../../common/components/actions/actions.component';
import { IAction, getAction } from '../../common/components/actions/actions';
import { AiFillDelete, AiFillCode } from 'react-icons/ai';
import { EXECUTE_WORKFLOWTRIGGER_LINK_KEY } from '../../workflowTriggers/workflowTriggers.service';
import { WorkflowTriggerDialog } from '../../workflowTriggers/components/workflowTrigger.dialog';

export type WorkflowEditProps = {
  workflowId?: string | null | undefined;
  onWorkflowCreated?: (workflow: WorkflowDto) => void;
  onWorkflowUpdated?: (workflow: WorkflowDto) => void;
  onWorkflowLoaded?: (workflow: WorkflowDto) => void;
  onCancel?: () => void;
  onDelete?: (workflow: WorkflowDto) => void;
};

const initialState: WorkflowDto = {
  isActive: false,
  workflowYamlDocument: '',
  links: [],
};

export const WorkflowEdit = ({
  workflowId,
  onWorkflowLoaded,
  onWorkflowCreated,
  onWorkflowUpdated,
  onCancel,
  onDelete,
}: WorkflowEditProps) => {
  const [isCreateMode, setIsCreateMode] = useState(workflowId == null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [workflow, setWorkflow] = useState<WorkflowDto | null>(null);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setIsCreateMode(workflowId == null);
  }, [workflowId]);

  const workflowSchema = useMemo(() => {
    const schema = Yup.object().shape({
      isActive: Yup.boolean().required('Is Active is required'),
      workflowYamlDocument: Yup.string().required(
        'Workflow Yaml Document is required',
      ),
    });

    return schema;
  }, []);

  const getActions = useCallback((): IAction[] => {
    const activeActions: (IAction | null)[] = [
      getAction(
        EXECUTE_WORKFLOWTRIGGER_LINK_KEY,
        'Execute',
        <AiFillCode />,
        async () => {
          await showDialog({
            dialog: WorkflowTriggerDialog,
            props: {
              workflowId: workflow?.workflowId,
              title: workflow?.workflowName || 'Execute Workflow',
            },
          });
        },
        workflow?.links,
      ),
      getAction(
        DELETE_WORKFLOW_LINK_KEY,
        'Delete',
        <AiFillDelete />,
        async () => {
          const dialogResult = await showDialog({
            dialog: Confirm,
            props: {
              title: `Delete Workflow`,
              message: `Are you sure you want to delete?`,
              className: 'delete-modal',
            },
          });
          if (dialogResult) {
            try {
              await deleteWorkflowFx(workflow);
              if (onDelete) onDelete(workflow);
            } catch (error) {
              // Handle error here
            }
          }
        },
        workflow?.links,
      ),
    ];
    return activeActions.filter(Boolean);
  }, [workflow]);

  const fetchWorkflowData = useCallback(async () => {
    if (!isCreateMode) {
      const workflowDto = await getWorkflowFx({ workflowId });
      setWorkflow(workflowDto);
      if (onWorkflowLoaded) onWorkflowLoaded(workflowDto);
      setIsLoading(false);
    }
  }, [workflowId, onWorkflowLoaded]);

  useEffect(() => {
    setActions(getActions());
  }, [getActions]);

  useEffect(() => {
    if (!isCreateMode) {
      fetchWorkflowData();
    } else {
      setWorkflow({ ...initialState });
      setIsLoading(false);
    }
  }, [isCreateMode, fetchWorkflowData]);

  const onSubmit = useCallback(
    async (data: WorkflowDto) => {
      setIsSubmitting(true);
      try {
        const result = isCreateMode
          ? await createWorkflowFx(data)
          : await updateWorkflowFx(data);
        isCreateMode
          ? onWorkflowCreated?.(result)
          : onWorkflowUpdated?.(result);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isCreateMode, onWorkflowCreated, onWorkflowUpdated],
  );

  if (isLoading) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <WorkflowForm
      id={'workflow-form'}
      initialValues={workflow || initialState}
      onSubmit={onSubmit}
      validationSchema={workflowSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <Tabs>
              <TabList>
                <Tab>Workflow YAML</Tab>
                <li className="react-tabs__tab react-tabs__tab--button">
                  <ActionsDropdown size="sm" actions={actions} />
                </li>
              </TabList>

              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h3 className="header-form">Add New Workflow</h3>
                  ) : (
                    <h3 className="header-form">Update Workflow</h3>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <WorkflowForm.WorkflowYamlDocument />
                      <WorkflowForm.IsActive
                        defaultValue={workflow.isActive}
                        disabled={true}
                      />
                    </div>
                  </div>
                </Panel>
              </TabPanel>
            </Tabs>
            <div className="row m-3">
              <div className="col-auto text-right">
                {(userHas(UPDATE_WORKFLOW_LINK_KEY, workflow?.links) ||
                  isCreateMode) && (
                  <Button
                    name="save-workflow"
                    type="submit"
                    color="primary"
                    form={'workflow-form'}
                    disabled={isSubmitting}
                  >
                    Save Workflow
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </WorkflowForm>
  );
};
