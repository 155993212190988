import React, { useEffect, useState } from 'react';
import { CommodityGrid } from '../../../common/components/grid/commodity-grid.component';
import {
  getCommodity,
  updateCommoditiesTableColumns,
} from '../../../commodities/commodities.store';
import { showDialog } from '../../../common/dialog.store';
import { CommodityDialog } from '../../../commodities/components/commodity.dialog';
import { authStore } from '../../../auth/auth.store';
import {
  Column,
  CommodityDto,
  OrderTypes,
} from '../../../../models/data.models';

export interface CommoditiesTableProps {
  commodities: CommodityDto[];
  setCommodities?: any;
  columns: Column[];
  onCommoditiesChange?: any;
  setParcelShipment?: any;
  setPurchaseCommodity?: any;
  setIsVerifiedAll?: any;
  shouldRenderShipments?: boolean;
}

export const CommoditiesTable = ({
  commodities,
  setCommodities,
  columns,
  setParcelShipment,
  setIsVerifiedAll,
  shouldRenderShipments,
  setPurchaseCommodity,
}: CommoditiesTableProps) => {
  const { user: currentUser } = authStore.getState();

  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');

  useEffect(() => {
    countCommodities();
  }, []);

  const [quantityCommodities, setQuantityCommodities] = useState(0);

  const onCommoditySelect = async (commodity, commodityId) => {
    if (commodityId) {
      const commodityToUpdate = await getCommodity({
        commodityId: commodity?.commodityId,
      });

      showDialog({
        dialog: CommodityDialog,
        props: {
          commodity: commodityToUpdate,
          commodityId: commodityToUpdate.commodityId,
          title: 'Update Package',
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          isEditModeInCommodities: true,
          saveBtnLabel: 'Save Package',
        },
      }).then((commodity) => {
        if (commodity !== null) {
          const i = commodities.findIndex(
            (x) => x?.commodityId == commodity?.commodityId,
          );
          const newCommodities = commodities;
          newCommodities[i] = commodity;
          if (setCommodities instanceof Function) {
            setCommodities(newCommodities);
          }
          if (setPurchaseCommodity instanceof Function) {
            setPurchaseCommodity(newCommodities[0]);
          }
          countCommodities();
        }
      });
    }
  };

  const onLimitChanged = (limit: number) => {
    setLimit(limit);
    setOffset(0);
  };

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const countCommodities = () => {
    setQuantityCommodities(
      commodities?.reduce(
        (quantitySum, pack) =>
          quantitySum +
          pack.containerCommodities.reduce(
            (quantitySumPack, com) => quantitySumPack + Number(com.quantity),
            0,
          ),
        0,
      ),
    );
  };

  return (
    <>
      <div className={'d-flex align-items-center'}>
        <div className={'w-100 d-flex'}>
          <div className={'d-flex align-items-center col-6 pl-0'}>
            <h3 className={'m-0'}>Packages</h3>
          </div>
        </div>
      </div>
      <CommodityGrid
        showAllStore={true}
        showEmptyTable={true}
        data={commodities}
        total={commodities?.length ?? 0}
        totalQuantity={quantityCommodities}
        columns={columns}
        rowKeys={['commodityId']}
        onColumnsChanged={updateCommoditiesTableColumns}
        showToolbar={false}
        expandable={true}
        onEdit={null}
        onDelete={null}
        defaultExpanded={true}
        onSelect={onCommoditySelect}
        parentOrderType={OrderTypes.Purchase}
        limit={limit}
        onLimitChanged={onLimitChanged}
        offset={offset}
        onPageChanged={onPageChanged}
        sort={sort}
        onSort={setSort}
        filter={filter}
        onFilter={setFilter}
        setOrder={setParcelShipment}
        setIsVerifiedAll={setIsVerifiedAll}
        tableLayout="fixed"
        shouldRenderShipments={shouldRenderShipments}
      />
    </>
  );
};
