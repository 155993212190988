import React, { useEffect } from 'react';
import { onScannerResult } from '../scanner.store';

export const useScanner = (listener) => {
  useEffect(() => {
    const unwatch = onScannerResult.watch(listener);
    return () => {
      unwatch();
    };
  }, [listener]);
};
