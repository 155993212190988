import React from 'react';
import { FaBarcode } from 'react-icons/fa';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { USE_SCANNER_EMULATOR } from '../../../properties';
import { Button } from '../../common/components/button/button.component';
import { showDialog } from '../../common/dialog.store';
import { TakePhotoDialog } from '../../common/takePhoto/takePhoto.dialog';
import { connected, scan } from '../scanner.manager';
import { SCAN_EVENT } from '../scanner.mock';
import { FormikProps, FormikValues, useFormikContext } from 'formik';

export const handleAutoSubmitBarcodeScanning = (
  context: FormikProps<FormikValues>,
  scannedValue: any,
  autoSubmit?: any,
) => {
  if (autoSubmit) {
    const data = context.values;
    data.trackingNumber = scannedValue;
    autoSubmit(data);
  }
};

export const ScanButton = ({
  name,
  disabled,
  onChange,
  setFieldValue,
  autoSubmit = null,
  barcodeButtonClassName = '',
}) => {
  const formikContext = useFormikContext<any>();

  const onScanButtonClicked = async () => {
    if (USE_SCANNER_EMULATOR === 'true' && !connected()) {
      const result = await scan(SCAN_EVENT.data, 'input');
      onChange(result.data);
      setFieldValue(name, result.data);
      handleAutoSubmitBarcodeScanning(formikContext, result.data, autoSubmit);
    } else {
      const result = await scan();
      onChange(result.data);
      setFieldValue(name, result.data);
      handleAutoSubmitBarcodeScanning(formikContext, result.data, autoSubmit);
    }
  };
  const takePhoto = async () => {
    const imageUrl = await showDialog({
      dialog: TakePhotoDialog,
      props: { title: 'Take a Photo', className: 'delete-modal' },
    });
    if (!('BarcodeDetector' in window)) {
      console.error('Barcode Detector is not supported by this browser.');
    } else {
      window.BarcodeDetector.getSupportedFormats().then((supportedFormats) => {
        const barcodeDetector = new window.BarcodeDetector({
          formats: supportedFormats,
        });
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          barcodeDetector
            .detect(image)
            .then((barcodes) => {
              if (barcodes.length) {
                const firstBarcode = barcodes[0];
                onChange(firstBarcode.rawValue);
                setFieldValue(name, firstBarcode.rawValue);
                scan(firstBarcode.rawValue, 'input').then(() =>
                  handleAutoSubmitBarcodeScanning(
                    formikContext,
                    firstBarcode.rawValue,
                    autoSubmit,
                  ),
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
        };
      });
    }
  };
  return (
    <div className="d-flex">
      <Button
        color={'secondary'}
        disabled={disabled}
        onClick={onScanButtonClicked}
        outline
        className={'input-append radius-0'}
      >
        <div className={'d-flex align-items-center'}>
          <FaBarcode className={'mr-2'} />
          Scan
        </div>
      </Button>
      <Button
        color={'secondary'}
        disabled={disabled}
        onClick={takePhoto}
        outline
        className={
          barcodeButtonClassName
            ? `input-append ${barcodeButtonClassName}`
            : 'input-append'
        }
      >
        <MdOutlinePhotoCamera />
      </Button>
    </div>
  );
};
