import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const CustomFieldForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {children}
    </Form>
  );
};

CustomFieldForm.Category = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'category'}
      label={'Category'}
      placeholder={'Category'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CustomFieldForm.CustomFieldEntityType = ({
  onChange,
  options,
  name = 'customFieldEntityType',
  id = 'customFieldEntityType',
  placeholder = 'Select Entity',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
CustomFieldForm.CustomFieldType = ({
  onChange,
  options,
  name = 'customFieldType',
  id = 'customFieldType',
  placeholder = 'Select Type',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
CustomFieldForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CustomFieldForm.DisplayName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'displayName'}
      label={'Display Name'}
      placeholder={'Display Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CustomFieldForm.FieldConfig = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'fieldConfig'}
      label={'Field Config'}
      placeholder={'Field Config'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CustomFieldForm.InternalName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'internalName'}
      label={'Internal Name'}
      placeholder={'Internal Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CustomFieldForm.IsInactive = ({
  className,
  defaultValue,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

CustomFieldForm.Options = ({
  defaultValue,
  onChange,
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'fieldConfig.options'}
      label={'Options'}
      placeholder={'Options'}
      required={false}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      rows={8}
      onChange={onChange}
      valueInput={defaultValue}
    />
  );
};
