import React from 'react';
import { CommodityDto } from '../../../../models/data.models';
import { Button } from '../../../common/components/button/button.component';
import { showDialog } from '../../../common/dialog.store';
import { CommodityDialog } from '../../../commodities/components/commodity.dialog';
import { authStore } from '../../../auth/auth.store';

export type EntitiesFragmentProps = {
  commodityIds: number[];
  setCommodityIds: (commdodityIds: number[]) => void;
  container?: CommodityDto | null;
  setContainer: (container: CommodityDto) => void;
  isSending: boolean;
  handleNext: () => void;
  handleBack: () => void;
  needRepack: boolean;
  setNeedRepack: (needRepack: boolean) => void;
};

export const RepackFragment = ({
  isSending,
  handleNext,
  handleBack,
  container,
  setContainer,
  needRepack,
  setNeedRepack,
}: EntitiesFragmentProps) => {
  const { user: currentUser } = authStore.getState();
  function openRepackModal() {
    showDialog({
      dialog: CommodityDialog,
      props: {
        title: 'Repacking',
        commodity: container ?? {},
        commodityId: 0,
        className: 'commodity-modal',
        saveButtonRenderCondition:
          currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
        isEditMode: false,
        saveBtnLabel: 'Create Container',
      },
    }).then(
      (result) => {
        setContainer({
          ...result,
        });
      },
      () => {},
    );
  }

  return (
    <div className="p-5">
      <div className="row mb-3">
        <div className="col-7">
          <h2>Repacking</h2>
        </div>
      </div>
      <div>
        <input
          className={'pointer'}
          type={'checkbox'}
          name={'needRepack'}
          id={'needRepack'}
          checked={needRepack}
          onChange={() => {
            setNeedRepack(!needRepack);
          }}
          data-testid={'need-repack-checkbox'}
        />
        <label className={'ml-3 pointer no-select'} htmlFor={'needRepack'}>
          Yes, I would like to repack the cargo.
        </label>
      </div>
      <div className={'mt-5'}>
        <Button
          color={'primary'}
          disabled={!needRepack}
          onClick={openRepackModal}
          data-testid={'repack-button'}
        >
          Repack
        </Button>

        {needRepack && container && (
          <div className={'mt-3'}>
            <h3 className={'py-2'} data-testid={'container-package-type'}>
              Container Type: {container?.packageTypeName ?? ''}
            </h3>
            <h3 className={'py-2'} data-testid={'container-description'}>
              Description: {container?.description ?? ''}
            </h3>
          </div>
        )}
      </div>
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button
          className="mr-4"
          color="primary"
          onClick={handleNext}
          style={{ minWidth: '8.5rem' }}
        >
          Next
        </Button>
        <Button
          className="mr-3"
          onClick={handleBack}
          style={{ minWidth: '8.5rem' }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};
