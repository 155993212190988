import { useContext, useEffect, useState } from 'react';
import { Field, FormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { BsCalendar3 } from 'react-icons/bs';
import { get, isArray } from 'lodash';

export const RangeDatePicker = ({
  defaultValue,
  id,
  name,
  required,
  size = 'md',
  onChange,
  placeholder,
  readonly,
  disabled,
  clearMessages,
  header,
  componentProps,
}) => {
  const formikContext = useContext(FormikContext);

  const [dateRange, setDateRange] = useState([
    defaultValue?.[0] ? new Date(defaultValue?.[0]) : null,
    defaultValue?.[1] ? new Date(defaultValue?.[1]) : null,
  ]);

  useEffect(() => {
    if (!defaultValue && dateRange) {
      setDateRange(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (formikContext?.values) {
      let value = get(formikContext.values, id);

      if (isArray(value) && value.length === 2) {
        setDateRange([
          value[0] ? new Date(value[0]) : value[0],
          value[1] ? new Date(value[1]) : value[1],
        ]);
      }
    }
  }, [formikContext?.values]);

  const startDate = dateRange?.[0];
  const endDate = dateRange?.[1];

  const onChangeHadler = (date: any) => {
    setDateRange(date);
    if (isArray(date) && date.length === 2) {
      if (date.every((x) => !!x)) {
        formikContext.setFieldValue(id, date);
        try {
          if (typeof onChange === 'function') onChange(date);
        } catch (error) {
          // V2 error here
        }
      } else if (date.every((x) => x === null)) {
        formikContext.setFieldValue(id, date);
      }
    }
  };

  return (
    <div>
      {header && (
        <label className="input-label" htmlFor={name}>
          <span className="d-flex justify-content-between align-items-center">
            <span className="input-label-primary">{header}</span>
          </span>
        </label>
      )}
      <Field required={required} type="hidden" name={id} />
      <div className="d-flex align-items-center position-relative">
        <DatePicker
          className={`form-control form-control-${size} ${name}`}
          selectsRange={true}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeHadler}
          onCalendarClose={() => formikContext.setFieldTouched(id, true, true)}
          placeholderText={placeholder}
          readonly={readonly}
          disabled={disabled}
          required={required}
          onFocus={clearMessages}
          id={name}
          shouldCloseOnSelect={true}
          autoComplete={'off'}
          selectsDisabledDaysInRange
          isClearable={componentProps?.props?.isClearable ?? true}
        />
        <label
          htmlFor={name}
          className="label-datepicker calendar-icon position-absolute"
        >
          <BsCalendar3 />
        </label>
      </div>
    </div>
  );
};
