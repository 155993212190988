import React, { useEffect } from 'react';
import StickyBox from 'react-sticky-box';
import { TopBar } from '../top-bar/top-bar.component';
import { useStore } from 'effector-react';
import { authStore, logout } from '../../../auth/auth.store';
import {
  organizationsStore,
  setCurrentOrganization,
} from '../../../organization/organization.store';
import { CountryMenu } from '../../../countries/components/country.menu';
import { PortMenu } from '../../../ports/components/port.menu';
import { DivisionMenu } from '../../../divisions/components/division.menu';
import { OrderMenu } from '../../../orders/components/order.menu';
import { PackageTypeMenu } from '../../../packageTypes/components/packageType.menu';
import { PackageCategoryMenu } from '../../../packageCategories/components/packageCategory.menu';
import { CustomersMenu } from '../../../customers/components/customer.menu';
import { CarrierMenu } from '../../../carriers/components/carrier.menu';
import { VendorMenu } from '../../../vendor/components/vendor.menu';
import { ContactMenu } from '../../../contacts/components/contact.menu';
import { DriverMenu } from '../../../drivers/components/driver.menu';
import { SalesPersonMenu } from '../../../salesPersons/components/salesPerson.menu';
import { EmployeeMenu } from '../../../employees/components/employee.menu';
import {
  navigationStore,
  setCurrentPagePath,
  toggleMenu,
} from '../../nav.store';
import { ForwardingAgentMenu } from '../../../forwardingAgent/components/forwardingAgent.menu';
import { AccountingAccountMenu } from '../../../accountingAccounts/components/accountingAccount.menu';
import { SalesTaxesMenu } from '../../../salesTaxes/components/salesTaxes.menu';
import { StatesMenu } from '../../../states/components/state.menu';
import { AccountingItemMenu } from '../../../accountingItems/components/accountingItem.menu';
import { CurrenciesMenu } from '../../../currencies/components/currencies.menu';
import { PaymentTermMenu } from '../../../paymentTerms/components/paymentTerm.menu';
import { InvoiceMenu } from '../../../invoice/components/invoice.menu';
import { BillMenu } from '../../../bill/components/bill.menu';
import { CommodityMenu } from '../../../commodities/components/commodity.menu';
import { ContainerTypeMenu } from '../../../containerTypes/components/containerType.menu';
import { ContainerDescriptionMenu } from '../../../containerDescriptions/components/containerDescription.menu';
import { ModeOfTransportationMenu } from '../../../modeOfTransportations/components/modeOfTransportation.menu';
import {
  faCog,
  faPollH,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { CarrierPayManagerMenu } from '../../../carrierPayManager/components/carrierPayManager.menu';
import { InvoiceManagerMenu } from '../../../invoiceManager/components/invoiceManager.menu';
import { DriverPayManagerMenu } from '../../../driverPayManager/components/driverPayManager.menu';
import { EquipmentTypeMenu } from '../../../equipmentTypes/components/equipmentType.menu';
import { FactoringCompanyMenu } from '../../../factoringCompanies/components/factoringCompany.menu';
import { OrganizationMenu } from '../../../organization/components/organization.menu';
import { DocumentTemplateMenu } from '../../../documentTemplates/components/documentTemplate.menu';
import { CustomFieldMenu } from '../../../customFields/components/customFields.menu';
import { UsersMenu } from '../../../users/components/user.menu';
import { ActionsGroupComponent } from '../actions-group/actions-group.component';
import { GET_USERS_LINK_KEY } from '../../../users/users.service';
import { GET_CUSTOMFIELDS_LINK_KEY } from '../../../customFields/customFields.service';
import { GET_DOCUMENTTEMPLATES_LINK_KEY } from '../../../documentTemplates/documentTemplates.service';
import { GET_MODEOFTRANSPORTATIONS_LINK_KEY } from '../../../modeOfTransportations/modeOfTransportations.service';
import { GET_CURRENCIES_LINK_KEY } from '../../../currencies/currencies.service';
import { GET_ORGANIZATION_LINK_KEY } from '../../../organization/organization.service';
import { GET_ACCOUNTINGACCOUNTS_LINK_KEY } from '../../../accountingAccounts/accountingAccounts.service';
import { GET_SALESTAXES_LINK_KEY } from '../../../salesTaxes/salesTaxes.service';
import { GET_ACCOUNTINGITEMS_LINK_KEY } from '../../../accountingItems/accountingItems.service';
import { GET_PAYMENTTERMS_LINK_KEY } from '../../../paymentTerms/paymentTerms.service';
import { GET_ACCOUNTINGTRANSACTIONS_LINK_KEY } from '../../../accountingTransactions/accountingTransactions.service';
import { GET_CARRIER_PAYS_LINK_KEY } from '../../../carrierPayManager/carrierPayManager.service';
import { GET_UNINVOICED_ORDERS_LINK_KEY } from '../../../invoiceManager/invoiceManager.service';
import { GET_DRIVER_PAYS_LINK_KEY } from '../../../driverPayManager/driverPayManager.service';
import { GET_DIVISIONS_LINK_KEY } from '../../../divisions/divisions.service';
import { GET_COUNTRIES_LINK_KEY } from '../../../countries/countries.service';
import { GET_STATES_LINK_KEY } from '../../../states/states.service';
import { GET_PACKAGETYPES_LINK_KEY } from '../../../packageTypes/packageTypes.service';
import { GET_PACKAGECATEGORIES_LINK_KEY } from '../../../packageCategories/packageCategories.service';
import { GET_PORTS_LINK_KEY } from '../../../ports/ports.service';
import { GET_CONTACTS_LINK_KEY } from '../../../contacts/contacts.service';
import { GET_COMMODITIES_LINK_KEY } from '../../../commodities/commodities.service';
import { GET_CONTAINERTYPES_LINK_KEY } from '../../../containerTypes/containerTypes.service';
import { GET_CONTAINERDESCRIPTIONS_LINK_KEY } from '../../../containerDescriptions/containerDescriptions.service';
import { GET_EQUIPMENTTYPES_LINK_KEY } from '../../../equipmentTypes/equipmentType.service';
import { GET_USERGROUPS_LINK_KEY } from '../../../userGroups/userGroups.service';
import { UserGroupMenu } from '../../../userGroups/components/userGroup.menu';
import { PaymentMenu } from '../../../payments/components/payment.menu';
import { GET_PAYMENTS_LINK_KEY } from '../../../payments/payments.service';
import { EventDefinitionsMenu } from '../../../eventDefinitions/components/eventDefinitions.menu';
import { GET_EVENTDEFINITIONS_LINK_KEY } from '../../../eventDefinitions/eventDefinitions.service';
import { LeadsMenu } from '../../../leads/components/lead.menu';
import { QuoteMenu } from '../../../quotes/components/quote.menu';
import { WarehouseLocationMenu } from '../../../warehouseLocations/components/warehouseLocation.menu';
import { WarehouseZoneMenu } from '../../../warehouseZones/components/warehouseZone.menu';
import { WarehouseInspectorMenu } from '../../../warehouseInspector/components/warehouseInspector.menu';
import { PurchaseMenu } from '../../../purchases/components/purchase.menu';
import { CommodityTypesMenu } from '../../../commodityTypes/components/commodityType.menu';
import { ClauseMenu } from '../../../clauses/components/clause.menu';
import { WarehouseReceiptMenu } from '../../../warehouseReceipts/components/warehouseReceipt.menu';
import { AirShipmentOrderMenu } from '../../../airShipmentOrders/components/airShipmentOrder.menu';
import { ParcelShipmentMenu } from '../../../parcelShipments/components/parcelShipment.menu';
import { DashboardMenu } from '../../../dashboard/components/dashboard.menu';
import { CustomCodeMenu } from '../../../customCodes/components/customCode.menu';
import { GET_CUSTOMCODES_LINK_KEY } from '../../../customCodes/customCodes.service';
import { VesselMenu } from '../../../vessels/components/vessel.menu';
import { RateMenu } from '../../../rates/components/rate.menu';
import UserPic from '../../../../img/photo-none.jpg';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { GET_ORDERS_LINK_KEY } from '../../../orders/orders.service';
import { HiOutlineMenu } from 'react-icons/hi';
import { ClientRateMenu } from '../../../rates/components/clientRate.menu';
import { CarrierRateMenu } from '../../../rates/components/carrierRate.menu';
import { GET_RATES_LINK_KEY } from '../../../rates/rates.service';
import { OceanShipmentOrderMenu } from '../../../oceanShipment/components/oceanShipmentOrder.menu';
import { GET_BUSINESSRULES_LINK_KEY } from '../../../businessRules/businessRules.service';
import { BusinessRuleMenu } from '../../../businessRules/components/businessRule.menu';
import { GET_ORDERSTATUSES_LINK_KEY } from '../../../orderStatuses/orderStatuses.service';
import { OrderStatusMenu } from '../../../orderStatuses/components/orderStatus.menu';
import { CargoMovementMenu } from '../../../cargoMovement/components/cargoMovement.menu';
import { ContactStatusMenu } from '../../../contactStatuses/components/contactStatus.menu';
import { GET_CONTACTSTATUSES_LINK_KEY } from '../../../contactStatuses/contactStatuses.service';
import { GET_COMMODITYSTATUSES_LINK_KEY } from '../../../commodityStatuses/commodityStatuses.service';
import { CommodityStatusMenu } from '../../../commodityStatuses/components/commodityStatus.menu';
import { CitiesMenu } from '../../../cities/components/city.menu';
import { GET_CITIES_LINK_KEY } from '../../../cities/cities.service';
import { ScannerButton } from '../../../barcodeScanner/components/scanner-button.component';
import md5 from 'md5';
import { ReceivingMenu } from '../../../warehouseReceipts/packageReceiveWizard/receiving.menu';
import { PackingMenu } from '../../../parcelShipments/packingWizard/packing.menu';
import { OrganizationConfigMenu } from '../../../organizationConfigs/components/organizationConfig.menu';
import { DiscountMenu } from '../../../discounts/components/discounts.menu';
import { GET_DISCOUNTS_LINK_KEY } from '../../../discounts/discounts.service';
import { WorkflowMenu } from '../../../workflows/components/workflow.menu';
import { ActionEventMenu } from '../../../actionEvents/components/actionEvent.menu';
import { JobStatusMenu } from '../../../jobStatuses/components/jobStatus.menu';
import { JobMenu } from '../../../jobs/components/job.menu';
import { AppModuleMenu } from '../../../appModules/components/appModule.menu';
import { AppComponent } from '../../screens/appComponent.component';
import { V2Menu } from '../layout/components/v2.menu';

export type InternalLayoutProps = {
  children: any;
  title: string;
  group?: string;
  createPath?: string;
  createAction?: () => void;
  createLabel?: string;
  addPath?: string;
  addPathLabel?: string | null;
  toolbar?: JSX.Element;
  version?: string;
  variables?: any;
};
export const InternalLayout = ({
  children,
  title,
  group,
  createPath,
  createAction = null,
  createLabel = 'Item',
  addPath = null,
  addPathLabel = null,
  toolbar,
  version,
  variables,
}: InternalLayoutProps) => {
  const { user } = useStore(authStore);
  const { organizations, currentOrganization } = useStore(organizationsStore);
  const navStore = useStore(navigationStore);
  const navigate = useNavigate();
  const goToPage = (path: string) => {
    navigate(path);
  };
  const onLogoutClicked = () => {
    if (logout) {
      logout();
    }
    goToPage('/login');
  };

  useEffect(() => {
    setCurrentPagePath(group ? [group, title] : [title]);
  }, [title]);

  const getGravatar = (email) => {
    if (!email) {
      return null;
    }

    const hash = md5(email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?s=200&d=identicon`;
  };

  const getGravatarUpdateLink = (email) => {
    if (!email) {
      return null;
    }
    return `https://www.gravatar.com/${md5(email.trim().toLowerCase())}`;
  };

  return (
    <div className=" d-flex flex-column w-100 nav-background">
      {version !== 'v2' && (
        <div className={`drawer px-5 `}>
          <StickyBox offsetTop={0} offsetBottom={20}>
            <div className="mud-drawer-content container">
              <nav className="d-flex align-items-center px-5">
                <div className="d-flex justify-center ">
                  <a
                    className={'link'}
                    onClick={() =>
                      goToPage(`/org/${currentOrganization.organizationId}/`)
                    }
                  >
                    <img className="logo-TrtExpress-color" alt="TRT Express" />
                  </a>
                </div>
                <button
                  className={'menu-toggle d-none'}
                  onClick={() => toggleMenu()}
                >
                  <HiOutlineMenu color={'white'} size={34} />
                </button>
                <ul
                  className={`TrtExpress-navmenu d-flex ${
                    navStore.menuOpen ? '' : 'collapse'
                  }`}
                >
                  <DashboardMenu className="nav-item nav-ripple d-none" />
                  {user?.roles.includes('Receiving') && (
                    <ActionsGroupComponent
                      actions={[
                        GET_ORDERS_LINK_KEY,
                        GET_ORDERS_LINK_KEY,
                        GET_CONTACTS_LINK_KEY,
                        GET_ORDERS_LINK_KEY,
                      ]}
                      groupName={'operations'}
                      icon={faPollH}
                      displayGroupName={'Operations'}
                    >
                      <div className={'d-flex'}>
                        <div
                          className={'d-flex flex-column justify-content-start'}
                        >
                          <ReceivingMenu className="nav-item nav-ripple" />
                          <WarehouseReceiptMenu className="nav-item nav-ripple" />
                        </div>
                      </div>
                    </ActionsGroupComponent>
                  )}

                  {!user?.roles.includes('Receiving') && (
                    <>
                      <ActionsGroupComponent
                        actions={[
                          GET_ORDERS_LINK_KEY,
                          GET_ORDERS_LINK_KEY,
                          GET_CONTACTS_LINK_KEY,
                          GET_ORDERS_LINK_KEY,
                        ]}
                        groupName={'operations'}
                        icon={faPollH}
                        displayGroupName={'Operations'}
                      >
                        <div className={'d-flex'}>
                          <div
                            className={
                              'd-flex flex-column justify-content-start'
                            }
                          >
                            <JobMenu className="nav-item nav-ripple" />
                            <PurchaseMenu className="nav-item nav-ripple" />
                            <ReceivingMenu className="nav-item nav-ripple" />
                            <PackingMenu className="nav-item nav-ripple" />
                            <WarehouseReceiptMenu className="nav-item nav-ripple" />
                            <CargoMovementMenu className="nav-item nav-ripple" />
                            <ParcelShipmentMenu className="nav-item nav-ripple" />
                            <OrderMenu className="nav-item nav-ripple" />
                            <CommodityMenu className="nav-item nav-ripple" />
                          </div>
                        </div>
                      </ActionsGroupComponent>
                      <ActionsGroupComponent
                        actions={[GET_ORDERS_LINK_KEY, GET_CONTACTS_LINK_KEY]}
                        groupName={'sales'}
                        icon={faPollH}
                        displayGroupName={'Sales'}
                      >
                        <div className={'d-flex'}>
                          <div
                            className={
                              'd-flex flex-column justify-content-start'
                            }
                          >
                            <QuoteMenu className="nav-item nav-ripple" />
                            <LeadsMenu className="nav-item nav-ripple" />
                          </div>
                        </div>
                      </ActionsGroupComponent>
                      <ActionsGroupComponent
                        actions={[GET_ORDERS_LINK_KEY, GET_ORDERS_LINK_KEY]}
                        groupName={'shipment'}
                        icon={faPollH}
                        displayGroupName={'Shipment'}
                      >
                        <div className={'d-flex'}>
                          <div
                            className={
                              'd-flex flex-column justify-content-start'
                            }
                          >
                            <AirShipmentOrderMenu className="nav-item nav-ripple" />
                            <OceanShipmentOrderMenu className="nav-item nav-ripple" />
                          </div>
                        </div>
                      </ActionsGroupComponent>
                      <ActionsGroupComponent
                        actions={[
                          GET_COUNTRIES_LINK_KEY,
                          GET_STATES_LINK_KEY,
                          GET_CITIES_LINK_KEY,
                          GET_PORTS_LINK_KEY,
                          GET_DIVISIONS_LINK_KEY,
                          GET_PACKAGETYPES_LINK_KEY,
                          GET_PACKAGECATEGORIES_LINK_KEY,
                          GET_CONTACTS_LINK_KEY,
                          GET_COMMODITIES_LINK_KEY,
                          GET_CONTAINERTYPES_LINK_KEY,
                          GET_CONTAINERDESCRIPTIONS_LINK_KEY,
                          GET_EQUIPMENTTYPES_LINK_KEY,
                          GET_CUSTOMCODES_LINK_KEY,
                        ]}
                        groupName={'maintenance'}
                        icon={faPollH}
                        displayGroupName={'Maintenance'}
                      >
                        <div className={'d-flex'}>
                          <div
                            className={
                              'd-flex flex-column justify-content-start'
                            }
                          >
                            <CountryMenu className="nav-item nav-ripple" />
                            <StatesMenu className="nav-item nav-ripple" />
                            <CitiesMenu className="nav-item nav-ripple" />
                            <PortMenu className="nav-item nav-ripple" />
                            <DivisionMenu className="nav-item nav-ripple" />
                            <VesselMenu className="nav-item nav-ripple" />
                          </div>
                          <div className={'d-flex flex-column'}>
                            <CustomersMenu className="nav-item nav-ripple" />
                            <CarrierMenu className="nav-item nav-ripple" />
                            <VendorMenu className="nav-item nav-ripple" />
                            <ContactMenu className="nav-item nav-ripple" />
                          </div>
                          <div className={'d-flex flex-column'}>
                            <SalesPersonMenu className="nav-item nav-ripple" />
                            <FactoringCompanyMenu className="nav-item nav-ripple" />
                            <DriverMenu className="nav-item nav-ripple" />
                            <EmployeeMenu className="nav-item nav-ripple" />
                            <ForwardingAgentMenu className="nav-item nav-ripple" />
                          </div>
                          <div className={'d-flex flex-column'}>
                            <ContainerTypeMenu className="nav-item nav-ripple" />
                            <ContainerDescriptionMenu className="nav-item nav-ripple" />
                            <WarehouseLocationMenu className="nav-item nav-ripple" />
                          </div>
                        </div>
                      </ActionsGroupComponent>
                      {!user?.roles.includes('OrgUser') && (
                        <ActionsGroupComponent
                          actions={[
                            GET_ACCOUNTINGACCOUNTS_LINK_KEY,
                            GET_SALESTAXES_LINK_KEY,
                            GET_ACCOUNTINGITEMS_LINK_KEY,
                            GET_PAYMENTTERMS_LINK_KEY,
                            GET_ACCOUNTINGTRANSACTIONS_LINK_KEY,
                            GET_CARRIER_PAYS_LINK_KEY,
                            GET_UNINVOICED_ORDERS_LINK_KEY,
                            GET_DRIVER_PAYS_LINK_KEY,
                            GET_PAYMENTS_LINK_KEY,
                            GET_RATES_LINK_KEY,
                            GET_DISCOUNTS_LINK_KEY,
                          ]}
                          groupName={'accounting'}
                          icon={faUserCircle}
                          displayGroupName={'Accounting'}
                        >
                          <>
                            <div className={'d-flex'}>
                              <div className={'d-flex flex-column'}>
                                <AccountingAccountMenu className="nav-item nav-ripple" />
                                <SalesTaxesMenu className="nav-item nav-ripple" />
                                <AccountingItemMenu className="nav-item nav-ripple" />
                                <DiscountMenu className="nav-item nav-ripple" />
                              </div>
                              <div className={'d-flex flex-column'}>
                                <PaymentTermMenu className="nav-item nav-ripple" />
                                <InvoiceMenu className="nav-item nav-ripple" />
                                <BillMenu className="nav-item nav-ripple" />
                                <CarrierPayManagerMenu className="nav-item nav-ripple" />
                              </div>
                              <div className={'d-flex flex-column'}>
                                <InvoiceManagerMenu className="nav-item nav-ripple" />
                                <DriverPayManagerMenu className="nav-item nav-ripple" />
                                <PaymentMenu className="nav-item nav-ripple" />
                              </div>
                              <div className={'d-flex flex-column'}>
                                <RateMenu className="nav-item nav-ripple" />
                                <ClientRateMenu className="nav-item nav-ripple" />
                                <CarrierRateMenu className="nav-item nav-ripple" />
                              </div>
                            </div>
                          </>
                        </ActionsGroupComponent>
                      )}
                      {!user?.roles.includes('SysAdmin') && (
                        <ActionsGroupComponent
                          actions={[
                            GET_CUSTOMFIELDS_LINK_KEY,
                            GET_DOCUMENTTEMPLATES_LINK_KEY,
                            GET_MODEOFTRANSPORTATIONS_LINK_KEY,
                            GET_CURRENCIES_LINK_KEY,
                            GET_ORGANIZATION_LINK_KEY,
                            GET_USERGROUPS_LINK_KEY,
                            GET_EVENTDEFINITIONS_LINK_KEY,
                            GET_BUSINESSRULES_LINK_KEY,
                            GET_ORDERSTATUSES_LINK_KEY,
                            GET_CONTACTSTATUSES_LINK_KEY,
                            GET_COMMODITYSTATUSES_LINK_KEY,
                          ]}
                          groupName={'systemConfiguration'}
                          icon={faCog}
                          displayGroupName={'Configuration'}
                        >
                          <>
                            <div className={'d-flex'}>
                              <div className={'d-flex flex-column'}>
                                <DocumentTemplateMenu className="nav-item nav-ripple" />
                                <ModeOfTransportationMenu className="nav-item nav-ripple" />
                                <CurrenciesMenu className="nav-item nav-ripple" />
                                <OrganizationMenu className="nav-item nav-ripple" />
                                <UserGroupMenu className="nav-item nav-ripple" />
                                <BusinessRuleMenu className="nav-item nav-ripple" />
                                <WorkflowMenu className="nav-item nav-ripple" />
                                <AppModuleMenu className="nav-item nav-ripple" />
                                <OrganizationConfigMenu className="nav-item nav-ripple" />
                              </div>
                              <div className={'d-flex flex-column'}>
                                <ClauseMenu className="nav-item nav-ripple" />
                                <CustomCodeMenu className="nav-item nav-ripple" />
                                <EquipmentTypeMenu className="nav-item nav-ripple" />
                                <WarehouseZoneMenu className="nav-item nav-ripple" />
                                <CommodityTypesMenu className="nav-item nav-ripple" />
                                <OrderStatusMenu className="nav-item nav-ripple" />
                              </div>
                              <div className={'d-flex flex-column'}>
                                <PackageTypeMenu className="nav-item nav-ripple" />
                                <PackageCategoryMenu className="nav-item nav-ripple" />
                                <ActionEventMenu className="nav-item nav-ripple" />
                                {!user?.isInOrgUserRole && (
                                  <CustomFieldMenu className="nav-item nav-ripple" />
                                )}
                                {!user?.isInOrgUserRole && (
                                  <EventDefinitionsMenu className="nav-item nav-ripple" />
                                )}
                                <ContactStatusMenu className="nav-item nav-ripple" />
                                <CommodityStatusMenu className="nav-item nav-ripple" />
                                <JobStatusMenu className="nav-item nav-ripple" />
                              </div>
                            </div>
                          </>
                        </ActionsGroupComponent>
                      )}
                      <ActionsGroupComponent
                        actions={[GET_USERS_LINK_KEY]}
                        groupName={'systemAdministration'}
                        icon={faCog}
                        displayGroupName={'Administration'}
                      >
                        <>
                          <UsersMenu className="nav-item nav-ripple" />
                          <V2Menu className="nav-item nav-ripple" />
                        </>
                      </ActionsGroupComponent>
                    </>
                  )}
                </ul>

                <div className="d-flex ml-auto">
                  <div className="d-flex">
                    <div className="mx-5">
                      <ScannerButton />
                    </div>
                    <div className="dropdown">
                      <img
                        alt="User Picture"
                        className="top-bar-img-user dropdown-toggle pointer"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-toggle="dropdown"
                        src={
                          user.avatarUrl || getGravatar(user.email) || UserPic
                        }
                        data-cy="user-menu"
                      />
                      {user ? (
                        <div
                          className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="mb-4 dropdown-item">
                            <h3>
                              {user.firstName} {user.lastName}
                            </h3>
                            <small className="text-info">{user.userName}</small>
                          </div>
                          <div className="dropdown-item">
                            <Link
                              data-cy="link-edit-profile"
                              to={generatePath(`/users/${user.userId}`)}
                            >
                              Edit Profile
                            </Link>
                          </div>
                          <div className="dropdown-item">
                            <Link
                              data-cy="link-create-organization"
                              to={generatePath('/createOrganization')}
                            >
                              Create Organization
                            </Link>
                          </div>
                          <div className="dropdown-item">
                            <a
                              className="pointer"
                              href={getGravatarUpdateLink(user.email)}
                              target="_blank"
                            >
                              Update Gravatar
                            </a>
                          </div>
                          <div className="dropdown-item">
                            <a className="pointer" onClick={onLogoutClicked}>
                              Logout
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </StickyBox>
        </div>
      )}
      {version === 'v2' && (
        <>
          <AppComponent component="System/Scripts" variables={variables} />
          <AppComponent component="System/Menu" variables={variables} />
        </>
      )}

      <div className="flex-grow-1">
        <div style={{ backgroundColor: 'white' }}>
          <header className={'container pb-2 mb-3'}>
            <TopBar
              user={user}
              organizations={organizations}
              currentOrganization={currentOrganization}
              createPath={createPath}
              createAction={createAction}
              createLabel={createLabel}
              addPath={addPath}
              addPathLabel={addPathLabel}
              title={title}
              goToPage={goToPage}
              organizationSetCurrent={setCurrentOrganization}
              logout={logout}
              toolbar={toolbar}
            />
          </header>
        </div>
        <div className="container" style={{ height: 'calc(100vh - 68px)' }}>
          {children}
        </div>
      </div>
    </div>
  );
};
