import {
  PackageCategoryDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  PackageCategoryDtoPagedResult,
  CreatePackageCategoryCommandValues,
  UpdatePackageCategoryCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_PACKAGECATEGORIES_LINK_KEY = 'get-packagecategories';
export const CREATE_PACKAGECATEGORY_LINK_KEY = 'create-packagecategory';
export const GET_PACKAGECATEGORY_LINK_KEY = 'get-packagecategory';
export const UPDATE_PACKAGECATEGORY_LINK_KEY = 'update-packagecategory';
export const DELETE_PACKAGECATEGORY_LINK_KEY = 'delete-packagecategory';
export const IMPORT_PACKAGECATEGORIES_LINK_KEY = 'import-packagecategories';
export const EXPORT_PACKAGECATEGORIES_LINK_KEY = 'export-packagecategories';
export const PACKAGECATEGORY_ENTITY_NAME = 'Package Category';

export interface GetPackageCategoryParams {
  packageCategoryCode: string;
}

export interface ImportPackageCategoriesParams {
  organizationId: number;
}

export interface ExportPackageCategoriesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getPackageCategoryRequest = async (
  resource: LinkResourceBaseDto | null,
  { packageCategoryCode }: GetPackageCategoryParams,
): Promise<PackageCategoryDto> => {
  if (resource && packageCategoryCode) {
    const getPackageCategoryLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGECATEGORY_LINK_KEY,
    );
    if (getPackageCategoryLink) {
      const result = await execLink(getPackageCategoryLink, {
        packageCategoryCode,
      });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getPackageCategoriesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PackageCategoryDtoPagedResult> => {
  if (resource) {
    const getPackageCategoriesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGECATEGORIES_LINK_KEY,
    );
    if (getPackageCategoriesListLink) {
      const result = await execLink(getPackageCategoriesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_PACKAGECATEGORIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createPackageCategoryRequest = async (
  resource: LinkResourceBaseDto,
  packageCategory: CreatePackageCategoryCommandValues,
) => {
  const createPackageCategoryLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PACKAGECATEGORY_LINK_KEY,
  );
  if (createPackageCategoryLink) {
    const result = await execLink(createPackageCategoryLink, packageCategory);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updatePackageCategoryRequest = async (
  resource: LinkResourceBaseDto,
  packageCategory: UpdatePackageCategoryCommandValues,
) => {
  const updatePackageCategoryLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PACKAGECATEGORY_LINK_KEY,
  );
  if (updatePackageCategoryLink) {
    const result = await execLink(updatePackageCategoryLink, packageCategory);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deletePackageCategoryRequest = async (
  packageCategory: PackageCategoryDto,
) => {
  const deletePackageCategoryLink = packageCategory.links?.find(
    (x: LinkDto) => x.rel === DELETE_PACKAGECATEGORY_LINK_KEY,
  );
  if (deletePackageCategoryLink) {
    const result = await execLink(deletePackageCategoryLink, packageCategory);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importPackageCategoriesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportPackageCategoriesParams,
  file: File,
) => {
  const importPackageCategoriesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_PACKAGECATEGORIES_LINK_KEY,
  );
  if (importPackageCategoriesLink) {
    const result = await postFormData(importPackageCategoriesLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_PACKAGECATEGORIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportPackageCategoriesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportPackageCategoriesParams,
) => {
  const exportPackageCategoriesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_PACKAGECATEGORIES_LINK_KEY,
  );
  if (exportPackageCategoriesLink) {
    const result = await downloadLinkFile(exportPackageCategoriesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_PACKAGECATEGORIES_LINK_KEY} is not allowed or missing link action`,
  );
};
