import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  CURRENCY_CREATE_PATH,
  CURRENCIES_LIST_PATH,
} from '../currencies.route';
import { CurrencyEdit } from '../components/currency-edit.component';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_CURRENCY_LINK_KEY } from '../currencies.service';
import { userHas } from '../../auth/auth.store';

export type CurrencyScreenProps = { pageTitle: string; group: string };

export const CurrencyScreen = ({ pageTitle, group }: CurrencyScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, currencyId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + CURRENCY_CREATE_PATH, {
      organizationId,
    });
  }
  const onCurrencyCreated = () => {
    const path = generatePath(INTERNAL_PATH + CURRENCIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'currency-created',
      type: 'success',
      autoHide: true,
      message: 'Currency successfully created!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCurrencyUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CURRENCIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'currency-updated',
      type: 'success',
      autoHide: true,
      message: 'Currency successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CURRENCIES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };
  return (
    <InternalLayout
      group={group}
      title={'Currencies'}
      createPath={userHas(CREATE_CURRENCY_LINK_KEY) ? createPath() : null}
      createLabel={'Currency'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CurrencyEdit
        currencyId={currencyId}
        onCurrencyUpdated={onCurrencyUpdated}
        onCurrencyCreated={onCurrencyCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
