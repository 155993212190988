import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContactScreen } from './screens/contact.screen';
import { ContactsListScreen } from './screens/contact-list.screen';

export const CONTACTS_LIST_PATH = '/contacts';
export const CONTACTS_EDIT_PATH = '/contacts/:contactId';
export const CONTACTS_CREATE_PATH = '/contacts/0';
export const CONTACTS_TAB_EDIT_PATH_FOR_CUSTOMERS =
  '/customers/:customerId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_LEADS =
  '/leads/:leadId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS =
  '/carriers/:carrierId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_VENDORS =
  '/vendors/:vendorId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_FACTORING_COMPANIES =
  '/factoring-companies/:factoringCompanyId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_FORWARDING_AGENTS =
  '/forwarding-agents/:forwardingAgentId/contacts/:contactId';

export const ContactsRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ContactsListScreen pageTitle="Contacts" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Add New Contact" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Update Contact" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
