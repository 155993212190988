import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { OrderStatusEdit } from '../components/orderStatus-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  ORDERSTATUSES_LIST_PATH,
  ORDERSTATUSES_CREATE_PATH,
} from '../orderStatuses.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_ORDERSTATUS_LINK_KEY } from '../orderStatuses.service';
import { userHas } from '../../auth/auth.store';

export type OrderStatusScreenProps = { pageTitle: string; group: string };

export const OrderStatusScreen = ({
  pageTitle,
  group,
}: OrderStatusScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderStatusId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + ORDERSTATUSES_CREATE_PATH, {
    organizationId,
  });

  const onOrderStatusCreated = () => {
    const path = generatePath(INTERNAL_PATH + ORDERSTATUSES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'orderStatus-created',
      type: 'success',
      autoHide: true,
      message: 'Order status successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onOrderStatusUpdated = () => {
    const path = generatePath(INTERNAL_PATH + ORDERSTATUSES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'orderStatus-updated',
      type: 'success',
      autoHide: true,
      message: 'Order status successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + ORDERSTATUSES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Order Status'}
      createPath={userHas(CREATE_ORDERSTATUS_LINK_KEY) ? createPath : null}
      createLabel={'Status'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrderStatusEdit
        orderStatusId={orderStatusId === 'create' ? null : orderStatusId}
        onOrderStatusUpdated={onOrderStatusUpdated}
        onOrderStatusCreated={onOrderStatusCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
