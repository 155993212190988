import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  CONTAINER_DESCRIPTION_CREATE_PATH,
  CONTAINER_DESCRIPTIONS_LIST_PATH,
} from '../containerDescriptions.route';
import { ContainerDescriptionEdit } from '../components/containerDescription-edit.component';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_CONTAINERDESCRIPTION_LINK_KEY } from '../containerDescriptions.service';
import { userHas } from '../../auth/auth.store';

export const ContainerDescriptionScreen = ({ group }) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, containerDescriptionCode } = useParams();

  const createPath = generatePath(
    INTERNAL_PATH + CONTAINER_DESCRIPTION_CREATE_PATH,
    {
      organizationId,
    },
  );

  const onContainerDescriptionCreated = () => {
    const path = generatePath(
      INTERNAL_PATH + CONTAINER_DESCRIPTIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    const message: Message = {
      id: 'container-description-created',
      type: 'success',
      autoHide: true,
      message: 'Container Description successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onContainerDescriptionUpdated = () => {
    const path = generatePath(
      INTERNAL_PATH + CONTAINER_DESCRIPTIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    const message: Message = {
      id: 'container-description-updated',
      type: 'success',
      autoHide: true,
      message: 'Container Description successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const isCreateMode =
    !containerDescriptionCode || containerDescriptionCode === ' ';

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(
      INTERNAL_PATH + CONTAINER_DESCRIPTIONS_LIST_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={'Container Descriptions'}
      createPath={
        userHas(CREATE_CONTAINERDESCRIPTION_LINK_KEY) ? createPath : null
      }
      createLabel={'Description'}
    >
      {isCreateMode ? (
        <Helmet>
          <title>Add New Container Description</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Update Container Description</title>
        </Helmet>
      )}
      <ContainerDescriptionEdit
        containerDescriptionCode={containerDescriptionCode}
        onContainerDescriptionUpdated={onContainerDescriptionUpdated}
        onContainerDescriptionCreated={onContainerDescriptionCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
