import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PackageCategoryForm } from './packageCategory.form';
import { PackageCategoryDto } from '../../../models/data.models';
import {
  createPackageCategoryFx,
  getPackageCategoryFx,
  updatePackageCategoryFx,
} from '../packageCategories.store';
import { Panel } from '../../common/components/panel/panel.component';
import { PackageCategoryDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_PACKAGECATEGORY_LINK_KEY } from '../packageCategories.service';

export type PackageCategoryEditProps = {
  packageCategoryCode?: string | null;
  onPackageCategoryCreated?: (packageCategory: PackageCategoryDto) => void;
  onPackageCategoryUpdated?: (packageCategory: PackageCategoryDto) => void;
  onPackageCategoryLoaded?: (packageCategory: PackageCategoryDto) => void;
  onCancel?: () => void;
};

const initialState: PackageCategoryDto = {
  organizationId: 0,
  packageCategoryCode: PackageCategoryDefaultValues.packageCategoryCode,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  isContainer: PackageCategoryDefaultValues.isContainer,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: PackageCategoryDefaultValues.packageCategoryName,
  links: [],
};

const packageCategorySchema = Yup.object().shape({
  packageCategoryCode: Yup.string()
    .required("Can't be blank")
    .max(10, 'Max length is 10')
    .nullable(true),
  name: Yup.string().required("Can't be blank").nullable(true),
});

export const PackageCategoryEdit = ({
  packageCategoryCode,
  onPackageCategoryLoaded = () => {},
  onPackageCategoryCreated = () => {},
  onPackageCategoryUpdated = () => {},
  onCancel = () => {},
}: PackageCategoryEditProps) => {
  const isCreateMode = !packageCategoryCode || packageCategoryCode === '0';
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    packageCategory,
    setPackageCategory,
  ] = useState<PackageCategoryDto | null>(null);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (packageCategoryCode) {
      getPackageCategoryFx({ packageCategoryCode }).then(
        (packageCategoryDto: PackageCategoryDto) => {
          setPackageCategory(packageCategoryDto);
          setIsLoading(false);
          onPackageCategoryLoaded(packageCategoryDto);
        },
      );
    } else {
      throw new Error('PackageCategory keys were not provided');
    }
  }, [packageCategoryCode]);

  const onSubmit = (data: PackageCategoryDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createPackageCategoryFx(data)
        .then((result) => {
          onPackageCategoryCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updatePackageCategoryFx(data)
        .then((result) => {
          onPackageCategoryUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Panel className="m-3">
      <div className="row">
        <div className="col-4">
          <PackageCategoryForm
            id={'packageCategory-form'}
            initialValues={packageCategory || initialState}
            onSubmit={onSubmit}
            validationSchema={packageCategorySchema}
          >
            <PackageCategoryForm.PackageCategoryCode disabled={!isCreateMode} />
            <PackageCategoryForm.IsContainer
              defaultValue={packageCategory?.isContainer ?? false}
            />
            <PackageCategoryForm.Name />
          </PackageCategoryForm>
        </div>
      </div>
      <div className="justify-content-end row">
        {(userHas(UPDATE_PACKAGECATEGORY_LINK_KEY, packageCategory?.links) ||
          isCreateMode) && (
          <div className="col-4">
            <Button
              form={'packageCategory-form'}
              type="submit"
              color="primary"
              className="btn-block"
              disabled={isSending}
              isSending={isSending}
            >
              Save Package Category
            </Button>
          </div>
        )}
        <div className="col-3">
          <Button
            type="button"
            color="primary"
            onClick={onCancel}
            className="w-100 btn-secondary"
            disabled={isSending}
          >
            Close
          </Button>
        </div>
      </div>
    </Panel>
  );
};
