import {
  ContactStatusDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateContactStatusCommandValues,
  UpdateContactStatusCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContactStatusRequest,
  getContactStatusesListRequest,
  getContactStatusRequest,
  deleteContactStatusRequest,
  updateContactStatusRequest,
  importContactStatusesRequest,
  exportContactStatusesRequest,
  GetContactStatusParams,
} from './contactStatuses.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type ContactStatusesStoreState = {
  links: LinkDto[];
  contactStatusColumns: EntityFieldDto[];
  defaultContactStatusColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getContactStatusesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContactStatus',
  });
});

addEffectStatusHandling(getContactStatusesColumnsFx);

export const getContactStatusesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'ContactStatus',
  });
});

addEffectStatusHandling(getContactStatusesDefaultColumnsFx);

export const updateContactStatusesColumns = createEvent<EntityFieldDto[]>();

export const getContactStatusesFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContactStatusesListRequest(currentOrganization, params);
});

addEffectStatusHandling(getContactStatusesFx);

export const createContactStatusFx = createEffect(
  (contactStatusData: ContactStatusDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createContactStatusCommand: CreateContactStatusCommandValues = {
      ...contactStatusData,
    };

    return createContactStatusRequest(
      currentOrganization!,
      createContactStatusCommand,
    );
  },
);

addEffectStatusHandling(createContactStatusFx, {
  completeMessage: 'Contact status was created successfully',
  errorMessage: 'Contact status was not created',
});

export const getContactStatusFx = createEffect(
  (contactStatusParams: GetContactStatusParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContactStatusRequest(
      currentOrganization as LinkResourceBaseDto,
      contactStatusParams,
    );
  },
);

addEffectStatusHandling(getContactStatusFx);

export const updateContactStatusFx = createEffect(
  (contactStatus: ContactStatusDto) => {
    const updateContactStatusCommand: UpdateContactStatusCommandValues = {
      ...contactStatus,
    };
    return updateContactStatusRequest(
      contactStatus,
      updateContactStatusCommand,
    );
  },
);

addEffectStatusHandling(updateContactStatusFx, {
  completeMessage: 'Contact status was updated successfully',
  errorMessage: 'Contact status was not updated',
});

export const deleteContactStatusFx = createEffect(
  (contactStatus: ContactStatusDto) => {
    return deleteContactStatusRequest(contactStatus);
  },
);

addEffectStatusHandling(deleteContactStatusFx, {
  completeMessage: 'Contact status was deleted successfully',
  errorMessage: 'Contact status was not deleted',
});

export const exportContactStatusesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportContactStatusesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportContactStatusesFx, {
  completeMessage: 'Contact statuses were exported successfully',
  errorMessage: 'Contact statuses were not exported',
});

export const importContactStatusesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importContactStatusesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importContactStatusesFx, {
  completeMessage: 'Contact statuses were imported successfully',
  errorMessage: 'Contact statuses were not imported',
  inFlightMessage: 'Importing contact statuses...',
});

const defaultState: ContactStatusesStoreState = {
  links: [],
  contactStatusColumns: [],
  defaultContactStatusColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const contactStatusStore: Store<ContactStatusesStoreState> = createStore(
  defaultState,
)
  .on(getContactStatusesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      contactStatusColumns: payload.result.items,
      defaultContactStatusColumns: payload.result.items,
    };
  })
  .on(getContactStatusesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultContactStatusColumns: payload.result.items,
    };
  })
  .on(updateContactStatusesColumns, (state, payload) => {
    return { ...state, contactStatusColumns: payload };
  });
