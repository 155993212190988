import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LogoutScreen } from './screens/logout.screen';

export const LOGOUT_PATH = '/logout';

export const LogoutRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<LogoutScreen pageTitle="Logout" />} />
    </Routes>
  );
};
