import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DashboardScreen } from './screens/dashboard.screen';

export const DASHBOARD_PATH = '/org/:organizationId'; // '/org/:organizationId';

export const DashboardRoute = () => {
  return (
    <Route
      path={'/'}
      element={
        <PrivateRoute>
          <DashboardScreen pageTitle="Dashboard" />
        </PrivateRoute>
      }
    />
  );
};
