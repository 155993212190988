import React, { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import {
  ContactAddressDto,
  ContactDto,
  ContactType,
} from '../../../models/data.models';
import { ReactSelectItem } from '../../../models/custom.models';
import { ContactForm } from './contact.form';
import { CarrierEdit } from '../../carriers/components/carrier-edit.component';
import { ContactEdit } from './contact-edit.component';
import { CustomerEdit } from '../../customers/components/customer-edit.component';
import { DriverEdit } from '../../drivers/components/driver-edit.component';
import { EmployeeEdit } from '../../employees/components/employee-edit.component';
import { FactoringCompanyEdit } from '../../factoringCompanies/components/factoringCompany-edit.component';
import { ForwardingAgentEdit } from '../../forwardingAgent/components/forwardingAgent-edit.component';
import { SalesPersonEdit } from '../../salesPersons/components/salesPerson-edit.component';
import { VendorEdit } from '../../vendor/components/vendor-edit.component';
import { splitByCapitals } from '../../../utils/helper.utils';
import { LeadEdit } from '../../leads/components/lead-edit.component';

export interface FormCreateContactCommand {
  values: ContactDto;
}
export type ContactEditProps = {
  contactId?: number;
  validateOnChange?: boolean;
  contactTypes?: ContactType[];
  onContactCreated?: (contact: ContactDto) => void;
  onContactUpdated?: (contact: ContactDto) => void;
  onCancel?: () => void;
};

const initialState = {
  name: null,
  divisionId: null,
  accountNumber: null,
  contactAddresses: [],
  shippingAddress: null,
  billingAddress: null,
  contactFirstName: null,
  contactLastName: null,
  contactType: null,
  created: null,
  createdBy: null,
  createdByUserName: null,
  divisionName: null,
  emailAddress: null,
  faxNumber: null,
  idNumber: null,
  idNumberType: null,
  lastModified: null,
  lastModifiedBy: null,
  lastModifiedByUserName: null,
  links: null,
  mobilePhoneNumber: null,
  organizationId: null,
  linkToContactId: null,
  contactLinkType: null,
  phoneNumber: null,
  website: null,
  contactId: null,
};

const contactAddressInitialState: ContactAddressDto = {
  addressType: null,
  contactId: null,
  stateName: null,
  stateCode: null,
  links: null,
  countryName: null,
  countryCode: null,
  createdBy: null,
  lastModified: null,
  created: null,
  lastModifiedBy: null,
  addressLine: null,
  organizationId: null,
  contactAddressId: null,
  addressLine2: null,
  cityName: null,
  postalCode: null,
  customValues: {},
};

export const MultiTypeContactEdit = ({
  contactId,
  validateOnChange,
  onContactCreated = () => {},
  onContactUpdated = () => {},
  onCancel = () => {},
  contactTypes = [],
}: ContactEditProps) => {
  const [selectedContactType, setSelectedContactType] = useState<ContactType>(
    null,
  );
  const [options, setOptions] = useState<ReactSelectItem[]>([]);

  useEffect(() => {
    if (contactTypes.length === 1) {
      setSelectedContactType(contactTypes[0]);
    } else {
      setOptions(
        contactTypes.map((contactType) => {
          return {
            label: splitByCapitals(contactType.toString()),
            value: contactType,
          };
        }),
      );
    }
  }, []);

  const getEditComponent = () => {
    switch (selectedContactType) {
      case ContactType.Carrier:
        return (
          <CarrierEdit
            onCarrierCreated={onContactCreated}
            onCancel={onCancel}
          />
        );
      case ContactType.Contact:
        return (
          <ContactEdit
            onContactCreated={onContactCreated}
            onCancel={onCancel}
          />
        );
      case ContactType.Customer:
        return (
          <CustomerEdit
            onCustomerCreated={onContactCreated}
            onCancel={onCancel}
          />
        );
      case ContactType.Driver:
        return (
          <DriverEdit onDriverCreated={onContactCreated} onCancel={onCancel} />
        );
      case ContactType.Employee:
        return (
          <EmployeeEdit
            onEmployeeCreated={onContactCreated}
            onCancel={onCancel}
          />
        );
      case ContactType.FactoringCompany:
        return (
          <FactoringCompanyEdit
            onFactoringCompanyCreated={onContactCreated}
            onCancel={onCancel}
          />
        );
      case ContactType.ForwardingAgent:
        return (
          <ForwardingAgentEdit
            onForwardingAgentCreated={onContactCreated}
            onCancel={onCancel}
          />
        );
      case ContactType.SalesPerson:
        return (
          <SalesPersonEdit
            onSalesPersonCreated={onContactCreated}
            onCancel={onCancel}
          />
        );
      case ContactType.Vendor:
        return (
          <VendorEdit onVendorCreated={onContactCreated} onCancel={onCancel} />
        );
      case ContactType.Lead:
        return (
          <LeadEdit onLeadCreated={onContactCreated} onCancel={onCancel} />
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      {contactTypes.length > 1 && !selectedContactType ? (
        <div className="w-25 pl-3">
          <ContactForm.ContactType
            defaultValue={
              selectedContactType
                ? {
                    label: ContactType[selectedContactType],
                    value: selectedContactType,
                  }
                : null
            }
            name={'contactType'}
            onChange={(selectedOption) => {
              setSelectedContactType(selectedOption?.value);
            }}
            useContext={false}
            options={options}
          />
        </div>
      ) : (
        <></>
      )}
      {getEditComponent()}
    </>
  );
};
