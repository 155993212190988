import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ClauseForm } from './clause.form';
import { ClauseDto } from '../../../models/data.models';
import { createClauseFx, getClauseFx, updateClauseFx } from '../clauses.store';
import { Panel } from '../../common/components/panel/panel.component';
import { userHas } from '../../auth/auth.store';
import { UPDATE_CLAUSE_LINK_KEY } from '../clauses.service';
import * as Yup from 'yup';

export type ClauseEditProps = {
  clauseId?: number | null;
  onClauseCreated?: (clause: ClauseDto) => void;
  onClauseUpdated?: (clause: ClauseDto) => void;
  onClauseLoaded?: (clause: ClauseDto) => void;
  onCancel?: () => void;
};

const initialState: ClauseDto = {
  clauseId: 0,
  category: '',
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: '',
  organizationId: 0,
  links: [],
};

const clauseSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name can't be blank")
    .max(50, 'Max length is 50'),
  category: Yup.string()
    .required("Category can't be blank")
    .max(100, 'Max length is 100'),
  description: Yup.string()
    .required("Description can't be blank")
    .max(700, 'Max length is 700'),
});

export const ClauseEdit = ({
  clauseId,
  onClauseLoaded = () => {},
  onClauseCreated = () => {},
  onClauseUpdated = () => {},
  onCancel = () => {},
}: ClauseEditProps) => {
  const isCreateMode = !clauseId || clauseId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clause, setClause] = useState<ClauseDto | null>(initialState);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (clauseId) {
      getClauseFx({ clauseId }).then((clauseDto: ClauseDto) => {
        setClause(clauseDto);
        setIsLoading(false);
        onClauseLoaded(clauseDto);
      });
    } else {
      throw new Error('Clause keys were not provided');
    }
  }, [clauseId]);

  const onSubmit = (data: ClauseDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createClauseFx(data)
        .then(
          (result) => {
            onClauseCreated(result.data);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateClauseFx(data)
        .then(
          (result) => {
            onClauseUpdated(result.data);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <ClauseForm
        id={'clause-form'}
        validationSchema={clauseSchema}
        initialValues={clause || initialState}
        onSubmit={onSubmit}
      >
        <div className="row">
          <div className="col-4">
            <ClauseForm.Name />
            <ClauseForm.Category />
            <ClauseForm.Description />
            <div className="justify-content-end row">
              {(userHas(UPDATE_CLAUSE_LINK_KEY, clause?.links) ||
                isCreateMode) && (
                <div>
                  <Button
                    form={'clause-form'}
                    name="save-clause"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Clause
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ClauseForm>
    </Panel>
  );
};
