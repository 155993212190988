import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ParcelShipmentScreen } from './screens/parcelShipment.screen';
import { ParcelShipmentsListScreen } from './screens/parcelShipment-list.screen';
import { PackingWizardScreen } from './screens/packing-wizard.screen';

export const PARCEL_SHIPMENT_LIST_PATH = '/parcelShipments';
export const PARCEL_SHIPMENT_EDIT_PATH = '/parcelShipments/:parcelShipmentId';
export const PARCEL_SHIPMENT_CREATE_PATH = '/parcelShipments/0';
export const PARCEL_SHIPMENT_PACKING_PATH = '/parcelShipments/packing';

export const ParcelShipmentRoute = (props: RouteProps) => {
  const group = 'Operations';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ParcelShipmentsListScreen
              pageTitle="Parcel Shipments"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ParcelShipmentScreen
              pageTitle="Add new Parcel Shipment"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':parcelShipmentId'}
        element={
          <PrivateRoute>
            <ParcelShipmentScreen
              pageTitle="Update Parcel Shipment"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'packing'}
        element={
          <PrivateRoute>
            <PackingWizardScreen pageTitle="Packing the parcel" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
