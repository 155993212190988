import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { BusinessRuleScreen } from './screens/businessRule.screen';
import { BusinessRulesListScreen } from './screens/businessRules-list.screen';

export const BUSINESSRULES_LIST_PATH = '/business-rules';
export const BUSINESSRULE_EDIT_PATH = '/business-rules/:businessRuleId';
export const BUSINESSRULE_CREATE_PATH = '/business-rules/create';

export const BusinessRulesRoute = () => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <BusinessRulesListScreen pageTitle="Business Rules" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <BusinessRuleScreen
              pageTitle="Add New Business Rule"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':businessRuleId'}
        element={
          <PrivateRoute>
            <BusinessRuleScreen
              pageTitle="Update Business Rule"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
