import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PortsListScreen } from './screens/ports-list.screen';
import { PortScreen } from './screens/port.screen';

export const PORTS_LIST_PATH = '/ports';
export const PORT_EDIT_PATH = '/ports/:portId/:countryCode';
export const PORT_CREATE_PATH = '/ports/create';

export const PortsRoute = (props: RouteProps) => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <PortsListScreen pageTitle="Ports" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <PortScreen pageTitle="Add New Port" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':portId/:countryCode'}
        element={
          <PrivateRoute>
            <PortScreen pageTitle="Update Port" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
