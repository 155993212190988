import React from 'react';
import { useStore } from 'effector-react';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, Link } from 'react-router-dom';
import { ActionItem } from '../../common/components/action-item/action-item.component';
import { USERS_LIST_PATH } from '../users.route';
import { GET_USERS_LINK_KEY } from '../users.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { navigationStore } from '../../common/nav.store';

export const UsersMenu = ({ className }) => {
  const { currentOrganization } = useStore(organizationsStore);
  const navStore = useStore(navigationStore);

  return (
    <ActionItem
      context={currentOrganization}
      actionKey={GET_USERS_LINK_KEY}
      render={({ available }) => {
        if (available) {
          return (
            <li className={className}>
              <Link
                to={generatePath(USERS_LIST_PATH)}
                className={`nav-link nav-icon-size-medium ${
                  navStore.path[navStore.path.length - 1] === 'Users'
                    ? 'active'
                    : ''
                }`}
                data-cy="link-users"
              >
                <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </div>
                <div className="nav-link-text">Users</div>
              </Link>
            </li>
          );
        }
        return null;
      }}
    />
  );
};
