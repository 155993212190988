import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import {
  CustomFieldDto,
  DimensionsUnit,
  VolumeUnit,
  WeightUnit,
} from '../../../models/data.models';
import { SelectPackageTypePaginate } from '../../packageTypes/components/packageType-react-select.component';
import { SelectCommodityTypePaginate } from '../../commodityTypes/components/commodityType-react-select.component';
import { CustomFieldsForm } from '../../common/components/form/customFields-form.component';
import { SelectWarehouseLocationPaginate } from '../../warehouseLocations/components/warehouseLocation-react-select.component';
import { SelectCommodityStatusPaginate } from '../../commodityStatuses/components/commodityStatus-react-select.component';
import { SelectJobPaginate } from '../../jobs/components/job-react-select.component';

export interface CommodityFormProps extends FormProps {
  customFields?: CustomFieldDto[];
}

export const CommodityForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  onKeyPress,
  validationSchema,
}: CommodityFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      initialValues={initialValues}
      innerRef={innerRef}
      onKeyPress={onKeyPress}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

CommodityForm.CommodityStatus = ({
  onChange,
  options,
  name = 'statusName',
  id = 'statusId',
  placeholder = 'Select Commodity Status',
  required = true,
  multiple = false,
  disabled = false,
  header = 'Commodity Status',
  defaultValue = '',
  readonly,
  isClearable,
  selectedFilter,
  defaultValueFilter,
}: InputPublicProps) => {
  return (
    <SelectCommodityStatusPaginate
      onChangeStatus={onChange}
      nameId={name}
      id={id}
      placeholder={placeholder}
      required={required}
      isMulti={multiple}
      isDisabled={disabled}
      header={header}
      selectedValue={defaultValue}
      isClearable={isClearable}
      closeMenuOnSelect={true}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
    />
  );
};

CommodityForm.Description = ({
  className,
  style,
  size,
  type = 'text',
  name = 'description',
  label = 'Description',
  placeholder = 'Description',
  required = true,
  selectedFieldName,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
    />
  );
};

CommodityForm.DimensionsUnit = ({
  onChange,
  options,
  name = 'dimensionsUnit',
  id = 'dimensionsUnit',
  placeholder = 'Select Dimension Unit',
  required = true,
  multiple = false,
  disabled = false,
  header,
  valueInput,
  defaultValue = '',
  label = 'Dimension Unit',
  isClearable,
  className,
}: InputPublicProps) => {
  return (
    <Input
      className={className}
      onChange={onChange}
      options={options}
      multiple={multiple}
      disabled={disabled}
      required={required}
      valueInput={valueInput}
      defaultValue={defaultValue}
      name={name}
      label={label}
      placeholder={placeholder}
      type={'react-select'}
      id={id}
      enumName={DimensionsUnit}
      isClearable={isClearable}
    />
  );
};
CommodityForm.Height = ({
  className,
  style,
  size,
  label = 'Height',
  onChange,
  onKeyDown,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      name={'height'}
      label={label}
      placeholder={'Height'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};

CommodityForm.PackageTypeSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Package Type',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'packageTypeId',
  header = 'Package Type',
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <SelectPackageTypePaginate
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePackageType={onChange}
    />
  );
};

CommodityForm.Length = ({
  className,
  style,
  size,
  label = 'Length',
  onChange,
  onKeyDown,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      name={'length'}
      label={label}
      placeholder={'Length'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};
CommodityForm.Pieces = ({
  className,
  style,
  size,
  onChange,
  type = 'text',
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'pieces'}
      label={'Pieces'}
      placeholder={'Pieces'}
      required={true}
      type={type}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};
CommodityForm.VolumePiece = ({
  className,
  style,
  size,
  defaultValue,
  dependencies,
  callback,
}: InputPublicProps) => {
  return (
    <Input
      dependencies={dependencies}
      callback={callback}
      name={'volumePiece'}
      label={'Volume Piece'}
      placeholder={'Volume Piece'}
      required={false}
      disabled={true}
      valueInput={defaultValue}
      type={'computed-input'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CommodityForm.VolumeTotal = ({
  className,
  style,
  size,
  defaultValue,
  dependencies,
  callback,
}: InputPublicProps) => {
  return (
    <Input
      dependencies={dependencies}
      callback={callback}
      name={'volumeTotal'}
      label={'Volume Total'}
      placeholder={'Volume Total'}
      valueInput={defaultValue}
      required={false}
      disabled={true}
      type={'computed-input'}
      className={className}
      style={style}
      size={size}
    />
  );
};

CommodityForm.VolumeUnit = ({
  onChange,
  options,
  name = 'volumeUnit',
  id = 'volumeUnit',
  placeholder = 'Select Volume Unit',
  required = true,
  multiple = false,
  disabled = false,
  header,
  valueInput,
  defaultValue = '',
  label = 'Volume Unit',
}: InputPublicProps) => {
  return (
    <Input
      onChange={onChange}
      options={options}
      multiple={multiple}
      disabled={disabled}
      required={required}
      valueInput={valueInput}
      defaultValue={defaultValue}
      name={name}
      label={label}
      placeholder={placeholder}
      type={'react-select'}
      id={id}
      enumName={VolumeUnit}
    />
  );
};
CommodityForm.WeightTotal = ({
  className,
  style,
  size,
  callback,
  dependencies,
}: InputPublicProps) => {
  return (
    <Input
      dependencies={dependencies}
      callback={callback}
      name={'weightTotal'}
      label={'Weight Total'}
      placeholder={'Weight Total'}
      required={false}
      disabled={true}
      type={'computed-input'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CommodityForm.Weight = ({
  className,
  style,
  size,
  label = 'Weight',
  disabled,
  onChange,
  onKeyDown,
  onBlur,
}: InputPublicProps) => {
  return (
    <Input
      disabled={disabled}
      name={'weight'}
      label={label}
      placeholder={'Weight'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};
CommodityForm.WeightByTotal = ({
  className,
  defaultValue,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'weightByTotal'}
      label={'Weight By Total'}
      placeholder={'Weight By Total'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
    />
  );
};

CommodityForm.WeightUnit = ({
  onChange,
  onBlur,
  options,
  name = 'weightUnit',
  id = 'weightUnit',
  placeholder = 'Select Weight Unit',
  required = true,
  multiple = false,
  disabled = false,
  header,
  valueInput,
  defaultValue = '',
  label = 'Weight Unit',
  isClearable,
  className,
}: InputPublicProps) => {
  return (
    <Input
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      multiple={multiple}
      disabled={disabled}
      required={required}
      valueInput={valueInput}
      defaultValue={defaultValue}
      name={name}
      label={label}
      placeholder={placeholder}
      type={'react-select'}
      id={id}
      enumName={WeightUnit}
      isClearable={isClearable}
    />
  );
};

CommodityForm.Width = ({
  className,
  style,
  size,
  label = 'Width',
  onChange,
  onBlur,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'width'}
      label={label}
      placeholder={'Width'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
};
CommodityForm.Quantity = ({
  className,
  style,
  size,
  onChange,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'quantity'}
      label={'Quantity'}
      placeholder={'Quantity'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};
CommodityForm.UnitaryValue = ({
  className,
  style,
  size,
  onChange,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'unitaryValue'}
      label={'Unitary Value'}
      placeholder={'Unitary Value'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};
CommodityForm.TotalValue = ({
  className,
  style,
  size,
  dependencies,
  callback,
}: InputPublicProps) => {
  return (
    <Input
      dependencies={dependencies}
      callback={callback}
      name={'valueTotal'}
      label={'Total Value'}
      placeholder={'Total Value'}
      required={false}
      disabled={true}
      type={'computed-input'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CommodityForm.Unit = ({
  className,
  style,
  size,
  onChange,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'unit'}
      label={'Unit'}
      placeholder={'Unit'}
      required={false}
      disabled={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};
CommodityForm.Note = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'note'}
      label={'Note'}
      placeholder={'Note'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
CommodityForm.CommodityTypeSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Commodity Type',
  required = true,
  defaultValue,
  sort = '',
  id = 'commodityTypeId',
  header = 'Commodity Type',
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <SelectCommodityTypePaginate
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCommodityType={onChange}
    />
  );
};

CommodityForm.JobSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Job Number',
  required = false,
  defaultValue,
  sort = '',
  id = 'jobId',
  header = 'Job Number',
  onChange = () => {},
}: InputPublicProps) => {
  return (
    <SelectJobPaginate
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeJob={onChange}
    />
  );
};

CommodityForm.CustomFields = ({ customFields, customValues, onChange }) => {
  return (
    <CustomFieldsForm
      customFields={customFields}
      defaultValue={customValues}
      inputNamePrefix={`orderEntityCommodity`}
      onChange={onChange}
    />
  );
};

CommodityForm.WarehouseLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Warehouse Location',
  required = false,
  defaultValue,
  sort = '',
  id = 'warehouseLocationId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  useContext = true,
}: InputPublicProps) => {
  return (
    <SelectWarehouseLocationPaginate
      id={id}
      required={required}
      isMulti={false}
      useContext={useContext}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeLocation={onChange}
      isDisabled={disabled}
    />
  );
};

CommodityForm.ValueByTotal = ({
  className,
  defaultValue,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'valueByTotal'}
      label={'Value By Total'}
      placeholder={'Value By Total'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
