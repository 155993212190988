import {
  CountryDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  CountryDtoPagedResult,
  CreateCountryCommandValues,
  UpdateCountryCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_COUNTRIES_LINK_KEY = 'get-countries';
export const CREATE_COUNTRY_LINK_KEY = 'create-country';
export const GET_COUNTRY_LINK_KEY = 'get-country';
export const UPDATE_COUNTRY_LINK_KEY = 'update-country';
export const DELETE_COUNTRY_LINK_KEY = 'delete-country';
export const IMPORT_COUNTRIES_LINK_KEY = 'import-countries';
export const EXPORT_COUNTRIES_LINK_KEY = 'export-countries';
export const COUNTRY_ENTITY_NAME = 'Country';

export interface GetCountryParams {
  countryCode: string;
}

export interface ImportCountriesParams {
  organizationId: number;
}

export interface ExportCountriesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getCountryRequest = async (
  resource: LinkResourceBaseDto | null,
  { countryCode }: GetCountryParams,
): Promise<CountryDto> => {
  if (resource && countryCode) {
    const getCountryLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_COUNTRY_LINK_KEY,
    );
    if (getCountryLink) {
      const result = await execLink(getCountryLink, { countryCode });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COUNTRY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getCountriesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CountryDtoPagedResult> => {
  if (resource) {
    const getCountriesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_COUNTRIES_LINK_KEY,
    );
    if (getCountriesListLink) {
      const result = await execLink(getCountriesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_COUNTRIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createCountryRequest = async (
  resource: LinkResourceBaseDto,
  country: CreateCountryCommandValues,
) => {
  const createCountryLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_COUNTRY_LINK_KEY,
  );
  if (createCountryLink) {
    const result = await execLink(createCountryLink, country);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_COUNTRY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateCountryRequest = async (
  resource: LinkResourceBaseDto,
  country: UpdateCountryCommandValues,
) => {
  const updateCountryLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_COUNTRY_LINK_KEY,
  );
  if (updateCountryLink) {
    const result = await execLink(updateCountryLink, country);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_COUNTRY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteCountryRequest = async (country: CountryDto) => {
  const deleteCountryLink = country.links?.find(
    (x: LinkDto) => x.rel === DELETE_COUNTRY_LINK_KEY,
  );
  if (deleteCountryLink) {
    const result = await execLink(deleteCountryLink, country);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_COUNTRY_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importCountriesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportCountriesParams,
  file: File,
) => {
  const importCountriesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_COUNTRIES_LINK_KEY,
  );
  if (importCountriesLink) {
    const result = await postFormData(importCountriesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_COUNTRIES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportCountriesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportCountriesParams,
) => {
  const exportCountriesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_COUNTRIES_LINK_KEY,
  );
  if (exportCountriesLink) {
    const result = await downloadLinkFile(exportCountriesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_COUNTRIES_LINK_KEY} is not allowed or missing link action`,
  );
};
