import React, { useEffect, useState } from 'react';
import { ContactDto, ContactType } from '../../../../models/data.models';
import { RoundCheckbox } from '../../../common/components/checkbox/roundCheckbox';
import {
  createContactAndContactAddresses,
  getContacts,
} from '../../../contacts/contacts.store';
import { CreateContactAndContactAddressesCommand } from '../../../../models/custom.models';
import { authStore } from '../../../auth/auth.store';

type UnassignedCheckboxProps = {
  onChange?: (data?: ContactDto) => void;
  selectedCustomerId?: number;
  isInvalid?: boolean;
};

export const UnassignedCheckbox = ({
  onChange,
  selectedCustomerId,
  isInvalid,
}: UnassignedCheckboxProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notFoundCustomer, setNotFoundCustomer] = useState<ContactDto | null>(
    null,
  );
  const { user: currentUser } = authStore.getState();

  const getOrCreateNotFoundCustomer = () => {
    if (
      !notFoundCustomer?.contactId ||
      notFoundCustomer?.contactId != selectedCustomerId
    ) {
      setIsLoading(true);
      getContacts({
        sort: '',
        filter: '',
        offset: 0,
        limit: 1,
        search: 'unassigned customer',
      })
        .then(
          (contacts) => {
            if (contacts.items[0]) {
              const notFoundCustomer = contacts.items[0];
              setNotFoundCustomer(notFoundCustomer);
            } else {
              const data: CreateContactAndContactAddressesCommand = {
                shippingAddress: null,
                billingAddress: null,
                contact: {
                  contactId: 0,
                  contactType: ContactType.Customer,
                  divisionId: currentUser.divisionId,
                  name: 'Unassigned Customer',
                },
              };
              createContactAndContactAddresses(data).then(() =>
                getOrCreateNotFoundCustomer(),
              );
            }
          },
          () => {},
        )
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (currentUser) getOrCreateNotFoundCustomer();
  }, [currentUser]);

  return (
    <>
      {notFoundCustomer?.contactId === selectedCustomerId ? (
        <RoundCheckbox
          chkBoxClassName={
            'checkbox-unassigned-customer checkbox-unassigned-customer-checked'
          }
          spinnerClassName="mr-0"
          onClick={() => onChange(notFoundCustomer)}
          label="Unassigned"
          size={17}
          isLoading={isLoading}
        />
      ) : isInvalid ? (
        <RoundCheckbox
          chkBoxClassName={
            'checkbox-unassigned-customer checkbox-unassigned-customer-invalid'
          }
          spinnerClassName="mr-0"
          onClick={() => onChange(notFoundCustomer)}
          label="Unassigned"
          isInvalid={isInvalid}
          size={17}
          isLoading={isLoading}
        />
      ) : (
        <RoundCheckbox
          chkBoxClassName={'checkbox-unassigned-customer'}
          spinnerClassName="mr-0"
          onClick={() => onChange(notFoundCustomer)}
          label="Unassigned"
          size={17}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
