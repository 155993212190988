import {
  LinkDto,
  LinkResourceBaseDto,
  ReceiveCarrierPayCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { CarrierPayManagerForListDtoPagedResult } from '../../models/custom.models';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CARRIER_PAYS_LINK_KEY = 'get-carrier-pays';
export const RECEIVE_CARRIER_PAY_LINK_KEY = 'receive-carrier-pay';

export const getCarrierPaysListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CarrierPayManagerForListDtoPagedResult> => {
  if (resource) {
    const getOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CARRIER_PAYS_LINK_KEY,
    );
    if (getOrdersListLink) {
      const result = await execLink(getOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CARRIER_PAYS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const receiveCarrierPayRequest = async (
  resource: LinkResourceBaseDto,
  carrierPay: ReceiveCarrierPayCommandValues,
) => {
  const receiveCarrierPayLink = resource?.links?.find(
    (x: LinkDto) => x.rel === RECEIVE_CARRIER_PAY_LINK_KEY,
  );
  if (receiveCarrierPayLink) {
    const result = await execLink(receiveCarrierPayLink, carrierPay);
    return result.data;
  }
  addMessage({
    message: `Action ${RECEIVE_CARRIER_PAY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
