import {
  AddressType,
  ContactAddressDto,
  ContactDto,
  ContactType,
  OrderEntityDto,
} from '../../../../models/data.models';
import { OrderForm } from '../order.form';
import React from 'react';

export type ConsigneeFormProps = {
  orderDeliveryDto: OrderEntityDto | null;
  index: number;
  allowDelete: boolean;
  onDeliveryCreated?: (orderDeliveryDto: OrderEntityDto) => void;
  onDeliveryUpdated?: (orderDeliveryDto: OrderEntityDto) => void;
  onDeliveryLoaded?: (orderDeliveryDto: OrderEntityDto) => void;
  onDeliveryDeleted?: () => void;
  onDeliveryNoteChange: (date?: any) => void;
  onDeliveryDateChange: (date?: any) => void;
  setDeliveryContact: (
    contactId: number,
    name: string,
    contactType: ContactType,
  ) => void;
  setDeliveryFilter: (filter: string) => void;
  setDeliveryContactAddress: (
    contactAddressId: number,
    addressLine: string,
  ) => void;
  deliveryFilter: string;
  deliveryContact: {
    contactId: number;
    name: string;
    contactType: ContactType;
  };
  deliveryContactAddress: { contactAddressId: string; addressLine: string };
};
export const ConsigneeForm = ({
  orderDeliveryDto,
  index,
  onDeliveryDateChange,
  onDeliveryNoteChange,
  setDeliveryContact,
  setDeliveryFilter,
  setDeliveryContactAddress,
  deliveryFilter,
  deliveryContact,
  deliveryContactAddress,
  allowDelete = false,
  onDeliveryDeleted,
}: ConsigneeFormProps) => {
  const isDisabledDeliveryContact = (): boolean => {
    if (
      deliveryContact?.contactId == null ||
      isNaN(deliveryContact?.contactId)
    ) {
      return true;
    }
    return false;
  };
  return (
    <div className="row">
      <div className="col-3">
        <OrderForm.ConsigneeCompany
          id={`orderDeliveries[${index}].contactId`}
          header={`Consignee ${index + 1}`}
          contactTypes={[
            ContactType.Customer,
            ContactType.Vendor,
            ContactType.ForwardingAgent,
            ContactType.Contact,
          ]}
          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
          required={false}
          defaultValue={
            deliveryContact &&
            deliveryContact.contactId != null &&
            deliveryContact.name != null &&
            deliveryContact.contactType != null
              ? {
                  contactId: deliveryContact.contactId,
                  name: deliveryContact.name,
                  contactType: deliveryContact.contactType,
                }
              : ''
          }
          onChange={(data?: ContactDto, context?: any) => {
            setDeliveryContact(data?.contactId, data?.name, data?.contactType);
            setDeliveryFilter(
              data?.contactId ? `contactId:${data?.contactId}` : ' ',
            );
            setDeliveryContactAddress(null, null);

            context?.setFieldValue(
              `orderDeliveries[${index}].consigneeAddressId`,
              null,
            );

            if (data === null) {
              orderDeliveryDto.customValues['deliveryDate'] = null;
              orderDeliveryDto.customValues['deliveryNotes'] = '';
              context?.setFieldValue(
                `orderDeliveries[${index}].deliveryDate`,
                null,
              );
              context?.setFieldValue(
                `orderDeliveries[${index}].deliveryNotes`,
                '',
              );
            }
          }}
          nameId={`orderDeliveries[${index}].consigneeContactName`}
        />
      </div>
      <div className="col-3">
        <OrderForm.ConsigneeLocation
          selectedFilter={deliveryFilter}
          id={`orderDeliveries[${index}].consigneeAddressId`}
          header={'Location'}
          required={false}
          disabled={isDisabledDeliveryContact()}
          defaultValue={
            deliveryContactAddress &&
            deliveryContactAddress.contactAddressId != null &&
            deliveryContactAddress.addressLine != null
              ? {
                  contactAddressId: deliveryContactAddress.contactAddressId,
                  addressLine: deliveryContactAddress.addressLine,
                }
              : ''
          }
          onChange={(data?: ContactAddressDto) => {
            setDeliveryContactAddress(
              data?.contactAddressId,
              data?.addressLine,
            );
          }}
          nameId={`orderDeliveries[${index}].consigneeAddressName`}
          contactId={deliveryContact.contactId}
          contactType={deliveryContact?.contactType}
          addressType={AddressType.Other}
        />
      </div>
      <div className="col-3">
        <OrderForm.ConsigneeDate
          defaultValue={orderDeliveryDto?.customValues['deliveryDate']}
          onChange={onDeliveryDateChange}
          name={`orderDeliveries[${index}].deliveryDate`}
          disabled={isDisabledDeliveryContact()}
        />
      </div>
      {allowDelete ? (
        <div className="col-3 d-flex">
          <div className={'w-100 mw-100 pr-3'}>
            <OrderForm.ConsigneeNotes
              name={`orderDeliveries[${index}].deliveryNotes`}
              disabled={isDisabledDeliveryContact()}
              onChange={onDeliveryNoteChange}
              defaultValue={orderDeliveryDto?.customValues['deliveryNotes']}
            />
          </div>
          <div className={'d-flex align-items-center pt-3'}>
            <div
              data-cy={`delete-consignee[${index}]`}
              onClick={(event) => {
                onDeliveryDeleted();
              }}
              color="primary"
              className="btn-close icon-close"
            />
          </div>
        </div>
      ) : (
        <div className="col-3 d-flex">
          <div className={'w-100'}>
            <OrderForm.ConsigneeNotes
              name={`orderDeliveries[${index}].deliveryNotes`}
              disabled={isDisabledDeliveryContact()}
              onChange={onDeliveryNoteChange}
              defaultValue={orderDeliveryDto?.customValues['deliveryNotes']}
            />
          </div>
        </div>
      )}
    </div>
  );
};
