import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CarrierPayManagerListScreen } from './screens/carrierPayManager-list.screen';

export const CARRIER_PAY_MANAGER_LIST_PATH = '/carrier-pay-manager';

export const CarrierPayManagerRoute = () => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CarrierPayManagerListScreen
              pageTitle="Carrier Pay Manager"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
