import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { InvoicesListScreen } from './screens/invoices-list.screen';
import { InvoicesScreen } from './screens/invoices.screen';

export const INVOICES_LIST_PATH = '/invoices';
export const INVOICES_EDIT_PATH = '/invoices/:accountingTransactionId';
export const INVOICES_CREATE_PATH = '/invoices/0';

export const InvoicesRoute = (props: RouteProps) => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <InvoicesListScreen pageTitle="Invoices" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <InvoicesScreen pageTitle="Add New Invoice" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':accountingTransactionId'}
        element={
          <PrivateRoute>
            <InvoicesScreen pageTitle="Update Invoice" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
