import React, { useCallback } from 'react';
import { Input } from '../input/input.component';
import { ContactDto, CustomFieldType } from '../../../../models/data.models';
import { FormikProps, FormikValues } from 'formik';
import { JsonField } from '../input/ace-input.component';
import { TextField } from '../input/input-text.component';
import { SelectJobPaginate } from '../../../jobs/components/job-react-select.component';
import { JobDto } from '../../../../models/data.models';
import { SelectContactPaginate } from '../../../contacts/components/contact-react-select.component';

export type CustomFieldInputProps = {
  customFieldType: string;
  name: string;
  internalName: string;
  namePrefix: string;
  displayName: string;
  context?: FormikProps<FormikValues>;
  placeholder?: string;
};

export const CustomFieldInput = (props: CustomFieldInputProps) => {
  switch (props.customFieldType) {
    case 'text':
    case CustomFieldType.Text:
      return <TextField {...props} />;
    case 'object':
      return <JsonField {...props} height={150} />;
    case 'boolean':
      return (
        <Input
          key={`input-${props.internalName}`}
          defaultValue={
            props.context?.values[`${props.namePrefix}${props.internalName}`]
          }
          onChange={props?.context?.setFieldValue}
          type={'checkbox'}
          name={`${props.namePrefix}${props.internalName}`}
          label={props.displayName}
        />
      );
    case 'jobId':
      return (
        <SelectJobPaginate
          selectedValue={
            props.context?.values[`${props.namePrefix}${props.name}`]
          }
          onChangeJob={(data?: JobDto) =>
            props?.context?.setFieldValue(
              `${props.namePrefix}${props.name}`,
              data?.[`${props.namePrefix}${props.name}`],
            )
          }
          closeMenuOnSelect={true}
          header={props.displayName || props.name}
        />
      );
    case 'contactId':
      return (
        <SelectContactPaginate
          selectedValue={
            props.context?.values[`${props.namePrefix}${props.name}`]
          }
          selectedFilter="contactType:Carrier"
          onChangeContact={(data?: ContactDto) =>
            props?.context?.setFieldValue(
              `${props.namePrefix}${props.name}`,
              data?.[`${props.namePrefix}${props.name}`],
            )
          }
          closeMenuOnSelect={true}
          header={props.displayName || props.name}
        />
      );
    default:
      return <TextField {...props} />;
  }
};
