import { Dialog } from '../../common/components/dialog/dialog.component';
import { WorkflowEdit } from './workflow-edit.component';
import { WorkflowDto } from '../../../models/data.models';

export type WorkflowDialogProperties = {
  workflowId?: string | null;
  title: string;
};

export class WorkflowDialog extends Dialog<WorkflowDialogProperties> {
  constructor(props: WorkflowDialogProperties) {
    super(props);
    this.onWorkflowSaved = this.onWorkflowSaved.bind(this);
  }

  onWorkflowSaved = (workflow: WorkflowDto) => {};

  onWorkflowDeleted = (workflow: WorkflowDto) => {
    this.close(workflow);
  };

  onCancel = () => {
    this.close();
  };

  renderContent(): any {
    const { workflowId } = this.props as WorkflowDialogProperties;
    return (
      <WorkflowEdit
        workflowId={workflowId}
        onWorkflowCreated={this.onWorkflowSaved}
        onWorkflowUpdated={this.onWorkflowSaved}
        onCancel={this.onCancel}
        onDelete={this.onWorkflowDeleted}
      />
    );
  }
}
