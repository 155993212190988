import { Dialog } from '../../common/components/dialog/dialog.component';
import { VesselEdit } from './vessel-edit.component';
import { VesselDto } from '../../../models/data.models';

export type VesselDialogProperties = {
  vesselId?: number | null;
  title: string;
};

export class VesselDialog extends Dialog<VesselDialogProperties> {
  constructor(props: VesselDialogProperties) {
    super(props);
    this.onVesselSaved = this.onVesselSaved.bind(this);
  }

  onVesselSaved = (vessel: VesselDto) => {
    this.close(vessel);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { vesselId } = this.props as VesselDialogProperties;
    return (
      <VesselEdit
        vesselId={vesselId}
        onVesselCreated={this.onVesselSaved}
        onVesselUpdated={this.onVesselSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
