import React, { useEffect, useState } from 'react';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'effector-react';

import {
  ContactDtoPagedResult,
  ContactType,
} from '../../../models/data.models';
import { FormContext } from '../../common/components/form/form.component';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { showDialog } from '../../common/dialog.store';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DriverDialog } from '../../drivers/components/driver.dialog';
import { CustomerDialog } from '../../customers/components/customer.dialog';
import { CarrierDialog } from '../../carriers/components/carrier.dialog';
import { VendorDialog } from '../../vendor/components/vendor.dialog';
import { EmployeeDialog } from '../../employees/components/employee.dialog';
import { FactoringCompanyDialog } from '../../factoringCompanies/components/factoringCompany.dialog';
import { SalesPersonDialog } from '../../salesPersons/components/salesPerson.dialog';
import { ForwardingAgentDialog } from '../../forwardingAgent/components/forwardingAgent..dialog';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { splitByCapitals } from '../../../utils/helper.utils';
import { LeadDialog } from '../../leads/components/lead.dialog';
import { getContact, getContacts } from '../../contacts/contacts.store';
import { ContactDialog } from '../../contacts/components/contact.dialog';
import {
  CONTACT_ENTITY_NAME,
  CREATE_CONTACT_LINK_KEY,
  GET_CONTACT_LINK_KEY,
} from '../../contacts/contacts.service';
import { MultiTypeContactDialog } from '../../contacts/components/multitype-contact.dialog';
import {
  clearContactsLoadOptionsCache,
  contactReactSelectStore,
  updateLastEditedContact,
} from '../../contacts/components/contact-react-select.store';

export const SelectContactPaginateForMovement = ({
  id = 'customerId' + uuidv4(),
  nameId = 'contactName' + uuidv4(),
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  showContactType = false,
  canCreateNewEntity = null,
  selectedValue = null,
  onChangeContact = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  readonly = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Contact',
  contactTypes = [],
  isMailInput = false,
  customStyles = {},
  getOptionValue = (selectedOption) => {
    return selectedOption.contactId;
  },
  getOptionLabel = (selectedOption) => {
    return selectedOption.name;
  },
}): JSX.Element => {
  const context = useFormikContext();

  const employeeContactId = parseInt(_.get(context.values, id));
  const employeeContactName = _.get(context.values, nameId);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  useEffect(() => {
    if (!selectedValue && readonly) {
      setDefaultContact();
    }
  }, []);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseContactDtoPagedResult: ContactDtoPagedResult = await getContacts(
      {
        limit,
        offset: (page - 1) * limit,
        sort,
        filter,
        search: searchQuery,
      },
    );

    let isThereANewEmail = false;
    if (isMailInput) {
      if (responseContactDtoPagedResult.items.length === 0) {
        const mailPattern = /[\w\-\.]+@\w+\.\w+/gi;
        if (searchQuery.match(mailPattern)) {
          responseContactDtoPagedResult.items = new Array({
            contactId: 0,
            name: 'External E-mail',
            emailAddress: searchQuery,
          });
          isThereANewEmail = true;
        }
      }
      responseContactDtoPagedResult.items = responseContactDtoPagedResult.items.filter(
        (item) => item.emailAddress !== null,
      );
    }

    return {
      options: responseContactDtoPagedResult.items,
      hasMore: isThereANewEmail
        ? responseContactDtoPagedResult.items.length - 1 >= 1
        : responseContactDtoPagedResult.items.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeContact === 'function') {
      onChangeContact(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const setDefaultContact = () => {
    getContacts({
      limit: 1,
      offset: 0,
      sort,
      filter: selectedFilter,
    }).then(
      (contacts) => {
        if (contacts.items.length > 0) {
          const defaultContact = contacts.items[0];
          onSelect(defaultContact);
        }
      },
      () => {},
    );
  };

  const onSelect = (contact) => {
    onChange(contact, context);
    if (context) {
      if (isMulti === true) {
        if (contact?.length > 0) {
          context.setFieldValue(id, contact?.length);
          context.setFieldError(id, undefined);
        } else {
          if (required) {
            context.setFieldValue(id, '');
          }
        }
      } else {
        context.setFieldValue(id, contact?.contactId);
        context.setFieldValue(nameId, contact?.name);
        context.setFieldError(id, undefined);
        if (required && !contact) {
          context.setFieldValue(id, '');
        }
      }
    }
  };

  const contactTypeToDialog = (contactType: string) => {
    switch (contactType) {
      case ContactType.Customer:
        return CustomerDialog;
      case ContactType.Carrier:
        return CarrierDialog;
      case ContactType.Vendor:
        return VendorDialog;
      case ContactType.Employee:
        return EmployeeDialog;
      case ContactType.FactoringCompany:
        return FactoringCompanyDialog;
      case ContactType.Driver:
        return DriverDialog;
      case ContactType.SalesPerson:
        return SalesPersonDialog;
      case ContactType.ForwardingAgent:
        return ForwardingAgentDialog;
      case ContactType.Contact:
        return ContactDialog;
      case ContactType.Lead:
        return LeadDialog;
      default:
        return null;
    }
  };

  const { clearLoadOptionsCache, lastEditedContact } = useStore(
    contactReactSelectStore,
  );

  const onContactEditIconClick = async () => {
    if (employeeContactId) {
      const contact = await getContact({
        contactId: Number(employeeContactId) || null,
      });
      const contactType = contact.contactType;
      const contactDialog = contactTypeToDialog(contactType);
      if (contactDialog) {
        showDialog({
          dialog: contactDialog,
          props: {
            contactId: employeeContactId,
            title: `Update ${contactType}`,
            disableDots: true,
          },
        }).then(
          async (contact) => {
            if (contact !== null) {
              clearContactsLoadOptionsCache();
              updateLastEditedContact(
                await getContact({
                  contactId: Number(employeeContactId) || null,
                }),
              );
            }
          },
          () => {},
        );
      }
    }
  };

  useEffect(() => {
    if (
      lastEditedContact &&
      employeeContactId &&
      lastEditedContact.contactId === employeeContactId
    ) {
      onSelect(lastEditedContact);
    }
  }, [lastEditedContact]);

  if (useContext) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti &&
                employeeContactId &&
                userHas(GET_CONTACT_LINK_KEY) ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onContactEditIconClick}
                    data-cy={`${header}-contact-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              styles={customStyles}
              className={id}
              entityName={CONTACT_ENTITY_NAME}
              canCreateNewEntity={
                canCreateNewEntity ?? userHas(CREATE_CONTACT_LINK_KEY)
              }
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: MultiTypeContactDialog,
                  props: {
                    title: showCreateDialogTitle,
                    contactId: 0,
                    contactTypes: contactTypes,
                  },
                }).then(
                  (contact) => {
                    if (contact?.contactId && contact?.name) {
                      clearContactsLoadOptionsCache();
                      return contact;
                    }
                  },
                  () => {},
                );
              }}
              key={filter}
              value={selectedValue}
              loadOptions={loadOptions}
              getOptionValue={getOptionValue}
              getOptionLabel={(option) =>
                showContactType
                  ? `${getOptionLabel(option)} — ${splitByCapitals(
                      option?.contactType?.toString(),
                    )}`
                  : `${getOptionLabel(option)}`
              }
              onChange={(contact?: any) => {
                onChange(contact, context);
                if (context) {
                  if (isMulti === true) {
                    if (contact?.length > 0) {
                      context.setFieldValue(id, contact?.length);
                      context.setFieldError(id, undefined);
                    } else {
                      if (required) {
                        context.setFieldValue(id, '');
                      }
                    }
                  } else {
                    context.setFieldValue(id, contact?.contactId);
                    context.setFieldValue(nameId, contact?.name);
                    context.setFieldError(id, undefined);
                    if (required && !contact) {
                      context.setFieldValue(id, '');
                    }
                  }
                }
              }}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              components={{
                IndicatorSeparator: () => null,
              }}
              isDisabled={isDisabled}
              isClearable={readonly ? false : isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={readonly ? false : isSearchable}
              placeholder={placeholder}
              menuIsOpen={readonly ? false : undefined}
              classNamePrefix={classNamePrefix}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        styles={customStyles}
        className={id}
        entityName={CONTACT_ENTITY_NAME}
        canCreateNewEntity={
          canCreateNewEntity ?? userHas(CREATE_CONTACT_LINK_KEY)
        }
        onCreateNewEntity={() => {
          return showDialog({
            dialog: MultiTypeContactDialog,
            props: {
              title: showCreateDialogTitle,
              contactId: 0,
              contactTypes: contactTypes,
            },
          }).then(
            (contact) => {
              if (contact?.contactId && contact?.name) {
                clearContactsLoadOptionsCache();
                return contact;
              }
            },
            () => {},
          );
        }}
        key={filter}
        value={selectedValue}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.contactId}
        getOptionLabel={(option) =>
          showContactType
            ? `${getOptionLabel(option)} — ${splitByCapitals(
                option?.contactType?.toString(),
              )}`
            : `${getOptionLabel(option)}`
        }
        onChange={(contact?: any) => {
          onChange(contact, context);
        }}
        onFocus={clearMessages}
        components={{
          IndicatorSeparator: () => null,
        }}
        menuIsOpen={readonly ? false : undefined}
        isDisabled={isDisabled}
        isClearable={readonly ? false : isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={readonly ? false : isSearchable}
        placeholder={placeholder}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
