import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { CustomFieldDto } from '../../../models/data.models';

const labelStyles = {
  fontWeight: 500,
  lineHeight: '19px',
  fontSize: '16px',
};

export interface TrackingNumberFormProps extends FormProps {
  customFields?: CustomFieldDto[];
}

export const PackageReceiveForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  onKeyPress,
  validationSchema,
}: TrackingNumberFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      initialValues={initialValues}
      onKeyPress={onKeyPress}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

PackageReceiveForm.TrackingNumber = ({
  className,
  style,
  size,
  placeholder = 'Tracking Number',
  type = 'text',
  name = 'trackingNumber',
  label = null,
  required = true,
  selectedFieldName,
  onChange,
  barcode,
  onBlur,
  autoSubmit,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      placeholder={placeholder}
      onChange={onChange}
      barcode={barcode}
      onBlur={onBlur}
      autoSubmit={autoSubmit}
    />
  );
};

PackageReceiveForm.TrackingNumberOptional = ({
  className,
  style,
  size,
  placeholder = 'Tracking Number (optional)',
  type = 'text',
  name = 'trackingNumberOptional',
  label = null,
  required = true,
  selectedFieldName,
  onChange,
  barcode,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      placeholder={placeholder}
      onChange={onChange}
      barcode={barcode}
    />
  );
};

PackageReceiveForm.PalletNumber = ({
  id,
  className,
  style,
  size,
  type = 'text',
  name = 'putAwayPallet',
  label = 'Put Away Pallet #',
  required = true,
  selectedFieldName,
  onChange,
  barcode,
}: InputPublicProps) => {
  return (
    <Input
      id={id}
      name={name}
      label={label}
      labelStyles={labelStyles}
      required={required}
      type={type}
      valueInput={selectedFieldName}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      barcode={barcode}
    />
  );
};
