import { Button } from 'react-bootstrap';
import { ComponentProps } from '../layout/layout-interfaces';
import { v4 } from 'uuid';
import { localized, parseTemplate } from '../layout/component-hooks';
import { useEffect } from 'react';
import { getOrganizationConfigsFx } from '../../../organizationConfigs/organizationConfigs.store';

export const Auth2Component = (props: ComponentProps) => {
  const {
    props: {
      clientId,
      clientSecret,
      authorizationUrl,
      tokenUrl,
      scopes,
      additionalParams,
      additionalHeaders,
      onToken,
      className,
    },
    context,
    variables,
  } = props;

  useEffect(() => {
    // listen for oAuth2Callback message event from the popup window
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'oAuth2Callback') {
        // on token
        if (onToken) {
          context.action(onToken, {
            token: event.data.data,
            ...variables,
            ...context?.store,
          });
        }
      }
    };
    window.addEventListener('message', handleMessage);
    // unsubscribe from message event
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [context, onToken]);

  const Authorize = () => {
    const redirectUri = `${window.location.origin}/oauth2/callback`;

    const sessionId = v4();

    const [
      parsedClientId,
      parsedClientSecret,
      parsedAuthorizationUrl,
      parsedTokenUrl,
      parsedAdditionalHeaders,
    ] = [
      clientId,
      clientSecret,
      authorizationUrl,
      tokenUrl,
      additionalHeaders,
    ].map((x) => parseTemplate(x, context?.store));

    const authParams = new URLSearchParams({
      client_id: parsedClientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: scopes?.join(' '),
      state: sessionId,
    });

    // add additionalParams
    if (additionalParams) {
      for (const [key, value] of Object.entries(additionalParams)) {
        authParams.append(key, String(value));
      }
    }

    const authUrl = `${parsedAuthorizationUrl}?${authParams.toString()}`;

    getOrganizationConfigsFx({
      filter: `configName:${props?.props?.workflowConfigName}`,
      limit: 1,
    }).then((organizationConfigs) => {
      const workflowId = (organizationConfigs?.items?.[0]?.customValues as {
        oauthWorkflowId?: string | null;
      })?.oauthWorkflowId;

      // set tokenUrl to local session storage for later use using sessionId
      localStorage.setItem(
        sessionId,
        JSON.stringify({
          tokenUrl: parsedTokenUrl,
          clientId: parsedClientId,
          clientSecret: parsedClientSecret,
          scope: scopes?.join(' '),
          additionalParams,
          additionalHeaders: parsedAdditionalHeaders,
          redirectUri,
          workflowId,
        }),
      );

      // open the auth url in a small popup window
      window.open(authUrl, 'authWindow', 'width=800,height=600');
    });
  };

  return (
    <>
      <Button className={className} onClick={Authorize}>
        {parseTemplate(localized(props.props?.label), {
          ...variables,
          ...context?.store,
        }) || 'Authorize'}
      </Button>
    </>
  );
};
