import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
//import { CustomCodeEdit } from '../components/customCode-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_RATE_LINK_KEY } from '../rates.service';
import { userHas } from '../../auth/auth.store';
import { RateEdit } from '../components/rate-edit.component';
import { RateType } from '../../../models/data.models';
import {
  STANDARD_CLIENT_RATES_LIST_PATH,
  STANDARD_CLIENT_RATE_CREATE_PATH,
} from '../standard-client-rates.route';
import {
  CLIENT_RATES_LIST_PATH,
  CLIENT_RATE_CREATE_PATH,
} from '../client-rates.route';
import {
  CARRIER_RATES_LIST_PATH,
  CARRIER_RATE_CREATE_PATH,
} from '../carrier-rates.route';

export type RateScreenProps = {
  pageTitle: string;
  rateType: RateType;
  group: string;
};

export const RateScreen = ({ pageTitle, rateType, group }: RateScreenProps) => {
  let rateListPath;
  let rateCreatePath;

  switch (rateType) {
    case RateType.StandardClientRate:
      rateListPath = STANDARD_CLIENT_RATES_LIST_PATH;
      rateCreatePath = STANDARD_CLIENT_RATE_CREATE_PATH;
      break;
    case RateType.ClientRate:
      rateListPath = CLIENT_RATES_LIST_PATH;
      rateCreatePath = CLIENT_RATE_CREATE_PATH;
      break;
    case RateType.CarrierRate:
      rateListPath = CARRIER_RATES_LIST_PATH;
      rateCreatePath = CARRIER_RATE_CREATE_PATH;
      break;
  }

  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, rateId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + rateCreatePath, {
    organizationId,
  });

  const onRateCreated = () => {
    const path = generatePath(INTERNAL_PATH + rateListPath, {
      organizationId,
    });
    const message: Message = {
      id: 'rate-created',
      type: 'success',
      autoHide: true,
      message: 'Rate successfully created!',
    };
    navigate(path);
    addMessage(message);
  };

  const onRateUpdated = () => {
    const path = generatePath(INTERNAL_PATH + rateListPath, {
      organizationId,
    });
    const message: Message = {
      id: 'rate-updated',
      type: 'success',
      autoHide: true,
      message: 'Rate successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + rateListPath, {
      organizationId: currentOrganization.organizationId,
    });
    navigate(path);
  };

  return (
    <InternalLayout
      group={group}
      title={pageTitle}
      createPath={userHas(CREATE_RATE_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <RateEdit
        rateId={rateId}
        onRateCreated={onRateCreated}
        onRateUpdated={onRateUpdated}
        onCancel={onCancel}
        rateType={rateType}
      />
    </InternalLayout>
  );
};
