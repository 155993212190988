import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { CARRIER_PAY_MANAGER_LIST_PATH } from '../carrierPayManager.route';
import { ORDER_CREATE_PATH, ORDER_EDIT_PATH } from '../../orders/order.route';
import { GetOrdersQuery } from '../../../models/data.models';
import { CarrierPayManagerList } from '../components/carrierPayManager-list.component';
import { GetOrderParams } from '../../orders/orders.service';
import { Helmet } from 'react-helmet';

export type CarrierPayManagerListScreenProps = {
  pageTitle: string;
  group: string;
};

export const CarrierPayManagerListScreen = ({
  pageTitle,
  group,
}: CarrierPayManagerListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = '-Order.OrderNumber~ToInt32',
    filter = ``,
    search = '',
  } = useQuery() as GetOrdersQuery;
  const navigate = useNavigate();

  const goToDetails = (orderParams: GetOrderParams) => {
    const path = generatePath(INTERNAL_PATH + ORDER_EDIT_PATH, {
      organizationId,
      orderId: orderParams.orderId,
    });
    navigate(path);
  };

  const onDelete = () => {
    navigate(navigate.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + CARRIER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + CARRIER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CARRIER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CARRIER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    navigate(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };
  return (
    <InternalLayout
      title={'Carrier Pay Manager'}
      group={group}
      createLabel={'Manager'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CarrierPayManagerList
        onSearch={onSearch}
        search={search}
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        filter={filter}
      />
    </InternalLayout>
  );
};
