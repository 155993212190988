import { useEffect, useState } from 'react';
import { localized, parseTemplate } from '../component-hooks';
import { ComponentProps } from '../layout-interfaces';

export const LinkComponent = (props: ComponentProps) => {
  const { label, to, options } = props.props;
  const [href, setHref] = useState(
    parseTemplate(to, { ...props.variables, ...props?.context?.store }),
  );
  const [parsedLabel, setParsedLabel] = useState(
    parseTemplate(label, { ...props.variables, ...props?.context?.store }),
  );

  useEffect(() => {
    setHref(
      parseTemplate(to, { ...props.variables, ...props?.context?.store }),
    );
    setParsedLabel(
      parseTemplate(label, { ...props.variables, ...props?.context?.store }),
    );
  }, [props]);

  return (
    <a href={href} {...options}>
      {localized(parsedLabel)}
    </a>
  );
};
