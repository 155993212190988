import { Dialog } from '../dialog/dialog.component';
import { ConfirmationComponent } from './confirmation.component';

export type ConfirmationDialogProperties = {
  title: string;
  className?: string;
  actionText?: string;
  onInvoiceConfirmed?: (isConformed: boolean) => void;
};

export class ConfirmationDialog extends Dialog<ConfirmationDialogProperties> {
  constructor(props: ConfirmationDialogProperties) {
    super(props);
    this.onInvoiceConfirmed = this.onInvoiceConfirmed.bind(this);
  }

  onInvoiceConfirmed = (isConfirmed) => {
    this.close(isConfirmed);
  };

  renderContent(): any {
    const { className, actionText } = this
      .props as ConfirmationDialogProperties;
    return (
      <ConfirmationComponent
        className={className}
        actionText={actionText}
        onInvoiceConfirmed={this.onInvoiceConfirmed.bind(this)}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
