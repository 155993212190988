import React, { useEffect, useState } from 'react';
import {
  CreateOrderDocumentsCommandValues,
  DocumentTemplateDtoPagedResult,
  EntityTypes,
  OrderDto,
} from '../../../models/data.models';
import {
  documentTemplateStore,
  getDocumentTemplatesFx,
} from '../../documentTemplates/documentTemplates.store';
import { FiSearch } from 'react-icons/fi';
import { Button } from '../../common/components/button/button.component';
import { OrderDocumentsGrid } from '../../common/components/grid/orderDocuments-grid.component';
import {
  createOrderDocumentsFx,
  documentTemplateStoreForOrderDocuments,
  orderDocumentsFiltersStore,
} from '../orderDocuments.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { Form } from '../../common/components/form/form.component';
import { useStore } from 'effector-react';
import { OrderDocumentsFiltersSelector } from './orderDocumentsFiltersSelector';
import { OrderDocumentsFilters } from './orderDocumentsFilters';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { getOrder, updateOrder } from '../../orders/orders.store';
import { OrderEditFormDto } from '../../../models/custom.models';

export interface OrderDocumentsEditProps {
  documentTemplateFilter?: string;
  isBolCreationDisabled?: () => boolean;
  onOrderDocumentsCreated?: () => void;
  saveButtonRenderCondition?: boolean;
  onCancel?: () => void;
  title?: string;
  orderContext: OrderDto;
  onSaveOrder: (data: OrderDto) => OrderDto;
}

export const OrderDocumentsEdit = ({
  documentTemplateFilter,
  isBolCreationDisabled,
  onOrderDocumentsCreated = () => {},
  saveButtonRenderCondition = false,
  onCancel = () => {},
  orderContext,
  onSaveOrder,
}: OrderDocumentsEditProps) => {
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [documentIds, setDocumentIds] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [checkedFilters, setCheckedFilters] = useState([]);
  const { defaultDocumentTemplatesForOrderDocumentColumns: columns } = useStore(
    documentTemplateStoreForOrderDocuments,
  );
  const [regenerateIds, setRegenerateIds] = useState<number[]>([]);
  const { orderDocumentsFilters: filters } = useStore(
    orderDocumentsFiltersStore,
  );

  const [
    documentTemplates,
    setDocumentTemplates,
  ] = useState<DocumentTemplateDtoPagedResult | null>(null);

  const onLimitChange = (limit: number) => {
    setLimit(limit);
    setOffset(0);
  };

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const toggleSelectedId = (id: any) => {
    if (documentIds.includes(id)) {
      setDocumentIds(documentIds.filter((selectedId) => selectedId !== id));
      setRegenerateIds(regenerateIds.filter((selectedId) => selectedId !== id));
    } else {
      setDocumentIds([...documentIds, id]);
    }
  };

  const toggleRegenerateIds = (id: number) => {
    if (regenerateIds.includes(id)) {
      setRegenerateIds(regenerateIds.filter((selectedId) => selectedId !== id));
    } else {
      setRegenerateIds([...regenerateIds, id]);
    }
  };

  const isDocumentSelected = (id: any) => {
    return documentIds.includes(id);
  };

  const getData = (filter?: string) => {
    getDocumentTemplatesFx({
      offset,
      limit,
      sort,
      filter: filter
        ? filter + ' AND ' + documentTemplateFilter
        : documentTemplateFilter,
      search,
    }).then(
      (documentTemplatesData) => {
        setDocumentTemplates(documentTemplatesData);
      },
      () => {},
    );
  };

  const changeLimit = (documnetslimit: number) => {
    setLimit(documnetslimit);
  };

  const onDocumentsSearch = (searchText) => {
    setSearch(searchText);
  };

  useEffect(() => {
    getData();
  }, [offset, limit, search]);

  const createOrderDocumentsForOrder = async (order: OrderEditFormDto) => {
    const updatedOrder = onSaveOrder(order);
    await updateOrder(updatedOrder);

    if (documentIds.length > 0) {
      const values = documentIds.map((id) => {
        const createValues: CreateOrderDocumentsCommandValues = {
          documentTemplateId: id,
          regenerateOnOrderChange: regenerateIds.includes(id),
          metadata: {
            orderId: order?.orderId.toString(),
            orderPickupId: order?.orderEntities
              .find((entity) => entity.entityType === EntityTypes.Shipper)
              ?.orderEntityId.toString(),
            orderDeliveryId: order?.orderEntities
              .find((entity) => entity.entityType === EntityTypes.Consignee)
              ?.orderEntityId.toString(),
            thirdPartyContactId: '',
            carrierId: '',
            customerId: order?.billToContactId?.toString() ?? '',
          },
        };
        return createValues;
      });

      return createOrderDocumentsFx({ order, values });
    }
    return Promise.resolve();
  };

  const onSubmit = async () => {
    setIsSending(true);
    try {
      await createOrderDocumentsForOrder(orderContext);
      onOrderDocumentsCreated();
    } catch (error) {
      addMessage({
        message: error.message,
        type: 'danger',
        id: v4(),
      });
    } finally {
      setIsSending(false);
    }
  };

  const onFilterDocumentTemplates = (query: string) => {
    getData(query);
  };

  const onCreateOrderDocumentDialog = () => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Create a new document`,
        message: 'Order will be updated, are you sure?',
        className: 'delete-modal',
      },
    }).then(async (result) => {
      if (result) {
        onSubmit();
      }
    });
  };

  return (
    <div className="py-4">
      <Form onSubmit={onCreateOrderDocumentDialog}>
        <div className={'d-flex align-items-center mb-4 pr-0 pl-0'}>
          <div className={'w-100 d-flex pl-0 pr-4'}>
            <div className={'d-flex align-items-center col-12 pl-0'}>
              <h3 className={'m-0 col-2'}>Adding documents</h3>
              <div className={'d-flex align-items-center w-100 pr-2'}>
                <FiSearch
                  className={'position-relative'}
                  style={{ left: '30px' }}
                />
                <input
                  type="search"
                  className={'form-control w-50 search-with-icon w-auto'}
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                  placeholder={'Search'}
                />
              </div>
              <div className="col-1">
                <OrderDocumentsFiltersSelector
                  filters={filters}
                  checkedFilters={checkedFilters}
                  setCheckedFilters={setCheckedFilters}
                />
              </div>
            </div>
          </div>
        </div>
        {checkedFilters?.length > 0 && (
          <div className="order-document-tabs mb-4 py-3 pl-4">
            <OrderDocumentsFilters
              filters={checkedFilters}
              onFilter={onFilterDocumentTemplates}
            ></OrderDocumentsFilters>
          </div>
        )}
        <OrderDocumentsGrid
          onSearch={onDocumentsSearch}
          search={search}
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={['documentTemplateId']}
          data={documentTemplates?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={documentTemplates?.totalCount}
          sort={sort}
          onSort={setSort}
          onEdit={null}
          onPageChanged={onPageChanged}
          onColumnsChanged={null}
          onSelect={null}
          setIds={toggleSelectedId}
          isSelected={isDocumentSelected}
          isCheckBoxDisabled={
            isBolCreationDisabled !== null ? isBolCreationDisabled : null
          }
          hideColumnsSelect={true}
          onLimitChange={changeLimit}
          isDropDownList={true}
          showToolbar={false}
          showPagination={true}
          setRegenerateIds={toggleRegenerateIds}
          isInRegenerateIds={(id) => regenerateIds?.includes(id)}
        />
        <div>
          <div className="justify-content-end d-flex col-12 px-0 pt-5">
            {saveButtonRenderCondition && (
              <div className="col-6 pl-0">
                <Button
                  name="add-orderDocuments"
                  type="submit"
                  className={
                    documentIds?.length < 1
                      ? 'btn-block btn-secondary'
                      : 'btn-block btn-outline-secondary'
                  }
                  disabled={documentIds?.length < 1}
                  isSending={isSending}
                >
                  Add
                </Button>
              </div>
            )}
            <div className="col-6 pr-0">
              <Button
                type="button"
                onClick={onCancel}
                className="col-12 btn btn-outline-secondary"
                disabled={isSending}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
