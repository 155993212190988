import {
  LinkDto,
  LinkResourceBaseDto,
  CustomCodeDtoPagedResult,
  CreateCustomCodeCommandValues,
  UpdateCustomCodeCommandValues,
  CustomCodeDto,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CUSTOMCODES_LINK_KEY = 'get-codes';
export const CREATE_CUSTOMCODE_LINK_KEY = 'create-code';
export const GET_CUSTOMCODE_LINK_KEY = 'get-code';
export const UPDATE_CUSTOMCODE_LINK_KEY = 'update-code';
export const DELETE_CUSTOMCODE_LINK_KEY = 'delete-code';

export const CUSTOMCODE_ENTITY_NAME = 'Customs Code';

export interface GetCustomCodeParams {
  code: string;
}

export const getCustomCodeRequest = async (
  resource: LinkResourceBaseDto | null,
  { code }: GetCustomCodeParams,
): Promise<CustomCodeDto> => {
  if (resource && code) {
    const getCustomCodeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CUSTOMCODE_LINK_KEY,
    );
    if (getCustomCodeLink) {
      const result = await execLink(getCustomCodeLink, { code });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CUSTOMCODE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getCustomCodesListRequest = (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CustomCodeDtoPagedResult> => {
  if (resource) {
    const getCustomCodesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CUSTOMCODES_LINK_KEY,
    );
    if (getCustomCodesListLink) {
      return execLink(getCustomCodesListLink, params).then((result: any) => {
        return result.data;
      });
    }
  }
  addMessage({
    message: `Action ${GET_CUSTOMCODES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createCustomCodeRequest = (
  resource: LinkResourceBaseDto,
  customCode: CreateCustomCodeCommandValues,
) => {
  const createCustomCodeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CUSTOMCODE_LINK_KEY,
  );
  if (createCustomCodeLink) {
    return execLink(createCustomCodeLink, customCode).then((result: any) => {
      return result.data;
    });
  }
  addMessage({
    message: `Action ${CREATE_CUSTOMCODE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateCustomCodeRequest = (
  resource: LinkResourceBaseDto,
  customCode: UpdateCustomCodeCommandValues,
) => {
  const updateCustomCodeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CUSTOMCODE_LINK_KEY,
  );
  if (updateCustomCodeLink) {
    return execLink(updateCustomCodeLink, customCode).then((result: any) => {
      return result.data;
    });
  }
  addMessage({
    message: `Action ${UPDATE_CUSTOMCODE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteCustomCodeRequest = (customCode: LinkResourceBaseDto) => {
  const deleteCustomcodeLink = customCode.links?.find(
    (x: LinkDto) => x.rel === DELETE_CUSTOMCODE_LINK_KEY,
  );
  if (deleteCustomcodeLink) {
    return execLink(deleteCustomcodeLink, customCode).then((result: any) => {
      return result.data;
    });
  }
  addMessage({
    message: `Action ${DELETE_CUSTOMCODE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
