import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ModeOfTransportationsListScreen } from './screen/modeOfTransportation-list.screen';
import { ModeOfTransportationScreen } from './screen/modeOfTransportation.screen';

export const MODE_OF_TRANSPORTATIONS_LIST_PATH = '/mode-of-transportations';
export const MODE_OF_TRANSPORTATION_EDIT_PATH =
  '/mode-of-transportations/:modeOfTransportationId';
export const MODE_OF_TRANSPORTATION_CREATE_PATH = '/mode-of-transportations/0';

export const ModeOfTransportationRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ModeOfTransportationsListScreen
              pageTitle="Mode Of Transportations"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ModeOfTransportationScreen
              pageTitle="Add New Mode Of Transportation"
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':modeOfTransportationId'}
        element={
          <PrivateRoute>
            <ModeOfTransportationScreen
              pageTitle="Update Mode Of Transportation"
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
