import React, { useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AttachmentForm } from './attachment.form';
import {
  AttachmentDto,
  AttachmentParentType,
  AttachmentType,
} from '../../../models/data.models';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import { getEnumKeyByValue, getEnumValues } from '../../../utils/helper.utils';
import { createAttachment } from '../attachments.store';

export type AddAttachmentsProps = {
  files?: File[];
  parentId: number;
  parentType: AttachmentParentType;
  onAttachmentCreated?: (attachments: AttachmentDto[]) => void;
  onAttachmentsUpdated?: (attachments: AttachmentDto[]) => void;
  childIds?: number[];
  attachmentType?: AttachmentType;
  prefix?: string;
};

const initialState: AttachmentDto = {
  attachmentId: 0,
  attachmentType: null,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: null,
  fileName: '',
  fileUri: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  parentId: 0,
  parentType: null,
  previewUri: null,
  thumbnailUri: null,
  createdByUserName: '',
  lastModifiedByUserName: '',
  presignedPreviewUri: null,
  presignedThumbnailUri: null,
  presignedFileUri: null,
  prefix: '',
  links: [],
};

export const AddAttachments = ({
  files = [],
  parentId,
  parentType,
  onAttachmentCreated = () => {},
  childIds,
  attachmentType,
  prefix = '',
}: AddAttachmentsProps) => {
  const [isSending, setIsSending] = useState(false);
  const [initialValues, setInitialValues] = useState<AttachmentDto[] | null>(
    files.map((file) => ({
      ...initialState,
      parentId: parentId,
      parentType: parentType,
      fileName: !!prefix.trim() ? `${prefix}~${file.name}` : file.name,
      attachmentType: attachmentType,
      prefix,
    })),
  );

  const [attachments, setAttachments] = useState<AttachmentDto[] | null>(
    initialValues,
  );
  const onAttachmentTypeChange = (
    newValueAttachmentType: ReactSelectItem,
    index,
  ) => {
    setAttachments((attachmentsDto) => {
      const attachmentDto = attachmentsDto[index];
      if (newValueAttachmentType) {
        attachmentDto.attachmentType = getEnumKeyByValue(
          newValueAttachmentType?.label,
          AttachmentType,
        );
      } else {
        attachmentDto.attachmentType = null;
      }
      return [...attachmentsDto];
    });
  };

  const onSubmit = (data: AttachmentDto[]) => {
    setIsSending(true);
    const createAttachmentsPromises = data.map((attachment, index) =>
      createAttachment({ attachmentData: attachment, file: files[index] }),
    );
    if (childIds?.length > 0) {
      childIds.forEach((childId) => {
        data.map((attachment, index) =>
          createAttachment({
            attachmentData: { ...attachment, parentId: childId },
            file: files[index],
          }),
        );
      });
    }
    Promise.all(createAttachmentsPromises)
      .then((attachments) => {
        const resolvedAttachments: AttachmentDto[] = attachments as AttachmentDto[];
        onAttachmentCreated(resolvedAttachments);
        setIsSending(false);
      })
      .catch((error) => {
        console.error(error);
        setIsSending(false);
      });
  };

  return (
    <Panel className="m-4">
      <AttachmentForm initialValues={initialValues} onSubmit={onSubmit}>
        {attachments.map((attachment, index) => (
          <div className={'row'} key={index}>
            <div className="col-4">
              <AttachmentForm.FileName
                name={`[${index}].fileName`}
                disabled={true}
                className={'w-100'}
                label="File name"
              />
            </div>
            <div className="col-4">
              <AttachmentForm.AttachmentType
                id={`[${index}].attachmentType`}
                name={`[${index}].attachmentType`}
                disabled={false}
                required={true}
                defaultValue={
                  attachment?.attachmentType
                    ? {
                        label: AttachmentType[attachment?.attachmentType],
                        value: attachment?.attachmentType,
                      }
                    : null
                }
                onChange={(value) => onAttachmentTypeChange(value, index)}
                multiple={false}
                options={getEnumValues(AttachmentType).filter(
                  (item) => item.label !== 'Avatar',
                )}
              />
            </div>
            <div className="col-4">
              <AttachmentForm.Description name={`[${index}].description`} />
            </div>
          </div>
        ))}
        <Button
          name="save-attachment"
          type="submit"
          color="primary"
          isSending={isSending}
          className="btn-block"
        >
          Save Attachment
        </Button>
      </AttachmentForm>
    </Panel>
  );
};
