import {
  PackageCategoryDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreatePackageCategoryCommandValues,
  UpdatePackageCategoryCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPackageCategoryRequest,
  getPackageCategoriesListRequest,
  getPackageCategoryRequest,
  deletePackageCategoryRequest,
  updatePackageCategoryRequest,
  importPackageCategoriesRequest,
  exportPackageCategoriesRequest,
  GetPackageCategoryParams,
} from './packageCategories.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type PackageCategoriesStoreState = {
  links: LinkDto[];
  packageCategoryColumns: EntityFieldDto[];
  defaultPackageCategoryColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getPackageCategoriesColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'PackageCategory',
  });
});

addEffectStatusHandling(getPackageCategoriesColumnsFx);

export const getPackageCategoriesDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'PackageCategory',
  });
});

addEffectStatusHandling(getPackageCategoriesDefaultColumnsFx);

export const updatePackageCategoriesColumns = createEvent<EntityFieldDto[]>();

export const getPackageCategoriesFx = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getPackageCategoriesListRequest(currentOrganization, params);
  },
);

addEffectStatusHandling(getPackageCategoriesFx);

export const createPackageCategoryFx = createEffect(
  (packageCategoryData: PackageCategoryDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createPackageCategoryCommand: CreatePackageCategoryCommandValues = {
      ...packageCategoryData,
    };

    return createPackageCategoryRequest(
      currentOrganization!,
      createPackageCategoryCommand,
    );
  },
);

addEffectStatusHandling(createPackageCategoryFx, {
  completeMessage: 'Package category was created successfully',
  errorMessage: 'Package category was not created',
});

export const getPackageCategoryFx = createEffect(
  (packageCategoryParams: GetPackageCategoryParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getPackageCategoryRequest(
      currentOrganization as LinkResourceBaseDto,
      packageCategoryParams,
    );
  },
);

addEffectStatusHandling(getPackageCategoryFx);

export const updatePackageCategoryFx = createEffect(
  (packageCategory: PackageCategoryDto) => {
    const updatePackageCategoryCommand: UpdatePackageCategoryCommandValues = {
      ...packageCategory,
    };
    return updatePackageCategoryRequest(
      packageCategory,
      updatePackageCategoryCommand,
    );
  },
);

addEffectStatusHandling(updatePackageCategoryFx, {
  completeMessage: 'Package category was updated successfully',
  errorMessage: 'Package category was not updated',
});

export const deletePackageCategoryFx = createEffect(
  (packageCategory: PackageCategoryDto) => {
    return deletePackageCategoryRequest(packageCategory);
  },
);

addEffectStatusHandling(deletePackageCategoryFx, {
  completeMessage: 'Package category was deleted successfully',
  errorMessage: 'Package category was not deleted',
});

export const exportPackageCategoriesFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportPackageCategoriesRequest(
      listResource as LinkResourceBaseDto,
      params,
    );
  },
);
addEffectStatusHandling(exportPackageCategoriesFx, {
  completeMessage: 'Package categories were exported successfully',
  errorMessage: 'Package categories were not exported',
});

export const importPackageCategoriesFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importPackageCategoriesRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importPackageCategoriesFx, {
  completeMessage: 'Package categories were imported successfully',
  errorMessage: 'Package categories were not imported',
  inFlightMessage: 'Importing package categories...',
});

const defaultState: PackageCategoriesStoreState = {
  links: [],
  packageCategoryColumns: [],
  defaultPackageCategoryColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const packageCategoryStore: Store<PackageCategoriesStoreState> = createStore(
  defaultState,
)
  .on(getPackageCategoriesColumnsFx.done, (state, payload) => {
    return {
      ...state,
      packageCategoryColumns: payload.result.items,
      defaultPackageCategoryColumns: payload.result.items,
    };
  })
  .on(getPackageCategoriesDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultPackageCategoryColumns: payload.result.items,
    };
  })
  .on(updatePackageCategoriesColumns, (state, payload) => {
    return { ...state, packageCategoryColumns: payload };
  });
