import {
  CreateOrderCommandValues,
  LinkDto,
  LinkResourceBaseDto,
  OrderDto,
  OrderDtoPagedResult,
  PatchCommandBase,
  UpdateOrderCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CARGO_MOVEMENTS_LINK_KEY = 'get-orders';
export const CREATE_CARGO_MOVEMENT_LINK_KEY = 'create-order';
export const GET_CARGO_MOVEMENT_LINK_KEY = 'get-order';
export const UPDATE_CARGO_MOVEMENT_LINK_KEY = 'update-order-movement';
export const PATCH_CARGO_MOVEMENT_LINK_KEY = 'patch-order';
export const DELETE_CARGO_MOVEMENT_LINK_KEY = 'delete-order';
export const GET_CARGO_MOVEMENT_CONFIRMATION_LINK_KEY =
  'get-order-confirmation';
export const GET_CARGO_MOVEMENTS_CONTACT_LINK_KEY =
  'get-orders-contact-commodities';

export interface GetOrderParams {
  orderId: number;
}
export interface GetOrderConfirmationParams {
  orderId: number;
}
export interface PatchOrderParams {
  resource: LinkResourceBaseDto;
  patchOrderCommand: PatchCommandBase;
}
export const getOrderRequest = async (
  resource: LinkResourceBaseDto | null,
  { orderId }: GetOrderParams,
): Promise<OrderDto> => {
  if (resource && orderId) {
    const getOrderLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CARGO_MOVEMENT_LINK_KEY,
    );
    if (getOrderLink) {
      const result = await execLink(getOrderLink, { orderId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CARGO_MOVEMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrdersListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderDtoPagedResult> => {
  if (resource) {
    const getOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CARGO_MOVEMENTS_LINK_KEY,
    );
    if (getOrdersListLink) {
      const result = await execLink(getOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CARGO_MOVEMENTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: CreateOrderCommandValues,
) => {
  const createOrderLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CARGO_MOVEMENT_LINK_KEY,
  );
  if (createOrderLink) {
    const result = await execLink(createOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CARGO_MOVEMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateMovementRequest = async (
  resource: LinkResourceBaseDto,
  order: UpdateOrderCommandValues,
) => {
  const updateOrderLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CARGO_MOVEMENT_LINK_KEY,
  );
  if (updateOrderLink) {
    const result = await execLink(updateOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CARGO_MOVEMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const patchOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: PatchCommandBase,
) => {
  const patchOrderLink = resource.links?.find((x: LinkDto) => {
    return x.rel === PATCH_CARGO_MOVEMENT_LINK_KEY;
  });
  if (patchOrderLink) {
    const result = await execLink(patchOrderLink, order?.patchItems);
    return result.data;
  }
  addMessage({
    message: `Action ${PATCH_CARGO_MOVEMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteOrderRequest = async (order: OrderDto) => {
  const deleteOrderLink = order.links?.find(
    (x: LinkDto) => x.rel === DELETE_CARGO_MOVEMENT_LINK_KEY,
  );
  if (deleteOrderLink) {
    const result = await execLink(deleteOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_CARGO_MOVEMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrdersContactCommoditiesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderDtoPagedResult> => {
  if (resource) {
    const getOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CARGO_MOVEMENTS_CONTACT_LINK_KEY,
    );
    if (getOrdersListLink) {
      const result = await execLink(getOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CARGO_MOVEMENTS_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
