import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CustomCodesListScreen } from './screens/customCodes-list.screent';
import { CustomCodeScreen } from './components/customCode-screen';

export const CUSTOMCODES_LIST_PATH = '/custom-codes';
export const CUSTOMCODE_EDIT_PATH = '/custom-codes/:code';
export const CUSTOMCODE_CREATE_PATH = '/custom-codes/create';

export const CustomCodesRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <CustomCodesListScreen pageTitle="Customs Codes" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'create'}
        element={
          <PrivateRoute>
            <CustomCodeScreen pageTitle="Add New Code" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':code'}
        element={
          <PrivateRoute>
            <CustomCodeScreen pageTitle="Update Code" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
