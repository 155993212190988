import React, { CSSProperties, useState } from 'react';

import {
  FaArrowDown,
  FaArrowUp,
  FaChevronDown,
  FaChevronUp,
  FaFilter,
} from 'react-icons/fa';
import { BsFilter, BsListTask } from 'react-icons/bs';
import { Filters } from '../../../common/components/filters/filters.component';
import { Button } from '../../../common/components/button/button.component';
import { showDialog } from '../../../common/dialog.store';
import { ContactDialog } from '../../../contacts/components/contact.dialog';
import { Pagination } from '../../../common/components/pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import {
  Column,
  CommodityDto,
  StatusStage,
} from '../../../../models/data.models';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import { CommoditySelector } from './commoditySelector.component';
import { WizardZoneView } from './wizardZoneView.component';
import { StatusBadge } from '../../../common/components/status-badge/status-badge';

export type GridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: any[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  disableDots?: boolean;
  isTab?: boolean;
  parentId?: number;
  addButtonRenderCondition?: boolean;
  setIds?: (selectedItem: any, toggleState: boolean, orderOfItem?: any) => void;
  isSelected: (selectedValue: any) => boolean;
  isCheckBoxDisabled?: (value: any) => boolean | null;
  onLimitChange?: (limit: number) => void;
  handleNext?: () => void;
  handleBack?: () => void;
};

export const WizardGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onColumnsChanged = () => {},
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  getContactsData,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
  loading,
  disableDots,
  isTab,
  parentId,
  addButtonRenderCondition = true,
  setIds,
  isSelected,
  isCheckBoxDisabled,
  onLimitChange,
  handleNext,
  handleBack,
}: GridProps) => {
  const colByName: { [key: string]: any } = {};
  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return onColumnsChanged(columns);
  };
  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col?.name === colName)?.sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };
  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };
  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRowsParentZone, setExpandedRowsParentZone] = useState([]);

  const handleExpandRow = (event, index, item = null) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(index);
      const newExpandedRows = isRowExpanded
        ? prevExpandedRows.filter((id) => id !== index)
        : prevExpandedRows.concat(index);
      return newExpandedRows;
    });
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case 'Delivered':
        return 'btn btn-outline-info';
      case 'Completed':
        return 'btn btn-outline-info';
      case 'Cancelled':
        return 'btn btn-outline-danger';
      default:
        return 'btn btn-outline-warning';
    }
  };

  const handleExpandRowParentZone = (event, index) => {
    setExpandedRowsParentZone((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(index);
      const newExpandedRows = isRowExpanded
        ? prevExpandedRows.filter((id) => id !== index)
        : prevExpandedRows.concat(index);
      return newExpandedRows;
    });
  };

  return (
    <div className={`grid ${className} mx-3 shadow-none`} style={style}>
      <div
        className={
          isDropDownList === true
            ? 'd-flex flex-wrap px-3 justify-content-start'
            : 'd-flex pl-3'
        }
      >
        {isTab && addButtonRenderCondition ? (
          <div className="offset-9 col-2">
            <Button
              size={'sm'}
              color="secondary"
              className="w-100 h-100"
              name="create-contact"
              onClick={() => {
                showDialog({
                  dialog: ContactDialog,
                  props: {
                    contactId: 0,
                    title: 'Create Contact',
                    isTab: true,
                    parentId: parentId,
                  },
                }).then((contact) => {
                  if (contact !== null) {
                    getContactsData();
                  }
                });
              }}
            >
              Add New Contact
            </Button>
          </div>
        ) : null}

        {onSearch ? (
          <div className="col-12 px-0 d-flex flex-row justify-content-between align-items-center">
            <div className="mt-2" style={{ position: 'relative' }}>
              <FaFilter
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  zIndex: '1',
                  color: '#CED4DA',
                }}
              />
              <input
                type="search"
                className={
                  (isDropDownList === true
                    ? 'w-100 col-3 form-control pl-8 '
                    : ' ') + 'form-control my-2 pl-8'
                }
                placeholder={
                  rowKeys.includes('documentTemplateId')
                    ? 'Search'
                    : 'Filter by keyword or tracking number'
                }
                value={decodeURIComponent(search)}
                onChange={updateSearch}
                style={{ minWidth: '22rem', minHeight: '2.5rem' }}
              />
            </div>
            <div
              className={
                rowKeys.includes('documentTemplateId') ? 'd-none' : 'mt-2'
              }
              style={{ fontSize: '20px' }}
            >
              {/*<small className="align-middle pl-1">Delivered</small>
              <small className="align-middle pl-4">In transit</small>
              <small className="align-middle pl-4">Cancelled</small>
              <small className="align-middle pl-4">In warehouse</small>*/}
            </div>
          </div>
        ) : null}
        {onFilter ? (
          <div
            className={
              isDropDownList === true
                ? 'dropdown dropdown-columns my-3 px-auto mx-auto w-auto col-auto'
                : 'dropdown dropdown-columns my-3 px-3 w-100'
            }
          >
            {columns.some((column) => column.showFilter) && (
              <span
                className="dropdown-toggle pointer"
                id="dropdownFilterButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <BsFilter />
                &nbsp;
                <small className="align-middle pl-1 text-uppercase">
                  Filters
                </small>
                &nbsp;
                <small className="align-middle text-primary">
                  {getReadableQueryString(filter, getFilteredRowsFilter())}
                </small>
              </span>
            )}
            {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
              <div
                className="dropdown-menu px-5"
                aria-labelledby="dropdownFilterButton"
              >
                <div>
                  <Filters
                    query={filter}
                    columns={getFilteredRowsFilter()}
                    onFilter={updateFilter}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {hideColumnsSelect ? null : (
          <div
            className={
              isDropDownList === true
                ? 'dropdown dropdown-columns my-3 px-3 mx-auto pointer col-auto'
                : 'dropdown dropdown-columns my-3 px-3 ml-auto pointer'
            }
          >
            <div
              className="dropdown-toggle"
              id="dropdownColumnsButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsListTask className="" />
              &nbsp;
              <small className="align-middle pl-1 text-uppercase">
                Columns
              </small>
            </div>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownColumnsButton"
              style={{ width: 'max-content', maxWidth: 'unset' }}
            >
              {columns
                .filter((item) => {
                  if (showAllStore) {
                    return true;
                  }
                  return rowKeys?.includes(item.name);
                })
                .map((col) => {
                  return (
                    <label key={col.name} className="dropdown-item pointer">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={() => updateCols(col.name)}
                        defaultChecked={col.visible}
                      />{' '}
                      {col.title}
                    </label>
                  );
                })}
            </div>
          </div>
        )}
        {actionBar && <div className={'mr-3 py-2'}>{actionBar}</div>}
      </div>
      {total > 0 || showEmptyTable === true ? (
        <div className="mt-6">
          <table className="table">
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th
                        scope="col"
                        key={col.name}
                        style={
                          col.name === 'currencySymbol'
                            ? { textAlign: 'center' }
                            : { backgroundColor: '' }
                        }
                      >
                        <a
                          className={col.sortName ? 'link' : 'inactive-link'}
                          onClick={() =>
                            col.sortName ? updateSort(col.name) : null
                          }
                        >
                          {col.title}
                          {sort === col.name || sort === col.sortName ? (
                            <FaArrowDown />
                          ) : null}
                          {sort === '-' + col.name ||
                          sort === '-' + col.sortName ? (
                            <FaArrowUp />
                          ) : null}
                        </a>
                      </th>
                    );
                  })}
                <th colSpan={2}>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="table-striped">
              {loading ? (
                <div className="m-5 text-center">
                  <h3 className="text-muted mb-4">Loading...</h3>
                </div>
              ) : (
                data.map((row, index) => {
                  return (
                    <React.Fragment key={index}>
                      {row && row?.warehouseZoneId ? (
                        <WizardZoneView
                          key={row?.warehouseZoneId}
                          zoneDto={row}
                          level={0}
                          columns={columns}
                          getStatusClassName={getStatusClassName}
                          isSelected={isSelected}
                          isCheckBoxDisabled={isCheckBoxDisabled}
                          setIds={setIds}
                        />
                      ) : (
                        <tr
                          key={
                            rowKeys
                              ? rowKeys?.map((x) => row[x]).join('_')
                              : index
                          }
                        >
                          {Object.values(columns)
                            .filter((item) => {
                              if (showAllStore && item.visible) {
                                return true;
                              }
                              return (
                                item.visible && rowKeys?.includes(item.name)
                              );
                            })
                            .map((item) => {
                              return (
                                <td
                                  key={`${rowKeys
                                    ?.map((x) => row[x])
                                    .join('_')}_${item.name}`}
                                  className={`cursor-pointer ${
                                    item.name === 'checkBox'
                                      ? 'justify-content-start'
                                      : ''
                                  }`}
                                  style={
                                    item.name === 'currencySymbol'
                                      ? { textAlign: 'center' }
                                      : {
                                          width: `calc(100% / ${
                                            columns.filter(
                                              (column) =>
                                                column.visible === true,
                                            ).length
                                          })`,
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          textAlign: 'start',
                                        }
                                  }
                                >
                                  {typeof row[item.name] === 'boolean' ? (
                                    row[item.name] ? (
                                      <>&#x2713;</>
                                    ) : (
                                      <></>
                                    )
                                  ) : item.name == 'created' ||
                                    item.name == 'lastModified' ||
                                    item.name == 'updated' ? (
                                    <div className={'text-center'}>
                                      {getFormattedDate(
                                        (row[item.name] as string).split(
                                          ',',
                                        )[0],
                                        false,
                                      )}
                                    </div>
                                  ) : item.type === 'checkBox' ? (
                                    <span
                                      title={
                                        isCheckBoxDisabled(row[item.name]) ===
                                          true &&
                                        item.name === 'documentTemplateId'
                                          ? 'Select shipper and ultimate consignee to generate BOL'
                                          : ''
                                      }
                                    >
                                      <label className="custom-control custom-checkbox d-inline-block mb-1">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input m-0"
                                          onChange={(event) => {
                                            setIds(
                                              row[item.name],
                                              event.target.checked,
                                              row,
                                            );
                                          }}
                                          checked={isSelected(row[item.name])}
                                          disabled={
                                            isCheckBoxDisabled
                                              ? isCheckBoxDisabled(
                                                  row[item.name],
                                                )
                                              : false
                                          }
                                        />
                                        <span className="custom-control-label"></span>
                                      </label>
                                      {item.name != 'documentTemplateId' && (
                                        <button
                                          type="button"
                                          className="btn m-0 pl-3 pr-3 pb-2 pt-0 border-0 shadow-none"
                                          onClick={(event) =>
                                            handleExpandRow(event, index, row)
                                          }
                                        >
                                          {expandedRows.includes(index) ? (
                                            <FaChevronUp color="#16467D" />
                                          ) : (
                                            <FaChevronDown color="#16467D" />
                                          )}
                                        </button>
                                      )}
                                      {onSelect ? (
                                        <u
                                          className="font-weight-bold cursor-pointer"
                                          onClick={() => {
                                            onSelect(
                                              row,
                                              rowKeys?.reduce(
                                                (keyObj, field) => {
                                                  return row[field];
                                                },
                                              ),
                                            );
                                          }}
                                        >
                                          {row[item?.labelToShow]}
                                        </u>
                                      ) : (
                                        <>{row[item?.labelToShow]}</>
                                      )}
                                    </span>
                                  ) : item.name == 'orderStatus' ? (
                                    <StatusBadge
                                      label={row?.orderStatus?.orderStatusName}
                                      stage={row?.orderStatus?.statusStage}
                                      enumType={StatusStage}
                                    />
                                  ) : (
                                    <div className={'text-center'}>
                                      {row[item.name]}
                                    </div>
                                  )}
                                </td>
                              );
                            })}
                          <td style={{ textAlign: 'center' }} />
                        </tr>
                      )}

                      {expandedRows.includes(index) && (
                        <React.Fragment>
                          <tr>
                            <td colSpan={columns?.length + 1}>
                              <div className="mt-3">
                                {row?.commodities &&
                                  row?.commodities?.map(
                                    (commodity: CommodityDto) => (
                                      <CommoditySelector
                                        order={row}
                                        commodity={commodity}
                                        columns={columns}
                                        isSelected={isSelected}
                                        isCheckBoxDisabled={isCheckBoxDisabled}
                                        getStatusClassName={getStatusClassName}
                                        setIds={setIds}
                                      ></CommoditySelector>
                                    ),
                                  )}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > limit && showPagination === true ? (
        <div className="d-flex flex-row justify-content-between grid-footer">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
            onLimitChanged={onLimitChange}
          />
        </div>
      ) : null}
      <div className="row mt-5 d-flex flex-row-reverse ml-auto">
        {handleNext && (
          <Button
            className="mr-3"
            color="primary"
            onClick={handleNext}
            style={{ minWidth: '8.5rem' }}
          >
            Next
          </Button>
        )}
        {handleBack && (
          <Button
            className="mr-3"
            onClick={handleBack}
            style={{ minWidth: '8.5rem' }}
          >
            Back
          </Button>
        )}
      </div>
    </div>
  );
};
