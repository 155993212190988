import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { VendorScreen } from './screens/vendor.screen';
import { VendorListScreen } from './screens/vendor-list.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_VENDORS } from '../contacts/contact.route';

export const VENDORS_LIST_PATH = '/vendors';
export const VENDORS_EDIT_PATH = '/vendors/:contactId';
export const VENDORS_CREATE_PATH = '/vendors/0';

export const VendorRoute = (props: RouteProps) => {
  const group = 'Maintenance';

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <VendorListScreen pageTitle="Vendors" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <VendorScreen pageTitle="Add New Vendor" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':contactId'}
        element={
          <PrivateRoute>
            <VendorScreen pageTitle="Update Vendor" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':vendorId/contacts/:contactId'}
        element={
          <PrivateRoute>
            <ContactScreen pageTitle="Contacts" isTab={true} group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
