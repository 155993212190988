import {
  ContactDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreateContactCommandValues,
  UpdateContactCommandValues,
  ContactType,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContactRequest,
  getContactsListRequest,
  getContactRequest,
  deleteContactRequest,
  updateContactRequest,
  importContactsRequest,
  exportContactsRequest,
  GetContactParams,
  createContactAndContactAddressesRequest,
  updateContactAndContactAddressesRequest,
} from './contacts.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';
import {
  CreateContactAndContactAddressesCommand,
  UpdateContactAndContactAddressesCommand,
} from '../../models/custom.models';

type ContactsStoreState = {
  links: LinkDto[];
  contactColumns: EntityFieldDto[];
  defaultContactColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getContactsColumns = createEffect((contactType: ContactType) => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: contactType,
  });
});

addEffectStatusHandling(getContactsColumns);

export const getContactsDefaultColumns = createEffect(
  (contactType: ContactType) => {
    const { currentOrganization } = organizationsStore.getState();
    return getEntityFieldListRequest(currentOrganization, {
      entityName: contactType,
    });
  },
);

addEffectStatusHandling(getContactsDefaultColumns);

export const updateContactsColumns = createEvent<EntityFieldDto[]>();

export const getContacts = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContactsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getContacts);

export const createContact = createEffect((contactData: ContactDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createContactCommand: CreateContactCommandValues = { ...contactData };

  return createContactRequest(currentOrganization!, createContactCommand);
});

export const updateContactAndContactAddresses = createEffect(
  (updateContactCommand: UpdateContactAndContactAddressesCommand) => {
    if (
      !updateContactCommand.shippingAddress?.addressLine &&
      !updateContactCommand.shippingAddress?.addressLine2 &&
      !updateContactCommand.shippingAddress?.cityName
    ) {
      delete updateContactCommand.shippingAddress;
    }
    if (
      !updateContactCommand.billingAddress?.addressLine &&
      !updateContactCommand.billingAddress?.addressLine2 &&
      !updateContactCommand.billingAddress?.cityName
    ) {
      delete updateContactCommand.billingAddress;
    }
    if (updateContactCommand.contact?.emailAddress === '') {
      updateContactCommand.contact.emailAddress = null;
    }
    return updateContactAndContactAddressesRequest(
      updateContactCommand.contact,
      updateContactCommand,
    );
  },
);

export const createContactAndContactAddresses = createEffect(
  (contactData: CreateContactAndContactAddressesCommand) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const organizationId = currentOrganization.organizationId;
    if (contactData.contact == null) {
      contactData.contact = {};
    }
    contactData.contact.organizationId = organizationId;
    if (
      !contactData.shippingAddress?.addressLine &&
      !contactData.shippingAddress?.addressLine2 &&
      !contactData.shippingAddress?.cityName
    ) {
      delete contactData.shippingAddress;
    }
    if (
      !contactData.billingAddress?.addressLine &&
      !contactData.billingAddress?.addressLine2 &&
      !contactData.billingAddress?.cityName
    ) {
      delete contactData.billingAddress;
    }
    if (
      contactData.shippingAddress !== null &&
      contactData.shippingAddress !== undefined
    ) {
      contactData.shippingAddress.organizationId = organizationId;
    }
    if (
      contactData.billingAddress !== null &&
      contactData.billingAddress !== undefined
    ) {
      contactData.billingAddress.organizationId = organizationId;
    }
    if (contactData.contact?.emailAddress === '') {
      contactData.contact.emailAddress = null;
    }

    return createContactAndContactAddressesRequest(
      currentOrganization!,
      contactData,
    );
  },
);

addEffectStatusHandling(createContact, {
  completeMessage: 'Contact was created successfully',
  errorMessage: 'Contact was not created',
});

export const getContact = createEffect((contactParams: GetContactParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContactRequest(
    currentOrganization as LinkResourceBaseDto,
    contactParams,
  );
});

addEffectStatusHandling(getContact);

export const updateContact = createEffect((contact: ContactDto) => {
  const updateContactCommand: UpdateContactCommandValues = { ...contact };
  return updateContactRequest(contact, updateContactCommand);
});

addEffectStatusHandling(updateContact, {
  completeMessage: 'Contact was updated successfully',
  errorMessage: 'Contact was not updated',
});

export const deleteContact = createEffect((contact: ContactDto) => {
  return deleteContactRequest(contact);
});

addEffectStatusHandling(deleteContact, {
  completeMessage: 'Contact was deleted successfully',
  errorMessage: 'Contact was not deleted',
});

export const exportContacts = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportContactsRequest(listResource as LinkResourceBaseDto, params);
  },
);

addEffectStatusHandling(exportContacts, {
  completeMessage: 'Contacts were exported successfully',
  errorMessage: 'Contacts were not exported',
});

export const importContacts = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importContactsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);

addEffectStatusHandling(importContacts, {
  completeMessage: 'Contacts were imported successfully',
  errorMessage: 'Contacts were not imported',
  inFlightMessage: 'Importing contacts...',
});

const defaultState: ContactsStoreState = {
  links: [],
  contactColumns: [],
  defaultContactColumns: [],
  defaultSort: 'contactId',
  defaultLimit: 20,
};

export const contactStore: Store<ContactsStoreState> = createStore(defaultState)
  .on(getContactsColumns.done, (state, payload) => {
    return {
      ...state,
      contactColumns: payload.result.items,
      defaultContactColumns: payload.result.items,
    };
  })
  .on(getContactsDefaultColumns.done, (state, payload) => {
    return {
      ...state,
      defaultContactColumns: payload.result.items,
    };
  })
  .on(updateContactsColumns, (state, payload) => {
    return { ...state, contactColumns: payload };
  });
