import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ClausesListScreen } from './screens/clause-list.screen';
import { ClauseScreen } from './screens/clause.screen';

export const CLAUSES_LIST_PATH = '/clauses';
export const CLAUSE_EDIT_PATH = '/clauses/:clauseId';
export const CLAUSE_CREATE_PATH = '/clauses/0';

export const ClausesRoute = () => {
  const group = 'Maintenance';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <ClausesListScreen pageTitle="Clauses" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <ClauseScreen pageTitle="Add New Clause" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':clauseId'}
        element={
          <PrivateRoute>
            <ClauseScreen pageTitle="Update Clause" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
