import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { RatesList } from '../components/rates-list.component';
import { CREATE_RATE_LINK_KEY, GetRateParams } from '../rates.service';
import { userHas } from '../../auth/auth.store';
import { getRatesDefaultColumnsFx, rateStore } from '../rates.store';
import { useStore } from 'effector-react';
import { RateType } from '../../../models/data.models';
import {
  STANDARD_CLIENT_RATES_LIST_PATH,
  STANDARD_CLIENT_RATE_CREATE_PATH,
  STANDARD_CLIENT_RATE_EDIT_PATH,
} from '../standard-client-rates.route';
import {
  CLIENT_RATES_LIST_PATH,
  CLIENT_RATE_CREATE_PATH,
  CLIENT_RATE_EDIT_PATH,
} from '../client-rates.route';
import {
  CARRIER_RATES_LIST_PATH,
  CARRIER_RATE_CREATE_PATH,
  CARRIER_RATE_EDIT_PATH,
} from '../carrier-rates.route';

export type RatesListScreenProps = {
  pageTitle: string;
  rateType: RateType;
  group: string;
};

const getPathByType = (rateType: RateType, create = false, edit = false) => {
  switch (rateType) {
    case RateType.StandardClientRate:
      return create || edit
        ? edit
          ? STANDARD_CLIENT_RATE_EDIT_PATH
          : STANDARD_CLIENT_RATE_CREATE_PATH
        : STANDARD_CLIENT_RATES_LIST_PATH;
    case RateType.ClientRate:
      return create || edit
        ? edit
          ? CLIENT_RATE_EDIT_PATH
          : CLIENT_RATE_CREATE_PATH
        : CLIENT_RATES_LIST_PATH;
    case RateType.CarrierRate:
      return create || edit
        ? edit
          ? CARRIER_RATE_EDIT_PATH
          : CARRIER_RATE_CREATE_PATH
        : CARRIER_RATES_LIST_PATH;
  }
};

const generateHistoryPath = (
  organizationId: string,
  rateType: RateType,
  params: {
    offset: number;
    limit: number;
    sort: string;
    filter: string;
    search: string;
    viewName?: string;
  },
) => {
  const path = generatePath(INTERNAL_PATH + getPathByType(rateType), {
    organizationId,
  });
  // Convert all params values to strings
  // @ts-ignore
  const queryParams = new URLSearchParams(params).toString();
  return `${path}?${queryParams}`;
};

export const RatesListScreen = ({
  pageTitle,
  rateType,
  group,
}: RatesListScreenProps) => {
  const [rateFilter, setRateFilter] = useState<string>(`rateType:${rateType}`);
  // @ts-ignore
  const { organizationId } = useParams();
  const { defaultSort, defaultLimit } = useStore(rateStore);
  const {
    offset = 0,
    limit = defaultLimit,
    filter = rateFilter,
    sort = defaultSort,
    search = '',
    view = '',
  } = useQuery();

  useEffect(() => {
    setRateFilter(`rateType:${rateType}`);
  }, [rateType]);

  const navigate = useNavigate();

  const createPath = useMemo(() => {
    if (userHas(CREATE_RATE_LINK_KEY)) {
      return generatePath(INTERNAL_PATH + getPathByType(rateType, true), {
        organizationId,
      });
    } else {
      return null;
    }
  }, [organizationId]);

  const goToDetails = useCallback(
    (rateParams: GetRateParams) => {
      const path = generatePath(
        INTERNAL_PATH + getPathByType(rateType, false, true),
        {
          organizationId,
          ...rateParams,
        },
      );
      navigate(path);
    },
    [organizationId, navigate],
  );

  const goToCreate = useCallback(() => {
    if (createPath) navigate(createPath);
  }, [createPath]);

  const onDelete = useCallback(() => {
    navigate(navigate.length - 1);
  }, [navigate]);

  const onQueryChange = useCallback(
    (
      newParams: Partial<{
        offset: number;
        limit: number;
        sort: string;
        filter: string;
        search: string;
        view: string;
      }>,
    ) => {
      const params = { offset, limit, sort, filter, search, view };
      const mergedParams = { ...params, ...newParams };
      const newPath = generateHistoryPath(
        organizationId,
        rateType,
        mergedParams,
      );
      navigate(newPath);
    },
    [offset, limit, sort, filter, search, view, organizationId, navigate],
  );

  const onPageChanged = useCallback(
    (page: number) => onQueryChange({ offset: page * limit }),
    [onQueryChange, limit],
  );

  const onLimitChanged = useCallback(
    (l: number) => onQueryChange({ limit: l }),
    [onQueryChange],
  );

  const onSortChanged = useCallback((s: string) => onQueryChange({ sort: s }), [
    onQueryChange,
  ]);

  const onFilterChanged = useCallback(
    (f: string) => onQueryChange({ filter: f, offset: 0 }),
    [onQueryChange],
  );

  const onSearchChanged = useCallback(
    (s: string) => onQueryChange({ search: s, offset: 0 }),
    [onQueryChange],
  );

  const onViewChanged = useCallback(
    (viewName: string, sortField: string, limitNumber: number) => {
      onQueryChange({ view: viewName, sort: sortField, limit: limitNumber });
      getRatesDefaultColumnsFx();
    },
    [onQueryChange],
  );

  return (
    <InternalLayout group={group} title={pageTitle} createPath={createPath}>
      <RatesList
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        search={search}
        goToDetails={goToDetails}
        goToCreate={goToCreate}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSortChanged}
        onFilter={onFilterChanged}
        onLimitChanged={onLimitChanged}
        onSearch={onSearchChanged}
        onViewChanged={onViewChanged}
      />
    </InternalLayout>
  );
};
