import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const CommodityTrackingNumberForm = ({
  children,
  onSubmit,
  initialValues,
}: FormProps) => {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {children}
    </Form>
  );
};

CommodityTrackingNumberForm.SyncOrderId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'syncOrderId'}
      label={'Sync Order Id'}
      placeholder={'Sync Order Id'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CommodityTrackingNumberForm.TrackingNumber = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'trackingNumber'}
      label={'Tracking Number'}
      placeholder={'Tracking Number'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CommodityTrackingNumberForm.TrackingNumberType = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'trackingNumberType'}
      id={'trackingNumberType'}
      label={'Tracking Number Type'}
      placeholder={'Tracking Number Type'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};
CommodityTrackingNumberForm.IsPrimary = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isPrimary'}
      label={'Is primary'}
      placeholder={'Is primary'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
