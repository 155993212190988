import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const BusinessRuleForm = ({
  children,
  onSubmit,
  initialValues,
  validationSchema,
  id,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      id={id}
    >
      {children}
    </Form>
  );
};

BusinessRuleForm.Action = ({
  className,
  style,
  size,
  disabled,
  name,
  id,
  label,
  placeholder,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      required={true}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      id={id}
      onChange={onChange}
      rows={8}
    />
  );
};
BusinessRuleForm.Condition = ({
  className,
  style,
  size,
  disabled,
  name = 'condition',
  id = 'condition',
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      id={id}
      label={''}
      placeholder={'Condition'}
      required={true}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
BusinessRuleForm.TriggerEvent = ({
  className,
  style,
  size,
  disabled,
  name = 'triggerEvent',
  id = 'triggerEvent',
  onChange,
  enumName,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      id={id}
      label={'Trigger Event'}
      placeholder={''}
      required={true}
      type={'enum-select-onChange'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      enumName={enumName}
      onChange={onChange}
    />
  );
};
BusinessRuleForm.Trigger = ({
  className,
  style,
  size,
  disabled,
  name = 'trigger',
  id = 'trigger',
  enumName,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      id={id}
      label={'Trigger'}
      placeholder={''}
      required={true}
      type={'enum-select-onChange'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      enumName={enumName}
      onChange={onChange}
    />
  );
};
BusinessRuleForm.Description = ({
  className,
  style,
  size,
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
BusinessRuleForm.IsEnabled = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'isEnabled'}
      label={'Is Enabled'}
      placeholder={'Is Enabled'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};
BusinessRuleForm.IsSynchronized = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'isSynchronized'}
      label={'Is Synchronized'}
      placeholder={'Is Synchronized'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};
BusinessRuleForm.IsSystem = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'isSystem'}
      label={'Is System'}
      placeholder={'Is System'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
    />
  );
};
BusinessRuleForm.Name = ({
  className,
  style,
  size,
  disabled,
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'input-OnChange-text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};
BusinessRuleForm.Priority = ({
  className,
  style,
  size,
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'priority'}
      label={'Priority'}
      placeholder={'Priority'}
      required={true}
      type={'input-OnChange-number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
