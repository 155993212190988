import React, { useEffect } from 'react';
import { InternalLayout } from '../../../common/components/internal-layout/internal-layout.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../../app.router';
import { CARGO_MOVEMENT_LIST_PATH } from '../../cargoMovement.route';
import { PickingActivityFlow } from '../pickingActivityFlow.component';
import { addMessage, Message } from '../../../common/messages.store';
import { Helmet } from 'react-helmet';
import { setCurrentPagePath } from '../../../common/nav.store';

export type OrderScreenProps = { pageTitle: string; group: string };

export const PickingActivityScreen = ({
  pageTitle,
  group,
}: OrderScreenProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { organizationId, orderId } = useParams();

  const onOrderUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'picking-list-updated',
      type: 'success',
      autoHide: true,
      message: 'Picking list successfully updated!',
    };
    navigate(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(INTERNAL_PATH + CARGO_MOVEMENT_LIST_PATH, {
      organizationId,
    });
    navigate(path);
  };

  useEffect(() => {
    setCurrentPagePath([
      group,
      'Cargo Movements',
      [orderId, '(Picking)'].join(' '),
    ]);
  });

  return (
    <InternalLayout group={group} title={'Picking'} createPath={null}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PickingActivityFlow
        orderId={orderId}
        onOrderUpdated={onOrderUpdated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
