import React from 'react';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { OceanShipmentOrderListScreen } from './screens/oceanShipmentOrders-list.screen';
import { OceanShipmentOrderScreen } from './screens/oceanShipmentOrder.screen';

export const OCEAN_ORDER_LIST_PATH = '/oceanShipmentOrders';
export const OCEAN_ORDER_EDIT_PATH = '/oceanShipmentOrders/:orderId';
export const OCEAN_ORDER_CREATE_PATH = '/oceanShipmentOrders/0';

export const OceanShipmentOrderRoute = (props: RouteProps) => {
  const group = 'Shipment';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <OceanShipmentOrderListScreen
              pageTitle={'Ocean Shipments'}
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <OceanShipmentOrderScreen
              pageTitle={'Add new Ocean Shipment'}
              group={group}
            />
          </PrivateRoute>
        }
      />
      <Route
        path={':orderId'}
        element={
          <PrivateRoute>
            <OceanShipmentOrderScreen
              pageTitle={'Update Ocean Shipment'}
              group={group}
            />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
