import {
  StateDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  StateDtoPagedResult,
  CreateStateCommandValues,
  UpdateStateCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_STATES_LINK_KEY = 'get-states';
export const CREATE_STATE_LINK_KEY = 'create-state';
export const GET_STATE_LINK_KEY = 'get-state';
export const UPDATE_STATE_LINK_KEY = 'update-state';
export const DELETE_STATE_LINK_KEY = 'delete-state';
export const IMPORT_STATES_LINK_KEY = 'import-states';
export const EXPORT_STATES_LINK_KEY = 'export-states';
export const STATE_ENTITY_NAME = 'State';

export interface GetStateParams {
  countryCode: string;
  stateCode: string;
}

export interface ImportStatesParams {
  organizationId: number;
}

export interface ExportStatesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getStateRequest = async (
  resource: LinkResourceBaseDto | null,
  { countryCode, stateCode }: GetStateParams,
): Promise<StateDto> => {
  if (resource && countryCode && stateCode) {
    const getStateLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_STATE_LINK_KEY,
    );
    if (getStateLink) {
      const result = await execLink(getStateLink, { countryCode, stateCode });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_STATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getStatesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<StateDtoPagedResult> => {
  if (resource) {
    const getStatesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_STATES_LINK_KEY,
    );
    if (getStatesListLink) {
      const result = await execLink(getStatesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_STATES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createStateRequest = async (
  resource: LinkResourceBaseDto,
  state: CreateStateCommandValues,
) => {
  const createStateLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_STATE_LINK_KEY,
  );
  if (createStateLink) {
    const result = await execLink(createStateLink, state);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_STATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateStateRequest = async (
  resource: LinkResourceBaseDto,
  state: UpdateStateCommandValues,
) => {
  const updateStateLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_STATE_LINK_KEY,
  );
  if (updateStateLink) {
    const result = await execLink(updateStateLink, state);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_STATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteStateRequest = async (state: StateDto) => {
  const deleteStateLink = state.links?.find(
    (x: LinkDto) => x.rel === DELETE_STATE_LINK_KEY,
  );
  if (deleteStateLink) {
    const result = await execLink(deleteStateLink, state);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_STATE_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importStatesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportStatesParams,
  file: File,
) => {
  const importStatesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_STATES_LINK_KEY,
  );
  if (importStatesLink) {
    const result = await postFormData(importStatesLink, params, [file]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_STATES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportStatesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportStatesParams,
) => {
  const exportStatesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_STATES_LINK_KEY,
  );
  if (exportStatesLink) {
    const result = await downloadLinkFile(exportStatesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_STATES_LINK_KEY} is not allowed or missing link action`,
  );
};
