import React from 'react';
import { OrderDto, CustomFieldDto } from '../../../../models/data.models';
import { Button } from '../../../common/components/button/button.component';
import { DeliveryOrderEntitiesForm } from '../../components/form-items/deliveryOrderEntitiesForm.component';

type DeliveryFragmentProps = {
  oceanOrderContextFormValue: OrderDto;
  setOceanOrderContextFormValue: (order: any) => void;
  customFields: CustomFieldDto[];
  handleNext: () => void;
  handleBack: () => void;
};

export const DeliveryFragment = ({
  oceanOrderContextFormValue,
  setOceanOrderContextFormValue,
  customFields,
  handleNext,
  handleBack,
}: DeliveryFragmentProps) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-3">
          <h2>Delivery information</h2>
        </div>
      </div>
      <DeliveryOrderEntitiesForm
        oceanOrderContextFormValue={oceanOrderContextFormValue}
        setOceanOrderContextFormValue={setOceanOrderContextFormValue}
        customFields={customFields}
      />
      <div className="row mt-5 d-flex flex-row-reverse">
        <Button className="mr-2" color="primary" onClick={handleNext}>
          Next
        </Button>
        <Button className="mr-3" onClick={handleBack}>
          Back
        </Button>
      </div>
    </div>
  );
};
