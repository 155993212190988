import React from 'react';
import { FiColumns, FiLock, FiUnlock } from 'react-icons/fi';
import { Column } from '../../../../../models/data.models';
import Dropdown from 'react-bootstrap/Dropdown';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { HiOutlineSelector } from 'react-icons/hi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GridContextValue } from '../../filters/filtersTab.component';

type GridColumnsSetupProperties = {
  rowKeys?: string[] | null;
  showAllStore?: boolean;
  className?: string;
  useGridContext?: () => GridContextValue;
};

export const GridColumnsSetup = ({
  rowKeys = ['id'],
  showAllStore = false,
  className = '',
  useGridContext,
}: GridColumnsSetupProperties) => {
  const { columns, onColumnsChanged } = useGridContext();

  const colByName: { [key: string]: any } = {};

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return onColumnsChanged(columns);
  };

  const updateColsPosition = (newColumns: Column[]) => {
    return onColumnsChanged(newColumns);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const newColumns = Array.from(columns);
    const [removed] = newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, removed);

    updateColsPosition(newColumns);
  };

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        id="dropdownColumnsButton"
        className="pointer btn-toggle"
        variant="light"
      >
        <FiColumns size="20px" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          width: 'max-content',
          maxWidth: 'unset',
          height: 'max-content',
          maxHeight: '400px',
        }}
        className="scrollbar"
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="columns">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {columns
                  .filter((item) => {
                    if (showAllStore) {
                      return true;
                    }
                    return rowKeys?.includes(item.name);
                  })
                  .map((col, index) => (
                    <Draggable
                      key={col.name}
                      draggableId={col.name}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            visibility: snapshot.isDragging && 'visible',
                          }}
                          className="dropdown-item pointer hover-bg"
                          onClick={() => updateCols(col.name)}
                        >
                          <div className="row justify-content-between">
                            <div
                              className="row pr-4 align-self-center"
                              style={{ userSelect: 'none' }}
                            >
                              <div
                                {...provided.dragHandleProps}
                                className="mr-2 icon-selector"
                              >
                                <HiOutlineSelector size="24px" />
                              </div>
                              <div className="align-self-center btn-toggle-text">
                                {col.title}
                              </div>
                            </div>
                            <div>
                              {col.visible ? (
                                <ImCheckboxChecked
                                  size={'20px'}
                                  color="#066FFC"
                                />
                              ) : (
                                <ImCheckboxUnchecked
                                  size={'20px'}
                                  color="#D0D5DD"
                                />
                              )}
                              {/* TODO
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  alert('Lock');
                                }}
                                className="ml-4"
                              >
                                {col.visible ? (
                                  <FiLock size={'20px'} color="#066FFC" />
                                ) : (
                                  <FiUnlock size={'20px'} color="#D0D5DD" />
                                )}
                              </span> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Dropdown.Menu>
    </Dropdown>
  );
};
