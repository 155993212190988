import { Dialog } from '../../common/components/dialog/dialog.component';
import { DivisionDto } from '../../../models/data.models';
import { DivisionEdit } from './division-edit.component';

export type DivisionDialogProperties = {
  divisionId: number;
  title: string;
};

export class DivisionDialog extends Dialog<DivisionDialogProperties> {
  constructor(props: DivisionDialogProperties) {
    super(props);
    this.onDivisionSaved = this.onDivisionSaved.bind(this);
  }

  onDivisionSaved = (division: DivisionDto) => {
    this.close(division);
  };

  renderContent(): any {
    const { divisionId } = this.props as DivisionDialogProperties;
    return (
      <DivisionEdit
        divisionId={divisionId}
        onDivisionCreated={this.onDivisionSaved}
        onDivisionUpdated={this.onDivisionSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
