import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { AddressType } from '../../../models/data.models';
import { CustomerSelect } from '../../customers/components/customer-select.component';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { SelectStatePaginate } from '../../states/components/state-react-select.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { PlaceAutocompleteInput } from '../../common/components/input/input-placeAutocomplete.component';

export const ContactAddressForm = ({
  children,
  onSubmit,
  initialValues,
  innerRef,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      innerRef={innerRef}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

ContactAddressForm.AddressLine = ({
  name = 'addressLine',
  label = 'Address Line',
  placeholder = 'Address Line',
  defaultValue,
  onSelect,
}: InputPublicProps) => {
  return (
    <PlaceAutocompleteInput
      name={name}
      placeholder={placeholder}
      header={label}
      defaultValue={defaultValue}
      onSelect={onSelect}
    />
  );
};
ContactAddressForm.AddressLine2 = ({
  className,
  style,
  size,
  ref,
  name = 'addressLine2',
  label = 'Address Line2',
  placeholder = 'Address Line2',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactAddressForm.AddressType = ({
  className,
  style,
  size,
  ref,
  name = 'addressType',
  label = 'Address Type',
  placeholder = 'Select Address Type',
  required = true,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'enum-select'}
      enumName={AddressType}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactAddressForm.City = ({
  className,
  style,
  size,
  ref,
  name = 'cityName',
  label = 'City',
  placeholder = 'City',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactAddressForm.ContactId = ({
  className,
  style,
  size,
  ref,
  id = 'contactId',
  nameId = 'Contact Id',
  header = 'Contact Id',
}: InputPublicProps) => {
  return (
    <CustomerSelect id={id} required={true} nameId={nameId} header={header} />
  );
};
ContactAddressForm.CountryCode = ({
  selectedFilter = ``,
  placeholder = 'Select Country',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'countryCode',
  header = 'Country',
  onChange = () => {},
  readonly,
  nameId = 'name',
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
    />
  );
};
ContactAddressForm.PostalCode = ({
  className,
  style,
  size,
  ref,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={'postalCode'}
      label={'Postal Code'}
      placeholder={'Postal Code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactAddressForm.StateCode = ({
  selectedFilter = ``,
  placeholder = 'Select State',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'stateCode',
  header = 'State',
  onChange = () => {},
  readonly,
  nameId = 'name',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectStatePaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeState={onChange}
      isDisabled={disabled}
    />
  );
};
ContactAddressForm.Latitude = ({
  className,
  style,
  size,
  ref,
  name = 'latitude',
  label = 'Latitude',
  placeholder = 'Latitude',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'number'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactAddressForm.Longitude = ({
  className,
  style,
  size,
  ref,
  name = 'longitude',
  label = 'Longitude',
  placeholder = 'Longitude',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'number'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactAddressForm.ContactAddressCustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  inputNamePrefix,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      inputNamePrefix={inputNamePrefix}
      onChange={onChange}
      defaultValue={defaultValue}
      isSending={isSending}
      saveButtonRenderCondition={saveButtonRenderCondition}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
