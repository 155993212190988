import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { INTERNAL_PATH_V2 } from '../../../../../app.router';

export const V2Menu = ({ className }) => {
  const { organizationId } = useParams();

  const handleClick = () => {
    const url = generatePath(INTERNAL_PATH_V2 + '/agentParcels', {
      organizationId,
    });
    window.location.href = url;
  };

  return (
    <li className={className}>
      <a
        href={generatePath(INTERNAL_PATH_V2 + '/jobs', {
          organizationId,
        })}
        className={`nav-link nav-icon-size-medium`}
        data-cy="link-v2"
        onClick={handleClick}
      >
        <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faExchangeAlt} />
        </div>
        <div className="nav-link-text">V2</div>
      </a>
    </li>
  );
};
