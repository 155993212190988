import {
  ContactStatusDto,
  LinkDto,
  FileType,
  LinkResourceBaseDto,
  ContactStatusDtoPagedResult,
  CreateContactStatusCommandValues,
  UpdateContactStatusCommandValues,
} from '../../models/data.models';
import { execLink, downloadLinkFile, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';

export const GET_CONTACTSTATUSES_LINK_KEY = 'get-contactstatuses';
export const CREATE_CONTACTSTATUS_LINK_KEY = 'create-contactstatus';
export const GET_CONTACTSTATUS_LINK_KEY = 'get-contactstatus';
export const UPDATE_CONTACTSTATUS_LINK_KEY = 'update-contactstatus';
export const DELETE_CONTACTSTATUS_LINK_KEY = 'delete-contactstatus';
export const IMPORT_CONTACTSTATUSES_LINK_KEY = 'import-contactstatuses';
export const EXPORT_CONTACTSTATUSES_LINK_KEY = 'export-contactstatuses';
export const CONTACTSTATUS_ENTITY_NAME = 'Contact Status';

export interface GetContactStatusParams {
  statusId: number;
}

export interface ImportContactStatusesParams {
  organizationId: number;
}

export interface ExportContactStatusesParams extends ListParams {
  organizationId: number;
  fileType: FileType;
}

export const getContactStatusRequest = async (
  resource: LinkResourceBaseDto | null,
  { statusId }: GetContactStatusParams,
): Promise<ContactStatusDto> => {
  if (resource && statusId) {
    const getContactStatusLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTSTATUS_LINK_KEY,
    );
    if (getContactStatusLink) {
      const result = await execLink(getContactStatusLink, { statusId });
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTACTSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const getContactStatusesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContactStatusDtoPagedResult> => {
  if (resource) {
    const getContactStatusesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTSTATUSES_LINK_KEY,
    );
    if (getContactStatusesListLink) {
      const result = await execLink(getContactStatusesListLink, params);
      return result.data;
    }
  }
  throw new Error(
    `Action ${GET_CONTACTSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const createContactStatusRequest = async (
  resource: LinkResourceBaseDto,
  contactStatus: CreateContactStatusCommandValues,
) => {
  const createContactStatusLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTACTSTATUS_LINK_KEY,
  );
  if (createContactStatusLink) {
    const result = await execLink(createContactStatusLink, contactStatus);
    return result.data;
  }
  throw new Error(
    `Action ${CREATE_CONTACTSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const updateContactStatusRequest = async (
  resource: LinkResourceBaseDto,
  contactStatus: UpdateContactStatusCommandValues,
) => {
  const updateContactStatusLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTACTSTATUS_LINK_KEY,
  );
  if (updateContactStatusLink) {
    const result = await execLink(updateContactStatusLink, contactStatus);
    return result.data;
  }
  throw new Error(
    `Action ${UPDATE_CONTACTSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const deleteContactStatusRequest = async (
  contactStatus: ContactStatusDto,
) => {
  const deleteContactStatusLink = contactStatus.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTACTSTATUS_LINK_KEY,
  );
  if (deleteContactStatusLink) {
    const result = await execLink(deleteContactStatusLink, contactStatus);
    return result.data;
  }
  throw new Error(
    `Action ${DELETE_CONTACTSTATUS_LINK_KEY} is not allowed or missing link action`,
  );
};

export const importContactStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ImportContactStatusesParams,
  file: File,
) => {
  const importContactStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === IMPORT_CONTACTSTATUSES_LINK_KEY,
  );
  if (importContactStatusesLink) {
    const result = await postFormData(importContactStatusesLink, params, [
      file,
    ]);
    return result.data;
  }
  throw new Error(
    `Action ${IMPORT_CONTACTSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};

export const exportContactStatusesRequest = async (
  resource: LinkResourceBaseDto,
  params: ExportContactStatusesParams,
) => {
  const exportContactStatusesLink = resource?.links?.find(
    (x: LinkDto) => x.rel === EXPORT_CONTACTSTATUSES_LINK_KEY,
  );
  if (exportContactStatusesLink) {
    const result = await downloadLinkFile(exportContactStatusesLink, params);
    return result;
  }
  throw new Error(
    `Action ${EXPORT_CONTACTSTATUSES_LINK_KEY} is not allowed or missing link action`,
  );
};
