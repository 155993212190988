import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';
import { SelectJobStatusPaginate } from '../../jobStatuses/components/jobStatus-select.component';

export const JobForm = ({
  id = 'jobForm',
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

JobForm.CustomValues = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'customValues'}
      id={'customValues'}
      label={'Custom Values'}
      placeholder={'Custom Values'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};

JobForm.CustomerId = ({
  selectedFilter = ``,
  nameId = 'customerName',
  placeholder = 'Customer',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'customerId',
  header = 'Customer',
  onChange = () => {},
  readonly,
  contactTypes = [],
  labelStyles,
  showContactType,
  showContactName,
  canCreateNewEntity,
  canUpdateEntity,
  getOptionLabel,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={showContactType}
      showContactName={showContactName}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      labelStyles={labelStyles}
      canCreateNewEntity={canCreateNewEntity}
      canUpdateEntity={canUpdateEntity}
      getOptionLabel={getOptionLabel}
    />
  );
};

JobForm.Description = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      id={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};

JobForm.DivisionId = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
  canUpdateEntity = true,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};

JobForm.EmployeeId = ({
  selectedFilter = ``,
  nameId = 'employeeName',
  placeholder = 'Employee',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'employeeId',
  header = 'Employee',
  onChange = () => {},
  readonly,
  contactTypes = [],
  labelStyles,
  showContactType,
  showContactName,
  canCreateNewEntity,
  canUpdateEntity,
  getOptionLabel,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={showContactType}
      showContactName={showContactName}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      labelStyles={labelStyles}
      canCreateNewEntity={canCreateNewEntity}
      canUpdateEntity={canUpdateEntity}
      getOptionLabel={getOptionLabel}
    />
  );
};

JobForm.JobNumber = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'jobNumber'}
      id={'jobNumber'}
      label={'Job Number'}
      placeholder={'Job Number'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
    />
  );
};

JobForm.JobStatusId = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Job Status',
  required = true,
  defaultValue,
  sort = 'priority',
  id = 'jobStatusId',
  header = 'Job Status',
  onChange = () => {},
  readonly,
  nameId = 'jobStatusName',
  disabled,
  canUpdateEntity = true,
}: InputPublicProps) => {
  return (
    <SelectJobStatusPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeJobStatus={onChange}
      isDisabled={disabled}
      canUpdateEntity={canUpdateEntity}
    />
  );
};
