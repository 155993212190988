import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { BillsListScreen } from './screens/bills-list.screen';
import { BillsScreen } from './screens/bills.screen';

export const BILLS_LIST_PATH = '/bills';
export const BILLS_EDIT_PATH = '/bills/:accountingTransactionId';
export const BILLS_CREATE_PATH = '/bills/0';

export const BillsRoute = () => {
  const group = 'Accounting';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <BillsListScreen pageTitle="Bills" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <BillsScreen pageTitle="Add New Bill" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':accountingTransactionId'}
        element={
          <PrivateRoute>
            <BillsScreen pageTitle="Update Bill" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
