import { Dialog } from '../../common/components/dialog/dialog.component';
import { SalesTaxEdit } from './salesTax-edit.component';
import { SalesTaxDto } from '../../../models/data.models';

export type SalesTaxDialogProperties = {
  salesTaxId?: number | null;
  title: string;
};

export class SalesTaxDialog extends Dialog<SalesTaxDialogProperties> {
  constructor(props: SalesTaxDialogProperties) {
    super(props);
    this.onSalesTaxSaved = this.onSalesTaxSaved.bind(this);
  }

  onSalesTaxSaved = (salesTax: SalesTaxDto) => {
    this.close(salesTax);
  };

  renderContent(): any {
    const { salesTaxId } = this.props as SalesTaxDialogProperties;
    return (
      <SalesTaxEdit
        salesTaxId={salesTaxId}
        onSalesTaxCreated={this.onSalesTaxSaved}
        onSalesTaxUpdated={this.onSalesTaxSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
