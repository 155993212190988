import React from 'react';
import { OrderEditFormDto } from '../../../../models/custom.models';
import { OrderEntityDto, ContactType } from '../../../../models/data.models';
import { SelectContactPaginate } from './../../../contacts/components/contact-react-select.component';
import { SelectContactAddressesPaginate } from './../../../contactAddresses/components/contactAddresses-react-select.component';

type OrderEntitiesFormProps = {
  oceanOrderContextFormValue: OrderEditFormDto;
  setOceanOrderContextFormValue: (orderDto: any) => void;
};

export const getOrderEntitySelects = (
  entity: OrderEntityDto,
  header: string,
  placeholder: string,
  contactTypes: ContactType[],
  filter: string,
  onContactChange: (data: any) => void,
  onAddressChange: (data: any) => void,
) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <SelectContactPaginate
            header={header}
            placeholder={`Select ${placeholder}`}
            id={`${entity.contactId}`}
            nameId={`${entity.contactId}`}
            selectedValue={
              entity?.contactId && entity?.contactName && entity?.contactType
                ? {
                    contactId: entity?.contactId,
                    name: entity?.contactName,
                    contactType: entity?.contactType,
                  }
                : ''
            }
            onChangeContact={onContactChange}
            contactTypes={contactTypes}
            selectedFilter={filter}
            isMulti={false}
            closeMenuOnSelect={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <SelectContactAddressesPaginate
            contactId={entity.contactId}
            isDisabled={!entity.contactId}
            header={'Address'}
            placeholder={'Select address'}
            id={`${entity.contactAddressId}`}
            nameId={`${entity.contactAddressId}`}
            selectedValue={
              entity?.contactAddressId && entity?.contactAddressName
                ? {
                    contactAddressId: entity?.contactAddressId,
                    addressLine: entity?.contactAddressName,
                  }
                : ''
            }
            onChangeContactAddress={onAddressChange}
            closeMenuOnSelect={true}
            selectedFilter={`contactId:${entity?.contactId}`}
            contactType={entity?.contactType}
          />
        </div>
      </div>
    </>
  );
};

export const OrderEntitiesForm = ({
  oceanOrderContextFormValue,
  setOceanOrderContextFormValue,
}: OrderEntitiesFormProps) => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          {getOrderEntitySelects(
            oceanOrderContextFormValue.shipper,
            'Shipper',
            'Select shipper',
            [
              ContactType.Customer,
              ContactType.Vendor,
              ContactType.ForwardingAgent,
              ContactType.Contact,
            ],
            `contactType: ${ContactType.Customer} OR 
                          contactType: ${ContactType.Vendor} OR 
                          contactType: ${ContactType.ForwardingAgent} OR 
                          contactType: ${ContactType.Contact}`,
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.shipper.contactId = Number(data?.contactId);
                orderDto.shipper.contactName = data?.name;
                orderDto.shipper.contactType = data?.contactType;
                orderDto.shipper.contactAddressId = null;
                orderDto.shipper.contactAddressName = null;
                return { ...orderDto };
              });
            },
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.shipper.contactAddressId = Number(
                  data?.contactAddressId,
                );
                orderDto.shipper.contactAddressName = data?.addressLine;
                return { ...orderDto };
              });
            },
          )}
        </div>
        <div className="col-6">
          {getOrderEntitySelects(
            oceanOrderContextFormValue.ultimateConsignee,
            'Ultimate Consignee',
            'Select consignee',
            [
              ContactType.Customer,
              ContactType.Vendor,
              ContactType.ForwardingAgent,
              ContactType.Contact,
            ],
            `contactType: ${ContactType.Customer} OR 
                          contactType: ${ContactType.Vendor} OR 
                          contactType: ${ContactType.ForwardingAgent} OR 
                          contactType: ${ContactType.Contact}`,
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.ultimateConsignee.contactId = Number(data?.contactId);
                orderDto.ultimateConsignee.contactName = data?.name;
                orderDto.ultimateConsignee.contactType = data?.contactType;
                orderDto.ultimateConsignee.contactAddressId = null;
                orderDto.ultimateConsignee.contactAddressName = null;
                return { ...orderDto };
              });
            },
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.ultimateConsignee.contactAddressId = Number(
                  data?.contactAddressId,
                );
                orderDto.ultimateConsignee.contactAddressName =
                  data?.addressLine;
                return { ...orderDto };
              });
            },
          )}
        </div>
      </div>

      <div className="row mb-7">
        <div className="col-6">
          {getOrderEntitySelects(
            oceanOrderContextFormValue.consignee,
            'Consignee',
            'Select consignee',
            [
              ContactType.Customer,
              ContactType.Vendor,
              ContactType.ForwardingAgent,
              ContactType.Contact,
            ],
            `contactType: ${ContactType.Customer} OR 
                       contactType: ${ContactType.Vendor} OR 
                       contactType: ${ContactType.ForwardingAgent} OR 
                       contactType: ${ContactType.Contact}`,
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.consignee.contactId = Number(data?.contactId);
                orderDto.consignee.contactName = data?.name;
                orderDto.consignee.contactType = data?.contactType;
                orderDto.consignee.contactAddressId = null;
                orderDto.consignee.contactAddressName = null;
                return { ...orderDto };
              });
            },
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.consignee.contactAddressId = Number(
                  data?.contactAddressId,
                );
                orderDto.consignee.contactAddressName = data?.addressLine;
                return { ...orderDto };
              });
            },
          )}
        </div>
      </div>

      <div className="row mb-7">
        <div className="col-6">
          {getOrderEntitySelects(
            oceanOrderContextFormValue.notifyParty,
            'Notify Party',
            'Select notify party',
            [
              ContactType.Customer,
              ContactType.Vendor,
              ContactType.ForwardingAgent,
              ContactType.Contact,
            ],
            `contactType: ${ContactType.Customer} OR 
                       contactType: ${ContactType.Vendor} OR 
                       contactType: ${ContactType.ForwardingAgent} OR 
                       contactType: ${ContactType.Contact}`,
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.notifyParty.contactId = Number(data?.contactId);
                orderDto.notifyParty.contactName = data?.name;
                orderDto.notifyParty.contactType = data?.contactType;
                orderDto.notifyParty.contactAddressId = null;
                orderDto.notifyParty.contactAddressName = null;
                return { ...orderDto };
              });
            },
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.notifyParty.contactAddressId = Number(
                  data?.contactAddressId,
                );
                orderDto.notifyParty.contactAddressName = data?.addressLine;
                return { ...orderDto };
              });
            },
          )}
        </div>
        <div className="col-6">
          {getOrderEntitySelects(
            oceanOrderContextFormValue.intermediate,
            'Intermediate',
            'Select intermediate',
            [
              ContactType.Customer,
              ContactType.Vendor,
              ContactType.ForwardingAgent,
              ContactType.Contact,
            ],
            `contactType: ${ContactType.Customer} OR 
                       contactType: ${ContactType.Vendor} OR 
                       contactType: ${ContactType.ForwardingAgent} OR 
                       contactType: ${ContactType.Contact}`,
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.intermediate.contactId = Number(data?.contactId);
                orderDto.intermediate.contactName = data?.name;
                orderDto.intermediate.contactType = data?.contactType;
                orderDto.intermediate.contactAddressId = null;
                orderDto.intermediate.contactAddressName = null;
                return { ...orderDto };
              });
            },
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.intermediate.contactAddressId = Number(
                  data?.contactAddressId,
                );
                orderDto.intermediate.contactAddressName = data?.addressLine;
                return { ...orderDto };
              });
            },
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {getOrderEntitySelects(
            oceanOrderContextFormValue.forwardingAgent,
            'Forwarding Agent',
            'Select agent',
            [
              ContactType.Customer,
              ContactType.Vendor,
              ContactType.ForwardingAgent,
              ContactType.Contact,
            ],
            `contactType: ${ContactType.Customer} OR 
                       contactType: ${ContactType.Vendor} OR 
                       contactType: ${ContactType.ForwardingAgent} OR 
                       contactType: ${ContactType.Contact}`,
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.forwardingAgent.contactId = Number(data?.contactId);
                orderDto.forwardingAgent.contactName = data?.name;
                orderDto.forwardingAgent.contactType = data?.contactType;
                orderDto.forwardingAgent.contactAddressId = null;
                orderDto.forwardingAgent.contactAddressName = null;
                return { ...orderDto };
              });
            },
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.forwardingAgent.contactAddressId = Number(
                  data?.contactAddressId,
                );
                orderDto.forwardingAgent.contactAddressName = data?.addressLine;
                return { ...orderDto };
              });
            },
          )}
        </div>
        <div className="col-6">
          {getOrderEntitySelects(
            oceanOrderContextFormValue.destinationAgent,
            'Destination agent',
            'Select agent',
            [
              ContactType.Customer,
              ContactType.Vendor,
              ContactType.ForwardingAgent,
              ContactType.Contact,
            ],
            `contactType: ${ContactType.Customer} OR 
                       contactType: ${ContactType.Vendor} OR 
                       contactType: ${ContactType.ForwardingAgent} OR 
                       contactType: ${ContactType.Contact}`,
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.destinationAgent.contactId = Number(data?.contactId);
                orderDto.destinationAgent.contactName = data?.name;
                orderDto.destinationAgent.contactType = data?.contactType;
                orderDto.destinationAgent.contactAddressId = null;
                orderDto.destinationAgent.contactAddressName = null;
                return { ...orderDto };
              });
            },
            (data: any) => {
              setOceanOrderContextFormValue((orderDto) => {
                orderDto.destinationAgent.contactAddressId = Number(
                  data?.contactAddressId,
                );
                orderDto.destinationAgent.contactAddressName =
                  data?.addressLine;
                return { ...orderDto };
              });
            },
          )}
        </div>
      </div>
    </>
  );
};
