import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  EntityTypes,
  OrderDocumentDto,
  OrderDto,
  OrderTypes,
} from '../../../models/data.models';
import {
  deleteOrderDocument,
  deleteOrderDocumentsFx,
  getOrderDocumentsFx,
  orderDocumentsFiltersStore,
  orderDocumentsTableStore,
} from '../orderDocuments.store';
import { useStore } from 'effector-react';
import { OrderDocumentsFilters } from './orderDocumentsFilters';
import { OrderEditFormDto } from '../../../models/custom.models';
import { OrderDocumentsList } from '../../airShipmentOrders/components/orderDocuments-list-component';
import {
  FaAngleDown,
  FaEllipsisH,
  FaListUl,
  FaPlus,
  FaTh,
} from 'react-icons/fa';
import { showDialog } from '../../common/dialog.store';
import { OrderDocumentsDialog } from './orderDocuments.dialog';
import { authStore } from '../../auth/auth.store';
import { OrderDocumentsGrid } from '../../common/components/grid/orderDocuments-grid.component';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { Dropdown } from 'react-bootstrap';
import { OrderDocumentsFiltersSelector } from './orderDocumentsFiltersSelector';
import { getDocumentsLinks } from '../../../utils/helper.utils';
import { OrderDocumentDialog } from './orderDocument.dialog';
import { getOrderDocumentLink } from '../orderDocuments.service';

export interface OrderDocumentsFragmentProps {
  orderContextFormValue: OrderEditFormDto;
  onSaveOrder: (data: OrderDto) => OrderDto;
  setIsSending: (sending: boolean) => void;
  documentTemplatesFilter?: string;
  setOrderContextFormValue: () => {};
  orderType: OrderTypes;
  allowCreateOrderDocument: boolean;
}

export const OrderDocumentsFragment = ({
  orderContextFormValue,
  onSaveOrder,
  setIsSending,
  documentTemplatesFilter,
  setOrderContextFormValue,
  orderType,
  allowCreateOrderDocument = true,
}: OrderDocumentsFragmentProps) => {
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(8);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState<
    OrderDocumentDto[]
  >([]);
  const { orderDocumentColumns: columns } = useStore(orderDocumentsTableStore);
  const { user: currentUser } = authStore.getState();
  const [orderDocuments, setOrderDocuments] = useState<OrderDocumentDto[]>();
  const [isCardsActive, setIsCardActive] = useState(true);
  const [isTableActive, setIsTableActive] = useState(false);
  const [isDeleteActive, setIsDeleteActive] = useState(false);
  const [checkedFilters, setCheckedFilters] = useState([]);
  const { orderDocumentsFilters: filters } = useStore(
    orderDocumentsFiltersStore,
  );

  const getData = async (filter?: string) => {
    getOrderDocumentsFx({
      order: orderContextFormValue,
      params: {
        offset,
        limit,
        sort,
        filter: filter ? filter : `orderId:${orderContextFormValue.orderId}`,
        search,
      },
    }).then(
      (documentsData) => {
        getDocumentsLinks(documentsData.items).then(() =>
          setOrderDocuments(documentsData?.items),
        );
      },
      () => {},
    );
  };

  const onLimitChange = (limit: number) => {
    setLimit(limit);
    setOffset(0);
  };

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const isBOLCreationDisabled = (): boolean => {
    if (orderType == OrderTypes.ParcelShipment) {
      return !orderContextFormValue?.orderEntities.find(
        (entity) => entity.entityType === EntityTypes.Consignee,
      )?.orderEntityId;
    } else {
      return (
        !orderContextFormValue?.shipper?.contactId ||
        !orderContextFormValue?.consignee?.contactId
      );
    }
  };

  const onEditOrderDocument = (document: OrderDocumentDto) => {
    if (document?.orderDocumentId) {
      showDialog({
        dialog: OrderDocumentDialog,
        props: {
          title: 'Update Order',
          orderDocumentId: document?.orderDocumentId,
          order: orderContextFormValue,
          className: 'modal-edit-attachment',
        },
      }).then(() => {
        getData();
      });
    }
  };

  useEffect(() => {
    getData();
  }, [offset, limit, search]);

  useEffect(() => {
    if (selectedDocuments.length > 0) {
      setIsDeleteActive(true);
    } else {
      setIsDeleteActive(false);
    }
  }, [selectedDocuments]);

  const createDocumentTemplatesDialog = () => {
    if (documentTemplatesFilter != null) {
      showDialog({
        dialog: OrderDocumentsDialog,
        props: {
          title: '',
          isBolCreationDisabled: isBOLCreationDisabled,
          documentTemplateFilter: documentTemplatesFilter,
          saveButtonRenderCondition:
            currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
          orderContext: orderContextFormValue,
          className: 'modal-order-documents',
          onSaveOrder: onSaveOrder,
        },
      }).then(
        async () => await getData(),
        () => {},
      );
    }
  };

  const deleteSelectedOrderDocuments = () => {
    if (selectedDocuments?.length > 0) {
      const actionDropdown = document.querySelector(
        '.actions-dropdown',
      ) as HTMLDivElement;
      actionDropdown.click();
      deleteOrderDocumentsFx({
        order: orderContextFormValue,
        documents: selectedDocuments,
      }).then(() => {
        getData().then(() => setSelectedDocuments([]));
      });
    }
  };

  const onFilterOrderDocuments = (query: string) => {
    getData(query);
  };

  const onDeleteDocument = (document: OrderDocumentDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${document.orderDocumentId} document`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(async (result) => {
      if (result) {
        await deleteOrderDocument(document);
        await getData();
      }
    });
  };

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) => (
      <span
        className="pointer"
        onClick={(e) => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        <FaEllipsisH size="15" color="#005C98" />
        &nbsp;
        <span className="align-bottom px-2 tab-items-text">Actions</span>
        &nbsp;
        <FaAngleDown size="15" color="#005C98" />
      </span>
    ),
  );

  const openDocument = async (document: OrderDocumentDto) => {
    const s3link = await getOrderDocumentLink(document);
    window.open(s3link ?? document?.lastGeneratedFile, '_blank');
  };

  return (
    <div>
      <div className={'d-flex mb-4'}>
        <div
          className={
            'col-5 d-flex align-items-center justify-content-start pl-0 pr-0'
          }
        >
          <FiSearch className={'position-relative'} style={{ left: '30px' }} />
          <input
            type="search"
            style={{ minHeight: '40px' }}
            className={
              'form-control flex-grow-1 search-with-icon w-auto shadow-none'
            }
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            placeholder={'Search'}
          />
        </div>
        <div className="col-2">
          <OrderDocumentsFiltersSelector
            filters={filters}
            checkedFilters={checkedFilters}
            setCheckedFilters={setCheckedFilters}
          />
        </div>
        <div className="col-5 d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center actions-dropdown pr-5">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                <div className="px-5">
                  <div className="filters-form-block">
                    <a
                      className={
                        isDeleteActive
                          ? 'dropdown-item cursor-pointer'
                          : 'dropdown-item disabled cursor-pointer'
                      }
                      onClick={deleteSelectedOrderDocuments}
                    >
                      Delete selected documents
                    </a>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {allowCreateOrderDocument && (
            <div
              className="pr-5 d-flex align-items-center text-end"
              onClick={createDocumentTemplatesDialog}
            >
              <FaPlus color="#005C98" size="12" />
              <span className="align-bottom pl-2 tab-items-text pointer">
                Add document
              </span>
            </div>
          )}
          <div className="pr-5">
            <FaTh
              className="pointer"
              color={isCardsActive ? '#005C98' : '#98A2B3'}
              size="20"
              onClick={() => {
                setIsCardActive(true);
                setIsTableActive(false);
              }}
            />
          </div>
          <div className="pr-4">
            <FaListUl
              className="pointer"
              color={isTableActive ? '#005C98' : '#98A2B3'}
              size="20"
              onClick={() => {
                setIsCardActive(false);
                setIsTableActive(true);
              }}
            />
          </div>
        </div>
      </div>
      {checkedFilters?.length > 0 && (
        <div className="order-document-tabs mb-4 py-3 pl-4">
          <OrderDocumentsFilters
            filters={checkedFilters}
            onFilter={onFilterOrderDocuments}
          ></OrderDocumentsFilters>
        </div>
      )}
      {orderDocuments?.length > 0 ? (
        <div>
          {isCardsActive && (
            <OrderDocumentsList
              airOrderContextFormValue={orderContextFormValue}
              onSaveAirOrder={onSaveOrder}
              setIsSending={setIsSending}
              showPagination={true}
              offset={offset}
              limit={limit}
              onLimitChange={onLimitChange}
              onPageChanged={onPageChanged}
              total={orderDocuments?.length}
              orderDocuments={orderDocuments}
              setSelectedDocuments={setSelectedDocuments}
              orderType={orderType}
              onDeleteDocument={onDeleteDocument}
              selectedDocuments={selectedDocuments}
              onEditDocument={onEditOrderDocument}
            />
          )}
          {isTableActive && (
            <OrderDocumentsGrid
              search={search}
              showEmptyTable={false}
              showAllStore={true}
              rowKeys={['orderDocumentId']}
              data={orderDocuments}
              columns={columns}
              offset={offset}
              limit={limit}
              total={orderDocuments?.length}
              sort={sort}
              onSort={setSort}
              onEdit={onEditOrderDocument}
              onPageChanged={onPageChanged}
              onColumnsChanged={null}
              onSelect={null}
              hideColumnsSelect={true}
              onLimitChange={onLimitChange}
              isDropDownList={true}
              showToolbar={false}
              onDelete={onDeleteDocument}
              onOpen={openDocument}
            />
          )}
        </div>
      ) : (
        <div className="no-found-card order-document-tabs mb-4">
          <h3>No documents found</h3>
          <img className="search-icon"></img>
          <h4>
            You can add a document by click on{' '}
            <span className="no-found-card-span">Add document</span>
          </h4>
        </div>
      )}
    </div>
  );
};
