import React from 'react';
import { useStore } from 'effector-react';
import { dialogStore, closeDialog } from '../../dialog.store';

export const DialogsHolder = () => {
  const { dialogs } = useStore(dialogStore);
  return (
    <div>
      {dialogs.map((dialog, index) => {
        return (
          <dialog.modal
            key={index}
            {...dialog.props}
            close={(result) => {
              dialog._resolve(result);
              closeDialog(dialog);
            }}
          />
        );
      })}
    </div>
  );
  // return <div>{dialogs.map((dialog) => {return dialog})}</div>;
};
