import {
  PaymentDto,
  LinkDto,
  LinkResourceBaseDto,
  EntityFieldDto,
  FileType,
  CreatePaymentCommandValues,
  UpdatePaymentCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPaymentRequest,
  getPaymentsListRequest,
  getPaymentRequest,
  deletePaymentRequest,
  updatePaymentRequest,
  importPaymentsRequest,
  exportPaymentsRequest,
  GetPaymentParams,
} from './payments.service';

import { getEntityFieldListRequest } from '../entityFields/entityFields.service';
import { addEffectStatusHandling } from '../common/messages.store';

type PaymentsStoreState = {
  links: LinkDto[];
  paymentColumns: EntityFieldDto[];
  defaultPaymentColumns: EntityFieldDto[];
  defaultSort: string;
  defaultLimit: number;
};

export const getPaymentsColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Payment',
  });
});

addEffectStatusHandling(getPaymentsColumnsFx);

export const getPaymentsDefaultColumnsFx = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();
  return getEntityFieldListRequest(currentOrganization, {
    entityName: 'Payment',
  });
});

addEffectStatusHandling(getPaymentsDefaultColumnsFx);

export const updatePaymentsColumns = createEvent<EntityFieldDto[]>();

export const getPaymentsFx = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPaymentsListRequest(currentOrganization, params);
});

addEffectStatusHandling(getPaymentsFx);

export const createPaymentFx = createEffect((paymentData: PaymentDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  paymentData.checkNumber = paymentData.checkNumber ?? '';

  const createPaymentCommand: CreatePaymentCommandValues = { ...paymentData };

  return createPaymentRequest(currentOrganization!, createPaymentCommand);
});

addEffectStatusHandling(createPaymentFx, {
  completeMessage: 'Payment was created successfully',
  errorMessage: 'Payment was not created',
});

export const getPaymentFx = createEffect((paymentParams: GetPaymentParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPaymentRequest(
    currentOrganization as LinkResourceBaseDto,
    paymentParams,
  );
});

addEffectStatusHandling(getPaymentFx);

export const updatePaymentFx = createEffect((payment: PaymentDto) => {
  payment.checkNumber = payment.checkNumber ?? '';
  const updatePaymentCommand: UpdatePaymentCommandValues = { ...payment };
  return updatePaymentRequest(payment, updatePaymentCommand);
});

addEffectStatusHandling(updatePaymentFx, {
  completeMessage: 'Payment was updated successfully',
  errorMessage: 'Payment was not updated',
});

export const deletePaymentFx = createEffect((payment: PaymentDto) => {
  return deletePaymentRequest(payment);
});

addEffectStatusHandling(deletePaymentFx, {
  completeMessage: 'Payment was deleted successfully',
  errorMessage: 'Payment was not deleted',
});

export const exportPaymentsFx = createEffect(
  async ({ listResource }: { listResource: LinkResourceBaseDto }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
      fileType: FileType.Csv,
    };

    await exportPaymentsRequest(listResource as LinkResourceBaseDto, params);
  },
);
addEffectStatusHandling(exportPaymentsFx, {
  completeMessage: 'Payments were exported successfully',
  errorMessage: 'Payments were not exported',
});

export const importPaymentsFx = createEffect(
  async ({
    file,
    listResource,
  }: {
    file: File;
    listResource: LinkResourceBaseDto;
  }) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const params = {
      organizationId: currentOrganization.organizationId,
    };
    await importPaymentsRequest(
      listResource as LinkResourceBaseDto,
      params,
      file,
    );
  },
);
addEffectStatusHandling(importPaymentsFx, {
  completeMessage: 'Payments were imported successfully',
  errorMessage: 'Payments were not imported',
  inFlightMessage: 'Importing payments...',
});

const defaultState: PaymentsStoreState = {
  links: [],
  paymentColumns: [],
  defaultPaymentColumns: [],
  defaultSort: '',
  defaultLimit: 20,
};

export const paymentStore: Store<PaymentsStoreState> = createStore(defaultState)
  .on(getPaymentsColumnsFx.done, (state, payload) => {
    return {
      ...state,
      paymentColumns: payload.result.items,
      defaultPaymentColumns: payload.result.items,
    };
  })
  .on(getPaymentsDefaultColumnsFx.done, (state, payload) => {
    return {
      ...state,
      defaultPaymentColumns: payload.result.items,
    };
  })
  .on(updatePaymentsColumns, (state, payload) => {
    return { ...state, paymentColumns: payload };
  });
