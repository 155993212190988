import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { UserGroupScreen } from './screens/userGroup.screen';
import { UserGroupsListScreen } from './screens/userGroups-list.screen';

export const USERGROUPS_LIST_PATH = '/userGroups';
export const USERGROUP_EDIT_PATH = '/userGroups/:userGroupId';
export const USERGROUP_CREATE_PATH = '/userGroups/0';

export const UserGroupRoute = (props: RouteProps) => {
  const group = 'Configuration';
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute>
            <UserGroupsListScreen pageTitle="User Groups" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={'0'}
        element={
          <PrivateRoute>
            <UserGroupScreen pageTitle="Add New User Group" group={group} />
          </PrivateRoute>
        }
      />
      <Route
        path={':userGroupId'}
        element={
          <PrivateRoute>
            <UserGroupScreen pageTitle="Update User Group" group={group} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
