import React from 'react';
import { OrganizationDto, UserDto } from '../../../../models/data.models';
import { navigationStore } from '../../nav.store';
import { Button } from '../button/button.component';
import { useStore } from 'effector-react';
import { HiPlus } from 'react-icons/hi';
import { FaChevronRight } from 'react-icons/fa';

export type TopBarProps = {
  title: string | null;
  user?: UserDto | null;
  addPath?: string | null;
  addPathLabel?: string | null;
  createPath?: string | null;
  createAction?: (() => void) | null;
  createLabel: string;
  logout?: () => void;
  organizationSetCurrent?: (organization: OrganizationDto) => void;
  organizations?: OrganizationDto[];
  currentOrganization?: OrganizationDto | null;
  goToPage?: (path: string) => void;
  toolbar?: React.ReactNode | null;
};

export const TopBar = ({
  goToPage = () => {},
  title = null,
  createPath = null,
  createAction = null,
  createLabel = '',
  addPath = null,
  addPathLabel = null,
  toolbar = null,
}: TopBarProps) => {
  const navStore = useStore(navigationStore);

  return (
    <div className="top-bar p-4 d-flex flex-column">
      <div className={'mb-5'}>
        <span className="top-bar-path">
          {navStore?.path?.map((i, index) => {
            if (navStore?.path?.length - 1 > index)
              return (
                <React.Fragment key={index}>
                  <span>{i}</span>
                  <FaChevronRight className={'mx-2'} />
                </React.Fragment>
              );
            else return <span key={index}>{i}</span>;
          })}
        </span>
      </div>
      <div className={'d-flex justify-content-between'}>
        <div className="d-flex">
          <span className="top-bar-header-name pr-5 text-nowrap">{title}</span>
        </div>
        <div className={'d-flex'}>
          {navStore?.actionButtons &&
            navStore?.actionButtons?.map((b) => {
              return b;
            })}
          {addPath && addPathLabel ? (
            <Button
              size={'lg'}
              color={'primary'}
              onClick={() => {
                goToPage && goToPage(addPath);
              }}
              data-cy="btn-create-new"
            >
              <HiPlus className={'mr-2'} />
              {addPathLabel}
            </Button>
          ) : null}
          {createPath ? (
            <Button
              size={'lg'}
              color={'primary'}
              onClick={() => {
                if (createAction) {
                  createAction();
                } else {
                  goToPage && goToPage(createPath);
                }
              }}
              data-cy="btn-create-new"
              className={addPath && addPathLabel && 'ml-3'}
            >
              <HiPlus className={'mr-2'} />
              New {createLabel}
            </Button>
          ) : null}
          {toolbar && toolbar}
        </div>
      </div>
    </div>
  );
};
