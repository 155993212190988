import { createEvent, createStore, Event, Store } from 'effector';

const defaultState = {
  menuOpen: false,
  path: ['Operations', 'Orders'],
  actionButtons: [],
};

export const toggleMenu: Event<void> = createEvent();
export const toggleMenuItem: Event<string> = createEvent();
export const openMenuItem: Event<string> = createEvent();
export const closeMenuItem: Event<string> = createEvent();
export const setCurrentPagePath: Event<string[]> = createEvent();
export const setActionButtons: Event<JSX.Element[]> = createEvent();
export const clearActionButtons: Event<void> = createEvent();

export const navigationStore: Store<any> = createStore(defaultState)
  .on(toggleMenuItem, (state, payload) => {
    return { ...state, [payload]: !state[payload] };
  })
  .on(openMenuItem, (state, payload) => {
    return { ...state, [payload]: true };
  })
  .on(closeMenuItem, (state, payload) => {
    return { ...state, [payload]: false };
  })
  .on(toggleMenu, (state) => {
    return { ...state, menuOpen: !state.menuOpen };
  })
  .on(setCurrentPagePath, (state, payload) => {
    return { ...state, path: payload };
  })
  .on(setActionButtons, (state, payload) => {
    return { ...state, actionButtons: payload };
  })
  .on(clearActionButtons, (state) => {
    return { ...state, actionButtons: [] };
  });
