import React from 'react';
import { LoginForm } from './login.form';
import { Button } from '../../common/components/button/button.component';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginParams } from '../auth.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import GoogleButton from 'react-google-button';

export type LoginProps = {
  onLogin?: (loginParams: LoginParams) => void;
  className?: string;
};

const initialValues = {
  username: null,
  password: null,
};

const loginSchema = Yup.object().shape({
  username: Yup.string().required("Can't be blank").nullable(true),
  password: Yup.string().required("Can't be blank").nullable(true),
});

export const Login = ({ onLogin = () => undefined, className }: LoginProps) => {
  const signInWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    scope: 'email profile openid',
    onSuccess: async (codeResponse) => {
      onLogin({
        grant_type: 'external-google',
        redirect_uri: window.location.origin,
        code: codeResponse.code,
        rememberMe: true,
      });
    },
    onError: (errorResponse) =>
      addMessage({
        message: `${errorResponse}`,
        type: 'danger',
        id: v4(),
      }),
  });

  return (
    <div className={className}>
      <LoginForm
        onSubmit={({ username, password, rememberMe }) => {
          onLogin({
            grant_type: 'password',
            username,
            password,
            rememberMe,
          });
        }}
        id={'LoginForm'}
        validationSchema={loginSchema}
        initialValues={initialValues}
      >
        <LoginForm.Username />
        <LoginForm.Password />
        <LoginForm.RememberMe />
        <Button
          name="sign-in"
          type="submit"
          color="primary"
          className="btn-block"
        >
          Sign In
        </Button>
        <GoogleButton
          onClick={() => signInWithGoogle()}
          className="mt-2 mx-auto"
        >
          Google
        </GoogleButton>
        <div className="mt-5">
          <div className="d-flex justify-content-center">
            <span>Don’t have an account?</span>
            <span>
              <Link to="/register" data-cy="link-sign-up">
                &nbsp;Sign up here
              </Link>
            </span>
          </div>
        </div>
      </LoginForm>
    </div>
  );
};
