import {
  PortDto,
  LinkDto,
  LinkResourceBaseDto,
  PortDtoPagedResult,
  GetEmailCommandValues,
  ContactDtoPagedResult,
  EmailDto,
  SendDocumentCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_EMAIL_LINK_KEY = 'get-email';
export const SEND_EMAIL_LINK_KEY = 'send-email';

export const getEmailRequest = async (
  resource: LinkResourceBaseDto,
  emailParams: GetEmailCommandValues,
): Promise<EmailDto> => {
  const getEmailLink = resource?.links?.find(
    (x: LinkDto) => x.rel === GET_EMAIL_LINK_KEY,
  );
  if (getEmailLink) {
    const result = await execLink(getEmailLink, emailParams);
    return result.data;
  }
  addMessage({
    message: `Action ${GET_EMAIL_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const sendEmailRequest = async (
  resource: LinkResourceBaseDto,
  emailParams: SendDocumentCommandValues,
): Promise<EmailDto> => {
  const sendEmailLink = resource?.links?.find(
    (x: LinkDto) => x.rel === SEND_EMAIL_LINK_KEY,
  );
  if (sendEmailLink) {
    const result = await execLink(sendEmailLink, emailParams);
    return result.data;
  }
  addMessage({
    message: `Action ${SEND_EMAIL_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
