import { v4 } from 'uuid';
import { executeMutation } from '../../../graphql/graphql.service';
import { parseTemplate } from '../../components/layout/component-hooks';
import { addMessage } from '../../messages.store';

export const workflowAction = async ({
  actionProps,
  data,
  latestStoreValues,
  source,
  onAction,
}) => {
  if (!actionProps.workflow.workflowId) {
    throw new Error('Workflow ID is required');
  }
  const wfVariables = parseTemplate(actionProps.workflow.inputs, {
    ...latestStoreValues,
    ...source?.formikContext?.values,
    ...data,
  });

  const workflowId = parseTemplate(actionProps.workflow.workflowId, {
    ...latestStoreValues,
    ...source?.formikContext?.values,
    ...data,
  });

  try {
    const result = await executeMutation({
      mutation: `mutation ExecuteWorkflowMutation($input: ExecuteWorkflowInput!) {
        executeWorkflow(input: $input) {
           workflowExecutionResult {
              outputs
          }
        }
      }`,
      variables: {
        input: {
          organizationId: data.organizationId,
          workflowId,
          variables: wfVariables,
        },
      },
    });

    if (actionProps.workflow.onSuccess && result) {
      await onAction(
        actionProps.workflow.onSuccess,
        {
          ...data,
          ...result.executeWorkflow?.workflowExecutionResult,
          result: result.executeWorkflow?.workflowExecutionResult,
        },
        latestStoreValues,
        source,
      );
    }
    return result.executeWorkflow?.workflowExecutionResult;
  } catch (e) {
    if (actionProps.workflow.onError) {
      await onAction(
        actionProps.workflow.onError,
        {
          error: { ...e, message: e.cause?.result?.errors[0]?.message },
          ...data,
        },
        latestStoreValues,
        source,
      );
    } else {
      const parseGraphQLError = (graphQlException) => {
        const errors = graphQlException?.networkError?.result?.errors;
        if (errors) {
          let allErrors = ['Workflow execution failed:'];

          if (graphQlException && errors && Array.isArray(errors)) {
            errors.forEach((error) => {
              if (error.extensions && error.extensions.errors) {
                const errorTypes = Object.keys(error.extensions.errors);
                errorTypes.forEach((type) => {
                  if (Array.isArray(error.extensions.errors[type])) {
                    allErrors = allErrors.concat(error.extensions.errors[type]);
                  }
                });
              }
            });
          }

          return allErrors.join('\n');
        }
        return graphQlException?.message;
      };

      addMessage({
        message: (parseGraphQLError(e) || 'Error executing workflow') as string,
        type: 'danger',
        autoHide: true,
        id: v4(),
      });
    }
    return false;
  }
};
